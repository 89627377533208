import _ from "lodash";
import moment from "moment";
import dataService, { fetchDataSimple } from "../services/data.service";
import { API_REGISTRY, TILES, ABORT_REQUEST_CONTROLLERS, APP_CACHE } from "./config";
import { TABLE_CONFIG } from "./table-config";
import TableToExcel, { MAX_VALUE_16BITS } from "@linways/table-to-excel";
import AbortController from "abort-controller";
import IndexedDBManager from "../indexed-db/indexed-db"
import DataService from "../services/data.service";
import { UPDATE_STATE } from '../actions/types';

export const getSumOfData = (data, options) => {
    let totals = [];
    const calcKpis = ['Occ', 'Revenue', 'Rooms'];
    const { capacity } = options;
   data.forEach((o)=> {
        o.forEach((item, i) => {
            totals[i] = totals[i] ? totals[i] : {};
            let kpis = Object.keys(o[i]);
            kpis.forEach(kpi => {
                totals[i][kpi] = totals[i][kpi] ? totals[i][kpi] : 0;
                if (calcKpis.includes(kpi)) {
                    let kpiVal = getCorrectValue(o[i][kpi]);
                    totals[i][kpi] = _.sum([totals[i][kpi], kpiVal]);
                } else {
                    totals[i][kpi] = item[kpi];
                }
            });
            totals[0].segment = 'Total'; 
            totals[0].segment_code = 'Total';
            if (totals[i].hasOwnProperty('ADR')) {
                totals[i].ADR = _.divide(totals[i].Revenue, totals[i].Rooms);
            }
            if (totals[i].hasOwnProperty('RevPAR')) {
                totals[i].RevPAR = _.divide(totals[i].Revenue, capacity);
            }
        });
    });
    return totals;
    // console.log(totals);
}

export const calculateIdentityVariance = (a, b, controls) => {
    // debugger;
    let calc = {};
    // let a = primary;
    // let b = pickup;
    if (a && b) {
        let kpis = Object.keys(a);
        const ignoreKpis = ['Capacity', 'MedianSellRate', 'SellRate_xx'];
        const { identifier } = controls;
        kpis.map(kpi => {
            if (!ignoreKpis.includes(kpi)) {
                calc[kpi] = (a[kpi] - b[kpi]) / b[kpi] * 100;
                calc[kpi] = calc[kpi] && isFinite(calc[kpi]) ? _.round(calc[kpi], 2) + '%' : '0%';
            } else {
                calc[kpi] = a[kpi];
            }
            calc[kpi] = calc[kpi] ? calc[kpi] : '0%'; //Aded to fixe #27
        })
    }
    return calc;
};

export const calculateIdentityDiff = (a, b, controls) => {
    let calc = {};
    if (a && b) {
        let kpis = Object.keys(a);
        const ignoreKpis = ['Capacity', 'MedianSellRate', 'SellRate_xx'];
        const { identifier } = controls;
        kpis.map(kpi => {
            if (!ignoreKpis.includes(kpi)) {
                if (['marketotbpickup', 'otb_difference', 'otb_sdly_diff'].includes(identifier)) {
                    if (kpi === 'ADR') {
                        calc[kpi] = round(divide((a['Revenue'] - b['Revenue']), (a['Rooms'] - b['Rooms'])))
                    } else {
                        calc[kpi] = getCorrectValue(a[kpi]) - getCorrectValue(b[kpi])
                    }
                    
                } else {
                    calc[kpi] = getCorrectValue(a[kpi]) - getCorrectValue(b[kpi]);
                    
                }
            } else {
                calc[kpi] = a[kpi];
            }
            calc[kpi] = calc[kpi] ? _.round(calc[kpi], 2) : 0; //Aded to fixe #27
        })
    }
    return calc;
};

export const getDayDateKey = (dayDate = 'day', mainKey) => {
    if (dayDate === 'day') {
        return mainKey;
    }
    let dataKey;
    switch (mainKey) {
        case "sdly":
            dataKey = "sdtly";
            break;
        case "sdly_actual":
            dataKey = "sdtly_actual";
            break;
        case "sdly_pickup":
            dataKey = "sdtly_pickup";
            break;
        default:
            dataKey = mainKey;
            break;
    }
    return dataKey;
}

export const getCacheDataKey = (params) => {

    const { active_tiles, stayRange, data_id, property_id, workspace_controls, selected_market, comp_stay_range } = params;
    const { as_of_date, date_from, date_until } = stayRange;
    let comp_date_from = (comp_stay_range.date_from) ? comp_stay_range.date_from : '';
    let comp_date_until = (comp_stay_range.date_util) ? comp_stay_range.date_util : '';
    let comp_as_of_date = (comp_stay_range.as_of_date) ? comp_stay_range.as_of_date : '';
    const { segmentType, sdYear, sdlyOn, dba, pickUpType, pickUpDays, scopeType, compareTo, clearComparision, unconstrained, dayDate } = workspace_controls;
    let market_id = (selected_market && selected_market.market_id) ? selected_market.market_id : "";
    let sdly_key = sdlyOn ? '__' + sdYear : '';
    let compare_to = clearComparision ? '__' + compareTo : '';
    let constrained_val = unconstrained ? '__' + unconstrained : '';
    return active_tiles.join('_')
        + '__' + property_id
        + '__' + as_of_date
        + '__' + date_from
        + '__' + date_until
        + '__' + comp_date_from
        + '__' + comp_date_until
        + '__' + comp_as_of_date
        + sdly_key
        + '__' + dba
        + '__' + pickUpType
        + '__' + pickUpDays
        + '__' + segmentType
        + '__' + scopeType
        + '__' + market_id
        + '__' + compare_to
        + '__' + constrained_val
        + '__' + dayDate
        ;
};

export const isSameDateRange = (dateObj1, dateObj2) => {
    let d1 = dateObj1; let d2 = dateObj2;
    let isSame = false;
    if (d1 && d2) {
        let d1Kys = Object.keys(d1);
        // debugger;
        isSame = d1Kys.every((key) => {
            return d1[key] && d2[key] && d1[key] === d2[key];
        })
    }
    return isSame;
}

export const correctTotalValue = (forecastData, segments) => {
    let output = [];
    if (forecastData) {
        let date = '';
        let segmentsKeys = Object.keys(segments);

        // Handling in case of market tile segmented
        if (segments.length) {
            segmentsKeys = segments.map(i => i.code);
        }

        forecastData.forEach(item => {
            if (item.stay_date !== date) {
                let totalObj = {
                    predicted_total_cons_adr: 0,
                    predicted_total_cons_occ: 0,
                    predicted_total_cons_revenue: 0,
                    predicted_total_cons_revpar: 0,
                    predicted_total_cons_rooms: 0,
                    predicted_total_uncons_adr: 0,
                    predicted_total_uncons_occ: 0,
                    predicted_total_uncons_revenue: 0,
                    predicted_total_uncons_revpar: 0,
                    predicted_total_uncons_rooms: 0
                };
                let totalObjKeys = Object.keys(totalObj);
                date = item.stay_date;
                let dateData = forecastData.filter(itm => itm.stay_date === date);
                let capacity = 0;
                segmentsKeys.forEach(code => {
                    let groupCodeItem = dateData.find(item => item.market_code === code);

                    if (groupCodeItem) {
                        capacity = groupCodeItem.capacity;
                        totalObjKeys.forEach(k => {
                            let key = k.replaceAll('_total', '');
                            totalObj[k] += getCorrectValue(groupCodeItem[key]);
                        });
                    }
                });
                totalObj.predicted_total_cons_adr = totalObj.predicted_total_cons_revenue / totalObj.predicted_total_cons_rooms;
                totalObj.predicted_total_cons_revpar = totalObj.predicted_total_cons_revenue / capacity;

                totalObj.predicted_total_uncons_adr = totalObj.predicted_total_uncons_revenue / totalObj.predicted_total_uncons_rooms;
                totalObj.predicted_total_uncons_revpar = totalObj.predicted_total_uncons_revenue / capacity;

                dateData.forEach((data, i) => {
                    let tmp = { ...data, ...totalObj };
                    output.push(tmp);
                });
                // debugger;
            }
        });
    }

    return output;
};

export const hasSameControls = (stateControls, currControls) => {
    let keys = Object.keys(stateControls);
    keys.forEach((val, i) => {
        if (currControls && currControls[val] && currControls[val] !== stateControls[val]) {
            return false;
        }
    })
    return true;
}

export const cleanIDB = () => {
    //Cleaning indexed db for API response cache
    const idbManager = new IndexedDBManager();
    idbManager.openDB()
        .then(() => {
            idbManager.cleanIdbStore();
        })
        .then(() => {
            idbManager.closeDB();
        });
}

export const alterApiParams = (api, varMap) => {
    const indexOfQ = api.indexOf("?");
    const indexOfn = api.indexOf("&");
    let altered_api = api;
    let q = [];
    let q2 = [];
    if (indexOfQ > -1 && indexOfn > -1) {
        q = api.split('?');
        q2 = q[1].split('&');
        let q3 = q2.map(q => {
            let temp = q.split('=');
            let key = temp[0];
            let value = temp[1];
            if (varMap[key]) {
                return key + '=' + varMap[key];
            }
            return key + '=' + value;
        });
        if (q[1]) {
            q[1] = q3.join('&');
            altered_api = q.join('?');
        }
    }

    return altered_api;
}

export const aggregateSegmentedGroupedData = (data = [], isForecast = false) => {
    const output = {};
    if (!isForecast) {
        data.forEach((item, i) => {
            const identities = Object.keys(item);
            let dbaIndex = identities.indexOf('dba');
            // debugger;
            if (dbaIndex > -1) identities.splice(dbaIndex, 1);
            identities.forEach(identity => {
                output[identity] = output[identity] ? output[identity] : {};
                let kpis = Object.keys(item[identity]);

                kpis.forEach(kpi => {

                    if (!output[identity][kpi]) output[identity][kpi] = 0;
                    if (!item[identity][kpi]) data[i][identity][kpi] = 0;
                    if (kpi === 'date') {
                        output[identity][kpi] = data[i][identity][kpi];
                    }
                });


                kpis.forEach(kpi => {
                    if (!['Capacity', 'date', 'SellRate'].includes(kpi)) {
                        output[identity][kpi] += Number(item[identity][kpi]);
                    } else if (['SellRate', 'Capacity'].includes(kpi) && item[identity][kpi]) {
                        output[identity][kpi] = Number(item[identity][kpi]);
                    }
                });
                output[identity]['ADR'] = output[identity]['Revenue'] / output[identity]['Rooms'];
            })
        });
    }
    if (isForecast) {
        // if(data_id && data_id === 'ForecastMarketSegment') debugger;
        data.forEach((item, i) => {
            let ignore_keys = ['asof_date', 'capacity', 'dba', 'hotel_segment', 'market_code', 'market_segment', 'pid', 'stay_date', 'sdly_date'];
            let item_keys = Object.keys(item);
            let kpis = ['adr', 'occ', 'revenue', 'revpar', 'rooms'];
            item_keys.forEach((key) => {
                let isTotalKey = key.split('_total_');
                if (!ignore_keys.includes(key) && isTotalKey.length < 2) {
                    output[key] = output[key] ? output[key] : 0;
                    output[key] = sum(output[key], Number(item[key]));
                } else {
                    if (!output[key]) output[key] = item[key]
                }
            });
            item_keys.forEach((key) => {
                let kpi = key.split('_').reverse();
                //Correcting value of ADR calculation
                if (kpis.includes(kpi[0]) && kpi[0] === 'adr') {
                    let revenueKey = key.replaceAll('_adr', '_revenue');
                    let roomsKey = key.replaceAll('_adr', '_rooms');
                    output[key] = output[revenueKey] / output[roomsKey];
                }
                //output.predicted_cons_adr = output.predicted_cons_revenue / output.predicted_cons_rooms;
                //output.predicted_uncons_adr = output.predicted_uncons_revenue / output.predicted_uncons_rooms;
            });

        });
    }

    return output;
}

export const generalizeDataSet = function () {
    const cache = {};
    // debugger;
    return (dataSet = [], meta) => {
        const { stayDates, controls } = meta;
        const newDataSet = deepCopy(dataSet);
        const forecastDataIds = ['OSRContrainedForecast', 'MarketForecast', 'MarketForecastSegmented'];
        const supportedDataIds = [...forecastDataIds, 'OSRSegment', 'MarektARISegment', "GetRatePlan"];
        const ignoreDatas = [];
        const osrSegment = dataSet.find(d => d.data_id === 'OSRSegment');
        const marketARI = dataSet.find(d => d.data_id === 'marketARI');
        const marketARISegment = dataSet.find(d => d.data_id === 'marketARISegment');

        dataSet.forEach((D, i) => {
            const { data_id, data } = D;
            if (supportedDataIds.includes(data_id) && data.length) {
                // newDataSet.push(D);
                if (controls.scopeType === 'Date') {
                    const newD = deepCopy(D);
                    newD.data = [];

                    stayDates.forEach((date) => {
                        let dataItem = { index: { date: date }, total: {}, segmented: [] };
                        //Do for forecast data
                        if (forecastDataIds.includes(data_id)) {
                            let dateDatas = data.filter(d => new moment(d.stay_date).isSame(date, 'day'));
                            if (!dateDatas.length && osrSegment) {
                                dateDatas = osrSegment.data.find(d => new moment(d.index.date).isSame(date, 'day'));
                                dateDatas = dateDatas ? [dateDatas] : [];
                            }
                            dateDatas.forEach(item => {
                                let segmentCode = item.market_segment || item.market_code;
                                if (segmentCode) {
                                    let sgementedItem = { name: segmentCode, value: {} };
                                    //Do for hotel forecast data
                                    if (item.hotel_segment || item.hotel_segment) {
                                        sgementedItem.value = transformHotelForecastItem(item);
                                    }

                                    //Do for market forecast data
                                    if (item.market_segment) {
                                        sgementedItem.value = transformMarketForecastItem(item);
                                    }

                                    dataItem.segmented.push(sgementedItem);
                                }
                                if (!segmentCode) {
                                    let itemKeys = Object.keys(item);
                                    itemKeys.forEach(key => {
                                        let k = key.split('Segment_');
                                        if (k[1]) {
                                            segmentCode = k[1];
                                            let sgementedItem = { name: segmentCode, value: {} };
                                            //Do for hotel forecast data
                                            if (item.HotelTotal) {
                                                sgementedItem.value = deepCopy(item['Segment_' + segmentCode]);
                                            }

                                            //Do for market forecast data
                                            if (item.MarketTotal) {
                                                sgementedItem.value = deepCopy(item['MarketSegment_' + segmentCode]);
                                            }

                                            dataItem.segmented.push(sgementedItem);
                                        }
                                    });
                                }
                            });
                            // debugger;
                            //Collecting kpi values for total
                            dataItem.segmented.forEach((item) => {
                                let identities = Object.keys(item.value);
                                identities.forEach((identity) => {
                                    let item_idendtity = item.value[identity];
                                    dataItem.total[identity] = dataItem.total[identity] ? dataItem.total[identity] : {};

                                    let kpis = Object.keys(item_idendtity);
                                    kpis.forEach(kpi => {
                                        let item_idendtity_kpi = Number(item_idendtity[kpi]);
                                        // debugger;
                                        if (!isNaN(item_idendtity_kpi)) {
                                            dataItem.total[identity][kpi] = dataItem.total[identity][kpi] ? dataItem.total[identity][kpi] : 0;
                                            if (kpi === 'Occ') {
                                                item_idendtity_kpi = convertToPercent(item_idendtity_kpi);
                                            }
                                            if (kpi !== 'ADR') {
                                                dataItem.total[identity][kpi] = sum(dataItem.total[identity][kpi], item_idendtity_kpi, 13);
                                            }
                                        }


                                    });
                                    //correcting calculation for Occ/ADR
                                    // dataItem.total[identity].Occ = dataItem.total[identity].Revenue / dataItem.total[identity].Rooms;
                                    dataItem.total[identity].ADR = dataItem.total[identity].Revenue / dataItem.total[identity].Rooms;
                                });
                            });
                            newD.data.push(dataItem);
                            // debugger;
                        }
                        //Do for other data
                        if (!forecastDataIds.includes(data_id)) {

                            let dateDatas = data.filter(d => new moment(d.stay_date).isSame(date, 'day'));
                            if (!dateDatas.length && osrSegment) {
                                dateDatas = osrSegment.data.find(d => new moment(d.index.date).isSame(date, 'day'));
                                dateDatas = dateDatas ? [dateDatas] : [];
                            }
                            dateDatas.forEach(item => {
                                let segmentCode = item.market_segment || item.market_code;
                                if (segmentCode) {
                                    let sgementedItem = { name: segmentCode, value: {} };
                                    //Do for hotel forecast data
                                    if (item.hotel_segment || item.hotel_segment) {
                                        sgementedItem.value = transformHotelForecastItem(item);
                                    }

                                    //Do for market forecast data
                                    if (item.market_segment) {
                                        sgementedItem.value = transformMarketForecastItem(item);
                                    }

                                    dataItem.segmented.push(sgementedItem);
                                }
                                if (!segmentCode) {
                                    let itemKeys = Object.keys(item);
                                    itemKeys.forEach(key => {
                                        let k = key.split('Segment_');
                                        if (k[1]) {
                                            segmentCode = k[1];
                                            let sgementedItem = { name: segmentCode, value: {} };
                                            //Do for hotel forecast data
                                            if (item.HotelTotal) {
                                                sgementedItem.value = deepCopy(item['Segment_' + segmentCode]);
                                            }

                                            //Do for market forecast data
                                            if (item.MarketTotal) {
                                                sgementedItem.value = deepCopy(item['MarketSegment_' + segmentCode]);
                                            }

                                            dataItem.segmented.push(sgementedItem);
                                        }
                                    });
                                }
                            });
                            // debugger;
                            //Collecting kpi values for total
                            dataItem.segmented.forEach((item) => {
                                let identities = Object.keys(item.value);
                                identities.forEach((identity) => {
                                    let item_idendtity = item.value[identity];
                                    dataItem.total[identity] = dataItem.total[identity] ? dataItem.total[identity] : {};

                                    let kpis = Object.keys(item_idendtity);
                                    kpis.forEach(kpi => {
                                        let item_idendtity_kpi = Number(item_idendtity[kpi]);
                                        // debugger;
                                        if (!isNaN(item_idendtity_kpi)) {
                                            dataItem.total[identity][kpi] = dataItem.total[identity][kpi] ? dataItem.total[identity][kpi] : 0;
                                            if (kpi === 'Occ') {
                                                item_idendtity_kpi = convertToPercent(item_idendtity_kpi);
                                            }
                                            if (kpi !== 'ADR') {
                                                dataItem.total[identity][kpi] = sum(dataItem.total[identity][kpi], item_idendtity_kpi, 13);
                                            }
                                        }


                                    });
                                    //correcting calculation for Occ/ADR
                                    // dataItem.total[identity].Occ = dataItem.total[identity].Revenue / dataItem.total[identity].Rooms;
                                    dataItem.total[identity].ADR = dataItem.total[identity].Revenue / dataItem.total[identity].Rooms;
                                });
                            });
                            newD.data.push(dataItem);
                            // debugger;

                        }
                    });
                    newDataSet.push(newD);

                } else if (controls.scopeType === 'Dba') {
                    //Do for Dba data

                } else {
                    newDataSet.splice(i, 1, D);
                }
            }
        });
        // debugger;
        return newDataSet;
    }
}

export const transformMarketForecastItem = (item) => {
    let blankKpis = { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0, RevPAR: 0 };
    let keys = item ? Object.keys(item) : [];
    let dateItemObj = { forecast: deepCopy(blankKpis), forecast_cons: deepCopy(blankKpis), forecast_uncons: deepCopy(blankKpis) };
    if (keys.includes('market_segment')) {
        dateItemObj.forecast = deepCopy(blankKpis);
        dateItemObj.forecast_cons = deepCopy(blankKpis);
        dateItemObj.forecast_uncons = deepCopy(blankKpis);
        let kpis = Object.keys(dateItemObj.forecast);
        //setting MarketTotal for forecast
        kpis.map((kpi) => {
            dateItemObj.forecast[kpi] = getCorrectValue(item["forecast_" + kpi.toLocaleLowerCase()]);
            dateItemObj.forecast_cons[kpi] = getCorrectValue(item["constrained_forecast_" + kpi.toLocaleLowerCase()]);
            dateItemObj.forecast_uncons[kpi] = getCorrectValue(item["unconstrained_forecast_" + kpi.toLocaleLowerCase()]);
        })
    }
    return dateItemObj;
}

export const transformHotelForecastItem = (item) => {
    let blankKpis = { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0, RevPAR: 0 };
    let keys = item ? Object.keys(item) : [];
    let dateItemObj = { forecast: deepCopy(blankKpis), forecast_cons: deepCopy(blankKpis), forecast_uncons: deepCopy(blankKpis) };
    if (keys.includes('market_code')) {
        dateItemObj.forecast = deepCopy(blankKpis);
        dateItemObj.forecast_cons = deepCopy(blankKpis);
        dateItemObj.forecast_uncons = deepCopy(blankKpis);
        let kpis = Object.keys(dateItemObj.forecast);
        //setting MarketTotal for forecast
        kpis.map((kpi) => {
            dateItemObj.forecast[kpi] = item["predicted_cons_" + kpi.toLocaleLowerCase()];
            dateItemObj.forecast_cons[kpi] = item["predicted_cons_" + kpi.toLocaleLowerCase()];
            dateItemObj.forecast_uncons[kpi] = item["predicted_uncons_" + kpi.toLocaleLowerCase()];
        })
    }
    return dateItemObj;
}

//This will return data from cache if available
export const getHelperData = (id) => {
    let cache = {};
    return () => {
        if (cache[id]) return cache[id];
        let data = getSeriesById(id);
        if (data && data.data) cache[id] = data.data;
        return cache[id];
    }
};

//This will return unique item from an array of numbers/strings
export const getUnique = (arr) => {
    return arr.filter((value, index) => arr.indexOf(value) === index);
};

//This adding to avoid any error in adding decimmal numbers
export const sum = (n1, n2, positions = 3) => {
    const factor = Math.pow(10, positions);
    let a = isNaN(n1) ? 0 : n1;
    let b = isNaN(n2) ? 0 : n2;
    return (a.toFixed(positions) * factor + b.toFixed(positions) * factor) / factor
}

export async function calculateCompStayRangePromise(params) {
    const apc = APP_CACHE;
    // debugger;
    let dataLevel = "properties";
    let srcId = params.property_id; //app_state.property_id;
    let meta = params.primary_stay_range;
    let workspace_controls = params.workspace_controls;
    let key = srcId + '__' + meta.as_of_date + '__' + meta.date_from + '__' + meta.date_until + '__' + workspace_controls.sdYear;
    let api = "rms/" + dataLevel + "/get-sdly/" + srcId + "/?asOfDate=" + meta.as_of_date + "&fromDate=" + meta.date_from + "&toDate=" + meta.date_until + "&year=" + workspace_controls.sdYear;
    //Return if already exists
    if (!apc.compStayRange) apc.compStayRange = {};
    if (apc.compStayRange[key]) return apc.compStayRange[key];

    return await dataService.fetchDataSimple({ api: api, request: { method: 'GET' } })
        .then((rs) => {
            let { as_of_date, from_date, to_date } = rs;

            // LRV-880 uncomment the below code
            // let apiSdtly = "rms/" + dataLevel + "/get-sdtly/" + srcId + "/?asOfDate=" + meta.as_of_date + "&fromDate=" + meta.date_from + "&toDate=" + meta.date_until + "&year=" + workspace_controls.sdYear;
            // return fetchDataSimple({ api: apiSdtly, request: { method: 'GET' } })
            //     .then((result) => {
            //         apc.compStayRange[key] = { as_of: meta.as_of_date, date_from: from_date, date_until: to_date, as_of_date: as_of_date, comp_as_of_date: as_of_date, sdtly_asof: result.as_of_date, sdtly_from: result.from_date, sdtly_to: result.to_date };
            //         return { as_of: meta.as_of_date, date_from: from_date, date_until: to_date, as_of_date: as_of_date, comp_as_of_date: as_of_date, sdtly_asof: result.as_of_date, sdtly_from: result.from_date, sdtly_to: result.to_date };
            //     }, (err) => {
            //         console.error("SDTLY api error ", err);
            //     })

            // LRV-880 remove the below 2 line
            apc.compStayRange[key] = { as_of: meta.as_of_date, date_from: from_date, date_until: to_date, as_of_date: as_of_date, comp_as_of_date: as_of_date };
            return { as_of: meta.as_of_date, date_from: from_date, date_until: to_date, as_of_date: as_of_date, comp_as_of_date: as_of_date };
            // return { date_from: from_date, date_until: to_date, as_of_date: as_of_date, comp_as_of_date: as_of_date };
            // app_state.comp_stay_range = { ...app_state.comp_stay_range, date_from: from_date, date_until: to_date, as_of_date: as_of_date, comp_as_of_date: as_of_date };

        }, (err) => {
            console.error(err);
        })
}

export function calculateCompStayRange(params) {
    // debugger;
    //Return from APP_CACHE if data is available
    const apc = APP_CACHE;
    let srcId = params.property_id; //app_state.property_id;
    let meta = params.primary_stay_range;
    let workspace_controls = params.workspace_controls;
    let key = srcId + '__' + meta.as_of_date + '__' + meta.date_from + '__' + meta.date_until + '__' + workspace_controls.sdYear;
    if (apc.compStayRange && apc.compStayRange[key]) return apc.compStayRange[key];

    let app_state = { primary_stay_range: params.primary_stay_range };

    let daysDiff = dateDiff(app_state.primary_stay_range.date_until, app_state.primary_stay_range.date_from);
    // let fromDateLastYear = new moment(app_state.primary_stay_range.date_from).subtract(1, 'year').startOf('months').format("YYYY-MM-DD");

    if (params.comp_stay_range) {
        app_state.comp_stay_range = params.comp_stay_range;
        app_state.comp_stay_range.date_until = new moment(params.comp_stay_range.date_from).add(daysDiff, 'day').format("YYYY-MM-DD");
        return app_state.comp_stay_range;
    }

    let primaryDateFrom = new moment(app_state.primary_stay_range.date_from);
    let isoWeekday = primaryDateFrom.weekday();
    // let isoWeek = primaryDateFrom.isoWeek();
    let lastYear = new moment(app_state.primary_stay_range.date_from).startOf('day').subtract(1, 'year');
    let fromDateLastYear = lastYear.weekday(isoWeekday).format("YYYY-MM-DD");

    let untilDateLastYear = new moment(fromDateLastYear).add(daysDiff, 'day').format("YYYY-MM-DD");
    let primaryAsOf = new moment(app_state.primary_stay_range.as_of_date);
    let sdlyAsOf = new moment(app_state.primary_stay_range.as_of_date).startOf('day').subtract(1, 'year')
        .week(primaryAsOf.week())
        .weekday(primaryAsOf.weekday());
    let cAsOfDate = sdlyAsOf.format("YYYY-MM-DD");
    let comp_stay_range = { date_from: fromDateLastYear, date_until: untilDateLastYear, comp_as_of_date: cAsOfDate, as_of_date: cAsOfDate };
    return comp_stay_range;
}

export function calculatePrimaryStayRange(params) {
    if (params.date_range) {
        let { date_until, date_from, as_of_date } = params.date_range;
        let date_range = { date_until, date_from, as_of_date };
        let daysDiff = dateDiff(date_until, date_from);
        date_range.date_from = params.date_from;
        date_range.date_until = new moment(params.date_from).add(daysDiff, 'day').format("YYYY-MM-DD");
        return date_range;
    }
}

export function abortRequestSafe(key, reason = "CANCELLED") {
    if (key && ABORT_REQUEST_CONTROLLERS.get(key)) {
        let ab = ABORT_REQUEST_CONTROLLERS.get(key);
        // debugger;
        ABORT_REQUEST_CONTROLLERS.get(key).abort(reason);
        console.log('ABORT_REQUEST_CONTROLLERS', ABORT_REQUEST_CONTROLLERS);
    }
}

export function setAbortSignal(key) {
    const newController = new AbortController();
    ABORT_REQUEST_CONTROLLERS.set(key, newController);
    return newController.signal;
}

export function clearAPIRegistryData() {
    for (let key in API_REGISTRY) {
        API_REGISTRY[key].data = null;
    }
}
export function clearAPIRegistryDataByKey(key) {
    API_REGISTRY[key].data = null;
}
export function isEmptyObj(obj) {
    return _.isEmpty(obj);
}
export const checkExistance = (val, blankValue = '', key = 'Revenue') => {
    // return (val) ? parseFloat(val).toFixed(2) : blankValue;
    if (key === "Rooms") {
        return (val) ? val.toLocaleString() : blankValue;
    } else {
        return (val) ? val.toLocaleString(undefined, { minimumFractionDigits: 2 }) : blankValue;
    }
}


export function getCorrectValue(v) {
    let val = v ? v : 0; //Filtering false values like null
    val = isFinite(v) ? v : 0;
    val = +val || 0;
    return val;
}

export function showPercentValues(val) {
    let result = 0;
    val = (val) ? val.toString() : '';
    if (val && val.includes('%')) {
        result = val.replaceAll('%', '');
    } else {
        result = val;
    }
    result = getCorrectValue(result).toFixed(2) + "%";
    return result;
}


export function convertTOCorrectValue(val, kpiType = "Occ", currSymbol = '') {
    if (kpiType !== "Rooms" && kpiType !== "Occ" && !Number(removeSpecialChar(val, currSymbol))) {
        val = +val || currSymbol + "0.00"
    }
    if (kpiType === "Occ" && !Number(removeSpecialChar(val, currSymbol))) {
        val = +val || "0.00%"
    }

    if (kpiType === "SellRate") {
        let updatedVal = val;//removeSpecialCharExceptDecimal(val);
        // console.log("checking sellrate ", val, "=>> ", updatedVal);

        // updatedVal = currSymbol+Math.round(updatedVal);  
        val = updatedVal;
    }
    if (kpiType === "Rooms") {
        if (val === "") {
            val = "0";
        }
    }
    return val;
}

export function removeSpecialCharExceptDecimal(val) {
    val = (val) ? val.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '') : '';
    return val;
}

export function removeCurrSymbol(val, currSymbol) {
    if (currSymbol && val) {
        val = val.replace(currSymbol, '');
    }
    return val;
}

export function removeSpecialChar(val, currSymbol) {
    val = (val) ? val.replace(/[&\/\\#,+()~%.'":*?<>{}]/g, '') : '';
    if (currSymbol) {
        val = val.replace(currSymbol, '');
    }
    return val;
}

export function removeSpecialCharWithOutDecimal(val) {
    val = (val) ? val.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '') : '';
    return val;
}


export function capitalize(str) {
    var pieces = str.split(" ");
    for (var i = 0; i < pieces.length; i++) {
        var j = pieces[i].charAt(0).toUpperCase();
        pieces[i] = j + pieces[i].substr(1);
    }
    return pieces.join(" ");
}

export function getDataSources(properties, propertyID) {
    const property_details = getFromArr(properties, 'id', propertyID);
    const { data_sources, active_source, default_source, i } = property_details;
    return { data_sources, active_source, default_source, index: i };
}

export function getPermissions(properties, propertyID) {
    const property_details = getFromArr(properties, 'id', propertyID);

    const { permissions, i } = property_details;
    return { permissions, index: i };
}

export const getDaysForPickupOcc = (app_state, ui_state) => {
    let startDate = moment(app_state.as_of_date).subtract(1, "days").format('YYYY-MM-DD');
    let endDate = app_state.primary_stay_range.date_until;
    let noOfDays = moment(startDate).diff(moment(endDate), "days");
    // console.log(startDate," ",endDate," from method => ",noOfDays);
    return Math.abs(noOfDays) + 1;
}

export const getDatesInRange = (start, end) => {
    let startDate = moment(start);
    let endDate = moment(end);
    let dates = [];
    while (startDate.isSameOrBefore(endDate)) {
        dates.push(startDate.format("YYYY-MM-DD"));
        startDate.add(1, 'day');
    }
    return dates;
}

export const getStayToSdly = (app_state, stay_date) => {
    const stayDateArray = getDatesInRange(app_state.primary_stay_range.date_from, app_state.primary_stay_range.date_until);
    const sdlyDateArray = getDatesInRange(app_state.metas.osr.sdly_from, app_state.metas.osr.sdly_to);
    const index = stayDateArray.indexOf(stay_date);
    const sdlyDate = sdlyDateArray[index];
    return sdlyDate;
}

export const getStayToSdtly = (app_state, stay_date) => {
    const stayDateArray = getDatesInRange(app_state.primary_stay_range.date_from, app_state.primary_stay_range.date_until);
    const sdlyDateArray = getDatesInRange(app_state.metas.osr.sdtly_from, app_state.metas.osr.sdtly_to);
    const index = stayDateArray.indexOf(stay_date);
    const sdlyDate = sdlyDateArray[index];
    return sdlyDate;
}

export const getStayToComp = (app_state, stay_date) => {
    const stayDateArray = getDatesInRange(app_state.primary_stay_range.date_from, app_state.primary_stay_range.date_until);
    const compDateArray = getDatesInRange(app_state.comp_stay_range.date_from, app_state.comp_stay_range.date_until);
    const index = stayDateArray.indexOf(stay_date);
    const compDate = compDateArray[index];
    return compDate;
}

export const getSDLYorSDTLYDate = (sdlyOn, app_state) => {
    const { date_from, date_until } = app_state.comp_stay_range;
    const { dayDate } = app_state.workspace_controls;

    let fromDate2 = date_from;
    let toDate2 = date_until;

    if (sdlyOn && dayDate === 'day') {
        fromDate2 = dateFormat(app_state.metas.osr.sdly_from, "YYYY-MM-DD");
        toDate2 = dateFormat(app_state.metas.osr.sdly_to, "YYYY-MM-DD");
    }

    if (sdlyOn && dayDate === 'date') {
        fromDate2 = dateFormat(app_state.metas.osr.sdtly_from, "YYYY-MM-DD");
        toDate2 = dateFormat(app_state.metas.osr.sdtly_to, "YYYY-MM-DD");
    }

    return { fromDate2, toDate2 }
}

export const formattedCompDateCalc = (sdlyOn, formatted_stay_date, app_state) => {
    let date = dateFormat(getStayToComp(app_state, formatted_stay_date), "YYYY-MM-DD");
    const { dayDate } = app_state.workspace_controls;
    if (sdlyOn && dayDate === "day") {
        date = dateFormat(getStayToSdly(app_state, formatted_stay_date), "YYYY-MM-DD");
    }
    if (sdlyOn && dayDate === "date") {
        date = dateFormat(getStayToSdtly(app_state, formatted_stay_date), "YYYY-MM-DD");
    }
    return date;
}

export const in_array = (arr, searchElementArr) => {
    let isFound = false;
    searchElementArr.map((item) => {
        if (arr.includes(item)) {
            isFound = true;
        }
    })
    return isFound;
}

// this function is used to pause the code for the given amount of time 
export function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
        end = new Date().getTime();
    }
}

export const checkProperValue = (val) => {
    let correctValue = (val && isFinite(val)) ? val : 0.00
    return correctValue;
}


// function to replace multi values from a string 
export function replaceMultiText(text, correction) {
    const reg = new RegExp(Object.keys(correction).join("|"), "g");
    return text.replace(reg, (matched) => correction[matched]);
}
export function generateTableHead(table, cols) {
    let thead = table.createTHead();
    let row = thead.insertRow();
    cols.forEach(key => {
        let th = document.createElement("th");
        key = key.replace("_", " ");
        let text = document.createTextNode(key);
        th.appendChild(text);
        row.appendChild(th);
    })


}

export function generateTable(table, data) {

    for (let element of data) {
        let row = table.insertRow();
        for (let key of Object.keys(element)) {
            let cell = row.insertCell();
            let val = element[key];

            if (!isNaN(val) && val !== "") {
                cell.dataset.t = "n";
            }

            let text = document.createTextNode(element[key]);
            cell.appendChild(text);
        }
    }
}

export function downloadReportToExcel(name, selectedPropertyName, tableID, exportType) {
    let exportFileName = name + "_" + selectedPropertyName;
    let table = document.getElementById(tableID);
    if (exportType === 'csv') {
        downloadcsvwithTable(table, exportFileName);
    } else {
        TableToExcel.convert(table, {
            name: exportFileName + ".xlsx",
            sheet: {
                name: name.toUpperCase()
            }
        });
    }



}

export function generateRoomTypeExcel(name, selectedPropertyName, roomtype_pricing_overrides, exportType) {
    const table = document.createElement('table');
    let exportFileName = name + "_" + selectedPropertyName;
    let thead = table.createTHead();
    let row = thead.insertRow();
    let colData = ["Date", "Dow", "Rooms & Rate"];
    colData.forEach(col => {
        let th = document.createElement("th");
        let text = document.createTextNode(col);
        th.appendChild(text);
        row.appendChild(th);
    })

    Object.keys(roomtype_pricing_overrides).forEach((stay_date, i) => {
        const tr = table.insertRow();
        colData.forEach((col, j) => {
            const td = tr.insertCell();
            let text = "";
            if (col === "Date") {
                text = stay_date
            }
            if (col === "Dow") {
                text = moment(stay_date).format("ddd")
            }

            if (col === "Rooms & Rate") {
                for (const [key, item] of Object.entries(roomtype_pricing_overrides[stay_date])) {
                    text += `${key}- ${item.SellRate} `
                }
            }
            td.appendChild(document.createTextNode(text));
            tr.appendChild(td);
        })
        table.appendChild(tr);
    })

    if (exportType === 'csv') {
        downloadcsvwithTable(table, exportFileName);
    } else {
        TableToExcel.convert(table, {
            name: exportFileName + ".xlsx",
            sheet: {
                name: name.toUpperCase()
            }
        });
    }

}
export function getSystemSettings(property_id, property_details) {

    const prop_details = property_details.filter((item, index) => {
        if (item.id === property_id) {
            return item.id === property_id;
        }
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    return system_settings[0];
}
export const getCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return "";
}
export const totalRowCalculation = (tile, app_state, i, displayDataFormat) => {
    console.log("Tile => ", tile);
    if (tile.toLowerCase() === "market_summaryx") {

    } else {
        let occValue = (tile.toLowerCase() === 'pace') ? i.Occ / app_state.workspace_controls.dba : i.Occ;
        let revParValue = (tile.toLowerCase() === 'pace') ? i.RevPAR / app_state.workspace_controls.dba : i.RevPAR;
        revParValue = ((typeof revParValue).toLowerCase() === "string") ? revParValue : displayDataFormat(revParValue, "RevPAR", "");
        let adrValue = ((typeof i.ADR).toLowerCase() === "string") ? i.ADR : displayDataFormat(i.ADR, "ADR", "");
        i.occValue = occValue;
        i.revParValue = revParValue;
        i.adrValue = adrValue;
    }
    return i;
    // console.log(i);
}

export const processColumnsNew = (mainCol) => {
    mainCol.sort(function (a, b) { return a.order - b.order });
    mainCol.map((item, index) => {
        item.order = index + 1;
        let subColumns = item.subcolumns
        subColumns.sort(function (a, b) { return a.order - b.order });
    })

    let sortedColumns = [...mainCol];
    return sortedColumns;
}

export const validateEmail = (email) => {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(email);
}

//Common functions for display value formatting
export const displayFormat = (value, type, options) => {
    let isCurrencyNegative = type === 'currency' && value < 0 ? true : false;
    let formatted = isCurrencyNegative ? Math.abs(value) : value;
    switch (type) {
        case "percent":
            formatted += "%";
            break;
        case "currency":
            formatted = currencyFormat(formatted, options);
            break;
    }
    if (isCurrencyNegative) {
        return '(' + formatted + ')';
    }
    return formatted;
}

export const currencyFormat = (val, currency) => {
    //debugger;
    return currency.symbol + val;
}


// function to get Local data 

export function getLocalData(auth, app_state, property_id) {
    const { property_details, default_property_config, application_config } = auth.profile;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === property_id) {
            return item.id === property_id;
        }
    });
    const { system_settings } = (prop_details && prop_details.length) ? prop_details[0] : null;
    // console.log(app_state.property_id,"system_settings[0].default_locale",system_settings);
    let default_locale_name = (system_settings && system_settings[0].default_locale) ? system_settings[0].default_locale : 'default';
    if (!application_config['locales'][default_locale_name]) default_locale_name = 'default';
    const localeSettings = {
        currency_symbol: application_config['locales'][default_locale_name]['currency'],
        currencyCode: application_config['locales'][default_locale_name]['currencyCode']
    };
    return localeSettings;
}


// function is used to get the application data 
// Parameter : auth , app_state

export function getAppData(auth, app_state) {
    const { user, profile } = auth;
    const { property_details } = auth.profile || [];
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
    });
    const { system_settings } = prop_details && prop_details.length ? prop_details[0] : null;
    let inventory_settings_ = {};
    if (system_settings && system_settings.length && system_settings[0].inventory_settings) {
        inventory_settings_ = JSON.parse(JSON.stringify(system_settings[0].inventory_settings));
        inventory_settings_.current.external_systems[0].system_info =
            inventory_settings_.current.external_systems[0].system_info || " ";
        const inventory_settings = { ...inventory_settings_ };

        return system_settings[0];
    } else {
        return system_settings[0];
    }

}

// function is used to get all dates from the range selected
// Parameters : startDate / endDate
export function getAllDates(startDate, endDate) {
    let rangeDates = [];
    for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
        let dateValue = m.format('YYYY-MM-DD');
        if (!rangeDates.includes(dateValue)) {
            rangeDates.push(dateValue);
        }
    }
    return rangeDates;
}


// function is used to get the list of years only ,from the range selected
// Parameters : startDate / endDate
export function getList(startDate, endDate) {
    let rangeYears = [];
    for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
        let yearValue = m.format('YYYY');
        //console.log(m," yearValue ",yearValue);
        if (!rangeYears.includes(yearValue)) {
            rangeYears.push(yearValue);
        }
    }
    return rangeYears;
}

export function daysCountFromRange(app_state) {
    let date1 = moment(app_state.primary_stay_range.date_from);
    let date2 = moment(app_state.primary_stay_range.date_until);
    let daysCount = date2.diff(date1, 'days') + 1;
    return daysCount;
}

export function getObjectFilter(array, value, key) {
    return array.filter(key
        ? a => a[key] === value
        : a => Object.keys(a).some(k => a[k] === value)
    );
}


// sort an string array by key 

export const sortStringArray = (arr, key) => {
    console.log(arr, key);
    arr.sort(function (a, b) {
        let x = a[key].toLowerCase();
        let y = b[key].toLowerCase();
        if (x < y) { return -1; }
        if (x > y) { return 1; }
        return 0;
    });
    return arr;
}

// function to truncate the string and add the 3 dots ...

export function truncate(str, n) {
    return (str && str.length > n) ? str.slice(0, n - 1) + '...' : str;
};

export const numberFormatForReport = (key) => {
    let formatValues = { type: 'n', numfmt: '0.00' };
    if (key === "compdate" || key === "date" || key === "dow" || key === "month" || key === "segment"
        || key === "UpdateTime" || key === "User" || key === "Action" || key === "Status" || key === "Roomsrate" || key === "Restrictions" || key === "rec" || key === "cur") {
        formatValues = { type: 's', numfmt: '' }
    } else if (key === "Occ") {
        formatValues = { type: 'n', numfmt: '' }
    } else if (key === "Rooms") {
        formatValues = { type: 'n', numfmt: '0' }
    } else {
        formatValues = { type: 'n', numfmt: '$0.00' }
    }
    return formatValues;
}

export const calc = (n1, n2, limit = 2) => {
    let multiplier = 1;
    for (let i = 0; i < limit; i++) {
        multiplier = multiplier * 10;
    }
    return {
        diff: () => {
            return (Math.round(n2 * multiplier) - Math.round(n1 * multiplier)) / multiplier
        },
        add: () => {
            n1 = Number(n1);
            n2 = Number(n2);
            if (isNaN(n1)) {
                n1 = 0;
            }
            if (isNaN(n2)) {
                n2 = 0;
            }
            return (Math.round(n2 * multiplier) + Math.round(n1 * multiplier)) / multiplier
        }
    }
}
export function getStringFromHTML(htmlStr) {
    let temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = htmlStr;
    let textValue = temporaryElement.textContent || temporaryElement.innerText || "";
    return textValue
}

export function getRestrictionsStr(restrictions, house_level = true) {
    let arr = [];
    Object.keys(restrictions).forEach((key, index) => {
        if (key !== "stay_date") {
            if (key === "ctd" || key === "cta") {
                if (restrictions[key] === true) {
                    arr.push(key.toUpperCase())
                }

            }
            if (key === "close") {
                if (restrictions[key] === true) {
                    arr.push("CLS");
                }

            }
            if (key === "min_los") {
                if (restrictions[key] > 1) {
                    arr.push("MN " + restrictions[key])
                }

            }
            if (key === "max_los") {
                if (restrictions[key] > 1) {
                    arr.push("MX " + restrictions[key])
                }

            }
        }
    })
    if (arr.length === 0) {
        return null;
    }
    return arr.join(", ");
}
export const getRestrictionsArray = (restrictions) => {
    const array = [];
    if (restrictions.cta) {
        array.push("CTA");
    }
    if (restrictions.ctd) {
        array.push("CTD");
    }
    if (restrictions.close) {
        array.push("CLS");
    }
    if (restrictions.min_los > 1) {
        array.push("MN " + restrictions.min_los);
    }
    if (restrictions.max_los > 1) {
        array.push("MX " + restrictions.max_los);

    }

    return array;
}

/***
    This function checks if the password meets the following criteria:
    Contains at least one lowercase letter [a-z]
    Contains at least one uppercase letter [A-Z]
    Contains at least one digit [0-9]
    Contains at least one special character from the provided set [~!@#$%^&*()_-+={[}]|\:;"'<,>.?/]`
    Has a minimum length of 8 characters {8,}
****/
export const isPasswordVaild = (password, validItems) => {
    let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]).{8,}$/;

    // // Check if the password matches the pattern
    // return pattern.test(password);
    if (!pattern.test(password)) {
        // Define messages for different scenarios
        var messages = [];
        if (!/(?=.*[a-z])/.test(password)) {
            messages.push("Password must contain at least one lowercase letter.");
            validItems[0].valid = false;
        } else {
            validItems[0].valid = true;
        }
        if (!/(?=.*[A-Z])/.test(password)) {
            messages.push("Password must contain at least one uppercase letter.");
            validItems[1].valid = false;
        } else {
            validItems[1].valid = true;
        }
        if (!/(?=.*\d)/.test(password)) {
            messages.push("Password must contain at least one digit.");
            validItems[2].valid = false;
        } else {
            validItems[2].valid = true;
        }
        if (!/(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])/.test(password)) {
            messages.push("Password must contain at least one special character.");
            validItems[3].valid = false;
        } else {
            validItems[3].valid = true;
        }
        if (password.length < 8) {
            messages.push("Password must be at least 8 characters long.");
            validItems[4].valid = false;
        } else {
            validItems[4].valid = true;
        }

        return { valid: false, validItems };
    } else {
        validItems = validItems.map(i => {
            i.valid = true;
            return i;
        });
        return { valid: true, validItems };
    }


}
export function getResourceIdByPropId(prop_id, propDetails) {
    // debugger; 
    if (!propDetails) { return null; }
    const property_details = propDetails;
    let prop_details_index = 0;
    const property_detail = property_details.filter((item, index) => {
        return item.id === prop_id;
        //
    });
    if (!property_detail.length) { return null; }

    const { system_settings } = property_detail[0];
    const { compsetSettings } = system_settings[0];
    if (!compsetSettings) { return null; }
    return compsetSettings.resource_id;
}

export function getSeriesById(id) {
    return API_REGISTRY[id] || null;
}

export function getTileDataSource(id) {
    return TILES[id].data_sources || null
}

export function setUpDate(data_row, date) {
    const displayDate = moment(date).format("YYYY/MM/DD");
    const dow = moment(date).format("ddd");
    if (date) {
        data_row["date"] = displayDate;
        data_row["dow"] = dow;
    } else {
        data_row["date"] = "";
        data_row["dow"] = "";
    }
    return data_row;
}

export function getTileById(id) {
    return TILES[id] || null
}

export function getFromSession(key) {
    return sessionStorage.getItem(key);
}
export function addToSession(key, val) {
    sessionStorage.setItem(key, val)
}
export function getFromArr(arr, key, val) {
    let obj = null;
    arr.map((item, i) => {
        if (item[key] && item[key] === val) {
            obj = _.cloneDeep(item);
            obj.i = i;
        }
    })
    return obj;
}
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
//Arranging Tile in defined order
export function getTilesInOrder(tilesObj, group) {
    let orderedTiles = [];
    for (let i = 0; i < group.length; i++) {
        if (tilesObj[group[i]]) {
            orderedTiles.push(tilesObj[group[i]]);
        }
        // for (let key in tilesObj) {
        //     if (tilesObj[key].order == group[i]) {
        //         orderedTiles.push(tilesObj[key]);
        //     }
        // }
    }
    return orderedTiles;
}
export function deepCopy(obj) {
    //return JSON.parse(JSON.stringify(obj));
    return _.cloneDeep(obj);
}


// function is used to calculate the compset median 
export function getCompsetMedian(dataArr) {
    dataArr = dataArr.filter(val => val !== 0 && val !== null);
    dataArr.sort(function (a, b) { return a - b });
    let len = dataArr.length;
    let compMedianValue = 0;
    if (len % 2 !== 0) {
        let index = parseInt((dataArr.length) / 2);
        compMedianValue = dataArr[index];
    } else {
        let m1 = len / 2;
        let m2 = m1 - 1;
        compMedianValue = (dataArr[m1] + dataArr[m2]) / 2;
    }
    compMedianValue = (compMedianValue > 0) ? compMedianValue : 0;
    return Number(compMedianValue.toFixed(2));
}

export function round(num) {
    return Math.round(num * 100) / 100
}
export function isMobileDevice() {
    return false;
}

export function dateFormat(date, format) {
    return moment(date).format(format);
}
export function dateDiff(date1, date2, type) {
    let d1 = new moment(date1); // moment object of now
    let d2 = new moment(date2); // moment object of other date

    return d1.diff(d2, 'days'); // calculate the difference in days
}
export function monthDiff(date1, date2, type) {
    let d1 = new moment(date1); // moment object of now
    let d2 = new moment(date2); // moment object of other date

    return d1.add(1, 'day').diff(d2, 'months'); // calculate the difference in days
}
export function array_move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
};
export function convertToPercent(num, type = 'dec') {
    if (!num) {
        return 0;
    }
    if (type === 'int') {
        num = num / 100;
    } else if (type === 'dec') {
        num = num * 100;
    }


    return Number.parseFloat((Math.round((num * 1000) / 10) / 100).toFixed(2));
}
export function hasStandardPickup() {
    let hasStandard = false;
    // if(ctr.getSeriesLoadStatus("OSR") === "loaded"){
    //     let osrData = ctr.getSeriesById("OSR");
    //     let { property_id} = appStateModule.getCurrentState();

    //     if(osrData && osrData.data){
    //         osrData.data.map(item=>{
    //             if(!item.HotelTotal.current || !item.HotelTotal.prior){
    //                 hasStandard=false
    //             }
    //         });
    //     }

    //     if((300000 < property_id ) &&  (property_id < 399999)){
    //         hasStandard = false;
    //     }
    //     if(property_id === 1263){
    //         hasStandard =false;
    //     }

    // } 
    return hasStandard;
}
export const hasRestrictions = (value) => {
    // debugger; 
    let result = false;
    if (value && (value.cta || value.ctd || value.close || value.min_los > 1 || value.max_los > 1)) {
        result = true;
    }
    return result;
}
export function timeDiff(time_from, time_to, type) {

    return moment(time_to).diff(moment(time_from), type);

}
let loggedIn = false;
export function isLoggedIn() {
    if (getFromSession("token")) {
        loggedIn = true;
    }
    else {
        loggedIn = false;
    }
    return loggedIn;
}
function getTimeMilliSeconds() {
    return parseInt(moment().format("x"));
}
const inActivityInterval = 60 * 1000; //milliseconds how often iniactivity is checked
const maxUserInactivityLimit = 4 * 60 * 60; //seconds inactivity limit until modal is showed
let timeUserInactive = 0;
let ignoreInactivity = false;
let lastEventLog = getTimeMilliSeconds();
export function setLastEventLog() {
    if (isLoggedIn()) {
        lastEventLog = getTimeMilliSeconds();
        timeUserInactive = 0;
    }


}
let startedModal = false;
export function checkActivityIntervalTrigger(logoutApp) {
    timeUserInactive = 0;
    lastEventLog = getTimeMilliSeconds();
    //startedModal=false;
    return setInterval(function () {
        if (lastEventLog && isLoggedIn() && !ignoreInactivity) {
            const timeNow = getTimeMilliSeconds();
            timeUserInactive = timeDiff(lastEventLog, timeNow, 'seconds');
            if (timeUserInactive > maxUserInactivityLimit && !startedModal) {
                console.log(timeUserInactive + " seconds of inactivity");
                logoutApp();
                // showInactivityModal();
                // startedModal=true;
            }
        }
    }, 3000);
}

export function divide(num1, num2) {
    if (num1 == 0 || num2 === 0) {
        return 0;
    }
    return (num1 / num2);

}
export function sdly(date) {
    return moment(date).subtract(364, 'days').format("YYYY-MM-DD")
}
export function toSdly(date) {
    return moment(date).subtract(364, 'days').format("YYYY-MM-DD")
}
export function largeNumToDisplayString(num) {
    // if (number < 1000 && number > -1000) {
    //     return round(number,0);
    // }
    // else {
    //     return round(number/1000,0)+"K";
    // }
    let digits = 2;
    if (isNaN(num)) {
        return 0;
    }

    var si = [
        { value: 1E18, symbol: "E" },
        { value: 1E15, symbol: "P" },
        { value: 1E12, symbol: "T" },
        { value: 1E9, symbol: "B" },
        { value: 1E6, symbol: "M" },
        { value: 1E3, symbol: "K" }
    ],
        rx = /\.0+$|(\.[0-9]*[1-9])0+$/,
        i;

    if (num < 9999) { digits = 2 };
    if (num > 9999 && num < 99999) { digits = 1 };
    if (num > 99999 && num < 999999) { digits = 0 };
    if (num > 999999 && num < 9999999) { digits = 2 };
    if (num > 9999999 && num < 99999999) { digits = 1 };
    if (num > 99999999 && num < 999999999) { digits = 0 };
    if (num > 999999999 && num < 9999999999) { digits = 2 };
    for (i = 0; i < si.length; i++) {
        if (num >= si[i].value) {
            var beforeformattedNum = (num / si[i].value).toFixed(digits).replace(rx, "$1");

            if (beforeformattedNum.indexOf(".") >= 0) {
                var before_decimal = beforeformattedNum.split(".")[0];
                var after_decimal = beforeformattedNum.split(".")[1];
                var total_length = before_decimal.length + after_decimal.length;
                if (total_length != 3) {
                    beforeformattedNum = before_decimal + "." + after_decimal + "0";
                }
            } else if (beforeformattedNum.length == 2) {
                beforeformattedNum = beforeformattedNum + ".0"
            } else if (beforeformattedNum.length == 1) {
                beforeformattedNum = beforeformattedNum + ".00"
            }
            var finalformattedNum = beforeformattedNum + si[i].symbol
            return finalformattedNum;
        }
    }

    return num.toFixed(digits).replace(rx, "$1");
}

//get frequently used data from state
export function getFromState(key, state, propertyID) {
    // key can be like this "system_settings>market_segment_category_mapping"
    // debugger;
    let data = {};
    if (!key || !state) { return false; }
    let keys = key.split(">");
    const { app, auth } = state;
    const { app_state, ui_state } = app.current_state;

    let { property_id } = app_state;
    property_id = propertyID ? propertyID : property_id;
    const { user, profile } = auth;
    let settings = profile.property_details.filter((detail) => detail.id === property_id);
    let { system_settings } = settings.length ? settings[0] : {};
    system_settings = system_settings && system_settings.length ? system_settings[0] : null;
    switch (keys[0]) {
        case 'system_settings':
            data = system_settings ? system_settings[keys[1]] : null;
            break;
        case 'check_system_settings':
            data = system_settings ? property_id : null;
            break;
    }

    if ((ui_state.active_tiles[0] === "bi_pace_comparison" || ui_state.active_tiles[0] === "otb_v2_multi_segment" || ui_state.active_tiles[0] === "bi_sensitivity_analysis") && data && keys[1] === 'market_segment_category_mapping') {
        let marketSegments = data;
        Object.keys(marketSegments).map((key) => {
            if (marketSegments[key].includes(key)) {
                let fromIndex = marketSegments[key].indexOf(key);
                marketSegments[key].splice(fromIndex, 1)
                marketSegments[key].splice(0, 0, key)
            } else {
                marketSegments[key].splice(0, 0, key)
            }
        });
        return marketSegments;
    }
    if ((ui_state.active_tiles[0] !== "bi_pace_comparison" || ui_state.active_tiles[0] !== "otb_v2_multi_segment" || ui_state.active_tiles[0] !== "bi_sensitivity_analysis") && data && keys[1] === 'market_segment_category_mapping') {
        let marketSegments = deepCopy(data);
        Object.keys(marketSegments).map((key) => {
            if (!['IGNORED'].includes(key)) {
                if (marketSegments[key].includes(key)) {
                    let fromIndex = marketSegments[key].indexOf(key);
                    marketSegments[key].splice(fromIndex, 1)
                    marketSegments[key].splice(0, 0, key)
                } else {
                    marketSegments[key].splice(0, 0, key)
                }
            }
            else {
                delete marketSegments[key];
            }
        });
        return marketSegments;
    }
    return data;
}


export function getTransformedSegments(marketSegments, segment_ignore_codes) {
    if (marketSegments) {
        let segments = { list: [], map: {}, arrUnique: [] };
        let segmentKeys = Object.keys(marketSegments);
        segmentKeys.map((codes) => {
            let item = codes;
            let cleanCodes = deepCopy(marketSegments[codes]);
            marketSegments[codes].map((itm) => {
                if (segment_ignore_codes.includes(itm)) {
                    let index = cleanCodes.indexOf(itm);
                    cleanCodes.splice(index, 1);
                }
            })
            if (cleanCodes.length) {
                segments.arrUnique.push(cleanCodes[0]);
                cleanCodes.map((code) => {
                    segments.list.push({ code: code, name: item });
                    segments.map[code] = item;
                });
            }
        });
        return { ...segments, marketSegments };
    }
    return null;
}

export function getTransformedSegments_x(marketSegments, segment_ignore_codes) {
    if (marketSegments) {
        let segments = { list: [], map: {}, arrUnique: [] };
        let segmentKeys = Object.keys(marketSegments);
        segmentKeys.map((item) => {
            if (marketSegments[item].length && !segment_ignore_codes.include(marketSegments[item][0])) {
                segments.arrUnique.push(marketSegments[item][0]);
                marketSegments[item].map((code) => {
                    segments.list.push({ code: code, name: item });
                    segments.map[code] = item;
                });
            }
        });
        return segments;
    }
    return null;
}
export function filterTopSegments(lvl1_mseg_grouping) {

    let topSegments = {};
    lvl1_mseg_grouping.map((item) => {
        if (item.Transient) topSegments.Transient = item.Transient;
        if (item.Group) topSegments.Group = item.Group;
        if (item.Contract) topSegments.Contract = item.Contract;
    });
    return topSegments;
    // let segments = { list: [], map: {} };
    // let segmentKeys = Object.keys(marketSegments);
    // segmentsGrouping.map()
    // segmentKeys.map((item) => {
    //     if (marketSegments[item].length) {
    //         marketSegments[item].map((code) => {
    //             segments.list.push({ code: code, name: item });
    //             segments.map[code] = item;
    //         });
    //     }
    // });
    // return segments;
}
export function aggregateObject(ob1, ob2, keys, depth) {
    // debugger
    let aggregated = {};
    if (depth && depth === 2) {

        keys[0].map((k) => {
            aggregated[k] = aggregated[k] ? aggregated[k] : {};
            ob1[k] = ob1[k] ? ob1[k] : {};
            ob2[k] = ob2[k] ? ob2[k] : {};
            keys[1].map((k2) => {
                let ag = aggregated;
                // debugger;
                aggregated[k][k2] = aggregated[k][k2] ? aggregated[k][k2] : 0;
                aggregated[k][k2] = calc(ob1[k][k2], ob2[k][k2], 4).add();
            });
        });
        return aggregated;
    }
}
export function absouluteVal(num) {
    return Math.abs(num);
}
// const inputObj = {
//     data1: {
//         key1: {
//             innerKey1: 10,
//             innerKey2: 20
//         },
//         key2: {
//             innerKey1: 5,
//             innerKey2: 15
//         }
//     },
//     data2: {
//         key1: {
//             innerKey1: 8,
//             innerKey2: 12
//         },
//         key2: {
//             innerKey1: 3,
//             innerKey2: 7
//         }
//     }
// };

// input->inputObj,  keys: [['key1', 'key2'],['innerKey1', 'innerKey2']]
// Output:
// {
//     key1: {
//         innerKey1: 18,
//         innerKey2: 32
//     },
//     key2: {
//         innerKey1: 8,
//         innerKey2: 22
//     }
// }
export function aggregateInner(obj, keys = []) {

    let aggregated = {};
    keys[0].forEach(item => {
        aggregated[item] = {};
        keys[1].forEach(innerItem => {
            aggregated[item][innerItem] = 0;
        })
    })
    let measureCounts = {};
    keys[0].forEach(key1 => {
        keys[1].forEach(item => {
            measureCounts[key1 + "_" + item + "_count"] = 0;
        })
    })
    let capacity = 0;
    Object.keys(obj).map(obKey => {
        let dataItem = obj[obKey];

        keys[0].forEach(keyitem => {
            if (dataItem && dataItem[keyitem]) {
                keys[1].forEach(innerItem => {
                    // debugger;
                    if (!capacity && aggregated[keyitem].Revenue && aggregated[keyitem].RevPAR) {
                        capacity = aggregated[keyitem].Revenue / aggregated[keyitem].RevPAR;
                    }

                    if (!['ADR'].includes(innerItem)) {
                        aggregated[keyitem][innerItem] += dataItem[keyitem][innerItem];
                    }
                    // if(dataItem[keyitem][innerItem]!==0){
                    //     measureCounts[keyitem+"_"+innerItem+"_count"]+=1;
                    // }
                    // aggregated[keyitem][innerItem+"_count"]= measureCounts[keyitem+"_"+innerItem+"_count"];
                });
                // debugger;
                aggregated[keyitem].ADR = aggregated[keyitem].Revenue / aggregated[keyitem].Rooms;
                aggregated[keyitem].RevPAR = capacity ? aggregated[keyitem].Revenue / capacity : aggregated[keyitem].RevPAR;
            }
        })
    })

    return aggregated;
}


export function toInteger(val) {
    return _.toInteger(val)
}
export function isString(val) {
    return _.isString(val);
}
export function isNumber(val) {
    return _.isNumber(val);
}
export function dataTransformer(data, params) {
    // In this function we are generalising the data and 
    // transform it into more specific and meaningful format
    let transformedData = [];
    let metadata = {};
    let { keyMap, mainkey, segmented_view } = params;
    if (!data || !data.length) return [];
    let dataSample = {
        "Rooms": 0,
        "Occ": 0,
        "ADR": 0,
        "RevPAR": 0,
        "Revenue": 0,
        "SellRate": 0
    };
    let meta = {
        asof_date: "",
        capacity: 0
    };
    let dataWrapHotel = {
        current: deepCopy(dataSample),
        primary: deepCopy(dataSample),
        pickup: deepCopy(dataSample),
        prior: deepCopy(dataSample),
        sdly: deepCopy(dataSample),
        sdly_actual: deepCopy(dataSample),
        sdly_picup: deepCopy(dataSample),
    };
    let dataWrapHotelForecast = {
        primary: deepCopy(dataSample),
    };
    let dataWrapBookingCurve = {
        primary: deepCopy(dataSample),
    }
    let dataWrapMarket = {
        primary: deepCopy(dataSample),
        primary_noncomm: deepCopy(dataSample),
        pickup: deepCopy(dataSample),
        pickup_noncomm: deepCopy(dataSample),
        sdly: deepCopy(dataSample),
        sdly_noncomm: deepCopy(dataSample),
        sdly_actual: deepCopy(dataSample),
        sdly_actual_noncomm: deepCopy(dataSample),
    };

    let outputSample = {
        HotelTotal: deepCopy(dataWrapHotel),

        HotelForecastTotal: deepCopy(dataWrapHotelForecast),
        HotelForecastUnconTotal: deepCopy(dataWrapHotelForecast),

        MarketTotal: deepCopy(dataWrapMarket),
        MarketForecastTotal: deepCopy(dataWrapMarket),
        MarketForecastConTotal: deepCopy(dataWrapMarket),
        MarketForecastUnconTotal: deepCopy(dataWrapMarket),

        bookingCurveTotal: deepCopy(dataWrapBookingCurve),
        bookingCurveSdlyTotal: deepCopy(dataWrapBookingCurve),
        bookingCurveComparisonTotal: deepCopy(dataWrapBookingCurve),

        segmented: {
            hotel: {},
            hotelForecast: {},
            hotelForecastUncon: {},

            market: {},
            marketForecast: {},
            marketForecastUncon: {},

            bookingCurve: {},
            bookingCurveSdly: {},
            bookingCurveCompareTo: {},
        },
        index: { date: "", dba: "" }
    };
    if (!keyMap) {
        keyMap = {
            // "asof_date": "meta.asof_date",
            // "capacity": "meta.capacity",
            // "dba": "index.dba",
            // "stay_date": "index.date",
            // "hotel_segment": "Segment_NAME",
            // "market_code": "Segment_CODE",

            hotelForecast: {
                "asof_date": "meta.asof_date",
                "capacity": "meta.capacity",
                "dba": "index.dba",
                "stay_date": "index.date",
                "hotel_segment": "Segment_NAME",
                "market_code": "Segment_CODE",

                "predicted_cons_adr": "segmented.hotelForecast.CODE.ADR",
                "predicted_cons_occ": "segmented.hotelForecast.CODE.Occ",
                "predicted_cons_revenue": "segmented.hotelForecast.CODE.Revenue",
                "predicted_cons_revpar": "segmented.hotelForecast.CODE.RevPAR",
                "predicted_cons_rooms": "segmented.hotelForecast.CODE.Rooms",

                "predicted_total_cons_adr": "HotelForecastTotal.ADR",
                "predicted_total_cons_occ": "HotelForecastTotal.Occ",
                "predicted_total_cons_revenue": "HotelForecastTotal.Revenue",
                "predicted_total_cons_revpar": "HotelForecastTotal.RevPAR",
                "predicted_total_cons_rooms": "HotelForecastTotal.Rooms",

                "predicted_total_uncons_adr": "HotelForecastUnconTotal.ADR",
                "predicted_total_uncons_occ": "HotelForecastUnconTotal.Occ",
                "predicted_total_uncons_revenue": "HotelForecastUnconTotal.Revenue",
                "predicted_total_uncons_revpar": "HotelForecastUnconTotal.RevPAR",
                "predicted_total_uncons_rooms": "HotelForecastUnconTotal.Rooms",
            },
            marketForecast: {
                //For market Forecast
                "constrained_forecast_adr": "MarketForecastConTotal.ADR",
                "constrained_forecast_occ": "MarketForecastConTotal.Occ",
                "constrained_forecast_revenue": "MarketForecastConTotal.Revenue",
                "constrained_forecast_revpar": "MarketForecastConTotal.RevPAR",
                "constrained_forecast_rooms": "MarketForecastConTotal.Rooms",

                "forecast_adr": "MarketForecastTotal.ADR",
                "forecast_occ": "MarketForecastTotal.Occ",
                "forecast_revenue": "MarketForecastTotal.Revenue",
                "forecast_revpar": "MarketForecastTotal.RevPAR",
                "forecast_rooms": "MarketForecastTotal.Rooms",

                "unconstrained_forecast_adr": "MarketForecastUnconTotal.ADR",
                "unconstrained_forecast_occ": "MarketForecastUnconTotal.Occ",
                "unconstrained_forecast_revenue": "MarketForecastUnconTotal.Revenue",
                "unconstrained_forecast_revpar": "MarketForecastUnconTotal.RevPAR",
                "unconstrained_forecast_rooms": "MarketForecastUnconTotal.Rooms",
            },
            marketForecastSegmented: {
                //For segmented market Forecast
                "market_segment": "segmented.marketForecast.CODE",
                "constrained_forecast_adr": "segmented.marketForecast.CODE.ADR",
                "constrained_forecast_occ": "segmented.marketForecast.CODE.Occ",
                "constrained_forecast_revenue": "segmented.marketForecast.CODE.Revenue",
                "constrained_forecast_revpar": "segmented.marketForecast.CODE.RevPAR",
                "constrained_forecast_rooms": "segmented.marketForecast.CODE.Rooms",

                "unconstrained_forecast_adr": "segmented.marketForecastUncon.CODE.ADR",
                "unconstrained_forecast_occ": "segmented.marketForecastUncon.CODE.Occ",
                "unconstrained_forecast_revenue": "segmented.marketForecastUncon.CODE.Revenue",
                "unconstrained_forecast_revpar": "segmented.marketForecastUncon.CODE.RevPAR",
                "unconstrained_forecast_rooms": "segmented.marketForecastUncon.CODE.Rooms",
            },


        };
    }
    let keyMapSelector = "hotelForecast";
    data.map((item, i) => {
        let output = deepCopy(outputSample);
        let itemData = item;
        if (mainkey && item[mainkey]) {
            itemData = item[mainkey];
        }
        for (let key in itemData) {
            if (keyMap[keyMapSelector][key]) {
                let keys = keyMap[keyMapSelector][key].split(".");
                if (keys.length > 1) {
                    if (keys[0] === 'meta') {
                        metadata[keys[1]] = itemData[key];
                    } else if (keys[0] === 'index') {
                        output.index[keys[1]] = itemData[key][keys[1]];
                    } else if (keys[0] === 'segmented') {
                        output.segmented[keyMapSelector][key] = itemData[key][keys[1]];
                    } else {

                    }

                } else {

                }
            }
        }

    });
    //if(!inputSample || !outputSample) return data;

}

export function combineArr(arr1, arr2, duplicates = false) {
    let combinedArr = [];
    if (duplicates === false) {
        combinedArr = [...new Set([...arr1, ...arr2])]
    } else {
        combinedArr = arr1.concat(arr2);
    }

    return combinedArr;
}

export function getNumDaysFromDateRange({ date_from, date_until }) {
    return moment(date_until).diff(date_from, 'days') + 1;
}
export function safeDivide(num1, num2) {
    if (num1 == 0 || num2 == 0) {
        return 0;
    }
    return num1 / num2;
}
export function safeReference(ref, defaultValIfEmpty, path) {
    var currentRef = ref;
    if (!currentRef) {
        return defaultValIfEmpty;
    }
    if (!path || path.length < 1) {
        return currentRef;
    }
    for (var i = 0; i < path.length; i++) {
        currentRef = currentRef[path[i]];
        if (!currentRef) {
            return defaultValIfEmpty;
        }
    }
    return currentRef;
}

// get Compset Name 
export const getCompsetName = (ord = 4, app_state) => {

    let compsetData = (app_state.workspace_controls.scopeType !== 'Dba') ? dataService.getSeriesById("Compset").data : dataService.getSeriesById("CompsetBookingPace").data;
    let data = (compsetData) ? compsetData.data : [];
    const subColumns = [];
    const firstData = (data && data[0]) ? data[0] : {};
    let order = ord
    Object.keys(firstData).map((key) => {
        // console.log("here is => ", key);
        if (key !== 'Avg' && key !== 'Total' && key !== 'index' && key !== 'Index' && key !== 'Median' && key !== 'Min' && key !== 'Max') {
            let key_ = key;
            let name = key.split("|");
            subColumns.push({
                display: name[0].replaceAll("compset_", ""),
                subcol_id: "compset_medium_" + key,
                type: "currency",
                value: key,
                "parent": "compset_medium",
                "className": "col-sub",
                "draggable": true,
                "sortable": true,
                "filterable": true,
                "filterMenu": true,
                "sorted": null,
                "hidden": false,
                "order": order
            })
            order++;
        }

    })
    return subColumns;
}
// end 

/** function to get the market segment name */

export const getMarketSegment = (active_tiles = null) => {
    let marketSegmentData = [];
    if (active_tiles && active_tiles.length > 1 && active_tiles.includes('market')) {
        marketSegmentData = (dataService.getSeriesById("marketOtbSegment").data) ? deepCopy(dataService.getSeriesById("marketOtbSegment").data.data) : deepCopy(dataService.getSeriesById("MarketARISegmented").data?.data);
    } else {
        marketSegmentData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];
    }
    let marketSegments = [];
    if (marketSegmentData && marketSegmentData.length > 0) {
        let keysArr = Object.keys(marketSegmentData[0]).sort();
        let ingonreKeys = ['MarketTotal', 'index'];
        keysArr.forEach(key => {
            let obj = {};
            if (!ingonreKeys.includes(key)) {
                let segmentName = key.split('_')[1];
                if (!segmentName) segmentName = "Other";
                obj.code = segmentName;
                obj.name = segmentName;
                obj.value = segmentName;
                obj.title = segmentName;
                obj.type = "market_segments";
                obj.hidden = false;
                let exists = marketSegments.find(item => item.code === segmentName);
                if (!exists) {
                    marketSegments.push(obj);
                }
            }
        })
    }
    if (active_tiles && active_tiles.length === 1 && active_tiles.includes('market')) {
        marketSegments = marketSegments.filter((item) => item.code !== 'UNMAPPED');
    }
    // debugger;
    return marketSegments;
}

//// get total 
export const getTotalData_all = (dataSet, app_state, ui_state, type = 'Total') => {
    // debugger;
    // if(type === 'segmentTotal') debugger;
    var date1 = moment(app_state.primary_stay_range.date_from);
    var date2 = moment(app_state.primary_stay_range.date_until);
    var daysCount = (type === 'segment') ? 1 : dataSet.length; //date2.diff(date1, 'days') + 1;
    const dataArray = [];
    if (dataSet.length > 0) {
        // let keysArray = Object.keys(dataSet[0][1]);
        let dataObj = {}
        for (let j = 1; j < dataSet[0].length; j++) {
            let roomsTotal = 0;
            let occ = 0;
            let revPar = 0;
            let revenue = 0;
            let sellRate = 0;
            let total_sellRate;
            var total_sellRate_count = 0;
            let lenforSegment = 1;

            /// for variance 
            let varRoomsTotal = 0;
            let varRoomsSdlyTotal = 0;
            let varOccTotal = 0;
            let varOccSdlyTotal = 0;
            let varRevParTotal = 0;
            let varRevParSdlyTotal = 0;
            let varRevenueTotal = 0;
            let varRevenueSdlyTotal = 0;
            let varAdrTotal = 0;
            let varAdrSdlyTotal = 0;

            for (let i = 0; i < dataSet.length; i++) {
                let items = dataSet[i];
                if (app_state.workspace_controls.segmented_view) {
                    if (type === 'Total' && items[0] && items[0].segment === "Total" && items[0].date !== '') {
                        if (items[j] && Object.keys(items[j]).length) {
                            let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                            if (items[j].SellRate !== '') {
                                total_sellRate = items[j].SellRate;
                                total_sellRate_count++;
                            } else {
                                total_sellRate = 0;
                                total_sellRate_count++;
                            }
                            let adrTotal;
                            let primaryValtotal
                            if (typeof items[j].Rooms === "string") {
                                // total for variance /////
                                varRoomsTotal += parseFloat(items[1].Rooms);
                                varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                varRevParTotal += parseFloat(items[1].RevPAR);
                                varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                let revParTmp = (varRevParTotal / total_sellRate_count);
                                let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                varOccTotal += (items[1].Occ);
                                varOccSdlyTotal += (items[2].Occ);
                                let occTmp = (varOccTotal / total_sellRate_count);
                                let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                varRevenueTotal += (items[1].Revenue);
                                varRevenueSdlyTotal += (items[2].Revenue);
                                let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                            } else {
                                roomsTotal += (items[j].Rooms);
                                occ += parseFloat(items[j].Occ);
                                revPar += parseFloat(items[j].RevPAR);
                                revenue += parseFloat(items[j].Revenue);
                                sellRate += parseFloat(total_sellRate);
                                adrTotal = (revenue / roomsTotal);
                                adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                            }
                            dataObj = {
                                "Rooms": roomsTotal,
                                "Occ": occ,
                                "RevPAR": revPar,
                                "Revenue": revenue,
                                "SellRate": sellRate,
                                "ADR": adrTotal //(revenue / roomsTotal)
                            }
                            if ('SellRate' in items[j] === false) {
                                delete dataObj["SellRate"];
                            }
                            if (Object.keys(items[j]).includes("Restrictions")) {
                                dataObj = { "Restrictions": "" };
                            }
                        }
                        lenforSegment++;
                    }
                    if (type === 'segment' && items[0] && items[0].segment !== "Total" && items[0].date !== '') {
                        if (Object.keys(items[j]).length) {
                            let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                            if (items[j].SellRate !== '') {
                                total_sellRate = items[j].SellRate;
                                total_sellRate_count++;
                            } else {
                                total_sellRate = 0;
                                total_sellRate_count++;
                            }
                            let adrTotal;
                            let primaryValtotal
                            if (typeof items[j].Rooms === "string") {
                                // total for variance /////
                                varRoomsTotal += parseFloat(items[1].Rooms);
                                varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                varRevParTotal += parseFloat(items[1].RevPAR);
                                varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                let revParTmp = (varRevParTotal / total_sellRate_count);
                                let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                varOccTotal += (items[1].Occ);
                                varOccSdlyTotal += (items[2].Occ);
                                let occTmp = (varOccTotal / total_sellRate_count);
                                let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                varRevenueTotal += (items[1].Revenue);
                                varRevenueSdlyTotal += (items[2].Revenue);
                                let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                            } else {
                                roomsTotal += (items[j].Rooms);
                                occ += parseFloat(items[j].Occ);
                                revPar += parseFloat(items[j].RevPAR);
                                revenue += parseFloat(items[j].Revenue);
                                sellRate += parseFloat(total_sellRate);
                                adrTotal = (revenue / roomsTotal);
                                adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                            }
                            dataObj = {
                                "Rooms": roomsTotal,
                                "Occ": occ,
                                "RevPAR": revPar,
                                "Revenue": revenue,
                                "SellRate": sellRate,
                                "ADR": adrTotal //(revenue / roomsTotal)
                            }
                            if ('SellRate' in items[j] === false) {
                                delete dataObj["SellRate"];
                            }
                            if (Object.keys(items[j]).includes("Restrictions")) {
                                dataObj = { "Restrictions": "" };
                            }
                        }
                        lenforSegment++;
                    }
                    if (type === 'segmentTotal' && items[0] && items[0].segment !== "Total") {
                        if (Object.keys(items[j]).length) {
                            let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                            if (items[j].SellRate !== '') {
                                total_sellRate = items[j].SellRate;
                                total_sellRate_count++;
                            } else {
                                total_sellRate = 0;
                                total_sellRate_count++;
                            }
                            let adrTotal;
                            let primaryValtotal
                            if (typeof items[j].Rooms === "string") {
                                // total for variance /////
                                varRoomsTotal += parseFloat(items[1].Rooms);
                                varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                varRevParTotal += parseFloat(items[1].RevPAR);
                                varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                let revParTmp = (varRevParTotal / total_sellRate_count);
                                let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                varOccTotal += (items[1].Occ);
                                varOccSdlyTotal += (items[2].Occ);
                                let occTmp = (varOccTotal / total_sellRate_count);
                                let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                varRevenueTotal += (items[1].Revenue);
                                varRevenueSdlyTotal += (items[2].Revenue);
                                let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                            } else {
                                roomsTotal += (items[j].Rooms);
                                occ += parseFloat(items[j].Occ);
                                revPar += parseFloat(items[j].RevPAR);
                                revenue += parseFloat(items[j].Revenue);
                                sellRate += parseFloat(total_sellRate);
                                adrTotal = (revenue / roomsTotal);
                                adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                            }
                            dataObj = {
                                "Rooms": roomsTotal,
                                "Occ": occ / dataSet.length,
                                "RevPAR": revPar,
                                "Revenue": revenue,
                                "SellRate": sellRate,
                                "ADR": adrTotal //(revenue / roomsTotal)
                            }
                            if ('SellRate' in items[j] === false) {
                                delete dataObj["SellRate"];
                            }
                            if (Object.keys(items[j]).includes("Restrictions")) {
                                dataObj = { "Restrictions": "" };
                            }
                        }
                        lenforSegment++;
                    }
                } else {
                    if (type === 'Total' && items[0] && items[0].segment === "Total" && items[0].date !== '') {
                        if (items[j] && Object.keys(items[j]).length) {
                            let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                            if (items[j].SellRate !== '') {
                                total_sellRate = items[j].SellRate;
                                total_sellRate_count++;
                            } else {
                                total_sellRate = 0;
                                total_sellRate_count++;
                            }
                            let adrTotal;
                            let primaryValtotal
                            if (typeof items[j].Rooms === "string") {
                                // total for variance /////
                                varRoomsTotal += parseFloat(items[1].Rooms);
                                varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                varRevParTotal += parseFloat(items[1].RevPAR);
                                varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                let revParTmp = (varRevParTotal / total_sellRate_count);
                                let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                varOccTotal += (items[1].Occ);
                                varOccSdlyTotal += (items[2].Occ);
                                let occTmp = (varOccTotal / total_sellRate_count);
                                let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                varRevenueTotal += (items[1].Revenue);
                                varRevenueSdlyTotal += (items[2].Revenue);
                                let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                            } else {
                                roomsTotal += (items[j].Rooms);
                                occ += parseFloat(items[j].Occ);
                                revPar += parseFloat(items[j].RevPAR);
                                revenue += parseFloat(items[j].Revenue);
                                sellRate += parseFloat(total_sellRate);
                                adrTotal = (revenue / roomsTotal);
                                adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                            }
                            dataObj = {
                                "Rooms": roomsTotal,
                                "Occ": occ,
                                "RevPAR": revPar,
                                "Revenue": revenue,
                                "SellRate": sellRate,
                                "ADR": adrTotal //(revenue / roomsTotal)
                            }
                            if ('SellRate' in items[j] === false) {
                                delete dataObj["SellRate"];
                            }
                            if (Object.keys(items[j]).includes("Restrictions")) {
                                dataObj = { "Restrictions": "" };
                            }
                        }
                        lenforSegment++;
                    }
                    if (type === 'segment' && items[0] && items[0].segment !== "Total" && items[0].date !== '') {
                        if (Object.keys(items[j]).length) {
                            let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                            if (items[j].SellRate !== '') {
                                total_sellRate = items[j].SellRate;
                                total_sellRate_count++;
                            } else {
                                total_sellRate = 0;
                                total_sellRate_count++;
                            }
                            let adrTotal;
                            let primaryValtotal
                            if (typeof items[j].Rooms === "string") {
                                // total for variance /////
                                varRoomsTotal += parseFloat(items[1].Rooms);
                                varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                varRevParTotal += parseFloat(items[1].RevPAR);
                                varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                let revParTmp = (varRevParTotal / total_sellRate_count);
                                let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                varOccTotal += (items[1].Occ);
                                varOccSdlyTotal += (items[2].Occ);
                                let occTmp = (varOccTotal / total_sellRate_count);
                                let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                varRevenueTotal += (items[1].Revenue);
                                varRevenueSdlyTotal += (items[2].Revenue);
                                let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                            } else {
                                roomsTotal += (items[j].Rooms);
                                occ += parseFloat(items[j].Occ);
                                revPar += parseFloat(items[j].RevPAR);
                                revenue += parseFloat(items[j].Revenue);
                                sellRate += parseFloat(total_sellRate);
                                adrTotal = (revenue / roomsTotal);
                                adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                            }
                            dataObj = {
                                "Rooms": roomsTotal,
                                "Occ": occ,
                                "RevPAR": revPar,
                                "Revenue": revenue,
                                "SellRate": sellRate,
                                "ADR": adrTotal //(revenue / roomsTotal)
                            }
                            if ('SellRate' in items[j] === false) {
                                delete dataObj["SellRate"];
                            }
                            if (Object.keys(items[j]).includes("Restrictions")) {
                                dataObj = { "Restrictions": "" };
                            }
                        }
                        lenforSegment++;
                    }
                    if (type === 'segmentTotal' && items[0] && items[0].segment !== "Total") {
                        if (Object.keys(items[j]).length) {
                            let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                            if (items[j].SellRate !== '') {
                                total_sellRate = items[j].SellRate;
                                total_sellRate_count++;
                            } else {
                                total_sellRate = 0;
                                total_sellRate_count++;
                            }
                            let adrTotal;
                            let primaryValtotal
                            if (typeof items[j].Rooms === "string") {
                                // total for variance /////
                                varRoomsTotal += parseFloat(items[1].Rooms);
                                varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                varRevParTotal += parseFloat(items[1].RevPAR);
                                varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                let revParTmp = (varRevParTotal / total_sellRate_count);
                                let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                varOccTotal += (items[1].Occ);
                                varOccSdlyTotal += (items[2].Occ);
                                let occTmp = (varOccTotal / total_sellRate_count);
                                let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                varRevenueTotal += (items[1].Revenue);
                                varRevenueSdlyTotal += (items[2].Revenue);
                                let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                            } else {
                                roomsTotal += (items[j].Rooms);
                                occ += parseFloat(items[j].Occ);
                                revPar += parseFloat(items[j].RevPAR);
                                revenue += parseFloat(items[j].Revenue);
                                sellRate += parseFloat(total_sellRate);
                                adrTotal = (revenue / roomsTotal);
                                adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                            }
                            dataObj = {
                                "Rooms": roomsTotal,
                                "Occ": occ / dataSet.length,
                                "RevPAR": revPar,
                                "Revenue": revenue,
                                "SellRate": sellRate,
                                "ADR": adrTotal //(revenue / roomsTotal)
                            }
                            if ('SellRate' in items[j] === false) {
                                delete dataObj["SellRate"];
                            }
                            if (Object.keys(items[j]).includes("Restrictions")) {
                                dataObj = { "Restrictions": "" };
                            }
                        }
                        lenforSegment++;
                    }

                    if (items[0] && items[0].segment !== "Total" && items[0].date !== '' && items[0].dba !== '') {
                        if (Object.keys(items[j]).length) {
                            let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                            if (items[j].SellRate !== '') {
                                total_sellRate = items[j].SellRate;
                                total_sellRate_count++;
                            } else {
                                total_sellRate = 0;
                                total_sellRate_count++;
                            }
                            let adrTotal;
                            let primaryValtotal
                            if (typeof items[j].Rooms === "string") {
                                // total for variance /////
                                varRoomsTotal += parseFloat(items[1].Rooms);
                                varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                varRevParTotal += parseFloat(items[1].RevPAR);
                                varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                let revParTmp = (varRevParTotal / total_sellRate_count);
                                let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                varOccTotal += (items[1].Occ);
                                varOccSdlyTotal += (items[2].Occ);
                                let occTmp = (varOccTotal / total_sellRate_count);
                                let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                varRevenueTotal += (items[1].Revenue);
                                varRevenueSdlyTotal += (items[2].Revenue);
                                let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                            } else {
                                roomsTotal += (items[j].Rooms);
                                occ += parseFloat(items[j].Occ);
                                revPar += parseFloat(items[j].RevPAR);
                                revenue += parseFloat(items[j].Revenue);
                                sellRate += parseFloat(total_sellRate);
                                adrTotal = (revenue / roomsTotal);
                                adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                            }
                            dataObj = {
                                "Rooms": roomsTotal,
                                "Occ": occ,
                                "RevPAR": revPar,
                                "Revenue": revenue,
                                "SellRate": sellRate,
                                "ADR": adrTotal //(revenue / roomsTotal)
                            }
                            if ('SellRate' in items[j] === false) {
                                delete dataObj["SellRate"];
                            }
                            if (Object.keys(items[j]).includes("Restrictions")) {
                                dataObj = { "Restrictions": "" };
                            }
                        }
                        lenforSegment++;
                    }
                }
            }
            // let sellRateValue = (ui_state.active_tiles[0].toLowerCase() === 'market') ? 0 : '';
            let sRate = round(dataObj.SellRate / total_sellRate_count);
            if (sRate <= 0 && ui_state.active_tiles[0].toLowerCase() === 'market' && app_state.workspace_controls.scopeType === 'Dba') {
                // sRate = "";
            }
            let adrTotal = dataObj.ADR;
            if (ui_state.active_tiles[0] === "market_summary" || ui_state.active_tiles[0] === "hotel_summary") {
                daysCount = dataSet.length;
                sRate = round(dataObj.SellRate / daysCount);
            }


            let occTotal = type === 'Total' ? dataObj.Occ / daysCount : dataObj.Occ;
            if (typeof dataObj.Occ !== "string" && typeof dataObj.RevPAR !== "string") {
                var newValues = {
                    Occ: occTotal,
                    RevPAR: round(dataObj.RevPAR / daysCount),
                    SellRate: sRate,
                    Revenue: round(dataObj.Revenue),
                    ADR: round(dataObj.ADR)
                }
                dataObj = { ...dataObj, ...newValues };
            }
            dataArray.push(dataObj);
        }
    }
    if (ui_state.active_tiles[0] === "pace" && dataArray[1]) {
        let paceAdrDiff = dataArray[0]["ADR"] - dataArray[1]["ADR"];
        let paceRevparDiff = dataArray[0]["RevPAR"] - dataArray[1]["RevPAR"];

        dataArray.forEach((dataItem, i) => {
            if (i === 2) {
                dataArray[i].ADR = paceAdrDiff;
                dataArray[i].RevPAR = paceRevparDiff;
            }
        })
    }
    return dataArray;
}

export function isRestrictionSame(entry, rec) {
    let same = true;
    if (entry.min_los === "") {
        delete entry.min_los;
    }
    if (entry.max_los === "") {
        delete entry.max_los;
    }
    if (entry.cta !== rec.cta || entry.ctd !== rec.ctd) {
        same = false;
    }
    if (entry.min_los !== rec.min_los || entry.max_los !== rec.max_los) {
        same = false;
    }
    if (entry.close === true) {
        same = false;
    }
    return same;
}

export function preferredOrder(obj, order) {
    var newObject = {};
    for (var i = 0; i < order.length; i++) {
        if (obj.hasOwnProperty(order[i])) {
            newObject[order[i]] = obj[order[i]];
        }
    }
    return newObject;
}

export function showSegmentType(app_state, ui_state, segment_text) {
    if (ui_state.active_tiles[0] === 'otb_v2_multi_segment' && (segment_text === 'Room Type' || segment_text === 'Segment')) {
        let segmentType = app_state.workspace_controls.segmentType;
        if (segmentType === 'top_market_segment') {
            segment_text = "Top Segment";
        } else if (segmentType === 'rate_plan') {
            segment_text = "Rate Plan";
        } else if (segmentType === 'room_class') {
            segment_text = "Room Class";
        } else if (segmentType === 'room_type') {
            segment_text = "Room Type";
        } else {
            segment_text = "Segment";
        }
    }
    return segment_text;
}

export function getSdlyDate_old(activeDate, app_state) {
    let sdYear = app_state.workspace_controls.sdYear;
    let rangeYear = moment(activeDate).format('YYYY');
    let yearDiff = rangeYear - sdYear;
    let isoWeekday = moment(activeDate).weekday();
    let lastYear = moment(activeDate).startOf('day').subtract(yearDiff, 'year');
    let fromDateLastYear = lastYear.weekday(isoWeekday).format("YYYY-MM-DD");
    return fromDateLastYear;
}

export function getSdlyDate(activeDate, app_state, tile = null, dayDate = null, ratePlanMeta = null, stay_date) {
    // debugger;
    if (dayDate === 'date' && ratePlanMeta && stay_date !== '') {
        return ratePlanMeta?.sdtly_from;
    } else {
        return ratePlanMeta?.sdly_from;
    }
    let sdYear = app_state.workspace_controls.sdYear;
    let rangeYear = moment(activeDate).format('YYYY');
    let yearDiff = rangeYear - sdYear;


    let isoWeekday = moment(activeDate).weekday();
    let lastYear = moment(activeDate).startOf('day').subtract(yearDiff, 'year');
    let fromDateLastYear = lastYear.weekday(isoWeekday).format("YYYY-MM-DD");
    if (moment(fromDateLastYear).format('YYYY') < moment(activeDate).subtract(yearDiff, 'year').format('YYYY')) {
        isoWeekday = moment(activeDate).isoWeekday();
        lastYear = moment(activeDate).startOf('day').subtract(yearDiff, 'year');
        fromDateLastYear = lastYear.isoWeekday(isoWeekday).add(7, 'days').format("YYYY-MM-DD");
    }

    if (tile === 'pace') {
        let data = (dataService.getSeriesById("OSRBookingPaceSegment").data) ? dataService.getSeriesById("OSRBookingPaceSegment").data.data : [];
        let dataSet = data.filter((item) => moment(item.index.date).isSame(moment(activeDate)))
        fromDateLastYear = (dataSet[0] && dataSet[0].HotelTotal.comparison.date) ? dataSet[0].HotelTotal.comparison.date : '';
    }

    return fromDateLastYear;
}


function getDatesOfFirstWeek(year) {
    const firstDayOfYear = moment(`${year}-01-01`);
    const firstSunday = firstDayOfYear.clone().startOf('isoWeek');

    const firstWeekDates = [];
    for (let i = 0; i < 7; i++) {
        const currentDate = firstSunday.clone().add(i, 'days');
        firstWeekDates.push(currentDate.format('YYYY-MM-DD'));
    }
    return firstWeekDates;
}


export function sendMultipleAPIs(apiUrls, request) {
    const promises = apiUrls.map(url => fetchDataSimple({ api: url.api, request: { method: 'GET' } }));
    return Promise.all(promises);
}

export const getAdrValue = (revenue, rooms, calType = 'f') => {
    let result = 0;
    if (calType === 'f') {
        revenue = (revenue) ? revenue : 0;
        rooms = (rooms) ? rooms : 0;
        if (revenue && rooms) {
            result = revenue / rooms;
        }
    }
    return result;
}


export const getMarketFutureSegmentedAgg = (data) => {
    let totalObj = {
        "asof_date": "",
        "constrained_forecast_adr": 0,
        "constrained_forecast_occ": 0,
        "constrained_forecast_revenue": 0,
        "constrained_forecast_revpar": 0,
        "constrained_forecast_rooms": 0,
        "forecast_adr": 0,
        "forecast_occ": 0,
        "forecast_revenue": 0,
        "forecast_revpar": 0,
        "forecast_rooms": 0,
        "otb_occ": 0,
        "otb_revpar": 0,
        "sdly_actual_occ": 0,
        "sdly_actual_revpar": 0,
        "sdly_date": "",
        "sdly_otb_occ": 0,
        "sdly_otb_revpar": 0,
        "stay_date": "",
        "unconstrained_forecast_adr": 0,
        "unconstrained_forecast_occ": 0,
        "unconstrained_forecast_revenue": 0,
        "unconstrained_forecast_revpar": 0,
        "unconstrained_forecast_rooms": 0
    };
    let keys = Object.keys(totalObj);
    let stayDates = [];
    let result = [];
    data.forEach((element, index) => {
        let stay_date = element.stay_date;
        if (!stayDates.includes(stay_date)) {
            let dateData = data.filter((item) => item.stay_date === stay_date);
            let aggObj = deepCopy(totalObj);
            stayDates.push(stay_date);
            dateData.forEach((itm, idx) => {
                keys.forEach((key) => {
                    if (["asof_date", "stay_date", "sdly_date"].includes(key)) {
                        aggObj[key] = itm[key];
                    } else {
                        aggObj[key] += itm[key];

                    }
                })
            });

            keys.forEach((key) => {
                if (key.indexOf("_adr") > -1) {
                    let rev = key.replaceAll("_adr", "_revenue");
                    let rooms = key.replaceAll("_adr", "_rooms");
                    aggObj[key] = aggObj[rev] / aggObj[rooms];
                }
            })

            result.push(aggObj);
        }
    });
    return result;
}

export const getSdyearsAndSdlyOn = (app_state, ui_state, asOfDateYear) => {
    let { segmentType, rp_years, rt_years, propertyYears } = app_state.workspace_controls;
    let sdYears = propertyYears;
    let oldAsOfDateYear = Number(moment(app_state.as_of_date, "YYYY-MM-DD").format("YYYY"));
    const { active_tiles } = ui_state;

    let sdlyOn = app_state.workspace_controls.sdlyOn;
    let sdYearsArr = sdYears;
    let sdYear = Number(app_state.workspace_controls.sdYear);

    if (active_tiles && active_tiles.length > 1 && in_array(active_tiles, ['market', 'market_summary']) && app_state.selected_market) {
        const currentDate = moment();
        const lastYear = currentDate.subtract(1, 'years').year();
        const selected_market = app_state.selected_market;
        let allYears = (selected_market && selected_market.year) ? sdYears.filter(item => selected_market.year.includes(item)) : lastYear;
        sdYearsArr = [null].concat(allYears)
    } else if (active_tiles && active_tiles.length == 1 && in_array(active_tiles, ['market', 'market_summary']) && app_state.selected_market) {
        const currentDate = moment();
        const lastYear = currentDate.subtract(1, 'years').year();
        const selected_market = app_state.selected_market;
        let allYears = (selected_market && selected_market.year) ? selected_market.year : lastYear;
        sdYearsArr = [null].concat(allYears)
    } else {
        if (ui_state.active_tiles[0] === "otb_v2_multi_segment" && segmentType === "rate_plan") {
            sdYearsArr = (rp_years.length > 1) ? rp_years : rp_years.splice(0, 0, null);
        } else if (ui_state.active_tiles[0] === "otb_v2_multi_segment" && segmentType === "room_type") {
            sdYearsArr = (rt_years.length > 1) ? rt_years : rt_years.splice(0, 0, null);
        }
        sdYearsArr = [null].concat(sdYearsArr)
    }
    if (asOfDateYear !== oldAsOfDateYear) {
        if (!sdYearsArr.includes(sdYear)) {
            sdlyOn = false;
        } else {
            sdYear = asOfDateYear - 1;
            if (sdYearsArr.includes(sdYear) && sdlyOn) {
                sdlyOn = true;
            } else {
                sdlyOn = false;
            }
        }
    } else {
        if (!sdYearsArr.includes(sdYear)) {
            sdYear = (sdYearsArr[1]) ? sdYearsArr[1] : moment().subtract(1, 'years').year();
            sdlyOn = false;
        }
    }

    return { sdYearsArr, sdYear, sdlyOn }
}

function getColumns(tiles) {
    let columns = [];

}

export function isValidNumber(s) {
    return /^[-+]?[0-9]+(\.[0-9]+)?$/.test(s);
}

export function downloadcsvwithTable(table, name, columns) {
    // Select rows from table_id
    let separator = ",";
    const rows = table.rows;
    let firstRows = rows[0].querySelectorAll("td, th");
    let subcolsArr = [];
    columns = columns.filter(col => col.id !== 'forecast' && col.id !== 'otb');
    columns.map((col, index) => {
        let displayName = firstRows[index].innerText;
        col.subcolumns.forEach(sub => {
            subcolsArr.push({ displayName, subColName: sub.display, type: col.type })
        })
    })
    // Construct csv
    let csv = [];
    //looping through the table
    for (let i = 0; i < rows.length; i++) {
        if (i === 0) {
            continue;
        }
        let row = [], cols = rows[i].querySelectorAll("td, th");
        //looping through the tr
        for (let j = 0; j < cols.length; j++) {
            // removing space from the data
            let data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, "").replace(/(\s\s)/gm, " ");
            data = data.trim();
            if (i === 1) {
                data = data.replace(" ", "_");
                if (subcolsArr[j] && subcolsArr[j].type !== "dateColumn") {
                    data = data + "__" + subcolsArr[j].displayName.replace(" ", "_");
                }
            } else if (subcolsArr[j] && subcolsArr[j].type !== "dateColumn") {
                data = data.replace("$", "");
                data = data.replace("%", "");
                if (isValidNumber(data)) {
                    data = round(data).toString();
                }
            }
            // removing double qoute from the data
            data = data.replace(/"/g, `""`);

            // Push escaped string
            row.push(`"` + data + `"`);
        }
        if (row[0] !== '"Total"') {
            csv.push(row.join(separator));
        }

    }
    let csv_string = csv.join("\n");
    // Download it
    let filename = name + ".csv";
    let link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string));
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

// Function to check if a date is within a range
export const isDateInRange = (date, startDate, endDate) => {
    const selected = new Date(date);
    const start = new Date(startDate);
    const end = new Date(endDate);
    return selected >= start && selected <= end;
};

// This function will return the Demand Imcact String based on the value
export const getDemandImpactName = (impactvalue) => {
    if (impactvalue >= -10 && impactvalue < -5) {
        return "High Negative";
    } else if (impactvalue >= -5 && impactvalue < 0) {
        return "Low Negative"
    } else if (impactvalue >= 0 && impactvalue < 5) {
        return "Neutral"
    } else if (impactvalue >= 5 && impactvalue < 10) {
        return "Low Positive"
    } else if (impactvalue >= 10) {
        return "High Positive"
    }
}

// This function will return the Demand Imcact Color based on the value
export const getDemandImpactColor = (impactvalue) => {
    //alert(impactvalue)
    if (impactvalue >= -10 && impactvalue < -5) {
        return '#C92A2A'; // High negative
    } else if (impactvalue >= -5 && impactvalue < 0) {
        return '#ff6b6b'; // Low Negative
    } else if (impactvalue >= 0 && impactvalue < 5) {
        return '#adb5bd'; // Neutral
    } else if (impactvalue >= 5 && impactvalue < 10) {
        return '#51cf66'; // Low Positive
    } else if (impactvalue >= 10) {
        return '#2B8A3E'; // High Positive
    }
};

export const getNotesData = async (sdlyOn, app_state, dispatch) => {

    const { fromDate2, toDate2 } = getSDLYorSDTLYDate(sdlyOn, app_state);

    try {
        const response = await DataService.getData({
            api: "rms/properties/notes/" + app_state.property_id + "/?fromDate=" + app_state.primary_stay_range.date_from + "&toDate=" + app_state.primary_stay_range.date_until + "&fromDate=" + fromDate2 + "&toDate=" + toDate2 + "&fillMissingDays=true&flat=true",
            request: { method: 'GET' }
        });
        if (response.status === "success") {
            app_state.notes = response.data
            console.log("Note is fetched for chart", response.data);

            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        notes: response.data
                    }
                }
            });
        } else {
            console.log("Note API status error");
        }
    } catch (error) {
        console.error('An error occurred', error);
        throw error; // Rethrow error to be handled outside
    }
}

//GET API for date wise impact fetch 
export const avgImpactGetData = async (sdlyOn, app_state, dispatch) => {

    try {
        const response = await DataService.getData({
            api: "rms/properties/events/" + app_state.property_id + "/?fromDate=" + app_state.primary_stay_range.date_from + "&toDate=" + app_state.primary_stay_range.date_until + "&avgDemandImpact=true&isIgnored=false",
            request: { method: 'GET' }
        });
        if (response.status === "success") {
            console.log("Avgs Impact Chart data is fetched", response.data)
            //setAvgImpactData(response.data);
            app_state.avgImpactData = response.data;
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        avgImpactData: response.data
                    }
                }
            })
        }
        else {
            console.log("Events List API status error")
        }
    }
    catch (error) {
        console.error('An error occurred', error);
    }
}
//GET API event list fetch 
export const EventsListGetData = async (sdlyOn, app_state, dispatch) => {
    try {
        const response = await DataService.getData({
            api: "rms/properties/events/" + app_state.property_id + "/?fromDate=" + app_state.primary_stay_range.date_from + "&toDate=" + app_state.primary_stay_range.date_until,
            request: { method: 'GET' }
        });
        if (response.status === "success") {
            console.log("Events List Chart is fetched", response.data)
            //setEventList(response.data);
            app_state.eventList = response.data;
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        eventList: response.data
                    }
                }
            })
        }
        else {
            console.log("Events List API status error")
        }
    }
    catch (error) {
        console.error('An error occurred', error);
    }
}

//GET API for date wise impact fetch SDLY
export const avgImpactGetDataSDLY = async (sdlyOn, app_state, dispatch) => {

    let sdlyStartDate = dateFormat(app_state.metas.osr.sdly_from, "YYYY-MM-DD");
    let sdlyEndDate = dateFormat(app_state.metas.osr.sdly_to, "YYYY-MM-DD");
    if (app_state.workspace_controls.dayDate === "date") {
        sdlyStartDate = dateFormat(app_state.metas.osr.sdtly_from, "YYYY-MM-DD");
        sdlyEndDate = dateFormat(app_state.metas.osr.sdtly_to, "YYYY-MM-DD");
    }

    try {
        const response = await DataService.getData({
            api: "rms/properties/events/" + app_state.property_id + "/?fromDate=" + sdlyStartDate + "&toDate=" + sdlyEndDate + "&avgDemandImpact=true&isIgnored=false",
            request: { method: 'GET' }
        });
        if (response.status === "success") {
            console.log("Avgs Impact SDLY Chart data is fetched", response.data)
            //setAvgImpactData(response.data);
            app_state.avgImpactDataSDLY = response.data;
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        avgImpactDataSDLY: response.data
                    }
                }
            })
        }
        else {
            console.log("Events List API status error")
        }
    }
    catch (error) {
        console.error('An error occurred', error);
    }
}
//GET API event list fetch SDLY
export const EventsListGetDataSDLY = async (sdlyOn, app_state, dispatch) => {

    let sdlyStartDate = dateFormat(app_state.metas.osr.sdly_from, "YYYY-MM-DD");
    let sdlyEndDate = dateFormat(app_state.metas.osr.sdly_to, "YYYY-MM-DD");
    if (app_state.workspace_controls.dayDate === "date") {
        sdlyStartDate = dateFormat(app_state.metas.osr.sdtly_from, "YYYY-MM-DD");
        sdlyEndDate = dateFormat(app_state.metas.osr.sdtly_to, "YYYY-MM-DD");
    }

    try {
        const response = await DataService.getData({
            api: "rms/properties/events/" + app_state.property_id + "/?fromDate=" + sdlyStartDate + "&toDate=" + sdlyEndDate,
            request: { method: 'GET' }
        });
        if (response.status === "success") {
            console.log("Events List SDLY Chart is fetched", response.data)
            //setEventList(response.data);
            app_state.eventListSDLY = response.data;
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        eventListSDLY: response.data
                    }
                }
            })
        }
        else {
            console.log("Events List API status error")
        }
    }
    catch (error) {
        console.error('An error occurred', error);
    }
}

//GET API for date wise impact fetch Comparision
export const avgImpactGetDataComparision = async (sdlyOn, app_state, dispatch) => {
    let sdlyStartDate = dateFormat(app_state.comp_stay_range.date_from, "YYYY-MM-DD");
    let sdlyEndDate = dateFormat(app_state.comp_stay_range.date_until, "YYYY-MM-DD");

    try {
        const response = await DataService.getData({
            api: "rms/properties/events/" + app_state.property_id + "/?fromDate=" + sdlyStartDate + "&toDate=" + sdlyEndDate + "&avgDemandImpact=true&isIgnored=false",
            request: { method: 'GET' }
        });
        if (response.status === "success") {
            console.log("Avgs Impact Comparision Chart data is fetched", response.data)
            //setAvgImpactData(response.data);
            app_state.avgImpactDataSDLY = response.data;
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        avgImpactDataSDLY: response.data
                    }
                }
            })
        }
        else {
            console.log("Events List API status error")
        }
    }
    catch (error) {
        console.error('An error occurred', error);
    }
}
//GET API event list fetch Comparision
export const EventsListGetDataComparision = async (sdlyOn, app_state, dispatch) => {

    let sdlyStartDate = dateFormat(app_state.comp_stay_range.date_from, "YYYY-MM-DD");
    let sdlyEndDate = dateFormat(app_state.comp_stay_range.date_until, "YYYY-MM-DD");

    try {
        const response = await DataService.getData({
            api: "rms/properties/events/" + app_state.property_id + "/?fromDate=" + sdlyStartDate + "&toDate=" + sdlyEndDate,
            request: { method: 'GET' }
        });
        if (response.status === "success") {
            console.log("Events List Comparision Chart is fetched", response.data)
            //setEventList(response.data);
            app_state.eventListSDLY = response.data;
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        eventListSDLY: response.data
                    }
                }
            })
        }
        else {
            console.log("Events List API status error")
        }
    }
    catch (error) {
        console.error('An error occurred', error);
    }
}

// function to check if the given date month data exist in the given array or not .
export const checkMonthData = (dateArray, endDate) => {
    let endDateMonth = moment(endDate).format('YYYY-MM');
    return dateArray.some(item => item.date.startsWith(endDateMonth));
};