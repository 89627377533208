import { breadcrumbsClasses } from "@mui/material";
import moment from "moment";
import { convertToPercent, round, getCompsetMedian, setUpDate, getList, dateFormat, getAppData, getObjectFilter, deepCopy, checkProperValue, getNumDaysFromDateRange, daysCountFromRange, getPermissions, hasRestrictions, getDayDateKey } from "../../app/util"
import { concatForcastData, getMarketDbaData, concatForcastDataSegmented, getMarketForecast, getMarketForecastNonSegment } from "./tilesData/market";
// import {getBookingCurveSegmentedData } from "./tilesData/bookingcurve"
import { getBookingCurveData, getBookingCurveDbaData, getBookingCurveSegmentedData } from "./tilesData/bookingcurvenew"
import { useDataSummary } from "../Tile/kpiService";
import dataService from "../../services/data.service";
import { getPickupData } from "./tilesData/pickup";
import { getMarketSellRatesData } from "./tilesData/marketsellrates";
import { getOtbSegmentData } from "./tilesData/otb";
import { getForecastNonSegmentedData, getForecastNonSegmentedDataNew } from "./tilesData/forecast";
import { TILES } from "../../app/config";
import { getPricingDataFormatted } from "./tilesData/pricing";
import restDemoData from "./test.json";
import { getFlightsData } from "./tilesData/flights";
import { getRestrictionsFormatted } from "./tilesData/restrictions";
import { getBudgetData } from "./tilesData/budget";
import { DeblurOutlined } from "@mui/icons-material";


export function processDataNew(columns, data, { auth, app_state, ui_state, segments }) {
    let setup_data = [];
    const { workspace_controls } = app_state;
    const { sdlyOn, pickUpType, segmented_view, scopeType, dayDate } = workspace_controls;
    const { active_tiles } = ui_state;
    const { profile } = auth;

    let startDate = app_state.primary_stay_range.date_from;
    let endDate = app_state.primary_stay_range.date_until;
    let tableLength = moment(endDate).diff(startDate, 'days') + 1;
    // debugger;
    /**  permission details  */
    let propDetails = getPermissions(profile.property_details, app_state.property_id);
    let restrictionEnable = (propDetails.permissions.restrictions) ? propDetails.permissions.restrictions : 0;
    /**  end Permisssion details  */
    const restrictionData = dataService.getSeriesById("GetRestrictions").data;
    const restrictionRoomTypeData = dataService.getSeriesById("GetRestrictionsRoomType").data;

    let tileLength = active_tiles.length;
    for (let index = 0; index < tileLength; index++) {
        const tile = active_tiles[index];
        let dataKey;
        if (['otb', 'pickup', 'pace', 'market', 'compset', 'market_sell_rates', 'forecast'].includes(tile)) {
            if (['otb', 'pickup', 'pace', 'market', 'compset', 'market_sell_rates', 'forecast'].includes(tile)) {
                // dataKey = "HotelTotal"
                dataKey = "total";
            }

            data.map((dataItem, index) => {
                let segmentedDataItem = dataItem.segmented;
                let dataItem_date = dataItem.index.date;
                let items = [];
                // debugger;
                if (segmentedDataItem && segmented_view) {
                    let itemObj = { index: deepCopy(dataItem.index) };
                    segmentedDataItem.map((segment) => {
                        let seg_name = segment.name;
                        let item = deepCopy(itemObj);
                        let seg = segments.map[seg_name] ? segments.map[seg_name] : seg_name;

                        // item.index.segment_code = seg_name;
                        // item.total = deepCopy(segment.value);
                        item.index = {...item.index, segment: seg, segment_code:seg_name  };
                        item.total = deepCopy(segment.value);
                        items.push(item);
                    });
                    //adding segment total row
                    if (scopeType === 'dba' && dataItem_date == '') {
                        dataItem.index.dba = '';
                        delete dataItem.index.date;
                        delete dataItem.index.dow;
                    }
                    let item = { index: deepCopy(dataItem.index) };
                    item.index.segment = 'Total';
                    item.index.segment_code = 'Total';
                    item.total = deepCopy(dataItem.total);
                    if (dataItem_date !== undefined && dataItem_date !== '') {
                        items.push(item);
                    }
                    if (dataItem.index.dba !== undefined && dataItem.index.dba !== '') {
                        items.push(item);
                    }

                } else {
                    items.push(dataItem);
                }

                items.map((item, index) => {
                    let index_date = item.index.date;
                    let data_columns = [];
                    // debugger;
                    // if (moment(item.index.date) <= moment(app_state.primary_stay_range.date_until)) {
                    columns.map(col => {
                        let dataObj = {};
                        let index = item.index;
                        let segment = index.segment;
                        let segment_code = index.segment_code;
                        let colType = col.type;
                        let colK = col.k;
                        let colId = col.id;
                        
                        if (colType === 'dateColumn') {
                            // let date = index.date;
                            // dataObj = setUpDate(dataObj, date);
                            let date = index.date;
                            dataObj = date ? setUpDate(dataObj, date) : index;
                            if (segmented_view) {
                                dataObj["segment"] = segment;
                                dataObj["segment_code"] = segment_code;
                            }
                        }
                        if (colType === 'dataColumn') {
                            let { mainKey, id, subcolumns } = col;
                            // console.log(mainKey," test ",id);
                            if (id === "pickup_prior") {
                                mainKey = (pickUpType === 'Standard') ? "prior" : "pickup";
                            }
                            if (id === "pickup_otb_current") {
                                mainKey = (pickUpType === 'Standard') ? "current" : "primary";
                            }
                            subcolumns.map(key => {
                                let key_value = key.value;
                                let itemDkPrimary = item[dataKey]['primary'][key_value];
                                if (mainKey === "restrictions" && restrictionEnable) {
                                    let resData = restrictionData; //dataService.getSeriesById("GetRestrictions").data;
                                    let resRmTypeCurData = restrictionRoomTypeData; //dataService.getSeriesById("GetRestrictionsRoomType").data;
                                    // let resData = restDemoData;
                                    resData = resData && resData.cur ? resData.cur : [];
                                    resRmTypeCurData = resRmTypeCurData && resRmTypeCurData.cur ? resRmTypeCurData.cur : [];
                                    let curDate = moment(index_date).format("YYYY-MM-DD")
                                    let filteredData = getObjectFilter(deepCopy(resData), curDate, "stay_date");
                                    let filteredRmTypeData = getObjectFilter(deepCopy(resRmTypeCurData), curDate, "stay_date");
                                    let filteredRmTypeData_size = filteredRmTypeData.length;
                                    let filterRestData = [];
                                    if (filteredData[0] && hasRestrictions(filteredData[0])) {
                                        filterRestData.push(filteredData[0]);

                                    }
                                    if (filteredRmTypeData_size > 0) {
                                        filteredRmTypeData = filteredRmTypeData.filter(i => {
                                            if (hasRestrictions(i)) {
                                                return i;
                                            }
                                        })
                                        filterRestData = filterRestData.concat(filteredRmTypeData)
                                    }
                                    dataObj[key_value] = filterRestData;
                                } else if(id === "forecast_difference"){
                                    // console.log(item[dataKey]['primary'][key.value]);
                                    // debugger;
                                    dataObj[key_value] = itemDkPrimary - item[dataKey]['userforecastdata'][key_value] ;
                                }
                                
                                else if (item[dataKey][mainKey]) {

                                    dataObj[key_value] = item[dataKey][mainKey][key_value];
                                    if (tile === 'compset' && key_value !== 'CM') {
                                        // dataObj[key.value] = {};
                                        dataObj[key_value] = item[dataKey][mainKey][key_value].sell_rate;
                                    }
                                    if (tile === 'market_sell_rates') {
                                        dataObj[key_value] = item[dataKey][mainKey][colK][key_value];
                                    }
                                    // debugger;
                                }
                            })
                        }
                        dataObj.id = colId; //col.id;
                        data_columns.push(dataObj);
                    })

                    data_columns.map((itmArr, idx) => {
                        if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                            // data_columns.push({"ignore_sort":true})
                            data_columns[idx]["ignore_sort"] = true;
                        }
                    })
                    // debugger;
                    setup_data.push(data_columns);

                });
                // }
                // debugger;
            })

        }
        if (tile === "pace__") {
            if (app_state.workspace_controls.segmented_view) {
                setup_data = (app_state.workspace_controls.scopeType === "Date") ?
                    [...getBookingCurveSegmentedData(data, columns, app_state)]
                    : [...getBookingCurveSegmentedData(data, columns, app_state)]

            } else {
                setup_data = (app_state.workspace_controls.scopeType === "Date") ? [...getBookingCurveData(data, columns, app_state)] : [...getBookingCurveDbaData(columns, app_state)];
            }
        }
        // if (tile === "pickup") {
        //     dataKey = "HotelTotal"
        //     setup_data = [...getPickupData(data, columns, app_state)];
        // }
        // Market Tile data 
        if (tile === "market__") {
            if (app_state.workspace_controls.scopeType === 'Dba') {
                let dataMarketSdlyDba = dataService.getSeriesById("MarketSdlyDba").data;
                // let marketDbaFinalData = data.concat(dataMarketSdlyDba);
                setup_data = [...getMarketDbaData(data, columns, app_state)];
            } else {
                // debugger;
                // let projectedTotal = {
                //     "G-Group" : {
                //         "Occ":0
                //     }};
                let projectedTotal = {};
                data.map((item, index) => {
                    projectedTotal[item.index.segment] = {
                        "Occ": 0,
                        "Rooms": 0,
                        "ADR": 0,
                        "RevPAR": 0,
                        "Revenue": 0,
                        "SellRate": 0,
                    };
                })
                dataKey = "MarketTotal"
                let idx = 0;
                data.map((item, index) => {
                    let data_columns = [];
                    columns.map(col => {
                        let dataObj = { id: col.id };
                        if (col.type === 'dateColumn') {
                            dataObj = setUpDate(dataObj, item.index.date);
                            if (app_state.workspace_controls.segmented_view) {
                                dataObj["segment"] = item.index.segment;
                            }
                        }
                        if (col.type === 'dataColumn') {
                            let { mainKey } = col;
                            col.subcolumns.map(key => {
                                if (mainKey === "marketotbpickup") {
                                    if (key.value == 'Occ') {
                                        dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey]["pickup"][key.value]);

                                    } else if (key.value == 'ADR') {
                                        let rev = round(item[dataKey]["primary"]['Revenue'] - item[dataKey]["pickup"]['Revenue']);
                                        let rooms = round(item[dataKey]["primary"]['Rooms'] - item[dataKey]["pickup"]['Rooms']);
                                        // debugger;
                                        let adrValue = round(rev / rooms);
                                        dataObj[key.value] = (isNaN(adrValue)) ? 0.00 : adrValue;
                                    } else if (key.value == 'Revenue') {
                                        dataObj[key.value] = round(item[dataKey]["primary"]['Revenue'] - item[dataKey]["pickup"]['Revenue'])
                                    } else {
                                        dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey]["pickup"][key.value])
                                    }
                                } else if (mainKey === "sdly_diff_sdowly") {
                                    if (key.value == 'Occ') {
                                        dataObj[key.value] = convertToPercent(item[dataKey]["pickup"][key.value] - item[dataKey]["sdly"][key.value]);

                                    } else if (key.value == 'ADR') {
                                        let rev = round(item[dataKey]["pickup"]['Revenue'] - item[dataKey]["sdly"]['Revenue']);
                                        let rooms = round(item[dataKey]["pickup"]['Rooms'] - item[dataKey]["sdly"]['Rooms']);
                                        // debugger;
                                        let adrValue = round(rev / rooms);
                                        dataObj[key.value] = (isNaN(adrValue)) ? 0.00 : adrValue;
                                    } else if (key.value == 'Revenue') {
                                        dataObj[key.value] = round(item[dataKey]["pickup"]['Revenue'] - item[dataKey]["sdly"]['Revenue'])
                                    } else {
                                        dataObj[key.value] = round(item[dataKey]["pickup"][key.value] - item[dataKey]["sdly"][key.value])
                                    }
                                } else if (mainKey === "sdly_variance_sdowly") {
                                    if (key.value == 'Occ') {
                                        let occValue = (item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]) / item[dataKey]["sdly"][key.value];
                                        dataObj[key.value] = (occValue && isFinite(occValue)) ? convertToPercent(occValue) + "%" : 0.00 + "%";
                                    } else if (key.value == 'ADR') {
                                        let adr1 = item[dataKey]["primary"]['Revenue'] / item[dataKey]["primary"]['Rooms'];
                                        let adr2 = item[dataKey]["sdly"]['Revenue'] / item[dataKey]["sdly"]['Rooms'];
                                        let adrValue = ((adr1 - adr2) / adr2) * 100;
                                        dataObj[key.value] = (adrValue && isFinite(adrValue)) ? adrValue + "%" : 0.00 + "%";
                                    } else if (key.value == 'Revenue') {
                                        let revenueValue = ((item[dataKey]["primary"]['Revenue'] - item[dataKey]["sdly"]['Revenue']) / item[dataKey]["sdly"]['Revenue']) * 100;
                                        dataObj[key.value] = checkProperValue(revenueValue) + "%"
                                    } else {
                                        let calVal = ((item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]) / item[dataKey]["sdly"][key.value]) * 100;
                                        dataObj[key.value] = isFinite(calVal) ? calVal + "%" : 0.00 + "%";
                                    }
                                } else if (mainKey === "sdly_diff_market_last_year_actuals") {
                                    if (key.value == 'Occ') {
                                        dataObj[key.value] = convertToPercent(item[dataKey]["sdly"][key.value] - item[dataKey]["sdly_actual"][key.value]);

                                    } else if (key.value == 'ADR') {
                                        let rev = round(item[dataKey]["sdly"]['Revenue'] - item[dataKey]["sdly_actual"]['Revenue']);
                                        let rooms = round(item[dataKey]["sdly"]['Rooms'] - item[dataKey]["sdly_actual"]['Rooms']);
                                        let adrValue = round(rev / rooms);
                                        dataObj[key.value] = (isNaN(adrValue)) ? 0.00 : adrValue;
                                    } else if (key.value == 'Revenue') {
                                        dataObj[key.value] = round(item[dataKey]["sdly"]['Revenue'] - item[dataKey]["sdly_actual"]['Revenue'])
                                    } else {
                                        dataObj[key.value] = round(item[dataKey]["sdly"][key.value] - item[dataKey]["sdly_actual"][key.value])
                                    }
                                } else if (mainKey === "sdly_variance_market_last_year_actuals") {
                                    if (key.value == 'Occ') {
                                        let occValue = (item[dataKey]["sdly"][key.value] - item[dataKey]["sdly_actual"][key.value]) / item[dataKey]["sdly_actual"][key.value];
                                        dataObj[key.value] = checkProperValue(convertToPercent(occValue)) + "%";
                                    } else if (key.value == 'ADR') {
                                        let adr1 = item[dataKey]["sdly"]['Revenue'] / item[dataKey]["sdly"]['Rooms'];
                                        let adr2 = item[dataKey]["sdly_actual"]['Revenue'] / item[dataKey]["sdly_actual"]['Rooms'];
                                        let adrValue = ((round(adr1) - round(adr2)) / round(adr2)) * 100;
                                        dataObj[key.value] = checkProperValue(adrValue) + "%";
                                    } else if (key.value == 'Revenue') {
                                        let revenueVal = ((round(item[dataKey]["sdly"]['Revenue']) - round(item[dataKey]["sdly_actual"]['Revenue'])) / round(item[dataKey]["sdly_actual"]['Revenue'])) * 100;
                                        dataObj[key.value] = checkProperValue(revenueVal) + "%"
                                    } else {
                                        let calVal = ((round(item[dataKey]["sdly"][key.value]) - round(item[dataKey]["sdly_actual"][key.value])) / round(item[dataKey]["sdly_actual"][key.value])) * 100;
                                        dataObj[key.value] = checkProperValue(calVal) + "%";
                                    }

                                }
                                else if (mainKey === "market_projected" && app_state.workspace_controls.segmented_view) {
                                    let forcastItem = getMarketForecast(item.index.date, item.index.segment, app_state)[0];
                                    if (forcastItem) {
                                        if (key.value === "Occ") {
                                            if (typeof forcastItem['forecast_occ'] !== 'undefined' && !isNaN(forcastItem['forecast_occ'])) {
                                                dataObj[key.value] = convertToPercent(forcastItem['forecast_occ']);
                                                if (item.index.date !== "") {
                                                    projectedTotal[item.index.segment]['Occ'] += convertToPercent(forcastItem['forecast_occ']);
                                                }
                                                projectedTotal[key.value] += convertToPercent(forcastItem['forecast_occ']);
                                            } else {
                                                dataObj[key.value] = 0;
                                            }
                                        } else if (key.value === "Rooms") {
                                            dataObj[key.value] = (typeof forcastItem['forecast_rooms'] === "number") ? forcastItem['forecast_rooms'] : 0;
                                            if (item.index.date !== "") {
                                                projectedTotal[item.index.segment]['Rooms'] += forcastItem['forecast_rooms'];
                                            }
                                        } else if (key.value === "ADR") {
                                            dataObj[key.value] = (typeof forcastItem['forecast_adr'] === "number") ? round(forcastItem['forecast_adr']) : parseFloat(0);
                                            if (item.index.date !== "") {
                                                projectedTotal[item.index.segment]['ADR'] += (typeof forcastItem['forecast_adr'] === "number") ? round(forcastItem['forecast_adr']) : parseFloat(0);
                                            }
                                        } else if (key.value === "RevPAR") {
                                            if (!isNaN(forcastItem['forecast_revpar'])) {
                                                dataObj[key.value] = round(forcastItem['forecast_revpar']);
                                                if (item.index.date !== "") {
                                                    projectedTotal[item.index.segment]['RevPAR'] += round(forcastItem['forecast_revpar']);
                                                }
                                            } else {
                                                dataObj[key.value] = 0;
                                            }
                                        } else if (key.value === "Revenue") {
                                            dataObj[key.value] = (!isNaN(forcastItem['forecast_revenue'])) ? round(forcastItem['forecast_revenue']) : 0;
                                            if (item.index.date !== "") {
                                                projectedTotal[item.index.segment]['Revenue'] += round(forcastItem['forecast_revenue']);
                                            }
                                        } else if (key.value === "SellRate") {
                                            let sellRate = (forcastItem['forecast_sellrate'] && forcastItem['forecast_sellrate'] !== undefined) ? round(forcastItem['forecast_sellrate']) : parseFloat(0);
                                            dataObj[key.value] = sellRate;
                                            if (item.index.date !== "") {
                                                projectedTotal[item.index.segment]['SellRate'] += sellRate;
                                            }
                                        }
                                    }

                                    ///////////
                                    if (!forcastItem) {
                                        let totalDays = daysCountFromRange(app_state)
                                        if (key.value === "Occ") {
                                            if (projectedTotal[item.index.segment]['Occ']) {
                                                dataObj[key.value] = projectedTotal[item.index.segment]['Occ'] / totalDays;
                                            } else {
                                                dataObj[key.value] = 0;
                                            }
                                        }
                                        else if (key.value === "Rooms") {
                                            dataObj[key.value] = (projectedTotal[item.index.segment]['Rooms']) ? projectedTotal[item.index.segment]['Rooms'] : 0;
                                        }
                                        else if (key.value === "ADR") {
                                            dataObj[key.value] = (projectedTotal[item.index.segment]['ADR']) ? projectedTotal[item.index.segment]['Revenue'] / projectedTotal[item.index.segment]['Rooms'] : 0;
                                        }
                                        else if (key.value === "RevPAR") {
                                            dataObj[key.value] = (projectedTotal[item.index.segment]['RevPAR']) ? projectedTotal[item.index.segment]['RevPAR'] / totalDays : 0;
                                        }
                                        else if (key.value === "Revenue") {
                                            dataObj[key.value] = (projectedTotal[item.index.segment]['Revenue']) ? projectedTotal[item.index.segment]['Revenue'] : 0;
                                        } else if (key.value === "SellRate") {
                                            dataObj[key.value] = (projectedTotal[item.index.segment]['SellRate']) ? projectedTotal[item.index.segment]['SellRate'] : 0;
                                        }
                                    }



                                }
                                else if (mainKey === "market_projected" && !app_state.workspace_controls.segmented_view) {
                                    let marketForcastNonSegmented = getMarketForecastNonSegment(item.index.date, app_state);
                                    let forcastItem = marketForcastNonSegmented[0];
                                    // debugger;
                                    if (key.value === "Occ") {
                                        if (typeof forcastItem['forecast_occ'] !== 'undefined' && !isNaN(forcastItem['forecast_occ'])) {
                                            dataObj[key.value] = convertToPercent(forcastItem['forecast_occ']);
                                        } else {
                                            dataObj[key.value] = 0;
                                        }
                                    } else if (key.value === "Rooms") {
                                        dataObj[key.value] = (typeof forcastItem['forecast_rooms'] === "number") ? forcastItem['forecast_rooms'] : 0;
                                    } else if (key.value === "ADR") {
                                        dataObj[key.value] = (typeof forcastItem['forecast_adr'] === "number") ? round(forcastItem['forecast_adr']) : parseFloat(0);
                                    } else if (key.value === "RevPAR") {
                                        if (!isNaN(forcastItem['forecast_revpar'])) {
                                            dataObj[key.value] = round(forcastItem['forecast_revpar']);
                                        } else {
                                            dataObj[key.value] = 0;
                                        }
                                    } else if (key.value === "Revenue") {
                                        dataObj[key.value] = (!isNaN(forcastItem['forecast_revenue'])) ? round(forcastItem['forecast_revenue']) : 0;
                                    } else if (key.value === "SellRate") {
                                        let sellRate = (forcastItem['forecast_sellrate'] && forcastItem['forecast_sellrate'] !== undefined) ? round(forcastItem['forecast_sellrate']) : parseFloat(0);
                                        dataObj[key.value] = sellRate;
                                    }
                                    // debugger;
                                    // let market_projected = (!app_state.workspace_controls.segmented_view) ?
                                    //     concatForcastData(app_state) : concatForcastDataSegmented(app_state);
                                    // if (idx < market_projected.length) {
                                    //     let forcastItem = market_projected[idx];
                                    //     if (key.value === "Occ") {
                                    //         if (typeof forcastItem['forecast_occ'] !== 'undefined' && !isNaN(forcastItem['forecast_occ'])) {
                                    //             dataObj[key.value] = convertToPercent(forcastItem['forecast_occ']);
                                    //         } else {
                                    //             dataObj[key.value] = 0;
                                    //         }
                                    //     } else if (key.value === "Rooms") {
                                    //         dataObj[key.value] = (typeof forcastItem['forecast_rooms'] === "number") ? forcastItem['forecast_rooms'] : 0;
                                    //     } else if (key.value === "ADR") {
                                    //         dataObj[key.value] = (typeof forcastItem['forecast_adr'] === "number") ? round(forcastItem['forecast_adr']) : parseFloat(0);
                                    //     } else if (key.value === "RevPAR") {
                                    //         if (!isNaN(forcastItem['forecast_revpar'])) {
                                    //             dataObj[key.value] = round(forcastItem['forecast_revpar']);
                                    //         } else {
                                    //             dataObj[key.value] = 0;
                                    //         }
                                    //     } else if (key.value === "Revenue") {
                                    //         dataObj[key.value] = (!isNaN(forcastItem['forecast_revenue'])) ? round(forcastItem['forecast_revenue']) : 0;
                                    //     } else if (key.value === "SellRate") {
                                    //         let sellRate = (forcastItem['forecast_sellrate'] && forcastItem['forecast_sellrate'] !== undefined) ? round(forcastItem['forecast_sellrate']) : parseFloat(0);
                                    //         dataObj[key.value] = sellRate;
                                    //     }
                                    // }

                                }
                                else {
                                    if (!item[dataKey][mainKey][key.value] || item[dataKey][mainKey][key.value] === null) {
                                        item[dataKey][mainKey][key.value] = 0;
                                    }
                                    if (key.value === "Occ") {
                                        dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]);
                                    } else {
                                        dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                                    }
                                }
                            })
                        }
                        console.log("MarketProjected ", projectedTotal);
                        data_columns.push(dataObj);
                    })
                    setup_data.push(data_columns);
                    idx++;
                })
            }
        }

        if (tile === "compset__") {
            let compsetdata = [];
            compsetdata = (app_state.workspace_controls.scopeType !== 'Dba') ? data.filter((res) => (res.index.date >= startDate && res.index.date <= endDate)) : data.slice(0, app_state.workspace_controls.dba);
            let tempdata = [];
            let count = 1;
            let m = 0;
            compsetdata.map((item, index) => {
                let data_columns = [];
                let compMedianValues = [];
                columns.map(col => {
                    let dataObj = {};
                    if (col.type === 'dateColumn') {
                        if (app_state.workspace_controls.scopeType === 'Dba') {
                            dataObj['dba'] = item.index.dba;
                            // dataObj = setUpDate(dataObj, item.Index.dba);
                        } else {
                            dataObj = setUpDate(dataObj, item.index.date);
                        }
                    }
                    // debugger;
                    if (col.type === 'dataColumn') {
                        col.subcolumns.map(key => {
                            if (key.display.toLowerCase() === "compset median") {
                                dataObj[key.value] = key.value;
                            } else {

                                if (!item[key.value]) {
                                    dataObj[key.value] = 0.00;
                                } else {
                                    dataObj[key.value] = round(item[key.value]["sell_rate"]);
                                    if (item[key.value]["sell_rate"] > 0) {
                                        compMedianValues.push(item[key.value]["sell_rate"]);
                                    }
                                }

                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                data_columns[1].CM = getCompsetMedian(compMedianValues);
                setup_data.push(data_columns);
                count++;
            })
        }
        if (tile === "market_summary") {
            dataKey = "market_total"
            data.map((item, index) => {
                let data_columns = [];
                columns.map(col => {
                    let dataObj = { id: col.id };
                    if (col.type === 'dateColumn') {
                        // dataObj['date_market_summary'] = moment(item.index.date).format('MMM, YYYY');
                        dataObj['month'] = moment(item.index.date).format('MMM, YYYY');
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "difference_sdly") {
                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]);
                                } else {
                                    dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]);

                                }
                            }
                            else if (mainKey === "difference_sdly_percent") {
                                if (key.value !== 'SellRate') {
                                    let diff = round(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]);
                                    let val = (diff * 100) / item[dataKey]["sdly"][key.value];
                                    dataObj[key.value] = val + "%"//round(val);
                                }
                            }
                            else if (mainKey === "total_forecast") {
                                //  dataObj[key.value] = round(item["total_forecast"][rooms_forecast.value]);
                                // key1 = "rooms_forecast";
                                dataObj['Rooms'] = round(item["total_forecast"].rooms_forecast);
                                dataObj['Occ'] = convertToPercent(item["total_forecast"].occ_forecast);
                                dataObj['ADR'] = round(item["total_forecast"].adr_forecast);
                                dataObj['RevPAR'] = round(item["total_forecast"].revpar_forecast);
                                dataObj['Revenue'] = round(item["total_forecast"].revenue_forecast);
                                dataObj['SellRate'] = 0.00;//round(0).toFixed(2);
                                //  debugger;
                            }
                            else if (mainKey === "sdly_actuals") {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey]["sdly_actuals"][key.value]);
                                } else {
                                    dataObj[key.value] = round(item[dataKey]["sdly_actuals"][key.value]);
                                }
                            }

                            else if (mainKey === "difference_forcast_actualSDLY") {
                                dataObj['Rooms'] = round(round(item["total_forecast"].rooms_forecast) - item[dataKey]["sdly_actuals"]['Rooms']);
                                dataObj['Occ'] = convertToPercent((item["total_forecast"].occ_forecast) - item[dataKey]["sdly_actuals"]['Occ']);
                                dataObj['ADR'] = round(round(item["total_forecast"].adr_forecast) - item[dataKey]["sdly_actuals"]['ADR']);
                                dataObj['RevPAR'] = round(round(item["total_forecast"].revpar_forecast) - item[dataKey]["sdly_actuals"]['RevPAR']);
                                dataObj['Revenue'] = round(round(item["total_forecast"].revenue_forecast) - item[dataKey]["sdly_actuals"]['Revenue']);
                                dataObj['SellRate'] = 0.00;//round(0).toFixed(2);
                            }
                            else if (mainKey === "difference_forcast_actualSDLY_percent") {
                                dataObj['Rooms'] = round(((round(item["total_forecast"].rooms_forecast) - item[dataKey]["sdly_actuals"]['Rooms']) * 100 / item[dataKey]["sdly_actuals"]['Rooms'])) + "%";
                                dataObj['Occ'] = round(((round(item["total_forecast"].occ_forecast) - item[dataKey]["sdly_actuals"]['Occ']) * 100 / item[dataKey]["sdly_actuals"]['Occ'])) + "%";
                                dataObj['ADR'] = round(((round(item["total_forecast"].adr_forecast) - item[dataKey]["sdly_actuals"]['ADR']) * 100 / item[dataKey]["sdly_actuals"]['ADR'])) + "%";
                                dataObj['RevPAR'] = round(((round(item["total_forecast"].revpar_forecast) - item[dataKey]["sdly_actuals"]['RevPAR']) * 100 / item[dataKey]["sdly_actuals"]['RevPAR'])) + "%";
                                dataObj['Revenue'] = round(((round(item["total_forecast"].revenue_forecast) - item[dataKey]["sdly_actuals"]['Revenue']) * 100 / item[dataKey]["sdly_actuals"]['Revenue'])) + "%";
                                // dataObj['SellRate']  = round(0).toFixed(2);

                            }

                            else if (mainKey === "sdly") {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey]["sdly"][key.value]).toFixed(2);
                                } else {
                                    dataObj[key.value] = round(item[dataKey]["sdly"][key.value]);
                                }
                                // dataObj[key.value] = round(item[dataKey]["sdly"][key.value]);

                            }
                            else if (item[dataKey][mainKey]) {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]).toFixed(2);
                                    // debugger;
                                } else {
                                    dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                                }
                                // debugger;
                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            })
        }
        if (tile === "hotel_summary") {
            dataKey = "hotel_total"
            data.map((item, index) => {
                let data_columns = [];
                columns.map(col => {
                    let dataObj = { id: col.id };
                    if (col.type === 'dateColumn') {
                        dataObj['date_market_summary'] = moment(item.index.date).format('MMM, YYYY');
                        dataObj['month'] = moment(item.index.date).format('MMM, YYYY');
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                            if (mainKey === "difference_sdly") {
                                if (key.value == 'Occ') {
                                    dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey][dayDateKey][key.value]);
                                } else {
                                    dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey][dayDateKey][key.value]);
                                }
                            }
                            else if (mainKey === "difference_sdly_percent") {
                                let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";

                                if (key.value == 'Occ') {
                                    dataObj[key.value] = convertToPercent((item[dataKey]["primary"][key.value] - item[dataKey][dayDateKey][key.value]) / item[dataKey][dayDateKey][key.value]) + "%";
                                } else if (key.value == 'ADR') {
                                    let adr1 = item[dataKey]["primary"]['Revenue'] / item[dataKey]["primary"]['Rooms'];
                                    let adr2 = item[dataKey][dayDateKey]['Revenue'] / item[dataKey][dayDateKey]['Rooms'];
                                    let adrVal  = ((adr1 - adr2) / adr2) * 100 ;
                                    dataObj[key.value] = (adrVal) ? adrVal+"%" : 0.00 + "%";
                                    // dataObj[key.value] = isFinite(adrCalVal) ? adrCalVal+"%" : 0.00 +"%";
                                } else if (key.value == 'Revenue') {
                                    dataObj[key.value] = ((item[dataKey]["primary"]['Revenue'] - item[dataKey][dayDateKey]['Revenue']) / item[dataKey][dayDateKey]['Revenue']) * 100 + "%"
                                    // dataObj[key.value] = isFinite(revCalVal) ? revCalVal+"%" : 0.00 +"%";
                                } else {
                                    let calVal = ((item[dataKey]["primary"][key.value] - item[dataKey][dayDateKey][key.value]) / item[dataKey][dayDateKey][key.value]) * 100;
                                    dataObj[key.value] = isFinite(calVal) ? calVal + "%" : 0.00 + "%";
                                }
                            }
                            else if (mainKey === "total_forecast") {
                                //  dataObj[key.value] = round(item["total_forecast"][rooms_forecast.value]);
                                // key1 = "rooms_forecast";
                                dataObj['Rooms'] = round(item["total_forecast"].rooms_forecast);
                                dataObj['Occ'] = convertToPercent(item["total_forecast"].occ_forecast);
                                dataObj['ADR'] = round(item["total_forecast"].adr_forecast);
                                dataObj['RevPAR'] = round(item["total_forecast"].revpar_forecast);
                                dataObj['Revenue'] = round(item["total_forecast"].revenue_forecast);
                                dataObj['SellRate'] = 0.00;//round(0).toFixed(2);
                                //  debugger;
                            }
                            else if (mainKey === "sdly_actuals") {
                                let dayDate_sdly_actual = (dayDate === "day") ? "sdly_actual" : "sdtly_actual";
                                if (key.value == 'Occ') {
                                    dataObj[key.value] = convertToPercent(item[dataKey][dayDate_sdly_actual][key.value]);
                                } else {
                                    dataObj[key.value] = round(item[dataKey][dayDate_sdly_actual][key.value]);
                                }
                            }

                            else if (mainKey === "difference_forcast_actualSDLY") {
                                let dayDate_sdly_actual = (dayDate === "day") ? "sdly_actual" : "sdtly_actual";
                                dataObj['Rooms'] = round(round(item["total_forecast"].rooms_forecast) - item[dataKey][dayDate_sdly_actual]['Rooms']);
                                dataObj['Occ'] = convertToPercent(round(item["total_forecast"].occ_forecast) - item[dataKey][dayDate_sdly_actual]['Occ']);
                                dataObj['ADR'] = round(round(item["total_forecast"].adr_forecast) - item[dataKey][dayDate_sdly_actual]['ADR']);
                                dataObj['RevPAR'] = round(round(item["total_forecast"].revpar_forecast) - item[dataKey][dayDate_sdly_actual]['RevPAR']);
                                dataObj['Revenue'] = round(round(item["total_forecast"].revenue_forecast) - item[dataKey][dayDate_sdly_actual]['Revenue']);
                                dataObj['SellRate'] = 0.00;//round(0).toFixed(2);
                            }
                            else if (mainKey === "difference_forcast_actualSDLY_percent") {
                                let dayDate_sdly_actual = (dayDate === "day") ? "sdly_actual" : "sdtly_actual";
                                dataObj['Rooms'] = round(((round(item["total_forecast"].rooms_forecast) - item[dataKey][dayDate_sdly_actual]['Rooms']) * 100 / item[dataKey][dayDate_sdly_actual]['Rooms'])) + "%";
                                dataObj['Occ'] = round(((round(item["total_forecast"].occ_forecast) - item[dataKey][dayDate_sdly_actual]['Occ']) * 100 / item[dataKey][dayDate_sdly_actual]['Occ'])) + "%";
                                dataObj['ADR'] = round(((round(item["total_forecast"].adr_forecast) - item[dataKey][dayDate_sdly_actual]['ADR']) * 100 / item[dataKey][dayDate_sdly_actual]['ADR'])) + "%";
                                dataObj['RevPAR'] = round(((round(item["total_forecast"].revpar_forecast) - item[dataKey][dayDate_sdly_actual]['RevPAR']) * 100 / item[dataKey][dayDate_sdly_actual]['RevPAR'])) + "%";
                                dataObj['Revenue'] = round(((round(item["total_forecast"].revenue_forecast) - item[dataKey][dayDate_sdly_actual]['Revenue']) * 100 / item[dataKey][dayDate_sdly_actual]['Revenue'])) + "%";
                                dataObj['SellRate'] = round(0).toFixed(2);
                            }

                            else if (mainKey === "sdly") {
                                let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";

                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey][dayDateKey][key.value]).toFixed(2);
                                } else {
                                    dataObj[key.value] = round(item[dataKey][dayDateKey][key.value]);
                                }
                            }
                            else if (item[dataKey][mainKey]) {
                                mainKey = getDayDateKey(dayDate, mainKey);
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]).toFixed(2);
                                } else {
                                    dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                                }
                                // debugger;
                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            })
        }
        //// Forecast Tile data 
        if (tile === "forecast__") {
            let otbData = [];
            let otb = dataService.getSeriesById("OSR").data;
            let as_of_date = app_state.as_of_date;
            let startDate = app_state.primary_stay_range.date_from;
            let endDate = app_state.primary_stay_range.date_until;
            let n = Math.abs(moment.duration(moment(as_of_date).diff(moment(endDate))).asDays()) + 1;
            let forcastData = data.slice(0, n);
            let forcastFinalData = (app_state.workspace_controls.segmented_view) ? data : getForecastNonSegmentedData();
            forcastFinalData.map((item, index) => {
                let data_columns = [];
                let m = moment(item.stay_date);
                if (m.isSameOrBefore(endDate) || app_state.workspace_controls.segmented_view) {
                    columns.map((col, idx) => {
                        let dataObj = { id: col.id };
                        if (col.type === 'dateColumn') {
                            dataObj = setUpDate(dataObj, item.stay_date);
                            if (app_state.workspace_controls.segmented_view) {
                                dataObj["segment"] = item.segment;
                            }

                        }
                        if (col.type === 'dataColumn') {
                            let { mainKey } = col;
                            col.subcolumns.map(key => {
                                if ((key.value).toLowerCase() == "rooms") {
                                    dataObj[key.value] = item.predicted_total_cons_rooms;
                                } else if ((key.value).toLowerCase() == "occ") {
                                    dataObj[key.value] = convertToPercent(item.predicted_total_cons_occ);
                                } else if ((key.value).toLowerCase() == "adr") {
                                    dataObj[key.value] = round(item.predicted_total_cons_adr);
                                } else if ((key.value).toLowerCase() == "revpar") {
                                    dataObj[key.value] = round(item.predicted_total_cons_revpar);
                                } else if ((key.value).toLowerCase() == "revenue") {
                                    dataObj[key.value] = round(item.predicted_total_cons_revenue);
                                } else {
                                    dataObj[key.value] = round("0.00");
                                }

                                let userForecastData = [];
                                userForecastData = (dataService.getSeriesById("ManualForecast") && dataService.getSeriesById("ManualForecast").data) ? dataService.getSeriesById("ManualForecast").data.data : [];
                                if (userForecastData && userForecastData.length > 0 && !app_state.workspace_controls.segmented_view) {
                                    if (mainKey === "userforecastdata") {
                                        /// add userForecast Data ///
                                        if (userForecastData && userForecastData.length > 0) {
                                            if ((key.value).toLowerCase() == "rooms") {
                                                dataObj[key.value] = userForecastData[index]['total_forecast']['Rooms'];
                                            } else if ((key.value).toLowerCase() == "occ") {
                                                dataObj[key.value] = (userForecastData[index]['total_forecast']['Occ%']);
                                            } else if ((key.value).toLowerCase() == "adr") {
                                                dataObj[key.value] = round(userForecastData[index]['total_forecast'].ADR);
                                            } else if ((key.value).toLowerCase() == "revpar") {
                                                dataObj[key.value] = round(userForecastData[index]['total_forecast'].RevPAR);
                                            } else if ((key.value).toLowerCase() == "revenue") {
                                                dataObj[key.value] = round(userForecastData[index]['total_forecast'].Revenue);
                                            }
                                        }
                                    }
                                    if (mainKey === "difference") {
                                        /// add userForecast Data ///
                                        if ((key.value).toLowerCase() == "rooms") {
                                            dataObj[key.value] = item.predicted_total_cons_rooms - userForecastData[index]['total_forecast']['Rooms'];
                                        } else if ((key.value).toLowerCase() == "occ") {
                                            dataObj[key.value] = convertToPercent(item.predicted_total_cons_occ) - (userForecastData[index]['total_forecast']['Occ%']);
                                        } else if ((key.value).toLowerCase() == "adr") {
                                            dataObj[key.value] = round(item.predicted_total_cons_adr - userForecastData[index]['total_forecast'].ADR);
                                        } else if ((key.value).toLowerCase() == "revpar") {
                                            dataObj[key.value] = round(item.predicted_total_cons_revpar) - round(userForecastData[index]['total_forecast'].RevPAR);
                                        } else if ((key.value).toLowerCase() == "revenue") {
                                            dataObj[key.value] = round(item.predicted_total_cons_revenue) - round(userForecastData[index]['total_forecast'].Revenue);
                                        }

                                    }
                                } else {


                                    if (mainKey === "userforecastdata") {
                                        /// add userForecast Data ///
                                        if (userForecastData && userForecastData.length > 0) {
                                            if ((key.value).toLowerCase() == "rooms") {
                                                dataObj[key.value] = 0;
                                            } else if ((key.value).toLowerCase() == "occ") {
                                                dataObj[key.value] = 0.00;
                                            } else if ((key.value).toLowerCase() == "adr") {
                                                dataObj[key.value] = 0.00;
                                            } else if ((key.value).toLowerCase() == "revpar") {
                                                dataObj[key.value] = 0.00;
                                            } else if ((key.value).toLowerCase() == "revenue") {
                                                dataObj[key.value] = 0.00;
                                            }
                                        }
                                    }
                                    if (mainKey === "difference") {
                                        /// add userForecast Data ///
                                        if ((key.value).toLowerCase() == "rooms") {
                                            dataObj[key.value] = item.predicted_total_cons_rooms;
                                        } else if ((key.value).toLowerCase() == "occ") {
                                            dataObj[key.value] = convertToPercent(item.predicted_total_cons_occ);
                                        } else if ((key.value).toLowerCase() == "adr") {
                                            dataObj[key.value] = round(item.predicted_total_cons_adr);
                                        } else if ((key.value).toLowerCase() == "revpar") {
                                            dataObj[key.value] = round(item.predicted_total_cons_revpar);
                                        } else if ((key.value).toLowerCase() == "revenue") {
                                            dataObj[key.value] = round(item.predicted_total_cons_revenue);
                                        }

                                    }



                                }


                            })
                        }

                        data_columns.push(dataObj);
                    })
                }
                setup_data.push(data_columns);
            })
        }
        // Market Sell Rate ///
        if (tile === "market_sell_rates__") {
            let tempdata = [];
            let count = 1;
            let m = 0;
            setup_data = [...getMarketSellRatesData(data, columns, app_state)];
        }
        if (tile === 'pricing') {
            let pricingData = getPricingDataFormatted(app_state);
            let pricingDataRoomType = getPricingDataFormatted(app_state, true)
            let otbData = dataService.getSeriesById("OSR").data.data;
            const foreCastData = getForecastNonSegmentedDataNew();
            const resData = getRestrictionsFormatted(app_state, "cur")

            let date = moment(startDate);
            for (let i = 0; i < tableLength; i++) {
                const data_columns = [];
                let baseRoomSellRate = 0;
                columns.map(col => {
                    let dataObj = {};
                    let data = [];

                    if (col.id === "otb" || col.id === "date") {
                        data = otbData
                    }
                    else if (col.id === "forecast") {
                        data = foreCastData;
                    } else if (col.id === "pricing") {
                        data = pricingData
                    } else if (col.id === "restrictions") {

                        data = resData;
                    } else if (col.id.includes("roomtype_pricing_")) {
                        data = pricingDataRoomType;

                    }
                    col.subcolumns.map(sub_col => {
                        if (col.type === 'dateColumn') {
                            if (sub_col.subcol_id === 'date') {
                                dataObj['date'] = date.format("YYYY-MM-DD");

                            } else if (sub_col.subcol_id === 'dow') {
                                dataObj['dow'] = date.format("ddd");

                            }
                            if (app_state.workspace_controls.segmented_view) {
                                // dataObj["segment"] = item.index.segment;
                            }
                        } else {
                            let key = sub_col.subcol_id.replace(col.id + "_", "");
                            let dPoint = "";

                            if (col.id === "otb" && data[i]) {
                                dPoint = data[i]["HotelTotal"]["primary"][key];


                            }
                            if (col.id === "forecast" && data[i]) {
                                dPoint = data[i]["HotelTotal"]["primary"][key];

                            }
                            if (col.id == "restrictions" && data[i]) {
                                dPoint = data[i];
                            }
                            if (col.id === "pricing" && data[i]) {
                                if (key === "Sold" && otbData[i]) {
                                    dPoint = otbData[i]["HotelTotal"]["primary"]["Rooms"];
                                }
                                if (key === 'SellRate' && otbData[i]) {
                                    dPoint = otbData[i]["HotelTotal"]["primary"]["SellRate"]
                                }
                                if (moment(date).diff(app_state.as_of_date, 'days') >= 0) {
                                    if (key == "ratediff") {
                                        dPoint = data[i]["RecSellRate"] - data[i]["SellRate"]
                                    }
                                    else if (key === 'SellRate' || key === "RecSellRate") {
                                        dPoint = data[i][key];
                                    }
                                } else {
                                    if (key == "ratediff" || key === "RecSellRate") {
                                        dPoint = "-";
                                    }

                                }

                            }
                            if (col.id.includes("roomtype_pricing_") && col.room_code) {
                                if (col.room_code && data[i] && data[i][col.room_code]) {
                                    if (col.isBase === true) {
                                        baseRoomSellRate = data[i][col.room_code]["SellRate"];
                                    }
                                    if (sub_col.subcol_id === "current_differential") {
                                        dPoint = data[i][col.room_code]["SellRate"] - baseRoomSellRate;
                                    }
                                    else if (sub_col.subcol_id === "SellRate") {
                                        dPoint = data[i][col.room_code]["SellRate"];
                                    }
                                    else if (sub_col.subcol_id === "RecSellRate") {
                                        dPoint = data[i][col.room_code]["RecSellRate"];
                                    }
                                    else if (sub_col.subcol_id === "lodgiq_differential") {
                                        dPoint = data[i][col.room_code]["lodgiq_differential"];
                                    }
                                    else if (sub_col.subcol_id === "Sold") {
                                        dPoint = data[i][col.room_code]["rooms_sold"];
                                        if (isNaN(data[i][col.room_code]["rooms_sold"])) {
                                            dPoint = 0;
                                        }
                                    }


                                } else {
                                    dPoint = "";
                                }
                            }
                            if (sub_col.type === "percent") {
                                dPoint = convertToPercent(dPoint);
                            }
                            dataObj[key] = dPoint;
                        }
                    })
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
                date.add(1, 'days');
            }
        }
        if (tile === "flights") {
            setup_data = [...getFlightsData(data, columns, app_state)];
        }
        if (tile === 'restrictions') {
            // debugger;
            let date = moment(startDate);
            data.map((item, index) => {
                // debugger;
                let data_columns = [];
                columns.map(col => {
                    let dataObj = {};
                    // let data = [];
                    col.subcolumns.map(sub_col => {

                        if (col.type === 'dateColumn') {
                            if (sub_col.type === 'date') {
                                dataObj['date'] = moment(item.index.date).format("YYYY-MM-DD");

                            }
                            if (sub_col.type === 'dow') {
                                dataObj['dow'] = moment(item.index.date).format("ddd");
                            }
                            dataObj['id'] = sub_col.parent;
                        } else {

                            let key = sub_col.subcol_id.replace(col.id + "_", "");
                            const mainKey = col.mainKey;

                            // if(mainKey==='restrictions')  debugger; 
                            let dPoint = item[mainKey] && item[mainKey][key] ? item[mainKey][key] : 0;
                            if (sub_col.type === "percent") {
                                dPoint = convertToPercent(dPoint);
                            }
                            dataObj[key] = dPoint;
                            dataObj['id'] = sub_col.parent;
                        }
                    })
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            });
        }

        if (tile === "budget") {
            setup_data = [...getBudgetData(data, columns, app_state)];
        }

    }

    return setup_data;
}

export function processData(columns, data, { auth, app_state, ui_state }) {
    let setup_data = [];
    const { workspace_controls } = app_state;
    const { sdlyOn } = workspace_controls;
    const { active_tiles } = ui_state;
    let startDate = app_state.primary_stay_range.date_from;
    let endDate = app_state.primary_stay_range.date_until;
    let tableLength = moment(endDate).diff(startDate, 'days') + 1;

    for (let index = 0; index < active_tiles.length; index++) {
        const tile = active_tiles[index];
        let dataKey;
        if (tile === "otb") {
            if (tile === "otb") {
                dataKey = "HotelTotal"
            }
            data.map((item, index) => {
                let data_columns = [];
                // if (moment(item.index.date) <= moment(app_state.primary_stay_range.date_until)) {
                columns.map(col => {
                    let dataObj = {};
                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, item.index.date);
                        if (app_state.workspace_controls.segmented_view) {
                            dataObj["segment"] = item.index.segment;
                        }
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "difference_sdly") {
                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value])
                                } else {
                                    dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value])
                                }
                            }
                            else if (mainKey === "variance_sdly") {
                                let calVal = convertToPercent((item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]) / item[dataKey]["sdly"][key.value]);
                                dataObj[key.value] = (calVal && isFinite(calVal)) ? calVal + "%" : 0 + "%";
                            }
                            else if (mainKey === "pickup_diff_sdly") {
                                if (key.value === "Occ") {
                                    let pick_diff_Val = item[dataKey]["sdly_actual"][key.value] - item[dataKey]["sdly"][key.value]
                                    dataObj[key.value] = (pick_diff_Val) ? pick_diff_Val : 0;

                                } else {
                                    dataObj[key.value] = round(item[dataKey]["sdly_actual"][key.value] - item[dataKey]["sdly"][key.value])
                                }

                            } else if (mainKey === "restrictions" && getAppData(auth, app_state).restrictions.enable) {

                                let resData = dataService.getSeriesById("GetRestrictions").data;
                                // let resData = restDemoData;
                                resData = resData && resData.cur ? resData.cur : [];
                                let filteredData = getObjectFilter(deepCopy(resData), item.index.date, "stay_date")
                                let filterRestData = [];
                                if (filteredData[0]) {
                                    Object.keys(filteredData[0]).forEach(function (key) {
                                        if (filteredData[0][key] === false || filteredData[0][key] === 1) {
                                            delete filteredData[0][key];
                                        }
                                        if (filteredData[0]["stay_date"]) {
                                            //    delete filteredData[0]["stay_date"];
                                        }
                                    });
                                    filterRestData.push(filteredData[0]);
                                }

                                dataObj[key.value] = filterRestData;
                            } else if (item[dataKey][mainKey]) {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]);
                                } else if (key.value == "Rooms") {
                                    dataObj[key.value] = item[dataKey][mainKey][key.value];
                                } else {
                                    dataObj[key.value] = (item[dataKey][mainKey][key.value]) ? parseFloat(item[dataKey][mainKey][key.value]) : 0.00;
                                }
                            }
                        })
                    }
                    dataObj.id = col.id;
                    data_columns.push(dataObj);
                })

                data_columns.map((itmArr, idx) => {
                    if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                        // data_columns.push({"ignore_sort":true})
                        data_columns[idx]["ignore_sort"] = true;
                    }
                })
                setup_data.push(data_columns);
                // }
                // debugger;
            })

        }
        if (tile === "pace") {
            if (app_state.workspace_controls.segmented_view) {
                setup_data = (app_state.workspace_controls.scopeType === "Date") ?
                    [...getBookingCurveSegmentedData(data, columns, app_state)]
                    : [...getBookingCurveSegmentedData(data, columns, app_state)]

            } else {
                setup_data = (app_state.workspace_controls.scopeType === "Date") ? [...getBookingCurveData(data, columns, app_state)] : [...getBookingCurveDbaData(columns, app_state)];
            }
        }
        if (tile === "pickup") {
            dataKey = "HotelTotal"
            setup_data = [...getPickupData(data, columns, app_state)];
        }
        // Market Tile data 
        if (tile === "market") {
            if (app_state.workspace_controls.scopeType === 'Dba') {
                let dataMarketSdlyDba = dataService.getSeriesById("MarketDba").data;
                // let marketDbaFinalData = data.concat(dataMarketSdlyDba);
                setup_data = [...getMarketDbaData(data, columns, app_state)];
            } else {
                // debugger;
                // let projectedTotal = {
                //     "G-Group" : {
                //         "Occ":0
                //     }};
                let projectedTotal = {};
                data.map((item, index) => {
                    projectedTotal[item.index.segment] = {
                        "Occ": 0,
                        "Rooms": 0,
                        "ADR": 0,
                        "RevPAR": 0,
                        "Revenue": 0,
                        "SellRate": 0,
                    };
                })
                dataKey = "MarketTotal"
                let idx = 0;
                data.map((item, index) => {
                    let data_columns = [];
                    columns.map(col => {
                        let dataObj = { id: col.id };
                        if (col.type === 'dateColumn') {
                            dataObj = setUpDate(dataObj, item.index.date);
                            if (app_state.workspace_controls.segmented_view) {
                                dataObj["segment"] = item.index.segment;
                            }
                        }
                        if (col.type === 'dataColumn') {
                            let { mainKey } = col;
                            col.subcolumns.map(key => {
                                if (mainKey === "marketotbpickup") {
                                    if (key.value == 'Occ') {
                                        dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey]["pickup"][key.value]);

                                    } else if (key.value == 'ADR') {
                                        let rev = round(item[dataKey]["primary"]['Revenue'] - item[dataKey]["pickup"]['Revenue']);
                                        let rooms = round(item[dataKey]["primary"]['Rooms'] - item[dataKey]["pickup"]['Rooms']);
                                        // debugger;
                                        let adrValue = round(rev / rooms);
                                        dataObj[key.value] = (isNaN(adrValue)) ? 0.00 : adrValue;
                                    } else if (key.value == 'Revenue') {
                                        dataObj[key.value] = round(item[dataKey]["primary"]['Revenue'] - item[dataKey]["pickup"]['Revenue'])
                                    } else {
                                        dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey]["pickup"][key.value])
                                    }
                                } else if (mainKey === "sdly_diff_sdowly") {
                                    if (key.value == 'Occ') {
                                        dataObj[key.value] = convertToPercent(item[dataKey]["pickup"][key.value] - item[dataKey]["sdly"][key.value]);

                                    } else if (key.value == 'ADR') {
                                        let rev = round(item[dataKey]["pickup"]['Revenue'] - item[dataKey]["sdly"]['Revenue']);
                                        let rooms = round(item[dataKey]["pickup"]['Rooms'] - item[dataKey]["sdly"]['Rooms']);
                                        // debugger;
                                        let adrValue = round(rev / rooms);
                                        dataObj[key.value] = (isNaN(adrValue)) ? 0.00 : adrValue;
                                    } else if (key.value == 'Revenue') {
                                        dataObj[key.value] = round(item[dataKey]["pickup"]['Revenue'] - item[dataKey]["sdly"]['Revenue'])
                                    } else {
                                        dataObj[key.value] = round(item[dataKey]["pickup"][key.value] - item[dataKey]["sdly"][key.value])
                                    }
                                } else if (mainKey === "sdly_variance_sdowly") {
                                    if (key.value == 'Occ') {
                                        let occValue = (item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]) / item[dataKey]["sdly"][key.value];
                                        dataObj[key.value] = (occValue && isFinite(occValue)) ? convertToPercent(occValue) + "%" : 0.00 + "%";
                                    } else if (key.value == 'ADR') {
                                        let adr1 = item[dataKey]["primary"]['Revenue'] / item[dataKey]["primary"]['Rooms'];
                                        let adr2 = item[dataKey]["sdly"]['Revenue'] / item[dataKey]["sdly"]['Rooms'];
                                        let adrValue = ((adr1 - adr2) / adr2) * 100;
                                        dataObj[key.value] = (adrValue && isFinite(adrValue)) ? adrValue + "%" : 0.00 + "%";
                                    } else if (key.value == 'Revenue') {
                                        let revenueValue = ((item[dataKey]["primary"]['Revenue'] - item[dataKey]["sdly"]['Revenue']) / item[dataKey]["sdly"]['Revenue']) * 100;
                                        dataObj[key.value] = checkProperValue(revenueValue) + "%"
                                    } else {
                                        let calVal = ((item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]) / item[dataKey]["sdly"][key.value]) * 100;
                                        dataObj[key.value] = isFinite(calVal) ? calVal + "%" : 0.00 + "%";
                                    }
                                } else if (mainKey === "sdly_diff_market_last_year_actuals") {
                                    if (key.value == 'Occ') {
                                        dataObj[key.value] = convertToPercent(item[dataKey]["sdly"][key.value] - item[dataKey]["sdly_actual"][key.value]);

                                    } else if (key.value == 'ADR') {
                                        let rev = round(item[dataKey]["sdly"]['Revenue'] - item[dataKey]["sdly_actual"]['Revenue']);
                                        let rooms = round(item[dataKey]["sdly"]['Rooms'] - item[dataKey]["sdly_actual"]['Rooms']);
                                        let adrValue = round(rev / rooms);
                                        dataObj[key.value] = (isNaN(adrValue)) ? 0.00 : adrValue;
                                    } else if (key.value == 'Revenue') {
                                        dataObj[key.value] = round(item[dataKey]["sdly"]['Revenue'] - item[dataKey]["sdly_actual"]['Revenue'])
                                    } else {
                                        dataObj[key.value] = round(item[dataKey]["sdly"][key.value] - item[dataKey]["sdly_actual"][key.value])
                                    }
                                } else if (mainKey === "sdly_variance_market_last_year_actuals") {
                                    if (key.value == 'Occ') {
                                        let occValue = (item[dataKey]["sdly"][key.value] - item[dataKey]["sdly_actual"][key.value]) / item[dataKey]["sdly_actual"][key.value];
                                        dataObj[key.value] = checkProperValue(convertToPercent(occValue)) + "%";
                                    } else if (key.value == 'ADR') {
                                        let adr1 = item[dataKey]["sdly"]['Revenue'] / item[dataKey]["sdly"]['Rooms'];
                                        let adr2 = item[dataKey]["sdly_actual"]['Revenue'] / item[dataKey]["sdly_actual"]['Rooms'];
                                        let adrValue = ((round(adr1) - round(adr2)) / round(adr2)) * 100;
                                        dataObj[key.value] = checkProperValue(adrValue) + "%";
                                    } else if (key.value == 'Revenue') {
                                        let revenueVal = ((round(item[dataKey]["sdly"]['Revenue']) - round(item[dataKey]["sdly_actual"]['Revenue'])) / round(item[dataKey]["sdly_actual"]['Revenue'])) * 100;
                                        dataObj[key.value] = checkProperValue(revenueVal) + "%"
                                    } else {
                                        let calVal = ((round(item[dataKey]["sdly"][key.value]) - round(item[dataKey]["sdly_actual"][key.value])) / round(item[dataKey]["sdly_actual"][key.value])) * 100;
                                        dataObj[key.value] = checkProperValue(calVal) + "%";
                                    }

                                }
                                else if (mainKey === "market_projected" && app_state.workspace_controls.segmented_view) {
                                    let forcastItem = getMarketForecast(item.index.date, item.index.segment, app_state)[0];
                                    if (forcastItem) {
                                        if (key.value === "Occ") {
                                            if (typeof forcastItem['forecast_occ'] !== 'undefined' && !isNaN(forcastItem['forecast_occ'])) {
                                                dataObj[key.value] = convertToPercent(forcastItem['forecast_occ']);
                                                if (item.index.date !== "") {
                                                    projectedTotal[item.index.segment]['Occ'] += convertToPercent(forcastItem['forecast_occ']);
                                                }
                                                projectedTotal[key.value] += convertToPercent(forcastItem['forecast_occ']);
                                            } else {
                                                dataObj[key.value] = 0;
                                            }
                                        } else if (key.value === "Rooms") {
                                            dataObj[key.value] = (typeof forcastItem['forecast_rooms'] === "number") ? forcastItem['forecast_rooms'] : 0;
                                            if (item.index.date !== "") {
                                                projectedTotal[item.index.segment]['Rooms'] += forcastItem['forecast_rooms'];
                                            }
                                        } else if (key.value === "ADR") {
                                            dataObj[key.value] = (typeof forcastItem['forecast_adr'] === "number") ? round(forcastItem['forecast_adr']) : parseFloat(0);
                                            if (item.index.date !== "") {
                                                projectedTotal[item.index.segment]['ADR'] += (typeof forcastItem['forecast_adr'] === "number") ? round(forcastItem['forecast_adr']) : parseFloat(0);
                                            }
                                        } else if (key.value === "RevPAR") {
                                            if (!isNaN(forcastItem['forecast_revpar'])) {
                                                dataObj[key.value] = round(forcastItem['forecast_revpar']);
                                                if (item.index.date !== "") {
                                                    projectedTotal[item.index.segment]['RevPAR'] += round(forcastItem['forecast_revpar']);
                                                }
                                            } else {
                                                dataObj[key.value] = 0;
                                            }
                                        } else if (key.value === "Revenue") {
                                            dataObj[key.value] = (!isNaN(forcastItem['forecast_revenue'])) ? round(forcastItem['forecast_revenue']) : 0;
                                            if (item.index.date !== "") {
                                                projectedTotal[item.index.segment]['Revenue'] += round(forcastItem['forecast_revenue']);
                                            }
                                        } else if (key.value === "SellRate") {
                                            let sellRate = (forcastItem['forecast_sellrate'] && forcastItem['forecast_sellrate'] !== undefined) ? round(forcastItem['forecast_sellrate']) : parseFloat(0);
                                            dataObj[key.value] = sellRate;
                                            if (item.index.date !== "") {
                                                projectedTotal[item.index.segment]['SellRate'] += sellRate;
                                            }
                                        }
                                    }

                                    ///////////
                                    if (!forcastItem) {
                                        let totalDays = daysCountFromRange(app_state)
                                        if (key.value === "Occ") {
                                            if (projectedTotal[item.index.segment]['Occ']) {
                                                dataObj[key.value] = projectedTotal[item.index.segment]['Occ'] / totalDays;
                                            } else {
                                                dataObj[key.value] = 0;
                                            }
                                        }
                                        else if (key.value === "Rooms") {
                                            dataObj[key.value] = (projectedTotal[item.index.segment]['Rooms']) ? projectedTotal[item.index.segment]['Rooms'] : 0;
                                        }
                                        else if (key.value === "ADR") {
                                            dataObj[key.value] = (projectedTotal[item.index.segment]['ADR']) ? projectedTotal[item.index.segment]['Revenue'] / projectedTotal[item.index.segment]['Rooms'] : 0;
                                        }
                                        else if (key.value === "RevPAR") {
                                            dataObj[key.value] = (projectedTotal[item.index.segment]['RevPAR']) ? projectedTotal[item.index.segment]['RevPAR'] / totalDays : 0;
                                        }
                                        else if (key.value === "Revenue") {
                                            dataObj[key.value] = (projectedTotal[item.index.segment]['Revenue']) ? projectedTotal[item.index.segment]['Revenue'] : 0;
                                        } else if (key.value === "SellRate") {
                                            dataObj[key.value] = (projectedTotal[item.index.segment]['SellRate']) ? projectedTotal[item.index.segment]['SellRate'] : 0;
                                        }
                                    }



                                }
                                else if (mainKey === "market_projected" && !app_state.workspace_controls.segmented_view) {
                                    let marketForcastNonSegmented = getMarketForecastNonSegment(item.index.date, app_state);
                                    let forcastItem = marketForcastNonSegmented[0];
                                    // debugger;
                                    if (key.value === "Occ") {
                                        if (typeof forcastItem['forecast_occ'] !== 'undefined' && !isNaN(forcastItem['forecast_occ'])) {
                                            dataObj[key.value] = convertToPercent(forcastItem['forecast_occ']);
                                        } else {
                                            dataObj[key.value] = 0;
                                        }
                                    } else if (key.value === "Rooms") {
                                        dataObj[key.value] = (typeof forcastItem['forecast_rooms'] === "number") ? forcastItem['forecast_rooms'] : 0;
                                    } else if (key.value === "ADR") {
                                        dataObj[key.value] = (typeof forcastItem['forecast_adr'] === "number") ? round(forcastItem['forecast_adr']) : parseFloat(0);
                                    } else if (key.value === "RevPAR") {
                                        if (!isNaN(forcastItem['forecast_revpar'])) {
                                            dataObj[key.value] = round(forcastItem['forecast_revpar']);
                                        } else {
                                            dataObj[key.value] = 0;
                                        }
                                    } else if (key.value === "Revenue") {
                                        dataObj[key.value] = (!isNaN(forcastItem['forecast_revenue'])) ? round(forcastItem['forecast_revenue']) : 0;
                                    } else if (key.value === "SellRate") {
                                        let sellRate = (forcastItem['forecast_sellrate'] && forcastItem['forecast_sellrate'] !== undefined) ? round(forcastItem['forecast_sellrate']) : parseFloat(0);
                                        dataObj[key.value] = sellRate;
                                    }
                                    // debugger;
                                    // let market_projected = (!app_state.workspace_controls.segmented_view) ?
                                    //     concatForcastData(app_state) : concatForcastDataSegmented(app_state);
                                    // if (idx < market_projected.length) {
                                    //     let forcastItem = market_projected[idx];
                                    //     if (key.value === "Occ") {
                                    //         if (typeof forcastItem['forecast_occ'] !== 'undefined' && !isNaN(forcastItem['forecast_occ'])) {
                                    //             dataObj[key.value] = convertToPercent(forcastItem['forecast_occ']);
                                    //         } else {
                                    //             dataObj[key.value] = 0;
                                    //         }
                                    //     } else if (key.value === "Rooms") {
                                    //         dataObj[key.value] = (typeof forcastItem['forecast_rooms'] === "number") ? forcastItem['forecast_rooms'] : 0;
                                    //     } else if (key.value === "ADR") {
                                    //         dataObj[key.value] = (typeof forcastItem['forecast_adr'] === "number") ? round(forcastItem['forecast_adr']) : parseFloat(0);
                                    //     } else if (key.value === "RevPAR") {
                                    //         if (!isNaN(forcastItem['forecast_revpar'])) {
                                    //             dataObj[key.value] = round(forcastItem['forecast_revpar']);
                                    //         } else {
                                    //             dataObj[key.value] = 0;
                                    //         }
                                    //     } else if (key.value === "Revenue") {
                                    //         dataObj[key.value] = (!isNaN(forcastItem['forecast_revenue'])) ? round(forcastItem['forecast_revenue']) : 0;
                                    //     } else if (key.value === "SellRate") {
                                    //         let sellRate = (forcastItem['forecast_sellrate'] && forcastItem['forecast_sellrate'] !== undefined) ? round(forcastItem['forecast_sellrate']) : parseFloat(0);
                                    //         dataObj[key.value] = sellRate;
                                    //     }
                                    // }

                                }
                                else {
                                    if (!item[dataKey][mainKey][key.value] || item[dataKey][mainKey][key.value] === null) {
                                        item[dataKey][mainKey][key.value] = 0;
                                    }
                                    if (key.value === "Occ") {
                                        dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]);
                                    } else {
                                        dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                                    }
                                }
                            })
                        }
                        console.log("MarketProjected ", projectedTotal);
                        data_columns.push(dataObj);
                    })
                    setup_data.push(data_columns);
                    idx++;
                })
            }
        }

        if (tile === "compset") {
            let compsetdata = [];
            compsetdata = (app_state.workspace_controls.scopeType !== 'Dba') ? data.filter((res) => (res.index.date >= startDate && res.index.date <= endDate)) : data.slice(0, app_state.workspace_controls.dba);
            let tempdata = [];
            let count = 1;
            let m = 0;
            compsetdata.map((item, index) => {
                let data_columns = [];
                let compMedianValues = [];
                columns.map(col => {
                    let dataObj = {};
                    if (col.type === 'dateColumn') {
                        if (app_state.workspace_controls.scopeType === 'Dba') {
                            dataObj['dba'] = item.index.dba;
                            // dataObj = setUpDate(dataObj, item.Index.dba);
                        } else {
                            dataObj = setUpDate(dataObj, item.index.date);
                        }
                    }
                    // debugger;
                    if (col.type === 'dataColumn') {
                        col.subcolumns.map(key => {
                            if (key.display.toLowerCase() === "compset median") {
                                dataObj[key.value] = key.value;
                            } else {

                                if (!item[key.value]) {
                                    dataObj[key.value] = 0.00;
                                } else {
                                    dataObj[key.value] = round(item[key.value]["sell_rate"]);
                                    if (item[key.value]["sell_rate"] > 0) {
                                        compMedianValues.push(item[key.value]["sell_rate"]);
                                    }
                                }

                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                data_columns[1].CM = getCompsetMedian(compMedianValues);
                setup_data.push(data_columns);
                count++;
            })
        }
        if (tile === "market_summary") {
            dataKey = "market_total"
            data.map((item, index) => {
                let data_columns = [];
                columns.map(col => {
                    let dataObj = { id: col.id };
                    if (col.type === 'dateColumn') {
                        // dataObj['date_market_summary'] = moment(item.index.date).format('MMM, YYYY');
                        dataObj['month'] = moment(item.index.date).format('MMM, YYYY');
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "difference_sdly") {
                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]);
                                } else {
                                    dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]);

                                }
                            }
                            else if (mainKey === "difference_sdly_percent") {
                                if (key.value !== 'SellRate') {
                                    let diff = round(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]);
                                    let val = (diff * 100) / item[dataKey]["sdly"][key.value];
                                    dataObj[key.value] = val + "%"//round(val);
                                }
                            }
                            else if (mainKey === "total_forecast") {
                                //  dataObj[key.value] = round(item["total_forecast"][rooms_forecast.value]);
                                // key1 = "rooms_forecast";
                                dataObj['Rooms'] = round(item["total_forecast"].rooms_forecast);
                                dataObj['Occ'] = convertToPercent(item["total_forecast"].occ_forecast);
                                dataObj['ADR'] = round(item["total_forecast"].adr_forecast);
                                dataObj['RevPAR'] = round(item["total_forecast"].revpar_forecast);
                                dataObj['Revenue'] = round(item["total_forecast"].revenue_forecast);
                                dataObj['SellRate'] = 0.00;//round(0).toFixed(2);
                                //  debugger;
                            }
                            else if (mainKey === "sdly_actuals") {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey]["sdly_actuals"][key.value]);
                                } else {
                                    dataObj[key.value] = round(item[dataKey]["sdly_actuals"][key.value]);
                                }
                            }

                            else if (mainKey === "difference_forcast_actualSDLY") {
                                dataObj['Rooms'] = round(round(item["total_forecast"].rooms_forecast) - item[dataKey]["sdly_actuals"]['Rooms']);
                                dataObj['Occ'] = convertToPercent((item["total_forecast"].occ_forecast) - item[dataKey]["sdly_actuals"]['Occ']);
                                dataObj['ADR'] = round(round(item["total_forecast"].adr_forecast) - item[dataKey]["sdly_actuals"]['ADR']);
                                dataObj['RevPAR'] = round(round(item["total_forecast"].revpar_forecast) - item[dataKey]["sdly_actuals"]['RevPAR']);
                                dataObj['Revenue'] = round(round(item["total_forecast"].revenue_forecast) - item[dataKey]["sdly_actuals"]['Revenue']);
                                dataObj['SellRate'] = 0.00;//round(0).toFixed(2);
                            }
                            else if (mainKey === "difference_forcast_actualSDLY_percent") {
                                dataObj['Rooms'] = round(((round(item["total_forecast"].rooms_forecast) - item[dataKey]["sdly_actuals"]['Rooms']) * 100 / item[dataKey]["sdly_actuals"]['Rooms'])) + "%";
                                dataObj['Occ'] = round(((round(item["total_forecast"].occ_forecast) - item[dataKey]["sdly_actuals"]['Occ']) * 100 / item[dataKey]["sdly_actuals"]['Occ'])) + "%";
                                dataObj['ADR'] = round(((round(item["total_forecast"].adr_forecast) - item[dataKey]["sdly_actuals"]['ADR']) * 100 / item[dataKey]["sdly_actuals"]['ADR'])) + "%";
                                dataObj['RevPAR'] = round(((round(item["total_forecast"].revpar_forecast) - item[dataKey]["sdly_actuals"]['RevPAR']) * 100 / item[dataKey]["sdly_actuals"]['RevPAR'])) + "%";
                                dataObj['Revenue'] = round(((round(item["total_forecast"].revenue_forecast) - item[dataKey]["sdly_actuals"]['Revenue']) * 100 / item[dataKey]["sdly_actuals"]['Revenue'])) + "%";
                                // dataObj['SellRate']  = round(0).toFixed(2);

                            }

                            else if (mainKey === "sdly") {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey]["sdly"][key.value]).toFixed(2);
                                } else {
                                    dataObj[key.value] = round(item[dataKey]["sdly"][key.value]);
                                }
                                // dataObj[key.value] = round(item[dataKey]["sdly"][key.value]);

                            }
                            else if (item[dataKey][mainKey]) {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]).toFixed(2);
                                    // debugger;
                                } else {
                                    dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                                }
                                // debugger;
                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            })
        }
        if (tile === "hotel_summary") {
            dataKey = "hotel_total"
            data.map((item, index) => {
                let data_columns = [];
                columns.map(col => {
                    let dataObj = { id: col.id };
                    if (col.type === 'dateColumn') {
                        dataObj['date_market_summary'] = moment(item.index.date).format('MMM, YYYY');
                        dataObj['month'] = moment(item.index.date).format('MMM, YYYY');
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "difference_sdly") {
                                if (key.value == 'Occ') {
                                    dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]);
                                } else {
                                    dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]);
                                }
                            }
                            else if (mainKey === "difference_sdly_percent") {

                                if (key.value == 'Occ') {
                                    dataObj[key.value] = convertToPercent((item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]) / item[dataKey]["sdly"][key.value]) + "%";
                                } else if (key.value == 'ADR') {
                                    let adr1 = item[dataKey]["primary"]['Revenue'] / item[dataKey]["primary"]['Rooms'];
                                    let adr2 = item[dataKey]["sdly"]['Revenue'] / item[dataKey]["sdly"]['Rooms'];
                                    dataObj[key.value] = ((adr1 - adr2) / adr2) * 100 + "%";
                                    // dataObj[key.value] = isFinite(adrCalVal) ? adrCalVal+"%" : 0.00 +"%";
                                } else if (key.value == 'Revenue') {
                                    dataObj[key.value] = ((item[dataKey]["primary"]['Revenue'] - item[dataKey]["sdly"]['Revenue']) / item[dataKey]["sdly"]['Revenue']) * 100 + "%"
                                    // dataObj[key.value] = isFinite(revCalVal) ? revCalVal+"%" : 0.00 +"%";
                                } else {
                                    let calVal = ((item[dataKey]["primary"][key.value] - item[dataKey]["sdly"][key.value]) / item[dataKey]["sdly"][key.value]) * 100;
                                    dataObj[key.value] = isFinite(calVal) ? calVal + "%" : 0.00 + "%";
                                }
                            }
                            else if (mainKey === "total_forecast") {
                                //  dataObj[key.value] = round(item["total_forecast"][rooms_forecast.value]);
                                // key1 = "rooms_forecast";
                                dataObj['Rooms'] = round(item["total_forecast"].rooms_forecast);
                                dataObj['Occ'] = convertToPercent(item["total_forecast"].occ_forecast);
                                dataObj['ADR'] = round(item["total_forecast"].adr_forecast);
                                dataObj['RevPAR'] = round(item["total_forecast"].revpar_forecast);
                                dataObj['Revenue'] = round(item["total_forecast"].revenue_forecast);
                                dataObj['SellRate'] = 0.00;//round(0).toFixed(2);
                                //  debugger;
                            }
                            else if (mainKey === "sdly_actuals") {
                                if (key.value == 'Occ') {
                                    dataObj[key.value] = convertToPercent(item[dataKey]["sdly_actual"][key.value]);
                                } else {
                                    dataObj[key.value] = round(item[dataKey]["sdly_actual"][key.value]);
                                }
                            }

                            else if (mainKey === "difference_forcast_actualSDLY") {
                                dataObj['Rooms'] = round(round(item["total_forecast"].rooms_forecast) - item[dataKey]["sdly_actual"]['Rooms']);
                                dataObj['Occ'] = convertToPercent(round(item["total_forecast"].occ_forecast) - item[dataKey]["sdly_actual"]['Occ']);
                                dataObj['ADR'] = round(round(item["total_forecast"].adr_forecast) - item[dataKey]["sdly_actual"]['ADR']);
                                dataObj['RevPAR'] = round(round(item["total_forecast"].revpar_forecast) - item[dataKey]["sdly_actual"]['RevPAR']);
                                dataObj['Revenue'] = round(round(item["total_forecast"].revenue_forecast) - item[dataKey]["sdly_actual"]['Revenue']);
                                dataObj['SellRate'] = 0.00;//round(0).toFixed(2);
                            }
                            else if (mainKey === "difference_forcast_actualSDLY_percent") {
                                dataObj['Rooms'] = round(((round(item["total_forecast"].rooms_forecast) - item[dataKey]["sdly_actual"]['Rooms']) * 100 / item[dataKey]["sdly_actual"]['Rooms'])) + "%";
                                dataObj['Occ'] = round(((round(item["total_forecast"].occ_forecast) - item[dataKey]["sdly_actual"]['Occ']) * 100 / item[dataKey]["sdly_actual"]['Occ'])) + "%";
                                dataObj['ADR'] = round(((round(item["total_forecast"].adr_forecast) - item[dataKey]["sdly_actual"]['ADR']) * 100 / item[dataKey]["sdly_actual"]['ADR'])) + "%";
                                dataObj['RevPAR'] = round(((round(item["total_forecast"].revpar_forecast) - item[dataKey]["sdly_actual"]['RevPAR']) * 100 / item[dataKey]["sdly_actual"]['RevPAR'])) + "%";
                                dataObj['Revenue'] = round(((round(item["total_forecast"].revenue_forecast) - item[dataKey]["sdly_actual"]['Revenue']) * 100 / item[dataKey]["sdly_actual"]['Revenue'])) + "%";
                                dataObj['SellRate'] = round(0).toFixed(2);
                            }

                            else if (mainKey === "sdly") {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey]["sdly"][key.value]).toFixed(2);
                                } else {
                                    dataObj[key.value] = round(item[dataKey]["sdly"][key.value]);
                                }
                                // dataObj[key.value] = round(item[dataKey]["sdly"][key.value]);

                            }
                            else if (item[dataKey][mainKey]) {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]).toFixed(2);
                                    // debugger;
                                } else {
                                    dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                                }
                                // debugger;
                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            })
        }
        //// Forecast Tile data 
        if (tile === "forecast") {
            let otbData = [];
            let otb = dataService.getSeriesById("OSR").data;
            let as_of_date = app_state.as_of_date;
            let startDate = app_state.primary_stay_range.date_from;
            let endDate = app_state.primary_stay_range.date_until;
            let n = Math.abs(moment.duration(moment(as_of_date).diff(moment(endDate))).asDays()) + 1;
            let forcastData = data.slice(0, n);
            let forcastFinalData = (app_state.workspace_controls.segmented_view) ? data : getForecastNonSegmentedData();
            forcastFinalData.map((item, index) => {
                let data_columns = [];
                let m = moment(item.stay_date);
                if (m.isSameOrBefore(endDate) || app_state.workspace_controls.segmented_view) {
                    columns.map((col, idx) => {
                        let dataObj = { id: col.id };
                        if (col.type === 'dateColumn') {
                            dataObj = setUpDate(dataObj, item.stay_date);
                            if (app_state.workspace_controls.segmented_view) {
                                dataObj["segment"] = item.segment;
                            }

                        }
                        if (col.type === 'dataColumn') {
                            let { mainKey } = col;
                            col.subcolumns.map(key => {
                                if ((key.value).toLowerCase() == "rooms") {
                                    dataObj[key.value] = item.predicted_total_cons_rooms;
                                } else if ((key.value).toLowerCase() == "occ") {
                                    dataObj[key.value] = convertToPercent(item.predicted_total_cons_occ);
                                } else if ((key.value).toLowerCase() == "adr") {
                                    dataObj[key.value] = round(item.predicted_total_cons_adr);
                                } else if ((key.value).toLowerCase() == "revpar") {
                                    dataObj[key.value] = round(item.predicted_total_cons_revpar);
                                } else if ((key.value).toLowerCase() == "revenue") {
                                    dataObj[key.value] = round(item.predicted_total_cons_revenue);
                                } else {
                                    dataObj[key.value] = round("0.00");
                                }

                                let userForecastData = [];
                                userForecastData = (dataService.getSeriesById("ManualForecast") && dataService.getSeriesById("ManualForecast").data) ? dataService.getSeriesById("ManualForecast").data.data : [];
                                if (userForecastData && userForecastData.length > 0 && !app_state.workspace_controls.segmented_view) {
                                    if (mainKey === "userforecastdata") {
                                        /// add userForecast Data ///
                                        if (userForecastData && userForecastData.length > 0) {
                                            if ((key.value).toLowerCase() == "rooms") {
                                                dataObj[key.value] = userForecastData[index]['total_forecast']['Rooms'];
                                            } else if ((key.value).toLowerCase() == "occ") {
                                                dataObj[key.value] = (userForecastData[index]['total_forecast']['Occ%']);
                                            } else if ((key.value).toLowerCase() == "adr") {
                                                dataObj[key.value] = round(userForecastData[index]['total_forecast'].ADR);
                                            } else if ((key.value).toLowerCase() == "revpar") {
                                                dataObj[key.value] = round(userForecastData[index]['total_forecast'].RevPAR);
                                            } else if ((key.value).toLowerCase() == "revenue") {
                                                dataObj[key.value] = round(userForecastData[index]['total_forecast'].Revenue);
                                            }
                                        }
                                    }
                                    if (mainKey === "difference") {
                                        /// add userForecast Data ///
                                        if ((key.value).toLowerCase() == "rooms") {
                                            dataObj[key.value] = item.predicted_total_cons_rooms - userForecastData[index]['total_forecast']['Rooms'];
                                        } else if ((key.value).toLowerCase() == "occ") {
                                            dataObj[key.value] = convertToPercent(item.predicted_total_cons_occ) - (userForecastData[index]['total_forecast']['Occ%']);
                                        } else if ((key.value).toLowerCase() == "adr") {
                                            dataObj[key.value] = round(item.predicted_total_cons_adr - userForecastData[index]['total_forecast'].ADR);
                                        } else if ((key.value).toLowerCase() == "revpar") {
                                            dataObj[key.value] = round(item.predicted_total_cons_revpar) - round(userForecastData[index]['total_forecast'].RevPAR);
                                        } else if ((key.value).toLowerCase() == "revenue") {
                                            dataObj[key.value] = round(item.predicted_total_cons_revenue) - round(userForecastData[index]['total_forecast'].Revenue);
                                        }

                                    }
                                } else {


                                    if (mainKey === "userforecastdata") {
                                        /// add userForecast Data ///
                                        if (userForecastData && userForecastData.length > 0) {
                                            if ((key.value).toLowerCase() == "rooms") {
                                                dataObj[key.value] = 0;
                                            } else if ((key.value).toLowerCase() == "occ") {
                                                dataObj[key.value] = 0.00;
                                            } else if ((key.value).toLowerCase() == "adr") {
                                                dataObj[key.value] = 0.00;
                                            } else if ((key.value).toLowerCase() == "revpar") {
                                                dataObj[key.value] = 0.00;
                                            } else if ((key.value).toLowerCase() == "revenue") {
                                                dataObj[key.value] = 0.00;
                                            }
                                        }
                                    }
                                    if (mainKey === "difference") {
                                        /// add userForecast Data ///
                                        if ((key.value).toLowerCase() == "rooms") {
                                            dataObj[key.value] = item.predicted_total_cons_rooms;
                                        } else if ((key.value).toLowerCase() == "occ") {
                                            dataObj[key.value] = convertToPercent(item.predicted_total_cons_occ);
                                        } else if ((key.value).toLowerCase() == "adr") {
                                            dataObj[key.value] = round(item.predicted_total_cons_adr);
                                        } else if ((key.value).toLowerCase() == "revpar") {
                                            dataObj[key.value] = round(item.predicted_total_cons_revpar);
                                        } else if ((key.value).toLowerCase() == "revenue") {
                                            dataObj[key.value] = round(item.predicted_total_cons_revenue);
                                        }

                                    }



                                }


                            })
                        }

                        data_columns.push(dataObj);
                    })
                }
                setup_data.push(data_columns);
            })
        }
        // Market Sell Rate ///
        if (tile === "market_sell_rates") {
            let tempdata = [];
            let count = 1;
            let m = 0;
            setup_data = [...getMarketSellRatesData(data, columns, app_state)];
        }
        if (tile === 'pricing') {
            let pricingData = getPricingDataFormatted(app_state);
            let pricingDataRoomType = getPricingDataFormatted(app_state, true)
            let otbData = (dataService.getSeriesById("OSR").data) ? dataService.getSeriesById("OSR").data.data : [];
            const foreCastData = getForecastNonSegmentedDataNew();
            const resData = getRestrictionsFormatted(app_state, "cur")

            let date = moment(startDate);
            for (let i = 0; i < tableLength; i++) {
                const data_columns = [];
                let baseRoomSellRate = 0;
                columns.map(col => {
                    let dataObj = {};
                    let data = [];

                    if (col.id === "otb" || col.id === "date") {
                        data = otbData
                    }
                    else if (col.id === "forecast") {
                        data = foreCastData;
                    } else if (col.id === "pricing") {
                        data = pricingData
                    } else if (col.id === "restrictions") {

                        data = resData;
                    } else if (col.id.includes("roomtype_pricing_")) {
                        data = pricingDataRoomType;

                    }
                    col.subcolumns.map(sub_col => {
                        if (col.type === 'dateColumn') {
                            if (sub_col.subcol_id === 'date') {
                                dataObj['date'] = date.format("YYYY-MM-DD");

                            } else if (sub_col.subcol_id === 'dow') {
                                dataObj['dow'] = date.format("ddd");

                            }
                            if (app_state.workspace_controls.segmented_view) {
                                // dataObj["segment"] = item.index.segment;
                            }
                        } else {
                            let key = sub_col.subcol_id.replace(col.id + "_", "");
                            let dPoint = "";

                            if (col.id === "otb" && data[i]) {
                                dPoint = data[i]["HotelTotal"]["primary"][key];


                            }
                            if (col.id === "forecast" && data[i]) {
                                dPoint = data[i]["HotelTotal"]["primary"][key];

                            }
                            if (col.id == "restrictions" && data[i]) {
                                dPoint = data[i];
                            }
                            if (col.id === "pricing" && data[i]) {
                                if (key === "Sold" && otbData[i]) {
                                    dPoint = otbData[i]["HotelTotal"]["primary"]["Rooms"];
                                }
                                if (key === 'SellRate' && otbData[i]) {
                                    dPoint = otbData[i]["HotelTotal"]["primary"]["SellRate"]
                                }
                                if (moment(date).diff(app_state.as_of_date, 'days') >= 0) {
                                    if (key == "ratediff") {
                                        dPoint = data[i]["RecSellRate"] - data[i]["SellRate"]
                                    }
                                    else if (key === 'SellRate' || key === "RecSellRate") {
                                        dPoint = data[i][key];
                                    }
                                    if (key == "Explainer") {
                                        dPoint = data[i][key];
                                    }
                                } else {
                                    if (key == "ratediff" || key === "RecSellRate") {
                                        dPoint = "-";
                                    }
                                    if (key == "Explainer") {
                                        dPoint = false;
                                    }

                                }

                            }
                            if (col.id.includes("roomtype_pricing_") && col.room_code) {
                                if (col.room_code && data[i] && data[i][col.room_code]) {
                                    if (col.isBase === true) {
                                        baseRoomSellRate = data[i][col.room_code]["SellRate"];
                                    }
                                    if (sub_col.subcol_id === "current_differential") {
                                        dPoint = data[i][col.room_code]["SellRate"] - baseRoomSellRate;
                                    }
                                    else if (sub_col.subcol_id === "SellRate") {
                                        dPoint = data[i][col.room_code]["SellRate"];
                                    }
                                    else if (sub_col.subcol_id === "RecSellRate") {
                                        dPoint = data[i][col.room_code]["RecSellRate"];
                                    }
                                    else if (sub_col.subcol_id === "lodgiq_differential") {
                                        dPoint = data[i][col.room_code]["lodgiq_differential"];
                                    }
                                    else if (sub_col.subcol_id === "Sold") {
                                        dPoint = data[i][col.room_code]["rooms_sold"];
                                        if (isNaN(data[i][col.room_code]["rooms_sold"])) {
                                            dPoint = 0;
                                        }
                                    }


                                } else {
                                    dPoint = "";
                                }
                            }
                            if (sub_col.type === "percent") {
                                dPoint = convertToPercent(dPoint);
                            }
                            dataObj[key] = dPoint;
                        }
                    })
                    dataObj.id = col.id;
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
                date.add(1, 'days');
            }
        }
        if (tile === "flights") {
            setup_data = [...getFlightsData(data, columns, app_state)];
        }
        if (tile === 'restrictions') {
            // debugger;
            let date = moment(startDate);
            if (workspace_controls.isRestrictionsRoomType) {
                data.forEach((item, index) => {
                    // debugger;
                    const data_columns = [];
                    columns.forEach(col => {
                        let dataObj = {};
                        col.subcolumns.forEach(sub_col => {
                            if (col.type === 'dateColumn') {
                                if (sub_col.type === 'date') {
                                    dataObj['date'] = moment(item.index.date).format("YYYY-MM-DD");

                                }
                                if (sub_col.type === 'dow') {
                                    dataObj['dow'] = moment(item.index.date).format("ddd");
                                }
                                dataObj['id'] = sub_col.parent;
                            } else {
                                const key = col.room_type + sub_col.value;
                                let mainKey = col.mainKey;
                                let dPoint = 0;
                                if (sub_col.parent === "bar_pricing") {
                                    dPoint = item[sub_col.parent] && item[sub_col.parent][col.room_type] ? item[sub_col.parent][col.room_type][sub_col.value] : null;
                                } else {
                                    dPoint = item[mainKey] && item[mainKey][col.room_type] ? item[mainKey][col.room_type][sub_col.value] : null;
                                }


                                if (sub_col.type === "percent") {
                                    dPoint = convertToPercent(dPoint);
                                }
                                dataObj[sub_col.value] = dPoint;
                                dataObj['id'] = col.id;
                            }
                        });
                        data_columns.push(dataObj);
                    })
                    setup_data.push(data_columns);
                })
            } else {
                data.forEach((item, index) => {
                    // debugger;
                    let data_columns = [];
                    columns.forEach(col => {
                        let dataObj = {};
                        // let data = [];
                        col.subcolumns.forEach(sub_col => {

                            if (col.type === 'dateColumn') {
                                if (sub_col.type === 'date') {
                                    dataObj['date'] = moment(item.index.date).format("YYYY-MM-DD");

                                }
                                if (sub_col.type === 'dow') {
                                    dataObj['dow'] = moment(item.index.date).format("ddd");
                                }
                                dataObj['id'] = sub_col.parent;
                            } else {

                                let key = sub_col.subcol_id.replace(col.id + "_", "");
                                const mainKey = col.mainKey;
                                if (mainKey === "bar_pricing") {

                                }
                                // if(mainKey==='restrictions')  debugger; 
                                let dPoint = item[mainKey] && item[mainKey][key] ? item[mainKey][key] : 0;
                                if (sub_col.type === "percent") {
                                    dPoint = convertToPercent(dPoint);
                                }
                                dataObj[key] = dPoint;
                                dataObj['id'] = sub_col.parent;
                            }
                        })
                        data_columns.push(dataObj);
                    })
                    setup_data.push(data_columns);
                });
            }

        }

        if (tile === "budget") {
            setup_data = [...getBudgetData(data, columns, app_state)];
        }

    }

    return setup_data;
}

const otbRestrictionsCols = [{
    display: "Restrictions",
    value: "Restrictions"
}];

const otbPickupSubcolumns = [{
    display: "Rooms",
    value: "Rooms"
}, {
    display: "OCC",
    value: "Occ"
}, {
    display: "ADR",
    value: "ADR"
}, {
    display: "RevPAR",
    value: "RevPAR"
}, {
    display: "Revenue",
    value: "Revenue"
},
{
    display: "SellRate",
    value: "SellRate"
}];
const otbPickupVarianceSubcolumns = [{
    display: "Rooms",
    value: "Rooms"
}, {
    display: "OCC",
    value: "Occ"
}, {
    display: "ADR",
    value: "ADR"
}, {
    display: "RevPAR",
    value: "RevPAR"
}, {
    display: "Revenue",
    value: "Revenue"
}];
const forecastSubcolumns = [
    {
        display: "Rooms",
        value: "Rooms"
    },
    {
        display: "Occ",
        value: "Occ"
    },
    {
        display: "ADR",
        value: "ADR"
    },
    {
        display: "RevPAR",
        value: "RevPAR"
    }, {
        display: "Revenue",
        value: "Revenue"
    },
];
const marketSubColumns = [{
    display: "Rooms",
    value: "Rooms"
}, {
    display: "OCC",
    value: "Occ"
}, {
    display: "ADR",
    value: "ADR"
}, {
    display: "RevPAR",
    value: "RevPAR"
}, {
    display: "Revenue",
    value: "Revenue"
},
{
    display: "SellRate",
    value: "SellRate"
}];

const marketSellRateColumns = [
    {
        display: "Avg Rate",
        value: "avg"
    }];

const marketSummarySubColumns = [{
    display: "Rooms Sold",
    value: "Rooms"
}, {
    display: "OCC",
    value: "Occ"
}, {
    display: "ADR",
    value: "ADR"
}, {
    display: "RevPAR",
    value: "RevPAR"
}, {
    display: "Revenue",
    value: "Revenue"
},
{
    display: "Sell Rate",
    value: "SellRate"
}];
const variancePercentSubColumns = [{
    display: "Rooms Sold",
    value: "Rooms"
}, {
    display: "OCC",
    value: "Occ"
}, {
    display: "ADR",
    value: "ADR"
}, {
    display: "RevPAR",
    value: "RevPAR"
}, {
    display: "Revenue",
    value: "Revenue"
}];
const hotelSummarySubColumns = [{
    display: "Rooms Sold",
    value: "Rooms"
}, {
    display: "OCC",
    value: "Occ"
}, {
    display: "ADR",
    value: "ADR"
}, {
    display: "RevPAR",
    value: "RevPAR"
}, {
    display: "Revenue",
    value: "Revenue"
},
{
    display: "Sell Rate",
    value: "SellRate"
}];

const bookingCurveSubColumns = [{
    display: "Rooms Sold",
    value: "Rooms"
}, {
    display: "OCC",
    value: "Occ"
}, {
    display: "ADR",
    value: "ADR"
}, {
    display: "RevPAR",
    value: "RevPAR"
}, {
    display: "Revenue",
    value: "Revenue"
},
{
    display: "SellRate",
    value: "SellRate"
}];

export function processColumns(data, { auth, app_state, ui_state }) {
    const columns = [];
    const { workspace_controls, as_of_date } = app_state;
    const { sdlyOn, pickUpType } = workspace_controls;
    const { active_tiles } = ui_state;
    let startDate = app_state.primary_stay_range.date_from;
    let endDate = app_state.primary_stay_range.date_until;
    let yearArr = getList(startDate, endDate);
    let firstTile = active_tiles[0];
    if (firstTile === "market_summary" || firstTile === "hotel_summary") {
        columns.push({
            type: "dateColumn",
            colspan: "1",
            display: yearArr.join(" - "),
            subcolumns: [{
                display: "Month",
                value: "Month"
            }],
            backgroundColor: "grey"
        })
    } else {
        if ((firstTile === "compset" || firstTile === "market")
            && (app_state.workspace_controls.scopeType === 'Dba')) {
            columns.push({
                type: "dateColumn",
                colspan: "1",
                display: yearArr.join(" - "),
                subcolumns: [{
                    display: "DBA",
                    value: "DBA"
                }],
                backgroundColor: "grey"
            })
        } else if (firstTile === "pace") {
            let subColSegmentObj = { display: "SEGMENT", value: "segment" };
            let subColDbaObj = { display: "DBA", value: "DBA" };
            let subCols = [subColDbaObj];
            if (app_state.workspace_controls.segmented_view) {
                subCols.push(subColSegmentObj);
            }
            // debugger;
            columns.push({
                type: "dateColumn",
                colspan: "1",
                display: yearArr.join(" - "),
                subcolumns: subCols,
                backgroundColor: "grey"
            })
        } else if (firstTile === "compset") {
            let subColDateObj = { display: "Date", value: "date" };
            let subColDowObj = { display: "DOW", value: "dow" };
            let subColSegmentObj = { display: "SEGMENT", value: "segment" }
            let subColArr = [subColDateObj, subColDowObj];
            // (app_state.workspace_controls.segmented_view) ? subColArr.push(subColSegmentObj) : subColArr.splice(2, 1);
            columns.push({
                type: "dateColumn",
                colspan: "2",
                display: yearArr.join(" - "),
                subcolumns: subColArr,
                backgroundColor: "grey"
            })
        } else if (active_tiles[0] === "market_sell_rates") {
            let subColDateObj = { display: "Date", value: "date" };
            let subColDowObj = { display: "DOW", value: "dow" };
            let subColSegmentObj = { display: "SEGMENT", value: "segment" }
            let subColArr = [subColDateObj, subColDowObj];
            // (app_state.workspace_controls.segmented_view) ? subColArr.push(subColSegmentObj) : subColArr.splice(2, 1);
            columns.push({
                type: "dateColumn",
                colspan: "2",
                display: yearArr.join(" - "),
                subcolumns: subColArr,
                backgroundColor: "grey"
            })
        } else {

            let subColDateObj = { display: "Date", value: "date" };
            let subColDowObj = { display: "DOW", value: "dow" };
            let subColSegmentObj = { display: "SEGMENT", value: "segment" }
            let subColArr = [subColDateObj, subColDowObj];
            (app_state.workspace_controls.segmented_view) ? subColArr.push(subColSegmentObj) : subColArr.splice(2, 1);
            columns.push({
                type: "dateColumn",
                colspan: "2",
                display: yearArr.join(" - "),
                subcolumns: subColArr,
                backgroundColor: "grey"
            })
        }
    }
    /////

    ///
    for (let index = 0; index < active_tiles.length; index++) {
        const tile = active_tiles[index];
        let restrictions = getAppData(auth, app_state).restrictions.enable;
        let displayTitle = "";
        if (tile === "otb") {
            displayTitle = "On The Books ";
            columns.push({
                type: "dataColumn",
                display: displayTitle + moment(as_of_date).format("MM/DD/YYYY"),
                subcolumns: otbPickupSubcolumns,
                backgroundColor: "lightblue",
                mainKey: "primary"
            });

            if (restrictions) {
                columns.push({
                    type: "dataColumn",
                    display: "",
                    subcolumns: otbRestrictionsCols,
                    backgroundColor: "lightblue",
                    mainKey: "restrictions"
                });
            }

            if (sdlyOn === true) {
                addSdlyColumns(tile, as_of_date, columns)
            }
        }
        if (tile === 'pricing') {
            const { table_columns } = TILES['pricing'].tableConfig;
            table_columns.map(col => {
                displayTitle = col.display
                if (col.type === 'dataColumn') {
                    columns.push({
                        type: "dataColumn",
                        series: col.series,
                        id: col.id,
                        display: displayTitle,
                        subcolumns: col.subcolumns.map(sub_col => {
                            return {
                                display: sub_col.display,
                                value: sub_col.subcol_id,
                                ...sub_col,
                            }
                        }),
                        backgroundColor: "lightblue",
                    });
                }
            })
        }
        if (tile === "forecast") {
            displayTitle = "System Forecast As Of - ";
            columns.push({
                type: "dataColumn",
                display: displayTitle + moment(as_of_date).format("MM/DD/YYYY"),
                subcolumns: forecastSubcolumns,
                backgroundColor: "lightblue",
                mainKey: "primary"
            });
        }
        if (tile === "pickup") {
            displayTitle = "OTB";
            let mainKey = 'current';
            let pickupDays = app_state.workspace_controls.pickUpDays;
            let sdlyPickupDays = 364 + parseInt(pickupDays);
            // debugger;
            if (app_state.workspace_controls.pickUpType === 'Standard') {
                mainKey = 'prior'
            }
            columns.push({
                type: "dataColumn",
                display: "Pickup",
                subcolumns: otbPickupSubcolumns,
                backgroundColor: "lightblue",
                mainKey: "otb_difference"
            });
            columns.push({
                type: "dataColumn",
                display: displayTitle + " - " + moment(as_of_date).format("MM/DD/YYYY"),
                subcolumns: otbPickupSubcolumns,
                backgroundColor: "lightblue",
                mainKey: mainKey
            });
            columns.push({
                type: "dataColumn",
                display: displayTitle + " - " + moment(as_of_date).subtract(pickupDays, "days").format("MM/DD/YYYY"),
                subcolumns: otbPickupSubcolumns,
                backgroundColor: "lightblue",
                mainKey: 'otb_pickup'
            });
            if (sdlyOn === true) {
                columns.push({
                    type: "dataColumn",
                    display: "Pickup sdow last year - " + moment(as_of_date).subtract(364, 'days').format("MM/DD/YYYY"),
                    subcolumns: otbPickupSubcolumns,
                    backgroundColor: "lightblue",
                    mainKey: "otb_sdly_diff"
                });
                columns.push({
                    type: "dataColumn",
                    display: displayTitle + " - SDLY - " + moment(as_of_date).subtract(364, 'days').format("MM/DD/YYYY"),
                    subcolumns: otbPickupSubcolumns,
                    backgroundColor: "lightblue",
                    mainKey: "sdly"
                });
                columns.push({
                    type: "dataColumn",
                    display: displayTitle + " - SDLY - " + moment(as_of_date).subtract(sdlyPickupDays, 'days').format("MM/DD/YYYY"),
                    subcolumns: otbPickupSubcolumns,
                    backgroundColor: "lightblue",
                    mainKey: "sdly_pickup"
                });
            }
        }
        if (tile === "compset") {
            const subcolumns = [{
                display: "Compset Median",
                value: 'CM',
            }];
            const firstData = data[0] ? data[0] : {};
            Object.keys(firstData).map(key => {
                if (key !== 'Avg' && key !== 'Total' && key !== 'index' && key !== 'Index') {
                    let name = key.split("|");
                    subcolumns.push({
                        display: name[0].replaceAll("compset_", ""),
                        value: key,
                    })
                }
            })
            columns.push({
                type: "dataColumn",
                display: "Compset",
                subcolumns,
                backgroundColor: "lightblue"
            })
        }

        if (tile === "pace") {
            columns.push({
                type: "dataColumn",
                display: "Booking Curve",
                subcolumns: bookingCurveSubColumns,
                backgroundColor: "lightblue",
                mainKey: "primary"
            });
            if (sdlyOn === true) {
                columns.push({
                    type: "dataColumn",
                    display: "Comparision Pace",
                    subcolumns: bookingCurveSubColumns,
                    backgroundColor: "lightblue",
                    mainKey: "pacesdly"
                });
                columns.push({
                    type: "dataColumn",
                    display: "Difference",
                    subcolumns: bookingCurveSubColumns,
                    backgroundColor: "lightblue",
                    mainKey: "pacesdlydiff"
                });
            }
            if (app_state.workspace_controls.compareTo) {
                // debugger;
                columns.push({
                    type: "dataColumn",
                    display: "Comparision Pace",
                    subcolumns: bookingCurveSubColumns,
                    backgroundColor: "lightblue",
                    mainKey: "comparePace"
                });
                columns.push({
                    type: "dataColumn",
                    display: "Difference",
                    subcolumns: bookingCurveSubColumns,
                    backgroundColor: "lightblue",
                    mainKey: "compareDiffPace"
                });

            }
        }

        if (tile === "market_sell_rates") {
            const subcolumns = [];
            const dataSet = data[0] ? data[0] : {};
            // columns.push({
            //     type: "dataColumn",
            //     display: "3 Star",
            //     subcolumns: marketSellRateColumns,
            //     backgroundColor: "lightblue",
            //     mainKey: "sda"
            // });


            if (dataSet.info && dataSet.info != "") {
                columns.push({
                    type: "dataColumn",
                    display: "3 Star",
                    subcolumns: marketSellRateColumns,
                    backgroundColor: "lightblue",
                    mainKey: "sets"
                });
                columns.push({
                    type: "dataColumn",
                    display: "4 Star",
                    subcolumns: marketSellRateColumns,
                    backgroundColor: "lightblue",
                    mainKey: "sets"
                });
                columns.push({
                    type: "dataColumn",
                    display: "5 Star",
                    subcolumns: marketSellRateColumns,
                    backgroundColor: "lightblue",
                    mainKey: "sets"
                });
            } else {
                Object.keys(dataSet.sets).map(key => {
                    if (key !== 'total') {
                        columns.push({
                            type: "dataColumn",
                            display: key.replaceAll('_', " "),
                            k: key,
                            subcolumns: marketSellRateColumns,
                            backgroundColor: "lightblue",
                            mainKey: "sets"
                        });
                    }
                })
            }


        }

        if (tile === "market") {
            if (app_state.workspace_controls.scopeType === 'Dba') {
                columns.push({
                    type: "dataColumn",
                    display: "Market Pace",
                    subcolumns: marketSubColumns,
                    backgroundColor: "lightblue",
                    mainKey: "primary"
                });
                columns.push({
                    type: "dataColumn",
                    display: "SDLY Comparision Market Pace",
                    subcolumns: marketSubColumns,
                    backgroundColor: "lightblue",
                    mainKey: "sdly_dba_comp"
                });
                columns.push({
                    type: "dataColumn",
                    display: "Variance",
                    subcolumns: marketSubColumns,
                    backgroundColor: "lightblue",
                    mainKey: "dba_variance"
                });
            } else {
                displayTitle = "Market OTB ";
                columns.push({
                    type: "dataColumn",
                    display: displayTitle + moment(as_of_date).format("MM/DD/YYYY"),
                    subcolumns: marketSubColumns,
                    backgroundColor: "lightblue",
                    mainKey: "primary"
                });
                columns.push({
                    type: "dataColumn",
                    display: displayTitle + moment(as_of_date).subtract(1, 'days').format("MM/DD/YYYY"),
                    subcolumns: marketSubColumns,
                    backgroundColor: "lightblue",
                    mainKey: "pickup"
                });
                columns.push({
                    type: "dataColumn",
                    display: displayTitle + " Pickup",
                    subcolumns: marketSubColumns,
                    backgroundColor: "lightblue",
                    mainKey: "marketotbpickup"
                });

                if (sdlyOn === true) {
                    columns.push({
                        type: "dataColumn",
                        display: "Market Sdly ",
                        subcolumns: marketSubColumns,
                        backgroundColor: "lightblue",
                        mainKey: "sdly"
                    });
                    columns.push({
                        type: "dataColumn",
                        display: "Difference to Market sdow ly ",
                        subcolumns: marketSubColumns,
                        backgroundColor: "lightblue",
                        mainKey: "sdly_diff_sdowly"
                        // mainKey: "sdly"
                    });
                    columns.push({
                        type: "dataColumn",
                        display: "Variance to Market sdow ly ",
                        subcolumns: marketSubColumns,
                        backgroundColor: "lightblue",
                        mainKey: "sdly_variance_sdowly"
                        // mainKey: "sdly"
                    });
                    columns.push({
                        type: "dataColumn",
                        display: "Sdly Actual ",
                        subcolumns: marketSubColumns,
                        backgroundColor: "lightblue",
                        mainKey: "sdly_actuals"
                    });
                    columns.push({
                        type: "dataColumn",
                        display: "Difference to Market Last Yeat Actual ",
                        subcolumns: marketSubColumns,
                        backgroundColor: "lightblue",
                        mainKey: "sdly_diff_market_last_year_actuals"
                    });
                    columns.push({
                        type: "dataColumn",
                        display: "Variance to Market Last Yeat Actual ",
                        subcolumns: marketSubColumns,
                        backgroundColor: "lightblue",
                        mainKey: "sdly_variance_market_last_year_actuals"
                    });

                }
                columns.push({
                    type: "dataColumn",
                    display: "Market Projected - " + moment(as_of_date).format("MM/DD/YYYY"),
                    subcolumns: marketSubColumns,
                    backgroundColor: "lightblue",
                    mainKey: "market_projected"
                });
            }

        }

        if (tile === "market_summary") {
            displayTitle = "Market Monthly Summary ";
            columns.push({
                type: "dataColumn",
                display: "OTB As Of " + moment(as_of_date).format("MM/DD/YYYY"),
                subcolumns: marketSummarySubColumns,
                backgroundColor: "lightblue",
                mainKey: "primary"
            });
            addMarketSdlyColumns(tile, as_of_date, columns)
        }
        if (tile === "hotel_summary") {
            displayTitle = "Hotel Monthly Summary ";
            columns.push({
                type: "dataColumn",
                display: "OTB As Of " + moment(as_of_date).format("MM/DD/YYYY"),
                subcolumns: hotelSummarySubColumns,
                backgroundColor: "lightblue",
                mainKey: "primary"
            });
            addHotelSdlyColumns(tile, as_of_date, columns)
        }
    }
    return columns;
}


// sub columns for Hotel Summary tile 

function addHotelSdlyColumns(tile, as_of_date, columns) {
    let displayTitle = "OTB AS OF ";

    columns.push({
        type: "dataColumn",
        display: displayTitle + "Sdly " + moment(as_of_date).subtract(364, 'days').format("MM/DD/YYYY"),
        subcolumns: marketSummarySubColumns,
        backgroundColor: "lightblue",
        mainKey: "sdly"
    });
    columns.push({
        type: "dataColumn",
        display: "Variance",
        subcolumns: marketSummarySubColumns,
        backgroundColor: "lightblue",
        mainKey: "difference_sdly"
    });
    columns.push({
        type: "dataColumn",
        display: "Variance %",
        subcolumns: variancePercentSubColumns,
        backgroundColor: "lightblue",
        mainKey: "difference_sdly_percent"
    });
    columns.push({
        type: "dataColumn",
        display: "System Forcast As Of " + moment(as_of_date).subtract(364, 'days').format("MM/DD/YYYY"),
        subcolumns: marketSummarySubColumns,
        backgroundColor: "lightblue",
        mainKey: "total_forecast"
    });
    columns.push({
        type: "dataColumn",
        display: "SDLY Actual",
        subcolumns: marketSummarySubColumns,
        backgroundColor: "lightblue",
        mainKey: "sdly_actuals"
    });
    columns.push({
        type: "dataColumn",
        display: "Variance",
        subcolumns: marketSummarySubColumns,
        backgroundColor: "lightblue",
        mainKey: "difference_forcast_actualSDLY"
    });
    columns.push({
        type: "dataColumn",
        display: "Variance %",
        subcolumns: variancePercentSubColumns,
        backgroundColor: "lightblue",
        mainKey: "difference_forcast_actualSDLY_percent"
    });
    return columns
}


// End columns for hotel Summary tile


function addMarketSdlyColumns(tile, as_of_date, columns) {
    let displayTitle = "OTB AS OF ";

    columns.push({
        type: "dataColumn",
        display: displayTitle + "Sdly " + moment(as_of_date).subtract(364, 'days').format("MM/DD/YYYY"),
        subcolumns: marketSummarySubColumns,
        backgroundColor: "lightblue",
        mainKey: "sdly"
    });
    columns.push({
        type: "dataColumn",
        display: "Variance",
        subcolumns: marketSummarySubColumns,
        backgroundColor: "lightblue",
        mainKey: "difference_sdly"
    });
    columns.push({
        type: "dataColumn",
        display: "Variance %",
        subcolumns: variancePercentSubColumns,
        backgroundColor: "lightblue",
        mainKey: "difference_sdly_percent"
    });
    columns.push({
        type: "dataColumn",
        display: "System Forcast As Of " + moment(as_of_date).subtract(364, 'days').format("MM/DD/YYYY"),
        subcolumns: marketSummarySubColumns,
        backgroundColor: "lightblue",
        mainKey: "total_forecast"
    });
    columns.push({
        type: "dataColumn",
        display: "SDLY Actual",
        subcolumns: marketSummarySubColumns,
        backgroundColor: "lightblue",
        mainKey: "sdly_actuals"
    });
    columns.push({
        type: "dataColumn",
        display: "Variance",
        subcolumns: marketSummarySubColumns,
        backgroundColor: "lightblue",
        mainKey: "difference_forcast_actualSDLY"
    });
    columns.push({
        type: "dataColumn",
        display: "Variance %",
        subcolumns: variancePercentSubColumns,
        backgroundColor: "lightblue",
        mainKey: "difference_forcast_actualSDLY_percent"
    });
    return columns
}


function addSdlyColumns(tile, as_of_date, columns) {
    let displayTitle = "";
    if (tile === "otb") {
        displayTitle = "On The Books ";
    }

    columns.push({
        type: "dataColumn",
        display: displayTitle + "Sdly " + moment(as_of_date).subtract(364, 'days').format("MM/DD/YYYY"),
        subcolumns: otbPickupSubcolumns,
        backgroundColor: "lightblue",
        mainKey: "sdly"
    });
    columns.push({
        type: "dataColumn",
        display: "Difference to Sdly",
        subcolumns: otbPickupSubcolumns,
        backgroundColor: "lightblue",
        mainKey: "difference_sdly"
    });
    columns.push({
        type: "dataColumn",
        display: "Variance to Sdly",
        subcolumns: otbPickupVarianceSubcolumns,
        backgroundColor: "lightblue",
        mainKey: "variance_sdly"
    });
    columns.push({
        type: "dataColumn",
        display: "SDLY Actual",
        subcolumns: otbPickupSubcolumns,
        backgroundColor: "lightblue",
        mainKey: "sdly_actual"
    });
    columns.push({
        type: "dataColumn",
        display: "Remaining Pickup for Sdly",
        subcolumns: otbPickupSubcolumns,
        backgroundColor: "lightblue",
        mainKey: "pickup_diff_sdly"
    });
    return columns
}