import React, { useEffect, useState, useRef } from 'react'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { getOptions, getMergedTilesOptions } from "./chartlogic"
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import { transformData, getMergedData, getMergedDataIntellimerge } from './functions';
import {
  getTileById, getCompsetMedian,
  getResourceIdByPropId, getSeriesById,
  deepCopy, getFromState, getTransformedSegments,
  aggregateObject
} from '../../app/util';
import '../../simple-grid.css';
import './chart-view.css';
import moment from 'moment';
import { getPropertyInfo, restrictionsAvailable } from '../../actions/appstate';

function ChartIntellimerge({ compDisplayData }) {
  // debugger;
  const dispatch = useDispatch();
  const state = useSelector(appState => appState);
  const { property_details } = useSelector(state => state.auth.profile);
  const { app_state, ui_state } = useSelector(state => state.app.current_state);
  const { active_tiles } = ui_state;
  const { workspace_controls, property_id } = app_state;
  const { sdlyOn, segmented_view, pickUpType, clearComarison } = workspace_controls;
  const [displayData, setDisplayData] = useState([]);
  const [compLoad, setCompLoad] = useState(false);
  const [options, setOptions] = useState({});
  const propertyInfo = getPropertyInfo(property_details, property_id);
  const restrictionsAvail = restrictionsAvailable(propertyInfo)
  const resource_id = getResourceIdByPropId(property_id, property_details);
  let segments = null

  let inventory = getFromState('system_settings>inventory_settings', state);
  if (!inventory) console.error("inventory_settings is not available for this property");
  const { total_capacity } = inventory && inventory.current ? inventory.current : {};

  if (segmented_view) {
    // debugger
    let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
    let segment_ignore_codes = getFromState('system_settings>market_code_ignorelist', state);
    segments = getTransformedSegments(marketSegments, segment_ignore_codes);
    // debugger;   
  }

  let compRef = useRef({
    compLoad: false
  });

  useEffect(() => {
    // debugger;
    let isSubscribed = true;
    if (isSubscribed && compRef.current.compLoad) {
      let param = {
        ...app_state.primary_stay_range,
        ...app_state.workspace_controls,
        active_api: app_state.active_api,
        ...ui_state,
        resource_id,
        clearComarison,
        segmented_view,
        workspace_controls:app_state.workspace_controls,
        pickUpType,
        segments,
        total_capacity,
        restrictionsAvail,
        hasUserForecastData: false
      };
      //Set true if user forecast is not blank
      if (ui_state.active_tiles.includes('forecast')) {
        let userForecast = getSeriesById('ManualForecast').data;
        if (userForecast && userForecast.data && userForecast.data.length) {
          param.hasUserForecastData = true;
        }
      }
      let chartOptions = getMergedTilesOptions(displayData, ui_state, app_state, param);
      // debugger;  
      // console.log('chartOptions:', chartOptions);
      setOptions({ options, ...chartOptions });
    }

    return () => (isSubscribed = false)
  }, [compLoad]);

  useEffect(() => {
    let isSubscribed = true;

    if (!ui_state.showspinner && !compRef.current.compLoad) {
      // debugger;
      if (compDisplayData && (compDisplayData.constructor.name === 'Array' && compDisplayData.length)) {
        let mergedData = {};
        let transformedData = [];
        try {
        
          mergedData = getMergedDataIntellimerge(compDisplayData,
            { ui_state: ui_state, app_state: app_state, segments, restrictionsAvail });
          compRef.current.compLoad = true;
          //Added to show user forecast if data available
          if (!workspace_controls.segmented_view && active_tiles.includes('forecast') && mergedData.forecast_aggregated) {
            let userForecast = getSeriesById('ManualForecast').data;
            if (userForecast && userForecast.data && userForecast.data.length) {
              let totalKey = 'HotelTotal';
              mergedData.forecast_aggregated.forEach((item) => {
                let manualForecast = userForecast.data.find(data => new moment(item.index.date).isSame(data.index.date, 'day'))
                if (manualForecast) {
                  let dateData = deepCopy(manualForecast.total_forecast);
                  dateData.Occ = dateData['Occ%'] / 100;
                  delete dateData['Occ%'];
                  item[totalKey].userforecastdata = dateData;
                }
              });
            }

          } else if (workspace_controls.segmented_view && active_tiles.includes('forecast') && mergedData.forecast_aggregated) {
            let totalKey = 'HotelTotal';
            mergedData.forecast_aggregated.forEach((item) => {
              delete item[totalKey].userforecastdata;

            });
          }

          
          setDisplayData(mergedData);
          setCompLoad(true);
        } catch (e) {
          console.warn("Code Block Failed!!", e);
        }
        // debugger;
      }
    }

    return () => (isSubscribed = false)
  }, [
    // ui_state.showspinner,
    ui_state,
    app_state.workspace_controls,
    app_state.data_load
  ]);

  return (
    <>
      {compRef.current.compLoad && <HighchartsReact
        highcharts={Highcharts}
        options={options} />}
      {!compRef.current.compLoad && <div style={{ margin: "0 10px" }}><Alert sx={{ hight: 30 }} severity="warning">{compRef.current.infoTxt}</Alert></div>}

    </>
  );
}

export default ChartIntellimerge;
