import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dataService from "../../services/data.service";
import { TILES } from "../../app/config";
import { divide, round, largeNumToDisplayString } from "../../app/util";
import { getForecastNonSegmentedData } from "../DynamicTable/tilesData/forecast";
import moment from "moment"
export function useDataSummary(seriesId, tileId) {
  const { app_state, ui_state } = useSelector(
    (state) => state.app.current_state
  );
  const { primary_stay_range } = app_state;
  const seriesData = dataService.getSeriesById(seriesId);
  const [kpiTileData, setKpiTileData] = useState({
    data: {},
    loading: true,
    loaded: false,
  });

  //let kpiData = {};
  useEffect(() => {
    //Do for OTB calculation
    if (tileId === 'otb') {
      if (seriesId === "OSR" && seriesData.loaded) {
        let otbData = processKpiData(seriesId, primary_stay_range);
        setKpiTileData(otbData);
      }
      if (seriesId === "OSRContrainedForecast" && seriesData.loaded) {
        let otbData = processKpiData(seriesId, primary_stay_range);
        setKpiTileData(otbData);
      }
    }
    //--->
  }, [primary_stay_range]);

  //debugger;
  return kpiTileData;
}

export function processOTBKpiData(primary_stay_range) {
  const OsrData = dataService.getSeriesById("OSR");
  const ForecastData = dataService.getSeriesById("OSRContrainedForecast");
  let dataType = "primary";
  let otbKpiData = {
    otb: {
      occAvg: "0",
      occYOY: "0",
      revenue: "0",
      revenueYOY: "0",
      adrAvg: "0",
      adrYOY: "0",
      revparAvg: "0",
      revparYOY: "0",
    },
    forecast: {
      occAvg: "0",
      occYOY: "0",
      occYOYSdly: "0",
      revenue: "0",
      revenueYOYSdly: "0",
      adrAvg: "0",
      adrYOYSdly: "0",
      revparAvg: "0",
      revparYOYSdly: "0",
    },
  };
  let sumOccSdly = 0,
    sumADRSdly = 0,
    sumRevenueSdly = 0,
    sumRevparSdly = 0;
  let sumOcc = 0,
    sumADR = 0,
    sumRevenue = 0,
    sumRooms = 0,
    sumRevpar = 0;
  if (OsrData.loaded) {
    let totalNum = OsrData.data.length;
    for (let index = 0; index < OsrData.data.length; index++) {
      const dataPoint = OsrData.data[index];
      sumOcc += dataPoint.HotelTotal[dataType]["Occ"];
      sumADR += dataPoint.HotelTotal[dataType]["ADR"];
      sumRevenue += dataPoint.HotelTotal[dataType]["Revenue"];
      sumRooms += dataPoint.HotelTotal[dataType]["Rooms"];
      sumRevpar += dataPoint.HotelTotal[dataType]["RevPAR"];

      if (dataPoint.HotelTotal["sdly"]) {    // added this condition because in some propert SDLY data is not coming.
        sumOccSdly += dataPoint.HotelTotal["sdly"]["Occ"];
        sumADRSdly += dataPoint.HotelTotal["sdly"]["ADR"];
        sumRevenueSdly += dataPoint.HotelTotal["sdly"]["Revenue"];
        sumRevparSdly += dataPoint.HotelTotal["sdly"]["RevPAR"];
      }

    }
    otbKpiData.otb.occAvg = round(divide(sumOcc, totalNum) * 100);
    otbKpiData.otb.adrAvg = round(divide(sumRevenue, sumRooms));
    otbKpiData.otb.revenue = largeNumToDisplayString(round(sumRevenue));
    otbKpiData.otb.revparAvg = round(divide(sumRevpar, totalNum));

    otbKpiData.otb.occYOY = parseInt(
      (divide(sumOcc, totalNum) - divide(sumOccSdly, totalNum)) * 100
    );
    otbKpiData.otb.adrYOY = parseInt(
      divide(sumADR, totalNum) - divide(sumADRSdly, totalNum)
    );
    otbKpiData.otb.revenueYOY = parseInt(
      divide(sumRevenue - sumRevenueSdly, sumRevenueSdly) * 100
    );
    otbKpiData.otb.revparYOY = parseInt(
      divide(sumRevpar, totalNum) - divide(sumRevparSdly, totalNum)
    );
    if (ForecastData.loaded) {
      let ForecastSumOcc = 0,
        ForecastSumADR = 0,
        ForecastSumRevenue = 0,
        ForecastSumRevpar = 0;
      // let sumOccSdly=0, sumADRSdly=0,sumRevenueSdly=0,sumRevparSdly=0 ;
      let totalNum = 0;
      let { data } = ForecastData.data;

      for (let index = 0; index < data.length; index++) {
        const dataPoint = data[index];
        ForecastSumOcc += dataPoint.predicted_total_cons_occ;
        ForecastSumADR += dataPoint.predicted_total_cons_adr;
        ForecastSumRevenue += dataPoint.predicted_total_cons_revenue;
        ForecastSumRevpar += dataPoint.predicted_total_cons_revpar;
        if (dataPoint.stay_date === primary_stay_range.date_until) {
          break;
        }
        totalNum += 1;
      }
      otbKpiData.forecast.occAvg = round(
        divide(ForecastSumOcc, totalNum) * 100
      );
      otbKpiData.forecast.adrAvg = round(divide(ForecastSumADR, totalNum));
      otbKpiData.forecast.revenue = largeNumToDisplayString(
        round(ForecastSumRevenue)
      );
      otbKpiData.forecast.revparAvg = round(
        divide(ForecastSumRevpar, totalNum)
      );

      otbKpiData.forecast.occYOYSdly = parseInt(
        (divide(ForecastSumOcc, totalNum) - divide(sumOccSdly, totalNum)) * 100
      );
      otbKpiData.forecast.adrYOYSdly = parseInt(
        divide(ForecastSumADR, totalNum) - divide(sumADRSdly, totalNum)
      );
      otbKpiData.forecast.revenueYOYSdly = parseInt(
        divide(sumRevenueSdly - ForecastSumRevenue, ForecastSumRevenue) * 100
      );
      otbKpiData.forecast.revparYOYSdly = parseInt(
        divide(ForecastSumRevpar, totalNum) - divide(sumRevparSdly, totalNum)
      );

      otbKpiData.forecast.occYOY = parseInt(
        (divide(ForecastSumOcc, totalNum) - divide(sumOcc, totalNum)) * 100
      );
      otbKpiData.forecast.adrYOY = parseInt(
        divide(ForecastSumADR, totalNum) - divide(sumADR, totalNum)
      );
      otbKpiData.forecast.revenueYOY = parseInt(
        divide(sumRevenue - ForecastSumRevenue, ForecastSumRevenue) * 100
      );
      otbKpiData.forecast.revparYOY = parseInt(
        divide(ForecastSumRevpar, totalNum) - divide(sumRevpar, totalNum)
      );
    }
  }

  return otbKpiData;
}
const getSumInventory = (Data, key, mainKey = "HotelTotal") => {
  let sumInventory = 0;
  Data.forEach(dataPoint => {
    if (key !== "forecast") {
      sumInventory += dataPoint[mainKey][key]["Capacity"];

    } else {
      sumInventory += dataPoint["capacity"];
    }
  })
  return sumInventory;
}
const getForecastConstrainedCombinedData = () => {
  const OtbData = dataService.getSeriesById("OSR");
  let OSRContrainedForecast = [];
  let forecastData = dataService.getSeriesById("OSRContrainedForecast")?.data?.data;

  let uniqueDates = {};

  if (moment(forecastData[0].stay_date).isAfter(OtbData[0].index.date)) {
    OtbData.forEach(item => {
      if (moment(item.index.date).isBefore(forecastData[0].stay_date) && item.HotelTotal.primary.Capacity) {
        OSRContrainedForecast.push({ capacity: item.HotelTotal.primary.Capacity });
      }
    })
  }

  forecastData.forEach(item => {
    if (!uniqueDates[item.stay_date]) {
      uniqueDates[item.stay_date] = item;
      OSRContrainedForecast.push(item);
    }
  })

  console.log("raw data", OSRContrainedForecast);
  return OSRContrainedForecast;
}
export async function processKpiData(seriesId, primary_stay_range, otbData, dayDate) {
  let sdlyKey = (dayDate && dayDate === 'day') ? 'sdly': 'sdly';  // LRV-880 make else condition sdtly
  let sdlyActualKey = (dayDate && dayDate === 'day') ? 'sdly_actual': 'sdly_actual'; // LRV-880 make else condition sdtly_actual
  let otbDataBlank = {
    data: {
      "occAvg": 0,
      "occYOY": 0,
      "revenue": 0,
      "revenueOrignal": 0,
      "revenueYOY": 0,
      "adrAvg": 0,
      "adrYOY": 0,
      "revparAvg": 0,
      "revparYOY": 0,
      "occAvgActual": 0,
      "revenueActual": 0,
      "adrActual": 0,
      "revparActual": 0
    }
  }
  if(!otbData){
    otbData = otbDataBlank;
  }
  const seriesData = dataService.getSeriesById(seriesId);
  if (seriesId === "OSRContrainedForecast") {

  }
  // debugger;
  //if(seriesId === 'OSRContrainedForecast' && seriesData.loaded) debugger;
  if (!seriesData.loading && seriesData.loaded && seriesData.data) {
    let dataType = "primary";
    let kpiKeys = {
      otb: {
        occAvg: "0",
        occYOY: "0",
        revenue: "0",
        revenueOrignal: "0",
        revenueYOY: "0",
        adrAvg: "0",
        adrYOY: "0",
        revparAvg: "0",
        revparYOY: "0",

        occAvgActual: "0",
        revenueActual: "0",
        adrActual: "0",
        revparActual: "0",

      },

      forecast: {
        occAvg: "0",
        occYOY: "0",
        occYOYSdly: "0",
        revenue: "0",
        revenueOrignal: "0",
        revenueYOYSdly: "0",
        adrAvg: "0",
        adrYOYSdly: "0",
        revparAvg: "0",
        revparYOYSdly: "0",
      },
    };
    let sumOccSdly = 0,
      sumRoomsSdly = 0,
      sumADRSdly = 0,
      sumRevenueSdly = 0,
      sumRevparSdly = 0;

    let sumOccSdly_actual = 0,
      sumRoomsSdly_actual = 0,
      sumADRSdly_actual = 0,
      sumRevenueSdly_actual = 0,
      sumRevparSdly_actual = 0;

    let sumOcc = 0,
      sumRooms = 0,
      sumADR = 0,
      sumRevenue = 0,
      sumRevpar = 0;

    let kpiData = {};
    //Do for OTB calculation
    if (seriesId === "OSR") {
      // debugger;
      kpiData = kpiKeys.otb;
      const data = seriesData.data ? seriesData.data.data : [];
      let totalNum = data.length;
      for (let index = 0; index < totalNum; index++) {
        const dataPoint = data[index];
        sumOcc += dataPoint.HotelTotal[dataType]["Occ"];
        sumRooms += dataPoint.HotelTotal[dataType]["Rooms"];
        sumADR += dataPoint.HotelTotal[dataType]["ADR"];
        sumRevenue += dataPoint.HotelTotal[dataType]["Revenue"];
        sumRevpar += dataPoint.HotelTotal[dataType]["RevPAR"];

        sumOccSdly += dataPoint.HotelTotal[sdlyKey]["Occ"];
        sumRoomsSdly += dataPoint.HotelTotal[sdlyKey]["Rooms"];
        sumADRSdly += dataPoint.HotelTotal[sdlyKey]["ADR"];
        sumRevenueSdly += dataPoint.HotelTotal[sdlyKey]["Revenue"];
        sumRevparSdly += dataPoint.HotelTotal[sdlyKey]["RevPAR"];

        sumOccSdly_actual += dataPoint.HotelTotal[sdlyActualKey]["Occ"];
        sumRoomsSdly_actual += dataPoint.HotelTotal[sdlyActualKey]["Rooms"];
        sumADRSdly_actual += dataPoint.HotelTotal[sdlyActualKey]["ADR"];
        sumRevenueSdly_actual += dataPoint.HotelTotal[sdlyActualKey]["Revenue"];
        sumRevparSdly_actual += dataPoint.HotelTotal[sdlyActualKey]["RevPAR"];

      }

      let sumInventory = getSumInventory(data, sdlyActualKey);

      // kpiData.occAvgActual = round(divide(sumOccSdly_actual, totalNum) * 100).toFixed(2);
      kpiData.occAvgActual = round(divide(sumRoomsSdly_actual, sumInventory) * 100).toFixed(2);
      kpiData.adrActual = round(sumRevenueSdly_actual / sumRoomsSdly_actual); //round(divide(sumADR, totalNum));
      kpiData.revenueActual = sumRevenueSdly_actual;
      // kpiData.revparActual = round(divide(sumRevparSdly_actual, totalNum));
      kpiData.revparActual = round(divide(sumRevenueSdly_actual, sumInventory));

      sumInventory = getSumInventory(data, "primary");
      // kpiData.occAvg = round(divide(sumOcc, totalNum) * 100).toFixed(2);
      kpiData.occAvg = round(divide(sumRooms, sumInventory) * 100).toFixed(2);
      kpiData.adrAvg = round(sumRevenue / sumRooms); //round(divide(sumADR, totalNum));
      kpiData.revenue = largeNumToDisplayString(round(sumRevenue));
      kpiData.revenueOrignal = sumRevenue;
      // kpiData.revparAvg = round(divide(sumRevpar, totalNum));
      kpiData.revparAvg = round(divide(sumRevenue, sumInventory));

      let sdlyAdr = round(sumRevenueSdly / sumRoomsSdly);
      // let occSdly = round(divide(sumOccSdly, totalNum) * 100).toFixed(2);
      sumInventory = getSumInventory(data,sdlyKey);
      let occSdly = round(divide(sumRooms, sumInventory) * 100).toFixed(2);

      // kpiData.occYOY = parseInt(
      //   Math.round(kpiData.occAvg - occSdly)
      // );
      kpiData.adrYOY = parseInt(
        Math.round(((kpiData.adrAvg - sdlyAdr) / sdlyAdr) * 100)
      );
      kpiData.revenueYOY = parseInt(
        Math.round(((sumRevenue - sumRevenueSdly) / sumRevenueSdly) * 100)
      );
      kpiData.revparYOY = parseInt(
        Math.round(((sumRevpar - sumRevparSdly) / sumRevparSdly) * 100)
      );

      kpiData.occYOY = parseInt(
        Math.round(((sumOcc - sumOccSdly) / sumOccSdly) * 100)
      );

    }
    //Do for forecast calculation
    if (seriesId === "OSRContrainedForecast") {
      kpiData = kpiKeys.forecast;
      let ForecastSumOcc = 0,
        ForecastSumRooms = 0,
        ForecastSumADR = 0,
        ForecastSumRevenue = 0,
        ForecastSumRevpar = 0;
      // const data = seriesData.data && seriesData.data.data ? seriesData.data.data : [];
      let data = getForecastNonSegmentedData();
      let totalNum = data.length;

      for (let index = 0; index < data.length; index++) {
        const dataPoint = data[index];
        ForecastSumOcc += dataPoint.predicted_total_cons_occ;
        ForecastSumRooms += dataPoint.predicted_total_cons_rooms;
        ForecastSumADR += dataPoint.predicted_total_cons_adr;
        ForecastSumRevenue += dataPoint.predicted_total_cons_revenue;
        ForecastSumRevpar += dataPoint.predicted_total_cons_revpar;
        if (dataPoint.stay_date === primary_stay_range.date_until) {
          break;
        }
        // totalNum += 1; 
      }
      // let OSRContrainedForecast= getForecastConstrainedCombinedData();
      let sumInventory = getSumInventory(data, "forecast");

      if (data && data[0] && data[0].capacity) {
        kpiData.occAvg = round(divide(ForecastSumRooms, sumInventory) * 100);

      } else {
        kpiData.occAvg = round(divide(ForecastSumOcc, totalNum) * 100);

      }
      kpiData.adrAvg = round(ForecastSumRevenue / ForecastSumRooms); //round(divide(ForecastSumADR, totalNum));
      kpiData.revenue = largeNumToDisplayString(round(ForecastSumRevenue));
      kpiData.revenueOrignal = ForecastSumRevenue;
      kpiData.revparAvg = round(divide(ForecastSumRevenue, sumInventory));

      // kpiData.occAvgActual = round(divide(sumOccSdly_actual, totalNum) * 100).toFixed(2);
      // kpiData.adrActual = round(sumRevenueSdly_actual / sumRoomsSdly_actual); //round(divide(sumADR, totalNum));
      // kpiData.revenueActual = sumRevenueSdly_actual;
      // kpiData.revparActual = round(divide(sumRevparSdly_actual, totalNum));

      //  console.log(kpiData.occAvg," testing  ",otbData.data);
      //  debugger;
      kpiData.occYOYSdly = parseInt(
        Math.round(parseInt(kpiData.occAvg - otbData.data.occAvgActual))
      );
      kpiData.adrYOYSdly = parseInt(
        // (kpiData.adrAvg - otbData.data.adrActual)
        Math.round(divide(kpiData.adrAvg - otbData.data.adrActual, otbData.data.adrActual) * 100)
        // divide(ForecastSumADR, totalNum) - divide(sumADRSdly, totalNum)
        // (kpiData.adrAvg - otbData.data.adrActual)
      );
      kpiData.revenueYOYSdly = parseInt(
        Math.round(divide(kpiData.revenueOrignal - otbData.data.revenueActual, otbData.data.revenueActual) * 100)
      );
      kpiData.revparYOYSdly = parseInt(
        Math.round(divide(kpiData.revparAvg - otbData.data.revparActual, otbData.data.revparActual) * 100)
        // divide(ForecastSumRevpar, totalNum) - divide(sumRevparSdly, totalNum)
      );

      // kpiData.occYOY = parseInt(
      //   (divide(ForecastSumOcc, totalNum) - divide(sumOcc, totalNum)) * 100
      // ); 
      kpiData.occYOY = parseInt(Math.round(kpiData.occAvg - otbData.data.occAvg));
      kpiData.adrYOY = parseInt(
        Math.round(divide(kpiData.adrAvg - otbData.data.adrAvg, otbData.data.adrAvg) * 100)
        // Math.round(kpiData.adrAvg - otbData.data.adrAvg)
      );
      kpiData.revenueYOY = parseInt(
        Math.round(divide(kpiData.revenueOrignal - otbData.data.revenueOrignal, otbData.data.revenueOrignal) * 100)
      );

      let revparYOY = Math.round(divide(kpiData.revparAvg - otbData.data.revparAvg, otbData.data.revparAvg) * 100)
      kpiData.revparYOY = parseInt(
        revparYOY
      );
    }
    // debugger;
    return Promise.resolve({ series: seriesId, loaded: true, loading: false, data: kpiData });
  }

}
