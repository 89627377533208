
import { Box, Select, FormControl, MenuItem, Tooltip } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

const DayOfWeek = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const [dayOfWeek, setDayOfWeek] = useState(controls.dayOfWeek);
    const dispatch = useDispatch();

    const handleChange = (type, value) => {
        controls.dayOfWeek = value;
        dispatch(updateAppState(type,
            { app_state: { workspace_controls: controls, data_load: true }, ui_state: { showspinner: true } }));
    }


    return (
        <>
            <Box id="tabletype" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box className="data-level-label" sx={{ minHeight: .6 }}>Day Of Week</Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                    <Select className='tabular-data-filter' fullWidth value={dayOfWeek} sx={{ height: "30px", mt: "2px" }} onChange={(e) => {
                        setDayOfWeek(e.target.value);
                        handleChange("day_of_week", e.target.value);
                    }}>
                        <MenuItem value="mon,tue,wed,thu,fri,sat,sun" className='tabular-data-filter'> All </MenuItem>
                        <MenuItem value="mon" className='tabular-data-filter'> Monday </MenuItem>
                        <MenuItem value="tue" className='tabular-data-filter'> Tuesday </MenuItem>
                        <MenuItem value="wed" className='tabular-data-filter'> Wednesday </MenuItem>
                        <MenuItem value="thu" className='tabular-data-filter'> Thursday </MenuItem>
                        <MenuItem value="fri" className='tabular-data-filter'> Friday </MenuItem>
                        <MenuItem value="sat" className='tabular-data-filter'> Saturday </MenuItem>
                        <MenuItem value="sun" className='tabular-data-filter'> Sunday </MenuItem>
                        <MenuItem value="mon,tue,wed,thu" className='tabular-data-filter'> Weekday </MenuItem>
                        <MenuItem value="fri,sat,sun" className='tabular-data-filter'> Weekend </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default DayOfWeek