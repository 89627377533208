
import React, { useState } from 'react';
import { Box, TextField, InputAdornment, Select, MenuItem, FormControl, Autocomplete } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';



const PickUpDays = () => {
    const {current_state} = useSelector(state => state.app);
    const {property_id, property_name, workspace_controls, primary_stay_range, user_view}= current_state.app_state;
  
    const dispatch = useDispatch();

    const handleChange = (type, value) => {
        const updateTypes = [type];
        workspace_controls.pickUpDays = value;
        dispatch(
            updateAppState(
                updateTypes, {
                app_state: { workspace_controls, data_load: true },
                ui_state: { showspinner: true }
            }));
        window.analyze.track("tile_change", {
            "selected_tile": current_state.ui_state.active_tiles.join(),
            ...primary_stay_range,
            property_id, property_name,
            data_view: user_view,pickUpDays:value
        }, {...current_state}, {
            app_state: { workspace_controls, data_load: true },
            ui_state: { showspinner: true }
        });
        // dispatch(updateAppState(type, {app_state: {selected_market:value,data_load:true},ui_state:{showspinner:true}}));

    }

    const daysOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
    const [openDropdown, setOpenDropdown] = useState(false);

    return (
        <Box id="pickupDaysBox" className='control-options' sx={{ position: "relative" }}>
            <Box className='data-level-label'> Pickup </Box>
            {/* <TextField
                size="small"
                fullWidth
                sx={{ mt: "5px", width:75}}
                InputProps={{
                    style: { height: 30, fontSize:13, },
                    endAdornment: <InputAdornment disableTypography={true} fontSize="12px" position="end">Day</InputAdornment>
                }}
                onBlur={(e) => {if(controls.pickUpDays != e.target.value) handleChange("pickupDays", e.target.value) }}
                defaultValue={controls.pickUpDays}
                type="Number"
                // endAdornment={<InputAdornment position="end">Day</InputAdornment>}
                // value={controls.pickUpDays}
            >
            </TextField> */}
            {/* <span className='label-days'>Day</span> */}


            <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={daysOptions}
                value={workspace_controls.pickUpDays}
                onChange={(event, newValue) => handleChange("pickupDays", newValue)}
                onBlur={(e) => { if (workspace_controls.pickUpDays !== e.target.value) handleChange("pickupDays", e.target.value) }}
                open={openDropdown} // State to control the open state of the dropdown
                onOpen={() => setOpenDropdown(true)} // Set open state to true when dropdown opens
                onClose={() => setOpenDropdown(false)} // Set open state to false when dropdown closes
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            style: { height: 30, fontSize: 13, marginTop: 5, padding: 0, paddingRight: 8 },
                            endAdornment: (
                                <InputAdornment disableTypography={true} sx={{ marginLeft: '0px', fontSize: 14, marginBottom: '2px' }} position="end">
                                    {workspace_controls.pickUpDays > 1 ? "Days" : "Day"}
                                    <svg
                                        style={{ marginLeft: "4px", cursor: "pointer" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#606060"
                                        viewBox="0 0 256 256"
                                        onClick={() => setOpenDropdown(!openDropdown)} // Toggle the open state on SVG click
                                    >
                                        <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,48,88H208a8,8,0,0,1,5.66,13.66Z"></path>
                                    </svg>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: 13 }}>{option}</li>
                )}
            />

        </Box>
    )
}

export default PickUpDays