import "./style.css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, MobileDatePicker, PickersDay } from "@mui/x-date-pickers/";

import moment from "moment";
import {
  AddSharp as AddSharpIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon, Delete as DeleteIcon,
  PlaylistAdd as AddNewIcon,
  RemoveSharp as RemoveSharpIcon,
  KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon,
  KeyboardArrowUpOutlined as KeyboardArrowUpOutlinedIcon,
  Edit as EditIcon, InfoOutlined as InfoOutlinedIcon,
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  DeleteOutlined as DeleteOutlineIcon,
  AddCircleOutlineRounded as AddCircleOutlineRoundedIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  ClearOutlined as ClearOutlinedIcon,
  SaveOutlined as SaveOutlinedIcon,
  ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,

} from "@mui/icons-material/";
import {
  Alert,
  Autocomplete,
  Badge,
  Box,
  Button,
  IconButton,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  ButtonGroup,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from "@mui/material/";
import React, { useEffect, useState, useReducer, useRef } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
  Breadcrumb,
  SettingsTitle,
  AddNewItem,
  UserConfirmationAlert,
} from "./comp.static";

import { settingsReducers } from "./reducers";
import Settings from "./index";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { NEW_PROPERTY_SYSTEM_SETTINGS } from "../../app/config";
import { updateAppState } from '../../actions/appstate';
import { SYSTEM_SETTINGS_UPDATED } from "../../actions/types";
import { dateFormat, deepCopy, getDataSources, toInteger } from "../../app/util";
import { API_REGISTRY } from "../../app/config";
import dataService, { fetchDataSimple } from "../../services/data.service";
import { color } from "highcharts";
import { border, lineHeight } from "@mui/system";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { pt } from "date-fns/locale";
import { clearAPIRegistryData } from "../../app/util";
import DataService from "../../services/data.service";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// import { useData } from "../../hooks/";

export default function ScheduleReportsSettings(props) {

  const dispatch = useDispatch();
  const [isrequesting, setIsRequesting] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestSuccessMsg, setRequestSuccessMsg] = useState(
    "Your change request for settings was successfull!"
  );
  const [isRequestError, setIsRequestError] = useState(false);
  const [resErrorMsg, setResErrorMsg] = useState("");
  const [expanded, setExpanded] = useState({});
  const containerRef = useRef(null);
  const { app, auth } = useSelector((state) => state);
  const { app_state, ui_state } = app.current_state;
  const { user, profile } = auth;
  const { property_details } = profile || [];

  const [compState, setCompState] = useState({ editable: '', dataStatus: 'loading' });

  const updateCompState = (key, value) => {
    let keys = key.split('>');
    //Handling single deptch key/value pairs
    if (keys[0] && !keys[1]) {
      compState[keys[0]] = value;
    }
    //Handling double deptch key/value pairs
    if (keys[0] && keys[1]) {
      compState[keys[0]] = compState[keys[0]] ? compState[keys[0]] : {};
      compState[keys[0]][keys[1]] = value;
    }
    setCompState((state) => {
      return { ...state, ...compState };
    });
  }

  const [originalSchedule, setOriginalSchedule] = useState({
    scheduled_report: [],
  });
  const [requestData, setRequestData] = useState({ scheduled_report: [] });

  const request = {
    method: 'GET'
    // owner_id: user.user_id,
  };
  //
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [settingsState, dispatchSettings] = useReducer(
    settingsReducers,
    initalState
  );
  const [age, setAge] = React.useState('');
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const { ui, data } = settingsState;
  //--
  const fetchReportSchedules = () => {
    dataService
      .getData({
        api: "reports/details",
        request: request,
      })
      .then((rs) => {
        console.log("getReportSchedules", rs);
        if (rs.status === "success") {
          let scheduled_report = rs.scheduled_reports;
          let reports = rs.reports;
          let markets = rs.markets;
          updateCompState('reports', reports);
          updateCompState('markets', markets);

          scheduled_report.map((item) => {
            if (item.reports && item.reports.length) {
              item.reports.map((report, i) => {
                let resource = reports.filter(r => r.id === report.report_id);
                // debugger;
                if (resource.length) {
                  report.resource = resource[0].resource;
                }
              })
            }
          });

          setRequestData(prev => {
            return { ...requestData, scheduled_report: scheduled_report }
          });

          const copy = JSON.parse(
            JSON.stringify({ scheduled_report: scheduled_report })
          );
          setOriginalSchedule({ ...copy });

        }
        setCompState((state) => ({ ...state, dataStatus: 'loaded' }));
      })
      .catch(() => {
        setCompState((state) => ({ ...state, dataStatus: 'loaded' }));
        console.log("getReportSchedules Error!");
      });
  }

  useEffect(() => {

    fetchReportSchedules();

  }, []);

  const saveField = (index, field) => {
    // debugger;
    if (field) {
      toggleEditable(null, 'cancel', index);
    }
    setRequestData(prev => {
      return { ...requestData };
    });
  };
  const cancelSave = (index, field) => {
    // debugger;
    if (!field) {
      requestData.scheduled_report[index].isChangeRequest = false;
      const copy = JSON.parse(JSON.stringify(originalSchedule));
      requestData.scheduled_report.splice(index, 1, copy.scheduled_report[index]);
    } else {
      const copy = JSON.parse(JSON.stringify(originalSchedule));
      requestData.scheduled_report[index][field] = copy.scheduled_report[index][field];
      toggleEditable(null, 'cancel', index);
    }

    setRequestData(prev => {
      return { ...requestData }
    });
  };

  const updateRequestData = (keys, value, index, reportIndex) => {
    // debugger;
    const updatedObj = JSON.parse(JSON.stringify(requestData));
    const key = keys.split(">");
    const size = key.length;
    let val = value;

    if (key[0] === "schedule" && size === 2) {
      updatedObj.scheduled_report[index][key[0]][key[1]] = value;
    }
    if (key[0] === "schedule" && size === 3) {
      updatedObj.scheduled_report[index][key[0]][key[1]][key[2]] = value;
    }
    if (key[0] === "status" && size === 1) {
      updatedObj.scheduled_report[index][key[0]] = value
        ? "active"
        : "inactive";
    }
    if (key[0] === "recipients") {
      let size = val.length; // debugger;
      let l = updatedObj.scheduled_report[index][key[0]].length;
      updatedObj.scheduled_report[index][key[0]].splice(0, l);
      val.map((item, i) => {
        updatedObj.scheduled_report[index][key[0]].push(item);
      });
    } else if (key[0] === "reports" && size === 2) {
      updatedObj.scheduled_report[index][key[0]][reportIndex][key[1]] = value;
      if (updatedObj.scheduled_report[index][key[0]][reportIndex].resource === "property") {
        let property_id = property_details[0].id;
        updatedObj.scheduled_report[index][key[0]][reportIndex].params = {
          "property_id": property_id
        }
      }
      if (updatedObj.scheduled_report[index][key[0]][reportIndex].resource === "market") {
        let market_id = compState.markets[0].id;
        updatedObj.scheduled_report[index][key[0]][reportIndex].params = {
          "market_id": market_id
        }
      }
    } else if (key[0] === "reports" && size === 3) {
      updatedObj.scheduled_report[index][key[0]][reportIndex][key[1]][key[2]] =
        value;
    } else if (size === 1) {
      updatedObj.scheduled_report[index][key[0]] = val;
    }
    updatedObj.scheduled_report[index].isChangeRequest = true;
    if (!updatedObj.scheduled_report[index].batch_name ||
      updatedObj.scheduled_report[index].reports[0].report_id === "") {
      updatedObj.scheduled_report[index].isChangeRequest = false;
    }
    console.log("update report", updatedObj.scheduled_report[index]);
    setRequestData(prev => {
      return { ...updatedObj };
    });
    //setIsChangeRequest(true);
  };

  const addNewItem = () => {
    // debugger;
    //setRequestData({...requestData});
    const size = requestData.scheduled_report.length;
    const item = {
      // batch_name: "New Schedule Batch " + (size + 1),
      batch_name: "",
      reports: [
        {
          report_id: "",
          params: {},
        },
      ],
      status: "active",
      schedule: {
        frequency: "daily",
        no_of_period: 1,
        dow_schedule: [],
        // dow: [],
        end: 'never',
        // approval_range: {
        //   end: 'never',
        //   // end: new moment()
        //   //   .add(1, "month")
        //   //   .startOf("day")
        //   //   .format("YYYY-MM-DDThh:mm:ss"),
        //   // start: new moment().startOf("day").format("YYYY-MM-DDThh:mm:ss"),
        // },
      },
      recipients: [],
    };
    originalSchedule.scheduled_report.push(item);
    // requestData.scheduled_report.push(item);
    requestData.scheduled_report.push(item);
    let len = requestData.scheduled_report.length;
    //requestData.pricing_settings.room_dow_plans.push(item);
    setRequestData(prev => {
      return { ...requestData }
    });
    
    setOriginalSchedule(prev => {
      return { ...originalSchedule };
    });
    expanded["id_" + (len-1)] = true;
    setExpanded(prev => { return { ...expanded } });
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const deleteItem = (index) => {
    // requestData.scheduled_report.splice(index,1);
    setIsRequesting(true);
    const bath_id = requestData.scheduled_report[index].batch_id;
    const request = { batch_id: bath_id, method: 'DELETE' };
    // debugger;
    if (bath_id) {
      dataService
        .getData({ api: "/users/scheduled-reports/", request: request })
        .then((rs) => {
          if (rs.status === "success") {
            //Removing from UI
            requestData.scheduled_report.splice(index, 1);

            setIsRequesting(false);

            setRequestData(prev => {
              return { ...requestData }
            });
            const copy = JSON.parse(
              JSON.stringify(requestData)
            );
    
            setOriginalSchedule(prev => {
              return { ...copy };
            });
          } else {
            setIsRequesting(false);
            console.log("delete_report_batches  Failed! ", rs);
          }
        })
        .catch(() => {
          setIsRequesting(false);
          console.log("delete_report_batches Error!");
        });
      //requestData.scheduled_report[index].isChangeRequest = true;
      //setRequestData({...requestData});
    } else {
      //Removing from UI only
      requestData.scheduled_report.splice(index, 1);
      setRequestData(prev => {
        return { ...requestData }
      });
      let copy = deepCopy(requestData)
      setOriginalSchedule(prev => {
        return { ...copy };
      });
      setIsRequesting(false);
    }
  };

  const remove = (type, index, i) => {
    const updatedObj = { ...requestData };
    updatedObj.scheduled_report[index].reports.splice(i, 1);
    updatedObj.scheduled_report[index].isChangeRequest = true;
    if (!updatedObj.scheduled_report[index].batch_name ||
      updatedObj.scheduled_report[index].reports[0].report_id === "") {
      updatedObj.scheduled_report[index].isChangeRequest = false;
    }
    setRequestData(prev => {
      return {
        ...requestData,
        scheduled_report: updatedObj.scheduled_report,
      }
    });
  };

  const add = (type, index) => {
    if (type !== "reports") {
      return false;
    }
    const updatedObj = { ...requestData };

    const reports_item = {
      report_id: "",
      params: {
        // market_id: "",
        // property_id: "",
      },
    };

    updatedObj.scheduled_report[index].reports.push(reports_item);
    requestData.scheduled_report[index].isChangeRequest = true;
    if (!updatedObj.scheduled_report[index].batch_name ||
      updatedObj.scheduled_report[index].reports[0].report_id === "") {
      updatedObj.scheduled_report[index].isChangeRequest = false;
    }
    setRequestData(prev => {
      return {
        ...requestData,
        scheduled_report: updatedObj.scheduled_report,
      }
    });
  };

  const saveChanges = (index, batch_id) => {
    setIsRequesting(true);
    const report_batch = JSON.parse(
      JSON.stringify(requestData.scheduled_report[index])
    );
    let sameNameBatches  = requestData.scheduled_report.filter(i=> i.batch_name === report_batch.batch_name);
    if(sameNameBatches.length > 1 ){
      setIsRequesting(false);
      setIsRequestError(true);
      setResErrorMsg( "Can't have more reports with same batch name !");
      setTimeout(() => {
        setIsRequestError(false);
      }, 3000);
      return; 
    }
    let payload = deepCopy(report_batch);
    delete payload.isChangeRequest;
    delete payload.owner;
    delete payload.editing;
    if (payload.reports && payload.reports.length) {
      payload.reports.map(report => delete report.resource);
    }

    //payload.report_batch.owner = user.user_id;
    if (!report_batch.batch_id) {
      payload.method = 'POST';
      //payload.op = "insert";
    } else {
      payload.method = 'PUT';
    }  
   
    // debugger;
    dataService
      .getData({ api: "users/scheduled-reports/", request: payload })
      .then((rs) => {
        //Do some task here just after respons
        if (rs.status === "success") {
          requestData.scheduled_report[index].isChangeRequest = false;
          requestData.scheduled_report[index].batch_id = rs.data.batch_id
          setIsRequestSuccess(true);
          setIsRequesting(false);
          //requestData.scheduled_report.splice(index, 1, rs.data[0]);
          const copy = JSON.parse(JSON.stringify(requestData));
          originalSchedule.scheduled_report[index] = copy.scheduled_report[index];
          expanded["id_" + index] = false;
          setExpanded(prev => { return { ...expanded } });
          setOriginalSchedule(prev => { return { ...originalSchedule } });
          setRequestData(prev => { return { ...requestData } });
          setTimeout(() => {
            setIsRequestSuccess(false);
          }, 3000);
        } else {
          setIsRequestError(true);
          setIsRequesting(false);
          setResErrorMsg(rs.message);
          //return Promise.reject(data);
        }
      }, (err) => {
        setIsRequesting(false);
        setIsRequestError(true);
        setResErrorMsg(
          (err && err.original && err.original.statusText) ||
          "Something went wrong, please try again later!"
        );
        setTimeout(() => {
          setIsRequestError(false);
        }, 3000);
      })
  };

  const handleExpand = (index) => {
    if (!expanded["id_" + index]) {
      expanded["id_" + index] = true;
    } else {
      expanded["id_" + index] = false;
    }
    setExpanded(prev => { return { ...expanded } });
  };

  console.log("overall", requestData.scheduled_report);

  const breadcrumbHierarchy = ["Report Schedule", "Settings", "Scheduler"];

  const handleConfirmation = (index) => {
    ui.ask = false;
    dispatchSettings({ type: "updateData" });
    deleteItem(index);
  };
  const handleConfirmationCancel = () => {
    ui.ask = false;
    dispatchSettings({ type: "updateData" });
  }

  const toggleEditable = (e, fieldKey, index, item) => {
    const event = e;
    requestData.scheduled_report[index].editing = fieldKey;
    setRequestData(prev => { return { ...requestData } });
    // updateCompState('editable', fieldKey);
    // debugger;
  }

  const handleWODClick = (e, item, id) => {
    const val = e.target.value;
    const idow = item.schedule.dow_schedule.indexOf(val);
    // debugger;
    if (idow === -1) {
      item.schedule.dow_schedule.push(val);
    } else {
      item.schedule.dow_schedule.splice(idow, 1);
    }
    updateRequestData("schedule>dow_schedule", item.schedule.dow_schedule, id);
    // ui.dataChanged["id_" + id] = true;
    // dispatchSettings({ type: "updateData", id: id });
  };

  const navigate = useNavigate();
  const [refreshingData, setRefreshingData] = useState(false);
  const [refreshedData, setRefreshedData] = useState(false);
  function goBackToDashboard() {
    dispatch(
      updateAppState("navigate_to_dashboard", {
        app_state: {
          data_load: true,
          kpi_load: true,
        },
        ui_state: {
          active_settings_menu: "",
          showspinner: true,
        },
      }, "settings_click")
    );
    navigate("/");
  }
  function refreshData() {
    //reverting changes back
    // if (!field) {
    //   requestData.scheduled_report[index].isChangeRequest = false;
    //   const copy = JSON.parse(JSON.stringify(originalSchedule));
    //   requestData.scheduled_report.splice(index, 1, copy.scheduled_report[index]);
    // } else {
    //   const copy = JSON.parse(JSON.stringify(originalSchedule));
    //   requestData.scheduled_report[index][field] = copy.scheduled_report[index][field];
    //   toggleEditable(null, 'cancel', index);
    // }
    // setRequestData(prev => {
    //   return { ...requestData }
    // });

    const payload = {
      token_string: user.token_string,
      resourceId: app_state.property_id,
      resourceType: "property",
      targetCache: ["srr", "forecast", "otb"],
      operation: "flush",
    };
    setRefreshingData(true);
    // debugger;
    DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
      .then((data) => {
        //Do some task here just after respons
        if (data.status === "success") {
          setRefreshedData(true);
          setRefreshingData(false);
          console.log("refreshData Success");
          setTimeout(() => {
            setRefreshedData(false);
            // window.location.reload();
          }, 2000);
          clearAPIRegistryData();
        } else {
          setRefreshingData(false);
          console.warn("refreshData Error, data could not refreshed");
          setTimeout(() => {
            setRefreshedData(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setRefreshingData(false);
        setTimeout(() => {
          setRefreshedData(false);
        }, 3000);
      });
  }
  const customDropdownIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="#606060"
      viewBox="0 0 256 256"
      style={{ marginRight: "10px" }}
    >
      <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
    </svg>
  );
  const customDropdownIcon2 = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="#606060"
      viewBox="0 0 256 256"
      style={{ marginRight: "10px" }}
    >
      <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
    </svg>
  );

  const [expandArea1, setExpandArea1] = useState(false);
  const handleCustomIconClick1 = () => {
    // Toggle the value of expandArea when the custom icon is clicked
    setExpandArea1(!expandArea1);
  };
  const [expandArea2, setExpandArea2] = useState(false);
  const handleCustomIconClick2 = () => {
    // Toggle the value of expandArea when the custom icon is clicked
    setExpandArea2(!expandArea2);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <UserConfirmationAlert open={ui.ask} onConfirm={handleConfirmation} index={ui.index} onCancel={handleConfirmationCancel} />
        {/* <Breadcrumb hierarchy={breadcrumbHierarchy} /> */}



        <Box className='top-axn-bar-1'
        >
          <Tooltip title="Back to Dashboard" arrow followCursor >
            <Button
              size="medium"
              variant="contained"
              className='back-to-dash-btn'
              onClick={goBackToDashboard}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                  <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                  <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                  </g>
                  <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                  </g>
                  <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                  </g>
                  <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                  </g>
                  <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                  </g>
                </g>
              </svg>
              <span className='back-to-dash-txt'>Back to Dashboard</span>
            </Button>
          </Tooltip>

          <Box className="top-axn-bar-1-sub">
            <Tooltip title="Refresh Data" >
              <box
                onClick={refreshData}
                size="medium"
                variant="contained"
                className='refresh-btn'
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
              </box>
            </Tooltip>

          </Box>

          <Alert
            icon={false}
            sx={{
              display: refreshedData ? "block" : "none",
              position: "fixed",
              left: 0,
              fontSize: 17,
              textAlign: "center",
              borderRadius: 0,
              lineHeight: 1,
              bottom: 40,
              width: "100%",
              zIndex: 100,
              backgroundColor: "green.main",
              color: "white.main",
            }}
            severity="success"
          >
            Data refreshed successfully!
          </Alert>

        </Box>

        <Box className='top-axn-bar'>
          <Box className='breadcrumb' sx={{ color: 'white.main' }}>
            <span className="breadcrum-inactive">Settings</span>
            <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
              sx={{ top: 3, position: "relative", height: 18 }}
            ></ArrowForwardIosOutlinedIcon>
            <span className="breadcrum-inactive">Scheduler</span>
            <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
              sx={{ top: 3, position: "relative", height: 18 }}
            ></ArrowForwardIosOutlinedIcon>
            <span className="breadcrum-active">Report Schedule</span>
          </Box>
        </Box>

        <Box className="bgwhite main-scroll-wrap-segments" sx={{ pl: 2, pr: 2, scrollBehavior: 'smooth' }} ref={containerRef}>

          <div className='profile-heading' style={{ paddingBottom: '5px' }}>REPORT SCHEDULE</div>

          <Alert
            severity="info"
            sx={{
              display: compState.dataStatus === 'loading' ? "flex" : "none",
            }}
          >
            Loading existing report schedules...
          </Alert>
          <Alert
            severity="success"
            sx={{
              display: isRequestSuccess ? "all" : "none",
            }}
          >
            {requestSuccessMsg}
          </Alert>
          <Alert
            severity="error"
            sx={{
              display: isRequestError ? "all" : "none",
            }}
          >
            {resErrorMsg}
          </Alert>

          <Box sx={{ overflowY: "auto" }}>

            {requestData.scheduled_report.map((item, index) => (
              <Paper className="scheduler-row report" key={"key-" + index} sx={{ mb: 2 }} variant="outlined" style={!expanded["id_" + index] ? { boxShadow: "0px 0px 0px 1px #EBEBEB" } : { overflow: "hidden" }}>
                <Box
                  sx={{
                    flexDirection: "column",
                    position: "relative",
                    display: "flex",
                    overflow: "hidden"
                  }}
                >

                  <Box
                    sx={{ maxHeight: "80vh", overflow: "auto" }}
                  >
                    <Grid container spacing={0}>
                      <Grid item className="scheduler-left" xs={3.5}>
                        <Grid container sx={{ p: 1.5, pb: 0 }}>
                          <div className="rate-push-badge">{index + 1}</div>

                          {!expanded["id_" + index] && (<Box className="collapased-report-name"> {item.batch_name !=="" ? item.batch_name : "Batch Name"} </Box>)}

                          <Grid item className="hotel-name" xs={12}>
                            {/* {item.editing !== 'batch_name' &&
                              <div className="field view">
                                {item.batch_name}
                                {expanded["id_" + index] &&
                                  <div className="usr-axns">
                                    <IconButton size="small" onClick={(e) => toggleEditable(e, 'batch_name', index, item)}>
                                      <EditIcon />
                                    </IconButton>
                                  </div>
                                }
                              </div>
                            } */}
                            {/* {item.editing == 'batch_name' && */}





                            <div className="field edit">
                              {expanded["id_" + index] && (
                                // <Input
                                //   className="accordian-field"
                                //   size="small"
                                //   fullWidth
                                //   sx={{
                                //     mt: 1, mr: 1,
                                //     textAlign: "center",
                                //     color: "blue.dark",
                                //   }}
                                //   value={item.batch_name}
                                //   placeholder="Please enter batch name..."
                                //   onChange={(e) => {
                                //     const value = e.target.value;
                                //     updateRequestData("batch_name", value, index);
                                //   }}
                                // />
                                <TextField
                                  fullWidth
                                  className="textfield"
                                  size="small"
                                  variant="outlined"
                                  label="Batch Name"
                                  placeholder="Please enter batch name..."
                                  value={item.batch_name}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    updateRequestData("batch_name", value, index);
                                  }}
                                  sx={{
                                    mt: 1, mr: 1,
                                    textAlign: "center",
                                    color: "blue.dark",
                                  }}
                                />

                              )}
                              {/* {!expanded["id_" + index] && item.batch_name} */}

                            </div>
                            {/* } */}
                          </Grid>

                        </Grid>
                      </Grid>
                      <Grid item xs={8.5}>


                        <Grid sx={!expanded["id_" + index] ? { background: "#f5f6fd", pt: .6, pr: 1, gap: "10px", borderRadius: "0px 6px 6px 0px" } : { pt: .6, pr: 1, gap: "10px" }} container justifyContent="flex-end" alignItems="center">
                          <Grid item>
                            <IconButton onClick={() => { deleteItem(index) }}>
                              {/* <DeleteOutlineIcon className="delete-button" sx={{ fontSize: 24 }}></DeleteOutlineIcon> */}
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#E03131" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>
                            </IconButton>
                          </Grid>

                          {/* <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 0.3 }} /> */}

                          <Grid item>
                            {expanded["id_" + index] &&
                              <IconButton className="collapse-arrow" onClick={() => {
                                handleExpand(index);
                              }}>
                                {/* <KeyboardArrowUpOutlinedIcon className="up" sx={{ fontSize: 40 }} /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#606060" viewBox="0 0 256 256"><path d="M213.66,165.66a8,8,0,0,1-11.32,0L128,91.31,53.66,165.66a8,8,0,0,1-11.32-11.32l80-80a8,8,0,0,1,11.32,0l80,80A8,8,0,0,1,213.66,165.66Z"></path></svg>
                              </IconButton>
                            }
                            {!expanded["id_" + index] &&
                              <IconButton className="collapse-arrow" onClick={() => {
                                handleExpand(index);
                              }}>
                                {/* <KeyboardArrowDownOutlinedIcon className="down" sx={{ fontSize: 40 }} /> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#606060" viewBox="0 0 256 256"><path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path></svg>
                              </IconButton>
                            }
                          </Grid>
                        </Grid>


                      </Grid>
                    </Grid>

                    <Collapse in={expanded["id_" + index]}
                      timeout="auto"
                      unmountOnExit
                      sx={{
                        overflow: "visible",
                        // border: "1px solid #000",
                      }}>

                      <Grid container spacing={0}>
                        <Grid item className="scheduler-left" xs={3.5}>
                          <Box sx={{ p: 1.5, }}>
                            <Grid container>
                              <Box className="field-controls">
                                <div className="control-label">Repeat every</div>
                                <div className="control-fields">
                                  <Grid container spacing={2}>
                                    <Grid item xs={2.2}>
                                      <TextField size="small"
                                        className="no-of-period"
                                        sx={{ pl: 0, pr: 0}}
                                        fullWidth
                                        value={item.schedule.no_of_period}
                                        onChange={(e) => {
                                          // debugger;
                                          let val = e.target.value;
                                          if (!isNaN(val)) {
                                            // item.schedule.repeat.count = val;
                                            // const id = "id_" + index;
                                            // ui.dataChanged[id] = true;
                                            // dispatchSettings({
                                            //   type: "updateData",
                                            //   id: id,
                                            // });
                                            updateRequestData("schedule>no_of_period", val, index);
                                          }

                                        }}
                                        variant="outlined" />
                                    </Grid>
                                    <Grid item xs={7}>
                                      <FormControl size="small">
                                        <Select sx={{background: "#FFF"}}
                                          value={item.schedule.frequency}
                                          onChange={(e) => {
                                            // debugger;
                                            let val = e.target.value;
                                            // item.schedule.repeat.period = val;
                                            updateRequestData("schedule>frequency", val, index);
                                          }}
                                          // open={expandArea1}
                                          // onClose={() => setExpandArea1(false)} // Close the select when the menu is closed
                                          // onOpen={() => {
                                          //   setExpandArea1(true)
                                          // }} // Open the select when the menu is opened
                                          IconComponent={() => (
                                            <i
                                              onClick={handleCustomIconClick1}
                                              style={{                                     
                                                color: "#606060",
                                                height: "25px",
                                                width: "25px",
                                                alignItems: "center",
                                                fontSize: "20px",
                                                marginRight: "8px",
                                                cursor: "pointer"
                                              }}
                                              className={`ph-bold ${expandArea1 ? "ph-caret-up" : "ph-caret-down"}`}
                                            ></i>
                                          )}
                                          MenuProps={{
                                            anchorOrigin: {
                                              vertical: "bottom",
                                              horizontal: "left"
                                            },
                                            transformOrigin: {
                                              vertical: "top",
                                              horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                          }}
                                        >
                                          <MenuItem value="daily">Day</MenuItem>
                                          <MenuItem value="weekly">Week</MenuItem>
                                          {/* <MenuItem value="monthly">Month</MenuItem> */}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Box>
                              {item.schedule.frequency !== 'daily' &&
                                <Box className="field-controls" sx={{width: "100%"}}>
                                  <div className="control-label">REPEAT ON</div>
                                  <div className="control-fields">
                                    {/* <Tooltip
                                      title="Select day of week"
                                      arrow
                                      disableInteractive
                                      followCursor
                                    > */}
                                    <ButtonGroup
                                      disableElevation
                                      className="dow-group"
                                      variant="contained"
                                      fullWidth
                                      sx={{ mr: 2 }}
                                    >
                                      {ui.dow.map((wd) => {
                                        const currIndex = item.schedule.dow_schedule.indexOf(wd);
                                        return currIndex !== -1 ? (
                                          <Button
                                            className="wd-name active"
                                            size="small"
                                            variant="outlined"
                                            sx={{
                                              pt: 1,
                                              pb: 1,
                                              backgroundColor:
                                                item.schedule.dow_schedule.indexOf(wd) !== -1
                                                  ? "grey"
                                                  : "primary",
                                              color: "dark",
                                            }}
                                            key={"dow-uid-" + wd}
                                            value={wd}
                                            onClick={(e) => {
                                              handleWODClick(e, item, index);

                                            }}
                                          >
                                            {wd[0]}
                                          </Button>
                                        ) : (
                                          <Button
                                            className="wd-name"
                                            variant="outlined"
                                            sx={{
                                              pt: 1,
                                              pb: 1,
                                              backgroundColor:
                                                item.schedule.dow_schedule.indexOf(wd) !== -1
                                                  ? "grey"
                                                  : "primary",
                                              color: "dark",
                                              border: "1px solid rgba(25, 118, 210)",
                                            }}
                                            key={"dow-uid-" + wd}
                                            size="small"
                                            value={wd}
                                            onClick={(e) => {
                                              handleWODClick(e, item, index);
                                            }}
                                          >
                                            {wd[0]}
                                          </Button>
                                        );
                                      })}
                                    </ButtonGroup>
                                    {/* </Tooltip> */}
                                  </div>
                                </Box>
                              }



                              <Box className="field-controls">
                                <div className="control-label">ENDS</div>
                                <div className="control-fields">
                                  <FormControl className="radio-grp">
                                    <RadioGroup
                                      // defaultValue="never"
                                      value={item.schedule.end}
                                      name="radio-buttons-group"
                                      onChange={(e) => {
                                        let val = e.target.value;
                                        updateRequestData("schedule>end", val, index);

                                      }}
                                    >
                                      <FormControlLabel sx={{ mb: 1 }} value="never" control={<Radio />} label="Never" />
                                      <FormControlLabel value={item.schedule.end !== 'never' ? item.schedule.end : new moment().add(1, 'month').format()} control={<Radio />} label={<><Box sx={{ display: 'inline-block', pt: .5 }}>
                                        <Grid container alignItems="center">

                                          <Grid item>
                                            <div className="date-heading-large">
                                              <MobileDatePicker
                                                disableCloseOnSelect={false}
                                                inputFormat="ddd, MMM DD YYYY"
                                                value={item.schedule.end !== 'never' ? item.schedule.end : new moment().add(1, 'month').format()}
                                                onChange={(val) => {
                                                  const formated = new moment(val).format();
                                                  updateRequestData("schedule>end", formated, index);
                                                  // const id = "id_" + index;
                                                  // ui.dataChanged[id] = true;
                                                  // dispatchSettings({
                                                  //   type: "updateData",
                                                  //   id: id,
                                                  // });
                                                }}
                                                sx={{ backgroundColor: 'none' }}
                                                renderInput={(params) => (

                                                  <TextField
                                                    {...params}
                                                    value={item.schedule.end}
                                                    className="rate-textfield date-picker-input"
                                                    // variant="outlined"
                                                    // label="TO"
                                                    placeholder=""
                                                  />
                                                )}
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      </Box></>} />

                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </Box>
                            </Grid>
                            {/* <Grid container justifyContent='flex-end' sx={{ pt: 1 }}>
                              <Grid item>
                                <Button
                                  disabled={!item.isChangeRequest}
                                  onClick={() => {
                                    saveChanges(index, item.batch_id);
                                  }}
                                  variant="contained"
                                  size="small"
                                  className="btn-main save"
                                  // color="green"
                                  sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                                >
                                  Save
                                </Button>

                              </Grid>
                              <Grid item>

                                <Button
                                  disabled={!item.isChangeRequest}
                                  onClick={() => {
                                    cancelSave(index);
                                  }}
                                  variant="contained"
                                  className="btn-main cancel"
                                  // color="warning"
                                  size="small"
                                  sx={{ minWidth: "auto" }}
                                >
                                  cancel
                                </Button>
                              </Grid>
                            </Grid> */}
                          </Box>

                        </Grid>





                        <Grid item xs={8.5} position={"relative"}>
                          <Box sx={{ p: 1.5 }}>
                            <Autocomplete
                              size="small"
                              multiple
                              options={[]}
                              value={item.recipients}
                              freeSolo
                              onChange={(e, newVal) => {
                                //item.recipients;
                                updateRequestData(
                                  "recipients",
                                  newVal,
                                  index
                                );
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={"recipient-" + index}
                                    size="small"
                                    variant="filled"
                                    color="primary"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="textfield"
                                  size="small"
                                  variant="outlined"
                                  label="ADDITIONAL RECIPIENTS"
                                  placeholder="Type and press enter..."
                                />
                              )}
                            />
                          </Box>

                          <Box sx={{ pl: 1.5, pr: 1.5, mb: 5.5, mt: 1 }}>
                            <Typography
                              // variant="h5"
                              fontSize={14}
                              // color="blue.dark"
                              // fontWeight={"bold"}
                              sx={{ p: 0, pb: 1, m: 0 , fontFamily: "SFUI Medium"}}
                            >
                              Report in Batch
                            </Typography>
                            {/* <Divider /> */}
                            <Box sx={{ pt: 0, maxHeight: 300, overflow: 'auto' }}>
                              {item.reports.map((report, report_i) => {
                                return (
                                  <Grid
                                    key={"report-" + report_i}
                                    container
                                    spacing={1}
                                    sx={{ mt: 0, mb: 2 }}
                                  >
                                    <Grid item xs={ report.resource === 'market' || report.resource === 'property' ?  5.3 :  10.6}>
                                      <Box sx={{}}>
                                        <FormControl className="textfield" fullWidth size="small">
                                          <InputLabel>REPORT</InputLabel>
                                          <Select
                                            fullWidth
                                            value={report.report_id}
                                            className="field"
                                            label="REPORT"
                                            onChange={(e) => {
                                              // debugger;
                                              const value = e.target.value;
                                              let existing = compState.reports.filter(item => item.id === value);
                                              if (existing && existing.length > 0) {
                                                report.resource = existing[0].resource;
                                                //updateCompState('report', report.resource);
                                              }
                                              // report.resource = e.target.value.resource;
                                              updateRequestData(
                                                "reports>report_id",
                                                value,
                                                index,
                                                report_i
                                              );
                                            }}
                                          >
                                            {
                                             compState.reports &&  (compState.reports.map((report) => {
                                                return (<MenuItem value={report.id} name={report.resource}>
                                                  {report.name}
                                                </MenuItem>)
                                              }))
                                            }

                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Grid>

                                    {report.resource === 'market' &&
                                      <Grid item xs={5.3}>
                                        <FormControl className="textfield" fullWidth size="small" sx={{}}>
                                          <InputLabel>MARKET</InputLabel>
                                          <Select
                                            fullWidth
                                            className="field"
                                            label="MARKET"
                                            sx={{ width: "100%" }}
                                            value={report.params.market_id}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              updateRequestData(
                                                "reports>params>market_id",
                                                value,
                                                index,
                                                report_i
                                              );
                                            }}
                                          >
                                            {compState.markets && compState.markets.map(
                                              (market, index) => {
                                                return (

                                                  <MenuItem
                                                    key={"market-" + index}
                                                    value={market.id}
                                                  >
                                                    {market.name}
                                                    {/* ({market.id}) */}
                                                  </MenuItem>

                                                );
                                              }
                                            )}
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                    }

                                    {report.resource === 'property' &&
                                      <Grid item xs={5.3} alignContent="end">
                                        <Box sx={{}}>
                                          <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>PROPERTY</InputLabel>
                                            <Select
                                              fullWidth
                                              className="field"
                                              value={report.params.property_id}
                                              label="PROPERTY"
                                              onChange={(e) => {
                                                const value = e.target.value;
                                                updateRequestData(
                                                  "reports>params>property_id",
                                                  value,
                                                  index,
                                                  report_i
                                                );
                                              }}
                                            >
                                              {property_details.map((prop) => {
                                                return (
                                                  <MenuItem
                                                    key={"prop-" + prop.id}
                                                    value={prop.id}
                                                  >
                                                    {prop.name + " (" + prop.id + ")"}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </Grid>
                                    }

                                    <Grid item xs={1.4}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        //justifyContent: "end"
                                      }}
                                    >
                                      {report_i + 1 < item.reports.length && item.reports.length > 1 &&
                                        <>
                                          <IconButton onClick={(e) => {
                                            remove("reports", index, report_i);
                                          }}>
                                            {/* <RemoveCircleOutlineIcon className="inside-line delete-button" /> */}
                                            <svg style={{background: "#EBEBEB", borderRadius: "50%", padding: "3px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#E03131" viewBox="0 0 256 256"><path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128Z"></path></svg>
                                          </IconButton>
                                        </>
                                      }
{/* 
                                      {report_i + 1 < item.reports.length && item.reports.length > 1 &&
                                        <>
                                        </>
                                      } */}

                                      {report_i + 1 === item.reports.length && 
                                        <Tooltip title="Add">
                                          <>
                                            {report_i > 0 && <IconButton onClick={(e) => {
                                              remove("reports", index, report_i);
                                            }}>
                                              {/* <RemoveCircleOutlineIcon className="inside-line delete-button" /> */}                                          
                                              <svg style={{background: "#EBEBEB", borderRadius: "50%", padding: "3px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#E03131" viewBox="0 0 256 256"><path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128Z"></path></svg>
                                            </IconButton>
                                            }
                                            <IconButton onClick={(e) => {
                                              add("reports", index);
                                            }}>
                                              {/* <AddCircleOutlineRoundedIcon className="inside-line" /> */}
                                              <svg style={{background: "#EBEBEB", borderRadius: "50%", padding: "3px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00415F" viewBox="0 0 256 256"><path d="M228,128a12,12,0,0,1-12,12H140v76a12,12,0,0,1-24,0V140H40a12,12,0,0,1,0-24h76V40a12,12,0,0,1,24,0v76h76A12,12,0,0,1,228,128Z"></path></svg>                                             
                                            </IconButton>
                                          </>
                                        </Tooltip>
                                      }

                                    </Grid>
                                  </Grid>
                                );
                              })}

                            </Box>
                          </Box>

                          <Grid container justifyContent='flex-end' position={"absolute"} sx={{ bottom: 1, p: 1, gap: "10px" }}>
                            <Grid item>
                              {/* <Button
                                disabled={!item.isChangeRequest}
                                onClick={() => {
                                  saveChanges(index, item.batch_id);
                                }}
                                variant="contained"
                                size="small"
                                className="btn-main save"
                                // color="green"
                                sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                              >
                                Save
                              </Button> */}

                              <Button
                                disabled={!item.isChangeRequest}
                                onClick={() => {
                                  cancelSave(index);
                                }}
                                variant="outlined"
                                className={(item.isChangeRequest) ? "sub-navigate-button-active-cancel" : "sub-navigate-button-cancel"}
                                size="small"
                              //sx={{ mr: 1}}
                              >
                                Cancel
                              </Button>
                            </Grid>

                            <Grid item>
                              <Button
                                disabled={!item.isChangeRequest}
                                onClick={() => {
                                  saveChanges(index, item.batch_id);
                                }}
                                variant="outlined"
                                size="small"
                                className={(item.isChangeRequest) ? "sub-navigate-button-active" : "sub-navigate-button"}
                              >
                                <span style={{ display: !isrequesting ? "block" : "none" }}>
                                  Save
                                </span>
                                <span
                                  style={{
                                    display: isrequesting ? "block" : "none",
                                    paddingRight: 10,
                                  }}
                                >
                                  saving...
                                </span>
                                <CircularProgress
                                  sx={{ display: isrequesting ? "block" : "none" }}
                                  color="white"
                                  size="16px"
                                />
                              </Button>

                              {/* <Button
                                disabled={!item.isChangeRequest}
                                onClick={() => {
                                  cancelSave(index);
                                }}
                                variant="contained"
                                className="btn-main cancel"
                                // color="warning"
                                size="small"
                                sx={{ minWidth: "auto" }}
                              >
                                cancel
                              </Button> */}
                            </Grid>
                          </Grid>

                        </Grid>



                      </Grid>

                    </Collapse>
                  </Box>

                  {/* {isrequesting && (
                    <Box
                      sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.3,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 1000
                      }}
                    >
                      <CircularProgress
                        size={40}
                        sx={{
                          color: "white",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-20px",
                          marginLeft: "-20px",
                        }}
                      />
                    </Box>
                  )} */}
                </Box>
              </Paper>
            ))}

          </Box>

          <Button
            variant="outlined"
            className="add-button"
            size="small"
            sx={{ lineHeight: "auto" }}
            //disabled={!compState.reports}
            onClick={() => {
              addNewItem();
            }}
          >
            <span className="add-btn-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#333333" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>
            </span>
            <span className="add-btn-txt">Add New Report</span>
          </Button>


          {isrequesting && (
            <Box
              sx={{
                textAlighn: "center",
                backgroundColor: "dark.darkest",
                opacity: 0.8,
                position: "fixed",
                top: 40,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 100,
              }}
            >
              <CircularProgress
                size={40}
                sx={{
                  color: "white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-20px",
                  marginLeft: "-20px",
                }}
              />
            </Box>
          )}

        </Box>

        {refreshingData && (
          <Box
            sx={{
              textAlighn: "center",
              backgroundColor: "dark.darkest",
              opacity: 0.8,
              position: "fixed",
              top: 40,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 100,
            }}
          >
            <CircularProgress
              size={40}
              sx={{
                color: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-20px",
                marginLeft: "-20px",
              }}
            />
          </Box>
        )}





        <Box sx={{ pt: 2, pb: 0, mb: 2, ml: 2, mr: 2, display: "none" }}>
          <Alert
            severity="info"
            sx={{
              width: "100%",
              marginTop: 0,
              display: compState.dataStatus === 'loading' ? "flex" : "none",
            }}
          >
            Loading existing report schedules...
          </Alert>
          <Alert
            severity="success"
            sx={{
              width: "100%",
              marginTop: -2,
              display: isRequestSuccess ? "flex" : "none",
            }}
          >
            {requestSuccessMsg}
          </Alert>
          <Alert
            severity="error"
            sx={{
              width: "100%",
              marginTop: -2,
              display: isRequestError ? "flex" : "none",
            }}
          >
            {resErrorMsg}
          </Alert>
          {requestData.scheduled_report.map((item, index) => (
            <Paper className="scheduler-row report" key={"key-" + index} sx={{ mb: 2 }} variant="outlined">
              <Box
                sx={{
                  flexDirection: "column",
                  position: "relative",
                  display: "flex",
                }}
              >


                <Box
                  sx={{ maxHeight: "70vh", overflow: "auto" }}
                >
                  <Grid container spacing={0}>
                    <Grid item className="scheduler-left" xs={3.5}>
                      <Grid container sx={{ p: 1.5, pb: 0 }}>
                        <div className="rate-push-badge">{index + 1}</div>
                        <Grid item className="hotel-name" xs={12}>
                          {/* {item.editing !== 'batch_name' &&
                            <div className="field view">
                              {item.batch_name}
                              {expanded["id_" + index] &&
                                <div className="usr-axns">
                                  <IconButton size="small" onClick={(e) => toggleEditable(e, 'batch_name', index, item)}>
                                    <EditIcon />
                                  </IconButton>
                                </div>
                              }
                            </div>
                          } */}
                          {/* {item.editing == 'batch_name' && */}
                          <div className="field edit">
                            {expanded["id_" + index] && (<Input
                              className="accordian-field"
                              size="small"
                              fullWidth
                              sx={{
                                mt: 1, mr: 1,
                                textAlign: "center",
                                color: "blue.dark",
                              }}
                              value={item.batch_name}
                              placeholder="Please enter batch name..."
                              onChange={(e) => {
                                const value = e.target.value;
                                updateRequestData("batch_name", value, index);
                              }}
                            />)}
                            {!expanded["id_" + index] && item.batch_name}
                            {/* <div className="usr-axns">
                                <IconButton size="small" onClick={(e) => saveField(index, 'batch_name')}>
                                  <Tooltip title="Save">
                                    <SaveOutlinedIcon color="green" />
                                  </Tooltip>
                                </IconButton>

                                <IconButton size="small" onClick={(e) => cancelSave(index, 'batch_name')}>
                                  <Tooltip title="Cancel">
                                    <ClearOutlinedIcon color="error" />
                                  </Tooltip>
                                </IconButton>
                              </div> */}
                          </div>
                          {/* } */}
                        </Grid>
                        {/* <Grid item xs={2}>
                          <Switch
                            className="switch-button"
                            size="medium"
                            // color="green"
                            checked={item.status === "active" ? true : false}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              const val = checked ? "active" : "inactive";
                              updateRequestData("status", val, index);
                            }}
                          />
                        </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={8.5}>
                      <Grid sx={{ pt: .6 }} container justifyContent="flex-end" alignItems="end">
                        <Grid item>
                          <IconButton onClick={() => { deleteItem(index) }}>
                            <DeleteOutlineIcon className="delete-button" sx={{ fontSize: 24 }}></DeleteOutlineIcon>
                          </IconButton>
                        </Grid>
                        <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 0.3 }} />

                        <Grid item>
                          {expanded["id_" + index] &&
                            <IconButton className="collapse-arrow" onClick={() => {
                              handleExpand(index);
                            }}>
                              <KeyboardArrowUpOutlinedIcon className="up" sx={{ fontSize: 40 }} />
                            </IconButton>
                          }
                          {!expanded["id_" + index] &&
                            <IconButton className="collapse-arrow" onClick={() => {
                              handleExpand(index);
                            }}>
                              <KeyboardArrowDownOutlinedIcon className="down" sx={{ fontSize: 40 }} />
                            </IconButton>
                          }
                        </Grid>
                      </Grid>


                    </Grid>
                  </Grid>

                  <Collapse in={expanded["id_" + index]}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      overflow: "visible",
                      // border: "1px solid #000",
                    }}>

                    <Grid container spacing={0}>
                      <Grid item className="scheduler-left" xs={3.5}>
                        <Box sx={{ p: 1.5, }}>
                          <Grid container>
                            <Box className="field-controls">
                              <div className="control-label">Repeat every</div>
                              <div className="control-fields">
                                <Grid container spacing={2}>
                                  <Grid item xs={3}>
                                    <TextField size="small"
                                      fullWidth
                                      value={item.schedule.no_of_period}
                                      onChange={(e) => {
                                        // debugger;
                                        let val = e.target.value;
                                        if (!isNaN(val)) {
                                          // item.schedule.repeat.count = val;
                                          // const id = "id_" + index;
                                          // ui.dataChanged[id] = true;
                                          // dispatchSettings({
                                          //   type: "updateData",
                                          //   id: id,
                                          // });
                                          updateRequestData("schedule>no_of_period", val, index);
                                        }

                                      }}
                                      variant="outlined" />
                                  </Grid>
                                  <Grid item xs={7}>
                                    <FormControl size="small">
                                      <Select
                                        value={item.schedule.frequency}
                                        onChange={(e) => {
                                          // debugger;
                                          let val = e.target.value;
                                          // item.schedule.repeat.period = val;
                                          updateRequestData("schedule>frequency", val, index);
                                        }}
                                      >
                                        <MenuItem value="daily">Day</MenuItem>
                                        <MenuItem value="weekly">Week</MenuItem>
                                        {/* <MenuItem value="monthly">Month</MenuItem> */}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </div>
                            </Box>
                            {item.schedule.frequency !== 'daily' &&
                              <Box className="field-controls">
                                <div className="control-label">REPEAT ON</div>
                                <div className="control-fields">
                                  <Tooltip
                                    title="Select day of week"
                                    arrow
                                    disableInteractive
                                    followCursor
                                  >
                                    <ButtonGroup
                                      disableElevation
                                      className="dow-group"
                                      variant="contained"
                                      fullWidth
                                      sx={{ mr: 2 }}
                                    >
                                      {ui.dow.map((wd) => {
                                        const currIndex = item.schedule.dow_schedule.indexOf(wd);
                                        return currIndex !== -1 ? (
                                          <Button
                                            className="wd-name active"
                                            size="small"
                                            variant="outlined"
                                            sx={{
                                              pt: 1,
                                              pb: 1,
                                              backgroundColor:
                                                item.schedule.dow_schedule.indexOf(wd) !== -1
                                                  ? "grey"
                                                  : "primary",
                                              color: "dark",
                                            }}
                                            key={"dow-uid-" + wd}
                                            value={wd}
                                            onClick={(e) => {
                                              handleWODClick(e, item, index);

                                            }}
                                          >
                                            {wd[0]}
                                          </Button>
                                        ) : (
                                          <Button
                                            className="wd-name"
                                            variant="outlined"
                                            sx={{
                                              pt: 1,
                                              pb: 1,
                                              backgroundColor:
                                                item.schedule.dow_schedule.indexOf(wd) !== -1
                                                  ? "grey"
                                                  : "primary",
                                              color: "dark",
                                              border: "1px solid rgba(25, 118, 210)",
                                            }}
                                            key={"dow-uid-" + wd}
                                            size="small"
                                            value={wd}
                                            onClick={(e) => {
                                              handleWODClick(e, item, index);
                                            }}
                                          >
                                            {wd[0]}
                                          </Button>
                                        );
                                      })}
                                    </ButtonGroup>
                                  </Tooltip>
                                </div>
                              </Box>
                            }
                            <Box className="field-controls">
                              <div className="control-label">ENDS</div>
                              <div className="control-fields">
                                <FormControl className="radio-grp">
                                  <RadioGroup
                                    // defaultValue="never"
                                    value={item.schedule.end}
                                    name="radio-buttons-group"
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      updateRequestData("schedule>end", val, index);

                                    }}
                                  >
                                    <FormControlLabel sx={{ mb: 1 }} value="never" control={<Radio />} label="Never" />
                                    <FormControlLabel value={item.schedule.end !== 'never' ? item.schedule.end : new moment().add(1, 'month').format()} control={<Radio />} label={<><Box sx={{ display: 'inline-block', pt: .5 }}>
                                      <Grid container alignItems="center">
                                        <Grid item>
                                          <CalendarMonthOutlinedIcon className="inside-line" color="primary" sx={{ fontSize: 34 }} />
                                        </Grid>
                                        <Grid item>
                                          <div className="date-heading-large">
                                            <MobileDatePicker
                                              disableCloseOnSelect={false}
                                              inputFormat="ddd, MMM DD YYYY"
                                              value={item.schedule.end !== 'never' ? item.schedule.end : new moment().add(1, 'month').format()}
                                              onChange={(val) => {
                                                const formated = new moment(val).format();
                                                updateRequestData("schedule>end", formated, index);
                                                // const id = "id_" + index;
                                                // ui.dataChanged[id] = true;
                                                // dispatchSettings({
                                                //   type: "updateData",
                                                //   id: id,
                                                // });
                                              }}
                                              sx={{ backgroundColor: 'none' }}
                                              renderInput={(params) => (

                                                <TextField
                                                  {...params}
                                                  value={item.schedule.end}
                                                  className="rate-textfield date-picker-input"
                                                  // variant="outlined"
                                                  // label="TO"
                                                  placeholder=""
                                                />
                                              )}
                                            />
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Box></>} />

                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </Box>
                          </Grid>
                          <Grid container justifyContent='flex-end' sx={{ pt: 1 }}>
                            <Grid item>
                              <Button
                                disabled={!item.isChangeRequest}
                                onClick={() => {
                                  saveChanges(index, item.batch_id);
                                }}
                                variant="contained"
                                size="small"
                                className="btn-main save"
                                // color="green"
                                sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                              >
                                Save
                              </Button>

                            </Grid>
                            <Grid item>

                              <Button
                                disabled={!item.isChangeRequest}
                                onClick={() => {
                                  cancelSave(index);
                                }}
                                variant="contained"
                                className="btn-main cancel"
                                // color="warning"
                                size="small"
                                sx={{ minWidth: "auto" }}
                              >
                                cancel
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>

                      </Grid>
                      <Grid item xs={8.5}>
                        <Box sx={{ p: 1.5 }}>
                          <Autocomplete
                            size="small"
                            multiple
                            options={[]}
                            value={item.recipients}
                            freeSolo
                            onChange={(e, newVal) => {
                              //item.recipients;
                              updateRequestData(
                                "recipients",
                                newVal,
                                index
                              );
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  key={"recipient-" + index}
                                  size="small"
                                  variant="filled"
                                  color="primary"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className="textfield"
                                size="small"
                                variant="outlined"
                                label="ADDITIONAL RECIPIENTS"
                                placeholder="Type and press enter..."
                              />
                            )}
                          />
                        </Box>
                        <Box sx={{ p: 1.5 }}>
                          <Typography
                            // variant="h5"
                            fontSize={14}
                            // color="blue.dark"
                            // fontWeight={"bold"}
                            sx={{ p: 0, pb: 1, m: 0 }}
                          >
                            REPORT IN BATCH
                          </Typography>
                          {/* <Divider /> */}
                          <Box sx={{ pt: 0, maxHeight: 210, overflow: 'auto' }}>
                            {item.reports.map((report, report_i) => {
                              return (
                                <Grid
                                  key={"report-" + report_i}
                                  container
                                  spacing={1}
                                  sx={{ mt: 0, mb: 1 }}
                                >
                                  <Grid item xs={5}>
                                    <Box sx={{}}>
                                      <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>REPORT</InputLabel>
                                        <Select
                                          fullWidth
                                          value={report.report_id}
                                          className="field"
                                          label="REPORT"
                                          onChange={(e) => {
                                            // debugger;
                                            const value = e.target.value;
                                            let existing = compState.reports.filter(item => item.id === value);
                                            if (existing && existing.length > 0) {
                                              report.resource = existing[0].resource;
                                              //updateCompState('report', report.resource);
                                            }
                                            // report.resource = e.target.value.resource;
                                            updateRequestData(
                                              "reports>report_id",
                                              value,
                                              index,
                                              report_i
                                            );
                                          }}
                                        >
                                          {
                                            compState.reports && (compState.reports.map((report) => {
                                              return (<MenuItem value={report.id} name={report.resource}>
                                                {report.name}
                                              </MenuItem>)
                                            }))
                                          }

                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </Grid>
                                  {report.resource === 'market' &&
                                    <Grid
                                      item
                                      xs={5}

                                    >
                                      <FormControl className="textfield" fullWidth size="small" sx={{}}>
                                        <InputLabel>MARKET</InputLabel>
                                        <Select
                                          fullWidth
                                          className="field"
                                          label="MARKET"
                                          sx={{ width: "100%" }}
                                          value={report.params.market_id}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            updateRequestData(
                                              "reports>params>market_id",
                                              value,
                                              index,
                                              report_i
                                            );
                                          }}
                                        >
                                          {compState.markets && compState.markets.map(
                                            (market, index) => {
                                              return (

                                                <MenuItem
                                                  key={"market-" + index}
                                                  value={market.id}
                                                >
                                                  {market.name}
                                                  {/* ({market.id}) */}
                                                </MenuItem>

                                              );
                                            }
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  }
                                  {report.resource === 'property' &&
                                    <Grid item xs={5} alignContent="end">
                                      <Box sx={{}}>
                                        <FormControl className="textfield" fullWidth size="small">
                                          <InputLabel>PROPERTY</InputLabel>
                                          <Select
                                            fullWidth
                                            className="field"
                                            value={report.params.property_id}
                                            label="PROPERTY"
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              updateRequestData(
                                                "reports>params>property_id",
                                                value,
                                                index,
                                                report_i
                                              );
                                            }}
                                          >
                                            {property_details.map((prop) => {
                                              return (
                                                <MenuItem
                                                  key={"prop-" + prop.id}
                                                  value={prop.id}
                                                >
                                                  {prop.name + " (" + prop.id + ")"}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </Box>
                                    </Grid>
                                  }
                                  <Grid
                                    item
                                    xs={2}
                                    sx={{

                                    }}
                                  >
                                    {report_i + 1 < item.reports.length && item.reports.length > 1 &&
                                      <>
                                        <IconButton onClick={(e) => {
                                          remove("reports", index, report_i);
                                        }}>
                                          <RemoveCircleOutlineIcon className="inside-line delete-button" />
                                        </IconButton>


                                      </>
                                    }
                                    {report_i + 1 < item.reports.length && item.reports.length > 1 &&
                                      <>



                                      </>
                                    }
                                    {report_i + 1 === item.reports.length && <Tooltip title="Add">
                                      <>
                                        {report_i > 0 && <IconButton onClick={(e) => {
                                          remove("reports", index, report_i);
                                        }}>
                                          <RemoveCircleOutlineIcon className="inside-line delete-button" />
                                        </IconButton>
                                        }
                                        <IconButton onClick={(e) => {
                                          add("reports", index);
                                        }}>
                                          <AddCircleOutlineRoundedIcon className="inside-line" />
                                        </IconButton>
                                      </>
                                    </Tooltip>
                                    }

                                  </Grid>
                                </Grid>
                              );
                            })}

                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                  </Collapse>
                </Box>
                {isrequesting && (
                  <Box
                    sx={{
                      textAlighn: "center",
                      backgroundColor: "dark.darkest",
                      opacity: 0.3,
                      position: "fixed",
                      top: 40,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      zIndex: 1000
                    }}
                  >
                    <CircularProgress
                      size={40}
                      sx={{
                        color: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-20px",
                        marginLeft: "-20px",
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Paper>
          ))}
          {/* <Box sx={{ mt: 1, mb: 2, display: 'none' }}>
            {requestData.scheduled_report.map((item, index) => {
              return (
                <Paper
                  key={"key-" + index}
                  className="pricing-rules item"
                  elevation={1}
                  sx={{ mb: 1, overflow: "hidden", border: "1px solid #d1d1d1", borderRadius: "4px", marginBottom: "20px" }}
                >
                  <Grid
                    container
                    className="header"
                    sx={{
                      backgroundColor: expanded["id_" + index]
                        ? "blue.main"
                        : "grey.lighter",


                    }}
                  >
                    <Grid item xs={4}>
                      <Badge
                        badgeContent={index + 1}
                        variant="string"
                        color="dark"
                        sx={{
                          position: "absolute",
                          color: "white.main",
                          fontWeight: "bold",
                          fontSize: 16,
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      />
                      <Input
                        className="accordian-field"
                        size="small"
                        sx={{
                          ml: 5,
                          mr: 2,
                          mt: 1,
                          textAlign: "center",
                          color: "blue.dark",
                        }}
                        value={item.batch_name}
                        onChange={(e) => {
                          const value = e.target.value;
                          updateRequestData("batch_name", value, index);
                        }}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      {" "}
                    </Grid>

                    <Grid item xs={2} textAlign="right">
                      <Switch
                        className="switch-button"
                        size="medium"
                        // color="green"
                        checked={item.status === "active" ? true : false}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const val = checked ? "active" : "inactive";
                          updateRequestData("status", val, index);
                        }}
                      />
                      <Typography
                        sx={{
                          // color: "blue.dark",
                          display: "inline-block",
                          mr: 2,
                        }}
                        visibility={item.active ? "hidden" : "visible"}
                      >
                        Activate
                      </Typography>
                    </Grid>
                    <Grid item xs={3} textAlign="right">
                      <Button
                        disabled={!item.isChangeRequest}
                        onClick={() => {
                          saveChanges(index, item.batch_id);
                        }}
                        variant="contained"
                        size="small"
                        className="navigate-button"
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                      >
                        Save
                      </Button>
                      <Button
                        disabled={!item.isChangeRequest}
                        onClick={() => {
                          cancelSave(index);
                        }}
                        variant="contained"
                        className="navigate-button"
                        // color="warning"
                        size="small"
                        sx={{ minWidth: "auto" }}
                      >
                        cancel
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        className="delete-icon"
                        // color="white"
                        sx={{
                          backgroundColor: "#333",
                          // color: "error.main",
                          minWidth: "auto",
                          ml: 1,
                          mr: 1,
                        }}
                        onClick={() => {
                          deleteItem(index);
                        }}
                      >
                        <Tooltip title="Delete">
                          <DeleteOutlineOutlinedIcon fontSize="small"
                            color="#fff"> </DeleteOutlineOutlinedIcon>
                        </Tooltip>
                      </Button>
                      <Button
                        variant="contained"
                        type="button"
                        className="navigate-button"

                        sx={{
                          backgroundColor: "#333",
                          borderRadius: 0,
                          border: "none",
                          margin: 0,
                          pb: 1.5,
                        }}
                        onClick={() => {
                          handleExpand(index);
                        }}
                      >
                        <KeyboardArrowDownOutlined fontSize="small"
                          sx={{
                            display: expanded["id_" + index]
                              ? "none"
                              : "inline-block",
                          }}
                          className="arrow-down"
                        />
                        <KeyboardArrowUpOutlinedIcon fontSize="small"
                          sx={{
                            display: expanded["id_" + index]
                              ? "inline-block"
                              : "none",
                          }}
                          className="arrow-up"
                        />
                      </Button>
                    </Grid>
                  </Grid>

                  <Collapse
                    in={expanded["id_" + index]}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      overflow: "visible",
                      // border: "1px solid #000",
                    }}
                  >
                    <Box sx={{ p: 1, mt: 1, mb: 1 }}>
                      <Grid container spacing={1} className="header">
                        <Grid item xs={8}>
                          <Box sx={{}}>
                            <Autocomplete
                              size="small"
                              multiple
                              options={[]}
                              value={item.recipients}
                              freeSolo
                              onChange={(e, newVal) => {
                                //item.recipients;
                                updateRequestData(
                                  "recipients",
                                  newVal,
                                  index
                                );
                              }}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    key={"recipient-" + index}
                                    size="small"
                                    variant="filled"
                                    color="primary"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="textfield"
                                  size="small"
                                  variant="outlined"
                                  label="ADDITIONAL RECIPIENTS"
                                  placeholder="Email ID"
                                />
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box sx={{}}>
                            <FormControl className="textfield" fullWidth size="small">
                              <InputLabel>FREQUENCY</InputLabel>
                              <Select
                                className="field"
                                value={item.schedule.frequency}
                                label="FREQUENCY"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  updateRequestData(
                                    "schedule>frequency",
                                    value,
                                    index
                                  );
                                }}
                              >
                                <MenuItem value="daily" def>
                                  Daily
                                </MenuItem>
                                <MenuItem value="weekly">Weekly</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            sx={{
                              visibility:
                                item.schedule.frequency === "weekly"
                                  ? "visible"
                                  : "hidden",
                            }}
                          >
                            <FormControl className="textfield" fullWidth size="small">
                              <InputLabel>DOW</InputLabel>
                              <Select
                                className="field"
                                value={item.schedule.dow_schedule || "mon"}
                                label="DOW"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  updateRequestData(
                                    "schedule>dow_schedule",
                                    value,
                                    index
                                  );
                                }}
                              >
                                <MenuItem value="mon">Monday</MenuItem>
                                <MenuItem value="tue">Tuesday</MenuItem>
                                <MenuItem value="wed">Wednesday</MenuItem>
                                <MenuItem value="thu">Thursday</MenuItem>
                                <MenuItem value="fri">Friday</MenuItem>
                                <MenuItem value="sat">Saturday</MenuItem>
                                <MenuItem value="sun">Sunday</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Typography
                        // variant="h5"
                        fontSize={14}
                        // color="blue.dark"
                        // fontWeight={"bold"}
                        sx={{ pt: 2, pb: 1 }}
                      >
                        REPORT IN BATCH
                      </Typography>
                      <Divider />
                      <Box sx={{ pt: 0 }}>
                        {item.reports.map((report, report_i) => {
                          return (
                            <Grid
                              key={"report-" + report_i}
                              container
                              spacing={1}
                              sx={{ mt: 2, mb: 1 }}
                            >
                              <Grid item xs={4}>
                                <Box sx={{}}>
                                  <FormControl className="textfield" fullWidth size="small">
                                    <InputLabel>REPORT</InputLabel>
                                    <Select
                                      value={report.report_id}
                                      className="field"
                                      label="REPORT"
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        updateRequestData(
                                          "reports>report_id",
                                          value,
                                          index,
                                          report_i
                                        );
                                      }}
                                    >
                                      <MenuItem value="bi_market_monthly_summary_export">
                                        Market Detail Analysis
                                      </MenuItem>
                                      <MenuItem value="bi_hotel_monthly_summary_export">
                                        Hotel Detail Analysis
                                      </MenuItem>
                                      <MenuItem value="HMSReport">
                                        365 Day Pace &amp; Pickup
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{
                                  display:
                                    report.report_id ===
                                      "bi_market_monthly_summary_export"
                                      ? "inline-flex"
                                      : "none",
                                }}
                              >
                                <FormControl className="textfield" fullWidth size="small" sx={{}}>
                                  <InputLabel>MARKET</InputLabel>
                                  <Select
                                    className="field"
                                    label="MARKET"
                                    sx={{ width: "100%" }}
                                    value={report.params.market_id}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      updateRequestData(
                                        "reports>params>market_id",
                                        value,
                                        index,
                                        report_i
                                      );
                                    }}
                                  >
                                    {profile.market_settings && profile.market_settings.map(
                                      (market, index) => {
                                        return (
                                          market.active && (
                                            <MenuItem
                                              key={"market-" + index}
                                              value={market.market_id}
                                            >
                                              {market.market_name} (
                                              {market.market_id})
                                            </MenuItem>
                                          )
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={3} alignContent="end">
                                <Box sx={{}}>
                                  <FormControl className="textfield" fullWidth size="small">
                                    <InputLabel>PROPERTY</InputLabel>
                                    <Select
                                      className="field"
                                      value={report.params.property_id}
                                      label="PROPERTY"
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        updateRequestData(
                                          "reports>params>property_id",
                                          value,
                                          index,
                                          report_i
                                        );
                                      }}
                                    >
                                      {property_details.map((prop) => {
                                        return (
                                          <MenuItem
                                            key={"prop-" + prop.id}
                                            value={prop.id}
                                          >
                                            {prop.name + " (" + prop.id + ")"}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                sx={{
                                  visibility:
                                    report_i === 0 ? "hidden" : "visible",
                                }}
                              >
                                <Tooltip title="Remove">
                                  <Button
                                    type="button"
                                    variant="contained"
                                    size="small"
                                    color="dark"
                                    sx={{
                                      // fontWeight: "bold",
                                      minWidth: "auto",
                                      textTransform: "none",
                                      color: "#fff",
                                    }}
                                    onClick={(e) => {
                                      remove("reports", index, report_i);
                                    }}
                                  >
                                    <RemoveSharpIcon
                                      sx={{ mt: 0.3, mb: 0.3 }}
                                    />
                                  </Button>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          );
                        })}
                        <Tooltip title="Add">
                          <Button
                            type="button"
                            variant="contained"
                            size="small"

                            color="dark"
                            sx={{
                              // fontWeight: "bold",
                              minWidth: "auto",
                              textTransform: "none",
                              color: "white.main",
                            }}
                            onClick={(e) => {
                              add("reports", index);
                            }}
                          >
                            <AddSharpIcon sx={{ mt: 0.3, mb: 0.3 }} />
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Collapse>
                </Paper>
              );
            })}
          </Box> */}
        </Box>

      </LocalizationProvider>
    </>
  );
}

const initalState = {
  data: { list: [] },
  ui: {
    //Any kind of interaction with server is a request
    request: {
      resType: null,
      status: "idle", // May have these values "idle", "loading", "loaded", "empty", "error"
      message: "",
    },
    expanded: {},
    mode: {},
    dow: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
    dataChanged: {},
    schedule_time: new moment(),
  },
};