import React, { useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';

import UpdateRestrictionsModal from '../Modal/UpdateRestrictionsModal'
import RestrictionsToolTip from './RestrictionsToolTip';
import { deepCopy, getAppData, getObjectFilter } from '../../app/util';
import { useSelector } from 'react-redux';

export const getClassName = (type) => {
    let classStyle = "btn-cta";
    if (type.toLowerCase() === "cta") {
        classStyle = "btn-cta";
    } else if (type.toLowerCase() === "ctd") {
        classStyle = "btn-ctd";
    } else if (type.toLowerCase() === "cls") {
        classStyle = "btn-cls";
    } else if (type.toLowerCase() === "open") {
        classStyle = "btn-open";
    } else if (type.toLowerCase() === "close") {
        classStyle = "btn-close";
    } else if (type.toLowerCase() === "min_los") {
        classStyle = "btn-min_los";
    } else if (type.toLowerCase() === "max_los") {
        classStyle = "btn-max_los";
    }

    return classStyle;
}
export const getResName = (type, value) => {
    let resDetail = {};
    let resName;
    if (type.toLowerCase() === "cta") {
        resDetail.resName = "cta";
        resDetail.fullName = "Close To Arrival";
    } else if (type.toLowerCase() === "ctd") {
        resDetail.resName = "ctd";
        resDetail.fullName = "Close To Departure";
    } else if (type.toLowerCase() === "cls") {
        resDetail.resName = "cls";
        resDetail.fullName = "Close";
    } else if (type.toLowerCase() === "open") {
        resDetail.resName = "open";
        resDetail.fullName = "Open";
    } else if (type.toLowerCase() === "close") {
        resDetail.resName = "close";
        resDetail.fullName = "Close";
    } else if (type.toLowerCase() === "min_los") {
        resDetail.resName = "mn " + value;
        resDetail.fullName = "Minimum Length Of Stay";
    } else if (type.toLowerCase() === "max_los") {
        resDetail.resName = "mx " + value;
        resDetail.fullName = "Maximum Length Of Stay";
    }
    return resDetail;
}


function RestrictionTableCell(props) {
    const blankRestriction = { cta: false, ctd: false, close: false };
    const { editable, recData, data, cur, stay_date, room_type, houseLvlRes } = props;
    const { auth, app } = useSelector(state => state);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [toolTipOpen, setToolTipOpen] = useState(null);
    let recArr = [];
    let recAll = [];
    const { workspace_controls, roomTypeRestrictions } = app_state;
    const [current, setCurrent] = useState( deepCopy({ ...blankRestriction, ...cur }))
    // let current = null;


    const [compState, setCompState] = useState({
        modalOpen: false, entryFields: [],
        cur: cur ? deepCopy({ ...blankRestriction, ...cur }) : deepCopy(blankRestriction),
        entry: deepCopy(blankRestriction)
    });
    
    useEffect(()=>{
        let current = null;
        //if(app_state.roomTypeRestrictions.length>0){
            if(workspace_controls.isRestrictionsRoomType){
                const rest = app_state.roomTypeRestrictions.find(item=> item.stay_date === stay_date);
                if(rest && rest.stay_date && rest[room_type]){
                     current = {stay_date: rest.stay_date, ...rest[room_type].entry};
                     current.updatedEntry = true;
                }
              
            }
            if(current){
                current.min_los = current.min_los === 1 ? '' : current.min_los;
                current.max_los = current.max_los === 1 ? '' : current.max_los;
            } else {
                current = deepCopy({ ...blankRestriction, ...cur });
            }
            setCurrent(state=>{
                return {...current};
            })
        //}
    },[app_state.roomTypeRestrictions, room_type])
    useEffect(() => {
        // setCurrent(state=>{
        //     return {...data};
        // })
        let blankData = {...blankRestriction, room_type:props.room_type, stay_date:props.stay_date};
        setCompState(state=>{
            return {...state, cur:cur ? deepCopy({ ...blankRestriction, ...cur }) : deepCopy(blankRestriction),
            data:data ? deepCopy({ ...blankData, ...data }) : deepCopy(blankData) }
         })
    }, [props.data])
    const handleCellClick = (entry) => {


        return (event) => {
            //event.stopPropagation();
            setAnchorEl(null);
            setToolTipOpen(false)
            let inArray = false;
            console.log(props, compState);
            event.target.classList.forEach(item=>{
                if(["update-restrictions-current-input",
                        "update-restrictions", "res-rules-display", 
                        "res-rule"].includes(item)){
                            inArray=true;
                }
            })
            if(!inArray){
                return;
            }
            let entryFields = [];
            if (data) {
                let keys = Object.keys(entry);
                // debugger;
                keys.map((key) => {
                    if (['cta', 'ctd', 'close'].includes(key) && entry[key]) {
                        entryFields.push(key);
                    }
                });
            }
            setCompState(state => {
                return { ...state, modalOpen: true, entryFields, entry: entry };
            });
        }
    }

    const handleModalClose = () => {
        setCompState(state => {
            return { ...state, modalOpen: false };
        })
    }
    const chipArrAll =[];
    if(houseLvlRes){
        if(houseLvlRes.cta){
            chipArrAll.push(<span className="chip btn-cta">CTA</span>)
        }
        if(houseLvlRes.ctd){
            chipArrAll.push(<span className="chip btn-ctd">CTD</span>)
        }
        if(houseLvlRes.close){
            chipArrAll.push(<span className="chip btn-cls">CLS</span>)
        }
        if(houseLvlRes.min_los && houseLvlRes.min_los > 1 ){
            chipArrAll.push(<span className="chip btn-min_los">MN {houseLvlRes.min_los}</span>)
        }if(houseLvlRes.max_los &&  houseLvlRes.max_los > 1){
            chipArrAll.push(<span className="chip btn-max_los">MX {houseLvlRes.max_los}</span>)
        }
        
    }
    if (recData ) {
        // debugger;
        if(recData.cta){
            recAll.push(<span className="res-rule res-cta">CTA</span>)
        }
        if(recData.ctd){
            recAll.push(<span className="res-rule res-ctd">CTD</span>)
        }
        if(recData.close){
            recAll.push(<span className="res-rule res-cls">CLS</span>)
        }
        if(recData.min_los && recData.min_los > 1 ){
            recAll.push(<span className="res-rule res-min_los">MN {recData.min_los}</span>)
        }if(recData.max_los &&  recData.max_los > 1){
            recAll.push(<span className="res-rule res-max_los">MX {recData.max_los}</span>)
        }
        recArr = recAll.filter((item, index) =>{
            if(index <2)
            return item;
        });
    }
    const { modalOpen, entry } = compState;
    const chipArray =[];
    if(current.cta){
        chipArray.push(<span className="res-rule res-cta">CTA</span>)
    }
    if(current.ctd){
        chipArray.push(<span  className="res-rule res-ctd">CTD</span>)
    }
    if(current.close){
        chipArray.push(<span   className="res-rule res-cls">CLS</span>)
    }
    if(current.min_los > 1 && current.min_los !== 0){
        chipArray.push(<span className="res-rule res-min_los">MN {current.min_los}</span>)
    }if(current.max_los > 1 && current.max_los !== 0){
        chipArray.push(<span className="res-rule res-max_los">MX {current.max_los}</span>)
    }
    const displayChips =chipArray.filter((item, index) =>{
        if(index <2)
        return item;
    });
    

    if(!editable){
        return (
            <Box className="update-restrictions" sx={{ position: 'relative', "min-height": 16, "max-height": '100%' }}>
                <Box sx={{ position: 'absolute', top: -5, pl: 0.5, pt: .6, left: 0, width: '100%', "min-height": 26, height: '100%', '&:hover .add-restriction-blanck': { visibility: 'visible' } }}>
                    <div className="res-rules-display">
                        { recArr}
                        {/* {(recAll.length>2 || chipArrAll.length>1) && (<RoomTypeRestrictionsList chipArrAll={chipArrAll} restrictionHouseData={{}}
                            isRestrictionsRoomType={workspace_controls.isRestrictionsRoomType}
                         roomTypeRestdata={[recData]} /> )} */}
                     </div>
                </Box>
            </Box>
        )
    }
    return( <><Box className="update-restrictions" onClick={handleCellClick(current)} 
     sx={{ position: 'relative', "min-height": 16, "max-height": '100%' }}>
        <Box 
            className={"update-restrictions-current-input " + (current.updatedEntry === true  ? "updated-entry" :"")}
            sx={{  borderRadius: "6px", position: 'absolute', top: -5, pl: 0.4, pt: .5, left: 0, width: '100%', "min-height": 26, height: '100%', cursor: 'pointer', 
            //'&:hover': { border: '1px solid #616161', borderCollapse: 'collapse' }, 
            '&:hover .add-restriction-blanck': { visibility: 'visible' } }}>
            <div className="res-rules-display">
                    { displayChips}
                    {(chipArray.length>2 || chipArrAll.length>=1)&& (<RestrictionsToolTip isRestrictionsRoomType={workspace_controls.isRestrictionsRoomType}
                     chipArrAll={chipArrAll} restrictionHouseData={{}} roomTypeRestdata={[current]}
                     toolTipOpen={toolTipOpen} openToolTip={(event)=>{
                       
                        setAnchorEl(event.currentTarget)
                        setToolTipOpen(true);
                     }} setAnchorEl={setAnchorEl} anchorEl={anchorEl} /> )}
            
            </div>
        </Box>
    </Box>
        {modalOpen && <UpdateRestrictionsModal {...props} 
        modalOpen={modalOpen} handleModalClose={handleModalClose} 
        entryFields={compState.entryFields} entry={deepCopy(compState.entry)} 
        cur={deepCopy(compState.cur)} data={[deepCopy(compState.cur)]} />}
        </>
    )
}

export default RestrictionTableCell;
