import React, { useEffect, useState } from "react";
import { Table, TableCell, TableContainer, TableRow, TableBody, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CloseSharp as CloseSharpIcon } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import moment from 'moment';
import {
    dateFormat, getSdlyDate, daysCountFromRange, deepCopy, getAppData, getCorrectValue, getFromState, getPermissions, getTransformedSegments, in_array, sendMultipleAPIs, truncate,
    isDateInRange, getDemandImpactName, getDemandImpactColor
} from '../../app/util';
import { useDispatch, useSelector } from "react-redux";
import dataService, { fetchDataSimple } from '../../services/data.service';
import RatePlanList from './RatePlanList';
import RatePlanListTotal from './RatePlanListTotal';
import './rate-plan-popup.scss'
import RatePlanHeader from './RatePlanHeader';
import MultipleTileOptions from './MultipleTileOptions';
import DataService from "../../services/data.service";
import { UPDATE_STATE, NOTE_LETTER_LIMIT } from '../../actions/types';


export default function EventsPopup(props) {

    const dispatch = useDispatch();

    const { current_state } = useSelector(
        (state) => state.app
    );
    const { app_state, ui_state } = current_state;
    const { notes, property_id, property_name, avgImpactData, eventList } = app_state;
    const { comp_as_of_date, as_of_date } = app_state;
    let stay_date = moment(props.params.stay_date);

    const formatted_stay_date = dateFormat(stay_date, "YYYY-MM-DD");
    const avgImpactDataForStayDate = avgImpactData && avgImpactData.find(item => item.date === formatted_stay_date);

    const avgImpactColor = avgImpactDataForStayDate !== undefined ? getDemandImpactColor(avgImpactDataForStayDate.demand_impact) : "transparent";

    const filteredEventList = eventList.filter(event =>
        isDateInRange(formatted_stay_date, event.start_date, event.end_date) && event.is_ignored === false
    );

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        //Tracking analytics
        // debugger;
        const { property_id, property_name, workspace_controls, primary_stay_range } = app_state;
        // const { title, event_id, category, created_at, created_by, demand_impacts, description, start_date, end_date, last_edited_at, last_edited_by } = response.data.data;
        // const { demand_impact, day_type } = demand_impacts[0];
        window.analyze.track("view_event", {
            "selected_tile": ui_state.active_tiles.join(),
            // primary_stay_range,
            property_id, property_name,
            "Stay Date": formatted_stay_date
        }, {}, {
            app_state: {
                workspace_controls,
                // data_load: true
            },
            ui_state: { 
                // showspinner: true 
            }
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // if (in_array(ui_state.active_tiles, ["otb_v2_multi_segment", "bi_pace_comparison", "bi_pickup_analysis", "DailyRatesendStatus", "rm_room_type_ratesend_status", "restrictions_push_status"])) {
    //     return;
    // }

    return (
        <div className='rateplan-details' style={props.params.isTotalRow === 'Total' && filteredEventList.length === 0 ? { opacity: 0 } : { paddingRight: "0px" }}>
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ lineHeight: 1, fontSize: "18px", mt: "-7px" }}
                className={filteredEventList.length === 0 ? "event-icon-div no-icon" : "event-icon-div"}
            >
                {filteredEventList.length === 0 ? null :
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill={avgImpactColor} viewBox="0 0 256 256">
                        <path d="M246.66,132.44,201,200.88A16,16,0,0,1,187.72,208H32a8,8,0,0,1-6.66-12.44L70.39,128l-45-67.56A8,8,0,0,1,32,48H187.72A16,16,0,0,1,201,55.12l45.63,68.44A8,8,0,0,1,246.66,132.44Z"></path>
                    </svg>
                }
            </div>
            <Menu
                className='rateplan-details'
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}

                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        mt: -3,
                        ml: 2,
                        borderRadius: "8px !important",
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                <Box className='rateplan-box events'>
                    <div className='rateplan-box-title'>
                        {(stay_date &&
                            <span>{moment(stay_date).format("ddd, MMM DD, YYYY")}</span>
                        )}
                    </div>
                    <Box className="scrollable-container">
                        <Box className="rateplan-box-content" >
                            {/* Events to be looped */}
                            {filteredEventList.map((event, index) => (
                                <Box>
                                    <Box className='events-list'>
                                        <Box>
                                            <div className="main-title">{event.title}</div>
                                            {event.demand_impacts.map((impact, impactIndex) => (
                                                impact.date !== formatted_stay_date ? null :
                                                    <div className="sub-title">{impact.day_type}</div>
                                            ))}
                                        </Box>
                                        <Box pt={1.5} sx={{ width: "110px" }}>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div className="main-title">Type</div>
                                                <div className="main-title">Impact</div>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                <div className="sub-title">{event.category}</div>
                                                {event.demand_impacts.map((impact, impactIndex) => (
                                                    impact.date !== formatted_stay_date ? null : (
                                                        <div
                                                            key={impactIndex}
                                                            className="impact-color"
                                                            style={{ borderBottom: `5px solid ${getDemandImpactColor(impact.demand_impact)}` }}
                                                        ></div>
                                                    )
                                                ))}
                                            </div>
                                        </Box>
                                    </Box>
                                    {filteredEventList.length === index + 1 ? null :
                                        <div className="divider"></div>
                                    }
                                </Box>
                            ))}
                            {/* Loop ends here */}
                        </Box>
                    </Box>
                </Box>

            </Menu>
        </div>
    );
}