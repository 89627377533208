import {
    DATA_SUCCESS, DATA_FAILED,
    UPDATE_STATE, STATE_HIST
    , LOGOUT
    ,SYSTEM_SETTINGS_UPDATED
    ,REPORT_SCHEDULE_SUCCESS
    ,RATEPUSH_SCHEDULE_SUCCESS
  } from "../actions/types";
 import {deepCopy} from "../app/util";
  const max_hist =5;
  const initialState ={ current_state:{}, prev_state:{}, app_state_hist:[]} ;
  
  export default function (state = initialState, action) {
    // console.log("Reducer Called UPDATE_STATE called", action); 
    const { type, payload } = action;
    switch (type) {
      case "USER_LOGOUT":
        if (action.type === 'USER_LOGOUT') {
          // debugger;
          return { ...initialState };
        }
      break;
      case REPORT_SCHEDULE_SUCCESS:
        return {
          ...state,
          scheduler_settings: {
            report: payload
          }
        };
      break;
      case RATEPUSH_SCHEDULE_SUCCESS:
        return {
          ...state,
          scheduler_settings: {
            ratepush: payload
          }
        };
      break;
      case UPDATE_STATE:        
        let prev_state = JSON.stringify(state.current_state);
        prev_state = JSON.parse(prev_state);
        const {app_state, ui_state, data_store} = payload;
        // debugger;
        return {
          ...state,
          prev_state: {...prev_state},
          current_state:{
            app_state:{
              ...prev_state.app_state,
              ...app_state
            },
            ui_state:{
              ...prev_state.ui_state,
              ...ui_state
            },
            data_store: {...prev_state.data_store, ...data_store}
          },
        };
      break;
      case DATA_SUCCESS:
        
        return {
          ...state,
          ...payload,
          data_success:true,
        };
        break;
      case LOGOUT:
        return initialState;
      break;
      default:
        return state;
    }
  }
  