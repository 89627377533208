import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import './style.scss';
import { deepCopy, isMobileDevice, dateFormat, dateDiff, calculateCompStayRange } from "../../app/util";
import { updateAppState } from "../../actions/appstate";
import { Button, Popover, ButtonGroup, TextField, Grid } from "@mui/material/";
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { useSelector } from "react-redux";
import './styles.css';

import { useDispatch } from 'react-redux';
import { UPDATE_STATE } from '../../actions/types';
import { APP_CACHE } from '../../app/config-v4-api';
import { sendLog } from "../../app/log-interface";

export default function CompDatePicker(props) {
    const { current_state } = useSelector((state) => state.app);
    const { profile } = useSelector((state) => state.auth);
    const { app_state, ui_state } = current_state;
    const { active_tiles } = ui_state;

    const { comp_stay_range } = app_state;
    const dispatch = useDispatch();
    let [tempDateRange, setTempDateRange] = useState(comp_stay_range);
    let [displayDateRange, setDisplayDateRange] = useState(props.dateRange);
    let [rangeType, setRangeType] = useState("single_date");
    let [range_segment, setRangeSegment] = useState("");
    let [calendarOn, setCalendarOn] = useState("");
    const [isApplyEnable, setIsApplyEnable] = useState(true)

    const [datePickerConfig, setDatePickerConfig] = useState(props.datePickerConfig)
    const [showRangeSegment, setShowRangeSegment] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    let { navigation } = datePickerConfig;

    const openDatePicker = Boolean(anchorEl);
    const id = openDatePicker ? 'date-picker-popover' : undefined;
    const [singleDate, setSingleDate] = useState(moment(tempDateRange.date_from).format("MM/DD/YYYY"));
    const [asOfDate, setAsOfDate] = useState(moment(tempDateRange.as_of_date).format("MM/DD/YYYY"));

    let selector = (<span></span>);

    const [dateRange, setDateRange] = useState([moment(tempDateRange.date_from).toDate(), moment(tempDateRange.date_until).toDate()]);
    let { date_from, date_until } = app_state.comp_stay_range;
    const initialRender = useRef(true);

    const isValidDateAndFormat = (dateString) => moment(dateString, "MM/DD/YYYY", true).isValid();

    const compRef = useRef({ initRender: true });

    useEffect(() => {
        let isSubscribed = true;
        // debugger;
        if (!compRef.current.initRender) {
            let comp = app_state.comp_stay_range.date_from;
            // debugger;
            // if (app_state.comp_stay_range.date_from !== displayDateRange.date_from) {

               
                const { scopeType } = app_state.workspace_controls;
                const { date_from, date_until, comp_as_of_date } = app_state.comp_stay_range;
                tempDateRange = deepCopy(app_state.comp_stay_range);
                displayDateRange = deepCopy(app_state.comp_stay_range);
                setTempDateRange({ ...tempDateRange, ...app_state.comp_stay_range });
                setDisplayDateRange({ ...displayDateRange, ...app_state.comp_stay_range });

                if (app_state.comp_stay_range.as_of_date !== tempDateRange.as_of_date) {
                    onDateChange(null, app_state.comp_stay_range.as_of_date, 'as_of');
                    setRangeType('range');
                    applyChange('comp_as_of_date');
                }


                let from_value = moment(date_from);
                let until_value = moment(date_until);
                if (scopeType === 'Dba') {
                    let primary_stay_range = APP_CACHE.primary_stay_range; //app_state.primary_stay_range;
                    let compare_range = calculateCompStayRange({ primary_stay_range, workspace_controls: app_state.workspace_controls, property_id: app_state.property_id });
                    onDateChange(null, [compare_range.date_from], 'date_from');
                    onDateChange(null, [compare_range.date_until], 'date_until');
                    // onDateChange(null, [from_value], 'single');
                    setRangeType('single_date');
                    applyChange('compStaydates');
                }
                if (scopeType === 'Date') {
                    let primary_stay_range = app_state.primary_stay_range;
                    let compare_range = calculateCompStayRange({ primary_stay_range, workspace_controls: app_state.workspace_controls, property_id: app_state.property_id });
                    onDateChange(null, compare_range.as_of_date, 'as_of');
                    onDateChange(null, [compare_range.date_from], 'date_from');
                    onDateChange(null, [compare_range.date_until], 'date_until');
                    setRangeType('range');
                    applyChange('compStaydates');
                }
            } else {
                compRef.current.initRender = false;
            }

        return () => (isSubscribed = false)
    }
        , [
            app_state.primary_stay_range.date_from,
            app_state.primary_stay_range.date_until,
            app_state.primary_stay_range.as_of_date,
            // app_state.comp_stay_range.date_from,
            // ui_state.showspinner
        ]
    );

    useEffect(() => {
        if (initialRender.current === false) {
            if (
                isValidDateAndFormat(singleDate) &&
                isValidDateAndFormat(asOfDate) &&
                moment(asOfDate, "MM/DD/YYYY").isSameOrBefore(moment(profile.current_property_date))
            ) {
                setIsApplyEnable(true)
            } else {
                setIsApplyEnable(false)
            }
        }
        initialRender.current = false
    }, [singleDate, asOfDate])

    const openDatePickerHandler = (event) => {
        setAnchorEl(event.currentTarget)
        setSingleDate(moment(displayDateRange.date_from).format("MM/DD/YYYY"));
        setAsOfDate(moment(displayDateRange.as_of_date).format("MM/DD/YYYY"));
        setCalendarOn("")
        let range_segment = determineRangeSegment(displayDateRange);
        setRangeSegment(range_segment);
        setTempDateRange({ ...displayDateRange });
    }

    const closeDatePickerHandler = () => {
        if (!(isValidDateAndFormat(asOfDate) && moment(asOfDate, "MM/DD/YYYY").isSameOrBefore(moment(profile.current_property_date)))) {
            setAsOfDate(moment(displayDateRange.as_of_date).format("MM/DD/YYYY"));
        }
        setAnchorEl(null)
    }

    const applyChange = (key) => {
        // let primary_date_from = app_state.primary_stay_range.date_from;
        // let primary_date_until = app_state.primary_stay_range.date_until;
        const apc = APP_CACHE;
        let primary_date_from = (APP_CACHE && APP_CACHE.primary_stay_range) ? APP_CACHE.primary_stay_range.date_from : app_state.primary_stay_range.date_from;
        let primary_date_until = (APP_CACHE && APP_CACHE.primary_stay_range) ? APP_CACHE.primary_stay_range.date_until : app_state.primary_stay_range.date_until;
        let primary_as_of_date = (APP_CACHE && APP_CACHE.primary_stay_range) ? APP_CACHE.primary_stay_range.as_of_date : app_state.primary_stay_range.as_of_date;
        let daysDiffCount = Math.abs(dateDiff(primary_date_until, primary_date_from));
        let comFromDate = tempDateRange.date_from;
        let comToDate = moment(comFromDate).add(daysDiffCount, 'days').format("YYYY-MM-DD");
        let apcKey = getApcKey(APP_CACHE, app_state);
        
        tempDateRange.comp_as_of_date = app_state.comp_stay_range.as_of_date;
        tempDateRange.as_of_date = app_state.comp_stay_range.comp_as_of_date;

        let date_until_comp = {
            date_until: comToDate
        }
        if (app_state.workspace_controls.scopeType === 'Dba') {
            tempDateRange.date_until = comToDate;
        }
        if (app_state.workspace_controls.scopeType === 'Date') {
            tempDateRange.date_until = comToDate;
        }

        if ("compStaydates" === key) {
            setTempDateRange(tempDateRange);

            app_state.comp_range_type = 'rangeTileChange'
            app_state.comp_stay_range = deepCopy(tempDateRange);
            app_state.comp_as_of_date = app_state.comp_stay_range.comp_as_of_date;
            apc.compStayRange[apcKey] = deepCopy(tempDateRange);
 
            // sendLog({ category: "comparison_date_picker", action: "changedates" }, { ...current_state },{
            //     app_state: {
            //         ...app_state,
            //         // comp_range_type: 'rangeTileChange',
            //         //date_range_type: 'rangeTileChange_compStayChanged',
            //         workspace_controls: { ...app_state.workspace_controls },
            //         comp_as_of_date: app_state.comp_stay_range.comp_as_of_date,
            //         comp_stay_range: { ...app_state.comp_stay_range, ...tempDateRange },
            //         data_load: false,
            //         kpi_load: false
            //     },
            //     ui_state: {
            //         ...ui_state
            //     }
            // })
            window.analyze.track("tile_change", {
                "selected_tile": current_state.ui_state.active_tiles.join(),
                comp_stay_range: app_state.comp_stay_range,
                property_id: app_state.property_id, property_name: app_state.property_name,
                data_view: app_state.user_view, clearComparision: true
            }, { ...current_state }, {
                app_state: {
                    ...app_state,
                    // comp_range_type: 'rangeTileChange',
                    //date_range_type: 'rangeTileChange_compStayChanged',
                    workspace_controls: { ...app_state.workspace_controls },
                    comp_as_of_date: app_state.comp_stay_range.comp_as_of_date,
                    comp_stay_range: { ...app_state.comp_stay_range, ...tempDateRange },
                    data_load: false,
                    kpi_load: false
                },
                ui_state: {
                    ...ui_state
                }
            });
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        ...app_state,
                        // comp_range_type: 'rangeTileChange',
                        //date_range_type: 'rangeTileChange_compStayChanged',
                        workspace_controls: { ...app_state.workspace_controls },
                        comp_as_of_date: app_state.comp_stay_range.comp_as_of_date,
                        comp_stay_range: { ...app_state.comp_stay_range, ...tempDateRange },
                        data_load: false,
                        kpi_load: false
                    },
                    ui_state: {
                        ...ui_state
                    }
                }
            })
        }
        if ("staydates" === key) {
            if (datePickerConfig.dateRangeKey === "comp_stay_range") {
                app_state.workspace_controls.clearComparision = true;
                // sendLog({ category: "comparison_date_picker", action: "changedates" }, { ...current_state },{
                //     app_state: {
                //         ...app_state,
                //         // comp_range_type: 'rangeTileChange',
                //         date_range_type: 'rangeTileChange_compStayChanged',
                //         workspace_controls: { ...app_state.workspace_controls },
                //         comp_as_of_date: app_state.comp_stay_range.comp_as_of_date,
                //         comp_stay_range: { ...app_state.comp_stay_range, ...tempDateRange },
                //         // data_load: true,
                //         // kpi_load: true
                //     },
                //     ui_state: { ...ui_state, showspinner: true }
                // })
                window.analyze.track("tile_change", {
                    "selected_tile": current_state.ui_state.active_tiles.join(),
                    comp_stay_range: app_state.comp_stay_range,
                    property_id: app_state.property_id, property_name: app_state.property_name,
                    data_view: app_state.user_view, clearComparision: true
                }, { ...current_state }, {
                    app_state: {
                        ...app_state,
                        // comp_range_type: 'rangeTileChange',
                        date_range_type: 'rangeTileChange_compStayChanged',
                        workspace_controls: { ...app_state.workspace_controls },
                        comp_as_of_date: app_state.comp_stay_range.comp_as_of_date,
                        comp_stay_range: { ...app_state.comp_stay_range, ...tempDateRange },
                        // data_load: true,
                        // kpi_load: true
                    },
                    ui_state: { ...ui_state, showspinner: true }
                });
                apc.compStayRange[apcKey] = {...app_state.comp_stay_range, ...tempDateRange};
                dispatch(updateAppState('comp_stay_range', {
                    app_state: {
                        ...app_state,
                        // comp_range_type: 'rangeTileChange',
                        date_range_type: 'rangeTileChange_compStayChanged',
                        workspace_controls: { ...app_state.workspace_controls },
                        comp_as_of_date: app_state.comp_stay_range.comp_as_of_date,
                        comp_stay_range: { ...app_state.comp_stay_range, ...tempDateRange },
                        // data_load: true,
                        // kpi_load: true
                    },
                    ui_state: { ...ui_state, showspinner: true }
                }))
            }
        }
        if ("comp_as_of_date" === key) {
            tempDateRange.comp_as_of_date = moment(new Date(asOfDate)).format("YYYY-MM-DD");
            tempDateRange.as_of_date = moment(new Date(asOfDate)).format("YYYY-MM-DD");
            displayDateRange = { ...tempDateRange };
            setDisplayDateRange(tempDateRange);
            setTempDateRange(tempDateRange);
            app_state.comp_stay_range = { ...app_state.comp_stay_range, ...tempDateRange };
            app_state.comp_as_of_date = moment(new Date(asOfDate)).format("YYYY-MM-DD");
            app_state.workspace_controls.clearComparision = true;
            app_state.data_load = true;
            ui_state.showspinner = true;
            apc.compStayRange[apcKey] = {...app_state.comp_stay_range, ...tempDateRange}

            window.analyze.track("tile_change", {
                "selected_tile": current_state.ui_state.active_tiles.join(),
                comp_stay_range: app_state.comp_stay_range,
                property_id: app_state.property_id, property_name: app_state.property_name,
                data_view: app_state.user_view, clearComparision: true
            }, { ...current_state }, {
                app_state: {
                    ...app_state,
                },
                ui_state: { ...ui_state }
            });
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        ...app_state,
                        // date_range_type: 'rangeTileChange_compStayChanged',
                    },
                    ui_state: { ...ui_state }
                }
            })
            // dispatch(updateAppState('comp_stay_range', {
            //     app_state: {
            //         ...app_state,
            //         date_range_type: 'rangeTileChange_compStayChanged',
            //     },
            //     ui_state: { ...ui_state }
            // }))
        }
        
        let dateRange = deepCopy(tempDateRange);
        // dateRange.comp_as_of_date = moment(new Date(asOfDate)).format("YYYY-MM-DD");
        // dateRange.as_of_date = moment(new Date(asOfDate)).format("YYYY-MM-DD");
        setShowRangeSegment(true);
        setDisplayDateRange(dateRange);
        setAnchorEl(null);
    }

    const determineRangeSegment = (tempDateRange, type) => {
        let daysDiff = dateDiff(tempDateRange.date_until, tempDateRange.date_from);
        if (type === "comparisonStayDates" && daysDiff === 364) {
            return "sdly";
        }
        let startOfMonth = moment(tempDateRange.date_from).startOf('month');
        let endOfMonth = moment(tempDateRange.date_until).endOf('month');
        if (dateDiff(startOfMonth, tempDateRange.date_from) === 0 && dateDiff(endOfMonth, tempDateRange.date_until) === 0) {
            return "current_month";
        }
        let isToday = (dateDiff(moment(), tempDateRange.date_from) === 0) ? true : false;
        if (isToday) {
            if (daysDiff === 29 || daysDiff === 59 || daysDiff === 89) {
                return daysDiff;
            }
        }
        else {
            return "custom";
        }

    }

    const getApcKey = (APP_CACHE, app_state) => {
        let primary_date_from = (APP_CACHE && APP_CACHE.primary_stay_range) ? APP_CACHE.primary_stay_range.date_from : app_state.primary_stay_range.date_from;
        let primary_date_until = (APP_CACHE && APP_CACHE.primary_stay_range) ? APP_CACHE.primary_stay_range.date_until : app_state.primary_stay_range.date_until;
        let primary_as_of_date = (APP_CACHE && APP_CACHE.primary_stay_range) ? APP_CACHE.primary_stay_range.as_of_date : app_state.primary_stay_range.as_of_date;
        let apcKey = app_state.property_id + '__' + primary_as_of_date + '__' + primary_date_from + '__' + primary_date_until + '__' + app_state.workspace_controls.sdYear;
        return apcKey;
    }

    const shiftBackward = () => {
        let primaryFrom = new moment(app_state.primary_stay_range.date_from);
        let primaryUntil = new moment(app_state.primary_stay_range.date_until);
        let diffDays = dateDiff(primaryUntil, primaryFrom);

        let dateFrom = new moment(app_state.comp_stay_range.date_from);
        let dateUntil = new moment(app_state.comp_stay_range.date_until);
        const apc = APP_CACHE;
        let apcKey = getApcKey(APP_CACHE, app_state);

        if (diffDays === 0) {
            tempDateRange.date_from = dateFrom.subtract(1, 'day').format("YYYY-MM-DD");
            tempDateRange.date_until = tempDateRange.date_from;
            app_state.comp_stay_range.date_from = tempDateRange.date_from;
            app_state.comp_stay_range.date_until = tempDateRange.date_until;
        }
        if (diffDays > 0) {
            tempDateRange.date_from = new moment(tempDateRange.date_from).subtract(diffDays + 1, 'day').format("YYYY-MM-DD");
            tempDateRange.date_until = new moment(tempDateRange.date_from).add(diffDays, 'day').format("YYYY-MM-DD");
            app_state.comp_stay_range.date_from = tempDateRange.date_from;
            app_state.comp_stay_range.date_until = tempDateRange.date_until;
        }

        if (datePickerConfig.dateRangeKey === "comp_stay_range") {
            displayDateRange.date_from = tempDateRange.date_from;
            displayDateRange.date_until = tempDateRange.date_until;

            onDateChange(null, [tempDateRange.date_from], 'date_from');
            onDateChange(null, [tempDateRange.date_until], 'date_until');
            setRangeType('range');
            setDisplayDateRange(displayDateRange);
            setTempDateRange(tempDateRange)
            apc.compStayRange[apcKey] = {...app_state.comp_stay_range}
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        comp_range_type: '',
                        comp_stay_range: { ...app_state.comp_stay_range },
                        data_load: true,
                    },
                    ui_state: { ...ui_state, showspinner: true }
                }
            });
        }

    }

    
    const shiftFoward = () => {
        let primaryFrom = new moment(app_state.primary_stay_range.date_from);
        let primaryUntil = new moment(app_state.primary_stay_range.date_until);
        let diffDays = dateDiff(primaryUntil, primaryFrom);
        const apc = APP_CACHE;
        let apcKey = getApcKey(APP_CACHE, app_state);

        if (diffDays === 0) {
            let dateFrom = new moment(app_state.comp_stay_range.date_until);
            let dateUntil = new moment(app_state.comp_stay_range.date_until);
            tempDateRange.date_from = dateFrom.add(1, 'day').format("YYYY-MM-DD");
            tempDateRange.date_until = tempDateRange.date_from;
            app_state.comp_stay_range.date_from = tempDateRange.date_from;
            app_state.comp_stay_range.date_until = tempDateRange.date_until;
        }
        if (diffDays > 0) {
            let dateFrom = new moment(app_state.comp_stay_range.date_until);
            let dateUntil = new moment(app_state.comp_stay_range.date_until);
            tempDateRange.date_from = dateFrom.add(1, 'day').format("YYYY-MM-DD")
            tempDateRange.date_until = dateUntil.add(diffDays + 1, 'day').format("YYYY-MM-DD");
            app_state.comp_stay_range.date_from = tempDateRange.date_from;
            app_state.comp_stay_range.date_until = tempDateRange.date_until;
        }

        displayDateRange.date_from = tempDateRange.date_from;
        displayDateRange.date_until = tempDateRange.date_until;
        setDisplayDateRange(displayDateRange);
        setTempDateRange(tempDateRange);
        onDateChange(null, [tempDateRange.date_from], 'date_from');
        onDateChange(null, [tempDateRange.date_until], 'date_until');
        setRangeType('range');
        
        if (datePickerConfig.dateRangeKey === "comp_stay_range") {
            // debugger;
            apc.compStayRange[apcKey] = {...app_state.comp_stay_range}
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        comp_stay_range: { ...app_state.comp_stay_range },
                        data_load: true,
                    },
                    ui_state: { ...ui_state, showspinner: true }
                }
            });
        }
    }

    const handleDateInputFocus = (calendar_on) => {
        setCalendarOn(calendar_on)
    }

    const handleDateInputChange = (date, key) => {
        if (key === "single") {
            setSingleDate(date)
        }
        if (key === "as_of") {
            setAsOfDate(date)
        }
        if (isValidDateAndFormat(date)) {
            if (key === "single") {
                tempDateRange.date_from = moment(new Date(date)).format("YYYY-MM-DD");
                tempDateRange.date_until = moment(new Date(date)).format("YYYY-MM-DD");
            }
            if (key === "as_of") {
                if (moment(date, "MM/DD/YYYY").isSameOrBefore(moment(profile.current_property_date))) {
                    tempDateRange.as_of_date = moment(new Date(date)).format("YYYY-MM-DD");
                }
            }
        }
    }

    const onDateChange = (event, date, key) => {
        if (
            isValidDateAndFormat(singleDate) &&
            isValidDateAndFormat(asOfDate) &&
            moment(asOfDate, "MM/DD/YYYY").isSameOrBefore(moment(profile.current_property_date))
        ) {
            setIsApplyEnable(true)
        }

        if (datePickerConfig.type === "comparisonStayDates") {
            if ("date_from" === key) {
                tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
                tempDateRange.date_until = moment(tempDateRange.date_from).add(datePickerConfig.comparisonDiff, 'days').format("YYYY-MM-DD");
            }

        } else {
            if ("single" === key) {
                tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
                tempDateRange.date_until = tempDateRange.date_from;
                setSingleDate(moment(date[0]).format("MM/DD/YYYY"));
            }

            if ("as_of" === key) {
                tempDateRange.as_of_date = moment(date).format("YYYY-MM-DD");
                setAsOfDate(moment(date).format("MM/DD/YYYY"));
            }
            if ("date_from" === key) {
                tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
                if (moment(tempDateRange.date_until).diff(moment(tempDateRange.date_from), 'days') < 0) {
                    tempDateRange.date_until = tempDateRange.date_from
                }
                setRangeSegment("custom");
                setShowRangeSegment(true);
            }
            if ("date_until" === key) {
                tempDateRange.date_until = moment(date[0]).format("YYYY-MM-DD");
                if (moment(tempDateRange.date_until).diff(moment(tempDateRange.date_from), 'days') < 0) {
                    tempDateRange.date_from = tempDateRange.date_until
                }
                setRangeSegment("custom");
                setShowRangeSegment(true);
            }
            if ("weeks" === key) {
                if (event && event.shiftKey === true) {
                    let orgStartDate = moment(tempDateRange.date_from, "YYYY-MM-DD").toDate();
                    let orgEndDate = moment(tempDateRange.date_until, "YYYY-MM-DD").toDate();
                    let start = moment(date[0]).startOf("week"), end = moment(date[0]).endOf("week");

                    if (moment(date[0]).diff(orgStartDate, 'days') < 0) {
                        start = moment(date[0]).startOf("week");
                        end = moment(orgEndDate).endOf("week");
                    }

                    if (moment(date[0]).diff(orgEndDate, 'days') > 0) {
                        end = moment(date[0]).endOf('week');
                        start = moment(orgStartDate).startOf("week");

                    }
                    if (moment(date[0]).diff(orgEndDate, 'days') < -7 && moment(date[0]).diff(orgStartDate, 'days') > 0) {
                        end = moment(date[0]).endOf('week');

                    }

                    tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
                    tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
                }
                else {
                    let start = moment(date[0]).startOf('week');
                    let end = moment(date[0]).endOf('week');
                    tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
                    tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
                    calendarOn = "weeks";

                }

            }
            if ("months" === key) {
                if (event && event.shiftKey === true) {

                    let start = moment(date[0]).startOf('month');
                    let end = moment(date[0]).endOf('month');
                    if (moment(date[0]).diff(moment(tempDateRange.date_from, "YYYY-MM-DD"), 'days') < 0) {
                        start = moment(date[0]).startOf("month");
                        end = moment(moment(tempDateRange.date_until, "YYYY-MM-DD")).endOf("month");
                    }

                    if (moment(date[0]).diff(moment(tempDateRange.date_until, "YYYY-MM-DD"), 'days') > 0) {
                        end = moment(date[0]).endOf('month');
                        start = moment(moment(tempDateRange.date_from, "YYYY-MM-DD")).startOf("month");

                    }

                    tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
                    tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
                } else {
                    let start = moment(date[0]).startOf('month');
                    let end = moment(date[0]).endOf('month');
                    tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
                    tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
                }

            }
        }
        setCalendarOn(calendarOn);
        setTempDateRange(tempDateRange);
        setDateRange([moment(tempDateRange.date_from).toDate(), moment(tempDateRange.date_until).toDate()])


    }

    const rangeSegmentChange = (range_segment) => {
        if (range_segment === "current_month") {
            tempDateRange.date_from = moment().startOf('month').format("YYYY-MM-DD");
            tempDateRange.date_until = moment().endOf('month').format("YYYY-MM-DD");
        }
        if (range_segment === 29 || range_segment === 59 || range_segment === 89) {
            tempDateRange.date_from = moment().format("YYYY-MM-DD");
            tempDateRange.date_until = moment().add(range_segment - 1, 'days').format("YYYY-MM-DD");

        }
        if (range_segment === "custom") {
            tempDateRange.date_from = moment().format("YYYY-MM-DD");
            tempDateRange.date_until = moment().add(range_segment - 1, 'days').format("YYYY-MM-DD");
        }
        setTempDateRange(tempDateRange);
        setRangeSegment(range_segment);

    }


    let singleCalendar = (<div id="single-date-picker-calendar">
        <Calendar
            onChange={(date) => { onDateChange(null, date, "single"); }}
            value={moment(tempDateRange.date_from).toDate()}
            returnValue="range"
            calendarType="US"
            minDetail="year"
            formatMonth={(locale, date) => dateFormat(date, 'MMM')}
            showNeighboringMonth={false}
        />
    </div>);
    let asOfCalendar = (<div id="date-picker-calendar-asof">
        <Calendar
            onChange={(date) => { onDateChange(null, date, "as_of"); }}
            value={moment(tempDateRange.as_of_date).toDate()}
            returnValue="start"
            calendarType="US"
            minDetail="year"
            maxDate={moment(profile.current_property_date).toDate()}
            prev2Label={null}
            next2Label={null}
            formatMonth={(locale, date) => dateFormat(date, 'MMM')}
            className={isApplyEnable ? 'calendar-asof' : 'calendar-asof hide-date-selection'}
        />
    </div>);
    let rangeSegmentLabels = navigation.range.range_segments.map((segment, index) => {
        let label = "";
        switch (segment) {
            case "current_month":
                label = <><span className='this-month'>This</span><span className='days-label'> Month</span></>;
                break;
            case 29:
                label = <><span className='next-label'>next</span><span className='days-num'>30</span><span className='days-label'> days</span></>;
                break;
            case 59:
                label = <><span className='next-label'>next</span><span className='days-num'>60</span><span className='days-label'> days</span></>;
                break;
            case 89:
                label = <><span className='next-label'>next</span><span className='days-num'>90</span><span className='days-label'> days</span></>;
                break;
            case "sdly":
                label = "Same Period, SDLY";
                break;
            case "custom":
                label = "Custom";
                break;
        }
        return (<Button key={index} className={(range_segment === segment) ? "days-num-btn active" : "days-num-btn"} onClick={() => { rangeSegmentChange(segment) }}>{label}</Button>)
    })
    let ranges = Object.entries(navigation);
    let rangeTypes = ranges.map((item, index) => {
        let disabledClass = (active_tiles[0] === "pace" && item[0] !== "single_date") ? "disabled-range-type" : "";
        return (<label key={index} className={(rangeType === item[0]) ? disabledClass + " btn btn-default active" : "btn btn-default " + disabledClass} onClick={() => {
            if (!disabledClass) {

                let range_type = item[0];
                if (range_type === "weeks" || range_type === "months") {
                    calendarOn = range_type;
                }
                // setCalendarOn(range_type);
                setRangeType(range_type);
                setShowRangeSegment(true);

            }

        }}>{item[1].label}</label>)
    });

    let range_type = "single_date";
    let singleCompDate = (app_state.workspace_controls.clearComparision) ? moment(singleDate).format("MM/DD/YYYY") : "";
    switch (range_type) {
        case "single_date":
            selector = (<div className="range-selector row" style={{ "paddingTop": "10px" }}>
                <div style={{ "display": "block", "width": "100%" }}>
                    <div className="form-group col-md-12" style={{ "marginBottom": 0 }}>
                        <TextField
                            fullWidth
                            inputProps={{ tabIndex: "1" }}
                            placeholder='mm/dd/yyyy'
                            label="Comparison Date:"
                            size="small"
                            id="comparisonDatePicker"
                            className='date-picker'
                            name="comparisonDatePicker"
                            error={!isValidDateAndFormat(singleDate)}
                            onFocus={() => { handleDateInputFocus("single") }}
                            onChange={(event) => handleDateInputChange(event.target.value, "single")}
                            value={singleDate}
                        />
                    </div>
                </div>
                {((calendarOn === "single") &&
                    <>
                        {singleCalendar}
                    </>
                )}
            </div>);
            break;
    }
    let popoverStyles = { "top": "0px", "display": "block", "visibility": "visible", "width": "300px", };
    let isMobile = isMobileDevice();
    if (isMobile) {
        popoverStyles.top = "-40px";
        popoverStyles.transform = "scale(.65)";
    }

    let popup = (<div className="popover bottom-left" style={popoverStyles}>
        <div className="arrow"></div>
        <div className="popover-content" style={{ "padding": "9px" }}>
            <div>
                {selector}

                <Grid container spacing={1} sx={{ mt: 1.5 }}>
                    <Grid item xs={7}>
                        {(app_state.workspace_controls.scopeType !== "Dba" && ui_state.active_tiles[0] !== "bi_sensitivity_analysis" &&
                            <div>
                                <TextField
                                    label="As Of Date"
                                    inputProps={{ tabIndex: "1" }}
                                    placeholder='mm/dd/yyyy'
                                    size="small"
                                    id="asOfDate"
                                    name="asOfDate"
                                    className='date-picker'
                                    error={moment(asOfDate, "MM/DD/YYYY").isAfter(moment(profile.current_property_date)) || !isValidDateAndFormat(asOfDate)}
                                    onFocus={() => { handleDateInputFocus("as_of") }}
                                    onChange={(event) => handleDateInputChange(event.target.value, "as_of")}
                                    value={asOfDate}
                                />
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={5}>
                        <div>{
                            isApplyEnable ?
                                (<button type="button" className="btn btn-info btn-block" tabIndex={1} onClick={() => applyChange("comp_as_of_date")} >Apply</button>) :
                                (<button type="button" className="btn btn-info btn-block btn-disabled"  >Apply</button>)
                        }</div>
                    </Grid>

                    <Grid item xs={12}>
                        {(calendarOn === "as_of" && calendarOn && <div className='calendar-box'>
                            {asOfCalendar}
                        </div>)}
                    </Grid>

                </Grid>
            </div>
        </div>
    </div>
    )
    let shiftBackwardBtn = () => (<button type="button" className="btn-stay-range shift-prev" onClick={shiftBackward} disabled={ui_state.showspinner}>
        {/* <ArrowBackIosIcon fontSize='small' /> */}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#606060" viewBox="0 0 256 256"><path d="M168,48V208a8,8,0,0,1-13.66,5.66l-80-80a8,8,0,0,1,0-11.32l80-80A8,8,0,0,1,168,48Z"></path></svg>
    </button>);
    let shiftFowardBtn = () => (<button type="button" className="btn-stay-range shift-next" onClick={shiftFoward} disabled={ui_state.showspinner}>
        {/* <ArrowForwardIosIcon fontSize='small' /> */}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#606060" viewBox="0 0 256 256"><path d="M181.66,133.66l-80,80A8,8,0,0,1,88,208V48a8,8,0,0,1,13.66-5.66l80,80A8,8,0,0,1,181.66,133.66Z"></path></svg>
    </button>);
    let topLabel = (<span></span>);
    if (!app_state.workspace_controls.clearComparision) {
        topLabel = (<label className="label">Comparison Date 
        </label>);
    } else if (datePickerConfig.type === "primaryStayDates" && app_state.workspace_controls.scopeType !== "Dba" && ui_state.active_tiles[0] !== "bi_sensitivity_analysis") {
        topLabel = (<label className="label">Comparision Dates -
            <span className="date-text"> As of </span>
            <span className="">{dateFormat(app_state.comp_stay_range.as_of_date, "ddd, MMM DD, YYYY")}</span>
        </label>);
    } else {
        topLabel = (<label className="label"> </label>);
    }
    return (
        <>
            <div className="smart-date-picker" style={{ "display": " inline-block", "verticalAlign": "top" }}>
                <div className="chart-stay-date clearfix current-date-range" id="stay-date">
                    {topLabel}
                    <div className="stay-date-picker clearfix" id="stay-date-picker-id">
                        {(datePickerConfig.shiftBtns === true) && shiftBackwardBtn()}
                        <button type="button" className="btn-stay-picker clearfix"
                            onClick={(event) => { openDatePickerHandler(event) }}
                        >
                            {(datePickerConfig.dateRangeKey === "comparison_stay_range") && (<span>Select Dates</span>)}
                            {
                                ((datePickerConfig.dateRangeKey !== "comparison_stay_range") && app_state.workspace_controls.clearComparision) ?
                                    <span>
                                        <span className="rangeFromDate ">{dateFormat(displayDateRange.date_from, "ddd, MMM DD")}</span> -
                                        <span className="rangeToDate" >{dateFormat(displayDateRange.date_until, "ddd, MMM DD, YYYY")}</span>
                                    </span>
                                    : <>
                                        Select Date for Comparison</>
                            }
                        </button>
                        <Popover
                            PaperProps={{ elevation: 0, sx: { backgroundColor: 'transparent', boxShadow: 'none', mt: -1 } }}
                            id={id}
                            open={openDatePicker}
                            anchorEl={anchorEl}
                            onClose={() => closeDatePickerHandler()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
                            className="elevation0 date-picker-popover"
                        >
                            <div className='date-picker-wrapper'>
                                {popup}
                            </div>
                        </Popover>
                        {(datePickerConfig.shiftBtns === true) && shiftFowardBtn()}
                    </div>
                </div>
            </div>
        </>);

}