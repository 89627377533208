import { currencyFormat } from '../app/util';
import { TABLE_CONFIG } from "./table-config";
// const API_BASE_URL = "https://rm-app-staging.lodgiq.com/"
// const API_BASE_URL = "http://rm-api-staging.lodgiq.com/"
// const API_BASE_URL = "https://app.lodgiq.com/";
// const API_BASE_URL = "http://rm-api-dev.lodgiq.com/";
// const API_BASE_URL = "http://172.31.15.232/";
//  const API_BASE_URL = "/"; //Calling throgh proxy (http://rm-api-staging.lodgiq.com) in app package.json instead

// const API_BASE_URL = "http://172.31.15.244/";

//Make this var false to disable all console log
const DEBUG_MODE = true;
const API_BASE_URL = "/";

const API_DI_CERT = "https://di-cert.lodgiq.com/lde_v3/"

// const API_REPORT_URL = "/";
const API_REPORT_URL = "http://rm-app-staging.lodgiq.com/";

const api_status = {};
const promises = { all: [] };
const TILE_GROUP_ONE = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const TILE_GROUP_TWO = [11, 12, 13, 14, 15, 16, 17];

const API_REGISTRY = {
    "UserLogin": {
        uri: "users/login",
        payload: {
            "email": null,
            "password": null,            
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "UserResetPassword": {
        uri: "users/password_reset",
        payload: {
            "email": null,
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "UserProfile": {
        uri: "users/get_profile",
        payload: {
            "token_string": null,
            "user_id": null,            
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRRateRecommend": {
        uri: "api.v1/get_current_recommended_sell_rates/",
        payload: {
            "params": {
                "pid": null,
                "asof_date": null,
                "date_from": null,
                "date_until": null,
                "from_cache": "false"
            },
            "token_string": null
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSR": {
        uri: "rms/get_otb_pickup_sdly_new/",
        payload: {
            // "method": "GET",
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "asOfDate": null,
            "pickupDays": 1,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARI",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "1",
            "type": 1
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRYear": {
        uri: "rms/get_otb_pickup_sdly_new/",
        payload: {
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "asOfDate": null,
            "pickupDays": 1,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARI",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "1",
            "type": 1
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRSegment": {
        uri: "rms/get_otb_pickup_sdly_new/",
        payload: {
            // "method": "GET",
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "asOfDate": null,
            "pickupDays": 1,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARI",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "all",
            "type": 1
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "SendRatePush":{
        uri:"optimization/user_rate_update",
        method:"post",
        payload:{
            
                "token_string":"",
                "user_id":null,
                "property_id":null,
                "asof_date":"",
                "segment_type":"market_code",
                "rate_code":"TBAR",
                "rate_update":[]
        },
        data:null,
        loading:false,
        loaded:false,
    },
    // "forecast": {
    //     uri: "api.v1/get_hotel_forecast/",
    //     payload: {
    //         "params": {
    //             "pid": null,
    //             "asof_date": null,
    //             "date_from": null,
    //             "date_until": null,
    //             "from_cache": "false"
    //         },
    //         "token_string": null
    //     },
    //     data: null,
    //     loaded: false,
    //     loading: false,
    // },
    "GetRestrictions": {
        uri: "restrictions/get-restrictions/",
        payload: {
            "property_id": null,
            "token_string": null
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "GetRestrictionsRoomType": {
        uri: "restrictions/get-restrictions/",
        payload: {
            "property_id": null,
            "token_string": null,
            "room_type":true
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "ManualForecast": {
        uri: "upload/get_manual_forecast_data/",
        payload: {
            "property_id": null,
            "asOfDate": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "segment_filter": "all",
            "token_string": null
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "MarketARI": {
        uri: "market/get_market_otb_pickup_sdly/",
        payload: {
            "resourceId": "",
            "resourceScope": "market",
            "token": null,
            "asOfDate": null,
            "pickupDays": 1,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARI",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "total"
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "Restrictions":{
        uri:"/restrictions/get-restrictions",
        method:"GET",
        params:['property_id', 'token','room_type'],
        payload:{},
        data:null,
        loaded:false,
        loading:false,
    },
    "MarketARISegmented": {
        uri: "market/get_market_otb_pickup_sdly/",
        payload: {
            "resourceId": "",
            "resourceScope": "market",
            "token": null,
            "asOfDate": null,
            "pickupDays": 1,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARI",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "all"
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "MarketForecast": {
        uri: "api.v1/get_mfc_market_futures/",
        payload: {
            "params": {
                "algo": "MFC_v3",
                "marketid": "",
                "asof_date": null,
                "algo": "",
                "date_from": null,
                "date_to": null,
                "all_forecast_measures": "True",
            },
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "MarketForecastSegmented": {
        uri: "api.v1/get_mfc_market_futures/",
        payload: {
            "params": {
                "algo": "MFC_v3",
                "marketid": "",
                "asof_date": null,
                "algo": "",
                "date_from": null,
                "date_to": null,
                "segmented": "True",
                "all_forecast_measures": "True",
            },
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "MarketDba": {
        uri: "market/get_market_historical_curve/",
        payload: {
            "resourceId": null,
            "resourceScope": "market",
            "source": "ota_i_api",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "segmentFilter": "1"
        },
        data: null,
        loaded: false,
        loading: false,

    },
    "MarketSdlyDba": {
        uri: "market/get_market_historical_curve/",
        payload: {
            "resourceId": null,
            "resourceScope": "market",
            "token": null,
            "source": "ota_i_api",
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "segmentFilter": "1"
        },
        data: null,
        loaded: false,
        loading: false,

    },

    "ForcastSegment": {
        uri: "forecast/get_constrained_multiStay_forecast",
        payload: {
            "property_id": "396",
            "token_string": "313280f831b64bccb8e3a4b1e0f08fa3",
            "asof_date": "2022-12-13",
            "resource_type": "property",
            "forecast_start_range": "2022-12-13",
            "forecast_end_range": "2022-12-31",
            "segment_type": "market_code",
            "segment_filter": "all"
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "OSRContrainedForecast": {
        uri: "api.v1/get_hotel_forecast/",
        payload: {
            "params": {
                "pid": null,
                "asof_date": null,
                "date_from": null,
                "date_until": null,
                "from_cache": "false"
            },
            "token_string": null
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRContrainedForecastYear": {
        uri: "api.v1/get_hotel_forecast/",
        payload: {
            "params": {
                "pid": null,
                "asof_date": null,
                "date_from": null,
                "date_until": null,
                "from_cache": "false"
            },
            "token_string": null
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRContrainedForecastSDLY": {
        uri: "api.v1/get_hotel_forecast/",
        payload: {
            "params": {
                "pid": null,
                "asof_date": null,
                "date_from": null,
                "date_until": null,
                "from_cache": "false"
            },
            "token_string": null
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "OSRBookingPace": {
        uri: "rms/get_historical_curve/",
        payload: {
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARICurve",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "1"
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRBookingPaceSdly": {
        uri: "rms/get_historical_curve/",
        payload: {
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARICurve",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "1"
        },
        data: null,
        loaded: false,
        loading: false,
    },
    "OSRBookingPaceCompareTo": {
        uri: "rms/get_historical_curve/",
        payload: {
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARICurve",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "1"
        },
        data: null,
        loaded: false,
        loading: false,
    },


    
    "OSRBookingPaceCompareToSegment": {
        uri: "rms/get_historical_curve/",
        payload: {
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARICurve",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "all"
        },
        data: null,
        loaded: false,
        loading: false,
    },
    /////////////  Booking Curve Segment View //////////

    "OSRBookingPaceSegment": {
        uri: "rms/get_historical_curve/",
        payload: {
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARICurve",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "all"
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "OSRBookingPaceSdlySegment": {
        uri: "rms/get_historical_curve/",
        payload: {
            "resourceId": null,
            "resourceScope": "property",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "queryType": "ARICurve",
            "querySubtype": "",
            "segmentationType": "market_code",
            "segmentFilter": "all"
        },
        data: null,
        loaded: false,
        loading: false,
    },
    /////////// End Booking Curve Segment view //////////////

    "CompsetBookingPace": {
        uri: "compset/get_compset_historical_curve/",
        payload: {
            "resourceId": null,
            "compId": null,
            "resourceScope": "ratecompset",
            "source": "ota_i_api",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null
        },
        data: null,
        loaded: false,
        loading: false,

    },
    // "Compset":{
    //     uri:"compset/get_compset_historical_curve/",
    //     payload:{
    //         "resourceId":null, 
    //         "resourceScope":"ratecompset", 
    //         "source":"ota_i_api", 
    //         "token":null,
    //         "rangeFromDate":null,
    //         "rangeUntilDate":null
    //     },
    //     data:null,
    //     loaded:false,
    //     loading:false,

    // }, 
    "Compset": {
        uri: "compset/get_rateshop_compset/",
        // dependency: ["CompsetBookingPace"],
        payload: {
            "resourceId": null,
            "compId": null,
            "resourceScope": "ratecompset",
            "source": "ota_i_api",
            "token": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
            "asOfDate": null
        },
        data: null,
        loaded: false,
        loading: false,

    },
    "Event": {
        uri: "api.v1/get_events_impact/",
        payload: {
            params: {
                "mid": null,
                "date_from": null,
                "date_to": null,
                "source": "CUSTOM_v2"
            },
            token_string: null
        }
    },
    "MarketSummary": {
        uri: "export/market_summary_excel_export/",
        payload: {
            "market_id": null,
            "as_of_date": null,
            "from_date": null,
            "to_date": null,
            "data_aggregation": "month",
            "mfc_algo": "MFC_v3",
            "data_format": "json_mockapi"
        }
    },
    "HotelSummary": {
        uri: "export/forecast_excel_export/",
        payload: {
            "property_id": null,
            "as_of_date": null,
            "from_date": null,
            "to_date": null,
            "data_aggregation": "month",
            "mfc_algo": "MFC_v3",
            "data_format": "json_mockapi"
        }
    },

    "MarketSellRate": {
        uri: "api.v1/get_alt_dataset/",
        payload: {
            "params": {
                "source": "market_sell_rate",
                "asof_date": null,
                "date_from": null,
                "date_until": null,
                "resource_id": null,
            },
        },
        data: null,
        loaded: false,
        loading: false,
    },

    "BudgetYear": {
        uri: "upload/get_budget_data/",
        payload: {
            "property_id": null,
            "asOfDate": null,
            "rangeFromDate": null,
            "rangeUntilDate": null,
        },
        data: null,
        loaded: false,
        loading: false,
    },
}

const _md = {

    standard_property_fields: [{ label: "Rooms", value: "Rooms" },
    { label: "OCC %", value: "Occ" },
    { label: "RevPAR", value: "RevPAR" },
    { label: "Revenue", value: "Revenue" },
    { label: "ADR", value: "ADR" },
    { label: "Sell Rate", value: "SellRate" }],

    standard_market_fields: [{ label: "Rooms", value: "Rooms" },
    { label: "OCC %", value: "Occ" },
    { label: "RevPAR", value: "RevPAR" },
    { label: "Revenue", value: "Revenue" },
    { label: "ADR", value: "ADR" }],

    standard_compset_fields: [{ label: "Sell Rate", value: "SellRate" }]
}
const TILES = {
    pricing: {
        id: "pricing", class_name: "tile pricing",
        label: "Pricing",
        measures_list: [{ label: 'Sell Rate', value: 'SellRate' }],
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],

        },
        data_sources: ['OSR','OSRRateRecommend','OSRContrainedForecast'],
        labelSize: 12,
        order: 1,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-pricing-1',
        iconSize: 50,
        defaultView:"table",
        workspaceControls:["sendRatesBtn", "toggleRoomTypePricing"],
        tableConfig:TABLE_CONFIG['pricing'],
    
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    room_pricing: {
        id: "room_pricing", class_name: "tile room-pricing",
        label: "Room Pricing",
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        measures_list: [],
        data_sources: [],
        labelSize: 12,
        order: 2,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-pricing',
        iconSize: 50,
        p: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    group: {
        id: "group", class_name: "tile group",
        label: "Group",
        measures_list: [],
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        data_sources: [],
        labelSize: 12,
        order: 3,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        view: 'popup',
        //Styles
        iconClass: 'icon icon-icon-groups',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    price_calendar: {
        id: "price_calendar", class_name: "tile price-calendar",
        label: "Price Calendar",
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        measures_list: [],
        data_sources: ['OSRRateRecommend'],
        labelSize: 11.5,
        order: 4,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        view: 'popup', //This indicates that when clicked data will be shown in popup mode
        //Styles
        iconClass: 'icon icon-table-view-icon',
        iconSize: 45,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    budget: {
        id: "budget", class_name: "tile budget",
        label: "Budget",
        measures_list: _md.standard_market_fields,
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        data_sources: [],
        labelSize: 12,
        order: 5,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-budget',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    forecast: {
        id: "forecast", class_name: "tile forecast",
        label: "Forecast",
        tableConfig: TABLE_CONFIG['forecast'],
        measures_list: _md.standard_market_fields,
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: [],
            mainkey: 'HotelTotal',
            controlSupport: { sdlyOn: false, segmented_view: true }
        },
        measures_list: _md.standard_market_fields,
        data_sources: ['OSRContrainedForecast', 'OSR', "OSRSegment", "ManualForecast"],
        labelSize: 12,
        order: 6,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-forecast',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    daily_flash: {
        id: "daily_flash", class_name: "tile daily-flash",
        label: "Daily Flash",
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
            xAxisKey: 'monthData',
            mainkey: 'aggregates'
        },
        measures_list: [],
        data_sources: ['OSRYear', 'OSRContrainedForecastYear', 'BudgetYear'],
        labelSize: 12,
        order: 7,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        view: 'popup',
        //Styles
        iconClass: 'icon icon-flash-report',
        iconSize: 50,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    market_sell_rates: {
        id: "market_sell_rates", class_name: "tile market-sell-rates",
        label: "Market Sell Rates",
        tableConfig: TABLE_CONFIG['market_sell_rates'],
        chartConfig: {
            identifiers: ['3_star', "4_star", "5_star"],
            sdlyIdentifiers: [],
            mainkey: 'sets'
        },
        measures_list: [{
            "label": "Market SellRate",
            "value": "SellRate"
        }],
        data_sources: ["MarketSellRate"],
        labelSize: 12,
        order: 8,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-market-sell-rates',
        iconSize: 45,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    hotel_summary: {
        id: "hotel_summary", class_name: "tile hotel-summary",
        tableConfig: TABLE_CONFIG['hotel_summary'],
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        measures_list: _md.standard_property_fields,
        label: "Hotel Summary",
        data_sources: ['HotelSummary'],
        labelSize: 12,
        order: 9,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-hotel-summary',
        iconSize: 45,
        p: 1,
        pt: 1,
        pb: 0,
        ml: 1,
        mb: 0
    },
    market_summary: {
        id: "market_summary", class_name: "tile market-summary",
        label: "Market Summary",
        tableConfig: TABLE_CONFIG['market_summary'],
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
        },
        measures_list: _md.standard_property_fields,
        data_sources: ['MarketSummary'],
        labelSize: 12,
        order: 10,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-market-analysis',
        iconSize: 45,
        p: 1,
        pt: 1,
        pb: 0,
        ml: 1,
        mb: 0
    },
    otb: {
        id: "otb", class_name: "otb-tile",
        label: "OTB",
        tableConfig: TABLE_CONFIG['otb'],
        chartConfig: {
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'sdly', "sdly_actual"],
            mainkey: "HotelTotal",
            controlSupport: { sdlyOn: true, segmented_view: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        labelSize: 12,
        data_sources: ["OSR", "OSRSegment","GetRestrictions","GetRestrictionsRoomType"],
        measures_list: _md.standard_property_fields,
        order: 11,
        usedSpace: 6,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-onthebooks',
        iconSize: 45,
        p: 1,
        pt: 0,
        pb: 1,
        ml: 0,
        mb: 0
    },
    pickup: {
        id: "pickup", class_name: "tile pickup",
        label: "Pickup",
        tableConfig: TABLE_CONFIG['pickup'],
        chartConfig: {
            identifiers: ['primary', 'pickup'],
            sdlyIdentifiers: ['primary', 'pickup', 'sdly', 'sdly_pickup', "sdly_actual"],
            mainkey: "HotelTotal",
            defaultMeasures: ['Occ', 'ADR'],
            controlSupport: { sdlyOn: true, segmented_view: true, pickUpType: true },
            maps: {
                legends: {
                    //Legend keys and values as text
                    //Formula: tileID + 
                }
            },
            chartOptions: {}
        },
        measures_list: _md.standard_property_fields,
        data_sources: ["OSR", "OSRSegment"],
        labelSize: 12,
        order: 12,
        usedSpace: 1,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-pickup',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    pace: {
        id: "pace", class_name: "tile pace",
        label: "Booking Curve",
        tableConfig: TABLE_CONFIG['pace'],
        chartConfig: {
            //Data setup options
            identifiers: ['primary'],
            sdlyIdentifiers: ['primary', 'paceSdly'],
            compareToIdentifiers: ['primary', 'paceCompareTo'],
            mainkey: "HotelTotal",
            sdlyKey: 'paceSdly',
            compareToKey: 'paceCompareTo',
            xAxisKey: "Index",
            xAxisType: "Dba",
            controlSupport: { scopeType: false, sdlyOn: true },
            //Chart options settings for this tile
            chartOptions: {
                xAxis: {
                    type: 'areaspline',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 1,
                            y2: 1
                        },
                        // stops : [
                        //     [0, '#3176B0'],
                        //     [1, window.Highcharts.Color('#3176B0').setOpacity(0).get('rgba')]
                        // ]
                    }
                },
                //yAxis: {}
            }
        },
        measures_list: _md.standard_property_fields,
        data_sources: ["OSRBookingPace", "OSRBookingPaceSdly", "OSRBookingPaceCompareTo", "OSRBookingPaceSegment", "OSRBookingPaceSdlySegment", "OSRBookingPaceCompareToSegment"],
        labelSize: 12,
        order: 13,
        usedSpace: 1,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-booking-pace',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    compset: {
        id: "compset", class_name: "tile compset",
        label: "Compset",
        tableConfig: TABLE_CONFIG['compset'],
        chartConfig: {
            //Data setup options
            identifiers: [],
            sdlyIdentifiers: [],
            defaultMeasures: [null, { label: 'SellRate', value: 'SellRate' }],
            xAxisKey: "index",
            controlSupport: { scopeType: true, sdlyOn: false },
            //Chart options settings for this tile
            chartOptions: {
                // "legend": {
                //     "enabled": true,
                //     "align": "right",
                //     "layout": "vertical",
                //     "verticalAlign": "top",
                //     "y": 10,
                //     "padding": 5,
                //     "useHTML": true,
                //     "shadow": false,
                //     "itemStyle": {
                //         "color": "#606060",
                //         "font-size": ".7vw",
                //         "padding-bottom": ".5vw"
                //     }
                // },
                // xAxis: {
                //     type: 'spline',
                // },
                //yAxis: {}
            }
        },
        measures_list: _md.standard_compset_fields,
        data_sources: ["Compset", "CompsetBookingPace"],
        labelSize: 12,
        order: 14,
        usedSpace: 1,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-comp-set',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    market: {
        id: "market", class_name: "tile market",
        data_sources: ["MarketARI", "MarketDba", "MarketForecast", "MarketSdlyDba", "MarketARISegmented", "MarketForecastSegmented"],
        //data_sources_dba: ["MarketDba", "MarketSdlyDba"],
        //data_sources_segmented: ["MarketARISegmented", "MarketDbaSegmented", "MarketSdlyDbaSegmented", "MarketForecastSegmented"],
        measures_list: _md.standard_market_fields.concat([{ label: "Sell Rate", value: "SellRate" }]),
        label: "Market",
        tableConfig: TABLE_CONFIG['market'],
        chartConfig: {
            identifiers: ['primary', 'market_forecast'],
            sdlyIdentifiers: ['primary', 'sdly', 'sdly_actuals', 'market_forecast'],
            dba_identifiers: ['primary'],
            sdly_dba_identifiers: ['primary', 'sdly'],
            mainkey: "MarketTotal",
            controlSupport: {
                scopeType: true,
                segmented_view: true,
                sdlyOn: true,
            },
            chartOptions: {
                legend: {},
                xAxis: {
                    type: 'areaspline',
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 1,
                            y2: 1
                        },
                        // stops : [
                        //     [0, '#3176B0'],
                        //     [1, window.Highcharts.Color('#3176B0').setOpacity(0).get('rgba')]
                        // ]
                    }
                },
                //yAxis: {}
            }
        },
        labelSize: 12,
        order: 15,
        usedSpace: 1,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-marke',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    events: {
        id: "events", class_name: "tile events",
        label: "Events",
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        measures_list: [],
        labelSize: 12,
        data_sources: [],
        order: 16,
        usedSpace: 1,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-events',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
    flights: {
        id: "flights", class_name: "tile flights",
        chartConfig: {
            identifiers: [],
            sdlyIdentifiers: [],
        },
        measures_list: [],
        label: "Flights",
        data_sources: [],
        labelSize: 12,
        order: 17,
        usedSpace: 1,
        mergeability: [], //IDs of tiles for which it can be merged and show data properly
        disabled: false,
        //Styles
        iconClass: 'icon icon-icon-flights-default',
        iconSize: 55,
        p: 1,
        pt: 1,
        pb: 1,
        ml: 1,
        mb: 1
    },
}

//Default Configuration for chart options
const CHART_OPTIONS = {
    dailyFlash: {
        "credits": {
            "enabled": false
        },
        "chart": {
            //"height": (9 / 16 * 100) + '%', // 16:9 ratio
            //"height": (6.3 / 16 * 100) + '%',
            "height": '280px',
            "type": 'column',
            "reflow": false,
            "backgroundColor": "transparent",
            "style": {
                "color": "#606060",
                "fontFamily": "Lato Regular"
            },
            "marginBottom": 80
        },
        "legend": {
            "enabled": true,
            "floating": false,
            "verticalAlign": "bottom",
            "align": "left",
            "fontFamily": "Lato Light",
            "fontSize": 15, "marginTop": "25px"
        },
        "xAxis": [
            {
                // "min": xAxisMin,
                // "max": Number(moment(date_until).format('x')),
                //"max": xAxisMax,
                "crosshair": {
                    "width": 1,
                    "color": "rgb(130,203,208)"
                },
                "type": 'datetime',
                "dateTimeLabelFormats": {
                    "day": "%b %e",
                    "week": "%Y<br/>%m-%d",
                    "month": "%b",
                    "year": "%Y",
                },
                "labels": {
                    "style": {
                        "color": "#606060",
                        "fontFamily": "Lato Regular"
                    },
                    "formatter": function () {
                        let d = new Date(this.value);
                        return window.Highcharts.dateFormat('%b', d);
                    }
                },
                "title": {
                    "style": {
                        "color": "#606060",
                        "fontFamily": "Lato Light"
                    }
                },
                "ordinal": false,
            }
        ],
        "yAxis": [], //This need to be manipulated with left and right y axis
        "rangeSelector": {
            "enabled": true,
            "height": 0,
            "inputEnabled": false,
            "buttonTheme": {
                "visibility": "hidden"
            },
            "labelStyle": {
                "visibility": "hidden"
            }
        },
        "title": {
            "text": "",
            "style": {
                "color": "#606060",
                "fontFamily": "Open Sans"
            }
        },
        "enableMouseTracking": true,
        "enableStickyTracking": false,
        "tooltip": {
            "enabled": false,
            "animation": true,
            "enabled": true,
            "shared": true,
            "useHTML": true,
            "shape": "callout",
            "backgroundColor": "rgba(255,255,255,1)",
            "borderColor": "#777",
            "borderRadius": 3, "padding": 0,
            "hideDelay": 0,
            "className": "chart-tooltip",
            "formatter": function () {
                //  debugger;
                let tooltipTitle = '';
                let timestamp = new Date(this.x);
                // debugger;
                tooltipTitle = window.Highcharts.dateFormat('%b %Y', timestamp)
                //moment.unix(timestamp).format("ddd, MMM D, YYYY");
                let shared_points = this.points

                let s = '<div class="tooltip-title" style="color:white">' + tooltipTitle + '</div>';

                //first find all headers needed to put on tooltip
                let subheaders = []
                shared_points.forEach((point) => {
                    let header = point.series.userOptions.seriesName;

                    if (!subheaders.includes(header)) {
                        subheaders.push(header)
                    }
                })


                //go through all subheaders and create the html tag, then go through each point and then put them under
                subheaders.forEach((header, I) => {
                    // debugger;
                    let header_color = shared_points[I * 2].color;
                    let header_html = '<div style="margin:5px 2px 0 2px;font-weight:bold;color:' + header_color + '">' + header.toUpperCase() + '</div>';
                    s += header_html
                    shared_points.forEach((point) => {
                        let seriesMeasureName = point.series.userOptions.name;
                        let seriesName = point.series.userOptions.seriesName.toUpperCase();
                        let seriesColor = point.color;

                        // debugger;
                        if (header == point.series.userOptions.seriesName) {
                            let mainMeasure = point.series.userOptions.mainMeasure;
                            let displayVal = point.y.toFixed(0);
                            if (mainMeasure !== 'Occ%' && mainMeasure !== 'Rooms') {
                                displayVal = currencyFormat(displayVal, point.series.userOptions.currency);
                            }
                            if (mainMeasure === 'Occ%') {
                                displayVal += '%';
                            }
                            //debugger;

                            let measure_element_html = '<div style="margin-left:5px;margin-right:5px;"><span style="font-size:17px; margin-right:2px;color:' + seriesColor + '">&#9679;</span> ' + point.series.userOptions.mainMeasure + "<strong> : " + displayVal + "</strong></div>"
                            s += measure_element_html
                        }
                    })
                })
                return s
            }
        },
        "navigation": {
            "buttonOptions": {
                "enabled": false
            }
        },
        "navigator": {
            "baseSeries": 0,
            "height": 15,
            "useHTML": true,
            "enabled": false
        },
        "scrollbar": {
            "height": 6,
            "barBorderRadius": 5,
            "barBorderWidth": 1,
            "barBorderColor": "purple",
            "barBackgroundColor": "purple",
            "enabled": false
        },
        //"series": series,
        "series": [],
        "plotOptions": {
            "series": {
                "pointInterval": undefined,
                "turboThreshold": 1000000000000000000,
                "showInNavigator": true,
                "events": {

                },
                "animation": {
                    duration: 1200
                },
                "stacking": 'normal',
                "borderWidth": 0
            }
        }
    },
    default: {
        option: {
            "credits": {
                "enabled": false
            },
            "chart": {
                //"height": (9 / 16 * 100) + '%', // 16:9 ratio
                //"height": (6.3 / 16 * 100) + '%',
                // "height": '100%',
                "type": 'column',
                "reflow": false,
                "backgroundColor": "transparent",
                "style": {
                    "color": "#606060",
                    "fontFamily": "Lato Regular"
                },
                "marginBottom": 80
            },
            "legend": {
                "enabled": true,
                "floating": false,
                "verticalAlign": "bottom",
                "align": "right",
                "fontFamily": `"Lato Regular"`,
                "fontSize": 16,
            },
            "xAxis": [
                {
                    //"min": xAxisMin,
                    // "max": Number(moment(date_until).format('x')),
                    //"max": xAxisMax,
                    "crosshair": {
                        "width": 1,
                        "color": "rgb(130,203,208)"
                    },
                    // "type": 'datetime',
                    "dateTimeLabelFormats": {
                        "day": "%b %e",
                        "week": "%Y<br/>%m-%d",
                        "month": "%b",
                        "year": "%Y",
                    },
                    "labels": {
                        "style": {
                            "color": "#606060",
                            "fontFamily": "Lato Regular"
                        },
                        // "formatter": function () {
                        //     return window.Highcharts.dateFormat('%a %d %b ', this.value);
                        // }
                    },
                    "title": {
                        "style": {
                            "color": "#606060",
                            "fontFamily": "Open Sans"
                        }
                    },
                    "ordinal": false,
                }
            ],
            "yAxis": [], //This need to be manipulated with left and right y axis
            "rangeSelector": {
                "enabled": true,
                "height": 0,
                "inputEnabled": false,
                "buttonTheme": {
                    "visibility": "hidden"
                },
                "labelStyle": {
                    "visibility": "hidden"
                }
            },
            "title": {
                "text": "",
                "style": {
                    "color": "#606060",
                    "fontFamily": "Open Sans"
                }
            },
            "enableMouseTracking": true,
            "enableStickyTracking": false,
            "tooltip": {
                "enabled": true,
                "animation": true,
                "shared": true,
                "useHTML": true,
                "shape": "callout",
                "backgroundColor": "transparent",
                "shadow": "none",
                "borderColor": "transparent", "marginLeft": 0,
                "borderRadius": 0,
                "hideDelay": 500,
                // "className": "chart-tooltip",
                // "className": "chart-tooltip-wrapper",
                // "formatter": function () {
                //     //  debugger;
                //     let tooltipTitle = '';
                //     let timestamp = this.x / 1000
                //     tooltipTitle = moment.unix(timestamp).format("ddd, MMM D, YYYY");
                //     let shared_points = this.points

                //     let s = '<div style="background-color:#777;padding:2px;color:white">' + tooltipTitle + '</div>';

                //     //first find all headers needed to put on tooltip
                //     let subheaders = []
                //     shared_points.forEach((point) => {
                //         let header = point.series.userOptions.seriesName;

                //         if (!subheaders.includes(header)) {
                //             subheaders.push(header)
                //         }
                //     })


                //     //go through all subheaders and create the html tag, then go through each point and then put them under
                //     subheaders.forEach((header, I) => {
                //         // debugger;
                //         let header_color = shared_points[I].color;
                //         let header_html = '<div style="margin:5px 2px 0 2px;font-weight:bold;color:' + header_color + '">' + header.toUpperCase() + '</div>';
                //         s += header_html
                //         shared_points.forEach((point) => {
                //             let seriesMeasureName = point.series.userOptions.name;
                //             let seriesName = point.series.userOptions.seriesName.toUpperCase();
                //             let seriesColor = point.color;

                //             // debugger;
                //             if (header == point.series.userOptions.seriesName) {
                //                 let measure_element_html = '<div style="margin-left:5px;margin-right:5px;"><span style="font-size:17px; margin-right:2px;color:' + seriesColor + '">&#9679;</span> ' + point.series.userOptions.mainMeasure + " : " + point.y + "</div>"
                //                 s += measure_element_html
                //             }
                //         })
                //     })
                //     return s
                // }
            },
            "navigation": {
                "buttonOptions": {
                    "enabled": false
                }
            },
            "navigator": {
                "baseSeries": 0,
                "height": 15,
                "useHTML": true,
                "enabled": false
            },
            "scrollbar": {
                "height": 6,
                "barBorderRadius": 5,
                "barBorderWidth": 1,
                "barBorderColor": "purple",
                "barBackgroundColor": "purple",
                "enabled": false
            },
            //"series": series,
            "series": [],
            "plotOptions": {
                "series": {
                    "pointInterval": undefined,
                    "turboThreshold": 1000000000000000000,
                    "showInNavigator": true,
                    "events": {

                    },
                    "animation": {
                        duration: 1200
                    },
                    //"stacking": false,
                }
            }
        },
        series: {
            "data": [], // push data for series
            "name": "series name",
            //"colorKey": setChartSeriesColorKey({ identifier, identifierIndex, measure, measureIndex, seriesName, resource_id, tile: firstTile, }),
            //"mainCKey": setChartSeriesColorKey({ isMainColor: true, identifier, identifierIndex, measure, measureIndex, seriesName, resource_id, tile: firstTile, }),
            "id": "put unique id",
            "currency": {}, //{ code: currency, symbol: currency_symbol },
            "dataGrouping": {
                "units": [
                    [
                        "day",
                        [
                            1
                        ]
                    ]
                ],
                "enabled": false
            },
            "type": 'column',
            "negativeColor": "#FF0000",
            "yAxis": "",
            "category": "category name",
            "seriesName": "pull seriesName",
            "mainMeasure": "put measure value",
            "subCategory": "",
            "color": "#81D4FA",

        },
        yAxis: {
            "id": "",
            "title": {
                "text": "",
                "style": {
                    "color": "#606060",
                    "fontFamily": "Lato Regular"
                },
                "useHTML": true,
                "align": "high",
                "offset": 0,
                "rotation": 0
            },
            "opposite": false,
            "labels": {
                "style": {
                    "color": "#606060",
                    "fontFamily": "Lato Regular"
                }
            },
            "crosshair": {
                "width": 1,
                "color": "rgb(130,203,208)"
            },
            "endOnTick": true,
            "showLastLabel": true,
            "maxPadding": 0.25,

        }

    },
    COLORS: {
        dailyFlash: {
            OTB_PRIMARY_L: "rgb(129, 212, 250)",
            OTB_PRIMARY_R: "rgb(255, 87, 34)",
            OTB_SDLY_L: "rgb(41, 182, 246)",
            OTB_SDLY_R: "rgb(255, 152, 0)",
            OTB_SDLY_ACTUAL_L: "rgb(2, 119, 189)",
            OTB_SDLY_ACTUAL_R: "rgb(255, 193, 7)",

            PICKUP_PICKUP_L: "rgb(129, 212, 250)",
            PICKUP_PICKUP_R: "rgb(255, 87, 34)",
        }
    }
}

export {
    DEBUG_MODE,
    CHART_OPTIONS,
    TILES,
    api_status, promises,
    API_REGISTRY, API_BASE_URL,
    API_REPORT_URL, API_DI_CERT,
    TILE_GROUP_ONE, TILE_GROUP_TWO
};