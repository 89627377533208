import React from 'react'
import { CardContent, Chip, Container, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Tooltip } from '@mui/material';
import moment from 'moment';
import { dateFormat, deepCopy, getCorrectValue, getFromState, getPermissions, getSdlyDate, getSeriesById, getTransformedSegments, in_array, truncate } from '../../app/util';
import { useSelector } from 'react-redux';
import { TILES } from "../../app/config";

import './rate-plan-popup.css'

const RatePlanHeader = (props) => {
    const state = useSelector(appState => appState);
    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    
    const { workspace_controls, comp_as_of_date, as_of_date } = app_state;
    const { sdlyOn, dayDate } = workspace_controls;
    let { segmentName, stay_date, activeTiles, compdate, ratePlanMeta } = props;
    // debugger;
    let active_tiles = activeTiles;//ui_state.active_tiles;
    let propDetails = getSeriesById("OSR").data;
    stay_date = (stay_date) ? stay_date : as_of_date;

    let otbSdlyDate = (propDetails) ? propDetails['meta']['sdly_asof'] : dateFormat(moment(as_of_date).subtract(364, "days"), "ddd, MMM DD, YYYY");

    const getColumnsName = () => {
        let valueArr = [];
        active_tiles.map((tile, index) => {
            let ratePlanColumns = TILES[tile].tableConfig.columns[0].subcolumns_rateplan;
            ratePlanColumns.map((kpi) => {
                valueArr.push(kpi.display);
            })
            if (in_array([tile], ['otb', 'pickup']) && sdlyOn) {
                ratePlanColumns.map((kpi) => {
                    valueArr.push(kpi.display);
                })
            }
            if (tile === 'pace') {
                ratePlanColumns.map((kpi) => {
                    valueArr.push(kpi.display);
                })
            }
        })
        return valueArr;
    }

    return (
        <>
            <TableHead className='rateplan-head'>
                {/* <TableRow className='rateplan-head-row head-row-title'>
                    {active_tiles.map((tile, index) => {
                        let ratePlanColumns = TILES[tile].tableConfig.columns[0].subcolumns_rateplan;
                        return (<>
                            {(index === 0 && <TableCell className='rateplan-th ' colSpan={ratePlanColumns.length + 1}>
                                <div className='rateplan-box-title'>
                                    <span>{truncate(segmentName)}</span>
                                    {(stay_date &&
                                        <span>{moment(stay_date).format("ddd, MMM DD, YYYY")}</span>
                                    )}
                                </div>
                            </TableCell>
                            )}
                            {(index > 0 && <TableCell className='rateplan-th ' colSpan={ratePlanColumns.length}>
                                <div className='rateplan-box-title'></div>
                            </TableCell>
                            )}
                            {(in_array([tile], ['otb', 'pickup']) && sdlyOn && <TableCell className='rateplan-th ' colSpan={ratePlanColumns.length}><div className='rateplan-box-title'></div></TableCell>)}
                            {(tile === 'pace' && <TableCell className='rateplan-th ' colSpan={ratePlanColumns.length}>
                                <div className='rateplan-box-title'>
                                    <span></span>
                                    <span>{moment(props.compdate).format("ddd, MMM DD, YYYY")}</span>
                                </div>
                            </TableCell>
                            )}

                        </>)
                    })}

                </TableRow> */}


                {/*  this row is for tile name in intellimerge case */}
                {(ui_state.active_tiles.length > 1 && <TableRow className='rateplan-head-row head-row-title'>
                    <TableCell className='rateplan-th rateplan-th-row-span' rowSpan={3}>Rate Plan</TableCell>
                    {active_tiles.map((tile, index) => {
                        let ratePlanColumns = TILES[tile].tableConfig.columns[0].subcolumns_rateplan;
                        let tileName = TILES[tile].label;
                        let asOfDate = dateFormat(stay_date, "ddd, MMM DD, YYYY");//((tileName.toLowerCase() === 'otb' || tileName.toLowerCase() === 'pickup') && sdlyOn) ? dateFormat(as_of_date, "ddd, MMM DD, YYYY") : tileName;
                        let colSpanValue = (sdlyOn || tile === 'pace') ? ratePlanColumns.length * 2 : ratePlanColumns.length;
                        return (<>
                            <TableCell className='rateplan-th' colSpan={colSpanValue}>{tileName}</TableCell>
                            {/* {((tile.toLowerCase() === 'otb' || tile.toLowerCase() === 'pickup') && sdlyOn && <TableCell className='rateplan-th' colSpan={ratePlanColumns.length}>{dateFormat(otbSdlyDate, "ddd, MMM DD, YYYY")} </TableCell>)} */}
                            {/* {(in_array([tile], ['pickup', 'otb']) &&<TableCell className='rateplan-th' colSpan={ratePlanColumns.length}>testtdste{tileName}</TableCell>)} */}

                        </>)
                    })}
                </TableRow>
                )}

                {/*  End this row is for tile name in intellimerge case */}

                {(active_tiles.length > 1 && stay_date !== '' && <TableRow className='rateplan-head-row head-row-tile'  >
                    {/* <TableCell className='rateplan-th rateplan-th-row-span' rowSpan={2}>Rate Plan</TableCell> */}
                    {active_tiles.map((tile, index) => {
                        let ratePlanColumns = TILES[tile].tableConfig.columns[0].subcolumns_rateplan;
                        let tileName = TILES[tile].label;
                        let asOfDate = dateFormat(stay_date, "ddd, MMM DD, YYYY");//((tileName.toLowerCase() === 'otb' || tileName.toLowerCase() === 'pickup') && sdlyOn) ? dateFormat(as_of_date, "ddd, MMM DD, YYYY") : tileName;

                        return (<>
                            <TableCell className='rateplan-th' colSpan={ratePlanColumns.length}>{asOfDate}</TableCell>
                            {((tile.toLowerCase() === 'otb' || tile.toLowerCase() === 'pickup') && sdlyOn && <TableCell className='rateplan-th' colSpan={ratePlanColumns.length}>{dateFormat(getSdlyDate(stay_date, app_state, '', dayDate, ratePlanMeta, props.stay_date), "ddd, MMM DD, YYYY")}</TableCell>)}
                            {((tile.toLowerCase() === 'pace') && <TableCell className='rateplan-th' colSpan={ratePlanColumns.length}>{dateFormat(getSdlyDate(stay_date,app_state,'pace'), "ddd, MMM DD, YYYY")} </TableCell>)}
                        </>)
                    })}
                </TableRow>
                )}

                {(active_tiles.length === 1 && in_array(active_tiles, ['pickup', 'otb']) && sdlyOn && <TableRow className='rateplan-head-row head-row-tile'  >
                    {ui_state.active_tiles.length === 1 &&<TableCell className='rateplan-th rateplan-th-row-span' rowSpan={2}>Rate Plan</TableCell>}
                    {active_tiles.map((tile, index) => {
                        let ratePlanColumns = TILES[tile].tableConfig.columns[0].subcolumns_rateplan;
                        let tileName = TILES[tile].label;
                        tileName = ((tileName.toLowerCase() === 'otb' || tileName.toLowerCase() === 'pickup') && sdlyOn) ? dateFormat(stay_date, "ddd, MMM DD, YYYY") : tileName;
                        return (<>
                            <TableCell className='rateplan-th' colSpan={ratePlanColumns.length}>{tileName}</TableCell>
                            {(in_array([tile], ['pickup', 'otb']) && sdlyOn && <TableCell className='rateplan-th' colSpan={ratePlanColumns.length}>
                               {dateFormat(getSdlyDate(stay_date,app_state, '', dayDate, ratePlanMeta, props.stay_date), "ddd, MMM DD, YYYY")}
                            </TableCell>)}

                        </>)
                    })}
                </TableRow>
                )}

                {(active_tiles.length === 1 && ui_state.active_tiles.length === 1 && stay_date !== '' && in_array(active_tiles, ['pace']) && <TableRow className='rateplan-head-row head-row-tile'  >
                    <TableCell className='rateplan-th rateplan-th-row-span' rowSpan={2}>Rate Plan</TableCell>
                    {active_tiles.map((tile, index) => {
                        let ratePlanColumns = TILES[tile].tableConfig.columns[0].subcolumns_rateplan;
                        let tileName = TILES[tile].label;
                        let dates =  dateFormat(stay_date, "ddd, MMM DD, YYYY");
                        return (<>
                            <TableCell className='rateplan-th' colSpan={ratePlanColumns.length}>{dates}</TableCell>
                            {/* <TableCell className='rateplan-th' colSpan={ratePlanColumns.length}>{moment(compdate).format("ddd, MMM DD, YYYY")}</TableCell> */}
                            <TableCell className='rateplan-th' colSpan={ratePlanColumns.length}>{dateFormat(getSdlyDate(stay_date,app_state,'pace'), "ddd, MMM DD, YYYY")} </TableCell>
                            {/* {(in_array([tile], ['pickup', 'otb']) && sdlyOn && <TableCell className='rateplan-th' colSpan={ratePlanColumns.length}>{dateFormat(otbSdlyDate, "ddd, MMM DD, YYYY")} </TableCell>)} */}

                        </>)
                    })}
                </TableRow>
                )}



                <TableRow className='rateplan-head-row head-row-label' >
                    {(active_tiles.length === 1 && in_array(active_tiles,['pace']) && stay_date === '' && <TableCell className='rateplan-th rateplan-th-row-span'>Rate Plan</TableCell>)}

                    {(active_tiles.length === 1 && ui_state.active_tiles.length === 1 && !in_array(active_tiles,['pace']) && !sdlyOn && <TableCell className='rateplan-th rateplan-th-row-span'>Rate Plan</TableCell>)}
                    {
                        getColumnsName().map((v) => {
                            return (<>
                                <TableCell className='rateplan-th'>{v}</TableCell>
                            </>)
                        })
                    }
                </TableRow>
            </TableHead>
        </>
    )
}

export default RatePlanHeader
