import React from 'react'
import { CardContent, Chip, Container, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Tooltip } from '@mui/material';
import { convertToPercent } from '../../app/util';
import { getCodeDetails } from './DisplayData';
import { useSelector } from 'react-redux';
import { TILES } from "../../app/config";
import { checkCorrectValue, getDataMainKey, getKpisSymbol } from './ratePlan_functions';


function RatePlanList(props) {
    const state = useSelector(appState => appState);
    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { workspace_controls, comp_as_of_date, as_of_date } = app_state;
    const { sdlyOn, dayDate } = workspace_controls;
    let { data, activeTiles } = props;
    let data1 = data.sort((a, b) => b.data.primary.Rooms - a.data.primary.Rooms);
    let dataStatus = (data && data.length) ? true : false;
    let dataAvailable = false;
    let dataMainKey = "primary";
    // let active_tiles = ['otb', 'pickup'];
    let active_tiles = activeTiles;
    let dataKey = (active_tiles.length === 1) ? getDataMainKey(active_tiles[0]) : "primary"; 
    let val = 0;
    function getColumnValues(item) {
        let dataMainKey = "primary";
        let columnValues = [];
        
        let sdlyKey = (dayDate === 'day') ? "sdly" : "sdtly" ;
        let sdlyPickupKey = (dayDate === 'day') ? "sdly_pickup" : "sdtly_pickup" ;
        active_tiles.map((tile) => {
            dataMainKey = getDataMainKey(tile);
            let ratePlanColumns = TILES[tile].tableConfig.columns[0].subcolumns_rateplan;
            ratePlanColumns.map((kpi) => {
                let symbol = getKpisSymbol(kpi.value);
                if (item.data[dataMainKey]) {
                    let val = checkCorrectValue(item.data[dataMainKey][kpi.value], symbol);
                    columnValues.push(val);
                }
            })
            if (tile === 'otb' && sdlyOn) {
                ratePlanColumns.map((kpi) => {
                    let symbol = getKpisSymbol(kpi.value);
                    if (item.data[sdlyKey]) {
                        let val = checkCorrectValue(item.data[sdlyKey][kpi.value], symbol);
                        columnValues.push(val);
                    }
                })
            }
            if (tile === 'pickup' && sdlyOn) {
                ratePlanColumns.map((kpi) => {
                    let symbol = getKpisSymbol(kpi.value);
                    if (item.data[sdlyPickupKey]) {
                        let val = checkCorrectValue(item.data[sdlyPickupKey][kpi.value], symbol);
                        columnValues.push(val);
                    }
                })
            }
            if (tile === 'pace') {
                ratePlanColumns.map((kpi) => {
                    let symbol = getKpisSymbol(kpi.value);
                    if (item.data['comparison']) {
                        let val = checkCorrectValue(item.data['comparison'][kpi.value], symbol);
                        columnValues.push(val);
                    }
                })
            }
        })
        return columnValues;
    }

    return (
        <>
            {(dataStatus && data && data.map((item, index) => {
                if (item.data[dataKey] && item.data[dataKey].Rooms !== 0) {
                    dataAvailable = true;
                    let bgcolor = (index % 2 === 0) ? "" : "rateplan-even-row";
                    return (
                        // <TableRow key={index} className={`rateplan-row ${bgcolor}`} >
                        <TableRow key={index} className={`rateplan-row rateplan-even-row`} >

                            <TableCell component="td" scope="row" className='rateplan-td'>
                                <Tooltip title={getCodeDetails(item.ratePlanCode, app_state)} arrow followCursor>
                                    <div>{item.ratePlanCode}</div>
                                </Tooltip>
                            </TableCell>
                            {
                                getColumnValues(item).map((v) => {
                                    return (<>
                                        <TableCell component="td" scope="row" className='rateplan-td'>
                                            {v}
                                        </TableCell>
                                    </>)
                                })
                            }


                            {/* {
                                        active_tiles.map((tile) => {
                                            dataMainKey = getDataMainKey(tile);
                                            let ratePlanColumns = TILES[tile].tableConfig.columns[0].subcolumns_rateplan;
                                            debugger;
                                            return ratePlanColumns.map((kpi) => {
                                                let symbol = getKpisSymbol(kpi.value);
                                                return (<>
                                                    <TableCell component="td" scope="row" className='rateplan-td'>
                                                        {(item.data[dataMainKey] && checkCorrectValue(item.data[dataMainKey][kpi.value], symbol))}
                                                    </TableCell>
                                                </>)
                                            })
                                        })
                                    } */}
                        </TableRow>
                    )

                }
            })
            )}

            {((!dataStatus || !dataAvailable) && <>
                <TableRow>
                    <TableCell colSpan={7} className='rateplan-heading' sx={{ textAlign: "center" }}>No Rate Plan Found!</TableCell>
                </TableRow>
            </>
            )}



        </>
    )
}

export default RatePlanList
