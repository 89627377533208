//This confg will be used to configure event/action names for analytics purposes
const analytics = {
    events: {
        logout: 'logout',
        login_success: 'login success',
        login_fail: 'login failed',
        tile_change: 'Tile Change',
        tile_merged: 'Tile Intelimerged',
        property_changed: 'Property changed',
        ds_changed: 'Data Source changed',
        notification_popup_open: "Notification Popup Open",
        flash_report_opened: "Flash Report opened",
        group_pricing_popup_opened: "Group Pricing Popup Opened",
        pricing_ratepush_success: "Pricing Rate Pushed",
        export_download:"Export Download",
        change_data_level:"Change Data Level",
    },
    axns: {
        
    }

};
export default  analytics;