
import moment from "moment";
import { it } from "date-fns/locale";
import dataService from "../../services/data.service";
import { deepCopy, aggregateObject, truncate, daysCountFromRange, getAppData, getDatesInRange } from "../../app/util";
import { API_REGISTRY, TILES } from "../../app/config";

export function transformRestrictionsData(app_state, auth, data, params) {
    const { primary_stay_range } = app_state;
    const { date_from, date_until } = primary_stay_range;
    let stayDates = getDatesInRange(date_from, date_until);
    let { tile, roomType, roomCodes } = params;
    roomCodes = roomCodes && roomCodes.length ? roomCodes : [''];
    let transformed = [];
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let restrictionData = data.filter((item) => item.data_id === "GetRestrictions");
    let restrictionRoomTypeData = data.filter((item) => item.data_id === "GetRestrictionsRoomType");

    let osrData = API_REGISTRY['OSR'].data;
    let osrRecData = API_REGISTRY['OSRRateRecommend'].data;
    let osrForecastData = API_REGISTRY['OSRContrainedForecast'].data;

    restrictionData = restrictionData.length ? restrictionData[0] : {}
    restrictionRoomTypeData = restrictionRoomTypeData.length ? restrictionRoomTypeData[0] : {}
    
    stayDates.map((date, i) => {
        let index = { date: date };
        let toBePushed = { index: index, restrictions: {} };
        let cur; let rec;
        // debugger;
        //Adding restrictions cur 
        if (restrictionData && restrictionData.cur) {
            if (roomCodes && roomCodes.length && roomCodes[0] !== '') {
                //Writer code to handle room type enabled conditions

            } else {
                cur = restrictionData.cur.filter((item) => item.stay_date === date);
                cur = cur.length ? cur[0] : {};
                toBePushed.restrictions.cur = cur;
            }
        }
        //Adding restrictions rec
        if (restrictionData && restrictionData.rec) {
            if (roomCodes && roomCodes.length && roomCodes[0] !== '') {
                //Writer code to handle room type enabled conditions

            } else {
                rec = restrictionData.rec.filter((item) => item.stay_date === date);
                rec = rec.length ? rec[0] : {};
                toBePushed.restrictions.rec = rec;
            }
        }

        //Adding OTB primary
        if (osrData && osrData.data && osrData.data.length) {
            // debugger;
            let osrItem = osrData.data.filter((item) => moment(item.index.date).format("YYYY-MM-DD") === date);

            osrItem = osrItem.length ? osrItem[0].HotelTotal.primary : {...blankObj};
            toBePushed.otb = osrItem;
        }

         //Adding Forecast
         if (osrForecastData && osrForecastData.data && osrForecastData.data.length) {
            let forecastItem = osrForecastData.data.filter((item) => moment(item.stay_date).format("YYYY-MM-DD") === date);
            if(forecastItem.length){
                forecastItem = forecastItem[0];
                toBePushed.forecast = {
                    Occ: forecastItem.predicted_total_cons_occ,
                    Rooms: forecastItem.predicted_total_cons_rooms,
                    ADR: forecastItem.predicted_total_uncons_adr,
                    Revenue: forecastItem.predicted_total_cons_revenue,
                    RevPAR: forecastItem.predicted_total_cons_revpar,
                };
            } else{
                toBePushed.forecast = toBePushed.otb;
            }
        }
        //Adding Rec Data
        if (osrRecData && osrRecData.data && osrRecData.data.length) {
            let recItem = osrRecData.data.filter((item) => moment(item.stay_date).format("YYYY-MM-DD") === date && item.is_base);
              
            toBePushed.bar_pricing = {
                Rooms: toBePushed.otb &&  toBePushed.otb.Rooms ?  toBePushed.otb.Rooms : 0,
                SellRate: toBePushed.otb && toBePushed.otb.SellRate ? toBePushed.otb.SellRate : 0
            };
        }
 
        transformed.push(toBePushed);
    });
    // debugger; 
    if (transformed.length) {
        return (transformed);
    }
    return (data);
}
export function transformRestrictionsRoomTypeData(app_state, auth, data, params){
    const { primary_stay_range, room_settings } = app_state;
    const { date_from, date_until } = primary_stay_range;
    let stayDates = getDatesInRange(date_from, date_until);
    let { tile,} = params;
    const roomCodes = room_settings.map(room=> room.room_code);

    let transformed = [];
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let restrictionData = data.find((item) => item.data_id === "GetRestrictions");
    let restrictionRoomTypeData = data.find((item) => item.data_id === "GetRestrictionsRoomType");

    let osrRecData = API_REGISTRY['OSRRateRecommend'].data;
    stayDates.forEach((date, i) => {
        let index = { date: date };
        let toBePushed = { index: index, restrictions: {}, bar_pricing:{} };
        let cur; let rec;
        // debugger;
        //Adding restrictions cur 
        if (restrictionRoomTypeData && restrictionRoomTypeData.cur) {
            if (room_settings && room_settings.length) {
                //Writer code to handle room type enabled conditions
                
                restrictionRoomTypeData.cur.find((item) => {
                    if(item.stay_date === date){

                        toBePushed.restrictions[item.room_type] ={
                            ...toBePushed.restrictions[item.room_type],
                            cur:{...item}
                        } ;
                    }
                });
        
            } 
        }
        //Adding restrictions rec
        if (restrictionData && restrictionData.rec) {
            if (room_settings && room_settings.length ) {
                //Writer code to handle room type enabled conditions
                restrictionRoomTypeData.rec.find((item) => {
                    if(item.stay_date === date && item.room_type){

                        toBePushed.restrictions[item.room_type] ={
                            ...toBePushed.restrictions[item.room_type],
                            rec:{...item}
                        } ;
                    }
                });
            } 
        }

        //Adding OTB primary
      
        //Adding Rec Data
        if (osrRecData && osrRecData.data && osrRecData.data.length) {
            osrRecData.data.find((recItem) => {
                if(moment(recItem.stay_date).format("YYYY-MM-DD") === date){
                    toBePushed.bar_pricing[recItem.room_type] ={
                        ...toBePushed.bar_pricing[recItem.room_type],
                        Rooms:recItem.rooms_sold,
                        SellRate: recItem.current_sell_rate
                    } ;
                } 
            });
        }
 
        transformed.push(toBePushed);
    });
    // debugger; 
    if (transformed.length) {
        return (transformed);
    }

}