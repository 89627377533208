import React, { useState } from 'react'
import { useSelector } from "react-redux";
import "./lockLayer.scss";
import { Modal } from '@mui/material';
import RequestSupport from '../RequestSupport/'

const LockLayer = ({ userName = '', isLock, module = 'module'}) => {
    const { app } = useSelector(state => state);
    const { current_state: { app_state: { property_name } } } = app;
    const [openRequestSupport, setOpenRequestSupport] = useState(false);
    function openRequestSupportModal() {
        setOpenRequestSupport(true);
    }
    function handleRequestSupportClose() {
        setOpenRequestSupport(false);
    }

    if (isLock) {
        return (
            <>
                <div className={`lock_layer `} >
                    <div className='message-text'>
                        <div className='message'>
                            {`Hello ${userName}, ${module} is not enabled for you.`}<br />
                            {`Would you like to request access?`}
                        </div>
                        <button className='request-btn' onClick={openRequestSupportModal}>Request Access</button>
                    </div>
                </div>
                <Modal
                    open={openRequestSupport}
                    onClose={handleRequestSupportClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <RequestSupport autoSubmit={true} handleClose={handleRequestSupportClose} message={`I would like to enable ${module} for ${property_name}`} />
                </Modal>
            </>
        )
    }
    return null
}
export default LockLayer