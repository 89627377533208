import moment from "moment";
import { convertToPercent, deepCopy, round,setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";


export function getMarketSellRatesData(data,columns,app_state) {
    let setup_data = [];
    let as_of_date = app_state.as_of_date
    let date_until = app_state.primary_stay_range.date_until;
    let date_from = app_state.primary_stay_range.date_from;
    let daysCount =  Math.abs(moment.duration(moment(as_of_date).diff(moment(date_until))).asDays())+1;
   
    if(!data[0]['info']){
        // data = data.slice(0,daysCount);
        data.map((item, index) => {
            if (moment(item.index.date) <= moment(date_until)) {
                let data_columns = [];
                columns.map(col => {
                    let dataObj = {id:col.id};
                    let { mainKey, k } = col;
                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, item.index.date);
                    }
                    if (col.type === 'dataColumn') {
                        col.subcolumns.map(key => {
                            if (item[mainKey][k]) {
                                dataObj[key.value] = item[mainKey][k].avg;
                            }else{
                                dataObj[key.value] = 0;
                            }
                    })
                }
                data_columns.push(dataObj);
            })
            setup_data.push(data_columns);
        
        }
        })
    }
    return setup_data;
}



/// function to calculate the total 
export const marketSellRatesTotal = (obj, key, index, count, ui_state) => {
    let total = 0;
    let daysInMonth = moment().daysInMonth();
    let totalDays = 0;
    let currSymbol = ui_state.currency_symbol;
    for (let i = 0; i < obj.length; i++) {
        if (obj[i][count][key.value] > 0) {
            total = parseFloat(total + obj[i][count][key.value]);
            totalDays++;
        }
    }
    total = Math.round(total / totalDays).toFixed(2);
    total = (isNaN(total)) ? parseFloat(0).toFixed(2) : total;
    return currSymbol + "" + total;
};

export const marketSellRatesTotal_old = (obj, key, index, count,ui_state) => {
    let total = 0;
    let daysInMonth = moment().daysInMonth();
    let totalDays = 0;
    let currSymbol = ui_state.currency_symbol;
    for (let i = 0; i < obj.length; i++) {
        total = parseFloat(total + obj[i][count][key.value]);
        if (obj[i][count][key.value] > 0) {
            totalDays++;
        }
    }
    total = Math.round(total / totalDays).toFixed(2);
    total = (isNaN(total)) ? parseFloat(0).toFixed(2) : total;
    return currSymbol + "" + total;
};


export const summaryTotal = (totalData,app_state,displayDataFormat) =>{
    let setTotalRow = [];
    totalData.map((i, k) => {
        let sty = {};
        sty["background"] = "#606060 !important";
        sty = { fontWeight: "bold" };
        if (k === "Revenue") {
            sty["color"] = "#3583B8";
        } else {
            sty["color"] = "#fff !important";
        }


    })
}



// function to get Market sell Rate data 
export const getMarketSellRateInt = (date,mainKey, id, tileName, k, key, index, dataKey, auth, app_state,marketSellRateData) => {
    // debugger;
    // let marketSellRateData = (dataService.getSeriesById("MarketSellRate").data)?deepCopy(dataService.getSeriesById("MarketSellRate").data.data):[];
    // let marketSellRateData = deepCopy(dataService.getSeriesById("MarketSellRate").data.data);
    let keyValue = key.value;
    let dataObj = {};
    mainKey = "sets";
    if(tileName === "market_sell_rates" && marketSellRateData){
        let itemsArr = marketSellRateData;//marketSellRateData.filter((item)=> item.index.date === moment(date).format('YYYY-MM-DD'));
        if(itemsArr){
            dataObj[keyValue] = (itemsArr[mainKey][k]) ? itemsArr[mainKey][k].avg : 0;
        }else{ 
            dataObj[keyValue] = 0;
        }
    }
    return dataObj;
}

