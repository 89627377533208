
import {Box, Switch} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';

const UnconstrainedPicker = () => {
    const {current_state} = useSelector(state => state.app);
    const {property_id, property_name, workspace_controls, primary_stay_range, user_view}= current_state.app_state;
  
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const dispatch = useDispatch();

    const handleClick = (type,value)=>{
        const updateTypes = [type];
        controls.unconstrained = value;
        // debugger;
        // dispatch(updateAppState(updateTypes, {app_state: {workspace_controls: controls}}));
        dispatch(updateAppState(type, {
            app_state: {
                workspace_controls: { ...controls },
                data_load: true
            },
            ui_state: { showspinner: true }
        },));
        window.analyze.track("tile_change", {
            "selected_tile": current_state.ui_state.active_tiles.join(),
            ...primary_stay_range,
            property_id, property_name,
            data_view: user_view,unconstrained:value
        }, {},  {
            app_state: {
                workspace_controls: { ...controls },
                data_load: true
            },
            ui_state: { showspinner: true }
        });
    }

    return(
        <Box className='switch-box'>
            <Box className="data-level-label">Unconstrained</Box>
            <Switch sx={{ml:0}} 
                checked={controls.unconstrained} 
                onChange={(e) => {handleClick("unconToggle", !controls.unconstrained)}} 
            />
        </Box>
        )

}

export default UnconstrainedPicker