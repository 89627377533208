import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material/";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_STATE } from "../../actions/types";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
  Container,
} from "@mui/material/";
import "./style.css";
import { color } from "highcharts";

function SettingsSideMenu(props) {
  const { app, auth } = useSelector((state) => state);
  const { ui_state, } = app.current_state;
  const { settingsActiveIndex, active_settings_menu } = ui_state;
  const { settingsSideMenuList } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('isChanged', false);
  }, []);

  const { user } = auth;
  const { user_login_type } = user;
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [activeSettingsMenu, setActiveSettingsMenu] = useState(active_settings_menu);
  // debugger;
  if (ui_state.isFromHeader) {
    dispatch({
      type: UPDATE_STATE,
      payload: { ui_state: { isFromHeader: false } },
    });
  }


  const handleListItemClick = (item) => (event, isExpanded) => {
    let shakeBtnsDiv = document.getElementsByClassName("top-axn-bar-1-sub");
    let shakeAlert = document.getElementsByClassName("save-changes-alert");
    const isChanged = localStorage.getItem('isChanged');
    if (isChanged == 'true') {
      if (shakeBtnsDiv.length > 0) {
        shakeBtnsDiv[0].className += " bt-shake";
        setTimeout(() => {
          shakeBtnsDiv[0].className = shakeBtnsDiv[0].className.replace("bt-shake", "").trim();
        }, 2000);
      }
      if (shakeAlert.length > 0) {
        shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
        setTimeout(() => {
          shakeAlert[0].className += " display-none";
        }, 2000);
      }
      return;
    }
    ui_state.isFromHeader = false;
    ui_state.active_settings_menu = active_settings_menu;
    ui_state.activeSettingsComponentName = item.component;
    ui_state.settingsActiveIndex = item.index;

    dispatch({
      type: UPDATE_STATE,
      payload: {
        ui_state: {
          isFromHeader: false,
          active_settings_menu: active_settings_menu,
          activeSettingsComponentName: item.component,
          settingsActiveIndex: item.index,
        },
      },
    });
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleChange = (panel) => {
    let shakeBtnsDiv = document.getElementsByClassName("top-axn-bar-1-sub");
    let shakeAlert = document.getElementsByClassName("save-changes-alert");
    const isChanged = localStorage.getItem('isChanged');
    if (isChanged == 'true') {
      if (shakeBtnsDiv.length > 0) {
        shakeBtnsDiv[0].className += " bt-shake";
        setTimeout(() => {
          shakeBtnsDiv[0].className = shakeBtnsDiv[0].className.replace("bt-shake", "").trim();
        }, 2000);
      }
      if (shakeAlert.length > 0) {
        shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
        setTimeout(() => {
          shakeAlert[0].className += " display-none";
        }, 2000);
      }
      return;
    }
    setActiveSettingsMenu(panel)
    ui_state.active_settings_menu = panel
    let menuPanel = settingsSideMenuList.find(menuItem => menuItem.menuId === panel);
    const settingsActiveItem = eval(menuPanel.data)[0];
    let selected_index = settingsActiveIndex;
    if (panel === "profileSettingsMenu") selected_index = 1;
    if (panel === "propertySettingsMenu") selected_index = 3;
    if (panel === "schedulerSettingsMenu") selected_index = 16;
    ui_state.isFromHeader = false;
    ui_state.active_settings_menu = panel;
    ui_state.activeSettingsComponentName = settingsActiveItem.component;
    ui_state.settingsActiveIndex = selected_index;
    dispatch({
      type: UPDATE_STATE,
      payload: {
        ui_state: {
          isFromHeader: false,
          active_settings_menu: panel,
          activeSettingsComponentName: settingsActiveItem.component,
          settingsActiveIndex: selected_index,
        },
      },
    });
  };

  useEffect(() => {
    setActiveSettingsMenu(active_settings_menu)
  }, [active_settings_menu])

  return (
    <>
      <Grid
        className={`setting-sidemenu ${!isSidebarCollapsed ? "" : "collapsed"}`}
      >
        <div className="sidemenu-arrow-icon" onClick={toggleSidebar}>
          {isSidebarCollapsed ?
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path></svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M168.49,199.51a12,12,0,0,1-17,17l-80-80a12,12,0,0,1,0-17l80-80a12,12,0,0,1,17,17L97,128Z"></path></svg>
          }
        </div>
        <Container className='sideMenuContainer' style={{ minHeight: "100%" }}>
          {isSidebarCollapsed ?
            <>
              <svg className='collapsedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM74.08,197.5a64,64,0,0,1,107.84,0,87.83,87.83,0,0,1-107.84,0ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120Zm97.76,66.41a79.66,79.66,0,0,0-36.06-28.75,48,48,0,1,0-59.4,0,79.66,79.66,0,0,0-36.06,28.75,88,88,0,1,1,131.52,0Z"></path></svg>
              <svg className='collapsedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M240,208H224V96a16,16,0,0,0-16-16H144V32a16,16,0,0,0-24.88-13.32L39.12,72A16,16,0,0,0,32,85.34V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM208,96V208H144V96ZM48,85.34,128,32V208H48ZM112,112v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm-32,0v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm0,56v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Zm32,0v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Z"></path></svg>
              <svg className='collapsedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M128,32a96,96,0,1,0,96,96A96.11,96.11,0,0,0,128,32Zm0,176a80,80,0,1,1,80-80A80.09,80.09,0,0,1,128,208ZM61.66,29.66l-32,32A8,8,0,0,1,18.34,50.34l32-32A8,8,0,1,1,61.66,29.66Zm176,32a8,8,0,0,1-11.32,0l-32-32a8,8,0,0,1,11.32-11.32l32,32A8,8,0,0,1,237.66,61.66ZM184,120a8,8,0,0,1,0,16H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48Z"></path></svg>
            </>
            :
            <>
              {settingsSideMenuList.map((item) => (<Box key={item.menuId}>
                {item.data.length === 1 && <Accordion
                  component="div"
                  className="settings-side-menu single-child"
                  elevation={0}
                  square={true}
                  expanded={item.menuId === activeSettingsMenu}
                  onChange={() => handleChange(item.menuId)}
                  sx={{ backgroundColor: "#333", color: "#fff" }}
                >
                  <AccordionSummary className="accordian-menu">
                    <Box sx={{ width: "100%" }}>
                      <nav>
                        <List sx={{ maxHeight: "50vh", overflow: "auto", pb: 0 }}>
                          {item.data.map((item, index) => {
                            // debugger; 
                            if (item.access && item.access && item.access.includes(user_login_type)) {
                              return (<div key={"menu-" + index} className="menu-item">
                                {/* <ListItem disablePadding> */}
                                <ListItemButton
                                  className="settings-side-menu-item "
                                  selected={settingsActiveIndex === item.index}
                                  onClick={handleListItemClick(item)}
                                  sx={{ p: 0, pl: 0 }}
                                >
                                  <ListItemIcon sx={{ minWidth: 25 }}>
                                    {item.icon}
                                  </ListItemIcon>
                                  <span className="menu-item-label">{item.label}</span>
                                </ListItemButton>
                                {/* </ListItem> */}
                              </div>)
                            } else {
                              return false;
                            }
                          })}
                        </List>
                      </nav>
                    </Box>
                  </AccordionSummary>
                </Accordion>}
                {item.data.length > 1 && <Accordion
                  className="settings-side-menu"
                  square={true}
                  expanded={item.menuId === activeSettingsMenu}
                  onChange={() => handleChange(item.menuId)}
                  sx={{ backgroundColor: "#333", color: "#fff" }}
                >
                  <AccordionSummary className="accordian-menu" expandIcon={<ExpandMoreIcon />}>
                    {item.menuIcon}
                    <Typography sx={{ ml: 1, flexShrink: 0 }}>{item.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <Box sx={{ width: "100%" }}>
                      <nav>
                        <List sx={{ maxHeight: "50vh", overflow: "auto", pb: 0 }}>
                          {item.data.map((item, index) => {
                            if (item.access && item.access.includes(user_login_type)) {
                              return (
                                <div key={"menu-" + index} className="menu-item">
                                  <ListItem disablePadding>
                                    <ListItemButton
                                      selected={settingsActiveIndex === item.index}
                                      onClick={handleListItemClick(item)}
                                      sx={{ p: 0, pl: 1 }}
                                    >
                                      <ListItemIcon sx={{ minWidth: 25, ml: 2 }}>
                                        {item.icon}
                                      </ListItemIcon>
                                      <ListItemText
                                        className="menu-item-label"
                                        primary={item.label}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                  <Divider sx={{ display: item.isLast ? "none" : "block" }} />
                                </div>
                              )
                            }
                          })}
                        </List>
                      </nav>
                    </Box>
                  </AccordionDetails>
                </Accordion>}
              </Box>
              ))}

            </>
          }
        </Container></Grid>
    </>
  );
}

export default SettingsSideMenu;
