import moment from "moment";
import { convertToPercent, round, getCompsetMedian, setUpDate, getList, dateFormat, getAppData, getObjectFilter, deepCopy, getAllDates, getFromState, getTransformedSegments, in_array } from "../../app/util"
import { concatForcastData, getMarketDbaData, concatForcastDataSegmented, getMarketDataInt, getMarketDbaDataInt } from "./tilesData/market";
// import {getBookingCurveSegmentedData } from "./tilesData/bookingcurve"
import { getBookingCurveData, getBookingCurveDataInt, getBookingCurveDataInt_segment, getBookingCurveDbaData, getBookingCurveSegmentedData } from "./tilesData/bookingcurvenew"
import { useDataSummary } from "../Tile/kpiService";
import dataService from "../../services/data.service";
import { getPickupData, getPickupDataInt, getPickupDataInt_segment } from "./tilesData/pickup";
import { getMarketSellRateInt, getMarketSellRatesData } from "./tilesData/marketsellrates";
import { getOtbDataInt, getOtbSegmentData, getOtbDataIntTest } from "./tilesData/otb";
import { getForecastNonSegmentedData, getForecastNonSegmentedDataNew, getForeCastDataInt, getForeCastDataInt_segment } from "./tilesData/forecast";
import { transformSegmentData } from "./segmentedData";
import { getBudgetInt } from "./tilesData/budget";
import { addPastDates, getPricingDataFormatted, getPricingDataInt } from "./tilesData/pricing";

export function getActiveTilesData(state, app_state, auth, active_tiles, segments) {
    let otbSegmentData;
    let dataObj = {};
    active_tiles.map((tile, tileIndex) => {
        if (tile === "otb" || tile === "pickup") {
            if (!dataObj[tile] && !active_tiles.includes('market')) {
                let otbData = deepCopy(dataService.getSeriesById("OSR").data.data);
                otbSegmentData = transformSegmentData(app_state, auth, otbData, { segmentedOn: app_state.workspace_controls.segmented_view, tile: tile, segments: segments, active_tiles: active_tiles });
                dataObj[tile] = otbSegmentData;
            }
            if (active_tiles.includes('market')) {
                let otbData = deepCopy(dataService.getSeriesById("marketOtbSegment").data.data);
                otbSegmentData = transformSegmentData(app_state, auth, otbData, { segmentedOn: app_state.workspace_controls.segmented_view, tile: tile, segments: segments, active_tiles: active_tiles });
                dataObj[tile] = otbSegmentData;
            }
        } else if (tile === "forecast") {
            if (!dataObj[tile] && !active_tiles.includes('market')) {
                let forecastData = deepCopy(dataService.getSeriesById("OSRContrainedForecast").data.data_transformed);
                let forecastSegmentData = transformSegmentData(app_state, auth, forecastData, { segmentedOn: true, tile: tile, segments: segments, active_tiles: active_tiles });
                dataObj[tile] = forecastSegmentData;
            }
            if (active_tiles.includes('market')) {
                console.log(dataService.getSeriesById("ForecastMarketSegment").data);
                let forecastData = deepCopy(dataService.getSeriesById("ForecastMarketSegment").data.data);
                let forecastSegmentData = transformSegmentData(app_state, auth, forecastData, { segmentedOn: true, tile: tile, segments: segments, active_tiles: active_tiles });
                dataObj[tile] = forecastSegmentData;
            }
        } else if (tile === "pace") {
            let bookingCurveData = deepCopy(dataService.getSeriesById("OSRBookingPaceSegment").data.data);
            let bookingpaceSegmentData = transformSegmentData(app_state, auth, bookingCurveData, { segmentedOn: app_state.workspace_controls.segmented_view, tile: tile, segments: segments });
            dataObj[tile] = bookingpaceSegmentData;
        } else if (tile === "compset") {
            let compsetData = deepCopy(dataService.getSeriesById("Compset").data.data);
            let compsetSegmentData = transformSegmentData(app_state, auth, compsetData, { segmentedOn: app_state.workspace_controls.segmented_view, tile: tile, segments: segments });
            dataObj[tile] = compsetSegmentData;
        } else if (tile === "market_sell_rates") {
            let marketSellRateData = (dataService.getSeriesById("MarketSellRate").data) ? deepCopy(dataService.getSeriesById("MarketSellRate").data.data) : [];
            let marketSellRateSegmentData = transformSegmentData(app_state, auth, marketSellRateData, { segmentedOn: app_state.workspace_controls.segmented_view, tile: tile, segments: segments });
            dataObj[tile] = marketSellRateSegmentData;
        } else if (tile === "pricing") {
            let pricingDataFormated = getPricingDataFormatted({
                primary_stay_range: app_state.primary_stay_range,
                room_settings: app_state.room_settings
            })
            let pricingDataAdjusted = addPastDates(pricingDataFormated, app_state.primary_stay_range.as_of_date)
            let pricingSegmentedData = transformSegmentData(app_state, auth, pricingDataAdjusted, { segmentedOn: app_state.workspace_controls.segmented_view, tile, segments });
            dataObj[tile] = pricingSegmentedData;
        } else if (tile === "market") {
            let marketOtbData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];
            let marketSegmentData = transformSegmentData(app_state, auth, marketOtbData, { segmentedOn: app_state.workspace_controls.segmented_view, tile: tile, segments: segments, active_tiles: active_tiles });
            dataObj[tile] = marketSegmentData;
        }
    })
    return dataObj;
}

export function processDataIntelimergeSegment(columns, data, state, { auth, app_state, ui_state, segments }) {
    let setup_data = [];
    const { workspace_controls, primary_stay_range } = app_state;
    const { sdlyOn } = workspace_controls;
    const { active_tiles } = ui_state;
    let startDate = primary_stay_range.date_from;
    let endDate = primary_stay_range.date_until;
    let tableLength = moment(endDate).diff(startDate, 'days');

    const { otb, pickup, forecast, pace, pricing } = getActiveTilesData(state, app_state, auth, active_tiles, segments);
    // active_tiles.map((tile, tileIndex) => {
    setup_data = [];
    if (active_tiles[0] === "pickup") {
        data = deepCopy(pickup)
    } else if (active_tiles[0] === "forecast") {
        data = deepCopy(forecast)
    } else if (active_tiles[0] === "pace") {
        data = deepCopy(pace)
    } else if (active_tiles[0] === "pricing") {
        data = deepCopy(pricing);
    } else {
        data = deepCopy(otb)
    }
    // if (tile === "otb") {
    // data = (otb) ? deepCopy(otb) : deepCopy(active_tiles[0].replace('"',''));
    let dataKey = "HotelTotal";
    // debugger;
    let count = 0;
    // debugger;
    let start = moment().format("x");
    let curData = '';
    let dataSet = { otb, pickup, forecast, pace, pricing };
    let otb_ = []; let pickup_ = []; let forecast_ = []; let pace_ = []; let pricing_;
    data.map((item, index) => {
        let start = moment().format('x');

        let itemDate = (item.index) ? item.index.date : item.stay_date;
        let seg;
        seg = (item.index && item.index.segment) ? item.index.segment : item.segment;

        if (itemDate && curData !== itemDate) {
            curData = itemDate;
            otb_ = dataSet.otb ? dataSet.otb.filter((itm) => moment(itm.index.date).isSame(moment(itemDate))) : [];
            pickup_ = dataSet.pickup ? dataSet.pickup.filter((itm) => moment(itm.index.date).isSame(moment(itemDate))) : [];
            forecast_ = dataSet.forecast ? dataSet.forecast.filter((itm) => moment(itm.stay_date).isSame(moment(itemDate))) : [];
            pace_ = dataSet.pace ? dataSet.pace.filter((itm) => moment(itm.index.date).isSame(moment(itemDate))) : [];
            pricing_ = dataSet.pricing ? dataSet.pricing.filter((itm) => moment(itm.index.date).isSame(moment(itemDate))) : [];

        } else if (itemDate === '') {
            otb_ = dataSet.otb ? dataSet.otb.filter((itm) => itm.index.date === '') : [];
            pickup_ = dataSet.pickup ? dataSet.pickup.filter((itm) => itm.index.date === '') : [];
            forecast_ = dataSet.forecast ? dataSet.forecast.filter((itm) => itm.stay_date === '') : [];
            pace_ = dataSet.pace ? dataSet.pace.filter((itm) => itm.index.date === '') : [];
            pricing_ = dataSet.pricing ? dataSet.pricing.filter((itm) => itm.index.date === '') : [];

        }
        let data_columns = [];

        let otbFilterData;
        let pickupFilteredData;
        let forecastFilterData;
        let paceFilteredData;
        let pricingFilteredData;
        if (in_array(active_tiles, ["otb"])) {

            if (itemDate) {
                otbFilterData = otb_.find((itm) => itm.index.segment === seg && moment(itm.index.date).isSame(moment(itemDate)))
            } else {
                otbFilterData = otb_.find((itm) => itm.index.segment === seg && itm.index.date === '')
            }
        }
        if (in_array(active_tiles, ["pickup"])) {

            if (itemDate) {
                pickupFilteredData = pickup_.find((itm) => itm.index.segment === seg && moment(itm.index.date).isSame(moment(itemDate)))
            } else {
                pickupFilteredData = pickup_.find((itm) => itm.index.segment === seg && itm.index.date === '')
            }
        }
        if (in_array(active_tiles, ["pricing"])) {

            if (itemDate) {
                pricingFilteredData = pricing_.find((itm) => itm.index.segment === seg && moment(itm.index.date).isSame(moment(itemDate)))
            } else {
                pricingFilteredData = pricing_.find((itm) => itm.index.segment === seg && itm.index.date === '')
            }
        }
        if (in_array(active_tiles, ["forecast"])) {
            if (itemDate) {
                forecastFilterData = forecast.filter((itm) => itm.segment === seg && moment(itm.stay_date).isSame(moment(itemDate)))
            } else {
                forecastFilterData = forecast.filter((itm) => itm.segment === seg && itm.stay_date === '')
            }
        }
        if (in_array(active_tiles, ["pace"])) {

            if (itemDate) {
                paceFilteredData = pace.filter((itm) => itm.segment === seg && moment(itm.index.date).isSame(moment(itemDate)))
            } else {
                let segNew = (seg) ? seg.replaceAll(' Total', '') : '';
                paceFilteredData = pace.filter((itm) => itm.segment === segNew && itm.index.date === '')
            }
        }

        columns.map(col => {
            let dataObj = {};
            if (col.type === 'dateColumn') {
                dataObj = (item.index) ? setUpDate(dataObj, item.index.date) : setUpDate(dataObj, item.stay_date);
                if (app_state.workspace_controls.segmented_view) {

                    if (active_tiles[0] === "pace") {
                        dataObj["segment"] = (item.index && item.index.segment) ? item.index.segment : item.segment;
                        seg = (item.index && item.index.segment) ? item.index.segment : item.segment;
                    } else {
                        dataObj["segment"] = (item.index) ? item.index.segment : item.segment;
                        seg = (item.index) ? item.index.segment : item.segment;
                    }
                }
            }

            if (col.type === 'dataColumn') {
                let { mainKey, id, tileName } = col;
                let itemDate = (item.index) ? item.index.date : item.stay_date;
                col.subcolumns.map(key => {
                    if (in_array(active_tiles, ["otb"])) {
                        let otbSegData = (active_tiles[0] !== "otb") ? otb[index] : item;
                        let otbDataObj = getOtbDataIntTest(mainKey, id, tileName, key, index, dataKey, auth, app_state, otbFilterData)
                        dataObj = Object.assign(dataObj, otbDataObj);
                    }
                    if (in_array(active_tiles, ["pickup"])) {
                        start = moment().format('x');
                        if (pickupFilteredData) {
                            let pickDataObj = getPickupDataInt_segment(mainKey, id, tileName, key, index, dataKey, auth, app_state, pickupFilteredData)
                            dataObj = Object.assign(dataObj, pickDataObj);
                        } else {
                            let pickDataObj = getPickupDataInt_segment(mainKey, id, tileName, key, index, dataKey, auth, app_state, {})
                            dataObj = Object.assign(dataObj, pickDataObj);
                        }
                    }
                    if (in_array(active_tiles, ["forecast"])) {
                        let forecastDataObj = getForeCastDataInt_segment(mainKey, id, key, index, app_state, forecastFilterData[0])
                        dataObj = Object.assign(dataObj, forecastDataObj);
                    }
                    if (in_array(active_tiles, ["pace"])) {
                        let paceDataObj = getBookingCurveDataInt_segment(mainKey, id, tileName, key, index, dataKey, auth, paceFilteredData[0])
                        dataObj = Object.assign(dataObj, paceDataObj);
                    }

                })
            }
            dataObj.id = col.id;
            data_columns.push(dataObj);
        })

        let st = moment().format('x');
        data_columns.map((itmArr, idx) => {
            if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                data_columns[idx]["ignore_sort"] = true;
            }
        })
        setup_data.push(data_columns);
    })

    // })

    return setup_data;
}



export function processDataIntelimerge(columns, data, { auth, app_state, ui_state, dataSet }) {
    let setup_data = [];
    const { workspace_controls, primary_stay_range } = app_state;
    const { sdlyOn, dayDate } = workspace_controls;
    const { active_tiles } = ui_state;
    let startDate = app_state.primary_stay_range.date_from;
    let endDate = app_state.primary_stay_range.date_until;
    let tableLength = moment(endDate).diff(startDate, 'days');
    const { scopeType } = app_state.workspace_controls;
    if (app_state.workspace_controls.scopeType === 'Dba') {

        let dbaCount = app_state.workspace_controls.dba - 2;
        let dataKey = "HotelTotal";

        for (let i = -1; i <= dbaCount; i++) {
            let dataIndex = i + 1;
            let data_columns = [];
            columns.map(col => {
                let dataObj = {};
                if (col.type === 'dateColumn') {
                    dataObj['dba'] = i;
                }
                if (col.type === 'dataColumn') {
                    let { mainKey, id, tileName, k } = col;
                    col.subcolumns.map(key => {
                        let tile = tileName;
                        // active_tiles.map((tile, tileIndex) => {
                        if (tile === "market") {
                            if (key.value === "Occ" || key.value === "Rooms" || key.value === "ADR" || key.value === "RevPAR" || key.value === "Revenue" || key.value === "SellRate") {
                                let marketDbaDataObj = getMarketDbaDataInt(mainKey, id, tileName, key, dataIndex, "MarketTotal", auth, app_state, ui_state)
                                dataObj = Object.assign(dataObj, marketDbaDataObj);
                            }
                        }
                        if (tile === "compset") {
                            if (key.value !== "Occ" || key.value !== "Rooms" || key.value !== "ADR" || key.value !== "RevPAR" || key.value !== "Revenue" || key.value !== "SellRate") {
                                let compsetDataObj = getCompsetDataDbaInt(mainKey, id, tileName, key, i, dataKey, auth, app_state, ui_state);
                                dataObj = Object.assign(dataObj, compsetDataObj);
                            }
                        }

                        if (tile === "pace") {
                            if (key.value === "Occ" || key.value === "Rooms" || key.value === "ADR" || key.value === "RevPAR" || key.value === "Revenue" || key.value === "SellRate") {
                                let paceDataObj = getBookingCurveDataInt(mainKey, id, tileName, key, dataIndex, dataKey, auth, app_state, ui_state)
                                dataObj = Object.assign(dataObj, paceDataObj);
                            }
                        }

                        // })
                    })

                }
                dataObj.id = col.id;
                data_columns.push(dataObj);
                // data_columns = [...data_columns];
            })
            setup_data.push(data_columns);
            // debugger;
        }



    } else {

        if (active_tiles[0] === "forecast") {
            data = getForecastNonSegmentedData();
        }

        // **    Intelimerge Common functionality starts
        let dataKey = "HotelTotal";
        let forcastDataSet;
        let forecastOtb;
        if (data && data.length > 0) {

            let tileDataSet = {};

            if (active_tiles.includes("otb") || active_tiles.includes("pickup")) {
                tileDataSet['otb'] = dataSet.find((d) => d.data_id === 'OSR');
                tileDataSet['pickup'] = tileDataSet['otb'];
            }
            if (active_tiles.includes("pace")) {
                if (scopeType !== 'Dba') {
                    // tileDataSet['pace'] = dataSet.find((d) => d.data_id === 'OSRBookingPace');
                    tileDataSet['pace'] = dataSet.find((d) => d.data_id === 'OSRBookingPaceSegment');
                } else {
                    // tileDataSet['pace'] = dataSet.find((d) => d.data_id === 'OSRBookingPaceDBA');
                    tileDataSet['pace'] = dataSet.find((d) => d.data_id === 'OSRBookingPaceSegmentDBA');
                }
            }
            if (active_tiles.includes("compset")) {
                if (scopeType !== 'Dba') {
                    tileDataSet['compset'] = dataSet.find((d) => d.data_id === 'Compset');
                } else {
                    tileDataSet['compset'] = dataSet.find((d) => d.data_id === 'CompsetBookingPace');
                }
            }
            if (active_tiles.includes("forecast")) {
                tileDataSet['forecast'] = dataSet.find((d) => d.data_id === 'OSRContrainedForecast');
                forcastDataSet = getForecastNonSegmentedData();
                /// need to add user forecast data 
            }

            if (active_tiles.includes("market_sell_rates")) {
                tileDataSet['market_sell_rates'] = dataSet.find((d) => d.data_id === 'MarketSellRate');
            }

            if (active_tiles.includes("market")) {
                if (scopeType !== 'Dba') {
                    // tileDataSet['market'] = dataSet.find((d) => d.data_id === 'MarketARI');
                    // let MarketForecastData = dataSet.find((d) => d.data_id === 'MarketForecast');
                    tileDataSet['market'] = dataSet.find((d) => d.data_id === 'MarketARISegmented');
                    let MarketForecastData = dataSet.find((d) => d.data_id === 'MarketForecastSegmented');
                    if (tileDataSet['market'] && MarketForecastData) {
                        forecastOtb = concatForcastData(app_state.as_of_date, tileDataSet['market'].data, MarketForecastData.data);
                    }
                } else {
                    tileDataSet['market'] = dataSet.find((d) => d.data_id === 'MarketDba');
                }
            }
            if (active_tiles.includes("pricing")) {
                let pricingDataFormated = getPricingDataFormatted({
                    primary_stay_range: app_state.primary_stay_range,
                    room_settings: app_state.room_settings
                })
                let pricingDataAdjusted = addPastDates(pricingDataFormated, primary_stay_range.as_of_date)
                tileDataSet['pricing'] = pricingDataAdjusted;
            }
            data.map((item, index) => {
                let dbaNumber = index - 1;
                let data_columns = [];
                let date = (item.index && item.index.date) ? item.index.date : item.stay_date;
                let tileData = {};
                if (active_tiles.includes("otb") || active_tiles.includes("pickup")) {
                    tileData['otb'] = tileDataSet['otb'].data.find((d) => moment(d.index.date).isSame(date));
                }
                if (active_tiles.includes("pace") && tileDataSet['pace']) {
                    tileData['pace'] = tileDataSet['pace'].data.find((d) => moment(d.index.date).isSame(date));
                }
                if (active_tiles.includes("compset") && tileDataSet['compset'] && tileDataSet['compset'].data) {
                    tileData['compset'] = tileDataSet['compset'].data.find((d) => moment(d.index.date).isSame(date));
                }
                if (active_tiles.includes("forecast") && forcastDataSet) {
                    tileData['forecast'] = forcastDataSet.find((d) => moment(d.stay_date).isSame(date));
                }
                if (active_tiles.includes("market_sell_rates") && tileDataSet['market_sell_rates']) {
                    tileData['market_sell_rates'] = tileDataSet['market_sell_rates'].data.find((d) => moment(d.index.date).isSame(date));
                }
                if (active_tiles.includes("market") && tileDataSet['market']) {
                    tileData['market'] = tileDataSet['market'].data.find((d) => moment(d.index.date).isSame(date));
                    tileData['marketForecast'] = (forecastOtb) ? forecastOtb.find((d) => moment(d.stay_date).isSame(date)) : [];
                }
                if (active_tiles.includes("pricing") && tileDataSet['pricing']) {
                    tileData['pricing'] = tileDataSet['pricing'].find((d) => moment(d.stay_date).isSame(date));
                }

                columns.map(col => {
                    let dataObj = {};
                    if (col.type === 'dateColumn') {
                        // need to add a condition for dba if needed. stay_date
                        // debugger;
                        if (scopeType === "Date") {
                            dataObj = setUpDate(dataObj, date);
                        } else {
                            dataObj['dba'] = dbaNumber; //item.index.dba;
                        }
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey, id, tileName, k } = col;
                        col.subcolumns.map(key => {
                            // active_tiles.map((tile, tileIndex) => {
                            // debugger;
                            if (active_tiles.includes("otb") && tileName === 'otb') {

                                let otbDataObj = getOtbDataInt(mainKey, id, tileName, key, index, dataKey, auth, app_state, tileData['otb'])
                                dataObj = Object.assign(dataObj, otbDataObj);
                            }
                            if (active_tiles.includes("pickup") && tileName === 'pickup') {
                                let pickDataObj = getPickupDataInt(mainKey, id, tileName, key, index, dataKey, auth, app_state, tileData['otb'])
                                dataObj = Object.assign(dataObj, pickDataObj);
                            }
                            if (active_tiles.includes("pace") && tileName === 'pace' && tileData['pace']) {
                                let paceDataObj = getBookingCurveDataInt(mainKey, id, tileName, key, index, dataKey, auth, app_state, ui_state, tileData['pace'])
                                dataObj = Object.assign(dataObj, paceDataObj);
                            }
                            if (active_tiles.includes("pricing") && tileName === 'pricing' && tileData['pricing']) {
                                let pricingDataObj = getPricingDataInt(tileData['pricing'], key);
                                dataObj = Object.assign(dataObj, pricingDataObj);
                            }
                            if (active_tiles.includes("market") && tileName === 'market' && tileData['marketForecast']) {
                                if (scopeType !== 'Dba') {
                                    let marketDataObj = getMarketDataInt(mainKey, id, tileName, key, index, "MarketTotal", auth, app_state, tileData['market'], tileData['marketForecast'])
                                    dataObj = Object.assign(dataObj, marketDataObj);
                                } else {
                                    let marketDbaDataObj = getMarketDbaDataInt(mainKey, id, tileName, key, index, "MarketTotal", auth, app_state, ui_state)
                                    // dataObj = Object.assign(dataObj, marketDbaDataObj);
                                }
                            }
                            if (active_tiles.includes("compset") && tileName === 'compset') {
                                let compsetDataObj = getCompsetDataInt(mainKey, id, tileName, key, index, dataKey, auth, app_state, ui_state, tileData['compset']);
                                dataObj = Object.assign(dataObj, compsetDataObj);
                            }

                            if (active_tiles.includes("forecast") && tileName === 'forecast') {
                                let forecastDataObj = getForeCastDataInt(mainKey, id, key, index, app_state, tileData['forecast']);
                                dataObj = Object.assign(dataObj, forecastDataObj);
                            }
                            // debugger;

                            if (active_tiles.includes("market_sell_rates") && tileName === 'market_sell_rates') {

                                let marketSellrateDataObj = getMarketSellRateInt(date, mainKey, id, tileName, k, key, index, dataKey, auth, app_state, tileData['market_sell_rates'])
                                // debugger;
                                dataObj = Object.assign(dataObj, marketSellrateDataObj);
                            }
                            if (active_tiles.includes("budget") && tileName === 'budget') {
                                let marketSellrateDataObj = getBudgetInt(date, mainKey, id, tileName, k, key, index, dataKey, auth, app_state)
                                dataObj = Object.assign(dataObj, marketSellrateDataObj);
                            }

                            // })
                        })
                    }
                    dataObj.id = col.id;
                    data_columns.push(dataObj);
                })
                data_columns.map((itmArr, idx) => {
                    if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                        data_columns[idx]["ignore_sort"] = true;
                    }
                })
                setup_data.push(data_columns);
            })
        }
        // ** End 
    }
    return setup_data;

}


///

export const getCompsetDataDbaInt = (mainKey, id, tileName, key, index, dataKey, auth, app_state, ui_state) => {

    let compsetDataFull = deepCopy(dataService.getSeriesById("CompsetBookingPace").data.data);
    let compsetData = compsetDataFull.filter((item) => item.index.dba === index);
    let { active_tiles } = ui_state;
    if (in_array(ui_state.active_tiles, ["pace", "market"]) && app_state.workspace_controls.scopeType === "Dba" && compsetData.length <= 0) {
        let blankObj = { "index": { dba: index } };
        for (const key in compsetData[0]) {
            blankObj[key] = { sell_rate: 0 };
        }
        compsetData.splice(0, 0, blankObj);
    }

    compsetData = compsetData[0];
    let dbaCount = app_state.workspace_controls.dba;
    let cmValues = [];
    for (const key in compsetData) {
        if (key !== "Avg" && key !== "Total" && key !== "index" && compsetData[key]["sell_rate"] > 0) {
            cmValues.push(compsetData[key]["sell_rate"]);
        }
    }
    let dataObj = {};
    if (key.display.toLowerCase() === "compset median") {
        dataObj[key.value] = getCompsetMedian(cmValues);
    } else {
        if (tileName === "compset") {
            for (const key in compsetData) {
                if (compsetData[key]) {
                    dataObj[key] = round(compsetData[key]["sell_rate"]);
                } else {
                    dataObj[key] = 0;
                }
            }
        }
    }
    return dataObj;
}



// Function to create data for compset for Intellimerge
export const getCompsetDataInt = (mainKey, id, tileName, key, index, dataKey, auth, { scopeType }, ui_state, tileData) => {
    let compsetData = tileData; //(app_state.workspace_controls.scopeType !== 'Dba') ? deepCopy(dataService.getSeriesById("Compset").data.data) : deepCopy(dataService.getSeriesById("CompsetBookingPace").data.data);
    let { active_tiles } = ui_state;
    //    debugger;
    if (in_array(ui_state.active_tiles, ["pace", "market"]) && scopeType === "Dba") {
        let blankObj = { "index": { dba: -1 } };
        for (const key in compsetData[0]) {
            blankObj[key] = { sell_rate: 0 };
        }
        compsetData.splice(0, 0, blankObj);
    }

    // let dbaCount = app_state.workspace_controls.dba;
    // compsetData = (app_state.workspace_controls.scopeType === 'Dba') ? compsetData.slice(0, dbaCount) : compsetData;
    let value = key.value;
    let cmValues = [];
    for (const key in compsetData) {
        if (key !== "Max" && key !== "Median" && key !== "Min" && key !== "Avg" && key !== "Total" && key !== "index" && compsetData[key]["sell_rate"] > 0) {
            cmValues.push(compsetData[key]["sell_rate"]);
        }
    }
    let dataObj = {};
    if (key.display.toLowerCase() === "compset median") {
        dataObj[value] = getCompsetMedian(cmValues);
    } else {
        if (tileName === "compset") {
            if (!compsetData) {
                dataObj[value] = 0.00;
            } else {
                if (compsetData[value]) {
                    dataObj[value] = round(compsetData[value]["sell_rate"]);
                } else {
                    dataObj[value] = 0;
                }

            }
        }
    }
    return dataObj;
}

///
