import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect'
import EditIcon from '@mui/icons-material/Edit';
import {
  ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
  MoreVertOutlined as MoreVertOutlinedIcon,
  Opacity,
}
  from '@mui/icons-material/';
import { UPDATE_STATE } from '../../actions/types'
import { updateAppState } from "../../actions/appstate";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import {
  Box
  , Container
  , Grid
  , Paper
  , Chip
  , Avatar
  , Button
  , TextField
  , CircularProgress
  , Alert
  , FormControl
  , Radio
  , RadioGroup
  , FormControlLabel
  , Checkbox
  , label
  , Accordion
  , AccordionDetails
  , AccordionSummary
  , Typography
  , List
  , ListSubheader
  , ListItem
  , ListItemButton
  , Divider
  , ListItemIcon
  , ListItemText
  , styles as styled,
  Badge,
  Select,
  MenuItem,
  Tooltip,
  Input,
  InputAdornment,
  IconButton,
  Autocomplete
}
  from '@mui/material/';
import './style.css';
import dataService from '../../services/data.service';
import { deepCopy } from '@firebase/util';

import Settings from "./index";
import { useLocation, useNavigate } from "react-router-dom";
import { clearAPIRegistryData, cleanIDB } from "../../app/util";
import DataService from "../../services/data.service";
import IndexedDBManager from "../../indexed-db/indexed-db";
// import Select, { components } from 'react-select';
// const { Option } = components;




function ProfileSettings(props) {
  const dispatch = useDispatch();
  const { app, auth } = useSelector(state => state);
  const { app_state, ui_state } = app.current_state;
  const { user, profile } = auth;
  const properties = profile.property_details;
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [compState, setCompState] = useState({
    user_info: {},
    user_info_: {},
    time_zones: [],
    skip_keys: [],
    editing: '',
    delta: {},
    isProfileChanged: false,
    msgTimeout: 4000,
  });
  // debugger;
  const updateCompState = (key, value) => {
    let keys = key ? key.split('>') : [];
    //Handling single deptch key/value pairs
    if (keys[0] && !keys[1]) {
      compState[keys[0]] = value;
    }
    //Handling double deptch key/value pairs
    if (keys[0] && keys[1]) {
      compState[keys[0]] = compState[keys[0]] ? compState[keys[0]] : {};
      compState[keys[0]][keys[1]] = value;
    }
    // compState.isProfileChanged = true;
    setCompState((state) => {
      return { ...state, ...compState };
    });
  }

  useEffect(() => {
    let isSubscribed = true;

    //Getting user profile information
    dataService
      .getData({
        api: "users/info/",
        request: { method: 'GET' },
      })
      .then((rs) => {
        console.log("users/info", rs);
        if (rs.status === "success") {
          //Changing default property name into id
          let res = deepCopy(rs);
          res.default_property = profile.default_property_id;
          let selected_property = properties.filter(p => p.id === res.default_property);
          if (selected_property && selected_property.length > 0) {
            res.selected_property = selected_property[0];

          }
          updateCompState('user_info_', rs);
          updateCompState('user_info', res);
          // debugger;
        }
      }, (err) => {
        console.log("ratePushSchedules Error!");
      });

    //Getting timezone list
    dataService
      .getData({
        api: "users/common-timezones/",
        request: { method: 'GET' },
      })
      .then((rs) => {
        console.log("timezones", rs);
        if (rs.status === "success") {
          // debugger
          updateCompState('time_zones', rs.data);
        }
      }, (err) => {
        console.log("users/common-timezones failed!");
      });
    notificationOnload();
    return () => (isSubscribed = false);
  }, []);


  const { user_info } = compState;

  const handleSave = (params) => {
    // debugger;
    let { delta } = compState;
    let formData = new FormData();
    let deltaArray = Object.keys(delta);
    deltaArray.map((key) => {
      formData.append(key, delta[key]);
    });
    // formData.append('method', 'PUT');
    if (deltaArray.length) {
      updateCompState('request', 'loading');
      dataService
        .updateData({
          api: "users/edit-user/",
          request: { formData, method: 'PUT' }
        })
        .then((rs) => {
          updateCompState('request', '');

          if (rs.status === "success") {
            updateCompState('isProfileChanged', false);
            updateCompState('requestSuccess', true);
            updateCompState('requestMsg', "Data saved successfully!");

            //Updating app_state for default property id
            // debugger;
            if (delta.defaultProperty) {
              profile.default_property_id = delta.defaultProperty;
              dispatch({ type: "SYSTEM_SETTINGS_UPDATED", payload: profile });
            }

            setTimeout(() => {
              updateCompState('requestSuccess', false);
            }, compState.msgTimeout);

            // debugger;
          } else {
            updateCompState('request', '');
            updateCompState('requestError', true);
            updateCompState('requestMsg', "Data saving failed: " + rs.message);

            setTimeout(() => {
              updateCompState('requestError', false);
            }, compState.msgTimeout);
          }
        }, (err) => {
          // debugger
          updateCompState('request', '');
          updateCompState('requestError', true);
          updateCompState('requestMsg', "Data saving failed!");

          setTimeout(() => {
            updateCompState('requestError', false);
          }, compState.msgTimeout);

          console.log("ratePushSchedules Error!");
        });
    }

    // debugger;
  };

  const handleCancel = (params) => {
    // debugger
    let original = deepCopy(compState.user_info_);
    let default_property_id = profile.default_property_id;
    let selected_property = properties.filter(p => p.id === default_property_id);
    if (selected_property && selected_property.length > 0) {
      selected_property = selected_property[0];
      original.selected_property = selected_property;
    }
    updateCompState('user_info', original);
    updateCompState('isProfileChanged', false);
    // debugger;
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  const navigate = useNavigate();
  const [refreshingData, setRefreshingData] = useState(false);
  const [refreshedData, setRefreshedData] = useState(false);
  const [pushNotificationState, setPushNotificationState] = useState(null);
  function goBackToDashboard() {
    dispatch(
      updateAppState("navigate_to_dashboard", {
        app_state: {
          data_load: true,
          kpi_load: true,
        },
        ui_state: {
          active_settings_menu: "",
          showspinner: true,
        },
      }, "settings_click")
    );
    navigate("/");
  }
  function refreshData() {
    cleanIDB();
    //reverting changes back
    let original = deepCopy(compState.user_info_);
    let default_property_id = profile.default_property_id;
    let selected_property = properties.filter(p => p.id === default_property_id);
    if (selected_property && selected_property.length > 0) {
      selected_property = selected_property[0];
      original.selected_property = selected_property;
    }
    updateCompState('user_info', original);
    updateCompState('isProfileChanged', false);

    const payload = {
      token_string: user.token_string,
      resourceId: app_state.property_id,
      resourceType: "property",
      targetCache: ["srr", "forecast", "otb"],
      operation: "flush",
    };
    setRefreshingData(true);
    notificationOnload();
    // debugger;
    DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
      .then((data) => {
        //Do some task here just after respons
        if (data.status === "success") {
          setRefreshedData(true);
          setRefreshingData(false);
          console.log("refreshData Success");
          setTimeout(() => {
            setRefreshedData(false);
            // window.location.reload();
          }, 2000);
          clearAPIRegistryData();
        } else {
          setRefreshingData(false);
          console.warn("refreshData Error, data could not refreshed");
          setTimeout(() => {
            setRefreshedData(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setRefreshingData(false);
        setTimeout(() => {
          setRefreshedData(false);
        }, 3000);
      });
  }
  function notificationOnload() {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        setPushNotificationState(true);
      }
      else {
        setPushNotificationState(false);
      }
    }
    //setPushNotificationState('Notification' in window && Notification.permission === 'granted')
  }
  // function pushNotification() {
  //   if ('Notification' in window) {
  //     if (Notification.permission === 'granted') {
  //       setPushNotificationState(false);
  //       alert("NOOTIFICATION DISABLED!\nYou won't be able to receive push notifications fron LodgIQ.");
  //       // new Notification('Want to turn off notification?', {
  //       // body: 'Please change your browser notification permission to disable it.',
  //       // });
  //     }
  //     else if (Notification.permission === 'denied') {
  //       setPushNotificationState(false);
  //       alert("NOTIFICATIONS ARE BLOCKED!\nTo enable them, please update your browser's notification settings.");
  //     }
  //     else if (Notification.permission === 'default') {
  //       Notification.requestPermission()
  //         .then(permission => {
  //           if (permission === 'granted') {
  //             setPushNotificationState(true);
  //             alert("NOTIFICATION ENABLED!\nNow you are able to receive push notifications from LodgIQ.");
  //             // new Notification('Notification is Enabled', {
  //             // body: 'Now you are able to receive push notifications from LodgIQ.',
  //             // });
  //           }
  //           else if (permission === 'denied') {
  //             setPushNotificationState(false);
  //             alert("NOTIFICATION BLOCKED!\nYou won't be able to receive push notifications fron LodgIQ.");
  //           }
  //         });
  //     }
  //   }
  // }
  function pushNotification() {
    if ('Notification' in window) {
      if (Notification.permission === 'default') {
        Notification.requestPermission()
          .then(permission => {
            if (permission === 'granted') {
              setPushNotificationState(true);
              alert("NOTIFICATION ENABLED!\nNow you are able to receive push notifications from LodgIQ.");
              // new Notification('Notification is Enabled', {
              // body: 'Now you are able to receive push notifications from LodgIQ.',
              // });
            }
            else if (permission === 'denied') {
              setPushNotificationState(false);
              alert("NOTIFICATION BLOCKED!\nYou won't be able to receive push notifications fron LodgIQ.");
            }
          });
      }
    }
  }


  return (
    <div>

      <Box className='top-axn-bar-1'
      >
        <Tooltip title="Back to Dashboard" arrow followCursor >
          <Button
            size="medium"
            variant="contained"
            className='back-to-dash-btn'
            onClick={goBackToDashboard}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
              <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                  <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                  <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                </g>
                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                  <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                  <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                </g>
                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                  <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                  <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                </g>
                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                  <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                  <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                </g>
                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                  <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                  <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                </g>
              </g>
            </svg>
            <span className='back-to-dash-txt'>Back to Dashboard</span>
          </Button>
        </Tooltip>

        <Tooltip title="Refresh Data" >
          <box
            onClick={refreshData}
            size="medium"
            variant="contained"
            className='refresh-btn'
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
          </box>
        </Tooltip>

        <Alert
          icon={false}
          sx={{
            display: refreshedData ? "block" : "none",
            position: "fixed",
            left: 0,
            fontSize: 17,
            textAlign: "center",
            borderRadius: 0,
            lineHeight: 1,
            bottom: 40,
            width: "100%",
            zIndex: 100,
            backgroundColor: "green.main",
            color: "white.main",
          }}
          severity="success"
        >
          Data refreshed successfully!
        </Alert>

      </Box>

      <Box className='top-axn-bar'>
        <Box className='breadcrumb' sx={{ color: 'white.main' }}>
          <span className="breadcrum-inactive">Settings</span>
          <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
            sx={{ top: 3, position: "relative", height: 18 }}
          ></ArrowForwardIosOutlinedIcon>
          <span className="breadcrum-inactive">Profile Settings</span>
          <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
            sx={{ top: 3, position: "relative", height: 18 }}
          ></ArrowForwardIosOutlinedIcon>
          <span className="breadcrum-active">Profile</span>
        </Box>
      </Box>

      <Box className="bgwhite main-scroll-wrap" sx={{ pl: 2, pr: 0, pb: 2 }}>

        <div className='profile-heading'>PROFILE</div>

        <Alert
          severity="success"
          sx={{
            mt: 1,
            mr: 2,
            display: compState.requestSuccess ? "flex" : "none",
          }}
        >
          {compState.requestMsg}
        </Alert>
        <Alert
          severity="error"
          sx={{
            mt: 1,
            mr: 2,
            display: compState.requestError ? "flex" : "none",
          }}
        >
          {compState.requestMsg}
        </Alert>

        <Grid container sx={{ pt: 0 }}>
          <Grid item md={9} sx={{ mr: 0, pr: 0, position: 'relative' }}>

            <Box className='profile-name'>

              <Grid container className='profile' >
                <Grid>
                  <item>
                    <Box className='profile-pic' style={{ background: compState.localProfilePic }}>
                      <div className='profile-bio'>
                        {!compState.localProfilePic && !user_info.profile_image_url && user.fullname && user.fullname[0]}
                        {compState.localProfilePath && user_info.profile_image_url && <Tooltip title={user.fullname} followCursor>
                          {<Avatar sx={{ width: 90, height: 90 }} title={user.fullname} src={compState.localProfilePath} />}
                        </Tooltip>}
                        {!compState.localProfilePath && user_info.profile_image_url && <Tooltip title={user.fullname} followCursor>
                          {<Avatar sx={{ width: 90, height: 90 }} title={user.fullname} src={user_info.profile_image_url} />}
                        </Tooltip>}
                      </div>
                      <div className="half-circle">
                        <Box sx={{ position: 'relative', height: 30, mt: -1 }}>
                          <CameraAltOutlinedIcon color='white' fontSize='large' sx={{ position: 'absolute', left: 25, top: 2, zIndex: 99 }}></CameraAltOutlinedIcon>
                          <Tooltip sx={{ cursor: 'pointer' }} title="Click to choose file" arrow followCursor>
                            <Input onChange={(e) => {
                              // debugger;
                              let files = e.nativeEvent.srcElement.files;
                              // var img = document.createElement("img");
                              var reader = new FileReader();
                              reader.onloadend = function () {
                                // img.src = reader.result;
                                // debugger;
                                let localFile = reader.result;
                                updateCompState('localProfilePath', localFile);
                                updateCompState('localProfilePic', files[0]);
                                updateCompState('isProfileChanged', true);
                                updateCompState('delta>profileImage', files[0]);
                              }
                              reader.readAsDataURL(files[0]);

                              // let fileName = files.name;
                              // updateCompState('localProfilePic', img);
                              // debugger;
                            }} type="file" className='select-profile-pic' name="profileImage" />
                          </Tooltip>
                        </Box>

                      </div>

                    </Box>
                    <Box sx={{ position: 'relative' }}>
                      {compState.localProfilePic && <Box sx={{ whiteSpace: 'nowrap', position: 'absolute', top: -3, color: "#ccc" }}>{compState.localProfilePic.name}</Box>}
                    </Box>
                  </item>
                </Grid>
                <Grid item className='username'>


                  {compState.editing === 'fullname' && <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item>
                      <div className='fullname editable'>
                        <FormControl sx={{ mr: 1 }}>
                          <TextField className='first-name' type="text" size='small' variant='standard'
                            value={user_info.first_name}
                            // label="First Name"
                            onBlur={(e) => {
                              // debugger;
                              let value = e.target.value;
                              updateCompState('user_info>first_name', value);
                              updateCompState('editing', '');
                              updateCompState('delta>firstName', value);

                            }}
                            onChange={(e) => {
                              let value = e.target.value;
                              updateCompState('user_info>first_name', value);
                              updateCompState('isProfileChanged', true);
                            }} />
                        </FormControl>

                      </div>
                    </Grid>
                    <Grid item>
                      <div className='fullname editable'>
                        <FormControl sx={{}} label="Last Name">
                          <TextField className='last-name' size='small' variant='standard'
                            value={user_info.last_name}
                            // label="Last Name"
                            onBlur={(e) => {
                              updateCompState('editing', '');

                            }}
                            onChange={(e) => {
                              let value = e.target.value;
                              updateCompState('user_info>last_name', value);
                              updateCompState('isProfileChanged', true);
                              updateCompState('delta>lastName', value);
                            }}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                  }
                  {compState.editing !== 'fullname' &&
                    <>
                      {/* {user_info.fullname} */}
                      {user_info.first_name}  {user_info.last_name}
                      <Box className='pencil-edit' sx={{ cursor: 'pointer', display: 'inline', ml: 1 }}>
                        <Tooltip title="Click to edit" sx={{ display: 'inline-block' }}>
                          <svg className='profile-edit-pencil-icon' onClick={() => updateCompState('editing', 'fullname')} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path></svg>
                          {/* <EditIcon fontSize='18px' onClick={() => updateCompState('editing', 'fullname')} /> */}
                        </Tooltip>
                      </Box>
                    </>}

                </Grid>

              </Grid>

              <Grid container className='profile-info'>
                <Grid item sx={{}}>
                  <Box><span className='main-label'>Title:</span></Box>
                </Grid>
                <Grid item>
                  <Box elevation={2}>
                    {compState.editing === 'designation' && <FormControl fullWidth sx={{ mr: 1, fontSize: 14 }}>
                      <TextField sx={{ 'input': { fontSize: 14 } }} className='view designation' type="text" size='small' variant='standard'
                        value={user_info.designation || 'Regional Director of Revenue'}
                        // label="First Name"
                        onBlur={(e) => {
                          let value = e.target.value;
                          updateCompState('user_info>designation', value);
                          updateCompState('editing', '');
                          updateCompState('delta>designation', value);
                        }}
                        onChange={(e) => {
                          let value = e.target.value;
                          user_info.designation = value;
                          updateCompState('user_info>designation', value);
                          updateCompState('isProfileChanged', true);
                        }} />
                    </FormControl>
                    }

                    {/* {compState.editing !== 'designation' && <div onClick={() => { updateCompState('editing', 'designation') }}>{user_info.designation || 'Regional Director of Revenue'}</div>} */}
                    {compState.editing !== 'designation' &&
                      <>{user_info.designation || 'Regional Director of Revenue'}
                        <Box className='pencil-edit' sx={{ cursor: 'pointer', display: 'inline', ml: 1 }}>
                          <Tooltip title="Click to edit" sx={{ display: 'inline-block' }}>
                            <svg className='profile-edit-pencil-icon' onClick={() => updateCompState('editing', 'designation')} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path></svg>
                            {/* <EditIcon fontSize='18px' onClick={() => updateCompState('editing', 'designation')} /> */}
                          </Tooltip>
                        </Box>
                      </>}
                    {/* Regional Director of Revenue */}
                  </Box>
                </Grid>
              </Grid>

              <Grid container className='profile-info'>
                <Grid item sx={{}}>
                  <Box><span className='main-label'>EMAIL:</span></Box>
                </Grid>
                <Grid item>
                  <Box>{user_info.email}</Box>
                </Grid>
              </Grid>

              <Grid container className='profile-info'>
                <Grid item sx={{}}>
                  <Box><span className='main-label'>MOBILE:</span></Box>
                </Grid>
                <Grid item>
                  <Box>
                    {compState.editing === 'mobileNo' && <FormControl sx={{ mr: 1 }}>
                      <TextField className='view mobileNo' type="text" size='small' variant='standard'
                        value={user_info.mobile_no}
                        // label="First Name"
                        onBlur={(e) => {
                          let value = e.target.value;
                          updateCompState('user_info>mobile_no', value);
                          updateCompState('editing', '');
                          updateCompState('delta>mobileNo', value);
                        }}
                        onChange={(e) => {
                          let value = e.target.value;
                          user_info.mobile_no = value;
                          updateCompState('user_info>mobile_no', value);
                          updateCompState('isProfileChanged', true);
                        }} />
                    </FormControl>
                    }
                    {compState.editing !== 'mobileNo' &&
                      <>{user_info.mobile_no || 'Mobile No'}
                        <Box className='pencil-edit' sx={{ cursor: 'pointer', display: 'inline', ml: 1 }}>
                          <Tooltip title="Click to edit" sx={{ display: 'inline-block' }}>
                            <svg className='profile-edit-pencil-icon' onClick={() => updateCompState('editing', 'mobileNo')} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path></svg>
                            {/* <EditIcon fontSize='18px' onClick={() => updateCompState('editing', 'mobileNo')} /> */}
                          </Tooltip>
                        </Box>
                      </>
                    }
                  </Box>
                </Grid>
              </Grid>

              <Grid container className='profile-info'>
                <Grid item sx={{}}>
                  <Box><span className='main-label'>User Time Zone:</span></Box>
                </Grid>
                <Grid item>
                  <Box>
                    {compState.editing === 'timezone' && <FormControl className="rate-textfield" size="small">

                      <Select
                        sx={{ height: 30 }}
                        value={compState.user_info.timezone}
                        className="timezone-select"
                        onBlur={(e) => {
                          let value = e.target.value;
                          updateCompState('user_info>timezone', value);
                          updateCompState('editing', '');

                        }}
                        onClose={
                          () => {
                            updateCompState('editing', '');
                            updateCompState('delta>userTimezone', user_info.timezone);
                            updateCompState('isProfileChanged', true);

                          }
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          compState.userTimezone = value;
                          updateCompState("user_info>timezone", value);
                        }}
                      >
                        {compState.time_zones.map((timeZone) => {
                          return <MenuItem value={timeZone}>{timeZone}</MenuItem>
                        })}

                      </Select>
                    </FormControl>
                    }

                    {compState.editing !== 'timezone' &&
                      <>{user_info.timezone}
                        <Box className='pencil-edit' sx={{ cursor: 'pointer', display: 'inline', ml: 1 }}>
                          <Tooltip title="Click to edit" sx={{ display: 'inline-block' }}>
                            <svg className='profile-edit-pencil-icon' onClick={() => updateCompState('editing', 'timezone')} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path></svg>
                            {/* <EditIcon fontSize='18px' onClick={() => updateCompState('editing', 'timezone')} /> */}
                          </Tooltip>
                        </Box>
                      </>}
                  </Box>

                </Grid>
              </Grid>

              <Grid container className='profile-info'>
                <Grid item sx={{}}>
                  <Box><span className='main-label'>Default Property:</span></Box>
                </Grid>
                <Grid item>
                  <Box>
                    {compState.editing === 'defaultProperty' && <FormControl className="rate-textfield-2" size="small" sx={{ width: "50%", color: "#606060" }}>

                      {/* <Select
                        sx={{ height: 30 }}
                        value={user_info.selected_property}
                        className="timezone-select"
                        onBlur={(e) => {
                          let value = e.target.value;
                          updateCompState('user_info>defaultProperty', value);
                          updateCompState('editing', '');

                        }}
                        onClose={
                          () => {
                            updateCompState('editing', '');
                            updateCompState('isProfileChanged', true);
                          }
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          // debugger;
                          // compState.defaultProperty = value;
                          updateCompState("user_info>defaultProperty", value);
                          updateCompState("user_info>selected_property", value);
                          updateCompState('delta>defaultProperty', value.id);
                        }}
                      >
                        <div className='profile-property-search'>
                          <TextField
                            className='textfield'
                            fullWidth
                            placeholder="Search"
                            onChange={handleSearch}
                            value={searchQuery}
                            InputProps={{
                              startAdornment: (
                                <div style={{ marginRight: '8px', marginTop: '5px' }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    fill="#606060"
                                    viewBox="0 0 256 256"
                                  >
                                    <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                                  </svg>
                                </div>
                              ),
                            }}
                          />
                        </div>
                        {properti.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item.name}
                          </MenuItem>
                        ))}

                      </Select> */}

                      <Autocomplete
                        value={user_info.selected_property}
                        options={properties}
                        getOptionLabel={(option) => option.name}
                        sx={{ height: 30 }}
                        className="default-property-search"
                        onBlur={(e) => {
                          let value = e.target.value;
                          updateCompState('user_info>defaultProperty', value);
                          updateCompState('editing', '');
                        }}
                        onClose={() => {
                          updateCompState('editing', '');
                          updateCompState('isProfileChanged', true);
                        }}
                        onChange={(event, value) => {
                          if (value) {
                            updateCompState('user_info>defaultProperty', value.name);
                            updateCompState('user_info>selected_property', value);
                            updateCompState('delta>defaultProperty', value.id);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />


                    </FormControl>
                    }

                    {compState.editing !== 'defaultProperty' &&
                      <>{user_info.selected_property && user_info.selected_property.name}
                        <Box className='pencil-edit' sx={{ cursor: 'pointer', display: 'inline', ml: 1 }}>
                          <Tooltip title="Click to edit" sx={{ display: 'inline-block' }}>
                            <svg className='profile-edit-pencil-icon' onClick={() => updateCompState('editing', 'defaultProperty')} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#000000" viewBox="0 0 256 256"><path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path></svg>
                            {/* <EditIcon fontSize='18px' onClick={() => updateCompState('editing', 'defaultProperty')} /> */}
                          </Tooltip>
                        </Box>
                      </>}
                  </Box>

                </Grid>
              </Grid>

              <Grid container className='profile-info'>
                <Grid item sx={{}}>
                  <Box>
                    <span className='main-label'>Default Mode:</span>
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{ ml: 0 }}>
                    <Grid container>
                      <Grid item>
                        <FormControl sx={{ mt: 0.8 }}>
                          <RadioGroup className='radio-group'
                            aria-labelledby="demo-radio-buttons-group-label"
                            // defaultValue="Graph"
                            value={user_info.default_view}
                            name="radio-buttons-group"
                            row
                            onChange={(e) => {
                              // debugger;
                              const value = e.target.value.toLowerCase();
                              user_info.default_view = value;
                              updateCompState("user_info>default_view", value);
                              updateCompState('isProfileChanged', true);
                              updateCompState('delta>defaultView', value);
                            }}
                          >
                            <FormControlLabel value="chart" checked={user_info.default_view && user_info.default_view.toLowerCase() === 'chart'} className="graph" control={<Radio />} label="Graph View" />
                            <FormControlLabel value="table" checked={user_info.default_view && user_info.default_view.toLowerCase() === 'table'} className='table' sx={{}} control={<Radio />} label="Table View" />

                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              {/* LRV-880 Remove the class dn */}
              <Grid container className='profile-info dn'>
                <Grid item sx={{}}>
                  <Box>
                    <span className='main-label'>Default Comparison</span>
                  </Box>
                </Grid>
                <Grid item>
                  <Box sx={{ ml: 0 }}>
                    <Grid container>
                      <Grid item>
                        <FormControl sx={{ mt: 0.8 }}>
                          <RadioGroup className='radio-group'
                            aria-labelledby="comparison-buttons-group-label"
                            // defaultValue="Graph"
                            value={user_info.default_ly}
                            name="comparison-buttons-group"
                            row
                            onChange={(e) => {
                              debugger;
                              const value = e.target.value.toLowerCase();
                              user_info.default_ly = value;
                              updateCompState("user_info>default_ly", value);
                              updateCompState('isProfileChanged', true);
                              updateCompState('delta>defaultLy', value);
                            }}
                          >
                            <FormControlLabel value="sdly" checked={user_info.default_ly && user_info.default_ly.toLowerCase() === 'sdly'} className="sdly" control={<Radio />} label="Day" />
                            <FormControlLabel value="sdtly" checked={user_info.default_ly && user_info.default_ly.toLowerCase() === 'sdtly'} className='sdtly' sx={{}} control={<Radio />} label="Date" />

                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Grid container className='profile-info'>
                <Grid item sx={{}}>
                  <Box><span className='main-label'>Notification Preference:</span></Box>
                </Grid>
                <Grid item>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item sx={{ fontFamily: "SFUI Medium" }}>Notify me via..</Grid>

                      <Tooltip title={!(user_info && user_info.mobile_no && user_info.mobile_no.length >= 10) ? "Please enter valid mobile number" : ""} arrow followCursor >
                        <Grid item >
                          {compState.user_info.text_noti_subscribed &&
                            <Checkbox
                              checked={(user_info && user_info.mobile_no && user_info.mobile_no.length >= 10)}
                              disabled={!(user_info && user_info.mobile_no && user_info.mobile_no.length >= 10)}
                              onChange={(e) => {
                                const value = e.target.checked;
                                compState.user_info.text_noti_subscribed = value;
                                updateCompState("user_info>text_noti_subscribed", value);
                                updateCompState('delta>notificationPrefText', value);
                                updateCompState('isProfileChanged', true);
                              }} value={user_info.text_noti_subscribed} className='settting-profile-checkbox'
                            />
                          }
                          {!compState.user_info.text_noti_subscribed &&
                            <Checkbox
                              disabled={!(user_info && user_info.mobile_no && user_info.mobile_no.length >= 10)}
                              onChange={(e) => {
                                const value = e.target.checked;
                                compState.user_info.text_noti_subscribed = value;
                                updateCompState("user_info>text_noti_subscribed", value);
                                updateCompState('delta>notificationPrefText', value);
                                updateCompState('isProfileChanged', true);
                              }} value={user_info.text_noti_subscribed} className='settting-profile-checkbox'
                            />
                          }
                          <span style={!(user_info && user_info.mobile_no && user_info.mobile_no.length >= 10) ? { opacity: 0.4 } : {}}> Mobile</span>
                        </Grid>
                      </Tooltip>

                      <Grid item >
                        {compState.user_info.mail_noti_subscribed && <Checkbox
                          checked
                          onChange={(e) => {
                            // debugger;
                            const value = e.target.checked;
                            compState.user_info.mail_noti_subscribed = value;
                            updateCompState("user_info>mail_noti_subscribed", value);
                            updateCompState('delta>notificationPrefEmail', value);
                            updateCompState('isProfileChanged', true);
                          }} value={user_info.mail_noti_subscribed} className='settting-profile-checkbox' />
                        }
                        {!compState.user_info.mail_noti_subscribed && <Checkbox

                          onChange={(e) => {
                            // debugger;
                            const value = e.target.checked;
                            compState.user_info.mail_noti_subscribed = value;
                            updateCompState("user_info>mail_noti_subscribed", value);
                            updateCompState('delta>notificationPrefEmail', value);
                            updateCompState('isProfileChanged', true);
                          }} value={user_info.mail_noti_subscribed} className='settting-profile-checkbox' />
                        }
                        <span> Email</span>
                      </Grid>

                      <Grid item >
                        {console.log("pushNotificationState", compState.user_info.push_noti_subscribed)}

                        <Checkbox
                          //checked={pushNotificationState ? true : false}
                          checked={compState.user_info.push_noti_subscribed
                            ?
                            pushNotificationState ? true : false
                            :
                            false
                          }
                          onChange={(e) => {
                            const value = e.target.checked;
                            compState.user_info.push_noti_subscribed = value;
                            updateCompState("user_info>push_noti_subscribed", value);
                            updateCompState('delta>notificationPrefWeb', value);
                            updateCompState('isProfileChanged', true);
                            pushNotification();
                          }}
                          value={user_info.push_noti_subscribed}
                          className='settting-profile-checkbox'
                        />


                        <span> Push Notification</span>
                      </Grid>

                    </Grid>

                  </Box>
                </Grid>

              </Grid>

              <Box className='profile-buttons'>
                <Button
                  className='profile-cancel-btn'
                  disabled={!compState.isProfileChanged}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className='profile-save-btn'
                  disabled={!compState.isProfileChanged}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>

            </Box>

            <Box className={`profile-name change-pass ${isExpanded ? 'expanded' : ''}`} sx={{ pt: 0, pb: 0, m: 0 }}>
              <div>
                <Grid container>
                  <Grid item xs={12}>
                    <Box className='change-password'>
                      <Grid className='security-heading' container >
                        <Grid>
                          <div className='heading'>
                            {/* <div className={`security-icon ${isExpanded ? 'expanded' : ''}`}></div> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#333333" viewBox="0 0 256 256"><path d="M128,112a28,28,0,0,0-8,54.83V184a8,8,0,0,0,16,0V166.83A28,28,0,0,0,128,112Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,128,152Zm80-72H176V56a48,48,0,0,0-96,0V80H48A16,16,0,0,0,32,96V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V96A16,16,0,0,0,208,80ZM96,56a32,32,0,0,1,64,0V80H96ZM208,208H48V96H208V208Z"></path></svg>
                            Change Password
                          </div>
                        </Grid>
                        <Grid >
                          <svg
                            onClick={handleToggle}
                            className={`pass-change-icon ${isExpanded ? 'expanded' : 'collapsed'}`}
                            xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#333333" viewBox="0 0 256 256"><path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
                          </svg>
                        </Grid>
                      </Grid>
                      {isExpanded && <SecuritySettings />}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>

          </Grid>

          <Grid item md={3}>
            <Box className='profile-dashboard' sx={{ height: 'calc(100vh - 18vh)', overflow: 'hidden', ml: 0, }}>
              <div className='profile-side-head'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00415F" viewBox="0 0 256 256"><path d="M240,208H224V96a16,16,0,0,0-16-16H144V32a16,16,0,0,0-24.88-13.32L39.12,72A16,16,0,0,0,32,85.34V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM208,96V208H144V96ZM48,85.34,128,32V208H48ZM112,112v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm-32,0v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm0,56v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Zm32,0v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Z"></path></svg>
                <h3 className='profile-right-heading'>Access List</h3>
              </div>
              <Box sx={{ height: 'calc(100vh - 20vh)', overflow: 'auto', pb: 2, ml: 1, mr: 1 }}>
                <List sx={{ ml: 0, pl: 0 }}>
                  {
                    properties.map((item, index) => {
                      return (<ListItem key={`item-${index}`} className="property-list">
                        <ListItemText primary={`${item.name}`} className="property-name" />
                        {/* <Chip className='property-role border' label="Revenue" variant="outlined" /> */}
                      </ListItem>

                      )
                    })
                  }
                </List>

              </Box>
            </Box>

          </Grid>

        </Grid >
        {
          compState.request === 'loading' && (
            <Box
              sx={{
                textAlighn: "center",
                backgroundColor: "dark.darkest",
                opacity: 0.8,
                position: "fixed",
                top: 40,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 100,
              }}
            >
              <CircularProgress
                size={40}
                sx={{
                  color: "white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-20px",
                  marginLeft: "-20px",
                }}
              />
            </Box>
          )
        }
      </Box >

      {refreshingData && (
        <Box
          sx={{
            textAlighn: "center",
            backgroundColor: "dark.darkest",
            opacity: 0.8,
            position: "fixed",
            top: 40,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 100,
          }}
        >
          <CircularProgress
            size={40}
            sx={{
              color: "white",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-20px",
              marginLeft: "-20px",
            }}
          />
        </Box>
      )}

    </div >
  );
}

function SecuritySettings(props) {
  const dispatch = useDispatch();
  const { app, auth } = useSelector(state => state);
  const { user, profile } = auth;
  const properties = profile.property_details;
  const [formData, setFormData] = useState({ old_pass: '', new_pass: '', repeat_pass: '', submitDisabled: true });
  const [errorType, setErrorType] = useState("");
  const [isrequesting, setIsRequesting] = useState(false);
  const [resError, setResError] = useState(false);
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const [requestSuccessMsg, setRequestSuccessMsg] = useState('Your request for change password was successfull!');
  const [isRequestError, setIsRequestError] = useState(false);
  const [resErrorMsg, setResErrorMsg] = useState('');

  useEffect(() => {
    const { old_pass, new_pass, repeat_pass, submitDisabled } = formData;
    if (old_pass && new_pass && repeat_pass && (new_pass === repeat_pass)) {
      //setFormData({...formData, submitDisabled:false});
    } else {
      //setFormData({...formData, submitDisabled:true});
    }
  }, [formData]);
  const checkValidity = () => {
    const { old_pass, new_pass, repeat_pass } = formData;

    if (old_pass && new_pass && repeat_pass && (new_pass === repeat_pass)) {
      setFormData({ ...formData, submitDisabled: false });
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const { old_pass, new_pass } = formData;
    setIsRequesting(true);
    let payload = {
      password: {
        old: old_pass,
        new: new_pass
      }
      // "current_password": old_pass,
      // "new_password": new_pass,
      // "email": user.email,
      // "token_string": user.token_string,
      // "user_id": user.user_id
    }
    dataService.update({ api: 'users/change-password', request: payload })
      .then(
        (data) => {
          //Do some task here just after respons
          if (data.status === "success") {
            setIsRequestSuccess(true);
            setIsRequesting(false);
            setFormData({ old_pass: '', new_pass: '', repeat_pass: '', submitDisabled: true });
            setTimeout(() => {
              setIsRequestSuccess(false);
            }, 3000);
            //return Promise.resolve(data);
          }
          else {
            setIsRequestError(true);
            setIsRequesting(false);
            setResErrorMsg(data.message);
            //return Promise.reject(data);
          }
        }
      )
      .catch((err) => {
        setIsRequestError(true);
        setResErrorMsg(err.original.statusText || 'Something went wrong, please try again later!');
        return Promise.reject(err);
      })
  }
  function updateRequestData(e, type) {
    formData[type] = e.target.value;
    //setFormData({...formData});
    const { old_pass, new_pass, repeat_pass } = formData;
    if (new_pass && repeat_pass && (new_pass !== repeat_pass)) {
      setIsRequestError(true);
      setResErrorMsg("New password and repeated password is not matching, please check and try again");
    } else {
      setIsRequestError(false);
      setResErrorMsg("");
    }
    if (old_pass && new_pass && repeat_pass && (new_pass === repeat_pass)) {
      setFormData({ ...formData, submitDisabled: false });
    } else {
      setFormData({ ...formData, submitDisabled: true });
    }
  }


  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRenewPassword, setShowRenewPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const handleRenewPasswordVisibility = () => {
    setShowRenewPassword(!showRenewPassword);
  };


  return (
    <>
      <Grid container spacing={3}>

        <Grid item md={12} sx={{ mr: 0, pr: 0 }}>
          <Box sx={{ pt: 2 }}>
            <Box sx={{ display: isRequestSuccess ? 'block' : 'none', p: 1, position: 'relative', color: 'white.main', backgroundColor: 'green.green', textAlign: 'center', fontSize: 17 }}>
              {requestSuccessMsg}
            </Box>
            <Alert severity="error" sx={{ display: isRequestError ? 'flex' : 'none' }}>{resErrorMsg}</Alert>
            <Box className='pass-change-form' component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 0, padding: 1, }}>

              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        paddingRight: 0,
                      },
                    }}
                    className='textfield'
                    size='small'
                    error={errorType === "password"}
                    helperText={errorType === "password" ? "Invalid password" : ""}
                    margin="normal"
                    required
                    fullWidth
                    id="old_password"
                    label="Old Password"
                    type={showPassword ? 'text' : 'password'}
                    name="old_password"
                    autoComplete=""
                    value={formData.old_pass}
                    onChange={(e) => {
                      updateRequestData(e, 'old_pass');
                      //setFormData({...formData, old_pass:e.target.value});
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={handlePasswordVisibility}>
                            {showPassword ? <i class="ph ph-eye"></i> : <i class="ph ph-eye-closed"></i>}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoFocus
                  />

                </Grid>
                <Grid item xs={4}>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        paddingRight: 0,
                      },
                    }}
                    className='textfield'
                    size='small'
                    margin="normal"
                    error={errorType === "password"}
                    helperText={errorType === "password" ? "Invalid password" : ""}
                    required
                    fullWidth
                    name="new_pass"
                    label="New Password"
                    type={showNewPassword ? 'text' : 'password'}
                    id="new_password"
                    autoComplete=""
                    value={formData.new_pass}
                    onChange={(e) => {
                      updateRequestData(e, 'new_pass');
                      //setFormData({...formData, new_pass:e.target.value});
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={handleNewPasswordVisibility}>
                            {showNewPassword ? <i class="ph ph-eye"></i> : <i class="ph ph-eye-closed"></i>}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        paddingRight: 0,
                      },
                    }}
                    className='textfield'
                    size='small'
                    margin="normal"
                    error={errorType === "password"}
                    helperText={errorType === "password" ? "Invalid password" : ""}
                    required
                    fullWidth
                    name="repeat_pass"
                    label="Repeat New Password"
                    type={showRenewPassword ? 'text' : 'password'}
                    id="repeat_pass"
                    autoComplete=""
                    value={formData.repeat_pass}
                    onChange={(e) => {
                      updateRequestData(e, 'repeat_pass');
                      //setFormData({...formData, repeat_pass:e.target.value});

                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={handleRenewPasswordVisibility}>
                            {showRenewPassword ? <i class="ph ph-eye"></i> : <i class="ph ph-eye-closed"></i>}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <div className='profile-change-password-btn-parent' >
                  <Button
                    className='profile-change-password-btn'
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={formData.submitDisabled}
                    sx={{ mr: 1, mt: 2, mb: 0, backgroundColor: 'green.main', fontSize: 14, color: '#fff', '&:disabled': { backgroundColor: '#333333', color: '#fff', opacity: 0.4, cursor: 'not-allowed' }, '&:hover': { backgroundColor: '#333333', opacity: 1 } }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>

              </Grid>









              {isrequesting && (
                <Box sx={{ textAlighn: 'center', backgroundColor: 'dark.darkest', opacity: 0.8, position: 'absolute', top: 40, left: 0, width: '100%', height: '100%', zIndex: 100 }}>
                  <CircularProgress
                    size={40}
                    sx={{
                      color: 'white',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-20px',
                      marginLeft: '-20px',
                    }}
                  />
                </Box>

              )}

            </Box>
          </Box>
        </Grid>

      </Grid>
    </>

  );
}



export { ProfileSettings, SecuritySettings };