
import { Box, TextField } from '@mui/material'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';

const DbaPicker = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const dispatch = useDispatch();
    const [defaultValue, setDefaultValue] = useState(controls.dba);

    const handleChange = (type, value) => {
        if (controls.dba !== value) {
            const updateTypes = [type];
            let dbaVal = (value) ? value : 45;
            controls.dba = dbaVal;

            dispatch(
                updateAppState(
                    updateTypes, {
                    app_state: {
                        workspace_controls: controls,
                        data_load: true
                    },
                    ui_state: { showspinner: true }
                }, "DBA_input"));
            setDefaultValue(dbaVal);

        }
    }
    return (
        <Box id="compareToBox" sx={{ width: "60px" }}>
            <Box className="data-level-label"> DBA</Box>
            <TextField
                size="small"
                sx={{ mt: "5px" }}
                InputProps={{
                    style: { height: 30, fontFamily:"SFUI Regular", fontSize:"14px" }
                }}
                value={defaultValue}
                onChange={(e) => { 
                    let value = e.target.value;
                    setDefaultValue(value);
                    // handleChange("dbaInput", ) 
                }}
                onBlur={(e) => { handleChange("dbaInput", e.target.value) }}
                // defaultValue={45}
                color="blue"
            >
            </TextField>

        </Box>
    )
}

export default DbaPicker