
import { Box, Select, FormControl, MenuItem } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import { useProductVariant } from "../../../../hooks/use-product-variant"
import { UPDATE_STATE } from '../../../../actions/types';
import { APP_CACHE } from '../../../../app/config';


const SummaryPicker = () => {
    const { ui_state, app_state } = useSelector((state) => state.app.current_state);
    const { hasFeature } = useProductVariant();
    const dispatch = useDispatch();

    const handleChange = (value) => {
        ui_state.isLock = !hasFeature([value], 'tiles');
        ui_state.active_tiles.splice(0);
        ui_state.active_tiles.push(value);
        app_state.workspace_controls.summaryType = value;
        // let marketSelect = value === 'market_summary' ? true : false;
        APP_CACHE.active_tiles = [value];
        dispatch({
            type: UPDATE_STATE,
            payload: {
                app_state: {
                    date_range_type: 'rangeTileChange_' + value,
                    workspace_controls:{...app_state.workspace_controls, summaryType: value},
                    // controlSupport: {...app_state.controlSupport, marketSelect: marketSelect},
                    data_load: false
                },
                ui_state: {
                    // ...ui_state,
                    active_tiles: [value],
                    showspinner: true
                }
            }
        })
        // dispatch(updateAppState("change_active_tiles", {
        //     app_state: {
        //         date_range_type: 'rangeTileChange_' + value,
        //         workspace_controls: app_state.workspace_controls,
        //         controlSupport: {...app_state.controlSupport, marketSelect: marketSelect}
        //         // data_load: true
        //     },
        //     ui_state: {
        //         ...ui_state,
        //         active_tiles: [value],
        //         showspinner: true
        //     },
        // }))
    }

    return (
        <>
            <Box id="pickupBox" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box className="data-level-label">Summary Reports</Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                    <Select className='tabular-data-filter' value={app_state.workspace_controls.summaryType} sx={{ height: "30px", mt: "5px" }} onChange={(e) => {
                        handleChange(e.target.value);
                    }}>
                        <MenuItem value="hotel_summary" className='tabular-data-filter'> Hotel Summary </MenuItem>
                        <MenuItem value="market_summary" className='tabular-data-filter'> Market Summary </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}


export default SummaryPicker