import { useState, useEffect, useCallback } from "react";

//This hook is a helper hook to check online status of the user
function useOnlineStatus(params) {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  // debugger;
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }
    function handleOffline() {
      setIsOnline(false);
    }
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return isOnline;
}


const useVisbilityState = (val) => {
  const [visibilityState, setVisibilityState] = useState(val || 'visible');

  const handleVisbilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState);
  }, [setVisibilityState]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisbilityChange);
    return () =>
      document.removeEventListener('visibilitychange', handleVisbilityChange);
  }, [handleVisbilityChange]);

  return visibilityState;
};

export {
  useOnlineStatus,
  useVisbilityState
}
