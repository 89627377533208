import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './dailyflash.css'
import SmallLoader from './SmallLoader';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { dataFormatMonthlyCard, removeSpecials } from './functions';
import { removeSpecialChar, removeSpecialCharWithOutDecimal, getPermissions } from '../../app/util';



export default function MonthCard(props) {
    const { auth, app } = useSelector(state => state);
    const { app_state, ui_state } = app.current_state;
    const { profile } = auth;
    const resError = props.resError;
    let {sdlyText, dayDate} = props;
    let sdlyKey = (dayDate === 'day') ? "sdly" : "sdtly";
    const [monthlyData, setMonthlyData] = React.useState(props.data);
    let currentMonth = moment(app_state.as_of_date).format("MMMM YYYY");
    let result;
    let primary = { Occ: null, ADR: null, RevPAR: null, Revenue: null }
    let sdly = { Occ: null, ADR: null, RevPAR: null, Revenue: null }

    let primaryNext = { Occ: null, ADR: null, RevPAR: null, Revenue: null }
    let sdlyNext = { Occ: null, ADR: null, RevPAR: null, Revenue: null }

    let primaryFar = { Occ: null, ADR: null, RevPAR: null, Revenue: null }
    let sdlyFar = { Occ: null, ADR: null, RevPAR: null, Revenue: null }

    let forecast = { Occ: null, ADR: null, RevPAR: null, Revenue: null }
    let forecastNext = { Occ: null, ADR: null, RevPAR: null, Revenue: null }
    let forecastFar = { Occ: null, ADR: null, RevPAR: null, Revenue: null }
    let { selectedMarketProperty, availableMarkets } = props;
    let selectedResource = {}
    if (availableMarkets.length > 1) {
        selectedResource = availableMarkets.find(i => i.id === selectedMarketProperty);

    }
    let permissionDetails = getPermissions(profile.property_details, app_state.property_id);
    if (props.forecastData && props.forecastData.length > 0) {
        // debugger;
        forecast = {
            ...forecast,
            Occ: props.forecastData[0].Occ,
            ADR: props.forecastData[0].ADR,
            RevPAR: props.forecastData[0].RevPAR,
            Revenue: props.forecastData[0].Revenue,
        }
        forecastNext = {
            ...forecastNext,
            Occ: props.forecastData[1].Occ,
            ADR: props.forecastData[1].ADR,
            RevPAR: props.forecastData[1].RevPAR,
            Revenue: props.forecastData[1].Revenue,
        }
        forecastFar = {
            ...forecastFar,
            Occ: props.forecastData[2].Occ,
            ADR: props.forecastData[2].ADR,
            RevPAR: props.forecastData[2].RevPAR,
            Revenue: props.forecastData[2].Revenue,
        }
    }
    if (props.data && props.data.length > 0) {
        primary = {
            ...primary,
            Occ: props.data[0].primary.Occ,
            ADR: props.data[0].primary.ADR,
            RevPAR: props.data[0].primary.RevPAR,
            Revenue: props.data[0].primary.Revenue,
        }
        sdly = {
            ...sdly,
            Occ: props.data[0][sdlyKey].Occ,
            ADR: props.data[0][sdlyKey].ADR,
            RevPAR: props.data[0][sdlyKey].RevPAR,
            Revenue: props.data[0][sdlyKey].Revenue,
        }
        primaryNext = {
            ...primaryNext,
            Occ: props.data[1].primary.Occ,
            ADR: props.data[1].primary.ADR,
            RevPAR: props.data[1].primary.RevPAR,
            Revenue: props.data[1].primary.Revenue,
        }
        sdlyNext = {
            ...sdlyNext,
            Occ: props.data[1][sdlyKey].Occ,
            ADR: props.data[1][sdlyKey].ADR,
            RevPAR: props.data[1][sdlyKey].RevPAR,
            Revenue: props.data[1][sdlyKey].Revenue,
        }
        primaryFar = {
            ...primaryFar,
            Occ: props.data[2].primary.Occ,
            ADR: props.data[2].primary.ADR,
            RevPAR: props.data[2].primary.RevPAR,
            Revenue: props.data[2].primary.Revenue,
        }
        sdlyFar = {
            ...sdlyFar,
            Occ: props.data[2][sdlyKey].Occ,
            ADR: props.data[2][sdlyKey].ADR,
            RevPAR: props.data[2][sdlyKey].RevPAR,
            Revenue: props.data[2][sdlyKey].Revenue,
        }

    }

    let budgetCurrent;
    let budgetNext;
    let budgetFar;
    // debugger; 
    if (props.budgetData) {
        budgetCurrent = props.budgetData[0];
        budgetNext = props.budgetData[1];
        budgetFar = props.budgetData[2];
    }

    const UpIcon = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                fill="#69DB7C"
                viewBox="0 0 256 256"
            >
                <path d="M205.66,117.66a8,8,0,0,1-11.32,0L136,59.31V216a8,8,0,0,1-16,0V59.31L61.66,117.66a8,8,0,0,1-11.32-11.32l72-72a8,8,0,0,1,11.32,0l72,72A8,8,0,0,1,205.66,117.66Z"></path>
            </svg>
        );
    };
    const DownIcon = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                fill="#FF6B6B"
                viewBox="0 0 256 256"
            >
                <path d="M205.66,149.66l-72,72a8,8,0,0,1-11.32,0l-72-72a8,8,0,0,1,11.32-11.32L120,196.69V40a8,8,0,0,1,16,0V196.69l58.34-58.35a8,8,0,0,1,11.32,11.32Z"></path>
            </svg>
        );
    };

    return (
        <Grid container justifyContent="space-between" spacing={1}>
            <Grid item xs={4}>
                <Card className='card-container'>
                    <CardContent>
                        <Typography className='card-date-title'>
                            {currentMonth}
                        </Typography>
                        <Box className='card-content-box'>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 1, pl: 1.9 }}>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Occupancy</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Avg. Daily Rate</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {resError ? "No Data" : (primary.Occ) ? primary.Occ : <SmallLoader />}
                                    </span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {resError ? "No Data" : (primary.ADR) ? primary.ADR : <SmallLoader />}

                                    </span>
                                </Grid>

                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text capitalize'>{sdlyText}</span>
                                        <span className='card-data-text'> {resError ? "No Data" : (sdly.Occ) ? sdly.Occ : <SmallLoader />}</span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 60 }}>
                                            {
                                                (sdly.Occ && primary.Occ && primary.Occ > sdly.Occ) ?
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }
                                        </span>
                                    </span>

                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast</span>
                                        <span className='card-data-text'>{resError || permissionDetails.permissions.hfc === 0 || !forecast.Occ ? "No Data" : (forecast.Occ) ? forecast.Occ : <SmallLoader />}</span>
                                        {forecast.Occ !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 60 }}>
                                                {
                                                    (forecast.Occ && primary.Occ && primary.Occ > forecast.Occ) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetCurrent && budgetCurrent.Occ) ? budgetCurrent.Occ : "No Data"}

                                        </span>
                                        {budgetCurrent && budgetCurrent.Occ !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 60 }}>
                                                {
                                                    (Number(removeSpecialChar(primary.Occ, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetCurrent.Occ, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>

                                </Grid>
                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>{sdlyText}</span>
                                        <span className='card-data-text'>
                                            {resError ? "No Data" : (sdly.ADR) ? sdly.ADR : <SmallLoader />}
                                        </span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 60 }}>
                                            {
                                                (sdly.ADR && primary.ADR && parseInt(removeSpecialChar(primary.ADR, ui_state.currency_symbol)) > parseInt(removeSpecialChar(sdly.ADR, ui_state.currency_symbol))) ?
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }

                                        </span>
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast</span>
                                        <span className='card-data-text'>{resError || permissionDetails.permissions.hfc === 0 || !forecast.ADR ? "No Data" : (forecast.ADR) ? forecast.ADR : <SmallLoader />}</span>
                                        {forecast.ADR !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 60 }}>
                                                {
                                                    (forecast.ADR && primary.ADR && parseInt(removeSpecialChar(primary.ADR, ui_state.currency_symbol)) > parseInt(removeSpecialChar(forecast.ADR, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetCurrent && budgetCurrent.ADR) ? budgetCurrent.ADR : "No Data"}
                                        </span>
                                        {budgetCurrent && budgetCurrent.ADR !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 60 }}>
                                                {
                                                    (Number(removeSpecialChar(primary.ADR, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetCurrent.ADR, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                </Grid>

                            </Grid>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 2, pl: 1.9 }}>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Rev Par</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Revenue</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {resError ? "No Data" : (primary.RevPAR) ? primary.RevPAR : <SmallLoader />}
                                    </span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {/* { removeSpecialChar(primary.Revenue)} */}
                                        {resError ? "No Data" : (primary.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(primary.Revenue, ui_state.currency_symbol), ui_state) : <SmallLoader />}
                                    </span>
                                </Grid>

                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>{sdlyText}</span>
                                        <span className='card-data-text'>
                                            {resError ? "No Data" : (sdly.RevPAR) ? sdly.RevPAR : <SmallLoader />}</span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 60 }}>
                                            {
                                                //    (sdly.RevPAR && primary.RevPAR && primary.RevPAR > sdly.RevPAR) ? 
                                                (sdly.RevPAR && primary.RevPAR && parseInt(removeSpecialChar(primary.RevPAR, ui_state.currency_symbol)) > parseInt(removeSpecialChar(sdly.RevPAR, ui_state.currency_symbol))) ?
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }
                                        </span>
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast</span>
                                        <span className='card-data-text'>{resError || permissionDetails.permissions.hfc === 0 || !forecast.RevPAR ? "No Data" : (forecast.RevPAR) ? forecast.RevPAR : <SmallLoader />}</span>
                                        {forecast.RevPAR !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 60 }}>
                                                {

                                                    (forecast.RevPAR && primary.RevPAR && Number(removeSpecialChar(primary.RevPAR, ui_state.currency_symbol)) > Number(removeSpecialChar(forecast.RevPAR, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetCurrent && budgetCurrent.RevPAR) ? budgetCurrent.RevPAR : "No Data"}

                                        </span>
                                        {budgetCurrent && budgetCurrent.RevPAR !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 60 }}>
                                                {
                                                    (Number(removeSpecialChar(primary.RevPAR, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetCurrent.RevPAR, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>

                                </Grid>
                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>{sdlyText}</span>
                                        <span className='card-data-text'>{resError ? "No Data" : (sdly.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(sdly.Revenue, ui_state.currency_symbol), ui_state) : <SmallLoader />}</span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 52 }}>

                                            {
                                                (sdly.Revenue && primary.Revenue && Number(removeSpecialChar(primary.Revenue, ui_state.currency_symbol)) > Number(removeSpecialChar(sdly.Revenue, ui_state.currency_symbol))) ?
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }
                                        </span>
                                    </span> <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast</span>
                                        <span className='card-data-text'>{resError || permissionDetails.permissions.hfc === 0 || !forecast.Revenue ? "No Data" : (forecast.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(forecast.Revenue, ui_state.currency_symbol), ui_state) : <SmallLoader />}</span>
                                        {forecast.Revenue !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 52 }}>
                                                {
                                                    (forecast.Revenue && primary.Revenue && Number(removeSpecialChar(primary.Revenue, ui_state.currency_symbol)) > Number(removeSpecialChar(forecast.Revenue, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span> 
                                    

                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetCurrent && budgetCurrent.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(budgetCurrent.Revenue, ui_state.currency_symbol), ui_state)  : "No Data"}

                                        </span>
                                        {budgetCurrent && budgetCurrent.Revenue !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 52 }}>
                                                {
                                                    (Number(removeSpecialChar(primary.Revenue, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetCurrent.Revenue, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                </Grid>

                            </Grid>

                        </Box>
                    </CardContent>

                </Card>
            </Grid>

            <Grid item xs={4}>
                <Card className='card-container'>
                    <CardContent>
                        <Typography className='card-date-title'>
                            {moment(currentMonth).add(1, "month").format("MMMM YYYY")}
                        </Typography>
                        <Box className='card-content-box'>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 1, pl: 1.9 }}>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Occupancy</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Avg. Daily Rate</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {resError ? "No Data" : (primaryNext.Occ) ? primaryNext.Occ : <SmallLoader />}
                                    </span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {resError ? "No Data" : (primaryNext.ADR) ? primaryNext.ADR : <SmallLoader />}

                                    </span>
                                </Grid>

                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>{sdlyText}</span>
                                        <span className='card-data-text'>
                                            {resError ? "No Data" : (sdlyNext.Occ) ? sdlyNext.Occ : <SmallLoader />}</span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 60 }}>
                                            {

                                                (sdlyNext.Occ && primaryNext.Occ && Number(removeSpecialChar(primaryNext.Occ, ui_state.currency_symbol)) > Number(removeSpecialChar(sdlyNext.Occ, ui_state.currency_symbol))) ?
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }
                                        </span>
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast</span>
                                        <span className='card-data-text'>
                                            {resError || permissionDetails.permissions.hfc === 0 || !forecastNext.Occ ? "No Data" : (forecastNext.Occ) ? forecastNext.Occ : <SmallLoader />}
                                        </span>
                                        {forecastNext.Occ !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 60 }}>
                                                {
                                                    (forecastNext.Occ && primaryNext.Occ && parseInt(removeSpecialChar(primaryNext.Occ, ui_state.currency_symbol)) > parseInt(removeSpecialChar(forecastNext.Occ, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span> 
                                    
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetNext && budgetNext.Occ) ? budgetNext.Occ : "No Data"}

                                        </span>
                                        {budgetNext && budgetNext.Occ !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 60 }}>
                                                {
                                                    (Number(removeSpecialChar(primaryNext.Occ, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetNext.Occ, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>

                                </Grid>
                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>{sdlyText}</span>
                                        <span className='card-data-text'>
                                            {resError ? "No Data" : (sdlyNext.ADR) ? sdlyNext.ADR : <SmallLoader />}
                                        </span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 60 }}>
                                            {
                                                (sdlyNext.ADR && primaryNext.ADR && parseInt(removeSpecialChar(primaryNext.ADR, ui_state.currency_symbol)) > parseInt(removeSpecialChar(sdlyNext.ADR, ui_state.currency_symbol))) ?
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }
                                        </span>
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast</span>
                                        <span className='card-data-text'> {resError || permissionDetails.permissions.hfc === 0 || !forecastNext.ADR ? "No Data" : (forecastNext.ADR) ? forecastNext.ADR : <SmallLoader />}</span>
                                        {forecastNext.ADR !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 60 }}>
                                                {

                                                    (forecastNext.ADR && primaryNext.ADR && parseInt(removeSpecialChar(primaryNext.ADR, ui_state.currency_symbol)) > parseInt(removeSpecialChar(forecastNext.ADR, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetNext && budgetNext.ADR ) ? budgetNext.ADR : "No Data"}

                                        </span>
                                        {budgetNext && budgetNext.ADR !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 60 }}>
                                                {
                                                    (Number(removeSpecialChar(primaryNext.ADR, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetNext.ADR, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                </Grid>

                            </Grid>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 2, pl: 1.9 }}>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Rev Par</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Revenue</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {resError ? "No Data" : (primaryNext.RevPAR) ? primaryNext.RevPAR : <SmallLoader />}
                                    </span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {resError ? "No Data" : (primaryNext.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(primaryNext.Revenue, ui_state.currency_symbol), ui_state) : <SmallLoader />}</span>
                                </Grid>

                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>{sdlyText}</span>
                                        <span className='card-data-text'>
                                            {resError ? "No Data" : (sdlyNext.RevPAR) ? sdlyNext.RevPAR : <SmallLoader />}</span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 60 }}>
                                            {
                                                (sdlyNext.RevPAR && primaryNext.RevPAR && parseInt(removeSpecialChar(primaryNext.RevPAR, ui_state.currency_symbol)) > parseInt(removeSpecialChar(sdlyNext.RevPAR, ui_state.currency_symbol))) ?
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }
                                        </span>
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast</span>
                                        <span className='card-data-text'> {resError || permissionDetails.permissions.hfc === 0 || !forecastNext.RevPAR ? "No Data" : (forecastNext.RevPAR) ? forecastNext.RevPAR : <SmallLoader />}</span>
                                        {forecastNext.RevPAR !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 60 }}>
                                                {
                                                    (forecastNext.RevPAR && primaryNext.RevPAR && parseInt(removeSpecialChar(primaryNext.RevPAR, ui_state.currency_symbol)) > parseInt(removeSpecialChar(forecastNext.RevPAR, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetNext && budgetNext.RevPAR) ? budgetNext.RevPAR : "No Data"}

                                        </span>
                                        {budgetNext && budgetNext.RevPAR !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 60 }}>
                                                {
                                                    (Number(removeSpecialChar(primaryNext.RevPAR, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetNext.RevPAR, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>

                                </Grid>
                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>

                                        <span className='card-data-text'>{sdlyText}</span>
                                        <span className='card-data-text'>{resError ? "No Data" : (sdlyNext.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(sdlyNext.Revenue, ui_state.currency_symbol), ui_state) : <SmallLoader />}</span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 40 }}>
                                            {
                                                (sdlyNext.Revenue && primaryNext.Revenue && parseInt(removeSpecialChar(primaryNext.Revenue, ui_state.currency_symbol)) > parseInt(removeSpecialChar(sdlyNext.Revenue, ui_state.currency_symbol))) ?
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }
                                        </span>
                                    </span> <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast</span>
                                        <span className='card-data-text'> {resError || permissionDetails.permissions.hfc === 0 || !forecastNext.Revenue ? "No Data" : (forecastNext.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(forecastNext.Revenue, ui_state.currency_symbol), ui_state) : <SmallLoader />}</span>
                                        {forecastNext.Revenue !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 40 }}>
                                                {
                                                    (forecastNext.Revenue && primaryNext.Revenue && Number(removeSpecialChar(primaryNext.Revenue, ui_state.currency_symbol)) > Number(removeSpecialChar(forecastNext.Revenue, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetNext && budgetNext.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(budgetNext.Revenue, ui_state.currency_symbol), ui_state)  : "No Data"}

                                        </span>

                                        {budgetNext && budgetNext.Revenue !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 40 }}>
                                                {
                                                    (Number(removeSpecialChar(primaryNext.Revenue, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetNext.Revenue, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                </Grid>

                            </Grid>

                        </Box>
                    </CardContent>

                </Card>
            </Grid>
            <Grid item xs={4}>
                <Card className='card-container'>
                    <CardContent>
                        <Typography className='card-date-title'>
                            {moment(currentMonth).add(2, "month").format("MMMM YYYY")}
                        </Typography>
                        <Box className='card-content-box'>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 1, pl: 1.9 }}>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Occupancy</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Avg. Daily Rate</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {resError ? "No Data" : (primaryFar.Occ) ? primaryFar.Occ : <SmallLoader />}
                                    </span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {resError ? "No Data" : (primaryFar.ADR) ? primaryFar.ADR : <SmallLoader />}

                                    </span>
                                </Grid>

                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>{sdlyText}</span>
                                        <span className='card-data-text'> {resError ? "No Data" : (sdlyFar.Occ) ? sdlyFar.Occ : <SmallLoader />}</span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 40 }}>
                                            {
                                                (sdlyFar.Occ && primaryFar.Occ && parseInt(removeSpecialChar(primaryFar.Occ, ui_state.currency_symbol)) > parseInt(removeSpecialChar(sdlyFar.Occ, ui_state.currency_symbol))) ?
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }
                                        </span>
                                    </span> <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast</span>
                                        <span className='card-data-text'> {resError || permissionDetails.permissions.hfc === 0 || !forecastFar.Occ ? "No Data" : (forecastFar.Occ) ? forecastFar.Occ : <SmallLoader />}</span>
                                        {forecastFar.Occ !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 40 }}>
                                                {
                                                    (forecastFar.Occ && primaryFar.Occ && Number(removeSpecialChar(primaryFar.Occ, ui_state.currency_symbol)) > Number(removeSpecialChar(forecastFar.Occ, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span> <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetFar && budgetFar.Occ) ? budgetFar.Occ : "No Data"}
                                        </span>
                                        {budgetFar && budgetFar.Occ !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 40 }}>
                                                {
                                                    (Number(removeSpecialChar(primaryFar.Occ, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetFar.Occ, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>

                                </Grid>
                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>{sdlyText}</span>
                                        <span className='card-data-text'>
                                            {resError ? "No Data" : (sdlyFar.ADR) ? sdlyFar.ADR : <SmallLoader />}
                                        </span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 60 }}>
                                            {
                                                (sdlyFar.ADR && primaryFar.ADR && Number(removeSpecialChar(primaryFar.ADR, ui_state.currency_symbol)) > Number(removeSpecialChar(sdlyFar.ADR, ui_state.currency_symbol))) ?
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }
                                        </span>
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast </span>
                                        <span className='card-data-text'>{resError || permissionDetails.permissions.hfc === 0 || !forecastFar.ADR ? "No Data" : (forecastFar.ADR) ? forecastFar.ADR : <SmallLoader />}</span>
                                        {forecastFar.ADR !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 60 }}>
                                                {
                                                    (forecastFar.ADR && primaryFar.ADR && Number(removeSpecialChar(primaryFar.ADR, ui_state.currency_symbol)) > Number(removeSpecialChar(forecastFar.ADR, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetFar && budgetFar.ADR) ? budgetFar.ADR : "No Data"}
                                        </span>
                                        {budgetFar && budgetFar.ADR !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 60 }}>
                                                {
                                                    (Number(removeSpecialChar(primaryFar.ADR, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetFar.ADR, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                </Grid>

                            </Grid>

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 2 }} sx={{ mt: 2, pl: 1.9 }}>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Rev Par</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-title'>Revenue</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {resError ? "No Data" : (primaryFar.RevPAR) ? primaryFar.RevPAR : <SmallLoader />}
                                    </span>
                                </Grid>
                                <Grid item xs={6}>
                                    <span className='card-data-main-text'>
                                        {resError ? "No Data" : (primaryFar.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(primaryFar.Revenue, ui_state.currency_symbol), ui_state) : <SmallLoader />}</span>
                                </Grid>

                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>{sdlyText}</span>
                                        <span className='card-data-text'>
                                            {resError ? "No Data" : (sdlyFar.RevPAR) ? sdlyFar.RevPAR : <SmallLoader />}</span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 60 }}>
                                            {
                                                (sdlyFar.RevPAR && primaryFar.RevPAR && Number(removeSpecialChar(primaryFar.RevPAR, ui_state.currency_symbol)) > Number(removeSpecialChar(sdlyFar.RevPAR, ui_state.currency_symbol))) ?
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }
                                        </span>
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast </span>
                                        <span className='card-data-text'>{resError || permissionDetails.permissions.hfc === 0 || !forecastFar.RevPAR ? "No Data" : (forecastFar.RevPAR) ? forecastFar.RevPAR : <SmallLoader />}</span>
                                        {forecastFar.RevPAR !== null &&
                                            <span span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 60 }}>
                                                {
                                                    (Number(removeSpecialChar(ui_state.currency_symbol)) > Number(removeSpecialChar(ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetFar && budgetFar.RevPAR ) ? budgetFar.RevPAR : "No Data"}
                                        </span>
                                        {budgetFar && budgetFar.RevPAR !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 60 }}>
                                                {
                                                    (Number(removeSpecialChar(primaryFar.RevPAR, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetFar.RevPAR, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>

                                </Grid>
                                <Grid item xs={6}>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>{sdlyText}</span>
                                        <span className='card-data-text'>{resError ? "No Data" : (sdlyFar.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(sdlyFar.Revenue, ui_state.currency_symbol), ui_state) : <SmallLoader />}</span>
                                        <span className='card-data-text-arrow' style={{ position: 'absolute', top: 3, right: 40 }}>
                                            {
                                                (Number(removeSpecialChar(primaryFar.Revenue, ui_state.currency_symbol)) > Number(removeSpecialChar(sdlyFar.Revenue, ui_state.currency_symbol))) ?
                                                    //    ( removeSpecialCharWithOutDecimal(primaryFar.Revenue) > removeSpecialCharWithOutDecimal(sdlyFar.Revenue) ) ? 
                                                    <UpIcon />
                                                    :
                                                    <DownIcon />
                                            }
                                        </span>
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Forecast</span>
                                        <span className='card-data-text'>{resError || permissionDetails.permissions.hfc === 0 || !forecastFar.Revenue ? "No Data" : (forecastFar.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(forecastFar.Revenue, ui_state.currency_symbol), ui_state) : <SmallLoader />}</span>
                                        {forecastFar.Revenue !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 23, right: 40 }}>
                                                {
                                                    (Number(removeSpecialChar(primaryFar.Revenue, ui_state.currency_symbol)) > Number(removeSpecialChar(forecastFar.Revenue, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                    <span style={{ position: 'relative' }}>
                                        <span className='card-data-text'>Budget</span>
                                        <span className='card-data-text'>
                                            {(budgetFar && budgetFar.Revenue) ? dataFormatMonthlyCard("Revenue", removeSpecialChar(budgetFar.Revenue, ui_state.currency_symbol), ui_state)  : "No Data"}

                                        </span>
                                        {budgetFar && budgetFar.Revenue !== null &&
                                            <span className='card-data-text-arrow' style={{ position: 'absolute', top: 45, right: 40 }}>
                                                {
                                                    (Number(removeSpecialChar(primaryFar.Revenue, ui_state.currency_symbol)) > Number(removeSpecialChar(budgetFar.Revenue, ui_state.currency_symbol))) ?
                                                        <UpIcon />
                                                        :
                                                        <DownIcon />
                                                }
                                            </span>
                                        }
                                    </span>
                                </Grid>

                            </Grid>

                        </Box>
                    </CardContent>

                </Card>
            </Grid>

        </Grid>
    );
}