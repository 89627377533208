import React from 'react'
import { CardContent, Chip, Container, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Tooltip } from '@mui/material';
import { convertToPercent, daysCountFromRange } from '../../app/util';
import { getCodeDetails } from './DisplayData';
import { useSelector } from 'react-redux';
import { checkCorrectValue, getDataMainKey, getKpisSymbol } from './ratePlan_functions';
import { TILES } from "../../app/config";

function RatePlanListTotal(props) {
    const state = useSelector(appState => appState);
    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let { data, activeTiles } = props;
    const { workspace_controls, comp_as_of_date, as_of_date } = app_state;
    const { sdlyOn } = workspace_controls;
    let active_tiles = activeTiles;//ui_state.active_tiles;
    let dataSet = [];
    if (data && data.length > 0) {
        let data1 = Object.keys(data[0]);
        data1.map((item) => {
            dataSet.push(data[0][item]);
        })
    }
    let data2 = dataSet.sort((a, b) => b.data.primary.Rooms - a.data.primary.Rooms);
    let daysCount = daysCountFromRange(app_state);
    let dataAvailable = false;
    let dataKeyDefault = (active_tiles.length === 1) ? getDataMainKey(active_tiles[0]) : "primary";
    let dataMainKey = "primary";


    function getColumnValues(item) {
        let dataMainKey = "primary";
        let columnValues = [];
        active_tiles.map((tile) => {
            dataMainKey = getDataMainKey(tile);
            let ratePlanColumns = TILES[tile].tableConfig.columns[0].subcolumns_rateplan;
            ratePlanColumns.map((kpi) => {
                let symbol = getKpisSymbol(kpi.value);
                if (item.data[dataMainKey]) {
                    let val = checkCorrectValue(item.data[dataMainKey][kpi.value], symbol);
                    val = (kpi.value === 'ADR') ? checkCorrectValue(item.data[dataMainKey]['Revenue'] / item.data[dataMainKey]['Rooms'], symbol) : val;
                    columnValues.push(val);
                }
            })
            if (tile === 'otb' && sdlyOn) {
                ratePlanColumns.map((kpi) => {
                    let symbol = getKpisSymbol(kpi.value);
                    if (item.data['sdly']) {
                        let val = checkCorrectValue(item.data['sdly'][kpi.value], symbol);
                        columnValues.push(val);
                    }
                })
            }
            if (tile === 'pickup' && sdlyOn) {
                ratePlanColumns.map((kpi) => {
                    let symbol = getKpisSymbol(kpi.value);
                    if (item.data['sdly_pickup']) {
                        let val = checkCorrectValue(item.data['sdly_pickup'][kpi.value], symbol);
                        val = (kpi.value === 'ADR') ? checkCorrectValue(item.data['sdly_pickup']['Revenue'] / item.data['sdly_pickup']['Rooms'], symbol) : val;

                        columnValues.push(val);
                    }
                })
            }
            if (tile === 'pace') {
                ratePlanColumns.map((kpi) => {
                    let symbol = getKpisSymbol(kpi.value);
                    if (item.data['comparison']) {
                        let val = checkCorrectValue(item.data['comparison'][kpi.value], symbol);
                        columnValues.push(val);
                    }
                })
            }
        })
        return columnValues;
    }

    return (
        <>
            {(dataSet && dataSet.map((item, index) => {
                if (item.data[dataKeyDefault].Rooms !== 0) {
                    dataAvailable = true;
                    // let bgcolor = (index % 2 === 0) ? "" : "rateplan-even-row";
                    return (
                        <TableRow key={index} className={`rateplan-row rateplan-even-row`} >
                            <TableCell component="td" scope="row" className='rateplan-td'>
                                <Tooltip title={getCodeDetails(item.ratePlanCode, app_state)} arrow followCursor>
                                    <div>{item.ratePlanCode}</div>
                                </Tooltip>
                            </TableCell>
                            {
                                getColumnValues(item).map((v) => {
                                    return (<>
                                        <TableCell component="td" scope="row" className='rateplan-td'>
                                          {v}
                                        </TableCell>
                                    </>)
                                })
                            }

                            {/* {
                                active_tiles.map((tile) => {
                                    dataMainKey = getDataMainKey(tile);
                                    let ratePlanColumns = TILES[tile].tableConfig.columns[0].subcolumns_rateplan;
                                    return ratePlanColumns.map((kpi) => {
                                        let symbol = getKpisSymbol(kpi.value);
                                        return (<>
                                            <TableCell component="td" scope="row" className='rateplan-td'>
                                                {(item.data[dataMainKey] && checkCorrectValue(item.data[dataMainKey][kpi.value], symbol))}
                                            </TableCell>
                                        </>)
                                    })
                                })
                            } */}



                            {/* <TableCell component="td" scope="row" className='rateplan-td'>
                                {convertToPercent(item.data[dataKeyDefault].Occ / daysCount)}%
                            </TableCell>
                            <TableCell component="td" scope="row" className='rateplan-td'>
                                {item.data[dataKeyDefault].Rooms}
                            </TableCell>
                            <TableCell component="td" scope="row" className='rateplan-td'>
                                ${((item.data[dataKeyDefault].ADR)).toFixed(2)}
                            </TableCell> */}

                        </TableRow>
                    )
                }
            })
            )}

            {((dataSet.length === 0 || !dataAvailable) && <>
                <TableRow>
                    <TableCell colSpan={4} className='table-heading' sx={{ textAlign: "center" }}>No Rate Plan Found!</TableCell>
                </TableRow>
            </>
            )}


        </>
    )
}

export default RatePlanListTotal
