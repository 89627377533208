import React, { useEffect, useState } from 'react'
import { IconButton, Menu, Tooltip } from '@mui/material/';
import AlertList from './AlertList';
import { CustomSVGIcon } from "../../components/layout/dashboard-ui"
import Badge from '@mui/material/Badge';
import { deepCopy, getSeriesById, replaceMultiText } from '../../app/util';
import dataService, { fetchDataSimple } from '../../services/data.service';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../actions/appstate';
import { UPDATE_STATE } from '../../actions/types';

const AlertBell = () => {
    const [currentURLPath, setCurrentURLPath] = useState("");
    useEffect(() => {
        setCurrentURLPath(window.location.pathname);
    }, []);

    const { app, auth } = useSelector((state) => state);
    const { app_state } = app.current_state;

    const dispatch = useDispatch();


    const [alertsData, setAlertData] = useState(null);
    const [alertCount, setAlertCount] = useState(null);

    useEffect(() => {
        const request = {
            "method": "GET",
        };

        const correction = {
            "{pid}": app_state.property_id,
            "{asOfDate}": app_state.as_of_date,
        };

        let apiUrl = "alerts/alerts/?asOfDate={asOfDate}&propertyIds={pid}";
        apiUrl = replaceMultiText(apiUrl, correction);
        let api = apiUrl;
        fetchDataSimple({ api, request })
            .then((response) => {
                if (response.status === "success") {
                    setAlertData(response);
                    // update alerts count
                    let alertCounts = (response.meta.total - response.meta.read);
                    // debugger;
                    dispatch({
                        type: UPDATE_STATE,
                        payload: {
                            app_state: { alertCounts: alertCounts },
                        }
                    });
                    // dispatch(
                    //     updateAppState(
                    //         "alertUpdate", {
                    //         app_state: { alertCounts: alertCounts},
                    //         // ui_state: { showspinner: true }
                    //     }));

                    setAlertCount(alertCounts);

                } else {
                    setAlertData(null);
                    dispatch({
                        type: UPDATE_STATE,
                        payload: {
                            app_state: { alertCounts: 0 },
                        }
                    });

                    // dispatch(
                    //     updateAppState(
                    //         "alertUpdate", {
                    //         app_state: { alertCounts: 0},
                    //         // ui_state: { showspinner: true }
                    //     }));



                    setAlertCount(0);
                }
            })
            .catch((error) => {
                setAlertData(null);
            })
        // }, [app_state.property_id, app_state.as_of_date])
    }, [app_state.property_id, app_state.as_of_date, app_state.alertCounts])


    const [anchorElAlert, setAnchorElAlert] = useState(null);
    const handleOpenAlert = (event) => {
        setAnchorElAlert(event.currentTarget);
    };
    const handleCloseAlert = () => {
        setAnchorElAlert(null);
    };


    // let alertsData = deepCopy(dataService.getSeriesById("GetAlerts").data);
    // let alertsCount = (alertsData) ? alertsData.meta.total : 0;
    // debugger
    let alertsDetails = (alertsData) ? alertsData : [];

    return (
        <>
            {currentURLPath === '/settings_xx'
                ?
                <Badge color="secondary" className='alert-badge' badgeContent={alertCount} sx={{opacity: 0.4}}>
                    <IconButton sx={{ p: 0 }} className="icon-btn alert-bell-btn">
                        <CustomSVGIcon opacity={1} path="assets/img/notification.svg" width="25" height="25" className="notification-icon no-hover"/>
                        <span className="notification"></span>
                    </IconButton>
                </Badge>
                :
                <Tooltip title="Notification">
                    <Badge color="secondary" className='alert-badge' badgeContent={alertCount}>
                        <IconButton onClick={handleOpenAlert} sx={{ p: 0 }} className="icon-btn alert-bell-btn">
                            <CustomSVGIcon path="assets/img/notification.svg" width="25" height="25" className="notification-icon" />
                            <span className="notification"></span>
                        </IconButton>
                    </Badge>
                </Tooltip>
            }

            <Menu
                sx={{ mt: '20px', zIndex: 9992, }}
                id="menu-appbar"
                anchorEl={anchorElAlert}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElAlert)}
                onClose={handleCloseAlert}
                PaperProps={{
                    className: "notification-menu-div",
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                <AlertList alertsDetails={alertsDetails} alertsCount={app_state.alertCounts} handleCloseAlert={handleCloseAlert} />
            </Menu>
        </>
    )
}

export default AlertBell
