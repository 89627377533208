import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import { Box, FormControl, Select, MenuItem } from '@mui/material'
import moment from "moment";
function ToggleRoomTypeRestrictions() {
    const { workspace_controls } = useSelector(state => state.app.current_state.app_state);
    const dispatch = useDispatch();

    const handleChange = (value) => {
        workspace_controls.isRestrictionsRoomType = value;
        let date_range_type = "scopeTypeChanged_Date";
        // if (workspace_controls.isRestrictionsRoomType === true) {
        //      date_range_type = "restrictionsRoomType";
        // }
        dispatch(updateAppState("toggleRoomTypeRestrictions", {
            app_state: {
                workspace_controls: { ...workspace_controls },
                date_range_type,
            },
            ui_state: { showspinner: true }
        }));
    }

    return (
        <>
            <Box id="tabletype" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box className="data-level-label">Data Level </Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                    <Select fullWidth
                        className='tabular-data-filter'
                        value={workspace_controls.isRestrictionsRoomType}
                        onChange={(event) => handleChange(event.target.value)}
                        sx={{ height: "30px", mt: "5px" }}
                    >
                        <MenuItem value={false} className='tabular-data-filter'>Hotel</MenuItem>
                        <MenuItem value={true} className='tabular-data-filter'>Room Type</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default ToggleRoomTypeRestrictions;
