import React, { useState } from 'react'
import { useSelector } from "react-redux";
import { Box } from '@mui/system';
import { Button, Modal } from '@mui/material';
import { CloseSharp as CloseSharpIcon } from '@mui/icons-material';
import LockIcon from '../Icons/LockIcon'
import Project from '../Icons/Project'
import Upload from '../Icons/Upload'
import BusinessIntelligence from '../Icons/BusinessIntelligence';
import UsersThree from '../Icons/UsersThree';
import RequestSupport from '../RequestSupport/'
import "./fullScreenLock.scss"

const FullScreenLock = ({ userName = "", handelClose, handelRequestSupport, fullScreenLockData, propertyName }) => {
    const { module, title, status } = fullScreenLockData
    const [openRequestSupport, setOpenRequestSupport] = useState(false);


    function openRequestSupportModal() {
        setOpenRequestSupport(true);
    }

    function handleRequestSupportClose() {
        setOpenRequestSupport(false);
    }

    function handelOpenRequestSupport() {
        openRequestSupportModal();
        handelRequestSupport()
    }

    let titleIcon = <LockIcon height="18px" width='18px' fill="#ffffff" />;

    console.log("title", title)

    switch (title) {
        case 'Daily Flash':
            titleIcon = <BusinessIntelligence height="18px" width='18px' fill="#ffffff" />;
            break;

        case 'Reports':
            titleIcon = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#fff" viewBox="0 0 256 256"><path d="M96,104a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H104A8,8,0,0,1,96,104Zm8,40h64a8,8,0,0,0,0-16H104a8,8,0,0,0,0,16Zm128,48a32,32,0,0,1-32,32H88a32,32,0,0,1-32-32V64a16,16,0,0,0-32,0c0,5.74,4.83,9.62,4.88,9.66h0A8,8,0,0,1,24,88a7.89,7.89,0,0,1-4.79-1.61h0C18.05,85.54,8,77.61,8,64A32,32,0,0,1,40,32H176a32,32,0,0,1,32,32V168h8a8,8,0,0,1,4.8,1.6C222,170.46,232,178.39,232,192ZM96.26,173.48A8.07,8.07,0,0,1,104,168h88V64a16,16,0,0,0-16-16H67.69A31.71,31.71,0,0,1,72,64V192a16,16,0,0,0,32,0c0-5.74-4.83-9.62-4.88-9.66A7.82,7.82,0,0,1,96.26,173.48ZM216,192a12.58,12.58,0,0,0-3.23-8h-94a26.92,26.92,0,0,1,1.21,8,31.82,31.82,0,0,1-4.29,16H200A16,16,0,0,0,216,192Z"></path></svg>;
            break;

        case 'Uploads':
            titleIcon = <Upload height="18px" width='18px' fill="#ffffff" />;
            break;

        case 'Group Pricing':
            titleIcon = <UsersThree height="18px" width='18px' fill="#ffffff" />;
            break;

        default:
            titleIcon = <LockIcon height="18px" width='18px' fill="#ffffff" />;
            break;
    }



    if (openRequestSupport) {
        return (
            <Modal
                open={openRequestSupport}
                onClose={handleRequestSupportClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <RequestSupport autoSubmit={true} handleClose={handleRequestSupportClose} message={`I would like to enable ${module} for ${propertyName}`} />
            </Modal>
        )
    } else {
        return (
            <Modal
                open={status}
                onClose={handelClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '360px',
                    height: 'auto',
                    bgcolor: '#fff',
                    boxShadow: 24, borderRadius: '8px'
                }}

                    className="full_screen_lock">
                    <div className="pop-up-header clearfix request-popup-header">
                        <Box >
                            <div className="tile-title title_text_icon" data-tile-icon="icon-icon-events" data-id="events">
                                <span>{titleIcon}</span>
                                <span className="tile-name">{title}</span>
                            </div>
                            <Button className='cross-btn' sx={{
                                minWidth: 'auto', width: '22px', height: '22px', p: '7px', position: 'absolute', top: -10, right: -16,
                                mr: '7px', borderRadius: '50%', background: '#000', color: '#FFF', fontSize: '10px', border: '1px solid #EBEBEB', '&:hover': { background: '#000' }
                            }} onClick={handelClose}>
                                <CloseSharpIcon fontSize='small' />
                            </Button>
                        </Box>

                    </div>
                    <div className='content_part '>

                        <LockIcon height="36px" width='28px' fill="#999999" />

                        <p>
                            {`Hey ${userName}, ${module} is not subscribed. Would you like to enable ${module}?`}
                        </p>

                        <Button
                            className='yes_please'
                            fullWidth
                            sx={{ mt: 2, mb: 2, display: 'inherit' }}
                            onClick={() => handelOpenRequestSupport()}
                        >
                            Yes! Please
                        </Button>
                    </div>

                </Box>
            </Modal>
        )
    }
}

export default FullScreenLock