import {
  getTileById, getCompsetMedian,
  getResourceIdByPropId, getSeriesById,
  deepCopy, getFromState, getTransformedSegments, safeDivide,
  aggregateObject,
  getDatesInRange,
  aggregateInner,
  sum,
  aggregateSegmentedGroupedData,
} from '../../app/util';
import moment from 'moment';
import { getPricingDataFormatted } from '../DynamicTable/tilesData/pricing';
import { getRestrictionsFormatted } from '../DynamicTable/tilesData/restrictions';
import { getColumnName_biSensitivity } from '../DynamicTable/tilesData/bi_sensitivity_report_functions';
const getRestrictionsDisplay = (recData, toString = false) => {
  let display = (<div className="res-rules-display">
    {recData.cta && <span className="res-rule res-cta">CTA</span>}
    {recData.ctd && <span className="res-rule res-ctd">CTD</span>}
    {recData.close && <span className="res-rule res-cls">CLS</span>}
    {recData.min_los && recData.min_los !== 1 && <span className="res-rule res-min_los">MN {recData.min_los}</span>}
    {recData.max_los && recData.max_los !== 1 && <span className="res-rule res-max_los">MX {recData.max_los}</span>}
  </div>);
  if (toString) {
    display = '<div class="res-rules-display">';
    if (recData.cta) {
      display += '<span class="res-rule res-cta">CTA</span>'
    }
    if (recData.ctd) {
      display += '<span class="res-rule res-ctd">CTD</span>'
    }
    if (recData.close) {
      display += '<span class="res-rule res-cls">CLS</span>'
    }
    if (recData.min_los && recData.min_los !== 1) {
      display += '<span class="res-rule res-min_los">MN ' + recData.min_los + '</span>'
    }
    if (recData.max_los && recData.max_los !== 1) {
      display += '<span class="res-rule res-max_los">MX ' + recData.max_los + '</span>'
    }
    display += '</div>';
  }
  return display;
}

const transformData = (params) => {
  const { dataSet, data_id, range, segments, tile, pickUpType,
    room_settings, topSegments, segmentType, restrictionsAvail , app_state={}} = params;
  let dataId = data_id;
  //Handle for single tile
  if (dataSet && dataSet.constructor.name === 'Array') {
    let transformed = [];
    //let segmentTotal = {};
    //let keys = [
    //  ['current', 'primary', 'pickup', 'pickup_diff', 'prior', 'sdly', 'sdly_actual', 'sdly_pickup'],
    //  ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms', 'SellRate']
    // ];

    let segTotal = {};
    switch (dataId) {

      case "OSRBookingPaceSegment":
        // debugger;
        if (tile === 'pace') {
          let segmentTotal = {};
          let keys = [
            ['primary', 'comparison'],
            ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms']
          ];
          dataSet.map(item => {
            let HotelTotal = deepCopy(item.HotelTotal);
            let index = deepCopy(item.index);
            let ingonreKeys = ['HotelTotal', 'index'];
            let tmp = { order: [], segmented: { total: HotelTotal } };
            // debugger;

            for (let key in item) {
              if (!ingonreKeys.includes(key)) {
                let segmentCode = key.split('_')[1];
                let segmentName = segments.map[segmentCode];

                //If segment code is not configured in system settings 
                //then put the segment into Other 
                if (!segmentName) {
                  console.warn("Warning! - No segment name exists in system settings for code: " + segmentCode);
                  segmentName = "Other";
                }

                segmentTotal[segmentName] = segmentTotal[segmentName] ? segmentTotal[segmentName] : 0;

                if (tmp.segmented && tmp.segmented[segmentName]) {
                  tmp.segmented[segmentName] = aggregateObject(tmp.segmented[segmentName], item[key], keys, 2);
                } else {
                  tmp.segmented[segmentName] = tmp.segmented[segmentName] ? tmp.segmented[segmentName] : item[key];
                }
                tmp.HotelTotal = HotelTotal;
                tmp.segmented.total = HotelTotal;
                tmp.index = index;
                // debugger;
                //Adding for maintaining orders of segments in chart          
                keys[0].map((dkey) => {
                  segmentTotal[segmentName] += tmp.segmented[segmentName][dkey] ? tmp.segmented[segmentName][dkey].Rooms : 0;
                });

              }
            }
            // debugger;
            tmp.segmentTotal = segmentTotal;
            transformed.push(tmp);
          });
          // Insert order into first item of transformed
          for (let k in segmentTotal) {
            transformed[0].order.push({ name: k, value: segmentTotal[k] });
          }
          if (transformed[0] && transformed[0].order) {
            transformed[0].order.sort((a, b) => b.value - a.value);
            transformed[0].order.push({ name: 'total', value: 0 });
          }

          // debugger;
        }
        // debugger; 
        break;

      //For forecast
      // 'OSRContrainedForecast', 'OSR', "OSRSegment", "ForcastSegment"
      case "OSRContrainedForecast":
        if (tile === 'forecast') {

          let segmentTotal = {};
          let keys = [
            ['primary'],
            ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms']
          ];
          if (segments && segments.map) {
            dataSet.map(item => {
              let HotelTotal = deepCopy(item.HotelTotal);
              let index = deepCopy(item.index);
              let ingonreKeys = ['HotelTotal', 'index'];
              let tmp = { order: [], segmented: { total: HotelTotal } };
              // debugger;

              for (let key in item) {
                if (!ingonreKeys.includes(key)) {
                  let segmentCode = key.split('_')[1];
                  let segmentName = segmentCode; //segments.map[segmentCode];

                  //If segment code is not configured in system settings 
                  //then put the segment into Other 
                  if (!segmentName) {
                    console.warn("Warning! - No segment name exists in system settings for code: " + segmentCode);
                    segmentName = "Other";
                  }

                  segmentTotal[segmentName] = segmentTotal[segmentName] ? segmentTotal[segmentName] : 0;

                  if (tmp.segmented && tmp.segmented[segmentName]) {
                    tmp.segmented[segmentName] = aggregateObject(tmp.segmented[segmentName], item[key], keys, 2);
                  } else {
                    tmp.segmented[segmentName] = tmp.segmented[segmentName] ? tmp.segmented[segmentName] : item[key];
                  }
                  tmp.HotelTotal = HotelTotal;
                  tmp.segmented.total = HotelTotal;
                  tmp.index = index;
                  // debugger;
                  //Adding for maintaining orders of segments in chart          
                  keys[0].map((dkey) => {
                    segmentTotal[segmentName] += tmp.segmented[segmentName][dkey] ? tmp.segmented[segmentName][dkey].Rooms : 0;
                  });

                }
              }
              // debugger;
              tmp.segmentTotal = segmentTotal;
              transformed.push(tmp);
            });

            // debugger;
            // Insert order into first item of transformed
            for (let k in segmentTotal) {
              transformed[0].order.push({ name: k, value: segmentTotal[k] });
            }

            transformed[0].order.sort((a, b) => b.value - a.value);
            transformed[0].order.push({ name: 'total', value: 0 });
          }
        }
        break;
      case "OSR":
        if (tile === 'pickup') {
          let keys = [
            ['current', 'primary', 'pickup', 'pickup_diff', 'prior', 'sdly', 'sdly_actual', 'sdly_pickup'],
            ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms', 'SellRate']
          ];
          dataSet.map((item, i) => {
            // HotelTotal.pickup_diff = {};
            // let tmp = { index: index };
            for (let mkey in item) {
              if (mkey !== 'index') {
                let pickup_diff = {};
                let sdly_pickup_diff = {};
                let sdtly_pickup_diff = {};
                // item[mkey]["pickup_diff"] = {};
                if (pickUpType === 'Latest') {
                  keys[1].map(key => {
                    pickup_diff[key] = (item[mkey].primary[key] * 1000) - (item[mkey].pickup[key] * 1000); // Math.round(item[mkey].primary[key] * 1000) - Math.round(item[mkey].pickup[key] * 1000);
                    pickup_diff[key] = pickup_diff[key] / 1000;

                    sdly_pickup_diff[key] = (item[mkey].sdly[key] * 10000) - (item[mkey].sdly_pickup[key] * 10000); //Math.round(item[mkey].sdly[key] * 1000) - Math.round(item[mkey].sdly_pickup[key] * 1000);
                    sdly_pickup_diff[key] = sdly_pickup_diff[key] / 10000;

                    // sdtly_pickup_diff[key] = (item[mkey].sdtly[key] * 10000) - (item[mkey].sdtly_pickup[key] * 10000); //Math.round(item[mkey].sdly[key] * 1000) - Math.round(item[mkey].sdly_pickup[key] * 1000);
                    // sdtly_pickup_diff[key] = sdtly_pickup_diff[key] / 10000;
                    
                  });
                } else {
                  keys[1].map(key => {
                    pickup_diff[key] = (item[mkey].current[key] * 10000) - (item[mkey].prior[key] * 10000); //Math.round(item[mkey].current[key] * 1000) - Math.round(item[mkey].prior[key] * 1000);
                    pickup_diff[key] = pickup_diff[key] / 10000;

                    sdly_pickup_diff[key] = (item[mkey].sdly[key] * 10000) - (item[mkey].sdly_pickup[key] * 10000); //Math.round(item[mkey].sdly[key] * 1000) - Math.round(item[mkey].sdly_pickup[key] * 1000);
                    sdly_pickup_diff[key] = sdly_pickup_diff[key] / 10000;

                    // sdtly_pickup_diff[key] = (item[mkey].sdtly[key] * 10000) - (item[mkey].sdtly_pickup[key] * 10000); //Math.round(item[mkey].sdly[key] * 1000) - Math.round(item[mkey].sdly_pickup[key] * 1000);
                    // sdtly_pickup_diff[key] = sdtly_pickup_diff[key] / 10000;
                  });
                }
                //Handle ADR
                // debugger; 
                item[mkey].pickup_diff = pickup_diff;
                item[mkey].pickup_diff.ADR = pickup_diff.Rooms !== 0 ? Math.round(pickup_diff.Revenue / pickup_diff.Rooms) : 0;
                item[mkey].sdly_pickup_diff = sdly_pickup_diff;
                item[mkey].sdly_pickup_diff.ADR = sdly_pickup_diff.Rooms !== 0 ? Math.round(sdly_pickup_diff.Revenue / sdly_pickup_diff.Rooms) : 0;

                // item[mkey].sdtly_pickup_diff = sdtly_pickup_diff;
                // item[mkey].sdtly_pickup_diff.ADR = sdtly_pickup_diff.Rooms !== 0 ? Math.round(sdtly_pickup_diff.Revenue / sdtly_pickup_diff.Rooms) : 0;
              }
            }
            // debugger;
            transformed.push(item);
          });
        }
        break;

      case "GetRatePlan":
        dataSet.map(item => {
          let HotelTotal = deepCopy(item.HotelTotal);
          let index = deepCopy(item.index);
          let ingonreKeys = ['HotelTotal', 'index'];
          let tmp = { order: [], segmented: { total: HotelTotal } };
          for (let key in item) {
            if (!ingonreKeys.includes(key)) {
              let segmentCode = key.split('_')[1];
              let segmentName = segmentCode; //segments.map[segmentCode];

              //If segment code is not configured in system settings 
              //then put the segment into Other 


              segmentTotal[segmentName] = segmentTotal[segmentName] ? segmentTotal[segmentName] : 0;

              // if (tmp.segmented && tmp.segmented[segmentName]) {
              //   tmp.segmented[segmentName] = aggregateObject(tmp.segmented[segmentName], item[key], keys, 2);
              // } 
              tmp.segmented[segmentName] = tmp.segmented[segmentName] ? tmp.segmented[segmentName] : item[key];
              tmp.HotelTotal = HotelTotal;
              tmp.segmented.total = HotelTotal;
              tmp.index = index;
              // debugger;
              //Adding for maintaining orders of segments in chart          
              keys[0].map((dkey) => {
                segmentTotal[segmentName] += tmp.segmented[segmentName][dkey] ? tmp.segmented[segmentName][dkey].Rooms : 0;
              });

            }
          }
          // debugger;
          tmp.segmentTotal = segmentTotal;
          transformed.push(tmp);
        });

        for (let k in segmentTotal) {
          if (segmentTotal[k] > 0) {
            transformed[0].order.push({ name: k, value: segmentTotal[k] });
          }
        }

        transformed[0].order.sort((a, b) => b.value - a.value);
        transformed[0].order.push({ name: 'total', value: 0 });

        break;
      case "GetRoomClass":

        dataSet.map(item => {
          let HotelTotal = deepCopy(item.HotelTotal);
          let index = deepCopy(item.index);
          let ignoreKeys = ['HotelTotal', 'index'];
          let tmp = { order: [], segmented: { total: HotelTotal } };
          let keys = [Object.keys(item.HotelTotal), Object.keys(item.HotelTotal["primary"])]
          for (let key in item) {
            if (!ignoreKeys.includes(key)) {
              let segmentCode = key.split('_')[1];
              let segmentName = segmentCode; //segments.map[segmentCode];

              segTotal[segmentName] = segTotal[segmentName] ? segTotal[segmentName] : 0;
              tmp.segmented[segmentCode] = aggregateInner(item[key], keys)//tmp.segmented[segmentName] ? tmp.segmented[segmentName] : item[key];

              // if (tmp.segmented && tmp.segmented[segmentName]) {
              //   tmp.segmented[segmentName] = aggregateObject(tmp.segmented[segmentName], item[key], keys, 2);
              // } else {
              //   tmp.segmented[segmentName] = aggregateInner(item[key], keys,)//tmp.segmented[segmentName] ? tmp.segmented[segmentName] : item[key];
              // }
              tmp.HotelTotal = HotelTotal;
              tmp.segmented.total = HotelTotal;
              tmp.index = index;
              // debugger;
              // //Adding for maintaining orders of segments in chart          
              // keys[0].map((dkey) => {
              //   segTotal[segmentName] += tmp.segmented[segmentName][dkey] ? tmp.segmented[segmentName][dkey].Rooms : 0;
              // });

            }
          }
          // debugger;
          tmp.segmentTotal = segTotal;
          transformed.push(tmp);
        });
        for (let k in segTotal) {

          transformed[0].order.push({ name: k, value: segTotal[k] });


        }

        transformed[0].order.sort((a, b) => b.value - a.value);
        transformed[0].order.push({ name: 'total', value: 0 });

        break;

      case "GetRoomType":
        dataSet.map(item => {
          let HotelTotal = deepCopy(item.HotelTotal);
          let index = deepCopy(item.index);
          let ingonreKeys = ['HotelTotal', 'index'];
          let tmp = { order: [], segmented: { total: HotelTotal } };
          for (let key in item) {
            if (!ingonreKeys.includes(key)) {
              let segmentCode = key.split('_')[1];
              let segmentName = segmentCode; //segments.map[segmentCode];

              //If segment code is not configured in system settings 
              //then put the segment into Other 

              segTotal[segmentName] = segTotal[segmentName] ? segTotal[segmentName] : 0;

              tmp.segmented[segmentName] = tmp.segmented[segmentName] ? tmp.segmented[segmentName] : item[key];

              tmp.HotelTotal = HotelTotal;
              tmp.segmented.total = HotelTotal;
              tmp.index = index;
              // debugger;
              //Adding for maintaining orders of segments in chart          
              // keys[0].map((dkey) => {
              //   segTotal[segmentName] += tmp.segmented[segmentName][dkey] ? tmp.segmented[segmentName][dkey].Rooms : 0;
              // });

            }
          }
          // debugger;
          tmp.segmentTotal = segTotal;
          transformed.push(tmp);
        });
        for (let k in segTotal) {
          transformed[0].order.push({ name: k, value: segTotal[k] });
        }

        transformed[0].order.sort((a, b) => b.value - a.value);
        transformed[0].order.push({ name: 'total', value: 0 });

        break;

      case "OSRSegment":
        // debugger;
        let segmentTotal = {};
        let keys = [
          ['current', 'primary', 'pickup', 'pickup_diff', 'prior', 'sdly', 'sdly_actual', 'sdly_pickup'],
          ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms', 'SellRate']
        ];
        if (tile === 'pickup') {
          dataSet.map((item, i) => {
            // HotelTotal.pickup_diff = {};
            // tmp = { index: index };
            for (let mkey in item) {
              if (mkey !== 'index') {
                let pickup_diff = {};
                let sdly_pickup_diff = {};
                let sdtly_pickup_diff = {};

                item[mkey]["pickup_diff"] = {};
                item[mkey]["sdly_pickup_diff"] = {};
                if (pickUpType === 'Latest') {
                  keys[1].map(key => {
                    pickup_diff[key] = (item[mkey].primary[key] * 10000) - (item[mkey].pickup[key] * 10000); //Math.round(item[mkey].primary[key] * 100) - Math.round(item[mkey].pickup[key] * 100);
                    pickup_diff[key] = pickup_diff[key] / 10000;

                    sdly_pickup_diff[key] = (item[mkey].sdly[key] * 10000) - (item[mkey].sdly_pickup[key] * 10000); //Math.round(item[mkey].sdly[key] * 1000) - Math.round(item[mkey].sdly_pickup[key] * 1000);
                    sdly_pickup_diff[key] = sdly_pickup_diff[key] / 10000;

                    // sdtly_pickup_diff[key] = (item[mkey].sdtly[key] * 10000) - (item[mkey].sdtly_pickup[key] * 10000); //Math.round(item[mkey].sdly[key] * 1000) - Math.round(item[mkey].sdly_pickup[key] * 1000);
                    // sdtly_pickup_diff[key] = sdtly_pickup_diff[key] / 10000;
                  });
                } else {
                  keys[1].map(key => {
                    pickup_diff[key] = (item[mkey].current[key] * 10000) - (item[mkey].prior[key] * 10000); //Math.round(item[mkey].current[key] * 100) - Math.round(item[mkey].prior[key] * 100);
                    pickup_diff[key] = pickup_diff[key] / 10000;

                    sdly_pickup_diff[key] = (item[mkey].sdly[key] * 10000) - (item[mkey].sdly_pickup[key] * 10000); //Math.round(item[mkey].sdly[key] * 1000) - Math.round(item[mkey].sdly_pickup[key] * 1000);
                    sdly_pickup_diff[key] = sdly_pickup_diff[key] / 10000;
                    // debugger; 
                    // sdtly_pickup_diff[key] = (item[mkey].sdtly[key] * 10000) - (item[mkey].sdtly_pickup[key] * 10000); //Math.round(item[mkey].sdly[key] * 1000) - Math.round(item[mkey].sdly_pickup[key] * 1000);
                    // sdtly_pickup_diff[key] = sdtly_pickup_diff[key] / 10000;
                  });
                }
                //Handle ADR
                // debugger;
                item[mkey].pickup_diff = deepCopy(pickup_diff);
                item[mkey].sdly_pickup_diff = deepCopy(sdly_pickup_diff);
                item[mkey].sdtly_pickup_diff = deepCopy(sdtly_pickup_diff);

                item[mkey].pickup_diff.ADR = pickup_diff.Rooms !== 0 ? Math.round(pickup_diff.Revenue / pickup_diff.Rooms) : 0;
                item[mkey].sdly_pickup_diff.ADR = sdly_pickup_diff.Rooms !== 0 ? Math.round(sdly_pickup_diff.Revenue / sdly_pickup_diff.Rooms) : 0;
                // item[mkey].sdtly_pickup_diff.ADR = sdtly_pickup_diff.Rooms !== 0 ? Math.round(sdtly_pickup_diff.Revenue / sdtly_pickup_diff.Rooms) : 0;
              }
            }
          });
        }
        dataSet.map(item => {
          let HotelTotal = deepCopy(item.HotelTotal);
          let index = deepCopy(item.index);
          let ingonreKeys = ['HotelTotal', 'index'];
          let tmp = { order: [], segmented: { total: HotelTotal } };
          // debugger;

          for (let key in item) {
            if (!ingonreKeys.includes(key)) {
              let segmentCode = key.split('_')[1];
              let segmentName = segmentCode; //segments.map[segmentCode];

              //If segment code is not configured in system settings 
              //then put the segment into Other 
              if (!segmentName) {
                console.warn("Warning! - No segment name exists in system settings for code: " + segmentCode);
                segmentName = "Other";
              }

              segmentTotal[segmentName] = segmentTotal[segmentName] ? segmentTotal[segmentName] : 0;

              if (tmp.segmented && tmp.segmented[segmentName]) {
                tmp.segmented[segmentName] = aggregateObject(tmp.segmented[segmentName], item[key], keys, 2);
              } else {
                tmp.segmented[segmentName] = tmp.segmented[segmentName] ? tmp.segmented[segmentName] : item[key];
              }
              tmp.HotelTotal = HotelTotal;
              tmp.segmented.total = HotelTotal;
              tmp.index = index;
              // debugger;
              //Adding for maintaining orders of segments in chart          
              keys[0].map((dkey) => {
                segmentTotal[segmentName] += tmp.segmented[segmentName][dkey] ? tmp.segmented[segmentName][dkey].Rooms : 0;
              });

            }
          }
          // debugger;
          tmp.segmentTotal = segmentTotal;
          transformed.push(tmp);
        });

        // Insert order into first item of transformed
        for (let k in segmentTotal) {
          transformed[0].order.push({ name: k, value: segmentTotal[k] });
        }
        if (segmentType === "top_market_segment") {
          let segmentTotal = {}
          let topKeys = [];
          let kpiKeys = [];
          let segmentedObject = transformed[0].segmented;
          let segmentedDataKays = Object.keys(segmentedObject);
          let segmentedDataKaysCounter = 0;
          while (segmentedDataKaysCounter < segmentedDataKays.length) {
            let segmentedDataCurrentKey = segmentedDataKays[segmentedDataKaysCounter]
            if (segmentedDataCurrentKey !== "total" && segmentedObject[segmentedDataCurrentKey] !== undefined) {
              topKeys = Object.keys(segmentedObject[segmentedDataCurrentKey])
              kpiKeys = Object.keys(segmentedObject[segmentedDataCurrentKey]["primary"])
              break;
            }
            segmentedDataKaysCounter++;
          }
          transformed = transformed.map(item => {
            let HotelTotal = deepCopy(item.HotelTotal);
            let segmentedData = deepCopy(item.segmented);
            let segTotal = deepCopy(item.segmentTotal);
            item.segmented = {};
            item.segmentTotal = {};
            Object.keys(topSegments).forEach(segCat => {
              let segAggregate = {};
              let tempSegData = {};
              let tempSegTotal = 0;
              topSegments[segCat].forEach(segmentName => {
                tempSegData[segmentName] = deepCopy(segmentedData[segmentName]);
                tempSegTotal += segTotal[segmentName];

              })
              segAggregate = aggregateInner(tempSegData, [topKeys, kpiKeys]);
              Object.keys(segAggregate).forEach(innerkey => {
                segAggregate[innerkey].ADR = safeDivide(segAggregate[innerkey].Revenue, segAggregate[innerkey].Rooms);
                //segAggregate[innerkey].RevPAR = segAggregate[innerkey].RevPAR/topSegments[segCat].length;
              })
              console.log(segAggregate);
              item.segmented[segCat] = deepCopy(segAggregate);
              item.segmentTotal[segCat] = tempSegTotal;
              segmentTotal[segCat] = tempSegTotal;
            })

            item.segmented.total = HotelTotal;
            return item;
          })
          transformed[0].order = [];
          Object.keys(topSegments).forEach(segCat => {
            transformed[0].order.push({ name: segCat, value: segmentTotal[segCat] });
          })

        }
        transformed[0].order.sort((a, b) => b.value - a.value);
        transformed[0].order.push({ name: 'total', value: 0 });
        let t = transformed;
        // debugger; 
        break;

      case  "BiSensitivity":
        if(true){
          console.time("BiSensitivity")
          let biSensitivityColumns = getColumnName_biSensitivity('', app_state);
          let biSensitivityColumnsKeys = biSensitivityColumns.map(col => col.mainKey);
          let segmentTotal = {};
          let keys = [
            biSensitivityColumnsKeys,
            ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms', 'SellRate']
          ];

          dataSet.map(item => {
            let HotelTotal = deepCopy(item.HotelTotal);
            let index = deepCopy(item.index);
            let ingonreKeys = ['HotelTotal', 'index'];
            let tmp = { order: [], segmented: { total: HotelTotal } };
            // debugger;
  
            for (let key in item) {
              if (!ingonreKeys.includes(key)) {
                let segmentCode = key.split('_')[1];
                let segmentName = segments.map[segmentCode];
  
                //If segment code is not configured in system settings 
                //then put the segment into Other 
                if (!segmentName) {
                  console.warn("Warning! - No segment name exists in system settings for code: " + segmentCode);
                  segmentName = "Other";
                }
  
                segmentTotal[segmentName] = segmentTotal[segmentName] ? segmentTotal[segmentName] : 0;
  
                if (tmp.segmented && tmp.segmented[segmentName]) {
                  tmp.segmented[segmentName] = aggregateObject(tmp.segmented[segmentName], item[key], keys, 2);
                } else {
                  tmp.segmented[segmentName] = tmp.segmented[segmentName] ? tmp.segmented[segmentName] : item[key];
                }
                tmp.HotelTotal = HotelTotal;
                tmp.segmented.total = HotelTotal;
                tmp.index = index;
                // debugger;
                //Adding for maintaining orders of segments in chart          
                keys[0].map((dkey) => {
                  segmentTotal[segmentName] += tmp.segmented[segmentName][dkey] ? tmp.segmented[segmentName][dkey].Rooms : 0;
                });
  
              }
            }
            // debugger;
            tmp.segmentTotal = segmentTotal;
            transformed.push(tmp);
          });
  
          // Insert order into first item of transformed
          for (let k in segmentTotal) {
            transformed[0].order.push({ name: k, value: segmentTotal[k] });
          }
          if (segmentType === "top_market_segment") {
            let segmentTotal = {}
            let topKeys = [];
            let kpiKeys = [];
            let segmentedObject = transformed[0].segmented;
            let segmentedDataKays = Object.keys(segmentedObject);
            let segmentedDataKaysCounter = 0;
            while (segmentedDataKaysCounter < segmentedDataKays.length) {
              let segmentedDataCurrentKey = segmentedDataKays[segmentedDataKaysCounter]
              if (segmentedDataCurrentKey !== "total" && segmentedObject[segmentedDataCurrentKey] !== undefined) {
                topKeys = Object.keys(segmentedObject[segmentedDataCurrentKey])
                kpiKeys = Object.keys(segmentedObject[segmentedDataCurrentKey]["primary"])
                break;
              }
              segmentedDataKaysCounter++;
            }
            transformed = transformed.map(item => {
              let HotelTotal = deepCopy(item.HotelTotal);
              let segmentedData = deepCopy(item.segmented);
              let segTotal = deepCopy(item.segmentTotal);
              item.segmented = {};
              item.segmentTotal = {};
              Object.keys(topSegments).forEach(segCat => {
                let segAggregate = {};
                let tempSegData = {};
                let tempSegTotal = 0;
                topSegments[segCat].forEach(segmentName => {
                  tempSegData[segmentName] = deepCopy(segmentedData[segmentName]);
                  tempSegTotal += segTotal[segmentName];
  
                })
                segAggregate = aggregateInner(tempSegData, [topKeys, kpiKeys]);
                Object.keys(segAggregate).forEach(innerkey => {
                  segAggregate[innerkey].ADR = safeDivide(segAggregate[innerkey].Revenue, segAggregate[innerkey].Rooms);
                  //segAggregate[innerkey].RevPAR = segAggregate[innerkey].RevPAR/topSegments[segCat].length;
                })
                console.log(segAggregate);
                item.segmented[segCat] = deepCopy(segAggregate);
                item.segmentTotal[segCat] = tempSegTotal;
                segmentTotal[segCat] = tempSegTotal;
              })
  
              item.segmented.total = HotelTotal;
              return item;
            })
            transformed[0].order = [];
            Object.keys(topSegments).forEach(segCat => {
              transformed[0].order.push({ name: segCat, value: segmentTotal[segCat] });
            })
  
          }
          transformed[0].order.sort((a, b) => b.value - a.value);
          transformed[0].order.push({ name: 'total', value: 0 });
          let t = transformed;
        }
          // debugger; 
        console.timeEnd("BiSensitivity");
        break;

      case "OSRRateRecommend":
        let pricingData = getPricingDataFormatted({ primary_stay_range: range, room_settings }, false, dataSet)
        let tData = [];


        if (restrictionsAvail) {
          const resData = getRestrictionsFormatted({ primary_stay_range: range, room_settings }, "cur")
          pricingData.forEach((item, index) => {
            const date = item.stay_date;

            const { stay_date, ...restrictions } = resData[index];
            const restrictionsDisplay = getRestrictionsDisplay(restrictions);
            const data = {
              index: { date },
              sets: {
                Recomended: { SellRate: item.RecSellRate },
                Today: { SellRate: item.SellRate },
                Restrictions: { ...restrictions }
              }
            }
            // if(restrictions){

            // }
            tData.push(data)
          })
        } else {
          pricingData.forEach(item => {
            tData.push({
              index: { date: item.stay_date },
              sets: {
                Recomended: { SellRate: item.RecSellRate },
                Today: { SellRate: item.SellRate },
              }
            })
          })
        }
        return deepCopy(tData);
        break;
      case "MarketForecastSegmented":
        // debugger;
        if (tile === 'market') {
          let segmentTotal = {};
          let keys = [
            ['current', 'primary', 'pickup', 'pickup_diff', 'prior', 'sdly', 'sdly_actual', 'sdly_pickup'],
            ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms', 'SellRate']
          ];

          if (segments && segments.map) {
            dataSet.map(item => {
              let MarketTotal = deepCopy(item.MarketTotal);
              let index = deepCopy(item.index);
              let ingonreKeys = ['MarketTotal', 'index'];
              let tmp = { order: [], segmented: { total: MarketTotal } };
              // debugger;

              for (let key in item) {
                if (!ingonreKeys.includes(key)) {
                  let segmentCode = key.split('Segment_')[1];
                  let segmentName = segments.map[segmentCode];

                  //If segment code is not configured in system settings 
                  //then put the segment into Other 
                  if (!segmentName) {
                    console.warn("Warning! - No segment name exists in system settings for code: " + segmentCode);
                    segmentName = "Other";
                  }

                  segmentTotal[segmentName] = segmentTotal[segmentName] ? segmentTotal[segmentName] : 0;

                  if (tmp.segmented && tmp.segmented[segmentName]) {
                    tmp.segmented[segmentName] = aggregateObject(tmp.segmented[segmentName], item[key], keys, 2);
                  } else {
                    tmp.segmented[segmentName] = tmp.segmented[segmentName] ? tmp.segmented[segmentName] : item[key];
                  }
                  tmp.MarketTotal = MarketTotal;
                  tmp.segmented.total = MarketTotal;
                  tmp.index = index;
                  // debugger;
                  //Adding for maintaining orders of segments in chart          
                  keys[0].map((dkey) => {
                    segmentTotal[segmentName] += tmp.segmented[segmentName][dkey] ? tmp.segmented[segmentName][dkey].Rooms : 0;
                  });

                }
              }
              // debugger;
              tmp.segmentTotal = segmentTotal;
              transformed.push(tmp);
            });

            // debugger;
            // Insert order into first item of transformed
            for (let k in segmentTotal) {
              transformed[0].order.push({ name: k, value: segmentTotal[k] });
            }

            transformed[0].order.sort((a, b) => b.value - a.value);
            transformed[0].order.push({ name: 'total', value: 0 });
          }
          // debugger; 
        }

        break;

      case "MarketSellRate":
        // debugger;
        let last_stay_date = new moment().endOf('month').format('x');
        if (range) {
          last_stay_date = new moment(range.date_until).endOf('day').format('x');
        }
        dataSet.map(item => {
          let rates = [];
          let stay_date = new moment(item.index.date).format('x');
          let tobPushed = {};
          let ingonreKeys = ['total'];
          for (let key in item) {
            if (key.toLocaleLowerCase() === 'index') tobPushed['index'] = deepCopy(item[key]);
            if (key === 'sets') {
              // debugger;
              tobPushed[key] = {
                // "3_star": {SellRate: null},
                // "4_star": {SellRate: null},
                // "5_star": {SellRate: null}
              };
              for (let k2 in item[key]) {
                if (!ingonreKeys.includes(k2)) {
                  tobPushed[key][k2] = { SellRate: deepCopy(item[key][k2].avg) }
                }
              }
            }
          }
          if (stay_date <= last_stay_date) {
            transformed.push(tobPushed);
          }

        });
        // debugger
        break;

      case 'Compset':
        dataSet.map(item => {
          let rates = [];
          let tobPushed = { median: 0, min: 0, max: 0 };
          let ingonreKeys = ['Avg', 'Total'];
          for (let key in item) {
            // debugger;
            if (key.toLocaleLowerCase() === 'index') tobPushed['index'] = deepCopy(item[key]);
            if (!ingonreKeys.includes(key) && item[key].sell_rate > -1) {
              tobPushed[key] = { SellRate: item[key].sell_rate };
              rates.push(item[key].sell_rate);
            }
          }
          tobPushed.median = { SellRate: getCompsetMedian(rates) };
          tobPushed.min = { SellRate: Math.min(...rates) };
          tobPushed.max = { SellRate: Math.max(...rates) };

          transformed.push(tobPushed);
        })
        break;

      case 'CompsetBookingPace':
        // debugger;
        dataSet.map(item => {
          let rates = [];
          let tobPushed = { median: 0, min: 0, max: 0 };
          let ingonreKeys = ['Avg', 'Total'];
          for (let key in item) {
            // debugger;
            if (key.toLocaleLowerCase() === 'index') tobPushed['index'] = deepCopy(item[key]);
            if (!ingonreKeys.includes(key) && item[key].sell_rate > -1) {
              tobPushed[key] = { SellRate: item[key].sell_rate };
              rates.push(item[key].sell_rate);
            }
          }
          tobPushed.median = { SellRate: getCompsetMedian(rates) };
          tobPushed.min = { SellRate: Math.min(...rates) };
          tobPushed.max = { SellRate: Math.max(...rates) };

          transformed.push(tobPushed);
        })
        break;

      default:
        return dataSet;
        break;
    }

    // debugger;
    if (tile === 'forecast') console.log("forecast transformed:", transformed);

    return transformed;
  }

  if (dataSet && dataSet.constructor.name === 'Array') {

  }
}
const getDataIDBySegmentType = (segmentType) => {
  const dataIDMapping = {
    "rate_plan": "GetRatePlan",
    "hotel": "OSRSegment",
    "market_segment": "OSRSegment",
    "top_market_segment": "OSRSegment",
    "room_type": "GetRoomType",
    "room_class": "GetRoomClass"
  }
  return (dataIDMapping[segmentType]) ? dataIDMapping[segmentType] : "OSRSegment";
}
const getMergedData = (dataSet, params = {}) => {
  // debugger;
  if (!(dataSet && dataSet[0] && dataSet[0].data)) {
    return [];
  }
  let mainData = deepCopy(dataSet[0].data);
  let dataSetSize = dataSet.length;
  let mergedData = [];
  let {app_state} = params
  let { workspace_controls, primary_stay_range, room_settings } = app_state;
  let { active_tiles } = params.ui_state;
  let { dba, sdlyOn, compareTo, scopeType, unconstrained, segmented_view, segmentType } = workspace_controls;
  let { segments, topSegments } = params;
  let active_tile = active_tiles[0];

  // Forecast tile
  if (active_tiles.includes('otb')) {
    let { data_sources, chartConfig } = deepCopy(getTileById('otb'));
    if (workspace_controls.segmented_view) {
      let segmentedDataIdIndex = data_sources.indexOf("OSR");
      // let segmentedId = data_sources[1];
      // let tmp = dataSet.filter((data) => data.data_id === segmentedId);
      let tmp = deepCopy(dataSet[segmentedDataIdIndex].data);
      // debugger; 
      //tmp = tmp.length ? tmp[0].data : null;
      if (tmp.length) {
        // debugger; 
        let transformed = transformData({ dataSet: tmp, data_id: "OSRSegment", segments });
        mergedData = transformed;
        // debugger;
      }
    }
  }
  if (active_tiles.includes('otb_v2_multi_segment')) {
    let { data_sources, chartConfig } = deepCopy(getTileById('otb'));
    if (segmentType !== "hotel") {
      let dataID = getDataIDBySegmentType(segmentType);
      // let segmentedId = data_sources[1];
      // let tmp = dataSet.filter((data) => data.data_id === segmentedId);
      let tmp = dataSet.filter(item => item.data_id === dataID)[0].data
      // debugger; 
      //tmp = tmp.length ? tmp[0].data : null;
      if (tmp.length) {
        //if(segmentType ==="")
        let transformed = transformData({ dataSet: tmp, data_id: dataID, segments, topSegments, segmentType });
        mergedData = transformed;
        // debugger;
      }
    }

  }

  if ( active_tiles.includes('bi_sensitivity_analysis')) {
    
    if (segmentType !== "hotel") {
      let dataID =  "BiSensitivity";  //getDataIDBySegmentType(segmentType);
      // let segmentedId = data_sources[1];
      // let tmp = dataSet.filter((data) => data.data_id === segmentedId);
      let tmp = dataSet.filter(item => item.data_id === dataID)[0].data
      //tmp = tmp.length ? tmp[0].data : null;
      if (tmp.length) {
        //if(segmentType ==="")
        let transformed = transformData({ dataSet: tmp, data_id: dataID, segments, topSegments, segmentType, app_state });
        mergedData = transformed;
        // debugger;
      }
    }

  }



  if (active_tiles.includes('pickup')) {
    let { data_sources, chartConfig } = deepCopy(getTileById('pickup'));
    let dataIdIndex = data_sources.indexOf("OSR");
    let dataCopy = deepCopy(dataSet[dataIdIndex].data);

    if (workspace_controls.segmented_view) {

      if (dataCopy.length) {
        let transformed = transformData({ tile: active_tile, pickUpType: workspace_controls.pickUpType, dataSet: dataCopy, data_id: "OSRSegment", segments });
        mergedData = transformed;
      }
    } else {
      dataCopy = deepCopy(dataSet[dataIdIndex].data);
      if (dataCopy.length) {

        let transformed = transformData({ tile: active_tile, pickUpType: workspace_controls.pickUpType, dataSet: dataCopy, data_id: "OSR" });
        mergedData = transformed;
      }
    }
  }

  // Forecast tile
  if (active_tiles.includes('forecast')) {
    let { data_sources, chartConfig } = deepCopy(getTileById('forecast'));
    let collection = {};
    data_sources.map((src) => {
      let { data } = getSeriesById(src);
      collection[src] = data ? deepCopy(data.data) : [];
      if (src === 'OSRContrainedForecast') {
        collection[src] = data && data.data_transformed ? deepCopy(data.data_transformed) : [];
      }
    });
    if (!segmented_view) {
      // debugger;
      if (collection.OSR) {
        collection.OSR.map((item, i) => {
          let stay_date = item.index ? new moment(item.index.date).format("YYYY-MM-DD") : null;
          let OSRContrainedForecast = collection.OSRContrainedForecast;
          if (!OSRContrainedForecast) {
            console.error("OSRContrainedForecast not available");
            OSRContrainedForecast = [];
          }
          let temps = OSRContrainedForecast.filter((d) => {
            return new moment(d.stay_date).format("YYYY-MM-DD") === stay_date;
          });
          if (temps.length) {
            temps = temps[0];
            collection.OSR[i].HotelTotal.primary = {
              ADR: temps.predicted_total_cons_adr,
              Occ: temps.predicted_total_cons_occ,
              RevPAR: temps.predicted_total_cons_revpar,
              Revenue: temps.predicted_total_cons_revenue,
              Rooms: temps.predicted_total_cons_rooms
            };
          }
          mergedData.push(collection.OSR[i]);
        });
      }
    } else {
      if (collection.OSR) {
        collection.OSR.map((item, i) => {
          let stay_date = item.index ? new moment(item.index.date).format("YYYY-MM-DD") : null;
          let temps = collection.OSRContrainedForecast.filter((d) => {
            return new moment(d.stay_date).format("YYYY-MM-DD") === stay_date;
          });
          let tobePushed = { index: item.index, HotelTotal: { primary: {} } };
          if (temps.length) {
            let firstItem = temps[0];
            tobePushed.HotelTotal.primary = {
              ADR: firstItem.predicted_total_cons_adr,
              Occ: firstItem.predicted_total_cons_occ,
              RevPAR: firstItem.predicted_total_cons_revpar,
              Revenue: firstItem.predicted_total_cons_revenue,
              Rooms: firstItem.predicted_total_cons_rooms
            };
            temps.map((segmented, idx) => {
              tobePushed["Segment_" + segmented.market_code] = {
                primary: {
                  ADR: segmented.predicted_cons_adr,
                  Occ: segmented.predicted_cons_occ,
                  RevPAR: segmented.predicted_cons_revpar,
                  Revenue: segmented.predicted_cons_revenue,
                  Rooms: segmented.predicted_cons_rooms
                }
              };
            });
          } else {
            for (let k in item) {
              if (!tobePushed[k]) tobePushed[k] = {};
              if (k !== "index") {
                tobePushed[k].primary = item[k].primary;
              }
            }
          }
          mergedData.push(tobePushed);
        });
        // let md = mergedData;
        // debugger;
      }
    }
  }

  //Market tile
  if (active_tiles.includes('market')) {
    let forecast = {}, unconstrained_forecast = {};
    let { data_sources, chartConfig } = deepCopy(getTileById('market'));
    let { mainkey } = chartConfig;
    // exclude MarketARI
    //data_sources.splice(0, 1);
    // let dbaDataSrc = ['MarketDba', 'MarketSdlyDba'];
    // let dateDataSrc = ['MarketARI', 'MarketForecast'];
    let collection = {};
    // debugger;
    data_sources.map((src) => {
      let { data } = getSeriesById(src);

      collection[src] = data ? data.data : [];
      if (data && data.data && data.data.constructor.name !== 'Array') {
        collection[src] = [];
      }
    });
    // debugger; 
    if (scopeType === 'Date') {
      if (!segmented_view) {
        mainData.map((item, i) => {
          let date = '';
          if (item.index && item.index.date) {
            date = moment(item.index.date).format('YYYY-MM-DD');
          }

          let tmp = collection["MarketForecastSegmented"].filter((d) => {
            return d.stay_date === date;
          });

          if (tmp.length) {
            // debugger;
            // tmp = tmp[0];
            let aggregated = {
              forecast_adr: 0,
              forecast_occ: 0,
              forecast_revenue: 0,
              forecast_revpar: 0,
              forecast_rooms: 0
            };
            let ignoreKeys = ['asof_date', 'market_segment', 'sdly_date', 'stay_date'];
            let capacity = 0;
            tmp.forEach((item) => {
              let keys = Object.keys(item);
              keys.forEach((key) => {
                if (!ignoreKeys.includes(key)) {
                  if (!capacity) capacity = Math.round(item.forecast_revenue / item.forecast_revpar);
                  if (!aggregated[key]) aggregated[key] = 0;
                  aggregated[key] = sum(aggregated[key], item[key]);
                } else {
                  if (!aggregated[key]) aggregated[key] = item[key];
                }
              });
            });
            aggregated.forecast_adr = aggregated.forecast_revenue / aggregated.forecast_rooms;
            aggregated.forecast_revpar = aggregated.forecast_revenue / capacity;
            aggregated.unconstrained_forecast_adr = aggregated.unconstrained_forecast_revenue / aggregated.unconstrained_forecast_rooms;
            aggregated.unconstrained_forecast_revpar = aggregated.unconstrained_forecast_revenue / capacity;

            forecast = {
              Occ: aggregated.forecast_occ,
              ADR: aggregated.forecast_adr,
              Rooms: aggregated.forecast_rooms,
              RevPAR: aggregated.forecast_revpar,
              Revenue: aggregated.forecast_revenue,
            };
            unconstrained_forecast = {
              Occ: aggregated.unconstrained_forecast_occ,
              ADR: aggregated.unconstrained_forecast_adr,
              Rooms: aggregated.unconstrained_forecast_rooms,
              RevPAR: aggregated.unconstrained_forecast_revpar,
              Revenue: aggregated.unconstrained_forecast_revenue,
            };
            //if unconstrained option is switched on, forecast would be unconstrained_forecast
            if (unconstrained) {
              forecast = unconstrained_forecast;
            }
          } else {
            if (item[mainkey]) {
              forecast = item[mainkey].primary;
            }
          }
          // debugger
          if (mainData[i][mainkey]) {
            mainData[i][mainkey].market_forecast = forecast;
          }
          mergedData.push(mainData[i]);
          // debugger; 
        });
      }
      if (segmented_view) {
        // debugger;
        let marketSegments = { list: [], map: {} };
        if (collection.MarketARISegmented) {
          collection.MarketARISegmented.map((item, i) => {
            let stay_date = item.index ? moment(item.index.date).format("YYYY-MM-DD") : null;

            let forecastSegmented = collection.MarketForecastSegmented.filter((d) => {
              return moment(d.stay_date).format("YYYY-MM-DD") === stay_date;
            });
            let forecastTotal = collection.MarketForecastSegmented.filter((d) => {
              return moment(d.stay_date).format("YYYY-MM-DD") === stay_date;
            });
            // debugger;
            let tobePushed = { index: { date: stay_date }, MarketTotal: { primary: {} } };
            for (let k in item) {
              if (k.indexOf('MarketSegment_') > -1) {
                let marketCode = k.split('MarketSegment_')[1];
                //Insert segment code for market
                let exists = marketSegments.list.filter(item => item.code === marketCode);
                if (!exists.length) {
                  marketSegments.list.push({
                    "code": marketCode,
                    "name": marketCode
                  });
                  marketSegments.map[marketCode] = marketCode;
                }
                tobePushed['Segment_' + marketCode] = deepCopy(item[k]);
              }
            }
            if (!forecastTotal.length) {
              tobePushed.MarketTotal = {
                ...item.MarketTotal,
                market_forecast: {
                  ADR: null,
                  Occ: null,
                  RevPAR: null,
                  Revenue: null,
                  Rooms: null
                }
              };
            } else {
              
              let foreCastItem = forecastTotal[0];
              tobePushed.MarketTotal = {
                ...item.MarketTotal,
                market_forecast: {
                  ADR: foreCastItem.forecast_adr,
                  Occ: foreCastItem.forecast_occ,
                  RevPAR: foreCastItem.forecast_revpar,
                  Revenue: foreCastItem.forecast_revenue,
                  Rooms: foreCastItem.forecast_rooms
                },
              }
              forecastSegmented.map((thisItem, i) => {
                if (tobePushed["Segment_" + thisItem.market_segment]) {
                  if (!workspace_controls.unconstrained) {
                    tobePushed["Segment_" + thisItem.market_segment]['market_forecast'] = {
                      ADR: thisItem.forecast_adr,
                      Occ: thisItem.forecast_occ,
                      RevPAR: thisItem.forecast_revpar,
                      Revenue: thisItem.forecast_revenue,
                      Rooms: thisItem.forecast_rooms
                    }
                  } else {
                    tobePushed["Segment_" + thisItem.market_segment]['market_forecast'] = {
                      ADR: thisItem.unconstrained_forecast_adr,
                      Occ: thisItem.unconstrained_forecast_occ,
                      RevPAR: thisItem.unconstrained_forecast_revpar,
                      Revenue: thisItem.unconstrained_forecast_revenue,
                      Rooms: thisItem.unconstrained_forecast_rooms
                    }
                  }

                }
              });
            }
            // debugger
            mergedData.push(tobePushed);
          });
          // debugger;
          let transformed = transformData({ tile: active_tile, dataSet: mergedData, data_id: "MarketForecastSegmented", segments: marketSegments });
          mergedData = transformed;
        }
      }

    }
    if (scopeType === 'Dba' && !segmented_view) {
      // debugger 
      let { mainkey } = chartConfig;
      const { dbaStart, dba } = workspace_controls;
      // let dbaMainkey = 'MarketTotal';
      let currDba = dbaStart;

      do {
        console.log(currDba);
        let new_item = {};

        let tmp = collection["MarketDba"].filter((d) => {
          return Number(d.Index.dba) == currDba;
        });
        if (tmp.length) {
          tmp = tmp[0];

          new_item.index = tmp.Index ? deepCopy(tmp.Index) : deepCopy(tmp.index);
          new_item.Index = tmp.Index ? deepCopy(tmp.Index) : deepCopy(tmp.index);
          new_item[mainkey] = {};
          new_item[mainkey].primary = deepCopy(tmp[mainkey].primary);
          new_item[mainkey].sdly = deepCopy(tmp[mainkey].sdly);
          mergedData.push(new_item);
          // debugger; 
        }

        currDba++;
      } while (currDba <= dba);
      // debugger;
    }
  }

  //Pace/Booking Curve is
  if (active_tiles.includes('pace')) {
    let { clearComparision } = workspace_controls;
    const { chartConfig, data_sources } = getTileById('pace');
    const { mainkey, identifiers, compareToIdentifiers } = chartConfig;
    let data = dataSet.filter(data => data.data_id === "OSRBookingPaceSegment");
    let DBAData = dataSet.filter(data => data.data_id === "OSRBookingPaceSegmentDBA");
    let segmentedData = dataSet.filter(data => data.data_id === "OSRBookingPaceSegment");
    let segmentedDBAData = dataSet.filter(data => data.data_id === "OSRBookingPaceSegmentDBA");
    data = data.length ? data[0].data : [];
    DBAData = DBAData.length ? DBAData[0].data : [];
    segmentedData = segmentedData.length ? segmentedData[0].data : [];
    segmentedDBAData = segmentedDBAData.length ? segmentedDBAData[0].data : [];
    let finalData = data;
    let finalIdentifiers = clearComparision ? compareToIdentifiers : identifiers;
    let ignoreKeys = ['index'];
    // debugger;
    if (segmented_view) {
      if (scopeType === 'Date') {
        finalData = segmentedData;
        finalData.map(item => {
          let stay_date = item.index.date;
          let dataTotal = data.filter(d => d.index.date === stay_date);
          dataTotal = dataTotal.length ? dataTotal[0] : {};
          let tmp = { index: item.index };
          item[mainkey] = dataTotal[mainkey] ? dataTotal[mainkey] : {};
          for (let key in item) {
            // debugger;
            if (!ignoreKeys.includes(key)) {
              finalIdentifiers.map(id => {
                tmp[key] = tmp[key] ? tmp[key] : {};
                tmp[key][id] = item[key][id];
              });
            }
          }
          mergedData.push(tmp);
        });
        // debugger
        mergedData = transformData({ dataSet: mergedData, tile: 'pace', data_id: "OSRBookingPaceSegment", segments });
        // debugger;
      }
      if (scopeType === 'Dba') {
        finalData = segmentedDBAData;
        finalData.map(item => {
          let dba = item.index.dba;
          let dataTotal = DBAData.filter(d => d.index.dba === dba);
          dataTotal = dataTotal.length ? dataTotal[0] : {};
          let tmp = { index: item.index };
          item[mainkey] = dataTotal[mainkey] ? dataTotal[mainkey] : {};
          for (let key in item) {
            // debugger;
            if (!ignoreKeys.includes(key)) {
              finalIdentifiers.map(id => {
                tmp[key] = tmp[key] ? tmp[key] : {};
                tmp[key][id] = item[key][id];
              });
            }
          }
          //Control 
          if (dba <= workspace_controls.dba) {
            mergedData.push(tmp);
          }

        });
        mergedData = transformData({ dataSet: mergedData, tile: 'pace', data_id: "OSRBookingPaceSegment", segments });
        // debugger;
      }
    }
    if (!segmented_view) {
      if (scopeType === 'Dba') {
        finalData = DBAData;
        finalData.map(item => {
          if (item.index.dba <= dba) {
            let tmp = { index: item.index };
            tmp[mainkey] = {};
            finalIdentifiers.map(id => {
              tmp[mainkey] = tmp[mainkey] ? tmp[mainkey] : {};
              tmp[mainkey][id] = item[mainkey][id];
            });
            mergedData.push(tmp);
          }
        });
      }
      if (scopeType === 'Date') {
        finalData.map(item => {
          let tmp = { index: item.index };
          tmp[mainkey] = {};
          finalIdentifiers.map(id => {
            tmp[mainkey] = tmp[mainkey] ? tmp[mainkey] : {};
            tmp[mainkey][id] = item[mainkey][id];
          });
          mergedData.push(tmp);
        });
      }
    }
  }
  // debugger;
  //return mainData if mergedData could not be prepared
  if (active_tiles[0] === "pricing") {
    let pricingData = getPricingDataFormatted({ primary_stay_range, room_settings }, false)
    let tData = [];
    const resData = getRestrictionsFormatted({ primary_stay_range, room_settings }, "cur")
    pricingData.forEach((item, index) => {
      const date = item.stay_date;

      const { stay_date, ...restrictions } = resData[index];
      const restrictionsDisplay = getRestrictionsDisplay(restrictions);
      const data = {
        index: { date },
        sets: {
          Recomended: { SellRate: item.RecSellRate },
          Today: { SellRate: item.SellRate },
          Restrictions: { ...restrictions }
        }
      }
      // if(restrictions){

      // }
      tData.push(data)
    })
    mainData = deepCopy(tData);
  }
  if (dataSetSize && !mergedData.length) {
    return mainData;
  }
  // debugger;
  return mergedData;
};

const getMergedData2 = (dataSet, params = {}) => {

  if (!(dataSet && dataSet[0] && dataSet[0].data)) {
    return [];
  }
  let dataIndex = 0;
  let mainData = deepCopy(dataSet[0].data);
  let dataSetSize = dataSet.length;
  let mergedData = [];
  let { workspace_controls, primary_stay_range, room_settings } = params.app_state;
  let active_tiles = [params.tile];
  let { dba, sdlyOn, compareTo, scopeType, unconstrained, segmented_view } = params.app_state.workspace_controls;
  let { segments, restrictionsAvail } = params;
  let active_tile = active_tiles[0];
  // debugger;
  // Forecast tile
  if (active_tiles.includes('otb')) {
    let { data_sources, chartConfig } = deepCopy(getTileById('otb'));
    if (workspace_controls.segmented_view) {
      let tmp = deepCopy(dataSet[dataIndex].data);
      // debugger; 
      if (tmp.length) {
        let transformed = transformData({ dataSet: tmp, data_id: "OSRSegment", segments });
        mergedData = transformed;
        // debugger;
      }
    }
  }
  if (active_tiles.includes('pricing')) {
    let dataCopy = deepCopy(dataSet[dataIndex].data);
    let transformed = transformData({
      tile: active_tile, dataSet: dataCopy,
      range: primary_stay_range, room_settings, data_id: "OSRRateRecommend", segments, restrictionsAvail
    });
    mergedData = transformed;
  }
  if (active_tiles.includes('pickup')) {
    let { data_sources, chartConfig } = deepCopy(getTileById('pickup'));
    let dataCopy = deepCopy(dataSet[dataIndex].data);
    if (workspace_controls.segmented_view) {
      // debugger; 
      if (dataCopy.length) {
        let transformed = transformData({
          tile: active_tile,
          pickUpType: workspace_controls.pickUpType, dataSet: dataCopy, data_id: "OSRSegment", segments
        });
        mergedData = transformed;
      }
    } else {
      dataCopy = deepCopy(dataSet[dataIndex].data);
      // debugger 
      if (dataCopy.length) {
        // debugger
        let transformed = transformData({ tile: active_tile, pickUpType: workspace_controls.pickUpType, dataSet: dataCopy, data_id: "OSR" });
        mergedData = transformed;
      }
    }
  }

  // Forecast tile
  if (active_tiles.includes('forecast')) {
    let rawForecastData = [];
    let { data_sources, chartConfig } = deepCopy(getTileById('forecast'));
    let collection = {};
    data_sources.map((src) => {
      let { data } = getSeriesById(src);

      collection[src] = data ? deepCopy(data.data) : [];
      // debugger;
      if (src === 'OSRContrainedForecast') {
        collection[src] = data && data.data_transformed ? deepCopy(data.data_transformed) : [];
        if (params.active_tiles.includes('market')) {
          collection[src] = getSeriesById("ForecastMarketSegment").data;
          collection[src] = collection[src] ? collection[src].data : [];
          collection['OSR'] = getSeriesById("marketOtbSegment").data ? getSeriesById("marketOtbSegment").data.data : [];
        }
      }
    });
    // debugger;
    if (!segmented_view) {
      if (collection.OSR) {
        let OSRContrainedForecast = collection.OSRContrainedForecast;
        if (!OSRContrainedForecast) {
          console.error("OSRContrainedForecast not available");
          OSRContrainedForecast = [];
        }
        collection.OSR.map((item, i) => {
          let stay_date = item.index ? moment(item.index.date).format("YYYY-MM-DD") : null;
          // debugger;
          let temps = OSRContrainedForecast.filter((d) => {
            return d.stay_date === stay_date;
          });
          if (temps.length) {
            temps = temps[0];
            collection.OSR[i].HotelTotal.primary = {
              ADR: temps.predicted_total_cons_adr,
              Occ: temps.predicted_total_cons_occ,
              RevPAR: temps.predicted_total_cons_revpar,
              Revenue: temps.predicted_total_cons_revenue,
              Rooms: temps.predicted_total_cons_rooms
            };
          }
          rawForecastData.push(collection.OSR[i]);
        });
      }
    } else {
      if (collection.OSR) {
        collection.OSR.map((item, i) => {
          let stay_date = item.index ? new moment(item.index.date).format("YYYY-MM-DD") : null;
          let temps = collection.OSRContrainedForecast.filter((d) => d.stay_date === stay_date);
          let tobePushed = { index: item.index, HotelTotal: { primary: {} } };
          // debugger;
          if (temps.length) {
            let firstItem = temps[0];
            tobePushed.HotelTotal.primary = {
              ADR: firstItem.predicted_total_cons_adr,
              Occ: firstItem.predicted_total_cons_occ,
              RevPAR: firstItem.predicted_total_cons_revpar,
              Revenue: firstItem.predicted_total_cons_revenue,
              Rooms: firstItem.predicted_total_cons_rooms
            };
            // debugger;
            temps.map((segmented, idx) => {
              
              let access_key = workspace_controls.unconstrained ? "predicted_uncons" : "predicted_cons" ;
              let segment_code = segmented.market_segment ? segmented.market_segment : segmented.market_code;
              tobePushed["Segment_" + segment_code] = {
                primary: {
                  ADR: segmented[access_key + "_adr"],
                  Occ: segmented[access_key + "_occ"],
                  RevPAR: segmented[access_key + "_revpar"],
                  Revenue: segmented[access_key + "_revenue"],
                  Rooms: segmented[access_key + "_rooms"]
                }
              };
            });
          } else {
            for (let k in item) {
              if (!tobePushed[k]) tobePushed[k] = {};
              if (k !== "index") {
                tobePushed[k].primary = item[k].primary;
              }
            }
          }
          
          tobePushed.index.date = new moment(tobePushed.index.date).format("YYYY-MM-DD");
          rawForecastData.push(tobePushed);
        });
        // let md = mergedData;
        // debugger;
      }
    }
    
    if (!segmented_view) {
      mergedData = transformData({ tile: 'forecast', dataSet: rawForecastData, data_id: "OSRContrainedForecast" });
    } else {
      mergedData = transformData({ tile: 'forecast', dataSet: rawForecastData, data_id: "OSRContrainedForecast", segments });
    }

  }

  //Market tile
  if (active_tiles.includes('market')) {
    let forecast = {}, unconstrained_forecast = {};
    let { data_sources, chartConfig } = deepCopy(getTileById('market'));
    // exclude MarketARI
    // debugger; 

    if (data_sources[0] === 'MarketARI') data_sources.splice(0, 1);

    let collection = {};

    data_sources.map((src) => {
      let { data } = getSeriesById(src);
      collection[src] = data ? data.data : [];
      if (data && data.data && data.data.constructor.name !== 'Array') {
        collection[src] = [];
      }
    });

    if (scopeType === 'Date') {
      let { mainkey } = chartConfig;
      let dbaMainkey = 'MarketTotal';

      mainData.map((item, i) => {
        let new_item = {};
        let date = '';
        if (item.index && item.index.date) {
          date = moment(item.index.date).format('YYYY-MM-DD');
        }

        let marketForecastTmp = getSeriesById('MarketForecast');
        let tmp = [];
        if (marketForecastTmp.data && marketForecastTmp.data.data) {
          tmp = marketForecastTmp.data.data.filter((d) => {
            return d.stay_date === date;
          });
        }
        // let tmp = collection["MarketForecastSegmented"].filter((d) => {
        //   return d.stay_date === date;
        // });

        if (tmp.length) {
          // debugger;
          tmp = tmp[0];
          forecast = {
            Occ: tmp.forecast_occ,
            ADR: tmp.forecast_adr,
            Rooms: tmp.forecast_rooms,
            RevPAR: tmp.forecast_revpar,
            Revenue: tmp.forecast_revenue,
          };
          unconstrained_forecast = {
            Occ: tmp.unconstrained_forecast_occ,
            ADR: tmp.unconstrained_forecast_adr,
            Rooms: tmp.unconstrained_forecast_rooms,
            RevPAR: tmp.unconstrained_forecast_revpar,
            Revenue: tmp.unconstrained_forecast_revenue,
          };
          //if unconstrained option is switched on, forecast would be unconstrained_forecast
          if (unconstrained) {
            forecast = unconstrained_forecast;
          }
        } else {
          if (item[mainkey]) {
            forecast = item[mainkey].primary;
          }
        }
        // debugger
        if (mainData[i][mainkey]) {
          mainData[i][mainkey].market_forecast = forecast;
        }
        // mergedData.push(mainData[i]);
        // debugger; 
      });
      // debugger;
      let marketSegments = { list: [], map: {} };
      if (collection.MarketARISegmented) {
        collection.MarketARISegmented.map((item, i) => {
          let stay_date = item.index ? moment(item.index.date).format("YYYY-MM-DD") : null;

          let forecastSegmented = collection.MarketForecastSegmented.filter((d) => {
            return moment(d.stay_date).format("YYYY-MM-DD") === stay_date;
          });
          let forecastTotal = collection.MarketForecastSegmented.filter((d) => {
            return moment(d.stay_date).format("YYYY-MM-DD") === stay_date;
          });
          // debugger;
          let tobePushed = { index: { date: stay_date }, MarketTotal: { primary: {} } };
          for (let k in item) {
            if (k.indexOf('MarketSegment_') > -1) {
              let marketCode = k.split('MarketSegment_')[1];
              //Insert segment code for market
              let exists = marketSegments.list.filter(item => item.code === marketCode);
              if (!exists.length) {
                marketSegments.list.push({
                  "code": marketCode,
                  "name": marketCode
                });
                marketSegments.map[marketCode] = marketCode;
              }
              tobePushed['Segment_' + marketCode] = deepCopy(item[k]);
            }
          }
          if (!forecastTotal.length) {
            tobePushed.MarketTotal = {
              ...item.MarketTotal,
              market_forecast: {
                ADR: null,
                Occ: null,
                RevPAR: null,
                Revenue: null,
                Rooms: null
              }
            };
          } else {
            let aggregated = aggregateSegmentedGroupedData(forecastTotal, true, 'MarketForecast');
            // debugger;
            let foreCastItem = aggregated; //forecastTotal[0];
            tobePushed.MarketTotal = {
              ...item.MarketTotal,
              market_forecast: {
                ADR: foreCastItem.forecast_adr,
                Occ: foreCastItem.forecast_occ,
                RevPAR: foreCastItem.forecast_revpar,
                Revenue: foreCastItem.forecast_revenue,
                Rooms: foreCastItem.forecast_rooms
              },
            }
            forecastSegmented.map((thisItem, i) => {
              if (tobePushed["Segment_" + thisItem.market_segment]) {
                if (!workspace_controls.unconstrained) {
                  tobePushed["Segment_" + thisItem.market_segment]['market_forecast'] = {
                    ADR: thisItem.forecast_adr,
                    Occ: thisItem.forecast_occ,
                    RevPAR: thisItem.forecast_revpar,
                    Revenue: thisItem.forecast_revenue,
                    Rooms: thisItem.forecast_rooms
                  }
                } else {
                  tobePushed["Segment_" + thisItem.market_segment]['market_forecast'] = {
                    ADR: thisItem.unconstrained_forecast_adr,
                    Occ: thisItem.unconstrained_forecast_occ,
                    RevPAR: thisItem.unconstrained_forecast_revpar,
                    Revenue: thisItem.unconstrained_forecast_revenue,
                    Rooms: thisItem.unconstrained_forecast_rooms
                  }
                }

              }
            });
          }
          // debugger
          mergedData.push(tobePushed);
        });
        // debugger;
        let transformed = transformData({ tile: active_tile, dataSet: mergedData, data_id: "MarketForecastSegmented", segments: marketSegments });
        mergedData = transformed;
      }
    }

    if (scopeType === 'Dba') {
      // debugger 
      let { mainkey } = chartConfig;
      const { dbaStart, dba } = workspace_controls;
      // let dbaMainkey = 'MarketTotal';
      let currDba = dbaStart;

      do {
        console.log(currDba);
        let new_item = {};

        let tmp = collection["MarketDba"].filter((d) => {
          return d.Index.dba === currDba;
        });
        if (tmp.length) {
          tmp = tmp[0];

          new_item.index = tmp.Index ? deepCopy(tmp.Index) : deepCopy(tmp.index);
          new_item.Index = tmp.Index ? deepCopy(tmp.Index) : deepCopy(tmp.index);
          new_item[mainkey] = {};
          new_item[mainkey] = deepCopy(tmp[mainkey]);
          // new_item[mainkey].sdly = deepCopy(tmp[mainkey].sdly);
          mergedData.push(new_item);
          // debugger; 
        }

        currDba++;
      } while (currDba <= dba);
      // debugger;
    }
  }

  //Pace/Booking Curve is
  if (active_tiles.includes('pace')) {
    let { clearComparision } = workspace_controls;
    const { chartConfig, data_sources } = getTileById('pace');
    const { mainkey, identifiers, compareToIdentifiers } = chartConfig;
    let data = dataSet.filter(data => data.data_id === "OSRBookingPaceSegment");
    let DBAData = dataSet.filter(data => data.data_id === "OSRBookingPaceSegmentDBA");
    let segmentedData = dataSet.filter(data => data.data_id === "OSRBookingPaceSegment");
    let segmentedDBAData = dataSet.filter(data => data.data_id === "OSRBookingPaceSegmentDBA");
    data = data.length ? data[0].data : [];
    DBAData = DBAData.length ? DBAData[0].data : [];
    segmentedData = segmentedData.length ? segmentedData[0].data : [];
    segmentedDBAData = segmentedDBAData.length ? segmentedDBAData[0].data : [];
    let finalData = data;
    let finalIdentifiers = clearComparision ? compareToIdentifiers : identifiers;
    let ignoreKeys = ['index'];
    // debugger;
    if (segmented_view) {
      if (scopeType === 'Date') {
        finalData = segmentedData;
        finalData.map(item => {
          let stay_date = item.index.date;
          let dataTotal = data.filter(d => d.index.date === stay_date);
          dataTotal = dataTotal.length ? dataTotal[0] : {};
          let tmp = { index: item.index };
          item[mainkey] = dataTotal[mainkey] ? dataTotal[mainkey] : {};
          for (let key in item) {
            // debugger;
            if (!ignoreKeys.includes(key)) {
              finalIdentifiers.map(id => {
                tmp[key] = tmp[key] ? tmp[key] : {};
                tmp[key][id] = item[key][id];
              });
            }
          }
          mergedData.push(tmp);
        });
        // debugger
        mergedData = transformData({ dataSet: mergedData, tile: 'pace', data_id: "OSRBookingPaceSegment", segments });
        // debugger;
      }
      if (scopeType === 'Dba') {
        finalData = segmentedDBAData;
        finalData.map(item => {
          let dba = item.index.dba;
          let dataTotal = DBAData.filter(d => d.index.dba === dba);
          dataTotal = dataTotal.length ? dataTotal[0] : {};
          let tmp = { index: item.index };
          item[mainkey] = dataTotal[mainkey] ? dataTotal[mainkey] : {};
          for (let key in item) {
            // debugger;
            if (!ignoreKeys.includes(key)) {
              finalIdentifiers.map(id => {
                tmp[key] = tmp[key] ? tmp[key] : {};
                tmp[key][id] = item[key][id];
              });
            }
          }
          //Control 
          if (dba <= workspace_controls.dba) {
            mergedData.push(tmp);
          }

        });
        mergedData = transformData({ dataSet: mergedData, tile: 'pace', data_id: "OSRBookingPaceSegment", segments });
        // debugger;
      }
    }
    if (!segmented_view) {
      if (scopeType === 'Dba') {
        finalData = DBAData;
        finalData.map(item => {
          if (item.index.dba <= dba) {
            let tmp = { index: item.index };
            tmp[mainkey] = {};
            finalIdentifiers.map(id => {
              tmp[mainkey] = tmp[mainkey] ? tmp[mainkey] : {};
              tmp[mainkey][id] = item[mainkey][id];
            });
            mergedData.push(tmp);
          }
        });
      }
      if (scopeType === 'Date') {
        finalData.map(item => {
          let tmp = { index: item.index };
          tmp[mainkey] = {};
          finalIdentifiers.map(id => {
            tmp[mainkey] = tmp[mainkey] ? tmp[mainkey] : {};
            tmp[mainkey][id] = item[mainkey][id];
          });
          mergedData.push(tmp);
        });
      }
    }
  }

  //compset tile
  if (active_tile === 'compset') {
    let { data_sources, chartConfig } = deepCopy(getTileById(active_tile));
    // debugger;
    let dataCopy = deepCopy(dataSet[dataIndex].data);
    if (scopeType === 'Date') {
      // debugger; 
      if (dataCopy.length) {
        let transformed = transformData({ tile: active_tile, pickUpType: workspace_controls.pickUpType, dataSet: dataCopy, data_id: "Compset" });
        mergedData = transformed;
      }
    } else {
      if (dataCopy.length) {
        let transformed = transformData({ tile: active_tile, pickUpType: workspace_controls.pickUpType, dataSet: dataCopy, data_id: "CompsetBookingPace" });
        mergedData = transformed;
      }
    }
  }

  //market_sell_rates tile
  if (active_tile === 'market_sell_rates') {
    let { data_sources, chartConfig } = deepCopy(getTileById(active_tile));
    let dataCopy = deepCopy(dataSet[dataIndex].data);
    if (dataCopy.length) {
      let transformed = transformData({ range: primary_stay_range, tile: active_tile, dataSet: dataCopy, data_id: "MarketSellRate" });
      mergedData = transformed;
    }
  }

  //return mainData if mergedData could not be prepared
  if (dataSetSize && !mergedData.length) {
    return mainData;
  }
  return mergedData;
};

const getMergedDataIntellimerge = (dataSet, params = {}) => {
  // debugger;
  if (!(dataSet && dataSet[0] && dataSet[0].data)) {
    return [];
  }
  let { workspace_controls, primary_stay_range, room_settings } = params.app_state;
  let { pickUpType } = workspace_controls;
  let { date_from, date_until } = primary_stay_range;
  let { active_tiles } = params.ui_state;
  let { dba, sdlyOn, compareTo, clearComparision, scopeType, unconstrained, segmented_view } = params.app_state.workspace_controls;
  let { segments, restrictionsAvail } = params;
  let active_tile = active_tiles[0];
  let finalDataSources = [];
  let finalDataSet = [];
  let mergedTilesData = {};
  let stayDates = getDatesInRange(date_from, date_until);
  let dbas = workspace_controls.dba;
  //Collect data sources of tiles
  // debugger;

  //---START : Collecting unique data sources
  active_tiles.map((tile) => {
    // debugger;
    let { data_sources, chartConfig } = getTileById(tile);
    for (let i = 0; i < data_sources.length; i++) {
      if (!finalDataSources.includes(data_sources[i])) {
        let finalData = dataSet.filter((data) => (data.data_id === data_sources[i]));
        if (finalData && finalData.length) {
          finalDataSet.push(finalData[0]);
          finalDataSources.push(data_sources[i]);
        }
      }
    }
  });
  //---END
  // debugger;
  //---START : colllecting transformed data for active tiles
  let keys = [
    ['current', 'primary', 'pickup', 'pickup_diff', 'prior', 'sdly', 'sdly_actual', 'sdly_pickup'],
    ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms', 'SellRate']
  ];
  const pricingData = deepCopy(finalDataSet[finalDataSources.indexOf("OSRRateRecommend")])

  active_tiles.map((tile) => {
    let dataKeys = {
      "ADR": 0,
      "Occ": 0,
      "RevPAR": 0,
      "Revenue": 0,
      "Rooms": 0,
      "SellRate": 0
    };
    let { data_sources, chartConfig } = deepCopy(getTileById(tile));
    let { mainkey } = chartConfig;
    let rawTileData = [];
    let ingonreKeys = ['index', 'order', 'segmentTotal', 'segmented'];
    let osrData = deepCopy(finalDataSet[finalDataSources.indexOf('OSR')]);
    osrData = osrData && osrData.data ? osrData.data : [];
    // debugger;
    let osrSegmentedData = deepCopy(finalDataSet[finalDataSources.indexOf('OSR')]);
    osrSegmentedData = osrSegmentedData && osrSegmentedData.data ? osrSegmentedData.data : [];
    osrSegmentedData.map((item) => {
      item.index.date = new moment(item.index.date).format("YYYY-MM-DD");
    });
    let marketData = deepCopy(finalDataSet[finalDataSources.indexOf('MarketARI')]);
    marketData = marketData && marketData.data ? marketData.data : [];

    let marketSegmentedData = deepCopy(finalDataSet[finalDataSources.indexOf('MarketARISegmented')]);
    marketSegmentedData = marketSegmentedData && marketSegmentedData.data ? marketSegmentedData.data : [];

    if (tile === 'otb' || tile === 'pickup') {
      //Added for market segmented view intelimerge with otb/pickup
      let osr_data_id = active_tiles.includes("market") ? "marketOtbSegment" : "OSR";
      if (segmented_view && chartConfig.controlSupport.segmented_view) {
        let tileData = deepCopy(finalDataSet[finalDataSources.indexOf(osr_data_id)]);
        rawTileData = getMergedData2([tileData], { ...params, tile });
      } else {
        let tileData = deepCopy(finalDataSet[finalDataSources.indexOf(osr_data_id)]);
        rawTileData = getMergedData2([tileData], { ...params, tile });
      }

    }
    if (tile === 'pricing') {

      rawTileData = getMergedData2([pricingData], { ...params, primary_stay_range, tile });
      console.log('pricing')
    }

    if (tile === 'forecast') {
      //Added for market segmented view intelimerge with otb/pickup
      let forecast_data_id = active_tiles.includes("market") ? "ForecastMarketSegment" : "OSRContrainedForecast";

      let tileData = deepCopy(finalDataSet[finalDataSources.indexOf(forecast_data_id)]);
      
      rawTileData = getMergedData2([tileData], { ...params, tile, active_tiles });
    }

    if (tile === 'market') {

      if (segmented_view && chartConfig.controlSupport.segmented_view) {
        let tileData = deepCopy(finalDataSet[finalDataSources.indexOf('MarketARISegmented')]);
        rawTileData = getMergedData2([tileData], { ...params, tile });

      } else if (scopeType === 'Dba' && chartConfig.controlSupport.scopeType) {
        let tileData = deepCopy(finalDataSet[finalDataSources.indexOf('MarketDba')]);
        rawTileData = getMergedData2([tileData], { ...params, tile });

      } else {
        let tileData = deepCopy(finalDataSet[finalDataSources.indexOf('MarketARISegmented')]);
        rawTileData = getMergedData2([tileData], { ...params, tile });
      }

    }
    if (tile === 'compset') {
      // debugger;
      if (!segmented_view) {
        if (scopeType === 'Date') {
          let tileData = deepCopy(finalDataSet[finalDataSources.indexOf('Compset')]);
          rawTileData = getMergedData2([tileData], { ...params, tile });
        }
        if (scopeType === 'Dba') {
          let tileData = deepCopy(finalDataSet[finalDataSources.indexOf('CompsetBookingPace')]);
          rawTileData = getMergedData2([tileData], { ...params, tile });
        }
      } else {
        if (scopeType === 'Date') {
          let tileData = deepCopy(finalDataSet[finalDataSources.indexOf('Compset')]);
          rawTileData = getMergedData2([tileData], { ...params, tile });
        }
      }
    }
    if (tile === 'market_sell_rates') {
      // debugger;  
      let tileData = deepCopy(finalDataSet[finalDataSources.indexOf('MarketSellRate')]);
      rawTileData = getMergedData2([tileData], { ...params, tile });
    }
    if (tile === 'pace') {
      // debugger; 
      keys = [
        ['primary', 'comparison'],
        ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms']
      ];
      dataKeys = {
        "ADR": 0,
        "Occ": 0,
        "RevPAR": 0,
        "Revenue": 0,
        "Rooms": 0,
      };
      rawTileData = getMergedData2(finalDataSet, { ...params, tile });
    }

    // rawTileData = rawTileData && rawTileData.length ? rawTileData : [];

    // debugger;
    let tileData = [];
    if (scopeType === 'Dba' && chartConfig.controlSupport.scopeType) {
      tileData = rawTileData;
    }
    if (scopeType === 'Date') {
      stayDates.map((date) => {
        let tobePushed = {
          index: { date: date, timestamp: moment(date).format('x') },
        }
        if (tile === 'otb') {
          let item = rawTileData.filter((data) => moment(data.index.date).format("YYYY-MM-DD") === date);
          if (item && item.length) {
            let data = item[0];
            for (let mkey in data) {
              if (!ingonreKeys.includes(mkey)) {
                tobePushed[mkey] = {
                  primary: { ...dataKeys, ...data[mkey].primary },
                  current: { ...dataKeys, ...data[mkey].current },
                  sdly: { ...dataKeys, ...data[mkey].sdly },
                  sdly_actual: { ...dataKeys, ...data[mkey].sdly_actual },
                };
              }
              if (mkey === 'segmented') {
                // debugger;
                tobePushed[mkey] = {};
                for (let skey in data[mkey]) {
                  tobePushed[mkey][skey] = {
                    primary: { ...dataKeys, ...data[mkey][skey].primary },
                    current: { ...dataKeys, ...data[mkey][skey].current },
                    sdly: { ...dataKeys, ...data[mkey][skey].sdly },
                    sdly_actual: { ...dataKeys, ...data[mkey][skey].sdly_actual },
                  };
                }
                tobePushed[mkey] = data[mkey];
              } else {
                tobePushed[mkey] = data[mkey];
              }
            };
          } else {
            tobePushed[mainkey] = {
              primary: { ...dataKeys },
              current: { ...dataKeys },
              sdly: { ...dataKeys },
              sdly_actual: { ...dataKeys },
            };
          }
        }

        if (tile === 'pickup') {
          let item = rawTileData.filter((data) => moment(data.index.date).format("YYYY-MM-DD") === date);
          if (item && item.length) {
            let data = item[0];
            for (let mkey in data) {
              if (!ingonreKeys.includes(mkey)) {
                let pickup_diff = {};
                let sdly_pickup_diff = {};
                let sdtly_pickup_diff = {};
                data[mkey]["pickup_diff"] = {};
                if (pickUpType === 'Latest') {
                  keys[1].map(key => {
                    pickup_diff[key] = Math.round(data[mkey].primary[key] * 10000) - Math.round(data[mkey].pickup[key] * 10000);
                    pickup_diff[key] = pickup_diff[key] / 10000;
                    
                    sdly_pickup_diff[key] = Math.round(data[mkey].sdly[key] * 10000) - Math.round(data[mkey].sdly_pickup[key] * 10000);
                    sdly_pickup_diff[key] = sdly_pickup_diff[key] / 10000;
                    
                   // sdtly_pickup_diff[key] = Math.round(data[mkey].sdtly[key] * 10000) - Math.round(data[mkey].sdtly_pickup[key] * 10000);
                    //sdtly_pickup_diff[key] = sdtly_pickup_diff[key] / 10000;
                  });
                } else {
                  keys[1].map(key => {
                    pickup_diff[key] = Math.round(data[mkey].current[key] * 10000) - Math.round(data[mkey].prior[key] * 10000);
                    pickup_diff[key] = pickup_diff[key] / 10000;
                    sdly_pickup_diff[key] = Math.round(data[mkey].sdly[key] * 10000) - Math.round(data[mkey].sdly_pickup[key] * 10000);
                    sdly_pickup_diff[key] = sdly_pickup_diff[key] / 10000;

                    // LRV-880
                    // sdtly_pickup_diff[key] = Math.round(data[mkey].sdtly[key] * 10000) - Math.round(data[mkey].sdtly_pickup[key] * 10000);
                    // sdtly_pickup_diff[key] = sdtly_pickup_diff[key] / 10000;
                  });
                }
                //Handle ADR
                data[mkey].pickup_diff = deepCopy(pickup_diff);
                data[mkey].pickup_diff.ADR = pickup_diff.Rooms !== 0 ? Math.round(pickup_diff.Revenue / pickup_diff.Rooms) : 0;

                data[mkey].sdly_pickup_diff = deepCopy(sdly_pickup_diff);
                // data[mkey].sdtly_pickup_diff = deepCopy(sdtly_pickup_diff);
                
                data[mkey].sdly_pickup_diff.ADR = sdly_pickup_diff.Rooms !== 0 ? Math.round(sdly_pickup_diff.Revenue / sdly_pickup_diff.Rooms) : 0;
                // data[mkey].sdtly_pickup_diff.ADR = sdtly_pickup_diff.Rooms !== 0 ? Math.round(sdtly_pickup_diff.Revenue / sdtly_pickup_diff.Rooms) : 0;

                tobePushed[mkey] = data[mkey];
              } else {

              }
              if (mkey === 'segmented') {
                // tobePushed[mkey] = {};
                // debugger;
                for (let skey in data[mkey]) {
                  let pickup_diff = {};
                  let sdly_pickup_diff = {};
                  let sdtly_pickup_diff = {};
                  // data[mkey]["pickup_diff"] = {};
                  if (pickUpType === 'Latest') {
                    keys[1].map(key => {
                      pickup_diff[key] = Math.round(data[mkey][skey].primary[key] * 10000) - Math.round(data[mkey][skey].pickup[key] * 10000);
                      pickup_diff[key] = pickup_diff[key] / 10000;
                      sdly_pickup_diff[key] = Math.round(data[mkey][skey].sdly[key] * 10000) - Math.round(data[mkey][skey].sdly_pickup[key] * 10000);
                      sdly_pickup_diff[key] = sdly_pickup_diff[key] / 10000;

                      // sdtly_pickup_diff[key] = Math.round(data[mkey][skey].sdtly[key] * 10000) - Math.round(data[mkey][skey].sdtly_pickup[key] * 10000);
                      // sdtly_pickup_diff[key] = sdtly_pickup_diff[key] / 10000;
                    });
                  } else {
                    keys[1].map(key => {
                      pickup_diff[key] = Math.round(data[mkey][skey].current[key] * 10000) - Math.round(data[mkey][skey].prior[key] * 10000);
                      pickup_diff[key] = pickup_diff[key] / 10000;
                      sdly_pickup_diff[key] = Math.round(data[mkey][skey].sdly[key] * 10000) - Math.round(data[mkey][skey].sdly_pickup[key] * 10000);
                      sdly_pickup_diff[key] = sdly_pickup_diff[key] / 10000;

                     // sdtly_pickup_diff[key] = Math.round(data[mkey][skey].sdtly[key] * 10000) - Math.round(data[mkey][skey].sdtly_pickup[key] * 10000);
                     // sdtly_pickup_diff[key] = sdtly_pickup_diff[key] / 10000;
                    });
                  }
                  //Handle ADR
                  data[mkey][skey].pickup_diff = deepCopy(pickup_diff);
                  data[mkey][skey].pickup_diff.ADR = pickup_diff.Rooms !== 0 ? Math.round(pickup_diff.Revenue / pickup_diff.Rooms) : 0;
                  data[mkey][skey].sdly_pickup_diff = deepCopy(sdly_pickup_diff);
                  data[mkey][skey].sdly_pickup_diff.ADR = sdly_pickup_diff.Rooms !== 0 ? Math.round(sdly_pickup_diff.Revenue / sdly_pickup_diff.Rooms) : 0;
                  
                  //data[mkey][skey].sdtly_pickup_diff = deepCopy(sdtly_pickup_diff);
                  //data[mkey][skey].sdtly_pickup_diff.ADR = sdtly_pickup_diff.Rooms !== 0 ? Math.round(sdtly_pickup_diff.Revenue / sdtly_pickup_diff.Rooms) : 0;
                }
                tobePushed[mkey] = data[mkey];
              } else {
                tobePushed[mkey] = data[mkey];
              }
            };
          } else {
            tobePushed[mainkey] = {
              primary: { ...dataKeys },
              pickup: { ...dataKeys },
              prior: { ...dataKeys },
              current: { ...dataKeys },
              sdly: { ...dataKeys },
              sdly_actual: { ...dataKeys },
            };
          }
        }
        if (tile === 'pricing') {
          //tobePushed[mainkey]
          const item = rawTileData.filter((data) => moment(data.index.date).format("YYYY-MM-DD") === date)[0];
          tobePushed[mainkey] = item.sets;
        }
        if (tile === 'forecast') {
          let otbData = segmented_view ? osrSegmentedData : osrData;
          let otbItem = otbData.filter((data) => moment(data.index.date).format("YYYY-MM-DD") === date);
          let item = rawTileData.filter((data) => moment(data.stay_date).format("YYYY-MM-DD") === date);
          if(active_tiles.includes('market') && segmented_view){
            item = rawTileData.filter((data) => moment(data.index.date).format("YYYY-MM-DD") === date);
          }
          // debugger;

          if (item && item.length) {
            let temps = item[0];
            tobePushed[mainkey] = {
              primary: {
                ADR: temps.predicted_total_cons_adr,
                Occ: temps.predicted_total_cons_occ,
                RevPAR: temps.predicted_total_cons_revpar,
                Revenue: temps.predicted_total_cons_revenue,
                Rooms: temps.predicted_total_cons_rooms,
                SellRate: null
              }
            };
          } else if (otbItem && otbItem.length) {
            let temps = otbItem[0];
            tobePushed[mainkey] = {
              primary: { ...dataKeys, ...temps[mainkey].primary }
            };
          } else {
            tobePushed[mainkey] = {
              primary: { ...dataKeys },
            };
          }
          //in case of segmented view pls add more data
          if (segmented_view) {
            //if forecast data exists for this date
            if (item && item.length) {
              for (let i = 0; i < item.length; i++) {
                let temps = item[i];
                let segmentKey = "Segment_" + item[i].market_code;
                let kpiKey = "predicted_cons_";
                tobePushed[segmentKey] = {
                  primary: {
                    ADR: temps[kpiKey + "adr"],
                    Occ: temps[kpiKey + "occ"],
                    RevPAR: temps[kpiKey + "revpar"],
                    Revenue: temps[kpiKey + "revenue"],
                    Rooms: temps[kpiKey + "rooms"],
                    SellRate: null
                  }
                };
              }
            } else if (otbItem && otbItem.length) {
              let data = otbItem[0];
              for (let key in data) {
                if (key !== 'index') {
                  tobePushed[key] = {
                    primary: { ...dataKeys, ...data[key].primary },
                  };
                }
              };
            }
          }
        }

        if (tile === 'market') {
          // debugger
          // marketData = segmented_view ? marketSegmentedData : marketData;
          // let marketItem = marketData.filter((data) => moment(data.index.date).format("YYYY-MM-DD") === date);
          let item = rawTileData.filter((data) => moment(data.index.date).format("YYYY-MM-DD") === date);
          // debugger;
          item = item.length ? item[0] : {};
          // tobePushed[mainkey] = item[mainkey];
          tobePushed = item;
        }

        if (tile === 'pace') {
          // debugger
          let item = rawTileData.filter((data) => moment(data.index.date).format("YYYY-MM-DD") === date);
          if (item.length) {
            tobePushed = item[0];
          }
        }
        if (tile === 'compset') {
          // debugger
          let item = rawTileData.filter((data) => moment(data.index.date).format("YYYY-MM-DD") === date);
          if (item.length) {
            tobePushed = item[0];
          }
        }
        if (tile === 'market_sell_rates') {
          // debugger
          let item = rawTileData.filter((data) => moment(data.index.date).format("YYYY-MM-DD") === date);
          if (item.length) {
            tobePushed = item[0];
          }
        }

        tileData.push(tobePushed);
      });
    }
    // debugger;
    mergedTilesData[tile + "_aggregated"] = tileData;

    if (tile === 'forecast' && segmented_view) {
      mergedTilesData[tile + "_aggregated"] = rawTileData;
      // transformData({ dataSet: tileData, data_id: "OSRSegment", segments });
      // debugger;
    }

    // mergedTilesData[tile + "_aggregated"] = tileData;
  });
  // debugger;
  //---END
  //return mainData if mergedData could not be prepared
  // if (dataSetSize && !mergedData.length) {
  //   return mainData;
  // }
  return mergedTilesData;
};

export { transformData, getMergedData, getMergedDataIntellimerge, getRestrictionsDisplay };