import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import { deepCopy, getMarketSegment, getSeriesById, in_array } from '../../app/util';
import dataService from '../../services/data.service';
import { getRoomClassCategory } from './tilesData/otb_multisegment_report_functions';
import { Button, Divider } from '@mui/material';
import { Box } from '@mui/system';
import { updateAppState } from '../../actions/appstate';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SearchFilter(props) {
    const dispatch = useDispatch();
    const state = useSelector(appState => appState);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );


    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        let cols = [];
        let activeTile = ui_state.active_tiles[0];
        if (props.details.type === "segment") {
            let segmentArr = [];
            switch (activeTile) {
                case "market":
                    segmentArr = getMarketSegment(ui_state.active_tiles);
                    if (ui_state.table_filters.table_reports.segments !== "all") {
                        setSelectedOptions(ui_state.table_filters.table_common.marketSegments);
                    }
                    break;
                case "otb_v2_multi_segment":
                case "bi_sensitivity_analysis":
                    let segmentType = app_state.workspace_controls.segmentType;
                    if (segmentType === "top_market_segment") {
                        segmentArr = getTopSegments();
                        if (ui_state.table_filters.table_reports[segmentType] !== "all") {
                            setSelectedOptions(ui_state.table_filters.table_reports[segmentType]);
                        }
                    } else if (segmentType === "room_class") {
                        segmentArr = getRoomClass();
                        if (ui_state.table_filters.table_reports[segmentType] !== "all") {
                            setSelectedOptions(ui_state.table_filters.table_reports[segmentType]);
                        }
                    } else if (segmentType === "rate_plan") {
                        segmentArr = getRatePlan();
                        if (ui_state.table_filters.table_reports[segmentType] !== "all") {
                            setSelectedOptions(ui_state.table_filters.table_reports[segmentType]);
                        }
                    } else if (segmentType === "room_type") {
                        segmentArr = getRoomType();
                        if (ui_state.table_filters.table_reports[segmentType] !== "all") {
                            setSelectedOptions(ui_state.table_filters.table_reports[segmentType]);
                        }
                    } else {
                        segmentArr = props.details.segments.list
                        segmentArr = segmentArr.map(v => ({ ...v, hidden: false, title: v.name, type: "reports_segments" }))
                        if (ui_state.table_filters.table_reports[segmentType] !== "all") {
                            setSelectedOptions(ui_state.table_filters.table_reports[segmentType]);
                        }
                        // debugger;
                    }

                    break;
                default:
                    segmentArr = props.details.segments.list
                    // segmentArr = segmentArr.map(v => ({ ...v, hidden: false, title: v.name, type: "property_segments" }))
                    segmentArr = segmentArr.map(v =>
                        v.name === 'Other'
                            ? { ...v, code: 'Other', hidden: false, title: v.name, type: "property_segments" }
                            : { ...v, hidden: false, title: v.name, type: "property_segments" }
                    );
                    if (ui_state.table_filters.table_common.dow[0].segments !== "all") {
                        setSelectedOptions(ui_state.table_filters.table_common.segments);
                    }

                    break;
            }
            if (ui_state.active_tiles.length > 1 && ui_state.active_tiles.includes('market')) {
                setSelectedOptions(ui_state.table_filters.table_common.marketIntellimergeSegments);
                segmentArr = getMarketSegment(ui_state.active_tiles);
            }

            cols = segmentArr.filter((obj, index) => {
                return index === segmentArr.findIndex(o => obj.name === o.name);
            });
        }
        else {
            let dowType = (in_array(ui_state.active_tiles, ['otb_v2_multi_segment', 'bi_pace_comparison',
                'bi_pickup_analysis', 'revpar_index', 'bi_sensitivity_analysis', 'DailyRatesendStatus', 'rm_room_type_ratesend_status'])) ?
                "report_dow" : "dow";
            cols = [
                { code: "sun", hidden: false, title: "Sun", type: dowType },
                { code: "mon", hidden: false, title: "Mon", type: dowType },
                { code: "tue", hidden: false, title: "Tue", type: dowType },
                { code: "wed", hidden: false, title: "Wed", type: dowType },
                { code: "thu", hidden: false, title: "Thu", type: dowType },
                { code: "fri", hidden: false, title: "Fri", type: dowType },
                { code: "sat", hidden: false, title: "Sat", type: dowType }
            ]
            if (dowType === "dow" && ui_state.table_filters.table_common.dow[0].code !== "all") {
                setSelectedOptions(ui_state.table_filters.table_common.dow);
            } else {
                setSelectedOptions(ui_state.table_filters.table_reports.dow);
            }
        }

        setOptions(cols);
    }, [])

    // const handleChange = (event, value) => setSelectedOptions(value);
    const handleChange = (event, value) => {
        let eventValue = ""
        if (event.target.matches("input")) {
            eventValue = event.target.closest("li").innerText;
        }
        else if (event.target.matches("path") || event.target.matches("svg")) {
            eventValue = event.target.closest("div").innerText;
        } else {
            eventValue = event.target.innerText;
        }

        const duplicateValue = value.reduce((accumulator, currentValue) => {
            if (currentValue.title.toLowerCase() === eventValue.toLowerCase()) {
                accumulator++;
            }
            return accumulator;
        }, 0);

        setSelectedOptions(() => {
            if (duplicateValue === 1) {
                return value
            } else {
                return value.filter((item) => {
                    if (item.title.toLowerCase() !== eventValue.toLowerCase()) {
                        return item
                    }
                })
            }
        })
    };
    const handleSubmit = () => {
        let selectedVal = [];
        let segmentType = app_state.workspace_controls.segmentType;
        if (selectedOptions[0].type === "property_segments") {
            ui_state.table_filters.table_common.segments = selectedOptions;
        } else if (selectedOptions[0].type === "market_segments") {
            if (ui_state.active_tiles.length > 1 && ui_state.active_tiles.includes('market')) {
                ui_state.table_filters.table_common.marketIntellimergeSegments = selectedOptions
            } else {
                ui_state.table_filters.table_common.marketSegments = selectedOptions
            }

        } else if (selectedOptions[0].type === "reports_segments") {
            ui_state.table_filters.table_reports[segmentType] = selectedOptions
        } else if (selectedOptions[0].type === "top_segments") {
            ui_state.table_filters.table_reports[segmentType] = selectedOptions
        } else if (selectedOptions[0].type === "room_class") {
            ui_state.table_filters.table_reports[segmentType] = selectedOptions
        } else if (selectedOptions[0].type === "room_type") {
            ui_state.table_filters.table_reports[segmentType] = selectedOptions
        } else if (selectedOptions[0].type === "rate_plan") {
            if (selectedOptions.length === options.length) {
                ui_state.table_filters.table_reports.rate_plan_filter_all_selected = true
            } else {
                ui_state.table_filters.table_reports.rate_plan_filter_all_selected = false
            }
            ui_state.table_filters.table_reports[segmentType] = selectedOptions
        } else if (selectedOptions[0].type === "report_dow") {
            ui_state.table_filters.table_reports.dow = selectedOptions
        } else {
            ui_state.table_filters.table_common.dow = selectedOptions
        }
        dispatch(updateAppState("change_filter", {
            //app_state: { data_load: true },
            ui_state: {
                ...ui_state,
            }
        }, "search"));
        let selectedSegments = selectedOptions.map((item) => item.name);
        selectedSegments = [...new Set(selectedSegments)];
        props.details.applyFilterHandler(selectedSegments);
        console.log("updated global state =>> ", ui_state.table_filters);
    }

    const handleSelectAll = () => {
        setSelectedOptions(options)
    }

    const handleClearAll = () => {
        setSelectedOptions([])
        setSelectedLetter(null)
        setSearchQuery('')
    }

    const handleChangeDow = (option) => {
        const optionIndex = selectedOptions.findIndex((item) => item.title === option.title);
        if (optionIndex === -1) {
            // If the option is not in the selectedOptions array, add it.
            setSelectedOptions([...selectedOptions, option]);
        } else {
            // If the option is already in the selectedOptions array, remove it.
            setSelectedOptions(selectedOptions.filter((item) => item.title !== option.title));
        }
    };

    const getFullDay = (day) => {
        if (day === 'Sun') {
            return "Sunday";
        }
        else if (day === 'Mon') {
            return "Monday";
        }
        else if (day === 'Tue') {
            return "Tuesday";
        }
        else if (day === 'Wed') {
            return "Wednesday";
        }
        else if (day === 'Thu') {
            return "Thursday";
        }
        else if (day === 'Fri') {
            return "Friday";
        }
        else if (day === 'Sat') {
            return "Saturday";
        }
    }

    //console.log("selectedOptions", selectedOptions)

    const [isLetter, setIsLetter] = React.useState(true);

    const allLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const allNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    const letterToNumber = () => {
        if (isLetter) {
            setIsLetter(false);
            setSelectedLetter(null);
        }
        else {
            setIsLetter(true);
            setSelectedLetter(null);
        }
    };


    const [selectedLetter, setSelectedLetter] = React.useState(null);
    const handleLetterFilter = (letter) => {
        if (selectedLetter === letter) {
            setSelectedLetter(null);
        }
        else {
            setSelectedLetter(letter);
        }
    };


    const [searchQuery, setSearchQuery] = React.useState('');

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    // let filteredOptions = options.filter((option) =>
    //     option.title.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    let filteredOptions = options
        .filter((option) => {
            const isMatchingLetter = selectedLetter === null || option.title[0].toLowerCase() === selectedLetter.toLowerCase();
            return isMatchingLetter && option.title.toLowerCase().includes(searchQuery.toLowerCase());
        })
        .sort((a, b) => {
            const aChecked = selectedOptions.some((item) => item.title === a.title);
            const bChecked = selectedOptions.some((item) => item.title === b.title);

            if (aChecked && !bChecked) {
                return -1; // 'a' should come before 'b'
            } else if (!aChecked && bChecked) {
                return 1; // 'b' should come before 'a'
            }

            return 0; // Keep the same order
        });
    //end filteredOptions

    // if (isLetter) {
    //     const nonNumericOptions = filteredOptions.filter(option => !/\d/.test(option.title));
    //     filteredOptions = nonNumericOptions
    // }
    if (!isLetter) {
        const numericOptions = filteredOptions.filter(option => /\d/.test(option.title));
        filteredOptions = numericOptions
    }

    console.log("Selected option => ", selectedOptions);
    console.log("filteredOptions =>>", filteredOptions);

    return (
        <>

            {props.details.type === "segment"

                ?
                // <Autocomplete
                //     multiple
                //     size="small"
                //     id="checkboxes-tags-demo"
                //     options={options}
                //     disableCloseOnSelect
                //     // disableListWrap
                //     // disablePortal={true}
                //     // defaultValue={selectedOptions}
                //     value={selectedOptions}
                //     ListboxProps={{ style: { maxHeight: 200 } }}
                //     onChange={handleChange}
                //     getOptionLabel={(option) => option.title}
                //     renderOption={(props, option, ref) => {
                //         let exists = selectedOptions.filter((item) => option.title === item.title);
                //         // let existsGlobal = seg.filter((item) => option.title === item.title);
                //         // debugger;
                //         return (
                //             <li {...props}>
                //                 <Checkbox
                //                     icon={icon}
                //                     checkedIcon={checkedIcon}
                //                     style={{ marginRight: 8 }}
                //                     checked={(exists.length) ? true : false}
                //                 />
                //                 {option.title}
                //             </li>
                //         )
                //     }}
                //     style={{ width: 280 }}

                //     renderInput={(params) => (
                //         <>
                //             <TextField {...params} label={(props.label)} hiddenLabel size="small" placeholder={"Search " + props.label} />
                //         </>
                //     )}
                // />
                <div className='dow-filter-popup'>

                    <div className='dow-filter-search'>
                        <TextField
                            fullWidth
                            placeholder="Search"
                            onChange={handleSearch}
                            value={searchQuery}
                            InputProps={{
                                startAdornment: (
                                    <div style={{ marginRight: '8px', marginTop: '5px' }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            fill="#606060"
                                            viewBox="0 0 256 256"
                                        >
                                            <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                                        </svg>
                                    </div>
                                ),
                            }}
                        />
                    </div>

                    <div className='dow-filter-letter'>
                        <span onClick={letterToNumber}>#</span>
                        {
                            isLetter ?
                                allLetters.map((letter) => (
                                    <span
                                        key={letter}
                                        onClick={() => handleLetterFilter(letter)}
                                        className={selectedLetter === letter ? 'selected-letter' : ''}
                                    >
                                        {letter}
                                    </span>
                                ))
                                :
                                allNumbers.map((letter) => (
                                    <span
                                        key={letter}
                                        onClick={() => handleLetterFilter(letter)}
                                        className={selectedLetter === letter ? 'selected-letter' : ''}
                                    >
                                        {letter}
                                    </span>
                                ))
                        }

                    </div>

                    <div style={{ maxHeight: "20vh", overflowY: "auto", display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                        {filteredOptions.map((option) => (
                            <div className='dow-filter-row' key={option.title}>
                                <label>
                                    <Checkbox
                                        className='dow-filter-checkbox'
                                        //value={selectedOptions}
                                        checked={selectedOptions.some((item) => item.title === option.title)}
                                        onChange={() => handleChangeDow(option)}
                                    //onChange={handleChange}
                                    />
                                    <span className={`dow-filter-title ${selectedOptions.some((item) => item.title === option.title) ? 'active' : ''}`}>
                                        {option.title}
                                    </span>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                :

                <div className='dow-filter-popup'>
                    {options.map((option) => (
                        <div className='dow-filter-row' key={option.title}>
                            <label>
                                <Checkbox
                                    className='dow-filter-checkbox'
                                    //value={selectedOptions}
                                    checked={selectedOptions.some((item) => item.title === option.title)}
                                    onChange={() => handleChangeDow(option)}
                                //onChange={handleChange}
                                />
                                <span className={`dow-filter-title ${selectedOptions.some((item) => item.title === option.title) ? 'active' : ''}`}>
                                    {getFullDay(option.title)}
                                </span>
                            </label>
                        </div>
                    ))}
                </div>

            }



            {/* <Divider /> */}

            <Box sx={{ mt: 1, p: 1, display: "flex", justifyContent: "space-between", isolation: "isolate", boxShadow: " 0 4px 0.75rem rgba(12,14,28,0.3)", borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}>
                <Box>
                    <Button
                        disabled={selectedOptions.length === options.length}
                        className='filter-button secondary'
                        variant="outlined" sx={{ mr: 0.5 }}
                        size="small"
                        onClick={handleSelectAll}
                    >
                        Select
                    </Button>
                    {props.details.type === "segment" ?
                        <Button
                            disabled={selectedOptions.length < 1}
                            className='filter-button secondary'
                            variant="outlined" sx={{ ml: 1 }}
                            size="small"
                            onClick={handleClearAll}
                        >
                            Clear
                        </Button>
                        : ""}
                </Box>
                <Box>
                    <Button
                        disabled={selectedOptions.length < 1}
                        className='filter-button primary'
                        variant="outlined"
                        size="small"
                        onClick={handleSubmit}
                    >
                        Apply
                    </Button>
                </Box>
            </Box>
            {/* <Button onClick={handleSubmit} variant="outlined" sx={{ position: "absolute", right: 0, m: .1 }} size="medium">Apply</Button> */}

        </>
    );

}

/** function to get the Array object of top segments  */
export const getTopSegments = () => {
    let topSegmentsList = ["Transient", "Group", "Contract"];
    let topSegments = [];
    topSegmentsList.map((item, index) => {
        let obj = {};
        obj.code = item;
        obj.name = item;
        obj.title = item;
        obj.hidden = false;
        obj.type = "top_segments";
        topSegments.push(obj);
    })
    return topSegments;
}


/** Function to get the Array Object of  room class */
export const getRoomClass = () => {
    let data = (dataService.getSeriesById("GetRoomClass").data) ? deepCopy(dataService.getSeriesById("GetRoomClass").data.data) : [];
    let result = [...getRoomClassCategory(data)];
    let finalArray = [];
    result.map((item, index) => {
        let className = item.replace("roomclass_", "");
        let obj = {};
        obj.code = className;
        obj.name = className;
        obj.title = className;
        obj.hidden = false;
        obj.type = "room_class";
        finalArray.push(obj);
    })
    return finalArray;
}


function getMaxKeysCountWithIndex(objectsArray) {
    let maxKeysCount = 0;
    let maxKeysIndex = -1;

    objectsArray.forEach((obj, index) => {
        const keysCount = Object.keys(obj).length;
        if (keysCount > maxKeysCount) {
            maxKeysCount = keysCount;
            maxKeysIndex = index;
        }
    });

    return { maxKeysCount, maxKeysIndex };
}

const getAllRatePlan =(data) =>{
    let ratePlansArr = [];
    data.forEach((element, index) => {
        let segmentCodesArr = Object.keys(data[index]);
        segmentCodesArr = segmentCodesArr.filter(element => element !== 'index' && element !== 'HotelTotal');
        console.log(index," =>  ",segmentCodesArr);
        segmentCodesArr.map((code)=>{
            if(!ratePlansArr.includes(code)){
                ratePlansArr.push(code);
            }
        })
    });
    return ratePlansArr;
}

/** Function to get the Array Object for RatePlan  */
export const getRatePlan = () => {
    let data = (dataService.getSeriesById("GetRatePlanNoMissingDays").data) ? deepCopy(dataService.getSeriesById("GetRatePlanNoMissingDays").data.data) : [];
    let segmentCodes = [];
    let dataRequest = getMaxKeysCountWithIndex(data);
    let maxKeysIndex = (dataRequest && dataRequest.maxKeysIndex) ? dataRequest.maxKeysIndex : 0
    getAllRatePlan(data);
    if (data && data.length) {
        // segmentCodes = Object.keys(data[maxKeysIndex]);
        // segmentCodes.sort().push(segmentCodes.shift());
        segmentCodes = getAllRatePlan(data);
        segmentCodes.sort();
    }
    let result = [...segmentCodes];
    let finalArray = [];
    result.map((item, index) => {
        let className = item.replace("Segment_", "");
        let obj = {};
        if (!in_array([className], ["HotelTotal", "index"])) {
            obj.name = className;
            obj.code = className;
            obj.title = className;
            obj.hidden = false;
            obj.type = "rate_plan";
            finalArray.push(obj);
        }
    })
    return finalArray;
}


/** Function to get the Array Object for Room Type  */
export const getRoomType = () => {
    let data = (dataService.getSeriesById("GetRoomType").data) ? deepCopy(dataService.getSeriesById("GetRoomType").data.data) : [];
    let segmentCodes = [];
    if (data && data.length) {
        segmentCodes = Object.keys(data[0]);
        segmentCodes.sort().push(segmentCodes.shift());
    }
    let result = [...segmentCodes];
    let finalArray = [];
    result.map((item, index) => {
        let className = item.replace("Segment_", "");
        let obj = {};
        if (!in_array([className], ["HotelTotal", "index"])) {
            obj.name = className;
            obj.code = className;
            obj.title = className;
            obj.hidden = false;
            obj.type = "room_type";
            finalArray.push(obj);
        }
    })

    return finalArray;
}