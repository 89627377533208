import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processColumns, processData } from "./processData";
import moment from "moment";
import {
    convertToPercent, round, getRestrictionsArray, getFromSession,
    getFromState, getTransformedSegments, numberFormatForReport, truncate, preferredOrder,
    sortStringArray, deepCopy, processColumnsNew, getAppData, getCompsetName, generateTable, isDateInRange, formattedCompDateCalc,
    dateFormat, in_array, getPermissions, downloadcsvwithTable, getSeriesById, showSegmentType, getUnique, getCorrectValue
} from "../../app/util"
import { transformSegmentData } from "./segmentedData";
import { useDownloadExcel } from 'react-export-table-to-excel';
import "./table-reports.css";

import { updateAppState } from "../../actions/appstate";
import { Button, Tooltip, getTableSortLabelUtilityClass, IconButton, Popover, Alert, AlertTitle } from "@mui/material";
import dataService from "../../services/data.service";
import { Box } from "@mui/system";
import { marketSellRatesTotal } from "./tilesData/marketsellrates";
import TableToExcel from "@linways/table-to-excel";
import Spinner from "../Spinner";
import { format, parse } from "date-fns";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Filter from "./Filter";
import { TILES } from "../../app/config";
import Restrictions, { getResName } from "./Restrictions";
import { primaryHeaderDisplay } from './table-functions';
import RoomTypeFilter from './RoomTypeFilter';
import ReportFilter from './ReportFilter';
import { processDataReports } from './processDataReports';
import { getColumnName_revpar } from './tilesData/revpar_index_report_functions';
import { getColumnName_biSensitivity } from './tilesData/bi_sensitivity_report_functions';
import AirportCodes from '../Workspace/subComponents/leftComponents/AirportCodes';
import DisplayData from './DisplayData';
import DisplayDataLogs from './DisplayDataLogs';
import { generalizeData, otbTransformTotal } from './functions';
import { getRatePlan, getRoomClass, getRoomType, getTopSegments } from './SearchFilter';
import { UPDATE_STATE } from '../../actions/types';
import { TableVirtuoso } from 'react-virtuoso'
// import { dateFormat } from 'highcharts';


const PrimaryHeaderCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#606060",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const SecondaryHeaderGreyCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#b2b2b2",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const SecondaryHeaderBlueCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3fa7cd",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#ebebeb",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));



function downloadReportToExcel(exportFileName, name, table) {

    TableToExcel.convert(table, {
        name: exportFileName + ".xlsx",
        sheet: {
            name: name.toUpperCase()
        }
    });
}

function TableReports({ compDisplayData }) {
    // debugger;
    const state = useSelector(appState => appState);

    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { workspace_controls, eventList, eventListSDLY } = app_state;
    const { sdlyOn, segmented_view } = workspace_controls;

    if (ui_state.active_tiles[0] === "revpar_index") {
        compDisplayData = [compDisplayData];
    }
    const { active_tiles, table_filters } = ui_state;
    let selectedPropertyName = profile.property_details.filter(property => property.id == app_state.property_id)[0].name;
    const active_tiles_className =
        "tile-active-" + ui_state.active_tiles.join(" tile-active-");
    const [columns, setColumns] = useState([]);
    const [data_cols, setDataCols] = useState([]);
    const [data_setup, setDataSetup] = useState([]);
    const dispatch = useDispatch();
    const [displayData, setDisplayData] = useState(compDisplayData);
    const [compLoad, setCompLoad] = useState(false);
    let [reloadGrid, setReloadGrid] = useState("");
    let [compLoadStatus, setCompLoadStatus] = useState('loading');
    const [renderBodyData, setRenderBodyData] = useState([]);
    let [filteredSegments, setFilteredSegments] = useState(['all']);
    let segments = null
    let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
    let segment_ignore_codes = getFromState('system_settings>market_code_ignorelist', state);
    segments = getTransformedSegments(marketSegments, segment_ignore_codes);
    const tableVirtuoso = useRef(null);
    // debugger;

    let filteredDow = table_filters.table_reports.dow.map(d => d.code);
    let segmentType = app_state.workspace_controls.segmentType;
    if (segmentType === 'hotel' && segmented_view) {
        segmentType = "market_segment";
    }

    // if (filteredSegments && filteredSegments.includes('all')) {
    //     let segmentArr = segments.list
    //     if (segmentType === "room_class") {
    //         segmentArr = getRoomClass();
    //         // debugger;
    //     } else if (segmentType === "rate_plan") {
    //         segmentArr = getRatePlan();
    //     } else if (segmentType === "room_type") {
    //         segmentArr = getRoomType();
    //     }
    //     segmentArr = segmentArr.map(v => ({ ...v, hidden: false, title: v.name, type: "reports_segments" }))
    //     ui_state.table_filters.table_reports[segmentType] = segmentArr;
    //     filteredSegments = segmentArr.map(d => d.name);
    // }


    // debugger;

    const getNeededColumns = ({ auth, app_state, ui_state }) => {
        const { workspace_controls, as_of_date } = app_state;
        let { sdlyOn, pickUpType, segmented_view } = workspace_controls;
        const { active_tiles } = ui_state;
        let startDate = app_state.primary_stay_range.date_from;
        let endDate = app_state.primary_stay_range.date_until;
        let restrictions = (getAppData(auth, app_state).restrictions) ? getAppData(auth, app_state).restrictions.enable : false;
        let finalColumns = [];
        active_tiles.map((tile, i) => {
            let tableConfig = deepCopy(TILES[tile]['tableConfig']);
            let columns = tableConfig['columns'];

            if (ui_state.active_tiles[0] === "revpar_index") {
                columns = (app_state.workspace_controls.tableType === "monthly") ? tableConfig['columns'] : tableConfig['dailycolumns'];
            }

            let sdlyColumns = deepCopy(TILES[tile]['tableConfig']['sdlyOn_columns']);
            let restrictionsColumns = deepCopy(TILES[tile]['tableConfig']['restrictionsOn_columns']);
            let dataDbaColumns = deepCopy(TILES[tile]['tableConfig']['dataDbaColumns']);
            let dataDateColumns = deepCopy(TILES[tile]['tableConfig']['dataDateColumns']);
            let dbaSdlyColumns = deepCopy(TILES[tile]['tableConfig']['dbaOn_sdly_columns']);
            let userForecastColumns = deepCopy(TILES[tile]['tableConfig']['userForecast_columns']);

            finalColumns = [...finalColumns, ...columns];
            //  debugger;
            let tile_segmented_view = true;
            let segmentedViewIgnore = ['compset'];
            if (segmentedViewIgnore.includes(active_tiles[0])) {
                tile_segmented_view = false;
            }
            if (active_tiles[0] === "market" && app_state.workspace_controls.scopeType === 'Dba') {
                tile_segmented_view = false;
            }
            if (active_tiles[0] === "productionMarketReport" || active_tiles[0] === "productionReport") {
                tile_segmented_view = true;
                segmented_view = true
            }

            if (active_tiles[0] === "compset") {
                let subColumns = getCompsetName();
                columns[1]['subcolumns'] = [...columns[1]['subcolumns'], ...subColumns];
            }

            if (active_tiles[0] === "revpar_index") {
                let subColumns = getColumnName_revpar('', app_state);
                columns[1]['subcolumns'] = [...columns[1]['subcolumns'], ...subColumns];
            }

            if (active_tiles[0] === "bi_sensitivity_analysis") {
                let biSensitivityColumns = getColumnName_biSensitivity('', app_state);
                finalColumns = [...finalColumns, ...biSensitivityColumns];
            }

            // check Scope is DBA
            if (dataDbaColumns && app_state.workspace_controls.scopeType === 'Dba' && active_tiles[0] !== "bi_sensitivity_analysis") {
                let dateColIndex = columns.findIndex(item => item.type === "dateColumn");
                let dbaSubColumns = columns[dateColIndex].subcolumns_dba;
                columns[dateColIndex]['subcolumns'] = dbaSubColumns;
                if (dataDbaColumns) {
                    finalColumns = [...finalColumns, ...dataDbaColumns];
                }
            } else {
                if (dataDateColumns) {
                    finalColumns = [...finalColumns, ...dataDateColumns];
                }
            }
            /// end ///

            /// to check user forecast ///
            let userForecastData = [];
            if (!dataService.getSeriesById("ManualForecast").data) {
                userForecastData = [];
            } else {
                userForecastData = dataService.getSeriesById("ManualForecast").data.data;
            }
            if (userForecastData.length > 0 && userForecastColumns) {
                finalColumns = [...finalColumns, ...userForecastColumns];
            }
            //// end ///

            if (sdlyOn && sdlyColumns) {
                finalColumns = [...finalColumns, ...sdlyColumns];
            }
            if (restrictions && restrictionsColumns) {
                finalColumns = [...finalColumns, ...restrictionsColumns];
            }
            if (segmented_view && tile_segmented_view) {
                let dateColIndex = columns.findIndex(item => item.type === "dateColumn");
                let dateColumn = columns[dateColIndex];
                if (dateColIndex > -1 && dateColumn["subcolumns_segmented"]) {
                    dateColumn["subcolumns_segmented"].map(segmented => {
                        columns[dateColIndex].subcolumns.push(segmented);
                    });
                }
            }
        });

        //// remove the comparison column after click on the clear comparison button
        if (active_tiles[0] === "pace" && !app_state.workspace_controls.clearComparision) {
            let columnsWithOutComp = finalColumns.slice(0, 2);
            finalColumns = [...columnsWithOutComp];
        }
        // debugger;
        // End //

        /** remove the revpar and sellrate column from the rate plan report  */
        if (active_tiles[0] === "otb_v2_multi_segment") {
            let ratePlanColumns = getRatePlanColumn(finalColumns);
            // finalColumns = [...columnsWithOutComp];
        }
        /** end  */

        finalColumns.sort(function (a, b) { return a.order - b.order });
        finalColumns.map((item, index) => {
            let subColumns = item.subcolumns;
            subColumns.sort(function (a, b) { return a.order - b.order });
        })

        let sortedColumns = [...finalColumns];
        // debugger;
        return sortedColumns;
    };

    /** function is used to remove the revpar and sellrate from the rate plan report   */
    const getRatePlanColumn = (finalColumns) => {
        if (app_state.workspace_controls.segmentType === "rate_plan") {
            for (let i = 1; i < finalColumns.length; i++) {
                finalColumns[i]["subcolumns"].map((item, index) => {
                    if (item.display === "RevPAR" || item.display === "SellRate") {
                        finalColumns[i]["subcolumns"][index]["hidden"] = true;
                        finalColumns[i]["subcolumns"] = [...finalColumns[i]["subcolumns"]]
                    }
                })
            }
        }
        let label;
        if (app_state.workspace_controls.segmentType === "rate_plan") {
            label = "Rate Plan";
        } else if (app_state.workspace_controls.segmentType === "room_type") {
            label = "Room Type";
        } else if (app_state.workspace_controls.segmentType === "room_class") {
            label = "Room Class";
        } else {
            label = "Segment";
        }
        finalColumns[0]["subcolumns"].map((itm, idx) => {
            if (itm.display === "segment") {
                finalColumns[0]["subcolumns"][idx]["display"] = label;
                finalColumns[0]["subcolumns"] = [...finalColumns[0]["subcolumns"]]
            }
        })
    }

    const compRef = useRef({
        itemDragged: {},
        itemDroppedOn: {},
        columns: getNeededColumns({ auth, app_state, ui_state })
        // columns: deepCopy(TILES[active_tiles[0]]['tableConfig']['columns']),
        // sortedColumn: TILES[active_tiles[0]]['tableConfig']['columns'][0][0]
    });

    const TableStyle = {
        height: "60vh",
        overflow: "auto",
    };

    let permissionDetails = getPermissions(profile.property_details, app_state.property_id);

    //Runs only first time just after component rendered
    useEffect(() => {
        let filteredSegments = (segmentType !== 'hotel' && segmented_view) ? ui_state.table_filters.table_reports[segmentType].map(d => d.name) : [];
        filteredSegments = [...new Set(filteredSegments)];

        let selectedCategory = typeof ui_state.reports_dpbs.selectedCategory === 'string' ? [ui_state.reports_dpbs.selectedCategory] : ui_state.reports_dpbs.selectedCategory;
        if (['bi_pace_comparison', 'bi_pickup_analysis'].includes(active_tiles[0]) && typeof selectedCategory === 'object' && selectedCategory.constructor.name === 'Array' && selectedCategory.length) {
            let topSegments = {};
            let system_settings = getAppData(auth, app_state)
            const lvl1_mseg_grouping = deepCopy(system_settings.lvl1_mseg_grouping);

            lvl1_mseg_grouping.forEach(segment => {
                topSegments = { ...topSegments, ...segment };
            });
            let topSegmentsList = [];

            if (selectedCategory && selectedCategory.includes("Hotel")) {
                topSegmentsList = topSegments[selectedCategory];
                // topSegmentsList.unshift("All");
            }
            filteredSegments = [];

            selectedCategory.forEach(cat => {
                if (cat === 'Hotel') {
                    Object.keys(topSegments).forEach(key => {
                        filteredSegments = [...filteredSegments, key];
                    })
                }
                else if (ui_state.reports_dpbs.selectedSegment) {
                    filteredSegments = ui_state.reports_dpbs.selectedSegment
                }
                else {
                    filteredSegments = [...filteredSegments, ...topSegments[cat]];
                }
            });

            filteredSegments = [...new Set(filteredSegments)];
            // debugger;
        }

        if (filteredSegments.includes('all')) {
            let segmentArr = segments.list
            if (segmentType === "room_class") {
                segmentArr = getRoomClass();
            } else if (segmentType === "rate_plan") {
                segmentArr = getRatePlan();
            } else if (segmentType === "room_type") {
                segmentArr = getRoomType();
            }
            segmentArr = segmentArr.map(v => ({ ...v, hidden: false, title: v.name, type: "reports_segments" }))
            let segmentArrUnique = segmentArr.filter((obj, index) => {
                return index === segmentArr.findIndex(o => obj.name === o.name);
            });
            ui_state.table_filters.table_reports[segmentType] = segmentArrUnique;
            filteredSegments = segmentArr.map(d => d.name);
        }
        if (segmentType === "rate_plan" && ui_state.table_filters.table_reports.rate_plan_filter_all_selected) {
            let segmentList = getRatePlan();
            ui_state.table_filters.table_reports.rate_plan = segmentList;
            filteredSegments = segmentList.map(d => d.name);
        }
        setFilteredSegments(filteredSegments);
        if (active_tiles.includes('revpar_index')) {
            if (app_state.workspace_controls.tableType === 'daily') {
                ui_state.table_filters.table_common.kpis = ["hotel_revpar", "market_revpar", "revpar_index", "sdly_hotel_revpar", "sdly_market_revpar", "sdly_revpar_index"];
            } else {
                ui_state.table_filters.table_common.kpis = ["revpar_lift", "hotel_revpar", "hotel_revpar_ly", "market_revpar", "market_revpar_ly", "revpar_index", "revpar_index_ly"];
            }
        } else {
            ui_state.table_filters.table_common.kpis = ['Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue', 'SellRate', 'Restrictions'];
        }

    }, []);

    useEffect(() => {

        let isSubscribed = true;
        let finalProcessData;
        let date_dba = active_tiles[0] === 'bi_sensitivity_analysis' ? 'dba' : 'date';
        compRef.current.filteredColumns = getFilteredColumns(compRef.current.columns);
        // debugger;

        if (reloadGrid === 'reOrderedMainCols' && compRef.current.data_setup) {
            let t = compRef.current.columns;
            let mainColOrders = compRef.current.columns.map((item) => ({ id: item.id, order: item.order }))
            compRef.current.data_setup.forEach((row, index) => {
                let newOrderColumn = [];
                // compRef.current.data_setup[index].sort()
                mainColOrders.forEach((col) => {
                    let colData = row.find((itm) => col.id === itm.id)
                    newOrderColumn.push(colData);
                })
                compRef.current.data_setup[index].splice(0, row.length, ...newOrderColumn);
            });

            setReloadGrid('applySorting');
        }

        if (reloadGrid === 'applySorting' && compRef.current.data_setup) {
            let finalRows = [];
            let tmpColumns = compRef.current.sortedColumn;
            let sortedColumn = getSortedColumn(compRef.current.columns);
            let data_setup = deepCopy(compRef.current.data_setup);
            data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });
            // if (date_dba === 'date') {
            //     data_setup = compRef.current.data_setup.filter((item) => item[0][date_dba] === '' || filteredDow.includes(moment(item[0].date).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
            // }

            if (date_dba === 'date' && active_tiles[0] !== 'revpar_index') {
                data_setup = data_setup.filter((item) => item[0][date_dba] === '' || filteredDow.includes(moment(item[0].date).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
            }
            if (active_tiles[0] === 'revpar_index' && app_state.workspace_controls.tableType === 'daily') {
                data_setup = data_setup.filter((item) => item[0][date_dba] === '' || filteredDow.includes(moment(item[0].date).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
            }
            if (active_tiles[0] === 'bi_sensitivity_analysis' && segmentType === 'hotel') {
                data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
            } else if (active_tiles[0] === 'otb_v2_multi_segment' && segmentType === 'hotel') {
                data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
            } else if (
                (ui_state.reports_dpbs.selectedCategory.includes("Hotel") && 
                workspace_controls.segmented_view) || 
                (!ui_state.reports_dpbs.selectedCategory.includes("Hotel")) || 
                (active_tiles[0] === 'otb_v2_multi_segment'  && 
                    in_array([segmentType], ['top_market_segment', 'market_segment', 'room_class', 'room_type', 'rate_plan'])
                )||
                active_tiles[0] === 'bi_sensitivity_analysis'
            ) {
                let totalsRows = data_setup.filter(item => item[0].segment === 'Total');
                data_setup = data_setup.filter((item) => (item[0][date_dba] === '' && filteredSegments.includes(item[0].segment)) || item[0].segment === 'Total' || filteredSegments.includes(item[0].segment) || filteredSegments.includes('all'));
                totalsRows.forEach(item => {
                    let segmentedRows = data_setup.filter(d => item[0][date_dba] === d[0][date_dba] && d[0].segment !== 'Total');
                    if (workspace_controls.segmented_view) {
                        segmentedRows = getFilteredData({ data: segmentedRows, columns: compRef.current.columns });
                    }
                    if (segmentedRows.length > 0) {

                        let calcTotal = getTotalData(segmentedRows, 'segment');
                        if(active_tiles[0] === 'bi_sensitivity_analysis' && segmentType === 'market_segment') {
                            calcTotal.forEach((col, i) => {
                                let findTotalSaleRateEle = item.find(d => d.id === col.id);
                                col.SellRate = findTotalSaleRateEle.SellRate;
                            })
                        }
                        let totalOfFiltered = deepCopy([...[item[0]], ...calcTotal]);
                        totalOfFiltered.forEach((col, i) => {
                            totalOfFiltered[i].id = item[i].id;
                        });

                        if (totalOfFiltered.length) {
                            if (workspace_controls.segmented_view) {
                                finalRows = [...finalRows, ...segmentedRows, ...[totalOfFiltered]];
                            } else {
                                finalRows = [...finalRows, ...[totalOfFiltered]];
                                finalRows = getFilteredData({ data: finalRows, columns: compRef.current.columns });

                            }
                        }

                    }
                });
                let finalSegmentsTotal = [];

                if (finalRows.length && workspace_controls.segmented_view) {
                    filteredSegments.forEach((item) => {
                        let segmentTotalRow = finalRows.filter((row) => row && row[0] && row[0].segment === item);
                        let dateCol = deepCopy(finalRows[0][0]);
                        if(date_dba === 'date') {
                            dateCol.date = ''; 
                            dateCol.dow = ''; 
                        }
                        if(date_dba === 'dba'){
                            dateCol.dba = ''; 
                        }                        
                        dateCol.segment = segments.map[item] ? segments.map[item] : item; dateCol.segment = item;

                        let calcTotal = getTotalData(segmentTotalRow, 'segmentTotal');

                        if (calcTotal.length) {
                            segmentTotalRow = [...[dateCol], ...getTotalData(segmentTotalRow, 'segmentTotal')];
                            finalSegmentsTotal = [...finalSegmentsTotal, ...[segmentTotalRow]];
                        }
                    });
                    finalSegmentsTotal.sort((a, b) => {
                        let A = a[1] && a[1].Rooms ? a[1].Rooms : 0; let B = b[1] && b[1].Rooms ? b[1].Rooms : 0;
                        if (B > 0 || A > 0) {
                            return B - A;
                        }
                    });
                    finalRows = [...finalRows, ...finalSegmentsTotal];

                }
                data_setup = deepCopy(finalRows);
                data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });

            } else {
                // debugger;
                data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });
                data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
            }
            setDataSetup(data_setup);
            setReloadGrid('appliedSorting');
            setCompLoadStatus('sortedCols');
        }

        if (!['applySorting', 'appliedSorting'].includes(reloadGrid) && !compRef.current.data_setup) {
            if (compLoad && displayData && displayData.length > 0) {
                setupData();
            }
        }

        if (hasInfoBoxReportTable()) {
            ui_state.showDownloadReport = false;
        } else {
            ui_state.showDownloadReport = true;
        }
        if (app_state.data_load) {
            dispatch(
                updateAppState("data_load", {
                    app_state: {
                        data_load: false,
                    },
                    ui_state: {
                        ...ui_state,
                        // showspinner: true
                    }
                })
            );
        }


        return () => (isSubscribed = false);

    }, [displayData, compLoad, reloadGrid]);

    const { dayDate } = app_state.workspace_controls;


    useEffect(() => {
        let isSubscribed = true;
        let propDetails = getPermissions(profile.property_details, app_state.property_id);
        app_state.workspace_controls.rate_plan = (propDetails.permissions.rate_plan) ? propDetails.permissions.rate_plan : 1;
        app_state.workspace_controls.room_type = (propDetails.permissions.room_type) ? propDetails.permissions.room_type : 1;

        dispatch({
            type: UPDATE_STATE,
            payload: {
                app_state: {
                    workspace_controls: { ...app_state.workspace_controls }
                }
            }
        })
        // dispatch(updateAppState("toggleChartTableView", {
        //     app_state: {
        //         workspace_controls: { ...app_state.workspace_controls }
        //     }
        // }));

        if (isSubscribed) {
            if (!ui_state.showspinner) {
                //setCompLoad(false);
                //Handle multiple data set for intellimerge
                // debugger
                if (compDisplayData && compDisplayData.constructor.name === 'Array' && compDisplayData.length) {
                    // check for segmentrd data 
                    let transformedData = [];
                    if (workspace_controls.scopeType === 'Dba' && compDisplayData[0].data && !compDisplayData[0].data.length) {
                        transformedData = transformSegmentData(app_state, auth, compDisplayData[1].data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments });
                    } else {
                        if (ui_state.active_tiles[0] === "rm_room_type_ratesend_status" || ui_state.active_tiles[0] === "DailyRatesendStatus") {
                            let data = (app_state.workspace_controls.logsRatePushMode === "date_range") ? compDisplayData[0].data : compDisplayData[1].data;
                            transformedData = transformSegmentData(app_state, auth, data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments });
                        } else {
                            transformedData = transformSegmentData(app_state, auth, compDisplayData[0].data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments });
                        }

                    }
                    if (ui_state.active_tiles[0] === "revpar_index") {
                        setDisplayData([transformedData]);
                    } else {
                        setDisplayData(transformedData);
                    }
                    setCompLoad(true);
                    // setupData();
                }
                if (compDisplayData && compDisplayData.data) {
                    let transformedData = transformSegmentData(app_state, auth, compDisplayData.data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments });
                    // debugger;
                    setDisplayData(transformedData);
                    setCompLoad(true);
                    // setupData();
                }

                if (app_state.workspace_controls.downloadExportButton === true) {
                    let exportFileName = moment(app_state.as_of_date).format('MMM DD YYYY') + "_" + selectedPropertyName + "_" + ui_state.active_tiles[0];
                    // if (ui_state.active_tiles[0] !== "restrictions_push_status" &&
                    //     ui_state.active_tiles[0] !== "otb_v2_multi_segment" &&
                    //     ui_state.active_tiles[0] !== "rm_room_type_ratesend_status") {
                    //     let table = document.getElementById("mainTable");
                    //     downloadReportToExcel(exportFileName, ui_state.active_tiles[0], table);
                    // } else {
                    const table = document.createElement('table');
                    const header = document.querySelector("#mainTable .table-head").cloneNode(true);

                    // Add same style for row 1 for Notes and Events
                    const firstRow = header.querySelectorAll('tr')[0];
                    if (firstRow && !['bi_sensitivity_analysis','revpar_index'].includes(active_tiles[0]) ) {
                        // Add the Events column heading to the first row of the header
                        const eventsTh = firstRow.querySelector('th').cloneNode(true);
                        eventsTh.textContent = 'Events';
                        eventsTh.setAttribute('colspan', '2');
                        if (!sdlyOn) {
                            eventsTh.removeAttribute('colspan');
                        }
                        firstRow.appendChild(eventsTh);
                    }

                    const secondRow = header.querySelectorAll('tr')[1];
                    if (secondRow && !['bi_sensitivity_analysis','revpar_index'].includes(active_tiles[0]) ) {
                        // Add the new "Event TY" column heading to the second row of the header
                        const eventsTh2 = secondRow.querySelector('th').cloneNode(true);
                        eventsTh2.textContent = 'Event TY';
                        secondRow.appendChild(eventsTh2);

                        // Add the new "Event SDLY" column heading to the second row of the header
                        if (sdlyOn) {
                            const secondRow2 = header.querySelectorAll('tr')[1];
                            if (secondRow2) {
                                const eventsTh2 = secondRow2.querySelector('th').cloneNode(true); // Clone an existing th to retain styles
                                eventsTh2.textContent = dayDate === 'day' ? 'Event SDLY' : 'Event SDTLY';
                                secondRow2.appendChild(eventsTh2);
                            }
                        }
                    }

                    table.append(header);
                    let data = [];
                    let columnNodes = document.querySelectorAll(".filter-position-r .col-head-label");
                    let colNames = [];
                    columnNodes.forEach(item => {
                        colNames.push(item.textContent);
                    })
                    let colNameHash = {};

                    let data_cols = [];
                    compRef.current.columns.forEach(col => {
                        col.subcolumns.forEach(subcol => {
                            if (colNames.includes(subcol.display)) {
                                colNameHash[subcol.value] = subcol.display;
                                //data_cols.push(subcol.value);
                            }
                        })
                    })
                    let totalArrObj = [];
                    if(!['bi_sensitivity_analysis','revpar_index',"DailyRatesendStatus","restrictions_push_status","rm_room_type_ratesend_status" ].includes(ui_state.active_tiles[0])){
                        totalArrObj.push({
                            TotalRow: "TotalRow",
                            id: 'TotalRow'
                        })
                    }

                    let data_setupExcel = deepCopy(data_setup);
                    const totalData = getTotalData(data_setup);
                    data_setupExcel.push(totalArrObj);
                    data_setupExcel.forEach((dItem, idx) => {
                        let dataPoint = {};
                        dItem.forEach((item, index) => {
                            if (item.RoomsRestrictions) {
                                let resArray = [];
                                item.RoomsRestrictions.forEach(res => {
                                    let restrictions = [];
                                    if (res.room_type) {
                                        restrictions.push(res.room_type);
                                    } else {
                                        restrictions.push("House");
                                    }
                                    restrictions = restrictions.concat(getRestrictionsArray(res));
                                    resArray.push(restrictions.join(" "))
                                })
                                item.RoomsRestrictions = resArray.join(", ");
                            }
                            if (item.Roomsrate) {
                                let romRatesArr = [];
                                item.Roomsrate.forEach(room => {
                                    romRatesArr.push(room.room_code + " " + room.changed_rate);
                                })
                                item.Roomsrate = romRatesArr.join(", ");
                            }
                            if (ui_state.active_tiles[0] === "otb_v2_multi_segment" || item.id === "otb_current" ) {
                                delete item.SellRate;
                                item = (item.hasOwnProperty('Rooms')) ? preferredOrder(item, ['Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue', 'SellRate']) : item;
                            }
                            if (['bi_pace_comparison', 'bi_pickup_analysis'].includes(active_tiles[0])) {
                                if (!segmented_view) {
                                    delete item.segment;
                                }
                                if (item.id === "sdly_actual" || item.id === "sdtly_actual") {
                                    delete item.SellRate;
                                }
                                item = (item.hasOwnProperty('Rooms')) ? preferredOrder(item, ['Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue', 'SellRate']) : item;
                                if (segmented_view) {
                                    item = (item.hasOwnProperty('segment')) ? preferredOrder(item, ['date', 'dow', 'segment']) : item;
                                }
                            }
                            Object.keys(item).forEach(key => {
                                if ((colNameHash[key] || key === "segment") && key !== "id") {
                                    let value = (item[key]) ? item[key] : 0;
                                    if (!item[key] && (key === "date" || key === "dow")) {
                                        value = "";
                                    }
                                    if (value === Infinity || value === -Infinity) {
                                        value = 0;
                                    }
                                    if (typeof value === "string" && value.indexOf("Infinity") !== -1) {
                                        value = value.replace("Infinity", "0")
                                    }
                                    dataPoint[key + "-" + index] = value;
                                }
                            })
                        })
                        
                        if(!['bi_sensitivity_analysis','revpar_index',"DailyRatesendStatus","restrictions_push_status","rm_room_type_ratesend_status" ].includes(active_tiles[0])){
                            if (segmented_view) {
                                // Add the new columns here if the row is Total
                                if (dItem[0].segment === "Total") {
                                    dataPoint["Event TY"] = calculateEventTY(dItem);
                                    if (sdlyOn) {
                                        if (dayDate === 'day') {
                                            dataPoint["Event SDLY"] = calculateEventSDLY(dItem);
                                        } else {
                                            dataPoint["Event SDTLY"] = calculateEventSDLY(dItem);
                                        }
                                    }
                                }
                            }
                            else {
                                // Add the new columns here if the row is not Total
                                if (!dItem[0].TotalRow) {
                                    dataPoint["Event TY"] = calculateEventTY(dItem);
                                    if (sdlyOn) {
                                        if (dayDate === 'day') {
                                            dataPoint["Event SDLY"] = calculateEventSDLY(dItem);
                                        } else {
                                            dataPoint["Event SDTLY"] = calculateEventSDLY(dItem);
                                        }
                                    }
                                }
                            }
                        }

                        if (dItem.length > 0 && dItem[0].TotalRow && dItem[0].TotalRow === "TotalRow") {
                            dItem = data_setupExcel[0];
                            let count = 0;
                            dItem.forEach((item, index) => {
                                if (active_tiles[0] === 'hotel_summary') {
                                    delete item['date_market_summary'];
                                }
                                if (['bi_pace_comparison', 'bi_pickup_analysis'].includes(active_tiles[0])) {
                                    if (!segmented_view) {
                                        delete item.segment;
                                    }
                                    item = (item.hasOwnProperty('Rooms')) ? preferredOrder(item, ['Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue', 'SellRate']) : item;
                                }
                                Object.keys(item).forEach((key, keyIndex) => {
                                    if (key === "segment" || (key !== 'id' && key !== 'segment_code' && colNameHash[key])) {
                                        let value = item[key];
                                        if (key === "date" || key === "date_market_summary" || key === "month") {
                                            value = "Total";
                                        } else if (key === "dow") {
                                            value = "";
                                        } else if (key.toLocaleLowerCase() === "segment") {
                                            value = "";
                                        } else {
                                            // value = (totalRowsData[count]) ? totalRowsData[count] : '';
                                            value = (totalData[count]) ? totalData[count][key] : "";
                                            if (isNaN(value) || value === Infinity || value === -Infinity) {
                                                if (['Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue', 'SellRate'].includes(key)) {
                                                    if (value === "" || value === 'NaN' || Number.isNaN(value) || value === 'Infinity' || value === '-Infinity' || value === Infinity || value === -Infinity) {
                                                        value = 0
                                                    }
                                                    if (value === 'Infinity%' || value === '-Infinity%') {
                                                        value = '0%';
                                                    }
                                                } else {
                                                    if (value === "" || value === 'NaN' || Number.isNaN(value) || value === 'Infinity' || value === '-Infinity' || value === Infinity || value === -Infinity) {
                                                        value = ""
                                                    }
                                                    if (value === 'Infinity%' || value === '-Infinity%') {
                                                        value = '0%';
                                                    }
                                                }
                                            }
                                        }
                                        dataPoint[key + "-" + index] = value;
                                    }
                                })
                                if (index !== 0) {
                                    count++;
                                }

                            })
                        }

                        data.push(dataPoint);
                    })
                    const tBody = generateTable(table, data)
                    if (app_state.workspace_controls.exportType == 'csv') {
                        downloadcsvwithTable(table, exportFileName, compRef.current.columns);
                    } else {
                        downloadReportToExcel(exportFileName, ui_state.active_tiles[0], table);

                    }
                    //}
                    dispatch(updateAppState("toggleChartTableView", {
                        app_state: {
                            workspace_controls: { ...app_state.workspace_controls, downloadExportButton: false, }
                        }
                    }, "dashboard"));
                }
            }
        }
        return () => (isSubscribed = false);
    }, [ui_state.showspinner,
    workspace_controls.sdlyOn,
    workspace_controls.scopeType,
    workspace_controls.downloadExportButton,
    ]);

    const onDragStartHandler = (columParam) => {
        return (event) => {
            // event.preventDefault();
            event.dataTransfer.setData("columnMeta", JSON.stringify(columParam));
            compRef.current.itemDragged = deepCopy(columParam);
            // debugger;
        }
    };

    const calculateEventTY = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        let current_stay_date = dateFormat(formatted_stay_date, "YYYY/MM/DD")

        const filteredEventList = eventList.filter(event =>
            isDateInRange(formatted_stay_date, event.start_date, event.end_date) && event.is_ignored === false
        );

        let EventData = filteredEventList.map(event => event.title).join(", ");

        return EventData
    }
    const calculateEventSDLY = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        const formatted_comp_date = formattedCompDateCalc(sdlyOn, formatted_stay_date, app_state);
        let current_stay_date = dateFormat(formatted_comp_date, "YYYY/MM/DD")

        const filteredEventList = eventListSDLY.filter(event =>
            isDateInRange(formatted_comp_date, event.start_date, event.end_date) && event.is_ignored === false
        );

        let EventData = filteredEventList.map(event => event.title).join(", ");

        return EventData
    }


    const mainColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            const draggedCellOrder = columnMeta.order;
            const draggedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === draggedCellOrder);
            const droppedCellOrder = columParam.order;
            const droppedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === droppedCellOrder);
            compRef.current.itemDroppedOn = columParam;

            compRef.current.columns.map((mainCol, i) => {
                //moving to left
                if (draggedCellOrder > droppedCellOrder && i <= draggedCellOrderIndex) {
                    // debugger;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i <= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order + 1;
                    }
                }
                //moving to right
                if (draggedCellOrder < droppedCellOrder && i >= draggedCellOrderIndex) {
                    // debugger;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i >= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order - 1;
                    }
                }
            });
            compRef.current.columns = processColumnsNew(compRef.current.columns);
            // setupData();
            let ts = Date.now();
            // setReloadGrid(ts);
            setCompLoadStatus('loading');
            setReloadGrid('reOrderedMainCols');

        }
    };
    const dataColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            compRef.current.itemDroppedOn = columParam;
            // debugger;
            let { itemDragged, itemDroppedOn } = compRef.current;
            if (columnMeta.parent === columParam.parent) {
                let draggedOrder = columnMeta.order;
                let droppedOrder = columParam.order;
                let mainColumn = compRef.current.columns.filter((item) => item.id === columnMeta.parent);
                compRef.current.columns.map((mainCol, i) => {
                    if (mainCol.id === columnMeta.parent) {
                        compRef.current.columns[i].subcolumns.map((col) => {
                            if (col.value === columnMeta.value) {
                                col.order = droppedOrder;
                            }
                            if (col.value === columParam.value) {
                                col.order = draggedOrder;
                            }
                        });
                    }
                });
                let columns = processColumnsNew(compRef.current.columns);
                compRef.current.columns = columns;
                // setupData();
                let ts = Date.now();
                setCompLoadStatus('loading');
                // setReloadGrid(ts);
                setReloadGrid('applySorting');

            }
        }
    };

    const columnToggleHandler = (item, index, hiddenStatus) => {
        let cols = compRef.current.columns;
        let items = item;
        if (item && item.constructor.name === 'Object') {
            items = [item]
        }
        let hiddenSubcols = items.filter(col => col.hidden);
        hiddenSubcols = hiddenSubcols.map(col => col.value);
        compRef.current.columns.forEach(column => {
            let subcosl = column.subcolumns;
            subcosl.forEach(subcol => {
                if (hiddenSubcols.includes(subcol.value)) {
                    subcol.hidden = true;
                } else {
                    subcol.hidden = false;
                }
            })
        });

        compLoadStatus = 'sortingCols';
        reloadGrid = 'applySorting';
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    }
    const applyFilterHandler = (selectedSegment, type) => {
        let ts = Date.now();
        if (selectedSegment && selectedSegment[0] && type !== 'column') {
            filteredSegments = selectedSegment;
            setFilteredSegments(selectedSegment);
        }
        compLoadStatus = 'sortingCols';
        reloadGrid = 'applySorting';
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    };
    const clearFilterHandler = (item, type) => {
        // debugger;
        if (type === 'all') {
            compRef.current.columns.map((main, i) => {
                main.subcolumns.map((sub, j) => {
                    //it depends on property sorted
                    sub.filter = null;
                });
            });
        }
        compLoadStatus = 'sortingCols';
        reloadGrid = 'applySorting';
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    };

    const onOrderByChangeHandler = (item) => {
        // debugger;
        setCompLoadStatus('sortingCols');
        // let cols = compRef.current.columns;
        compRef.current.sortedColumn = deepCopy(item);
        compRef.current.columns.map((main) => {
            if (main.id === item.parent) {
                main.subcolumns.map((col) => {
                    if (col.value === item.value) {
                        col.sorted = item.sorted;
                    } else {
                        col.sorted = null;
                    }
                })
            } else {
                main.subcolumns.map((col) => {
                    col.sorted = null;
                });
            }

        });
        let ts = Date.now();
        compLoadStatus = 'sortingCols';
        reloadGrid = 'applySorting';
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    }





    const setupData = () => {
        let columns = processColumnsNew(compRef.current.columns);
        compRef.current.columns = columns;
        let data_cols = [];
        columns.map((i) => {
            i.subcolumns.map((x) => {
                data_cols.push(x.value);
            });
        });
        compRef.current.data_cols = data_cols;
        let data_setup = processDataReports(columns, displayData, {
            auth,
            app_state,
            ui_state,
            state
        });
        // debugger;
        compRef.current.data_setup = deepCopy(data_setup);
        let t = compRef.current.sortedColumn;
        let date_dba = active_tiles[0] === 'bi_sensitivity_analysis' ? 'dba' : 'date';
        if (date_dba === 'date' && active_tiles[0] !== 'revpar_index') {
            data_setup = data_setup.filter((item) => item[0][date_dba] === '' || filteredDow.includes(moment(item[0].date).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
        }
        if (active_tiles[0] === 'revpar_index' && app_state.workspace_controls.tableType === 'daily') {
            data_setup = data_setup.filter((item) => item[0][date_dba] === '' || filteredDow.includes(moment(item[0].date).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
        }

        let sortedColumn = getSortedColumn(compRef.current.columns);
        data_setup = getFilteredData({ data: data_setup, columns });
        let finalRows = [];
        // debugger;
        if (active_tiles[0] === 'bi_sensitivity_analysis'  && segmentType === 'hotel') {
            data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
        } else if (active_tiles[0] === 'otb_v2_multi_segment' && segmentType === 'hotel') {
            data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
        } else if (
            (!ui_state.reports_dpbs.selectedCategory.includes("Hotel")) || 
            (active_tiles[0] === 'otb_v2_multi_segment' && 
                in_array([segmentType], ['top_market_segment', 'market_segment', 'room_class', 'room_type', 'rate_plan'])
            ) ||
            active_tiles[0] === 'bi_sensitivity_analysis'
            ) {
            data_setup = data_setup.filter((item) => (item[0][date_dba] === '' && filteredSegments.includes(item[0].segment)) || item[0].segment === 'Total' || filteredSegments.includes(item[0].segment) || filteredSegments.includes('all'));
            let totalsRows = data_setup.filter(item => item[0].segment === 'Total');
            // debugger;
            totalsRows.forEach(item => {
                let segmentedRows = data_setup.filter(d => item[0][date_dba] === d[0][date_dba] && d[0].segment !== 'Total');
                if (!workspace_controls.segmented_view) {
                    segmentedRows = getFilteredData({ data: segmentedRows, columns: compRef.current.columns });
                }
                if (segmentedRows.length > 0) {
                    let calcTotal = getTotalData(segmentedRows, 'segment');
                    if(active_tiles[0] === 'bi_sensitivity_analysis' && segmentType === 'market_segment') {
                        calcTotal.forEach((col, i) => {
                            let findTotalSaleRateEle = item.find(d => d.id === col.id);
                            col.SellRate = findTotalSaleRateEle.SellRate;
                        })                        
                    }
                    let totalOfFiltered = deepCopy([...[item[0]], ...calcTotal]);
                    totalOfFiltered.forEach((col, i) => {
                        totalOfFiltered[i].id = item[i].id;
                    });
                    if (totalOfFiltered.length) {

                        if (workspace_controls.segmented_view) {
                            finalRows = [...finalRows, ...segmentedRows, ...[totalOfFiltered]];
                        } else {
                            finalRows = [...finalRows, ...[totalOfFiltered]];
                            finalRows = getFilteredData({ data: finalRows, columns: compRef.current.columns });

                        }

                        // if (workspace_controls.segmented_view) {
                        //     finalRows = [...finalRows, ...segmentedRows, ...[totalOfFiltered]];
                        // } else {
                        //     finalRows = [...finalRows, ...[totalOfFiltered]];
                        // }
                    }

                }
            });
            let finalSegmentsTotal = [];

            if (finalRows.length && workspace_controls.segmented_view) {
                filteredSegments = getUnique(filteredSegments);
                filteredSegments.forEach((item) => {
                    let segmentTotalRow = finalRows.filter((row) => row && row[0] && row[0].segment === item);
                    let dateCol = deepCopy(finalRows[0][0]);
                    if(date_dba === 'date') {
                        dateCol.date = ''; 
                        dateCol.dow = ''; 
                    }
                    if(date_dba === 'dba'){
                        dateCol.dba = ''; 
                    }
                    dateCol.segment = segments.map[item] ? segments.map[item] : item; dateCol.segment = item;

                    let calcTotal = getTotalData(segmentTotalRow, 'segmentTotal');

                    if (calcTotal.length) {
                        segmentTotalRow = [...[dateCol], ...getTotalData(segmentTotalRow, 'segmentTotal')];
                        finalSegmentsTotal = [...finalSegmentsTotal, ...[segmentTotalRow]];
                    }
                });
                finalSegmentsTotal.sort((a, b) => {
                    let A = a[1] && a[1].Rooms ? a[1].Rooms : 0; let B = b[1] && b[1].Rooms ? b[1].Rooms : 0;
                    if (B > 0 || A > 0) {
                        return B - A;
                    }
                });
                finalRows = [...finalRows, ...finalSegmentsTotal];

            }
            data_setup = deepCopy(finalRows);
            data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });
        }
        else {
            data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });
            data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
        }
        setDataSetup(data_setup);
        setCompLoadStatus('loaded');
    };

    const renderHeader = () => {
        let columns = compRef.current.columns;
        let cols = [];
        let colheaders = [];
        let count = 0;
        let bckgClass = "grey";
        for (let index = 0; index < columns.length; index++) {
            if (index > 0) {
                bckgClass = "light_blue";
            }

            const col = columns[index];
            // const col_len = col["subcolumns"].length;
            let colSpan = col["subcolumns"].length;
            let hiddenCols = col["subcolumns"].filter(item => item.hidden);
            colSpan = colSpan - hiddenCols.length;
            // debugger
            let primaryHSty = {};
            if (col.type == "dateColumn") {
                primaryHSty = {
                    position: "sticky",
                    left: col.left ? col.left : 0,
                    zIndex: 5,
                };
                // colSpan = 2;
            }
            colSpan = colSpan > 1 ? colSpan : null;
            const header = (
                <PrimaryHeaderCell
                    onDrop={mainColumnOnDropHandler(col)}
                    key={index}
                    align="center"
                    colSpan={colSpan}
                    style={primaryHSty}
                    className={"primary-header-cell header_col "}
                    sx={{ fontWeight: "bold !important" }}
                    data-fill-color="606060"
                    data-f-color="ffffff"
                    data-b-r-s="thin"
                    data-a-v="middle"
                    data-b-a-c="ffffff"
                    data-a-h="center"
                    data-f-sz="16"
                >
                    {col.draggable && <div draggable={col.draggable} onDragStart={onDragStartHandler(col)} onDragOver={(e) => { e.preventDefault() }}>
                        {/* <span>{col.display}</span> */}
                        {primaryHeaderDisplay(col, app_state, ui_state)}

                    </div>}

                    {!col.draggable && <div>
                        {/* <span>{col.display}</span> */}
                        {primaryHeaderDisplay(col, app_state, ui_state)}

                    </div>}

                </PrimaryHeaderCell>
            );

            col["subcolumns"].map((item, sub_col_index) => {
                bckgClass = ((item.display).toLowerCase() === "comparison date") ? "header-grey" : "header-blue";
                let segmentClass = ((item.display).toLowerCase() === "segment") ? "segment-class" : "";
                // debugger;
                let sty = {};
                // sty["paddingBottom"] = "18px";
                sty["fontWeight"] = "bold";
                sty["background"] = "#3fa7cd";
                sty["fontSize"] = "19px !important";
                sty["width"] = (item.value === "Roomsrate") ? "570px" : "";

                let segmentType = workspace_controls.segmentType;
                let hasFilterClass = item.filter && (item.filter.gte || item.filter.lte) ? "has-filter" : "";
                if (!hasFilterClass && !['dow', 'segment', 'date'].includes(item.type) && item.parent === col.id) {

                    let isHiddenCol = col["subcolumns"].find(itm => itm.hidden);
                    if (segmentType === 'rate_plan') {
                        isHiddenCol = col["subcolumns"].find(itm => itm.hidden && itm.value !== 'RevPAR' && itm.value !== 'SellRate');
                    }

                    if (isHiddenCol) {
                        hasFilterClass = "has-filter";
                    }
                    // 
                }
                if (['dow'].includes(item.type) && ui_state.table_filters.table_reports.dow.length < 7) {
                    hasFilterClass = "has-filter";
                }

                if (segmentType === "market_segment" && ['segment'].includes(item.type)) {
                    let activeSegment = ui_state.table_filters.table_reports.market_segment;
                    let segmentList = new Set(segments.list.map(item => item.name));
                    if (activeSegment.length !== segmentList.size) {
                        hasFilterClass = segmentType + " has-filter";
                    }
                }
                if (segmentType === "rate_plan" && ['segment'].includes(item.type)) {
                    let activeSegment = ui_state.table_filters.table_reports.rate_plan;
                    let segmentList = getRatePlan();
                    if (activeSegment.length !== segmentList.length) {
                        hasFilterClass = segmentType + " has-filter";
                    }
                }
                if (segmentType === "top_market_segment" && ['segment'].includes(item.type)) {
                    let activeSegment = ui_state.table_filters.table_reports.top_market_segment;
                    let segmentList = getTopSegments();
                    if (activeSegment.length !== segmentList.length) {
                        hasFilterClass = segmentType + " has-filter";
                    }
                }
                if (segmentType === "room_class" && ['segment'].includes(item.type)) {
                    let activeSegment = ui_state.table_filters.table_reports.room_class;
                    let segmentList = getRoomClass();
                    if (activeSegment.length !== segmentList.length) {
                        hasFilterClass = segmentType + " has-filter";
                    }
                }

                if (segmentType === "room_type" && ['segment'].includes(item.type)) {
                    let activeSegment = ui_state.table_filters.table_reports.room_type;
                    let segmentList = getRoomType();
                    if (activeSegment.length !== segmentList.length) {
                        hasFilterClass = segmentType + " has-filter";
                    }
                }


                // debugger;
                let el = (
                    <SecondaryHeaderBlueCell
                        onDrop={dataColumnOnDropHandler(item)}
                        className={"header_col  " + bckgClass + " col_len_1  header-col-" + item.value}
                        style={sty}
                        key={count}
                        align="center"
                        data-fill-color="B2B2B2"
                        data-f-color="ffffff"
                        data-a-v="middle"
                        data-a-h="center"
                        data-f-bold="true"
                        data-f-sz="15"
                        // data-b-a-c="ffffff"
                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        sx={{
                            fontSize: "13px", padding: "0px !important",
                            top: "30px !important", verticalAlign: "top"
                        }}
                    >
                        <div className={"filter-position-r " + bckgClass + " " + segmentClass} onDragOver={(e) => { e.preventDefault() }}>
                            <ColumnSorting
                                className={"column-sorting field-" + item.value}
                                colItem={item} sortable={item.sortable} sorted={item.sorted}
                                label={item.display} value={item.value}
                                onOrderByChange={onOrderByChangeHandler} />

                            <div className="col-head-label" draggable={item.draggable} onDragStart={onDragStartHandler(item)}>
                                {/* {item.display}----test */}
                                {showSegmentType(app_state, ui_state, item.display)}
                            </div>
                            {item.filterMenu &&
                                <div className={"filter-main " + item.type + " segment-type-" + segmentType}>
                                    {hasFilterClass && <span className={hasFilterClass}></span>}
                                    <Filter filteredColumns={compRef.current.filteredColumns} columnList={compRef.current.columns} currentColumn={item}
                                        segments={segments}
                                        columnToggleHandler={columnToggleHandler}
                                        applyFilterHandler={applyFilterHandler}
                                        clearFilterHandler={clearFilterHandler}
                                    />
                                </div>}
                        </div>
                    </SecondaryHeaderBlueCell>
                );


                if (col.type == "dateColumn") {
                    var colClass = "date_subcolumn";
                    sty["position"] = "sticky";
                    sty["zIndex"] = "5";
                    sty["textAlign"] = "center";
                    sty["paddingBottom"] = "18px";
                    sty["fontWeight"] = "bold";
                    sty["paddingLeft"] = "15px";
                    sty["paddingRight"] = "15px";
                    sty["background"] = "#b2b2b2";

                    if ((item.display).toLowerCase() === 'dba' || (item.display).toLowerCase() === 'month') {
                        sty["left"] = sub_col_index * 64;
                    }

                    if ((item.display).toLowerCase() === 'dow') {
                        // sty["left"] = "70px !important";
                        sty["width"] = "60px !important";
                    }
                    if ((item.display).toLowerCase() === 'segment' && ui_state.active_tiles[0].toLowerCase() !== "pace") {
                        sty["left"] = sub_col_index * 64;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }

                    if ((item.display).toLowerCase() === 'segment' && app_state.workspace_controls.scopeType === "Dba" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                        sty["left"] = sub_col_index * 80.5;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }
                    if ((item.display).toLowerCase() === 'segment' && app_state.workspace_controls.scopeType === "Date" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                        sty["left"] = sub_col_index * 74.5;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }


                    <SecondaryHeaderGreyCell
                        className={"header_col  " + bckgClass + " " + colClass}
                        style={sty}
                        key={count}
                        align="left"
                        data-fill-color="3fa7cd"
                        data-f-color="ffffff"
                        data-a-v="middle"
                        data-a-h="center"
                        data-f-bold="true"
                        data-f-sz="15"
                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        // sx={{pl:3.5,pr:13.5,pt:1}}
                        sx={{ fontSize: "13px", padding: "0px !important", top: "30px !important" }}
                    >
                        {/* <div className="filter-position-r">
                                {item.display}
                                <Filter className="filter-position-a" />
                            </div> */}
                        <div className="filter-position-r date-col" sx={{ minWidth: "80px !important" }}>
                            <div className="col-head-label">
                                {item.display}
                            </div>
                            <div className="filter-main">
                                <Filter />
                            </div>
                        </div>
                    </SecondaryHeaderGreyCell>
                }
                if (!item.hidden) {
                    cols.push(el);
                }
                count++;

            });
            if (!col.hidden) {
                colheaders.push(header);
            }

        }

        return (
            <>
                <TableRow data-height="30" className="header_row top_header">{colheaders}</TableRow>
                <TableRow data-height="30" className="header_row bottom_header">{cols}</TableRow>
            </>
        );
    };
    const renderBody = (params) => {
        // const data_setup = compRef.current.data_setup;
        const rows = [];
        const size = data_setup.length;
        var colCount = 0;
        var totalArr = [];
        var rowsCount = 0;
        var columnsCounts = 0;
        if (size > 0) {
            for (let index = 0; index < size; index++) {
                var evenRow = (rowsCount % 2 === 0) ? "even-row" : "";
                let columnTotal = 0;
                const data_columns = data_setup[index];
                const cols = [];
                const reportCols = [];
                let count = 0;
                totalArr[index] = 0;
                colCount = 0;
                var totalRow = "";
                var totalCol = "";
                let rowDate;
                console.log("test", data_columns);
                data_columns.map((column, col_index) => {
                    console.log(compRef.current.columns[col_index]);
                    let subCols = (compRef.current.columns[col_index]) ? compRef.current.columns[col_index].subcolumns.filter(col => !col.hidden) : [];
                    // let subCols = Object.keys(column); debugger;

                    let cellClass = "data-cell";
                    subCols.map((subcol, sub_col_index) => {
                        let key = subcol.value;
                        let val = column[key];
                        let isTotalRow = column['segment'];
                        // debugger;
                        let txtAlignClass = (app_state.workspace_controls.segmented_view && key === 'Revenue') ? "text-align-right_x" : "";
                        let txtAlignClassml = (key === 'ADR' || key === 'Occ') ? "text-align-left-ml" : "";
                        let txtAlignClassRight = (key === 'Rooms') ? "" : "";

                        if (key === 'segment' && val === "Total") {
                            totalRow = "total-row";
                            totalCol = "total-col";
                        }
                        let sty = {};
                        if (col_index === 0) {
                            sty = { position: "sticky", zIndex: 1, textAlign: "left" };
                            sty["textAlign"] = "center";
                            if (key === "segment" && app_state.workspace_controls.scopeType === "Date" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                                sty["left"] = sub_col_index * 74.5;
                                sty["borderRight"] = "solid 1px #ddd";
                                sty["borderCollapse"] = "collapse";
                            }
                            if (key === "segment" && app_state.workspace_controls.scopeType === "Dba" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                                sty["left"] = sub_col_index * 80.5;
                                sty["borderRight"] = "solid 1px #ddd";
                            } else if (key === "segment" && ui_state.active_tiles[0].toLowerCase() !== "pace") {
                                sty["left"] = sub_col_index * 64;
                                sty["borderRight"] = "solid 1px #ddd";
                            }

                            else {
                                sty["left"] = sub_col_index * 94;
                                // sty["borderRight"] = "solid 10px #000";
                                // debugger; 
                                if ((!app_state.workspace_controls.segmented_view) && (key === "dow" || key === "date_market_summary" || key === "dba")) {
                                    sty["borderRight"] = "solid 1px #ddd";
                                    // sty["width"] = "solid 1px #ddd"; 
                                }
                                // if(app_state.workspace_controls.segmented_view) {
                                //     sty["borderRight"] = "solid 10px #ff0000";
                                // }
                            }
                        } else {
                            if (key.toLowerCase() === "revenue") {
                                // sty["color"] = "#3583B8";
                                sty["textAlign"] = "center";
                                sty["ml"] = "5px !important";
                                sty["padingLeft"] = "5px";
                            }
                        }
                        var txtAlign = { textAlign: "center" };
                        let rowsBackColor = (rowsCount % 2 === 0) ? "ebebeb" : "ffffff";
                        // var totalRow = "1";
                        let dataType = numberFormatForReport(key);
                        let reportData;
                        // if (!val)
                        let tmpVal;

                        if (val && typeof val === "number") {
                            tmpVal = val;
                        } else if (val && typeof val !== "object") {
                            tmpVal = val.replaceAll("%", "");
                        } else if (val && typeof val === "object") {
                            let resVal = (val[0]) ? Object.keys(val[0]).join(",") : "";
                            tmpVal = resVal;
                        }

                        if (key === 'date') {
                            reportData = (val) ? moment(val).format('YYYY/MM/DD') : '';
                            rowDate = reportData;
                        } else if (key === 'Revenue' || key === 'ADR' || key === 'Occ' || key === 'RevPAR') {
                            tmpVal = (tmpVal && isFinite(tmpVal) && !isNaN(tmpVal)) ? tmpVal : parseFloat(0.00);
                            reportData = tmpVal;//(Number.isFinite(tmpVal)) ? parseFloat(tmpVal).toFixed(2) : parseFloat(0.00).toFixed(2);
                        } else if (key === "Roomsrate") {
                            let repArr = [];
                            val.map((item) => {

                                repArr.push(item.room + "-" + item.changed_rate)
                            })
                            reportData = repArr.toString(",");
                            // debugger;

                        } else if (key === "RoomsRestrictions") {
                            reportData = "rest";
                        } else {
                            reportData = (tmpVal);
                        }
                        let brdRight;
                        let dowStyle
                        if (key === "dow") {
                            dowStyle = "dow-style";
                        }
                        if (key === "compdate") {
                            reportData = moment(val).format('MMM DD YYYY,  ddd');
                            dowStyle = "dow-style-comp-date";
                        }
                        if (key === "segment") {
                            dowStyle = "segment-td-style";
                        }

                        reportCols.push(
                            <TableCell
                                data-t={dataType.type}
                                // data-num-fmt={dataType.numfmt}
                                data-fill-color={rowsBackColor}
                                data-b-r-s="thin"
                                data-b-a-c="ffffff"
                                data-f-sz="12"
                                data-a-h="center"
                                key={count} className={cellClass + " " + totalCol}
                                style={sty}>{reportData}
                                {/* {reportData} - {key} */}
                                {/* <DisplayDataLogs displayValue={reportData} dataKey={key} displayType="report" /> */}
                            </TableCell>
                        );
                        cols.push(
                            // <Tooltip title={key} arrow>
                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="thin" data-b-a-c="ffffff"
                                data-t="s" data-exclude="true" data-f-sz="13" data-a-h="center" key={count}
                                className={dowStyle + " " + brdRight + " " + cellClass + " " + totalCol + " " + txtAlignClass + " " + txtAlignClassml + " " + txtAlignClassRight}
                                style={sty}>
                                {
                                    (ui_state.active_tiles[0] === "revpar_index") ?
                                        (key === "revpar_lift") ?
                                            parseFloat(val).toFixed(2) + "%"
                                            :
                                            displayDataFormat(val, key, "")
                                        :
                                        (key !== "compdate" && key !== "RoomsRestrictions" && key !== "date" && key !== "dow" && key !== "segment" && key != "month" && key != "date_market_summary" && key != "dba" && (typeof val === "string" || typeof val === "object")) ?
                                            <DisplayDataLogs displayValue={val} dataKey={key} displayType="table" />
                                            :
                                            <DisplayData displayValue={displayDataFormat(val, key, "", ui_state.active_tiles[0])} dataKey={key} dataValue={val} stay_date={rowDate} isTotalRow={isTotalRow} />
                                    // displayDataFormat(val, key, "") 
                                }
                            </TableCell>
                            // </Tooltip>
                        );
                        colCount++;

                        if (typeof val === 'string') {
                            //totalArr[count] = parseFloat(totalArr[count] + 0);
                        } else {
                            if (count === 4) {
                                if (totalArr[count] === 'undefind') {
                                    totalArr[count] = 0;
                                }
                            }
                            totalArr[count] += val;// parseFloat(totalArr[count] + val);
                        }
                        count += 1;
                    });
                    columnsCounts++;
                });
                //rows.push(<StyledTableRow data-exclude="true" data-height="20" className={(totalRow) ? totalRow : evenRow + " table-row"}>{cols}</StyledTableRow>);
                //rows.push(<StyledTableRow data-height="20" sx={{ display: "none" }} >{reportCols}</StyledTableRow>);
                rows.push(<>{cols}</>);

                rowsCount++;
            }
        } else {
            rows.push(<StyledTableRow className="">
                <TableCell colSpan={5}
                    sx={{ height: "auto", borderRight: "0px solid #ddd", background: "#fff", textAlign: "center", borderBottom: "0px solid" }}>
                    <Spinner />
                </TableCell></StyledTableRow>);
        }

        if (rowsCount <= 12) {
            let h = 30 * (12 - rowsCount) + 'px';
            rows.push(
                <><TableCell
                    sx={{ height: "auto", borderRight: "0px solid #ddd", background: "#fff", borderBottom: "0px solid" }}>
                    <div style={{ height: h }}></div>
                </TableCell></>);
        }

        let footerCols = [];
        var colSpanTotal = 2;
        let columns = processColumnsNew(compRef.current.columns);
        // if (data_setup.length > 0) {
        //     columns = data_setup[0];
        // }
        let count = 0;


        let multiSegmentOtbCurrent = {}
        let multiSegmentOtbSdly = {}

        columns.map((column, col_index) => {
            // let subCols = Object.keys(column);
            let subCols = compRef.current.columns[col_index].subcolumns.filter(col => !col.hidden);
            let frontColumn = Object.keys(column).length;
            // debugger;  
            if (col_index === 0) {
                footerCols.push(
                    <TableCell
                        colSpan={subCols.length}
                        className="data-cell"

                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        data-a-h="center"
                        data-f-sz="15"
                        data-fill-color="b1e2f4"
                        data-f-color="000000"
                        data-f-bold="true"

                        key={Math.random()}
                        style={{
                            borderRight: "1px solid #ddd",
                            left: 0,
                            position: "sticky",
                            fontWeight: "bold",
                            zIndex: 5,
                            borderBottomLeftRadius: "6px",
                            textTransform: "capitalize",
                            fontSize: 14
                        }}
                    >
                        Total
                    </TableCell>
                );
            } else {
                if (ui_state.active_tiles[0].toLowerCase() === 'compset') {
                    subCols.map((key, sub_col_index) => {
                        var finalTotal;
                        let sty = {};
                        sty["background"] = "#606060 !important";
                        sty = { fontWeight: "bold" };
                        if (key.value.toLowerCase() === "revenue") {
                            sty["color"] = "#3583B8";
                        } else {
                            sty["color"] = "#fff !important";
                        }
                        footerCols.push(
                            <TableCell style={sty} className="data-cell"
                                data-b-r-s="thin"
                                data-b-a-c="ffffff"
                                data-a-h="center"
                                data-f-sz="14"
                                data-fill-color="b1e2f4"
                                data-f-color="000000"
                                data-f-bold="true"

                                key={Math.random()}>
                                {compsetTotal(data_setup, key.value, sub_col_index, count)}
                                {/* {
                    // (ui_state.active_tiles[0].toLowerCase() !== 'compset') ?
                    //   totalCalulate(data_setup, key, sub_col_index, count)
                    //   : compsetTotal(data_setup, key, sub_col_index, count)
                    } */}
                            </TableCell>
                        );
                    });
                }
                ///// for Market Sell rate Total row ////
                if (ui_state.active_tiles[0].toLowerCase() === 'market_sell_rates') {
                    subCols.map((key, sub_col_index) => {
                        var finalTotal;
                        let sty = {};
                        sty["background"] = "#606060 !important";
                        sty = { fontWeight: "bold" };
                        sty["color"] = "#fff !important";

                        footerCols.push(
                            <TableCell style={sty} data-b-r-s="thin"
                                data-b-a-c="ffffff"
                                data-a-h="center"
                                data-f-sz="14"
                                data-fill-color="b1e2f4"
                                data-f-color="000000"
                                data-f-bold="true" className="data-cell" key={Math.random()}>
                                {marketSellRatesTotal(data_setup, key, sub_col_index, count, ui_state)}

                            </TableCell>
                        );
                    });
                }

                if (ui_state.active_tiles[0].toLowerCase() !== 'compset' && ui_state.active_tiles[0].toLowerCase() !== 'market_sell_rates') {
                    let totalType = (app_state.workspace_controls.segmented_view) ? "Total" : "Total";
                    const totalData = getTotalData(data_setup);
                    let sty = {};
                    let dt1 = moment(app_state.primary_stay_range.date_from);
                    let dt2 = moment(app_state.primary_stay_range.date_until);
                    let daysCount = dt2.diff(dt1, 'days') + 1;

                    let system_settings = getAppData(auth, app_state);
                    let totalCapacity = system_settings.property_current_total_capacity;

                    let marketCapacity = (dataService.getSeriesById("MarketCapacity").data) ? dataService.getSeriesById("MarketCapacity").data.data : [];
                    let totalMarketCapacity = (marketCapacity && marketCapacity.length > 0) ? marketCapacity[marketCapacity.length - 1]['Capacity'] : 0;


                    let i = totalData[col_index - 1];

                    subCols.map((col) => {

                        /** make total calculation  */
                        if (active_tiles[0] === "otb_v2_multi_segment") {
                            if (col.parent === "otb_current") {
                                multiSegmentOtbCurrent[col.value] = i[col.value];
                            }
                            if (col.parent === "otb_sdly") {
                                multiSegmentOtbSdly[col.value] = i[col.value];
                            }

                            if (col.parent === "otb_variance_sdly") {
                                let varianceResult = (multiSegmentOtbCurrent[col.value] - multiSegmentOtbSdly[col.value]) / multiSegmentOtbSdly[col.value];
                                i[col.value] = (varianceResult && isFinite(varianceResult)) ? convertToPercent(varianceResult) + "%" : "0.00" + "%";
                            }
                        }

                        /** Pickup by Segment  */
                        // if(active_tiles[0] === "bi_pickup_analysis"){
                        if ((col.parent === "variance_pickup" || col.parent === "otb_sdly_pickup_var") && (col.display === "Occ" || col.display === "RevPAR")) {
                            i[col.value] = (col.value === "Occ") ? convertToPercent(i['Rooms'] / (totalCapacity * daysCount)) : i['Revenue'] / (totalCapacity * daysCount);

                        }
                        // }

                        /** end Pickup by segment  */
                        if (active_tiles[0] === "bi_pace_comparison") {
                            // if (col.parent === "otb_sdly_pickup_var") {
                            //     if (col.value === "ADR") {
                            //         i[col.value] = i['Revenue'] / i['Rooms'];
                            //     }
                            // }

                            /** Calculation for Occ and RevPAR (will Verify with Raghu and Atul )  */
                            // if ((col.parent === "otb_sdly_pickup_var") && (col.display === "Occ" || col.display === "RevPAR")) {
                            //     var dt1 = moment(app_state.primary_stay_range.date_from);
                            //     var dt2 = moment(app_state.primary_stay_range.date_until);
                            //     var daysCount = dt2.diff(dt1, 'days') + 1;

                            //     let system_settings = getAppData(auth, app_state);
                            //     let totalCapacity = system_settings.inventory_settings.current.total_capacity;

                            //     let pickupDaysCt = app_state.workspace_controls.pickUpDays;

                            //     let asOfDate = app_state.as_of_date;
                            //     let pickupDate = moment(asOfDate).subtract(pickupDaysCt, "days");

                            //     if (!pickupDate.isSameOrBefore(moment(dt1))) {
                            //         if (app_state.workspace_controls.pickUpDays > 1) {
                            //             let subsDays = parseInt(pickupDaysCt) + 1;
                            //             let pickupDaysCount = moment(app_state.as_of_date).subtract(subsDays, "days")
                            //             let finalDaysValue = daysCount - moment(pickupDaysCount).format("DD");
                            //             i[col.value] = (col.value === "Occ") ? convertToPercent(i['Rooms'] / (totalCapacity * finalDaysValue)) : i['Revenue'] / (totalCapacity * finalDaysValue);
                            //         } else {
                            //             let v = i[col.value] * daysCount;
                            //             let newdaysCount = getDaysForPickupOcc(app_state, ui_state);
                            //             i[col.value] = v / newdaysCount;
                            //         }
                            //     }

                            // }
                        }

                        /** end Total Calculation */

                        let occValue = (ui_state.active_tiles[0].toLowerCase() === 'pace') ? i.Occ / app_state.workspace_controls.dba : i.Occ;
                        let revParValue = (ui_state.active_tiles[0].toLowerCase() === 'pace') ? i.RevPAR / app_state.workspace_controls.dba : i.RevPAR;
                        revParValue = ((typeof revParValue).toLowerCase() === "string") ? revParValue : displayDataFormat(revParValue, "RevPAR", "");
                        let adrValue = ((typeof i.ADR).toLowerCase() === "string") ? i.ADR : displayDataFormat(i.ADR, "ADR", "");
                        footerCols.push(
                            <TableCell style={sty} className="data-cell" data-b-r-s="thin"
                                data-b-a-c="ffffff"
                                data-a-h="center"
                                data-f-sz="14"
                                data-fill-color="b1e2f4"
                                data-f-color="000000"
                                data-f-bold="true" key={Math.random()}>
                                {(col.value !== "date" && col.value !== 'dow' && col.value !== 'compdate') ?
                                    displayDataFormat(i[col.value], col.value, "")
                                    // i[col.value]
                                    : <></>
                                }
                            </TableCell>
                        )
                    })

                    // });
                }

            }
            count++;
        });

        rows.push(
            < >
                {in_array(ui_state.active_tiles, ["bi_sensitivity_analysis", "restrictions_push_status",
                    "revpar_index", "rm_room_type_ratesend_status", "DailyRatesendStatus"]) ? (
                    <></>
                ) : footerCols}

                {/* {
                (active_tiles[0] === "revpar_index" || bi_sensitivity_analysis) ? 
                <></> :
                footerCols
                
                } */}
            </>
        );

        return rows;
        //full table body is array of rows
        return <TableBody className="table-body">{rows}</TableBody>;
    };

    /////
    const getTotalData = (dataSet, type = 'Total') => {

        // if(type === 'segmentTotal') debugger;
        var date1 = moment(app_state.primary_stay_range.date_from);
        var date2 = moment(app_state.primary_stay_range.date_until);
        var daysCount = (type === 'segment') ? 1 : dataSet.length; //date2.diff(date1, 'days') + 1;
        //  daysCount = (type === 'Total') ? 1 : dataSet.length; //date2.diff(date1, 'days') + 1;
        if (type === 'Total') {
            daysCount = date2.diff(date1, 'days') + 1;
        }
        let dataArray = [];
        if (dataSet.length > 0) {
            // let keysArray = Object.keys(dataSet[0][1]);
            let dataObj = {}
            for (let j = 1; j < dataSet[0].length; j++) {
                let roomsTotal = 0;
                let occ = 0;
                let revPar = 0;
                let revenue = 0;
                let sellRate = 0;
                let total_sellRate;
                var total_sellRate_count = 0;
                let lenforSegment = 1;

                /// for variance 
                let varRoomsTotal = 0;
                let varRoomsSdlyTotal = 0;
                let varOccTotal = 0;
                let varOccSdlyTotal = 0;
                let varRevParTotal = 0;
                let varRevParSdlyTotal = 0;
                let varRevenueTotal = 0;
                let varRevenueSdlyTotal = 0;
                let varAdrTotal = 0;
                let varAdrSdlyTotal = 0;

                for (let i = 0; i < dataSet.length; i++) {
                    let items = dataSet[i];
                    if (app_state.workspace_controls.segmented_view) {
                        if (type === 'Total' && items[0] && items[0].segment === "Total" && items[0].date !== '') {
                            if (items[j] && Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[1].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                    varRevParTotal += parseFloat(items[1].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[1].Occ);
                                    varOccSdlyTotal += (items[2].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[1].Revenue);
                                    varRevenueSdlyTotal += (items[2].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += getCorrectValue(items[j].Rooms);
                                    occ += getCorrectValue(items[j].Occ);
                                    revPar += getCorrectValue(items[j].RevPAR);
                                    revenue += getCorrectValue(items[j].Revenue);
                                    sellRate += getCorrectValue(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                        if (type === 'segment' && items[0] && items[0].segment !== "Total" && items[0].date !== '') {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[1].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                    varRevParTotal += parseFloat(items[1].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[1].Occ);
                                    varOccSdlyTotal += (items[2].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[1].Revenue);
                                    varRevenueSdlyTotal += (items[2].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += getCorrectValue(items[j].Rooms);
                                    occ += getCorrectValue(items[j].Occ);
                                    revPar += getCorrectValue(items[j].RevPAR);
                                    revenue += getCorrectValue(items[j].Revenue);
                                    sellRate += getCorrectValue(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                        if (type === 'segmentTotal' && items[0] && items[0].segment !== "Total") {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[1].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                    varRevParTotal += parseFloat(items[1].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[1].Occ);
                                    varOccSdlyTotal += (items[2].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[1].Revenue);
                                    varRevenueSdlyTotal += (items[2].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    // occ = (convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) / dataSet.length )+ "%"; 
                                    occ = (convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp))) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += getCorrectValue(items[j].Rooms);
                                    occ += getCorrectValue(items[j].Occ) / dataSet.length;
                                    revPar += getCorrectValue(items[j].RevPAR);
                                    revenue += getCorrectValue(items[j].Revenue);
                                    sellRate += getCorrectValue(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                    } else {
                        if (type === 'Total' && items[0] && items[0].segment === "Total" && items[0].date !== '') {
                            if (items[j] && Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[1].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                    varRevParTotal += parseFloat(items[1].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[1].Occ);
                                    varOccSdlyTotal += (items[2].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[1].Revenue);
                                    varRevenueSdlyTotal += (items[2].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += getCorrectValue(items[j].Rooms);
                                    occ += getCorrectValue(items[j].Occ);
                                    revPar += getCorrectValue(items[j].RevPAR);
                                    revenue += getCorrectValue(items[j].Revenue);
                                    sellRate += getCorrectValue(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                        if (type === 'segment' && items[0] && items[0].segment !== "Total" && items[0].date !== '') {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[1].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                    varRevParTotal += parseFloat(items[1].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[1].Occ);
                                    varOccSdlyTotal += (items[2].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[1].Revenue);
                                    varRevenueSdlyTotal += (items[2].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += getCorrectValue(items[j].Rooms);
                                    occ += getCorrectValue(items[j].Occ);
                                    revPar += getCorrectValue(items[j].RevPAR);
                                    revenue += getCorrectValue(items[j].Revenue);
                                    sellRate += getCorrectValue(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                        if (type === 'segmentTotal' && items[0] && items[0].segment !== "Total") {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[1].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                    varRevParTotal += parseFloat(items[1].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[1].Occ);
                                    varOccSdlyTotal += (items[2].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[1].Revenue);
                                    varRevenueSdlyTotal += (items[2].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += getCorrectValue(items[j].Rooms);
                                    occ += getCorrectValue(items[j].Occ);
                                    revPar += getCorrectValue(items[j].RevPAR);
                                    revenue += getCorrectValue(items[j].Revenue);
                                    sellRate += getCorrectValue(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ / dataSet.length,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }

                        if (type !== 'segment' && items[0] && items[0].segment !== "Total" && items[0].date !== '' && items[0].dba !== '') {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[1].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                    varRevParTotal += parseFloat(items[1].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[1].Occ);
                                    varOccSdlyTotal += (items[2].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[1].Revenue);
                                    varRevenueSdlyTotal += (items[2].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += getCorrectValue(items[j].Rooms);
                                    occ += getCorrectValue(items[j].Occ);
                                    revPar += getCorrectValue(items[j].RevPAR);
                                    revenue += getCorrectValue(items[j].Revenue);
                                    sellRate += getCorrectValue(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                    }
                }
                // let sellRateValue = (ui_state.active_tiles[0].toLowerCase() === 'market') ? 0 : '';
                let sRate = round(dataObj.SellRate / total_sellRate_count);
                if (sRate <= 0 && ui_state.active_tiles[0].toLowerCase() === 'market' && app_state.workspace_controls.scopeType === 'Dba') {
                    // sRate = "";
                }
                let adrTotal = dataObj.ADR;
                if (ui_state.active_tiles[0] === "market_summary" || ui_state.active_tiles[0] === "hotel_summary") {
                    daysCount = dataSet.length;
                    sRate = round(dataObj.SellRate / daysCount);
                }
                let occTotal = type === 'Total' ? dataObj.Occ / daysCount : dataObj.Occ;
                if (typeof dataObj.Occ !== "string" && typeof dataObj.RevPAR !== "string") {
                    var newValues = {
                        Occ: occTotal,
                        RevPAR: round(dataObj.RevPAR / daysCount),
                        SellRate: sRate,
                        Revenue: round(dataObj.Revenue),
                        ADR: round(dataObj.ADR)
                    }
                    dataObj = { ...dataObj, ...newValues };
                }
                if (dataObj.id === 'otb_end_sdly') {
                    delete dataObj["SellRate"];
                }
                dataArray.push(dataObj);
            }
        }

        //    console.log("dataArraydataArray ",dataArray);

        let marketTotalIndex = dataArray.findIndex((it) => it.id === "market_sdly_diff_report");
        if (marketTotalIndex >= 0) {
            let currentIndex = dataArray.findIndex((it) => it.id === "market_report");
            let sdlyIndex = dataArray.findIndex((it) => it.id === "market_sdly_report");
            if (dataArray[currentIndex] && dataArray[sdlyIndex].ADR) {
                dataArray[marketTotalIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
            }
        }
        let sdlyPickupTotalIndex = dataArray.findIndex((it) => it.id === "sdly_pickup_diff");
        if (sdlyPickupTotalIndex >= 0) {
            let currentIndex = dataArray.findIndex((it) => it.id === "sdly_actual");
            let sdlyIndex = dataArray.findIndex((it) => it.id === "sdly");
            if (dataArray[currentIndex] && dataArray[sdlyIndex].ADR) {
                dataArray[sdlyPickupTotalIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
            }
        }
        let sdlyPickupIndex = dataArray.findIndex((it) => it.id === "otb_sdly_pickup_var");
        if (sdlyPickupIndex >= 0) {
            let currentIndex = dataArray.findIndex((it) => it.id === "otb_end_sdly");
            let sdlyIndex = dataArray.findIndex((it) => it.id === "otb_sdly");
            if (dataArray[currentIndex] && dataArray[sdlyIndex].ADR) {
                dataArray[sdlyPickupIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
            }
        }

        let diffIndex = dataArray.findIndex((it) => it.id === "otb_diff_sdly");
        if (diffIndex >= 0) {
            let currentIndex = dataArray.findIndex((it) => it.id === "otb_primary_report");
            currentIndex = (currentIndex > -1) ? currentIndex : dataArray.findIndex((it) => it.id === "otb_current");
            let sdlyIndex = dataArray.findIndex((it) => it.id === "otb_sdly");
            if (dataArray[currentIndex] && dataArray[sdlyIndex].ADR) {
                dataArray[diffIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
            }
        }

        let diffTotalIndex = dataArray.findIndex((it) => it.id === "difference_sdly");
        if (diffTotalIndex >= 0) {
            let currentIndex = dataArray.findIndex((it) => it.id === "otb_primary_report");
            let sdlyIndex = dataArray.findIndex((it) => it.id === "sdly");
            if (dataArray[currentIndex] && dataArray[sdlyIndex].ADR) {
                dataArray[diffTotalIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
            }
        }

        let diffVarianceIndex = dataArray.findIndex((it) => it.id === "variance_pickup");
        if (diffVarianceIndex >= 0) {
            // let currentIndex = dataArray.findIndex((it) => it.id === "otb_primary_report");
            // let sdlyIndex = dataArray.findIndex((it) => it.id === "otb_pickup_report");
            // if (dataArray[currentIndex] && dataArray[sdlyIndex].ADR) {
            //     dataArray[diffVarianceIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
            // }

            dataArray[diffVarianceIndex].ADR = dataArray[diffVarianceIndex].Revenue / dataArray[diffVarianceIndex].Rooms;
        }
        /** Calculation for Pickup and Market when mode = date  */
        // if(in_array(ui_state.active_tiles,["pickup","market"])){
        //         let system_settings = getAppData(auth, app_state);
        //         let totalCapacity = system_settings.inventory_settings.current.total_capacity;
        //         dataArray[0]['Occ'] = dataArray[0]['Rooms'] / (totalCapacity * 20) ;
        // }
        /** end  */

        if (ui_state.active_tiles[0] === "pace" && dataArray[1]) {
            let paceAdrDiff = dataArray[0]["ADR"] - dataArray[1]["ADR"];
            let paceRevparDiff = dataArray[0]["RevPAR"] - dataArray[1]["RevPAR"];

            dataArray.forEach((dataItem, i) => {
                if (i === 2) {
                    dataArray[i].ADR = paceAdrDiff;
                    dataArray[i].RevPAR = paceRevparDiff;
                }
            })
        }

        if (type === "Total") {
            dataArray = otbTransformTotal(dataArray, ui_state.active_tiles, workspace_controls, dataSet);

        }
        return dataArray;
    }
    const getTotalData_10Oct = (dataSet) => {
        var date1 = moment(app_state.primary_stay_range.date_from);
        var date2 = moment(app_state.primary_stay_range.date_until);
        var daysCount = date2.diff(date1, 'days') + 1;
        const dataArray = [];
        if (dataSet.length > 0) {
            let keysArray = Object.keys(dataSet[0][1]);
            let dataObj = {}
            for (let j = 1; j < dataSet[0].length; j++) {
                let roomsTotal = 0;
                let occ = 0;
                let revPar = 0;
                let revenue = 0;
                let sellRate = 0;
                let total_sellRate;
                var total_sellRate_count = 0;
                let lenforSegment = 1;

                /// for variance 
                let varRoomsTotal = 0;
                let varRoomsSdlyTotal = 0;
                let varOccTotal = 0;
                let varOccSdlyTotal = 0;
                let varRevParTotal = 0;
                let varRevParSdlyTotal = 0;
                let varRevenueTotal = 0;
                let varRevenueSdlyTotal = 0;
                let varAdrTotal = 0;
                let varAdrSdlyTotal = 0;

                for (let i = 0; i < dataSet.length; i++) {
                    let items = dataSet[i];
                    if (items[0] && items[0].segment !== "Total" && items[0].date !== '' && items[0].dba !== '') {
                        if (Object.keys(items[j]).length) {
                            let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                            if (items[j].SellRate !== '') {
                                total_sellRate = items[j].SellRate;
                                total_sellRate_count++;
                            } else {
                                total_sellRate = 0;
                                total_sellRate_count++;
                            }
                            let adrTotal;
                            let primaryValtotal
                            if (typeof items[j].Rooms === "string") {
                                // total for variance /////
                                varRoomsTotal += parseFloat(items[1].Rooms);
                                varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                varRevParTotal += parseFloat(items[1].RevPAR);
                                varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                let revParTmp = (varRevParTotal / total_sellRate_count);
                                let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                varOccTotal += (items[1].Occ);
                                varOccSdlyTotal += (items[2].Occ);
                                let occTmp = (varOccTotal / total_sellRate_count);
                                let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                varRevenueTotal += (items[1].Revenue);
                                varRevenueSdlyTotal += (items[2].Revenue);
                                let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                            } else {
                                roomsTotal += (items[j].Rooms);
                                occ += parseFloat(items[j].Occ);
                                revPar += parseFloat(items[j].RevPAR);
                                revenue += parseFloat(items[j].Revenue);
                                sellRate += parseFloat(total_sellRate);
                                adrTotal = (revenue / roomsTotal);
                                adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                            }
                            dataObj = {
                                "Rooms": roomsTotal,
                                "Occ": occ,
                                "RevPAR": revPar,
                                "Revenue": revenue,
                                "SellRate": sellRate,
                                "ADR": adrTotal //(revenue / roomsTotal)
                            }
                            if ('SellRate' in items[j] === false) {
                                delete dataObj["SellRate"];
                            }
                            if (Object.keys(items[j]).includes("Restrictions")) {
                                dataObj = { "Restrictions": "" };
                            }
                        }
                        lenforSegment++;
                    }
                }
                // let sellRateValue = (ui_state.active_tiles[0].toLowerCase() === 'market') ? 0 : '';
                let sRate = round(dataObj.SellRate / total_sellRate_count);
                if (sRate <= 0 && ui_state.active_tiles[0].toLowerCase() === 'market' && app_state.workspace_controls.scopeType === 'Dba') {
                    // sRate = "";
                }
                let adrTotal = dataObj.ADR;
                if (ui_state.active_tiles[0] === "market_summary" || ui_state.active_tiles[0] === "hotel_summary") {
                    daysCount = dataSet.length;
                    sRate = round(dataObj.SellRate / daysCount);
                }
                let occTotal = dataObj.Occ / daysCount;
                if (typeof dataObj.Occ !== "string" && typeof dataObj.RevPAR !== "string") {
                    var newValues = {
                        Occ: occTotal,
                        RevPAR: round(dataObj.RevPAR / daysCount),
                        SellRate: sRate,
                        Revenue: round(dataObj.Revenue),
                        ADR: round(dataObj.ADR)
                    }
                    dataObj = { ...dataObj, ...newValues };
                }
                dataArray.push(dataObj);
            }
        }

        if (ui_state.active_tiles[0] === "pace" && dataArray[1]) {
            let paceAdrDiff = dataArray[0]["ADR"] - dataArray[1]["ADR"];
            let paceRevparDiff = dataArray[0]["RevPAR"] - dataArray[1]["RevPAR"];

            dataArray.forEach((dataItem, i) => {
                if (i === 2) {
                    dataArray[i].ADR = paceAdrDiff;
                    dataArray[i].RevPAR = paceRevparDiff;
                }
            })
        }

        if (ui_state.active_tiles[0] === "bi_pace_comparison" && dataArray[7]) {
            let marketDiffAdr = dataArray[5]['ADR'] - dataArray[6]['ADR'];
            dataArray[7]['ADR'] = marketDiffAdr;
            dataArray[2]['ADR'] = dataArray[0]['ADR'] - dataArray[1]['ADR'];;
            dataArray[4]['ADR'] = dataArray[3]['ADR'] - dataArray[1]['ADR'];;
        }

        if (ui_state.active_tiles[0] === "bi_pickup_analysis" && dataArray[6]) {
            dataArray[6]['ADR'] = dataArray[4]['ADR'] - dataArray[5]['ADR'];;

        }

        return dataArray;
    }

    ////////
    /////////
    const compsetTotal = (obj, key, index, count) => {
        let total = 0;
        let daysInMonth = moment().daysInMonth();
        let totalDays = 0;
        for (let i = 0; i < obj.length; i++) {
            total = parseFloat(total + obj[i][count][key]);
            totalDays++;
        }
        total = (app_state.workspace_controls.scopeType !== 'Dba') ? round(total / totalDays) : round(total / 366);
        return displayDataFormat(total.toFixed(2), 'compset', '');
    };

    /////////




    const displayDataFormat = (val, key, type, tile = null) => {
        // if(!val){ val = 0; }
        key = key.toLowerCase();
        let value = val;
        let currSymbol = ui_state.currency_symbol;
        if (key === "roomsrestrictions") {
            return val;
        }
        if (val !== '' && key !== 'segment' && key !== 'compdate') {
            if (key.toLowerCase() !== "rooms" && key.toLowerCase() !== "dba" && key.toLowerCase() !== "revenue") {
                val = Number.isInteger(val) ? val.toFixed(2) : val;
            }
            if (type === "") {
                if (key === "date") {
                    val = moment(val).format("MMM DD");
                } else if (key === "dow" || key === "date_market_summary" || key === "month" || key === "dba") {
                    val = val;
                } else if (key === "occ" || key === "tcocc") {
                    val = parseFloat(val).toFixed(2) + "%";
                } else if (key === "sellrate") {
                    if (val && !val.toString().includes("%")) {
                        val = currSymbol + Math.round(val);
                    } else {
                        val = val;//currSymbol + Math.round(val);
                    }
                } else if ((key === "adr" || key === "revpar" || key === "sellrate_xx") && val !== "") {
                    // debugger;
                    if (val) {
                        if (!val.toString().includes("%") && (key.toLowerCase() === "sellrate" || key.toLowerCase() === "adr" || key.toLowerCase() === "revpar")) {
                            val = parseFloat(val);
                        }
                    }
                    if (typeof val !== "string" && (key === "sellrate" || key === "adr" || key === "revpar")) {
                        val = (!isFinite(val)) ? parseFloat(0.00).toFixed(2) : parseFloat(val).toFixed(2);
                        val = currSymbol + (val).toLocaleString(undefined, { minimumFractionDigits: 2 });
                    } else {
                        val = val;
                    }

                } else if (key === "revenue") {
                    if (typeof val === "string") {
                        currSymbol = "";
                    }
                    if (val) {
                        val = currSymbol + (val).toLocaleString(undefined, { minimumFractionDigits: 2 });
                    }
                } else if (key === "rooms") {
                    val = (val) ? val.toLocaleString() : 0;
                } else {
                    val = currSymbol + val;
                }
            } else if (type !== "" && typeof val !== "string") {
                val = val + "%";
            }
            if (key !== "rooms" && key !== "dba") {

                if (value >= 0 || typeof value === "string") {
                    val = val;
                } else {
                    if (Number.isFinite(value)) {
                        val = "(" + val.toString().replaceAll("-", "") + ")";
                    } else {
                        val = currSymbol + parseFloat(0.00).toFixed(2);
                    }
                }

            }
        }
        if (key === 'segment') {
            val = truncate(val, 17);
        }
        if (key === "revpar_index" || key === "revpar_index_ly" || key === "sdly_revpar_index") {
            val = val.replaceAll('$', '');
        }
        if (key === 'compdate') {
            // let compVal =  dateFormat(val, "MMM DD, YYYY ddd");
            val = (val) ? dateFormat(val, "MMM DD YYYY,  ddd") : '';
            // debugger;
        }
        if (tile === "bi_sensitivity_analysis" && value === 0 && key !== "dba") {
            val = "";
        }

        return val;
    };

    const displayDataFormat_backup = (val, key, type) => {
        // if(!val){ val = "0.00"; }
        key = key.toLowerCase();
        let value = val;
        let currSymbol = ui_state.currency_symbol;
        if (val !== '' && key !== 'segment' && key !== 'compdate') {
            if (key.toLowerCase() !== "rooms" && key.toLowerCase() !== "dba" && key.toLowerCase() !== "revenue") {
                val = Number.isInteger(val) ? val.toFixed(2) : val;
            }
            if (type === "") {
                if (key === "date") {
                    val = moment(val).format("MMM DD");
                } else if (key === "dow" || key === "date_market_summary" || key === "month" || key === "dba") {
                    val = val;
                } else if (key === "occ" || key === "tcocc") {
                    val = parseFloat(val).toFixed(2) + "%";
                } else if ((key === "adr" || key === "revpar" || key === "sellrate") && val !== "") {
                    if (typeof val !== "string" || key === "sellrate" || key === "adr" || key === "revpar") {
                        val = (!isFinite(val)) ? parseFloat(0.00).toFixed(2) : parseFloat(val).toFixed(2);
                        val = currSymbol + (val).toLocaleString(undefined, { minimumFractionDigits: 2 });
                    } else {
                        val = val;
                    }

                } else if (key === "revenue") {
                    if (typeof val === "string") {
                        currSymbol = "";
                    }
                    val = currSymbol + (val).toLocaleString(undefined, { minimumFractionDigits: 2 });

                } else if (key === "rooms") {
                    val = val.toLocaleString();
                } else {
                    val = currSymbol + val;
                }
            } else if (type !== "" && typeof val !== "string") {
                val = val + "%";
            }
            if (key !== "rooms" && key !== "dba") {

                if (value >= 0 || typeof value === "string") {
                    val = val;
                } else {
                    if (Number.isFinite(value)) {
                        val = "(" + val.toString().replaceAll("-", "") + ")";
                    } else {
                        val = currSymbol + parseFloat(0.00).toFixed(2);
                    }
                }

            }
        }
        if (key === 'segment') {
            val = truncate(val, 17);
        }
        if (key === 'compdate') {
            // let compVal =  dateFormat(val, "MMM DD, YYYY ddd");
            val = dateFormat(val, "MMM DD YYYY,  ddd");
            // debugger;
        }
        return val;
    };

    const totalCalulate = (obj, key, index, count) => {
        let dataIndex = index + 1;
        var total = 0;
        let symbol = "$";
        var totalRevenue = 0;
        for (let i = 0; i < obj.length; i++) {
            let dataInnerObj = obj[i][count];
            totalRevenue = parseFloat(total) + parseFloat(dataInnerObj.Revenue);
            if (key.toLowerCase() === "rooms") {
                symbol = "";
                total = parseFloat(total) + parseFloat(dataInnerObj.Rooms);
            }
            if (key.toLowerCase() === "occ") {
                symbol = "%";
                total = (parseFloat(total) + parseFloat(dataInnerObj.Occ)).toFixed(2);
            }
            if (key.toLowerCase() === "adr") {
                total = (parseFloat(total) + parseFloat(dataInnerObj.ADR)).toFixed(2);
            }
            if (key.toLowerCase() === "revpar") {
                total = (parseFloat(total) + parseFloat(dataInnerObj.RevPAR)).toFixed(
                    2
                );
            }
            if (key.toLowerCase() === "revenue") {
                total = (parseFloat(total) + parseFloat(dataInnerObj.Revenue));
            }
            if (key.toLowerCase() === "sellrate" && dataInnerObj.SellRate !== "") {
                total = parseFloat(total) + parseFloat(dataInnerObj.SellRate);
            }
        }

        if (
            key.toLowerCase() === "adr" ||
            key.toLowerCase() === "occ" ||
            key.toLowerCase() === "sellrate" ||
            key.toLowerCase() === "revpar"
        ) {
            total = (total / 12);
        }
        return displayDataFormat(round(total), key, "");
    };

    const calculateColWidth = (tile) => {
        let colWidth = [];
        let dataColumn = (tile === "compset") ? 20 : 12;
        for (let i = 1; i <= data_cols.length; i++) {
            if (i <= 2) {
                colWidth.push(10);
            } else {
                colWidth.push(dataColumn)
            }
        }
        return (colWidth.toString()).trim();
    }

    const hasInfoBoxReportTable = () => {
        let infoText = false;
        const { segmentType } = app_state.workspace_controls
        // console.log(ui_state.active_tiles[0]);
        // debugger;
        if (permissionDetails.permissions.rate_plan === 0 && segmentType === "rate_plan" && ui_state.active_tiles[0] === "otb_v2_multi_segment") {
            infoText = 'Rate Plan is not enabled for this property! Please contact success@lodgiq.com for further details.';
            // return true;
        }
        if (permissionDetails.permissions.room_type === 0 && segmentType === "room_type" && ui_state.active_tiles[0] === "otb_v2_multi_segment") {
            infoText = 'Room Type is not enabled for this property! Please contact success@lodgiq.com for further details.';
            // return true;
        }
        if (permissionDetails.permissions.room_type === 0 && segmentType === "room_class" && ui_state.active_tiles[0] === "otb_v2_multi_segment") {
            infoText = 'Room Class is not enabled for this property! Please contact success@lodgiq.com for further details.';
            // return true;
        }
        return infoText;
    }


    let footerRowClass = (ui_state.reports_dpbs.selectedCategory.includes("Hotel")) ? "report-filter" : "report-filter-segments";
    footerRowClass = (ui_state.active_tiles[0] === "bi_pace_comparison" || ui_state.active_tiles[0] === "bi_pickup_analysis") ? footerRowClass : "";

    if (['loaded', 'sortedCols'].includes(compLoadStatus) && compLoad && displayData.length && data_setup.length) {
        let newRenderBodyData = renderBody({ data_setup: data_setup });
        setRenderBodyData(newRenderBodyData);
        setCompLoadStatus('loaded..');
    }

    let noScroll = ui_state.active_tiles[0] === "rm_room_type_ratesend_status" ? "" : "no-scroll ";
    let tableContClass = renderBodyData.length < 12 ? `table-scrollable ${noScroll}` : "table-scrollable ";
    tableContClass += ` ${footerRowClass} `
    const TableComponentsValue = useCallback(TableComponents(tableContClass, active_tiles_className, data_setup, app_state), [tableContClass, active_tiles_className, data_setup])
    return (
        <Paper sx={{ width: "100%" }} elevation={0}>
            {/* <button onClick={onDownload} className="primary"> Download</button> */}
            {(ui_state.active_tiles[0] === "bi_pickup_analysis" || ui_state.active_tiles[0] === "bi_pace_comparison") ? <ReportFilter applyFilterHandler={applyFilterHandler} /> : ""}
            {!hasInfoBoxReportTable() && (
                <>
                    {
                        (compLoadStatus === 'sortingCols' && compLoad && displayData.length && data_setup.length) ? <Alert sx={{ position: 'absolute', width: 'calc(100% - 8px)', zIndex: 999, textAlign: 'center', opacity: 0.95, borderRadius: 0, lineHeight: 1, p: 0, pl: 1, left: 0, top: 80 }} severity="info" color='info' textAlign='center' icon={false}>Sorting data...</Alert> : ''
                    }
                    <TableVirtuoso
                        ref={tableVirtuoso}
                        data={renderBodyData.length ? new Array(renderBodyData.length - 1) : [1]}
                        components={TableComponentsValue}
                        fixedHeaderContent={() => (
                            renderHeader()
                        )}
                        fixedFooterContent={!renderBodyData.length > 3 ?
                            () => (<></>)
                            :
                            () => (
                                <TableRow sx={{
                                    position: "sticky",
                                    bottom: 0,
                                    zIndex: 3,
                                    backgroundColor: "#fff",
                                }}
                                    className="table-row footer-row">
                                    {renderBodyData.length && data_setup.length ? renderBodyData[renderBodyData.length - 1] : []}
                                </TableRow>
                            )}

                        itemContent={
                            (index) => (
                                ((compLoadStatus === 'loaded..' || compLoadStatus === 'sortedCols') && compLoad && displayData.length && data_setup.length) ?
                                    renderBodyData[index]
                                    :
                                    ((index === 0 ? (
                                        <TableCell colSpan={8} sx={{ p: 0, borderBottom: 'none', position: 'absolute', width: '100%' }}>
                                            <Alert severity="info">
                                                {['bi_pace_comparison', 'bi_pickup_analysis'].includes(active_tiles[0]) && <AlertTitle>Top Segments are not set for this data source in the property settings, please contact success@lodgiq.com for further details.</AlertTitle>}
                                                {!['bi_pace_comparison', 'bi_pickup_analysis'].includes(active_tiles[0]) && <AlertTitle>No data found</AlertTitle>}
                                            </Alert>
                                        </TableCell>
                                    ) : null))
                            )
                        }
                    />
                </>
            )}
            {hasInfoBoxReportTable() && <Box sx={{ p: 1 }}>
                <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    {hasInfoBoxReportTable()}
                </Alert>
            </Box>}

        </Paper>
    );
}

export default TableReports;

const TableComponents = (tableContClass, active_tiles_className, data_setup, app_state) => ({

    Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} style={{ ...props.style, scrollPaddingLeft: '460px', scrollPaddingBlock: '120px 30px' }} ref={ref} className={`${tableContClass} `} />), //className={"table-scrollable table-scrollable report-filter" +   'footerRowClass'}
    Table: (props) => <Table {...props} id="mainTable" stickyHeader size="small" aria-label="a dense table" className={active_tiles_className + " react-dynamic-table"} />,
    TableHead: React.forwardRef((props, ref) => <TableHead ref={ref} {...props} className="table-head" style={{ ...props.style, zIndex: 2 }} />),
    TableRow: (props) => {
        let ind = props['data-index'];
        let item = data_setup && data_setup.length ? data_setup[ind] : {};
        let allRowSegmentsHaveTotal = false;
        if (data_setup && data_setup.length && data_setup.length > 2) {
            allRowSegmentsHaveTotal = data_setup[0][0].segment === 'Total' && data_setup[1][0].segment === 'Total'
        }
        let total_row = item && item[0] && item[0].segment === 'Total' && !allRowSegmentsHaveTotal ? ' total-row' : '';
        return (<TableRow  {...props} className={`${props['data-index'] % 2 ? 'table-row even-row' + total_row : 'table-row odd-row' + total_row}`} />);
    },
    TableBody: React.forwardRef((props, ref) => <TableBody className="table-body" {...props} ref={ref} />),
})

const TableRowSet = (props) => {
    // debugger; 
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let { dataSet } = props;
    let tm = props.compRef;

    const dispatch = useDispatch();
    const [renderCount, setRenderCount] = useState(1);
    const chunkLength = 50;
    const dataSize = dataSet.length;
    let totalRow = dataSet.slice(-1);
    const compRef = useRef({
        intervalId: null,
        renderItems: (dataSize > chunkLength) ? dataSet.slice(0, chunkLength).concat(totalRow) : dataSet,
        chunks: Math.ceil(dataSize / chunkLength),
        index: 1,
        renderCount: 0
    });

    useEffect(() => {
        let isSubscribed = true;
        if (compRef.current.index >= compRef.current.chunks) {
            clearInterval(compRef.current.intervalId);
            // debugger
            if (app_state.workspace_controls.disableExport) {
                // debugger;
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            workspace_controls: { ...app_state.workspace_controls, disableExport: false }
                        }
                    }
                })
                // dispatch(updateAppState("exportStatus", {
                //     app_state: {
                //         workspace_controls: { ...app_state.workspace_controls, disableExport: false }
                //     }
                // }));
            }

        }
        return () => (isSubscribed = false);
    }, [renderCount]);

    useEffect(() => {

        let isSubscribed = true;
        if (compRef.current.chunks > 1) {
            dispatch(updateAppState("exportStatus", {
                app_state: {
                    workspace_controls: { ...app_state.workspace_controls, disableExport: true }
                }
            }));
            compRef.current.intervalId = setInterval(() => {
                compRef.current.renderItems.splice(-1);
                let newArray = dataSet.slice(chunkLength * compRef.current.index, chunkLength * (compRef.current.index + 1));
                if (compRef.current.chunks === compRef.current.index + 1) {
                    compRef.current.renderItems = compRef.current.renderItems.concat(newArray);
                } else {
                    compRef.current.renderItems = compRef.current.renderItems.concat(newArray, totalRow);
                }

                compRef.current.renderCount = compRef.current.index;
                setRenderCount(compRef.current.index);
                compRef.current.index++;
            }, 90);
            return () => clearInterval(compRef.current.intervalId);
        }
        return () => (isSubscribed = false);
    }, []);

    if (!compRef.current.renderItems.length) {
        return (
            <>Loading...</>
        );
    }
    let ri = compRef.current.renderItems;
    // debugger;
    return (compRef.current.renderItems)
};

//Helper functions
const getFilteredData = (params) => {
    // debugger;
    // {data:data_setup, sortBy: 'Occ', parent: 'otb_prior'};
    const { data, columns } = params;
    const filteredColumns = getFilteredColumns(columns);
    let filtereddData = deepCopy(data);
    if (!filteredColumns.length) {
        return filtereddData;
    }
    // debugger
    filtereddData = [];
    let pushedRow = [];
    data.map((row, i) => {
        for (let j = 0; j < row.length; j++) {
            for (let k = 0; k < filteredColumns.length; k++) {
                let { parent, value, filter } = filteredColumns[k];
                if (row[j].id === parent) {
                    // debugger
                    let rowData = null;
                    if (filter) {
                        let dataValue = row[j][value];
                        if (typeof dataValue !== 'number' && dataValue.indexOf('%') !== -1) {
                            dataValue = Number(dataValue.replaceAll(/%/g, ''));
                        }
                        if (filter.gte && filter.lte) {
                            if (dataValue >= Number(filter.gte) && dataValue <= filter.lte) {
                                rowData = row;
                            }
                        } else {
                            if (filter.gte && dataValue >= Number(filter.gte)) {
                                rowData = row;
                            }
                            if (filter.lte && dataValue <= filter.lte) {
                                rowData = row;
                            }
                        }

                        if (pushedRow.indexOf(i) === -1 && rowData) {
                            filtereddData.push(rowData);
                            pushedRow.push(i);
                        }
                    }
                }
            }
        }
    });
    // debugger;
    return filtereddData;
}


const sortData = (params) => {
    // {data:data_setup, sortBy: 'Occ', parent: 'otb_prior'};
    const { data, sortBy, parent, order, workspace_controls } = params;
    const sortedData = deepCopy(data);
    let finalSortedData = [];
    if (!order) {
        return sortedData;
    }
    let sortedParentIndex = null;
    if (sortedData[0]) {
        sortedData[0].map((item, i) => {
            if (item.id === parent) {
                sortedParentIndex = i;
            }
        });
        let weekdaysShort = moment.weekdaysShort();
        // debugger;
        let sortedDates = [];
        let tempSortedDates = deepCopy(sortedData);
        let tempSortedDatesTotal = [];
        let tempSortedDatesBlank = [];
        let dba_date = workspace_controls.scopeType.toLowerCase();
        if (workspace_controls.segmented_view) {
            tempSortedDatesTotal = deepCopy(sortedData.filter(d => d[0].segment === 'Total'));
            tempSortedDates = deepCopy(sortedData.filter(d => d[0][dba_date] !== '' && d[0].segment !== 'Total'));
            tempSortedDatesBlank = deepCopy(sortedData.filter(d => d[0][dba_date] === ''));
            tempSortedDatesTotal = arraySort(tempSortedDatesTotal);

        } else {
            tempSortedDates = arraySort(sortedData);
            // deepCopy(sortedData);
        }

        function arraySort(arr) {
            let arr_copy = deepCopy(arr);
            arr_copy.sort((a, b) => {
                if (sortedParentIndex !== null) {
                    let A = a[sortedParentIndex][sortBy];
                    let B = b[sortedParentIndex][sortBy];
                    //This is added for Pickup variance to sdly column
                    A = (A) ? A : 0;
                    B = (B) ? B : 0;

                    if (typeof A !== 'number' && A.indexOf('%') !== -1) {
                        A = Number(A.replaceAll(/%/g, ''));
                    }
                    if (typeof B !== 'number' && B.indexOf('%') !== -1) {
                        B = Number(B.replaceAll(/%/g, ''));
                    }
                    //---
                    if ((sortBy === 'date') || (sortBy === 'compdate')) {
                        A = moment(A).format('x');
                        B = moment(B).format('x');
                    }

                    if (sortBy === 'dow') {
                        A = weekdaysShort.indexOf(A);
                        B = weekdaysShort.indexOf(B);
                    }

                    if (order === 'asc') {
                        return A - B;
                    }

                    if (order === 'desc') {
                        return B - A;
                    }
                }
            });
            return arr_copy;
        }

        // let sortedTotal = arraySort(tempSortedDatesTotal);
        // let sortedSegment = arraySort(tempSortedDates);
        // tempSortedDates.sort((a, b) => {
        //     if (sortedParentIndex !== null) {
        //         let A = a[sortedParentIndex][sortBy];
        //         let B = b[sortedParentIndex][sortBy];
        //         //This is added for Pickup variance to sdly column
        //         A = (A) ? A : 0;
        //         B = (B) ? B : 0;

        //         if (typeof A !== 'number' && A.indexOf('%') !== -1) {
        //             A = Number(A.replaceAll(/%/g, ''));
        //         }
        //         if (typeof B !== 'number' && B.indexOf('%') !== -1) {
        //             B = Number(B.replaceAll(/%/g, ''));
        //         }
        //         //---
        //         if ((sortBy === 'date') || (sortBy === 'compdate')) {
        //             A = moment(A).format('x');
        //             B = moment(B).format('x');
        //         }

        //         if (sortBy === 'dow') {
        //             A = weekdaysShort.indexOf(A);
        //             B = weekdaysShort.indexOf(B);
        //         }

        //         if (order === 'asc') {
        //             return A - B;
        //         }

        //         if (order === 'desc') {
        //             return B - A;
        //         }
        //     }
        // });

        if (workspace_controls.segmented_view) {
            tempSortedDatesTotal.map((item, i) => {
                if (!sortedDates.includes(item[0][dba_date])) {
                    sortedDates.push(item[0][dba_date]);
                    let dateFilter = tempSortedDates.filter((d) => d[0][dba_date] === item[0][dba_date] && d[0].segment !== 'Total');
                    let dateFilterTotal = tempSortedDatesTotal.filter((d) => d[0][dba_date] === item[0][dba_date] && d[0].segment === 'Total');
                    dateFilter = arraySort(dateFilter);
                    // debugger;
                    finalSortedData = [...finalSortedData, ...dateFilter, ...dateFilterTotal];
                }
            });
            finalSortedData = [...finalSortedData, ...tempSortedDatesBlank];
        } else {
            finalSortedData = tempSortedDates;
        }

    }
    return finalSortedData;
}

const getSortedColumn = (columns) => {
    //Get sorted column details from column configuration
    let sortedColumn = {};
    columns.map((main, i) => {
        main.subcolumns.map((sub, j) => {
            //it depends on property sorted
            if (sub.sorted) {
                sortedColumn = sub;
            }
        });
    });
    return sortedColumn;
};

const getFilteredColumns = (columns) => {
    //Get filter column details from column configuration
    let filteredColumns = [];
    columns.map((main, i) => {
        let subcols = main.subcolumns;
        for (let j = 0; j < subcols.length; j++) {
            let sub = subcols[j];
            if (sub.filterable && sub.filter && (sub.filter.gte || sub.filter.lte)) {
                filteredColumns.push(sub);
            }
        }
    });
    // debugger;
    return filteredColumns;
};
//Helper components
const ColumnSorting = (props) => {
    const { colItem, sortable, onOrderByChange, sorted, label, value } = props;

    const onClickHandler = (ev) => {
        // debugger;
        if (!sorted) {
            colItem.sorted = 'asc';
        }
        if (sorted === 'asc') {
            colItem.sorted = 'desc';
        }
        if (sorted === 'desc') {
            colItem.sorted = null;
        }
        onOrderByChange(colItem);
    }

    if (!sortable) {
        return (<></>);
    }
    return (
        <div style={{ position: 'relative' }}>
            <Tooltip title={"Sort by " + label} arrow>
                <Button className={props.className + ' order-' + colItem.sorted} onClick={onClickHandler}>
                    <ArrowDropUpIcon className='order-arrow order-arrow-up' />
                    <ArrowDropDownIcon className='order-arrow order-arrow-down' />
                </Button>
            </Tooltip>

        </div>

    );
}