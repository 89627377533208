import React, { useState, useEffect, useReducer } from 'react';

import Box from '@mui/material/Box';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import moment from 'moment';
import {
    Grid, Button, LinearProgress, Badge, Modal, IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import {
    CloseSharp as CloseSharpIcon,
    ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
    ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
    CheckSharp as CheckSharpIcon
}
    from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { settingsReducers } from './reducers';
import { getPricingDataFormatted } from '../DynamicTable/tilesData/pricing';
import { API_REGISTRY } from '../../app/config';
import { deepCopy } from '../../app/util';
import { getForecastNonSegmentedDataNew } from '../DynamicTable/tilesData/forecast';
import dataService from '../../services/data.service';
import { updateAppState } from '../../actions/appstate';


export default function SendRate(props) {
    const dispatch = useDispatch();
    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;
    const { room_settings } = app_state;
    const { isRoomTypePricing } = app_state.workspace_controls;
    const [numUpdates, setNumUpdates] = useState(props.rateRows.length);
    const [ratePushSucessOpen, setRatePushSuccessOpen] = useState(false);
    const [ratePushFail, setRatePushFail] = useState(false);

    const initalState = {
        data: {},
        rateCount: 10,
        ui: {
            status: "idle",
            message: "",
        }

    };
    const [settingsState, dispatchSettings] = useReducer(settingsReducers, initalState);
    const { ui, data, rateCount } = settingsState;

    // const [rows, setRows] = React.useState(settingsState.data);
    const [rows, setRows] = React.useState(props.rateRows);

    useEffect(() => {
        setRows(props.rateRows)
        // if(props.rateRows && props.rateRows.length){
        //     setRows( [...props.rateRows.map(item=>{
        //         item.override_rate= null
        //         return item;
        //     })]);
        // }
    })
    //debugger;
    // const handleDelete = (index, id) => {
    //     const rateRows = rows.filter(
    //         (rows) => rows.id !== id
    //     );
    //     setRows(prev=>{
    //         return rateRows;
    //     });
    //     //  dispatch(updateAppState("confirmed_rates_success", {
    //     //         app_state: {
    //     //             pricing_override_rates: {...app_state.pricing_override_rates},
    //     //             roomtype_pricing_overrides:{...app_state.roomtype_pricing_overrides}
    //     //         },
    //     //         ui_state: { pricingRateModalOpen: false }
    //     //     }))
    // //   dispatchSettings({ type: "rateCountUpdate", checkedLength:rateRows.length,data:rateRows  });
    // // 
    // }

    const handleRatePush = (dayData) => {
        let { uri } = API_REGISTRY["SendRatePush"];

        uri += app_state.property_id + "/";
        let request = {};
        const pricingData = getPricingDataFormatted(app_state);
        const forecastData = getForecastNonSegmentedDataNew();
        const otbData = deepCopy(dataService.getSeriesById("OSR").data.data);
        let rates = {}, otb = {}, forecast = {};
        if (dayData) {
            console.log("");
            if (isRoomTypePricing === false) {
                request = {
                    ...API_REGISTRY["SendRatePush"].payload,
                    "user_id": user.user_id,
                    "pid": app_state.property_id,
                    "asof_date": app_state.primary_stay_range.asof_date,


                }
                request["segment_type"] = "market_code";
                request["rate_code"] = "TBAR";
                pricingData.map(pricing => {
                    if (pricing.stay_date === dayData.stay_date) {
                        rates = pricing;
                    }
                })
                forecastData.map(fdata => {
                    if (fdata.index.date === dayData.stay_date) {
                        forecast = fdata.HotelTotal.primary;
                    }
                })
                otbData.map(onthebooks => {
                    if (onthebooks.index.date === dayData.stay_date) {
                        otb = onthebooks.HotelTotal.primary;
                    }
                })
                let user_action = (dayData.override_rate === rates.RecSellRate) ? "recommendation_accept" : "overridden";

                request.rate_updates.push({
                    "stay_date": dayData.stay_date,
                    "new_rate": Number(dayData.override_rate),
                    "old_rate": Number(dayData.current_rate),
                    "system_recommended_rate": rates.RecSellRate,
                    // "user_override_rate": dayData.override_rate,
                    "user_action": user_action,
                    "snapshot": {
                        "otb": { rooms: otb.Rooms, occ: otb.Occ, adr: otb.ADR, revenue: otb.Revenue },
                        "forecast": { rooms: forecast.Rooms, occ: forecast.Occ, adr: forecast.ADR, revenue: forecast.Revenue },
                        "rates": { "current_rate": rates.RecSellRate, "recommended_rate": rates.SellRate }
                    },
                    //"rule_rate": 900 // hardCode
                })
                delete app_state.pricing_override_rates[dayData.stay_date];
            }
            setRows(prevRows => {
                return [...prevRows.filter(row => {
                    if (row.stay_date !== dayData.stay_date) {
                        row.user_override = null;
                        return row
                    }
                })]
            });
            dispatch(updateAppState("confirmed_rates_success", {
                app_state: {
                    pricing_override_rates: { ...app_state.pricing_override_rates },
                    roomtype_pricing_overrides: { ...app_state.roomtype_pricing_overrides }
                },
                ui_state: { pricingRateModalOpen: false }
            }))

        } else {

            if (isRoomTypePricing === false) {

                request = {
                    ...API_REGISTRY["SendRatePush"].payload,
                    "user_id": user.user_id,
                    "pid": app_state.property_id,
                    "asof_date": app_state.primary_stay_range.asof_date,


                }
                request["segment_type"] = "market_code";
                request["rate_code"] = "TBAR";
                rows.map(data => {
                    pricingData.map(pricing => {
                        if (pricing.stay_date === data.stay_date) {
                            rates = pricing;
                        }
                    })
                    forecastData.map(fdata => {
                        if (fdata.index.date === data.stay_date) {
                            forecast = fdata.HotelTotal.primary;
                        }
                    })
                    otbData.map(onthebooks => {
                        if (onthebooks.index.date === data.stay_date) {
                            otb = onthebooks.HotelTotal.primary;
                        }
                    })
                    let user_action = (data.override_rate === rates.RecSellRate) ? "recommendation_accept" : "overridden";

                    request.rate_updates.push({
                        "stay_date": data.stay_date,
                        "new_rate": data.override_rate,
                        "old_rate": data.current_rate,
                        "system_recommended_rate": rates.RecSellRate,
                        // "user_override_rate": data.override_rate,
                        "user_action": user_action,
                        "snapshot": {
                            "otb": { rooms: otb.Rooms, occ: otb.Occ, adr: otb.ADR, revenue: otb.Revenue },
                            "forecast": { rooms: forecast.Rooms, occ: forecast.Occ, adr: forecast.ADR, revenue: forecast.Revenue },
                            "rates": { "current_rate": rates.RecSellRate, "recommended_rate": rates.SellRate }
                        },
                        //"rule_rate": 900 // hardCode
                    })
                })
            }


            setRows([]);


        }
        dataService.getData({ api: uri, request }).then((res) => {
            console.log(res)
            if (!dayData) {
                setRatePushSuccessOpen(true);
                setTimeout(() => {
                    setRatePushSuccessOpen(false);
                    props.onClose();
                }, 3000)
            }

        }).catch(err => {
            console.log(err);
        })



    }
    const ratePushSucessDiv = (<>
        <Grid item className='success-popup'></Grid>
        <div className='rate-push-success'>
            <div className='rate-push-success-container'>
                <Grid item className='task-complete'></Grid>
                <Grid item className='success-text'>Success!</Grid>
                <Grid item>Check you rates push status beloW </Grid>
                <Button variant="contained" onClick={() => {
                    setRatePushSuccessOpen(false);
                    props.onClose();
                }} className='button-green'>DAILY RATE PUSH STATUS</Button>
            </div>
        </div></>);

    const housePricingTable = (<TableContainer className='rate-push-table' sx={{ maxHeight: "91%" }}>
        <Table stickyHeader aria-label="simple table">
            <TableHead>
                <TableRow sx={{ backgroundColor: '#606060', '& td, & th': { pt: 1, pb: 1 } }}>
                    <TableCell align="center">DATE	</TableCell>
                    <TableCell align="center">CURRENT RATE</TableCell>
                    <TableCell align="center">APPROVED RATE</TableCell>
                    <TableCell align="center" sx={{ color: '#fff', width: 150 }}>RATE OVERRIDE</TableCell>
                    <TableCell align="left" className='status-column'>STATUS</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className='ratepush-tbody pricing' sx={{ overflowX: "hidden", overflowY: "auto" }}>


                {rows.length > 0 ?
                    rows.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td, & th': { pt: 1, pb: 1, fontSize: 14 } }}
                        >
                            <TableCell align='center' component="th" scope="row">
                                {moment(row.stay_date).format("ddd, MMM DD, YYYY")}
                            </TableCell>
                            <TableCell component="th" scope="row" align='center' sx={{ color: "#000" }}>
                                {ui_state.currency_symbol} {row.current_rate}
                            </TableCell>
                            <TableCell component="th" scope="row" align='center' sx={{ fontWeight: "bold" }}>
                                {ui_state.currency_symbol} {row.override_rate}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <TextField
                                    type="number"
                                    size="small"
                                    value={row.user_override}
                                    onChange={(e) => {
                                        let value = Number(e.target.value);
                                        setRows(prevRows => {
                                            prevRows[index].override_rate = value;
                                            prevRows[index].user_override = value;
                                            return [...prevRows];
                                        })
                                    }}
                                />

                            </TableCell>
                            <TableCell component="th" scope="row">
                                <Grid container direction="row" justifyContent="space-between" alignItems="center"
                                >
                                    <Grid item>
                                        <Button onClick={() => props.handleRatePush(row)} className='icon-btn icon-status icon-ratepush'>
                                            <span className='upload-button'></span>
                                        </Button>
                                    </Grid>
                                    {/* <Grid item xs={4}>
                                            
                                            <CircularProgress size={20} />
                                            
                                        </Grid> */}
                                    {/* <Grid xs={4}>
                                            <item><Button>
                                                <span className='in-progress'></span>
                                            </Button></item>
                                        </Grid> */}
                                    <Grid item >
                                        {/* <Button className='icon-btn icon-status icon-warning'>
                                            <span className='error'></span>
                                        </Button> */}
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={() => {
                                            props.handleDelete(index, row.id)
                                        }} className='icon-btn icon-status icon-cancel'>
                                            <span className='push-delete'></span>
                                        </Button>
                                    </Grid>
                                </Grid>



                                {/* <CloudUploadOutlinedIcon onClick={() => handleRatePush(index)} sx={{ color: "#9ed00d", cursor: "pointer" }} /> */}

                                {/* <CloseSharpIcon onClick={() => {
                                        //props.handleDelete(index, row.id)
                                    }} sx={{ ml: 2, color: "#ddd", cursor: "pointer" }} /> */}
                            </TableCell>
                        </TableRow>
                    ))
                    : (
                        <p>
                            No Record Found!
                        </p>
                    )
                }
            </TableBody>
        </Table>
    </TableContainer>)


    return (<>
        <Modal
            className="popup-modal-wrap"
            open={props.action}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='popup-modal modal-pricing'>



                <Box className='modal-titlebar'>
                    <Grid container spacing={0} className='PricingModal'>
                        <Grid item xs={7}>
                            <item><span className='text-green'>Pricing -</span> Review and update</item>
                        </Grid>
                        <Grid className='right-cta' xs={5} item alignItems="end">
                            <Badge anchorOrigin={{ vertical: 'top', horizontal: 'right', }} component="div" badgeContent={rows.numUpdates} color="primary" className='btn-ratepush' sx={{ mt: 0, mr: 1, position: 'relative' }}>
                                <Button className='PushRateButton-popup' size="small" sx={{ m: "auto" }} onClick={() => props.handleRatePush()}>
                                    <span>confirm all changes</span>
                                </Button>

                            </Badge>
                            <IconButton aria-label="delete" onClick={() => {
                                // let name = "";
                                // if(isRoomTypePricing){
                                //     name = "PricingRates"
                                // } else {
                                //     name = "RoomPricingRates"
                                // }
                                // downloadReportToExcel(name,property_name, "pricingRatesTable")

                            }}>
                                <FileDownloadOutlinedIcon className='Rate-Push-DownloadButton '

                                    fontSize='large' />
                            </IconButton>

                            <Button sx={{
                                minWidth: 'auto', position: 'absolute', top: -12, right: -12, width: '24px', height: '24px', p: '7px',
                                borderRadius: '50%', borderWidth: '1px', borderStyle: 'solid',
                                borderColor: '#ccc', boxShadow: '0 0 4px #000', background: '#000', color: '#FFF', fontSize: '10px',
                                '&:hover': { backgroundColor: '#000' }
                            }} onClick={() => {
                                props.onClose()

                            }}>
                                <CloseSharpIcon fontSize='small' />
                            </Button>



                        </Grid>
                    </Grid>
                </Box>


                <Box sx={{ height: '100%' }} id="pricingRatesTable">

                    {/* rate listing white section */}

                    {housePricingTable}
                    {numUpdates < 1 && (
                        <p>
                            No Record Found!
                        </p>
                    )}
                    {/* <Stack sx={{ width: '100%' }} spacing={2}>

                        <Alert severity="warning" sx={{ color:"#C02E2E" }}>Something Went Wrong, Please Try Again</Alert>
                    </Stack> */}
                    {/* <Stack sx={{ width: '100%' }} spacing={2}>

                        <Alert severity="success" sx={{ color:"#56874C" }}>Success! </Alert>
                    </Stack> */}


                    {ratePushSucessOpen && ratePushSucessDiv}

                    {ratePushFail && (<Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="warning" sx={{ color: "#C02E2E" }}>Something Went Wrong, Please Try Again</Alert>
                    </Stack>)}
                </Box>



            </Box>
        </Modal>
    </>)


}
