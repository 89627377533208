import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandler } from "../../app/ErrorHandler";
import {
  Box,
  Grid,
} from "@mui/material/";
import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_STATE } from "../../actions/types";
import theme from "../../theme";
import Header from "../Header";
import { DashboardFooter } from "../layout/dashboard-ui";
import { ProfileSettings, SecuritySettings } from "./profile-settings";
import {
  AddNewProperty,
  AlertsSettings,
  CompsetSettings,
  DefaultRoomTypePricing,
  DiscountRulesSettings,
  IntegrationSettings,
  InventorySettings,
  LocaleSettings,
  ManageRatePlans,
  MarketsSettings,
  PricingRules,
  RatesSettings,
  SegmentsSettings,
  SegmentsMappingSettings,
  MarketMappingSettings,
  DecisionResyncSettings
} from "./property-settings";

import ScheduleReportsSettings from "./scheduler-reports";
import ScheduleRatePushSettings from "./scheduler-ratepush";
import SettingsSideMenu from "./settings-menu";
import { APP_CACHE } from '../../app/config-v4-api';
import { cleanIDB, clearAPIRegistryData } from '../../app/util';

//Global custom theme configuration
//import DataService from "../services/data.service";;
//Common UI Elements for screen
const Settings = () => {

  const profileSettingsMenu = [
    {
      id: "profile",
      label: "Profile",
      component: "ProfileSettings",
      index: 1,
      access: [0, 1, 2, 3, 4, 5],
      icon: <span className='profile-icon'></span>,
    },
    {

    }
  ];
  const propertySettingsMenu = [
    {
      id: "inventory",
      label: "Inventory",
      component: "InventorySettings",
      index: 3,
      access: [0, 1, 2, 3, 4, 5],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,16V152h-28.7A15.86,15.86,0,0,0,168,156.69L148.69,176H107.31L88,156.69A15.86,15.86,0,0,0,76.69,152H48V48Zm0,160H48V168H76.69L96,187.31A15.86,15.86,0,0,0,107.31,192h41.38A15.86,15.86,0,0,0,160,187.31L179.31,168H208v40Z"></path></svg>,
    },
    {
      id: "compset",
      label: "Compset *",
      component: "CompsetSettings",
      index: 4,
      access: [2],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M200,224H150.54A266.56,266.56,0,0,0,174,200.25c27.45-31.57,42-64.85,42-96.25a88,88,0,0,0-176,0c0,31.4,14.51,64.68,42,96.25A266.56,266.56,0,0,0,105.46,224H56a8,8,0,0,0,0,16H200a8,8,0,0,0,0-16ZM56,104a72,72,0,0,1,144,0c0,57.23-55.47,105-72,118C111.47,209,56,161.23,56,104Zm112,0a40,40,0,1,0-40,40A40,40,0,0,0,168,104Zm-64,0a24,24,0,1,1,24,24A24,24,0,0,1,104,104Z"></path></svg>,
    },
    {
      id: "rates",
      label: "Rates *",
      component: "RatesSettings",
      index: 5,
      access: [2],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm40-68a28,28,0,0,1-28,28h-4v8a8,8,0,0,1-16,0v-8H104a8,8,0,0,1,0-16h36a12,12,0,0,0,0-24H116a28,28,0,0,1,0-56h4V72a8,8,0,0,1,16,0v8h16a8,8,0,0,1,0,16H116a12,12,0,0,0,0,24h24A28,28,0,0,1,168,148Z"></path></svg>,
    },
    {
      id: "mapping",
      label: "Property Mapping",
      component: "SegmentsMappingSettings",
      index: 12,
      access: [0, 1, 2, 3, 4, 5],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64ZM216,96H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path></svg>,
    },
    {
      id: "market_mapping",
      label: "Market Mapping",
      component: "MarketMappingSettings",
      index: 13,
      access: [0, 1, 2, 3, 4, 5],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64ZM216,96H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path></svg>,
    },
    {
      id: "markets",
      label: "Markets *",
      component: "MarketsSettings",
      index: 7,
      access: [2],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M240,208H224V96a16,16,0,0,0-16-16H144V32a16,16,0,0,0-24.88-13.32L39.12,72A16,16,0,0,0,32,85.34V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM208,96V208H144V96ZM48,85.34,128,32V208H48ZM112,112v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm-32,0v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm0,56v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Zm32,0v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Z"></path></svg>,
    },
    {
      id: "locale",
      label: "Locale *",
      component: "LocaleSettings",
      index: 8,
      access: [2],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm64-88a8,8,0,0,1-8,8H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48h48A8,8,0,0,1,192,128Z"></path></svg>,
    },
    {
      id: "integration",
      label: "Integration *",
      component: "IntegrationSettings",
      index: 9,
      access: [2],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M86.75,44.3,33.48,128l53.27,83.7a8,8,0,0,1-2.46,11.05A7.91,7.91,0,0,1,80,224a8,8,0,0,1-6.76-3.71l-56-88a8,8,0,0,1,0-8.59l56-88a8,8,0,1,1,13.5,8.59Zm152,79.41-56-88a8,8,0,1,0-13.5,8.59L222.52,128l-53.27,83.7a8,8,0,0,0,2.46,11.05A7.91,7.91,0,0,0,176,224a8,8,0,0,0,6.76-3.71l56-88A8,8,0,0,0,238.75,123.71Z"></path></svg>,
    },
    {
      id: "discountrules",
      label: "Discount Rules *",
      component: "DiscountRulesSettings",
      index: 10,
      access: [2],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M243.31,136,144,36.69A15.86,15.86,0,0,0,132.69,32H40a8,8,0,0,0-8,8v92.69A15.86,15.86,0,0,0,36.69,144L136,243.31a16,16,0,0,0,22.63,0l84.68-84.68a16,16,0,0,0,0-22.63Zm-96,96L48,132.69V48h84.69L232,147.31ZM96,84A12,12,0,1,1,84,72,12,12,0,0,1,96,84Z"></path></svg>,
    },
    {
      id: "alerts",
      label: "Alerts",
      component: "AlertsSettings",
      index: 11,
      access: [0, 1, 2, 3, 4, 5],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M224,71.1a8,8,0,0,1-10.78-3.42,94.13,94.13,0,0,0-33.46-36.91,8,8,0,1,1,8.54-13.54,111.46,111.46,0,0,1,39.12,43.09A8,8,0,0,1,224,71.1ZM35.71,72a8,8,0,0,0,7.1-4.32A94.13,94.13,0,0,1,76.27,30.77a8,8,0,1,0-8.54-13.54A111.46,111.46,0,0,0,28.61,60.32,8,8,0,0,0,35.71,72Zm186.1,103.94A16,16,0,0,1,208,200H167.2a40,40,0,0,1-78.4,0H48a16,16,0,0,1-13.79-24.06C43.22,160.39,48,138.28,48,112a80,80,0,0,1,160,0C208,138.27,212.78,160.38,221.81,175.94ZM150.62,200H105.38a24,24,0,0,0,45.24,0ZM208,184c-10.64-18.27-16-42.49-16-72a64,64,0,0,0-128,0c0,29.52-5.38,53.74-16,72Z"></path></svg>,
    },
    {
      id: "defaultroomtypepricing",
      label: "Pricing Seasons",
      component: "DefaultRoomTypePricing",
      index: 14,
      access: [0, 1, 2, 3, 4, 5],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M208,72H24V48A8,8,0,0,0,8,48V208a8,8,0,0,0,16,0V176H232v32a8,8,0,0,0,16,0V112A40,40,0,0,0,208,72ZM24,88H96v72H24Zm88,72V88h96a24,24,0,0,1,24,24v48Z"></path></svg>,
    },
    {
      id: "decisionResync",
      label: "Decision Resync *",
      component: "DecisionResyncSettings",
      index: 15,
      access: [2],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 10 15" ><path d="M9.5 10C9.49917 10.7954 9.18284 11.558 8.62041 12.1204C8.05798 12.6828 7.2954 12.9992 6.5 13H5.5V14C5.5 14.1326 5.44732 14.2598 5.35355 14.3536C5.25979 14.4473 5.13261 14.5 5 14.5C4.86739 14.5 4.74021 14.4473 4.64645 14.3536C4.55268 14.2598 4.5 14.1326 4.5 14V13H3.5C2.7046 12.9992 1.94202 12.6828 1.37959 12.1204C0.817163 11.558 0.500827 10.7954 0.5 10C0.5 9.86739 0.552679 9.74021 0.646447 9.64645C0.740215 9.55268 0.867392 9.5 1 9.5C1.13261 9.5 1.25979 9.55268 1.35355 9.64645C1.44732 9.74021 1.5 9.86739 1.5 10C1.5 10.5304 1.71071 11.0391 2.08579 11.4142C2.46086 11.7893 2.96957 12 3.5 12H6.5C7.03043 12 7.53914 11.7893 7.91421 11.4142C8.28929 11.0391 8.5 10.5304 8.5 10C8.5 9.46957 8.28929 8.96086 7.91421 8.58579C7.53914 8.21071 7.03043 8 6.5 8H4C3.20435 8 2.44129 7.68393 1.87868 7.12132C1.31607 6.55871 1 5.79565 1 5C1 4.20435 1.31607 3.44129 1.87868 2.87868C2.44129 2.31607 3.20435 2 4 2H4.5V1C4.5 0.867392 4.55268 0.740215 4.64645 0.646447C4.74021 0.552678 4.86739 0.5 5 0.5C5.13261 0.5 5.25979 0.552678 5.35355 0.646447C5.44732 0.740215 5.5 0.867392 5.5 1V2H6C6.7954 2.00083 7.55798 2.31716 8.12041 2.87959C8.68284 3.44202 8.99917 4.2046 9 5C9 5.13261 8.94732 5.25979 8.85355 5.35355C8.75979 5.44732 8.63261 5.5 8.5 5.5C8.36739 5.5 8.24021 5.44732 8.14645 5.35355C8.05268 5.25979 8 5.13261 8 5C8 4.46957 7.78929 3.96086 7.41421 3.58579C7.03914 3.21071 6.53043 3 6 3H4C3.46957 3 2.96086 3.21071 2.58579 3.58579C2.21071 3.96086 2 4.46957 2 5C2 5.53043 2.21071 6.03914 2.58579 6.41421C2.96086 6.78929 3.46957 7 4 7H6.5C7.2954 7.00083 8.05798 7.31716 8.62041 7.87959C9.18284 8.44202 9.49917 9.2046 9.5 10Z" fill="#F8F9FA" ></path></svg>
    }
  ];
  const schedulerSettingsMenu = [
    {
      id: "reports",
      label: "Reports",
      component: "ScheduleReportsSettings",
      index: 16,
      access: [0, 1, 2, 3, 4, 5],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M224,200h-8V40a8,8,0,0,0-8-8H152a8,8,0,0,0-8,8V80H96a8,8,0,0,0-8,8v40H48a8,8,0,0,0-8,8v64H32a8,8,0,0,0,0,16H224a8,8,0,0,0,0-16ZM160,48h40V200H160ZM104,96h40V200H104ZM56,144H88v56H56Z"></path></svg>,
    },
    {
      id: "ratepush",
      label: "Rate Push",
      component: "ScheduleRatePushSettings",
      index: 17,
      access: [0, 1, 2, 3, 4, 5],
      icon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M200,168a48.05,48.05,0,0,1-48,48H136v16a8,8,0,0,1-16,0V216H104a48.05,48.05,0,0,1-48-48,8,8,0,0,1,16,0,32,32,0,0,0,32,32h48a32,32,0,0,0,0-64H112a48,48,0,0,1,0-96h8V24a8,8,0,0,1,16,0V40h8a48.05,48.05,0,0,1,48,48,8,8,0,0,1-16,0,32,32,0,0,0-32-32H112a32,32,0,0,0,0,64h40A48.05,48.05,0,0,1,200,168Z"></path></svg>,
      isLast: true,
    },
  ];
  const settingsComponentsMap = {
    ProfileSettings: <ProfileSettings></ProfileSettings>,
    SecuritySettings: <SecuritySettings></SecuritySettings>,
    InventorySettings: <InventorySettings></InventorySettings>,
    CompsetSettings: <CompsetSettings></CompsetSettings>,
    RatesSettings: <RatesSettings></RatesSettings>,
    SegmentsSettings: <SegmentsSettings></SegmentsSettings>,
    SegmentsMappingSettings: <SegmentsMappingSettings></SegmentsMappingSettings>,
    MarketMappingSettings: <MarketMappingSettings></MarketMappingSettings>,
    MarketsSettings: <MarketsSettings></MarketsSettings>,
    LocaleSettings: <LocaleSettings></LocaleSettings>,
    IntegrationSettings: <IntegrationSettings></IntegrationSettings>,
    DiscountRulesSettings: <DiscountRulesSettings></DiscountRulesSettings>,
    AlertsSettings: <AlertsSettings></AlertsSettings>,
    ManageRatePlans: <ManageRatePlans></ManageRatePlans>,
    PricingRules: <PricingRules></PricingRules>,
    DefaultRoomTypePricing: <DefaultRoomTypePricing></DefaultRoomTypePricing>,
    AddNewProperty: <AddNewProperty></AddNewProperty>,
    ScheduleReportsSettings: (
      <ScheduleReportsSettings></ScheduleReportsSettings>
    ),
    ScheduleRatePushSettings: (
      <ScheduleRatePushSettings></ScheduleRatePushSettings>
    ),
    DecisionResyncSettings: <DecisionResyncSettings></DecisionResyncSettings>,
  };

  const { app, auth } = useSelector((state) => state);
  const { ui_state } = app.current_state;

  const ActiveSettingsComponent = () => {
    const {
      activeSettingsComponentName,
      active_settings_menu,
    } = ui_state;
    let component = activeSettingsComponentName;
    if (ui_state.isFromHeader) {
      component = eval(active_settings_menu)[0].component;
    }
    if (!component) return "";
    return <ErrorBoundary FallbackComponent={ErrorHandler}>{settingsComponentsMap[component]}</ErrorBoundary>;
  };
  const isFromHeader = ui_state.isFromHeader;
  const dispatch = useDispatch();
  const active_settings_menu = ui_state.active_settings_menu;
  const { settingsActiveIndex } = ui_state;

  let selected_index = settingsActiveIndex;
  if (active_settings_menu === "profileSettingsMenu") selected_index = 1;
  if (active_settings_menu === "propertySettingsMenu") selected_index = 3;
  if (active_settings_menu === "schedulerSettingsMenu") selected_index = 16;

  const active_settings_classNames = {
    ProfileSettings: "profile-settings",
    ScheduleReportsSettings: "scheduler reports",
    ScheduleRatePushSettings: "scheduler ratepush",
  };

  if (ui_state.isFromHeader) {
    const settingsActiveItem = eval(active_settings_menu)[0];
    dispatch({
      type: UPDATE_STATE,
      payload: {
        ui_state: {
          isFromHeader: false,
          activeSettingsComponentName: settingsActiveItem.component,
          settingsActiveIndex: selected_index,
        },
      },
    });
  }

  const SettingsSideMenuList = [
    {
      className: "profileSettingsMenu",
      menuIcon: <svg svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256" > <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM74.08,197.5a64,64,0,0,1,107.84,0,87.83,87.83,0,0,1-107.84,0ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120Zm97.76,66.41a79.66,79.66,0,0,0-36.06-28.75,48,48,0,1,0-59.4,0,79.66,79.66,0,0,0-36.06,28.75,88,88,0,1,1,131.52,0Z"></path></svg>,
      menuId: "profileSettingsMenu",
      activeItemIndex: selected_index,
      title: "Profile settings",
      data: profileSettingsMenu,
      isFromHeader: isFromHeader
    },
    {
      className: "propertySettingsMenu",
      menuIcon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M240,208H224V96a16,16,0,0,0-16-16H144V32a16,16,0,0,0-24.88-13.32L39.12,72A16,16,0,0,0,32,85.34V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM208,96V208H144V96ZM48,85.34,128,32V208H48ZM112,112v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm-32,0v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm0,56v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Zm32,0v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Z"></path></svg>,
      menuId: "propertySettingsMenu",
      activeItemIndex: selected_index,
      title: <span style={{ marginTop: "2px" }}>Property settings</span>,
      data: propertySettingsMenu,
      isFromHeader: isFromHeader
    },
    {
      className: "schedulerSettingsMenu",
      menuIcon: <svg className='expandedIcons' xmlns="http://www.w3.org/2000/svg" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M128,32a96,96,0,1,0,96,96A96.11,96.11,0,0,0,128,32Zm0,176a80,80,0,1,1,80-80A80.09,80.09,0,0,1,128,208ZM61.66,29.66l-32,32A8,8,0,0,1,18.34,50.34l32-32A8,8,0,1,1,61.66,29.66Zm176,32a8,8,0,0,1-11.32,0l-32-32a8,8,0,0,1,11.32-11.32l32,32A8,8,0,0,1,237.66,61.66ZM184,120a8,8,0,0,1,0,16H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48Z"></path></svg>,
      menuId: "schedulerSettingsMenu",
      activeItemIndex: selected_index,
      title: "Scheduler",
      data: schedulerSettingsMenu,
      isFromHeader: isFromHeader,
    }
  ]

  useEffect(() => {
    return () => {
      let apc = APP_CACHE;
      Object.keys(apc).map((key) => {
        if (key.startsWith("finalized")) {
          delete apc[key];
        }
      })
      cleanIDB();
      clearAPIRegistryData();
    }
  }, [])


  return (
    <ThemeProvider theme={theme}>
      <Box className="settings" sx={{ backgroundColor: "white.main", minHeight: "100%" }}>
        <Header />

        <Grid
          className={active_settings_classNames[ui_state.activeSettingsComponentName]}
          container
          display="flex"
          flexWrap="nowrap"
          sx={{ minHeight: "calc(100vh - 35px)" }}
        >
          {/* settings side menu */}
          <SettingsSideMenu settingsSideMenuList={SettingsSideMenuList} />
          {/* settings body */}
          <Grid
            item
            className={`settings-forms`}
            flexWrap={'nowrap'}
          >
            <Box sx={{ position: 'relative' }}>
              <ActiveSettingsComponent></ActiveSettingsComponent>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <DashboardFooter />
    </ThemeProvider>
  );
};
export default Settings;
