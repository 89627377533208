import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { useOnlineStatus } from '../../hooks/helper-hooks';

function DisplayOnlineStatus() {
  const isOnline = useOnlineStatus();
  const [renderCount, setRenderCount] = useState(0);
  const [displayOnlineStatus, setDisplayOnlineStatus] = useState(false);

  useEffect(()=>{
    //Handle first render and status is online
    if (isOnline && renderCount === 0) {
        setDisplayOnlineStatus(false);
        setRenderCount(1);
    }
    //Handle first render and status is offline
    if (!isOnline && renderCount === 0) {
        setRenderCount(1);
        setDisplayOnlineStatus(true);
    }
    //Handle after first render and status is offline
    if (isOnline && renderCount > 0) {
        setDisplayOnlineStatus(true);
        setTimeout(()=>{
            setDisplayOnlineStatus(false);
        }, 3000);
    }
    //Handle after first render and status is offline
    if (!isOnline && renderCount > 0) {
        setDisplayOnlineStatus(true);
    }
  }, [isOnline]);

  if(!isOnline && displayOnlineStatus){
    return(<div style={{background:'#d32f2f', position:'fixed', width:'100vw', textAlign:'center', paddingTop:2,  bottom:0, zIndex:999, opacity:0.85,  color:'#FFF'}}>You are offline, app may not work!</div>)
  } else if(isOnline && displayOnlineStatus) {
    return(<div style={{background:'#b0e80e', position:'fixed', width:'100vw', textAlign:'center', paddingTop:2,  bottom:0, zIndex:999, opacity:0.85, color:'#333'}}>Back to online!</div>)
  }  
} 

export default DisplayOnlineStatus;