import React, { useEffect, useReducer, useRef, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Container,
  Avatar,
  Button,
  Grid,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
} from "@mui/material/";
import {
  Person as PersonIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  ScheduleOutlined as ScheduleOutlinedIcon,
  VideocamOutlined as VideocamOutlinedIcon,
  PowerSettingsNewOutlined as PowerSettingsNewOutlinedIcon,
  AssessmentOutlined as AssessmentOutlinedIcon,
  ArrowForwardRounded as ArrowForwardRoundedIcon,
  ApartmentSharp as ApartmentSharpIcon,
  Search as SearchIcon,
  KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
  Splitscreen,
  Height,
} from "@mui/icons-material/";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FolderIcon from "@mui/icons-material/Folder";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { API_BASE_URL } from "../../app/config";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import FileFolderList from "./FileFolderList";
import { replaceMultiText } from "../../app/util";
import { fetchDataSimple } from "../../services/data.service";
import { LightTooltip } from "../layout/dashboard-ui";


const DocumentList = (props) => {
  const { app, auth } = useSelector((state) => state);
  const { app_state } = app.current_state;
  const [folderShow, setFolderShow] = useState(false);
  const [value, setValue] = useState("");

  let label;
  if (props.reportType === "budget_upload") {
    label = "Budget";
  } else if (props.reportType === "forecast_upload") {
    label = "User Forcast";
  } else if (props.reportType === "documents_upload") {
    label = "Document";
  } else {
    label = "";
  }
  const [templateUrls, setTemplateUrls] = useState({
    "forcast": "http://rm-app-staging.lodgiq.com/app/v3/docs/2022_LodgIQ%20Forecast%20Upload%20Template.xls",
    "budget": "http://rm-app-staging.lodgiq.com/app/v3/docs/2022_LodgIQ%20Budget%20Upload%20Template.xls"
  })
  useEffect(() => {
    let request = { method: "GET" }
    fetchDataSimple({ api: "upload/templates/", request }).then(res => {
      console.log(res)
      if (res.status === "success") {
        setTemplateUrls(res.templates);
      }
    }).catch(err => {
      console.log(err);
    })
    return () => {

    }
  }, [])


  const downloadFile = () => {
    // console.log("test="+uploadReport);
    let downloadFileName = "forecast_upload";
    if (props.reportType === "forecast_upload") {
      //downloadFileName = "http://rm-app-staging.lodgiq.com/app/v3/docs/2022_LodgIQ%20Forecast%20Upload%20Template.xls";
      downloadFileName = templateUrls["forcast"];
    } else {
      //downloadFileName = "http://rm-app-staging.lodgiq.com/app/v3/docs/2022_LodgIQ%20Budget%20Upload%20Template.xls";
      downloadFileName = templateUrls["budget"];
    }
    window.location.href = downloadFileName;
  }

  const handleToggle = (val) => {
    if (folderShow === false) {
      setFolderShow(val)
    }
    else {
      setFolderShow(false)
    }
  }

  const emptyValue = (val) => {
    setValue('');
    setFolderShow(false);
  }


  return (
    <>
      <Box sx={{ flexGrow: 1, mt: 1, position: "relative" }}>
        <Grid container className="gContainer" spacing={2}>
          <Grid xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

            {/* <Box className="lebel-txt" aria-label="breadcrumb">

              <Link sx={{ cursor: "pointer" }} underline="hover" color="inherit" onClick={() => { props.handleBreadcrumb("0"); }} separator="/" aria-label="breadcrumb">
                {label}
              </Link>

              {
                props.ui.breadcrumb.map((item, index) => {
                  let breadPath = item.split(">");
                  return (
                    <>
                      <Link onClick={() => { props.handleBreadcrumb(item); }} underline="hover" color="inherit" separator="›" aria-label="breadcrumb">
                        {breadPath[3]}
                      </Link>
                    </>
                  )
                })
              }
            </Box> */}
            <Breadcrumbs className="lebel-txt" aria-label="breadcrumb" sx={{ cursor: "pointer" }}>
              <Link underline="hover" color="inherit" onClick={() => {
                props.handleBreadcrumb("0");
              }} separator="/" aria-label="breadcrumb">
                {label}
              </Link>

              {
                props.ui.breadcrumb.map((item, index) => {
                  let breadPath = item.split(">");
                  return (
                    <> <Link onClick={() => {
                      props.handleBreadcrumb(item);

                    }} underline="hover" color="inherit" separator="›" aria-label="breadcrumb">
                      {breadPath[3]}
                    </Link>
                    </>
                  )
                })
              }
            </Breadcrumbs>

            <Box className="lebel-txt">
              {props.reportType !== "documents_upload" ?
                (
                  <Tooltip title="Download the template,Fill it and upload the competed file" arrow>
                    <Button className="download-template-btn" variant="outlined" size='large' component="label" onClick={downloadFile} startIcon={<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 14" fill="none">
                      <path d="M12.25 8.3125V11.375C12.25 11.6071 12.1578 11.8296 11.9937 11.9937C11.8296 12.1578 11.6071 12.25 11.375 12.25H2.625C2.39294 12.25 2.17038 12.1578 2.00628 11.9937C1.84219 11.8296 1.75 11.6071 1.75 11.375V8.3125C1.75 8.19647 1.79609 8.08519 1.87814 8.00314C1.96019 7.92109 2.07147 7.875 2.1875 7.875C2.30353 7.875 2.41481 7.92109 2.49686 8.00314C2.57891 8.08519 2.625 8.19647 2.625 8.3125V11.375H11.375V8.3125C11.375 8.19647 11.4211 8.08519 11.5031 8.00314C11.5852 7.92109 11.6965 7.875 11.8125 7.875C11.9285 7.875 12.0398 7.92109 12.1219 8.00314C12.2039 8.08519 12.25 8.19647 12.25 8.3125ZM6.69047 8.62203C6.7311 8.66271 6.77935 8.69498 6.83246 8.717C6.88558 8.73901 6.94251 8.75034 7 8.75034C7.05749 8.75034 7.11442 8.73901 7.16754 8.717C7.22065 8.69498 7.2689 8.66271 7.30953 8.62203L9.49703 6.43453C9.53768 6.39388 9.56992 6.34563 9.59192 6.29252C9.61392 6.23941 9.62524 6.18249 9.62524 6.125C9.62524 6.06751 9.61392 6.01059 9.59192 5.95748C9.56992 5.90437 9.53768 5.85612 9.49703 5.81547C9.45638 5.77482 9.40813 5.74258 9.35502 5.72058C9.30191 5.69858 9.24499 5.68726 9.1875 5.68726C9.13001 5.68726 9.07309 5.69858 9.01998 5.72058C8.96687 5.74258 8.91862 5.77482 8.87797 5.81547L7.4375 7.25648V2.1875C7.4375 2.07147 7.39141 1.96019 7.30936 1.87814C7.22731 1.79609 7.11603 1.75 7 1.75C6.88397 1.75 6.77269 1.79609 6.69064 1.87814C6.60859 1.96019 6.5625 2.07147 6.5625 2.1875V7.25648L5.12203 5.81547C5.03994 5.73338 4.9286 5.68726 4.8125 5.68726C4.6964 5.68726 4.58506 5.73338 4.50297 5.81547C4.42088 5.89756 4.37476 6.0089 4.37476 6.125C4.37476 6.2411 4.42088 6.35244 4.50297 6.43453L6.69047 8.62203Z" fill="#606060" />
                    </svg>}>
                      {label} Template
                    </Button>
                  </Tooltip>
                )
                :
                (
                  <>

                    <Typography sx={{ position: "absolute", pr: 1, float: "right", top: 12, right: 2 }}>

                      {folderShow && <div className='sent-email'>
                        <svg style={{ position: "absolute", top: "12px", right: "-15px" }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" viewBox="0 0 256 256"><path d="M181.66,133.66l-80,80A8,8,0,0,1,88,208V48a8,8,0,0,1,13.66-5.66l80,80A8,8,0,0,1,181.66,133.66Z"></path></svg>
                        <div className='message-text'>
                          <TextField
                            autoFocus
                            className='email-sent-textarea'
                            fullWidth
                            id="input-with-icon-textfield"
                            multiline
                            value={value}
                            onChange={(e) => { setValue(e.target.value) }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" cursor="pointer">
                                  <svg style={{ cursor: "pointer" }} onClick={() => { props.createFolder(value); emptyValue(); }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 15" fill="none">
                                    <path d="M12.5598 4.74703L5.55977 11.747C5.51914 11.7877 5.47089 11.82 5.41778 11.842C5.36467 11.864 5.30774 11.8753 5.25024 11.8753C5.19275 11.8753 5.13582 11.864 5.08271 11.842C5.0296 11.82 4.98134 11.7877 4.94071 11.747L1.87821 8.68453C1.79612 8.60244 1.75 8.4911 1.75 8.375C1.75 8.2589 1.79612 8.14756 1.87821 8.06547C1.9603 7.98337 2.07165 7.93726 2.18774 7.93726C2.30384 7.93726 2.41518 7.98337 2.49727 8.06547L5.25024 10.819L11.9407 4.12797C12.0228 4.04587 12.1341 3.99976 12.2502 3.99976C12.3663 3.99976 12.4777 4.04587 12.5598 4.12797C12.6419 4.21006 12.688 4.3214 12.688 4.4375C12.688 4.5536 12.6419 4.66494 12.5598 4.74703Z" fill="black" />
                                  </svg>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </div>
                      }


                      <div onClick={(e) => { handleToggle(true) }} className="lebel-txt" style={{ paddingTop: 0, paddingBottom: 0, gap: "8px", cursor: "pointer" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 12 10" fill="none">
                          <path d="M10.8125 1.9375H6.14602L4.62898 0.8C4.4773 0.686827 4.29323 0.625469 4.10398 0.625H1.1875C0.955436 0.625 0.732876 0.717187 0.568782 0.881282C0.404687 1.04538 0.3125 1.26794 0.3125 1.5V8.9375C0.3125 9.16956 0.404687 9.39212 0.568782 9.55622C0.732876 9.72031 0.955436 9.8125 1.1875 9.8125H10.8612C11.0802 9.81221 11.2903 9.72506 11.4452 9.57015C11.6001 9.41525 11.6872 9.20524 11.6875 8.98617V2.8125C11.6875 2.58044 11.5953 2.35788 11.4312 2.19378C11.2671 2.02969 11.0446 1.9375 10.8125 1.9375ZM10.8125 8.9375H1.1875V1.5H4.10398L5.62102 2.6375C5.7727 2.75067 5.95677 2.81203 6.14602 2.8125H10.8125V8.9375ZM7.75 5.875C7.75 5.99103 7.70391 6.10231 7.62186 6.18436C7.53981 6.26641 7.42853 6.3125 7.3125 6.3125H6.4375V7.1875C6.4375 7.30353 6.39141 7.41481 6.30936 7.49686C6.22731 7.57891 6.11603 7.625 6 7.625C5.88397 7.625 5.77269 7.57891 5.69064 7.49686C5.60859 7.41481 5.5625 7.30353 5.5625 7.1875V6.3125H4.6875C4.57147 6.3125 4.46019 6.26641 4.37814 6.18436C4.29609 6.10231 4.25 5.99103 4.25 5.875C4.25 5.75897 4.29609 5.64769 4.37814 5.56564C4.46019 5.48359 4.57147 5.4375 4.6875 5.4375H5.5625V4.5625C5.5625 4.44647 5.60859 4.33519 5.69064 4.25314C5.77269 4.17109 5.88397 4.125 6 4.125C6.11603 4.125 6.22731 4.17109 6.30936 4.25314C6.39141 4.33519 6.4375 4.44647 6.4375 4.5625V5.4375H7.3125C7.42853 5.4375 7.53981 5.48359 7.62186 5.56564C7.70391 5.64769 7.75 5.75897 7.75 5.875Z" fill="#606060" />
                        </svg>
                        <div>Add New Folder</div>
                      </div>
                      {/* <CreateNewFolderIcon variant="outlined" onClick={(e) => { handleToggle(true) }} sx={{ float: "right", color: "#9ed00d" }} /> */}


                    </Typography>
                  </>
                )
              }
            </Box>

          </Grid>

          {/* Table Container */}
          <TableContainer component={Paper} className="table-container" sx={{ height: 200 }}>
            <FileFolderList params={{ fileType: props.reportType, data: props.data }} deleteItem={props.deleteItem} deleteDocument={props.deleteDocument} handleFolderChange={props.handleFolderChange} />
          </TableContainer>

          {/* Table Container */}

        </Grid>
        <Grid container spacing={2} sx={{ pt: 2, overflow: "auto", height: 200, display: "none" }}>
          {props.reportType === "documents_upload" ? (
            props.data.folders.length > 0 ? (
              props.data.folders.map((item, index) => {
                // const test = ["index>folders>uid1","index>folders>uid2","index>folders>uid3"]
                let path = index + ">folders>" + item.folder_uid + ">" + item.folder_name;

                return (
                  <>
                    <Grid xs={2}>
                      <Button
                        onClick={() => {
                          props.handleFolderChange(path);
                        }}
                        startIcon={<FolderIcon color="success" />}
                        sx={{ color: "#000", textTransform: "none" }}
                      >
                        {item.folder_name}
                      </Button>
                    </Grid>
                    <Grid xs={2} sx={{ textAlign: "center" }}>
                      <Grid item xs={12} sx={{ mt: 1, ml: 1 }}></Grid>
                    </Grid>
                    <Grid xs={2} sx={{ textAlign: "center" }}>
                      <Grid item xs={12} sx={{ mt: 1, ml: 4 }}>
                        {moment(item.insert_timestamp).format("MM/DD/YYYY")}
                        <DeleteIcon
                          onClick={() => props.deleteItem(index)}
                          color="error"
                          sx={{ float: "right", mr: 1, cursor: "pointer" }}
                        />
                      </Grid>
                    </Grid>
                  </>
                );
              })
            ) : (
              <> <Grid xs={6} sx={{ boxShadow: 4 }}>
                <Grid item xs={12} sx={{ margin: 1, textAlign: "center", pt: 6, color: "#bbb" }}>
                  No Record Found
                </Grid></Grid>
              </>
            )

          ) : (
            <>
            </>
          )}
          {
            props.data.files.length > 0 ? (
              props.data.files.map((item, index) => {
                let rType = "";
                let noRecord = 0;
                if (props.reportType === "forecast_upload") {
                  rType = "manual_forecast";

                } else if (props.reportType === "budget_upload") {
                  rType = "budget";

                } else if (props.reportType === "document") {
                  rType = "document";
                }
                // console.log(label, "here = ", rType + " " + item.file_type);
                if (rType === item.file_type) {


                  return (
                    <>

                      <Grid xs={2} sx={{}}>
                        <Button
                          startIcon={<FilePresentIcon color="success" />}
                          sx={{ color: "#fff", textTransform: "none" }}
                        >
                          {item.file_name.substring(0, 25)}
                        </Button>
                      </Grid>
                      <Grid xs={2} sx={{ textAlign: "center" }}>
                        <Grid item xs={12} sx={{ mt: 1, ml: 1 }}>
                          {item.file_type}
                        </Grid>
                      </Grid>
                      <Grid xs={2} sx={{ textAlign: "center" }}>
                        <Grid item xs={12} sx={{ mt: 1, ml: 3 }}>
                          {" "}
                          {moment(item.insert_timestamp.$date).format("MM/DD/YYYY")}
                          <DeleteIcon
                            index={index}
                            onClick={() => props.deleteDocument(index)}
                            color="error"
                            sx={{ float: "right", mr: 2, cursor: "pointer" }}
                          />
                        </Grid>
                      </Grid>

                    </>
                  );
                } else {
                  <></>
                }

              })
            ) : (
              <> <Grid xs={6} sx={{ boxShadow: 4 }}>
                <Grid item xs={12} sx={{ margin: 1, textAlign: "center", pt: 6 }}>
                  No Record Found
                </Grid></Grid>
              </>
            )
          }
        </Grid>

      </Box>
    </>
  );
};

export default DocumentList;
