//React
import React, { useEffect, useState } from 'react';
//React Redux
import { useDispatch, useSelector } from 'react-redux';

//Highcharts
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { CHART_OPTIONS } from '../../app/config';

import { generatDailyFlashChartOptions } from './functions';

import {
    Grid, Alert
} from '@mui/material';

//Util functions
import { deepCopy, getTileById } from '../../app/util';

//Moment
import moment from "moment";
//APP's
import Spinner from '../Spinner';
import Loading from './Loading';
import './dailyflash.css';
import { border, Box, fontSize, textAlign } from '@mui/system';


import Skeleton from '@mui/material/Skeleton';
import Lottie from 'react-lottie';
import TableLoadingLottie from "../../lotties/table-loading.json";
import GraphLoadingLottie from "../../lotties/graph-loading.json";
import LockLayer from "../LockLayer/index.js";
import msg from "../Workspace/messages.js";

function DataChart(props) {
    // const chartOptions = deepCopy(CHART_OPTIONS.dailyFlash);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { workspace_controls } = app_state;
    const { sdlyOn, dayDate } = workspace_controls;
    const { data } = props;
    const [optionOtbByMonth, setOptionOtbByMonth] = useState({});
    const [optionPickupByMonth, setOptionPickupByMonth] = useState({});
    const [showLoader, setShowLoader] = useState(true);

    const resError = props.resError;

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (data && data.length) {
                // debugger;
                if (showLoader) {
                    setShowLoader(false);
                }
                if (!showLoader) {
                    let tile = getTileById('daily_flash');
                    let { chartConfig } = tile;
                    let identifiers = ['primary', 'sdly', 'sdly_actual'];
                    //Modify identifiers for sdly/sdtly for dayDate options
                    // debugger
                    if (dayDate === 'date') {
                        identifiers = ['primary', 'sdtly', 'sdtly_actual'];
                    }

                    let chartOptions = generatDailyFlashChartOptions(data, {
                        reportType: 'otb',
                        identifiers: identifiers,
                        measures: ['Occ', 'ADR'],
                        data_id: 'aggregates', xAxisKey: 'monthData',
                        ui_state
                    });
                    setOptionOtbByMonth(chartOptions);

                    let chartOptionsPickup = generatDailyFlashChartOptions(data, {
                        reportType: 'pickup',
                        identifiers: ['pickup'],
                        measures: ['Rooms', 'ADR'],
                        data_id: 'aggregates', xAxisKey: 'monthData',
                        ui_state
                    });
                    // debugger;

                    setOptionPickupByMonth(chartOptionsPickup);
                }
            }
        }


        return () => (isSubscribed = false)
    }, [data, showLoader]);

    //skeleton loader starts
    function randomMessage() {
        const messages = msg;

        const randomIndex = Math.floor(Math.random() * messages.length);
        const randomMessage = messages[randomIndex];

        return randomMessage;
    }
    const graphSkeletonLoader = (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <Box className="graph-view-scaleton-loader">
                <Skeleton className="skeleton-bars" variant="rounded" height={'40%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'50%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'80%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'70%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'50%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'30%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'20%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'10%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'60%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'90%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'80%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'43%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'45%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'56%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'68%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'45%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'23%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'65%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'37%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'87%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'23%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'45%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'66%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'77%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'55%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'22%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'54%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'41%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'62%'} />
                <Skeleton className="skeleton-bars" variant="rounded" height={'37%'} />
            </Box>
            <Box sx={{ display: "flex", gap: "20px", justifyContent: "end", padding: "4px 16px 8px 16px" }}>
                <Skeleton variant="rounded" width={100} height={20} />
                <Skeleton variant="rounded" width={100} height={20} />
            </Box>
        </Box>
    );
    const defaultOptionsGraph = {
        loop: true,
        autoplay: true,
        animationData: GraphLoadingLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const GraphLottieLoader = () => {
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const timer = setTimeout(() => {
                setLoading(true);
            }, 2000); // Adding delay in milliseconds 

            // Clear the timeout if the component is unmounted before the delay is completed
            return () => clearTimeout(timer);
        }, []);

        if (loading) {
            return (
                <Box className="lottie-main-container">
                    <Box className="table-lottie-container">
                        <Lottie
                            options={defaultOptionsGraph}
                            height={40}
                            width={40}
                        />
                        <div>
                            {randomMessage()}
                        </div>
                    </Box>
                </Box>
            );
        }
        return null;
    };

    return (
        // <div className='chart-view data-table-container'>
        //     {(props.data && props.data.length > 0) ? (<Grid container spacing={1}>
        //         <Grid item xs={6}>
        //             <div className='chart-box'>
        //                 <header className='chart-title'>On The Books (By Month)</header>
        //                 <div className='chart-container'>
        //                     {!showLoader && <HighchartsReact
        //                         highcharts={Highcharts}
        //                         options={optionOtbByMonth} />
        //                     }
        //                     {showLoader && <Spinner />}
        //                 </div>
        //             </div>
        //         </Grid>
        //         <Grid item xs={6}>
        //             <div className='chart-box'>
        //                 <header className='chart-title'>Pickup (By Month)</header>
        //                 <div className='chart-container'>
        //                     {!showLoader && <HighchartsReact
        //                         highcharts={Highcharts}
        //                         options={optionPickupByMonth} />
        //                     }
        //                     {showLoader && <Spinner />}
        //                 </div>
        //             </div>
        //         </Grid>
        //     </Grid>)
        //         :
        //         (<>
        //             <Grid className='daily-flash-waiting' sx={{minHeight: "348px" }}>
        //                 {/* <Loading showText="Generating the Report, Please wait..." showLoader="false" /> */}
        //                 <GraphLottieLoader />
        //                 {graphSkeletonLoader}
        //             </Grid>
        //         </>)}
        // </div>

        <div className='chart-view data-chart-container'>
            <Grid container spacing={1}>
                <Grid item xs={12} sx={{ display: resError ? "all" : "none", minHeight: "356px" }}>
                    <Alert severity="info">
                        Something went wrong, please contact success@lodgiq.com for further details.
                    </Alert>
                </Grid>
                <Grid item xs={6} sx={{ display: !resError ? "all" : "none" }}>
                    <div className='chart-box'>
                        <header className='chart-title'>On The Books (By Month)</header>
                        <div className='chart-container'>
                            {props.data && props.data.length > 0 && <HighchartsReact
                                highcharts={Highcharts}
                                options={optionOtbByMonth} />
                            }
                            {!props.data &&
                                <div style={{ height: "100%" }}>
                                    {/* <GraphLottieLoader /> */}
                                    {graphSkeletonLoader}
                                </div>
                            }
                        </div>
                    </div>
                </Grid>
                <Grid item xs={6} sx={{ display: !resError ? "all" : "none" }}>
                    <div className='chart-box'>
                        <header className='chart-title'>Pickup (By Month)</header>
                        <div className='chart-container'>
                            {props.data && props.data.length > 0 && <HighchartsReact
                                highcharts={Highcharts}
                                options={optionPickupByMonth} />
                            }
                            {!props.data &&
                                <div style={{ height: "100%" }}>
                                    {/* <GraphLottieLoader /> */}
                                    {graphSkeletonLoader}
                                </div>
                            }
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default DataChart
