import { deepCopy, alterApiParams } from "../app/util";
import { fetchDataSimple } from '../services/data.service';
import moment from 'moment';
/* Usage example
const dbName = 'MyDatabase';
const storeName = 'MyStore';
const dbManager = new IndexedDBManager(dbName, storeName);

dbManager.openDB().then(() => {
    const data = {};
    return dbManager.addData(data);
}).then((key) => {
    console.log(`Data added with key: ${key}`);
    return dbManager.getData(key);
}).then((retrievedData) => {
    console.log('Retrieved data:', retrievedData);
    retrievedData.value = 'Updated value';
    return dbManager.updateData(retrievedData.id, retrievedData);
}).then(() => {
    console.log('Data updated');
    return dbManager.deleteData(retrievedData.id);
}).then(() => {
    console.log('Data deleted');
    dbManager.closeDB();
}).catch((error) => {
    console.error('Error:', error);
    dbManager.closeDB();
});
*/

export default class IndexedDBManager {
    constructor(dbName = 'lodgiq_RM_DB', storeName = 'api_response', version = 1) {
        this.dbName = dbName;
        this.storeName = storeName;
        this.db = null;
        this.version = version;
    }

    openDB() {
        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open(this.dbName, this.version);

            request.onerror = (event) => {
                reject(event.target.error);
            };

            request.onsuccess = (event) => {
                this.db = event.target.result;
                resolve();
            };

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(this.storeName)) {
                    const store = db.createObjectStore(this.storeName, { keyPath: 'id', autoIncrement: true });
                    // Add any additional configuration for the store
                }
            };
        });
    }

    closeDB() {
        if (this.db) {
            this.db.close();
            this.db = null;
        }
    }

    addData(data, key) {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, 'readwrite');
            const objectStore = transaction.objectStore(this.storeName, { keyPath: "id" });
            const request = objectStore.put(data);

            request.onsuccess = () => {
                // debugger;
                resolve(request.result);
            };

            request.onerror = (event) => {
                // debugger;
                reject(event.target.error);
            };
        });
    }

    haveFilterableData(data) {
        if(data.index || data.Index || data.stay_date) return true;
        return false;
    }

    getData(key, meta) {
        //const { fromDate, toDate, get_url } = meta;

        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, 'readonly');
            const objectStore = transaction.objectStore(this.storeName);
            const request = objectStore.get(key);

            request.onsuccess = () => {
                // debugger;
                if (request.result && request.result.response && request.result.response.data && request.result.response.data[0] && this.haveFilterableData(request.result.response.data[0])) {

                    if (meta && meta.date_from && meta.date_until) {
                        const storedResponse = request.result.response;
                        let filteredData = [];

                        let firstDay = new moment().startOf('month');
                        let lastDay = new moment().add(12, 'month').endOf('month');
                        let fromDate = new moment(meta.date_from);
                        let toDate = new moment(meta.date_until);

                        //Find out if data for first date is available or not
                        const firstDateData = storedResponse.data.find((item) => {
                            let itemDate = item.index && item.index.date ? item.index.date : '';
                            if (item.stay_date) itemDate = item.stay_date;
                            if (item.Index && item.Index.date) itemDate = item.Index.date;
                            if (itemDate) {
                                let date = new moment(itemDate);
                                if (date.isSame(fromDate)) {
                                    return true;
                                }
                            }
                        });

                        //Find out if data for last date is available or not
                        const lastDateData = storedResponse.data.find((item) => {
                            let itemDate = item.index && item.index.date ? item.index.date : '';
                            if (item.stay_date) itemDate = item.stay_date;
                            if (item.Index && item.Index.date) itemDate = item.Index.date;
                            if (itemDate) {
                                let date = new moment(itemDate);
                                if (date.isSame(toDate)) {
                                    return true;
                                }
                            }
                        });

                        if(!firstDateData || !lastDateData || (fromDate.isBefore(firstDay) || toDate.isAfter(lastDay))) {
                            // debugger;
                            console.warn('DATA Warning: data for ' + key + ' the date range is not available in IDB');
                            return resolve({ result: 'no-data', stopYearlyData: true });
                        };
                        

                        //Find out if dba data is available or not
                        const dbaData = storedResponse.data.find((item) => {
                            if(item.index) return item.index && item.index.dba;
                            if(item.Index) return item.Index && item.Index.dba;
                        });

                        

                        //All date range data available
                        if (!dbaData) {
                            //Filter data from localStorage
                            filteredData = storedResponse.data.filter((item) => {
                                let itemDate = item.index && item.index.date ? item.index.date : '';
                                if (item.stay_date) itemDate = item.stay_date;
                                if (item.Index && item.Index.date) itemDate = item.Index.date;
                                if (itemDate) {
                                    let date = new moment(itemDate);
                                    if (date.isSameOrAfter(fromDate) && date.isSameOrBefore(toDate)) {
                                        return true;
                                    }
                                }
                            });

                            let storedResponseCopy = deepCopy(storedResponse);
                            storedResponseCopy.data = filteredData;
                            //resolving request using local storage data
                            //resolve(storedResponseCopy);
                        }

                        //DBA data
                        if (dbaData) {
                            //Filter data from localStorage
                            let startDba = -1;
                            let endDba = Number(meta.dbaFilter);
                            filteredData = storedResponse.data.filter((item) => {
                                let itemDba = item.index && item.index.dba ? item.index.dba : '';
                                if (itemDba >= startDba && itemDba <= endDba) { 
                                    return true;
                                }
                            });

                            let storedResponseCopy = deepCopy(storedResponse);
                            storedResponseCopy.data = filteredData;
                            //resolving request using local storage data
                            //resolve(storedResponseCopy);
                        }
                        

                        // In case first date data not available
                        // Preparing an API call for missing dates data from server
                        if (filteredData.length) {
                            let item = filteredData[0];
                            let itemDate = item.index && item.index.date ? item.index.date : '';
                            if (item.Index && item.Index.date) itemDate = item.Index.date;
                            if (item.stay_date) itemDate = item.stay_date;
                            let toDate = new moment(itemDate).subtract(1, 'day').startOf('month').format('YYYY-MM-DD');
                            
                            let storedResponseCopy = deepCopy(storedResponse);
                            storedResponseCopy.data = filteredData;
                            // debugger;

                            //Handle for forecast data
                            if (['OSRContrainedForecast'].includes(storedResponse.data_id)) {
                                let today = new moment();
                                let isPast = fromDate.isBefore(today);
                                let isFuture = fromDate.isSameOrAfter(today);
                                
                                if (isPast) {
                                    return resolve(storedResponseCopy);
                                } else if (isFuture && (firstDateData || lastDateData)) {
                                    return resolve(storedResponseCopy);
                                } else {
                                    return resolve({ result: 'no-data' });
                                }
                            }
                            
                           
                            if (!lastDateData && !dbaData) {
                                console.warn('DATA Warning: Last date data is not available');
                                // return resolve({ result: 'no-data', cause: 'Last date data is not available', firstDateData });
                            }
                            if (!firstDateData && !dbaData) {
                                console.warn('DATA Warning: First date data is not available');
                                // return resolve({ result: 'no-data', cause: 'First date data is not available', lastDateData });
                            }
                            return resolve(storedResponseCopy);
                        } else {
                            console.warn('DATA Warning: data for ' + key + ' the date range is not available in IDB');
                            return resolve({ result: 'no-data' });
                        }
                    }
                }
                if (request.result && request.result.response) {
                    // debugger;
                    return resolve(request.result.response);
                }
                if (!request.result) {
                    // debugger;
                    return resolve({ result: 'no-data', cause: 'No data in IDB store' });
                }
            };

            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    }

    updateData(key, newData) {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, 'readwrite');
            const objectStore = transaction.objectStore(this.storeName, { keyPath: "id" });
            const request = objectStore.put(newData, key);

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    }

    deleteData(key) {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, 'readwrite');
            const objectStore = transaction.objectStore(this.storeName);
            const request = objectStore.delete(key);

            request.onsuccess = () => {
                resolve();
            };

            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    }
    cleanIdbStore(key) {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction(this.storeName, 'readwrite');
            const objectStore = transaction.objectStore(this.storeName);
            const request = objectStore.clear();

            request.onsuccess = () => {
                resolve({ result: this.storeName + ' cleared' });
            };

            request.onerror = (event) => {
                reject(event.target.error);
            };
        });
    }
}