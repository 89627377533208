
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectingAirportsSharpIcon from '@mui/icons-material/ConnectingAirportsSharp';
import FlightIcon from '@mui/icons-material/Flight';
import { Button, Box, Modal, FormControl, Select, Typography, Container, Grid, Icon } from '@mui/material'
import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';
import { deepCopy, getSeriesById, in_array } from "../../app/util";
import dataService from "../../services/data.service";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RatePlanPopup from './RatePlanPopup';
import LogsRoomCodes from "./LogsRoomCodes";

const DisplayDataLogs = (props) => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    if (props.dataKey === "Roomsrate") {
        console.log(props.displayValue);
        // debugger;
    }

    let logsReports = ["rm_room_type_ratesend_status"];
    let activeTileArr = [ui_state.active_tiles[0]];
    // (in_array(logsReports,ui_state.active_tiles[0])) ? 
    let displayValue = props.displayValue;
    switch (ui_state.active_tiles[0]) {
        case "rm_room_type_ratesend_status":
            displayValue = displayValue;
            break;
        case "DailyRatesendStatus":
            displayValue = displayValue;
            break;
        case "restrictions_push_status":
            displayValue = displayValue;
            break;
        default:
            displayValue = parseFloat(props.displayValue).toFixed(2) + "%";
            break;
    }
//console.log(ui_state.active_tiles[0]," => ",displayValue);
displayValue = (displayValue === 'Infinity%') ? "0%" : displayValue;
    return (
        <>
            {
                (props.dataKey === "Roomsrate") ?
                    <LogsRoomCodes displayValue={displayValue} displayType={props.displayType} />
                    :
                    displayValue
            }
            {/* {displayValue} */}
        </>
    )
}

export default DisplayDataLogs