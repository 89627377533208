import { APP_CACHE, NEW_PROPERTY_SYSTEM_SETTINGS } from "../app/config";
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
    PROFILE_FAILED,
    PROFILE_SUCCESS,
    //For reset password
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
} from "./types";
import { addToSession, getFromSession, getDataSources, clearAPIRegistryData, getSeriesById } from "../app/util";
import { API_REGISTRY } from "../app/config";
import { fetchDataSimple } from "../services/data.service";
import DataService from "../services/data.service";
import AuthService from "../services/auth.service";
import IndexedDBManager from "../indexed-db/indexed-db";



export const getUserProfile = (payload) => (dispatch) => {
    const { UserProfile, GetPropData } = API_REGISTRY;
    return DataService.getData(payload).then(
        (data) => {
            let payLoadData = data.user_profile;
            payLoadData.justLoggedIn = true;
            //   debugger;
            //Do some task here just after response
            //Handle API response change for profile
            if (data && data.status === "success") {
                let system_settings = {};
                let defaultPropIndex = 0;
                let defaultProp = payLoadData.property_details.filter((prop, i) => {
                    if (prop.default === 'Y') {
                        defaultPropIndex = i;
                        return prop.default === 'Y';
                    }
                });

                if (defaultProp && defaultProp.length && defaultProp[0].system_settings) {
                    system_settings = defaultProp[0].system_settings;
                    if (system_settings && !system_settings[0].inventory_settings) {
                        console.error("CRITICAL: System settings -> Inventory settings not available");
                        payLoadData.property_details[defaultPropIndex].system_settings[0] = { ...NEW_PROPERTY_SYSTEM_SETTINGS, ...system_settings[0] };
                    }
                }
                //
                const { user_profile } = data;
                const { default_property_id, default_view } = user_profile;
                APP_CACHE.user_view = default_view;
                const requestUri = GetPropData.uri.replace('{pid}', default_property_id);
                fetchDataSimple({ api: requestUri, request: { method: 'GET' } })
                    .then((rs) => {
                        let cache_data = { data: rs, loaded: true, loading: false, reqParams: { api: requestUri, request: { method: 'GET' } } };
                        DataService.updateDataCache("GetPropData", cache_data);
                        payLoadData.property_details[defaultPropIndex].product_variant = rs.pv;
                        payLoadData.current_property_date = rs.current_date;
                        payLoadData.current_property_time_zone = rs.timezone;

                        if (rs.pv && rs.pv.default_tiledefault_tile) {
                            APP_CACHE.active_tile = rs.pv.default_tile;
                            APP_CACHE.active_tiles = [rs.pv.default_tile];
                        } else {
                            APP_CACHE.active_tile = 'otb';
                            APP_CACHE.active_tiles = ['otb'];
                        }

                        dispatch({ type: PROFILE_SUCCESS, payload: payLoadData });
                        return Promise.resolve(data);
                    }, (error) => {
                        console.error(error);
                    });
            }
            if (data && data.status !== "success") {
                dispatch({ type: PROFILE_FAILED, payload: data });
                return Promise.reject(data);
            }
        }
    )
};

export const login = (payload) => (dispatch) => {
    return AuthService.login(payload).then(
        (data) => {
            if (data.status === "success") {
                addToSession("token", data.token_string || data.token);
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { user: data },
                });

                APP_CACHE.active_tiles = [];
                APP_CACHE.active_tile = '';
                APP_CACHE.yearDataStaus = {}; // added for flash report
                //Cleaning indexed db for API response cache
                const idbManager = new IndexedDBManager();
                idbManager.openDB()
                    .then(() => {
                        idbManager.cleanIdbStore();
                    })
                    .then(() => {
                        idbManager.closeDB();
                    });

                return Promise.resolve(data);
            } else {

                dispatch({
                    type: LOGIN_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: data.message,
                });
                return Promise.reject(data.message);
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: LOGIN_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};
export const logout = () => (dispatch) => {
    let token = getFromSession('token');
    dispatch({
        type: LOGOUT,
    });
    const idbManager = new IndexedDBManager();
    idbManager.openDB()
        .then(() => {
            idbManager.cleanIdbStore();
        })
        .then(() => {
            idbManager.closeDB();
        });

    AuthService.logout({ token: token })
        .then((rs) => {
            clearAPIRegistryData();
            dispatch({ type: 'USER_LOGOUT' });

            setTimeout(() => {
                // debugger;
                // window.location.reload();
            }, 50)
            //window.location.reload();
        });

    let zendeskLogout = 'https://lodgiqhelp.zendesk.com/access/logout?return_to=https://lodgiqhelp.zendesk.com/hc/en-us';
    let width = 1;  // Set the width of the mini window
    let height = 1; // Set the height of the mini window
    let left = window.screen.width - width; // Position the window at the right edge of the screen
    let top = window.screen.height - height; // Position the window at the bottom of the screen
    let newTab = window.open(
        zendeskLogout, '_blank', 
        `width=${width},height=${height},left=${left},top=${top},scrollbars=no,resizable=no`
    );
    setTimeout(function () {
        newTab.close();
    }, 500);
};

export const resetPassword = (payload) => (dispatch) => {
    return AuthService.resetPassword(payload).then(
        (data) => {
            if (data.status === "success") {
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    payload: { user: data },
                });
                return Promise.resolve();
            } else {
                dispatch({
                    type: RESET_PASSWORD_FAIL,
                });
                dispatch({
                    type: SET_MESSAGE,
                    payload: data.error_message,
                });
                return Promise.reject(data.error_message);
            }
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch({
                type: LOGIN_FAIL,
            });
            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
            return Promise.reject();
        }
    );
};