import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { setUpDate, checkExistance, convertToPercent } from '../../app/util';
import moment from 'moment';

const CompsetAlertDetails = (props) => {
    let { alertDetails } = props;
    let { kpi, delta, unit, resource_type } = alertDetails.alert_rule;
    let percentageSymbol;
    let dollorSymbol;
    let statusClass;
    let deltaSymbol;
    let bgRooms;
    let bgOcc;
    let bgAdr;
    let bgRevpar;
    let bgRevenue;
    if (kpi === "rooms") {
        bgRooms = "bgRooms";
    } else if (kpi === "occ") {
        bgOcc = "bgOcc";
    } if (kpi === "adr") {
        bgAdr = "bgAdr";
    } if (kpi === "revpar") {
        bgRevpar = "bgRevpar";
    } if (kpi === "revenue") {
        bgRevenue = "bgRevenue";
    }

    if (unit && unit === "percentage") {
        percentageSymbol = "%";
    } else if (unit && unit === "dollor") {
        dollorSymbol = "$";
    }

    if (delta === "increase") {
        statusClass = "delta-increase";
        deltaSymbol = "+";
    } else {
        statusClass = "delta-decrease";
        deltaSymbol = "-";

    }

    let data = alertDetails.data;
    let headerNames = Object.keys(data[0]['snapshot']).reverse();



    return (
        <>
            <Table sx={{ minWidth: 600 }} size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                    <TableRow className='alert-row-head-style'>
                        <TableCell align="center" sx={{ width: "12vw", top: ' -1px' }}>DATE</TableCell>
                        <TableCell align="center" sx={{ width: "12vw", top: ' -1px' }}>DOW</TableCell>
                        {(headerNames && headerNames.length > 0 && headerNames.map((headerName) => {
                            return (<TableCell className={(headerName === "median") ? "median-header" : "median-header"} align="center" sx={{ top: ' -1px' }}>{headerName}</TableCell>);
                        }))}
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        (data && data.length > 0 && data.map((item, index) => {
                            let bgClass = (index % 2 === 0) ? "bg-gray1 hoverStyle" : "hoverStyle";
                            return (
                                <>
                                    <TableRow className={bgClass} key={Math.random()} sx={{ '&:last-child td, &:last-child th': { borderBottom: "1px solid #ddd" } }}>
                                        <TableCell scope="row" align="center" sx={{ p: 0 }}>
                                            {moment(item.stay_date).format("MMM DD")}
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: "1px solid #ddd !important" }}>
                                            {moment(item.stay_date).format("ddd")}
                                        </TableCell>

                                        {(headerNames && headerNames.length > 0 && headerNames.map((headerName) => {
                                            let headerNameValue = kpi ;
                                            if(kpi === "rate"){
                                                headerNameValue = resource_type;
                                            } 
                                            return (
                                                <TableCell align="center" sx={{ p: 0 }} className={bgRevenue}>
                                                    <div className='valContainer'>
                                                        <div className={(headerName === headerNameValue) ? 'val-left' : 'full-width'}>${checkExistance(item['snapshot'][headerName], "0.00")}</div>
                                                        {(kpi && headerName === headerNameValue &&
                                                            <div className={'val-right ' + statusClass}>
                                                                {deltaSymbol}{dollorSymbol}{checkExistance(item.observed_change, "0")}{percentageSymbol}
                                                            </div>
                                                        )}
                                                    </div>
                                                </TableCell>);
                                        }))}

                                    </TableRow>
                                </>
                            );

                        }))
                    }
                </TableBody>
            </Table>
        </>
    )
}

export default CompsetAlertDetails
