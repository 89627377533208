import moment from "moment";
import { convertToPercent, daysCountFromRange, deepCopy, getAppData, getCorrectValue, getDatesInRange, getDayDateKey, getFromState, getObjectFilter, getTotalData_all, getTransformedSegments, in_array, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { transformSegmentData } from "../segmentedData";
import { getSegmentedCode } from "../processDataReports";
import { objectEach } from "highcharts";

export function getReportData_hotel(columns, auth, app_state, ui_state) {
    let inputData = (app_state.workspace_controls.segmented_view) ? [] : deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let marketData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];
    let setup_data = [];
    const { active_tiles, table_filters } = ui_state;
    const { dayDate } = app_state.workspace_controls;
    const filteredDow = table_filters.table_reports.dow.map(d => d.code);
    // this block of code is used to remove the unmapped item from the hotel total key
    if (inputData && inputData.length) {
        inputData.forEach((dataSet, index) => {
            let k = Object.keys(dataSet);
            if (k.includes("Segment_UNMAPPED")) {
                let hotelKeys = Object.keys(dataSet.HotelTotal);
                hotelKeys.forEach(element => {
                    inputData[index]['HotelTotal'][element]['Rooms'] = inputData[index]['HotelTotal'][element]['Rooms'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['Rooms']);
                    inputData[index]['HotelTotal'][element]['Occ'] = inputData[index]['HotelTotal'][element]['Occ'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['Occ']);
                    inputData[index]['HotelTotal'][element]['ADR'] = inputData[index]['HotelTotal'][element]['ADR'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['ADR']);
                    inputData[index]['HotelTotal'][element]['RevPAR'] = inputData[index]['HotelTotal'][element]['RevPAR'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['RevPAR']);
                    inputData[index]['HotelTotal'][element]['Revenue'] = inputData[index]['HotelTotal'][element]['Revenue'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['Revenue']);
                    inputData[index]['HotelTotal'][element]['SellRate'] = inputData[index]['HotelTotal'][element]['SellRate'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['SellRate']);
                });
            }
        });
    }
    let data = inputData;

    if (data) {
        let dataKey = "HotelTotal";
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = {};
                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, item.index.date);
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.index.segment;
                    }
                }

                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (mainKey === "difference_sdly") {
                            let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey]["primary"][key.value] - item[dataKey][dayDateKey][key.value])
                            } else if (key.value === "ADR") {
                                dataObj[key.value] = (item[dataKey]['primary']['Revenue'] / item[dataKey]['primary']['Rooms']) - (item[dataKey][dayDateKey]['Revenue'] / item[dataKey][dayDateKey]['Rooms']);
                            } else {
                                dataObj[key.value] = round(item[dataKey]["primary"][key.value] - item[dataKey][dayDateKey][key.value])
                            }
                        } else if (mainKey === "sdly_pickup_diff") {
                            let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                            let dayDate_sdly_actual = (dayDate === "day") ? "sdly_actual" : "sdtly_actual";
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey][dayDate_sdly_actual][key.value] - item[dataKey][dayDateKey][key.value]);
                            } else if (key.value === "ADR") {
                                dataObj[key.value] = (item[dataKey][dayDate_sdly_actual]['Revenue'] / item[dataKey][dayDate_sdly_actual]['Rooms']) - (item[dataKey][dayDateKey]['Revenue'] / item[dataKey][dayDateKey]['Rooms']);
                            } else {
                                dataObj[key.value] = round(item[dataKey][dayDate_sdly_actual][key.value] - item[dataKey][dayDateKey][key.value])

                            }
                        } else if (mainKey === "marketprimary") {
                            if (key.value == "Occ") {
                                dataObj[key.value] = (marketData[index]) ? convertToPercent(marketData[index]["MarketTotal"]["primary"][key.value]) : 0.00;
                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["primary"][key.value]) : 0.00;
                            }
                        } else if (mainKey === "marketsdly") {
                            if (key.value == "Occ") {
                                dataObj[key.value] = (marketData[index]) ? convertToPercent(marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00;
                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00;
                            }
                        } else if (mainKey === "market_sdly_diff") {
                            if (key.value == "Occ") {
                                dataObj[key.value] = (marketData[index]) ? round(convertToPercent(marketData[index]["MarketTotal"]["primary"][key.value]) - convertToPercent(marketData[index]["MarketTotal"]["sdly"][key.value])) : 0.00;
                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["primary"][key.value] - marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00;
                            }
                        } else {
                            mainKey = getDayDateKey(dayDate, mainKey);
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]);
                            } else if (key.value == "ADR") {
                                dataObj[key.value] = item[dataKey][mainKey]['Revenue'] / item[dataKey][mainKey]['Rooms'];
                            } else {
                                dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                            }
                        }
                    })
                }
                dataObj.id = col.id;
                data_columns.push(dataObj);
            })

            data_columns.map((itmArr, idx) => {
                if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                    data_columns[idx]["ignore_sort"] = true;
                }
            })
            setup_data.push(data_columns);
        })
    }
    return setup_data;

}


export function getTopSegments(auth, app_state, category) {
    let system_settings = getAppData(auth, app_state)
    let market_segment_category_mapping = system_settings.market_segment_category_mapping;
    const lvl1_mseg_grouping = JSON.parse(
        JSON.stringify(system_settings.lvl1_mseg_grouping)
    );
    const topSegments = {};
    lvl1_mseg_grouping.filter((item) => {
        if (item.Transient) topSegments.Transient = item.Transient;
        if (item.Group) topSegments.Group = item.Group;
        if (item.Contract) topSegments.Contract = item.Contract;
    });
    let segmentCodes = [];
    let topSegmentsList = topSegments[category];
    if (topSegmentsList) {
        topSegmentsList.map((item) => {
            if (market_segment_category_mapping[item]) {
                segmentCodes = [...segmentCodes, ...market_segment_category_mapping[item]];
            }
        })
        segmentCodes = [...new Set(segmentCodes)];
    }
    return segmentCodes;
}


export function getReportSegmentedData_hotel(columns, auth, app_state, ui_state) {
    let data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    let categoryList = ["Transient", "Group", "Contract", "Total"];
    const { active_tiles, table_filters } = ui_state;
    const { dayDate } = app_state.workspace_controls;

    let dates = getDatesInRange(date_from, date_until);
    let sdlyText = "sdly";
    let sdlyActualText = "sdly_actual";
    if (dayDate === 'day') {
        sdlyText = "sdly";
        sdlyActualText = "sdly_actual";
    } else {
        sdlyText = "sdtly";
        sdlyActualText = "sdtly_actual";
    }
    /////// final Total /////
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdtlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdtly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

    let allTotal = {
        "Transient": {
            "primary": deepCopy(blankObj),
            [sdlyText]: deepCopy(blankObj),
            "difference_sdly": deepCopy(blankObj),
            [sdlyActualText]: deepCopy(blankObj),
            "sdly_pickup_diff": deepCopy(blankObj),
            "marketprimary": deepCopy(blankObj),
            "marketsdly": deepCopy(blankObj),
            "market_sdly_diff": deepCopy(blankObj),

            // "sdtly": deepCopy(blankObj),
            // "sdtly_actual": deepCopy(blankObj),
            // "sdtly_pickup": deepCopy(blankObj),
        },
        "Group": {
            "primary": deepCopy(blankObj),
            [sdlyText]: deepCopy(blankObj),
            "difference_sdly": deepCopy(blankObj),
            [sdlyActualText]: deepCopy(blankObj),
            "sdly_pickup_diff": deepCopy(blankObj),
            "marketprimary": deepCopy(blankObj),
            "marketsdly": deepCopy(blankObj),
            "market_sdly_diff": deepCopy(blankObj),
            // "sdtly": deepCopy(blankObj),
            // "sdtly_actual": deepCopy(blankObj),
            // "sdtly_pickup": deepCopy(blankObj),
        },
        "Contract": {
            "primary": deepCopy(blankObj),
            [sdlyText]: deepCopy(blankObj),
            "difference_sdly": deepCopy(blankObj),
            [sdlyActualText]: deepCopy(blankObj),
            "sdly_pickup_diff": deepCopy(blankObj),
            "marketprimary": deepCopy(blankObj),
            "marketsdly": deepCopy(blankObj),
            "market_sdly_diff": deepCopy(blankObj),
            // "sdtly": deepCopy(blankObj),
            // "sdtly_actual": deepCopy(blankObj),
            // "sdtly_pickup": deepCopy(blankObj),
        },

    }

    ///// end final total //// 
    let allSegmentCodes = [];
    categoryList.map((category, idx) => {
        let segmentCodes = getTopSegments(auth, app_state, category);
        allSegmentCodes = [...allSegmentCodes, ...segmentCodes]
    })
    let roomsTotal = 0;
    let totalDays = daysCountFromRange(app_state);

    if (dates && dates.length > 0) {

        dates.map((date, dateIndex) => {

            categoryList.map((category, categoryIndex) => {
                let data_columns = [];
                let segmentCodes = getTopSegments(auth, app_state, category);
                let code = (category === "Total") ? allSegmentCodes : segmentCodes

                columns.map(col => {
                    let dataObj = {};
                    dataObj.id = col.id;
                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        if (app_state.workspace_controls.segmented_view) {
                            dataObj["segment"] = category;
                        }
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {

                            if (mainKey === "difference_sdly") {
                                let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";

                                let keyPrimaryTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, code, "primary", "osr", category);
                                let keySdlyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, code, dayDateKey, "osr", category);

                                let keysTotalPrimary = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, "primary", "osr", category);
                                let keysTotalSdly = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dayDateKey, "osr", category);

                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keySdlyTotalValue);
                                    if (category !== "Total") {
                                        allTotal[category][mainKey][key.value] += convertToPercent(((keysTotalPrimary - keysTotalSdly) / totalDays));
                                    }
                                } else if (key.value === "ADR_xxx") {
                                    dataObj[key.value] = round(keyPrimaryTotalValue - keySdlyTotalValue);
                                  
                                    if (category !== "Total") {
                                        allTotal[category][mainKey][key.value] += keysTotalPrimary - keysTotalSdly;
                                    }
                                } else {
                                    dataObj[key.value] = round( getCorrectValue(keyPrimaryTotalValue) - getCorrectValue(keySdlyTotalValue));
                                    
                                    if (category !== "Total") {
                                        allTotal[category][mainKey][key.value] += (in_array([key.value], ['Rooms', 'Revenue'])) ? keysTotalPrimary - keysTotalSdly : ((keysTotalPrimary - keysTotalSdly) / totalDays);
                                    }
                                }
                            } else if (mainKey === "sdly_pickup_diff") {
                                let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                                let dayDate_sdly_actual = (dayDate === "day") ? "sdly_actual" : "sdtly_actual";

                                let keySdlyActualTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, code, dayDate_sdly_actual, "osr", category);
                                let keySdlyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, code, dayDateKey, "osr", category);

                                let keysTotal_sdly_actual = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dayDate_sdly_actual, "osr", category);
                                let keysTotalSdly = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dayDateKey, "osr", category);

                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(keySdlyActualTotalValue - keySdlyTotalValue);
                                    if (category !== "Total") {
                                        allTotal[category][mainKey][key.value] += convertToPercent(((keysTotal_sdly_actual - keysTotalSdly) / totalDays));
                                    }
                                } else {
                                    dataObj[key.value] = round(keySdlyActualTotalValue - keySdlyTotalValue);
                                    if (category !== "Total") {
                                        allTotal[category][mainKey][key.value] += (in_array([key.value], ['Rooms', 'Revenue'])) ? keysTotal_sdly_actual - keysTotalSdly : ((keysTotal_sdly_actual - keysTotalSdly) / totalDays);
                                    }
                                }
                            }
                            else if (mainKey === "marketprimary") {
                                let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValue_market);
                                } else {
                                    dataObj[key.value] = round(keyTotalValue_market);
                                }
                                if (category !== "Total") {
                                    allTotal[category][mainKey][key.value] = 0;
                                }
                            } else if (mainKey === "marketsdly") {
                                let keyTotalValueSdly_market = 0;// getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");

                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValueSdly_market);
                                } else {
                                    dataObj[key.value] = round(keyTotalValueSdly_market);
                                }
                                if (category !== "Total") {
                                    allTotal[category][mainKey][key.value] = 0;
                                }

                            } else if (mainKey === "market_sdly_diff") {
                                let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                let keyTotalValueSdly_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                dataObj[key.value] = round(keyTotalValue_market - keyTotalValueSdly_market);
                                if (category !== "Total") {
                                    allTotal[category][mainKey][key.value] = 0;
                                }
                            }
                            else {
                                mainKey = getDayDateKey(dayDate, mainKey);

                                let keyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, code, mainKey, "osr", category);
                                let keysTotal = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, mainKey, "osr", category);

                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValue);
                                    if (category !== "Total") {
                                        allTotal[category][mainKey][key.value] += convertToPercent((keysTotal / totalDays));
                                    }
                                } else {
                                    dataObj[key.value] = round(keyTotalValue);
                                    if (category !== "Total") {
                                        allTotal[category][mainKey][key.value] += (in_array([key.value], ['Rooms', 'Revenue'])) ? keysTotal : (keysTotal / totalDays);
                                    }
                                }
                            }
                        })
                    }
                    data_columns.push(dataObj);
                })

                setup_data.push(data_columns);

            })

        })
    }

    let totalResult = getAllTotalData(allTotal);
    let sdlyId = (dayDate === 'day') ? "otb_sdly" : "sdtly";
    let sdly_actual_id = (dayDate === 'day') ? "sdly_actual" : "sdtly_actual";
    totalResult.forEach((itm, idx) => {
        itm.forEach((element, index) => {
            if (element.id === "difference_sdly") {
                let diffFrom = itm.findIndex((it) => it.id === "primary");
                let diffTo= itm.findIndex((it) => it.id === sdlyId);
                totalResult[idx][index].ADR =   itm[diffFrom].ADR - itm[diffTo].ADR ;
            }else if (element.id === "sdly_pickup_diff") {
                let diffFrom = itm.findIndex((it) => it.id === sdly_actual_id);
                let diffTo= itm.findIndex((it) => it.id === sdlyId);
                totalResult[idx][index].ADR =   itm[diffFrom].ADR - itm[diffTo].ADR ;
            }
            else{
                let rsAdr = element.Revenue / element.Rooms;
                totalResult[idx][index].ADR = rsAdr;
            }
        })
    })

    setup_data = setup_data.concat(totalResult);
    return setup_data;
}


/** final Total for Hotel Level */

function getAllTotalData(allTotal) {
    let keys = Object.keys(allTotal);
    let result = [];
    keys.map((item) => {
        let itemKeys = Object.keys(allTotal[item]);
        let totalArr = [];
        let obj = {};
        obj.segment = item;
        obj.date = "";
        obj.dow = "";
        obj.id = "staydates";
        totalArr.push(obj);

        itemKeys.map((mainKey, index) => {
            if (mainKey === 'sdly') {
                allTotal[item][mainKey]['id'] = "otb_sdly";
            } else {
                allTotal[item][mainKey]['id'] = mainKey;
            }
            totalArr.push(allTotal[item][mainKey]);
        })
        result.push(totalArr);
    })
    return result;
}



/** End  */

// function to calculate segmented data for individual category segment.

export function getReportSegmentedData_topSegment(columns, auth, app_state, ui_state, segmentCodes, segments) {
    let data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

    const { active_tiles, table_filters } = ui_state;
    const { dayDate } = app_state.workspace_controls;

    let filteredDow = [];
    let dates = getDatesInRange(date_from, date_until);

    let totalAll = {
        primary: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
        sdly: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
        difference_sdly: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
        sdly_actual: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
        sdly_pickup_diff: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
        marketprimary: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
        marketsdly: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
        market_sdly_diff: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
        sdtly: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
        sdtly_actual: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
        sdtly_pickup: { "Occ": 0, "Rooms": 0, "ADR": 0, "RevPAR": 0, "Revenue": 0, "Sellrate": 0 },
    };
    let setup_data_updated = [];
    if (dates && dates.length > 0) {
        dates.map((date, dateIndex) => {
            let dataRecord = data.filter((item) => moment(item.index.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"));

            segmentCodes.map((segmentCode, segmentIndex) => {
                let data_columns = [];
                let segmentName = segmentCode;
                let segmentKey = "Segment_" + segmentCode;
                columns.map(col => {
                    let dataObj = {};
                    dataObj.id = col.id;
                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        dataObj["segment"] = segmentCode; //segments.map[segmentCode];
                    }

                    if (dataRecord && dataRecord.length) {
                        let to = {};
                        let total = dataRecord[0].HotelTotal;
                        let identities = Object.keys(total);
                        if (dataRecord[0][segmentKey] && !dataRecord[0][segmentKey].primary) {
                            identities.forEach((k) => {
                                to[k] = deepCopy(blankObj);
                            });
                            dataRecord[0][segmentKey] = to;
                        }
                        if (!dataRecord[0][segmentKey]) {
                            identities.forEach((k) => {
                                to[k] = deepCopy(blankObj);
                            });
                            dataRecord[0][segmentKey] = to;
                        }
                    }

                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (dataRecord[0][segmentKey]) {

                                if (mainKey === "difference_sdly") {
                                    let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                                    if (key.value === "Occ") {
                                        dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey][dayDateKey][key.value])
                                        totalAll[mainKey][key.value] += convertToPercent(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey][dayDateKey][key.value])
                                    } else {
                                        dataObj[key.value] = round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey][dayDateKey][key.value])
                                        totalAll[mainKey][key.value] += round(dataRecord[0][segmentKey]["primary"][key.value] - dataRecord[0][segmentKey][dayDateKey][key.value]);
                                    }
                                } else if (mainKey === "sdly_pickup_diff") {
                                    let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                                    let dayDate_sdly_actual = (dayDate === "day") ? "sdly_actual" : "sdtly_actual";
                                    if (key.value === "Occ") {
                                        dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][dayDate_sdly_actual][key.value] - dataRecord[0][segmentKey][dayDateKey][key.value])
                                        totalAll[mainKey][key.value] += convertToPercent(dataRecord[0][segmentKey][dayDate_sdly_actual][key.value] - dataRecord[0][segmentKey][dayDateKey][key.value])
                                    } else {
                                        dataObj[key.value] = round(dataRecord[0][segmentKey][dayDate_sdly_actual][key.value] - dataRecord[0][segmentKey][dayDateKey][key.value])
                                        totalAll[mainKey][key.value] += round(dataRecord[0][segmentKey][dayDate_sdly_actual][key.value] - dataRecord[0][segmentKey][dayDateKey][key.value])
                                    }
                                } else if (mainKey === "marketprimary") {
                                    if (key.value == "Occ") {
                                        dataObj[key.value] = 0;//convertToPercent(marketData[index]["MarketTotal"]["primary"][key.value]);
                                        totalAll[mainKey][key.value] += 0;
                                    } else {
                                        dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["primary"][key.value]);
                                        totalAll[mainKey][key.value] += 0;
                                    }
                                } else if (mainKey === "marketsdly") {
                                    if (key.value == "Occ") {
                                        dataObj[key.value] = 0;//convertToPercent(marketData[index]["MarketTotal"]["sdly"][key.value]);
                                        totalAll[mainKey][key.value] += 0;
                                    } else {
                                        dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["sdly"][key.value]);
                                        totalAll[mainKey][key.value] += 0;
                                    }
                                } else if (mainKey === "market_sdly_diff") {
                                    dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["primary"][key.value] - marketData[index]["MarketTotal"]["sdly"][key.value])
                                    totalAll[mainKey][key.value] += 0;
                                } else {
                                    mainKey = getDayDateKey(dayDate, mainKey);
                                    if (key.value == "Occ") {
                                        dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                        totalAll[mainKey][key.value] += convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                    } else {
                                        if (mainKey === "primary") {
                                            totalAll[mainKey][key.value] += round(dataRecord[0][segmentKey][mainKey][key.value]);
                                        }
                                        if (mainKey === "sdly" || mainKey === "sdtly") {
                                            totalAll[mainKey][key.value] += round(dataRecord[0][segmentKey][mainKey][key.value]);
                                        }
                                        dataObj[key.value] = round(dataRecord[0][segmentKey][mainKey][key.value]);
                                    }
                                }

                            }
                        })
                    }
                    data_columns.push(dataObj);

                })
                setup_data.push(data_columns);
            })



            /**  get keys total  */
            // let tmpFilteredData = deepCopy(setup_data);
            // tmpFilteredData = tmpFilteredData.filter(item => item[0].segment === 'Other' &&  moment(item[0].date).isSame(moment(date)) );
            // let result = getTotalData_all(tmpFilteredData, app_state, ui_state, "segment")
            // result.splice(0,0,tmpFilteredData[0][0]);
            // console.log("result ", result);

            // setup_data.map((item,index) => {
            //    let countItem =  item.filter((a)=> a.segment !== 'Other' &&  moment(item[0].date).isSame(moment(date)) )
            //     item.map((itemInner, innerIndex)=>{
            //         if(itemInner.segment === 'Other' && moment(item[0].date).isSame(moment(date)) ){
            //             setup_data[index].splice(index,1)
            //             // console.log(index, " inner ",itemInner, innerIndex);
            //         }
            //     })
            //     // setup_data[index].splice(0,0,result)
            //     console.log(item," ",index);
            //     debugger;
            // })


            // console.log(setup_data);
            // debugger;
            /** end  */

            //////////
            // console.log("Total All = ", totalAll);
            let dateData = setUpDate({}, date)
            let innerArr = [{
                date: dateData['date'],
                dow: dateData['dow'],
                id: "staydates",
                segment: "Total"
            },
            {
                "id": "otb_primary_report",
                "Occ": totalAll.primary.Occ,
                "Rooms": totalAll.primary.Rooms,
                "ADR": getCorrectValue(totalAll.primary.Revenue / totalAll.primary.Rooms),
                "RevPAR": totalAll.primary.RevPAR,
                "Revenue": totalAll.primary.Revenue,
                "SellRate": totalAll.primary.SellRate,
            },
            {
                "id": "sdly",
                "Occ": totalAll.sdly.Occ,
                "Rooms": totalAll.sdly.Rooms,
                "ADR": getCorrectValue(totalAll.sdly.Revenue / totalAll.sdly.Rooms),
                "RevPAR": totalAll.sdly.RevPAR,
                "Revenue": totalAll.sdly.Revenue,
                "SellRate": totalAll.sdly.SellRate,
            },
            {
                "id": "difference_sdly",
                "Occ": totalAll.difference_sdly.Occ,
                "Rooms": totalAll.difference_sdly.Rooms,
                "ADR": getCorrectValue(totalAll.difference_sdly.Revenue / totalAll.difference_sdly.Rooms),
                "RevPAR": totalAll.difference_sdly.RevPAR,
                "Revenue": totalAll.difference_sdly.Revenue,
                "SellRate": totalAll.difference_sdly.SellRate,
            }
                ,
            {
                "id": "sdly_actual",
                "Occ": totalAll.sdly_actual.Occ,
                "Rooms": totalAll.sdly_actual.Rooms,
                "ADR": getCorrectValue(totalAll.sdly_actual.Revenue / totalAll.sdly_actual.Rooms),
                "RevPAR": totalAll.sdly_actual.RevPAR,
                "Revenue": totalAll.sdly_actual.Revenue,
                "SellRate": totalAll.sdly_actual.SellRate,
            },
            {
                "id": "sdly_pickup_diff",
                "Occ": totalAll.sdly_pickup_diff.Occ,
                "Rooms": totalAll.sdly_pickup_diff.Rooms,
                "ADR": getCorrectValue(totalAll.sdly_pickup_diff.Revenue / totalAll.sdly_pickup_diff.Rooms),
                "RevPAR": totalAll.sdly_pickup_diff.RevPAR,
                "Revenue": totalAll.sdly_pickup_diff.Revenue,
                "SellRate": totalAll.sdly_pickup_diff.SellRate,
            },
            {
                "id": "marketprimary",
                "Occ": totalAll.marketprimary.Occ,
                "Rooms": totalAll.marketprimary.Rooms,
                "ADR": getCorrectValue(totalAll.marketprimary.Revenue / totalAll.marketprimary.Rooms),
                "RevPAR": totalAll.marketprimary.RevPAR,
                "Revenue": totalAll.marketprimary.Revenue,
                "SellRate": totalAll.marketprimary.SellRate,
            },
            {
                "id": "marketsdly",
                "Occ": totalAll.marketsdly.Occ,
                "Rooms": totalAll.marketsdly.Rooms,
                "ADR": getCorrectValue(totalAll.marketsdly.Revenue / totalAll.marketsdly.Rooms),
                "RevPAR": totalAll.marketsdly.RevPAR,
                "Revenue": totalAll.marketsdly.Revenue,
                "SellRate": totalAll.marketsdly.SellRate,
            }
                ,
            {
                "id": "market_sdly_diff",
                "Occ": totalAll.market_sdly_diff.Occ,
                "Rooms": totalAll.market_sdly_diff.Rooms,
                "ADR": getCorrectValue(totalAll.market_sdly_diff.Revenue / totalAll.market_sdly_diff.Rooms),
                "RevPAR": totalAll.market_sdly_diff.RevPAR,
                "Revenue": totalAll.market_sdly_diff.Revenue,
                "SellRate": totalAll.market_sdly_diff.SellRate,
            }
            ]
            setup_data.push(innerArr);
            // setup_data_updated.push(innerArr);
            // console.log(totalAll,date," == ",setup_data);
        })
    }
    return setup_data;
}


export function getReportData_topSegment(columns, auth, app_state, ui_state, segmentCodes) {
    let data = (app_state.workspace_controls.segmented_view) ? [] : deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let marketData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];
    let setup_data = [];

    const { active_tiles, table_filters } = ui_state;
    const filteredDow = table_filters.table_reports.dow.map(d => d.code);
    data = data.filter((item) => filteredDow.includes(moment(item.index.date).format('ddd').toLocaleLowerCase()));
    marketData = marketData.filter((item) => filteredDow.includes(moment(item.index.date).format('ddd').toLocaleLowerCase()));


    if (data) {
        let dataKey = "HotelTotal";
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = {};
                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, item.index.date);
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.index.segment;
                    }
                }

                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (mainKey === "difference_sdly") {
                            let keyPrimaryTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "primary", "osr");
                            let keySdlyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly", "osr");

                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keySdlyTotalValue)
                            } else {
                                dataObj[key.value] = round(keyPrimaryTotalValue - keySdlyTotalValue)
                            }
                        } else if (mainKey === "sdly_pickup_diff") {
                            let keySdlyActualTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly_actual", "osr");
                            let keySdlyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly", "osr");
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keySdlyActualTotalValue - keySdlyTotalValue)
                            } else {
                                dataObj[key.value] = round(keySdlyActualTotalValue - keySdlyTotalValue)
                            }
                        }
                        else if (mainKey === "marketprimary") {
                            let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValue_market);
                            } else {
                                dataObj[key.value] = round(keyTotalValue_market);
                            }
                        } else if (mainKey === "marketsdly") {
                            let keyTotalValueSdly_market = 0;// getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");

                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValueSdly_market);
                            } else {
                                dataObj[key.value] = round(keyTotalValueSdly_market);
                            }
                        } else if (mainKey === "market_sdly_diff") {
                            let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            let keyTotalValueSdly_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            dataObj[key.value] = round(keyTotalValue_market - keyTotalValueSdly_market)
                        }
                        else {
                            let keyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, mainKey, "osr");
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValue);
                            } else {
                                dataObj[key.value] = round(keyTotalValue);
                            }
                        }
                    })
                }
                dataObj.id = col.id;
                data_columns.push(dataObj);
            })

            data_columns.map((itmArr, idx) => {
                if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                    data_columns[idx]["ignore_sort"] = true;
                }
            })
            setup_data.push(data_columns);
        })
    }
    return setup_data;
}






// function is used to calculate the total of all passed key basis on the the segement
export const getSegmentKeyTotal = (item, key, segmentCodes, mainKey, segmentType = "osr", segmentTotalRow = "") => {
    let keyTotal = 0;
    let revenueTotal = 0;
    let roomsTotal = 0
    if (segmentTotalRow && segmentTotalRow === "Total") {

        // console.log("Item ",item," ",segmentCodes);

        // if (key !== "SellRate") {
        //     keyTotal = item["HotelTotal"][mainKey][key];
        //     console.log(segmentTotalRow," keyTotal ",keyTotal);
        // } else {
        //     keyTotal = 0;
        // }
        // return keyTotal;
        // debugger; 
        if (segmentCodes && segmentCodes.length > 0) {
            segmentCodes.map((code, index) => {
                let segmentCode = (segmentType === "market") ? "MarketSegment_" + code : "Segment_" + code;
                if (item[segmentCode] && item[segmentCode][mainKey]) {
                    if (key === "ADR") {
                        revenueTotal += (item[segmentCode][mainKey]["Revenue"]) ? item[segmentCode][mainKey]["Revenue"] : 0;
                        roomsTotal += (item[segmentCode][mainKey]["Rooms"]) ? item[segmentCode][mainKey]["Rooms"] : 0;
                    } else {
                        keyTotal += (item[segmentCode][mainKey][key]) ? item[segmentCode][mainKey][key] : 0;
                    }
                }
            })
            if (key === "ADR") {
                keyTotal = revenueTotal / roomsTotal;
            }
        }
        return keyTotal;
    } else {

        if (segmentCodes && segmentCodes.length > 0) {
            // debugger;
            segmentCodes.map((code, index) => {
                let segmentCode = (segmentType === "market") ? "MarketSegment_" + code : "Segment_" + code;
                if (item[segmentCode]) {

                    if (key === "ADR") {
                        revenueTotal += (item[segmentCode][mainKey] && item[segmentCode][mainKey]["Revenue"]) ? item[segmentCode][mainKey]["Revenue"] : 0;
                        roomsTotal += (item[segmentCode][mainKey] && item[segmentCode][mainKey]["Rooms"]) ? item[segmentCode][mainKey]["Rooms"] : 0;
                    } else {
                        keyTotal += (item[segmentCode][mainKey] && item[segmentCode][mainKey][key]) ? item[segmentCode][mainKey][key] : 0;
                    }
                }
            })
            if (key === "ADR") {
                keyTotal = revenueTotal / roomsTotal;
            }
            // console.log("keyTotalkeyTotalkeyTotal ",keyTotal);
            return keyTotal;
        }

    }

}





export const getSegmentKeyTotal_new_old = (item, key, segmentCodes, mainKey, segmentType = "osr", segmentTotalRow = "") => {
    let keyTotal = 0;
    let revenueTotal = 0;
    let roomsTotal = 0
    if (segmentTotalRow && segmentTotalRow === "Total") {
        if (segmentCodes && segmentCodes.length > 0) {
            segmentCodes.map((code, index) => {
                let segmentCode = (segmentType === "market") ? "MarketSegment_" + code : "Segment_" + code;
                if (key === "ADR") {
                    revenueTotal += (item && item[segmentCode][mainKey]["Revenue"]) ? item[segmentCode][mainKey]["Revenue"] : 0;
                    roomsTotal += (item && item[segmentCode][mainKey]["Rooms"]) ? item[segmentCode][mainKey]["Rooms"] : 0;
                } else {
                    // console.log(item," -> ",segmentCode);
                    keyTotal += (item && item[segmentCode][mainKey][key]) ? item[segmentCode][mainKey][key] : 0;
                }
            })
            if (key === "ADR") {
                keyTotal = revenueTotal / roomsTotal;
            }
        }
        return keyTotal;
    }

}


// export const getSegmentKeyTotal_new(fullData, key.value, filteredSegment, 'primary')
export const getSegmentKeyTotal_new = (item, key, segmentCodes, mainKey, segmentType = "osr") => {
    let keyTotal = 0;
    let revenueTotal = 0;
    let roomsTotal = 0
    if (segmentCodes && segmentCodes.length > 0) {
        segmentCodes.map((code, index) => {
            let segmentCode = (segmentType === "market") ? "MarketSegment_" + code : "Segment_" + code;
            if (key === "ADR") {
                revenueTotal += (item && item[segmentCode] && item[segmentCode][mainKey]["Revenue"]) ? item[segmentCode][mainKey]["Revenue"] : 0;
                roomsTotal += (item && item[segmentCode] && item[segmentCode][mainKey]["Rooms"]) ? item[segmentCode][mainKey]["Rooms"] : 0;
            } else {
                keyTotal += (item && item[segmentCode] && item[segmentCode][mainKey][key]) ? item[segmentCode][mainKey][key] : 0;
            }
        })
        if (key === "ADR") {
            keyTotal = revenueTotal / roomsTotal;
        }
    }
    return keyTotal;

}

export const getKeyTotal = (item, key, segmentCodes, mainKey, segmentType = "osr") => {
    let keyTotal = 0;
    let revenueTotal = 0;
    let roomsTotal = 0
    if (segmentCodes && segmentCodes.length > 0) {
        segmentCodes.map((code, index) => {
            let segmentCode = (segmentType === "market") ? "MarketSegment_" + code : "Segment_" + code;
            if (key === "ADR") {
                revenueTotal += (item && item[segmentCode] && item[segmentCode][mainKey]["Revenue"]) ? item[segmentCode][mainKey]["Revenue"] : 0;
                roomsTotal += (item && item[segmentCode] && item[segmentCode][mainKey]["Rooms"]) ? item[segmentCode][mainKey]["Rooms"] : 0;
            } else {
                keyTotal += (item && item[segmentCode] && item[segmentCode][mainKey][key]) ? item[segmentCode][mainKey][key] : 0;
            }
        })
        if (key === "ADR") {
            keyTotal = revenueTotal / roomsTotal;
        }
    }
    return keyTotal;

}