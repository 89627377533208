import React, { useEffect, useReducer, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FolderIcon from "@mui/icons-material/Folder";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CloseIcon from '@mui/icons-material/Close';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { API_BASE_URL } from "../../app/config";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { replaceMultiText } from "../../app/util";
import { fetchDataSimple } from "../../services/data.service";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { LightTooltip } from "../layout/dashboard-ui";
import { Box, Tooltip } from "@mui/material";
import { textAlign } from "@mui/system";
const FileFolderList = (props) => {


    const { app_state, ui_state } = useSelector(state => state.app.current_state);
    const { data, fileType } = props.params;
    let file_type;
    if (fileType === "forecast_upload") {
        file_type = "manual_forecast";
    } else if (fileType === "documents_upload") {
        file_type = "document";
    } else {
        file_type = "budget";
    }
    let filesData = data.files.filter((item) => item.file_type === file_type)
    let foldersData = (file_type === "document") ? data.folders : [];
    foldersData.sort((a, b) => a.folder_name.toLowerCase() > b.folder_name.toLowerCase() ? 1 : -1);
    // console.log("foldersData",foldersData);
    const handleDownload = (filesDetails) => {
        console.log("download", filesDetails)
        let request = { "method": "GET", "type": "blob" };
        let url = `upload/download-file/${app_state.property_id}/${filesDetails.document_uid}/`
        fetchDataSimple({ api: url, request }).then(res => {
            const newBlob = new Blob([res]);

            const blobUrl = window.URL.createObjectURL(newBlob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', `${filesDetails.file_name}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // clean up Url
            window.URL.revokeObjectURL(blobUrl);
        }).catch(err => {
            console.log(err);
        })
    }
    return (
        <>
            <Table className="file-list-table" sx={{ minWidth: 550 }} size="small" stickyHeader aria-label="a dense table">
                {((foldersData.length > 0 || filesData.length > 0) &&
                    <TableHead>
                        <TableRow key={Math.random()} sx={{ background: "#6b6b6a", color: "#fff" }}>
                            <TableCell className="table-heading" align="left">Name</TableCell>
                            <TableCell className="table-heading" align="left" width={180}>Upload Date</TableCell>
                            <TableCell className="table-heading" align="left" width={80}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>

                    {(foldersData && foldersData.length > 0 && foldersData.map((foldersDetails, index) => {
                        let bgClass = (index % 2 !== 0) ? "bg-gray file-column" : "file-column";
                        let path = index + ">folders>" + foldersDetails.folder_uid + ">" + foldersDetails.folder_name;

                        return (<TableRow key={Math.random()}>
                            <TableCell className="file-column" sx={{ position: "relative" }}>
                                <div style={{ display: "flex", alignItems: "center", gap: "2px", cursor: "pointer" }}>
                                    {/* <FolderCopyIcon onClick={() => { props.handleFolderChange(path, foldersDetails.folder_uid); }} variant="" sx={{ verticalAlign: "bottom", color: "#9ed00d", cursor: "pointer" }} /> */}
                                    <svg onClick={() => { props.handleFolderChange(path, foldersDetails.folder_uid); }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 14" fill="none">
                                        <path d="M11.8125 3.9375H7.18102L5.6875 2.44399C5.60654 2.36238 5.51017 2.29769 5.40399 2.25365C5.29781 2.20962 5.18393 2.18714 5.06898 2.1875H2.1875C1.95544 2.1875 1.73288 2.27969 1.56878 2.44379C1.40469 2.60788 1.3125 2.83044 1.3125 3.0625V10.9714C1.31279 11.1944 1.4015 11.4082 1.55917 11.5658C1.71684 11.7235 1.93061 11.8122 2.15359 11.8125H11.8612C12.0802 11.8122 12.2903 11.7251 12.4452 11.5702C12.6001 11.4153 12.6872 11.2052 12.6875 10.9862V4.8125C12.6875 4.58044 12.5953 4.35788 12.4312 4.19379C12.2671 4.02969 12.0446 3.9375 11.8125 3.9375ZM2.1875 3.0625H5.06898L5.94398 3.9375H2.1875V3.0625ZM11.8125 10.9375H2.1875V4.8125H11.8125V10.9375Z" fill="#606060" />
                                    </svg>
                                    <div className="filename-style cursor-style" onClick={() => { props.handleFolderChange(path, foldersDetails.folder_uid); }}>{foldersDetails.folder_name}</div>
                                </div>
                            </TableCell>

                            <TableCell className="file-column" align="right" sx={{ position: "relative", pr: 5 }}>
                                <div className="filename-style">{moment(foldersDetails.insert_timestamp).format(" MMM DD, YYYY hh:mm")}</div>
                            </TableCell>

                            <TableCell className="file-column" align="right" sx={{ position: "relative", pr: 5 }}>
                                {/* <Tooltip title="Delete Folder" arrow followCursor>
                                    <span className="icon-delete">
                                        <DeleteIcon onClick={() => props.deleteItem(index, foldersDetails.folder_uid)} />
                                    </span>
                                </Tooltip> */}
                                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                    <div className="action-icon disabled">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 14" fill="none">
                                            <path d="M12.25 8.3125V11.375C12.25 11.6071 12.1578 11.8296 11.9937 11.9937C11.8296 12.1578 11.6071 12.25 11.375 12.25H2.625C2.39294 12.25 2.17038 12.1578 2.00628 11.9937C1.84219 11.8296 1.75 11.6071 1.75 11.375V8.3125C1.75 8.19647 1.79609 8.08519 1.87814 8.00314C1.96019 7.92109 2.07147 7.875 2.1875 7.875C2.30353 7.875 2.41481 7.92109 2.49686 8.00314C2.57891 8.08519 2.625 8.19647 2.625 8.3125V11.375H11.375V8.3125C11.375 8.19647 11.4211 8.08519 11.5031 8.00314C11.5852 7.92109 11.6965 7.875 11.8125 7.875C11.9285 7.875 12.0398 7.92109 12.1219 8.00314C12.2039 8.08519 12.25 8.19647 12.25 8.3125ZM6.69047 8.62203C6.7311 8.66271 6.77935 8.69498 6.83246 8.717C6.88558 8.73901 6.94251 8.75034 7 8.75034C7.05749 8.75034 7.11442 8.73901 7.16754 8.717C7.22065 8.69498 7.2689 8.66271 7.30953 8.62203L9.49703 6.43453C9.53768 6.39388 9.56992 6.34563 9.59192 6.29252C9.61392 6.23941 9.62524 6.18249 9.62524 6.125C9.62524 6.06751 9.61392 6.01059 9.59192 5.95748C9.56992 5.90437 9.53768 5.85612 9.49703 5.81547C9.45638 5.77482 9.40813 5.74258 9.35502 5.72058C9.30191 5.69858 9.24499 5.68726 9.1875 5.68726C9.13001 5.68726 9.07309 5.69858 9.01998 5.72058C8.96687 5.74258 8.91862 5.77482 8.87797 5.81547L7.4375 7.25648V2.1875C7.4375 2.07147 7.39141 1.96019 7.30936 1.87814C7.22731 1.79609 7.11603 1.75 7 1.75C6.88397 1.75 6.77269 1.79609 6.69064 1.87814C6.60859 1.96019 6.5625 2.07147 6.5625 2.1875V7.25648L5.12203 5.81547C5.03994 5.73338 4.9286 5.68726 4.8125 5.68726C4.6964 5.68726 4.58506 5.73338 4.50297 5.81547C4.42088 5.89756 4.37476 6.0089 4.37476 6.125C4.37476 6.2411 4.42088 6.35244 4.50297 6.43453L6.69047 8.62203Z" fill="#606060" />
                                        </svg>
                                    </div>
                                    <Tooltip title="Delete Folder" arrow followCursor>
                                        <div className="action-icon">
                                            <svg onClick={() => props.deleteItem(index, foldersDetails.folder_uid)} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 14" fill="none">
                                                <path d="M11.8125 2.625H9.625V2.1875C9.625 1.8394 9.48672 1.50556 9.24058 1.25942C8.99444 1.01328 8.6606 0.875 8.3125 0.875H5.6875C5.3394 0.875 5.00556 1.01328 4.75942 1.25942C4.51328 1.50556 4.375 1.8394 4.375 2.1875V2.625H2.1875C2.07147 2.625 1.96019 2.67109 1.87814 2.75314C1.79609 2.83519 1.75 2.94647 1.75 3.0625C1.75 3.17853 1.79609 3.28981 1.87814 3.37186C1.96019 3.45391 2.07147 3.5 2.1875 3.5H2.625V11.375C2.625 11.6071 2.71719 11.8296 2.88128 11.9937C3.04538 12.1578 3.26794 12.25 3.5 12.25H10.5C10.7321 12.25 10.9546 12.1578 11.1187 11.9937C11.2828 11.8296 11.375 11.6071 11.375 11.375V3.5H11.8125C11.9285 3.5 12.0398 3.45391 12.1219 3.37186C12.2039 3.28981 12.25 3.17853 12.25 3.0625C12.25 2.94647 12.2039 2.83519 12.1219 2.75314C12.0398 2.67109 11.9285 2.625 11.8125 2.625ZM5.25 2.1875C5.25 2.07147 5.29609 1.96019 5.37814 1.87814C5.46019 1.79609 5.57147 1.75 5.6875 1.75H8.3125C8.42853 1.75 8.53981 1.79609 8.62186 1.87814C8.70391 1.96019 8.75 2.07147 8.75 2.1875V2.625H5.25V2.1875ZM10.5 11.375H3.5V3.5H10.5V11.375ZM6.125 5.6875V9.1875C6.125 9.30353 6.07891 9.41481 5.99686 9.49686C5.91481 9.57891 5.80353 9.625 5.6875 9.625C5.57147 9.625 5.46019 9.57891 5.37814 9.49686C5.29609 9.41481 5.25 9.30353 5.25 9.1875V5.6875C5.25 5.57147 5.29609 5.46019 5.37814 5.37814C5.46019 5.29609 5.57147 5.25 5.6875 5.25C5.80353 5.25 5.91481 5.29609 5.99686 5.37814C6.07891 5.46019 6.125 5.57147 6.125 5.6875ZM8.75 5.6875V9.1875C8.75 9.30353 8.70391 9.41481 8.62186 9.49686C8.53981 9.57891 8.42853 9.625 8.3125 9.625C8.19647 9.625 8.08519 9.57891 8.00314 9.49686C7.92109 9.41481 7.875 9.30353 7.875 9.1875V5.6875C7.875 5.57147 7.92109 5.46019 8.00314 5.37814C8.08519 5.29609 8.19647 5.25 8.3125 5.25C8.42853 5.25 8.53981 5.29609 8.62186 5.37814C8.70391 5.46019 8.75 5.57147 8.75 5.6875Z" fill="#E03131" />
                                            </svg>
                                        </div>
                                    </Tooltip>
                                </Box>
                            </TableCell>
                        </TableRow>
                        )
                    })

                    )}

                    {(filesData && filesData.length > 0 && filesData.map((filesDetails, index) => {
                        let bgClass = (index % 2 !== 0) ? "bg-gray file-column" : "file-column";
                        let fileName = filesDetails.file_name;
                        let chkExtension = fileName.split('.').pop();
                        return (<TableRow key={Math.random()}>
                            <TableCell className="file-column" align="left" sx={{ display: "flex", alignItems: "center" }}>
                                <div>
                                    {(chkExtension.toLowerCase() === "pdf") ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#606060" viewBox="0 0 256 256"><path d="M224,152a8,8,0,0,1-8,8H192v16h16a8,8,0,0,1,0,16H192v16a8,8,0,0,1-16,0V152a8,8,0,0,1,8-8h32A8,8,0,0,1,224,152ZM92,172a28,28,0,0,1-28,28H56v8a8,8,0,0,1-16,0V152a8,8,0,0,1,8-8H64A28,28,0,0,1,92,172Zm-16,0a12,12,0,0,0-12-12H56v24h8A12,12,0,0,0,76,172Zm88,8a36,36,0,0,1-36,36H112a8,8,0,0,1-8-8V152a8,8,0,0,1,8-8h16A36,36,0,0,1,164,180Zm-16,0a20,20,0,0,0-20-20h-8v40h8A20,20,0,0,0,148,180ZM40,112V40A16,16,0,0,1,56,24h96a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88v24a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H56v72a8,8,0,0,1-16,0ZM160,80h28.69L160,51.31Z"></path></svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 14" fill="none">
                                            <path d="M11.6845 4.50297L8.62203 1.44047C8.58137 1.39985 8.53311 1.36765 8.48 1.34569C8.42689 1.32373 8.36997 1.31245 8.3125 1.3125H3.0625C2.83044 1.3125 2.60788 1.40469 2.44378 1.56878C2.27969 1.73288 2.1875 1.95544 2.1875 2.1875V11.8125C2.1875 12.0446 2.27969 12.2671 2.44378 12.4312C2.60788 12.5953 2.83044 12.6875 3.0625 12.6875H10.9375C11.1696 12.6875 11.3921 12.5953 11.5562 12.4312C11.7203 12.2671 11.8125 12.0446 11.8125 11.8125V4.8125C11.8125 4.75503 11.8013 4.69811 11.7793 4.645C11.7574 4.59189 11.7251 4.54363 11.6845 4.50297ZM8.75 2.80602L10.319 4.375H8.75V2.80602ZM10.9375 11.8125H3.0625V2.1875H7.875V4.8125C7.875 4.92853 7.92109 5.03981 8.00314 5.12186C8.08519 5.20391 8.19647 5.25 8.3125 5.25H10.9375V11.8125ZM9.1875 7.4375C9.1875 7.55353 9.14141 7.66481 9.05936 7.74686C8.97731 7.82891 8.86603 7.875 8.75 7.875H5.25C5.13397 7.875 5.02269 7.82891 4.94064 7.74686C4.85859 7.66481 4.8125 7.55353 4.8125 7.4375C4.8125 7.32147 4.85859 7.21019 4.94064 7.12814C5.02269 7.04609 5.13397 7 5.25 7H8.75C8.86603 7 8.97731 7.04609 9.05936 7.12814C9.14141 7.21019 9.1875 7.32147 9.1875 7.4375ZM9.1875 9.1875C9.1875 9.30353 9.14141 9.41481 9.05936 9.49686C8.97731 9.57891 8.86603 9.625 8.75 9.625H5.25C5.13397 9.625 5.02269 9.57891 4.94064 9.49686C4.85859 9.41481 4.8125 9.30353 4.8125 9.1875C4.8125 9.07147 4.85859 8.96019 4.94064 8.87814C5.02269 8.79609 5.13397 8.75 5.25 8.75H8.75C8.86603 8.75 8.97731 8.79609 9.05936 8.87814C9.14141 8.96019 9.1875 9.07147 9.1875 9.1875Z" fill="#606060" />
                                        </svg>
                                    }
                                </div>
                                <div className="filename-style">{filesDetails.file_name}</div>
                            </TableCell>

                            <TableCell className="file-column" align="left">
                                <div className="filename-style">{moment(filesDetails.update_timestamp).format(" MMM DD, YYYY hh:mm")}</div>
                            </TableCell>

                            <TableCell className="file-column" align="left">
                                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                    <Tooltip title="Download Document" arrow followCursor>
                                        <div className="action-icon">
                                            <svg onClick={() => { handleDownload(filesDetails) }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 14" fill="none">
                                                <path d="M12.25 8.3125V11.375C12.25 11.6071 12.1578 11.8296 11.9937 11.9937C11.8296 12.1578 11.6071 12.25 11.375 12.25H2.625C2.39294 12.25 2.17038 12.1578 2.00628 11.9937C1.84219 11.8296 1.75 11.6071 1.75 11.375V8.3125C1.75 8.19647 1.79609 8.08519 1.87814 8.00314C1.96019 7.92109 2.07147 7.875 2.1875 7.875C2.30353 7.875 2.41481 7.92109 2.49686 8.00314C2.57891 8.08519 2.625 8.19647 2.625 8.3125V11.375H11.375V8.3125C11.375 8.19647 11.4211 8.08519 11.5031 8.00314C11.5852 7.92109 11.6965 7.875 11.8125 7.875C11.9285 7.875 12.0398 7.92109 12.1219 8.00314C12.2039 8.08519 12.25 8.19647 12.25 8.3125ZM6.69047 8.62203C6.7311 8.66271 6.77935 8.69498 6.83246 8.717C6.88558 8.73901 6.94251 8.75034 7 8.75034C7.05749 8.75034 7.11442 8.73901 7.16754 8.717C7.22065 8.69498 7.2689 8.66271 7.30953 8.62203L9.49703 6.43453C9.53768 6.39388 9.56992 6.34563 9.59192 6.29252C9.61392 6.23941 9.62524 6.18249 9.62524 6.125C9.62524 6.06751 9.61392 6.01059 9.59192 5.95748C9.56992 5.90437 9.53768 5.85612 9.49703 5.81547C9.45638 5.77482 9.40813 5.74258 9.35502 5.72058C9.30191 5.69858 9.24499 5.68726 9.1875 5.68726C9.13001 5.68726 9.07309 5.69858 9.01998 5.72058C8.96687 5.74258 8.91862 5.77482 8.87797 5.81547L7.4375 7.25648V2.1875C7.4375 2.07147 7.39141 1.96019 7.30936 1.87814C7.22731 1.79609 7.11603 1.75 7 1.75C6.88397 1.75 6.77269 1.79609 6.69064 1.87814C6.60859 1.96019 6.5625 2.07147 6.5625 2.1875V7.25648L5.12203 5.81547C5.03994 5.73338 4.9286 5.68726 4.8125 5.68726C4.6964 5.68726 4.58506 5.73338 4.50297 5.81547C4.42088 5.89756 4.37476 6.0089 4.37476 6.125C4.37476 6.2411 4.42088 6.35244 4.50297 6.43453L6.69047 8.62203Z" fill="#606060" />
                                            </svg>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title="Delete Document" arrow followCursor>
                                        <div className="action-icon">
                                            <svg onClick={() => props.deleteDocument(index, filesDetails.document_uid)} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 14 14" fill="none">
                                                <path d="M11.8125 2.625H9.625V2.1875C9.625 1.8394 9.48672 1.50556 9.24058 1.25942C8.99444 1.01328 8.6606 0.875 8.3125 0.875H5.6875C5.3394 0.875 5.00556 1.01328 4.75942 1.25942C4.51328 1.50556 4.375 1.8394 4.375 2.1875V2.625H2.1875C2.07147 2.625 1.96019 2.67109 1.87814 2.75314C1.79609 2.83519 1.75 2.94647 1.75 3.0625C1.75 3.17853 1.79609 3.28981 1.87814 3.37186C1.96019 3.45391 2.07147 3.5 2.1875 3.5H2.625V11.375C2.625 11.6071 2.71719 11.8296 2.88128 11.9937C3.04538 12.1578 3.26794 12.25 3.5 12.25H10.5C10.7321 12.25 10.9546 12.1578 11.1187 11.9937C11.2828 11.8296 11.375 11.6071 11.375 11.375V3.5H11.8125C11.9285 3.5 12.0398 3.45391 12.1219 3.37186C12.2039 3.28981 12.25 3.17853 12.25 3.0625C12.25 2.94647 12.2039 2.83519 12.1219 2.75314C12.0398 2.67109 11.9285 2.625 11.8125 2.625ZM5.25 2.1875C5.25 2.07147 5.29609 1.96019 5.37814 1.87814C5.46019 1.79609 5.57147 1.75 5.6875 1.75H8.3125C8.42853 1.75 8.53981 1.79609 8.62186 1.87814C8.70391 1.96019 8.75 2.07147 8.75 2.1875V2.625H5.25V2.1875ZM10.5 11.375H3.5V3.5H10.5V11.375ZM6.125 5.6875V9.1875C6.125 9.30353 6.07891 9.41481 5.99686 9.49686C5.91481 9.57891 5.80353 9.625 5.6875 9.625C5.57147 9.625 5.46019 9.57891 5.37814 9.49686C5.29609 9.41481 5.25 9.30353 5.25 9.1875V5.6875C5.25 5.57147 5.29609 5.46019 5.37814 5.37814C5.46019 5.29609 5.57147 5.25 5.6875 5.25C5.80353 5.25 5.91481 5.29609 5.99686 5.37814C6.07891 5.46019 6.125 5.57147 6.125 5.6875ZM8.75 5.6875V9.1875C8.75 9.30353 8.70391 9.41481 8.62186 9.49686C8.53981 9.57891 8.42853 9.625 8.3125 9.625C8.19647 9.625 8.08519 9.57891 8.00314 9.49686C7.92109 9.41481 7.875 9.30353 7.875 9.1875V5.6875C7.875 5.57147 7.92109 5.46019 8.00314 5.37814C8.08519 5.29609 8.19647 5.25 8.3125 5.25C8.42853 5.25 8.53981 5.29609 8.62186 5.37814C8.70391 5.46019 8.75 5.57147 8.75 5.6875Z" fill="#E03131" />
                                            </svg>
                                        </div>
                                    </Tooltip>
                                </Box>
                            </TableCell>


                        </TableRow>
                        )
                    })

                    )}

                    {(foldersData && foldersData.length === 0 && filesData && filesData.length == 0 &&
                        <Box sx={{position:"absolute", top: "38%", left:"42%"}}>
                            < svg xmlns="http://www.w3.org/2000/svg" width="125" height="100" viewBox="0 0 125 100" fill="none">
                                <g clip-path="url(#clip0_586_1382)">
                                    <path d="M73.6196 3.39006V4.76642L68.6836 12.9093C68.0666 13.9196 66.9683 14.5434 65.7817 14.5434H35.9492C37.1357 14.5434 38.2341 13.9196 38.8511 12.9093L45.6855 1.63401C46.3025 0.616991 47.4008 0 48.5874 0H70.2295C72.1076 0 73.6196 1.51875 73.6196 3.39006Z" fill="#E89712" />
                                    <path d="M124.47 3.39006V82.9344C124.47 84.8057 122.958 86.3245 121.08 86.3245H3.91985C2.04175 86.3245 0.529785 84.8057 0.529785 82.9344V17.9334C0.529785 16.0553 2.04175 14.5434 3.91985 14.5434H65.7817C66.9682 14.5434 68.0666 13.9196 68.6836 12.9094L73.6195 4.76643L75.5179 1.63401C76.1349 0.616991 77.2333 0 78.4198 0H121.08C122.958 0 124.47 1.51875 124.47 3.39006Z" fill="#F89726" />
                                    <path d="M111.432 14.5434C111.432 51.4476 71.9855 81.3614 23.3178 81.3614C15.4393 81.3614 7.79807 80.575 0.529785 79.0969V17.9334C0.529785 16.0553 2.04175 14.5434 3.91985 14.5434H65.7817C66.9682 14.5434 68.0666 13.9196 68.6836 12.9093L73.6195 4.76642L75.5179 1.63401C76.1349 0.616991 77.2333 0 78.4198 0H109.337C110.707 4.67828 111.432 9.54641 111.432 14.5434Z" fill="#FAAB28" />
                                    <path d="M7.74393 20.198H7.73715C6.97326 20.198 6.354 20.8173 6.354 21.5811V55.4004C6.354 56.1643 6.97326 56.7835 7.73715 56.7835H7.74393C8.50782 56.7835 9.12707 56.1643 9.12707 55.4004V21.5811C9.12707 20.8173 8.50782 20.198 7.74393 20.198Z" fill="white" />
                                    <path d="M120.057 6.65805C120.057 6.15254 119.647 5.74274 119.141 5.74274C118.636 5.74274 118.226 6.15254 118.226 6.65805V80.0461C118.226 80.5516 118.636 80.9614 119.141 80.9614C119.647 80.9614 120.057 80.5516 120.057 80.0461V6.65805Z" fill="#FAAB28" />
                                    <path d="M24.6333 99.8779V92.3317H25.6978L28.1183 96.1489C28.681 97.0303 29.115 97.8303 29.4743 98.6033L29.4946 98.5897C29.4065 97.5795 29.3794 96.6642 29.3794 95.4912V92.3317H30.2947V99.8779H29.3116L26.9182 96.0472C26.3894 95.2064 25.8876 94.3454 25.5079 93.5317L25.474 93.5453C25.5283 94.4945 25.5554 95.4031 25.5554 96.6574V99.8779H24.6401H24.6333Z" fill="#B2B2B2" />
                                    <path d="M36.8646 97.1252C36.8646 99.1321 35.4747 100 34.1661 100C32.7016 100 31.5693 98.9287 31.5693 97.2133C31.5693 95.403 32.7559 94.3386 34.2543 94.3386C35.7527 94.3386 36.8646 95.4708 36.8646 97.1252ZM32.566 97.1794C32.566 98.366 33.2508 99.2609 34.2136 99.2609C35.1764 99.2609 35.8612 98.3795 35.8612 97.1591C35.8612 96.2438 35.4001 95.0776 34.2407 95.0776C33.0813 95.0776 32.5728 96.1489 32.5728 97.1794H32.566Z" fill="#B2B2B2" />
                                    <path d="M40.519 92.3317H44.5803V93.1521H41.4885V95.6607H44.343V96.4675H41.4885V99.8847H40.5122V92.3385L40.519 92.3317Z" fill="#B2B2B2" />
                                    <path d="M46.7434 92.9419C46.7569 93.2809 46.5061 93.5453 46.1196 93.5453C45.7738 93.5453 45.5229 93.2741 45.5229 92.9419C45.5229 92.6097 45.7806 92.3249 46.1399 92.3249C46.4993 92.3249 46.7434 92.5961 46.7434 92.9419ZM45.645 99.8779V94.4606H46.6281V99.8779H45.645Z" fill="#B2B2B2" />
                                    <path d="M48.269 91.9316H49.2522V99.8779H48.269V91.9316Z" fill="#B2B2B2" />
                                    <path d="M51.4556 97.3489C51.476 98.6778 52.3303 99.227 53.3134 99.227C54.0185 99.227 54.4457 99.105 54.8118 98.949L54.9813 99.6542C54.6355 99.8101 54.0388 99.9932 53.1778 99.9932C51.5099 99.9932 50.5132 98.8948 50.5132 97.2608C50.5132 95.6268 51.476 94.3386 53.0557 94.3386C54.8253 94.3386 55.2932 95.8912 55.2932 96.8879C55.2932 97.0913 55.2728 97.2472 55.2593 97.3489H51.4556ZM54.344 96.6438C54.3575 96.02 54.0863 95.0437 52.9811 95.0437C51.9845 95.0437 51.5505 95.959 51.4692 96.6438H54.344Z" fill="#B2B2B2" />
                                    <path d="M56.3847 98.8745C56.6763 99.0643 57.1915 99.2677 57.6865 99.2677C58.4052 99.2677 58.7374 98.9084 58.7374 98.4609C58.7374 97.993 58.4594 97.7354 57.7272 97.4642C56.7508 97.1184 56.2966 96.5828 56.2966 95.9319C56.2966 95.0573 57.0017 94.3453 58.1679 94.3453C58.7171 94.3453 59.1985 94.5013 59.4968 94.6843L59.2527 95.403C59.0425 95.2674 58.6493 95.0912 58.1475 95.0912C57.5645 95.0912 57.239 95.4302 57.239 95.8302C57.239 96.2777 57.5645 96.4811 58.2696 96.7455C59.212 97.1049 59.6934 97.5727 59.6934 98.3795C59.6934 99.3287 58.9544 100 57.6662 100C57.0695 100 56.5271 99.8576 56.1406 99.6338L56.3847 98.8812V98.8745Z" fill="#B2B2B2" />
                                    <path d="M64.5819 97.5049L63.7954 99.8779H62.7852L65.348 92.3317H66.521L69.0974 99.8779H68.0533L67.2465 97.5049H64.5683H64.5819ZM67.0499 96.7455L66.3108 94.5759C66.1413 94.0809 66.0328 93.6335 65.9176 93.1995H65.8972C65.782 93.647 65.6599 94.1081 65.5175 94.5623L64.7785 96.7455H67.0499Z" fill="#B2B2B2" />
                                    <path d="M70.3243 94.4606L71.3888 97.5049C71.5651 97.9998 71.7142 98.4473 71.8227 98.8948H71.8566C71.9786 98.4473 72.1346 97.9998 72.3177 97.5049L73.3686 94.4606H74.3992L72.2702 99.8779H71.3278L69.2666 94.4606H70.3175H70.3243Z" fill="#B2B2B2" />
                                    <path d="M78.2843 99.8779L78.203 99.1931H78.1691C77.8639 99.6203 77.2876 100 76.5147 100C75.4163 100 74.8604 99.227 74.8604 98.4473C74.8604 97.1388 76.0265 96.4201 78.1148 96.4336V96.3184C78.1148 95.8709 77.9928 95.064 76.8808 95.064C76.3791 95.064 75.8503 95.22 75.4706 95.4641L75.2468 94.8132C75.6943 94.5216 76.3452 94.3318 77.03 94.3318C78.6843 94.3318 79.0912 95.4641 79.0912 96.5489V98.5761C79.0912 99.044 79.1115 99.505 79.1793 99.8779H78.2843ZM78.1352 97.1116C77.0639 97.0913 75.8435 97.2811 75.8435 98.3321C75.8435 98.9694 76.2706 99.2745 76.7723 99.2745C77.4775 99.2745 77.925 98.827 78.0809 98.366C78.1148 98.2643 78.1352 98.1558 78.1352 98.0541V97.1116Z" fill="#B2B2B2" />
                                    <path d="M81.7761 92.9419C81.7896 93.2809 81.5388 93.5453 81.1523 93.5453C80.8065 93.5453 80.5557 93.2741 80.5557 92.9419C80.5557 92.6097 80.8133 92.3249 81.1727 92.3249C81.532 92.3249 81.7761 92.5961 81.7761 92.9419ZM80.6777 99.8779V94.4606H81.6608V99.8779H80.6777Z" fill="#B2B2B2" />
                                    <path d="M83.2949 91.9316H84.278V99.8779H83.2949V91.9316Z" fill="#B2B2B2" />
                                    <path d="M88.9425 99.8779L88.8612 99.1931H88.8273C88.5221 99.6203 87.9458 100 87.1729 100C86.0745 100 85.5186 99.227 85.5186 98.4473C85.5186 97.1388 86.6847 96.4201 88.773 96.4336V96.3184C88.773 95.8709 88.651 95.064 87.539 95.064C87.0373 95.064 86.5085 95.22 86.1288 95.4641L85.905 94.8132C86.3525 94.5216 87.0034 94.3318 87.6882 94.3318C89.3425 94.3318 89.7493 95.4641 89.7493 96.5489V98.5761C89.7493 99.044 89.7697 99.505 89.8375 99.8779H88.9425ZM88.7933 97.1116C87.7221 97.0913 86.5017 97.2811 86.5017 98.3321C86.5017 98.9694 86.9288 99.2745 87.4305 99.2745C88.1357 99.2745 88.5832 98.827 88.7391 98.366C88.773 98.2643 88.7933 98.1558 88.7933 98.0541V97.1116Z" fill="#B2B2B2" />
                                    <path d="M91.2954 99.8779C91.3158 99.5118 91.3429 98.9626 91.3429 98.4812V91.9316H92.3192V95.3353H92.3396C92.6853 94.7318 93.3159 94.3386 94.1837 94.3386C95.5262 94.3386 96.4754 95.4573 96.4686 97.1049C96.4686 99.044 95.2482 100.007 94.0414 100.007C93.2549 100.007 92.6311 99.7017 92.2311 98.9897H92.1972L92.1497 99.8847H91.3022L91.2954 99.8779ZM92.3124 97.7083C92.3124 97.8303 92.3328 97.9524 92.3599 98.0677C92.5497 98.7524 93.1193 99.2203 93.838 99.2203C94.8685 99.2203 95.4855 98.3795 95.4855 97.1388C95.4855 96.054 94.9228 95.1251 93.8719 95.1251C93.2006 95.1251 92.5701 95.5861 92.3735 96.3319C92.3531 96.4472 92.3192 96.576 92.3192 96.732V97.7083H92.3124Z" fill="#B2B2B2" />
                                    <path d="M97.7095 91.9316H98.6926V99.8779H97.7095V91.9316Z" fill="#B2B2B2" />
                                    <path d="M100.896 97.3489C100.916 98.6778 101.771 99.227 102.754 99.227C103.459 99.227 103.886 99.105 104.252 98.949L104.422 99.6542C104.076 99.8101 103.479 99.9932 102.618 99.9932C100.95 99.9932 99.9536 98.8948 99.9536 97.2608C99.9536 95.6268 100.916 94.3386 102.496 94.3386C104.266 94.3386 104.734 95.8912 104.734 96.8879C104.734 97.0913 104.713 97.2472 104.7 97.3489H100.896ZM103.784 96.6438C103.798 96.02 103.527 95.0437 102.422 95.0437C101.425 95.0437 100.991 95.959 100.91 96.6438H103.784Z" fill="#B2B2B2" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_586_1382">
                                        <rect width="123.941" height="100" fill="white" transform="translate(0.529785)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </Box>
                    )}

                </TableBody>
            </Table >
        </>
    )
}

export default FileFolderList
