import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux';
import MenuSingleItem from './MenuSingleItem';
import moment from "moment";
import { APP_CACHE, ABORT_REQUEST_CONTROLLERS } from '../../app/config';

import {
    AppBar
    , Box
    , Toolbar
    , IconButton
    , Typography
    , Menu
    , MenuItem
    , Container
    , Avatar
    , Button
    , Grid
    , Tooltip
    , FormControl
    , InputLabel
    , Select
    , ListSubheader
    , TextField
    , InputAdornment
    , List
    , ListItem
    , ListItemIcon
    , ListItemText
    , Divider
    , Drawer
} from '@mui/material/';
import {
    Person as PersonIcon
    , SettingsOutlined as SettingsOutlinedIcon
    , ScheduleOutlined as ScheduleOutlinedIcon
    , VideocamOutlined as VideocamOutlinedIcon
    , PowerSettingsNewOutlined as PowerSettingsNewOutlinedIcon
    , AssessmentOutlined as AssessmentOutlinedIcon
    , ArrowForwardRounded as ArrowForwardRoundedIcon
    , ApartmentSharp as ApartmentSharpIcon
    , Search as SearchIcon
    , KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon
} from '@mui/icons-material/';
import { deepCopy, getFromSession, getTileById, replaceMultiText, abortRequestSafe, calculateCompStayRange } from '../../app/util';
import UploadsPopUp from './UploadsPopUp';
import dataService, { fetchDataSimple } from "../../services/data.service";
import { TILES, API_BASE_URL, API_REPORT_URL } from "../../app/config";
import { useDispatch } from 'react-redux';

import { getData, updateAppState } from '../../actions/appstate';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
// import { useDispatch, useSelector } from 'react-redux';


import LinearProgress from '@mui/material/LinearProgress';
import { useProductVariant } from "../../hooks/use-product-variant";
import {sendLog} from "../../app/log-interface"
import { UPDATE_STATE } from "../../actions/types";
import ZendeskDashboard from '../Zendesk/ZendeskDashboard';

const ReportMenusList = (props) => {
    const dispatch = useDispatch();
    const { hasFeature } = useProductVariant()
    const [openPopup, setOpenPopup] = React.useState(false);
    // const [label, setLabel] = React.useState("Budget"); 
    const [reportId, setReportId] = React.useState("budget_upload");
    const [zendesk, setZendesk] = React.useState(false);

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;

    let product_variant_key = ''
    switch (props.categoryType) {
        case 'Property - Revenue Intelligence':
            product_variant_key = 'reports'
            break;
        case 'Property - Decisions':
            product_variant_key = 'logs'
            break;
        case 'Uploads':
            product_variant_key = 'features'
            break
        default:
            product_variant_key = ''
            break;
    }


    const handleClose = useCallback(() => { setOpenPopup(false) })
    //  const handleClose = () => {
    //     setOpenPopup(false);
    //   };

    const reportDownload_old = (apiUrl, resType, reportName) => {
        let resource_id;
        let msg;
        if (resType !== "") {
            resource_id = (resType === "market") ? app_state.selected_market.market_id : app_state.property_id;
            msg = reportName + " report is generating , please wait..."
            dispatch(updateAppState('downloadReport', {
                ui_state: {
                    header_alert_msg: msg,
                }
            }));
        }
        const request = {
            resourceId: resource_id,
            resourceType: resType,
            asOfDate: app_state.as_of_date,
            rangeFromDate: app_state.primary_stay_range.date_from,
            rangeUntilDate: app_state.primary_stay_range.date_until,
            exportCommand: "HSHA_v0.1",
            exportType: "excel",
            debug: false,
            s3: true,
        };
        // loader will apear here 
        dataService
            .getData({
                // api: API_REPORT_URL + "export/" + apiUrl,
                api: "export/" + apiUrl,
                //api: "http://rm-app-staging.lodgiq.com/export/combined_data_export_v2",
                request: request,
            })
            .then((response) => {
                setTimeout(() => {
                    dispatch(updateAppState('onHideAlert', {
                        ui_state: {
                            header_alert_msg: "",
                        }
                    }));
                }, 3000);
                if (response.exportUrl) {
                    window.location.href = response.exportUrl;
                } else if (response.url) {
                    window.location.href = response.url;
                } else if (response.data[0].url) {
                    window.location.href = response.data[0].url
                }
            })
            .catch((error) => {
                msg = "Oops! Something went wrong.";
                dispatch(updateAppState('error', {
                    ui_state: {
                        header_alert_msg: "",
                    }
                }));
                setTimeout(() => {
                    dispatch(updateAppState('onHideAlert', {
                        ui_state: {
                            header_alert_msg: "",
                        }
                    }));
                }, 3000);
            });


    };

    const reportDownload = (report_id, resType, reportName, apiUrl) => {
        const { data_popup_api } = getTileById('export_reports');
        let activeApi = data_popup_api.OnSubmit;
        let resource_id;
        let msg;
        if (resType !== "") {
            resource_id = (resType === "market") ? app_state.selected_market.market_id : app_state.property_id;

        }

        // msg = reportName + " report is generating , please wait..."
        msg = "Generating the report, please wait..."
        const exportToast = toast(<div><LinearProgress className='toast-popup-progress' color='green' /> {msg}</div>, {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide
        });

        const correction = {
            "{pid}": resource_id,
            "{mid}": resource_id,
            "{asOfDate}": app_state.as_of_date
        };

        const request = {
            "asOfDate": app_state.as_of_date,
            "method": "GET",
        };
        let api = apiUrl;
        api = replaceMultiText(api, correction);
        fetchDataSimple({ api, request })
            .then((response) => {
                toast.update(exportToast, {
                    render: "Report Successfully Downloaded.",
                    type: toast.TYPE.SUCCESS,
                    autoClose: 3000
                })
                if (response.exportUrl) {
                    window.location.href = response.exportUrl;
                } else if (response.url) {
                    window.location.href = response.url;
                } else if (response.data[0].url) {
                    window.location.href = response.data[0].url
                }
            })
            .catch((error) => {
                msg = "Some error occour.Please try again.";
                toast.update(exportToast, {
                    render: msg,
                    type: toast.TYPE.ERROR,
                    autoClose: 3000
                })
                setTimeout(() => {
                    dispatch(updateAppState('onHideAlert', {
                        ui_state: {
                            header_alert_msg: "",
                        }
                    }));
                }, 3000);
            });
    }

    const handleOpenReport = (id) => {
        let hasLockOnMenu = !hasFeature([id], product_variant_key)
        ui_state.isLock = hasLockOnMenu;
        let currTile = TILES[id];
        let active_tiles = ui_state.active_tiles;
        let workspace_view = 'table';
        if (app_state.workspace_controls.isRoomTypePricing) {
            app_state.workspace_controls.isRoomTypePricing = false;
        }

        if (ui_state.active_tiles[0] !== id) {
            let new_app_state = {};
            let new_ui_state = {workspace_view};
            APP_CACHE.active_tiles = [id];
            let apc = APP_CACHE;
            if(apc.controls){
                apc.controls.scopeType = 'Date';
            }
            // debugger;
            
            let abotControllers = ABORT_REQUEST_CONTROLLERS;
            abotControllers.forEach((val, key) => {
                // console.log('abotControllers', ABORT_REQUEST_CONTROLLERS.get(key));
                if (val && !['OSR', 'OSRContrainedForecast'].includes(key)) {
                    val.abort();
                }
            });
            

            // app_state.active_apis.map((api_id, index) => {
            //     // debugger;
            //     abortRequestSafe(api_id, 'property changed');
            // });
            active_tiles = [id];
            app_state.workspace_controls.segmentType = "hotel";

            dispatch({ type: 'UPDATE_STATE', payload: {
                app_state:{...app_state, data_load: false, kpi_load: false, date_range_type:''}, 
                ui_state: { ...new_ui_state, showspinner: true } 
                } 
            });

            if (currTile.defaultView) {
                workspace_view = currTile.defaultView;
            }
            if (id === "otb_v2_multi_segment") {
                app_state.workspace_controls.segmented_view = (app_state.workspace_controls.segmentType === "hotel") ? false : true;
                app_state.workspace_controls.segmentType = "hotel";
            }
            
            if(id === "bi_sensitivity_analysis"){
                app_state.workspace_controls.segmentType = "hotel";
                app_state.workspace_controls.segmented_view = false;
            }

            if(id === 'bi_pickup_analysis'){
                app_state.workspace_controls.pickUpType = "Latest";
            }

            if (id === "revpar_index") {
                app_state.workspace_controls.segmented_view = false;
                // app_state.workspace_controls.segmentType = "hotel";
                ui_state.reports_dpbs.selectedCategory = ["Hotel"];
                delete ui_state.reports_dpbs.selectedSegment;
                delete ui_state.reports_dpbs.topSegmentsList;

            }
            if (currTile.controlSupport) {
                if (!currTile.controlSupport.scopeType) {
                    app_state.workspace_controls.scopeType = 'Date';
                    app_state.primary_stay_range = deepCopy(app_state.historicalRange);
                }
            } else {
                app_state.workspace_controls.scopeType = 'Date';
                app_state.primary_stay_range = deepCopy(app_state.historicalRange);
            }



        }
        // if(id === "restrictions_push_status" ||
        // id === "DailyRatesendStatus" ||
        // id === "rm_room_type_ratesend_status"){
        //     app_state.historicalRange = {as_of_date:app_state.primary_stay_range.as_of_date,
        //         date_from:app_state.primary_stay_range.date_from,date_until:moment(app_state.primary_stay_range.date_from).add(1,"months").format("YYYY-MM-DD")
        //     }
        // }
        
        let appstateNew = {
            date_range_type: 'rangeTileChange_' + id,
            primary_stay_range: { ...deepCopy(app_state.historicalRange) },
            workspace_controls: deepCopy(app_state.workspace_controls),
            data_load: false,
        };
 

        if (id === "bi_sensitivity_analysis") {
            let comp_stay_range = calculateCompStayRange({ primary_stay_range: appstateNew.primary_stay_range, workspace_controls: deepCopy(app_state.workspace_controls) });
            appstateNew["comp_stay_range"] = comp_stay_range;
        }

        if (id === "restrictions_push_status" ||
            id === "DailyRatesendStatus" ||
            id === "rm_room_type_ratesend_status") {
                appstateNew.workspace_controls.logsRatePushMode = "date_range";
        }
        let dowArray = [{ "code": "sun", "hidden": false, "title": "Sun", "type": "report_dow" },
        { "code": "mon", "hidden": false, "title": "Mon", "type": "report_dow" },
        { "code": "tue", "hidden": false, "title": "Tue", "type": "report_dow" },
        { "code": "wed", "hidden": false, "title": "Wed", "type": "report_dow" },
        { "code": "thu", "hidden": false, "title": "Thu", "type": "report_dow" },
        { "code": "fri", "hidden": false, "title": "Fri", "type": "report_dow" },
        { "code": "sat", "hidden": false, "title": "Sat", "type": "report_dow" }
        ];
        ui_state.table_filters.table_reports.dow = dowArray;
        // debugger;
        setTimeout(() => {
            dispatch(
                updateAppState("change_active_tiles", {
                    app_state: {
                        // ...app_state,
                        ...appstateNew
                    },
                    ui_state: {
                        ...ui_state,
                        active_tiles,
                        workspace_view,
                        showspinner: true
                    },
                }, "report_menu_click")
            );
        }, 50);
        
    }
    const handleFullScreenLock = (title, module) => {
        dispatch({
            type: UPDATE_STATE,
            payload: {
                ui_state: { ...ui_state, fullScreenLockData: { status: true, title, module } }
            }
        });
    }

    const handleMenuClick = (id, label) => {
        props.onMenuClicked(id, label);
        let apiUrl = "";
        switch (id) {
            case "budget_upload":
            case "forecast_upload":
            case "documents_upload":
                if (hasFeature([id], 'features')) {
                    sendLog({ category: "reports_menu", action: "open_document_uploads" }, { ...app.current_state },{
                        app_state:{
                           
                        }, ui_state:{}
                    })
                    setReportId(id);
                    setOpenPopup(true);
                } else {
                    handleFullScreenLock('Uploads', label)
                }
                break;
            case "HMSReport":
                if (hasFeature([id], 'reports')) {
                    apiUrl = "reports/combine-export/{pid}/?asOfDate={asOfDate}";
                    reportDownload(id, "property", "365 Day pace & pickup", apiUrl);
                    sendLog({ category: "reports_menu", action: "download_HMS_report" }, { ...app.current_state },{
                        app_state:{
                        }, ui_state:{}
                    })
                } else {
                    handleFullScreenLock('Reports', '365 Day pace & pickup')
                }
                break;
            case "bi_hotel_segmented_summary_report":
                if (hasFeature([id], 'reports')) {
                    apiUrl = "reports/hotel-segmented-summary-report/{pid}/?asOfDate={asOfDate}";
                    sendLog({ category: "reports_menu", action: "download_bi_hotel_segmented_summary_report" }, { ...app.current_state },{
                        app_state:{
                        }, ui_state:{}
                    })
                    reportDownload(id, "property", "KPI Summary Report", apiUrl);
                } else {
                    handleFullScreenLock('Reports', 'KPI Summary Report')
                }
                break;
            case "bi_market_segmented_summary_report":
                if (hasFeature([id], 'reports')) {
                    apiUrl = "reports/market-segmented-summary-report/{mid}/?asOfDate={asOfDate}";
                    sendLog({ category: "reports_menu", action: "download_bi_market_segmented_summary_report" }, { ...app.current_state },{
                        app_state:{
                        }, ui_state:{}
                    })
                    reportDownload(id, "market", "Segmented Market Summary", apiUrl);
                } else {
                    handleFullScreenLock('Reports', 'Segmented Market Summary')
                }
                break;
            case "releasenotes_id":
                const pdfUrl = "/assets/files/JuneQ2.pdf";
                const link = document.createElement("a");
                link.href = pdfUrl;
                link.download = "JuneQ2.pdf";
                document.body.appendChild(link);
                sendLog({ category: "reports_menu", action: "download_release_notes" }, { ...app.current_state },{
                    app_state:{
                    }, ui_state:{}
                })
                link.click();
                document.body.removeChild(link);
                break;

                case "userguide_id":
                setZendesk(true);
                setTimeout(() => {
                    setZendesk(false);
                }, 2000);
                break;
            default:
                handleOpenReport(id);
                break;
        }
    };

    let reportsList = auth.profile['application_config'].report_settings.report_access_settings || [];

    let reportsListNew = deepCopy(reportsList.filter((item) => item.report_category === props.categoryType));
    if (props.categoryType === "Property - Revenue Intelligence") {
        let marketList = reportsList.filter((item) => item.report_category === "Market");
        let labsList = reportsList.filter((item) => (item.report_category === "Labs" && item.report_id === "otb_v2_multi_segment"));
        reportsListNew = [...reportsListNew, ...marketList, ...labsList];
    }
    if (props.categoryType === "Property - Decisions") {
        let restrictionsReports = [{
            "access_level": "user_level",
            "display_name": "Restrictions Push Status",
            "report_category": "Property - Decisions",
            "report_id": "restrictions_push_status",
        }];
        if (!reportsListNew.find(i => i.report_id === "restrictions_room_type_push_status")) {
            reportsListNew = [...reportsListNew, ...restrictionsReports];
        }

    }
    reportsListNew.sort((a, b) => a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1);
    return (
        <>
            {
                reportsListNew.map((item, index) => (
                    <MenuSingleItem
                        key={"id_" + index}
                        access={item.access_level}
                        label={item.display_name}
                        onClick={handleMenuClick}
                        menuId={item.report_id}
                        hasLockOnMenu={!hasFeature([item.report_id], product_variant_key)}
                        categoryType={props.categoryType} />
                ))
            }
            {props.categoryType == "Uploads" && openPopup &&
                <UploadsPopUp
                    action={openPopup}
                    onClose={handleClose}
                    reportId={reportId} />
            }

                
            {(zendesk && <ZendeskDashboard />)}

            
        </>
    )
}

export default ReportMenusList
