import React, { useRef, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Lottie from 'react-lottie';
import AIExplainerLottie from "../../lotties/ai-explainer.json";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DOMPurify from 'dompurify'

import './AIExplainer.scss'

export default function AIExplainer({ data, explainer }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className='pricing_rec_sell_rate' >
            {data}
            {explainer && (
                <>
                    <div className={`explanation_icon ${open && 'explanation_icon_active'}`} aria-describedby={id} onClick={handleClick} >
                        {open ?
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: AIExplainerLottie,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }}
                                height={20}
                                width={20}
                            />
                            :
                            <HelpOutlineOutlinedIcon fontSize="small" className='help_icon' />
                        }
                    </div>
                    <Popover
                        className='explainer_popover'
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(explainer) }} />
                    </Popover>
                </>
            )}
        </div>
    );
}