import React from "react";

function BusinessIntelligence({ fill = '#EBEBEB', height = "32", width = "32" }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 256 256"
        >
            <path d="M88 144v-16a8 8 0 0116 0v16a8 8 0 01-16 0zm40 8a8 8 0 008-8v-24a8 8 0 00-16 0v24a8 8 0 008 8zm32 0a8 8 0 008-8v-32a8 8 0 00-16 0v32a8 8 0 008 8zm56-72v96h8a8 8 0 010 16h-88v17.38a24 24 0 11-16 0V192H32a8 8 0 010-16h8V80a16 16 0 01-16-16V48a16 16 0 0116-16h176a16 16 0 0116 16v16a16 16 0 01-16 16zm-80 152a8 8 0 10-8 8 8 8 0 008-8zM40 64h176V48H40zm160 16H56v96h144z"></path>
        </svg>
    );
}

export default BusinessIntelligence;

