
import { Box, Select, FormControl, MenuItem, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { sendLog } from '../../../../app/log-interface';
import { UPDATE_STATE } from '../../../../actions/types';

const MultiSegmentPickerOTB = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const [segmentType, setSegmentType] = useState(controls.segmentType);

    const dispatch = useDispatch();

    const state = useSelector(appState => appState);
    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const {property_id, property_name} = app_state;
    if(app_state.workspace_controls.segmentType === "market_segment"){
        controls.segmented_view = true;
        // debugger;
    }
    
    if(segmentType === 'room_class' || segmentType === 'room_type' || segmentType === 'rate_plan' || segmentType === 'top_market_segment'){
        controls.segmentType = 'hotel';
        controls.segmented_view = false;

        setSegmentType('hotel');
        dispatch(updateAppState('segmentType',
            { app_state: { workspace_controls: controls, data_load: true }, ui_state: { showspinner: false} }));
    }
    
    const handleChange = (type, value) => {
        controls.segmentType = value;
        let segmented_view = (value === "hotel") ? false : true;
        // controls.sdYear = moment().subtract(1, 'year').format("YYYY");
        // sendLog({ category: "datalevel_picker", action: "switch_data_level" }, { ...app.current_state },{
        //     app_state:{
        //         date_range_type: 'rangeTileChange_segment'+value,
        //         data_load: false, kpi_load: false,
        //         workspace_controls: {
        //             ...app_state.workspace_controls,
        //             segmented_view: segmented_view
        //         }
        //     }
        // })
        window.analyze.track("change_data_level", {
            "selected_tile": ui_state.active_tiles.join(),
            ...app_state.primary_stay_range,
            property_id, property_name,segmentType:value,
        }, { ...app.current_state}, { app_state:{
            date_range_type: 'rangeTileChange_segment'+value,
            data_load: false, kpi_load: false,
            workspace_controls: {
                ...app_state.workspace_controls,
                segmented_view: segmented_view
            }
        }});
        dispatch({
            type: UPDATE_STATE,
            payload: {
                app_state:{
                    date_range_type: 'rangeTileChange_segment'+value,
                    data_load: false, kpi_load: false,
                    workspace_controls: {
                        ...app_state.workspace_controls,
                        segmented_view: segmented_view
                    }
                },
                // ui_state: { 
                //     showspinner: true 
                // }
            }
        })
        // dispatch(updateAppState(type,
        //     { app_state: { workspace_controls: controls, data_load: true }, 
        //     ui_state: { showspinner: true } }));
    }

    useEffect(() => {
        if(app_state.workspace_controls.segmented_view){
            setSegmentType("market_segment");
            // handleChange("segmentType", 'market_segment');
        }
        if(!app_state.workspace_controls.segmented_view) {
            setSegmentType("hotel");
            // handleChange("segmentType", 'hotel');
        }
        
    }, [app_state.workspace_controls.segmented_view]);

    let hotel_level = (ui_state.active_tiles.includes("market")) ? "Market" : "Hotel";
    let segment_level = (ui_state.active_tiles.includes("market")) ? "Market Segment" : "Segment";
 

    return (
        <>
            <Box id="tabletype" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box className="data-level-label">Data Level </Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                    <Select disabled={ui_state.showspinner} className='tabular-data-filter' fullWidth value={segmentType} sx={{ height: "30px", mt: "5px" }} onChange={(e) => {
                        setSegmentType(e.target.value);
                       
                        handleChange("segmentType", e.target.value);
                    }}>

                        <MenuItem value="hotel" className='tabular-data-filter'> {hotel_level}</MenuItem>
                        <MenuItem value="market_segment" className='tabular-data-filter'> {segment_level} </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>
    )
}

export default MultiSegmentPickerOTB