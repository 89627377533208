
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectingAirportsSharpIcon from '@mui/icons-material/ConnectingAirportsSharp';
import FlightIcon from '@mui/icons-material/Flight';
import { Button, Box, Modal, FormControl, Select, Typography, Container, Grid, Icon, TableCell, Table } from '@mui/material'
import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';
import { deepCopy, getSeriesById, in_array } from "../../app/util";
import dataService from "../../services/data.service";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RatePlanPopup from './RatePlanPopup';
import './LogsRoomCodes.scss'

const LogsRoomCodes = (props) => {
    let { displayValue, displayType } = props;

    if(!Array.isArray(displayValue)){
        displayValue = [];
    } 

    let reportRateString;
    return (
        <div className="chip-list">
            {
                (displayValue && displayValue.map((item) => {
                    // return (<div> <Chip label={<><span className="rate-plan-code-first">{item.room_code}</span><span>{item.changed_rate}</span></>} size="small" variant="filled" className="rate-plan-code"  /></div>)
                    return (
                        <>
                            {(displayType !== "report") ?
                                <Chip
                                    label={
                                        <span>
                                            <span className='room-code'>{item.room_code}</span>
                                            <span className='rate-value'>
                                                ${item.changed_rate}
                                            </span>
                                        </span>
                                    }
                                />
                                :
                                <div className="space">{item.room_code} - {item.changed_rate}, </div>
                            }
                        </>
                    )
                }))
            }
        </div>
    )
}

export default LogsRoomCodes