import { Box } from '@mui/system';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../actions/appstate';
import { TILES } from '../../app/config-v4-api';
import { in_array } from '../../app/util';

const MultipleTileOptions = (props) => {
    const state = useSelector(appState => appState);
    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const dispatch = useDispatch();
    const { workspace_controls, comp_as_of_date, as_of_date } = app_state;
    const { sdlyOn } = workspace_controls;
    const { activeTiles } = props;

    let active_tiles = ui_state.active_tiles;
    // let ignoreTiles = ['pricing', 'market', 'forecast', 'budget', 'flights', 'market']
    let ratePlan_active_tiles = ui_state.ratePlan_tiles;
    let ignore_tiles = ['pricing', 'market', 'forecast', 'budget', 'flights', "compset"];

    return (
        <>
            <Box className="rateplan-box-footer">
                <dl className='rateplan-menu'>
                    <dd>Show:</dd>
                    {(active_tiles && active_tiles.map((globalTile) => {
                        if (!in_array(globalTile, ignore_tiles)) {
                            let tileName = TILES[globalTile].label;
                            let activeState = (activeTiles.includes(globalTile)) ? 'active' : '';
                            return (<>
                                <dd onClick={(e) => props.updateActiveTile(e, globalTile)} className={'rateplan-menu-item ' + activeState}>{tileName}</dd>
                                <dd>|</dd>
                            </>)
                        }
                    }
                    ))
                    }
                </dl>
            </Box>
        </>
    )
}

export default MultipleTileOptions
