import "./style.css";

import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import {
  Alert,
  Autocomplete,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
  Switch,
  IconButton,
  InputAdornment,

} from "@mui/material/";
import {
  SaveOutlined as SaveOutlinedIcon,
  ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
  KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  ClearOutlined as ClearOutlinedIcon,
  CalendarMonth as CalendarMonthIcon
} from '@mui/icons-material';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { DatePicker, MobileDatePicker, PickersDay, MobileTimePicker } from "@mui/x-date-pickers/";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormLabel from '@mui/material/FormLabel';

import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_DI_CERT } from "../../app/config";
import dataService from "../../services/data.service";
import {
  AddNewItem,
  Breadcrumb,
  CancelButton,
  CollapseButton,
  DeleteButton,
  PreviewButton,
  RatePushSchedulePreview,
  SaveButton,
  SettingsTitle,
  ToggleSwitch,
  UserAlertInfo,
  UserConfirmationAlert,
} from "./comp.static";

import { settingsReducers } from "./reducers";
// import { useData } from "../../hooks/";
import { deepCopy } from "../../app/util";

import { useLocation, useNavigate } from "react-router-dom";
import { updateAppState } from '../../actions/appstate';
import DataService from "../../services/data.service";
import { clearAPIRegistryData } from "../../app/util";

export default function ScheduleRatePushSettings(props) {
  const [isrequesting, setIsRequesting] = useState(false);
  const [requestData, setRequestData] = useState({ schedules: [] });

  const [compState, setCompState] = useState({
    editable: '',
    schedules: [],
    expanded: { id_0: true },
    skip_keys: ['expanded', 'owner', 'isChangeRequest'],
  });
  const scheduleLimit = 10;
  const updateCompState = (key, value) => {
    let keys = key ? key.split('>') : [];
    //Handling single deptch key/value pairs
    if (keys[0] && !keys[1]) {
      compState[keys[0]] = value;
    }
    //Handling double deptch key/value pairs
    if (keys[0] && keys[1]) {
      compState[keys[0]] = compState[keys[0]] ? compState[keys[0]] : {};
      compState[keys[0]][keys[1]] = value;
    }
    setCompState((state) => {
      return { ...state, ...compState };
    });
  }

  const toggleEditable = (e, fieldKey, index, item) => {
    const event = e;
    requestData.schedules[index].editing = fieldKey;
    setRequestData({ ...requestData });
    // updateCompState('editable', fieldKey);
    // debugger;
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleDelete = (params) => {
    const { date, index } = params;
    return (e) => {
      let dateIndex = requestData.schedules[index].skip_dates.indexOf(date);
      // const id = "id_" + index;
      // ui.dataChanged[id] = true;
      requestData.schedules[index].skip_dates.splice(dateIndex, 1);
      updateRequestData("skip_dates", requestData.schedules[index].skip_dates, index);
      // setRequestData({ ...requestData });
      // debugger;
    }
  }

  const [settingsState, dispatchSettings] = useReducer(
    settingsReducers,
    initalState
  );

  // debugger;

  const { app, auth } = useSelector((state) => state);
  const { user, profile } = auth;
  const { app_state } = app.current_state;
  const { property_details } = profile || [];
  let system_settings = {};
  try {
    system_settings = property_details.filter(
      (item) => item.id === app_state.property_id
    )[0].system_settings[0];
  } catch (error) {
    console.error("room_types can not be resoved");
  }
  const { ui, data } = settingsState;
  const { room_settings } = system_settings.inventory_settings.current;
  ui.room_type_codes = room_settings.map((item) => {
    if (item.rate_push_enabled) return item.room_code;
  });

  // const requestData = { scheduled_ratepush: [] };
  const containerRef = useRef(null);

  const request = {
    property_id: app_state.property_id,
  };
  // const schedules = useData({ type: 'di', path: 'scheduling_service/get_schedules' });
  // const schedules = {};
  // debugger;
  const baseRoom = useRef({});
  useEffect(() => {
    //Getting existing ratepush schedules-->
    dataService
      .getData({
        api: "schedulers/rate-push/" + app_state.property_id + "/",
        request: { method: 'GET' },
      })
      .then((rs) => {
        console.log("ratePushSchedule", rs);
        if (rs.status === "success") {
          let schedules = rs.data;
          for (let schedule of schedules) {
            schedule.expanded = false;
          }
          updateCompState('schedules', schedules);
          //Store original for future reference
          updateCompState('schedulesOriginal', deepCopy(schedules));
          requestData.schedules = schedules;
          setRequestData({ ...requestData });
        }
      }, (err) => {
        console.log("ratePushSchedules Error!");
      })
    //-->End of getting ratepush schedules

    const room_type_codes = room_settings.map((item) => {
      if(item.is_base){
        baseRoom.current = item;
      }
      return item.room_code
    });
    
    ui.room_type_codes = room_type_codes;

  }, [app_state.property_id]);

  const cancelSave = (index) => {
    if(compState.schedulesOriginal[index]){
      requestData.schedules[index].isChangeRequest = false;
      requestData.schedules[index] = deepCopy(compState.schedulesOriginal[index])
    }else {
      requestData.schedules.splice(index, 1);
      compState.schedules.splice(index,1)
    }
    
    setRequestData({ ...requestData });
  };
  const addNewItem = () => {
    const size = compState.schedules.length;
    if(size < scheduleLimit ){
      const item = {
        schedule_name: "New Rate Push Schedule " + (size + 1),
        //schedule_uuid: "",
        expanded: true,
        schedule_time: "00:00",
        property_id: app_state.property_id,
        email_addresses: [],
        enabled: true,
        approval_range: {
          end: new moment()
            .add(1, "month")
            .startOf("day")
            .format("YYYY-MM-DDThh:mm:ss"),
          start: new moment().startOf("day").format("YYYY-MM-DDThh:mm:ss"),
        },
        dow: ["SUN", "MON", 'TUE', "WED", "THU", "FRI", "SAT"],
        exclude_room_types: [],
        lead_time: 0,
        horizon: 365,
        skip_dates: [],
        // owner: user.fullname,
        schedule_time: "00:00",
        //rate_push_field: "algo_generated_recommended_rate",
        //push_base_room: true,
        //cron_setup: false,
      };
      compState.schedules.push(item);
      updateCompState('schedules', compState.schedules);
      setRequestData({ ...requestData, schedules: compState.schedules });
      //dispatchSettings({ type: "create", itemToBeAdded: item });
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }
    else{
            ui.request.resType = "error";
            ui.request.status = "failed";
            ui.request.message = `Can not configure more than ${scheduleLimit} schedules`;
            setRequestData({ ...requestData });
          
          setTimeout(() => {
            ui.request.resType = null;
            ui.request.status = "idle";
            ui.request.message = "Schedule is deleted successfully!";
            setRequestData({ ...requestData });
          }, 3000);
    }
  };
  function onPressDelete(index) {
    ui.ask = true;
    ui.index = index;
    dispatchSettings({ type: "updateData" });
  }
  const deleteItem = (index) => {
    // debugger;
    ui.request.status = "loading";
    const toBeDeleted = requestData.schedules[index]; //requestData.pricing_settings.room_dow_plans.splice(index,1);
    const reqestAPI = toBeDeleted.schedule_uuid
      ? "schedulers/rate-push/" + app_state.property_id + "/"
      : null;

    const payload = {
      schedule_uuid: toBeDeleted.schedule_uuid,
      method: "DELETE"
    };
    // debugger;
    if (!reqestAPI) {
      ui.request.resType = "success";
      ui.request.status = "idle";
      requestData.schedules.splice(index, 1);
      setRequestData({ ...requestData });
      setTimeout(() => {
        ui.request.resType = null;
        setRequestData({ ...requestData });
      }, 3000);
    } else {
      setIsRequesting(true);
      dataService
        .getData({ api: reqestAPI, request: payload })
        .then((rs) => {
          if (rs.status === 'success') {
            ui.request.resType = "success";
            ui.request.status = "success";
            ui.request.message = "Schedule is deleted successfully!";
            requestData.schedules.splice(index, 1);
            setRequestData({ ...requestData });
          } else {
            ui.request.resType = "error";
            ui.request.status = "failed";
            ui.request.message = "Something went wrong!";
            setRequestData({ ...requestData });
          }
          setTimeout(() => {
            ui.request.resType = null;
            ui.request.status = "idle";
            ui.request.message = "Schedule is deleted successfully!";
            setRequestData({ ...requestData });
          }, 3000);
          setIsRequesting(false);
        }, (err) => {
          ui.request.resType = "error";
          ui.request.status = "failed";
          setTimeout(() => {
            ui.request.resType = null;
            ui.request.status = "idle";
            setRequestData({ ...requestData });
          }, 3000);
          setIsRequesting(false);
          console.log("delete_report_batches Error!");
        })

      //requestData.scheduled_ratepush[index].isChangeRequest = true;
      //setRequestData({...requestData});
    }
  };

  const saveChanges = (index) => {
    // debugger;
    setIsRequesting(true);
    const itemToBeSaved = deepCopy(requestData.schedules[index]);
    itemToBeSaved.skip_dates = itemToBeSaved.skip_dates.map(date => moment(date).format('YYYY-MM-DD'));
    const isNew = itemToBeSaved.schedule_uuid ? false : true;
    const reqestAPI = "schedulers/rate-push/" + app_state.property_id + "/";
    let payload = {
      method: "POST",
      schedule_name: itemToBeSaved.schedule_name,
      
      approval_range: { start: moment(itemToBeSaved.approval_range.start).format('YYYY-MM-DD'), end: moment(itemToBeSaved.approval_range.end).format('YYYY-MM-DD') },
      email_addresses: itemToBeSaved.email_addresses,
      skip_dates: itemToBeSaved.skip_dates,
      dow: itemToBeSaved.dow,
      exclude_room_types: itemToBeSaved.exclude_room_types,
      // owner: user.fullname,
      schedule_time: itemToBeSaved.schedule_time,
      lead_time: Number(itemToBeSaved.lead_time),
      horizon: Number(itemToBeSaved.horizon),
    };
    settingsState.ui.request.status = "loading";
    dispatchSettings({ type: "updateData", index: index });

    if (!isNew) {
      payload.method = "PUT";
      // payload.enabled= itemToBeSaved.enabled;
      // payload.property_id= app_state.property_id;
      payload.schedule_uuid = itemToBeSaved.schedule_uuid;
    }
    compState.skip_keys.map((key) => {
      delete payload[key];
    });
    dataService
      .update({ api: reqestAPI, request: payload })
      .then((rs) => {
        //Do some task here just after respons
        // debugger;
        requestData.schedules[index].isChangeRequest = false;
        // debugger;
        if (rs.status === 'success') {
          ui.request.resType = "success";
          ui.request.status = "success";
          ui.request.message = "Rate push schedules is updated successfully";
          if(!requestData.schedules[index].schedule_uuid){
            requestData.schedules[index]['schedule_uuid'] = rs.data.schedule_uuid
          }
          compState.schedulesOriginal[index] = deepCopy(requestData.schedules[index]);
          handleExpand(index)
          updateCompState();
          setTimeout(() => {
            ui.request.resType = null;
            ui.request.status = "idle";
            setRequestData({ ...requestData });
          }, 3000);
        } else {
          ui.request.resType = "error";
          ui.request.status = "error";
          ui.request.message = rs.message ? rs.message : "Rate push schedules could not be updated, please try again later! ";
          setRequestData({ ...requestData });
          setTimeout(() => {
            ui.request.resType = null;
            setRequestData({ ...requestData });
          }, 3000);
        }
        setIsRequesting(false);
      }, (err) => {
        ui.request.resType = "error";
        ui.request.status = "error";
        ui.request.message = "Rate push schedules could not be updated, please try again later!";
        setTimeout(() => {
          ui.request.resType = null;
          setRequestData({ ...requestData });
        }, 3000);
        setIsRequesting(false);
      });
  };

  const handleWODClick = (e, item, index) => {
    const val = e.target.value;
    const idow = item.dow.indexOf(val);
    // debugger;
    if (idow === -1) {
      item.dow.push(val);
    } else {
      item.dow.splice(idow, 1);
    }
    //data.list[id] = item.dow;
    ui.dataChanged["id_" + index] = true;
    updateRequestData('dow', item.dow, index);
  };

  console.log(requestData.scheduled_ratepush);

  const breadcrumbHierarchy = ["Rate Push", "Settings", "Scheduler"];

  const handleConfirmation = (index) => {
    ui.ask = false;
    dispatchSettings({ type: "updateData" });
    deleteItem(index);
  };
  const handleConfirmationCancel = () => {
    ui.ask = false;
    dispatchSettings({ type: "updateData" });
  }

  const saveField = (index, field) => {
    // debugger;
    if (field) {
      toggleEditable(null, 'cancel', index);
    }
    setRequestData({ ...requestData });
  };


  let allScheduleTime = requestData.schedules.map(schedule => schedule.schedule_time)
  const hasSameTime = (index, time)=>{    
    let removeCurrentItem = deepCopy(allScheduleTime);
    removeCurrentItem.splice(index, 1);
    let result = removeCurrentItem.includes(time)
    return result;
  }

  const updateRequestData = (keys, value, index, reportIndex) => {
    // debugger;
    //alert(value);
    const updatedObj = JSON.parse(JSON.stringify(requestData));
    const key = keys.split(">");
    const size = key.length;
    let val = value;

    if (size === 1) {
      if (key[0] === "recipients") {
        let size = val.length; // debugger;
        let l = updatedObj.schedules[index][key[0]].length;
        updatedObj.schedules[index][key[0]].splice(0, l);
        val.map((item, i) => {
          updatedObj.schedules[index][key[0]].push(item);
        });
      }
      else {
        updatedObj.schedules[index][key[0]] = value;
      }

    }
    if (size === 2) {
      updatedObj.schedules[index][key[0]][key[1]] = value;
    }
    if (size === 3) {
      updatedObj.schedules[index][key[0]][key[1]][key[2]] = value;
    }

    let hasSameTimeValue = hasSameTime(index, value);
    if(
      (updatedObj.schedules[index].schedule_name  === "") ||      
      (moment(updatedObj.schedules[index].approval_range.end ).isSameOrBefore(updatedObj.schedules[index].approval_range.start )) ||
      updatedObj.schedules[index].dow.length === 0 ||
      hasSameTimeValue      
    ){
      updatedObj.schedules[index].isChangeRequest = false;
    }
    else{
      updatedObj.schedules[index].isChangeRequest = true;
    }

    setRequestData({ ...requestData, schedules: updatedObj.schedules });
    setCompState((state) => {
      return { ...state,  schedules: updatedObj.schedules};
    });
    //setIsChangeRequest(true);
  };

  const handleExpand = (index) => {
    let { schedules } = compState;
    if (!schedules[index].expanded) {
      schedules[index].expanded = true;
    } else {
      schedules[index].expanded = false;
    }
    updateCompState("schedules", schedules);
    // setExpanded({ ...expanded });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refreshingData, setRefreshingData] = useState(false);
  const [refreshedData, setRefreshedData] = useState(false);
  function goBackToDashboard() {
    dispatch(
      updateAppState("navigate_to_dashboard", {
        app_state: {
          data_load: true,
          kpi_load: true,
        },
        ui_state: {
          active_settings_menu: "",
          showspinner: true,
        },
      }, "settings_click")
    );
    navigate("/");
  }
  function refreshData() {
    //reverting changes back

    const payload = {
      token_string: user.token_string,
      resourceId: app_state.property_id,
      resourceType: "property",
      targetCache: ["srr", "forecast", "otb"],
      operation: "flush",
    };
    setRefreshingData(true);
    // debugger;
    DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
      .then((data) => {
        //Do some task here just after respons
        if (data.status === "success") {
          setRefreshedData(true);
          setRefreshingData(false);
          console.log("refreshData Success");
          setTimeout(() => {
            setRefreshedData(false);
            // window.location.reload();
          }, 2000);
          clearAPIRegistryData();
        } else {
          setRefreshingData(false);
          console.warn("refreshData Error, data could not refreshed");
          setTimeout(() => {
            setRefreshedData(false);
          }, 3000);
        }
      })
      .catch((err) => {
        setRefreshingData(false);
        setTimeout(() => {
          setRefreshedData(false);
        }, 3000);
      });
  }
  const [pushNext, setPushNext] = useState(false);

  const datePickerRef = useRef(null);



  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <UserConfirmationAlert open={ui.ask} onConfirm={handleConfirmation} index={ui.index} onCancel={handleConfirmationCancel} />

      <Box className='top-axn-bar-1'
      >
        <Tooltip title="Back to Dashboard" arrow followCursor >
          <Button
            size="medium"
            variant="contained"
            className='back-to-dash-btn'
            onClick={goBackToDashboard}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
              <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                  <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                  <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                </g>
                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                  <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                  <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                </g>
                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                  <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                  <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                </g>
                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                  <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                  <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                </g>
                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                  <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                  <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                </g>
              </g>
            </svg>
            <span className='back-to-dash-txt'>Back to Dashboard</span>
          </Button>
        </Tooltip>

        <Box className="top-axn-bar-1-sub">
          <Tooltip title="Refresh Data" >
            <box
              onClick={refreshData}
              size="medium"
              variant="contained"
              className='refresh-btn'
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
            </box>
          </Tooltip>
          <IconButton>
            <Tooltip title="LodgIQ provides users significant flexibility to select the 
                  Room Types, Days-of-Week, Date Range, and Horizon (e.g. 30 or 90 days) 
                  for automated rate pushes. 
                  Users can even select the specific time of day 
                  they would like the automated pushes to occur. These schedules can be 
                  updated at will, with Preview rates available for review while you 
                  determine what works best for your property.">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#00415f" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path></svg>
            </Tooltip>
          </IconButton>
        </Box>

        <Alert
          icon={false}
          sx={{
            display: refreshedData ? "block" : "none",
            position: "fixed",
            left: 0,
            fontSize: 17,
            textAlign: "center",
            borderRadius: 0,
            lineHeight: 1,
            bottom: 40,
            width: "100%",
            zIndex: 100,
            backgroundColor: "green.main",
            color: "white.main",
          }}
          severity="success"
        >
          Data refreshed successfully!
        </Alert>

      </Box>

      <Box className='top-axn-bar'>
        <Box className='breadcrumb' sx={{ color: 'white.main' }}>
          <span className="breadcrum-inactive">Settings</span>
          <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
            sx={{ top: 3, position: "relative", height: 18 }}
          ></ArrowForwardIosOutlinedIcon>
          <span className="breadcrum-inactive">Scheduler</span>
          <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
            sx={{ top: 3, position: "relative", height: 18 }}
          ></ArrowForwardIosOutlinedIcon>
          <span className="breadcrum-active">Rate Push</span>
        </Box>
      </Box>

      {ui.request.resType && (
        <Box mt={2.5} mb={1}>
          <UserAlertInfo
            status={ui.request.resType}
            message={ui.request.message}
          />
        </Box>
      )}

      <Box className="bgwhite main-scroll-wrap-segments" sx={{ pl: 2, pr: 2, scrollBehavior: 'smooth' }} ref={containerRef}>

        <div className='profile-heading'>RATE PUSH</div>

        <Box sx={{ mt: 2.5 }}>

          {requestData.schedules.map((item, index) => (
            <Paper className="scheduler-rate-push" key={"key-" + index} sx={{ mb: 2 }} variant="outlined">
              <Box
                sx={{
                  flexDirection: "column",
                  position: "relative",
                  // display: "flex",
                }}
              >

                <Box>
                  <Grid container spacing={0}>

                    <Grid item className="scheduler-left" xs={3.5} sx={!compState.schedules[index].expanded ? { pt: 1.5, pb: 3 } : ""}>
                      <Grid container sx={{ p: 1.5, pb: 0 }}>
                        <div className="rate-push-badge">{index + 1}</div>


                        {!compState.schedules[index].expanded &&
                          <Grid item className="hotel-name-collapased">{(item) ?  item.schedule_name : ""}</Grid>
                        }
                        {compState.schedules[index].expanded &&
                          <Grid item className="hotel-name">

                            <div className="field ">
                              <InputLabel error={(item.schedule_name === "")} required shrink sx={{mb:'-4px' }} htmlFor="name">Name</InputLabel>
                              <TextField
                                id="name"                                
                                required
                                className="accordian-field"
                                size="small"
                                variant="outlined"                                
                                //placeholder="Please enter batch name..."
                                fullWidth
                                sx={{
                                  mr: 1,
                                  textAlign: "center",
                                  color: "blue.dark",
                                }}
                                value={(item) ?  item.schedule_name : ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  //updateCompState('schedules>schedule_name', value, index);
                                  updateRequestData("schedule_name", value, index);
                                }}
                              />
                              {/* <Input
                              className="accordian-field"
                              size="small"
                              fullWidth
                              sx={{
                                mt: 1, mr: 1,
                                textAlign: "center",
                                color: "blue.dark",
                              }}
                              value={item.schedule_name}
                              onChange={(e) => {
                                const value = e.target.value;
                                //updateCompState('schedules>schedule_name', value, index);
                                updateRequestData("schedule_name", value, index);
                              }}
                            /> */}

                            </div>
                            {/*  } */}
                          </Grid>
                        }

                        {/* <Grid item xs={2}>
                          <Switch
                            className="switch-button"
                            size="medium"
                            // color="green"
                            checked={item.enabled ? true : false}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              const val = checked ? true : false;
                              updateRequestData("enabled", val, index);
                            }}
                          />
                        </Grid> */}

                      </Grid>
                    </Grid>

                    <Grid item xs={8.5} sx={!compState.schedules[index].expanded ? { pt: 1.5} : ""}>
                      <Grid sx={{ pt: 0, pr: "10px" }} container>
                        <Grid item xs={10.5}>
                          {!compState.schedules[index].expanded &&
                            <Grid container justifyContent="space-around">
                              <Grid item>
                                <FormControl className="range-display">
                                  <Grid container alignItems="center">
                                    <Grid item pr={1} mt={0.5}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#00415f" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z"></path></svg>
                                    </Grid>
                                    <Grid item>
                                      <div className="control-label">Start Date</div>
                                      <div className="value">
                                        <span>{moment(item.approval_range.start).format('ddd, MMM DD YYYY')}</span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <FormControl className="range-display to">
                                  <Grid container alignItems="center">
                                    <Grid item pr={1} mt={0.5}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#00415f" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z"></path></svg>
                                    </Grid>
                                    <Grid item>
                                      <div className="control-label">End Date</div>
                                      <div className="value">
                                        <span>{moment(item.approval_range.end).format('ddd, MMM DD YYYY')}</span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <FormControl className="range-display">
                                  <Grid container alignItems="center" textAlign="center">
                                    <Grid item pr={1} mt={0.5}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#00415f" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm64-88a8,8,0,0,1-8,8H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48h48A8,8,0,0,1,192,128Z"></path></svg>
                                    </Grid>
                                    <Grid item textAlign="left">
                                      <div className="control-label">Time</div>
                                      <div className="value">
                                        <span>{item.schedule_time}</span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <FormControl className="range-display">
                                  <Grid container alignItems="center" textAlign="center">
                                    <Grid item pr={1} mt={0.5}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#00415f" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z"></path></svg>
                                    </Grid>
                                    <Grid item textAlign="left">
                                      <div className="control-label">Lead Time</div>
                                      <div className="value">
                                        <span>{item.lead_time} DAYS</span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </FormControl>
                              </Grid>
                              <Grid item>
                                <FormControl className="range-display">
                                  <Grid container alignItems="center" textAlign="center">
                                    <Grid item pr={1} mt={0.5}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#00415f" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z"></path></svg>
                                    </Grid>
                                    <Grid item textAlign="left">
                                      <div className="control-label">Rate Update Range</div>
                                      <div className="value">
                                        <span>{item.horizon} DAYS</span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </FormControl>
                              </Grid>
                            </Grid>
                          }
                        </Grid>
                        <Grid item xs={1.5}>
                          <Grid container justifyContent="end" mt={0.5}>

                            <Grid item>
                              {/* <Switch
                                className="switch-button"
                                size="medium"
                                checked={item.enabled}
                                onChange={(e) => {
                                  console.log("onChange event fired"); // Add this line
                                  updateRequestData("enabled", e.target.checked, index);
                                }}
                              /> */}
                              {/* <input style={{ padding: 0 }}
                                type="checkbox"
                                className="switch-button"
                                checked={(item) ?  item.enabled : false} // item.enabled should be a boolean that determines the state
                                onChange={(e) => {
                                  console.log("onChange eve nt fired");
                                  // Update the state when the checkbox is toggled
                                  updateRequestData("enabled", e.target.checked, index);
                                }}
                              /> */}
                            </Grid>

                            <Grid item>
                              <IconButton onClick={() => { deleteItem(index) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#e03131" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>
                              </IconButton>
                            </Grid>

                            <Grid item>
                              {compState.schedules[index].expanded &&
                                <IconButton className="collapse-arrow" onClick={() => {
                                  handleExpand(index);
                                }}>
                                  <KeyboardArrowUpOutlinedIcon className="up" sx={{ fontSize: 38 }} />
                                </IconButton>
                              }
                              {!compState.schedules[index].expanded &&
                                <IconButton className="collapse-arrow" onClick={() => {
                                  handleExpand(index);
                                }}>
                                  <KeyboardArrowDownOutlinedIcon className="down" sx={{ fontSize: 38 }} />
                                </IconButton>
                              }
                            </Grid>

                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>

                  <Collapse in={compState.schedules[index].expanded}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      overflow: "visible",
                      // border: "1px solid #000",
                      //transition: "all 0.1s linear"
                    }}>

                    <Grid container spacing={0}>
                      <Grid item className="scheduler-left" xs={3.5}>
                        <Box sx={{ p: 1.5, pt: 0 }}>


                          <Box className="field-controls">

                            <div className="control-fields">
                              <InputLabel  shrink sx={{fontSize: '18px', fontWeight: 600, mb:1, width: '133% ', }}>
                                Schedule Will Run Between These Dates
                                <Tooltip sx={{ marginTop: '4px'}} title="Start and End Date are Included in the Schedule" arrow followCursor>
                                  <InfoOutlinedIcon className="info-icon-rate-push" />
                                </Tooltip>
                              </InputLabel>

                              {/* <div className="control-label">From Stay Date</div> */}
                              <FormControl error={moment(item.approval_range.end ).isSameOrBefore(item.approval_range.start )}  className="range-pickers from">                                
                                <Grid container alignItems="center">

                                  <Grid item xs={12}>
                                    <div className="date-heading-large">
                                      <InputLabel required shrink sx={{mb:0, left: '-14px' }} htmlFor="startDate">Select Start Date</InputLabel>
                                      <MobileDatePicker
                                        disableCloseOnSelect={false}
                                        inputFormat="ddd, MMM DD YYYY"
                                        value={(item && item.approval_range && item.approval_range.start )? item.approval_range.start : new moment().add(1, 'month').format()}
                                        onChange={(val) => {
                                          const formated = new moment(val).format();
                                          updateRequestData("approval_range>start", formated, index);
                                        }}
                                        sx={{ backgroundColor: 'none' }}
                                        renderInput={(params) => (
                                          <TextField
                                            required                                            
                                            id="startDate"
                                            fullWidth
                                            {...params}
                                            value={item.approval_range.end}
                                            className="textfield"
                                            vaiant="outlined"
                                            //label="From Stay Date"
                                            placeholder="Start Date"
                                            InputProps={{
                                              endAdornment: (
                                                <IconButton>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#606060" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z"></path></svg>
                                                </IconButton>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                    </div>
                                  </Grid>

                                  {/* <Grid item xs={1}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#606060" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z"></path></svg>
                                  </Grid> */}
                                </Grid>
                              </FormControl>

                              {/* <div className="control-label">To Stay Date</div> */}
                              <FormControl error={moment(item.approval_range.end ).isSameOrBefore(item.approval_range.start )} className="range-pickers to" style={{marginBottom: 0}}>
                                {/* <div className="control-label">To Stay Date</div> */}
                                <Grid container alignItems="center">

                                  <Grid item xs={12}>
                                    <div className="date-heading-large">
                                    <InputLabel required shrink sx={{mb:0, left:'-14px' }} htmlFor="endDate">Select End Date</InputLabel>
                                      <MobileDatePicker
                                        disableCloseOnSelect={false}
                                        inputFormat="ddd, MMM DD YYYY"
                                        value={item.approval_range.end !== 'never' ? item.approval_range.end : new moment().add(1, 'month').format()}
                                        onChange={(val) => {
                                          const formated = new moment(val).format();
                                          updateRequestData("approval_range>end", formated, index);

                                        }}
                                        sx={{ backgroundColor: 'none' }}
                                        renderInput={(params) => (
                                          <TextField
                                            required                                            
                                            id="endDate"
                                            fullWidth
                                            {...params}
                                            value={item.approval_range.end}
                                            className="textfield"
                                            vaiant="outlined"
                                            //label="To Stay Date"
                                            placeholder="End Date"
                                            InputProps={{
                                              endAdornment: (
                                                <IconButton>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#606060" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z"></path></svg>
                                                </IconButton>
                                              ),
                                            }}
                                          />
                                        )}
                                      />
                                      <FormHelperText className="helperText" id="endDate-error-text">The end date must be after the start date.</FormHelperText>
                                    </div>
                                  </Grid>

                                  {/* <Grid item xs={1}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#606060" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z"></path></svg>
                                  </Grid> */}
                                </Grid>
                              </FormControl>
                            </div>
                          </Box>


                          <Box sx={{ pt: 0 }} >
                            <Grid container>
                              <Grid item xs={12}>
                                <InputLabel error={hasSameTime(index,item.schedule_time)} shrink sx={{mb:0}} htmlFor="scheduleTriggerTime">
                                  Schedule Trigger Time
                                  <Tooltip title="Please Specify the Time in the Property Local TimeZone" arrow followCursor>
                                    <InfoOutlinedIcon className="info-icon-rate-push" />
                                  </Tooltip>
                                </InputLabel>
                                <Grid container>

                                  <Grid item className="push-rate-at" sx={{ width: "100%" }}>
                                    <MobileTimePicker
                                      // ampmInClock="false"
                                      format="HH:mm"
                                      ampm={true}
                                      value={moment(item.schedule_time, 'hh:mm')}
                                      onChange={(a, b) => {
                                        let formated = moment(a).format("HH:mm");
                                        if (item.schedule_time !== formated) {
                                          updateRequestData("schedule_time", formated, index);
                                        }
                                        // debugger;
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          id="scheduleTriggerTime"
                                          fullWidth
                                          className="textfield"
                                          size="small"
                                          variant="outlined"
                                          label="Push Rates At"
                                          {...params}
                                          value={item.schedule_time}
                                          placeholder=""
                                          InputProps={{
                                            endAdornment: (
                                              <IconButton>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#606060" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm64-88a8,8,0,0,1-8,8H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48h48A8,8,0,0,1,192,128Z"></path></svg>
                                              </IconButton>
                                            ),
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Grid>                                
                                <InputLabel  error={hasSameTime(index, item.schedule_time)} shrink sx={{mt:1}} > Each job must be scheduled at a unique time</InputLabel>
                                <InputLabel shrink sx={{mt:-1}} >Property in {profile.current_property_time_zone} time zone</InputLabel>

                                {/* <Grid className="date-heading-small">{moment(moment().format("YYYY-MM-DD") + " " + item.schedule_time).format("a")}</Grid> */}

                              </Grid>
                            </Grid>
                          </Box>



                          <Grid container>
                            <Box sx={{ width: "100%" }}>
                              <div className="control-fields" style={{ marginTop: "0px", width: "100%" }}>
                                <InputLabel error={item.dow.length === 0 }  shrink sx={{mb:0 }}> 
                                  Choose Active Days
                                  <Tooltip title="You Can Exclude Certain Days of Week From Triggering this Schedule." arrow followCursor>
                                    <InfoOutlinedIcon className="info-icon-rate-push" />
                                  </Tooltip>
                                </InputLabel>
                                
                                <ButtonGroup
                                  disableElevation
                                  className="dow-group"
                                  variant="contained"
                                  fullWidth
                                  sx={{ mr: 2 }}
                                >
                                  {ui.dow.map((wd) => {
                                    const currIndex = item.dow.indexOf(wd);
                                    return currIndex !== -1 ? (
                                      <Button
                                        className="wd-name active"
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                          pt: 1,
                                          pb: 1,
                                          backgroundColor:
                                            item.dow.indexOf(wd) !== -1
                                              ? "grey"
                                              : "primary",
                                          color: "dark",
                                        }}
                                        key={"dow-uid-" + wd}
                                        value={wd}
                                        onClick={(e) => {
                                          handleWODClick(e, item, index);

                                        }}
                                      >
                                        {wd[0]}
                                      </Button>
                                    ) : (
                                      <Button
                                        className="wd-name"
                                        variant="outlined"
                                        sx={{
                                          pt: 1,
                                          pb: 1,
                                          backgroundColor:
                                            item.dow.indexOf(wd) !== -1
                                              ? "grey"
                                              : "primary",
                                          color: "dark",
                                          border: "1px solid rgba(25, 118, 210)",
                                        }}
                                        key={"dow-uid-" + wd}
                                        size="small"
                                        value={wd}
                                        onClick={(e) => {
                                          handleWODClick(e, item, index);
                                        }}
                                      >
                                        {wd[0]}
                                      </Button>
                                    );
                                  })}
                                </ButtonGroup>
                                <InputLabel error={item.dow.length === 0 } shrink sx={{mt:1}} >Select at least one day from the week</InputLabel>
                              </div>
                            </Box>
                          </Grid>

                          {/* <Grid container justifyContent='flex-end' sx={{ pt: 1 }}>
                            <Grid item>
                              <Button
                                disabled={!item.isChangeRequest}
                                onClick={() => {
                                  saveChanges(index, item.batch_id);
                                }}
                                variant="contained"
                                size="small"
                                className="btn-main save"
                                // color="green"
                                sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                              >
                                Save
                              </Button>

                            </Grid>
                            <Grid item>

                              <Button
                                disabled={!item.isChangeRequest}
                                onClick={() => {
                                  cancelSave(index);
                                }}
                                variant="contained"
                                className="btn-main cancel"
                                // color="warning"
                                size="small"
                                sx={{ minWidth: "auto" }}
                              >
                                cancel
                              </Button>
                            </Grid>
                          </Grid> */}
                        </Box>
                      </Grid>

                      <Grid item xs={8.5}>
                        <Box sx={{ maxHeight: '45vh', overflow: 'auto', p: 1.5}}>

                          <Box sx={{ mb: 2 }}>
                            <Grid container spacing={2} className="scheduler-right-container1">
                              <Grid item xs={6}>                                
                                <InputLabel htmlFor="setLeadTime" shrink sx={{mb:0 }} >
                                  Set Lead Time                                 
                                  <Tooltip title="Days between the daily schedule start and the beginning of the stay date range for rate updates" arrow followCursor>
                                    <InfoOutlinedIcon className="info-icon-rate-push" />
                                  </Tooltip>
                                </InputLabel>
                                <Grid className="highlight-number">
                                  <FormControl className="rate-textfield2" fullWidth size="small">
                                    <TextField                                         
                                        type="number"
                                        id="setLeadTime"                                        
                                        value={item.lead_time}
                                        className="accordian-field"
                                        size="small"
                                        onFocus={e => {
                                          e.target.select();
                                        }}
                                        onBlur={(e)=>{
                                          let value = 0
                                          if(Number(e.target.value) < value  || e.target.value === ""){                                                                                    
                                            item.lead_time = value;                                         
                                            updateRequestData("lead_time", value, index);
                                          }
                                        }}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          item.lead_time = value;
                                          updateRequestData("lead_time", value, index);
                                        }}
                                        InputProps={{
                                          endAdornment: <InputAdornment position="start">Day(s)</InputAdornment>,
                                        }}
                                      />
                                      {/* <FormHelperText className="helperText" error id="setLeadTime-error-text">Lead time less than the range</FormHelperText> */}
                                  </FormControl>
                                </Grid>

                              </Grid>
                              
                              <Grid item xs={6}>                                
                                <InputLabel  htmlFor="rateUpdateRange" shrink sx={{mb:0 }} >
                                  Rate Update Range
                                  <Tooltip title="Choose the Number of Days to Push Rates." arrow followCursor>
                                    <InfoOutlinedIcon className="info-icon-rate-push" />
                                  </Tooltip>
                                </InputLabel>
                                <Grid className="highlight-number">
                                  <FormControl className="rate-textfield2" fullWidth size="small">
                                    <TextField                                       
                                      type="number"
                                      id="rateUpdateRange"
                                      value={item.horizon}
                                      className="accordian-field"
                                      size="small"
                                      onFocus={e => {
                                        e.target.select();
                                      }}
                                      onBlur={(e)=>{
                                        let value = 1;
                                        if(Number(e.target.value) < value  || e.target.value === ""){                                                                                    
                                          item.lead_time = value;
                                          updateRequestData("horizon", value, index);
                                        }
                                      }}
                                      onChange={(e) => {
                                        const value =  e.target.value;
                                        item.horizon = value;
                                        updateRequestData("horizon", value, index);
                                      }}
                                      InputProps={{
                                        endAdornment: <InputAdornment position="start">Day(s)</InputAdornment>,
                                      }}
                                    />
                                  </FormControl>
                                </Grid>

                              </Grid>
                            </Grid>
                          </Box>

                          <Box sx={{ mb: 2 }}>

                            <Grid className="scheduler-right-container1">

                              {/* <div className="heaading">
                                Skip dates
                                <DatePicker
                                  className="date-picker skip"
                                  disablePast={true}
                                  disableCloseOnSelect={true}
                                  components={{ OpenPickerIcon: CustomDatePickerIcon }}
                                  // format="YYYY-MM-DDT00:00:00"
                                  renderDay={(day, selected, params) => {
                                    //debugger;
                                    //const formatted = moment(day).format("ddd, MMM DD YYYY");
                                    const formatted = moment(day).format("YYYY-MM-DDT00:00:00");
                                    if (item.skip_dates.indexOf(formatted) !== -1) {
                                      params.selected = true;
                                    }
                                    params.onClick = (date) => {
                                      return (e) => {
                                        // debugger;
                                        if (params.selected) {
                                          const formatted = moment(date).format("YYYY-MM-DDT00:00:00");
                                          const i = item.skip_dates.indexOf(formatted);
                                          item.skip_dates.splice(i, 1);
                                          updateRequestData('skip_dates', item.skip_dates, index)
                                          // debugger;
                                        }
                                      }

                                    }
                                    params.onDaySelect = (a, b) => {
                                      // debugger;
                                      if (b == "finish") {
                                        const formatted = moment(a).format("YYYY-MM-DDT00:00:00");
                                        if (item.skip_dates.indexOf(formatted === -1)) {
                                          item.skip_dates.push(formatted);
                                          const id = "id_" + index;
                                          ui.dataChanged[id] = true;
                                          updateRequestData('skip_dates', item.skip_dates, index);
                                        }
                                      }

                                      return;
                                    };

                                    return <span onClick={params.onClick(day)}><PickersDay {...params} /></span>;
                                  }}
                                  value={requestData.skip_dates}
                                  onChange={() => { }}
                                  renderInput={(params) => {
                                    // let pr = params; debugger;
                                    return (
                                      <TextField
                                        className="skip-dates-picker"
                                        size="small"
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            paddingRight: 0,
                                          },
                                          "& .MuiButtonBase-root": {},
                                          "& input": {
                                            visibility: "hidden",
                                            pl: 0,
                                            width: 0,
                                            height: 0,
                                          },
                                          paddingRight: 0,
                                        }}
                                        {...params}
                                      />
                                    );
                                  }}
                                />
                              </div>

                              <div style={{ marginTop: "4px", width: "100%" }} className="skip-dates">
                                {item.skip_dates.map(date => (
                                  <Chip
                                    className="schedule-chip skip-date"
                                    label={<><span className="dow">{moment(date).format("ddd")}</span> {moment(date).format("YYYY-MM-DD")}</>}
                                    variant="outlined"
                                    onDelete={handleDelete({ date, index })}
                                  />
                                )
                                )}
                              </div> */}                              
                              <InputLabel  htmlFor="excludeSpecificDates" shrink sx={{ mb:0 }}>
                                  Exclude Specific Dates
                                <Tooltip title="Stay Dates Where You Don’t Want LodgIQ AI to Auto Update BAR Rates" arrow followCursor>
                                  <InfoOutlinedIcon className="info-icon-rate-push" />
                                </Tooltip>
                              </InputLabel>
                              <Box className="skip-dates"  pr={1} sx={{ display: "flex", alignItems: "center" }}>
                                <div style={{ marginTop: "4px", width: "100%"}}>
                                  {item.skip_dates.map(date => (
                                    <Chip
                                      className="schedule-chip skip-date"
                                      label={<><span className="dow">{moment(date).format("ddd")}</span> {moment(date).format("YYYY-MM-DD")}</>}
                                      variant="outlined"
                                      onDelete={handleDelete({ date, index })}
                                    />
                                  )
                                  )}
                                </div>

                                <div>
                                  <DatePicker
                                    id="excludeSpecificDates"
                                    className="date-picker skip skip-dates-pickers-field"
                                    disablePast={true}
                                    disableCloseOnSelect={true}
                                    components={{ OpenPickerIcon: CustomDatePickerIcon }}
                                    // format="YYYY-MM-DDT00:00:00"
                                    renderDay={(day, selected, params) => {
                                      //debugger;
                                      //const formatted = moment(day).format("ddd, MMM DD YYYY");
                                      const formatted = moment(day).format("YYYY-MM-DDT00:00:00");
                                      if (item.skip_dates.indexOf(formatted) !== -1) {
                                        params.selected = true;
                                      }
                                      params.onClick = (date) => {
                                        return (e) => {
                                          // debugger;
                                          if (params.selected) {
                                            const formatted = moment(date).format("YYYY-MM-DDT00:00:00");
                                            const i = item.skip_dates.indexOf(formatted);
                                            item.skip_dates.splice(i, 1);
                                            updateRequestData('skip_dates', item.skip_dates, index)
                                            // debugger;
                                          }
                                        }

                                      }
                                      params.onDaySelect = (a, b) => {
                                        // debugger;
                                        if (b == "finish") {
                                          const formatted = moment(a).format("YYYY-MM-DDT00:00:00");
                                          if (item.skip_dates.indexOf(formatted === -1)) {
                                            item.skip_dates.push(formatted);
                                            const id = "id_" + index;
                                            ui.dataChanged[id] = true;
                                            updateRequestData('skip_dates', item.skip_dates, index);
                                          }
                                        }

                                        return;
                                      };

                                      return <span onClick={params.onClick(day)}><PickersDay {...params} /></span>;
                                    }}
                                    value={requestData.skip_dates}
                                    onChange={() => { }}
                                    renderInput={(params) => {
                                      // let pr = params; debugger;
                                      return (
                                        <TextField
                                          className="skip-dates-pickers"
                                          size="small"
                                          sx={{
                                            "& .MuiOutlinedInput-root": {
                                              paddingRight: 0,
                                            },
                                            "& .MuiButtonBase-root": {},
                                            "& input": {
                                              visibility: "hidden",
                                              pl: 0,
                                              width: 0,
                                              height: 0,
                                            },
                                            paddingRight: 0,
                                          }}
                                          {...params}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </Box>

                            </Grid>

                          </Box>

                          <Box sx={{ mb: 2 }}>
                            <Grid className="scheduler-right-container1">
                              <InputLabel  htmlFor="excludeSpecificRoomTypes" shrink sx={{ mb:0 }}>
                                Exclude Specific Room Types
                                <Tooltip title="Specific Room Types for Which You Don't Want LodgIQ AI to Change BAR Rates." arrow followCursor>
                                  <InfoOutlinedIcon className="info-icon-rate-push" />
                                </Tooltip>
                              </InputLabel>

                              {/* <Autocomplete
                                // sx={{ width: "calc(100% - 60px)" }}
                                className="room-types"
                                size="small"
                                multiple
                                options={room_settings.map(
                                  (option) => option.room_code
                                )}
                                getOptionLabel={(option) => option}
                                value={item.room_types}
                                freeSolo
                                onChange={(e, newVal) => {
                                  item.room_types.splice(0, item.room_types.length + 1);
                                  newVal.map((it) => {
                                    item.room_types.push(it);
                                  });
                                  const id = "id_" + index;
                                  ui.dataChanged[id] = true;
                                  updateRequestData('room_types', item.room_types, index);
                                  //   type: "updateData",
                                  //   id: id,
                                  // });
                                }}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      key={"room-type-" + index}
                                      size="small"
                                      variant="outlined"
                                      className="schedule-chip"
                                      sx={{ border: "1px dashed #1976d2" }}
                                      // color="primary"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    className="textfield"
                                    fullWidth
                                    variant="outlined"
                                    label="ROOM TYPES"
                                    placeholder="Add..."
                                  />
                                )}
                              /> */}

                              <Autocomplete 
                                id="excludeSpecificRoomTypes"
                                size="small"
                                multiple
                                renderOption={(props, option) => {
                                  const baseRoomCode = baseRoom.current.room_code;
                                  const { key, ...optionProps } = props;
                                  return (
                                    <Box
                                      key={key}
                                      component="li"
                                      {...optionProps}
                                    >
                                      {option === baseRoomCode ? option + " - Base Room" : option}                                       
                                    </Box>
                                  );
                                }}
                                options={room_settings.map( (option) => option.room_code )}
                                getOptionLabel={(option) => option}
                                value={item.exclude_room_types}
                                freeSolo={false}
                                onChange={(e, newVal) => {
                                  item.exclude_room_types.splice(0, item.exclude_room_types.length + 1);
                                  newVal.map((it) => {
                                    let newIt = it; 
                                    item.exclude_room_types.push(newIt);
                                  });
                                  const id = "id_" + index;
                                  ui.dataChanged[id] = true;
                                  updateRequestData('exclude_room_types', item.exclude_room_types, index);
                                  //   type: "updateData",
                                  //   id: id,
                                  // });
                                }}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      key={"room-type-" + index}
                                      size="small"
                                      variant="outlined"
                                      className="schedule-chip"
                                      sx={{ border: "1px dashed #1976d2" }}
                                      // color="primary"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    className="textfield"
                                    fullWidth
                                    variant="outlined"
                                    //label="ROOM TYPES"
                                    placeholder="Add..."
                                  />
                                )}
                              />

                            </Grid>
                          </Box>

                          <Box sx={{ mb: 2 }}>
                            <Grid className="scheduler-right-container1" >
                              <InputLabel  htmlFor="emailRecipients" shrink sx={{ mb:0 }}>
                                Email Recipients
                                <Tooltip title="Email Address of Team Members You Want LodgIQ to Notify Once the Rates are Updated" arrow followCursor>
                                  <InfoOutlinedIcon className="info-icon-rate-push" />
                                </Tooltip>
                              </InputLabel>

                              {/* <Autocomplete
                                className="recipients"
                                size="small"
                                multiple
                                options={[]}
                                value={item.email_addresses}
                                freeSolo
                                onChange={(e, newVal) => {
                                  // debugger;
                                  //item.recipients;
                                  updateRequestData('email_addresses', newVal, index);
                                  // updateRequestData(
                                  //   "recipients",
                                  //   newVal,
                                  //   index
                                  // );
                                }}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      className="recipients-chip-vijay"
                                      key={"recipient-" + index}
                                      size="small"
                                      variant="outlined"

                                      sx={{ border: "1px dashed #1976d2" }}
                                      // color="primary"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />

                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="textfield"
                                    size="small"
                                    variant="outlined"
                                    label="ADDITIONAL RECIPIENTS"
                                    placeholder=" Type and press enter..."
                                  />
                                )}
                              /> */}

                              <Autocomplete
                                id="emailRecipients"
                                size="small"
                                multiple
                                options={[]}
                                value={item.email_addresses}
                                freeSolo
                                onChange={(e, newVal) => {
                                  // debugger;
                                  //item.recipients;
                                  updateRequestData('email_addresses', newVal, index);
                                  // updateRequestData(
                                  //   "recipients",
                                  //   newVal,
                                  //   index
                                  // );
                                }}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      className="recipients-chip-vijay"
                                      key={"recipient-" + index}
                                      size="small"
                                      variant="outlined"

                                      sx={{ border: "1px dashed #1976d2" }}
                                      // color="primary"
                                      label={option}
                                      {...getTagProps({ index })}
                                    />

                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="textfield"
                                    size="small"
                                    variant="outlined"
                                    //label="ADDITIONAL RECIPIENTS"
                                    placeholder=" Type and press enter..."
                                  />
                                )}
                              />

                            </Grid>
                          </Box>
                        </Box>

                        <Grid container justifyContent='flex-end' sx={{ p: 1.5, gap: "10px" }}>
                          <Grid item>
                            <Button
                              disabled={!item.isChangeRequest}
                              onClick={() => {
                                cancelSave(index);
                              }}
                              variant="outlined"
                              className={(item.isChangeRequest) ? "sub-navigate-button-active-cancel" : "sub-navigate-button-cancel"}
                              size="small"
                            //sx={{ mr: 1}}
                            >
                              Cancel
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              disabled={!item.isChangeRequest}
                              onClick={() => {
                                saveChanges(index, item.batch_id);
                              }}
                              variant="outlined"
                              size="small"
                              className={(item.isChangeRequest) ? "sub-navigate-button-active" : "sub-navigate-button"}
                            >
                              <span style={{ display: !isrequesting ? "block" : "none" }}>
                                Save
                              </span>
                              <span
                                style={{
                                  display: isrequesting ? "block" : "none",
                                  paddingRight: 10,
                                }}
                              >
                                saving...
                              </span>
                              <CircularProgress
                                sx={{ display: isrequesting ? "block" : "none" }}
                                color="white"
                                size="16px"
                              />
                            </Button>

                            {/* <Button
                                disabled={!item.isChangeRequest}
                                onClick={() => {
                                  cancelSave(index);
                                }}
                                variant="contained"
                                className="btn-main cancel"
                                // color="warning"
                                size="small"
                                sx={{ minWidth: "auto" }}
                              >
                                cancel
                              </Button> */}
                          </Grid>
                        </Grid>


                      </Grid>
                    </Grid>

                  </Collapse>
                </Box>

              </Box>
            </Paper>
          ))}

        </Box>

        <Button
          variant="outlined"
          className="add-button"
          size="small"
          sx={{ lineHeight: "auto" }}
          onClick={addNewItem}
        >
          <span className="add-btn-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#333333" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>
          </span>
          <span className="add-btn-txt">Create New Schedule</span>
        </Button>

        {isrequesting && (
          <Box
            sx={{
              textAlighn: "center",
              backgroundColor: "dark.darkest",
              opacity: 0.8,
              position: "fixed",
              top: 40,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 100,
            }}
          >
            <CircularProgress
              size={40}
              sx={{
                color: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-20px",
                marginLeft: "-20px",
              }}
            />
          </Box>
        )}

      </Box>

      {
        refreshingData && (
          <Box
            sx={{
              textAlighn: "center",
              backgroundColor: "dark.darkest",
              opacity: 0.8,
              position: "fixed",
              top: 40,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 100,
            }}
          >
            <CircularProgress
              size={40}
              sx={{
                color: "white",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-20px",
                marginLeft: "-20px",
              }}
            />
          </Box>
        )
      }

    </LocalizationProvider >
  );
}

const initalState = {
  data: { list: [] },
  ui: {
    //Any kind of interaction with server is a request
    request: {
      resType: null,
      status: "idle", // May have these values "idle", "loading", "loaded", "empty", "error"
      message: "",
    },
    expanded: {},
    mode: {},
    dow: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
    dataChanged: {},
    schedule_time: new moment(),
  },
};

const CustomDatePickerIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#00415f" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z"></path></svg>
  )
}
