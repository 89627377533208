
import { Box, Select, FormControl, MenuItem, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import { UPDATE_STATE } from '../../../../actions/types';
import { deepCopy, getSdyearsAndSdlyOn } from "../../../../app/util";
import { APP_CACHE } from "../../../../app/config";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import moment from 'moment';

const MultiSegmentPicker = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const [segmentType, setSegmentType] = useState(controls.segmentType);
    const dispatch = useDispatch();

    const state = useSelector(appState => appState);
    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const {property_id, property_name} =app_state;
    const handleChange = (type, value) => {
        controls.segmentType = value;
        controls.segmented_view = (value === "hotel") ? false : true;
        if(ui_state.active_tiles[0]==="flights"){
            controls.segmented_view = (value === "total") ? false : true;
        }
        window.analyze.track("change_data_level", {
            "selected_tile": ui_state.active_tiles.join(),
            ...app_state.primary_stay_range,
            property_id, property_name,segmentType:value,
        }, { ...app.current_state}, {app_state: { workspace_controls: controls, data_load: true }, ui_state: { showspinner: true}});
        // controls.sdYear = moment().subtract(1, 'year').format("YYYY");

        let asOfDateYear =  Number(moment(app_state.as_of_date, "YYYY-MM-DD").format("YYYY"));
        let appState = deepCopy(app_state); 
        let uiState = deepCopy(ui_state) 

        let sdYearsAndSdlyOn = getSdyearsAndSdlyOn(appState, uiState,  asOfDateYear);
        let apc = APP_CACHE; 
        apc.controls = {...apc.controls, ...sdYearsAndSdlyOn};
        

        dispatch({
            type: UPDATE_STATE,
            payload: {
                app_state:{
                    data_load: true, kpi_load: false,
                    workspace_controls:{
                        ...controls,
                        ...sdYearsAndSdlyOn
                    }
                },
                ui_state: { 
                    showspinner: true 
                }
            }
        })
        // dispatch(updateAppState(type,
        //     { app_state: { workspace_controls: controls, data_load: true }, ui_state: { showspinner: true} }));
    }

    useEffect(() => {
        if(!ui_state.showspinner && !app_state.data_load){
            setSegmentType(controls.segmentType);
        }
    },[ui_state.showspinner]);
    const multilevelSelect = ()=>{
        if(ui_state.active_tiles[0] ==="otb_v2_multi_segment"){
            return ( <Select disabled={ui_state.showspinner} className='tabular-data-filter' fullWidth value={segmentType} sx={{ height: "30px", mt: "5px" }} onChange={(e) => {
                setSegmentType(e.target.value);
                handleChange("segmentType", e.target.value);
            }}>
               <MenuItem value="hotel" className='tabular-data-filter'> Hotel</MenuItem>
            <MenuItem value="top_market_segment" className='tabular-data-filter'> Top Segments </MenuItem>
            <MenuItem value="market_segment" className='tabular-data-filter'> Segments </MenuItem>
            {/* {(app_state.workspace_controls.rate_plan === 1 &&
                <MenuItem value="rate_plan" className='tabular-data-filter'> Rate Plan</MenuItem>
            )} */}
            {<MenuItem value="rate_plan" className='tabular-data-filter'> Rate Plan</MenuItem>}
            {/* {(app_state.workspace_controls.room_type === 1 && 
                <>*/}
                <MenuItem value="room_class" className='tabular-data-filter'> Room Class </MenuItem>
                <MenuItem value="room_type" className='tabular-data-filter'> Room Type </MenuItem>
                {/* </>
            )} */}
                            
            </Select>);
        }
        if(ui_state.active_tiles[0] ==="flights"){
            return ( <Select disabled={ui_state.showspinner} className='tabular-data-filter' fullWidth 
            value={segmentType} sx={{ height: "30px", mt: "5px" }} 
            onChange={(e) => {
                setSegmentType(e.target.value);
                handleChange("segmentType", e.target.value);
            }}>
                <MenuItem value="total" className='tabular-data-filter'> Total</MenuItem>
                <MenuItem value="paxProfile" className='tabular-data-filter'> Pax Profile</MenuItem>
                <MenuItem value="leadTime" className='tabular-data-filter'> Lead Time</MenuItem>
                <MenuItem value="channel" className='tabular-data-filter'> Channel</MenuItem>
                <MenuItem value="paxPerBooking" className='tabular-data-filter'> Pax Per Booking</MenuItem>
                <MenuItem value="lengthOfStay" className='tabular-data-filter'> Length of Stay</MenuItem>
            </Select>);
        }
       
    }
    return (
        <>
            <Box id="tabletype" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box className="data-level-label">Data Level</Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                   {multilevelSelect()}
                </FormControl>
            </Box>
        </>
    )
}

export default MultiSegmentPicker