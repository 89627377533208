
import moment from "moment";
import { capitalize, convertToPercent, deepCopy, getAppData, getDatesInRange, getFromState, getObjectFilter, getTransformedSegments, in_array, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";

export function getRestrictionsReportStatusData (columns){
    let data = []
    if(dataService.getSeriesById("RestrictionsStatus") &&
     dataService.getSeriesById("RestrictionsStatus").data){
        data = dataService.getSeriesById("RestrictionsStatus").data.data;
    }
    let date = data[0].restrictions[0].stay_date;
    console.log("columns", columns);
    console.log("data", data);
    let setup_data = [];
    data.forEach((item, index) => {
        let data_columns = [];
        columns.forEach(col => {
            let dataObj = {};
           
            if (col.type === 'dateColumn') {
                dataObj = setUpDate(dataObj, date);
            }
            if (col.type === 'dataColumn') {
                let datetime = item.inserted_on;
                let user_action = item.restrictions[0].user_action;
                let user = (item.user_name) ?item.user_name:"Test User";    
                let status = (item.status) ? item.status : "Failed";
                let updateTime = moment(datetime).format("llll")
                let restrictionsHouse = item.restrictions.filter(i=> !i.hasOwnProperty("room_type"));
                let restrictionsRoomType = item.restrictions.filter(i=> i.hasOwnProperty("room_type"));
                let restrictionsByDate = [];
                item.restrictions.forEach(res=>{
                    let rest = {};
                    if(res.hasOwnProperty("ctd")){
                        rest.ctd = res.ctd;
                    }
                    if(res.hasOwnProperty("cta")){
                        rest.cta = res.cta;
                    }
                    if(res.hasOwnProperty("close")){
                        rest.close = res.close;
                    }
                    if(res.min_los && res.min_los>1){
                        rest["min_los"] = parseInt(res["min_los"]);   
                    }
                    if(res.max_los && res.max_los>1){
                        rest["max_los"] = parseInt(res["max_los"]);   
                    }
                    if(res.hasOwnProperty("room_type")){
                        rest.room_type = res.room_type;
                        if(restrictionsByDate.find(i=>i.updateTime ===updateTime )){
                            let resIndex  = restrictionsByDate.findIndex(i=> i.updateTime ===updateTime);
                        
                            if(restrictionsByDate[resIndex][res.room_type]){
                                restrictionsByDate[resIndex][res.room_type]={...restrictionsByDate[resIndex][res.room_type], 
                                                                            ...rest}
                            }else {
                                restrictionsByDate[resIndex][res.room_type]={...rest}

                            }
                            
                        }else {
                            let resRmType = {
                                updateTime:updateTime, 
                                status:res.status,
                            }
                            resRmType[res.room_type] = {...rest};
                            restrictionsByDate.push(resRmType);
                        }
                    }
                    else {
                        if(restrictionsByDate.find(i=> i.updateTime === updateTime)){
                            let resIndex  = restrictionsByDate.findIndex(i=> i.updateTime === updateTime);
                            if(restrictionsByDate[resIndex]["House"]){
                                restrictionsByDate[resIndex]["House"]={...restrictionsByDate[resIndex]["House"], 
                                    ...rest}
                            }else {
                                restrictionsByDate[resIndex]={ ...restrictionsByDate[resIndex], "House":{...rest} };
                            }                            

                        }else {
                            restrictionsByDate.push({
                                updateTime:updateTime, "House":{...rest}
                            })
                        }
                    }
                })
                let restrictionsArr = [];
                restrictionsByDate  = restrictionsByDate.map(item=>{
                    let {updateTime, status, ...restrictions} = item;
                   
                    Object.keys(restrictions).forEach(key=>{
                        restrictionsArr.push(restrictions[key]);
                    })
                    return restrictionsArr;
                })
                // restrictionsRoomType.forEach()
                col.subcolumns.map(key => {
                   
                    if(key.value === "User"){
                        dataObj[key.value] = user;
                    }else if(key.value === "Action"){
                        dataObj[key.value] = user_action ? user_action : "Overriden";

                    }else if(key.value === "UpdateTime"){
                        dataObj[key.value] = moment.utc(updateTime).local().format("ddd, MMM DD, YYYY hh:mm A");
                    }else if(key.value === "Status"){
                        dataObj[key.value] =status;
                    }
                    else if(key.value === "RoomsRestrictions"){
                        dataObj[key.value] = restrictionsArr;
                    }
                   

                })
            }
            data_columns.push(dataObj);
        })
        setup_data.push(data_columns);;
    })
    return setup_data;

}
export function getRestrictionsReportStatusDataRange(columns){
    let data = []
    if(dataService.getSeriesById("RestrictionsStatusDateRange") &&
     dataService.getSeriesById("RestrictionsStatusDateRange").data){
        data = dataService.getSeriesById("RestrictionsStatusDateRange").data.data;
    }
    console.log(dataService.getSeriesById("RestrictionsStatusDateRange"));
    console.log("columns", columns);
    console.log("data", data);
    let setup_data = [];
    let dates =[];      
    const restrictionsByDate = [];
    data.forEach(item=>{ 
        item.restrictions.forEach( (res, i) => {
            let rest = {};
            rest.user_action = res.user_action;
            if(res.hasOwnProperty("ctd")){
                rest.ctd = res.ctd;
            }
            if(res.hasOwnProperty("cta")){
                rest.cta = res.cta;
            }
            if(res.hasOwnProperty("close")){
                rest.close = res.close;
            }
            if(res.min_los && res.min_los>1){
                rest["min_los"] = parseInt(res["min_los"]);   
            }
            if(res.max_los && res.max_los>1){
                rest["max_los"] = parseInt(res["max_los"]);   
            }
            if(res.hasOwnProperty("room_type")){
                rest.room_type = res.room_type;
                let resIndex  = restrictionsByDate.findIndex(i=> i.stay_date === res.stay_date && i.hasOwnProperty(res.room_type) );
                if(resIndex !== -1){                    
                    if(moment(item.inserted_on).isSameOrAfter(restrictionsByDate[resIndex].inserted_on) ){
                        if(restrictionsByDate[resIndex][res.room_type]){
                            restrictionsByDate[resIndex][res.room_type]={...restrictionsByDate[resIndex][res.room_type], ...rest}
                        }
                        // else {
                        //     restrictionsByDate[resIndex][res.room_type]={...rest}
                        // }
                    } 
                }else {
                    let resRmType = {
                        stay_date:res.stay_date, 
                        status:item.status,
                        inserted_on:item.inserted_on,
                    }
                    resRmType[res.room_type] = {...rest};
                    restrictionsByDate.push(resRmType);
                }
            }else {
                let resIndex  = restrictionsByDate.findIndex(i=> i.stay_date === res.stay_date && i.hasOwnProperty("House"));
                if(resIndex !== -1){
                    if(moment(item.inserted_on).isSameOrAfter(restrictionsByDate[resIndex].inserted_on) ){
                        if(restrictionsByDate[resIndex]["House"]){
                            restrictionsByDate[resIndex]["House"]={...restrictionsByDate[resIndex]["House"],  ...rest}
                        }
                        // else {
                        //     restrictionsByDate[resIndex]={ ...restrictionsByDate[resIndex],"House":{ ...rest}};
                        // }
                    }
                }else {                    
                    restrictionsByDate.push({
                        status:item.status,
                        stay_date:res.stay_date, 
                        "House":{ ...rest}, 
                        inserted_on:item.inserted_on,
                    })
                }
            }
        })
    })
    restrictionsByDate.forEach((item, index) => {
        let data_columns = [];
        columns.forEach(col => {
            let dataObj = {};
            let datetime = item.inserted_on;
            if (col.type === 'dateColumn') {
                dataObj = setUpDate(dataObj, item.stay_date);
            }
            if (col.type === 'dataColumn') {
                let user = (data[0].user_name) ? data[0].user_name:"Test User"; 
                let user_action = item.user_action;   
                let status = (item.status) ? item.status : "Failed";
                let updateTime = moment(datetime).format("llll")
            
                // restrictionsRoomType.forEach()
                col.subcolumns.map(key => {
                    if(key.value === "User"){
                        dataObj[key.value] = user;
                    }else if(key.value === "Action"){
                        dataObj[key.value] = user_action ? user_action : "Overriden";
                    }else if(key.value === "UpdateTime"){
                        dataObj[key.value] = moment.utc(updateTime).local().format("ddd, MMM DD, YYYY hh:mm A");
                    }else if(key.value === "Status"){
                        dataObj[key.value] =status;
                    }
                    else if(key.value === "RoomsRestrictions"){
                        let {status, stay_date, ...restrictions} = item;
                        let resArr =[];
                        Object.keys(restrictions).forEach(res=>{
                            if(res !== 'inserted_on'){
                                resArr.push(restrictions[res]);
                            }
                        })
                        dataObj[key.value] = resArr;
                    }

                })
            }
            data_columns.push(dataObj);
        })
        setup_data.push(data_columns);;
    })
    return setup_data;
}