import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import moment from "moment";
import {
    deepCopy, sum, getUnique, getCorrectValue, downloadcsvwithTable,
    checkProperValue, convertToPercent, getCompsetMedian, divide,
    getHelperData, round, transformHotelForecastItem, transformMarketForecastItem, getCacheDataKey
    , calculateIdentityDiff
    , getSeriesById,
    calculateIdentityVariance
} from "../../app/util"
import { Button, Tooltip } from "@mui/material";
import TableToExcel from "@linways/table-to-excel";
import { DeblurRounded, TurnedIn } from '@mui/icons-material';
import dataService from "../../services/data.service";
import { APP_CACHE } from "../../app/config";
import _ from "lodash";


export function transFormForecastData(params) {
    const { stayDates, dayDate } = params;
    const calcIdentifiers = [];
    const diffIdentifiers = ['difference'];
    const start = moment().format('x');
    const transformed = [];

    const osrData = deepCopy(getSeriesById('OSR')?.data?.data); //settled.find(i => i.value && i.value.data_id === "MarketARISegmented");
    const forecastData = deepCopy(getSeriesById('OSRContrainedForecast')?.data?.data); //settled.find(i => i.value && i.value.data_id === "MarketForecastSegmented");
    const userForecastData = deepCopy(getSeriesById('ManualForecast')?.data?.data);
    const { Capacity } = osrData[0].HotelTotal.primary;
    const capacity = Capacity;

    let segmentCodes = [];
    Object.keys(osrData[0]).forEach(i => {
        let codes = i.split('Segment_');
        if (codes.length > 1) segmentCodes.push(codes[1]);
    });

    let ignoredKpis = ['Capacity', 'MedianSellRate', 'SellRate'];

    stayDates.forEach(date => {
        const osr = osrData.find(d => moment(d.index.date).format('YYYY-MM-DD') === date);
        const forecastDateData = (forecastData) ? forecastData.filter(d => moment(d.stay_date).format('YYYY-MM-DD') === date) : [];
        let userForecast = userForecastData.find(d => moment(d.index.date).format('YYYY-MM-DD') === date);
        userForecast = userForecast ? userForecast : { index: osr.index, total_forecast: { Occ: 0, ADR: 0, Rooms: 0, RevPAR: 0, Revenue: 0 } };
        userForecast.total_forecast.Occ = userForecast && userForecast.total_forecast && userForecast.total_forecast['Occ%'] ? userForecast?.total_forecast['Occ%'] : 0;

        const i = deepCopy(osr);
        const tmp = { index: i.index, HotelTotal: deepCopy(i.HotelTotal), segmented: [], total: {} };
        tmp.index.dow = moment(i.index.date).format('ddd');
        let mainIdentifiers = ['primary', 'forecast_cons', 'forecast_uncons'];

        //Handle in case forecast data is not available
        if (!forecastDateData.length) {
            segmentCodes.forEach((code) => {
                const forecast = deepCopy(i['Segment_' + code]?.primary);
                forecast.Occ = _.round(forecast.Occ * 100, 2);
                let segItem = deepCopy({ name: code, value: { primary: forecast, forecast_cons: forecast, forecast_uncons: forecast } });
                if (code !== 'IGNORED') {
                    tmp.segmented.push(segItem);
                }
            });
            // debugger;
        } else {
            // debugger;
            forecastDateData.forEach(item => {
                const code = item.hotel_segment;
                const forecastFormatedData = transformHotelForecastItem(item);
                let segItem = deepCopy({ name: code, value: { ...forecastFormatedData, primary: forecastFormatedData.forecast } });
                segItem.value.primary.Occ = _.round(segItem.value.primary.Occ * 100, 2);
                if (code !== 'IGNORED') {
                    tmp.segmented.push(segItem);
                }
            })
        }

        // debugger;
        const total = {};
        const identifiers = Object.keys(tmp.segmented[0].value);

        tmp.segmented.forEach((item, ind) => {
            identifiers.forEach(identifier => {
                total[identifier] = total[identifier] ? total[identifier] : {};

                [item.value[identifier]].forEach(function (o) {
                    if (o) {
                        let kpis = Object.keys(o);

                        kpis.forEach(kpi => {
                            if (identifier.indexOf('_variance_') === -1) {
                                if (ignoredKpis.includes(kpi)) {
                                    total[identifier][kpi] = o[kpi] ? o[kpi] : 0;
                                } else {
                                    let kpiVal = (isNaN(o[kpi]) && o[kpi] === 'N/A') ? 0 : o[kpi];
                                    kpiVal = getCorrectValue(kpiVal);
                                    total[identifier][kpi] = _.sum([total[identifier][kpi], Number(kpiVal)]);
                                }
                            }
                        })
                    }

                });

                total[identifier].Capacity = capacity;
                total[identifier].ADR = _.divide(total[identifier].Revenue, total[identifier].Rooms);
                total[identifier].RevPAR = _.divide(total[identifier].Revenue, capacity);
                total[identifier].SellRate = tmp.HotelTotal[identifier]?.SellRate;
            });
        });

        if (userForecast) {
            total.userforecastdata = deepCopy(userForecast.total_forecast);
        }
        diffIdentifiers.forEach(identifier => {
            let a = total.primary;
            let b = total.userforecastdata;
            total[identifier] = calculateIdentityDiff(a, b, { identifier });
        });

        tmp.total = deepCopy(total);
        tmp.total.capacity = capacity;
        tmp.segmented = tmp.segmented.sort((a, b) => b.value.primary.Rooms - a.value.primary.Rooms);
        transformed.push(tmp);
    });
    let end = moment().format('x');
    console.log('time taken in transformations: ', end - start, transformed);
    // settled.find(i => i && i.value && i.value.data_id === "OSR").value;
    return transformed;
}

export function transFormPickupData(params) {
    const { dayDate, pickUpType } = params;
    const calcIdentifiers = ['otb_difference', 'otb_sdly_diff'];
    const diffIdentifiers = ['otb_difference', 'otb_sdly_diff'];
    let start = moment().format('x');
    const transformed = [];
    let mainData = getSeriesById('OSR'); //settled.find(i => i.value && i.value.data_id === "MarketARISegmented");
    mainData = mainData?.data?.data;
    let segmentCodes = [];
    let segmentCodesPrefix = 'Segment_';
    let totalKey = 'HotelTotal';

    Object.keys(mainData[0]).forEach(key => {
        let codes = key.split(segmentCodesPrefix);
        if (codes.length > 1) segmentCodes.push(codes[1]);
    });
    let ignoredKpis = ['Capacity', 'MedianSellRate', 'SellRate'];
    mainData.forEach(ariData => {
        const i = deepCopy(ariData);
        let d = moment(i.index.date).format("YYYY-MM-DD");
        let tmp = { index: i.index, [totalKey]: deepCopy(i[totalKey]), segmented: [], total: {} };
        tmp.index.dow = moment(i.index.date).format('ddd');
        let mainIdentifiers = Object.keys(i[totalKey]);
        segmentCodes.forEach((code) => {
            let segItem = i[segmentCodesPrefix + code] ? { name: code, value: i[segmentCodesPrefix + code] } : { name: code, value: {} };
            mainIdentifiers.forEach(identifier => {
                segItem.value[identifier].Occ = _.round(segItem.value[identifier].Occ * 100, 2);
                let kpis = Object.keys(segItem.value[identifier]);
                kpis.forEach((kpi) => {
                    segItem.value[identifier][kpi] = getCorrectValue(segItem.value[identifier][kpi]);
                })
                segItem.value[identifier].SellRate = segItem.value[identifier].SellRate ? segItem.value[identifier].SellRate : 0;

            });
            calcIdentifiers.forEach((identifier) => {
                // const diffIdentifiers = ['otb_difference', 'otb_sdly_diff'];

                if (!segItem.value[identifier]) {
                    if (identifier === 'otb_difference') {
                        const a = (pickUpType === 'Standard') ? segItem.value.current : segItem.value.primary;
                        const b = (pickUpType === 'Standard') ? segItem.value.prior : segItem.value.pickup;
                        segItem.value[identifier] = calculateIdentityDiff(a, b, { identifier });
                    }

                    if (['otb_sdly_diff'].includes(identifier)) {
                        const a = (dayDate === 'day') ? segItem.value.sdly : segItem.value.sdtly;
                        const b = (dayDate === 'day') ? segItem.value.sdly_pickup : segItem.value.sdtly_pickup;
                        segItem.value[identifier] = calculateIdentityDiff(a, b, { identifier });
                    }
                    //Correcting after calculations
                    let kpis = Object.keys(segItem.value[identifier]);
                    kpis.forEach((kpi) => {
                        segItem.value[identifier][kpi] = getCorrectValue(segItem.value[identifier][kpi]);
                    })
                    segItem.value[identifier].SellRate = segItem.value[identifier].SellRate ? segItem.value[identifier].SellRate : 0;
                }
            });
            if (code !== 'IGNORED') {
                tmp.segmented.push(segItem);
            }
        });

        const total = {};
        let capacity = i[totalKey]?.primary?.Capacity;
        const identifiers = Object.keys(tmp.segmented[0].value);
        tmp.segmented.forEach(item => {
            let varianceIdentifiers = [];

            identifiers.forEach(identifier => {
                total[identifier] = total[identifier] ? total[identifier] : {};
                // item.value[identifier].Occ = _.round(item.value[identifier].Occ * 100, 2);
                _.sumBy([item.value[identifier]], function (o) {
                    if (o) {
                        let kpis = Object.keys(o);
                        kpis.forEach(kpi => {
                            if (identifier.indexOf('variance_') === -1) {
                                if (ignoredKpis.includes(kpi)) {
                                    total[identifier][kpi] = o[kpi] ? o[kpi] : 0;
                                } else {
                                    let kpiVal = (isNaN(o[kpi]) && o[kpi] === 'N/A') ? 0 : o[kpi];
                                    kpiVal = getCorrectValue(kpiVal);
                                    total[identifier][kpi] = _.sum([total[identifier][kpi], Number(kpiVal)]);
                                    total[identifier][kpi] = total[identifier][kpi] ? total[identifier][kpi] : 0;
                                }
                                total[identifier][kpi] = _.round(total[identifier][kpi], 2);
                            } else {
                                varianceIdentifiers.push(identifier);
                            }
                        })
                    }
                });
                //Correcting some KPIs
                // debugger;
                if (identifier.indexOf('variance_') === -1) {
                    total[identifier].ADR = _.divide(total[identifier].Revenue, total[identifier].Rooms);
                    total[identifier].ADR = total[identifier].ADR ? _.round(total[identifier].ADR, 2) : 0; //Correcting values if null/undefined
                    total[identifier].RevPAR = _.round(_.divide(total[identifier].Revenue, capacity), 2);
                }
                total[identifier].Capacity = capacity;
                total[identifier].SellRate = tmp[totalKey][identifier]?.SellRate;
                //Added to fix excel export issue #27
                total[identifier].SellRate = total[identifier].SellRate ? _.round(total[identifier].SellRate) : 0;
            });

            diffIdentifiers.forEach(dIdentifier => {
                let a = (dayDate === 'day') ? total.primary : total.primary;
                let b = (dayDate === 'day') ? total.sdly : total.sdtly;
                if (dIdentifier === "otb_difference") {
                    a = (pickUpType === 'Standard') ? total.current : total.primary;
                    b = (pickUpType === 'Standard') ? total.prior : total.pickup;
                }
                if (dIdentifier === 'otb_sdly_diff') {
                    a = (dayDate === 'day') ? total.sdly : total.sdtly;
                    b = (dayDate === 'day') ? total.sdly_pickup : total.sdtly_pickup;
                }
                total[dIdentifier] = calculateIdentityDiff(a, b, { identifier: dIdentifier });
            });

            varianceIdentifiers.forEach(vIdentifier => {
                let a = (dayDate === 'day') ? total.primary : total.primary;
                let b = (dayDate === 'day') ? total.sdly : total.sdtly;
                if (vIdentifier === "variance_sdly") {
                    a = total.primary;
                    b = (dayDate === 'day') ? total.sdly : total.sdtly;
                }
                total[vIdentifier] = calculateIdentityVariance(a, b, { identifier: vIdentifier });
            });

        });
        tmp.total = deepCopy(total);
        tmp.total.capacity = capacity;
        tmp.segmented = tmp.segmented.sort((a, b) => b.value.otb_difference.Rooms - a.value.otb_difference.Rooms);
        transformed.push(tmp);
    });
    let end = moment().format('x');
    // console.log('time taken in transformations Pickup: ', end - start, transformed);
    // debugger;
    return transformed;
}

export function transFormOtbData(params) {
    // debugger;
    const { dayDate } = params;
    const calcIdentifiers = ['difference_sdly', 'variance_sdly', 'pickup_diff_sdly'];
    const diffIdentifiers = ['difference_sdly', 'pickup_diff_sdly'];
    let start = moment().format('x');
    const transformed = [];
    let mainData = getSeriesById('OSR'); //settled.find(i => i.value && i.value.data_id === "MarketARISegmented");
    mainData = mainData?.data?.data;
    let segmentCodes = [];
    let segmentCodesPrefix = 'Segment_';
    let totalKey = 'HotelTotal';
    Object.keys(mainData[0]).forEach(key => {
        let codes = key.split(segmentCodesPrefix);
        if (codes.length > 1) segmentCodes.push(codes[1]);
    });
    let ignoredKpis = ['Capacity', 'MedianSellRate', 'SellRate'];
    mainData.forEach(ariData => {
        const i = deepCopy(ariData);
        let d = moment(i.index.date).format("YYYY-MM-DD");
        let tmp = { index: i.index, [totalKey]: deepCopy(i[totalKey]), segmented: [], total: {} };
        tmp.index.dow = moment(i.index.date).format('ddd');
        let mainIdentifiers = Object.keys(i[totalKey]);
        segmentCodes.forEach((code) => {
            let segItem = i[segmentCodesPrefix + code] ? { name: code, value: i[segmentCodesPrefix + code] } : { name: code, value: {} };
            mainIdentifiers.forEach(identifier => {
                // console.log(d," ",identifier," ",segmentCodesPrefix," ",code," Shubham ",segItem.value[identifier]);
                if(!segItem.value[identifier]){
                    segItem.value[identifier] =  {"ADR": 0, "Occ": 0, "RevPAR": 0, "Revenue": 0, "Rooms": 0, "Capacity": 0, "SellRate": 0}
                }
                segItem.value[identifier].Occ = (segItem.value[identifier])? _.round(segItem.value[identifier].Occ * 100, 2) : 0;

                //Correcting after calculations , added to fix #27
                let kpis = Object.keys(segItem.value[identifier]);
                kpis.forEach((kpi) => {
                    segItem.value[identifier][kpi] = _.round(getCorrectValue(segItem.value[identifier][kpi]), 2);
                })
                segItem.value[identifier].SellRate = segItem.value[identifier].SellRate ? segItem.value[identifier].SellRate : 0;
            });
            calcIdentifiers.forEach((identifier) => {
                if (!segItem.value[identifier]) {
                    if (identifier === 'difference_sdly') {
                        const a = segItem.value.primary;
                        const b = segItem.value.sdly;
                        segItem.value[identifier] = calculateIdentityDiff(a, b, { identifier })
                    }

                    if (['variance_sdly'].includes(identifier)) {
                        const a = segItem.value.primary;
                        const b = (dayDate === 'date' && segItem.value.sdtly) ? segItem.value.sdtly : segItem.value.sdly;
                        segItem.value[identifier] = calculateIdentityVariance(a, b, { identifier });
                        segItem.value[identifier].SellRate = segItem.value[identifier].SellRate ? segItem.value[identifier].SellRate : '0';
                    }
                    if (['pickup_diff_sdly'].includes(identifier)) {
                        const a = (dayDate === 'day') ? segItem.value.sdly_actual : segItem.value.sdtly_actual;
                        const b = (dayDate === 'day') ? segItem.value.sdly : segItem.value.sdtly;
                        segItem.value[identifier] = calculateIdentityDiff(a, b, { identifier })
                        segItem.value[identifier].ADR = _.divide(segItem.value[identifier].Revenue, segItem.value[identifier].Rooms);
                        segItem.value[identifier].ADR = getCorrectValue(segItem.value[identifier].ADR);
                    }
                    //Correcting after calculations , added to fix #27
                    segItem.value[identifier].SellRate = segItem.value[identifier].SellRate ? segItem.value[identifier].SellRate : 0;
                }
            });
            if (code !== 'IGNORED') {
                tmp.segmented.push(segItem);
            }
        });

        const total = {};
        let capacity = i[totalKey]?.primary?.Capacity;
        // debugger;
        let identifiers = Object.keys(tmp.segmented[0].value);
        // LRV-880 remove below line
        identifiers = identifiers.filter(element => !['sdtly', 'sdtly_actual', 'sdtly_pickup'].includes(element));
        tmp.segmented.forEach(item => {
            let varianceIdentifiers = [];
            identifiers.forEach(identifier => {
                total[identifier] = total[identifier] ? total[identifier] : {};
                // item.value[identifier].Occ = _.round(item.value[identifier].Occ * 100, 2);
                _.sumBy([item.value[identifier]], function (o) {
                    if (o) {
                        let kpis = Object.keys(o);
                        kpis.forEach(kpi => {
                            if (identifier.indexOf('variance_') === -1) {
                                if (ignoredKpis.includes(kpi)) {
                                    total[identifier][kpi] = o[kpi] ? o[kpi] : 0;
                                } else {
                                    let kpiVal = (isNaN(o[kpi]) && o[kpi] === 'N/A') ? 0 : o[kpi];
                                    kpiVal = getCorrectValue(kpiVal);
                                    total[identifier][kpi] = _.sum([total[identifier][kpi], Number(kpiVal)]);
                                }
                            } else {
                                varianceIdentifiers.push(identifier);
                            }
                        })
                    }
                });
                //Correcting some KPIs
                // debugger;
                if (identifier.indexOf('variance_') === -1) {
                    total[identifier].ADR = _.divide(total[identifier].Revenue, total[identifier].Rooms);
                    total[identifier].ADR = getCorrectValue(total[identifier].ADR); 
                    total[identifier].RevPAR = _.divide(total[identifier].Revenue, capacity);
                }
                total[identifier].Capacity = capacity;
                total[identifier].SellRate = tmp[totalKey][identifier]?.SellRate;
                total[identifier].SellRate = total[identifier].SellRate ? total[identifier].SellRate : 0;
            });

            diffIdentifiers.forEach(dIdentifier => {
                let a = (dayDate === 'day') ? total.primary : total.primary;
                let b = (dayDate === 'day') ? total.sdly : total.sdtly;
                if (dIdentifier === "pickup_diff_sdly") {
                    a = (dayDate === 'day') ? total.sdly_actual : total.sdtly_actual;
                    b = (dayDate === 'day') ? total.sdly : total.sdtly;
                }
                total[dIdentifier] = calculateIdentityDiff(a, b, { identifier: dIdentifier });

                if (dIdentifier === "pickup_diff_sdly") {
                    total[dIdentifier].ADR = _.divide(total[dIdentifier].Revenue, total[dIdentifier].Rooms);
                    total[dIdentifier].ADR = getCorrectValue(total[dIdentifier].ADR);
                    // total[dIdentifier].RevPAR = _.divide(total[identifier].Revenue, capacity);
                }
            });

            // diffIdentifiers.forEach(dIdentifier => {
            //     if (dIdentifier === "pickup_diff_sdly") {
            //         total[dIdentifier].ADR = _.divide(total[identifier].Revenue, total[identifier].Rooms);
            //         total[dIdentifier].RevPAR = _.divide(total[identifier].Revenue, capacity);
            //     }
            // });

            varianceIdentifiers.forEach(vIdentifier => {
                let a = (dayDate === 'day') ? total.primary : total.primary;
                let b = (dayDate === 'day') ? total.sdly : total.sdtly;
                if (vIdentifier === "variance_sdly") {
                    a = total.primary;
                    b = (dayDate === 'day') ? total.sdly : total.sdtly;
                }
                total[vIdentifier] = calculateIdentityVariance(a, b, { identifier: vIdentifier });
            });

        });
        tmp.total = deepCopy(total);
        tmp.total.capacity = capacity;
        tmp.segmented = tmp.segmented.sort((a, b) => b.value.primary.Rooms - a.value.primary.Rooms);
        transformed.push(tmp);
    });
    let end = moment().format('x');
    console.log('time taken in transformations OTB: ', end - start, transformed);
    return transformed;
}

export function transFormMarketData(params) {
    const { dayDate } = params;
    const calcIdentifiers = [
        'marketotbpickup', 'sdly_diff_sdowly',
        'sdly_variance_sdowly', 'sdly_diff_market_last_year_actuals',
        'sdly_variance_market_last_year_actuals'
    ];
    const diffIdentifiers = ['marketotbpickup', 'sdly_diff_sdowly', 'sdly_diff_market_last_year_actuals'];
    let start = moment().format('x');
    const transformed = [];
    let marketARI = deepCopy(getSeriesById('MarketARISegmented')); //settled.find(i => i.value && i.value.data_id === "MarketARISegmented");
    let marketForecast = deepCopy(getSeriesById('MarketForecastSegmented')); //settled.find(i => i.value && i.value.data_id === "MarketForecastSegmented");

    marketForecast = marketForecast?.data?.data;
    marketARI = marketARI?.data?.data;
    let segmentCodes = [];
    if (marketARI) {
        Object.keys(marketARI[0]).forEach(i => {
            let codes = i.split('MarketSegment_');
            if (codes.length > 1) segmentCodes.push(codes[1]);
        });
        let ignoredKpis = ['Capacity', 'MedianSellRate', 'SellRate'];
        marketARI.forEach(ariData => {
            const i = deepCopy(ariData);
            const mData = deepCopy(ariData);
            let d = moment(i.index.date).format("YYYY-MM-DD");
            let forecast = (marketForecast) ? marketForecast.filter(f => f.stay_date === d) : [];
            let tmp = { index: i.index, MarketTotal: deepCopy(i.MarketTotal), segmented: [], total: {} };
            tmp.index.dow = moment(i.index.date).format('ddd');
            let mainIdentifiers = Object.keys(i.MarketTotal);
            segmentCodes.forEach((code) => {
                let segItem = { name: code, value: i['MarketSegment_' + code] };
                if (!forecast.length) {
                    segItem.value.market_projected = deepCopy(segItem.value.primary);
                }
                if (forecast.length) {
                    let forecastItem = forecast.find(di => di.stay_date === d && di.market_segment === segItem.name)
                    segItem.value.market_projected = transformMarketForecastItem(forecastItem).forecast;
                }
                segItem.value.market_projected.Occ = _.round(segItem.value.market_projected.Occ * 100, 2);
                mainIdentifiers.forEach(identifier => {
                    segItem.value[identifier].Occ = _.round(segItem.value[identifier].Occ * 100, 2);
                });
                calcIdentifiers.forEach((identifier) => {
                    if (!segItem.value[identifier]) {
                        if (identifier === 'marketotbpickup') {
                            const a = segItem.value.primary;
                            const b = segItem.value.pickup;
                            segItem.value[identifier] = calculateIdentityDiff(a, b, { identifier })
                        }

                        if (['sdly_diff_sdowly', 'sdly_variance_sdowly'].includes(identifier)) {
                            const a = segItem.value.primary;
                            const b = (dayDate === 'day' && segItem.value.sdtly) ? segItem.value.sdtly : segItem.value.sdly;
                            if (identifier === 'sdly_diff_sdowly') {
                                segItem.value[identifier] = calculateIdentityDiff(a, b, { identifier })
                            } else {
                                segItem.value[identifier] = calculateIdentityVariance(a, b, { identifier })
                            }
                        }
                        if (['sdly_diff_market_last_year_actuals', 'sdly_variance_market_last_year_actuals'].includes(identifier)) {
                            const a = (dayDate === 'day') ? segItem.value.sdly : segItem.value.sdtly;;
                            const b = (dayDate === 'day') ? segItem.value.sdly_actual : segItem.value.sdtly_actual;
                            if (identifier === 'sdly_diff_market_last_year_actuals') {
                                segItem.value[identifier] = calculateIdentityDiff(a, b, { identifier });
                            } else {
                                segItem.value[identifier] = calculateIdentityVariance(a, b, { identifier });
                            }
                        }
                    }
                });

                tmp.segmented.push(segItem);
            });

            const total = {};
            let capacity = i.MarketTotal?.primary?.Capacity;
            const identifiers = Object.keys(tmp.segmented[0].value);

            tmp.segmented.forEach(item => {
                let varianceIdentifiers = [];
                identifiers.forEach(identifier => {
                    total[identifier] = total[identifier] ? total[identifier] : {};
                    // item.value[identifier].Occ = _.round(item.value[identifier].Occ * 100, 2);
                    _.sumBy([item.value[identifier]], function (o) {
                        if (o) {
                            let kpis = Object.keys(o);
                            kpis.forEach(kpi => {
                                if (identifier.indexOf('_variance_') === -1) {
                                    if (ignoredKpis.includes(kpi)) {
                                        total[identifier][kpi] = o[kpi] ? _.round(o[kpi]) : 0;
                                    } else {
                                        let kpiVal = (isNaN(o[kpi]) && o[kpi] === 'N/A') ? 0 : o[kpi];
                                        kpiVal = getCorrectValue(kpiVal);
                                        total[identifier][kpi] = _.round(_.sum([total[identifier][kpi], Number(kpiVal)]), 2);
                                    }
                                } else {
                                    varianceIdentifiers.push(identifier);
                                }
                            })
                        }
                    });

                    if (identifier.indexOf('_variance_') === -1 && identifier.indexOf('_diff_') === -1) {
                        total[identifier].ADR = _.divide(total[identifier].Revenue, total[identifier].Rooms);
                        //total[identifier].RevPAR = _.divide(total[identifier].Revenue, capacity);
                    }
                    total[identifier].Capacity = capacity;
                    total[identifier].SellRate = tmp.MarketTotal[identifier]?.SellRate;
                    total[identifier].SellRate = total[identifier].SellRate ? _.round(total[identifier].SellRate) : 0;
                });

                diffIdentifiers.forEach(dIdentifier => {
                    let a = total.primary;
                    let b = total.pickup;
                    if (dIdentifier === 'sdly_diff_sdowly') {
                        a = total.primary;
                        b = (dayDate === 'date' && total.sdtly) ? total.sdtly : total.sdly;
                    }
                    if (dIdentifier === 'sdly_diff_market_last_year_actuals') {
                        a = (dayDate === 'date' && total.sdtly) ? total.sdtly : total.sdly;
                        b = (dayDate === 'date' && total.sdtly_actual) ? total.sdtly_actual : total.sdly_actual;
                    }

                    total[dIdentifier] = calculateIdentityDiff(a, b, { identifier: dIdentifier });
                    if (dIdentifier === 'marketotbpickup') {
                        total[dIdentifier].SellRate = mData.MarketTotal.primary?.SellRate - mData.MarketTotal.pickup?.SellRate;
                        total[dIdentifier].SellRate = _.round(total[dIdentifier].SellRate);
                    }
                    if (dIdentifier === 'sdly_diff_sdowly') {
                        total[dIdentifier].SellRate = mData.MarketTotal.primary?.SellRate - mData.MarketTotal.sdly?.SellRate;
                        total[dIdentifier].SellRate = _.round(total[dIdentifier].SellRate);
                    }
                    
                });

                varianceIdentifiers.forEach(vIdentifier => {
                    let a = (dayDate === 'day') ? total.primary : total.primary;
                    let b = (dayDate === 'day') ? total.sdly : total.sdtly;
                    if (vIdentifier === "sdly_variance_market_last_year_actuals") {
                        a = (dayDate === 'day') ? total.sdly : total.sdtly;
                        b = (dayDate === 'day') ? total.sdly_actual : total.sdtly_actual;
                    }
                    total[vIdentifier] = calculateIdentityVariance(a, b, { identifier: vIdentifier });
                });
            });

            tmp.total = deepCopy(total);
            tmp.total.capacity = capacity;
            transformed.push(tmp);
        });
        let end = moment().format('x');
    }
    return transformed;
}

export function getTotals(data = [], meta) {
    const { type } = meta;
    let totalRow = {};
    let capacity = 0;
    let totalCapacity = 0;

    data.forEach((item, index) => {
        item.forEach((kpi, ind) => {
            totalRow[kpi.id] = totalRow[kpi.id] ? totalRow[kpi.id] : {};

            if (kpi.id !== "staydates") {
                let kpis = Object.keys(kpi);

                if (kpi.RevPAR > 0) {
                    if (!capacity) {
                        capacity = Math.round(kpi.Revenue / kpi.RevPAR);
                        totalCapacity = capacity;
                    }
                }

                kpis.forEach(kpiKey => {
                    if (kpiKey !== 'id') {
                        totalRow[kpi.id][kpiKey] = totalRow[kpi.id][kpiKey] ? totalRow[kpi.id][kpiKey] : 0;
                        kpi[kpiKey] = (kpi[kpiKey]) ? kpi[kpiKey] : 0;
                        totalRow[kpi.id][kpiKey] += kpi[kpiKey];
                        // if (isNaN(totalRow[kpi.id][kpiKey])) {
                        //     totalRow[kpi.id][kpiKey] = 0;
                        // }
                    }
                    if (['compdate', 'id'].includes(kpiKey)) {
                        totalRow[kpi.id][kpiKey] = kpi[kpiKey];
                    }
                });

                totalRow[kpi.id].ADR = totalRow[kpi.id].Revenue / totalRow[kpi.id].Rooms;
                totalRow[kpi.id].Occ = convertToPercent(totalRow[kpi.id].Rooms / totalCapacity);
                totalRow[kpi.id].RevPAR = totalRow[kpi.id].Revenue / totalCapacity;

                // totalRow[kpi.id] = deepCopy(kpisTotal);                  

            } else if (kpi.id === "staydates") {
                totalRow[kpi.id] = deepCopy(kpi);
                totalRow[kpi.id].segment = "Total";

            }
        });
        if (type === 'Total') {
            if (capacity > 0) {
                totalCapacity += capacity;
            }
            totalRow.staydates.date = '';
            totalRow.staydates.dow = '';
        }
    });

    let rowArr = [];
    for (let k in totalRow) {
        // totalRow[k].id = k;
        rowArr.push(totalRow[k]);
    }
    return rowArr;
}

/*
- This function is used to generalize data into simple format so that 
- filter and sorting operations can be done easily in optimized way.
- This will support forecast data format coming from DS API
*/
export function generalizeForecastData(params) {
    const { data, capacity, filters } = params;
    let start = moment().format("x");
    let blankKpis = { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0, RevPAR: 0 };
    const filteredHotelSegments = ['all'];
    const filteredMarketSegments = ['all'];
    const totals = { grand: {}, segments: {}, capacity: 0 };
    let segmentSortidentifier = 'primary';
    const dates = [];
    const hotelForecastKeys = {};

    let transformed = [];
    data.map((item, i) => {
        let date = item.stay_date;
        let keys = Object.keys(item);
        let totalKey = keys.includes('market_segment') ? 'MarketTotal' : 'HotelTotal';

        let dateItemObj = {
            index: { date: date },
            segmented: [],
            total: {}
        }

        if (!dates.includes(date)) {
            dates.push(date);
            let sameDateData = data.filter(item => item.stay_date === date);
            totals.capacity += item.capacity;

            //Transforeming for Market forecast
            if (keys.includes('market_segment')) {

                //setting up total
                totals.grand.primary = totals.grand.primary ? totals.grand.primary : deepCopy(blankKpis);
                totals.grand.forecast_cons = totals.grand.forecast_cons ? totals.grand.forecast_cons : deepCopy(blankKpis);
                totals.grand.forecast_uncons = totals.grand.forecast_uncons ? totals.grand.forecast_uncons : deepCopy(blankKpis);

                dateItemObj[totalKey] = dateItemObj[totalKey] ? dateItemObj[totalKey] : { primary: deepCopy(blankKpis), forecast_cons: deepCopy(blankKpis), forecast_uncons: deepCopy(blankKpis) };
                dateItemObj.total.primary = deepCopy(blankKpis);
                dateItemObj.total.forecast_cons = deepCopy(blankKpis);
                dateItemObj.total.forecast_uncons = deepCopy(blankKpis);


                //setting MarketTotal for forecast
                keys.map((key) => {
                    if (key.indexOf('predicted_') > -1) {
                        let k = key.split('_');
                        if (k[1] === 'total' && k[2] === 'cons') {
                            for (let kpi in dateItemObj[totalKey].primary) {

                                dateItemObj[totalKey].primary[kpi] = item[k[0] + '_' + k[1] + '_' + k[2] + "_" + kpi.toLocaleLowerCase()];
                                dateItemObj[totalKey].forecast_cons[kpi] = item[k[0] + '_' + k[1] + '_' + k[2] + "_" + kpi.toLocaleLowerCase()];
                            }
                        }
                        if (k[1] === 'total' && k[2] === 'uncons') {
                            for (let kpi in dateItemObj[totalKey].primary) {
                                dateItemObj[totalKey].forecast_uncons[kpi] = item[k[0] + '_' + k[1] + '_' + k[2] + "_" + kpi.toLocaleLowerCase()];
                            }
                        }
                    }
                });

                sameDateData.map((item, i) => {
                    let segmentCode = item.market_segment;
                    if (filteredHotelSegments.length && (filteredHotelSegments.includes(segmentCode) || filteredHotelSegments.includes('all'))) {
                        // dateItemObj.total = dateItemObj.total ? dateItemObj.total : { forecast: deepCopy(blankKpis), forecast_cons: deepCopy(blankKpis), forecast_uncons: deepCopy(blankKpis) };
                        let segmentObj = { name: segmentCode, value: {} };
                        segmentObj.value.primary = deepCopy(blankKpis);
                        segmentObj.value.forecast_cons = deepCopy(blankKpis);
                        segmentObj.value.forecast_uncons = deepCopy(blankKpis);

                        totals.segments[segmentCode] = totals.segments[segmentCode] ? totals.segments[segmentCode] : {};
                        totals.segments[segmentCode].primary = totals.segments[segmentCode].primary ? totals.segments[segmentCode].primary : deepCopy(blankKpis);
                        totals.segments[segmentCode].forecast_cons = totals.segments[segmentCode].forecast_cons ? totals.segments[segmentCode].forecast_cons : deepCopy(blankKpis);
                        totals.segments[segmentCode].forecast_uncons = totals.segments[segmentCode].forecast_uncons ? totals.segments[segmentCode].forecast_uncons : deepCopy(blankKpis);

                        if (keys.indexOf('predicted_cons_rooms') > -1) {
                            let k = "predicted_cons_rooms".split('_');
                            if (k[1] === 'cons') {
                                for (let kpi in segmentObj.value.primary) {
                                    //Key will be lik predicted_cons_adr
                                    segmentObj.value.primary[kpi] = item[k[0] + '_' + k[1] + '_' + kpi.toLocaleLowerCase()];
                                    segmentObj.value.forecast_cons[kpi] = item[k[0] + '_' + k[1] + '_' + kpi.toLocaleLowerCase()];

                                    // if(kpi=='Rooms') debugger;
                                    dateItemObj.total.primary[kpi] += segmentObj.value.primary[kpi]
                                    dateItemObj.total.forecast_cons[kpi] += segmentObj.value.forecast_cons[kpi];

                                    totals.segments[segmentCode].primary[kpi] += segmentObj.value.primary[kpi];
                                    totals.segments[segmentCode].forecast_cons[kpi] += segmentObj.value.forecast_cons[kpi];

                                    totals.grand.primary[kpi] += segmentObj.value.primary[kpi];
                                    totals.grand.forecast_cons[kpi] += segmentObj.value.forecast_cons[kpi];
                                }
                                //Recalculating ADR
                                dateItemObj.total.primary.ADR = dateItemObj.total.primary.Revenue / dateItemObj.total.primary.Rooms;
                                dateItemObj.total.forecast_cons.ADR = dateItemObj.total.forecast_cons.Revenue / dateItemObj.total.forecast_cons.Rooms;

                                totals.segments[segmentCode].primary.ADR = totals.segments[segmentCode].primary.Revenue / totals.segments[segmentCode].primary.Rooms;
                                totals.segments[segmentCode].forecast_cons.ADR = totals.segments[segmentCode].forecast_cons.Revenue / totals.segments[segmentCode].forecast_cons.Rooms;

                                totals.grand.primary.ADR = totals.grand.primary.Revenue / totals.grand.primary.Rooms;
                                totals.grand.forecast_cons.ADR = totals.grand.forecast_cons.Revenue / totals.grand.forecast_cons.Rooms;

                                //Recalculating RevPAR
                                totals.grand.primary.RevPAR = totals.grand.primary.Revenue / totals.capacity;
                                totals.grand.forecast_cons.RevPAR = totals.grand.forecast_cons.Revenue / totals.capacity;

                                //Recalculating Occ 
                                totals.grand.primary.Occ = totals.grand.primary.Rooms / totals.capacity;
                                totals.grand.forecast_cons.Occ = totals.grand.forecast_cons.Rooms / totals.capacity;
                                totals.segments[segmentCode].primary.Occ = totals.segments[segmentCode].primary.Rooms / totals.capacity;
                                totals.segments[segmentCode].forecast_cons.Occ = totals.segments[segmentCode].forecast_cons.Rooms / totals.capacity;
                            }
                        }

                        if (keys.indexOf('predicted_uncons_rooms') > -1) {
                            let k = "predicted_uncons_rooms".split('_');
                            if (k[1] === 'uncons') {
                                for (let kpi in segmentObj.value.primary) {
                                    //Key will be like predicted_cons_adr
                                    segmentObj.value.forecast_uncons[kpi] = item[k[0] + '_' + k[1] + '_' + kpi.toLocaleLowerCase()];

                                    dateItemObj.total.forecast_uncons[kpi] += item[k[0] + '_' + k[1] + '_' + kpi.toLocaleLowerCase()];

                                    totals.grand.forecast_uncons[kpi] += dateItemObj.total.forecast_uncons[kpi];

                                    totals.segments[segmentCode].forecast_uncons[kpi] += segmentObj.value.forecast_uncons[kpi];
                                }
                                //Recalculating ADR
                                dateItemObj.total.forecast_uncons.ADR = dateItemObj.total.forecast_uncons.Revenue / dateItemObj.total.forecast_uncons.Rooms;
                                totals.segments[segmentCode].forecast_uncons.ADR = totals.segments[segmentCode].forecast_uncons.Revenue / totals.segments[segmentCode].forecast_uncons.Rooms;
                                totals.grand.forecast_uncons.ADR = totals.grand.forecast_uncons.Revenue / totals.grand.forecast_uncons.Rooms;

                                //Recalculating RevPAR
                                totals.grand.forecast_uncons.RevPAR = totals.grand.primary.Revenue / totals.capacity;

                                //Recalculating Occ 
                                totals.grand.forecast_uncons.Occ = totals.grand.forecast_uncons.Rooms / totals.capacity;
                                totals.segments[segmentCode].forecast_uncons.Occ = totals.segments[segmentCode].forecast_uncons.Rooms / totals.capacity;
                                totals.segments[segmentCode].forecast_uncons.Occ = totals.segments[segmentCode].forecast_uncons.Rooms / totals.capacity;
                            }
                        }

                        dateItemObj.segmented.push(segmentObj);
                        // dateItemObj.total
                    }
                });
                dateItemObj.segmented.sort((a, b) => b.value[segmentSortidentifier].Rooms - a.value[segmentSortidentifier].Rooms);
                transformed.push(dateItemObj);
            }

            //Transforeming for Hotel forecast
            if (keys.includes('hotel_segment')) {
                //setting up total
                totals.grand.primary = totals.grand.primary ? totals.grand.primary : deepCopy(blankKpis);
                totals.grand.forecast_cons = totals.grand.forecast_cons ? totals.grand.forecast_cons : deepCopy(blankKpis);
                totals.grand.forecast_uncons = totals.grand.forecast_uncons ? totals.grand.forecast_uncons : deepCopy(blankKpis);

                dateItemObj[totalKey] = dateItemObj[totalKey] ? dateItemObj[totalKey] : { primary: deepCopy(blankKpis), forecast_cons: deepCopy(blankKpis), forecast_uncons: deepCopy(blankKpis) };
                dateItemObj.total.primary = deepCopy(blankKpis);
                dateItemObj.total.forecast_cons = deepCopy(blankKpis);
                dateItemObj.total.forecast_uncons = deepCopy(blankKpis);


                //setting HotelTotal for forecast
                keys.map((key) => {
                    if (key.indexOf('predicted_') > -1) {
                        let k = key.split('_');
                        if (k[1] === 'total' && k[2] === 'cons') {
                            for (let kpi in dateItemObj[totalKey].primary) {

                                dateItemObj[totalKey].primary[kpi] = item[k[0] + '_' + k[1] + '_' + k[2] + "_" + kpi.toLocaleLowerCase()];
                                dateItemObj[totalKey].forecast_cons[kpi] = item[k[0] + '_' + k[1] + '_' + k[2] + "_" + kpi.toLocaleLowerCase()];
                            }
                        }
                        if (k[1] === 'total' && k[2] === 'uncons') {
                            for (let kpi in dateItemObj[totalKey].primary) {
                                dateItemObj[totalKey].forecast_uncons[kpi] = item[k[0] + '_' + k[1] + '_' + k[2] + "_" + kpi.toLocaleLowerCase()];
                            }
                        }
                    }
                });

                sameDateData.map((item, i) => {
                    let segmentCode = item.market_code;
                    if (filteredHotelSegments.length && (filteredHotelSegments.includes(segmentCode) || filteredHotelSegments.includes('all'))) {
                        // dateItemObj.total = dateItemObj.total ? dateItemObj.total : { forecast: deepCopy(blankKpis), forecast_cons: deepCopy(blankKpis), forecast_uncons: deepCopy(blankKpis) };
                        let segmentObj = { name: segmentCode, value: {} };
                        segmentObj.value.primary = deepCopy(blankKpis);
                        segmentObj.value.forecast_cons = deepCopy(blankKpis);
                        segmentObj.value.forecast_uncons = deepCopy(blankKpis);

                        totals.segments[segmentCode] = totals.segments[segmentCode] ? totals.segments[segmentCode] : {};
                        totals.segments[segmentCode].primary = totals.segments[segmentCode].primary ? totals.segments[segmentCode].primary : deepCopy(blankKpis);
                        totals.segments[segmentCode].forecast_cons = totals.segments[segmentCode].forecast_cons ? totals.segments[segmentCode].forecast_cons : deepCopy(blankKpis);
                        totals.segments[segmentCode].forecast_uncons = totals.segments[segmentCode].forecast_uncons ? totals.segments[segmentCode].forecast_uncons : deepCopy(blankKpis);

                        if (keys.indexOf('predicted_cons_rooms') > -1) {
                            let k = "predicted_cons_rooms".split('_');
                            if (k[1] === 'cons') {
                                for (let kpi in segmentObj.value.primary) {
                                    //Key will be lik predicted_cons_adr
                                    segmentObj.value.primary[kpi] = item[k[0] + '_' + k[1] + '_' + kpi.toLocaleLowerCase()];
                                    segmentObj.value.forecast_cons[kpi] = item[k[0] + '_' + k[1] + '_' + kpi.toLocaleLowerCase()];

                                    // if(kpi=='Rooms') debugger;
                                    dateItemObj.total.primary[kpi] += segmentObj.value.primary[kpi]
                                    dateItemObj.total.forecast_cons[kpi] += segmentObj.value.forecast_cons[kpi];

                                    totals.segments[segmentCode].primary[kpi] += segmentObj.value.primary[kpi];
                                    totals.segments[segmentCode].forecast_cons[kpi] += segmentObj.value.forecast_cons[kpi];

                                    totals.grand.primary[kpi] += segmentObj.value.primary[kpi];
                                    totals.grand.forecast_cons[kpi] += segmentObj.value.forecast_cons[kpi];
                                }
                                //Recalculating ADR
                                dateItemObj.total.primary.ADR = dateItemObj.total.primary.Revenue / dateItemObj.total.primary.Rooms;
                                dateItemObj.total.forecast_cons.ADR = dateItemObj.total.forecast_cons.Revenue / dateItemObj.total.forecast_cons.Rooms;

                                totals.segments[segmentCode].primary.ADR = totals.segments[segmentCode].primary.Revenue / totals.segments[segmentCode].primary.Rooms;
                                totals.segments[segmentCode].forecast_cons.ADR = totals.segments[segmentCode].forecast_cons.Revenue / totals.segments[segmentCode].forecast_cons.Rooms;

                                totals.grand.primary.ADR = totals.grand.primary.Revenue / totals.grand.primary.Rooms;
                                totals.grand.forecast_cons.ADR = totals.grand.forecast_cons.Revenue / totals.grand.forecast_cons.Rooms;

                                //Recalculating RevPAR
                                totals.grand.primary.RevPAR = totals.grand.primary.Revenue / totals.capacity;
                                totals.grand.forecast_cons.RevPAR = totals.grand.forecast_cons.Revenue / totals.capacity;

                                //Recalculating Occ 
                                totals.grand.primary.Occ = totals.grand.primary.Rooms / totals.capacity;
                                totals.grand.forecast_cons.Occ = totals.grand.forecast_cons.Rooms / totals.capacity;
                                totals.segments[segmentCode].primary.Occ = totals.segments[segmentCode].primary.Rooms / totals.capacity;
                                totals.segments[segmentCode].forecast_cons.Occ = totals.segments[segmentCode].forecast_cons.Rooms / totals.capacity;
                            }
                        }

                        if (keys.indexOf('predicted_uncons_rooms') > -1) {
                            let k = "predicted_uncons_rooms".split('_');
                            if (k[1] === 'uncons') {
                                for (let kpi in segmentObj.value.primary) {
                                    //Key will be like predicted_cons_adr
                                    segmentObj.value.forecast_uncons[kpi] = item[k[0] + '_' + k[1] + '_' + kpi.toLocaleLowerCase()];

                                    dateItemObj.total.forecast_uncons[kpi] += item[k[0] + '_' + k[1] + '_' + kpi.toLocaleLowerCase()];

                                    totals.grand.forecast_uncons[kpi] += dateItemObj.total.forecast_uncons[kpi];

                                    totals.segments[segmentCode].forecast_uncons[kpi] += segmentObj.value.forecast_uncons[kpi];
                                }
                                //Recalculating ADR
                                dateItemObj.total.forecast_uncons.ADR = dateItemObj.total.forecast_uncons.Revenue / dateItemObj.total.forecast_uncons.Rooms;
                                totals.segments[segmentCode].forecast_uncons.ADR = totals.segments[segmentCode].forecast_uncons.Revenue / totals.segments[segmentCode].forecast_uncons.Rooms;
                                totals.grand.forecast_uncons.ADR = totals.grand.forecast_uncons.Revenue / totals.grand.forecast_uncons.Rooms;

                                //Recalculating RevPAR
                                totals.grand.forecast_uncons.RevPAR = totals.grand.primary.Revenue / totals.capacity;

                                //Recalculating Occ 
                                totals.grand.forecast_uncons.Occ = totals.grand.forecast_uncons.Rooms / totals.capacity;
                                totals.segments[segmentCode].forecast_uncons.Occ = totals.segments[segmentCode].forecast_uncons.Rooms / totals.capacity;
                                totals.segments[segmentCode].forecast_uncons.Occ = totals.segments[segmentCode].forecast_uncons.Rooms / totals.capacity;
                            }
                        }

                        dateItemObj.segmented.push(segmentObj);
                        // dateItemObj.total
                    }
                });
                dateItemObj.segmented.sort((a, b) => b.value[segmentSortidentifier].Rooms - a.value[segmentSortidentifier].Rooms);
                transformed.push(dateItemObj);
            }
        }
    });

    return {
        totals,
        data: transformed
    }
    console.log("time taken:", moment().format("x") - start, totals, transformed);
}

/*
- This function is used to generalize data into simple format so that 
- filter and sorting operations can be done easily in optimized way.
- This may not support all data coming from API but most of them should work like OSR, Market and 
- data coming for report sections.
*/
export function generalizeData(params) {
    const apc = APP_CACHE;

    const { data, capacity, filters, columns, data_id, segments, workspace_controls, stayDates, helperData, tile, primary_stay_range } = params;
    let blankKpis = { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0, RevPAR: 0 };

    // if(apc[apc.cache_keys.general]) return apc[apc.cache_keys.general];

    //Coollecting CODES of Other segments
    let segment_others = [];
    let other_codes = [];
    let Other_segments = segments.list.filter(item => {
        if (item.name === 'Other') {
            if (!other_codes.includes(item.code)) {
                other_codes.push(item.code);
            }
            return true;
        }
    });
    segments.list.map((item) => { if (item.name === "Other" && filters.segments.includes('Other')) segment_others.push(item.code) });

    //Getting the columns mainKeys
    const mainKeys = [];
    const columnDetails = [];
    columns.map((column) => {
        if (column.type === "dataColumn") {
            columnDetails.push(column);
            mainKeys.push(column.tileName + '->' + column.id + '->' + column.mainKey);
        }
    });
    //End

    let compset_median_col = columns.find((col) => col.id === "compset_medium");
    let market_sell_rates = columns.find((col) => col.tileName === "market_sell_rates");

    let identifiers = getUnique(mainKeys);

    let start = moment().format("x");

    // const filteredSegments = ['GASC', 'TBAR', 'TFIT'];
    const totals = { grand: {}, segments: {}, capacity: 0 };
    let ignoreIdentities = [];
    let [tileName, columnId, identity] = identifiers[0].split('->');
    let segmentSortidentifier = identity;
    let transformed = [];
    let datesDba = workspace_controls.scopeType === 'Date' ? stayDates : [-1].concat(Array.from(Array(Number(workspace_controls.dba)).keys()));
    datesDba = (workspace_controls.scopeType !== 'Date' && datesDba) ? datesDba.slice(0, data.length - 1) : datesDba;
    const date_dba = workspace_controls.scopeType.toLocaleLowerCase();

    if (data && !data.length) {
        return { data: [], totals: {} }
    }
    if (data && data.length) {
        datesDba.map((d, i) => {
            let original_item = data.filter(item => {
                let item_date = '';
                let index = item.index || item.Index;
                if (!item.stay_date && index) {
                    item_date = index[date_dba];
                    if (date_dba === 'date' && item_date && moment(item_date).format('YYYY-MM-DD') === d) {
                        return true;
                    } else if (item_date === d) {
                        return true;
                    }
                }
                if (item.stay_date) {
                    item_date = item.stay_date;
                }
            });

            let helperDataItems = [];
            if (helperData && date_dba !== 'dba') {
                helperDataItems = helperData.filter(item => item.stay_date && moment(item.stay_date).isSame(d, 'day'));
            }
            if (original_item.length && original_item.length === 1) {
                if (helperDataItems.length === 1) {

                }
                //Futures data
                if (!helperDataItems.length) {
                    if (tile === 'market') {
                        let keys = Object.keys(original_item[0]);
                        keys.forEach((key) => {
                            if (key !== 'index') {
                                original_item[0][key].market_projected = deepCopy(original_item[0][key].primary);
                            }
                        });
                    }
                }
                if (helperDataItems.length > 1) {
                    const forcastMarketCodes = helperDataItems.map((item) => item.market_code);
                    if (tile !== 'market') {
                        original_item[0] = { HotelTotal: original_item[0].HotelTotal, index: original_item[0].index };
                    }
                    forcastMarketCodes.forEach((code) => {
                        original_item[0]['Segment_' + code] = original_item[0]['Segment_' + code] ? original_item[0]['Segment_' + code] : {};
                    });
                    helperDataItems.forEach((item) => {
                        let tmp = {};
                        if (tile === 'forecast') {
                            let segmentCodeKey = 'market_code';
                            let segmentCode = item[segmentCodeKey];
                            tmp['Segment_' + segmentCode] = transformHotelForecastItem(item);
                            tmp['Segment_' + segmentCode] = tmp['Segment_' + segmentCode] ? tmp['Segment_' + segmentCode] : {};
                            tmp['Segment_' + segmentCode].primary = deepCopy(tmp['Segment_' + segmentCode].forecast);
                            original_item[0]['Segment_' + segmentCode] = {};
                            original_item[0]['Segment_' + segmentCode].primary = deepCopy(tmp['Segment_' + segmentCode].forecast);
                            if (workspace_controls.unconstrained) {
                                original_item[0]['Segment_' + segmentCode].primary = deepCopy(tmp['Segment_' + segmentCode].forecast_uncons);
                            }

                            // tmp['HotelTotal'] = deepCopy(tmp['Segment_' + segmentCode].forecast);
                        }

                        if (tile === 'market') {
                            original_item[0].index = original_item[0].Index || original_item[0].index;
                            let segmentCodeKey = 'market_segment';
                            let segmentCode = item[segmentCodeKey];
                            tmp['MarketSegment_' + segmentCode] = transformMarketForecastItem(item);
                            original_item[0]['MarketSegment_' + segmentCode].market_projected = deepCopy(tmp['MarketSegment_' + segmentCode].forecast);
                            let marketProjectedTotal = deepCopy(tmp['MarketSegment_' + segmentCode].forecast);
                            original_item[0]['MarketTotal'].market_projected = correctNaValues(marketProjectedTotal);

                            if (workspace_controls.unconstrained) {
                                original_item[0]['MarketSegment_' + segmentCode].market_projected = deepCopy(tmp['MarketSegment_' + segmentCode].forecast_uncons);
                            }
                        }
                    });
                }
                original_item = original_item[0];
            }

            let totalKey = Object.keys(original_item).filter((key) => key.indexOf('Total') > -1);
            totalKey = totalKey.length ? totalKey[0] : null;
            let segmented = [];
            let total = {};
            let item = {};

            //Aggregating all segment code mapped to Other 
            let segmentKeys = Object.keys(original_item);
            let keysStr = segmentKeys.join(' ');
            let hasSegmented = keysStr.indexOf('Segment_') > -1;
            if (segments && segments.arrUnique && segments.arrUnique.length) {
                segmentKeys.map((key) => {
                    let keys = key.split('Segment_');
                    let segmentSplit = key.split('Segment_');
                    let segmentSplitter = segmentSplit[1] ? segmentSplit[0] + 'Segment_' : 'Segment_';
                    let segmentCode = key.split(segmentSplitter)[1];

                    if (segmentCode && (other_codes.includes(segmentCode) || !segments.map[segmentCode])) {
                        item['Segment_Other'] = item['Segment_Other'] ? item['Segment_Other'] : {};
                        let identifiers = Object.keys(original_item[totalKey]);
                        identifiers.map((identity, i) => {
                            // const [tileName, columnId, identity] = unique_identity.split('->');
                            item['Segment_Other'][identity] = item['Segment_Other'][identity] ? item['Segment_Other'][identity] : {};
                            if (original_item['Segment_' + segmentCode][identity]) {
                                let kpis = Object.keys(original_item['Segment_' + segmentCode][identity]);
                                kpis.map((kpi) => {
                                    if (kpi !== 'date') {
                                        // if(identity=='primary' && kpi=='Rooms' && original_item['Segment_' + segmentCode][identity][kpi]>0){ let t = original_item['Segment_' + segmentCode][identity][kpi]; debugger}
                                        item['Segment_Other'][identity][kpi] = sum(checkProperValue(item['Segment_Other'][identity][kpi]), Number(original_item['Segment_' + segmentCode][identity][kpi]), 4);
                                    } else {
                                        item['Segment_Other'][identity][kpi] = original_item['Segment_' + segmentCode][identity][kpi];
                                    }
                                });
                                // segmentCode = segment_others.includes(segmentCode) ? 'Other' : segmentCode;
                            }
                        });
                    } else if (segmentCode && !other_codes.includes(segmentCode) && segments.marketSegments[segmentCode]) {
                        item['Segment_' + segmentCode] = original_item['Segment_' + segmentCode];
                    } else {
                        item[keys[0]] = original_item[keys[0]];
                    }
                });
            }
            //End of aggregation

            if (original_item[totalKey] && original_item[totalKey].primary && original_item[totalKey].primary.Revenue) {
                if (!total.capacity) {
                    total.capacity = Math.round(original_item[totalKey].primary.Revenue / original_item[totalKey].primary.RevPAR);
                }
                totals.capacity += total.capacity;
            }
            //  else {
            //     total.capacity = 0;
            //     totals.capacity = 0;
            // }

            // Adding Other as segment; 
            if (!filters.segments.includes('Other')) filters.segments.push('Other');

            //Looping through the properties of the data items

            if (!Object.keys(item).length) {
                item = original_item;
                item.index = original_item.index || original_item.Index;
            }
            let keys = Object.keys(item);
            // let keysStr = keys.join(' ');
            // let hasSegmented = keysStr.indexOf('Segment_') > -1;
            //Getting date or dba

            let date = date_dba === 'date' && item.index ? item.index.date : null;
            let dba = date_dba === 'dba' ? item.index.dba : null;
            if (item.stay_date) date = item.stay_date;
            // if (!item.stay_date) { }
            keys.map((key) => {
                let segmentSplit = key.split('Segment_');
                let segmentSplitter = segmentSplit[1] ? segmentSplit[0] + 'Segment_' : 'Segment_';
                let segmentCode = key.split(segmentSplitter)[1];

                identifiers.map((unique_identity, i) => {
                    const [tileName, columnId, identity] = unique_identity.split('->');
                    //Putting this identity for main calculations ignore list                  
                    if (original_item[totalKey] && !original_item[totalKey][identity] && !ignoreIdentities.includes(identity)) ignoreIdentities.push(identity);
                });

                //Only do this for segments type properties of the data items
                if (hasSegmented && segmentCode && filters && filters.segments && (filters.segments.includes('all') || filters.segments.includes(segmentCode))) {
                    let tmp = {};
                    totals.segments[segmentCode] = totals.segments[segmentCode] ? totals.segments[segmentCode] : {};
                    tmp.name = segmentCode;
                    tmp.value = deepCopy(item[key]);

                    //snippet for calculating segmented grand totals
                    let allidentifiers = Object.keys(tmp.value);
                    //debugger;
                    allidentifiers.map((identity, i) => {
                        if (tmp.value[identity] && !ignoreIdentities.includes(identity)) {
                            // let totalsSegmentsIdentity = totalsSegments[segmentCode] ? totalsSegments[segmentCode] : {};
                            totals.segments[segmentCode][identity] = totals.segments[segmentCode][identity] ? totals.segments[segmentCode][identity] : {};
                            let kpis = Object.keys(tmp.value[identity]);
                            kpis.map((kpi) => {
                                if (kpi === 'Occ') {
                                    tmp.value[identity][kpi] = convertToPercent(tmp.value[identity][kpi]);
                                }
                                tmp.value[identity][kpi] = Number(tmp.value[identity][kpi]);
                                let kpi_val = tmp.value[identity][kpi];

                                totals.segments[segmentCode][identity][kpi] = totals.segments[segmentCode][identity][kpi] ? totals.segments[segmentCode][identity][kpi] : 0;

                                if (!['date', 'ADR'].includes(kpi)) {
                                    totals.segments[segmentCode][identity][kpi] = sum(checkProperValue(totals.segments[segmentCode][identity][kpi]), Number(kpi_val), 4);
                                }
                                //Do for pace comparison date

                                if (kpi === 'date') {
                                    tmp.value[identity].compdate = item[key][identity][kpi];
                                    delete tmp.value[identity].date;
                                }
                            });
                            //Calculating ADR, becasue ADR, Occ, RevPAR calculation formula is Revenue/Room sold
                            totals.segments[segmentCode][identity].ADR = !isNaN(totals.segments[segmentCode][identity].Revenue / totals.segments[segmentCode][identity].Rooms) ? totals.segments[segmentCode][identity].Revenue / totals.segments[segmentCode][identity].Rooms : 0;
                            totals.segments[segmentCode][identity].Occ = !isNaN(totals.segments[segmentCode][identity].Rooms / totals.capacity) ? totals.segments[segmentCode][identity].Rooms / totals.capacity : 0;
                            totals.segments[segmentCode][identity].Occ = convertToPercent(totals.segments[segmentCode][identity].Occ);
                        }
                    });

                    ignoreIdentities.map((identity, i) => {
                        // const [tileName, columnId, identity] = unique_identity.split('->');
                        //Do this for custom calculations from table-config
                        totals.segments[segmentCode][identity] = totals.segments[segmentCode][identity] ? totals.segments[segmentCode][identity] : deepCopy(blankKpis);;

                        let columnDetail = columnDetails.find((col) => col.mainKey === identity);
                        let columnDetailCalculate = columnDetail.calculate;
                        let columnDetailColcId = columnDetail.colc_id;
                        tmp.value[identity] = deepCopy(blankKpis);
                        if (columnDetail && columnDetailCalculate) {
                            if (columnDetailColcId && columnDetailColcId !== data_id) {
                                let helperData = getHelperData(columnDetailColcId)();

                                if (helperData && helperData.data && helperData.data.length) {
                                    let filteredData = [];
                                    if (helperData.data[0].index) {
                                        if (date) {
                                            filteredData = helperData.data.filter((d) => moment(d.index.date).isSame(date, 'day'))
                                        }
                                        if (dba) {
                                            filteredData = helperData.data.filter((d) => d.index.dba === dba)
                                        }
                                    }
                                    //This is for forecast data only
                                    if (helperData.data[0].stay_date) {
                                        if (date) {
                                            filteredData = helperData.data.filter((d) => moment(d.stay_date).isSame(date, 'day'))
                                        }
                                        if (dba) {
                                            filteredData = helperData.data.filter((d) => d.dba === dba)
                                        }
                                    }

                                    tmp.value[identity] = 0;//columnDetailCalculate(deepCopy(filteredData), workspace_controls, { original_item, date, dba, identity, segmentCode });
                                    // tmp.value[identity] = {};


                                    // if(!tmp.value[identity]) debugger;
                                    let kpis = Object.keys(tmp.value[identity]);
                                    kpis.map((kpi) => {
                                        let kpi_val = tmp.value[identity][kpi];
                                        if (!['date', 'ADR'].includes(kpi)) {
                                            totals.segments[segmentCode][identity][kpi] = sum(checkProperValue(totals.segments[segmentCode][identity][kpi]), Number(kpi_val), 4);
                                        }
                                        if (kpi === 'Occ') {
                                            tmp.value[identity][kpi] = convertToPercent(tmp.value[identity][kpi]);
                                        }
                                    });

                                    totals.segments[segmentCode][identity].ADR = !isNaN(totals.segments[segmentCode][identity].Revenue / totals.segments[segmentCode][identity].Rooms) ? totals.segments[segmentCode][identity].Revenue / totals.segments[segmentCode][identity].Rooms : 0;
                                    totals.segments[segmentCode][identity].Occ = !isNaN(totals.segments[segmentCode][identity].Rooms / totals.capacity) ? totals.segments[segmentCode][identity].Rooms / totals.capacity : 0;
                                    totals.segments[segmentCode][identity].Occ = convertToPercent(totals.segments[segmentCode][identity].Occ);
                                }

                            } else {

                                tmp.value[identity] = columnDetailCalculate(deepCopy(tmp.value), workspace_controls, { original_item, date, dba, identity, segmentCode });
                                // totals.segments[segmentCode][identity] = columnDetail.calculate(deepCopy(totals.segments[segmentCode]), workspace_controls, { original_item, date, dba, identity, segmentCode });
                            }
                        }
                    });

                    segmented.push(tmp);
                }

                //Handle in case of no segment mode
                if (!hasSegmented && totalKey) {
                    let tmp = {};
                    totals.segments[segmentCode] = totals.segments[segmentCode] ? totals.segments[segmentCode] : {};
                    // total = item[totalKey];

                    let allidentifiers = Object.keys(item[totalKey]);
                    allidentifiers.map((identity, i) => {
                        // const [tileName, columnId, identity] = unique_identity.split('->');
                        total[identity] = total[identity] ? total[identity] : {};
                        totals.grand[identity] = totals.grand[identity] ? totals.grand[identity] : {};

                        if (!ignoreIdentities.includes(identity)) {
                            let kpis = Object.keys(item[totalKey][identity]);
                            kpis.map((kpi) => {
                                totals.grand[identity][kpi] = totals.grand[identity][kpi] ? totals.grand[identity][kpi] : 0;
                                total[identity][kpi] = total[identity][kpi] ? total[identity][kpi] : 0;
                                let kpi_val = Number(item[totalKey][identity][kpi]);
                                // total.capacity = capacity;

                                if (!['compdate', 'ADR'].includes(kpi)) {
                                    total[identity][kpi] = kpi_val;
                                    totals.grand[identity][kpi] = sum(totals.grand[identity][kpi], Number(kpi_val), 13);
                                }
                                if (kpi === 'Occ') {
                                    total[identity][kpi] = convertToPercent(total[identity][kpi]);
                                }
                                //Do for pace comparison date
                                if (kpi === 'compdate') {

                                    total[identity].compdate = item.value[identity].compdate;
                                    // delete total[identity].date;
                                }
                            });

                            //Calculating ADR, Occ and RevPAR for grand total
                            total[identity].ADR = !isNaN(total[identity].Revenue / total[identity].Rooms) ? total[identity].Revenue / total[identity].Rooms : 0;

                            totals.grand[identity].ADR = !isNaN(totals.grand[identity].Revenue / totals.grand[identity].Rooms) ? totals.grand[identity].Revenue / totals.grand[identity].Rooms : 0;
                            totals.grand[identity].Occ = !isNaN(totals.grand[identity].Rooms / totals.capacity) ? totals.grand[identity].Rooms / totals.capacity : 0;
                            totals.grand[identity].RevPAR = !isNaN(totals.grand[identity].Revenue / totals.capacity) ? totals.grand[identity].Revenue / totals.capacity : 0;
                        }
                    });

                    ignoreIdentities.map((identity, i) => {
                        // const [tileName, columnId, identity] = unique_identity.split('->');
                        total[identity] = total[identity] ? total[identity] : {};
                        totals.grand[identity] = totals.grand[identity] ? totals.grand[identity] : {};

                        //Add calculation based identity
                        let columnDetail = columnDetails.find((col) => col.mainKey === identity);

                        if (columnDetail && columnDetail.calculate) {
                            if (columnDetail.colc_id && columnDetail.colc_id !== data_id) {
                                let helperData = getHelperData(columnDetail.colc_id)();

                                if (helperData && helperData.data && helperData.data.length) {
                                    let filteredData = [];
                                    if (helperData.data[0].index) {
                                        if (date) {
                                            filteredData = helperData.data.filter((d) => moment(d.index.date).isSame(date, 'day'))
                                        }
                                        if (dba) {
                                            filteredData = helperData.data.filter((d) => d.index.dba === dba)
                                        }
                                    }
                                    //This is for forecast data only
                                    if (helperData.data[0].stay_date) {
                                        if (date) {
                                            filteredData = helperData.data.filter((d) => moment(d.stay_date).isSame(date, 'day'))
                                        }
                                        if (dba) {
                                            filteredData = helperData.data.filter((d) => d.dba === dba)
                                        }
                                    }

                                    if (filteredData.length) {
                                        total[identity] = columnDetail.calculate(deepCopy(filteredData), workspace_controls, { original_item, date, dba, identity });
                                    }

                                    let kpis = Object.keys(total[identity]);
                                    kpis.map((kpi) => {
                                        let kpi_val = Number(total[identity][kpi]);
                                        if (!['date', 'ADR'].includes(kpi)) {
                                            totals.grand[identity][kpi] = sum(checkProperValue(totals.grand[identity][kpi]), Number(kpi_val), 4);
                                        }
                                        if (kpi === 'Occ') {
                                            total[identity][kpi] = convertToPercent(total[identity][kpi]);
                                        }
                                    });
                                    // debugger
                                    totals.grand[identity].ADR = !isNaN(totals.grand[identity].Revenue / totals.grand[identity].Rooms) ? totals.grand[identity].Revenue / totals.grand[identity].Rooms : 0;
                                    totals.grand[identity].Occ = !isNaN(totals.grand[identity].Rooms / totals.capacity) ? totals.grand[identity].Rooms / totals.capacity : 0;
                                    totals.grand[identity].Occ = convertToPercent(totals.grand[identity].Occ);
                                }
                            } else {

                                total[identity] = columnDetail.calculate(deepCopy(total), workspace_controls, { original_item, date, dba, identity });
                                totals.grand[identity] = columnDetail.calculate(deepCopy(totals.grand), workspace_controls, { original_item, date, dba, identity });
                            }
                        }

                    });
                }
            });

            //Calculating total of segments
            // debugger
            segmented.map((item, index) => {

                let allidentifiers = Object.keys(item.value);
                allidentifiers.map((identity, i) => {
                    // const [tileName, columnId, identity] = unique_identity.split('->');
                    total[identity] = total[identity] ? total[identity] : {};
                    totals.grand[identity] = totals.grand[identity] ? totals.grand[identity] : {};

                    if (!ignoreIdentities.includes(identity)) {
                        let kpis = Object.keys(item.value[identity]);
                        kpis.map((kpi) => {
                            totals.grand[identity][kpi] = totals.grand[identity][kpi] ? totals.grand[identity][kpi] : 0;
                            total[identity][kpi] = total[identity][kpi] ? total[identity][kpi] : 0;

                            if (!['compdate', 'ADR'].includes(kpi)) {
                                total[identity][kpi] = sum(total[identity][kpi], Number(item.value[identity][kpi]), 13);
                                totals.grand[identity][kpi] = sum(totals.grand[identity][kpi], Number(item.value[identity][kpi]), 13);
                            }
                            //Do for pace comparison date
                            if (kpi === 'compdate') {

                                total[identity].compdate = item.value[identity].compdate;
                                // delete total[identity].date;
                            }
                        });

                        //Calculating ADR, Occ and RevPAR for total

                        total[identity].ADR = !isNaN(total[identity].Revenue / total[identity].Rooms) ? total[identity].Revenue / total[identity].Rooms : 0;

                        //Adding SellRates if available in HotelTotal/MarketTotal
                        total[identity].SellRate = original_item[totalKey] && original_item[totalKey][identity] && original_item[totalKey][identity].SellRate ? original_item[totalKey][identity].SellRate : 0;
                        //Doing this as sellrate is not available in segmented mode
                        if (totalKey === 'MarketTotal') {
                            let helperData = getHelperData('MarketARISegmented')().data;
                            let filteredData = helperData.find(item => moment(item.index.date).isSame(date, 'day'))
                            // total[identity].SellRate = filteredData ? filteredData[totalKey].primary.SellRate : 0;
                            total[identity].SellRate = (filteredData && filteredData[totalKey] && filteredData[totalKey][identity]) ? filteredData[totalKey][identity].SellRate : 0;

                        }

                        //Calculating ADR, Occ and RevPAR for grand total
                        totals.grand[identity].ADR = !isNaN(totals.grand[identity].Revenue / totals.grand[identity].Rooms) ? totals.grand[identity].Revenue / totals.grand[identity].Rooms : 0;
                        totals.grand[identity].Occ = !isNaN(totals.grand[identity].Rooms / totals.capacity) ? totals.grand[identity].Rooms / totals.capacity : 0;
                        totals.grand[identity].RevPAR = !isNaN(totals.grand[identity].Revenue / totals.capacity) ? totals.grand[identity].Revenue / totals.capacity : 0;
                    }
                });

                ignoreIdentities.map((identity, i) => {
                    // const [tileName, columnId, identity] = unique_identity.split('->');
                    total[identity] = total[identity] ? total[identity] : {};
                    totals.grand[identity] = totals.grand[identity] ? totals.grand[identity] : {};

                    //Add calculation based identity

                    let columnDetail = columnDetails.find((col) => col.mainKey === identity);
                    let subcol_SellRate = columnDetail.subcolumns.find((sub) => sub.value === 'SellRate');
                    // item.value[identity] = deepCopy(blankKpis);
                    if (columnDetail && columnDetail.calculate) {
                        // item.value[identity] = columnDetail.calculate(deepCopy(item.value), workspace_controls);



                        if (columnDetail.colc_id && columnDetail.colc_id !== data_id) {
                            let kpis = Object.keys(item.value[identity]);

                            kpis.map((kpi) => {
                                totals.grand[identity][kpi] = totals.grand[identity][kpi] ? totals.grand[identity][kpi] : 0;
                                total[identity][kpi] = total[identity][kpi] ? total[identity][kpi] : 0;
                                // total.capacity = capacity;

                                if (!['compdate', 'ADR'].includes(kpi)) {
                                    total[identity][kpi] = sum(total[identity][kpi], Number(item.value[identity][kpi]), 13);
                                    totals.grand[identity][kpi] = sum(totals.grand[identity][kpi], Number(item.value[identity][kpi]), 13);
                                }
                                //Do for pace comparison date
                                if (kpi === 'compdate') {

                                    total[identity].compdate = item.value[identity].compdate;
                                    // delete total[identity].date;
                                }
                            });

                            let ADR = total[identity].Revenue / total[identity].Rooms;
                            let SellRate = total.primary ? total.primary.SellRate : 0;
                            total[identity].ADR = isNaN(ADR) ? 0 : ADR;
                            if (subcol_SellRate) total[identity].SellRate = isNaN(SellRate) ? 0 : SellRate;
                        } else {
                            total[identity] = columnDetail.calculate(deepCopy(total), workspace_controls, { original_item, date, dba, identity });
                            // totals.grand[identity] = columnDetail.calculate(deepCopy(totals.grand), workspace_controls, { original_item, date, dba, identity });
                        }
                    }

                });
            });

            //Do for compset data
            if (compset_median_col) {
                total[compset_median_col.mainKey] = {};
                let compSellRates = [];
                compset_median_col.subcolumns.map((subcol) => {
                    if (subcol.value !== 'CM') {
                        total[compset_median_col.mainKey][subcol.value] = (original_item && original_item[subcol.value]) ? original_item[subcol.value] : 0;
                        let sellRate_val = (original_item && original_item[subcol.value]) ? original_item[subcol.value].sell_rate : 0;
                        compSellRates.push(sellRate_val);
                    }
                });
                total[compset_median_col.mainKey]['CM'] = getCompsetMedian(compSellRates);
            }
            //Do for market_sell_rates data
            if (market_sell_rates) {
                // total[market_sell_rates.mainKey] = {};
                columns.map((col) => {

                    if (col.type !== "dateColumn") {
                        total[col.mainKey] = total[col.mainKey] ? total[col.mainKey] : {};
                        total[col.mainKey][col.k] = {};
                        col.subcolumns.map((subcol) => {
                            total[col.mainKey][col.k][subcol.value] = original_item[col.mainKey][col.k] ? original_item[col.mainKey][col.k][subcol.value] : 0;
                        });
                    }

                });
            }

            //Returning item object
            // debugger
            segmented.sort((a, b) => {
                let B = b.value[segmentSortidentifier] ? b.value[segmentSortidentifier].Rooms : 0;
                let A = a.value[segmentSortidentifier] ? a.value[segmentSortidentifier].Rooms : 0;
                return B - A;
            })
            const finalObject = {
                index: deepCopy(item.index),
                segmented: segmented,
                // HotelTotal: item.HotelTotal,
                total: total
            };

            //Adding total key like 'HotelTotal' or MarketTotal

            if (totalKey && item[totalKey]) {
                finalObject[totalKey] = item[totalKey];
                if (tile === 'forecast' && item[totalKey].userforecastdata) {
                    finalObject.total.userforecastdata = item[totalKey].userforecastdata;
                    finalObject.segmented.filter(({ value }, index) => {
                        // debugger
                        if (value.userforecastdata) {
                            finalObject.total.difference = finalObject.segmented[index].value.difference;
                        }
                        finalObject.segmented[index].value.userforecastdata = { ADR: 0, Rooms: 0, Occ: 0, Revenue: 0, RevPAR: 0, SellRate: 0 };
                        finalObject.segmented[index].value.difference = { ADR: 0, Rooms: 0, Occ: 0, Revenue: 0, RevPAR: 0, SellRate: 0 };
                    })
                }
            }
            if (date_dba === 'date') {
                if (item.index && item.index[date_dba]) finalObject.index.dow = new moment(item.index[date_dba]).format('ddd');

                if (filters && filters.dow && finalObject.index && finalObject.index.dow && (filters.dow.includes('all') || filters.dow.includes(finalObject.index.dow.toLocaleLowerCase()))) {
                    transformed.push(finalObject);
                }
            }

            if (workspace_controls.scopeType === 'Dba') {
                transformed.push(finalObject);
            }
        });
    }
    //Adding segmented grand total

    if (workspace_controls.segmented_view) {
        let segmtsKeys = Object.keys(totals.segments);
        let segmentedGrandTotal = { index: { date: '', dow: '' }, segmented: [], total: totals.grand };
        if (date_dba === 'dba') {
            segmentedGrandTotal.index.dba = '';
            delete segmentedGrandTotal.index.date;
            delete segmentedGrandTotal.index.dow;
        }
        segmtsKeys.map((item, i) => segmentedGrandTotal.segmented.push({ name: item, value: totals.segments[item] }));
        // segmentedGrandTotal.segmented.sort((a, b) => (b.value[segmentSortidentifier].Rooms - a.value[segmentSortidentifier].Rooms));
        segmentedGrandTotal.segmented.sort((a, b) => {
            if ((b.value[segmentSortidentifier] && b.value[segmentSortidentifier].Rooms) && (a.value[segmentSortidentifier] && a.value[segmentSortidentifier].Rooms)) {
                return (b.value[segmentSortidentifier].Rooms - a.value[segmentSortidentifier].Rooms)
            }
        });
        transformed.push(segmentedGrandTotal);
    }
    apc[apc.cache_keys.general] = {
        totals,
        data: transformed
    };
    return {
        totals,
        data: transformed
    }
    console.log("time taken:", moment().format("x") - start, totals, transformed);
}

export function getFlightSegments(segmentType) {
    const segments = {
        arrUnique: [],
        list: [],
        map: {},
        marketSegments: {}
    };
    if (segmentType === 'total') {
        return segments;
    } else {
        if (segmentType === 'paxProfile') {
            segments.arrUnique = ['Business', 'Leisure', 'Group', 'VFR + Expats']
            segments.list = [{ name: 'Business', code: 'business' },
            { name: 'Leisure', code: 'leisure' },
            { name: 'Group', code: 'group' }, { name: 'VFR + Expats', code: 'vfr' }]
            segments.map = {
                business: "Business",
                leisure: 'Leisure',
                group: 'group',
                vfr: 'VFR + Expats'
            }
        }
        if (segmentType === 'leadTime') {
            segments.arrUnique = ['0 to 4 days', '5 to 14 days', '15 to 29 days', '30 to 44 days',
                '45 to 59 days', '60 to 89 days', '90 to 119 days', '120 days or more']
            segments.list = [{ name: '0 to 4 days', code: 'leadTime1' }, { name: '5 to 14 days', code: 'leadTime2' },
            { name: '15 to 29 days', code: 'leadTime3' }, { name: '30 to 44 days', code: 'leadTime4' },
            { name: '45 to 59 days', code: 'leadTime5' }, { name: '60 to 89 days', code: 'leadTime6' },
            { name: '90 to 119 days', code: 'leadTime7' }, { name: '120 days or more', code: 'leadTime8' }
            ]
            segments.map = {

            }
        }
        if (segmentType === 'channel') {
            segments.arrUnique = ['Retail TA', 'Online TA', 'Corporate TA', 'Other TA', 'Airline Direct']
            segments.list = [{ name: 'Retail TA', code: 'channelRetail' }, { name: 'Online TA', code: 'channelOTA' },
            { name: 'Corporate TA', code: 'channelCorpo' }, { name: 'Other TA', code: 'channelSpecial' },
            { name: 'Airline Direct', code: 'channelDirect' },
            ]
            segments.map = {

            }
        }
        if (segmentType === 'paxPerBooking') {
            segments.arrUnique = ['1 pax', '2 pax', '3 pax', '4 pax', '5 pax', '6 to 9 pax', '10 or more pax',]
            segments.list = [{ name: '1 pax', code: 'ppb1' }, { name: '2 pax', code: 'ppb2' },
            { name: '3 pax', code: 'ppb3' }, { name: '4 pax', code: 'ppb4' },
            { name: '5 pax', code: 'ppb5' }, { name: '6 to 9 pax', code: 'ppb6' },
            { name: '10 or more pax', code: 'ppb7' },
            ]
            segments.map = {

            }
        }
        if (segmentType === 'lengthOfStay') {
            segments.arrUnique = ['1 night', '2 nights', '3 nights', '4 to 5 nights',
                '6 to 8 nights', '9 to 13 nights', '14 to 21 nights', '21 nights or more']
            segments.list = [{ name: '1 night', code: 'lengthOfStay1' }, { name: '2 nights', code: 'lengthOfStay2' },
            { name: '3 nights', code: 'lengthOfStay3' }, { name: '4 to 5 nights', code: 'lengthOfStay4' },
            { name: '6 to 8 nights', code: 'lengthOfStay5' }, { name: '9 to 13 nights', code: 'lengthOfStay6' },
            { name: '14 to 21 nights', code: 'lengthOfStay7' }, { name: '21 nights or more', code: 'lengthOfStay8' }
            ]
            segments.map = {

            }
        }
        return segments;
    }

}
// this function transforms flightsdata
export function transformFlightsdata(transformedData, workspace_controls) {
    let segments = [];
    let flightsArr = [];

    if (workspace_controls.selected_airport_codes.length > 0) {

        if (transformedData[workspace_controls.selected_airport_codes[0]]) {
            flightsArr = getFlightsSegmentation(workspace_controls.segmentType, transformedData[workspace_controls.selected_airport_codes[0]])
        }

        workspace_controls.selected_airport_codes.forEach((key, index) => {
            if (transformedData[key]) {
                let airCodeArr = getFlightsSegmentation(workspace_controls.segmentType, transformedData[key]);

                airCodeArr.forEach(airCodeItem => {
                    let date = airCodeItem.date ? airCodeItem.date : airCodeItem.index.date;

                    let flightindex = flightsArr.findIndex(i => {
                        if (i.index && i.index.date && i.index.date === date) {
                            return true;
                        }
                        if (i.date && i.date === date) {
                            return true;
                        }
                        return false;
                    });
                    let currentFlight = flightsArr.find(i => {
                        if (i.index && i.index.date && i.index.date === date) {
                            return true;
                        }
                        if (i.date && i.date === date) {
                            return true;
                        }
                        return false;
                    });
                    let flightArrayItem = flightsArr[flightindex] ? Object.keys(flightsArr[flightindex]) : [];

                    flightArrayItem.forEach(flightKey => {
                        if (workspace_controls.segmentType !== 'total' && flightKey !== 'index') {

                            let currentSegmentation = currentFlight[flightKey];
                            Object.keys(currentSegmentation).forEach(seg_flight_key => {
                                if (seg_flight_key !== 'type' && !["index", "total", "arrival_total_sdly_variance", "arrival_dom_sdly_variance", "arrival_intl_sdly_variance",
                                    "stay_total_sdly_variance", "stay_per_day_sdly_variance"].includes(seg_flight_key) && index > 0) {
                                    flightsArr[flightindex][flightKey][seg_flight_key] += airCodeItem[flightKey][seg_flight_key]
                                }

                                if (seg_flight_key.indexOf('_variance') > -1) {
                                    let differenceKey = seg_flight_key.replace('_variance', '_difference');
                                    let totalSdlyKey = seg_flight_key.replace('_variance', '');
                                    flightsArr[flightindex][flightKey][seg_flight_key] = (currentSegmentation[differenceKey] / currentSegmentation[totalSdlyKey]) * 100;
                                }

                                //let divider = 0;
                                // if (seg_flight_key === "arrival_total_sdly_variance") {
                                //     //divider = flightsArr[flightindex].arrival_total ? flightsArr[flightindex].arrival_total : flightsArr[flightindex][flightKey].arrival_total;
                                //     flightsArr[flightindex][flightKey][seg_flight_key] = (currentSegmentation.arrival_total_sdly_difference / currentSegmentation.arrival_total_sdly) * 100;
                                //     debugger
                                // }
                                // if (seg_flight_key === "arrival_dom_sdly_variance") {
                                //     //divider = flightsArr[flightindex].arrival_dom ? flightsArr[flightindex].arrival_dom : flightsArr[flightindex][flightKey].arrival_dom;
                                //     flightsArr[flightindex][flightKey][seg_flight_key] = (currentSegmentation.arrival_dom_sdly_difference / currentSegmentation.arrival_dom_sdly) * 100;

                                // }
                                // if (seg_flight_key === "arrival_intl_sdly_variance") {
                                //     //divider = flightsArr[flightindex].arrival_intl ? flightsArr[flightindex].arrival_intl : flightsArr[flightindex][flightKey].arrival_intl;
                                //     flightsArr[flightindex][flightKey][seg_flight_key] = (currentSegmentation.arrival_intl_sdly_difference / currentSegmentation.arrival_intl_sdly) * 100;
                                // }
                                // if (seg_flight_key === "stay_total_sdly_variance") {
                                //     //divider = flightsArr[flightindex].stay_total ? flightsArr[flightindex].stay_total : flightsArr[flightindex][flightKey].stay_total;
                                //     flightsArr[flightindex][flightKey][seg_flight_key] = (currentSegmentation.stay_total_sdly_difference / currentSegmentation.stay_total_sdly) * 100;
                                // }
                                // if (seg_flight_key === "stay_per_day_sdly_variance") {
                                //     //divider = flightsArr[flightindex].stay_per_day ? flightsArr[flightindex].stay_per_day : flightsArr[flightindex][flightKey].stay_per_day;
                                //     flightsArr[flightindex][flightKey][seg_flight_key] = (currentSegmentation.stay_per_day_sdly_difference / currentSegmentation.stay_per_day_sdly) * 100;
                                // }
                                if (!isFinite(flightsArr[flightindex][flightKey][seg_flight_key])) flightsArr[flightindex][flightKey][seg_flight_key] = 0;

                            })
                        } else {
                            if (flightKey !== "date" &&
                                !["index", "total", "arrival_total_sdly_variance", "arrival_dom_sdly_variance", "arrival_intl_sdly_variance",
                                    "stay_total_sdly_variance", "stay_per_day_sdly_variance"].includes(flightKey) && index > 0) {
                                flightsArr[flightindex][flightKey] += airCodeItem[flightKey]
                            }

                            if (flightKey.indexOf('_variance') > -1) {
                                let differenceKey = flightKey.replace('_variance', '_difference');
                                let totalSdlyKey = flightKey.replace('_variance', '');
                                flightsArr[flightindex][flightKey] = (flightsArr[flightindex][differenceKey] / flightsArr[flightindex][totalSdlyKey]) * 100;
                            }

                            //let divider = 0;
                            // if (flightKey === "arrival_total_sdly_variance") {
                            //     //divider = flightsArr[flightindex].arrival_total ? flightsArr[flightindex].arrival_total : flightsArr[flightindex][flightKey].arrival_total;
                            //     flightsArr[flightindex][flightKey] = (flightsArr[flightindex].arrival_total_sdly_difference / flightsArr[flightindex].arrival_total_sdly) * 100;
                            //     //flightsArr[flightindex][flightKey]=0;
                            //     debugger
                            // }
                            // if (flightKey === "arrival_dom_sdly_variance") {
                            //     //divider = flightsArr[flightindex].arrival_dom ? flightsArr[flightindex].arrival_dom : flightsArr[flightindex][flightKey].arrival_dom;
                            //     flightsArr[flightindex][flightKey] = (flightsArr[flightindex].arrival_dom_sdly_difference / flightsArr[flightindex].arrival_dom_sdly) * 100;
                            // }
                            // if (flightKey === "arrival_intl_sdly_variance") {
                            //     //divider = flightsArr[flightindex].arrival_intl;
                            //     flightsArr[flightindex][flightKey] = (flightsArr[flightindex].arrival_intl_sdly_difference / flightsArr[flightindex].arrival_intl_sdly) * 100;;
                            // }
                            // if (flightKey === "stay_total_sdly_variance") {
                            //     //divider = flightsArr[flightindex].stay_total;
                            //     flightsArr[flightindex][flightKey] = (flightsArr[flightindex].stay_total_sdly_difference / flightsArr[flightindex].stay_total_sdly) * 100;;
                            // }
                            // if (flightKey === "stay_per_day_sdly_variance") {
                            //     //divider = flightsArr[flightindex].stay_per_day;
                            //     flightsArr[flightindex][flightKey] = (flightsArr[flightindex].stay_per_day_sdly_difference / flightsArr[flightindex].stay_per_day_sdly) * 100;;
                            // }
                        }
                    })
                })
            }
        })

    } else {
        let keys = Object.keys(transformedData);
        if (keys && keys.length > 0) {
            flightsArr = getFlightsSegmentation(workspace_controls.segmentType, transformedData[keys[0]])
        }
    }

    return flightsArr;
}

function getFlightsSegmentation(segmentType, data) {
    const segmentedData = [];

    data.forEach((item, index) => {

        let innerData = {
            index: {
                date: item.date,
                timestamp: moment(item.date).format("x")
            },
            HotelTotal: {}
        }
        Object.keys(item.sub_data[0]).forEach(i => {
            if (i !== 'type') {
                innerData["HotelTotal"][i] = 0;
            }
        })

        item.sub_data.forEach(i => {
            innerData[i.type] = { ...i };
            Object.keys(i).forEach(key => {
                if (key !== 'type') {
                    innerData.HotelTotal[key] += innerData[i.type][key]
                }
            })

        });

        if (segmentType === 'total') {
            innerData = {
                date: item.date,
                ...item.sub_data[0]
            }

        }
        segmentedData.push(innerData)
    })

    return segmentedData;
}
function downloadReportToExcel(app_state, active_tiles, selectedPropertyName) {
    let exportFileName = moment(app_state.as_of_date).format('MMM DD YYYY') + "_" + selectedPropertyName + "_" + active_tiles[0];
    if (app_state.workspace_controls.downloadExportButton === true) {
        let sheetName = active_tiles[0] ? active_tiles[0] : " ";
        if (app_state.workspace_controls.exportType == 'csv') {
            downloadcsvwithTable(document.getElementById("mainTable"), exportFileName);
        } else {
            TableToExcel.convert(document.getElementById("mainTable"), {
                name: exportFileName + ".xlsx",
                sheet: {
                    name: sheetName.toUpperCase()
                }
            });
        }

    }
}

//Helper functions
const getFilteredData = (params) => {

    // {data:data_setup, sortBy: 'Occ', parent: 'otb_prior'};
    const { data, columns } = params;
    const filteredColumns = getFilteredColumns(columns);
    let filtereddData = deepCopy(data);
    if (!filteredColumns.length) {
        return filtereddData;
    }
    // debugger
    filtereddData = [];
    let pushedRow = [];
    data.map((row, i) => {
        for (let j = 0; j < row.length; j++) {
            for (let k = 0; k < filteredColumns.length; k++) {
                let { parent, value, filter } = filteredColumns[k];
                if (row[j].id === parent) {
                    // debugger
                    let rowData = null;
                    if (filter) {
                        let dataValue = row[j][value];
                        if (typeof dataValue !== 'number' && dataValue.indexOf('%') !== -1) {
                            dataValue = Number(dataValue.replaceAll(/%/g, ''));
                        }
                        if (filter.gte && filter.lte) {
                            if (dataValue >= Number(filter.gte) && dataValue <= filter.lte) {
                                rowData = row;
                            }
                        } else {
                            if (filter.gte && dataValue >= Number(filter.gte)) {
                                rowData = row;
                            }
                            if (filter.lte && dataValue <= filter.lte) {
                                rowData = row;
                            }
                        }

                        if (pushedRow.indexOf(i) === -1 && rowData) {
                            filtereddData.push(rowData);
                            pushedRow.push(i);
                        }
                    }
                }
            }
        }
    });

    return filtereddData;
}

const sortData = (params) => {
    // {data:data_setup, sortBy: 'Occ', parent: 'otb_prior'};
    const { data, sortBy, parent, order } = params;
    const sortedData = deepCopy(data);
    if (!order) {
        return sortedData;
    }
    let sortedParentIndex = null;
    if (sortedData[0]) {
        sortedData[0].map((item, i) => {
            if (item.id === parent) {
                sortedParentIndex = i;
            }
        });
        let weekdaysShort = moment.weekdaysShort();
        //  debugger;
        sortedData.sort((a, b) => {
            if (sortedParentIndex !== null) {
                let A = a[sortedParentIndex][sortBy];
                let B = b[sortedParentIndex][sortBy];
                //This is added for Pickup variance to sdly column
                A = (A) ? A : 0;
                B = (B) ? B : 0;
                // console.log("a=> ", A);

                if (typeof A !== 'number' && A.indexOf('%') !== -1) {
                    A = Number(A.replaceAll(/%/g, ''));
                }
                if (typeof B !== 'number' && B.indexOf('%') !== -1) {
                    B = Number(B.replaceAll(/%/g, ''));
                }
                //---
                if ((sortBy === 'date') || (sortBy === 'compdate')) {
                    A = moment(A).format('x');
                    B = moment(B).format('x');
                }
                if (sortBy === 'dow') {
                    A = weekdaysShort.indexOf(A);
                    B = weekdaysShort.indexOf(B);
                }

                if (order === 'asc') {
                    return A - B;
                }
                if (order === 'desc') {
                    return B - A;
                }
            }
        });
    }
    return sortedData;
}

const getSortedColumn = (columns) => {
    //Get sorted column details from column configuration
    let sortedColumn = {};
    columns.map((main, i) => {
        main.subcolumns.map((sub, j) => {
            //it depends on property sorted
            if (sub.sorted) {
                sortedColumn = sub;
            }
        });
    });
    return sortedColumn;
};

const getFilteredColumns = (columns) => {
    //Get filter column details from column configuration
    let filteredColumns = [];
    columns.map((main, i) => {
        let subcols = main.subcolumns;
        for (let j = 0; j < subcols.length; j++) {
            let sub = subcols[j];
            if (sub.filterable && sub.filter && (sub.filter.gte || sub.filter.lte)) {
                filteredColumns.push(sub);
            }
        }
    });

    return filteredColumns;
};

const ColumnSorting = (props) => {
    const { colItem, sortable, onOrderByChange, sorted, label, value } = props;

    const onClickHandler = (ev) => {

        if (!sorted) {
            colItem.sorted = 'asc';
        }
        if (sorted === 'asc') {
            colItem.sorted = 'desc';
        }
        if (sorted === 'desc') {
            colItem.sorted = null;
        }
        onOrderByChange(colItem);
    }

    if (!sortable) {
        return (<></>);
    }
    return (
        <div style={{ position: 'relative' }}>
            <Tooltip title={"Sort by " + label} arrow>
                <Button className={props.className + ' order-' + colItem.sorted} onClick={onClickHandler}>
                    <ArrowDropUpIcon className='order-arrow order-arrow-up' />
                    <ArrowDropDownIcon className='order-arrow order-arrow-down' />
                </Button>
            </Tooltip>

        </div>

    );
}
const getSumInventory = (Data, key, mainKey = "HotelTotal") => {
    let sumInventory = 0;
    Data.forEach(dataPoint => {
        if (key !== "forecast") {
            sumInventory += dataPoint[mainKey][key]["Capacity"];

        } else {
            sumInventory += dataPoint["capacity"];
        }
    })
    return sumInventory;
}

const getForecastConstrainedCombinedData = (OtbData) => {
    const OSRContrainedForecast = [];
    let forecastData = dataService.getSeriesById("OSRContrainedForecast")?.data?.data;

    let uniqueDates = {};

    if (moment(forecastData[0].stay_date).isAfter(OtbData[0].index.date)) {
        OtbData.forEach(item => {
            if (moment(item.index.date).isBefore(forecastData[0].stay_date) && item.HotelTotal.primary.Capacity) {
                OSRContrainedForecast.push({ capacity: item.HotelTotal.primary.Capacity });
            }
        })
    }

    forecastData.forEach(item => {
        if (!uniqueDates[item.stay_date]) {
            uniqueDates[item.stay_date] = item;
            OSRContrainedForecast.push(item);
        }
    })
    return OSRContrainedForecast;
}

// this function transforms the data using variable capacity
export const otbTransformTotal = (dataArray, active_tiles, { scopeType, pickUpType, segmentType }, dataSet) => {
    if (active_tiles[0] === "flights") {
        return dataArray;
    }
    console.log("original dataArray", dataArray);
    const OtbData = dataService.getSeriesById("OSR")?.data?.data;
    let BookingCurveData, HotelSummaryData, otbMultilevelData, OSRContrainedForecast = [];
    if (active_tiles.includes("pace") && scopeType === "Date") {
        BookingCurveData = dataService.getSeriesById("OSRBookingPaceSegment")?.data?.data;
        console.log("raw data", BookingCurveData);
        if (!BookingCurveData || !BookingCurveData[0]["HotelTotal"]["primary"]["Capacity"]) {
            return dataArray;
        }
    }
    if (active_tiles.includes("hotel_summary")) {
        HotelSummaryData = dataService.getSeriesById("HotelSummary")?.data?.data;
        console.log("raw data", HotelSummaryData);
        if (!HotelSummaryData || !HotelSummaryData[0]["hotel_total"]["primary"]["Capacity"]) {
            return dataArray;
        }
    }
    if (active_tiles.includes("forecast")) {
        let forecastData = dataService.getSeriesById("OSRContrainedForecast")?.data?.data;
        let uniqueDates = {};
        if (forecastData && OtbData && moment(forecastData[0].stay_date).isAfter(OtbData[0].index.date)) {
            OtbData.forEach(item => {
                if (moment(item.index.date).isBefore(forecastData[0].stay_date) && item.HotelTotal.primary.Capacity) {
                    OSRContrainedForecast.push({ capacity: item.HotelTotal.primary.Capacity });
                }
            })
        }

        forecastData && forecastData.forEach(item => {
            if (!uniqueDates[item.stay_date]) {
                uniqueDates[item.stay_date] = item;
                OSRContrainedForecast.push(item);
            }
        })
        if (!OSRContrainedForecast || !OSRContrainedForecast[0]?.capacity) {
            return dataArray;
        }
        console.log("raw data", OSRContrainedForecast);


    }
    if (active_tiles.includes("otb_v2_multi_segment")) {

        if (segmentType === "room_class") {
            otbMultilevelData = dataService.getSeriesById("GetRoomClass")?.data?.data;

        }
        else if (segmentType === "room_type") {
            otbMultilevelData = dataService.getSeriesById("GetRoomType")?.data?.data;

        }

        else if (segmentType === "rate_plan") {
            // otbMultilevelData= dataService.getSeriesById("GetRoomType")?.data?.data;
            otbMultilevelData = OtbData;
            // if(!OSRContrainedForecast[0].capacity){
            //     return dataArray;
            // }
        }
        if (!otbMultilevelData || !otbMultilevelData[0]["HotelTotal"]["primary"]["Capacity"]) {
            return dataArray;
        }
        console.log("raw data", otbMultilevelData);
    }
    if (!["pace", "hotel_summary", "forecast"].includes(active_tiles)) {
        console.log("raw data", OtbData);
        if (!OtbData || !OtbData[0]["HotelTotal"]["primary"]["Capacity"]) {
            return dataArray;
        }
    }

    dataArray = deepCopy(dataArray);
    // console.log("dataSet", dataSet);
    dataArray.forEach((item, index) => {
        if (item.id === "forecast_primary") {
            let sumInventory = getSumInventory(OSRContrainedForecast, "forecast");

            dataArray[index].Occ = divide(dataArray[index].Rooms, sumInventory) * 100;
            dataArray[index].RevPAR = divide(dataArray[index].Revenue, sumInventory);
        }
        if (item.id === "otb_current" || item.id === "otb_primary_report") {
            let sumInventory = getSumInventory(OtbData, "primary");
            if (active_tiles.includes("otb_v2_multi_segment")) {
                if (segmentType === "rate_plan") {
                    // sumInventory = getSumInventory(otbMultilevelData, "forecast");
                    sumInventory = getSumInventory(otbMultilevelData, "primary");
                } else {
                    sumInventory = getSumInventory(otbMultilevelData, "primary");
                }
            }
            dataArray[index].Occ = divide(dataArray[index].Rooms, sumInventory) * 100;
            dataArray[index].RevPAR = divide(dataArray[index].Revenue, sumInventory);
        }
        if (item.id === "otb_sdly") {
            let sumInventory = getSumInventory(OtbData, "sdly");

            dataArray[index].Occ = divide(dataArray[index].Rooms, sumInventory) * 100;
            dataArray[index].RevPAR = divide(dataArray[index].Revenue, sumInventory);

        }
        if (item.id === "otb_actual_sdly") {
            let sumInventory = getSumInventory(OtbData, "sdly_actual");

            dataArray[index].Occ = divide(dataArray[index].Rooms, sumInventory) * 100;
            dataArray[index].RevPAR = divide(dataArray[index].Revenue, sumInventory);

        }
        if (item.id === "pickup_otb_current" || item.id === "otb_pickup_report") {
            let key = (pickUpType === "Standard") ? "current" : "primary";
            let sumInventory = getSumInventory(OtbData, key);
            dataArray[index].Occ = divide(dataArray[index].Rooms, sumInventory) * 100;
            dataArray[index].RevPAR = divide(dataArray[index].Revenue, sumInventory);

        }
        if (item.id === "pickup_prior") {

            let key = (pickUpType === "Standard") ? "prior" : "pickup";
            let sumInventory = getSumInventory(OtbData, key);
            dataArray[index].Occ = divide(dataArray[index].Rooms, sumInventory) * 100;
            dataArray[index].RevPAR = divide(dataArray[index].Revenue, sumInventory);

        }
        if (item.id === "booking_curve") {
            let sumInventory = getSumInventory(BookingCurveData, "primary");

            dataArray[index].Occ = divide(dataArray[index].Rooms, sumInventory) * 100;
            dataArray[index].RevPAR = divide(dataArray[index].Revenue, sumInventory);

        }
        if (item.id === "    ") {
            let sumInventory = getSumInventory(BookingCurveData, "comparison");

            dataArray[index].Occ = divide(dataArray[index].Rooms, sumInventory) * 100;
            dataArray[index].RevPAR = divide(dataArray[index].Revenue, sumInventory);

        }
        if (item.id === "hotel_summary_otb") {
            let sumInventory = getSumInventory(HotelSummaryData, "primary", "hotel_total");

            dataArray[index].Occ = divide(dataArray[index].Rooms, sumInventory) * 100;
            dataArray[index].RevPAR = divide(dataArray[index].Revenue, sumInventory);

        }
        if (item.id === "hotel_summary_sdly_otb") {
            let sumInventory = getSumInventory(HotelSummaryData, "sdly", "hotel_total");

            dataArray[index].Occ = divide(dataArray[index].Rooms, sumInventory) * 100;
            dataArray[index].RevPAR = divide(dataArray[index].Revenue, sumInventory);

        }
        if (item.id === "hotel_summary_sdly_actuals") {
            let sumInventory = getSumInventory(HotelSummaryData, "sdly_actual", "hotel_total");

            dataArray[index].Occ = divide(dataArray[index].Rooms, sumInventory) * 100;
            dataArray[index].RevPAR = divide(dataArray[index].Revenue, sumInventory);

        }
    })
    dataArray.forEach((item, index) => {
        if (item.id === "bc_date_diff" && scopeType === "Date") {
            let primary = dataArray.find(i => i.id === "booking_curve");
            let comparison = dataArray.find(i => i.id === "comparison_pace");
            dataArray[index].Occ = primary.Occ - comparison.Occ;
        }
    })
    return dataArray;
}


const correctNaValues = (obj) => {
    let keys = Object.keys(obj);
    if (keys.length) {
        keys.map((key, index) => {
            if (typeof obj[key] === 'string') {
                obj[key] = 0;
            }
        })
    }
    return obj;
}



export default {
    downloadReportToExcel, getFilteredData, sortData,
    getSortedColumn, getFilteredColumns, getFilteredColumns, ColumnSorting
};