import React, { useEffect, useState } from "react";
import { Table, TableCell, TableContainer, TableRow, TableBody, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CloseSharp as CloseSharpIcon } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import moment from 'moment';
import { dateFormat, getSdlyDate, daysCountFromRange, deepCopy, getAppData, getCorrectValue, getFromState, getPermissions, getTransformedSegments, in_array, sendMultipleAPIs, truncate } from '../../app/util';
import { useDispatch, useSelector } from "react-redux";
import dataService, { fetchDataSimple } from '../../services/data.service';
import RatePlanList from './RatePlanList';
import RatePlanListTotal from './RatePlanListTotal';
import './rate-plan-popup.scss'
import RatePlanHeader from './RatePlanHeader';
import MultipleTileOptions from './MultipleTileOptions';
import DataService from "../../services/data.service";
import { UPDATE_STATE, NOTE_LETTER_LIMIT } from '../../actions/types';
import { APP_CACHE, TILES } from "../../app/config";


export default function NotesPopup(props) {

    const dispatch = useDispatch();

    const { current_state } = useSelector(
        (state) => state.app
    );
    const {app_state, ui_state} = current_state;
    const { notes,property_id, property_name } = app_state;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const savedIcon = <svg style={{ marginBottom: "-2px" }} xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="#606060" viewBox="0 0 256 256"><path d="M160,40A88.09,88.09,0,0,0,81.29,88.67,64,64,0,1,0,72,216h88a88,88,0,0,0,0-176Zm0,160H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.11A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,72,72Zm37.66-93.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L144,148.69l42.34-42.35A8,8,0,0,1,197.66,106.34Z"></path></svg>

    const emptyNoteIcon = <svg className="note-icon" style={{ cursor: "pointer", marginBottom: "0px" }} xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="#606060" viewBox="0 0 256 256"><path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H156.69A15.86,15.86,0,0,0,168,219.31L219.31,168A15.86,15.86,0,0,0,224,156.69V48A16,16,0,0,0,208,32ZM48,48H208V152H160a8,8,0,0,0-8,8v48H48ZM196.69,168,168,196.69V168Z"></path></svg>
    const activeEmptyNoteIcon = <svg className="note-icon" style={{ cursor: "pointer", marginBottom: "0px" }} xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="#606060" viewBox="0 0 256 256"><path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H156.69A15.86,15.86,0,0,0,168,219.31L219.31,168A15.86,15.86,0,0,0,224,156.69V48A16,16,0,0,0,208,32ZM160,204.69V160h44.69Z"></path></svg>
    const filledNoteIcon = <svg className="note-icon" style={{ cursor: "pointer", marginBottom: "0px" }} xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="none"><g clip-path="url(#C)"><mask id="A" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="8"><path d="M8 0H0V8H8V0Z" fill="#fff" /></mask><g mask="url(#A)"><mask id="B" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="8"><path d="M8 0H0V8H8V0Z" fill="#fff" /></mask><g mask="url(#B)"><path opacity=".2" d="M7.333.667V6.14l-1.2 1.193H.667V.667h6.667z" fill="#000" /><path d="M2.667 3h2.667c.187 0 .333-.153.333-.333s-.147-.333-.333-.333H2.667c-.18 0-.333.147-.333.333S2.487 3 2.667 3zm2.667.667H2.667c-.18 0-.333.147-.333.333s.153.333.333.333h2.667c.187 0 .333-.153.333-.333s-.147-.333-.333-.333zM4 5H2.667c-.18 0-.333.147-.333.333s.153.333.333.333H4c.187 0 .333-.153.333-.333S4.187 5 4 5zm3.333-5H.667A.67.67 0 0 0 0 .667v6.667A.67.67 0 0 0 .667 8H5.2a.65.65 0 0 0 .467-.2l.467-.467 1.2-1.193.473-.473A.66.66 0 0 0 8 5.193V.667C8 .3 7.707 0 7.333 0zM5.667 6.86V5.667h1.2l-1.2 1.193zM7.333 5h-2C5.153 5 5 5.147 5 5.333v2H.667V.667h6.667V5zM2.667 3h2.667c.187 0 .333-.153.333-.333s-.147-.333-.333-.333H2.667c-.18 0-.333.147-.333.333S2.487 3 2.667 3zm0 1.333h2.667c.187 0 .333-.153.333-.333s-.147-.333-.333-.333H2.667c-.18 0-.333.147-.333.333s.153.333.333.333zm0 1.333H4c.187 0 .333-.153.333-.333S4.187 5 4 5H2.667c-.18 0-.333.147-.333.333s.153.333.333.333zM4 5H2.667c-.18 0-.333.147-.333.333s.153.333.333.333H4c.187 0 .333-.153.333-.333S4.187 5 4 5zm1.333-1.333H2.667c-.18 0-.333.147-.333.333s.153.333.333.333h2.667c.187 0 .333-.153.333-.333s-.147-.333-.333-.333zm0-1.333H2.667c-.18 0-.333.147-.333.333S2.487 3 2.667 3h2.667c.187 0 .333-.153.333-.333s-.147-.333-.333-.333z" fill="#606060" /></g></g></g><defs><clipPath id="C"><path fill="#fff" d="M0 0h8v8H0z" /></clipPath></defs></svg>
    const activeFilledNoteIcon = <svg className="note-icon" style={{ cursor: "pointer", marginBottom: "0px" }} xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="#606060" viewBox="0 0 256 256"><path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H156.69A15.92,15.92,0,0,0,168,219.31L219.31,168A15.92,15.92,0,0,0,224,156.69V48A16,16,0,0,0,208,32ZM96,88h64a8,8,0,0,1,0,16H96a8,8,0,0,1,0-16Zm32,80H96a8,8,0,0,1,0-16h32a8,8,0,0,1,0,16ZM96,136a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm64,68.69V160h44.7Z"></path></svg>

    const [noteIcon, setNoteIcon] = useState(emptyNoteIcon);
    const [activeIcon, setActiveIcon] = useState(false);

    let stay_date = moment(props.params.stay_date);
    const formatted_stay_date = stay_date.format("YYYY-MM-DD");

    const dataForDate = notes !== undefined ? notes.find(item => item.stay_date === formatted_stay_date) : undefined;

    useEffect(() => {
        if (dataForDate === undefined || dataForDate.note === undefined || dataForDate.note === "") {
            setNoteIcon(emptyNoteIcon)
        }
        else {
            setNoteIcon(filledNoteIcon)
        }
    }, [dataForDate]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setActiveIcon(true)
        let stay_date = moment(props.params.stay_date);
        const formatted_stay_date = stay_date.format("YYYY-MM-DD");

        let dataForDate = notes && notes.find(item => item.stay_date === formatted_stay_date);
        if(!dataForDate){
            dataForDate = {
                note : '',
                last_edited_by : '',
                last_edited_at : ''
            }
        }
        if (dataForDate.note === "") {
            setNoteIcon(activeEmptyNoteIcon)
            setHideLastUpdatedInfo(true)
        }
        else {
            setNoteIcon(activeFilledNoteIcon)
            setHideLastUpdatedInfo(false)
        }

        setNoteText(dataForDate.note)
        setLastEditedBy(dataForDate.last_edited_by)
        let formatted_last_edited_at = moment().format("MMM DD YYYY, hh:mm A");
        if (dataForDate.last_edited_at !== "") {
            const last_edited_at = moment(dataForDate.last_edited_at);
            formatted_last_edited_at = last_edited_at.format("MMM DD YYYY, hh:mm A");
            setLastEditedAt(formatted_last_edited_at)
        }
        const event_metadata = {
            property_id, property_name,
            active_tiles:ui_state.active_tiles
        };
        window.analyze.track("note_opened", {
            "Last Edited": formatted_last_edited_at,
            "Stay Date":formatted_stay_date,
            ...event_metadata
        }, { app_state, ui_state }, current_state);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setActiveIcon(false)
        let stay_date = moment(props.params.stay_date);
        const formatted_stay_date = stay_date.format("YYYY-MM-DD");

        let dataForDate = notes && notes.find(item => item.stay_date === formatted_stay_date);
        if(!dataForDate){
            dataForDate = {
                note : ''
            }
        }
        if (dataForDate.note === "") {
            setNoteIcon(emptyNoteIcon)
        }
        else {
            setNoteIcon(filledNoteIcon)
        }

        if (dataForDate.note !== noteText) {
            // calling save API
            saveNote();
        }
    };

    const handleNoteChange = (event) => {
        //setNoteText(event.target.value);
        const text = event.target.value;
        if (text.length <= NOTE_LETTER_LIMIT) {
            setNoteText(text);
        }
    };

    const [noteText, setNoteText] = useState("");
    const [lastEditedAt, setLastEditedAt] = useState("");
    const [lastEditedBy, setLastEditedBy] = useState("");
    const [hideLastUpdatedInfo, setHideLastUpdatedInfo] = useState(false);

    const notesGetData = async () => {
        try {
            const response = await DataService.getData({
                api: "rms/properties/notes/" + app_state.property_id + "/?fromDate=" + app_state.primary_stay_range.date_from + "&toDate=" + app_state.primary_stay_range.date_until + "&fromDate=" + app_state.comp_stay_range.date_from + "&toDate=" + app_state.comp_stay_range.date_until + "&fillMissingDays=true&flat=true",
                request: { method: 'GET' }
            });
            if (response.status === "success") {
                app_state.notes = response.data;
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            ...app_state,
                        }
                    }
                })
            }
            else {
                console.log("Note API status error")
            }
        }
        catch (error) {
            console.error('An error occurred', error);
        }
    }

    const saveNote = async () => {
        let stay_date = moment(props.params.stay_date);
        const formatted_stay_date = stay_date.format("YYYY-MM-DD");
        const event_metadata = {
            property_id, property_name,
            active_tiles:ui_state.active_tiles
        };
        try {
            const response = await DataService.update({
                api: "rms/properties/notes/" + app_state.property_id + "/",
                request: { method: 'POST', stayDate: formatted_stay_date, note: noteText }
            });
            if (response.status === "success") {
                let apc = APP_CACHE;
                apc.chartData = null;
                window.analyze.track("note_saved", {
                    "Stay Date": formatted_stay_date,
                    "Previous Note": dataForDate.note,
                    "Updated Note": noteText,
                    ...event_metadata
                }, { app_state, ui_state }, current_state);
                console.log("Note saved successfully");
                notesGetData();
            }
            else {
                console.log("Note not saved")
            }
        }
        catch (err) {
            console.error('An error occurred', err);
        }
    }

    return (
        <div className='rateplan-details' style={{paddingRight: "0px"}}>
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ lineHeight: 1, fontSize: "18px", mt: "-7px" }}
                className={activeIcon ? "note-icon-div active" : "note-icon-div"}
            >
                {noteIcon}
            </div>
            <Menu
                className='rateplan-details notes-main-div'
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}

                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'auto',
                        mt: 0.5,
                        ml: "32px",
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        resize: "both",
                        background: "#FFF9DB",
                        borderRadius: "8px",
                        minHeight: "100px",
                        minWidth: "200px",
                        width: "200px",
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                <div className='rateplan-box notes'>

                    {/* <Button className='close-btn' onClick={handleClose}>
                        <CloseSharpIcon fontSize='small' />
                    </Button> */}

                    <Box className="rateplan-box-content" sx={{ height: "100%" }}>
                        <Box sx={{ height: "100%" }}>
                            <textarea
                                className='notes-textarea'
                                value={noteText}
                                onChange={handleNoteChange}
                                placeholder="Write a note..."
                                autoFocus
                            >
                            </textarea>
                        </Box>
                    </Box>
                    <Box className="rateplan-box-content" sx={hideLastUpdatedInfo ? { opacity: 0, height: "20px" } : { height: "20px", display: "flex", gap: "6px", justifyContent: "end", alignItems: "center" }}>
                        <div className="last-edited-user">{lastEditedBy === "" ? null : savedIcon} {lastEditedBy}</div>
                        <div style={lastEditedAt === "" ? { display: "none" } : { display: "all", color: "#606060" }}>|</div>
                        <div className="last-edited-date">{lastEditedAt}</div>
                    </Box>
                </div>

            </Menu>
        </div>
    );
}