import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import FilterItems from "./FilterItems";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FilterItems2 from "./FilterItems2";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterItemsDow from "./FilterItemsDowSegment";
import FilterItemsDowSegment from "./FilterItemsDowSegment";
import { width } from "@mui/system";



export default function Filter(props) {
  // debugger;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{}}>
      {/* <FilterListIcon className="filter-menus" size="small" onClick={handleClick} /> */}
      <svg onClick={handleClick} className="filter-menus" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#fff" viewBox="0 0 256 256"><path d="M200,128a8,8,0,0,1-8,8H64a8,8,0,0,1,0-16H192A8,8,0,0,1,200,128Zm32-56H24a8,8,0,0,0,0,16H232a8,8,0,0,0,0-16Zm-80,96H104a8,8,0,0,0,0,16h48a8,8,0,0,0,0-16Z"></path></svg>
      <Popover
        id={id}

        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          elevation: 2,
          sx: {
            overflow: 'visible',
            border: '1px solid #fff',
            borderRadius: '5px',
            mt: 1,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 2,
              left: 'calc(50% - 6px)',
              width: 12,
              height: 12,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            mt: "5px",
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              width: 6,
              height: 1,
              top: 5,
              transform: "rotate(45deg)",
              border: "1px solid #ff0000",
              left: "calc(50% - 6px)"

            }
          }}
        />
        <Typography sx={{ mt: 0, p: 0, minWidth: "200px !important", width: props.currentColumn.type === "segment" ? "450px !important" : "" }}>
          {/* <FilterItems /> */}

          {(props.currentColumn.type === "segment" || props.currentColumn.type === "dow") && <FilterItemsDowSegment columnList={props.columnList} segments={props.segments} type={props.currentColumn.type}
            filteredColumns={props.filteredColumns}
            currentColumn={props.currentColumn}
            columnToggleHandler={props.columnToggleHandler}
            applyFilterHandler={(selectedSegments) => {
              props.applyFilterHandler(selectedSegments);
              handleClose();
            }}
            clearFilterHandler={props.clearFilterHandler}
          />

          }

          {props.currentColumn.type !== "dow" && props.currentColumn.type !== "segment" && props.currentColumn.type !== "segment" && <FilterItems2
            columnToggleChanged={props.columnToggleChanged}
            columnList={props.columnList}
            filteredColumns={props.filteredColumns}
            currentColumn={props.currentColumn}
            columnToggleHandler={props.columnToggleHandler}
            applyFilterHandler={props.applyFilterHandler}
            clearFilterHandler={props.clearFilterHandler}
            selectAllColumnHandler={props.selectAllColumnHandler}
            handleClose={handleClose}
          />
          }
        </Typography>
      </Popover>
    </div>
  );
}
