import moment from "moment";
import { convertToPercent, daysCountFromRange, deepCopy, getAppData, getDatesInRange, getFromState, getObjectFilter, getTransformedSegments, in_array, round, setUpDate, checkProperValue, getAdrValue, getCorrectValue, getDayDateKey } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { transformSegmentData } from "../segmentedData";
import { getSegmentedCode } from "../processDataReports";

export function getPickupReportData_hotel(columns, auth, app_state, ui_state) {
    let inputData = (app_state.workspace_controls.segmented_view) ? [] : deepCopy(dataService.getSeriesById("OSR").data.data);
    let marketData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];

    const { active_tiles, table_filters } = ui_state;
    const { dayDate } = app_state.workspace_controls;

    const filteredDow = table_filters.table_reports.dow.map(d => d.code);
    // data = data.filter((item) => filteredDow.includes(moment(item.index.date).format('ddd').toLocaleLowerCase()));
    // marketData = marketData.filter((item) => filteredDow.includes(moment(item.index.date).format('ddd').toLocaleLowerCase()));
    let setup_data = [];
    if (inputData && inputData.length) {
        inputData.forEach((dataSet, index) => {
            let k = Object.keys(dataSet);
            if (k.includes("Segment_UNMAPPED")) {
                let hotelKeys = Object.keys(dataSet.HotelTotal);
                hotelKeys.forEach(element => {
                    inputData[index]['HotelTotal'][element]['Rooms'] = inputData[index]['HotelTotal'][element]['Rooms'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['Rooms']);
                    inputData[index]['HotelTotal'][element]['Occ'] = inputData[index]['HotelTotal'][element]['Occ'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['Occ']);
                    inputData[index]['HotelTotal'][element]['ADR'] = inputData[index]['HotelTotal'][element]['ADR'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['ADR']);
                    inputData[index]['HotelTotal'][element]['RevPAR'] = inputData[index]['HotelTotal'][element]['RevPAR'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['RevPAR']);
                    inputData[index]['HotelTotal'][element]['Revenue'] = inputData[index]['HotelTotal'][element]['Revenue'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['Revenue']);
                    inputData[index]['HotelTotal'][element]['SellRate'] = inputData[index]['HotelTotal'][element]['SellRate'] - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['SellRate']);

                    let roomData = inputData[index]['HotelTotal'][element]['Rooms'] ;//- getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['Rooms']);
                    let revenueData = inputData[index]['HotelTotal'][element]['Revenue'];// - getCorrectValue(inputData[index]['Segment_UNMAPPED'][element]['Revenue']);
                    inputData[index]['HotelTotal'][element]['ADR'] = revenueData / roomData;
                    // debugger;

                });
            }
        });
    }
    let data = inputData;

    if (data) {
        let dataKey = "HotelTotal";
        let dataKey1 = (app_state.workspace_controls.pickUpType) === "Standard" ? "current" : "primary";
        let dataKey2 = (app_state.workspace_controls.pickUpType) === "Standard" ? "prior" : "pickup";

        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = {};
                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, item.index.date);
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.index.segment;
                    }
                }

                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (item && mainKey === "variance_pickup") {
                            if (key.value === "ADR") {
                                let revenue = item[dataKey][dataKey1].Revenue - item[dataKey][dataKey2].Revenue;
                                let rooms = item[dataKey][dataKey1].Rooms - item[dataKey][dataKey2].Rooms;
                                dataObj[key.value] = getAdrValue(revenue, rooms, 'f');
                            } else if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey][dataKey1][key.value] - item[dataKey][dataKey2][key.value])
                            } else {
                                dataObj[key.value] = round(item[dataKey][dataKey1][key.value] - item[dataKey][dataKey2][key.value])
                            }
                        } else if (mainKey === "difference_sdly") {
                            let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey][dataKey1][key.value] - item[dataKey][dayDateKey][key.value])
                            } else {
                                dataObj[key.value] = round(item[dataKey][dataKey1][key.value] - item[dataKey][dayDateKey][key.value])
                            }
                        } else if (mainKey === "sdly_pickup_diff") {
                            let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                            let dayDate_sdly_actual = (dayDate === "day") ? "sdly_actual" : "sdtly_actual";
                            dataObj[key.value] = round(item[dataKey][dayDate_sdly_actual][key.value] - item[dataKey][dayDateKey][key.value])
                        } else if (mainKey === "marketprimary") {
                            if (key.value == "Occ") {
                                dataObj[key.value] = (marketData[index]) ? convertToPercent(marketData[index]["MarketTotal"]["primary"][key.value]) : 0.00;
                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["primary"][key.value]) : 0.00;
                            }
                        } else if (mainKey === "marketsdly") {
                            if (key.value == "Occ") {
                                dataObj[key.value] = (marketData[index]) ? convertToPercent(marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00;
                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00;
                            }
                        } else if (mainKey === "market_sdly_diff") {
                            if (key.value === "Occ") {
                                dataObj[key.value] = (marketData[index]) ? convertToPercent(marketData[index]["MarketTotal"]["primary"][key.value] - marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00

                            } else {
                                dataObj[key.value] = (marketData[index]) ? round(marketData[index]["MarketTotal"]["primary"][key.value] - marketData[index]["MarketTotal"]["sdly"][key.value]) : 0.00
                            }
                        } else {
                            if (mainKey === "primary") {
                                mainKey = dataKey1;
                            } else if (mainKey === "pickup") {
                                mainKey = dataKey2;
                            } else {
                                mainKey = mainKey;
                            }
                            mainKey = getDayDateKey(dayDate, mainKey);
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value]);
                            } else {
                                dataObj[key.value] = round(item[dataKey][mainKey][key.value]);
                            }
                        }
                    })
                }
                dataObj.id = col.id;
                data_columns.push(dataObj);
            })

            data_columns.map((itmArr, idx) => {
                if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                    data_columns[idx]["ignore_sort"] = true;
                }
            })
            setup_data.push(data_columns);
        })
    }
    return setup_data;

}


export function getTopSegments(auth, app_state, category) {
    let system_settings = getAppData(auth, app_state)
    let market_segment_category_mapping = system_settings.market_segment_category_mapping;
    const lvl1_mseg_grouping = JSON.parse(
        JSON.stringify(system_settings.lvl1_mseg_grouping)
    );
    const topSegments = {};
    lvl1_mseg_grouping.filter((item) => {
        if (item.Transient) topSegments.Transient = item.Transient;
        if (item.Group) topSegments.Group = item.Group;
        if (item.Contract) topSegments.Contract = item.Contract;
    });
    let segmentCodes = [];
    let topSegmentsList = topSegments[category];
    if (topSegmentsList) {
        topSegmentsList.map((item) => {
            if (market_segment_category_mapping[item]) {
                segmentCodes = [...segmentCodes, item];
            }
        })
        segmentCodes = [...new Set(segmentCodes)];
    }
    return segmentCodes;
}

export function getTopSegments_xx(auth, app_state, category) {
    let system_settings = getAppData(auth, app_state)
    let market_segment_category_mapping = system_settings.market_segment_category_mapping;
    const lvl1_mseg_grouping = JSON.parse(
        JSON.stringify(system_settings.lvl1_mseg_grouping)
    );
    const topSegments = {};
    lvl1_mseg_grouping.filter((item) => {
        if (item.Transient) topSegments.Transient = item.Transient;
        if (item.Group) topSegments.Group = item.Group;
        if (item.Contract) topSegments.Contract = item.Contract;
    });
    // debugger;
    let segmentCodes = [];
    let topSegmentsList = topSegments[category];
    if (topSegmentsList) {
        topSegmentsList.map((item) => {
            if (market_segment_category_mapping[item]) {
                segmentCodes = [...segmentCodes, ...market_segment_category_mapping[item]];
            }
        })
        segmentCodes = [...new Set(segmentCodes)];
    }
    return segmentCodes;
}


export function getPickupReportSegmentedData_hotel(columns, auth, app_state, ui_state) {
    let data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let marketData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    const { dayDate } = app_state.workspace_controls;
    let categoryList = ["Transient", "Group", "Contract", "Total"];
    const { active_tiles, table_filters } = ui_state;
    const filteredDow = table_filters.table_reports.dow.map(d => d.code);
    let dates = getDatesInRange(date_from, date_until);
    let dataKey1 = (app_state.workspace_controls.pickUpType) === "Standard" ? "current" : "primary";
    let dataKey2 = (app_state.workspace_controls.pickUpType) === "Standard" ? "prior" : "pickup";

    /////// final Total /////
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdlyText = (dayDate === 'day') ? "sdly" : "sdtly";
    let allTotal = {
        "Transient": {
            "variance_pickup": deepCopy(blankObj),
            "primary": deepCopy(blankObj),
            "current": deepCopy(blankObj),
            "prior": deepCopy(blankObj),
            "pickup": deepCopy(blankObj),
            // "sdly": deepCopy(blankObj),
            [sdlyText]: deepCopy(blankObj),
            // "sdtly_actual": deepCopy(blankObj),
            // "sdtly_pickup": deepCopy(blankObj),
            "marketprimary": deepCopy(blankObj),
            "marketsdly": deepCopy(blankObj),
            "market_sdly_diff": deepCopy(blankObj),

        },
        "Group": {
            "variance_pickup": deepCopy(blankObj),
            "primary": deepCopy(blankObj),
            "current": deepCopy(blankObj),
            "prior": deepCopy(blankObj),
            "pickup": deepCopy(blankObj),
            [sdlyText]: deepCopy(blankObj),
            // "sdtly": deepCopy(blankObj),
            // "sdtly_actual": deepCopy(blankObj),
            // "sdtly_pickup": deepCopy(blankObj),
            "marketprimary": deepCopy(blankObj),
            "marketsdly": deepCopy(blankObj),
            "market_sdly_diff": deepCopy(blankObj),

        },
        "Contract": {
            "variance_pickup": deepCopy(blankObj),
            "primary": deepCopy(blankObj),
            "current": deepCopy(blankObj),
            "prior": deepCopy(blankObj),
            "pickup": deepCopy(blankObj),
            [sdlyText]: deepCopy(blankObj),
            // "sdtly": deepCopy(blankObj),
            // "sdtly_actual": deepCopy(blankObj),
            // "sdtly_pickup": deepCopy(blankObj),
            "marketprimary": deepCopy(blankObj),
            "marketsdly": deepCopy(blankObj),
            "market_sdly_diff": deepCopy(blankObj),

        },

    }

    if (app_state.workspace_controls.pickUpType === "Standard") {
        delete allTotal.Contract.primary;
        delete allTotal.Transient.primary;
        delete allTotal.Group.primary;
        delete allTotal.Contract.pickup;
        delete allTotal.Transient.pickup;
        delete allTotal.Group.pickup;
    }
    if (app_state.workspace_controls.pickUpType === "Latest") {
        delete allTotal.Contract.current;
        delete allTotal.Transient.current;
        delete allTotal.Group.current;
        delete allTotal.Contract.prior;
        delete allTotal.Transient.prior;
        delete allTotal.Group.prior;
    }

    ///// end final total //// 
    let totalDays = daysCountFromRange(app_state);
    if (dates && dates.length > 0) {

        dates.map((date, dateIndex) => {

            categoryList.map((category, categoryIndex) => {
                let data_columns = [];
                let segmentCodes = getTopSegments(auth, app_state, category);

                columns.map(col => {
                    let dataObj = {};
                    dataObj.id = col.id;
                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        if (app_state.workspace_controls.segmented_view) {
                            dataObj["segment"] = category;
                            // dataObj["segment"] = (categoryIndex == (categoryList.length - 1)) ? "Total" : category ;
                        }
                    }
                    if (col.type === 'dataColumn') {
                        let { mainKey } = col;
                        col.subcolumns.map(key => {
                            if (mainKey === "variance_pickup") {
                                let keyPrimaryTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dataKey1, "osr", category);
                                let keyPickupTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dataKey2, "osr", category);

                                let keysTotal_primary = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dataKey1, "osr", category);
                                let keysTotalpickup = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dataKey2, "osr", category);

                                if (key.value === "ADR") {

                                    let rev = getSegmentKeyTotal(data[dateIndex], 'Revenue', segmentCodes, dataKey1, "osr", category) - getSegmentKeyTotal(data[dateIndex], 'Revenue', segmentCodes, dataKey2, "osr", category);
                                    let rooms = getSegmentKeyTotal(data[dateIndex], 'Rooms', segmentCodes, dataKey1, "osr", category) - getSegmentKeyTotal(data[dateIndex], 'Rooms', segmentCodes, dataKey2, "osr", category);
                                    dataObj[key.value] = getAdrValue(rev, rooms, 'f'); //round(keyPrimaryTotalValue - keyPickupTotalValue);
                                    if (category !== "Total") {
                                        //allTotal[category][mainKey][key.value] += (in_array([key.value], ['Rooms', 'Revenue'])) ? keysTotal_primary - keysTotalpickup : ((keysTotal_primary - keysTotalpickup) / totalDays);
                                        allTotal[category][mainKey][key.value] += (in_array([key.value], ['Rooms', 'Revenue'])) ? keysTotal_primary - keysTotalpickup : ((keysTotal_primary - keysTotalpickup) / totalDays);
                                    }
                                } else if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keyPickupTotalValue);
                                    if (category !== "Total") {
                                        allTotal[category][mainKey][key.value] += convertToPercent(((keysTotal_primary - keysTotalpickup) / totalDays));
                                    }
                                } else {
                                    dataObj[key.value] = round(keyPrimaryTotalValue - keyPickupTotalValue);
                                    if (category !== "Total") {
                                        allTotal[category][mainKey][key.value] += (in_array([key.value], ['Rooms', 'Revenue'])) ? keysTotal_primary - keysTotalpickup : ((keysTotal_primary - keysTotalpickup) / totalDays);
                                    }
                                }
                            } else if (mainKey === "difference_sdly") {
                                let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                                let keyPrimaryTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dataKey1, "osr", category);
                                let keySdlyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dayDateKey, "osr", category);

                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keySdlyTotalValue)
                                } else {
                                    dataObj[key.value] = round(keyPrimaryTotalValue - keySdlyTotalValue)
                                }
                            } else if (mainKey === "sdly_pickup_diff") {
                                let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                                let dayDate_sdly_actual = (dayDate === "day") ? "sdly_actual" : "sdtly_actual";

                                let keySdlyActualTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dayDate_sdly_actual, "osr", category);
                                let keySdlyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dayDateKey, "osr", category);
                                dataObj[key.value] = round(keySdlyActualTotalValue - keySdlyTotalValue)

                                let keysTotal_sdly_actual = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dayDate_sdly_actual, "osr", category);
                                let keysTotalSdly = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, dayDateKey, "osr", category);
                                if (category !== "Total") {
                                    allTotal[category][mainKey][key.value] += convertToPercent(((keysTotal_sdly_actual - keysTotalSdly) / totalDays));
                                }

                            }
                            else if (mainKey === "marketprimary") {
                                let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValue_market);
                                } else {
                                    dataObj[key.value] = round(keyTotalValue_market);
                                }
                                if (category !== "Total") {
                                    allTotal[category][mainKey][key.value] = 0;
                                }
                            } else if (mainKey === "marketsdly") {
                                let keyTotalValueSdly_market = 0;// getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");

                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValueSdly_market);
                                } else {
                                    dataObj[key.value] = round(keyTotalValueSdly_market);
                                }
                                if (category !== "Total") {
                                    allTotal[category][mainKey][key.value] = 0;
                                }
                            } else if (mainKey === "market_sdly_diff") {
                                let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                let keyTotalValueSdly_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                                dataObj[key.value] = round(keyTotalValue_market - keyTotalValueSdly_market);
                                if (category !== "Total") {
                                    allTotal[category][mainKey][key.value] = 0;
                                }
                            }
                            else {
                                if (mainKey === "primary") {
                                    mainKey = dataKey1;
                                } else if (mainKey === "pickup") {
                                    mainKey = dataKey2;
                                } else {
                                    mainKey = mainKey;
                                }
                                mainKey = getDayDateKey(dayDate, mainKey);
                                let keyTotalValue = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, mainKey, "osr", category);
                                let keysTotal = getSegmentKeyTotal(data[dateIndex], key.value, segmentCodes, mainKey, "osr", category);
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(keyTotalValue);
                                    if (category !== "Total") {
                                        // console.log(allTotal, " ", keysTotal, " ==>  ", category, " => ", mainKey);
                                        // debugger;
                                        allTotal[category][mainKey][key.value] += convertToPercent((keysTotal / totalDays));
                                    }
                                } else {
                                    dataObj[key.value] = round(keyTotalValue);
                                    if (category !== "Total") {
                                        allTotal[category][mainKey][key.value] += (in_array([key.value], ['Rooms', 'Revenue'])) ? keysTotal : (keysTotal / totalDays);
                                    }
                                }
                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            })

        })
    }
    let totalResult = getAllTotalData(allTotal);
    totalResult.forEach((itm, idx) => {
        itm.forEach((element, index) => {
            // let diffVarianceIndex = element.findIndex((it) => it.id === "variance_pickup");
            // if (diffVarianceIndex >= 0) {
                let rsAdr = element.Revenue / element.Rooms;
                totalResult[idx][index].ADR = rsAdr;
            // }
        });
       


    })


    setup_data = setup_data.concat(totalResult);
    // debugger;
    // console.log("setup_data_combined ", setup_data);
    return setup_data;
}



function getAllTotalData(allTotal) {
    let keys = Object.keys(allTotal);
    let result = [];
    keys.map((item) => {
        let itemKeys = Object.keys(allTotal[item]);
        let totalArr = [];
        let obj = {};
        obj.segment = item;
        obj.date = "";
        obj.dow = "";
        obj.id = "staydates";
        totalArr.push(obj);

        itemKeys.map((mainKey, index) => {
            if (mainKey === 'sdly') {
                allTotal[item][mainKey]['id'] = "otb_sdly";
            } else {
                allTotal[item][mainKey]['id'] = mainKey;
            }
            totalArr.push(allTotal[item][mainKey]);
        })
        result.push(totalArr);
    })
    // debugger
    return result;
}



// function to calculate segmented data for individual category segment.

export function getPickupReportSegmentedData_topSegment(columns, auth, app_state, segmentCodes, ui_state, segments) {
    let data = deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let marketData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];
    let setup_data = [];
    let { date_from, date_until } = app_state.primary_stay_range;
    const { dayDate } = app_state.workspace_controls;

    const { active_tiles, table_filters } = ui_state;
    const filteredDow = table_filters.table_reports.dow.map(d => d.code);

    let dates = getDatesInRange(date_from, date_until);
    let dataKey1 = (app_state.workspace_controls.pickUpType) === "Standard" ? "current" : "primary";
    let dataKey2 = (app_state.workspace_controls.pickUpType) === "Standard" ? "prior" : "pickup";

    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    if (dates && dates.length > 0) {
        let currentDate;
        let totalObj = {};
        dates.map((date, dateIndex) => {
            let totalObjData = {};
            let totalColumns = [];
            if (!currentDate) {
                currentDate = date;
            }
            totalObj = setUpDate(totalObj, date);
            totalObj["segment"] = "Total";
            let primaryRoomstotal = 0;
            let primaryRevenueTotal = 0;

            let dataRecord = data.filter((item) => moment(item.index.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"));

            //Remove ignored codes from segmentCodes list
            let currTabSegmentCodes = [];
            // debugger;
            segmentCodes.forEach((code) => {
                if (segments.arrUnique.includes(code)) {
                    let uniq = [...new Set(segments.marketSegments[code])];
                    // uniq.splice(0,1);
                    // currTabSegmentCodes = [...uniq];
                    currTabSegmentCodes.push(code);
                }
            });
            // currTabSegmentCodes.splice(0,1);
            currTabSegmentCodes.map((segmentCode, segmentIndex) => {
                let data_columns = [];
                let segmentName = segmentCode;
                let segmentKey = "Segment_" + segmentCode;
                columns.map(col => {
                    let { mainKey } = col;
                    let colId = col.id;
                    let dataObj = {};
                    dataObj.id = col.id;
                    if (col.type === 'dateColumn') {
                        dataObj = setUpDate(dataObj, date);
                        // if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = segmentCode; //segments.map[segmentCode];
                        // }
                        if (colId === "staydates") {
                            totalObjData[colId] = setUpDate(totalObjData, date);
                            totalObjData[colId]["segment"] = "Total";
                        }
                    }
                    if (dataRecord && dataRecord.length && !dataRecord[0][segmentKey]) {
                        let to = {};
                        let total = dataRecord[0].HotelTotal;
                        let identities = Object.keys(total);

                        identities.forEach((k) => {
                            to[k] = deepCopy(blankObj);
                        });
                        dataRecord[0][segmentKey] = to;
                        //  debugger;
                    }
                    if (col.type === 'dataColumn') {
                        totalObjData[colId] = (totalObjData[colId] && totalObjData[colId] !== 'undefined') ? totalObjData[colId] : { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

                        col.subcolumns.map(key => {
                            if (mainKey === "variance_pickup") {
                                if (key.value === "ADR") {
                                    let revenue = dataRecord[0][segmentKey][dataKey1].Revenue - dataRecord[0][segmentKey][dataKey2].Revenue;
                                    let rooms = dataRecord[0][segmentKey][dataKey1].Rooms - dataRecord[0][segmentKey][dataKey2].Rooms;
                                    dataObj[key.value] = getAdrValue(revenue, rooms, 'f');
                                    // debugger; 
                                } else if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][dataKey1][key.value] - dataRecord[0][segmentKey][dataKey2][key.value])
                                    // totalObjData[colId][key.value] += dataObj[key.value];
                                } else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey][dataKey1][key.value] - dataRecord[0][segmentKey][dataKey2][key.value])
                                    // totalObjData[colId][key.value] += dataObj[key.value];

                                }
                            } else if (mainKey === "difference_sdly") {
                                let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                                if (key.value === "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][dataKey1][key.value] - dataRecord[0][segmentKey][dayDateKey][key.value])
                                    totalObjData[colId][key.value] += dataObj[key.value];

                                } else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey][dataKey1][key.value] - dataRecord[0][segmentKey][dayDateKey][key.value])
                                    totalObjData[colId][key.value] += dataObj[key.value];

                                }
                            } else if (mainKey === "sdly_pickup_diff") {
                                let dayDateKey = (dayDate === "day") ? "sdly" : "sdtly";
                                let dayDate_sdly_actual = (dayDate === "day") ? "sdly_actual" : "sdtly_actual";
                                dataObj[key.value] = round(dataRecord[0][segmentKey][dayDate_sdly_actual][key.value] - dataRecord[0][segmentKey][dayDateKey][key.value])
                                totalObjData[colId][key.value] += dataObj[key.value];
                            } else if (mainKey === "marketprimary") {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = 0;//convertToPercent(marketData[index]["MarketTotal"]["primary"][key.value]);
                                } else {
                                    dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["primary"][key.value]);
                                }
                                totalObjData[colId][key.value] += dataObj[key.value];
                            } else if (mainKey === "marketsdly") {
                                if (key.value == "Occ") {
                                    dataObj[key.value] = 0;//convertToPercent(marketData[index]["MarketTotal"]["sdly"][key.value]);
                                    totalObjData[colId][key.value] += dataObj[key.value];
                                } else {
                                    dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["sdly"][key.value]);
                                    totalObjData[colId][key.value] += dataObj[key.value];
                                }
                            } else if (mainKey === "market_sdly_diff") {
                                dataObj[key.value] = 0;//round(marketData[index]["MarketTotal"]["primary"][key.value] - marketData[index]["MarketTotal"]["sdly"][key.value])
                                totalObjData[colId][key.value] += dataObj[key.value];
                            } else {
                                if (mainKey === "primary") {
                                    mainKey = dataKey1;
                                } else if (mainKey === "pickup") {
                                    mainKey = dataKey2;
                                } else {
                                    mainKey = mainKey;
                                }
                                mainKey = getDayDateKey(dayDate, mainKey);
                                if (key.value == "Occ") {
                                    dataObj[key.value] = convertToPercent(dataRecord[0][segmentKey][mainKey][key.value]);
                                    totalObjData[colId][key.value] += dataObj[key.value];
                                } else if (key.value === "ADR") {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey][mainKey][key.value]);
                                    primaryRoomstotal += dataRecord[0][segmentKey][mainKey]['Rooms'];
                                    primaryRevenueTotal += round(dataRecord[0][segmentKey][mainKey]['Revenue']);
                                    let adrVal = round(primaryRevenueTotal) / round(primaryRoomstotal);
                                    console.log(adrVal, " ", primaryRevenueTotal, " ", primaryRoomstotal);
                                    totalObjData[colId][key.value] = adrVal;
                                } else {
                                    dataObj[key.value] = round(dataRecord[0][segmentKey][mainKey][key.value]);
                                    if (isNaN(dataObj[key.value])) dataObj[key.value] = 0;
                                    totalObjData[colId][key.value] += checkProperValue(dataObj[key.value]);

                                }
                            }
                        })
                    }
                    data_columns.push(dataObj);
                })
                setup_data.push(data_columns);
            })

            if (!dates[dateIndex + 1] || dates[dateIndex + 1] !== date) {

                let tempArr = [];
                let totalObjDataCopy = deepCopy(totalObjData);
                // debugger;
                totalColumns.push({ id: 'staydates', date: totalObjDataCopy.date, dow: totalObjDataCopy.dow, segment: 'Total' });
                for (let k in totalObjDataCopy) {
                    if (k !== "date" && k !== "dow" && k !== "segment" && k !== "staydates") {
                        let tempObj = {};
                        tempObj.id = k;
                        tempObj = { ...tempObj, ...totalObjDataCopy[k] }
                        totalColumns.push(tempObj);
                    }
                }

                // if (ui_state.reports_dpbs.selectedSegment === 'All') {
                setup_data.push(totalColumns);
                // }
                totalObjData = {};
                totalObj = {};
                currentDate = date;
                totalColumns = [];
            }
        })
    }
    // console.log("here ", setup_data);
    // debugger
    return setup_data;
}


export function getPickupReportData_topSegment(columns, auth, app_state, segmentCodes, ui_state) {
    let data = (app_state.workspace_controls.segmented_view) ? [] : deepCopy(dataService.getSeriesById("OSRSegment").data.data);
    let marketData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];

    const { active_tiles, table_filters } = ui_state;
    const filteredDow = table_filters.table_reports.dow.map(d => d.code);
    data = data.filter((item) => filteredDow.includes(moment(item.index.date).format('ddd').toLocaleLowerCase()));
    marketData = marketData.filter((item) => filteredDow.includes(moment(item.index.date).format('ddd').toLocaleLowerCase()));



    let setup_data = [];
    if (data) {
        let dataKey = "HotelTotal";
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = {};
                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, item.index.date);
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.index.segment;
                    }
                }

                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (mainKey === "variance_pickup") {
                            let keyPrimaryTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "primary", "osr");
                            let keyPickupTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "pickup", "osr");

                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keyPickupTotalValue)
                            } else {
                                dataObj[key.value] = round(keyPrimaryTotalValue - keyPickupTotalValue)
                            }
                        } else if (mainKey === "difference_sdly") {
                            let keyPrimaryTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "primary", "osr");
                            let keySdlyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly", "osr");

                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(keyPrimaryTotalValue - keySdlyTotalValue)
                            } else {
                                dataObj[key.value] = round(keyPrimaryTotalValue - keySdlyTotalValue)
                            }
                        } else if (mainKey === "sdly_pickup_diff") {
                            let keySdlyActualTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly_actual", "osr");
                            let keySdlyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, "sdly", "osr");
                            dataObj[key.value] = round(keySdlyActualTotalValue - keySdlyTotalValue)
                        }
                        else if (mainKey === "marketprimary") {
                            let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValue_market);
                            } else {
                                dataObj[key.value] = round(keyTotalValue_market);
                            }
                        } else if (mainKey === "marketsdly") {
                            let keyTotalValueSdly_market = 0;// getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");

                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValueSdly_market);
                            } else {
                                dataObj[key.value] = round(keyTotalValueSdly_market);
                            }
                        } else if (mainKey === "market_sdly_diff") {
                            let keyTotalValue_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            let keyTotalValueSdly_market = 0;//getSegmentKeyTotal(marketData[index], key.value, segmentCodes, "primary","market");
                            dataObj[key.value] = round(keyTotalValue_market - keyTotalValueSdly_market)
                        }
                        else {
                            let keyTotalValue = getSegmentKeyTotal(item, key.value, segmentCodes, mainKey, "osr");
                            if (key.value == "Occ") {
                                dataObj[key.value] = convertToPercent(keyTotalValue);
                            } else {
                                dataObj[key.value] = round(keyTotalValue);
                            }
                        }
                    })
                }
                dataObj.id = col.id;
                data_columns.push(dataObj);
            })

            data_columns.map((itmArr, idx) => {
                if (itmArr['date'] == "" || itmArr['segment'] === "Total") {
                    data_columns[idx]["ignore_sort"] = true;
                }
            })
            setup_data.push(data_columns);
        })
    }
    return setup_data;
}

// function is used to calculate the total of all passed key basis on the the segement
const getSegmentKeyTotal = (item, key, segmentCodes, mainKey, segmentType = "osr", segmentTotalRow = "") => {
    let keyTotal = 0;
    let revenueTotal = 0;
    let roomsTotal = 0
    // if (segmentTotalRow === "Total" && key === 'ADR') debugger;
    if (segmentTotalRow && segmentTotalRow === "Total") {
        if (key !== "SellRate") {
            // keyTotal = item["HotelTotal"][mainKey][key];
            console.log(item, mainKey);
            let unMappedTotal = (item['Segment_UNMAPPED'] && item['Segment_UNMAPPED'][mainKey][key]) ? item['Segment_UNMAPPED'][mainKey][key] : 0;
            if (key === 'ADR' && item['Segment_UNMAPPED']) {
                let roomData = item["HotelTotal"][mainKey]['Rooms'] - item['Segment_UNMAPPED'][mainKey]['Rooms'];
                let revenueData = item["HotelTotal"][mainKey]['Revenue'] - item['Segment_UNMAPPED'][mainKey]['Revenue'];
                keyTotal = revenueData / roomData;
            } else {
                keyTotal = item["HotelTotal"][mainKey][key] - unMappedTotal;
            }
        } else {
            keyTotal = 0;
        }
        return keyTotal;
    } else {

        if (segmentCodes && segmentCodes.length > 0) {
            segmentCodes.map((code, index) => {
                let segmentCode = (segmentType === "market") ? "MarketSegment_" + code : "Segment_" + code;
                if (key === "ADR") {
                    revenueTotal += (item[segmentCode] && item[segmentCode][mainKey]["Revenue"]) ? item[segmentCode][mainKey]["Revenue"] : 0;
                    roomsTotal += (item[segmentCode] && item[segmentCode][mainKey]["Rooms"]) ? item[segmentCode][mainKey]["Rooms"] : 0;
                } else {
                    keyTotal += (item[segmentCode] && item[segmentCode][mainKey][key]) ? item[segmentCode][mainKey][key] : 0;
                }
            })
            if (key === "ADR") {
                keyTotal = revenueTotal / roomsTotal;
            }
            return keyTotal;
        }

    }

}