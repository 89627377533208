import React, { useEffect, useRef, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle, Box } from '@mui/material';
import TablePricing from "./TablePricing";
import TableCommon from "./TableCommon";
import TableReports from "./TableReports";
import TableRestrictions from "./TableRestrictions";
import TableRestrictionsRoomType from "./TableRestrictionsRoomType";
import TableIntelimerge from "./TableIntelimerge";

import { getPermissions, deepCopy, getFromState, aggregateSegmentedGroupedData } from '../../app/util';
import { TILES } from "../../app/config";
// import "./style.css";



function expandLeftRightScrollEdge() {

    let tableScrollEle = undefined;
    let tableScrollEleSize = undefined;
    let tableEle = undefined;
    let tableEleSize = undefined;
    let hiddenAreaA = 0;
    let totalScrollWidth = 0;
    let speed = 2;
    let leftRightScrollEdgeSize = 100;
    document.ondragstart = (event) => {
        tableScrollEle = document.querySelector(".table-scrollable");
        tableScrollEleSize = tableScrollEle?.getBoundingClientRect();
        tableEle = document.querySelector("#mainTable");
        tableEleSize = tableEle.getBoundingClientRect();
        hiddenAreaA = Math.abs(tableEleSize.left);
        totalScrollWidth = Math.abs(tableEleSize.width - tableScrollEleSize.width);
    }
    document.ondrag = (event) => {
        if (event.clientX < leftRightScrollEdgeSize && event.clientX !== 0) {
            if (hiddenAreaA > 0) {
                hiddenAreaA = hiddenAreaA - speed
                tableScrollEle.scroll({
                    top: 0,
                    left: hiddenAreaA,
                    behavior: "smooth",
                })
            }
        }
        if (event.clientX > tableScrollEleSize.width - leftRightScrollEdgeSize && event.clientX !== 0) {
            if (hiddenAreaA < totalScrollWidth) {
                hiddenAreaA = hiddenAreaA + speed
                tableScrollEle.scrollTo({
                    top: 0,
                    left: hiddenAreaA,
                    behavior: "smooth",
                })
            }
        }
    }

}
function DynamicTable({ compDisplayData }) {
    // debugger;

    const { auth } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { active_tiles } = ui_state;
    const { permissions, index } = getPermissions(profile.property_details, app_state.property_id);
    // const displayData = deepCopy(compDisplayData);
    let [displayData, setDisplayData] = useState(compDisplayData);
    const tile = TILES[active_tiles[0]];
    let wrapperClass = 'tile-' + active_tiles.join(' tile-');
    let isolatedTiles = ['pricing', 'restrictions'];
    let infoText = '';

    if (ui_state.active_tiles[0] === 'pricing' && permissions && permissions.rate_push === 0) {
        infoText = 'Pricing is not enabled for this data source! Please contact success@lodgiq.com for further details.';
    }

    const hasInfoBox = () => {

        if (ui_state.active_tiles[0] === 'pricing' && permissions && permissions.rate_push === 0) {
            return true;
        }
        return false;
    }

    const hasInfoBoxTable = () => {
        let infoText = false;
        if (ui_state.active_tiles[0] === 'forecast' && permissions.hfc === 0) {
            infoText = 'System forecast is not enabled for this property! Please contact success@lodgiq.com for further details.';
        }

        if (ui_state.active_tiles[0] === 'market_sell_rates' && ((compDisplayData.data && compDisplayData.data.length <= 0) || (compDisplayData[0].data && compDisplayData[0].data.length <= 0))) {
            infoText = 'Market Sell Rates are not available for this Locale, Please contact success@lodgiq.com for further details.';
        }

        return infoText;
    }
    expandLeftRightScrollEdge();
    if (tile.type === 'report') {
        return (<div className={'table-reports ' + wrapperClass}>
            {active_tiles.length === 1 && <TableReports compDisplayData={displayData} />}
        </div>);
    } else if (hasInfoBox()) {
        return (<Box sx={{ p: 1 }}>
            <Alert severity="info">
                <AlertTitle>Info</AlertTitle>
                {infoText}
            </Alert></Box>)
    } else {
        let restrictionsTable = (<TableRestrictions compDisplayData={displayData} />)
        if (app_state.workspace_controls.isRestrictionsRoomType == true) {
            wrapperClass += " roomtype"
            restrictionsTable = (<TableRestrictionsRoomType compDisplayData={displayData} />)
        }
        return (<>
            {active_tiles.length === 1 && !isolatedTiles.includes(active_tiles[0]) && displayData.length && <div className={'table-common ' + wrapperClass}><TableCommon compDisplayData={displayData} /></div>}
            {active_tiles.length === 1 && active_tiles[0] === 'restrictions'
                && permissions.restrictions == 0 && <Box sx={{ p: 1 }}>
                    <Alert severity="info">
                        <AlertTitle>Info</AlertTitle>
                        Restrictions is not enabled for this Data Source! Please contact success@lodgiq.com for further details.</Alert></Box>}
            {active_tiles.length === 1 && active_tiles[0] === 'restrictions'
                && permissions.restrictions == 1 && <div className={'table-restrictions ' + wrapperClass} >
                    {restrictionsTable}</div>}

            {active_tiles.length === 1 && active_tiles[0] === 'pricing' && <div className={'table-pricing ' + wrapperClass}><TablePricing compDisplayData={displayData} /></div>}

            {!hasInfoBoxTable() && active_tiles.length > 1 && <div className={'table-merged ' + wrapperClass}><TableIntelimerge compDisplayData={displayData} /></div>}
            {hasInfoBoxTable() && active_tiles.length > 1 && <Box sx={{ p: 1 }}>
                <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    {hasInfoBoxTable()}</Alert></Box>}

        </>);
    }
}




export default memo(DynamicTable);