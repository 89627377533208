import { API_BASE_URL, API_REGISTRY, api_status, promises, ABORT_REQUEST_CONTROLLERS, APP_CACHE } from "../app/config";
import { deepCopy, getFromSession, setAbortSignal, alterApiParams } from "../app/util";
import { updateAppState } from '../actions/appstate';
import moment from "moment";
// import worker from '../workers/app.workers';
// import WebWorker from '../workers/web.worker';
import IndexedDBManager from "../indexed-db/indexed-db";
import { getPropertyDetails } from "../components/DynamicTable/table-functions";
const idbManager = new IndexedDBManager();

const handleWorkerApiCall = (() => {
  let workers = undefined;
  let acceptedAPIs = ['OSR', 'OSRContrainedForecast', 'GetRestrictionsRoomType', 'SwitchProperty'];
  let ignoreFor365 = ['OSRSegment', 'SwitchProperty', 'OSRBookingPaceSegment', 'OSRBookingPaceSegmentDBA', 'MarketDba', 'GetFlights', 'GetBudget', 'ManualForecast', 'GetRatePlan', 'GetPaceRatePlan', 'CompsetBookingPace'];
  let inprogressApiCall = [];
  let completedApiCalls = [];

  if (!workers) {
    workers = new Worker('/workers/app.workers.js');
  }
  workers.addEventListener('message', (event) => {
    const { active_api, response, api, as_of_date, idbStoreKey } = event.data;
    console.log("event.data ", event.data);
    // below condition is to set data for 1 year in OSRYEAR registry 
    // if(active_api && active_api === 'OSR'){
    //   updateDataCache("OSRYear", { data: response, loaded: true, loading: false, reqParams: null });
    //   updateDataCache("OSRYearGroup", { data: response, loaded: true, loading: false, reqParams: null });
    // }
    //debugger;
    //Do some task after API successfully fetched data
    if (!completedApiCalls.includes(active_api)) {

      inprogressApiCall.splice(inprogressApiCall.indexOf(active_api), 1);
      completedApiCalls.push(active_api);
      const key = idbStoreKey;
      // try {
      //localStorage.setItem(key, data_store[key]);         
      idbManager.openDB()
        .then(() => {
          if (response.status === 'success') {
            response.ok = true;
            response.data_id = active_api;
            //Adding this for to avoid daily flash api calls for the same data
            if (['OSR', 'OSRContrainedForecast'].includes(active_api)) {
              let apc = APP_CACHE;
              apc.yearDataStaus = apc.yearDataStaus ? apc.yearDataStaus : {};
              apc.yearDataStaus[active_api + 'Year'] = 'loaded';
              if (response.data) {
                updateDataCache(active_api + 'Year', { data: deepCopy(response) });
              }
              // debugger;
            }
            if (key) {
              if (response.hasOwnProperty('data') && response.data) {
                return idbManager.addData({ response: response, api: api, id: key });
              }
              if (!response.hasOwnProperty('data')) {
                return idbManager.addData({ response: response, api: api, id: key });
              }
            }
          }
          //  Added to fix daily flash report popup data loading loop issue 
          // as we are waiting for surccess of osr and forecast yearly data there
          if (response.status === 'error') {
            if (['OSR', 'OSRContrainedForecast'].includes(active_api)) {
              let apc = APP_CACHE;
              apc.yearDataStaus = apc.yearDataStaus ? apc.yearDataStaus : {};
              apc.yearDataStaus[active_api + 'Year'] = 'loaded';
            }
          }

        })
        .then((data) => {
          // console.log('Data', data);
          idbManager.closeDB();
        })
        .catch((error) => {
          console.error('Error:', error);

          idbManager.closeDB();
        });

      // } catch (err) {
      //   console.error(err);
      // }
      // dispatch(updateAppState("UPDATE_STORE", {data_store: data_store}));
      //console.log('workers onmessage event', event.data);          
    }
  });

  return (params) => {
    const { type, idbStoreKey, timeout, api, request, active_api, active_apis, token, property_id, as_of_date, dispatch, comp_date_from } = params;

    // let tmp = alterApiParams('https://rm-api-dev-v4.lodgiq.com/rms/properties/otb/440/?includeSellRate=true&segment=true&sdYear=2022&pickupDays=1&includeSdly=true&asOfDate=2023-12-23&fromDate=2023-12-01&toDate=2023-12-31', {fromDate:'2023-12-23'})
    let { store_in_idb } = getSeriesById(active_api);
    if (!inprogressApiCall.includes(active_api) && store_in_idb !== false) {
      inprogressApiCall.push(active_api);
      completedApiCalls.splice(completedApiCalls.indexOf(active_api), 1);
      setTimeout(() => {
        let daystoAdd = 365;
        //if (['GetRatePlan', 'OSRRateRecommend'].includes(active_api)) daystoAdd = 182;

        let fromDate = new moment(as_of_date).startOf('month').format('YYYY-MM-DD');
        // let toDate = new moment(fromDate).add(daystoAdd, 'day').format('YYYY-MM-DD');
        let toDate = new moment(fromDate).add(12, 'month').endOf('month').format('YYYY-MM-DD');
        let compFromStayDate = comp_date_from;
        let compToStayDate = new moment(compFromStayDate).add(365, 'day').format('YYYY-MM-DD');

        let altered_api = alterApiParams(api, { fromDate: fromDate, compFromStayDate, compToStayDate, fromStayDate: fromDate, toDate: toDate, toStayDate: toDate, dbaFilter: 365 });

        workers.postMessage({ type: 'fetchData', idbStoreKey, token, timeout, api: API_BASE_URL + altered_api, request, active_api, active_apis, as_of_date });

      }, 2 * 1000);
    }
  }
})();

const fetchData =
  //function ({ api, request, active_api }) {
  async (params) => {
    const { api, active_api, active_apis } = params;
    const request = deepCopy(params.request);
    let token = getFromSession("token");
    // request.token_string = getFromSession("token");
    // request.token = request.token ? request.token : getFromSession("token");
    let REQUEST_URL = api.search("://") === -1 ? API_BASE_URL + api : api;
    // debugger;
    let method = '';
    if (request && request.hasOwnProperty('method')) {
      method = request.method;
      delete request.method;
    } else {
      method = 'POST';
    }

    // debugger;
    return new Promise((reslove, reject) => {
      if (method === 'GET' && request.query) {
        // let urlParams = new URLSearchParams(request.query).toString();
        //REQUEST_URL += request.resourceId+"?"+urlParams;
      }

      let abortSignal = setAbortSignal(active_api, true);
      let abrc = ABORT_REQUEST_CONTROLLERS.get(active_api).signal;

      // debugger;
      fetch(REQUEST_URL, {
        signal: abortSignal,
        method: method, // *GET, POST, PUT, DELETE, etc.
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Token " + token,
          //'Access-Control-Allow-Origin':'*'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        //referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: method === 'GET' ? null : JSON.stringify(request), // body data type must match "Content-Type" header
      })
        .then((original) => {
          // debugger;
          console.log("API response for ", active_api)
          let t = active_api;

          try {
            let resJson = async () => await original.json();
            if (original.ok && original.status === 200) {

              return resJson();

            } else {
              let rs = { status: 'error', response: resJson(), data_id: active_api, data: null, message: "Data loading failed" }
              reject(rs);
              // debugger;
              // promises[active_api + '_callback_error'](rs);
              // return Promise.reject(rs);
            }
          } catch (e) {
            let rs = { status: 'error', response: original, data_id: active_api, data: null, message: e.toString() }
            reject(rs);
          }


        })
        .then((resData) => {
          // if (active_api==='MarketForecastSegmented') debugger;
          let ignoreStatus = ['SystemSettings', 'SwitchProperty'];
          if (!resData) {
            reject({ status: 'error', data: null, message: "Failed!" });
          } else if (resData && ignoreStatus.indexOf(active_api) > -1) {
            let reqParams = { api, request };
            let finalData = { data: JSON.parse(JSON.stringify(resData)) };
            finalData.data_id = active_api;
            finalData.ok = true;
            updateDataCache(active_api, { data: finalData, loaded: true, loading: false, reqParams: reqParams });

            reslove(finalData);
          } else if (resData && resData.status.toLowerCase() !== 'success') {

            let rs = { status: 'error', data: null, message: "Response Failed!", data_id: active_api }
            updateDataCache(active_api, { data: null, loaded: false, loading: false, reqParams: null });
            //promises[active_api + '_callback_error'](resData.data);

            //adding this to reduce otb api calls
            if (active_api === 'OSR') {
              updateDataCache("OSRSegment", { data: null, loaded: true, loading: false, reqParams: null });
              updateDataCache("OSRYear", { data: null, loaded: true, loading: false, reqParams: null });
            }

            reject(rs);
          } else if (resData.status.toLowerCase() === 'success') {
            let reqParams = { api, request };
            let finalData = JSON.parse(JSON.stringify(resData));

            //Handling consistency for data sceince APIs data
            if (resData.data && resData.data.data
              && resData.data.constructor.name === 'Object'
              && resData.data.data.constructor.name === 'Array') {
              finalData = JSON.parse(JSON.stringify(resData.data));
              finalData.proxy = resData.proxy;
              finalData.requestTimestamp = resData.requestTimestamp;
              finalData.status = resData.status;
            }
            if (resData.data && resData.data.data
              && resData.data.constructor.name === 'Object'
              && resData.data.data.constructor.name !== 'Array') {
              finalData = JSON.parse(JSON.stringify(resData.data));
              finalData.data = [];
              finalData.proxy = resData.proxy;
              finalData.requestTimestamp = resData.requestTimestamp;
              finalData.status = 'error';
            }

            if ((REQUEST_URL.search('/api.v1/') !== -1)) {
              finalData = JSON.parse(JSON.stringify(resData.data));
              finalData.proxy = resData.proxy;
              finalData.requestTimestamp = resData.requestTimestamp;
              finalData.status = resData.status;
              //Storing in cache
              console.log("Forecast API response transformed", resData);
            }
            // return finalData;
            finalData.data_id = active_api;
            finalData.ok = true;
            updateDataCache(active_api, { data: finalData, loaded: true, loading: false, reqParams: reqParams });

            //adding this to reduce otb api calls
            if (active_api === 'OSR') {
              updateDataCache("OSRSegment", { data: finalData, loaded: true, loading: false, reqParams: reqParams });
              updateDataCache("OSRYear", { data: finalData, loaded: true, loading: false, reqParams: reqParams });
            }

            /** LRV-774 below code is used to set the capacity from Market otb API in capacity registry */
            if (active_api === "MarketARISegmented") {
              let capacityData = {}
              let dataSet = [];
              let marketData = deepCopy(finalData);
              if (marketData && marketData.data && marketData.data.length) {

                if (marketData.data[0] && marketData.data[0]['MarketTotal']
                  && marketData.data[0]['MarketTotal']['primary']
                  && marketData.data[0]['MarketTotal']['primary']['Capacity']) {
                  dataSet = [{ "Capacity": marketData.data[0]['MarketTotal']['primary']['Capacity'], "Month": moment().format('YYYY-MM') }];
                }

                capacityData.data_id = "MarketCapacity";
                capacityData.data = dataSet;
                capacityData.ok = true;
                capacityData.status = "success";
                updateDataCache("MarketCapacity", { data: capacityData, loaded: true, loading: false, reqParams: reqParams });
              }
            }
            /** LRV-774 END  */
            reslove(finalData);

            if (!promises[active_api + '_callback_success']) {
              //console.log("No callback function found for:", active_api);
              // return Promise.resolve(cached.data);
              // Promise.resolve(cached.data);
            }
            //promises[active_api + '_callback_success'](cached.data);  
          } else if (resData.status === 'success' && resData.data.status === 'failed') {
            reject({ status: 'error', data: [], message: resData.data.message, data_id: active_api });
          }
        }

        )
        .catch((err) => {
          // debugger;
          console.log("Error block for  API");
          reject({ status: 'error', data: err.toString(), message: "Response Failed!", data_id: active_api });
          //promises[active_api + '_callback_error'](err);
        });
    })
  };

const getData = ({ api, request, active_apis }) => {
  if (request.method && request.method === "GET") {
    return fetchData({ api, request, active_apis });
  }
  return fetchDataSimple({ api, request, active_apis });
}

const updateData = ({ api, request }) => {
  if (request.method && request.method === "GET") {
    return fetchData({ api, request });
  }
  return fetchDataSimple({ api, request });
}

const callAPIAgain = async (meta, resolve, reject) => {
  const { active_api, data_status, api, request, active_apis, selectedAPI, without_cache, activeSeries, property_id, as_of_date, idbStoreKey, comp_date_from, comp_date_until } = meta;
  let payloadCopy = JSON.parse(JSON.stringify({ api, request }));
  let oldPayloadCopy = selectedAPI.reqParams ? selectedAPI.reqParams : {};
  let cacheData = getSeriesById(selectedAPI.id);
  // if(active_api==="GetRestrictions") debugger;

  if (cacheData) {
    if (request.method !== "GET") {
      // const cacheData = getSeriesById(selectedAPI.id);
      if (JSON.stringify(oldPayloadCopy) === JSON.stringify(payloadCopy) && cacheData.data) {
        console.log("Data Found in cashe for " + active_api);
        cacheData.from_local_cache = true;
        updateDataCache(active_api, { from_local_cache: true, loaded: true, loading: false });
        return resolve(cacheData.data);
      }

      if (JSON.stringify(oldPayloadCopy) !== JSON.stringify(payloadCopy)) {
        return fetchData({ api, request, active_api, active_apis });
      }
    }
    if (request.method === "GET") {

      if (payloadCopy.api === oldPayloadCopy.api && !without_cache && cacheData.data) {
        cacheData.from_local_cache = true;
        updateDataCache(active_api, { from_local_cache: true, loaded: true, loading: false });
        return resolve(cacheData.data);
      }


      if ((payloadCopy.api !== oldPayloadCopy.api) || !cacheData.data) {
        const token = getFromSession("token");

        activeSeries.loading = true;
        activeSeries.loaded = false;
        api_status[active_api] = 'loading';

        //Call API with 365days of data in background using webworker
        if (!meta.stopYearlyData) { //Stoping app worker api call if date range is before current month or after 365day from start of current month
          // debugger;
          handleWorkerApiCall({ type: 'fetchData', token, api, request, active_api, active_apis, property_id, as_of_date, comp_date_from, comp_date_until, idbStoreKey });
        }

        // return fetchData({ api, request, active_api, active_apis });
        fetchData({ api, request, active_api, active_apis })
          .then(
            (rs) => {
              const cacheData = getSeriesById(selectedAPI.id);
              // debugger;
              resolve(rs);
            },
            (err) => {
              reject(err);
            });

      }
    }
  }

  // activeSeries.reqParams = { api, request };
  //updateDataCache(selectedAPI.id, {loading:true, loaded: false, reqParams: { api, request }})

  //Now call APIs
  //return fetchData({ api, request, active_api, active_apis });
}

const getFromCache = (id) => {
  if (id === "RoomTypePushStatusDateRange") {
    return false;
  }
  if (id === "RoomTypePushStatus") {
    return false;
  }
  if (id === "RestrictionsStatusDateRange") {
    return false;
  }
  if (id === "RestrictionsStatus") {
    return false;
  }
  if (id === "GetRestrictions") {
    return false;
  }
  if (id === "GetRestrictionsRoomType") {
    return false;
  }
  return true
}
const getDataDashboard = async ({ api, request, selectedAPI, active_api, active_apis, data_status, without_cache }, meta) => {
  let start = moment().format('x');
  if (api.indexOf('get_profile') > -1) {
    return fetchDataSimple({ api, request });
  }
  console.log('getDataDashboard called with active_api', active_api);
  const idbManager = new IndexedDBManager();
  // debugger;
  const { idbStoreKey } = meta;
  // request.token = getFromSession("token");
  // request.token_string = getFromSession("token");

  let activeSeries = '';
  if (selectedAPI) {
    //updateDataCache(selectedAPI.id, {loaded: false, loading:true, data:null});
    activeSeries = getSeriesById(selectedAPI.id);
  }


  //Return from cache if payload is same
  // if (activeSeries && activeSeries.data && activeSeries.reqParams) {
  if (activeSeries) {

    //check local storage for the data and resolve promise if it exists
    // let activeApi = ['OSRYear'].includes(active_api) ? 'OSR' : active_api;
    // let asOfDate = meta.as_of_date;
    // const localStorageKey = meta.property_id + '_' + asOfDate + '_' + activeApi;

    // let inLocalStorage = localStorage.getItem(localStorageKey);

    return new Promise((resolve, reject) => {

      if (!getFromCache(selectedAPI.id)) {
        without_cache = true;
        return callAPIAgain({ stopYearlyData: true, active_api, data_status, api, request, active_apis, selectedAPI, without_cache, activeSeries, ...meta }, resolve, reject);
      }

      idbManager.openDB()
        .then(() => {
          // console.log(`Data added with key: ${key}`);
          // debugger;
          return idbManager.getData(idbStoreKey, meta);
        })
        .then((storedItem) => {
          let apc = APP_CACHE;
          // Do required set of task if data is available in local db
          if (storedItem && storedItem.data_id === selectedAPI.id) {
            if (['OSR', 'MarketARISegmented'].includes(selectedAPI.id)) {
              // debugger;
              let dataLevel = selectedAPI.id === "MarketARISegmented" ? "markets" : "properties";
              let srcId = (dataLevel === "markets") ? meta.market_id : meta.property_id;
              // let asOfDateFinal = (moment(meta.as_of_date).isSame(meta.data_as_of)) ? meta.as_of_date : meta.data_as_of;
              // let asOfDateFinal = (moment(meta.as_of_date).isBefore(meta.data_as_of)) ? meta.as_of_date : meta.data_as_of;
              // let asOfDateFinal = (moment(meta.as_of_date).isBefore(meta.data_as_of)) ? meta.data_as_of : meta.as_of_date;
              let asOfDateFinal = moment(meta.data_as_of).isSameOrBefore(meta.as_of_date) ? meta.data_as_of : meta.as_of_date;
              if (!apc.dayDateToggle) {
                let api = "rms/" + dataLevel + "/get-sdly/" + srcId + "/?asOfDate=" + asOfDateFinal + "&fromDate=" + meta.date_from + "&toDate=" + meta.date_until + "&year=" + meta.sdYear;

                //Return if already exists
                const key = dataLevel + "/get-sdly/" + srcId + "/?asOfDate=" + asOfDateFinal + "&fromDate=" + meta.date_from + "&toDate=" + meta.date_until + "&year=" + meta.sdYear;
                if (!apc.compStayRange) apc.compStayRange = {};
                if (apc.compStayRange[key]) {
                  // let rs = {};
                  // let { as_of_date, from_date, to_date } = apc.compStayRange[key];
                  let { as_of_date, date_from, date_until } = apc.compStayRange[key];
                  let from_date = date_from;
                  let to_date = date_until;
                  storedItem.meta = { ...storedItem.meta, as_of: asOfDateFinal, sdly_asof: as_of_date, sdly_from: from_date, sdly_to: to_date };
                  updateDataCache(selectedAPI.id, { loaded: true, loading: false, data: deepCopy(storedItem) });
                  return resolve(storedItem);
                }
                fetchDataSimple({ api: api, request: { method: 'GET' } })
                  .then((rs) => {
                    let { as_of_date, from_date, to_date } = rs;
                    apc.compStayRange[key] = { as_of: meta.as_of_date, date_from: from_date, date_until: to_date, as_of_date: as_of_date, comp_as_of_date: as_of_date };

                    storedItem.meta = { ...storedItem.meta, as_of: asOfDateFinal, sdly_asof: as_of_date, sdly_from: from_date, sdly_to: to_date };
                    updateDataCache(selectedAPI.id, { loaded: true, loading: false, data: deepCopy(storedItem) });
                    return resolve(storedItem);

                    // LRV-880 uncomment the below code
                    // sdtly api //
                    // let apiSdtly = "rms/" + dataLevel + "/get-sdtly/" + srcId + "/?asOfDate=" + asOfDateFinal + "&fromDate=" + meta.date_from + "&toDate=" + meta.date_until + "&year=" + meta.sdYear;
                    // fetchDataSimple({ api: apiSdtly, request: { method: 'GET' } })
                    //   .then((result) => {
                    //     let { as_of_date, from_date, to_date } = result;

                    //     /** need to remove below bunch of code once sdly logic will fixed for leap year */
                    //     let marchFirst = moment(asOfDateFinal).month(2).date(1);
                    //     let isBeforeMarch = moment(asOfDateFinal).isBefore(marchFirst);
                    //     if (isBeforeMarch) {
                    //      let activeApiDetails = getPropertyDetails(active_api);
                    //       from_date = activeApiDetails?.meta?.sdtly_from;
                    //       to_date = activeApiDetails?.meta?.sdtly_to;
                    //     }
                    //     /** end - need to remove bunch of code once sdly logic will fixed for leap year */

                    //     // console.log(isBeforeMarch," from data service ",as_of_date, from_date, to_date );
                    //     storedItem.meta = { ...storedItem.meta, as_of: asOfDateFinal, sdtly_asof: as_of_date, sdtly_from: from_date, sdtly_to: to_date };
                    //     updateDataCache(selectedAPI.id, { loaded: true, loading: false, data: deepCopy(storedItem) });
                    //     return resolve(storedItem);
                    //   }, (err) => {
                    //     console.error("SDTLY api error ", err);
                    //   })
                    // LRV-880 uncomment the below code - end

                  }, (err) => {
                    console.error(err);
                  })
              } else {
                // debugger;
                storedItem.meta = meta.metas.osr ? meta.metas.osr : meta.metas;
                if (!storedItem.meta.as_of) { storedItem.meta.as_of = asOfDateFinal; }
                updateDataCache(selectedAPI.id, { loaded: true, loading: false, data: deepCopy(storedItem) });

                return resolve(storedItem);
              }
              apc.dayDateToggle = false;
            } else {
              updateDataCache(selectedAPI.id, { loaded: true, loading: false, data: deepCopy(storedItem) });

              // console.log("Time taken in idb data fetching...", moment().format('x') - start);

              return resolve(storedItem);
            }

          } else if (storedItem && storedItem.result === 'no-data') {
            //Cleaning js cached data for needed API registries
            let series = getSeriesById(active_api);
            if (["OSR"].includes(active_api)) {
              series.data = null;
            }

            // debugger;
            return callAPIAgain({ stopYearlyData: storedItem.stopYearlyData, active_api, data_status, api, request, active_apis, selectedAPI, without_cache, activeSeries, ...meta }, resolve, reject);
          }

        })
        .then(() => {
          idbManager.closeDB();
        })
        .catch((error) => {
          // debugger
          console.error('Error in reading indexed DB:', error);
          idbManager.closeDB();
        });
    });

  }
  //  else {

  //   return new Promise((resolve, reject) => {

  //     callAPIAgain({ active_api, data_status, api, request, active_apis, selectedAPI, without_cache, activeSeries, ...meta }, resolve, reject)
  //       .then((rs) => {
  //         resolve(rs);
  //         // debugger; 
  //       })

  //   });
  //   // return fetchData({ api, request, active_api, active_apis });
  // }
};



const getSeriesById = (id) => {
  let series = null;
  if (API_REGISTRY[id]) {
    const dataSeries = API_REGISTRY[id];
    return dataSeries;
  }
  return series;
};
window.getSeriesById = getSeriesById;

const updateDataCache = (key, val) => {
  // debugger;
  let cache = API_REGISTRY[key];
  if (cache) {
    API_REGISTRY[key] = { ...API_REGISTRY[key], ...val }
    return API_REGISTRY[key];
  }
  if (!cache) {
    API_REGISTRY[key] = val;
    return API_REGISTRY[key];
  }

};

const update = async ({ api, request }) => {

  console.log("api", api)
  console.log("request", request)
  if (request.hasOwnProperty("token_string")) {
    request.token = getFromSession("token");
  }
  const REQUEST_URL = api.search("://") === -1 ? API_BASE_URL + api : api;
  const method = !request.method ? 'POST' : request.method;
  delete request.method;
  const response = await fetch(REQUEST_URL, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Token " + getFromSession("token"),
      //'Access-Control-Allow-Origin':'*'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    //referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(request), // body data type must match "Content-Type" header
  });
  const data = await response.json();
  data.original = { status: response.status, statusText: response.statusText };
  return data;
};

const uploadFile = async ({ api, request }) => {
  console.log("api", api);
  console.log("request", request);

  if (request.hasOwnProperty("token_string")) {
    request.token = getFromSession("token");
  }

  const REQUEST_URL = api.search("://") === -1 ? API_BASE_URL + api : api;
  const method = request.method ? request.method : 'POST';

  if (request instanceof FormData) {
    // Do not set Content-Type header for FormData
    const response = await fetch(REQUEST_URL, {
      method: method,
      cache: "no-cache",
      headers: {
        "Authorization": "Token " + getFromSession("token"),
      },
      redirect: "follow",
      body: request, // send FormData directly
    });
    const data = await response.json();
    data.original = { status: response.status, statusText: response.statusText };
    return data;
  }
};


export const fetchDataSimple = async ({ api, request }) => {
  if (!request.token_string) {
    //request.token = getFromSession("token");
  }
  // debugger;
  const token = getFromSession("token");
  let REQUEST_URL = api.search("://") === -1 ? API_BASE_URL + api : api;
  const method = request.method ? request.method : "POST";
  delete request.method;
  let body = JSON.stringify(request);

  let headers = {
    "Content-Type": 'application/json',
    "Authorization": "Token " + token,

    // 'Content-Type': 'application/x-www-form-urlencoded',
  };
  if (request.formData) {
    body = request.formData;
    // contentType = 'multipart/form-data; boundary=<calculated when request is sent>'
    delete headers["Content-Type"];
  }


  if (method === "GET") {
    body = null;
    // REQUEST_URL += "?"+ encodeURIComponent(JSON.stringify(request));
    // debugger;
  }

  const response = await fetch(REQUEST_URL, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: headers,


    redirect: "follow", // manual, *follow, error
    //referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: body, // body data type must match "Content-Type" header
  });
  if (request.type === "blob") {
    const data = await response.blob();

    return data;
  }
  let data;
  try {
    data = await response.json();
  } catch (err) {
    data = { status: 'error', message: err.message };
  }


  data.original = { status: response.status, statusText: response.statusText };
  return data;
};

const callAPI = async ({ api, request }) => {
  const REQUEST_URL = api.search("://") === -1 ? API_BASE_URL + api : api;
  const method = request.method ? request.method : "POST";
  delete request.method;
  const requestBody = method === "GET" ? null : JSON.stringify(request);
  const response = await fetch(REQUEST_URL, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
      // "Authorization": "Token " + request.token,
      //'Access-Control-Allow-Origin':'*'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    //referrerPolicy: 'origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: requestBody, // body data type must match "Content-Type" header
  });
  const data = await response.json();
  data.original = { status: response.status, statusText: response.statusText };
  return data;
};

export default {
  getData, updateData,
  getDataDashboard,
  getSeriesById,
  updateDataCache,
  update, uploadFile,
  callAPI,
  fetchDataSimple
};
