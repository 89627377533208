//React
import React, { useState, useEffect } from 'react';

//React Redux
import { useDispatch, useSelector } from 'react-redux';

//MUI
import {
    Button, TextField
    , LinearProgress, CircularProgress, Tooltip
} from '@mui/material';
import { CloseSharp as CloseSharpIcon, Label } from '@mui/icons-material';
import { Box } from '@mui/system';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import { styled } from '@mui/material/styles';
//Moments
import moment from 'moment';

//APP's
import { SUPPORT_REQUEST_SUCCESS, SUPPORT_REQUEST_FAILED } from "../../actions/types";
import theme from '../../theme';
import DataService from "../../services/data.service";
import { API_BASE_URL } from "../../app/config";

//Styles
import './style.css';
import { getFromSession, replaceMultiText } from '../../app/util';

const RequestSupport = ({ handleClose, message = '', autoSubmit = false }) => {
    const color = theme.palette;
    const dispatch = useDispatch();
    const { auth, app } = useSelector(state => state);
    const selectedProperty = app.current_state.app_state.property_id;
    let { user: AuthUser } = auth;
    const [submitDisabled, setSubmitDisabled] = useState(autoSubmit ? 0 : 1);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState('Your request is sent successfully, Our team will get back to you soon!');
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState('');
    const [isrequesting, setIsrequesting] = useState(false);
    const [requestData, setRequestData] = useState({
        email: AuthUser.email,
        fullname: AuthUser.fullname,
        user_id: AuthUser.user_id,
        propertyIds: selectedProperty,
        subject: "Request Support",
        message: message,
        token_string: AuthUser.token_string,
        token: AuthUser.token_string
    });

    const [timeLeft, setTimeLeft] = useState(3);

    function submitRequestTicket(payload) {
        return DataService.getData({ api: 'users/send_user_feedback', request: payload })
            .then(
                (data) => {
                    //Do some task here just after respons
                    if (data.status === "success") {
                        setIsRequestSuccess(true);
                        dispatch({ type: SUPPORT_REQUEST_SUCCESS, payload: data });
                        return Promise.resolve(data);
                    }
                    else {
                        setIsRequestError(true);
                        setResErrorMsg(data.message);
                        dispatch({ type: SUPPORT_REQUEST_FAILED, payload: data });
                        return Promise.reject(data);
                    }
                }
            )
            .catch((err) => {
                setIsRequestError(true);
                setResErrorMsg(err.original.statusText || 'Something went wrong, please try again later!');
                dispatch({ type: SUPPORT_REQUEST_FAILED, payload: err });
                return Promise.reject(err);
            })
    }

    const submitFileData = async (payload) => {
        let formData = new FormData();
        let propertyIds = "[" + selectedProperty + "]";

        if (selectedImage) {
            let fNameExtension = selectedImage.name.split('.').pop();
            let newFileName = moment().format('MMM-DD_mm_ss') + "_" + propertyIds + "-Screenshot." + fNameExtension;
            formData.append('screenShot', selectedImage, newFileName);
        }
        formData.append("propertyIds", propertyIds);
        formData.append("message", payload.message);

        const token = getFromSession("token");
        let apiUrl = "users/feedback";
        let api = API_BASE_URL + "" + apiUrl;
        let res = await fetch(api, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Authorization": "Token " + token,
                // 'Content-Type': 'application/json',
            },
            redirect: "follow",
            body: formData,
        })
        const data = await res.json();
        if (data.status === "success") {
            setIsRequestSuccess(true);
            dispatch({ type: SUPPORT_REQUEST_SUCCESS, payload: data });
            return Promise.resolve(data);
        }
        else {
            setIsRequestSuccess(true);
            setRequestSuccessMsg('Something went wrong, please try again later!');
            dispatch({ type: SUPPORT_REQUEST_FAILED, payload: data });
            return Promise.reject(data);
        }
    }



    function updateRequestData(e, type) {
        if (type !== 'screenShot') {
            requestData[type] = e.target.value;
        }
        setRequestData({ ...requestData });
        if (requestData.fullname !== '' && requestData.email !== '' && requestData.message !== '') {
            setSubmitDisabled(0);
        } else {
            setSubmitDisabled(1);
        }
    }

    function handleSubmit(event) {
        if (event) {
            event.preventDefault();
        }
        setIsrequesting(true);
        let payload = requestData;
        // submitRequestTicket(payload)
        submitFileData(payload)
            .then((rs) => {
                setIsrequesting(false);
                setIsRequestSuccess(true);
            })
            .catch((err) => {
                setIsrequesting(false);
                setIsRequestError(true);
                setResErrorMsg(err.original.statusText);

            })
    }
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [selectedImage, setSelectedImage] = useState(null);
    const handleChangeImage = (e) => {
        setSelectedImage(e.target.files[0])
        updateRequestData(e.target.files[0], 'screenShot')
    }
    const handleRemoveImage = (e) => {
        setSelectedImage(null)
        delete requestData['screenShot'];
    }
    let wid = (isRequestSuccess) ? "18vw" : "38vw";

    useEffect(() => {
        const timer = setTimeout(() => {
            if (timeLeft > 0) {
                setTimeLeft(timeLeft - 1);
            } else {
                handleSubmit()
            }
        }, 1000);

        if (!autoSubmit) {
            clearTimeout(timer);
        }
        // Clear timeout if the component is unmounted or timeLeft becomes 0
        return () => clearTimeout(timer);
    }, [timeLeft]);

    useEffect(() => {
        // Check if isRequestSuccess is true
        if (isRequestSuccess) {
            // Set a timeout to close the popup after 3 seconds
            const timeoutId = setTimeout(() => {
                handleClose(); // Call your handleClose function here
            }, 3000);

            // Clean up the timeout on component unmount or if isRequestSuccess becomes false
            return () => clearTimeout(timeoutId);
        }
    }, [isRequestSuccess]);

    return (
        <Box className="request-support-popup" sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { lg: wid, xs: '90vw' }, height: 'auto',
            bgcolor: '#fff',
            boxShadow: 24, borderRadius: '8px'
        }}>
            <div className="pop-up-header clearfix request-popup-header">
                <Box sx={{ float: 'left' }}>
                    <div className="tile-title" data-tile-icon="icon-icon-events" data-id="events">

                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 256 256"><path d="M152.27,37.93a8,8,0,0,1,9.8-5.66,86.22,86.22,0,0,1,61.66,61.66,8,8,0,0,1-5.66,9.8A8.23,8.23,0,0,1,216,104a8,8,0,0,1-7.73-5.94,70.35,70.35,0,0,0-50.33-50.33A8,8,0,0,1,152.27,37.93Zm-2.33,41.8c13.79,3.68,22.65,12.54,26.33,26.33A8,8,0,0,0,184,112a8.23,8.23,0,0,0,2.07-.27,8,8,0,0,0,5.66-9.8c-5.12-19.16-18.5-32.54-37.66-37.66a8,8,0,1,0-4.13,15.46Zm81.94,95.35A56.26,56.26,0,0,1,176,224C96.6,224,32,159.4,32,80A56.26,56.26,0,0,1,80.92,24.12a16,16,0,0,1,16.62,9.52l21.12,47.15,0,.12A16,16,0,0,1,117.39,96c-.18.27-.37.52-.57.77L96,121.45c7.49,15.22,23.41,31,38.83,38.51l24.34-20.71a8.12,8.12,0,0,1,.75-.56,16,16,0,0,1,15.17-1.4l.13.06,47.11,21.11A16,16,0,0,1,231.88,175.08Zm-15.88-2s-.07,0-.11,0h0l-47-21.05-24.35,20.71a8.44,8.44,0,0,1-.74.56,16,16,0,0,1-15.75,1.14c-18.73-9.05-37.4-27.58-46.46-46.11a16,16,0,0,1,1-15.7,6.13,6.13,0,0,1,.57-.77L104,87.15l-21-47a.61.61,0,0,1,0-.12A40.2,40.2,0,0,0,48,80,128.14,128.14,0,0,0,176,208,40.21,40.21,0,0,0,216,173.07Z"></path></svg>

                        <div className="tile-name">Request Support</div>
                    </div>
                </Box>
                <Box sx={{ float: 'right' }}>
                    <Button className='cross-btn'
                        sx={{
                            minWidth: 'auto', width: '22px', height: '22px', p: '7px', borderRadius: '50%', backgroundColor: 'dark.darkest', color: 'white.main', fontSize: '10px', '&:hover': { background: '#000' }
                        }} onClick={handleClose}>
                        <CloseSharpIcon fontSize='small' />
                    </Button>
                </Box>
            </div>

            {isRequestSuccess &&
                <Box p={2}>
                    <Box gridColumn="span 12" sx={{ textAlign: "center", pt: 0.6, pb: 0.8 }}>
                        <svg width="45" height="37" viewBox="0 0 45 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.8533 34.628C16.7609 29.2687 15.6988 23.9854 14.5916 18.7093C14.4977 18.2572 14.2109 17.7679 13.8662 17.4597C9.92191 13.9229 5.94859 10.416 1.98326 6.90204C1.78213 6.72352 1.58332 6.54439 1.38281 6.36818C0.890179 5.94141 0.650346 5.42466 0.858485 4.78045C1.07828 4.10592 1.58588 3.80426 2.27361 3.74113C12.1425 2.8423 22.0102 1.93885 31.8791 1.04002C35.434 0.715479 38.9903 0.405396 42.5444 0.0687017C43.2582 0.00108241 43.8642 0.113121 44.2459 0.762468C44.633 1.42273 44.3822 2.00915 43.9793 2.5819C36.2433 13.5258 28.5107 24.4738 20.784 35.4251C20.354 36.0348 19.8538 36.4655 19.0824 36.3087C18.1518 36.1205 17.9632 35.3427 17.851 34.6286L17.8533 34.628ZM20.122 31.4043C26.8145 21.9267 33.3718 12.6362 40.0552 3.17102C28.4806 4.22773 17.1571 5.26168 5.63801 6.3134C9.07297 9.36124 12.3364 12.2671 15.6288 15.143C15.787 15.2811 16.2388 15.2417 16.4626 15.1174C18.0244 14.2564 19.5646 13.3517 21.1102 12.4579C23.144 11.2824 25.1713 10.0915 27.2134 8.92863C28.3656 8.27376 29.5495 8.84415 29.5655 10.039C29.5736 10.7612 29.104 11.1491 28.5342 11.4773C25.0275 13.4949 21.5368 15.5355 18.0199 17.5335C17.5069 17.8243 17.3635 18.1198 17.4973 18.665C17.71 19.5327 17.8713 20.4117 18.0517 21.288C18.7293 24.5827 19.4023 27.8786 20.126 31.4007L20.122 31.4043Z" fill="#37B24D" />
                        </svg>
                    </Box>
                    <Box className="success-msg">
                        {requestSuccessMsg}
                    </Box>

                </Box>

            }

            {!isRequestSuccess && <Box component="form" onSubmit={handleSubmit} noValidate sx={{ p: 2, pt: 1, position: 'relative', display: "flex", flexDirection: "column", gap: "16px" }}>
                <Box>
                    <lebel className="lebel">Name</lebel>
                    <TextField
                        className="form-input"
                        size='small'
                        placeholder='Name'
                        required
                        fullWidth
                        id="name"
                        //label="Name"
                        name="name"
                        read-only
                        autoComplete=""
                        value={requestData.fullname}
                        onChange={(e) => { updateRequestData(e, 'fullname') }}
                        sx={{ backgroundColor: 'white.main', borderRadius: 1, pt: "4px", cursor: "not-allowed" }}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Box>

                <Box>
                    <lebel className="lebel">Email Address</lebel>
                    <TextField
                        className="form-input"
                        size='small'
                        placeholder='Email'
                        required
                        fullWidth
                        id="email"
                        name="email"
                        autoComplete=""
                        //label="Email Address"
                        value={requestData.email}
                        onChange={(e) => { updateRequestData(e, 'email') }}
                        sx={{ backgroundColor: 'white.main', borderRadius: 1, pt: "4px", cursor: "not-allowed" }}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Box>

                <Box>
                    <lebel className="lebel">Enter your comments</lebel>
                    <TextField
                        id="message"
                        multiline
                        fullWidth
                        required
                        rows={2}
                        defaultValue=""
                        value={requestData.message}
                        onChange={(e) => { updateRequestData(e, 'message') }}
                        className="form-textarea"
                        sx={{ backgroundColor: 'white.main', borderRadius: 1, pt: "4px" }}
                    />
                </Box>

                <Box sx={{ textAlighn: 'center' }}>
                    {
                        (selectedImage && <div className='image-main-div'>
                            <img src={URL.createObjectURL(selectedImage)} height={150} width={150} alt="Thumb" style={{ borderRadius: "8px" }} />
                            <div className='image-cross-btn' onClick={handleRemoveImage}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.8">
                                        <rect width="14" height="14" rx="7" fill="white" />
                                        <path d="M7 1.3125C5.87512 1.3125 4.7755 1.64607 3.8402 2.27102C2.90489 2.89597 2.17591 3.78423 1.74544 4.82349C1.31496 5.86274 1.20233 7.00631 1.42179 8.10958C1.64124 9.21284 2.18292 10.2263 2.97833 11.0217C3.77374 11.8171 4.78716 12.3588 5.89043 12.5782C6.99369 12.7977 8.13726 12.685 9.17651 12.2546C10.2158 11.8241 11.104 11.0951 11.729 10.1598C12.3539 9.2245 12.6875 8.12488 12.6875 7C12.6859 5.49207 12.0862 4.04636 11.0199 2.98009C9.95365 1.91382 8.50793 1.31409 7 1.3125ZM9.05953 8.44047C9.10018 8.48112 9.13243 8.52937 9.15442 8.58248C9.17642 8.63559 9.18775 8.69251 9.18775 8.75C9.18775 8.80749 9.17642 8.86441 9.15442 8.91752C9.13243 8.97063 9.10018 9.01888 9.05953 9.05953C9.01889 9.10018 8.97063 9.13242 8.91752 9.15442C8.86441 9.17642 8.80749 9.18774 8.75 9.18774C8.69252 9.18774 8.63559 9.17642 8.58249 9.15442C8.52938 9.13242 8.48112 9.10018 8.44047 9.05953L7 7.61852L5.55953 9.05953C5.51889 9.10018 5.47063 9.13242 5.41752 9.15442C5.36441 9.17642 5.30749 9.18774 5.25 9.18774C5.19252 9.18774 5.13559 9.17642 5.08249 9.15442C5.02938 9.13242 4.98112 9.10018 4.94047 9.05953C4.89982 9.01888 4.86758 8.97063 4.84558 8.91752C4.82358 8.86441 4.81226 8.80749 4.81226 8.75C4.81226 8.69251 4.82358 8.63559 4.84558 8.58248C4.86758 8.52937 4.89982 8.48112 4.94047 8.44047L6.38149 7L4.94047 5.55953C4.85838 5.47744 4.81226 5.3661 4.81226 5.25C4.81226 5.1339 4.85838 5.02256 4.94047 4.94047C5.02256 4.85838 5.13391 4.81226 5.25 4.81226C5.3661 4.81226 5.47744 4.85838 5.55953 4.94047L7 6.38148L8.44047 4.94047C8.48112 4.89982 8.52938 4.86758 8.58249 4.84558C8.63559 4.82358 8.69252 4.81226 8.75 4.81226C8.80749 4.81226 8.86441 4.82358 8.91752 4.84558C8.97063 4.86758 9.01889 4.89982 9.05953 4.94047C9.10018 4.98112 9.13243 5.02937 9.15442 5.08248C9.17642 5.13559 9.18775 5.19251 9.18775 5.25C9.18775 5.30749 9.17642 5.36441 9.15442 5.41752C9.13243 5.47063 9.10018 5.51888 9.05953 5.55953L7.61852 7L9.05953 8.44047Z" fill="#606060" />
                                    </g>
                                </svg>
                            </div>
                        </div>)
                    }
                    {(!selectedImage && !autoSubmit && <>
                        <Tooltip title="Image size limit is 5MB" arrow followCursor><Button component="label" variant="outlined"
                            className='upload-icon'
                            startIcon={<FileUploadOutlinedIcon fontSize='18px' />}>
                            Upload Screenshot
                            <VisuallyHiddenInput type="file" onChange={handleChangeImage} />
                        </Button></Tooltip>
                    </>)}

                </Box>

                <Box sx={{ textAlighn: 'center' }}>
                    <Button
                        type="submit"
                        disabled={submitDisabled}
                        className="btn-submit"
                        fullWidth
                        variant="contained"
                        // sx={{ mt: 2, mb: 2, display: 'inherit',opacity: 0.9, fontSize: 16, '&:disabled': { backgroundColor: 'green.green', cursor: 'not-allowed' } }}
                        sx={{ display: 'inherit' }}
                        onClick={handleSubmit}
                    >
                        {autoSubmit ? `Sending Request (${timeLeft} secs)` : `Submit`}
                    </Button>

                </Box>

            </Box>}
            {isrequesting && (
                <Box sx={{ textAlighn: 'center', backgroundColor: 'dark.darkest', opacity: 0.8, position: 'absolute', top: 40, left: 0, width: '100%', height: '100%' }}>
                    <CircularProgress
                        size={40}
                        sx={{
                            color: 'white',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-20px',
                            marginLeft: '-20px',
                        }}
                    />
                </Box>

            )}
        </Box>
    )
}
export default RequestSupport;