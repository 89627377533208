import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/material';
import { Button, Divider, FormControlLabel, FormGroup, ListItem, Switch, TextField, Typography, Checkbox } from '@mui/material';
import { deepCopy, in_array } from '../../app/util';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../actions/appstate';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';





export default function FilterItems2(props) {
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { currentColumn, filteredColumns } = props;
    const [subcolumns, setSubcolumns] = React.useState([]);
    const [colOpen, setColOpen] = React.useState(false);
    const [filter, setFilter] = React.useState({ gte: "", lte: "" });
    const [disableSelectAll, setDisableSelectAll] = React.useState(true);
    
    const compRef = React.useRef({
        filter: {
            lte: '',
            gte: ''
        },
        fields: { gte: null, lte: null },
        toggledCols: [],
        columnToggleChanged: false
    });

    if (filteredColumns && filteredColumns.length) {
        filteredColumns.map(column => {
            if (column.value !== currentColumn.value) {
                compRef.current.filterExists = true;
            }
        });
    } else {
        compRef.current.filterExists = false;
    }
    if (filteredColumns && filteredColumns.length && compRef.current.toggledCols.length !== filteredColumns.length) {
        compRef.current.filterColumnExists = true;
    }
    if (filteredColumns && filteredColumns.length && compRef.current.toggledCols.length === filteredColumns.length) {
        compRef.current.filterColumnExists = false;
    }

    const clearFilterHandler = (ev, column) => {
        // debugger;
        column.filter = { lte: "", gte: "" };
        setFilter({ lte: "", gte: "" });
        props.clearFilterHandler(column);
    }
    const clearAllFilterHandler = (ev, column) => {
        // debugger;
        column.filter = { lte: "", gte: "" };
        setFilter({ lte: "", gte: "" });
        props.clearFilterHandler(column, "all");
    }
    // const applyFilterHandler = (ev, column) => {
    //     let newFilter = deepCopy(filter);
    //     // clearAllFilterHandler(null, column);
    //     column.filter = newFilter;
    //     setFilter(newFilter);
    //     // props.columnToggleHandler();
    //     // debugger;
    //     props.columnToggleHandler([column]);
    //     //props.applyFilterHandler(column, 'column');
    // }

    React.useEffect(() => {
        let isSubscribed = true;
        let mainCols = props.columnList;
        // debugger; 
        let subcols = [];
        mainCols.map(main => {
            // debugger
            main.subcolumns.map(subcol => {
                let isExisting = subcols.filter((col) => col.value === subcol.value);
                if (!isExisting.length) {
                    subcols.push(subcol);
                }
            });
        });
        console.log("subcols ", subcols);
        subcols = subcols.filter((itm) => (itm.type !== "restrictions" && itm.value !== "month" && itm.value !== "segment" && itm.value !== "dba"));
        // debugger;
        setSubcolumns(subcols);
        if (currentColumn.filter) {
            setFilter({ ...filter, gte: currentColumn.filter.gte, lte: currentColumn.filter.lte });
        };

        return () => (isSubscribed = false);
    }, []);

    //custom tab start
    const [value2, setValue2] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setValue2(newValue);
        //alert(newValue)
    };
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    
    // const columnToggleHandler = (col, index, checked) => {
    //     // let cp = props;
    //     // let { kpis, kpis_hidden } = ui_state.table_filters.table_common;
    //     // debugger;
    //     if (compRef.current.toggledCols.includes(col.value)) {
    //         let colIndex = compRef.current.toggledCols.indexOf(col.value);
    //         compRef.current.toggledCols.splice(colIndex, 1);
    //     } else {
    //         compRef.current.toggledCols.push(col.value);
    //     }
    //     compRef.current.columnToggleChanged = true;

    //     if (!compRef.current.toggledCols.length) {
    //         setColumnToggled(false);
    //     } else {
    //         setColumnToggled(true);
    //     }

    //     setSelectedAll(false);

    //     // props.columnToggleHandler(compRef.current.toggledCols);
    // }

    return (
        <List className='column-sort-container'
            sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}
            component="nav"
            dense={true}
            aria-labelledby="nested-list-subheader">
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <Tabs className="mapping-tabs" value={value2} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Column Filter" {...a11yProps(0)} />
                    <Tab label="Column Visibility" {...a11yProps(1)} />
                </Tabs>
            </Box>

            {/* Columns Filter Tab */}
            <CustomTabPanel className="custom-tab-panel" value={value2} index={0}>

                {currentColumn.filterable && <>

                    <GteLteFilter handleClose={props.handleClose} clearAllFilterHandler={props.clearFilterHandler} clearFilterHandler={props.clearFilterHandler} applyFilterHandler={props.applyFilterHandler} currentColumn={props.currentColumn} columnList={props.columnList} />

                    {/* <ListItemButton sx={{ p: 0, pt: 1, pb: 1, pl: 3, pr: 2 }}>

                        <ListItemText primary={"Greater Than Equal To "} />
                        <TextField
                            size="small"
                            sx={{ width: 80, "& input": { padding: "0 0 0 5px" } }}
                            // defaultValue={filter.gte}
                            value={filter.gte}
                            onChange={(ev) => {
                                // debugger;
                                setFilter({ ...filter, gte: Number(ev.target.value) });
                                // debugger;
                            }}
                            InputProps={{

                                style: { height: 26, width: 80 },
                                type: 'number',
                                // defaultValue: filter.gte,
                                // value:filter.gte,

                            }}>
                        </TextField>

                    </ListItemButton> */}

                    {/* <ListItemButton sx={{ p: 0, pt: 1, pb: 1, pl: 3, pr: 2 }}>

                        <ListItemText primary={"Less Than Equal To "} />
                        <TextField
                            size="small"
                            sx={{ p: 0, "& input": { padding: "0 0 0 5px" } }}
                            value={filter.lte}
                            onChange={(ev) => {
                                // debugger;
                                setFilter({ ...filter, lte: Number(ev.target.value) });
                            }}
                            InputProps={{
                                style: { width: 80, height: 26 },
                                type: 'number',
                            }}>
                        </TextField>

                    </ListItemButton> */}

                    {/* <Divider /> */}


                </>}
            </CustomTabPanel>

            {/* Columns Vsiblity Tab */}
            <CustomTabPanel className="custom-tab-panel" value={value2} index={1}>
                <ColumnToggle handleClose={props.handleClose}  colList={subcolumns} currentColumn={props.currentColumn} columnToggleHandler={props.columnToggleHandler} />

            </CustomTabPanel>


        </List>
    );
}

// export default function FilterItems2(props) {
//     const { currentColumn, filteredColumns } = props;
//     const [subcolumns, setSubcolumns] = React.useState([]);
//     const [open, setOpen] = React.useState(false);
//     const [colOpen, setColOpen] = React.useState(false);
//     const [filter, setFilter] = React.useState({ gte: "", lte: "" });
//     const compRef = React.useRef({
//         filter: {
//             lte: '',
//             gte: ''
//         }
//     });

//     if (filteredColumns && filteredColumns.length) {
//         filteredColumns.map(column => {
//             if (column.value !== currentColumn.value) {
//                 compRef.current.filterExists = true;
//             }
//         });
//     } else {
//         compRef.current.filterExists = false;
//     }
//     const handleClick = () => {
//         setOpen(!open);
//     };
//     const handleColClick = () => {
//         setColOpen(!colOpen);

//     };

//     const clearFilterHandler = (ev, column) => {
//         // debugger;
//         column.filter = { lte: "", gte: "" };
//         setFilter({ lte: "", gte: "" });
//         props.clearFilterHandler(column);
//     }
//     const clearAllFilterHandler = (ev, column) => {
//         // debugger;
//         column.filter = { lte: "", gte: "" };
//         setFilter({ lte: "", gte: "" });
//         props.clearFilterHandler(column, "all");
//     }

//     const applyFilterHandler = (ev, column) => {
//         let newFilter = deepCopy(filter);
//         // clearAllFilterHandler(null, column);
//         column.filter = newFilter;
//         setFilter(newFilter);
//         props.applyFilterHandler(column, 'column');
//         // debugger;
//     }



//     React.useEffect(() => {
//         let isSubscribed = true;
//         let mainCols = props.columnList;
//         // debugger; 
//         let subcols = [];
//         mainCols.map(main => {
//             // debugger
//             main.subcolumns.map(subcol => {
//                 let isExisting = subcols.filter((col) => col.value === subcol.value);
//                 if (!isExisting.length) {
//                     subcols.push(subcol);

//                 }
//             });
//         });
//         console.log("subcols ", subcols);
//         subcols = subcols.filter((itm) => (itm.type !== "restrictions" && itm.value !== "month" && itm.value !== "segment" && itm.value !== "dba"));
//         // debugger;
//         setSubcolumns(subcols);
//         if (currentColumn.filter) {
//             setFilter({ ...filter, gte: currentColumn.filter.gte, lte: currentColumn.filter.lte });
//         };

//         return () => (isSubscribed = false);
//     }, []);


//     return (
//         <List
//             sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}
//             component="nav"
//             dense={true}
//             aria-labelledby="nested-list-subheader">

//             {/* Columns Section  */}
//             <ListItemButton onClick={handleColClick}>
//                 <ListItemIcon sx={{ minWidth: "35px !important" }}>
//                     <ViewWeekIcon />
//                 </ListItemIcon>
//                 <ListItemText primary="Columns" />
//                 {colOpen ? <ExpandLess /> : <ExpandMore />}
//             </ListItemButton>

//             <Collapse in={colOpen} timeout="auto" unmountOnExit>
//                 <ColumnToggle colList={subcolumns} currentColumn={props.currentColumn} columnToggleHandler={props.columnToggleHandler} />
//             </Collapse>
//             {/* End Columns Section  */}

//             {currentColumn.filterable && <>
//                 <Divider />
//                 <ListItemButton onClick={handleClick}>
//                     <ListItemIcon sx={{ minWidth: "35px !important" }}>
//                         <FilterAltIcon />
//                     </ListItemIcon>
//                     <ListItemText primary={(<><strong>{currentColumn.display}</strong>  Filter</>)} />
//                     {open ? <ExpandLess /> : <ExpandMore />}
//                 </ListItemButton>

//                 {open && <Box timeout="auto" unmountOnExit>
//                     <ListItemButton sx={{ p: 0, pt: 1, pb: 1, pl: 3, pr: 2 }}>

//                         <ListItemText primary={"Greater Than Equal To "} />
//                         <TextField
//                             size="small"
//                             sx={{ width: 80, "& input": { padding: "0 0 0 5px" } }}
//                             // defaultValue={filter.gte}
//                             value={filter.gte}
//                             onChange={(ev) => {
//                                 // debugger;
//                                 setFilter({ ...filter, gte: Number(ev.target.value) });
//                                 // debugger;
//                             }}
//                             InputProps={{

//                                 style: { height: 26, width: 80 },
//                                 type: 'number',
//                                 // defaultValue: filter.gte,
//                                 // value:filter.gte,

//                             }}>
//                         </TextField>

//                     </ListItemButton>
//                     <ListItemButton sx={{ p: 0, pt: 1, pb: 1, pl: 3, pr: 2 }}>

//                         <ListItemText primary={"Less Than Equal To "} />
//                         <TextField
//                             size="small"
//                             sx={{ p: 0, "& input": { padding: "0 0 0 5px" } }}
//                             value={filter.lte}
//                             onChange={(ev) => {
//                                 // debugger;
//                                 setFilter({ ...filter, lte: Number(ev.target.value) });
//                             }}
//                             InputProps={{
//                                 style: { width: 80, height: 26 },
//                                 type: 'number',
//                             }}>
//                         </TextField>

//                     </ListItemButton>
//                     <Divider />
//                     <Box sx={{ textAlign: 'right', mt: 1, p: 0.5, pr: 2 }}>

//                         <Button disabled={!compRef.current.filterExists} variant="outlined" sx={{ mr: 1 }} size="small" onClick={(ev) => clearAllFilterHandler(ev, currentColumn)}>Clear All</Button>
//                         <Button disabled={!filter.gte && !filter.lte} variant="outlined" sx={{ mr: 1 }} size="small" onClick={(ev) => clearFilterHandler(ev, currentColumn)}>Clear</Button>
//                         <Button disabled={!filter.gte && !filter.lte} variant="outlined" size="small" onClick={(ev) => applyFilterHandler(ev, currentColumn)}>Apply</Button>
//                     </Box>
//                 </Box>}
//             </>}
//         </List>
//     );
// }

const ColumnToggle = (props) => {
    const dispatch = useDispatch();
    const state = useSelector(appState => appState);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let cols = props.colList;
    const compRef = React.useRef({ columnToggle: ui_state.table_filters.table_common.kpis, columnToggled: [], columnToggleChanged: false });
    // removing date and dow from the array
    cols = cols.filter((col) => (col.value !== 'date' && col.value !== 'dow'))

    let [columnToggle, setColumnToggle] = React.useState(cols);
    let [disabledApply, setDisabledApply] = React.useState(true);
    let [disableSelectAll, setDisableSelectAll] = React.useState(true);
    let switches = [];

    React.useEffect(() => {
        let hiddenCol = cols.find(col => col.hidden);
        if (hiddenCol) {
            setDisableSelectAll(false);
        }
    }, []);

    React.useEffect(() => {
        columnToggle.map((item, index) => {
            let checkedHiddenStatus = columnToggle[index].hidden
            let checkedHiddenStatus2 = in_array(ui_state.table_filters.table_common.kpis_hidden, [item.value])
            if(checkedHiddenStatus !== checkedHiddenStatus2){
                if (item && item.value && !compRef.current.columnToggle.includes(item.value)) {
                    compRef.current.columnToggle.push(item.value);
                    compRef.current.columnToggled.push(item);
                    let col = columnToggle.find(c => c.value === item.value);
                    col.hidden = checkedHiddenStatus2;
                }
            }
        });
        setColumnToggle(columnToggle);
    }, []);

    const handleChange = (ev, item, index, all) => {
        // debugger
        if (item && item.value && !compRef.current.columnToggle.includes(item.value)) {
            compRef.current.columnToggle.push(item.value);
            compRef.current.columnToggled.push(item);
            setDisabledApply(false);
        } else {
            let indexOfCol = compRef.current.columnToggle.indexOf(item.value);
            compRef.current.columnToggle.splice(indexOfCol, 1);
            compRef.current.columnToggled.splice(indexOfCol, 1);
            setDisabledApply(false);
        }

        let target = ev.target;
        setColumnToggle(() => {
            columnToggle[index].hidden = !target.checked;
            if (columnToggle.find(c => c.hidden)) {
                setDisableSelectAll(false);
            } else {
                setDisableSelectAll(true);
            }
            return [...columnToggle];
        });

        //props.columnToggleHandler(compRef.current.columnToggled, index, !target.checked);
    }

    const applyColumnToggleHandler = (ev) => {
        let { colList } = props;
        getKipsShow(columnToggle);
        setDisabledApply(true);
        props.columnToggleHandler(colList);
        props.handleClose();
    };

    const selectAllHandler = () => {
        cols.forEach((item, index) => {
            if (item && item.value && !compRef.current.columnToggle.includes(item.value)) {
                compRef.current.columnToggle.push(item.value);
                compRef.current.columnToggled.push(item);
                let col = columnToggle.find(c => c.value === item.value);
                col.hidden = false;
                //handleChange({target: {checked: false}}, item, index);
            }
        });
        setColumnToggle(columnToggle);
        setDisableSelectAll(true);
        setDisabledApply(false);
    };

    const getKipsShow = (columnToggle) => {
        let kpisArr = [];
        let kpiArrHidden = [];
        columnToggle.map((item) => {
            if (item.value !== 'date' && item.value !== 'dow') {
                if (!item.hidden) {
                    kpisArr.push(item.value);
                } else {
                    kpiArrHidden.push(item.value);
                }
            }
        });

        ui_state.table_filters.table_common.kpis = [...new Set(kpisArr)];

        ui_state.table_filters.table_common.kpis_hidden = [...kpiArrHidden];
        // ui_state.table_filters.table_common.kpis_hidden = [...ui_state.table_filters.table_common.kpis_hidden, ...kpiArrHidden];
        dispatch(updateAppState("change_filter", {
            // app_state: { data_load: true },
            ui_state: {
                ...ui_state,
                // showspinner: true
            }
        },"table"));

        console.log(" Updated global state is -> ", ui_state.table_filters.table_common);
    }

    // columnToggle.map((item, i) => {
    //     let checkedStatus = in_array(ui_state.table_filters.table_common.kpis, [item.value])
    //     switches.push(<ListItem>
    //         <FormControlLabel key={item.value + i} sx={{ mb: -1 }} value={item.value}
    //             control={<Switch disabled={props.currentColumn.value === item.value}
    //                 onChange={(ev) => { handleChange(ev, item, i) }} color="primary"
    //                 checked={checkedStatus} name={item.display} />}
    //             label={item.display} labelPlacement="start" />

    //     </ListItem>)
    // })

    columnToggle.map((item, i) => {
        let checkedStatus = in_array(ui_state.table_filters.table_common.kpis, [item.value]);
        checkedStatus = in_array(compRef.current.columnToggle, [item.value]);
        let segmentType = app_state.workspace_controls.segmentType; 
        let tile = ui_state.active_tiles[0];
        // debugger; 
        if(tile === "otb_v2_multi_segment" && (segmentType!=='rate_plan' || (segmentType==='rate_plan' && !['RevPAR', 'SellRate'].includes(item.value)))){
            switches.push(

                <label >
                    <Checkbox
                        className='dow-filter-checkbox'
                        disabled={props.currentColumn.value === item.value}
                        onChange={(ev) => {
                            handleChange(ev, item, i);
                        }}
                        checked={checkedStatus}
                        name={item.display}
                    />
                    <span className={`dow-filter-title ${checkedStatus ? 'active' : ''}`} style={props.currentColumn.value === item.value ? { opacity: "0.4" } : {}}>
                        {item.display}
                    </span>
                </label>
    
            );
        }
        if(tile !== "otb_v2_multi_segment") {
            switches.push(

                <label >
                    <Checkbox
                        className='dow-filter-checkbox'
                        disabled={props.currentColumn.value === item.value}
                        onChange={(ev) => {
                            handleChange(ev, item, i);
                        }}
                        checked={checkedStatus}
                        name={item.display}
                    />
                    <span className={`dow-filter-title ${checkedStatus ? 'active' : ''}`} style={props.currentColumn.value === item.value ? { opacity: "0.4" } : {}}>
                        {item.display}
                    </span>
                </label>
    
            );
        }
       
    });

    return (<>
        <FormGroup sx={{maxHeight:"30vh", overflowY:"auto", flexWrap:"nowrap"}}>{switches}</FormGroup>
        {/* Actions buttons */}

        <Box sx={{ m: -1, mt: 1, p: 1, display: "flex", justifyContent: "space-between", isolation: "isolate", boxShadow: "0 4px 0.75rem rgba(12, 14, 28, 0.3)", borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}>

            <Button className='filter-button secondary' disabled={disableSelectAll} onClick={selectAllHandler} variant="outlined" sx={{ mr: 1 }} size="small">Select All</Button>

            <Button className='filter-button primary' variant="outlined" size="small" disabled={disabledApply} onClick={(ev) => {
                applyColumnToggleHandler(ev)
                //applyFilterHandler(ev, currentColumn)
            }}>Apply</Button>
        </Box>
    </>);
}

const GteLteFilter = (props) => { 
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { currentColumn, filteredColumns, columnList } = props;
    const [hasOtherFilter, setHasOtherFilter] = React.useState(false);
    const [filter, setFilter] = React.useState({ gte: "", lte: "" });

    const compRef = React.useRef({
        filter: {
            lte: '',
            gte: ''
        },
        fields: { gte: null, lte: null },
        toggledCols: [],
        columnToggleChanged: false
    });

    if (filteredColumns && filteredColumns.length) {
        filteredColumns.map(column => {
            if (column.value !== currentColumn.value) {
                compRef.current.filterExists = true;
            }
        });
    } else {
        compRef.current.filterExists = false;
    }
    if (filteredColumns && filteredColumns.length && compRef.current.toggledCols.length !== filteredColumns.length) {
        compRef.current.filterColumnExists = true;
    }
    if (filteredColumns && filteredColumns.length && compRef.current.toggledCols.length === filteredColumns.length) {
        compRef.current.filterColumnExists = false;
    }

    const clearFilterHandler = (ev, column) => {
        // debugger;
        column.filter = { lte: "", gte: "" };
        setFilter({ lte: "", gte: "" });
        props.clearFilterHandler(column);
        props.handleClose();
    }
    const clearAllFilterHandler = (ev, column) => {
        // debugger;
        column.filter = { lte: "", gte: "" };
        setFilter({ lte: "", gte: "" });
        props.clearFilterHandler(column, "all");
        props.handleClose();
    }
    const applyFilterHandler = (ev, column) => {
        // debugger;
        let newFilter = deepCopy(filter);
        // clearAllFilterHandler(null, column);
        column.filter = newFilter;
        setFilter(newFilter);
        // props.columnToggleHandler();
        // debugger;
        props.applyFilterHandler(column, 'column');
        props.handleClose();
    }

    React.useEffect(() => {
        let isSubscribed = true;
        let mainCols = props.columnList;
        // debugger; 
        let subcols = [];
        mainCols.map(main => {
            // debugger
            main.subcolumns.map(subcol => {
                let isExisting = subcols.filter((col) => col.value === subcol.value);
                if (!isExisting.length) {
                    subcols.push(subcol);
                }
            });
        });
        console.log("subcols ", subcols);
        subcols = subcols.filter((itm) => (itm.type !== "restrictions" && itm.value !== "month" && itm.value !== "segment" && itm.value !== "dba"));
        // debugger;
        // setSubcolumns(subcols);
        if (currentColumn.filter) {
            setFilter({ ...filter, gte: currentColumn.filter.gte, lte: currentColumn.filter.lte });
        };
        
        columnList.forEach((column) => {
            let filter = column.subcolumns.find((subcol, index) => subcol.filter && (subcol.filter.gte || subcol.filter.lte));
            if(filter) {
                setHasOtherFilter(true);
            }
        });

        return () => (isSubscribed = false);
    }, []);

    return (<>
    <Box className="column-visiblity-row" pt={1}>
                        <Box className="input-label">Greater Than Equal to</Box>
                        <Box className="input-box">
                            <TextField
                                className='textfield'
                                size="small"                                
                                value={filter.gte}
                                onChange={(ev) => {
                                    filter.gte = Number(ev.target.value);
                                    setFilter({ ...filter, gte: Number(ev.target.value) });
                                }}

                                InputProps={{
                                    type: 'number',
                                }}
                            />
                        </Box>
                    </Box>

                    <Box className="column-visiblity-row" pt={1}>
                        <Box className="input-label">Less Than Equal to</Box>
                        <Box className="input-box">
                            <TextField
                                
                                className='textfield'
                                size="small"
                                value={filter.lte}
                                onChange={(ev) => {
                                    setFilter({ ...filter, lte: Number(ev.target.value) });
                                }}
                                InputProps={{
                                    type: 'number',
                                }}>
                            </TextField>
                        </Box>
                    </Box>

                    {/* Actions buttons */}
                    <Box sx={{ m: -1, mt: 1.5, p: 1, display: "flex", justifyContent: "space-between", isolation: "isolate", boxShadow: "0 4px 0.75rem rgba(12, 14, 28, 0.3)", borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}>

                        <Button className='filter-button secondary' disabled={!hasOtherFilter} variant="outlined" sx={{ mr: 1 }} size="small" onClick={(ev) => clearAllFilterHandler(ev, currentColumn)}>Clear All</Button>
                        <Button className='filter-button secondary' disabled={!filter.gte && !filter.lte} variant="outlined" sx={{ mr: 1 }} size="small" onClick={(ev) => clearFilterHandler(ev, currentColumn)}>Clear</Button>

                        <Button className='filter-button primary' disabled={(!filter.gte && !filter.lte)} variant="outlined" size="small" onClick={(ev) => {

                            applyFilterHandler(ev, currentColumn)
                        }}>Apply</Button>
                    </Box>
    </>)

}