import React, { useEffect, useState } from 'react';
//For amplitude analytics only
import { AMPLITUDE_API_KEY } from "../app/config";
import * as amplitude from '@amplitude/analytics-browser';
import { sendAnalyticsData } from '../app/log-interface';
// import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

import analConfig from '../app/analytics-config';
const isEnvLocal = window.location.host.search('localhost') > -1 ? true : false;
const { events } = analConfig;
//Restrict analytics for localhost
const analytics = {};

analytics.amplitude = amplitude;
// const sessionReplayTracking = sessionReplayPlugin({
//     sampleRate:1
// });

 
analytics.intilize = ()=>{
    if(!isEnvLocal){
       
        amplitude.init(AMPLITUDE_API_KEY, 'session replay user',{
            defaultTracking: {
                attribution: false,
                pageViews: false,
                sessions: true,
                formInteractions: false,
                fileDownloads: false,
              },
        });
        // amplitude.add(sessionReplayTracking);
    }
}
analytics.track = function (eventKey, params, oldState, newState) {
    let eventName = eventKey;

    if(events[eventKey]){
        eventName = events[eventKey];
    }
    if(eventKey === "tile_change"){
       
        eventName= params.selected_tile.replace("_", " ");
    }
    if(params.selected_tile){
        let tiles = params.selected_tile.split(',');
        if(tiles.length>1){
            params.selected_tile = 'intellimerged tile';
        }
    }
    // debugger;
    if (eventName) {
        //Insert into amplitude
        //Restrict analytics for localhost
        if(!isEnvLocal){
            analytics.amplitude.track(eventName, params);
            
           
        }
        //Insert into lodgiq system
        if(eventName !== "login attempt"){
            sendAnalyticsData(eventName, params, oldState, newState);
        }
    }
}
const useAnalytics = function (props) {
    const [analytics, setAnalytics] = useState({});
   
    useEffect(() => {
      
        setAnalytics(analytics);
    }, []);

    return analytics;
};
export { analytics };