import React, { useState } from 'react'
import { Grid, Modal, Tooltip } from '@mui/material';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { fetchDataSimple } from '../../services/data.service';

const AlertFeedback = (props) => {
    let { alertDetails, alertTitle } = props;
    const [feedback,setFeedback] = useState(alertDetails.feedback);
    
    /** Function is used to send feedback */
    const sendFeedback = (alert_id, feedback) => {
        const request = {
            "method": "PUT",
            "alertId": alert_id,
            "feedback": feedback
        };

        let api = "alerts/feedback/";
        fetchDataSimple({ api, request })
            .then((response) => {
                setFeedback(feedback)
                console.log("response true ", response);
            })
            .catch((error) => {
                console.log("Error", error);
            })
    }

    return (
        <>
            <Grid item>
                <item className="text-green fs11">
                    How was your experience?
                </item>
            </Grid>
            <Grid item>
                <item><span className='text-green'>
                    {(feedback === 1) ?
                        <ThumbUpAltIcon sx={{fill:"#01A4EF"}} className='feedback-icon' />
                        :
                        <ThumbUpOffAltIcon className='feedback-icon'
                            onClick={() => {
                                sendFeedback(alertDetails.alert_id, 1);
                            }} />
                    }

                    {(feedback === -1) ?
                        <ThumbDownAltIcon sx={{fill:"#01A4EF"}} className='feedback-icon' />
                        :
                        <ThumbDownOffAltIcon className='feedback-icon'
                            onClick={() => {
                                sendFeedback(alertDetails.alert_id, -1);
                            }} />
                    }
                </span> </item>
            </Grid>
        </>
    )
}

export default AlertFeedback
