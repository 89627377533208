import React, { useState, useEffect, useReducer } from 'react';

import { styled, Switch, Box, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Info as InfoIcon, LegendToggle, LensTwoTone, Mail as MailIcon } from '@mui/icons-material';
import {
    Grid, Button, LinearProgress, Badge, Modal, IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import {
    CloseSharp as CloseSharpIcon,
    ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
    ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
    CheckSharp as CheckSharpIcon
}
    from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState, getPropertyInfo } from '../../actions/appstate';
import DataService from "../../services/data.service";
import { deepCopy, dateFormat, isEmptyObj } from "../../app/util";
import "./update-restrictions-modal.scss";

export default function UpdateRestrictionsModal(props) {
    const { modalOpen, handleModalClose, stay_date, room_type,
        recData, data, entryFields, entry } = props;
    const [compState, setcompState] = useState({
        open: true,
        entryFields: [...entryFields], entry: entry,
        modified: false
    });
    const { auth } = useSelector(appState => appState);
    const dispatch = useDispatch();
    const { app_state, ui_state } = useSelector((state) => state.app.current_state);
    const { isRestrictionsRoomType } = app_state.workspace_controls;
    const property_info = getPropertyInfo(auth.profile.property_details, app_state.property_id);
    const hasExceptions = (property_info.permissions.restriction_exception === 1);
    useEffect(() => {
        let isSubscribed = true;
        console.log(property_info);
        if (entry.cta
            || entry.ctd
            || entry.close) {
            compState.open = false;
        } else {
            compState.open = true;
        }
        console.log(props);
        let newEntry = deepCopy(entry);
        if (data && data[0]) {
            // entryFields


            if (isRestrictionsRoomType) {
                if (app_state.roomTypeRestrictions.length === 0) {
                    newEntry = deepCopy(data[0]);
                }
            } else {
                let saved = app_state.savedRestrictions;


                if (saved.length) {
                    let exist = saved.filter(item => item.stay_date === stay_date);
                    if (exist.length) {
                        if (data[0].min_los == 1 && newEntry.min_los == 1) {
                            newEntry.min_los = '';
                        }
                        if (data[0].max_los == 1 && newEntry.max_los == 1) {
                            newEntry.max_los = '';
                        }
                    } else {
                        newEntry = deepCopy(data[0]);
                        if (newEntry.min_los == 1) {
                            newEntry.min_los = '';
                        }

                        if (newEntry.max_los == 1) {
                            newEntry.max_los = '';
                        }
                    }

                } else {
                    newEntry = deepCopy(data[0]);

                }
            }

            if (newEntry.min_los <= 1) {
                newEntry.min_los = '';
                delete newEntry.min_los
            }

            if (newEntry.max_los <= 1) {
                newEntry.max_los = '';
                delete newEntry.max_los
            }

        }
        setcompState(state => {
            return { ...state, open: compState.open, entry: { ...newEntry } }
        })
        return () => {
            return (isSubscribed = false);
        }
    }, [modalOpen])

    const handleClose = () => {
        handleModalClose(false);
        if (data && data[0]) {
            setcompState(state => {
                return { ...state, entry: { ...compState.entry, ...data[0] } }
            })
        }
    }

    const updateEntry = (key, updated_value) => {
        const blank = { cta: false, ctd: false, close: false, min_los: 1, max_los: 1 };
        const oldEntry = deepCopy({ ...blank, ...entry });
        if (key === "cta" || key === "ctd" || key === "close") {
            entry[key] = !entry[key];
        } else {
            if (key === "max_los" || key === "min_los") {
                updated_value = parseInt(updated_value)

                updated_value = (isNaN(updated_value) && updated_value !== "") ? "" : updated_value;
                entry[key] = (updated_value === 1) ? '' : updated_value
            } else {
                entry[key] = updated_value;
            }
        }
        if (entry.cta === true
            || entry.ctd === true
            || entry.close === true) {
            compState.open = false;

        } else {
            compState.open = true;
        }

        setcompState(state => {
            // debugger

            let newEntry = deepCopy({ ...state.entry, ...entry });
            if (newEntry.min_los === 1) {
                newEntry.min_los = '';

            }
            if (newEntry.max_los === 1) {
                newEntry.max_los = '';

            }

            // if (newEntry.min_los === '') {
            //     delete newEntry.min_los;
            // }
            // if (newEntry.max_los === '') {
            //     delete newEntry.max_los;
            // }
            let oldEntryStr = JSON.stringify({ ...blank, ...oldEntry });
            let newEntryStr = JSON.stringify({ ...blank, ...newEntry });

            let modified = false;
            if (oldEntryStr !== newEntryStr) { modified = true };
            return { ...state, modified, entry: { ...newEntry } }
        })
    }
    const hasRestrictions = (value) => {
        let result = false;
        if (value && (value.cta || value.ctd || value.close || value.min_los > 1 || value.max_los > 1)) {
            result = true;
        }
        return result;
    }
    const handleOnChangeText = (key) => {
        // debugger;
        return (event) => {
            let { value } = event.target;
            value = parseInt(value);
            value = isNaN(value) ? "" : value;
            const prevVal = (compState.entry[key]) ? compState.entry[key] : "";
            if (value !== prevVal || value === "") {

                if (value !== "") {
                    compState.modified = true;
                } else {
                    compState.modified = false;
                }
                if (key === 'min_los' || key === 'max_los') {
                    compState.entry[key] = value;
                }

                setcompState(state => {

                    return { ...state, ...compState }
                })
            }
        }

    };
    const getDelta = (newRestriction) => {
        let cur = deepCopy(newRestriction.cur);
        let entry = deepCopy(newRestriction.entry);
        let delta = {};
        if (cur.min_los === 1) {
            delete cur.min_los
        }
        if (cur.max_los === 1) {
            delete cur.max_los
        }
        if (entry.cta !== cur.cta) {
            delta.cta = true;
        } else {
            delete delta.cta;
        }

        if (entry.ctd !== cur.ctd) {
            delta.ctd = true;
        } else {
            delete delta.ctd;
        }

        if (entry.close !== cur.close) {
            delta.close = true;
        } else {
            delete delta.close;
        }
        if (entry.min_los !== cur.min_los && entry.min_los !== "" && !(entry.min_los === 0 && !cur.min_los)) {
            delta.min_los = true;
        } else {
            delete delta.min_los;
        }
        if (entry.max_los !== cur.max_los && entry.max_los !== "" && !(entry.max_los === 0 && !cur.max_los)) {
            delta.max_los = true;
        } else {
            delete delta.max_los;
        }
        if (!isEmptyObj(delta)) {
            newRestriction.entry = entry;
            newRestriction.delta = delta;
        } else {
            newRestriction.entry = newRestriction.cur;
        }

        return newRestriction;
    }
    const handleSaveEntry = () => {


        handleModalClose();
        if (isRestrictionsRoomType) {
            let savedRestrictions = {
                stay_date: stay_date,
            };
            let newRestriction = {
                cur: data[0],
                entry: compState.entry,
                room_type,
            };
            newRestriction = getDelta(newRestriction);
            const existingEntryIndex = app_state.roomTypeRestrictions.findIndex(item => item.stay_date === stay_date)

            if (existingEntryIndex > -1) {
                if (newRestriction.delta) {
                    app_state.roomTypeRestrictions[existingEntryIndex][room_type] = newRestriction;
                } else {
                    delete app_state.roomTypeRestrictions[existingEntryIndex][room_type];
                    if (Object.keys(app_state.roomTypeRestrictions[existingEntryIndex]).length === 1) {
                        app_state.roomTypeRestrictions.splice(existingEntryIndex, 1);
                    }
                }

            } else if (newRestriction.delta) {
                savedRestrictions[room_type] = newRestriction;
                app_state.roomTypeRestrictions.push(savedRestrictions);
            }

            dispatch(updateAppState("saveRestrictions", {
                app_state: {
                    roomTypeRestrictions: [...app_state.roomTypeRestrictions]
                }
            }));
        } else {
            let newRestriction = {
                stay_date: stay_date,
                cur: data[0],
                entry: compState.entry,
            };
            let existingEntryIndex = app_state.savedRestrictions.findIndex((item, i) => item.stay_date === stay_date);
            newRestriction = getDelta(newRestriction, app_state.savedRestrictions[existingEntryIndex]);
            if (existingEntryIndex > -1) {
                app_state.savedRestrictions[existingEntryIndex].cur = newRestriction.cur;
                app_state.savedRestrictions[existingEntryIndex].entry = newRestriction.entry;
                app_state.savedRestrictions[existingEntryIndex].delta = newRestriction.delta;
                if (!newRestriction.delta) {
                    app_state.savedRestrictions.splice(existingEntryIndex, 1);
                }
            } else if (!isEmptyObj(newRestriction.delta)) {
                app_state.savedRestrictions.push(newRestriction);
            }
            dispatch(updateAppState("saveRestrictions", {
                app_state: {
                    savedRestrictions: [...app_state.savedRestrictions]
                }
            }));
        }

    }

    const customTickIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="#606060"
            viewBox="0 0 256 256"
        >
            <path d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z"></path>
        </svg>
    );
    const customInfoIcon = (
        <svg
            className='info-icon'
            xmlns="http://www.w3.org/2000/svg"
            width="13" height="13" fill="#606060"
            viewBox="0 0 256 256"
        >
            <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path>
        </svg>
    );



    return (<>
        <Modal
            className="popup-modal-wrap"
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={'popup-modal restrictions-update ' + ((hasExceptions) ? "has-exceptions " : "without-exceptions")}>
                <Box className='modal-titlebar'>
                    <Grid container spacing={0} className={'modal restrictions-update '}>
                        <Grid className='popup-main-title' item xs={10}>
                            <div ><span>Restrictions - </span> <span style={{ fontFamily: "SFUI Light", fontSize: "14px" }}>{dateFormat(stay_date, "ddd, MMM DD, YYYY")}</span></div>
                        </Grid>
                        <Grid className='right-cta' xs={2} item alignItems="end">
                            <Button sx={{
                                minWidth: 'auto', position: 'absolute', top: -12, right: -12, width: '24px', height: '24px', p: '7px',
                                borderRadius: '50%', borderWidth: '1px', borderStyle: 'solid',
                                borderColor: '#ccc', boxShadow: '0 0 4px #000', background: '#000', color: '#FFF', fontSize: '10px',
                                '&:hover': { backgroundColor: '#000' }
                            }} onClick={handleClose}>
                                <CloseSharpIcon fontSize='small' />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <Grid className='restriction-popup-container-1' container spacing={1} p={1} mt={0.4}>

                    <Box className='restriction-popup-title-1'>Restrictions</Box>

                    <Box className='restriction-popup-switch-container'>
                        <Box
                            className={compState.open ? 'restriction-popup-switch open active' : 'restriction-popup-switch open disable'}
                        >
                            {compState.open ? customTickIcon : ''}
                            <div>Open</div>
                        </Box>
                        {/* <RestrictionSwitch className='switch open' checked={compState.open} disabled /> */}

                        <Box
                            className={compState.entry.cta ? 'restriction-popup-switch cta active' : 'restriction-popup-switch cta'}
                            onClick={() => { updateEntry('cta') }}
                        >
                            {compState.entry.cta ? customTickIcon : ''}
                            <div>CTA</div>
                        </Box>
                        {/* <RestrictionSwitch className='switch cta' checked={compState.entry.cta} onChange={updateEntry('cta')} /> */}

                        <Box
                            className={compState.entry.ctd ? 'restriction-popup-switch ctd active' : 'restriction-popup-switch ctd'}
                            onClick={() => { updateEntry('ctd') }}
                        >
                            {compState.entry.ctd ? customTickIcon : ''}
                            <div>CTD</div>
                        </Box>
                        {/* <RestrictionSwitch className='switch ctd' checked={compState.entry.ctd} onChange={updateEntry('ctd')} /> */}

                        <Box
                            className={compState.entry.close ? 'restriction-popup-switch close active' : 'restriction-popup-switch close'}
                            onClick={() => { updateEntry('close') }}
                        >
                            {compState.entry.close ? customTickIcon : ''}
                            <div>Close</div>
                        </Box>
                        {/* <RestrictionSwitch className='switch close' checked={compState.entry.close} onChange={updateEntry('close')} /> */}
                    </Box>

                    <Box className='restriction-popup-input-container' mt={0.5}>
                        <Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div className='input-lebel'>Min LOS</div>
                                <div>
                                    <Tooltip title="Enter '0' to remove LOS" sx={{ mt: 0.5 }} arrow>
                                        {customInfoIcon}
                                    </Tooltip>
                                </div>
                            </Box>

                            <TextField
                                fullWidth
                                className='textfield colord2'
                                //placeholder='00'
                                value={compState.entry.min_los}
                                onBlur={(e) => { updateEntry('min_los', e.target.value) }}
                                onChange={handleOnChangeText('min_los')}
                            >
                            </TextField>
                        </Box>
                        <Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div className='input-lebel'>Max LOS</div>
                                <div>
                                    <Tooltip title="Enter '0' to remove LOS" sx={{ mt: 0.5 }} arrow>
                                        {customInfoIcon}
                                    </Tooltip>
                                </div>
                            </Box>

                            <TextField
                                fullWidth
                                className='textfield colord1'
                                //placeholder='00'
                                value={compState.entry.max_los}
                                onBlur={(e) => { updateEntry('max_los', e.target.value) }}
                                onChange={handleOnChangeText('max_los')}
                            >

                            </TextField>
                        </Box>
                    </Box>
                </Grid>

                {hasExceptions && (<Grid className='restriction-popup-container-2' container spacing={1} mt={1}>

                    <Box className='restriction-popup-title-1'>Exceptions</Box>

                    <Box className='restriction-popup-input-container'>
                        <Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div className='input-lebel'>Min Advance</div>
                                <div>
                                    <Tooltip title="Restriction will not apply if the guest books this number of days or more in Advance" sx={{ mt: 0.5 }} arrow>
                                        {customInfoIcon}
                                    </Tooltip>
                                </div>
                            </Box>

                            <TextField
                                fullWidth
                                className='textfield'
                                placeholder='00'

                            >

                            </TextField>
                        </Box>
                        <Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div className='input-lebel'>Max Advance</div>
                                <div>
                                    <Tooltip title="Restriction will not apply if the guest books this number of days or less in Advance." sx={{ mt: 0.5 }} arrow>
                                        {customInfoIcon}
                                    </Tooltip>
                                </div>
                            </Box>

                            <TextField
                                fullWidth
                                className='textfield'
                                placeholder='00'
                            >

                            </TextField>
                        </Box>

                    </Box>

                    <Box className='restriction-popup-input-container'>
                        <Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div className='input-lebel'>Min Length</div>
                                <div>
                                    <Tooltip title="Restriction will not apply if the guest stays this number or more (Nights)" sx={{ mt: 0.5 }} arrow>
                                        {customInfoIcon}
                                    </Tooltip>
                                </div>
                            </Box>

                            <TextField
                                fullWidth
                                className='textfield'
                                placeholder='00'
                            >

                            </TextField>
                        </Box>
                        <Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div className='input-lebel'>Max Length</div>
                                <div>
                                    <Tooltip title="Restriction will not apply if guests stay this number or fewer (Nights)." sx={{ mt: 0.5 }} arrow>
                                        {customInfoIcon}
                                    </Tooltip>
                                </div>
                            </Box>

                            <TextField
                                fullWidth
                                className='textfield'
                                placeholder='00'
                            >

                            </TextField>
                        </Box>

                    </Box>

                    <Box className='restriction-popup-input-container'>
                        <Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div className='input-lebel'>Min Price</div>
                                <div>
                                    {/* <Tooltip title="Enter '0' to remove LOS" sx={{ mt: 0.5 }} arrow>
                                        <svg className='info-icon' xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="#606060" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path></svg>
                                    </Tooltip> */}
                                </div>
                            </Box>

                            <TextField
                                fullWidth
                                className='textfield'
                                placeholder='00'
                            >

                            </TextField>
                        </Box>
                        <Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <div className='input-lebel'>Max Price</div>
                                <div>
                                    {/* <Tooltip title="Enter '0' to remove LOS" sx={{ mt: 0.5 }} arrow>
                                        <svg className='info-icon' xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="#606060" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path></svg>
                                    </Tooltip> */}
                                </div>
                            </Box>

                            <TextField
                                fullWidth
                                className='textfield'
                                placeholder='00'
                            >

                            </TextField>
                        </Box>

                    </Box>

                </Grid>)
                }
                <Grid className='restriction-popup-container-3' container spacing={1}>
                    <Button
                        className="navigate-cancel-button btn-active"
                        variant="outlined"
                        size="small"
                        sx={{ minWidth: "auto" }}
                        onClick={() => {
                            if (data && data[0]) {
                                setcompState(state => {
                                    return { ...state, entry: { ...data[0] } }
                                })
                            }
                            handleModalClose(false);
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        disabled={!compState.modified}
                        className={(compState.modified) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        variant="outlined"
                        size="small"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                        onClick={() => {
                            handleSaveEntry()
                        }}
                    >
                        Save
                    </Button>
                </Grid>


            </Box>
        </Modal>
    </>);
}

const RestrictionSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 56,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(30px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#01A4EF',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
