import React, { useEffect, useState } from "react";
import { Table, TableCell, TableContainer, TableRow, TableBody, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CloseSharp as CloseSharpIcon } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import moment from 'moment';
import {
    dateFormat, getSdlyDate, daysCountFromRange, deepCopy, getAppData, getCorrectValue, getFromState, getPermissions, getTransformedSegments, in_array, sendMultipleAPIs, truncate,
    isDateInRange, getDemandImpactName, getDemandImpactColor, getStayToSdly, getStayToSdtly, getStayToComp
} from '../../app/util';
import { useDispatch, useSelector } from "react-redux";
import dataService, { fetchDataSimple } from '../../services/data.service';
import RatePlanList from './RatePlanList';
import RatePlanListTotal from './RatePlanListTotal';
import './rate-plan-popup.scss'
import RatePlanHeader from './RatePlanHeader';
import MultipleTileOptions from './MultipleTileOptions';
import DataService from "../../services/data.service";
import { UPDATE_STATE, NOTE_LETTER_LIMIT } from '../../actions/types';


export default function EventsPopupMultiple(props) {

    const dispatch = useDispatch();

    const { current_state } = useSelector(
        (state) => state.app
    );
    const { app_state, ui_state } = current_state;
    const { workspace_controls, notes, property_id, property_name, avgImpactData, eventList, avgImpactDataSDLY, eventListSDLY } = app_state;
    const { comp_as_of_date, as_of_date } = app_state;
    let stay_date = moment(props.params.stay_date);

    const formatted_stay_date = dateFormat(stay_date, "YYYY-MM-DD");
    //const formatted_stay_date_SDLY = workspace_controls.sdlyOn ? workspace_controls.dayDate === "date" ? dateFormat(getStayToSdtly(app_state, formatted_stay_date), "YYYY-MM-DD") : dateFormat(getStayToSdly(app_state, formatted_stay_date), "YYYY-MM-DD") : dateFormat(getStayToComp(app_state, formatted_stay_date), "YYYY-MM-DD");
    let formatted_stay_date_SDLY;
    if (workspace_controls.sdlyOn) {
        if (workspace_controls.dayDate === "date") {
            formatted_stay_date_SDLY = dateFormat(getStayToSdtly(app_state, formatted_stay_date), "YYYY-MM-DD");
        } else {
            formatted_stay_date_SDLY = dateFormat(getStayToSdly(app_state, formatted_stay_date), "YYYY-MM-DD");
        }
    } else {
        formatted_stay_date_SDLY = dateFormat(getStayToComp(app_state, formatted_stay_date), "YYYY-MM-DD");
    }



    // console.log("workspace_controls.dayDate", workspace_controls.dayDate)
    // console.log("formatted_stay_date", formatted_stay_date)
    // console.log("formatted_stay_date_SDLY", formatted_stay_date_SDLY)

    const avgImpactDataForStayDate = avgImpactData && avgImpactData.find(item => item.date === formatted_stay_date);
    const avgImpactDataForStayDateSDLY = avgImpactDataSDLY && avgImpactDataSDLY.find(item => item.date === formatted_stay_date_SDLY);
    //console.log("avgImpactDataForStayDate", avgImpactDataForStayDate)

    const avgImpactColor = avgImpactDataForStayDate !== undefined ? getDemandImpactColor(avgImpactDataForStayDate.demand_impact) : "transparent";
    const avgImpactColorSDLY = avgImpactDataForStayDateSDLY !== undefined ? getDemandImpactColor(avgImpactDataForStayDateSDLY.demand_impact) : "transparent";

    const filteredEventList = eventList.filter(event =>
        isDateInRange(formatted_stay_date, event.start_date, event.end_date) && event.is_ignored === false
    );
    const filteredEventListSDLY = eventListSDLY.filter(event =>
        isDateInRange(formatted_stay_date_SDLY, event.start_date, event.end_date) && event.is_ignored === false
    );
    // console.log("filteredEventList", filteredEventList)
    // console.log("filteredEventListSDLY", filteredEventListSDLY)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    // if (in_array(ui_state.active_tiles, ["otb_v2_multi_segment", "bi_pace_comparison", "bi_pickup_analysis", "DailyRatesendStatus", "rm_room_type_ratesend_status", "restrictions_push_status"])) {
    //     return;
    // }

    return (
        <div className='rateplan-details' style={props.params.isTotalRow === 'Total' && (filteredEventList.length === 0 && filteredEventListSDLY.length === 0) ? { opacity: 0 } : { paddingRight: "0px" }}>
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ lineHeight: 1, fontSize: "18px", mt: "-7px" }}
                className={filteredEventList.length === 0 && filteredEventListSDLY.length === 0 ? "event-icon-div no-icon" : "event-icon-div"}
            >
                {filteredEventList.length === 0 && filteredEventListSDLY.length === 0 ? null :
                    filteredEventList.length === 0 && filteredEventListSDLY.length !== 0 ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill={avgImpactColorSDLY} viewBox="0 0 256 256"><path d="M250,121.34,204.36,52.91A20,20,0,0,0,187.72,44H32A12,12,0,0,0,22,62.66L65.58,128,22,193.34A12,12,0,0,0,32,212H187.72a20,20,0,0,0,16.64-8.91L250,134.66A12,12,0,0,0,250,121.34ZM185.58,188H54.42L90,134.66a12,12,0,0,0,0-13.32L54.42,68H185.58l40,60Z"></path></svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill={avgImpactColor} viewBox="0 0 256 256">
                            <path d="M246.66,132.44,201,200.88A16,16,0,0,1,187.72,208H32a8,8,0,0,1-6.66-12.44L70.39,128l-45-67.56A8,8,0,0,1,32,48H187.72A16,16,0,0,1,201,55.12l45.63,68.44A8,8,0,0,1,246.66,132.44Z"></path>
                        </svg>
                }

            </div>
            <Menu
                className='rateplan-details'
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}

                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        mt: -3,
                        ml: 2,
                        borderRadius: "8px !important",
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                <Box className='rateplan-box events'>
                    {filteredEventList.length !== 0 && filteredEventListSDLY.length !== 0 ?
                        <div className='rateplan-box-title' style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                            <div style={{ borderRight: "1px solid #EBEBEB" }}>{moment(formatted_stay_date).format("ddd, MMM DD, YYYY")}</div>
                            {/* <div className="divider-right"></div> */}
                            <div style={{ paddingLeft: "12px" }}>{moment(formatted_stay_date_SDLY).format("ddd, MMM DD, YYYY")}</div>
                        </div>
                        :
                        filteredEventList.length !== 0 && filteredEventListSDLY.length === 0 ?
                            <div className='rateplan-box-title'>
                                <div>{moment(formatted_stay_date).format("ddd, MMM DD, YYYY")}</div>
                            </div>
                            :
                            <div className='rateplan-box-title'>
                                <div>{moment(formatted_stay_date_SDLY).format("ddd, MMM DD, YYYY")}</div>
                            </div>
                    }

                    {filteredEventList.length !== 0 && filteredEventListSDLY.length !== 0 ?
                        <Box className="scrollable-container multiple">
                            <Box className="rateplan-box-content" sx={{ borderRight: "1px solid #EBEBEB" }}>
                                {/* Events to be looped */}
                                {filteredEventList.map((event, index) => (
                                    <Box>
                                        <Box className='events-list multiple'>
                                            <Box>
                                                <div className="main-title">{event.title}</div>
                                                {event.demand_impacts.map((impact, impactIndex) => (
                                                    impact.date !== formatted_stay_date ? null :
                                                        <div className="sub-title">{impact.day_type}</div>
                                                ))}
                                            </Box>
                                            <Box pt={1.5} sx={{ width: "110px" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div className="main-title">Type</div>
                                                    <div className="main-title">Impact</div>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div className="sub-title">{event.category}</div>
                                                    {event.demand_impacts.map((impact, impactIndex) => (
                                                        impact.date !== formatted_stay_date ? null : (
                                                            <div
                                                                key={impactIndex}
                                                                className="impact-color"
                                                                style={{ borderBottom: `5px solid ${getDemandImpactColor(impact.demand_impact)}` }}
                                                            ></div>
                                                        )
                                                    ))}
                                                </div>
                                            </Box>
                                        </Box>
                                        {filteredEventList.length === index + 1 ? null :
                                            <div className="divider"></div>
                                        }
                                    </Box>
                                ))}
                                {/* Loop ends here */}
                            </Box>

                            <Box className="rateplan-box-content">
                                {/* Events to be looped */}
                                {filteredEventListSDLY.map((event, index) => (
                                    <Box>
                                        <Box className='events-list multiple'>
                                            <Box>
                                                <div className="main-title">{event.title}</div>
                                                {event.demand_impacts.map((impact, impactIndex) => (
                                                    impact.date !== formatted_stay_date_SDLY ? null :
                                                        <div className="sub-title">{impact.day_type}</div>
                                                ))}
                                            </Box>
                                            <Box pt={1.5} sx={{ width: "110px" }}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div className="main-title">Type</div>
                                                    <div className="main-title">Impact</div>
                                                </div>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    <div className="sub-title">{event.category}</div>
                                                    {event.demand_impacts.map((impact, impactIndex) => (
                                                        impact.date !== formatted_stay_date_SDLY ? null : (
                                                            <div
                                                                key={impactIndex}
                                                                className="impact-color"
                                                                style={{ borderBottom: `5px solid ${getDemandImpactColor(impact.demand_impact)}` }}
                                                            ></div>
                                                        )
                                                    ))}
                                                </div>
                                            </Box>
                                        </Box>
                                        {filteredEventListSDLY.length === index + 1 ? null :
                                            <div className="divider"></div>
                                        }
                                    </Box>
                                ))}
                                {/* Loop ends here */}
                            </Box>
                        </Box>
                        :
                        filteredEventList.length !== 0 && filteredEventListSDLY.length === 0 ?
                            <Box className="scrollable-container">
                                <Box className="rateplan-box-content" >
                                    {/* Events to be looped */}
                                    {filteredEventList.map((event, index) => (
                                        <Box>
                                            <Box className='events-list'>
                                                <Box>
                                                    <div className="main-title">{event.title}</div>
                                                    {event.demand_impacts.map((impact, impactIndex) => (
                                                        impact.date !== formatted_stay_date ? null :
                                                            <div className="sub-title">{impact.day_type}</div>
                                                    ))}
                                                </Box>
                                                <Box pt={1.5} sx={{ width: "110px" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <div className="main-title">Type</div>
                                                        <div className="main-title">Impact</div>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <div className="sub-title">{event.category}</div>
                                                        {event.demand_impacts.map((impact, impactIndex) => (
                                                            impact.date !== formatted_stay_date ? null : (
                                                                <div
                                                                    key={impactIndex}
                                                                    className="impact-color"
                                                                    style={{ borderBottom: `5px solid ${getDemandImpactColor(impact.demand_impact)}` }}
                                                                ></div>
                                                            )
                                                        ))}
                                                    </div>
                                                </Box>
                                            </Box>
                                            {filteredEventList.length === index + 1 ? null :
                                                <div className="divider"></div>
                                            }
                                        </Box>
                                    ))}
                                    {/* Loop ends here */}
                                </Box>
                            </Box>
                            :
                            <Box className="scrollable-container">
                                <Box className="rateplan-box-content" >
                                    {/* Events to be looped */}
                                    {filteredEventListSDLY.map((event, index) => (
                                        <Box>
                                            <Box className='events-list'>
                                                <Box>
                                                    <div className="main-title">{event.title}</div>
                                                    {event.demand_impacts.map((impact, impactIndex) => (
                                                        impact.date !== formatted_stay_date_SDLY ? null :
                                                            <div className="sub-title">{impact.day_type}</div>
                                                    ))}
                                                </Box>
                                                <Box pt={1.5} sx={{ width: "110px" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <div className="main-title">Type</div>
                                                        <div className="main-title">Impact</div>
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <div className="sub-title">{event.category}</div>
                                                        {event.demand_impacts.map((impact, impactIndex) => (
                                                            impact.date !== formatted_stay_date_SDLY ? null : (
                                                                <div
                                                                    key={impactIndex}
                                                                    className="impact-color"
                                                                    style={{ borderBottom: `5px solid ${getDemandImpactColor(impact.demand_impact)}` }}
                                                                ></div>
                                                            )
                                                        ))}
                                                    </div>
                                                </Box>
                                            </Box>
                                            {filteredEventListSDLY.length === index + 1 ? null :
                                                <div className="divider"></div>
                                            }
                                        </Box>
                                    ))}
                                    {/* Loop ends here */}
                                </Box>
                            </Box>
                    }
                </Box>

            </Menu>
        </div>
    );
}