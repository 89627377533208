
import { useState, useEffect } from 'react'
import { Box, Select, FormControl, MenuItem, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';

const RatePushMode = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state } = useSelector(
        (state) => state.app.current_state
    );

    const [mode, setMode] = useState(controls.logsRatePushMode);
    const dispatch = useDispatch();

    useEffect(() => {
        setMode(app_state.workspace_controls.logsRatePushMode);
    }, [app_state.workspace_controls.logsRatePushMode])

    const handleChange = (type, value) => {
        //controls.logsRatePushMode = value;
        app_state.workspace_controls.logsRatePushMode = value;
        app_state.date_range_type = value === 'date' ? 'logsSingleDate' : 'logsDateRange';

        dispatch(updateAppState(type,
            {
                app_state: {
                    date_range_type:app_state.date_range_type,
                    workspace_controls:app_state.workspace_controls,
                },
                ui_state: { showspinner: true }
            }));
    }

    return (
        <div style={{ position: 'relative' }}>
            <Box id="mode" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box className="data-level-label">Mode</Box>
                <FormControl size="small" sx={{ borderRadius: "16px" }}>
                    <Select fullWidth className='tabular-data-filter' value={mode}
                        sx={{ height: "30px", minWidth: "100px", mt: "5px" }}
                        onChange={(e) => {
                            handleChange("logs_mode", e.target.value);
                        }}>
                        <MenuItem value="date" className='tabular-data-filter'> Date </MenuItem>
                        <MenuItem value="date_range" className='tabular-data-filter'> Date Range </MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </div>
    )
}

export default RatePushMode