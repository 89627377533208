import React, { useState, useEffect, useRef, useCallback } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import './style.scss';
import { deepCopy, isMobileDevice, dateFormat, dateDiff, monthDiff, in_array, calculateCompStayRange, calculateCompStayRangePromise, calculatePrimaryStayRange, toSdly, getSdyearsAndSdlyOn } from "../../app/util";
import { updateAppState } from "../../actions/appstate";
import { Button, Popover, ButtonGroup, TextField, Grid } from "@mui/material/";
import { sendLog } from "../../app/log-interface";
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';
import { useSelector, useDispatch } from "react-redux";

// import { useDateRanges } from "../../hooks/date-picker-logics";
import './styles.css';
import { UPDATE_STATE } from '../../actions/types';
import { APP_CACHE, ABORT_REQUEST_CONTROLLERS } from "../../app/config";
import dataService from '../../services/data.service';

export default function SmartDatePicker(props) {
    // const dateRanges = useDateRanges();

    const { current_state } = useSelector((state) => state.app);
    const { profile } = useSelector((state) => state.auth);
    const { app_state, ui_state } = current_state;
    const { active_tiles } = ui_state;

    const [datePickerConfig, setDatePickerConfig] = useState(props.datePickerConfig)
    const { primary_stay_range, workspace_controls } = app_state;
    let { navigation, initialRangeType } = datePickerConfig;

    const dispatch = useDispatch();

    const [tempDateRange, setTempDateRange] = useState(primary_stay_range)
    const [displayDateRange, setDisplayDateRange] = useState(props.dateRange)

    const [rangeType, setRangeType] = useState(initialRangeType);
    const [rangeSegment, setRangeSegment] = useState(null);

    const [calendarOn, setCalendarOn] = useState("range");
    const [isApplyEnable, setIsApplyEnable] = useState(true)

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [singleDate, setSingleDate] = useState(moment(tempDateRange.date_from).format("MM/DD/YYYY"));
    const [asOfDate, setAsOfDate] = useState(moment(tempDateRange.as_of_date).format("MM/DD/YYYY"));
    const [inputDateFrom, setInputDateFrom] = useState(moment(tempDateRange.date_from).format("MM/DD/YYYY"));
    const [inputDateUntil, setInputDateUntil] = useState(moment(tempDateRange.date_until).format("MM/DD/YYYY"));

    const openDatePicker = Boolean(anchorEl);
    const id = openDatePicker ? 'date-picker-popover' : undefined;

    let selector = (<span></span>);
    const initialRender = useRef(true);

    const isValidDateAndFormat = (dateString) => moment(dateString, "MM/DD/YYYY", true).isValid();

    useEffect(() => {
        // const { date_from, date_until } = dateRanges.primary_stay_range;


        if (app_state.date_range_type && app_state.date_range_type.split('_')[0] !== 'propertyChanged') {
            // debugger;
            const { date_from, date_until } = app_state.primary_stay_range;

            if (app_state.date_range_type === 'scopeTypeChanged_Dba') {
                let { date_from } = app_state.primary_stay_range;
                let from_value = moment(date_from);
                setRangeType('single_date');
                onDateChange(null, [from_value], 'single');
                applyChange('singleStaydates');
            }
            if (app_state.date_range_type === 'scopeTypeChanged_Date') {
                let { date_from, date_until } = app_state.primary_stay_range;
                let primary_stay_range = calculatePrimaryStayRange({ date_from, date_range: app_state.historicalRange });
                let from_value = moment(primary_stay_range.date_from);
                let until_value = moment(primary_stay_range.date_until);
                onDateChange(null, [from_value], 'date_from');
                onDateChange(null, [until_value], 'date_until');
                setRangeType('range');
                applyChange('staydates');
            }

            if (app_state.date_range_type === 'logsSingleDate') {
                let { date_from } = app_state.primary_stay_range;
                let from_value = moment(date_from);
                setRangeType('single_date');
                onDateChange(null, [from_value], 'single');

                applyChange('staydates');
            }

            if (app_state.date_range_type === 'logsDateRange') {
                let { date_from, date_until } = app_state.historicalRange;
                let from_value = moment(date_from);
                let until_value = moment(date_until);
                onDateChange(null, [from_value], 'date_from');
                onDateChange(null, [until_value], 'date_until');
                setRangeType('range');
                applyChange('staydates');
            }

            if (app_state.date_range_type === 'asOfChanged') {
                let as_of_date = app_state.primary_stay_range.as_of_date;
                setTempDateRange({ ...tempDateRange, as_of_date });
                setDisplayDateRange({ ...displayDateRange, as_of_date });
                setRangeType('range');
            }


            if (app_state.date_range_type.split('_')[0] === "rangeTileChange") {
                let changeType = app_state.date_range_type.indexOf('_sdYear') !== -1 ? 'sdYearChanged' : '';
                let tile = app_state.date_range_type.split('_')[1];
                let from_value = moment(date_from);
                let until_value = moment(date_until);
                onDateChange(null, [from_value], 'date_from');
                onDateChange(null, [until_value], 'date_until');

                if (app_state.workspace_controls.scopeType === 'Dba') {
                    setRangeType('single_date');
                    applyChange('singleStaydates');
                }

                if (app_state.workspace_controls.scopeType === 'Date') {
                    setRangeType('range');
                    applyChange('staydates_' + 'rangeTileChange', null, changeType);
                }
            }

            if (app_state.workspace_controls.scopeType === 'Dba_x') {
                if (app_state.date_range_type === 'single_date') {
                    let from_value = moment(date_from);
                    setRangeType('single_date');
                    onDateChange(null, [from_value], 'single');

                    applyChange('singleStaydates');
                }
                if (app_state.date_range_type === 'singlePace') {
                    let from_value = moment(date_from);
                    setRangeType('single_date');
                    onDateChange(null, [from_value], 'single');
                    applyChange('staydates');
                }
                if (app_state.workspace_controls.scopeType === "Dba") {
                    let { date_from } = app_state.primary_stay_range;
                    tempDateRange.date_until = displayDateRange.date_from;
                    let from_value = moment(date_from);
                    setRangeType('single_date');
                    onDateChange(null, [from_value], 'single');
                    applyChange('staydates');
                }

            }

            if (app_state.date_range_type === 'range') {
                let from_value = moment(date_from);
                let until_value = moment(date_until);
                onDateChange(null, [from_value], 'date_from');
                onDateChange(null, [until_value], 'date_until');
                setRangeType('range');
                applyChange('staydates');
            }

            if (app_state.date_range_type === 'pricingRoomType' ||
                'restrictionsRoomType' === app_state.date_range_type
            ) {
                rangeSegmentChange(29);
                setRangeType('range');
                applyChange('staydates');
            }
        }

        if (app_state.date_range_type && app_state.date_range_type.split('_')[0] === 'propertyChanged') {
            let as_of_date = new moment(profile.current_property_date).startOf('day').format('YYYY-MM-DD');
            let date_from = new moment(profile.current_property_date).startOf('month').format('YYYY-MM-DD');
            let date_until = new moment(profile.current_property_date).endOf('month').format('YYYY-MM-DD');
            let tempDateRange = { as_of_date, date_from, date_until };

            let comp_stay_range = calculateCompStayRange({ primary_stay_range: tempDateRange, workspace_controls, property_id: app_state.property_id });
            ui_state.showspinner = true;
            app_state.date_range_type = '';
            app_state.comp_stay_range = comp_stay_range;
            app_state.data_load = true;
            app_state.kpi_load = true;
            // app_state.workspace_controls.scopeType = "Date";
            console.log("Dispatched from datepicker", app_state);
            app_state.primary_stay_range = { as_of_date, date_from, date_until };
            // app_state.rerender_app = app_state.property_id;
            dispatch({
                type: "UPDATE_STATE",
                payload: {
                    app_state,
                    ui_state
                }
            });
            // updateAppState('stay_range', , "datepicker_update"));
            // debugger;
            let dateRange = deepCopy(tempDateRange);
            setDisplayDateRange(dateRange);
            setTempDateRange(dateRange);
            setSingleDate(moment(dateRange.date_from).format("MM/DD/YYYY"));
            setAsOfDate(moment(dateRange.as_of_date).format("MM/DD/YYYY"));
            setInputDateFrom(moment(dateRange.date_from).format("MM/DD/YYYY"));
            setInputDateUntil(moment(dateRange.date_until).format("MM/DD/YYYY"));
        }

    }, [
        //app_state.workspace_controls.scopeType,
        app_state.date_range_type,
        //app_state.primary_stay_range.as_of_date,
        // app_state.primary_stay_range.date_from,
        // app_state.primary_stay_range.date_until,
    ]);

    useEffect(() => {
        if (initialRender.current === false) {
            switch (rangeType) {
                case "single_date":
                    if (
                        isValidDateAndFormat(singleDate) &&
                        isValidDateAndFormat(asOfDate) &&
                        moment(asOfDate, "MM/DD/YYYY").isSameOrBefore(moment(profile.current_property_date))
                    ) {
                        setIsApplyEnable(true)
                    } else {
                        setIsApplyEnable(false)
                    }
                    break;
                case "range":
                    if (
                        isValidDateAndFormat(asOfDate) &&
                        moment(asOfDate, "MM/DD/YYYY").isSameOrBefore(moment(profile.current_property_date)) &&
                        isValidDateAndFormat(inputDateFrom) &&
                        isValidDateAndFormat(inputDateUntil) &&
                        moment(new Date(inputDateUntil)).diff(new Date(inputDateFrom), 'days') >= 0
                    ) {
                        setIsApplyEnable(true)
                    } else {
                        setIsApplyEnable(false)
                    }
                    break;
                case "weeks":
                case "months":
                    if (
                        isValidDateAndFormat(asOfDate) &&
                        moment(asOfDate, "MM/DD/YYYY").isSameOrBefore(moment(profile.current_property_date))
                    ) {
                        setIsApplyEnable(true)
                    } else {
                        setIsApplyEnable(false)
                    }
                    break;
                default:
                    break;
            }

            let range_segment = determineRangeSegment(tempDateRange);
            setRangeSegment(range_segment);
        }
        initialRender.current = false
    }, [singleDate, asOfDate, inputDateFrom, inputDateUntil, rangeType])

    const openDatePickerHandler = (event) => {
        setAnchorEl(event.currentTarget)
        setSingleDate(moment(displayDateRange.date_from).format("MM/DD/YYYY"));
        setAsOfDate(moment(displayDateRange.as_of_date).format("MM/DD/YYYY"));
        setInputDateFrom(moment(displayDateRange.date_from).format("MM/DD/YYYY"));
        setInputDateUntil(moment(displayDateRange.date_until).format("MM/DD/YYYY"));
        setCalendarOn("")
        let range_segment = determineRangeSegment(displayDateRange);
        setRangeSegment(range_segment);
        handleRangeChange(rangeType);
    }

    const closeDatePickerHandler = () => {
        setAsOfDate(moment(displayDateRange.as_of_date).format("MM/DD/YYYY"));
        tempDateRange.as_of_date = moment(displayDateRange.as_of_date).format("YYYY-MM-DD");
        setAnchorEl(null)
    }

    const applyChange = useCallback((key, initiater, changeType) => {
        // debugger;
        //Reseting chart cache data
        APP_CACHE.chartData = null;
        
        if ("singleStaydates" === key) {
            let primary_stay_range = app_state.primary_stay_range;
            tempDateRange.date_until = primary_stay_range.date_from;
            displayDateRange.date_until = primary_stay_range.date_from;
        }

        if (changeType !== 'sdYearChanged') {
            let asOfDateYear = Number(moment(asOfDate, "MM/DD/YYYY").format("YYYY"));
            let appState = deepCopy(app_state);
            let uiState = deepCopy(ui_state)

            let sdYearsAndSdlyOn = getSdyearsAndSdlyOn(appState, uiState, asOfDateYear);
            let apc = APP_CACHE;
            apc.controls = { ...apc.controls, ...sdYearsAndSdlyOn };
            console.log({ sdYearsAndSdlyOn })
            app_state.workspace_controls = { ...app_state.workspace_controls, ...sdYearsAndSdlyOn }
        }

        let controls = deepCopy(app_state.workspace_controls);
        calculateCompStayRangePromise({ primary_stay_range: tempDateRange, workspace_controls: app_state.workspace_controls, property_id: app_state.property_id })
            .then((rs) => {
                let sdly_asof = rs.as_of_date
                let sdly_from = rs.date_from
                let sdly_to = rs.date_until;
                let { sdtly_asof, sdtly_from, sdtly_to } = rs;
                app_state.metas.osr = { sdly_from: sdly_from, sdly_to: sdly_to, sdly_asof: sdly_asof, sdtly_asof, sdtly_from, sdtly_to };
                //console.log("rrrrrrrrrrrrrrrr", rs, key, initiater)
                let comp_stay_range = deepCopy(rs);
                app_state.comp_stay_range = comp_stay_range;
                let historicalRange = { ...app_state.primary_stay_range, ...app_state.historicalRange };
                // APP_CACHE.controls = app_state.workspace_controls;
                APP_CACHE.stay_range = [tempDateRange.date_from, tempDateRange.date_until, tempDateRange.as_of_date];
                APP_CACHE.primary_stay_range = deepCopy(tempDateRange);
                APP_CACHE.comp_stay_range = deepCopy(comp_stay_range);
                // setDisplayDateRange({ ...displayDateRange, ...tempDateRange });
                // setAnchorEl(null);

                if (initiater && initiater === 'picker') {
                    //historicalRange = deepCopy(tempDateRange);
                    //Abort in progress API calls
                    //Aborting active apis
                    let abotControllers = ABORT_REQUEST_CONTROLLERS;
                    abotControllers.forEach((val, key) => {
                        val.abort();
                        // console.log('abotControllers', ABORT_REQUEST_CONTROLLERS.get(key));
                        // if (val && !['OSR', 'OSRContrainedForecast'].includes(key)) {
                        //     val.abort();
                        // }
                    });

                    if (app_state.workspace_controls.scopeType === 'Date') {
                        historicalRange = deepCopy(tempDateRange);
                    }
                    // sendLog({ category: "datepicker", action: "changedates" }, { ...current_state },{
                    //     app_state: {
                    //         historicalRange,
                    //         primary_stay_range: { ...tempDateRange },
                    //         data_load: false, kpi_load: false
                    //     },
                    //     ui_state: {
                    //         showspinner: true,
                    //     }
                    // })
                    window.analyze.track("stay_dates_changed", {
                        "Previous Dates": app_state.primary_stay_range,
                        "New Dates ": { ...tempDateRange },
                        property_id: app_state.property_id, property_name: app_state.property_name,
                        data_view: app_state.user_view, historicalRange, active_tiles: ui_state.active_tiles,
                    }, { ...current_state }, {
                        app_state: {
                            historicalRange,
                            primary_stay_range: { ...tempDateRange },
                            data_load: false, kpi_load: false
                        },
                        ui_state: {
                            showspinner: true,
                        }
                    });

                    dispatch({
                        type: UPDATE_STATE, payload: {
                            app_state: {
                                historicalRange,
                                primary_stay_range: { ...tempDateRange },
                                comp_stay_range,
                                data_load: false, kpi_load: false,
                                workspace_controls: controls
                            },
                            ui_state: {
                                showspinner: true,
                                // show_chart_icon: true
                            }
                        }
                    });
                }
                // debugger;
                let curr_app_state = app_state;
                let as_of_date = moment(new Date(asOfDate)).format('YYYY-MM-DD');
                // tempDateRange.as_of_date = as_of_date;

                if (['Date', 'DBA'].includes(app_state.workspace_controls.scopeType)
                    && !app_state.workspace_controls.isRoomTypePricing
                    && ui_state.active_tiles[0] !== 'pricing') {
                    //historicalRange = deepCopy(app_state.primary_stay_range);
                    let tempDateRange_copy = deepCopy(tempDateRange);
                    tempDateRange_copy = { primary_stay_range: tempDateRange_copy, comp_stay_range: app_state.comp_stay_range, workspace_controls, property_id: app_state.property_id }
                    // debugger;
                    // app_state.comp_stay_range = calculateCompStayRange(tempDateRange_copy);
                }

                if ("singleStaydates" === key) {
                    let primary_stay_range = app_state.primary_stay_range;
                    // debugger;
                    tempDateRange.date_until = primary_stay_range.date_from;
                    displayDateRange.date_until = primary_stay_range.date_from;
                    let comp_stay_range = calculateCompStayRange({ primary_stay_range: tempDateRange, workspace_controls: app_state.workspace_controls, property_id: app_state.property_id });

                    primary_stay_range.date_until = primary_stay_range.date_from;

                    //APP_CACHE.primary_stay_range = deepCopy(primary_stay_range);
                    //APP_CACHE.comp_stay_range = deepCopy(comp_stay_range);

                    // app_state.workspace_controls.scopeType = APP_CACHE.controls.scopeType;
                    // debugger;
                    app_state.date_range_type = '';

                    setTimeout(() => {

                        dispatch({
                            type: UPDATE_STATE,
                            payload: {
                                app_state: {
                                    date_range_type: '',
                                    primary_stay_range,
                                    comp_stay_range,
                                    historicalRange,
                                    // workspace_controls: { ...APP_CACHE.controls, scopeType: 'Dba' },
                                    data_load: true, kpi_load: true
                                },
                                ui_state: { showspinner: true }
                            }
                        });
                    }, 100);

                }

                if ("staydates" === key) {
                    if (datePickerConfig.dateRangeKey === "primary_stay_range") {
                        let app_state = {}; let ui_state = {};
                        app_state.primary_stay_range = { ...tempDateRange };
                        app_state.data_load = true;
                        app_state.kpi_load = true;
                        ui_state.showspinner = true;
                        app_state.historicalRange = historicalRange;
                        app_state.as_of_date = as_of_date;
                        app_state.date_range_type = '';
                        app_state.comp_stay_range = comp_stay_range; //calculateCompStayRange({ primary_stay_range: app_state.primary_stay_range, workspace_controls, property_id: app_state.property_id });

                        APP_CACHE.primary_stay_range = app_state.primary_stay_range;
                        APP_CACHE.comp_stay_range = app_state.comp_stay_range;
                        // debugger;
                        setTimeout(() => {

                            dispatch({
                                type: UPDATE_STATE, payload: {
                                    app_state: {
                                        ...app_state,
                                    },
                                    ui_state: { ...ui_state }
                                }
                            })
                        }, 100);
                    }
                }

                if ("staydates_rangeTileChange" === key) {
                    // let final_state = APP_CACHE.final_state ? APP_CACHE.final_state : { app_state: {}, ui_state: {} };
                    // debugger;

                    if (datePickerConfig.dateRangeKey === "primary_stay_range") {
                        let app_state = {}; let ui_state = {};
                        // app_state.primary_stay_range = calculatePrimaryStayRange({ date_from: tempDateRange.date_from, date_range: curr_app_state.historicalRange });
                        app_state.data_load = true;
                        app_state.kpi_load = true;

                        app_state.historicalRange = historicalRange;
                        app_state.as_of_date = as_of_date;
                        app_state.date_range_type = '';
                        app_state.workspace_controls = controls;
                        ui_state.showspinner = true;
                        setTimeout(() => {

                            dispatch({
                                type: UPDATE_STATE,
                                payload: {
                                    app_state,
                                    ui_state
                                }
                            });
                        }, 100);
                    }
                }

                if (key === "as_of_date") {
                    let app_state = {}; let ui_state = {};
                    // let historicalRange = curr_app_state.historicalRange;
                    if (curr_app_state.workspace_controls.scopeType !== "Dba") {
                        //app_state.historicalRange = { ...historicalRange, ...deepCopy(tempDateRange) };
                    }
                    //app_state.primary_stay_range = deepCopy({ ...tempDateRange, as_of_date });
                    app_state.data_load = true;
                    app_state.kpi_load = true;
                    ui_state.showspinner = true;
                    app_state.comp_stay_range = comp_stay_range; //calculateCompStayRange({ primary_stay_range: tempDateRange, workspace_controls, property_id: app_state.property_id });
                    app_state.as_of_date = as_of_date;
                    app_state.date_range_type = '';

                    setTimeout(() => {

                        dispatch({
                            type: UPDATE_STATE, payload: {
                                app_state: {
                                    ...app_state,
                                    // historicalRange: { ...app_state.historicalRange, ...historicalRange },
                                    as_of_date,
                                    workspace_controls: { ...curr_app_state.workspace_controls },
                                },
                                ui_state: { ...ui_state }
                            }
                        });
                    }, 100);

                }


                // let comp_stay_range = calculateCompStayRange({ primary_stay_range: tempDateRange, workspace_controls: app_state.workspace_controls, property_id: app_state.property_id });
                // debugger;
                // APP_CACHE.primary_stay_range = deepCopy(tempDateRange);
                APP_CACHE.comp_stay_range = deepCopy(comp_stay_range);

                //Reseting the final proccessed data in APP_CACHE
                // debugger;
                APP_CACHE.chartData = null;
                APP_CACHE.tableData = null;
                APP_CACHE.renderTableData = null;

                // APP_CACHE.controls = deepCopy(app_state.workspace_controls);
                // setDisplayDateRange(displayDateRange);
                setDisplayDateRange({ ...displayDateRange, ...tempDateRange });
            });
            setAnchorEl(null);
    });

    const determineRangeSegment = useCallback((tempDateRange, type) => {
        let daysDiff = dateDiff(tempDateRange.date_until, tempDateRange.date_from);
        if (type === "comparisonStayDates" && daysDiff === 364) {
            return "sdly";
        }
        let startOfMonth = moment(new Date()).startOf('month');
        let endOfMonth = moment(tempDateRange.date_until).endOf('month');
        if (dateDiff(startOfMonth, tempDateRange.date_from) === 0 && dateDiff(endOfMonth, tempDateRange.date_until) === 0 && daysDiff <= 31) {
            return "current_month";
        }
        let isToday = (dateDiff(moment(), tempDateRange.date_from) === 0) ? true : false;
        if (isToday) {
            if (daysDiff === 29 || daysDiff === 59 || daysDiff === 89) {
                return daysDiff;
            }
        }
        else {
            return "custom";
        }
        return null
    });

    const shiftBackward = useCallback(() => {
        const appState = { primary_stay_range: {}, comp_stay_range: {} };
        let isMonth = false;
        let startOfMonth = new moment(displayDateRange.date_from).startOf('month');
        let endOfMonth = new moment(displayDateRange.date_until).endOf('month');
        let dateFrom = new moment(displayDateRange.date_from);
        let dateUntil = new moment(displayDateRange.date_until);
        let diffDays = dateDiff(dateUntil, dateFrom);
        let difMonth = monthDiff(dateUntil, dateFrom);
        if (startOfMonth.format("YYYY-MM-DD") === dateFrom.format("YYYY-MM-DD")
            && endOfMonth.format("YYYY-MM-DD") === dateUntil.format("YYYY-MM-DD")) {
            isMonth = true;
        }
        if (isMonth) {
            tempDateRange.date_from = dateFrom.subtract(difMonth, 'month').startOf('month').format("YYYY-MM-DD");
            tempDateRange.date_until = dateUntil.subtract(difMonth, 'month').endOf('month').format("YYYY-MM-DD");
        }
        if (diffDays === 0) {
            tempDateRange.date_from = dateFrom.subtract(1, 'day').format("YYYY-MM-DD");
            tempDateRange.date_until = dateUntil.subtract(1, 'day').format("YYYY-MM-DD");
        }
        if (!isMonth && diffDays > 0) {
            tempDateRange.date_from = dateFrom.subtract(diffDays + 1, 'day').format("YYYY-MM-DD");
            tempDateRange.date_until = dateUntil.subtract(diffDays + 1, 'day').format("YYYY-MM-DD");
        }
        if (datePickerConfig.dateRangeKey === "primary_stay_range") {
            appState.primary_stay_range.date_from = tempDateRange.date_from;
            appState.primary_stay_range.date_until = tempDateRange.date_until;
            // appState.comp_stay_range.date_from = toSdly(tempDateRange.date_from);
            // appState.comp_stay_range.date_until = toSdly(tempDateRange.date_until);
            if (tempDateRange.date_from !== tempDateRange.date_until) {
                appState.historicalRange = deepCopy(appState.primary_stay_range);
            }

            appState.data_load = true;
            appState.kpi_load = true;
            appState.date_range_type = '';

            if (tempDateRange.date_from !== tempDateRange.date_until) {
                appState.historicalRange = deepCopy(appState.primary_stay_range);
            }
            let comp_stay_range = calculateCompStayRange({ primary_stay_range: tempDateRange, workspace_controls, property_id: app_state.property_id });
            APP_CACHE.primary_stay_range = deepCopy(tempDateRange);
            APP_CACHE.comp_stay_range = deepCopy(comp_stay_range);

            displayDateRange.date_from = tempDateRange.date_from;
            displayDateRange.date_until = tempDateRange.date_until;
            setDisplayDateRange(displayDateRange);
            setTempDateRange(tempDateRange)

            ui_state.showspinner = true;
            APP_CACHE.compStayRange = {}
            calculateCompStayRangePromise({ primary_stay_range: tempDateRange, workspace_controls: app_state.workspace_controls, property_id: app_state.property_id })
                .then((rs) => {
                    let sdly_asof = rs.as_of_date
                    let sdly_from = rs.date_from
                    let sdly_to = rs.date_until;
                    let { sdtly_asof, sdtly_from, sdtly_to } = rs;
                    app_state.metas.osr = { sdly_from: sdly_from, sdly_to: sdly_to, sdly_asof: sdly_asof, sdtly_asof, sdtly_from, sdtly_to };
                    
                    //Reseting chart cache data
                    APP_CACHE.chartData = null;

                    dispatch({
                        type: UPDATE_STATE, payload: {
                            app_state: {
                                ...appState,
                                date_range_type: '',
                                primary_stay_range: { ...tempDateRange, date_from: tempDateRange.date_from, date_until: tempDateRange.date_until },
                                comp_stay_range: deepCopy(rs),
                                data_load: true, kpi_load: true
                            },
                            ui_state: {
                                showspinner: true
                            }
                        }
                    });

                });
        }
    })

    const shiftFoward = useCallback(() => {
        // debugger;
        let appState = { primary_stay_range: {}, comp_stay_range: {} };
        let isMonth = false;
        let startOfMonth = new moment(displayDateRange.date_from).startOf('month');
        let endOfMonth = new moment(displayDateRange.date_until).endOf('month');
        let dateFrom = new moment(displayDateRange.date_from);
        let dateUntil = new moment(displayDateRange.date_until);
        let diffDays = dateDiff(dateUntil, dateFrom);
        let difMonth = monthDiff(dateUntil, dateFrom);
        if (startOfMonth.format("YYYY-MM-DD") === dateFrom.format("YYYY-MM-DD")
            && endOfMonth.format("YYYY-MM-DD") === dateUntil.format("YYYY-MM-DD")) {
            isMonth = true;
        }

        if (isMonth) {
            tempDateRange.date_from = dateFrom.add(difMonth, 'month').startOf('month').format("YYYY-MM-DD");
            tempDateRange.date_until = dateUntil.add(difMonth, 'month').endOf('month').format("YYYY-MM-DD");
        }
        if (diffDays === 0) {
            tempDateRange.date_from = dateFrom.add(1, 'day').format("YYYY-MM-DD");
            tempDateRange.date_until = dateUntil.add(1, 'day').format("YYYY-MM-DD");
        }
        if (!isMonth && diffDays > 0) {
            tempDateRange.date_from = dateUntil.add(1, 'day').format("YYYY-MM-DD");
            tempDateRange.date_until = dateUntil.add(diffDays, 'day').format("YYYY-MM-DD");
        }

        if (datePickerConfig.dateRangeKey === "primary_stay_range") {
            appState.primary_stay_range.date_from = tempDateRange.date_from;
            appState.primary_stay_range.date_until = tempDateRange.date_until;
            //appState.comp_stay_range.date_from = toSdly(tempDateRange.date_from);
            //appState.comp_stay_range.date_until = toSdly(tempDateRange.date_until);
            if (tempDateRange.date_from !== tempDateRange.date_until) {
                appState.historicalRange = deepCopy(appState.primary_stay_range);
            }

            appState.data_load = true;
            appState.kpi_load = true;
            appState.date_range_type = '';

            if (tempDateRange.date_from !== tempDateRange.date_until) {
                appState.historicalRange = deepCopy(appState.primary_stay_range);
            }

            let comp_stay_range = {}; //calculateCompStayRange({ primary_stay_range: tempDateRange, workspace_controls, property_id: app_state.property_id });
            APP_CACHE.primary_stay_range = deepCopy(tempDateRange);
            APP_CACHE.comp_stay_range = deepCopy(comp_stay_range);

            displayDateRange.date_from = tempDateRange.date_from;
            displayDateRange.date_until = tempDateRange.date_until;
            setDisplayDateRange(displayDateRange);
            setTempDateRange(tempDateRange);

            ui_state.showspinner = true;
            APP_CACHE.compStayRange = {}
            calculateCompStayRangePromise({ primary_stay_range: tempDateRange, workspace_controls: app_state.workspace_controls, property_id: app_state.property_id })
                .then((rs) => {

                    let sdly_asof = rs.as_of_date
                    let sdly_from = rs.date_from
                    let sdly_to = rs.date_until;
                    let { sdtly_asof, sdtly_from, sdtly_to } = rs;
                    app_state.metas.osr = { sdly_from: sdly_from, sdly_to: sdly_to, sdly_asof: sdly_asof, sdtly_asof, sdtly_from, sdtly_to };

                    comp_stay_range = rs;
                    APP_CACHE.comp_stay_range = deepCopy(comp_stay_range);
                    //Reseting chart cache data
                    APP_CACHE.chartData = null;
                    dispatch({
                        type: UPDATE_STATE, payload: {
                            app_state: {
                                ...appState,
                                primary_stay_range: tempDateRange,
                                comp_stay_range: deepCopy(comp_stay_range),
                                // historicalRange: deepCopy(app_state.primary_stay_range),
                                // data_load: true,
                                // kpi_load: true,
                                // ...app_state,
                            },
                            ui_state: {
                                showspinner: true
                                // ...ui_state,
                            }
                        }
                    });


                });
        }
    });

    const handleDateInputFocus = (calendar_on) => {
        setCalendarOn(calendar_on)
    }
    const handleDateInputChange = useCallback((date, key) => {
        if (key === "single") {
            setSingleDate(date)
        }
        if (key === "as_of") {
            setAsOfDate(date)
        }
        if (key === "date_from") {
            setInputDateFrom(date)
        }
        if (key === "date_until") {
            setInputDateUntil(date)
        }

        if (moment(new Date(date)).isValid() && date.length === 10) {
            if (key === "single") {
                tempDateRange.date_from = moment(new Date(date)).format("YYYY-MM-DD");
                tempDateRange.date_until = moment(new Date(date)).format("YYYY-MM-DD");
            }
            if (key === "as_of") {
                if (moment(date, "MM/DD/YYYY").isSameOrBefore(moment(profile.current_property_date))) {
                    tempDateRange.as_of_date = moment(new Date(date)).format("YYYY-MM-DD");
                    if (rangeType === "single_date") {
                        tempDateRange.date_until = tempDateRange.date_from;
                    }
                }
            }
            if (key === "date_from") {
                tempDateRange.date_from = moment(new Date(date)).format("YYYY-MM-DD");
            }
            if (key === "date_until") {
                tempDateRange.date_until = moment(new Date(date)).format("YYYY-MM-DD");
            }
        }
    });

    const onDateChange = useCallback((event, date, key) => {
        if (
            isValidDateAndFormat(singleDate) &&
            isValidDateAndFormat(asOfDate) &&
            moment(asOfDate, "MM/DD/YYYY").isSameOrBefore(moment(profile.current_property_date)) &&
            isValidDateAndFormat(inputDateFrom) &&
            isValidDateAndFormat(inputDateUntil) &&
            moment(new Date(inputDateUntil)).diff(new Date(inputDateFrom), 'days') >= 0
        ) {
            setIsApplyEnable(true)
        }
        let calendar_on = "";
        if (datePickerConfig.type === "comparisonStayDates") {
            if ("date_from" === key) {
                tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
                tempDateRange.date_until = moment(tempDateRange.date_from).add(datePickerConfig.comparisonDiff, 'days').format("YYYY-MM-DD");
            }

        } else {
            if ("single" === key) {
                tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
                tempDateRange.date_until = tempDateRange.date_from;
                setSingleDate(moment(date[0]).format("MM/DD/YYYY"));
            }

            if ("as_of" === key) {
                tempDateRange.as_of_date = moment(date).format("YYYY-MM-DD");
                setAsOfDate(moment(date).format("MM/DD/YYYY"));
            }
            if ("date_from" === key) {
                tempDateRange.date_from = moment(date[0]).format("YYYY-MM-DD");
                setInputDateFrom(moment(date[0]).format("MM/DD/YYYY"))
            }
            if ("date_until" === key) {
                tempDateRange.date_until = moment(date[0]).format("YYYY-MM-DD");
                setInputDateUntil(moment(date[0]).format("MM/DD/YYYY"))
            }
            if ("weeks" === key) {
                if (event && event.shiftKey === true) {
                    let orgStartDate = moment(tempDateRange.date_from, "YYYY-MM-DD").toDate();
                    let orgEndDate = moment(tempDateRange.date_until, "YYYY-MM-DD").toDate();
                    let start = moment(date[0]).startOf("week"), end = moment(date[0]).endOf("week");

                    if (moment(date[0]).diff(orgStartDate, 'days') < 0) {
                        start = moment(date[0]).startOf("week");
                        end = moment(orgEndDate).endOf("week");
                    }

                    if (moment(date[0]).diff(orgEndDate, 'days') > 0) {
                        end = moment(date[0]).endOf('week');
                        start = moment(orgStartDate).startOf("week");

                    }
                    if (moment(date[0]).diff(orgEndDate, 'days') < -7 && moment(date[0]).diff(orgStartDate, 'days') > 0) {
                        end = moment(date[0]).endOf('week');

                    }

                    tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
                    tempDateRange.date_until = moment(end).format("YYYY-MM-DD");

                    calendar_on = "weeks";
                }
                else {
                    let start = moment(date[0]).startOf('week');
                    let end = moment(date[0]).endOf('week');
                    tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
                    tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
                    calendar_on = "weeks";
                }


            }
            if ("months" === key) {
                if (event && event.shiftKey === true) {

                    let start = moment(date[0]).startOf('month');
                    let end = moment(date[0]).endOf('month');
                    if (moment(date[0]).diff(moment(tempDateRange.date_from, "YYYY-MM-DD"), 'days') < 0) {
                        start = moment(date[0]).startOf("month");
                        end = moment(moment(tempDateRange.date_until, "YYYY-MM-DD")).endOf("month");
                    }

                    if (moment(date[0]).diff(moment(tempDateRange.date_until, "YYYY-MM-DD"), 'days') > 0) {
                        end = moment(date[0]).endOf('month');
                        start = moment(moment(tempDateRange.date_from, "YYYY-MM-DD")).startOf("month");

                    }

                    tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
                    tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
                } else {
                    let start = moment(date[0]).startOf('month');
                    let end = moment(date[0]).endOf('month');
                    tempDateRange.date_from = moment(start).format("YYYY-MM-DD");
                    tempDateRange.date_until = moment(end).format("YYYY-MM-DD");
                }
            }
        }
        setCalendarOn(calendar_on);
        setTempDateRange({ ...tempDateRange });
    });
    const handleRangeChange = useCallback((range_type) => {
        switch (range_type) {
            case "single_date":
                tempDateRange.date_from = moment(displayDateRange.date_from).format("YYYY-MM-DD");
                tempDateRange.date_until = moment(displayDateRange.date_from).format("YYYY-MM-DD");
                break;
            case "range":
                tempDateRange.date_from = moment(displayDateRange.date_from).format("YYYY-MM-DD");
                tempDateRange.date_until = moment(displayDateRange.date_until).format("YYYY-MM-DD");
                break;
            case "weeks":
                // tempDateRange.date_from = moment(displayDateRange.date_from).startOf('week').format("YYYY-MM-DD");
                // tempDateRange.date_until = moment(displayDateRange.date_from).endOf('week').format("YYYY-MM-DD");
                tempDateRange.date_from = moment(displayDateRange.date_from).format("YYYY-MM-DD");
                tempDateRange.date_until = moment(displayDateRange.date_until).format("YYYY-MM-DD");
                break;
            case "months":
                // tempDateRange.date_from = moment(displayDateRange.date_from).startOf('month').format("YYYY-MM-DD");
                // tempDateRange.date_until = moment(displayDateRange.date_from).endOf('month').format("YYYY-MM-DD");
                tempDateRange.date_from = moment(displayDateRange.date_from).format("YYYY-MM-DD");
                tempDateRange.date_until = moment(displayDateRange.date_until).format("YYYY-MM-DD");
                break;
            default:
                break;
        }
        setCalendarOn(range_type);
        setRangeType(range_type);
    });

    const rangeSegmentChange = useCallback((valueRangeSegment) => {
        if (valueRangeSegment === "current_month") {
            tempDateRange.date_from = moment().startOf('month').format("YYYY-MM-DD");
            tempDateRange.date_until = moment().endOf('month').format("YYYY-MM-DD");
        }
        if (valueRangeSegment === 29 || valueRangeSegment === 59 || valueRangeSegment === 89) {
            tempDateRange.date_from = moment().format("YYYY-MM-DD"); /// need to add as of date/ data availabilty issue
            tempDateRange.date_until = moment().add(valueRangeSegment, 'days').format("YYYY-MM-DD");
        }
        if (valueRangeSegment === "custom") {

            tempDateRange.date_from = moment().format("YYYY-MM-DD");
            tempDateRange.date_until = moment().add(valueRangeSegment, 'days').format("YYYY-MM-DD");

        }
        setInputDateFrom(moment(tempDateRange.date_from).format("MM/DD/YYYY"))
        setInputDateUntil(moment(tempDateRange.date_until).format("MM/DD/YYYY"))
        setTempDateRange(tempDateRange);
        setRangeSegment(valueRangeSegment);
        setCalendarOn('');
    });
    let dateRange = [moment(tempDateRange.date_from).toDate(), moment(tempDateRange.date_until).toDate()]
    let fromDateCalendar = (<div key='from-date' id="date-picker-calendar">
        <Calendar
            onChange={(date) => { onDateChange(null, date, "date_from"); }}
            value={dateRange}
            returnValue="range"
            calendarType="US"
            minDetail="year"
            formatMonth={(locale, date) => dateFormat(date, 'MMM')}
            showNeighboringMonth={false}
        />
    </div>);


    let toDateCalendar = (<div key='to-date' id="date-picker-calendar">
        <Calendar
            onChange={(date) => { onDateChange(null, date, "date_until"); }}
            value={dateRange}
            returnValue="range"
            calendarType="US"
            minDetail="year"
            formatMonth={(locale, date) => dateFormat(date, 'MMM')}
            showNeighboringMonth={false}
        />
    </div>);

    let singleCalendar = (<div id="single-date-picker-calendar">
        <Calendar
            onChange={(date) => { onDateChange(null, date, "single"); }}
            value={moment(tempDateRange.date_from).toDate()}
            returnValue="range"
            calendarType="US"
            minDetail="year"
            formatMonth={(locale, date) => dateFormat(date, 'MMM')}
            showNeighboringMonth={false}
        />
    </div>);
    let weeksCalendar = (<div key={'weeksCalendar'} id="week-picker-calendar">
        <Calendar
            onChange={(date, event) => { onDateChange(event, date, "weeks"); }}
            value={dateRange}
            returnValue="range"
            calendarType="US"
            maxDetail="month"
            minDetail="year"
            view="month"
            defaultActiveStartDate={dateRange[1]}
            formatMonth={(locale, date) => dateFormat(date, 'MMM')}
        />
    </div>);
    let monthsCalendar = (<div id="month-picker-calendar">
        <Calendar
            onChange={(date, event) => { onDateChange(event, date, "months"); }}
            value={dateRange}
            returnValue="range"
            calendarType="US"
            maxDetail="year"
            view="year"
            prev2Label={null}
            next2Label={null}
            formatMonth={(locale, date) => dateFormat(date, 'MMM')}
        />
    </div>);
    let asOfCalendar = (<div id="date-picker-calendar-asof">
        <Calendar
            onChange={(date) => { onDateChange(null, date, "as_of"); }}
            value={moment(tempDateRange.as_of_date).toDate()}
            returnValue="start"
            calendarType="US"
            minDetail="year"
            maxDate={moment(profile.current_property_date).toDate()}
            prev2Label={null}
            next2Label={null}
            formatMonth={(locale, date) => dateFormat(date, 'MMM')}
            className={isApplyEnable ? 'calendar-asof' : 'calendar-asof hide-date-selection'}
        />
    </div>);
    let rangeSegmentLabels = navigation.range.range_segments.map((segment, index) => {
        let label = "";
        switch (segment) {
            case "current_month":
                label = <><span className='this-month'>This</span><span className='days-label'> Month</span></>;
                break;
            case 29:
                label = <><span className='next-label'>next</span><span className='days-num'>30</span><span className='days-label'> days</span></>;
                break;
            case 59:
                label = <><span className='next-label'>next</span><span className='days-num'>60</span><span className='days-label'> days</span></>;
                break;
            case 89:
                label = <><span className='next-label'>next</span><span className='days-num'>90</span><span className='days-label'> days</span></>;
                break;
            case "sdly":
                label = "Same Period, SDLY";
                break;
            case "custom":
                label = "Custom";
                break;
            default:
                label = "";
        }
        return (<Button key={index} className={(rangeSegment === segment) ? "days-num-btn active" : "days-num-btn"} onClick={() => { rangeSegmentChange(segment) }}>{label}</Button>)
    })

    let ranges = Object.entries(navigation);
    let rangeTypes = ranges.map((item, index) => {
        let disabledClass = (in_array(active_tiles[0], ["DailyRatesendStatus", "restrictions_push_status", "rm_room_type_ratesend_status"])
            && app_state.workspace_controls.logsRatePushMode === "date" && item[0] !== "single_date") ? "disabled-range-type" : "";

        let range_type = (disabledClass && disabledClass !== "") ? "single_date" : rangeType;
        if (app_state.workspace_controls.scopeType === 'Dba') {
            disabledClass = "disabled-range-type";
            range_type = "single_date";
        } else {
            disabledClass = "";
            range_type = rangeType;
        }
        return (<label key={index} className={(range_type !== item[0]) ? disabledClass + " btn btn-default " : "btn btn-default active"} onClick={() => {
            if (!disabledClass) {
                handleRangeChange(item[0])
            }
        }}>{item[1].label}</label>)
    });
    switch (rangeType) {
        case "single_date":
            selector = (<div className="range-selector row" style={{ "paddingTop": "10px" }}>
                <div className="form-group col-md-12" style={{ "marginBottom": 0 }}>
                    <TextField
                        fullWidth
                        label="Date:"
                        inputProps={{ tabIndex: "1" }}
                        placeholder='mm/dd/yyyy'
                        size="small"
                        id="singleDatePicker"
                        className='date-picker'
                        name="singleDate"
                        error={!isValidDateAndFormat(singleDate)}
                        onFocus={() => { handleDateInputFocus("single") }}
                        onChange={(event) => handleDateInputChange(event.target.value, "single")}
                        value={singleDate}
                    />
                </div>
                {(calendarOn === "single") && singleCalendar}
            </div>);
            break;
        case "range":

            selector = (<div>
                <div className="range-segments " >
                    <ButtonGroup className='days-slect-group' size='small' fullWidth variant="outlined" aria-label="outlined primary button group">
                        {rangeSegmentLabels}
                    </ButtonGroup>
                </div>
                <div className="range-selector" style={{ "paddingTop": "20px" }}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                label="From:"
                                inputProps={{ tabIndex: "1" }}
                                placeholder='mm/dd/yyyy'
                                size="small"
                                id="dateFrom"
                                name="dateFrom"
                                className="date-picker range-from"
                                error={!moment(new Date(inputDateFrom)).isSameOrBefore(new Date(inputDateUntil)) || !isValidDateAndFormat(inputDateFrom)}
                                onFocus={() => { handleDateInputFocus("date_from") }}
                                onChange={(event) => handleDateInputChange(event.target.value, "date_from")}
                                value={inputDateFrom}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="To:"
                                inputProps={{ tabIndex: "1" }}
                                placeholder='mm/dd/yyyy'
                                size="small"
                                id="dateUntil"
                                name="dateUntil"
                                className="date-picker range-until"
                                error={!moment(new Date(inputDateUntil)).isSameOrAfter(new Date(inputDateFrom)) || !isValidDateAndFormat(inputDateUntil)}
                                onFocus={() => { handleDateInputFocus("date_until") }}
                                onChange={(event) => handleDateInputChange(event.target.value, "date_until")}
                                value={inputDateUntil}
                                disabled={datePickerConfig.type === "comparisonStayDates"}
                            />
                        </Grid>
                        <div>

                            {(calendarOn === "date_from" && navigation.range.inputs.indexOf("date_from") > -1) && <div className='calendar-box'>{fromDateCalendar}</div>}
                            {(calendarOn === "date_until" && navigation.range.inputs.indexOf("date_until") > -1) && <div className='calendar-box'>{toDateCalendar}</div>}
                        </div>
                    </Grid>



                </div>
            </div>);
            break;

        case "weeks":
            selector = (<div className="range-selector row" style={{ "paddingTop": "10px" }}>
                {weeksCalendar}
            </div>);
            break;

        case "months":
            selector = (<div className="range-selector row" style={{ "paddingTop": "10px" }}>
                {monthsCalendar}
            </div>);
            break;
        default:
            selector = (<span></span>);
    }

    let popoverStyles = { "top": "0px", "display": "block", "visibility": "visible", "width": "300px", };
    let isMobile = isMobileDevice();
    if (isMobile) {
        popoverStyles.top = "-40px";
        popoverStyles.transform = "scale(.65)";
    }
    let noAsOfTiles = ['DailyRatesendStatus', 'rm_room_type_ratesend_status', "restrictions_push_status"];
    let popup = (<div className="popover bottom-left" style={popoverStyles}>
        <div className="arrow"></div>
        <div className="popover-content" style={{ "padding": "9px" }}>
            <div>
                {app_state.workspace_controls.scopeType === "Dba" ||
                    (noAsOfTiles.includes(active_tiles[0]) && app_state.workspace_controls.logsRatePushMode === 'date') ? null :
                    <div className="btn-group btn-group-justified range-types " >
                        {rangeTypes}
                    </div>
                }
                {selector}

                {(navigation[rangeType].as_of_date === true) && (<div className="asof-selector clearfix" style={{ "clear": "both" }}>


                    <Grid container spacing={1} sx={{ mt: 1.5 }}>

                        <Grid item xs={7}>

                            {
                                ((app_state.workspace_controls.scopeType === "Dba" && active_tiles[0] === "pace") ||
                                    (in_array(active_tiles[0], ["DailyRatesendStatus", "rm_room_type_ratesend_status", "restrictions_push_status", ]) && app_state.workspace_controls.logsRatePushMode === "date" ) ||
                                    (in_array(active_tiles[0], ["bi_sensitivity_analysis" ])) ?
                                    <></> : <div>
                                        <TextField
                                            label="As Of Date:"
                                            inputProps={{ tabIndex: "1" }}
                                            placeholder='mm/dd/yyyy'
                                            size="small"
                                            id="asOfDate"
                                            name="asOfDate"
                                            className='date-picker'
                                            error={moment(asOfDate, "MM/DD/YYYY").isAfter(moment(profile.current_property_date)) || !isValidDateAndFormat(asOfDate)}
                                            onFocus={() => { handleDateInputFocus("as_of") }}
                                            onChange={(event) => handleDateInputChange(event.target.value, "as_of")}
                                            value={asOfDate}
                                        />
                                    </div>
                                )}
                        </Grid>
                        <Grid item xs={5}>
                            <div>{
                                isApplyEnable ?
                                    (<button type="button" className="btn btn-info btn-block " tabIndex={1} onClick={() => applyChange("as_of_date", 'picker')} >Apply</button>) :
                                    (<button type="button" className="btn btn-info btn-block btn-disabled">Apply</button>)
                            }</div>
                        </Grid>

                        {(calendarOn === "as_of" === true) &&
                            <Grid item xs={12}>
                                <div className='calendar-box'>
                                    {asOfCalendar}
                                </div>
                            </Grid>
                        }


                    </Grid>
                    <div className="row">


                    </div>

                </div>
                )}
            </div>
        </div>
    </div>
    )
    let shiftBackwardBtn = () => (<button type="button" className="btn-stay-range shift-prev" onClick={shiftBackward} disabled={ui_state.showspinner}>
        {/* <ArrowBackIosIcon fontSize='small' /> */}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#606060" viewBox="0 0 256 256"><path d="M168,48V208a8,8,0,0,1-13.66,5.66l-80-80a8,8,0,0,1,0-11.32l80-80A8,8,0,0,1,168,48Z"></path></svg>
    </button>);
    let shiftFowardBtn = () => (<button type="button" className="btn-stay-range shift-next" onClick={shiftFoward} disabled={ui_state.showspinner}>
        {/* <ArrowForwardIosIcon fontSize='small' /> */}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#606060" viewBox="0 0 256 256"><path d="M181.66,133.66l-80,80A8,8,0,0,1,88,208V48a8,8,0,0,1,13.66-5.66l80,80A8,8,0,0,1,181.66,133.66Z"></path></svg>
    </button>);
    let topLabel = (<span></span>);
    //let noAsOfTiles = ['DailyRatesendStatus', 'rm_room_type_ratesend_status', "restrictions_push_status"];
    if (noAsOfTiles.includes(active_tiles[0]) && app_state.workspace_controls.logsRatePushMode === 'date') {
        // debugger;
        topLabel = (<label className="label"> </label>);
    } else if (app_state.workspace_controls.scopeType === "Dba" && active_tiles[0] === 'pace' || active_tiles[0] === 'bi_sensitivity_analysis') {
        topLabel = (<label className="label"> </label>);
    } else if (datePickerConfig.type === "primaryStayDates") {
        topLabel = (<label className="label">Stay Dates -
            <span className="date-text"> As of </span>
            <span className="">{dateFormat(displayDateRange.as_of_date, "ddd, MMM DD, YYYY")}</span>
        </label>);
    } else if (datePickerConfig.type === "comparisonStayDates") {
        topLabel = (<label className="label">Compare To</label>);
    }
    return (
        <>


            <div className="smart-date-picker" style={{ "display": " inline-block", "verticalAlign": "top" }}>
                <div className="chart-stay-date clearfix current-date-range" id="stay-date">
                    {topLabel}
                    <div className="stay-date-picker clearfix" id="stay-date-picker-id">
                        {(datePickerConfig.shiftBtns === true) && shiftBackwardBtn()}
                        <button type="button" className="btn-stay-picker clearfix"
                            onClick={(event) => { openDatePickerHandler(event) }}
                        >
                            {/* {(datePickerConfig.dateRangeKey === "comparison_stay_range" && initialLoad) && (<span>Select Dates</span>)} */}
                            {(datePickerConfig.dateRangeKey !== "comparison_stay_range") && (<span><span className="rangeFromDate ">{dateFormat(displayDateRange.date_from, "ddd, MMM DD")}</span> -
                                <span className="rangeToDate" >{dateFormat(displayDateRange.date_until, "ddd, MMM DD, YYYY")}</span> </span>)}
                        </button>
                        <Popover
                            PaperProps={{ elevation: 0, sx: { backgroundColor: 'transparent', boxShadow: 'none', mt: -1 } }}
                            id={id}
                            open={openDatePicker}
                            anchorEl={anchorEl}
                            onClose={() => closeDatePickerHandler()}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            sx={{ backgroundColor: 'transparent' }}
                            className="elevation0 date-picker-popover"
                        >
                            <div className='date-picker-wrapper'>
                                {popup}
                            </div>

                        </Popover>
                        {(datePickerConfig.shiftBtns === true) && shiftFowardBtn()}

                    </div>
                </div>
            </div>
        </>);
}