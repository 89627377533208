//React
import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate } from "react-router-dom";

//React Redux
import { useDispatch, useSelector } from 'react-redux';
import { settingsReducers } from './reducers';
//MUI
import {
    Grid, Button, LinearProgress, Badge, Modal, Alert,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Switch
} from '@mui/material';
import {
    CloseSharp as CloseSharpIcon,
    ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
    ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
    CheckSharp as CheckSharpIcon
}
    from '@mui/icons-material';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';

import { border, Box, fontSize, textAlign } from '@mui/system';

//Moments
import moment from 'moment';

import Paper from '@mui/material/Paper';

//APP's
import theme from '../../theme';
import { styled } from "@mui/material/styles";

import './dailyflash.css'
import dataJson from './data365.json';
import { calculateMonthlyData, getMonthlyData } from './functions';
import dataService from '../../services/data.service';
import Loading from './Loading';
import { convertTOCorrectValue, getDayDateKey } from '../../app/util';



import Skeleton from '@mui/material/Skeleton';
import Lottie from 'react-lottie';
import TableLoadingLottie from "../../lotties/table-loading.json";
import GraphLoadingLottie from "../../lotties/graph-loading.json";
import LockLayer from "../LockLayer/index.js";
import msg from "../Workspace/messages.js";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#ebebeb",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));


function DataTable(props) {
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let currSymbol = ui_state.currency_symbol;
    const data = props.data;
    const resError = props.resError;
    const [expView, setExpView] = useState(true);
    const { dayDate } = app_state.workspace_controls;
    let dayDateSdly = (dayDate === 'day') ? "sdly" : "sdtly";
    let sdlyText = props.sdlyText;

    const handleChange = (type, value) => {
        setExpView(value);
    }
    let expViewClass = "dn";
    let colspanCount = 3;
    let colspanPickOver = 3;
    if (expView === true) {
        expViewClass = "";
        colspanCount = 6;
        colspanPickOver = 6;
    }

    const calculateColWidth = () => {
        let colWidth = [];
        let data_cols = (expView) ? 25 : 15;
        for (let i = 1; i <= data_cols; i++) {
            colWidth.push(13)
        }
        return (colWidth.toString()).trim();
    }
    let colExclude = (expViewClass && expViewClass != "") ? true : false;
    let dtFormat = "ddd, MMM DD, YYYY";


    //skeleton loader starts
    function randomMessage() {
        const messages = msg;

        const randomIndex = Math.floor(Math.random() * messages.length);
        const randomMessage = messages[randomIndex];

        return randomMessage;
    }
    const tableSkeletonLoader = (
        <Box p={0} m={0}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="table-view-scaleton-loader">
                <TableHead sx={{ background: "#606060", opacity: "0.4" }} className="table-head">
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell sx={{ width: "15%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "70%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "15%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                </TableHead>
            </Table>

            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="table-view-scaleton-loader">
                <TableHead sx={{ background: "#01A4EF", opacity: "0.3" }} className="table-head">
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell sx={{ background: "#B2B2B2", width: "7.5%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ background: "#B2B2B2", width: "7.5%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "10vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "25vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "30vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody className="table-body">

                    <TableRow >
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="table-view-scaleton-loader">
                <TableHead sx={{ background: "#01A4EF", opacity: "0.3" }} className="table-head">
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell sx={{ width: "15%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "10vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "25vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "30vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                </TableHead>
            </Table>

        </Box>
    );
    const defaultOptionsTable = {
        loop: true,
        autoplay: true,
        animationData: TableLoadingLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const TableLottieLoader = () => {
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const timer = setTimeout(() => {
                setLoading(true);
            }, 2000); // Adding delay in milliseconds 

            // Clear the timeout if the component is unmounted before the delay is completed
            return () => clearTimeout(timer);
        }, []);

        if (loading) {
            return (
                <Box className="lottie-main-container">
                    <Box className="table-lottie-container" sx={{ top: "18vh !important" }}>
                        <Lottie
                            options={defaultOptionsTable}
                            height={40}
                            width={40}
                        />
                        <div>
                            {randomMessage()}
                        </div>
                    </Box>
                </Box>
            );
        }
        return null;
    };
    //skeleton loader ends
    let resultSet = dataService.getSeriesById("OSRYear").data;
    let displayAsOfDate = (resultSet && resultSet.meta && resultSet.meta.as_of) ? resultSet.meta.as_of : app_state.as_of_date;
    let sdlyAsOf = app_state.as_of_date;
    // if(resultSet && resultSet.meta && resultSet.meta.sdly_asof && resultSet.meta.sdtly_asof){ lrv-880 uncomment this line
    if(resultSet && resultSet.meta && resultSet.meta.sdly_asof){
        sdlyAsOf = (dayDate === 'day') ? resultSet.meta.sdly_asof : resultSet.meta.sdtly_asof;
    } 
    return (
        <div className={resError ? '' : 'data-table-container'}>
            <Grid item xs={12} sx={{ display: resError ? "all" : "none", minHeight: "348px" }}>
                <Alert severity="info">
                    Something went wrong, please contact success@lodgiq.com for further details.
                </Alert>
            </Grid>
            <Grid item xs={12} sx={{ display: !resError ? "all" : "none" }}>
                {(props.data && props.data.length > 0) ? (
                    <>
                        <Grid className='inner-header'>
                            <span className='days-update-label' style={{ textTransform: "unset" }}>On the Book (By Month)</span>
                            <span className='days-update-label' style={{ float: "right", textTransform: "unset" }}>
                                Expanded Data View  <Switch color='white' checked={expView} onChange={() => {
                                    handleChange("expendedView", !expView)
                                }} /></span>
                        </Grid>
                        <Grid sx={{ pl: 0, pr: 0, background: '#dfdfdf' }}>
                            <Paper>
                                <TableContainer className='daily-flash-table' sx={{ maxHeight: 310, overflow: 'auto' }}>

                                    <Table id="dailyFlashTable" data-cols-width={calculateColWidth()} stickyHeader size="small" aria-label="sticky table">
                                        <TableHead>
                                            <TableRow sx={{ '& td, & th': { pt: 1.5, pb: 1.5, top: "0px !important" } }}>
                                                <TableCell stickyHeader align="center" colSpan={1} className='table-columns-header top-header firstcolumn'
                                                    sx={{ background: '#3EA7CD', minWidth: '100px', }}
                                                    data-fill-color="3EA7CD"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-b-b-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    {/* {calculateColWidth()} */}
                                                </TableCell>
                                                <TableCell align="center" colSpan={colspanCount} className='table-columns-header top-header'
                                                    data-fill-color="81d4fa"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-b-b-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    OTB As Of - {moment(displayAsOfDate).format(dtFormat)}
                                                </TableCell>
                                                <TableCell align="center" colSpan={colspanPickOver} className='table-columns-header top-header'
                                                    data-fill-color="606060"
                                                    data-f-color="ffffff"
                                                    data-b-b-s="hair"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">Pickup Over - {moment(displayAsOfDate).subtract(1, "days").format(dtFormat)}
                                                </TableCell>
                                                <TableCell align="center" colSpan={colspanCount} className='table-columns-header top-header'
                                                    data-fill-color="29b6f6"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-b-b-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    {/* OTB As Of SDLY - {moment(displayAsOfDate).subtract(364, 'days').format(dtFormat)} */}
                                                    OTB As Of {sdlyText} - {moment(sdlyAsOf).format(dtFormat)}
                                                </TableCell>
                                                <TableCell align="center" colSpan={6} className='table-columns-header top-header'
                                                    data-fill-color="0277bd"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-b-b-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    Difference To {sdlyText}
                                                </TableCell>

                                            </TableRow>


                                            <TableRow sx={{ '& td, & th': { pt: 1.5, pb: 1.5, top: "30px" } }}>
                                                <TableCell align="center" colSpan={1} className='table-columns-header second-header firstcolumn'
                                                    sx={{ background: '#3EA7CD', minWidth: '100px' }}
                                                    data-fill-color="3EA7CD"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    Month
                                                </TableCell>

                                                <TableCell data-exclude={colExclude} align="center" className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#81d4fa' }}
                                                    data-fill-color="81d4fa"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    Occ%
                                                </TableCell>
                                                <TableCell align="center" className='table-columns-header second-header' sx={{ background: '#81d4fa' }}
                                                    data-fill-color="81d4fa"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-cols-width="30"
                                                    data-f-sz="14">
                                                    Rooms
                                                </TableCell>
                                                <TableCell align="center" className='table-columns-header second-header' sx={{ background: '#81d4fa' }}
                                                    data-fill-color="81d4fa"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    ADR
                                                </TableCell>
                                                <TableCell data-exclude={colExclude} align="center" className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#81d4fa' }}
                                                    data-fill-color="81d4fa"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    RevPAR
                                                </TableCell>
                                                <TableCell data-exclude={colExclude} align="center" className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#81d4fa' }}
                                                    data-fill-color="81d4fa"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    Revenue
                                                </TableCell>
                                                <TableCell align="center" className='table-columns-header second-header'
                                                    sx={{ background: '#81d4fa' }}
                                                    data-fill-color="81d4fa"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    Sell Rate
                                                </TableCell>
                                                {/* end of OTB As Of  */}



                                                <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#25a4dd' }}
                                                    data-fill-color="606060"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-exclude={colExclude}
                                                    data-f-sz="14">
                                                    Occ%
                                                </TableCell>
                                                <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#25a4dd' }}
                                                    data-fill-color="606060"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    Rooms
                                                </TableCell> <TableCell align="center" key={Math.random()} className={'table-columns-header second-header'} sx={{ background: '#25a4dd' }}
                                                    data-fill-color="606060"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    ADR
                                                </TableCell>
                                                <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#25a4dd' }}
                                                    data-fill-color="606060"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-exclude={colExclude}
                                                    data-f-sz="14">
                                                    RevPAR
                                                </TableCell>
                                                <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#25a4dd' }}
                                                    data-fill-color="606060"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-exclude={colExclude}
                                                    data-f-sz="14">
                                                    Revenue
                                                </TableCell>
                                                <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#25a4dd' }}
                                                    data-fill-color="606060"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    // data-exclude={colExclude}
                                                    data-f-sz="14">
                                                    Sell Rate
                                                </TableCell>
                                                {/* End of PickOver  */}
                                                <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#29b6f6' }}
                                                    data-fill-color="29b6f6"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-exclude={colExclude}
                                                    data-f-sz="14">
                                                    Occ%
                                                </TableCell><TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#29b6f6' }}
                                                    data-fill-color="29b6f6"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">

                                                    Rooms
                                                </TableCell>
                                                <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#29b6f6' }}
                                                    data-fill-color="29b6f6"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    ADR
                                                </TableCell>
                                                <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#29b6f6' }}
                                                    data-fill-color="29b6f6"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-exclude={colExclude}
                                                    data-f-sz="14">
                                                    RevPAR
                                                </TableCell>
                                                <TableCell align="center" key={Math.random()} className={'table-columns-header second-header ' + expViewClass} sx={{ background: '#29b6f6' }}
                                                    data-fill-color="29b6f6"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-exclude={colExclude}
                                                    data-f-sz="14">
                                                    Revenue
                                                </TableCell>
                                                <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#29b6f6' }}
                                                    data-fill-color="29b6f6"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    Sell Rate
                                                </TableCell>
                                                {/* End of OTB as of SDLY  */}


                                                <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                    data-fill-color="0277bd"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    Occ%
                                                </TableCell> <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                    data-fill-color="0277bd"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    Rooms
                                                </TableCell> <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                    data-fill-color="0277bd"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    ADR
                                                </TableCell>
                                                <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                    data-fill-color="0277bd"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    RevPAR
                                                </TableCell> <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                    data-fill-color="0277bd"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    Revenue
                                                </TableCell> <TableCell align="center" key={Math.random()} className='table-columns-header second-header' sx={{ background: '#0277bd' }}
                                                    data-fill-color="0277bd"
                                                    data-f-color="ffffff"
                                                    data-b-r-s="hair"
                                                    data-a-v="middle"
                                                    data-b-a-c="ffffff"
                                                    data-a-h="center"
                                                    data-f-sz="14">
                                                    Sell Rate
                                                </TableCell>
                                                {/* End Of Variance  */}



                                            </TableRow>


                                        </TableHead>


                                        <TableBody>
                                            {
                                                
                                                data.map((item, index) => {
                                                    let rowsBackColor = (index % 2 !== 0) ? "ebebeb" : "ffffff";
                                                    return <StyledTableRow className='data-rows' sx={{ '& td, & th': { pt: 1.5, pb: 1.5, top: "62px !important" } }}>

                                                        <>
                                                            <TableCell
                                                                data-fill-color={rowsBackColor}
                                                                data-b-r-s="dashed" data-b-a-c="ffffff"
                                                                data-t="s" data-f-sz="13" data-a-h="center"
                                                                className='table-columns firstcolumn'>
                                                                {item.monthData}
                                                            </TableCell>
                                                            <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                                {convertTOCorrectValue(item.primary.Occ, "Occ", currSymbol)}
                                                            </TableCell>

                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                                {convertTOCorrectValue(item.primary.Rooms, "Rooms", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                                {convertTOCorrectValue(item.primary.ADR, "ADR", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                                {convertTOCorrectValue(item.primary.RevPAR, "RevPAR", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-exclude={colExclude} data-a-h="right" data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-f-sz="13" className={'table-columns revenue-txt ' + expViewClass}>
                                                                {convertTOCorrectValue(item.primary.Revenue, "Revenue", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns rightBorder'>
                                                                {convertTOCorrectValue(item.primary.SellRate, "SellRate", currSymbol)}
                                                            </TableCell>



                                                            <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                                {convertTOCorrectValue(item.pickup.Occ, "Occ", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                                {convertTOCorrectValue(item.pickup.Rooms, "Rooms", currSymbol)}
                                                            </TableCell><TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns '}>
                                                                {convertTOCorrectValue(item.pickup.ADR, "ADR", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                                {convertTOCorrectValue(item.pickup.RevPAR, "RevPAR", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="right" className={'table-columns revenue-txt ' + expViewClass}>
                                                                {convertTOCorrectValue(item.pickup.Revenue, "Revenue", currSymbol)}
                                                            </TableCell>
                                                            <TableCell
                                                                // data-exclude={colExclude} 
                                                                data-fill-color={rowsBackColor}
                                                                data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns rightBorder'}>
                                                                {convertTOCorrectValue(item.pickup.SellRate, "SellRate", currSymbol)}
                                                            </TableCell>


                                                            <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                                {convertTOCorrectValue(item[dayDateSdly].Occ, "Occ", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                                {convertTOCorrectValue(item[dayDateSdly].Rooms, "Rooms", currSymbol)}
                                                            </TableCell><TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                                {convertTOCorrectValue(item[dayDateSdly].ADR, "ADR", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className={'table-columns ' + expViewClass}>
                                                                {convertTOCorrectValue(item[dayDateSdly].RevPAR, "RevPAR", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-exclude={colExclude} data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="right" className={'table-columns revenue-txt ' + expViewClass}>
                                                                {convertTOCorrectValue(item[dayDateSdly].Revenue, "Revenue", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns rightBorder'>
                                                                {convertTOCorrectValue(item[dayDateSdly].SellRate, "SellRate", currSymbol)}
                                                            </TableCell>



                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                                {convertTOCorrectValue(item.variance.Occ, "Occ", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                                {convertTOCorrectValue(item.variance.Rooms, "Rooms", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                                {convertTOCorrectValue(item.variance.ADR, "ADR", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns'>
                                                                {convertTOCorrectValue(item.variance.RevPAR, "RevPAR", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="right" className='table-columns revenue-txt'>
                                                                {convertTOCorrectValue(item.variance.Revenue, "Revenue", currSymbol)}
                                                            </TableCell>
                                                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="dashed" data-b-a-c="ffffff" data-t="s" data-f-sz="13" data-a-h="center" className='table-columns rightBorder'>
                                                                {convertTOCorrectValue(item.variance.SellRate, "SellRate", currSymbol)}
                                                            </TableCell>




                                                        </>

                                                    </StyledTableRow>

                                                }
                                                )
                                            }
                                        </TableBody>


                                    </Table>

                                </TableContainer>
                            </Paper>
                        </Grid>
                    </>
                ) : (<>
                    <Grid className='daily-flash-waiting' sx={{ minHeight: "348px" }}>
                        <TableLottieLoader />
                        {tableSkeletonLoader}
                    </Grid>
                </>)}
            </Grid>
        </div>
    )
}

export default DataTable
