import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/material';
import { Button, Divider, FormControlLabel, FormGroup, ListItem, Switch, TextField } from '@mui/material';
import { deepCopy, getMarketSegment } from '../../app/util';
import { useSelector } from 'react-redux';
import SearchFilter from './SearchFilter';

export default function FilterItemsDowSegment(props) {
    const { currentColumn, filteredColumns } = props;
    const [subcolumns, setSubcolumns] = React.useState([]);
    const [open, setOpen] = React.useState(true);
    const [colOpen, setColOpen] = React.useState(false);
    const [filter, setFilter] = React.useState({ gte: "", lte: "" });
    const compRef = React.useRef({
        filter: {
            lte: '',
            gte: ''
        }
    });

    if (filteredColumns && filteredColumns.length) {
        filteredColumns.map(column => {
            if (column.value !== currentColumn.value) {
                compRef.current.filterExists = true;
            }
        });
    } else {
        compRef.current.filterExists = false;
    }
    const handleClick = () => {
        setOpen(!open);
    };
    const handleColClick = () => {
        setColOpen(!colOpen);

    };

    const clearFilterHandler = (ev, column) => {
        // debugger;
        column.filter = { lte: "", gte: "" };
        setFilter({ lte: "", gte: "" });
        props.clearFilterHandler(column);
    }
    const clearAllFilterHandler = (ev, column) => {
        // debugger;
        column.filter = { lte: "", gte: "" };
        setFilter({ lte: "", gte: "" });
        props.clearFilterHandler(column, "all");
    }

    const applyFilterHandler = (ev, column) => {
        let newFilter = deepCopy(filter);
        // clearAllFilterHandler(null, column);
        column.filter = newFilter;
        setFilter(newFilter);
        props.applyFilterHandler(column);
        // debugger;
    }



    React.useEffect(() => {
        let isSubscribed = true;
        let mainCols = props.columnList;
        // debugger; 
        let subcols = [];
        mainCols.map(main => {
            // debugger
            main.subcolumns.map(subcol => {
                let isExisting = subcols.filter((col) => col.value === subcol.value);
                if (!isExisting.length) {
                    subcols.push(subcol);

                }
            });
        });
        setSubcolumns(subcols);
        if (currentColumn.filter) {
            setFilter({ ...filter, gte: currentColumn.filter.gte, lte: currentColumn.filter.lte });
        };

        return () => (isSubscribed = false);
    }, []);


    return (
        <List
            className='sonu'
            sx={{ width: '100%', maxWidth: 600, height:"400", bgcolor: 'background.paper', pb:0, borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}
            component="nav"
            dense={true}
            aria-labelledby="nested-list-subheader">



            {currentColumn.filterable && <>
                {/* <ListItemButton onClick={handleClick}>
                    <ListItemIcon sx={{ minWidth: "35px !important" }}>
                        <FilterAltIcon />
                    </ListItemIcon>

                    <ListItemText primary={(<><strong>{currentColumn.display.toUpperCase()}</strong>  Filter</>)} />

                </ListItemButton> */}

                <SearchFilter details={props} label={currentColumn.display} />
                {/* <Divider /> */}
                    {/* <Box sx={{ textAlign: 'right', mt: 1, p: 0.5, pr: 2 }}>
                         <Button variant="outlined" size="small" onClick={(ev) => applyFilterHandler(ev, currentColumn)}>Apply</Button>
                    </Box> */}
                {/* {open && <Box timeout="auto" unmountOnExit>
                    <DaysSegmentToggle columnToggleHandler={props.columnToggleHandler} segments={props.segments} type={props.type} />
                </Box>} */}

            </>}
        </List>
    );
}

const DaysSegmentToggle = (props) => {
    const state = useSelector(appState => appState);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let cols = [];
    if (props.type === "segment") {
        let segmentArr = [];
        if(ui_state.active_tiles[0] !== "market") {
            segmentArr =  props.segments.list 
            segmentArr = segmentArr.map(v => ({ ...v, hidden: false, display: v.name }))
         }else{
            segmentArr = getMarketSegment(ui_state.active_tiles);
         }

        cols = segmentArr.filter((obj, index) => {
            return index === segmentArr.findIndex(o => obj.name === o.name);
        });

    } else {
        cols = [
            { value: "sun", hidden: false, display: "Sun" },
            { value: "mon", hidden: false, display: "Mon" },
            { value: "tue", hidden: false, display: "Tue" },
            { value: "wed", hidden: false, display: "Wed" },
            { value: "thu", hidden: false, display: "Thu" },
            { value: "fri", hidden: false, display: "Fri" },
            { value: "sat", hidden: false, display: "Sat" }
        ]
    }

    let [columnToggle, setColumnToggle] = React.useState(cols);
    React.useEffect(() => {

    }, [columnToggle])


    const selectAll = () => {
        columnToggle.map((item, index) => {
            columnToggle[index].hidden = false
            // return [...columnToggle];
        });
        console.log(columnToggle);
        // setColumnToggle(columnToggle);
    }


    let switches = [];
    const handleChange = (ev, item, index) => {
        let target = ev.target;
        setColumnToggle(() => {
            columnToggle[index].hidden = !target.checked
            return [...columnToggle];
        });

        props.columnToggleHandler(item, index);
    }

    columnToggle.map((item, i) => {
        switches.push(<ListItem>
            <FormControlLabel key={item.value + i} sx={{ mb: -1 }} value={item.value}
                control={<Switch onChange={(ev) => { handleChange(ev, item, i) }} color="primary" checked={!item.hidden} name={item.display} />}
                label={item.display} labelPlacement="start" />

        </ListItem>)
    })
    return (
        <>
            <FormGroup>{switches}</FormGroup>
            <Divider />
            <Box sx={{ textAlign: 'right', mt: 1, p: 0.5, pr: 2 }}>
                <Button variant="outlined" sx={{ mr: 1 }} size="small" onClick={() => { selectAll() }} >Select All</Button>
            </Box>
        </>
    );
}