import React from "react";

function UsersThree({ fill = '#EBEBEB', height = "32", width = "32" }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill={fill}
            viewBox="0 0 256 256"
        >
            <path d="M244.8 150.4a8 8 0 01-11.2-1.6A51.6 51.6 0 00192 128a8 8 0 01-7.37-4.89 8 8 0 010-6.22A8 8 0 01192 112a24 24 0 10-23.24-30 8 8 0 11-15.5-4A40 40 0 11219 117.51a67.94 67.94 0 0127.43 21.68 8 8 0 01-1.63 11.21zM190.92 212a8 8 0 11-13.84 8 57 57 0 00-98.16 0 8 8 0 11-13.84-8 72.06 72.06 0 0133.74-29.92 48 48 0 1158.36 0A72.06 72.06 0 01190.92 212zM128 176a32 32 0 10-32-32 32 32 0 0032 32zm-56-56a8 8 0 00-8-8 24 24 0 1123.24-30 8 8 0 1015.5-4A40 40 0 1037 117.51a67.94 67.94 0 00-27.4 21.68 8 8 0 1012.8 9.61A51.6 51.6 0 0164 128a8 8 0 008-8z"></path>
        </svg>
    );
}

export default UsersThree;
