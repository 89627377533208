import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Link,
    Typography,
    LinearProgress, Alert, AlertTitle, Snackbar, Box,
    Container, Grid, Button, Modal, FormControl, NativeSelect, Select, MenuItem,
    Tooltip, styled, tooltipClasses,
    Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions
} from '@mui/material';
import { GridViewOutlined as GridViewOutlinedIcon, StayCurrentLandscape } from '@mui/icons-material/';
import theme from '../../theme';
import moment from "moment";
import Icons from "../Icons/icons.svg";
import { determineMeasureStates } from "../../components/Measure/measure-logic";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { requestForToken, onMessageListener } from "../../notifications/firebase";
import { API_REGISTRY, APP_CACHE, TILES } from '../../app/config'
import { getDataSources, deepCopy } from '../../app/util';
import { setApiURLVars, updateAppState } from '../../actions/appstate';
import dataService from '../../services/data.service';
import { sendLog } from "../../app/log-interface";

import RequestSupport from '../RequestSupport/'

import { useDataSource } from '../../hooks/data-sources';
import { useProductVariant } from "../../hooks/use-product-variant"

import Lottie from 'react-lottie';
import TableLoadingLottie from "../../lotties/table-loading.json";
import msg from "../../components/Workspace/messages";
import { useVisbilityState } from "../../hooks/helper-hooks";
import { compressWorker } from '@linways/table-to-excel';
import { NEW_PROPERTY_SYSTEM_SETTINGS } from '../../app/config';

const TableLottieLoader = () => {
    const [loading, setLoading] = useState(false);
    function randomMessage() {
        const messages = msg;
        const randomIndex = Math.floor(Math.random() * messages.length);
        const randomMessage = messages[randomIndex];
        return randomMessage;
    }
    const defaultOptionsTable = {
        loop: true,
        autoplay: true,
        animationData: TableLoadingLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(true);
        }, 2000); // Adding delay in milliseconds 

        // Clear the timeout if the component is unmounted before the delay is completed
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <Box className="lottie-main-container">
                <Box className="table-lottie-container">
                    <Lottie
                        options={defaultOptionsTable}
                        height={40}
                        width={40}
                    />
                    <div>
                        {randomMessage()}
                    </div>
                </Box>
            </Box>
        );
    }
    return null;
};

function FBNotifications(props) {
    // debugger;
    const [msgCount, setMsgCount] = useState(1);
    useEffect(() => {
        let isSubscribed = true;

        onMessageListener().then(payload => {
            // requestForToken();
            // setShow(true);
            // debugger;
            console.log('onMessageListener:', payload);
            let { notification } = payload;
            if (notification && notification.title) {

                toast(<><strong>{notification.title}</strong><br />{notification.body}</>, {
                    position: "bottom-right",
                    // autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                    autoClose: 3000,
                    type: toast.TYPE.SUCCESS,
                });
                setMsgCount(msgCount + 1);
            }
            // setNotification({title: payload.notification.title, body: payload.notification.body})
            // console.log(payload);
        }).catch(err => console.log('onMessageListener: ', err));

        return () => (isSubscribed = false);
    }, [msgCount]);

    return (<></>)
}

function Copyright(props) {

    return (
        <Typography variant="body2" color="text.secondary" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://app.lodgiq.com/">
                LodgIQ
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function DashboardLogo(props) {
    return (
        <Link href='/' target='_blank' sx={{ lineHeight: "14px" }}>
            <img
                src={`assets/img/Lodgiq-Logo.svg`}
                alt={`Dashboard`}
                height="20"
                loading="lazy"
                className='app-logo'
            />
        </Link>
    );
}
function DashboardLogoSmall(props) {
    return (
        <Link href='' target='_blank' sx={{ lineHeight: "14px" }}>
            <img
                src={`assets/img/dashboard-logo-small.png`}
                alt={`Dashboard`}
                height="30"
                loading="lazy"
            />
        </Link>
    );
}

function CustomSVGIcon(props) {
    const { icon, width, height, path } = props;
    return (
        <span className={'icon icon-custom ' + props.className}>
            <img src={path} width={width} height={height} />
            {/* <svg className="icon-svg" fill="white" viewBox="0 0 410 450" width={width} height={height}>
    <use href={`${Icons}#${icon}`} height="100%" width="100%" />
  </svg> */}
        </span>
    );
}

function DashboardFooter(props) {
    const dataSource = useDataSource();
    const { productVariant } = useProductVariant()
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { auth, app } = state;
    const { profile } = auth;
    const { property_details } = profile;
    const { app_state, ui_state } = app.current_state;
    const [openRequestSupport, setOpenRequestSupport] = useState(false);
    // const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    const [dsInfo, setDsInfo] = useState(dataSource);
    const color = theme.palette;
    const [confirmOptions, setConfirmOptions] = useState({
        title: 'Are you sure to switch data source to {pms}?',
        descriptions: <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            This may lead to reset data of other users of same property!
        </Alert>,
        labelOk: "Change",
        labelCancel: "Cancel",
        open: false,
    });
    // debugger;

    useEffect(() => {
        // debugger;
        let isSubscribed = true;
        //if (!dsInfo.data_sources.length) {
        //dsInfo.data_sources.push(dsInfo.active_source);
        //}

        setDsInfo((state) => {
            let ds = getDataSources(property_details, app_state.property_id);
            return { ...state, ...ds };
        });

        return () => (isSubscribed = false);
    }, [app_state.property_id, app_state.active_data_src.id]);

    function openRequestSupportModal() {
        setOpenRequestSupport(true);
    }
    function handleRequestSupportClose() {
        setOpenRequestSupport(false);
    }
    function getIndexOfActiveSource() {
        let index;
        if (dsInfo && dsInfo.data_sources && dsInfo.data_sources.length) {
            dsInfo.data_sources.filter((item, i) => {
                if (item.id == dsInfo.active_source.id) index = i;
            });
            return index;
        } else {
            return 0;
        }
    }
    const handleDSChange = (selectedIndex) => {
        // debugger;
        let registery = API_REGISTRY['SwitchDataSource'];
        let currentDS = dsInfo.active_source.id;
        let toDSIndex = selectedIndex;
        let toDS = dsInfo.data_sources[toDSIndex];
        let newUrl = setApiURLVars(registery.uri, { pid: app_state.property_id, fromDS: currentDS, toDS: toDS.id });

        let notification = { title: 'Switching Data Source', body: 'Please Wait...' };
        // let dataSourceToast = toast(<><strong>{notification.title}</strong><br />{notification.body}</>, {
        //     position: "bottom-right",
        //     // autoClose: false,
        //     hideProgressBar: false,
        //     closeOnClick: false,
        //     pauseOnHover: false,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //     transition: Slide,
        //     autoClose: false,
        //     type: toast.TYPE.SUCCESS,
        // });
        // setMsgCount(msgCount + 1);
        dataService.getData({
            api: newUrl,
            request: { method: 'GET' }
        })
            .then((rs) => {
                // debugger;
                let system_settings = deepCopy(rs.system_settings);
                const { property_id } = app_state;

                setConfirmOptions((state) => {
                    return { ...state, showLoader: false, showSuccess: true };
                });
                profile.property_details[dsInfo.index].active_source = deepCopy(toDS);
                if (system_settings && !system_settings.inventory_settings) {
                    system_settings = { ...NEW_PROPERTY_SYSTEM_SETTINGS, ...system_settings };
                }
                profile.property_details[dsInfo.index].system_settings[0] = { ...system_settings };
                profile.property_details[dsInfo.index].permissions = rs.permissions;
                window.analyze.track("ds_changed", {
                    "previous_data_source": app_state.active_data_src,
                    "selected_data_source": dsInfo.active_source.id,
                    // ...event_metadata
                }, { ...app.current_state }, { app_state: { property: toDS }, ui_state: {} });
                sendLog({ category: "dashboard_click", action: "change_data_source" }, { ...app.current_state }, { app_state: { property: toDS }, ui_state: {} });

                ui_state.active_tiles = [productVariant.default_tile];
                APP_CACHE.active_tiles = [productVariant.default_tile];
                ui_state.table_filters.table_common.segments = [{
                    "code": "all",
                    "name": "all"
                }];

                dispatch({
                    type: "UPDATE_STATE", payload: {
                        app_state: {
                            workspace_controls: { ...app_state.workspace_controls, segmented_view: false },
                            data_load: false,
                            kpi_load: false,
                        },
                        ui_state: {
                            showSpinner: false,
                        }
                    }
                });

                dispatch((dispatch) => {
                    dispatch({ type: "SYSTEM_SETTINGS_UPDATED", payload: profile });
                });

                setTimeout(() => {
                    setConfirmOptions((state) => {
                        return { ...state, open: false, showLoader: false, showSuccess: false };
                    });
                    window.location.reload();
                }, 800);

                // notification.title = "Data Source Updated";
                // notification.body = "New Data Source is " + toDS.name + " (" + toDS.id + "), Reloading Screen...";
                // toast.update(dataSourceToast, {
                //     render: <><strong>{notification.title}</strong><br />{notification.body}</>,
                //     type: toast.TYPE.SUCCESS,
                //     autoClose: 3000
                // })
            }, (err) => {
                console.error("Data Source change Error!", err);
            })
    };
    function handleUserAction(action) {

        if (!action) {
            setConfirmOptions((state) => {
                return { ...state, open: false };
            })
            // handleDSChange(conFirmDialog.event, conFirmDialog.option);
        }
        if (action === 'agree') {
            // debugger;
            let property_info = {};
            const prop_details = property_details.filter((item) => {
                if (Number(item.id) === profile.default_property_id) {
                    property_info = { ...item };
                }
            });
            const measuresState = determineMeasureStates({
                active_tiles: property_info.product_variant.default_tile,
                userSelected_left_measure: TILES[property_info.product_variant.default_tile].chartConfig.defaultMeasures[0],
                userSelected_right_measure: TILES[property_info.product_variant.default_tile].chartConfig.defaultMeasures[1],
            });
            app_state.workspace_controls.sdlyOn = false;
            app_state.workspace_controls.sdYear = moment(app_state.as_of_date).subtract(1, 'years').format('YYYY');
            app_state.workspace_controls.pickUpDays = 1;
            app_state.workspace_controls.pickUpType = "Standard";
            ui_state.active_tiles = [property_info.product_variant.default_tile];
            ui_state.left_measure = measuresState.selectedLeftMeasure.value ? measuresState.selectedLeftMeasure.value : null;
            ui_state.right_measure = measuresState.selectedRightMeasure.value ? measuresState.selectedRightMeasure.value : null;
            ui_state.right_measures_list = measuresState.rightList;
            ui_state.left_measures_list = measuresState.leftList;
            setConfirmOptions((state) => {
                return { ...state, showLoader: true };
            });
            handleDSChange(confirmOptions.selectedIndex);
            // handleDSChange(conFirmDialog.event, conFirmDialog.option);
        }
        if (action === 'disagree') {
            setConfirmOptions((state) => {
                return { ...state, open: false };
            })
            // handleDSChange(conFirmDialog.event, conFirmDialog.option);
        }
    }
    function openConfirmDialog(event, item, selectedIndex) {
        let isSelected = Number(item.id) === dsInfo.active_source.id ? true : false;
        if (!isSelected) {
            setConfirmOptions((state) => {
                dispatch(updateAppState('dataSrcChanged', {
                    app_state: {
                        ...app_state,
                        active_data_src: { ...app_state.active_data_src, ...item },
                        roomTypeRestrictions: [],
                        savedRestrictions: [],
                        alertShowable: true
                    },
                    ui_state: { ...ui_state }
                }));
                return { ...state, pms: item.pms, open: true, selectedIndex: selectedIndex, event: event, item: item };
            })
        }
    }

    let dataSourcesOptions = () => {
        if (dsInfo.data_sources) {
            return dsInfo.data_sources.map((item, i) => {
                return <MenuItem value={i} onClick={(event) => openConfirmDialog(event, item, i)}>{item.pms}</MenuItem>
            });
        }
    }
    return (
        <Box sx={{ position: 'fixed', bottom: 0, zIndex: 999, background: color.grey.Gray2, color: color.white.main, width: '100%' }}>
            <Container maxWidth="xxl" className="container-control">
                <Grid container justifyContent="space-between" spacing={0.2} sx={{ lineHeight: "25px", mt: 0.4, mb: 0.4 }}>
                    <Grid item sm={6}>
                        {dsInfo.data_sources &&
                            <Select
                                defaultValue={getIndexOfActiveSource()}
                                value={getIndexOfActiveSource()}
                                // onChange={handleDSChange}
                                size="small"
                                width="100"
                                className='footer-ds-selector'
                                renderValue={(value) => {
                                    return (<>
                                        <LightTooltip title="DATA SOURCE" arrow followCursor>
                                            <span><DataSourceIcon /></span>
                                        </LightTooltip>
                                        {dsInfo.data_sources[getIndexOfActiveSource()].pms}</>)
                                }}
                            >
                                {dataSourcesOptions()}
                            </Select>
                        }

                        {app_state.data_as_of && <span className='footer-asof-ts'>
                            <LightTooltip title="AS OF DATE" arrow followCursor>
                                <span><AsOfIcon /></span>
                            </LightTooltip>
                            <span className='footerText' style={{ color: color.white.main2, marginLeft: 25 }}> {moment(app_state.data_as_of).format('MMMM DD, YYYY')}</span>
                        </span>}

                    </Grid>

                    <Grid item sm={6} sx={{ textAlign: 'right' }}>
                        <LightTooltip title="REQUEST SUPPORT" arrow followCursor>
                            <Button className='circle-border-icon' sx={{ p: 0, minWidth: 'auto', display: 'inline', mr: 1, pt: 0.2 }} onClick={openRequestSupportModal}>
                                <i class="ph ph-phone-call"></i>
                            </Button>
                        </LightTooltip>
                        <Modal
                            open={openRequestSupport}
                            onClose={handleRequestSupportClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <RequestSupport handleClose={handleRequestSupportClose} />

                        </Modal>


                        <Box className='footerText' sx={{ float: 'right', pt: 0.3 }}>
                            <span style={{ color: color.grey.Gray3 }} align="right" {...props}>
                                {'© '} {moment().format('YYYY')} LodgIQ. All rights reserved.
                            </span>
                        </Box>

                    </Grid>
                </Grid>
            </Container>

            <ToastContainer />
            <ConfirmDialog {...confirmOptions} handleUserAction={handleUserAction} />
        </Box>

    );
}

function ResponsiveTilesMenu(props) {
    const { onClick } = props;
    const color = theme.palette;
    return (
        <Button onClick={onClick} sx={{ minWidth: 'auto' }}>
            <GridViewOutlinedIcon fontSize='large' color='white' />
        </Button>
    );
}

function AsOfIcon() {
    return (
        <span style={{ position: 'relative' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" style={{ width: 18, marginRight: 5, position: 'absolute', top: -2 }} viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-96-88v64a8,8,0,0,1-16,0V132.94l-4.42,2.22a8,8,0,0,1-7.16-14.32l16-8A8,8,0,0,1,112,120Zm59.16,30.45L152,176h16a8,8,0,0,1,0,16H136a8,8,0,0,1-6.4-12.8l28.78-38.37A8,8,0,1,0,145.07,132a8,8,0,1,1-13.85-8A24,24,0,0,1,176,136,23.76,23.76,0,0,1,171.16,150.45Z"></path></svg>
        </span>
    );
}
function DataSourceIcon() {
    return (
        <svg id="Layer_1" fill='#fff' style={{ width: 14, marginRight: 5 }} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 116.67"><title></title><path className="cls-1" d="M16.94,25.56c7.76,2.6,18.58,4.2,30.59,4.2s22.82-1.6,30.58-4.2c6.82-2.28,11-5,11-7.72s-4.22-5.45-11-7.73c-7.76-2.58-18.57-4.2-30.57-4.2s-22.83,1.62-30.6,4.2c-14.45,4.83-14.85,10.49,0,15.45ZM85.37,85.72h11.3V81.44a1.78,1.78,0,0,1,.71-1.52c1.2-.8,2.38.3,3.21,1,2.36,2.13,6.62,7,7.86,8a1.74,1.74,0,0,1,0,2.74c-1.27,1.1-5.79,6.22-8.08,8.26-.8.7-1.88,1.58-3,.84a1.78,1.78,0,0,1-.71-1.52V95.06H85.37V85.72ZM106,50.33a1.49,1.49,0,0,0-1-.37.86.86,0,0,0-.22,0H72.89a3.12,3.12,0,0,0-2.22.93,3.09,3.09,0,0,0-.93,2.22v60.38a3.18,3.18,0,0,0,3.15,3.16h46.79a3.18,3.18,0,0,0,3.15-3.16V68.34a1.34,1.34,0,0,0,0-.35,1.51,1.51,0,0,0-.48-1.07L106.12,50.43a.53.53,0,0,0-.12-.1Zm-4.43,4.36V65.46a5.08,5.08,0,0,0,1.25,3.36c.77.77,3.58,2.57,4.75,2.57h10.64v40.25c0,.07-.11.17-.18.22s0,.1-.15.1H74.75a.26.26,0,0,1-.23-.1.37.37,0,0,1-.1-.22V55a.25.25,0,0,1,.1-.22.33.33,0,0,1,.23-.1Zm4.88,10.45V55.39l10.47,11.2h-9a1.46,1.46,0,0,1-1.45-1.45ZM6,47c.53,2.52,4.63,5,11,7.16,7.76,2.6,18.58,4.2,30.59,4.2,4.18,0,8.23-.2,12.05-.56v6c-3.84.35-7.88.54-12,.54-12.62,0-24.09-1.72-32.45-4.52A37,37,0,0,1,6,55.5V73.22c.53,2.52,4.63,5,11,7.17,7.76,2.58,18.58,4.19,30.59,4.19,4.17,0,8.22-.19,12-.55V90c-3.85.35-7.89.54-12.05.54-12.61,0-24.09-1.73-32.45-4.53A37.33,37.33,0,0,1,6,81.67V99.4c.53,2.51,4.63,5,11,7.16,7.77,2.59,18.58,4.2,30.59,4.2,4.19,0,8.23-.2,12-.57v4.6a8.41,8.41,0,0,0,.07,1.33c-3.87.36-7.93.55-12.11.55-12.61,0-24.09-1.73-32.45-4.53C10.68,110.67,0,105.83,0,100.05V98.16C0,71.77,0,44.23,0,17.88,0,12.31,5.76,7.63,15.06,4.52,23.44,1.72,34.93,0,47.54,0S71.61,1.72,80,4.53s14,7,14.94,11.85a2.91,2.91,0,0,1,.17,1V39.78H89.17v-13A36.86,36.86,0,0,1,80,31.14C71.63,34,60.16,35.67,47.55,35.67S23.45,34,15.06,31.14A36.93,36.93,0,0,1,6,26.86V47Z" /></svg>
    );
}

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.white,
    },
}));

function ConfirmDialog(props) {
    const { title, descriptions, labelOk, labelCancel, open, showLoader, showSuccess, handleUserAction, pms } = props;
    let newTitle = title;
    newTitle = newTitle.replace('{pms}', pms);
    return (
        <Dialog
            open={open}
            // onClose={() => handleUserAction()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {showLoader && <>
                <LinearProgress color="green" />
                <Alert severity='info'>
                    Please wait...<br />
                    We are switching data source to {pms}
                </Alert>
            </>}
            {showSuccess && <>
                <LinearProgress color="green" />
                <Alert severity='success'>
                    Data source is switched to {pms}<br />
                    We are reloading data...
                </Alert>
            </>}
            {!showLoader && !showSuccess && <>
                <DialogTitle id="alert-dialog-title">
                    {!title && "Are you sure about this action?"}
                    {title && newTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {!descriptions && "Do this only if you prety sure about impacts of this action."}
                        {descriptions && descriptions}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pr: 3 }}>
                    <Button variant='outlined' color='info' onClick={() => handleUserAction('disagree')}>
                        {labelCancel && labelCancel}
                        {!labelCancel && "Cancel"}
                    </Button>
                    <Button variant='outlined' color='warning' onClick={() => handleUserAction('agree')} autoFocus>
                        {labelOk && labelOk}
                        {!labelOk && "Agree"}
                    </Button>
                </DialogActions>
            </>}
        </Dialog>
    )
}

//This is for confirmation of user logout in case of inactivity for long time
function ConfirmLogoutDialog(props) {
    let { title, descriptions, labelOk, labelCancel, open, showLoader, showSuccess, handleUserAction, pms, graceTime = 1, timeout = 2, openConfirmDialog } = props;
    let newTitle = title;
    const visibility = useVisbilityState(document.visibilityState);
    let [graceTimeCounter, setGraceTimeCounter] = useState(graceTime * 60 * 1000);
    let [graceTimeDisplay, setGraceTimeDisplay] = useState(graceTime.toFixed(2));
    const compRefs = useRef({
        graceTimeCounter: graceTimeCounter, logoutTimer: null,
        hiddenTime: 0, visibleTime: 0, ts: 0,
        autologoutWorker: null, //new Worker('/workers/app.autologout.js')
        startCounterCalled: 0,
        documentHidden: false,
        open: open
    });

    // let autologoutWorker = new Worker('/workers/app.autologout.js');
    const onWorkerMessage = (event) => {
        const { status, ts } = JSON.parse(event.data);
        if (status === 'complete' && ts === compRefs.current.ts && compRefs.current.documentHidden) {
            handleUserAction('logout');
            // autologoutWorker.terminate();
        }
    };

    const startCounter = () => {
        clearInterval(compRefs.current.intervalId);
        // clearTimeout(compRefs.current.logoutTimer);
        // compRefs.current.autologoutWorker.terminate();
        const documentHidden = compRefs.current.documentHidden;
        let timeLapsed = 0;
        let { visibleTime, hiddenTime, startCounterCalled, open, graceTimeCounter } = compRefs.current;
        if (open) {
            compRefs.current.startCounterCalled = 1;
            startCounterCalled = 1;
        }
        if (documentHidden) {
            // debugger;
            compRefs.current.autologoutWorker.removeEventListener('message', onWorkerMessage);
            // compRefs.current.hiddenTime = moment().format('x');
            compRefs.current.ts = moment().format('x');
            if (open) {
                compRefs.current.autologoutWorker.postMessage({ type: 'autologout', ts: compRefs.current.ts, timeout: graceTimeCounter });
            } else {
                compRefs.current.autologoutWorker.postMessage({ type: 'autologout', ts: compRefs.current.ts, timeout: timeout + graceTimeCounter });
            }
            compRefs.current.autologoutWorker.addEventListener('message', onWorkerMessage);

            // let time = String((graceTimeCounter / 60000).toFixed(2)).split('.');
            // let min = time[0].toString().padStart(2, "0");
            // let sec = Math.round((time[1] * 60) / 100).toString().padStart(2, "0");
            // graceTimeDisplay = min + ':' + sec;
            // setGraceTimeDisplay(graceTimeDisplay);
            // compRefs.current.logoutTimer = 
            // setTimeout(() => {
            //     debugger;
            //     handleUserAction('logout');
            // }, graceTimeCounter);
        }

        if (!documentHidden) {
            compRefs.current.ts = moment().format('x');
            if (open && startCounterCalled && visibleTime && hiddenTime && visibleTime > hiddenTime) {
                timeLapsed = visibleTime - hiddenTime;
            }

            //debugger;
            if (open && timeLapsed >= compRefs.current.graceTimeCounter) {
                handleUserAction('logout');
            }
            if (!open && timeLapsed >= (compRefs.current.graceTimeCounter + timeout)) {
                handleUserAction('logout');
            }
            if (open && timeLapsed < compRefs.current.graceTimeCounter) {
                console.log("open: " + open, "timeLapsed: " + timeLapsed, "startCounterCalled: " + startCounterCalled, "graceTimeCounter: " + compRefs.current.graceTimeCounter, "visibleTime: " + visibleTime, "hiddenTime: " + compRefs.current.hiddenTime);

                //let timeLapsed = compRefs.current.visibleTime - compRefs.current.hiddenTime;
                console.log("graceTimeCounter: " + graceTimeCounter, timeLapsed);
                graceTimeCounter = compRefs.current.graceTimeCounter - timeLapsed;
                compRefs.current.graceTimeCounter = graceTimeCounter;
                setGraceTimeCounter(graceTimeCounter);
                let time = String((graceTimeCounter / 60000).toFixed(2)).split('.');
                let min = time[0].toString().padStart(2, "0");
                let sec = Math.round((time[1] * 60) / 100).toString().padStart(2, "0");
                graceTimeDisplay = min + ':' + sec;
                setGraceTimeDisplay(graceTimeDisplay);

                compRefs.current.intervalId = setInterval(() => {
                    // debugger;
                    graceTimeCounter = graceTimeCounter - 1000;
                    compRefs.current.graceTimeCounter = graceTimeCounter;
                    // if (graceTimeCounter <= 0) {
                    //     clearInterval(compRefs.current.intervalId);
                    // }
                    if (graceTimeCounter > -1) {
                        setGraceTimeCounter(graceTimeCounter);
                        let time = String((graceTimeCounter / 60000).toFixed(2)).split('.');
                        let min = time[0].toString().padStart(2, "0");
                        let sec = Math.round((time[1] * 60) / 100).toString().padStart(2, "0");
                        graceTimeDisplay = min + ':' + sec;
                        setGraceTimeDisplay(graceTimeDisplay);
                    }
                    if (graceTimeCounter <= 0) {
                        // debugger;
                        //Do logout action
                        clearInterval(compRefs.current.intervalId);
                        handleUserAction('logout');
                    }

                }, 1000);
                // compRefs.current.startCounterCalled += 1;
            }
            // autologoutWorker.postMessage({ type: 'autologout', timeout: graceTimeCounter });

        }

    }
    const omMouseEnter = () => {
        if(!document.hasFocus()){
            window.focus();
        }
    }
    const OnFocus = () => {
        // debugger;
        if(!document.hasFocus()){
            window.focus();
        }
        compRefs.current.documentHidden = false;
        compRefs.current.visibleTime = Number(moment().format('x'));
        compRefs.current.hiddenTime = Number(sessionStorage.getItem('documentHiddenTime'));
        sessionStorage.removeItem('documentHiddenTime');
        if (!compRefs.current.open) {
            graceTimeCounter = graceTime * 60000;
            setGraceTimeCounter(graceTimeCounter);
            compRefs.current.graceTimeCounter = graceTimeCounter;
            compRefs.current.startCounterCalled = 0;
        }
        if (compRefs.current.open) {
            compRefs.current.startCounterCalled = 1;
        }

        startCounter();

        console.log('document focused', timeout, graceTimeCounter, true);
    };
    const Onblur = () => {
        console.log('document blured');
        compRefs.current.documentHidden = true;
        clearInterval(compRefs.current.intervalId);
        console.log('graceTimeCounter', graceTimeCounter);

        if (compRefs.current.open) {
            compRefs.current.hiddenTime = Number(moment().format('x'));
            sessionStorage.setItem('documentHiddenTime', compRefs.current.hiddenTime);
            // compRefs.current.startCounterCalled = 1;
            // startCounter();
        }
        if (!compRefs.current.open) {
            // compRefs.current.hiddenTime = Number(moment().format('x'));
            // sessionStorage.setItem('documentHiddenTime', compRefs.current.hiddenTime);
        }
        startCounter();
    };

    const onVisibilityChange = (e) => {

        if (document.hidden) {
            // console.log('document is hidden');
            // compRefs.current.documentHidden = true;
            Onblur();
            // compRefs.current.startCounterCalled = 0;
            // compRefs.current.hiddenTime = Number(moment().format('x'));
            // sessionStorage.setItem('documentHiddenTime', Number(compRefs.current.hiddenTime));
        }
        if (!document.hidden) {
            window.focus();
            console.log('document is visible');
            // compRefs.current.documentHidden = false;
            OnFocus();
            // compRefs.current.visibleTime = Number(moment().format('x'));
            // compRefs.current.hiddenTime = Number(sessionStorage.getItem('documentHiddenTime'));
            // if (compRefs.current.hiddenTime && compRefs.current.visibleTime) {
            //     let timeLapsed = compRefs.current.visibleTime - compRefs.current.hiddenTime;
            //     if ((open && timeLapsed >= graceTimeCounter) || (!open && timeLapsed >= (graceTimeCounter + timeout))) {
            //         handleUserAction('logout');
            //     } else if(open && timeLapsed < graceTimeCounter){
            //         startCounter();
            //     } else if (!open && timeLapsed > timeout && timeLapsed < (graceTimeCounter + timeout)) {
            //         openConfirmDialog();
            //         startCounter();
            //     }
            // }
        }
    }

    useEffect(() => {
        sessionStorage.removeItem('documentHiddenTime');
        document.addEventListener("visibilitychange", onVisibilityChange);
        document.addEventListener('focus', OnFocus);
        document.addEventListener('blur', Onblur);
        document.addEventListener("mouseenter", omMouseEnter);
        // document.addEventListener("mouseleave", Onblur);

        if (!compRefs.current.autologoutWorker) {
            compRefs.current.autologoutWorker = new Worker('/workers/app.autologout.js');
        }
        startCounter();
        return () => {
            sessionStorage.removeItem('documentHiddenTime');
            document.removeEventListener('focus', OnFocus);
            document.removeEventListener('blur', Onblur);
            document.removeEventListener("visibilitychange", onVisibilityChange);
            compRefs.current.autologoutWorker.removeEventListener('message', onWorkerMessage);
            document.removeEventListener("mouseenter", omMouseEnter);
            // document.removeEventListener("mouseleave", Onblur);
            compRefs.current.autologoutWorker.terminate();
        }
    }, []);


    useEffect(() => {
        // autologoutWorker.terminate();
        const { documentHidden } = compRefs.current;
        console.log('document visibility', visibility, documentHidden);
        compRefs.current.open = open;
        if (open) {
            sessionStorage.setItem('documentHiddenTime', moment().format('x'));
            // compRefs.current.startCounterCalled = 1;
        }
        if (open && !documentHidden) {
            // debugger;
            startCounter();
        }
        if (open && documentHidden) {
            setGraceTimeCounter(graceTime * 60 * 1000);
            let time = String((graceTimeCounter / 60000).toFixed(2)).split('.');
            let min = time[0].toString().padStart(2, "0");
            let sec = Math.round((time[1] * 60) / 100).toString().padStart(2, "0");
            graceTimeDisplay = min + ':' + sec;
            setGraceTimeDisplay(graceTimeDisplay);
        }
        if (!open) {
            clearInterval(compRefs.current.intervalId);

            // autologoutWorker.terminate();
            // compRefs.current.ts = moment().format('x');

            // compRefs.current.autologoutWorker.postMessage({ type: 'autologout', ts: compRefs.current.ts, timeout: timeout + graceTimeCounter });
            // startCounter();
        }

        return () => {
            clearInterval(compRefs.current.intervalId);
        }

    }, [open]);

    return (
        <Dialog
            open={open}
            // onClose={() => handleUserAction()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="700"
        >

            <DialogTitle id="alert-dialog-title">
                {title && newTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {descriptions && <Alert severity="warning">
                        <AlertTitle>Warning</AlertTitle>
                        You will be logged out automatically in <strong>{graceTimeDisplay}</strong> mm:ss
                    </Alert>}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ pr: 3 }}>
                {labelCancel && <Button sx={{ textTransform: 'none' }} variant='outlined' color='info' onClick={() => {
                    // compRefs.current.ts = moment().format('x');
                    compRefs.current.autologoutWorker.terminate();
                    // debugger;
                    sessionStorage.removeItem('documentHiddenTime');
                    clearInterval(compRefs.current.intervalId);
                    compRefs.current.startCounterCalled = 0;
                    compRefs.current.hiddenTime = 0;
                    compRefs.current.visibleTime = 0;
                    graceTimeCounter = graceTime * 60000;
                    setGraceTimeCounter(graceTimeCounter);
                    compRefs.current.graceTimeCounter = graceTimeCounter;
                    handleUserAction('keeploggedin');
                    compRefs.current.autologoutWorker = new Worker('/workers/app.autologout.js')
                }}>
                    {labelCancel}
                </Button>}

                {labelOk && <Button sx={{ textTransform: 'none' }} variant='outlined' color='warning' onClick={() => handleUserAction('logout')} autoFocus>
                    {labelOk && labelOk}
                </Button>}
            </DialogActions>

        </Dialog>
    )
}

export {
    TableLottieLoader,
    LightTooltip, DashboardLogo, DashboardLogoSmall,
    DashboardFooter, ResponsiveTilesMenu, CustomSVGIcon,
    FBNotifications, ConfirmDialog, ConfirmLogoutDialog
};