
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectingAirportsSharpIcon from '@mui/icons-material/ConnectingAirportsSharp';
import FlightIcon from '@mui/icons-material/Flight';
import { Stack, Box, Modal, FormControl, Select, Typography, Container, Grid, Icon } from '@mui/material'
import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';
import { deepCopy, getSeriesById, in_array } from "../../app/util";
import dataService from "../../services/data.service";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RatePlanPopup from './RatePlanPopup';
import NotesPopup from './NotesPopup';
import NotesPopupMultiple from './NotesPopupMultiple';
import EventsPopup from './EventsPopup';
import EventsPopupMultiple from './EventsPopupMultiple';



export const getCodeDetails = (segmentCode, app_state) => {
    let data = (dataService.getSeriesById("GetRateCodeMapping").data) ? deepCopy(dataService.getSeriesById("GetRateCodeMapping").data.data) : [];
    segmentCode = segmentCode.replaceAll("Segment_", "");
    let getCodeDetails = data.filter(item => item.rate_code === segmentCode);
    // console.log(getCodeDetails);
    let segmentName = (getCodeDetails.length > 0) ? getCodeDetails[0].desc : "Not available";
    // debugger;
    // return (app_state.workspace_controls.segmentType === "rate_plan") ? segmentName : segmentCode;
    return segmentName;
}


const DisplayData = (props) => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let segmentType = app_state.workspace_controls.segmentType;
    let sdlyOn = app_state.workspace_controls.sdlyOn;
    let item = props.displayValue;
    let chipArrAll = [];
    let roomTypeRestdata = [];

    let displayItem = item;
    let restrictions = [];
    if (props.dataValue && props.dataKey === "RoomsRestrictions") {
        displayItem = "restrictions";
        props.displayValue.forEach(item => {

            restrictions.push((<Stack mt={2} pr={0.8}
                className='res-rules-display restrictions-delta'
                justifyContent="flex-start" alignItems="center" direction="row" spacing={1}
                sx={{ background: "rgba(235, 235, 235, 0.70)", borderRadius: "50px", width: "max-content" }}>
                <Box p={1.2} sx={{
                    background: "#EBEBEB", borderTopLeftRadius: "50px",
                    borderBottomLeftRadius: "50px", fontFamily: "SFUI Semibold",
                    color: "#333333", marginRight: "6px !important"
                }}>
                    {(item.room_type) ? item.room_type : "House"}
                </Box>
                {item.cta == true && <span className="res-rule res-cta">CTA</span>}
                {item.cta == false && <span className="res-rule removed">CTA</span>}
                {item.ctd === true && <span className="res-rule res-ctd">CTD</span>}
                {item.ctd == false && <span className="res-rule removed">CTD</span>}
                {item.close === true && <span className="res-rule res-cls">CLS</span>}
                {item.close == false && <span className="res-rule removed">CLS</span>}
                {item.min_los > 1 && <span className="res-rule res-min_los">MN {item.min_los}</span>}

                {item.max_los > 1 && <span className="res-rule res-max_los">MX {item.max_los}</span>}

            </Stack>
            ))
        })
        displayItem = restrictions;
    }

    return (
        <>
            <Box sx={!in_array(ui_state.active_tiles, ["otb_v2_multi_segment", "bi_pace_comparison", "bi_pickup_analysis", "DailyRatesendStatus", "rm_room_type_ratesend_status", "restrictions_push_status"]) && props.dataKey === "date" || props.dataKey === "dow" ? { display: "grid", gridTemplateColumns: "auto auto auto 1fr" } : {}}>
                {
                    // Non-Segment single note
                    (!in_array(ui_state.active_tiles, ["otb_v2_multi_segment", "bi_pace_comparison", "bi_pickup_analysis", "DailyRatesendStatus", "rm_room_type_ratesend_status", "restrictions_push_status"]) && !app_state.workspace_controls.segmented_view && props.dataKey === "date" && !in_array(ui_state.active_tiles, ["pace"]))
                        ?
                        sdlyOn
                            ?
                            <NotesPopupMultiple params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item, sdlyOn: sdlyOn }} />
                            :
                            <NotesPopup params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item }} />
                        : null
                }
                {
                    // Non-Segment multiple note
                    (!in_array(ui_state.active_tiles, ["otb_v2_multi_segment", "bi_pace_comparison", "bi_pickup_analysis", "DailyRatesendStatus", "rm_room_type_ratesend_status", "restrictions_push_status"]) && !app_state.workspace_controls.segmented_view && props.dataKey === "date" && in_array(ui_state.active_tiles, ["pace"]))
                        ?
                        app_state.workspace_controls.clearComparision
                            ?
                            <NotesPopupMultiple params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item }} />
                            :
                            <NotesPopup params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item }} />
                        : null
                }

                {
                    // Segment single note
                    (!in_array(ui_state.active_tiles, ["otb_v2_multi_segment", "bi_pace_comparison", "bi_pickup_analysis", "DailyRatesendStatus", "rm_room_type_ratesend_status", "restrictions_push_status"]) && app_state.workspace_controls.segmented_view && props.isTotalRow === 'Total' && props.dataKey === "date" && !in_array(ui_state.active_tiles, ["pace"]))
                        ?
                        sdlyOn
                            ?
                            <NotesPopupMultiple params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item, sdlyOn: sdlyOn }} />
                            :
                            <NotesPopup params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item }} />
                        : <div></div>
                }
                {
                    // Segment multiple note
                    (!in_array(ui_state.active_tiles, ["otb_v2_multi_segment", "bi_pace_comparison", "bi_pickup_analysis", "DailyRatesendStatus", "rm_room_type_ratesend_status", "restrictions_push_status"]) && app_state.workspace_controls.segmented_view && props.isTotalRow === 'Total' && props.dataKey === "date" && in_array(ui_state.active_tiles, ["pace"]))
                        ?
                        app_state.workspace_controls.clearComparision
                            ?
                            <NotesPopupMultiple params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item }} />
                            :
                            <NotesPopup params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item }} />
                        : <div></div>
                }



                {/* Non Segmented View Events */}
                {props.dataKey === "dow" && !app_state.workspace_controls.segmented_view ? (
                    sdlyOn || (ui_state.active_tiles.includes("pace") && app_state.workspace_controls.scopeType === "Date") ? (
                        <EventsPopupMultiple params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item }} />
                    ) : (
                        <EventsPopup params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item }} />
                    )
                ) : null}

                {/* Segmented View Events */}
                {props.dataKey === "dow" && app_state.workspace_controls.segmented_view && props.isTotalRow === 'Total' ? (
                    sdlyOn || (ui_state.active_tiles.includes("pace") && app_state.workspace_controls.scopeType === "Date") ? (
                        <EventsPopupMultiple params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item, isTotalRow: props.isTotalRow }} />
                    ) : (
                        <EventsPopup params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item, isTotalRow: props.isTotalRow }} />
                    )
                ) : null}



                {
                    (segmentType && segmentType === "rate_plan" && props.dataKey === "segment" && ui_state.active_tiles[0] === "otb_v2_multi_segment") ?
                        <Tooltip title={getCodeDetails(item, app_state)} arrow followCursor>
                            <div className="table-content-style">{item}</div>
                        </Tooltip>
                        : <div class="restrictions-container" style={{ justifySelf: "center" }}>
                            {props.dataKey !== "dow" && props.dataKey !== "date" ? displayItem :
                                props.dataKey === "dow" ?
                                    in_array(ui_state.active_tiles, ["bi_pace_comparison", "bi_pickup_analysis"]) ?
                                        <div style={app_state.workspace_controls.segmented_view ? props.isTotalRow === 'Total' ? { marginLeft: "-14px" } : { marginLeft: "25px" } : { marginLeft: "-14px" }}>{displayItem}</div>
                                        :
                                        <div style={app_state.workspace_controls.segmented_view ? props.isTotalRow === 'Total' ? { marginLeft: "-14px" } : { marginLeft: "20px" } : { marginLeft: "-14px" }}>{displayItem}</div>
                                    :
                                    in_array(ui_state.active_tiles, ["bi_pace_comparison", "bi_pickup_analysis"]) ?
                                        <div style={app_state.workspace_controls.segmented_view ? props.isTotalRow === 'Total' ? { marginLeft: "7px" } : { marginLeft: "8px" } : { marginLeft: "-4px" }}>{displayItem}</div>
                                        :
                                        <div style={app_state.workspace_controls.segmented_view ? props.isTotalRow === 'Total' ? { marginLeft: "7px" } : { marginLeft: "18px" } : { marginLeft: "-4px" }}>{displayItem}</div>
                            }
                        </div>
                }

                {
                    (in_array(ui_state.active_tiles, ["otb", "pickup", "pace"])
                        && !ui_state.active_tiles.includes('market')
                        && app_state.workspace_controls.scopeType === "Date"
                        && app_state.workspace_controls.segmented_view && props.dataKey === "segment"
                        && item !== "Total" && app_state.workspace_controls.rate_plan === 1 &&
                        <RatePlanPopup params={{ stay_date: props.stay_date, compdate: props.compdate, segmentName: item }} />
                        // <StarOutlineIcon sx={{ fontSize: 15 }} color="primary" />
                        //  <CurrencyExchangeIcon sx={{ fontSize: 12, position:"absolute", top:0,right:2 }} color="primary" />
                        // <Icon sx={{ fontSize:"10px", position:"absolute", top:0,right:0 }} color="primary" >star</Icon>
                    )
                }
            </Box>
        </>
    )
}

export default DisplayData