const msg = [
    "Loading data. Thank you for your patience.",
    "Hang tight! Data is on its way.",
    "We're working on it. Loading your information.",
    "Sit back and relax. Loading your data now.",
    "Just a moment. Loading data for you.",
    "Loading... Your information is coming soon.",
    "Thanks for waiting. Data loading in progress.",
    "Hold on. We're fetching your data.",
    "Loading, loading, loading... Almost there!",
    "Data on the move. Thanks for waiting!"
];

export default msg;