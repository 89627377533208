import moment from "moment";
import { dateFormat, deepCopy, getList, getSeriesById } from "../../app/util";

export const getPropertyDetails = (active_tile) => {
    let result = getSeriesById("OSR").data;
    switch (active_tile[0]) {
        case 'hotel_summary':
            result = getSeriesById("HotelSummary").data;
            break;
        case 'market_summary':
            result = getSeriesById("MarketSummary").data;
            break;
        default:
            result = getSeriesById("OSR").data;
            break;
    }
    return result;
}

export const primaryHeaderDisplay = ({ display, type }, app_state, ui_state) => {

    const { workspace_controls, comp_as_of_date, comp_stay_range, data_as_of } = app_state;
    const as_of_date = app_state.primary_stay_range.as_of_date;
    const { sdlyOn, pickUpType, isRoomTypePricing, isRestrictionsRoomType, dayDate } = workspace_controls;
    const { active_tiles } = ui_state;
    let dtFormat = "ddd, MMM DD, YYYY";
    let pickupDays = app_state.workspace_controls.pickUpDays;
    let sdlyPickupDays = 364 + parseInt(pickupDays);
    // let propDetails = (['hotel_summary', 'market_summary'].some(elem => active_tiles.includes(elem))) ? getSeriesById("HotelSummary").data : getSeriesById("OSR").data;
    let propDetails = getPropertyDetails(active_tiles);
    let propDetailsMarket = getSeriesById("MarketARISegmented").data;
    let startDate = app_state.primary_stay_range.date_from;
    let endDate = app_state.primary_stay_range.date_until;
    let yearArr = getList(startDate, endDate);
    let compYearArr = getList(app_state.comp_stay_range.date_from, app_state.comp_stay_range.date_until)
    let sdYear = workspace_controls.sdYear;
    let asOfDate = as_of_date;
    let sdlyDateMeta;//= dateFormat(moment(as_of_date).subtract(364, "days"), "ddd, MMM DD, YYYY");
    if (propDetails) {
        // if (!propDetails['meta'].hasOwnProperty("as_of" && !propDetails['meta'].hasOwnProperty("asof")) ){ propDetails['meta']['as_of'] = data_as_of; }
        if (!propDetails['meta'].hasOwnProperty("as_of") && propDetails['meta'].hasOwnProperty("asof")) { propDetails['meta']['as_of'] = propDetails['meta']['asof']; }
        asOfDate = (propDetails && propDetails['meta'] && moment(propDetails['meta']['as_of']).isSame(as_of_date)) ? dateFormat(as_of_date, "ddd, MMM DD, YYYY") : dateFormat(propDetails['meta']['as_of'], "ddd, MMM DD, YYYY");
        sdlyDateMeta = (dayDate === 'day') ? propDetails['meta']['sdly_asof'] : propDetails['meta']['sdtly_asof'];
    }
// debugger;
     let sdlyText = (dayDate === 'day') ? "SDLY" : "SDTLY";
    let otbSdlyDate = (propDetails) ? sdlyDateMeta : dateFormat(moment(as_of_date).subtract(364, "days"), "ddd, MMM DD, YYYY");
    let finalValue = display;
    if (active_tiles[0] === 'pricing' && isRoomTypePricing && type === "roomTypeColumn") {
        finalValue = (<><p className="room-code">{display.room_code} {(display.is_base && "(Base) ")}
            {"[" + display.room_inventory + "] "} </p>
            <p className="room-display-name">{display.room_display_name}</p></>);
    } else if (active_tiles[0] === 'restrictions' && isRestrictionsRoomType && type === "roomTypeColumn") {
        finalValue = (<><p className="room-code">{display.room_code} {(display.is_base && "(Base) ")}
            {"[" + display.room_inventory + "] "} </p>
            <p className="room-display-name">{display.room_display_name}</p></>);
    } else {
        // console.log("display =>  ", display);
        display = replaceAll(display, "%dayscount%", app_state.workspace_controls.pickUpDays)
        display = replaceAll(display, "%asOfDate%", asOfDate)
        display = replaceAll(display, "{sdYear}", sdYear)
        display = replaceAll(display, "{day-date}", dayDate)
        display = replaceAll(display, "{sdlyText}", sdlyText)
        display = replaceAll(display, "{sdYear-actuals}", getSdyearsActuals(propDetails, dayDate, startDate, endDate, sdYear));
        display = replaceAll(display, "{sdYear-market-actuals}", getSdyearsActuals(propDetailsMarket, dayDate));
        var result = display.split(/[{}]/);
        if (result.length > 1) {
            let dayDisplay = result[0].split(/[%%]/);
            if (result[1] === "asofdatetime") {
                finalValue = result[0] + " " + new moment(as_of_date).format("ddd, MMM DD") + " " + moment().format("HH:MM A") + " (GMT" + moment().format("Z") + ")";
            } else if (result[1] === "compAsOfDate") {
                finalValue = result[0] + " " + new moment(comp_stay_range.comp_as_of_date).format(dtFormat);
            } else if (result[1] === "dbamaindate") {
                finalValue = result[0] + " " + new moment(startDate).format(dtFormat);
            } else if (result[1] === "dbacomparisiondate") {
                finalValue = result[0] + " " + new moment(app_state.comp_stay_range.date_from).format(dtFormat);
            } else if (result[1] === "comparisonrangeyear") {
                finalValue = (compYearArr.length) ? compYearArr.join(" - ") : moment().format("YYYY");
                finalValue = "Comparison Pace - " + finalValue;
            } else if (result[1] === "rangeyear") {
                finalValue = (yearArr.length) ? yearArr.join(" - ") : moment().format("YYYY");
            } else if (result[1] === "asOfDate") {
                finalValue = result[0] + " " + asOfDate;

            } else if (result[1] === "otbSdlyDate" && propDetails) {
                // let propDetails = getSeriesById("OSR").data;
                let sdlyDateMeta = (dayDate === 'day') ? propDetails['meta']['sdly_asof'] : propDetails['meta']['sdtly_asof'];
                let otbSdlyDate = (propDetails) ? sdlyDateMeta : dateFormat(moment(as_of_date).subtract(364, "days"), "ddd, MMM DD, YYYY");
                finalValue = result[0] + " " + dateFormat(otbSdlyDate, "ddd, MMM DD, YYYY");
            } else if (result[1] === "subsYearDays" || result[1] === "substract364") {
                finalValue = result[0] + " " + dateFormat(moment(otbSdlyDate), "ddd, MMM DD, YYYY");
            } else if (result[1] === "subsPickupDays") {
                // finalValue = result[0] + " " + dateFormat(moment(otbSdlyDate).subtract(pickupDays, "days"), "ddd, MMM DD, YYYY");
                finalValue = result[0] + " " + dateFormat(moment(asOfDate).subtract(pickupDays, "days"), "ddd, MMM DD, YYYY");
            } else if (result[1] === "sdlyPickupDays") {
                finalValue = result[0] + " " + dateFormat(moment(otbSdlyDate).subtract(pickupDays, "days"), "ddd, MMM DD, YYYY");
            } else if (result[1] === "UNCONSTRAINED") {
                finalValue = (workspace_controls.unconstrained) ? "Unconstrained " + result[2] : "" + result[2];// dateFormat(moment(as_of_date).subtract(sdlyPickupDays, "days"), "ddd, MMM DD, YYYY");
            }

        } else {
            finalValue = result[0];
        }



        if (active_tiles[0] === 'pricing') {
            finalValue = (<p>{finalValue}</p>);
        } else {
            finalValue = (<span>{finalValue}</span>);
        }
    }

    return finalValue;
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function replaceAll(str, find, replace) {
    if (str) {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    } else {
        return "";
    }
}

function getSdyearsActuals(propDetails, dayDate, startDate, endDate, sdYear) {
    let sdlyTo = (dayDate === 'day') ? "sdly_to" : "sdtly_to";
    let sdlyFrom = (dayDate === 'day') ? "sdly_from" : "sdtly_from";

    let startYear = 0;
    let endYear = 0;
    if (dayDate === "date") {
        startYear = moment(startDate).year() - sdYear;
        endYear = moment(endDate).year() - sdYear;
    }

    let result = '';
    if (propDetails && propDetails["meta"][sdlyFrom] && propDetails["meta"][sdlyTo]) {

        let fromYear = (dayDate === 'day') ? propDetails["meta"][sdlyFrom] : propDetails["meta"]['sdtly_from'];
        let toYear = (dayDate === 'day') ? propDetails["meta"][sdlyTo] : propDetails["meta"]['sdtly_to'];

        if (moment(propDetails["meta"][sdlyFrom]).isSame(propDetails["meta"][sdlyTo], 'year')) {
            result = moment(fromYear).format('ddd, MMM DD') + " - " + moment(toYear).format('ddd, MMM DD YYYY');
        } else {
            result = moment(fromYear).format('ddd, MMM DD YYYY') + " - " + moment(toYear).format('ddd, MMM DD YYYY');
        }
    }
    return result;
}
