import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBack from '@mui/icons-material/ArrowBack';
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processColumns, processData } from "./processData";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { sortData, getSortedColumn, getFilteredColumns, ColumnSorting, getFilteredData } from "./TableHelperFunctions"
import moment from "moment";
import {
    downloadcsvwithTable, getCompsetName, round, getNumDaysFromDateRange, safeReference, dateDiff,
    getCompsetMedian, isEmptyObj, getRestrictionsArray, generateTable,
    numberFormatForReport, truncate, sortStringArray, dateFormat, deepCopy, processColumnsNew, getAppData, getPermissions,
    getStayToSdly, getStayToComp, in_array, getSDLYorSDTLYDate, isDateInRange
} from "../../app/util"
import { transformRestrictionsData } from "./restrictionsData";
import "./style.scss";
import { updateAppState, getPropertyInfo, restrictionsAvailable } from "../../actions/appstate";
import { Button, Tooltip, getTableSortLabelUtilityClass, IconButton, Popover } from "@mui/material";
import { Box } from "@mui/system";
import TableToExcel from "@linways/table-to-excel";
import Spinner from "../Spinner";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Filter from "./Filter";
import { TILES } from "../../app/config";
import Restrictions, { getResName, getRestrictionAll } from "./Restrictions";

import { primaryHeaderDisplay } from './table-functions';
import TablePagination from '@mui/material/TablePagination';
import DataService from "../../services/data.service";

import { getPricingDataFormatted } from './tilesData/pricing';
import DataGrid, { Row, RowRendererProps } from 'react-data-grid';
import InputEl from './InputEl';
import CheckboxEl from './CheckboxEl';
import InfoPopover from './InfoPopover';
import "./table-restrictions.css";
import RestrictionsUpdate from './RestrictionsUpdate';
import { ResetTvOutlined } from '@mui/icons-material';
import TableRestrictionsRoomType from './TableRestrictionsRoomType';

import NotesPopup from './NotesPopup';
import EventsPopup from './EventsPopup';
import DisplayData from './DisplayData';
import { UPDATE_STATE } from "../../actions/types";

const PrimaryHeaderCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#606060",
        color: "white",
        borderRight: "1px solid white",
        //textTransform: "uppercase",
        fontWieght: "500",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));
const SecondaryHeaderGreyCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#b2b2b2",
        color: "white",
        borderRight: "1px solid white",
        //textTransform: "uppercase",
        fontWieght: "500",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const SecondaryHeaderBlueCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3fa7cd",
        color: "white",
        borderRight: "1px solid white",
        //textTransform: "uppercase",
        fontWieght: "500",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#ebebeb",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));






function TableRestrictions({ compDisplayData }) {
    const state = useSelector(appState => appState);
    // debugger; 
    const { auth } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );


    const { workspace_controls, as_of_date, notes, eventList, eventListSDLY } = app_state;
    const { sdlyOn, pickUpType, segmented_view, isRoomTypePricing } = workspace_controls;
    const { active_tiles, table_filters } = ui_state;
    const [selectedPropertyName, setPropertyName] = useState(profile.property_details.filter(property => property.id == app_state.property_id)[0].name)
    const active_tiles_className =
        "tile-active-" + active_tiles.join(" tile-active-");
    let filteredDow = table_filters.table_common.dow.map(d => d.code);

    const [columns, setColumns] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [data_cols, setDataCols] = useState([]);
    const [data_setup, setDataSetup] = useState([]);
    const [page, setPage] = React.useState(0);
    const dispatch = useDispatch();
    let [displayData, setDisplayData] = useState(compDisplayData);
    const [compLoad, setCompLoad] = useState(false);
    const [reloadGrid, setReloadGrid] = useState("");
    const rowsPerPage = 31;
    const [compLoadStatus, setCompLoadStatus] = useState('loading');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [popOverData, setPopOverData] = useState({
        anchorEl: null,
        stay_date: "",
        sellRate: "",
        otb: {},
        forecast: {},
        room_code: "",
        isBase: "",
    });

    const [pricingData, setPricingData] = useState(getPricingDataFormatted(app_state, true))
    const [propertyInfo, setPropertyInfo] = useState(getPropertyInfo(profile.property_details, app_state.property_id));
    const { permissions, index } = getPermissions(profile.property_details, app_state.property_id);


    const getNeededColumns = ({ auth, app_state, ui_state }) => {

        let startDate = app_state.primary_stay_range.date_from;
        let endDate = app_state.primary_stay_range.date_until;
        let restrictions = (permissions && permissions.restrictions === 1) ? true : false; //getAppData(auth, app_state).restrictions.enable;
        let finalColumns = [];
        active_tiles.map((tile, i) => {
            let tableConfig = deepCopy(TILES[tile]['tableConfig']);
            let columns = tableConfig['columns'];

            let sdlyColumns = deepCopy(TILES[tile]['tableConfig']['sdlyOn_columns']);
            let restrictionsColumns = deepCopy(TILES[tile]['tableConfig']['restrictionsOn_columns']);
            let dataDbaColumns = deepCopy(TILES[tile]['tableConfig']['dataDbaColumns']);
            let dataDateColumns = deepCopy(TILES[tile]['tableConfig']['dataDateColumns']);
            // let dbaSdlyColumns = deepCopy(TILES[tile]['tableConfig']['dbaOn_sdly_columns']);
            let userForecastColumns = deepCopy(TILES[tile]['tableConfig']['userForecast_columns']);

            let houseLevelColumns = deepCopy(safeReference(TILES[tile], [], ["tableConfig", "houseLevelColumns"]));
            let roomTypeOnColumns = deepCopy(safeReference(TILES[tile], [], ["tableConfig", "roomTypeOnColumns"]));

            finalColumns = [...finalColumns, ...columns];
            //  debugger;
            let tile_segmented_view = true;
            let segmentedViewIgnore = ['compset'];
            if (segmentedViewIgnore.includes(active_tiles[0])) {
                tile_segmented_view = false;
            }
            if (active_tiles[0] === "market" && app_state.workspace_controls.scopeType === 'Dba') {
                tile_segmented_view = false;
            }

            if (active_tiles[0] === "compset") {
                let subColumns = getCompsetName();
                //    console.log("subColumns ",subColumns);
                columns[1]['subcolumns'] = [...columns[1]['subcolumns'], ...subColumns];
            }

            // check Scope is DBA
            // debugger;
            if (dataDbaColumns && app_state.workspace_controls.scopeType === 'Dba') {
                let dateColIndex = columns.findIndex(item => item.type === "dateColumn");
                let dbaSubColumns = columns[dateColIndex].subcolumns_dba;
                columns[dateColIndex]['subcolumns'] = dbaSubColumns;
                if (dataDbaColumns) {
                    finalColumns = [...finalColumns, ...dataDbaColumns];
                }
            } else {
                if (dataDateColumns) {
                    finalColumns = [...finalColumns, ...dataDateColumns];
                }
            }
            /// end ///

            /// to check user forecast ///
            let userForecastData = [];
            if (!DataService.getSeriesById("ManualForecast").data) {
                userForecastData = [];
            } else {
                userForecastData = DataService.getSeriesById("ManualForecast").data.data;
            }
            if (userForecastData.length > 0 && userForecastColumns) {
                // console.log("inside userForecastData= => ", userForecastData['data']);
                finalColumns = [...finalColumns, ...userForecastColumns];
            }
            //// end ///

            if (sdlyOn && sdlyColumns) {
                finalColumns = [...finalColumns, ...sdlyColumns];
            }
            if (restrictions && restrictionsColumns) {
                finalColumns = [...finalColumns, ...restrictionsColumns];
            }
        });
        // console.log("FinalColumns ==> ",finalColumns);

        // End //

        finalColumns.sort(function (a, b) { return a.order - b.order });
        finalColumns.map((item, index) => {
            let subColumns = item.subcolumns;
            subColumns.sort(function (a, b) { return a.order - b.order });
        })

        let sortedColumns = [...finalColumns];
        // debugger;
        return sortedColumns;
    };

    const compRef = useRef({
        itemDragged: {},
        itemDroppedOn: {},
        columns: getNeededColumns({ auth, app_state, ui_state })
        // columns: deepCopy(TILES[active_tiles[0]]['tableConfig']['columns']),
        // sortedColumn: TILES[active_tiles[0]]['tableConfig']['columns'][0][0]
    });
    const inputRef = useRef(deepCopy(app_state.roomtype_pricing_overrides))

    // const downloadReportToExcel = (app_state, active_tiles, selectedPropertyName) => {
    //     let exportFileName = moment(app_state.as_of_date).format('MMM DD YYYY') + "_" + selectedPropertyName + "_" + active_tiles[0];
    //     if (app_state.workspace_controls.downloadExportButton === true) {
    //         let sheetName = active_tiles[0] ? active_tiles[0] : " ";


    //         TableToExcel.convert(document.getElementById("mainTable"), {
    //             name: exportFileName + ".xlsx",
    //             sheet: {
    //                 name: sheetName.toUpperCase()
    //             }
    //         });
    //     }
    // }

    const downloadReportToExcel = (app_state, active_tiles, selectedPropertyName) => {
        let exportFileName = moment(app_state.as_of_date).format('MMM DD YYYY') + "_" + selectedPropertyName + "_" + active_tiles[0];
        if (app_state.workspace_controls.downloadExportButton === true) {
            let sheetName = active_tiles[0] ? active_tiles[0] : " ";

            // Get the table element and create a copy
            let table = document.getElementById("mainTable");
            let tableCopy = table.cloneNode(true);

            // Get the first row
            let firstRow = tableCopy.rows[0];

            // Clone the first cell of the first row
            let firstCellClone1 = firstRow.cells[0].cloneNode(true);
            firstCellClone1.innerText = "Notes";
            firstCellClone1.removeAttribute('colspan');
            firstRow.appendChild(firstCellClone1);

            let firstCellClone2 = firstRow.cells[0].cloneNode(true);
            firstCellClone2.innerText = "Events";
            firstCellClone2.removeAttribute('colspan');
            firstRow.appendChild(firstCellClone2);

            //Create new setof rows tableCopy have duplicate rows
            let size = tableCopy.rows.length;
            let tableCopyRows = [firstRow];
            for (let i = 1; i < size; i++) {
                let row = tableCopy.rows[i];
                let isExclude = row.attributes['data-exclude']                
                if ( !isExclude) {
                    tableCopyRows.push(row)
                }
            }

            // Start from the second row (index 1)
            for (let i = 1; i < tableCopyRows.length; i++) {
                let row = tableCopyRows[i];

                // Clone the first cell of the current row
                let firstCell = row.cells[0].cloneNode(true);
                let secondCell = row.cells[0].cloneNode(true);


                if (i == 1) {
                    // Assign header text fot notes column
                    firstCell.innerText = "Note TY";
                    secondCell.innerText = "Event TY";
                }
                else {
                    const formatted_stay_date = dateFormat(firstCell.innerText, "YYYY-MM-DD");
                    let dataForDate = notes && notes.find(item => item.stay_date === formatted_stay_date);
                    let current_stay_date = dateFormat(formatted_stay_date, "YYYY/MM/DD")

                    if (!dataForDate) {
                        dataForDate = {
                            note: '',
                            last_edited_by: '',
                            last_edited_at: ''
                        }
                    }
                    let noteData = "";
                    if (dataForDate.note !== '') {
                        noteData = current_stay_date + " - " + dataForDate.note;
                    }

                    const filteredEventList = eventList.filter(event =>
                        isDateInRange(formatted_stay_date, event.start_date, event.end_date) && event.is_ignored === false
                    );
            
                    let eventData = filteredEventList.map(event => event.title).join(", ");

                    // Assign notes in each rows
                    firstCell.innerText = noteData;
                    secondCell.innerText = eventData;
                }

                // Set text alignment to center
                firstCell.style.textAlign = "left";
                secondCell.style.textAlign = "left";

                // Append the cloned cells as new columns at the end of the row
                row.appendChild(firstCell);
                row.appendChild(secondCell);
            }

            // Convert the copied table to Excel

            if (app_state.workspace_controls.exportType == 'csv') {
                downloadcsvwithTable({rows: tableCopyRows}, exportFileName, compRef.current.columns);
            } else {
                TableToExcel.convert(tableCopy, {
                    name: exportFileName + ".xlsx",
                    sheet: {
                        name: sheetName.toUpperCase()
                    }
                });
            }

        }
    }

    useEffect(() => {
        applyFilterHandler();
    }, []);

    useEffect(() => {
        let isSubscribed = true;

        setPropertyInfo(getPropertyInfo(profile.property_details, app_state.property_id));
        setPropertyName(profile.property_details.filter(property => property.id == app_state.property_id)[0].name)
        if (app_state.workspace_controls.downloadExportButton === true) {
            downloadReportToExcel(app_state, active_tiles, propertyInfo.name);
            dispatch(updateAppState("toggleChartTableView", {
                app_state: {
                    workspace_controls: { ...app_state.workspace_controls, downloadExportButton: false, }
                }
            }));
        }

        // debugger; 
        if (isSubscribed) {
            if (compLoad && displayData && displayData.length > 0  ) {
                setupData();
            }
            if (app_state.data_load) {
                dispatch(
                    updateAppState("data_load", {
                        app_state: {
                            data_load: false,
                        },
                    })
                );
            }

            if (!ui_state.showspinner) {
                if (!compLoad && compDisplayData && compDisplayData.constructor.name === 'Array' && compDisplayData.length) {
                    // Transform and build data for restrictions
                    let start = moment().format("x");
                    let transformedData = transformRestrictionsData(app_state, auth, compDisplayData, { tile: active_tiles[0] });
                    // debugger;
                    console.log("time taken transformRestrictionsData:", moment().format("x") - start);
                    displayData = transformedData;
                    setDisplayData(transformedData);
                    setCompLoad(true);
                }
            }
        }
        return () => {
            // app_state.savedRestrictions.splice(0);
            // debugger;
            app_state.updatedRestrictions.splice(0);
            return (isSubscribed = false);
        }
    }, [ui_state.showspinner,
    workspace_controls.downloadExportButton,
    app_state.property_id, compLoad,
        reloadGrid,
    //displayData
    app_state.updatedRestrictions.length,
    app_state.savedRestrictions.length
    ]);

    useEffect(() => {
        let isSubscribed = true;

        if ( compRef.current.data_setup) {
            let sortedColumn = getSortedColumn(compRef.current.columns);
            let data_setup = deepCopy(compRef.current.data_setup);
            data_setup = compRef.current.data_setup.filter((item) => item[0]['date'] === '' || filteredDow.includes(moment(item[0].date).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
            data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });
            data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
            setDataSetup(data_setup);
            compRef.current.data_setup = deepCopy(data_setup);

        }

        return () => (isSubscribed = false);
    }, [reloadGrid])


    const onDragStartHandler = (columParam) => {
        return (event) => {
            // event.preventDefault();
            event.dataTransfer.setData("columnMeta", JSON.stringify(columParam));
            compRef.current.itemDragged = deepCopy(columParam);
        }
    };


    const mainColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            const draggedCellOrder = columnMeta.order;
            const draggedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === draggedCellOrder);
            const droppedCellOrder = columParam.order;
            const droppedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === droppedCellOrder);
            compRef.current.itemDroppedOn = columParam;

            compRef.current.columns.map((mainCol, i) => {
                //moving to left
                if (draggedCellOrder > droppedCellOrder && i <= draggedCellOrderIndex) {
                    // debugger;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i <= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order + 1;
                    }
                }
                //moving to right
                if (draggedCellOrder < droppedCellOrder && i >= draggedCellOrderIndex) {
                    // debugger;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i >= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order - 1;
                    }
                }
            });
            compRef.current.columns = processColumnsNew(compRef.current.columns);
            // setupData();
            // let ts = Date.now();
            // setReloadGrid(ts);
            // setCompLoadStatus('loading');
        }
    };
    const dataColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            compRef.current.itemDroppedOn = columParam;
            // debugger;
            let { itemDragged, itemDroppedOn } = compRef.current;
            if (columnMeta.parent === columParam.parent) {
                let draggedOrder = columnMeta.order;
                let droppedOrder = columParam.order;
                let mainColumn = compRef.current.columns.filter((item) => item.id === columnMeta.parent);
                compRef.current.columns.map((mainCol, i) => {
                    if (mainCol.id === columnMeta.parent) {
                        compRef.current.columns[i].subcolumns.map((col) => {
                            if (col.value === columnMeta.value) {
                                col.order = droppedOrder;
                            }
                            if (col.value === columParam.value) {
                                col.order = draggedOrder;
                            }
                        });
                    }
                });
                let columns = processColumnsNew(compRef.current.columns);
                compRef.current.columns = columns;
                // setupData();
                let ts = Date.now();
                setCompLoadStatus('loading');
                setReloadGrid(ts);
            }
        }
    };
    const columnToggleHandler = (item) => {
        let cols = compRef.current.columns;
        let ts = Date.now();
        setCompLoadStatus('loading');
        setReloadGrid(ts);
    }
    const applyFilterHandler = (item) => {
        let ts = Date.now();

        setCompLoadStatus('sortingCols');
        setReloadGrid(ts);
    };
    const clearFilterHandler = (item, type) => {
        // debugger;
        if (type === 'all') {
            compRef.current.columns.map((main, i) => {
                main.subcolumns.map((sub, j) => {
                    //it depends on property sorted
                    sub.filter = null;
                });
            });
        }
        setCompLoadStatus('loading');
        let ts = Date.now();
        setReloadGrid(ts);
    };

    const onOrderByChangeHandler = (item) => {
        // debugger;
        setCompLoadStatus('loading');
        let cols = compRef.current.columns;
        compRef.current.sortedColumn = item;
        compRef.current.columns.map((main) => {
            if (main.id === item.parent) {
                main.subcolumns.map((col) => {
                    if (col.value === item.value) {
                        col.sorted = item.sorted;
                    } else {
                        col.sorted = null;
                    }
                })
            } else {
                main.subcolumns.map((col) => {
                    col.sorted = null;
                });
            }

        });
        let ts = Date.now();
        setReloadGrid(ts);
        // setupData();
        // debugger;
    }
    const updateCheckedAll = (checked, sub_col_index) => {

        let daysFromAsOf = dateDiff(app_state.primary_stay_range.as_of_date, app_state.primary_stay_range.date_from);
        let fromDate = app_state.primary_stay_range.date_from;

        if (daysFromAsOf > 0) {
            fromDate = app_state.primary_stay_range.as_of_date;

        } else {
            daysFromAsOf = 0;
        }
        let date = fromDate;
        let numDays = dateDiff(app_state.primary_stay_range.date_until, fromDate) + 1;
        for (let index = 0; index < numDays; index++) {
            if (app_state.pricing_override_rates[date]) {
                app_state.pricing_override_rates[date].approved = checked;
            } else {
                let value = data_setup[index + daysFromAsOf][sub_col_index - 1]["RecSellRate"];
                app_state.pricing_override_rates[date] = {
                    override_rate: value,
                    approved: checked
                };
            }
            let currentRate = data_setup[index + daysFromAsOf][sub_col_index - 1]["SellRate"];
            if (app_state.pricing_override_rates[date].override_rate === currentRate) {
                app_state.pricing_override_rates[date].approved = false;
            }
            date = moment(date).add(1, 'days').format("YYYY-MM-DD");
        }

        dispatch(updateAppState("change_pricing_rates", {
            app_state: {
                pricing_override_rates: { ...app_state.pricing_override_rates }
            }
        }))
        setupData();
    }

    const setupData = () => {
        // console.log("Columns => ", columns);

        let columns = processColumnsNew(compRef.current.columns);
        compRef.current.columns = columns;
        let data_cols = [];
        columns.map((i) => {
            i.subcolumns.map((x) => {
                data_cols.push(x.value);
            });
        });
        compRef.current.data_cols = data_cols;
        //setDataCols(data_cols);
        let data_setup = processData(columns, displayData, {
            auth,
            app_state,
            ui_state,
        });
        let t = compRef.current.sortedColumn;
        // debugger;
        let sortedColumn = getSortedColumn(compRef.current.columns);
        data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted })

        compRef.current.filteredColumns = getFilteredColumns(compRef.current.columns);
        data_setup = getFilteredData({ data: data_setup, columns });

        compRef.current.data_setup = deepCopy(data_setup);

        // debugger;
        // setPricingData(getPricingDataFormatted(app_state, true))
        // setupRoomTypeInputs()
        setDataSetup(data_setup);
        setCompLoadStatus('loaded');
    };
    const hasRestrictions = (value) => {
        // debugger; 
        let result = false;
        if (value && (value.cta || value.ctd || value.close || value.min_los > 1 || value.max_los > 1)) {
            result = true;
        }
        return result;
    }
    const renderHeader = () => {
        let columns = compRef.current.columns;
        let cols = [];
        let colheaders = [];
        let count = 0;
        let bckgClass = "grey";
        let subHeaderStickyLeft = 0;
        for (let index = 0; index < columns.length; index++) {
            const col = columns[index];
            if (!col.isSticky) {
                bckgClass = "light_blue";
            }
            // const col_len = col["subcolumns"].length;
            let colSpan = col["subcolumns"].length;
            let hiddenCols = col["subcolumns"].filter(item => item.hidden);
            colSpan = colSpan - hiddenCols.length;
            // debugger
            let primaryHSty = {};
            if (col.type == "dateColumn" || col.isSticky === true) {
                primaryHSty = {
                    position: "sticky",
                    left: col.left ? col.left : 0,
                    zIndex: 5,
                };
                // colSpan = 2;
            }
            colSpan = colSpan > 1 ? colSpan : null;
            const header = (
                <PrimaryHeaderCell
                    onDrop={mainColumnOnDropHandler(col)}
                    key={index}
                    align="center"
                    colSpan={colSpan}
                    style={primaryHSty}
                    className={"primary-header-cell header_col "}
                    sx={{ fontWeight: "bold !important" }}
                    data-fill-color="606060"
                    data-f-color="ffffff"
                    data-b-r-s="thin"
                    data-a-v="middle"
                    data-b-a-c="ffffff"
                    data-a-h="center"
                    data-f-sz="12"
                >
                    {col.draggable && <div draggable={col.draggable} onDragStart={onDragStartHandler(col)} onDragOver={(e) => { e.preventDefault() }}>
                        <span>
                            {primaryHeaderDisplay(col, app_state, ui_state)}
                        </span>

                    </div>}

                    {!col.draggable && <div>
                        <span>
                            {primaryHeaderDisplay(col, app_state, ui_state)}
                        </span>

                    </div>}

                </PrimaryHeaderCell>
            )
            let subColClass = "";
            col["subcolumns"].map((item, sub_col_index) => {
                bckgClass = ((item.display).toLowerCase() === "comparison date") ? "header-grey" : "header-blue";
                let sty = {};
                subColClass = item.subcol_id;
                let stickyStyInner = {}
                // sty["paddingBottom"] = "18px";
                sty["fontWeight"] = "bold";
                sty["background"] = "#3fa7cd";
                sty["fontSize"] = "19px !important";
                let hasFilterClass = item.filter && (item.filter.gte || item.filter.lte) ? "has-filter" : "";
                if (!hasFilterClass && ['dow'].includes(item.type) && ui_state.table_filters.table_common.dow.length < 7) {
                    hasFilterClass = "has-filter";
                }
                if (col.isSticky === true) {
                    sty["position"] = "sticky";
                    sty["zIndex"] = 2;

                    sty["left"] = subHeaderStickyLeft;
                    if (!item.hidden) {
                        subHeaderStickyLeft += 76;
                    }

                    sty["background"] = "#b2b2b2";
                    //stickyStyInner["width"] = "50px";
                } else {
                    sty["zIndex"] = 1
                }
                let selectAllCheckBox = "";

                let el = (
                    <SecondaryHeaderBlueCell
                        onDrop={dataColumnOnDropHandler(item)}
                        className={"secondary-header-cell header_col  " + bckgClass + " col_len_1  header-col-" + item.value}
                        style={sty}
                        key={count}
                        align="center"
                        data-fill-color="B2B2B2"
                        data-f-color="ffffff"
                        data-a-v="middle"
                        data-a-h="center"
                        data-f-bold="true"
                        data-f-sz="15"
                        // data-b-a-c="ffffff"
                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        sx={{ fontSize: "13px", top: "30px" }}
                    >
                        <div className={"filter-position-r " + subColClass + " " + bckgClass} style={stickyStyInner} onDragOver={(e) => { e.preventDefault() }}>

                            <ColumnSorting
                                className={"column-sorting field-" + item.value}
                                colItem={item} sortable={item.sortable} sorted={item.sorted}
                                label={item.display} value={item.value}
                                onOrderByChange={onOrderByChangeHandler} />

                            <div className="col-head-label" style={{ "width": item.width ? item.width : 'auto' }} draggable={item.draggable} onDragStart={onDragStartHandler(item)}>
                                {item.subcol_id === "pricing_Approve" && (<><input type="checkbox" checked={allChecked} onChange={(e) => {
                                    const checked = e.target.checked;
                                    setAllChecked(checked);
                                    updateCheckedAll(checked, sub_col_index);
                                }} /><br /></>)}
                                {selectAllCheckBox}
                                {item.value === 'Rooms' && <>Room Sold</>}
                                {item.value !== 'Rooms' && item.display}
                            </div>
                            {item.filterMenu &&
                                <div className="filter-main">                                    
                                    {hasFilterClass && <span className={hasFilterClass}></span>}
                                    <Filter filteredColumns={compRef.current.filteredColumns} columnList={compRef.current.columns} currentColumn={item}
                                        columnToggleHandler={columnToggleHandler}
                                        applyFilterHandler={applyFilterHandler}
                                        clearFilterHandler={clearFilterHandler}
                                    />
                                </div>}
                        </div>
                    </SecondaryHeaderBlueCell>
                );


                if (col.type == "dateColumn") {
                    var colClass = "date_subcolumn";
                    sty["position"] = "sticky";
                    sty["zIndex"] = "5";
                    sty["textAlign"] = "center";
                    // sty["paddingBottom"] = "18px";
                    sty["fontWeight"] = "bold";
                    // sty["paddingLeft"] = "15px";
                    // sty["paddingRight"] = "15px";
                    sty["background"] = "#b2b2b2";

                    if ((item.display).toLowerCase() === 'dba' || (item.display).toLowerCase() === 'month') {
                        sty["left"] = sub_col_index * 64;
                    }

                    if (item.hasOwnProperty("value") && (item.display).toLowerCase() === 'dow') {
                        // sty["left"] = "70px !important";
                        sty["width"] = "60px !important";
                    }
                    if (item.hasOwnProperty("value") && (item.value).toLowerCase() === 'segment' && active_tiles[0].toLowerCase() !== "pace") {
                        sty["left"] = sub_col_index * 64;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }

                    if (item.hasOwnProperty("value") && (item.display).toLowerCase() === 'segment' && app_state.workspace_controls.scopeType === "Dba" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                        sty["left"] = sub_col_index * 100;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }
                    if ((item.display).toLowerCase() === 'segment' && app_state.workspace_controls.scopeType === "Date" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                        sty["left"] = sub_col_index * 74;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }


                    let el = (
                        <SecondaryHeaderGreyCell
                            className={"header_col  " + bckgClass + " " + colClass}
                            style={sty}
                            key={count}
                            align="left"
                            data-fill-color="3fa7cd"
                            data-f-color="ffffff"

                            data-a-v="middle"
                            data-a-h="center"
                            data-f-bold="true"
                            data-f-sz="15"
                            data-b-r-s="thin"
                            data-b-a-c="ffffff"
                            // sx={{pl:3.5,pr:13.5,pt:1}}
                            sx={{ fontSize: "13px", padding: "0px !important", top: "30px !important" }}
                        >
                            {/* <div className="filter-position-r">
                                {item.display}
                                <Filter className="filter-position-a" />
                            </div> */}
                            <div className="filter-position-r date-col" sx={{ minWidth: "80px !important" }}>
                                <div className="col-head-label">
                                    {item.display}
                                </div>
                                <div className="filter-main">
                                    <Filter />
                                </div>
                            </div>
                        </SecondaryHeaderGreyCell>
                    );
                }
                if (!item.hidden) {
                    cols.push(el);
                }
                count++;

            });
            if (!col.hidden) {
                colheaders.push(header);
            }

        }

        return (
            <TableHead className="table-head">
                <TableRow data-height="30" className="header_row top_header">{colheaders}</TableRow>
                <TableRow data-height="30" className="header_row bottom_header">{cols}</TableRow>
            </TableHead>
        );
    };

    const renderBody = (params) => {
        const data_setup = compRef.current.data_setup;
        const rows = [];
        let size = rowsPerPage + (page * rowsPerPage);
        if (compRef.current.data_setup.length < size) {
            size = compRef.current.data_setup.length
        }
        //breaking pagination logic as pagination not needed
        size = compRef.current.data_setup.length

        let colCount = 0;
        let totalArr = [];
        let rowsCount = 0;
        // debugger;
        console.log('render body')
        let columnsCounts = 0;
        if (size > 0) {
            for (let index = 0 + (rowsPerPage * page); index < size; index++) {
                var evenRow = (rowsCount % 2 === 0) ? "even-row" : "badmash";

                let columnTotal = 0;

                const data_columns = data_setup[index];
                const cols = [];
                const reportCols = [];
                let count = 0;
                totalArr[index] = 0;
                colCount = 0;
                var totalRow = "";
                var totalCol = "";
                let rowDate;
                let leftSticky = 152;// 152 if not hidden
                let stay_date = data_columns[0].date;
                let dateData = deepCopy(data_setup[index]);
                data_columns.map((column, col_index) => {
                    let subCols = compRef.current.columns[col_index].subcolumns.filter(col => !col.hidden);
                    // let subCols = Object.keys(column); debugger;
                    let currColumn = compRef.current.columns[col_index];
                    let cellClass = "data-cell";
                    subCols.map((sublcol, sub_col_index) => {
                        let type = sublcol.type || "";

                        let { subcol_id } = sublcol;
                        let subcol_key = subcol_id.replace(currColumn.id + "_", "");
                        let val = column[subcol_key];


                        let txtAlignClass = (app_state.workspace_controls.segmented_view && subcol_key === 'Revenue') ? "text-align-right" : "";
                        let txtAlignClassml = (subcol_key === 'ADR' || subcol_key === 'Occ') ? "text-align-left-ml" : "";
                        let txtAlignClassRight = (subcol_key === 'Rooms') ? "" : "";

                        // if (subcol_key === 'segment' && val === "Total") {
                        //     totalRow = "total-row";
                        //     totalCol = "total-col";
                        // }
                        let sty = {};
                        if (col_index === 0) {
                            sty = { position: "sticky", zIndex: 1, textAlign: "center" };
                            if (subcol_key === "segment" && app_state.workspace_controls.scopeType === "Date" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                                sty["left"] = sub_col_index * 74;
                                sty["borderRight"] = "solid 1px #ddd";
                            }
                            if (subcol_key === "segment" && app_state.workspace_controls.scopeType === "Dba" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                                sty["left"] = sub_col_index * 100;
                                sty["borderRight"] = "solid 1px #ddd";
                            } else if (subcol_key === "segment" && active_tiles[0].toLowerCase() !== "pace") {
                                sty["left"] = sub_col_index * 64;
                                sty["borderRight"] = "solid 1px #ddd";
                            } else {
                                sty["left"] = sub_col_index * 54; // old

                                sty["left"] = sub_col_index * 76;
                                if ((!app_state.workspace_controls.segmented_view) && (subcol_key === "DOW" || subcol_key === "date_market_summary" || subcol_key === "dba")) {
                                    sty["borderRight"] = "solid 1px #ddd";
                                    // sty["width"] = "solid 1px #ddd";
                                }
                            }
                        } else {
                            if (currColumn.isSticky === true) {
                                sty = { position: "sticky", zIndex: 1, textAlign: "center" };

                                sty["left"] = leftSticky;
                                leftSticky += 76;
                            }
                            if (subcol_key === "Revenue") {
                                sty["color"] = "#3583B8";
                                sty["textAlign"] = "center";
                                sty["ml"] = "5px !important";
                                sty["padingLeft"] = "5px";
                            }
                        }
                        var txtAlign = { textAlign: "center" };
                        let rowsBackColor = (rowsCount % 2 === 0) ? "ebebeb" : "ffffff";
                        // var totalRow = "1";
                        let dataType = numberFormatForReport(subcol_key);
                        let reportData;
                        // if (!val)
                        let tmpVal;

                        if (val && typeof val === "number") {
                            tmpVal = val;
                        } else if (val && typeof val !== "object") {
                            tmpVal = val.replaceAll("%", "");
                        } else if (val && typeof val === "object") {
                            let resVal = (val[0]) ? Object.keys(val[0]).join(",") : "";
                            // let resValDetails = getResName(resVal,val[0]['max_los']);
                            //  console.log(resVal," ",val[0]['max_los'],"TmpVal => ",resValDetails);
                            tmpVal = resVal;
                        }

                        if (subcol_key === 'Date') {
                            reportData = (val) ? moment(val).format('YYYY/MM/DD') : '';
                            rowDate = reportData;
                        } else if (subcol_key === 'Revenue' || subcol_key === 'ADR' || subcol_key === 'Occ' || subcol_key === 'RevPAR') {
                            tmpVal = (tmpVal && isFinite(tmpVal) && !isNaN(tmpVal)) ? tmpVal : parseFloat(0.00);
                            reportData = tmpVal;//(Number.isFinite(tmpVal)) ? parseFloat(tmpVal).toFixed(2) : parseFloat(0.00).toFixed(2);
                        } else {
                            reportData = val;
                        }
                        let brdRight;
                        let dowStyle
                        if (subcol_key === "dow") {
                            dowStyle = "dow-style";
                        }
                        if (subcol_key === "compdate") {
                            reportData = moment(val).format('MMM DD YYYY,  ddd');
                            dowStyle = "dow-style-comp-date";
                        }
                        if (subcol_key === "segment") {
                            dowStyle = "segment-td-style";
                        }

                        let rowStayDate = new moment(stay_date).startOf('day');
                        let today = new moment().subtract(1, 'days');
                        reportCols.push(
                            <TableCell
                                data-t={dataType.type}
                                // data-num-fmt={dataType.numfmt}
                                data-fill-color={rowsBackColor}
                                data-b-r-s="thin"
                                data-b-a-c="ffffff"
                                data-f-sz="12"
                                data-a-h="center"
                                subcol_key={count}
                                className={cellClass + " " + totalCol + " data-cell-" + subcol_key}
                                style={sty}>
                                {
                                    (subcol_id === "restrictions_rec" || subcol_id === 'restrictions_cur') ?
                                        (rowStayDate.isSameOrBefore(today)) ? "" : getRestrictionAll(stay_date, 'comma', subcol_id)
                                        : reportData
                                }
                                {/* {reportData} */}
                            </TableCell>
                        );
                        let displayData = "";
                        let disabledInput = false;

                        if (moment(stay_date).diff(app_state.as_of_date, 'days') < 0) {
                            disabledInput = true;
                        }


                        if (subcol_id === "restrictions_rec") {

                            //displayData = (<Restrictions data={val} stay_date={rowDate} />);
                        } else {
                            displayData = displayDataFormatNew(subcol_id, column, type, subcol_key);
                        }
                        if (type === "component" && subcol_id === 'restrictions_rec' && rowStayDate.isSameOrAfter(today) && hasRestrictions(val)) {
                            // debugger;
                            displayData = (<><Restrictions restriction_type="rec" data={val} stay_date={val.stay_date} /></>);
                        }
                        if (type === "component" && subcol_id === 'restrictions_cur' && !rowStayDate.isSameOrBefore(today)) {
                            displayData = (<RestrictionsUpdate editable={true} recData={column['rec']} cur={column[subcol_key]} data={[column[subcol_key]]} stay_date={stay_date} />);
                        }
                        let tCell = (<TableCell key={colCount} data-fill-color={rowsBackColor} data-b-r-s="thin" data-b-a-c="ffffff"
                            data-t="s" data-exclude="true" data-f-sz="5" data-a-h="center" subcol_key={count}
                            className={cellClass + " data-cell-" + subcol_key + " " + totalCol + " " + txtAlignClass + " " + txtAlignClassml + " " + txtAlignClassRight}
                            style={sty}>

                            {displayData}

                        </TableCell>)
                        cols.push(tCell);
                        colCount++;

                        if (typeof val === 'string') {
                            //totalArr[count] = parseFloat(totalArr[count] + 0);
                        } else {
                            if (count === 4) {
                                if (totalArr[count] === 'undefind') {
                                    totalArr[count] = 0;
                                }
                            }
                            totalArr[count] += val;// parseFloat(totalArr[count] + val);
                        }
                        count += 1;
                    });

                    columnsCounts++;
                });
                // rows.push(<StyledTableRow data-exclude="true"  key={rowsCount} data-height="20" className={"table-row"}>{cols}</StyledTableRow>);
                rows.push(<StyledTableRow data-exclude="true" data-height="20" className={(totalRow) ? totalRow : rowsCount % 2 === 0 ? "table-row even-row" : "table-row odd-row"}>{cols}</StyledTableRow>);

                rows.push(<StyledTableRow data-height="20" sx={{ display: "none" }}>{reportCols}</StyledTableRow>);
                rowsCount++;
            }
        } else {
            rows.push(<StyledTableRow className="">
                <TableCell colSpan={5}
                    sx={{ height: "auto", borderRight: "0px solid #ddd", background: "#fff", textAlign: "center", borderBottom: "0px solid" }}>
                    <Spinner />
                </TableCell></StyledTableRow>);
        }

        if (rowsCount <= 12) {
            rows.push(<StyledTableRow className="">
                <TableCell
                    sx={{ height: "auto", borderRight: "0px solid #ddd", background: "#fff", borderBottom: "0px solid" }}>
                </TableCell></StyledTableRow>);
        }

        let footerCols = [];
        var colSpanTotal = 2;
        let columns = processColumnsNew(compRef.current.columns);
        // if (data_setup.length > 0) {
        //     columns = data_setup[0];
        // }
        let count = 0;
        // console.log("test=> ",columns);


        return rows;
        //full table body is array of rows
        return <TableBody className="table-body">{rows}</TableBody>;
    };

    /////
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    /////////
    const getCurrency = () => {
        return ui_state.currency_symbol;
    }

    const displayDataFormatNew = (subcol_id, column, type, subcol_key) => {
        let returnElement = "";
        let val = column[subcol_key];

        if (type === "date") {
            //returnElement = moment(val).format("MMM DD");
            const formattedDate = moment(val).format("MMM DD");
            returnElement = (
                <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
                    <NotesPopup params={{ stay_date: val }} />
                    {formattedDate}
                </Box>
            );
        }

        if (type === "dow") {
            //returnElement = val;
            returnElement = (
                <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
                    <EventsPopup params={{ stay_date: column.date }} />
                    {val}
                </Box>
            );
        }


        if (type === "percent") {
            returnElement = val + "%";
        }
        if (subcol_key !== "rooms" && subcol_key !== "dba" && subcol_key !== "revenue") {
            val = Number.isInteger(val) ? val.toFixed(2) : val;
        }

        if (type === "integer") {
            returnElement = parseInt(val) | "";
        }
        if (type === "diff" && val !== '-') {
            let symbol = "", colorClass = "";
            if (val < 0) {
                val *= -1;
                symbol = "-"
                colorClass = "negative"
            } else if (val > 0) {
                symbol = "+"
                colorClass = "positive"
            }
            returnElement = (<span className={colorClass}>{symbol + getCurrency() + round(val)}</span>)
        }
        if (type === "string") {
            returnElement = val.toString();
        }

        if (subcol_key === 'segment') {
            returnElement = truncate(val, 17);
        }
        if (type === 'compdate') {
            // let compVal =  dateFormat(val, "MMM DD, YYYY ddd");
            returnElement = moment(val).format("MMM DD YYYY,  ddd");
            // debugger;
        }
        if (type === "currency") {

            if (val === '-') {
                returnElement = getCurrency() + val;
            } else {

                if (val >= 0) {

                    if (subcol_key !== "SellRate") {
                        val = parseFloat(round(val)).toFixed(2);
                    } else {
                        val = parseInt(val);
                    }
                } else {
                    if (Number.isFinite(val)) {
                        if (subcol_key !== "SellRate") {
                            val = parseFloat(val).toFixed(2);
                        }

                        val = "(" + val.toString().replaceAll("-", "") + ")";
                    } else {
                        if (subcol_key !== "SellRate") {
                            val = parseFloat(0.00).toFixed(2);
                        } else {
                            val = 0;
                        }
                    }
                }
                returnElement = getCurrency() + val;

            }
        }


        return returnElement;

    }



    const displayDataFormat = (val, key, type) => {
        key = key.toLowerCase();
        let value = val ? val : '';
        let currSymbol = ui_state.currency_symbol;
        if (val !== '' && key !== 'segment' && key !== 'compdate') {
            if (key !== "rooms" && key !== "dba" && key !== "revenue") {
                val = Number.isInteger(val) ? val.toFixed(2) : val;
            }
            if (type === "") {
                if (key === "date") {
                    value = moment(value).format("MMM DD");
                } else if (key === "dow" || key === "date_market_summary" || key === "month" || key === "dba") {
                    value = value;
                } else if (key === "occ" || key === "tcocc") {
                    value = parseFloat(value).toFixed(2) + "%";
                } else if ((key === "adr" || key === "revpar" || key === "sellrate") && value !== "") {
                    // if(key === "sellrate"){
                    //     console.log(key," <==> ",value," => ", typeof value);
                    // }
                    if (typeof value !== "string" || key === "sellrate" || key === "adr" || key === "revpar") {
                        value = (!isFinite(value)) ? parseFloat(0.00).toFixed(2) : parseFloat(value).toFixed(2);
                        value = currSymbol + (value).toLocaleString(undefined, { minimumFractionDigits: 2 });
                    } else {
                        value = value;
                    }

                } else if (key === "revenue") {
                    if (typeof value === "string") {
                        currSymbol = "";
                    }
                    value = currSymbol + (value).toLocaleString(undefined, { minimumFractionDigits: 2 });

                } else if (key === "rooms") {
                    value = value.toLocaleString();
                } else {
                    value = currSymbol + value;
                }
            } else if (type !== "" && typeof value !== "string") {
                value = value + "%";
            }
            if (key !== "rooms" && key !== "dba") {

                if (value >= 0 || typeof value === "string") {
                    value = value;
                } else {
                    if (Number.isFinite(value)) {
                        value = "(" + value.toString().replaceAll("-", "") + ")";
                    } else {
                        value = currSymbol + parseFloat(0.00).toFixed(2);
                    }
                }

            }
        }
        if (key === 'segment') {
            value = truncate(value, 17);
        }
        if (key === 'compdate') {
            // let compvalue =  dateFormat(value, "MMM DD, YYYY ddd");
            value = dateFormat(value, "MMM DD YYYY,  ddd");
            // debugger;
        }
        return value;
    };

    const calculateColWidth = (tile) => {
        let colWidth = [];
        let dataColumn = (tile === "compset") ? 20 : 12;
        for (let i = 1; i <= data_cols.length; i++) {
            if (i <= 2) {
                colWidth.push(10);
            } else {
                colWidth.push(dataColumn)
            }
        }
        return (colWidth.toString()).trim();
    }
    let tableContClass = "table-scrollable ";
    if (workspace_controls.isRestrictionsRoomType === true) {
        tableContClass += "room-type-restrictions"
    } else {
        tableContClass += "houselevel-restrictions"
    }

    return (<Paper sx={{ width: "100%" }} elevation={0}>
        <TableContainer className={tableContClass} sx={{ overflow: 'auto' }}>
            <Table
                stickyHeader
                size="small"
                aria-label="a dense table"
                className={active_tiles_className + " react-dynamic-table"}
                id="mainTable"
                data-cols-width={calculateColWidth(active_tiles[0])}
            >
                {renderHeader()}
                <tbody className="table-body">
                    {
                        (compLoadStatus !== 'loading' && compLoad && displayData.length && data_setup.length) ? renderBody() : ''
                    }
                </tbody>
            </Table>
        </TableContainer>
    </Paper>)



}

export default TableRestrictions;
