import "./style.css";
import {
    ArrowDropDown as ArrowDropDownIcon,
    ArrowDropUp as ArrowDropUpIcon,
    ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon,
    Delete as DeleteIcon,
    Difference as CopyRuleIcon,
    PlaylistAdd as AddNewIcon,
    KeyboardArrowDownOutlined as KeyboardArrowDownOutlinedIcon,
    KeyboardArrowUpOutlined as KeyboardArrowUpOutlinedIcon,
    KeyboardArrowDownOutlined as KeyboardArrowDownOutlined,
    DeleteOutlineOutlinedIcon as DeleteOutlineOutlined,


} from "@mui/icons-material/";
import {

    UserConfirmationAlert,
} from "./comp.static";
import {
    Autocomplete,
    Alert,
    Badge,
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Chip,
    CircularProgress,
    Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material/";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { MobileDatePicker } from "@mui/x-date-pickers/";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useRef, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { APP_CACHE, NEW_PROPERTY_SYSTEM_SETTINGS } from "../../app/config";
import { updateAppState } from '../../actions/appstate';
import { SYSTEM_SETTINGS_UPDATED } from "../../actions/types";
import { dateFormat, deepCopy, getDataSources, toInteger, cleanIDB } from "../../app/util";
import { API_REGISTRY } from "../../app/config";
import dataService, { fetchDataSimple } from "../../services/data.service";

import { color } from "highcharts";
import { border, lineHeight } from "@mui/system";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { pt } from "date-fns/locale";

import Settings from "./index";
import { DecisionResyncSettings } from "./DecisionResyncSettings";
import { useLocation, useNavigate } from "react-router-dom";
import { clearAPIRegistryData } from "../../app/util";
import DataService from "../../services/data.service";

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Import the default styles
import 'react-date-range/dist/theme/default.css'; // Import the default theme
const unsavedAlertMsg = "Please save your changes to continume";

function InventorySettings(props) {
    const dispatch = useDispatch();

    const [isShakeButton, setIsShakeButton] = useState(false);

    const [isrequesting, setIsRequesting] = useState(false);
    // const count = useRef(0);
    // useEffect(() => {
    //     count.current = count.current + 1;
    //   },[]);
    //console.log("count.current", count.current);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for inventory settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    // debugger;
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });

    let { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;

    const inventory_settings_ = JSON.parse(
        JSON.stringify(system_settings[0].inventory_settings)
    );

    inventory_settings_.current.external_systems[0].system_info = inventory_settings_.current.external_systems[0].system_info || " ";

    //Adding property_current_total_capacity
    inventory_settings_.current.property_current_total_capacity = system_settings[0].property_current_total_capacity;

    const pricing_settings_ = JSON.parse(
        JSON.stringify(system_settings[0].pricing_settings)
    );

    const inventory_settings = { ...inventory_settings_ };
    //const {status, system_name} = inventory_settings;
    const [requestData, setRequestData] = useState(inventory_settings);

    // const [requestPriceSettings, setRequestPriceSettings] = useState(pricing_settings_);
    const compRef = useRef({ requestPriceSettings: pricing_settings_ });

    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const [roomSettingsOld, setRoomSettingsOld] = useState(JSON.parse(JSON.stringify(requestData.current.room_settings))); //taking a backup of Old Array
    const [roomSettings, setRoomSettings] = useState(requestData.current.room_settings);

    const newRowRef = useRef(null);
    const containerRef = useRef(null);

    console.log('roomSettingsOld', roomSettingsOld);
    console.log('roomSettingsNew', roomSettings);

    const cancelSave = () => {
        setRoomSettings(JSON.parse(JSON.stringify(roomSettingsOld)));
        setRequestData(inventory_settings_);
        system_settings[0].pricing_settings = pricing_settings_;
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        // debugger;
        const updatedObj = { ...requestData };
        updatedObj.current.room_settings = roomSettings;
        const key = keys.split(">");
        switch (key[0]) {
            case "status":
                updatedObj.status = val;
                //setRequestData({...requestData, status: val});
                break;

            case "system_name":
            case "system_info":
            case "system_code":
            case "integration_code":

                updatedObj.current.external_systems[0][key] = val;

                break;
            case "total_capacity":
                updatedObj.current.total_capacity = val;
                break;
            case "property_current_total_capacity":
                updatedObj.current.property_current_total_capacity = val;
                break;
            case "room_settings":
                // debugger;
                if (key[1] === 'is_base') {
                    let old = updatedObj.current.room_settings.filter((itm, i) => {
                        if (itm.is_base) {
                            updatedObj.current.room_settings[i].is_base = false;
                            return true;
                        }
                    });

                    updatedObj.current.room_settings[index][key[1]] = true;
                } else {
                    // debugger;
                    updatedObj.current.room_settings[index][key[1]] = val;
                    if (key[1] === 'room_code') {
                        updatedObj.current.room_settings[index]["room_codes"]['pms'] = [val];
                    }
                    if (key[1] === "display_name") {
                        let old_room_lodgiq_code = inventory_settings_.current.room_settings[index] ? inventory_settings_.current.room_settings[index].room_lodgiq_code : '';
                        let { is_base } = inventory_settings_.current.room_settings[index] ? inventory_settings_.current.room_settings[index] : { is_base: false };
                        let new_room_lodgiq_code = val.replace(/[^a-zA-Z0-9|]/gi, '');

                        let originalPriceSettings = deepCopy(pricing_settings_);

                        // let rps = compRef.current.requestPriceSettings;
                        // debugger;

                        // let room_lodgiq_code = val.split(' ').join('');
                        let exists = updatedObj.current.room_settings.filter(item => item.room_lodgiq_code === new_room_lodgiq_code);
                        new_room_lodgiq_code = exists.length ? new_room_lodgiq_code + exists.length : new_room_lodgiq_code;
                        updatedObj.current.room_settings[index]["room_lodgiq_code"] = new_room_lodgiq_code;

                        originalPriceSettings.room_dow_plans.map((item, i) => {
                            let plan = old_room_lodgiq_code ? item.room_plans[old_room_lodgiq_code] : null;
                            if (plan) {
                                if (is_base) {
                                    originalPriceSettings.room_dow_plans[i].base_room = new_room_lodgiq_code;
                                    // originalPriceSettings.room_dow_plans[i].base_rate_code = ?
                                }
                                originalPriceSettings.room_dow_plans[i].room_plans[new_room_lodgiq_code] = plan;
                                delete item.room_plans[old_room_lodgiq_code];
                                compRef.current.requestPriceSettings = originalPriceSettings;
                            } else {
                                if (is_base) {
                                    originalPriceSettings.room_dow_plans[i].base_room = new_room_lodgiq_code;
                                    // originalPriceSettings.room_dow_plans[i].base_rate_code = ?
                                }
                                originalPriceSettings.room_dow_plans[i].room_plans[new_room_lodgiq_code] = {
                                    "Sun": 0,
                                    "Mon": 0,
                                    "Tue": 0,
                                    "Wed": 0,
                                    "Thu": 0,
                                    "Fri": 0,
                                    "Sat": 0
                                };
                                compRef.current.requestPriceSettings = originalPriceSettings;
                            }
                        });
                    }
                }

                break;


            default:
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };
    const deleteRoomType = (index) => {
        //debugger;
        //requestData.current.room_settings.splice(index, 1);
        //roomSettings.splice(index, 1);
        let { room_lodgiq_code } = deepCopy(roomSettings[index]);
        compRef.current.requestPriceSettings.room_dow_plans.map((plan, i) => {
            delete compRef.current.requestPriceSettings.room_dow_plans[i].room_plans[room_lodgiq_code];
        });
        // compRef.current.requestPriceSettings = originalPriceSettings;
        roomSettings.splice(index, 1);
        setRequestData({ ...requestData, current: requestData.current });
        setIsChangeRequest(true);
    };
    const changeRoomTypeOrder = (item, index, type) => {
        //debugger;
        const tempObj = item;
        const size = requestData.current.room_settings.length;
        if (type == "up" && index) {
            requestData.current.room_settings[index] = requestData.current.room_settings[index - 1];
            requestData.current.room_settings[index - 1] = item;
            setRequestData({ ...requestData, current: requestData.current });
            setIsChangeRequest(true);
        }
        if (type == "down" && index < size) {
            requestData.current.room_settings[index] = requestData.current.room_settings[index + 1];
            requestData.current.room_settings[index + 1] = item;
            setRequestData({ ...requestData, current: requestData.current });
            setIsChangeRequest(true);
        }
    };
    const addNewRoomType = (e) => {
        const new_room_type = {
            room_code: "",
            display_name: "",
            room_lodgiq_code: "",
            max_rate: 0,
            crs_room_code: "",
            room_class: "Standard",
            max_diff: 0,
            min_rate: 0,
            inventory: 0,
            room_codes: {
                pms: [],
            },
            new: false,
            min_diff: 0,
            standard_room_category: "default",
            ada_codes: [],
            room_type_proxy: "",
            is_base: false,
            rate_push_enabled: false,
        };
        //requestData.current.room_settings.push(new_room_type);
        roomSettings.push(new_room_type);
        setRequestData({ ...requestData, current: requestData.current });
        setIsChangeRequest(true);

        // Scroll to the bottom of the container after adding the new row
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };
    const saveChanges = (event) => {
        setIsRequesting(true);

        let { room_dow_plans } = compRef.current.requestPriceSettings;

        room_dow_plans.map((plan, i) => {
            let new_plans = {};
            requestData.current.room_settings.map((room_settings) => {
                let { room_lodgiq_code } = room_settings;

                if (!plan.room_plans[room_lodgiq_code]) {
                    new_plans[room_lodgiq_code] = {
                        "Sun": 0,
                        "Mon": 0,
                        "Tue": 0,
                        "Wed": 0,
                        "Thu": 0,
                        "Fri": 0,
                        "Sat": 0
                    };
                }
                if (plan.room_plans[room_lodgiq_code]) {
                    new_plans[room_lodgiq_code] = plan.room_plans[room_lodgiq_code];
                }

            });
            compRef.current.requestPriceSettings.room_dow_plans[i].room_plans = new_plans;
        });

        const request_system_settings = {
            ...system_settings[0],
            inventory_settings: requestData,
            pricing_settings: compRef.current.requestPriceSettings
        };
        // debugger;
        //Handling total_capacity and property_current_total_capacity
        request_system_settings.property_current_total_capacity = Number(request_system_settings.inventory_settings.current.property_current_total_capacity);
        delete request_system_settings.inventory_settings.current.property_current_total_capacity;
        let total_capacity_arr = request_system_settings.inventory_settings.current.room_settings.map(item => Number(item.inventory));
        total_capacity_arr = total_capacity_arr.length ? total_capacity_arr : [0];
        request_system_settings.inventory_settings.current.total_capacity = total_capacity_arr.reduce((total, inventory) => {
            return total + inventory;
        });
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            },
            // action: "settings",
            // method: "save_system_settings",
            // resourceId: app_state.property_id,
            // resourceType: "property",
            // update_type: "",
            // user_id: user.user_id,
            // token_string: user.token_string,
            // system_settings: request_system_settings,
        };
        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';
        // debugger;
        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        const updatedProfile = deepCopy(profile);
                        updatedProfile.property_details[
                            prop_details_index
                        ].system_settings[0] = request_system_settings;
                        dispatch({
                            type: SYSTEM_SETTINGS_UPDATED,
                            payload: updatedProfile,
                        });
                    }, 500);

                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const updatedRoomSettings = Array.from(roomSettings);
        const [movedItem] = updatedRoomSettings.splice(result.source.index, 1);
        updatedRoomSettings.splice(result.destination.index, 0, movedItem);

        setRoomSettings(updatedRoomSettings);

        requestData.current.room_settings = updatedRoomSettings;
        setRequestData({ ...requestData, current: requestData.current });
        setIsChangeRequest(true);
    };

    //console.log('BEFORErequestData', requestData.current.room_settings);

    //console.log(system_settings[0]);
    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        //reverting changes back
        setRoomSettings(JSON.parse(JSON.stringify(roomSettingsOld)));
        setRequestData(inventory_settings_);
        setIsChangeRequest(false);

        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }
    const customDropdownIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="#606060"
            viewBox="0 0 256 256"
            style={{ marginRight: "10px" }}
        >
            <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
        </svg>
    );
    const customDropdownIcon2 = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="#606060"
            viewBox="0 0 256 256"
            style={{ marginRight: "10px" }}
        >
            <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
        </svg>
    );

    return (
        <div style={{ position: 'relative' }}>

            <Box className='top-axn-bar-1'
            >
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }

                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>

                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Inventory</span>
                </Box>
            </Box>

            <Box className="bgwhite main-scroll-wrap-inventory" sx={{ pl: 2, pr: 2 }}>
                <div className='profile-heading' style={{ paddingBottom: '5px' }}>
                    INVENTORY
                    <Box sx={{ display: "inline-block", ml: 0 }}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue="room_level"
                            value={requestData.status}
                            onChange={(e) => {
                                const value = e.target.value;
                                // setRequestData({...requestData, status:value})
                                updateRequestData("status", value);
                            }}
                            sx={{ position: "relative", top: 3, mb: 0 }}
                            className="radio-group"
                        >
                            <FormControlLabel
                                value="total_only"
                                control={<Radio size="small" />}
                                label="Total Only"
                                sx={{ ml: 1 }}
                            />
                            <FormControlLabel
                                value="room_level"
                                control={<Radio size="small" />}
                                label="Room Level"
                                sx={{ ml: 1 }}
                            />
                        </RadioGroup>
                    </Box>
                </div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>


                <div className="profile-setting-section">
                    <Box className="total-only-container">
                        <FormControl className="textfield2" size="small">
                            <InputLabel>SYSTEM TYPE</InputLabel>
                            <Select
                                label="SYSTEM TYPE"
                                sx={{ color: '#797979' }}
                                value={requestData.current.external_systems[0].system_name}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    requestData.current.external_systems[0].system_name = value;
                                    updateRequestData("system_name", value);
                                }}
                                IconComponent={() => customDropdownIcon}
                            >
                                <MenuItem value="CRS">CRS</MenuItem>
                                <MenuItem value="PMS">PMS</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className="textfield2" size="small">
                            <InputLabel>SYSTEM INFO</InputLabel>
                            <OutlinedInput
                                value={requestData.current.external_systems[0].system_info}
                                onChange={(e) => {
                                    updateRequestData("system_info", e.currentTarget.value);
                                }}
                                label="SYSTEM IFNO"
                            />
                        </FormControl>
                        <FormControl className="textfield2" size="small">
                            <InputLabel>RATE PUSH VENDOR</InputLabel>
                            <OutlinedInput className="input-text"
                                label="RATE PUSH VENDOR"
                                value={requestData.current.external_systems[0].system_code}
                                onChange={(e) => {
                                    updateRequestData("system_code", e.currentTarget.value);
                                }}
                            />
                        </FormControl>
                        <FormControl className="textfield2" size="small">
                            <InputLabel>VENDOR HOTEL ID</InputLabel>
                            <OutlinedInput className="input-text"
                                label="VENDOR HOTEL ID"
                                value={
                                    requestData.current.external_systems[0].integration_code
                                }
                                onChange={(e) => {
                                    updateRequestData(
                                        "integration_code",
                                        e.currentTarget.value
                                    );
                                }}
                            />
                        </FormControl>
                        <FormControl className="textfield2" size="small">
                            <InputLabel>TOTAL # OF ROOMS</InputLabel>
                            <OutlinedInput className="input-text"
                                label="TOTAL # OF ROOMS"
                                value={requestData.current.property_current_total_capacity
                                }
                                onChange={(e) => {
                                    updateRequestData("property_current_total_capacity", e.currentTarget.value);
                                }}
                            />
                        </FormControl>
                    </Box>
                </div>

                <Box className="inventory-main-comtainer"
                    sx={{
                        mt: { lg: 1 },
                        display: requestData.status == "room_level" ? "block" : "none",
                    }}
                >
                    <Box className="room-type-table">

                        <Grid className="room-type-details head"
                            container
                            spacing={0}
                        // sx={{pl: '16px'}}
                        >
                            <Grid item className="inventory-row-head">

                            </Grid>
                            <Grid item className="inventory-row-head">
                                ROOM TYPE DESCRIPTION
                            </Grid>
                            <Grid item className="inventory-row-head">
                                PMS ROOM TYPE CODE
                            </Grid>
                            <Grid item className="inventory-row-head">
                                CRS ROOM TYPE CODE
                            </Grid>
                            <Grid item className="inventory-row-head">
                                ROOM CLASS
                            </Grid>
                            <Grid item className="inventory-row-head">
                                COUNT
                            </Grid>
                            <Grid item className="inventory-row-head" >
                                MIN RATE
                            </Grid>
                            <Grid item className="inventory-row-head" >
                                MAX RATE
                            </Grid>
                            <Grid item className="inventory-row-head" sx={{ textAlign: 'center' }} >
                                BASE
                            </Grid>
                            <Grid item className="inventory-row-head" >
                                MATCH RATE TO
                            </Grid>
                            <Grid item className="inventory-row-head">
                                RATE PUSH ENABLED
                            </Grid>
                            <Grid item className="inventory-row-head">

                            </Grid>
                        </Grid>

                        <Box
                            className="inventory-room-list-data"
                            sx={{ scrollBehavior: 'smooth' }}
                            ref={containerRef} // Set the ref for the container
                        // Add a max height and scrolling to the container
                        >

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="room-settings-list" direction="vertical">
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {roomSettings.map((item, index) => (
                                                <Draggable
                                                    key={"roomsettings-" + index}
                                                    draggableId={"roomsettings-" + index}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div className="inventory-draggable-row"
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <Tooltip title="Drag and Drop" arrow followCursor >
                                                                <Grid item md={0.3} className="room-type-details-col" style={{ pr: '10px' }}>
                                                                    <svg className="draggable-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000000" viewBox="0 0 256 256"><path d="M104,60A12,12,0,1,1,92,48,12,12,0,0,1,104,60Zm60,12a12,12,0,1,0-12-12A12,12,0,0,0,164,72ZM92,116a12,12,0,1,0,12,12A12,12,0,0,0,92,116Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,116ZM92,184a12,12,0,1,0,12,12A12,12,0,0,0,92,184Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,184Z"></path></svg>
                                                                </Grid>
                                                            </Tooltip>


                                                            <Grid item md={2} className="room-type-details-col">
                                                                <FormControl fullWidth size="small" className="textfield">
                                                                    <OutlinedInput
                                                                        className="field"
                                                                        // sx={{ fontSize: 12 }}
                                                                        value={item.display_name}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "room_settings>display_name",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>


                                                            <Grid item md={1} className="room-type-details-col">
                                                                <FormControl size="small" sx={{}}>
                                                                    <OutlinedInput
                                                                        className="field code textfield"
                                                                        value={item.room_code}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "room_settings>room_code",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>


                                                            <Grid item md={1} className="room-type-details-col">
                                                                <FormControl size="small" sx={{}}>
                                                                    <OutlinedInput
                                                                        className="field code textfield"
                                                                        value={item.crs_room_code}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "room_settings>crs_room_code",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>


                                                            <Grid item md={2} className="room-type-details-col">
                                                                <FormControl size="small" sx={{ width: "100%" }}>
                                                                    <Select
                                                                        className="field textfield"
                                                                        sx={{ width: "100%" }}
                                                                        value={item.room_class}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "room_settings>room_class",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                        IconComponent={() => customDropdownIcon2}
                                                                    >
                                                                        <MenuItem value="Standard">Standard</MenuItem>
                                                                        <MenuItem value="Superior">Superior</MenuItem>
                                                                        <MenuItem value="Deluxe">Deluxe</MenuItem>
                                                                        <MenuItem value="Accessible">Accessible</MenuItem>
                                                                        <MenuItem value="Jr-Suite">Jr-Suite</MenuItem>
                                                                        <MenuItem value="Suite">Suite</MenuItem>
                                                                        <MenuItem value="Suites">Suites</MenuItem>
                                                                        <MenuItem value="Specialty">Specialty</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={1} className="room-type-details-col">
                                                                <FormControl size="small" sx={{}}>
                                                                    <OutlinedInput
                                                                        className="field number textfield"
                                                                        value={item.inventory}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            if (!isNaN(value)) {
                                                                                value = parseInt(value);
                                                                                updateRequestData(
                                                                                    "room_settings>inventory",
                                                                                    value,
                                                                                    index
                                                                                );
                                                                            }

                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>


                                                            <Grid item md={1} className="room-type-details-col">
                                                                <FormControl size="small" sx={{}}>
                                                                    <OutlinedInput
                                                                        className="field textfield"
                                                                        value={item.min_rate}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            if (!isNaN(value)) {
                                                                                value = parseInt(value);
                                                                                updateRequestData(
                                                                                    "room_settings>min_rate",
                                                                                    value,
                                                                                    index
                                                                                );
                                                                            }

                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>


                                                            <Grid item md={1} className="room-type-details-col">
                                                                <FormControl size="small" sx={{}}>
                                                                    <OutlinedInput
                                                                        className="field textfield"
                                                                        value={item.max_rate}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            let value = e.target.value;
                                                                            if (!isNaN(value)) {
                                                                                value = parseInt(value);
                                                                                updateRequestData(
                                                                                    "room_settings>max_rate",
                                                                                    value,
                                                                                    index
                                                                                );
                                                                            }

                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>


                                                            <Grid item md={0.5} className="room-type-details-col"
                                                                sx={{ textAlign: 'center' }}
                                                            >
                                                                <Checkbox
                                                                    className="colored-checkbox"
                                                                    checked={item.is_base}
                                                                    name="is_base"
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        item.is_base = !item.is_base;
                                                                        updateRequestData(
                                                                            "room_settings>is_base",
                                                                            item.is_base,
                                                                            index
                                                                        );
                                                                    }}
                                                                />
                                                            </Grid>


                                                            <Grid item md={1} className="room-type-details-col">
                                                                <FormControl size="small" sx={{}}>
                                                                    <OutlinedInput
                                                                        className="field textfield"
                                                                        placeholder="PMS Code"
                                                                        value={item.room_type_proxy}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "room_settings>room_type_proxy",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>


                                                            <Grid item md={0.5} className="room-type-details-col"
                                                                alignContent="stretch"
                                                                sx={{ textAlign: 'center' }}
                                                            >
                                                                <Checkbox
                                                                    checked={item.rate_push_enabled}
                                                                    value={true}
                                                                    className="colored-checkbox"
                                                                    name="rate_push_enabled"
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        item.rate_push_enabled = !item.rate_push_enabled;
                                                                        updateRequestData(
                                                                            "room_settings>rate_push_enabled",
                                                                            item.rate_push_enabled,
                                                                            index
                                                                        );
                                                                    }}
                                                                />
                                                            </Grid>


                                                            <Grid item md={0.5} className="room-type-details-col"
                                                            // style={{textAlign: 'right'}}
                                                            >
                                                                <Box display="flex" spacing={1} sx={{ float: "right" }}>
                                                                    {/* <Button
                                                                        onClick={() => {
                                                                            changeRoomTypeOrder(item, index, "down");
                                                                        }}
                                                                        size="large"
                                                                        sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                                        disabled={
                                                                            index ===
                                                                            requestData.current.room_settings.length - 1
                                                                        }
                                                                    >


                                                                        <KeyboardArrowDownOutlined fontSize="small"></KeyboardArrowDownOutlined>
                                                                    </Button>
                                                                    <Button
                                                                        onClick={() => {
                                                                            changeRoomTypeOrder(item, index, "up");
                                                                        }}
                                                                        size="small"
                                                                        sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                                        disabled={index === 0}
                                                                    >
                                                                        <KeyboardArrowUpOutlinedIcon fontSize="small"></KeyboardArrowUpOutlinedIcon>
                                                                    </Button> */}
                                                                    <Button
                                                                        variant=""
                                                                        size="small"
                                                                        sx={{ minWidth: "auto", p: '4px', lineHeight: "auto", borderRadius: '50px' }}
                                                                        onClick={() => {
                                                                            deleteRoomType(index);
                                                                        }}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#E03131" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>

                                                                    </Button>
                                                                </Box>
                                                            </Grid>


                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Box>

                    </Box>


                    <Button
                        variant="outlined"
                        className="add-button"
                        size="small"
                        sx={{ lineHeight: "auto" }}
                        onClick={() => {
                            addNewRoomType();
                        }}
                    >
                        <span className="add-btn-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#333333" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>
                        </span>
                        <span className="add-btn-txt">Add Room Type</span>
                    </Button>

                </Box>


                {isrequesting && (
                    <Box
                        sx={{
                            textAlighn: "center",
                            backgroundColor: "dark.darkest",
                            opacity: 0.8,
                            position: "fixed",
                            top: 40,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: 100,
                        }}
                    >
                        <CircularProgress
                            size={40}
                            sx={{
                                color: "white",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-20px",
                                marginLeft: "-20px",
                            }}
                        />
                    </Box>
                )}

            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}
        </div>
    );
}

function CompsetSettings(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for compset settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));

    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const { compsetSettings, vendor_integation_mappings } = JSON.parse(
        JSON.stringify(system_settings[0])
    );
    const compsetSettings_ = JSON.parse(
        JSON.stringify(system_settings[0].compsetSettings)
    );
    const vendor_integation_mappings_ = JSON.parse(
        JSON.stringify(system_settings[0].vendor_integation_mappings)
    );
    //debugger;
    if (compsetSettings.source
        && vendor_integation_mappings.compset[compsetSettings.source]
        && vendor_integation_mappings.compset[compsetSettings.source].load_schedule) {
        vendor_integation_mappings.compset[compsetSettings.source].load_schedule =
            vendor_integation_mappings.compset[compsetSettings.source].load_schedule
                ? vendor_integation_mappings.compset[compsetSettings.source].load_schedule
                : {};
    }

    const [requestData, setRequestData] = useState({
        compsetSettings: compsetSettings,
        vendor_integation_mappings: vendor_integation_mappings,
    });
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const cancelSave = () => {
        setRequestData({
            compsetSettings: compsetSettings_,
            vendor_integation_mappings: vendor_integation_mappings_,
        });
        setIsChangeRequest(false);
        //Setting this ss_update variable to be used  in prop change confirmation 
        dispatch(updateAppState('settingStatus', {
            app_state: {
                ...app_state,
                ss_updated: false
            },
            ui_state: { ...ui_state }
        }, "settingStatus"));
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        // debugger;
        switch (key[0]) {
            case "source":
                updatedObj.compsetSettings[key[0]] = val;
                //We will make this active in case we need source other than ata_i_api
                // if (!updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source]) {
                //   updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source] = {
                //     "subscription_id": "",
                //     "channel": "",
                //     "hotel_id": "",
                //     "load_schedule": {
                //       "data_repull_interval_minutes": "",
                //       "from": "",
                //       "until": "",
                //       "timezone": ""
                //     },
                //     "is_bar": false
                //   }
                // }

                break;
            case "compsetSettings":
                // updatedObj.compsetSettings[key[1]] = val;
                if (key[1] == "compset_filter") {
                    let size = val.length;
                    updatedObj.compsetSettings[key[1]] = updatedObj.compsetSettings[
                        key[1]
                    ]
                        ? updatedObj.compsetSettings[key[1]]
                        : [];
                    let l = updatedObj.compsetSettings[key[1]].length;
                    updatedObj.compsetSettings[key[1]].splice(0, l);
                    val.map((item, i) => {
                        let compsetId = Number(item);
                        if (!isNaN(compsetId)) {
                            updatedObj.compsetSettings[key[1]].push(compsetId);
                        } else {
                            //updatedObj.compsetSettings[key[1]].push(compsetId);
                        }

                    });
                } else {
                    updatedObj.compsetSettings[key[1]] = val;
                }
                break;

            case "vendor_integation_mappings":
                // debugger;
                if (key[1] === "load_schedule") {
                    if (!updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source][key[1]]) {
                        updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source][key[1]] = {};
                    }
                    updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source][key[1]][key[2]] = val;

                }
                else if (key[1] == "is_bar" && updatedObj.vendor_integation_mappings.compset[updatedObj.compsetSettings.source]
                [key[1]] === 'on') {
                    updatedObj.vendor_integation_mappings.compset[
                        updatedObj.compsetSettings.source
                    ][key[1]] = '';
                } else if (key[1] !== "load_schedule") {
                    updatedObj.vendor_integation_mappings.compset[
                        updatedObj.compsetSettings.source
                    ][key[1]] = val;
                }

                break;

            default:
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
        updateAppState('settingStatus', {
            app_state: {
                ...app_state,
                ss_updated: true
            },
            ui_state: { ...ui_state }
        }, "settingStatus");

    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        let vimc = requestData.vendor_integation_mappings.compset.ota_i_api;
        delete vimc.data_repull_interval_minutes;
    
        /** LRV-814  if hotel id and subscription id is less then o then we need to send blank string. 0 is not allowed */
        vimc.subscription_id = (Number(vimc.subscription_id) > 0) ? Number(vimc.subscription_id) : '';
        vimc.hotel_id = (Number(vimc.hotel_id)) ? Number(vimc.hotel_id) : '';
        // return false;

        let data_repull_interval_minutes_value = (vimc && vimc.load_schedule && vimc.load_schedule.data_repull_interval_minutes) ? Number(vimc.load_schedule.data_repull_interval_minutes) : 0;
        if (vimc && vimc.load_schedule) {
            vimc.load_schedule.data_repull_interval_minutes = data_repull_interval_minutes_value;
        }
        // else {
        //     vimc['load_schedule'] = {
        //         'data_repull_interval_minutes': null
        //     }
        // }

        // vimc.load_schedule.data_repull_interval_minutes = Number(vimc.load_schedule.data_repull_interval_minutes);
        let compResource_id = (requestData.compsetSettings && requestData.compsetSettings.resource_id) ? Number(requestData.compsetSettings.resource_id) : '';
        let request_system_settings = {
            ...system_settings[0],
            compsetSettings: {
                ...requestData.compsetSettings, resource_id: compResource_id
            },
            vendor_integation_mappings: requestData.vendor_integation_mappings,
        };
        //  request_system_settings = { compsetSettings: {
        //             resource_id: compResource_id
        //         },}
        //let  compset_filter = request_system_settings.compsetSettings.compset_filter.split(',');
        //request_system_settings.compsetSettings.compset_filter = []
        // compset_filter.map((item, index)=>{
        //   //request_system_settings.compsetSettings.compset_filter.push(Number(item));
        // });

        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';
        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        const updatedProfile = deepCopy(profile);
                        updatedProfile.property_details[
                            prop_details_index
                        ].system_settings[0] = request_system_settings;
                        dispatch({
                            type: SYSTEM_SETTINGS_UPDATED,
                            payload: updatedProfile,
                        });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    //console.log(requestData);
    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }

    return (
        <div>
            {/* <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>

                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Compset</span>

            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Compset
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div> */}

            <Box className='top-axn-bar-1'>
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }


                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>

                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Compset</span>
                </Box>
            </Box>

            <Box className="bgwhite main-scroll-wrap" sx={{ pl: 2, pr: 2 }}>

                <div className='profile-heading' style={{ paddingBottom: '5px' }}>COMPSET</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>

                <Box
                    sx={{
                        pt: 2,
                        flexDirection: "column",
                        position: "relative",
                        display: "flex",
                    }}
                >
                    {/* <Alert
                        severity="success"
                        sx={{
                            position: "absolute",
                            zIndex: 100,
                            width: "100%",
                            marginTop: -10,
                            display: isRequestSuccess ? "flex" : "none",
                        }}
                    >
                        {requestSuccessMsg}
                    </Alert>
                    <Alert
                        severity="error"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestError ? "flex" : "none",
                        }}
                    >
                        {resErrorMsg}
                    </Alert> */}

                    <Box>
                        {/* className="property-setting-container-spacing" */}
                        <Grid container spacing={2} sx={{ fontSize: 10 }}>
                            <Grid container spacing={0}>
                                <Grid className="seperate-section-compset" item xs={12}>

                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                                        {/* <Grid item xs={12}>
                                            <Typography
                                                className="pd-bottom"
                                                gutterBottom
                                                sx={{ fontSize: 14, paddingBottom: "8px" }}
                                            >
                                                COMPSET SETTINGS
                                            </Typography>
                                            
                                        </Grid> */}
                                        <Grid container spacing={2} item xs={6}>
                                            <Grid item lg={12}>
                                                <FormControl className="textfield" fullWidth size="small">
                                                    <InputLabel>RATE SOURCE</InputLabel>
                                                    <OutlinedInput
                                                        className="input-text"
                                                        value={requestData.compsetSettings.source}
                                                        onChange={(e) => {
                                                            updateRequestData("source", e.currentTarget.value);
                                                        }}
                                                        label="RATE SOURCE"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <FormControl className="textfield" size="small" fullWidth>
                                                    <InputLabel>SHOP ID</InputLabel>
                                                    <OutlinedInput
                                                        className="input-text"
                                                        label="SHOP ID"
                                                        value={requestData.compsetSettings.resource_id}
                                                        onChange={(e) => {
                                                            updateRequestData(
                                                                "compsetSettings>resource_id",
                                                                e.currentTarget.value
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={12}>
                                                <FormControl className="textfield" fullWidth size="small">
                                                    <InputLabel>RESOURCE SCOPE</InputLabel>
                                                    <OutlinedInput
                                                        className="input-text"
                                                        label="RESOURCE SCOPE"
                                                        value={requestData.compsetSettings.resource_scope}
                                                        onChange={(e) => {
                                                            updateRequestData(
                                                                "compsetSettings>resource_scope",
                                                                e.currentTarget.value
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <Grid item lg={12}>
                                                <FormControl className="textfield" fullWidth size="small">
                                                    {/* <InputLabel>COMPSET FILTER</InputLabel> */}

                                                    <Autocomplete
                                                        // size="Extra"
                                                        className="fieldset-size"
                                                        multiple
                                                        options={[]}
                                                        value={requestData.compsetSettings.compset_filter}
                                                        freeSolo

                                                        onChange={(e, newVal) => {
                                                            //item.recipients;
                                                            updateRequestData(
                                                                "compsetSettings>compset_filter",
                                                                newVal
                                                            );
                                                        }}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (
                                                                <div className="compset-filter" sx={{ minHeight: '300px' }}>
                                                                    <Chip
                                                                        key={"id-" + index}
                                                                        size="small"

                                                                        variant="outlined"
                                                                        // color="primary"
                                                                        label={option}
                                                                        {...getTagProps({ index })}
                                                                        className="property-schedule-chip"
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField
                                                                className="textfield"
                                                                {...params}
                                                                size="small"
                                                                variant="outlined"
                                                                label="COMPSET FILTER"
                                                                placeholder="Type compset ID and press Enter"
                                                            />
                                                        )}
                                                    />

                                                    {/* <TextField
                    helperText="Enter comma seprated property IDs"
                    label="COMPSET FILTER"
                    size="small"
                    value={requestData.compsetSettings.compset_filter}
                    onChange={(e) => {
                      updateRequestData(
                        "compsetSettings>compset_filter",
                        e.currentTarget.value
                      );
                    }}
                  /> */}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>
                            {requestData.compsetSettings.source && requestData.vendor_integation_mappings.compset[requestData.compsetSettings.source] &&
                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            gutterBottom
                                            sx={{ fontSize: 14 }}
                                        >
                                            VENDOR INTEGRATION MAPPING
                                        </Typography>
                                        {/* <Divider sx={{clear:'both'}} /> */}
                                    </Grid>

                                    <Grid item lg={6}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>HOTEL ID</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="HOTEL ID"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].hotel_id
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>hotel_id",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                        {/* <FormControl className="textfield" fullWidth size="small">
                  <OutlinedInput
                    className="input-text"
                    label="HOTEL ID"
                    value={
                      requestData.vendor_integation_mappings.compset[
                        requestData.compsetSettings.source
                      ].hotel_id
                    }
                    onChange={(e) => {
                      updateRequestData(
                        "vendor_integation_mappings>hotel_id",
                        e.currentTarget.value
                      );
                    }}
                  />
                </FormControl> */}
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>SUBSCRIPTION ID</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="SUBSCRIPTION ID"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].subscription_id
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>subscription_id",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>NUMBER OF GUESTS</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="NUMBER OF GUESTS"
                                                type="number"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].guests
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>guests",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={6}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>LENGTH OF STAY</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="LENGTH OF STAY"
                                                type="number"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].length_of_stay
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>length_of_stay",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>CHANNEL</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="CHANNEL"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].channel
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>channel",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6}>
                                        <FormControlLabel className="textfield input-text"
                                            control={
                                                <Checkbox
                                                    className="colored-checkbox"
                                                    checked={requestData.compsetSettings.source &&
                                                        requestData.vendor_integation_mappings.compset[
                                                            requestData.compsetSettings.source
                                                        ].is_bar
                                                    }
                                                />
                                            }
                                            label="BAR"
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "vendor_integation_mappings>is_bar",
                                                    e.currentTarget.checked
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography gutterBottom sx={{ fontSize: 14 }}>
                                            LOAD SCHEDULE
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <FormControl name="from" label="From" className="textfield" fullWidth size="small">
                                            <InputLabel>From</InputLabel>

                                            <OutlinedInput
                                                className="input-text"
                                                label="From"
                                                placeholder="00:00"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule.from
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>load_schedule>from",
                                                        e.currentTarget.value
                                                    );
                                                }}

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <FormControl className="textfield" label="Until" fullWidth size="small">
                                            <InputLabel>Until</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="Until"
                                                name="source"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule.until
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>load_schedule>until",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>Pull Interval</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="Pull Interval"
                                                name="data_repull_interval_minutes"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule.data_repull_interval_minutes
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>load_schedule>data_repull_interval_minutes",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid item lg={3}>
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>Timezone</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="Timezone"
                                                value={requestData.compsetSettings.source &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule &&
                                                    requestData.vendor_integation_mappings.compset[
                                                        requestData.compsetSettings.source
                                                    ].load_schedule.timezone
                                                }
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "vendor_integation_mappings>load_schedule>timezone",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </>}
                        </Grid>

                    </Box>

                    {isrequesting && (
                        <Box
                            sx={{
                                textAlighn: "center",
                                backgroundColor: "dark.darkest",
                                opacity: 0.8,
                                position: "fixed",
                                top: 40,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 100,
                            }}
                        >
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-20px",
                                    marginLeft: "-20px",
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}
        </div>
    );
}

function RatesSettings(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for rates settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;

    //Handling if resource_lookup is missing in system_settings object
    if (!system_settings[0].rate_source.resource_lookup) {
        system_settings[0].rate_source.resource_lookup = { rate_code: "" };
    }
    // debugger;
    const rateSettings = {
        rate_source: {
            resource_lookup: { rate_code: system_settings[0].rate_source.resource_lookup.rate_code },
            type: system_settings[0].rate_source.type, //RATE SOURCE
            source: system_settings[0].rate_source.source, //SOURCE
            resource_id: system_settings[0].rate_source.resource_id, //RESOURCE IDS
        },
        default_rate_segment_code: system_settings[0].default_rate_segment_code, //DEFAULT MARKET CODE
        rate_push_type: system_settings[0].rate_push_type, //RATE PUSH MODE
        rtp_grid_option_type: system_settings[0].rtp_grid_option_type, //RTP SETTINGS
    };
    // debugger;
    const [requestData, setRequestData] = useState(rateSettings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const cancelSave = () => {
        setRequestData({
            rate_source: system_settings[0].rate_source,
            default_rate_segment_code: system_settings[0].default_rate_segment_code,
            rate_push_type: system_settings[0].rate_push_type,
            rtp_grid_option_type: system_settings[0].rtp_grid_option_type,
        });
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        //debugger
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        switch (key[0]) {
            case "default_rate_segment_code":
            case "rate_push_type":
            case "rtp_grid_option_type":
                updatedObj[key[0]] = val;
                break;

            case "rate_source":
                if (key.length === 2) {
                    if (key[1] === 'resource_id') {
                        updatedObj[key[0]][key[1]] = val.map(v => Number(v));
                    } else {
                        updatedObj[key[0]][key[1]] = val;
                    }

                }
                if (key.length === 3) {
                    updatedObj[key[0]][key[1]][key[2]] = val;
                }
                break;

            default:
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        // debugger;
        setIsRequesting(true);
        if (requestData.rate_source.type !== 'compset') {
            requestData.rate_source.source = "";
            requestData.rate_source.resource_id = [];
        }
        const request_system_settings = {
            ...system_settings[0],
            rate_source: requestData.rate_source,
            default_rate_segment_code: requestData.default_rate_segment_code,
            rate_push_type: requestData.rate_push_type,
            rtp_grid_option_type: requestData.rtp_grid_option_type,
        };
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };
        // debugger;

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 4000);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    //console.log(system_settings[0]);
    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }

    return (
        <div>
            <Box className='top-axn-bar-1'>
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }

                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>

                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Rates</span>
                </Box>
            </Box>

            <Box className="bgwhite main-scroll-wrap" sx={{ pl: 2, pr: 2 }}>

                <div className='profile-heading' style={{ paddingBottom: '5px' }}>RATES</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>

                <Grid className="locale-main-comtainer" >
                    <Box
                        sx={{
                            p: 1,
                            flexDirection: "column",
                            position: "relative",
                            display: "flex",
                        }}
                    >

                        <Box>

                            <Grid container spacing={2} sx={{ fontSize: 10 }}>

                                <Grid item className="settings-rates-inputs" lg={4}>
                                    <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>RATE SOURCE</InputLabel>
                                        <Select
                                            className="field"
                                            sx={{ width: "100%" }}
                                            value={requestData.rate_source.type}
                                            label="RATE SOURCE"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                updateRequestData("rate_source>type", value);
                                            }}
                                        >
                                            <MenuItem value="compset">compset</MenuItem>
                                            <MenuItem value="tt">tt</MenuItem>
                                            <MenuItem value="di">di</MenuItem>
                                            <MenuItem value="pms">pms</MenuItem>
                                            <MenuItem value="revup">revup</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {requestData.rate_source.type === 'compset' &&
                                    <>
                                        <Grid item className="settings-rates-inputs" lg={4}>
                                            <FormControl className="textfield" fullWidth size="small">
                                                <InputLabel>SOURCE</InputLabel>
                                                <OutlinedInput
                                                    label="SOURCE"
                                                    value={requestData.rate_source.source}
                                                    onChange={(e) => {
                                                        updateRequestData(
                                                            "rate_source>source",
                                                            e.currentTarget.value
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item className="settings-rates-inputs" lg={4}>
                                            {/* <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>RESOURCE IDS</InputLabel>
                                            <OutlinedInput
                                                label="RESOURCE IDS"
                                                value={requestData.rate_source.resource_id}
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "rate_source>resource_id",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                            />
                                        </FormControl> */}
                                            <Autocomplete
                                                size="small"
                                                multiple
                                                options={[]}
                                                value={
                                                    requestData.rate_source.resource_id
                                                }
                                                freeSolo
                                                onChange={(e, newVal) => {
                                                    const value = newVal;
                                                    updateRequestData(
                                                        "rate_source>resource_id",
                                                        value
                                                    );
                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip className="chip-close"
                                                            key={"id-" + index}
                                                            size="small"
                                                            variant="filled"
                                                            // color="primary"
                                                            label={option}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="textfield"
                                                        {...params}
                                                        size="small"
                                                        variant="outlined"
                                                        label="RESOURCE IDS"
                                                        placeholder="type & press enter"
                                                    />
                                                )}
                                            />

                                        </Grid>
                                    </>
                                }

                                <Grid item className="settings-rates-inputs" lg={4}>
                                    <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>DEFAULT RATE PLAN CODE</InputLabel>
                                        <OutlinedInput
                                            label="DEFAULT RATE PLAN CODE"
                                            value={requestData.rate_source.resource_lookup.rate_code}
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "rate_source>resource_lookup>rate_code",
                                                    e.currentTarget.value
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item className="settings-rates-inputs" lg={4}>
                                    <FormControl className="textfield" size="small" fullWidth>
                                        <InputLabel>DEFAULT MARKET CODE</InputLabel>
                                        <OutlinedInput
                                            className="input-text"
                                            label="DEFAULT MARKET CODE"
                                            value={requestData.default_rate_segment_code}
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "default_rate_segment_code",
                                                    e.currentTarget.value
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item className="settings-rates-inputs" lg={4}>
                                    <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>RATE PUSH MODE</InputLabel>
                                        <Select
                                            className="field"
                                            sx={{ width: "100%" }}
                                            label="RATE PUSH MODE"
                                            value={requestData.rate_push_type}
                                            onChange={(e) => {
                                                updateRequestData("rate_push_type", e.target.value);
                                            }}
                                        >
                                            <MenuItem value="default">Default</MenuItem>
                                            <MenuItem value="v2">V2</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item className="settings-rates-inputs" lg={4}>
                                    <FormControl className="textfield"
                                        fullWidth
                                        size="small"
                                        helperText="Enter comma seprated property IDs"
                                    >
                                        <InputLabel>RTP SETTINGS</InputLabel>
                                        <Select
                                            className="field"
                                            disabled
                                            sx={{ width: "100%" }}
                                            label="RTP SETTINGS"
                                            value={requestData.rtp_grid_option_type}
                                            onChange={(e) => {
                                                updateRequestData("rtp_grid_option_type", e.target.value);
                                            }}
                                        >
                                            <MenuItem value="default">Default</MenuItem>
                                            <MenuItem value="avail_ldiff_currentrate">
                                                Availability, LodgIQ Diff, Current Rate
                                            </MenuItem>
                                            <MenuItem value="ldiff_currentrate">
                                                LodgIQ Diff, Current Rate
                                            </MenuItem>
                                            <MenuItem value="currentdiff_currentrate">
                                                Current Diff, Current Rate
                                            </MenuItem>
                                            <MenuItem value="ldiff_currentdiff_currentrate">
                                                LodgIQ Diff, Current Diff, Current Rate
                                            </MenuItem>
                                            <MenuItem value="currentrate">Current Rate</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Box>

                        {isrequesting && (
                            <Box
                                sx={{
                                    textAlighn: "center",
                                    backgroundColor: "dark.darkest",
                                    opacity: 0.8,
                                    position: "fixed",
                                    top: 40,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 100,
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )
            }
        </div >
    );
}

function SegmentsSettings(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for segments settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });

    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const market_segment_category_mapping = JSON.parse(
        JSON.stringify(system_settings[0].market_segment_category_mapping)
    );
    const market_segment_category_order = JSON.parse(
        JSON.stringify(system_settings[0].market_segment_category_order)
    );
    let market_code_ignorelist = JSON.parse(
        JSON.stringify(system_settings[0].market_code_ignorelist)
    );
    //Handling market_code_ignorelist in case its coming wrong from API
    market_code_ignorelist = market_code_ignorelist && market_code_ignorelist[0] !== "{NULL}" ? market_code_ignorelist : [];

    const lvl1_mseg_grouping = JSON.parse(
        JSON.stringify(system_settings[0].lvl1_mseg_grouping)
    );
    const topSegments = {};
    // debugger;
    let Transient = lvl1_mseg_grouping.filter((item) => item.Transient);
    let Group = lvl1_mseg_grouping.filter((item) => item.Group);
    let Contract = lvl1_mseg_grouping.filter((item) => item.Contract);
    topSegments.Transient = Transient.length ? Transient[0].Transient : [];
    topSegments.Group = Group.length ? Group[0].Group : [];
    topSegments.Contract = Contract.length ? Contract[0].Contract : [];
    // debugger;
    const settings_ = JSON.parse(
        JSON.stringify({
            market_segment_category_mapping: market_segment_category_mapping,
            market_segment_category_order: market_segment_category_order,
            market_code_ignorelist: market_code_ignorelist,
            topSegments: topSegments,
            lvl1_mseg_grouping: lvl1_mseg_grouping,
        })
    );
    const settings = JSON.parse(
        JSON.stringify({
            market_segment_category_mapping:
                system_settings[0].market_segment_category_mapping,
            market_segment_category_order:
                system_settings[0].market_segment_category_order,
            market_code_ignorelist: market_code_ignorelist,
            topSegments: topSegments,
            lvl1_mseg_grouping: lvl1_mseg_grouping,
        })
    );
    //const settings = JSON.parse(JSON.stringify(system_settings[0].market_segment_category_mapping));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);
    // debugger; 
    const [ignoreCodes, setIgnoreCodes] = useState([]);
    useEffect(() => {
        requestData.market_segment_category_order.map((item, index) => {
            requestData.market_segment_category_mapping[item].map((itm, idx) => {
                if (ignoreCodes.indexOf(itm) === -1) {
                    ignoreCodes.push(itm);
                }
            });
        });
        // debugger;
        // setRequestData({ ...requestData });
        setIgnoreCodes([...ignoreCodes]);
    }, []);

    const [selectedValues, setSelectedValues] = useState(requestData.topSegments["Transient"]);
    const handleRemoveChip = (chipToRemove) => {
        setSelectedValues((chips) => chips.filter((chip) => chip !== chipToRemove));
    };

    const [roomSettingsOld, setRoomSettingsOld] = useState(JSON.parse(JSON.stringify(requestData.market_segment_category_order))); //taking a backup of Old Array
    const [roomSettings, setRoomSettings] = useState(requestData.market_segment_category_order);

    console.log('old-segment-order', roomSettingsOld);
    console.log('new-segment-order', roomSettings);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const updatedRoomSettings = Array.from(roomSettings);
        const [movedItem] = updatedRoomSettings.splice(result.source.index, 1);
        updatedRoomSettings.splice(result.destination.index, 0, movedItem);

        setRoomSettings(updatedRoomSettings);

        requestData.market_segment_category_order = updatedRoomSettings;
        setRequestData({ ...requestData, current: requestData.current });
        setIsChangeRequest(true);
    };

    const cancelSave = () => {
        setRoomSettings(JSON.parse(JSON.stringify(roomSettingsOld)));
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };
    const updateRequestData = (keys, val, index, item) => {
        const updatedObj = { ...requestData };
        updatedObj.market_segment_category_order = roomSettings;
        const key = keys.split(">");
        // const originalSettings = settings_;
        // debugger;
        if (key[0] === 'market_segment_category_mapping') {
            const size = updatedObj[key[0]][key[1]].length;
            updatedObj[key[0]][key[1]].splice(0, size);
            updatedObj[key[0]][key[1]] = [...val];
        } else
            if (key[0] == "lvl1_mseg_grouping") {
                updatedObj.topSegments[key[1]] = val;
            } else
                if (key[0] == "market_segment_category_order") {
                    updatedObj.market_segment_category_order[index] = val;
                    let temp = updatedObj.market_segment_category_mapping[item];
                    updatedObj.market_segment_category_mapping[val] = temp;
                    if (updatedObj.topSegments.Transient.indexOf(item) !== -1) {
                        let i = updatedObj.topSegments.Transient.indexOf(item);
                        updatedObj.topSegments.Transient.splice(i, 1, val);
                    }
                    if (updatedObj.topSegments.Group.indexOf(item) !== -1) {
                        let i = updatedObj.topSegments.Group.indexOf(item);
                        updatedObj.topSegments.Group.splice(i, 1, val);
                    }
                    if (updatedObj.topSegments.Contract.indexOf(item) !== -1) {
                        let i = updatedObj.topSegments.Contract.indexOf(item);
                        updatedObj.topSegments.Contract.splice(i, 1, val);
                    }
                    delete updatedObj.market_segment_category_mapping[item];

                    //debugger;
                    // let size = val.length;
                    // let l = updatedObj.market_segment_category_order[key[1]].length;
                    // updatedObj.market_segment_category_order[key[1]].splice(0, l);
                    // val.map((item, i) => {
                    //   updatedObj.market_segment_category_order[key[1]].push(item);
                    // });
                } else if (key[0] == "market_code_ignorelist") {
                    // updatedObj.market_code_ignorelist = val;
                    let size = val.length;

                    let l = updatedObj.market_code_ignorelist.length;
                    updatedObj.market_code_ignorelist.splice(0, l);
                    val.map((item, i) => {
                        updatedObj.market_code_ignorelist.push(item);
                    });
                } else {
                    let finalVal;
                    if (typeof val === "string" && val !== "") {
                        // finalVal = val.split(",");
                        finalVal = val;
                        // finalVal.map((item, index) => {
                        //   finalVal[index] = finalVal[index].trim();
                        // });
                        //updatedObj[key[0]][key[1]].push(finalVal);
                    } else {
                        //updatedObj[key[0]][key[1]].pop();
                    }
                    //updatedObj[key[0]][key[1]] = finalVal;
                }
        //  debugger;
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
        //debugger;
    };
    const deleteMarketSegment = (index) => {
        const segment = roomSettings[index];
        roomSettings.splice(index, 1);
        delete requestData.market_segment_category_mapping[segment];
        // debugger;
        //Removing from top segment
        if (requestData.topSegments.Group.indexOf(segment) !== -1) {
            let i = requestData.topSegments.Group.indexOf(segment);
            requestData.topSegments.Group.splice(i, 1);
        }
        if (requestData.topSegments.Contract.indexOf(segment) !== -1) {
            let i = requestData.topSegments.Contract.indexOf(segment);
            requestData.topSegments.Contract.splice(i, 1);
        }
        if (requestData.topSegments.Transient.indexOf(segment) !== -1) {
            let i = requestData.topSegments.Transient.indexOf(segment);
            requestData.topSegments.Transient.splice(i, 1);
        }

        setRequestData({
            ...requestData,
            market_segment_category_order: requestData.market_segment_category_order,
            market_segment_category_mapping: requestData.market_segment_category_mapping
        });
        setIsChangeRequest(true);
    };
    const changeSegmentsOrder = (item, index, type) => {
        //debugger;
        const tempObj = item;
        const size = requestData.market_segment_category_order.length;
        if (type == "up" && index) {
            requestData.market_segment_category_order[index] =
                requestData.market_segment_category_order[index - 1];
            requestData.market_segment_category_order[index - 1] = item;
            setRequestData({
                ...requestData,
                market_segment_category_order:
                    requestData.market_segment_category_order,
            });
            setIsChangeRequest(true);
        }
        if (type == "down" && index < size) {
            requestData.current.room_settings[index] =
                requestData.current.room_settings[index + 1];
            requestData.current.room_settings[index + 1] = item;
            setRequestData({ ...requestData, current: requestData.current });
            setIsChangeRequest(true);
        }
    };
    const addNewSegmentMap = (e, value) => {
        const newSegment = "new" + (roomSettings.length + 1);
        roomSettings.push(newSegment);
        requestData.market_segment_category_mapping[newSegment] = [];
        setRequestData({
            ...requestData,
            market_segment_category_mapping:
                requestData.market_segment_category_mapping,
            market_segment_category_order: roomSettings,
        });
        setIsChangeRequest(true);
    };
    const saveChanges = (event) => {
        setIsRequesting(true);
        let lvl1_mseg_grouping = [];
        lvl1_mseg_grouping.push({ Transient: requestData.topSegments.Transient });
        lvl1_mseg_grouping.push({ Group: requestData.topSegments.Group });
        lvl1_mseg_grouping.push({ Contract: requestData.topSegments.Contract });

        const request_system_settings = {
            ...system_settings[0],
            market_segment_category_mapping:
                requestData.market_segment_category_mapping,
            market_segment_category_order: roomSettings,
            market_code_ignorelist: requestData.market_code_ignorelist,
            lvl1_mseg_grouping: lvl1_mseg_grouping,
        };
        // debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setIsChangeRequest(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        //reverting changes back
        setRoomSettings(JSON.parse(JSON.stringify(roomSettingsOld)));
        setRequestData(settings_);
        setIsChangeRequest(false);

        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }

    function CustomChipDeleteIcon() {
        return <svg className="custom-chip-delete-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#606060" viewBox="0 0 256 256"><path d="M165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path></svg>
    }

    function ExpandMoreIcon() {
        return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#606060" viewBox="0 0 256 256"><path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path></svg>
    }

    return (
        <div style={{ maxWidth: "unset" }}>

            <Box className='top-axn-bar-1'
            >
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }

                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>

                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Segments</span>
                </Box>
            </Box>

            <Box className="bgwhite main-scroll-wrap-segments" sx={{ pl: 2, pr: 2 }}>

                <div className='profile-heading'>SEGMENTS</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>

                <Box className="segments-main-comtainer">

                    <Box className="segments-list-table">

                        <Grid className="segments-list-head" container spacing={0}>
                            <Grid item ></Grid>
                            <Grid item >SEGMENT NAME</Grid>
                            <Grid item >SEGMENT CODES</Grid>
                            <Grid item ></Grid>
                        </Grid>

                        <Box className="segments-list-data" sx={{ paddingBottom: "70px" }}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="room-settings-list" direction="vertical">
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {roomSettings.map((item, index) => (
                                                <Draggable
                                                    key={"roomsettings-" + index}
                                                    draggableId={"roomsettings-" + index}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div className="segments-draggable-row"
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <Tooltip title="Drag and Drop" arrow followCursor >
                                                                <Grid item md={0.3} className="room-type-details-col" style={{ pr: '10px' }}>
                                                                    <svg className="draggable-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000000" viewBox="0 0 256 256"><path d="M104,60A12,12,0,1,1,92,48,12,12,0,0,1,104,60Zm60,12a12,12,0,1,0-12-12A12,12,0,0,0,164,72ZM92,116a12,12,0,1,0,12,12A12,12,0,0,0,92,116Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,116ZM92,184a12,12,0,1,0,12,12A12,12,0,0,0,92,184Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,184Z"></path></svg>
                                                                </Grid>
                                                            </Tooltip>

                                                            <Grid item className="segments-detail">

                                                                <FormControl className="textfield" fullWidth size="small">
                                                                    <OutlinedInput
                                                                        className="field"
                                                                        sx={{ fontSize: 12 }}
                                                                        value={item}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "market_segment_category_order",
                                                                                value,
                                                                                index,
                                                                                item
                                                                            );
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item className="segments-detail">

                                                                <Autocomplete
                                                                    size="small"
                                                                    multiple
                                                                    options={[]}
                                                                    value={
                                                                        requestData.market_segment_category_mapping[item]
                                                                    }
                                                                    freeSolo
                                                                    onChange={(e, newVal) => {
                                                                        const value = newVal;
                                                                        updateRequestData(
                                                                            "market_segment_category_mapping>" + item,
                                                                            value,
                                                                            index
                                                                        );
                                                                    }}
                                                                    renderTags={(value, getTagProps) =>
                                                                        value.map((option, index) => (
                                                                            <Chip className="chip-close"
                                                                                key={"id-" + index}
                                                                                size="small"
                                                                                variant="filled"
                                                                                label={option}
                                                                                {...getTagProps({ index })}
                                                                            //deleteIcon={<CustomChipDeleteIcon />}
                                                                            />
                                                                        ))
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            className="textfield"
                                                                            {...params}
                                                                            size="small"
                                                                            variant="outlined"
                                                                            label=""
                                                                            placeholder=""
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>

                                                            <Grid item className="segments-detail" sx={{ textAlign: 'center' }}>
                                                                <Box display="flex" spacing={0} sx={{ float: "right" }}>
                                                                    <Button
                                                                        variant=""
                                                                        size="small"
                                                                        sx={{ minWidth: "auto", p: '4px', lineHeight: "auto", borderRadius: '50px' }}
                                                                        onClick={() => {
                                                                            deleteMarketSegment(index);
                                                                        }}
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#E03131" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>

                                                                    </Button>
                                                                </Box>
                                                            </Grid>

                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                        </Box>


                        <Button
                            variant="outlined"
                            className="add-button"
                            size="small"
                            sx={{ lineHeight: "auto", bottom: "20px !important" }}
                            onClick={() => {
                                addNewSegmentMap();
                            }}
                        >
                            <span className="add-btn-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#333333" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>
                            </span>
                            <span className="add-btn-txt">Add a Segment</span>
                        </Button>



                    </Box>
                </Box>

                <Box className="segments-main-comtainer-2">
                    <Grid item>
                        <FormControl fullWidth size="small">
                            <Autocomplete
                                size="small"
                                multiple
                                options={ignoreCodes}
                                value={requestData.market_code_ignorelist}
                                freeSolo
                                onChange={(e, newVal) => {
                                    //item.recipients;
                                    updateRequestData("market_code_ignorelist", newVal);
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            key={"id-" + index}
                                            size="small"
                                            variant="filled"
                                            // color="primary"
                                            label={option}
                                            {...getTagProps({ index })}
                                        //deleteIcon={<CustomChipDeleteIcon />}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        className="textfield"
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label="IGNORED CODES"
                                        placeholder="Type code and press enter"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Box>

                <Box className="segments-main-comtainer-2">
                    <Box>
                        <Grid container >

                            <Grid item xs={12}>
                                <Typography gutterBottom className="top-segments-txt" sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#333333" viewBox="0 0 256 256"><path d="M32,64a8,8,0,0,1,8-8H216a8,8,0,0,1,0,16H40A8,8,0,0,1,32,64ZM216,96H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Zm0,40H88a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z"></path></svg>
                                    TOP SEGMENTS
                                </Typography>
                            </Grid>

                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '8px' }}>
                                <Grid item>
                                    {/* <FormControl className="textfield"
                                        fullWidth
                                        size="small"
                                        helperText="Enter comma seprated property IDs"
                                    >
                                        <InputLabel>TRANSIENT</InputLabel>
                                        <Select
                                            className="field"
                                            label="TRANSIENT"
                                            multiple
                                            value={requestData.topSegments["Transient"]}
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "lvl1_mseg_grouping>Transient",
                                                    e.target.value
                                                );
                                            }}
                                            renderValue={(selected) => (
                                                <Box
                                                    sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                                                >
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={value}
                                                            variant="filled"
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {requestData.market_segment_category_order.map(
                                                (item, index) => {
                                                    return <MenuItem value={item}>{item}</MenuItem>;
                                                }
                                            )}
                                        </Select>
                                    </FormControl> */}
                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        multiple
                                        id="autocomplete-transient"
                                        value={requestData.topSegments["Transient"]}
                                        onChange={(e, newValue) => {
                                            updateRequestData("lvl1_mseg_grouping>Transient", newValue);
                                        }}
                                        options={requestData.market_segment_category_order}
                                        renderInput={(params) => (
                                            <TextField
                                                className="textfield"
                                                {...params}
                                                label="TRANSIENT"
                                            //helperText="Enter comma separated property IDs"
                                            />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    size="small"
                                                    key={index}
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                    variant="filled"
                                                //deleteIcon={<CustomChipDeleteIcon />}
                                                />
                                            ))
                                        }
                                        popupIcon={<ExpandMoreIcon />}
                                    />

                                </Grid>

                                <Grid item >
                                    {/* <FormControl className="textfield"
                                        fullWidth
                                        size="small"
                                        helperText="Enter comma seprated property IDs"
                                    >
                                        <InputLabel>GROUP</InputLabel>
                                        <Select
                                            className="field"
                                            label="GROUP"
                                            multiple
                                            value={requestData.topSegments["Group"]}
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "lvl1_mseg_grouping>Group",
                                                    e.target.value
                                                );
                                            }}
                                            renderValue={(selected) => (
                                                <Box
                                                    sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                                                >
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={value}
                                                            variant="filled"
                                                        // color="primary"
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {requestData.market_segment_category_order.map(
                                                (item, index) => {
                                                    return <MenuItem value={item}>{item}</MenuItem>;
                                                }
                                            )}
                                        </Select>
                                    </FormControl> */}
                                    <Autocomplete
                                        multiple
                                        id="group-autocomplete"
                                        options={requestData.market_segment_category_order}
                                        value={requestData.topSegments["Group"]}
                                        onChange={(e, newValue) => {
                                            updateRequestData("lvl1_mseg_grouping>Group", newValue);
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    size="small"
                                                    key={index}
                                                    label={option}
                                                    variant="filled"
                                                    {...getTagProps({ index })}
                                                //deleteIcon={<CustomChipDeleteIcon />}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                className="textfield"
                                                {...params}
                                                fullWidth
                                                size="small"
                                                //helperText="Enter comma separated property IDs"
                                                label="GROUP"
                                            />
                                        )}
                                        popupIcon={<ExpandMoreIcon />}
                                    />
                                </Grid>

                                <Grid item>
                                    {/* <FormControl className="textfield"
                                        fullWidth
                                        size="small"
                                        helperText="Enter comma seprated property IDs"
                                    >
                                        <InputLabel className="input-text">CONTRACT</InputLabel>
                                        <Select
                                            className="field"
                                            label="CONTRACT"
                                            multiple
                                            value={requestData.topSegments["Contract"]}
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "lvl1_mseg_grouping>Contract",
                                                    e.target.value
                                                );
                                            }}
                                            renderValue={(selected) => (
                                                <Box
                                                    sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                                                >
                                                    {selected.map((value) => (
                                                        <Chip
                                                            key={value}
                                                            label={value}
                                                            variant="filled"
                                                        // color="primary"
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                        >
                                            {requestData.market_segment_category_order.map(
                                                (item, index) => {
                                                    return <MenuItem value={item}>{item}</MenuItem>;
                                                }
                                            )}
                                        </Select>
                                    </FormControl> */}

                                    <Autocomplete
                                        fullWidth
                                        size="small"
                                        multiple
                                        value={requestData.topSegments["Contract"]}
                                        onChange={(e, newValue) => {
                                            updateRequestData("lvl1_mseg_grouping>Contract", newValue);
                                        }}
                                        options={requestData.market_segment_category_order}
                                        renderInput={(params) => (
                                            <TextField
                                                className="textfield"
                                                {...params}
                                                variant="outlined"
                                                label="CONTRACT"
                                            //helperText="Enter comma-separated property IDs"
                                            />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    size="small"
                                                    key={index}
                                                    label={option}
                                                    {...getTagProps({ index })}
                                                    variant="filled"
                                                //deleteIcon={<CustomChipDeleteIcon />}
                                                />
                                            ))
                                        }
                                        popupIcon={<ExpandMoreIcon />}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </Box>


                {isrequesting && (
                    <Box
                        sx={{
                            textAlighn: "center",
                            backgroundColor: "dark.darkest",
                            opacity: 0.8,
                            position: "fixed",
                            top: 40,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: 100,
                        }}
                    >
                        <CircularProgress
                            size={40}
                            sx={{
                                color: "white",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-20px",
                                marginLeft: "-20px",
                            }}
                        />
                    </Box>
                )}

            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}
        </div>
    );
}

function MarketsSettings(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for market settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });

    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const market_settings = JSON.parse(
        JSON.stringify(system_settings[0].market_settings)
    );
    const primary_vacation_rental_market =
        system_settings[0].primary_vacation_rental_market;
    const settings_ = JSON.parse(
        JSON.stringify({
            market_settings: market_settings,
            primary_vacation_rental_market: primary_vacation_rental_market,
        })
    );
    const settings = JSON.parse(
        JSON.stringify({
            market_settings: system_settings[0].market_settings,
            primary_vacation_rental_market: primary_vacation_rental_market,
        })
    );

    //const settings = JSON.parse(JSON.stringify(system_settings[0].market_segment_category_mapping));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        //const newVal = val.split(',');

        if (key[0] === "primary_vacation_rental_market") {
            updatedObj[key[0]] = val;
        }
        if (key[0] == "market_settings") {
            if (key[1] === "market_id") {
                let selectedMarket = profile.market_settings.filter((item) => {
                    return item[key[1]] === val;
                });
                updatedObj[key[0]][index].market_id = val;
                updatedObj[key[0]][index].value = val;
                updatedObj[key[0]][index].market_name = selectedMarket[0].market_name;
                updatedObj[key[0]][index].label = selectedMarket[0].market_name;
                updatedObj[key[0]][index].market_source_name =
                    selectedMarket[0].collection;
                updatedObj[key[0]][index].revup_market_idOFF = "";
                // label: "Key West Combined Market - Key West, FL"
                // market_id: 7777258
                // market_name: "Key West Combined Market - Key West, FL"
                // market_source_name: "Test_Key_West_Market_Combined"
                // revup_market_idOFF: ""
                // value: 7777258
                //debugger;
                //updatedObj[key[0]][index][key[1]] = val;
            } else if (key[1] === "is_default") {
                updatedObj.market_settings.map((item, I) => {
                    if (index !== I && item.is_default) {
                        updatedObj[key[0]][I][key[1]] = false;
                    }
                });
                updatedObj[key[0]][index][key[1]] = true;
            } else {
                updatedObj[key[0]][index][key[1]] = val;
            }
        }

        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };
    const deleteSettingsRow = (index) => {
        const segment = requestData.market_settings[index];
        requestData.market_settings.splice(index, 1);

        setRequestData({
            ...requestData,
            market_settings: requestData.market_settings,
        });
        setIsChangeRequest(true);
    };

    const addNewSettingsRow = (e, value) => {
        const newRow = {
            is_default: false,
            label: "",
            market_id: "",
            market_name: "",
            market_source_name: "",
            revup_market_idOFF: "",
            value: "",
        };
        requestData.market_settings.push(newRow);
        setRequestData({
            ...requestData,
            market_settings: requestData.market_settings,
        });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        const request_system_settings = {
            ...system_settings[0],
            market_settings: [],
            primary_vacation_rental_market:
                requestData.primary_vacation_rental_market,
        };
        requestData.market_settings.map((item, index) => {
            if (item.market_id) {
                request_system_settings.market_settings.push(item);
            }
        });
        //debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    console.log("requestData.market_settings", requestData.market_settings, profile);

    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }

    return (
        <div>
            {/* <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Markets</span>
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Markets
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div> */}

            <Box className='top-axn-bar-1'>
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }

                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>

                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Markets</span>
                </Box>
            </Box>

            <Box className="bgwhite main-scroll-wrap" sx={{ pl: 2, pr: 2 }}>

                <div className='profile-heading' style={{ paddingBottom: '5px' }}>MARKETS</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>

                <Box
                    sx={{
                        pt: 1,
                        flexDirection: "column",
                        position: "relative",
                        display: "flex",
                    }}
                >
                    {/* <Alert
                        severity="success"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestSuccess ? "flex" : "none",
                        }}
                    >
                        {requestSuccessMsg}
                    </Alert>
                    <Alert
                        severity="error"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestError ? "flex" : "none",
                        }}
                    >
                        {resErrorMsg}
                    </Alert> */}

                    <Box sx={{ mt: { lg: 1 } }}>

                        <Box>


                            <Grid>
                                <Grid
                                    container
                                    className="property-heading-title-heading"
                                    // spacing={1}
                                    sx={{
                                        fontSize: 12.5,
                                        // mt: 1,
                                        pb: 1,
                                        // borderTop: "1px solid #ccc",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <Grid item md={7.5} sx={{}}>
                                        MARKET
                                    </Grid>
                                    <Grid item md={2} textAlign="center">
                                        IS DEFAULT
                                    </Grid>
                                    <Grid item md={2} textAlign="center">
                                        GROUP COMM IN TOTAL
                                    </Grid>
                                    <Grid item md={1}>
                                        {" "}
                                    </Grid>
                                </Grid>

                                {requestData.market_settings.map((item, index) => {
                                    return (
                                        <Grid
                                            container
                                            className="room-type-details"
                                            spacing={1}
                                            sx={{ fontSize: 12.5, mt: 0 }}
                                        >
                                            <Grid item md={7.5}>
                                                <FormControl className="textfield" fullWidth size="small" sx={{}}>
                                                    <Select
                                                        className="field"
                                                        sx={{ width: "100%" }}
                                                        value={item.market_id}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            updateRequestData(
                                                                "market_settings>market_id",
                                                                value,
                                                                index
                                                            );
                                                        }}
                                                    >
                                                        {profile.market_settings && profile.market_settings.map((market, index) => {
                                                            return (
                                                                market.active && (
                                                                    <MenuItem value={market.market_id}>
                                                                        {market.market_name} ({market.market_id})
                                                                    </MenuItem>
                                                                )
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={2} sx={{ textAlign: "center" }}>
                                                <Radio
                                                    className="colored-radiobutton"
                                                    checked={item.is_default}
                                                    name="is_default"
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        updateRequestData(
                                                            "market_settings>is_default",
                                                            value,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={2} sx={{ textAlign: "center" }}>
                                                <Checkbox
                                                    className="colored-checkbox"
                                                    checked={item.market_mode === "comm"}
                                                    name="market_mode"
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        item.market_mode = item.market_mode ? "" : "comm";
                                                        updateRequestData(
                                                            "market_settings>market_mode",
                                                            item.market_mode,
                                                            index
                                                        );
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item md={0.5}>
                                                <Box display="flex" spacing={1}>
                                                    <Button
                                                        variant=""
                                                        className="delete-pd"
                                                        size="small"
                                                        sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                        onClick={() => {
                                                            deleteSettingsRow(index);
                                                        }}
                                                    >
                                                        <DeleteOutlineOutlinedIcon
                                                            fontSize="small"
                                                            color="error"
                                                            className="arrow-down inside-line-rateplan expend-arrow delete-icon-button"
                                                        ></DeleteOutlineOutlinedIcon>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    );
                                })}

                                {/* <Button
                                    className="add-button"
                                    variant="outlined"
                                    size="small"
                                    sx={{ lineHeight: "auto", mt: 1 }}
                                    onClick={() => {
                                        addNewSettingsRow();
                                    }}
                                >
                                    Add A Market
                                </Button> */}
                                <Button
                                    variant="outlined"
                                    className="add-button1"
                                    size="small"
                                    sx={{ lineHeight: "auto" }}
                                    onClick={() => {
                                        addNewSettingsRow();
                                    }}
                                >
                                    <span className="add-btn-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#333333" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>
                                    </span>
                                    <span className="add-btn-txt">Add Room Type</span>
                                </Button>



                                {/* <Divider sx={{ clear: "both", mt: 1, mb: 2 }} /> */}
                                <Grid mt={3} item lg={12}>
                                    <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>VR MARKET</InputLabel>
                                        <OutlinedInput
                                            className="field code"
                                            label="VR MARKET"
                                            value={requestData.primary_vacation_rental_market}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                updateRequestData(
                                                    "primary_vacation_rental_market",
                                                    value
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>


                        </Box>

                    </Box>
                    {isrequesting && (
                        <Box
                            sx={{
                                textAlighn: "center",
                                backgroundColor: "dark.darkest",
                                opacity: 0.8,
                                position: "fixed",
                                top: 40,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 100,
                            }}
                        >
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-20px",
                                    marginLeft: "-20px",
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}
        </div>
    );
}

function LocaleSettings(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for locale settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    //debugger;
    const localeSettings = {
        default_locale: system_settings[0].default_locale, //DEFAULT LOCALE
        locale_city: system_settings[0].locale_city, //CITY
    };
    //debugger;
    const [requestData, setRequestData] = useState(localeSettings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const cancelSave = () => {
        setRequestData({
            default_locale: system_settings[0].default_locale, //DEFAULT LOCALE
            locale_city: system_settings[0].locale_city, //CITY
        });
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        switch (key[0]) {
            case "default_locale":
            case "locale_city":
                updatedObj[key[0]] = val;
                break;

            case "rate_source":
                updatedObj[key[0]][key[1]] = val;
                break;

            default:
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        const request_system_settings = {
            ...system_settings[0],
            default_locale: requestData.default_locale, //DEFAULT LOCALE
            locale_city: requestData.locale_city, //CITY
        };
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 4000);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    //console.log(system_settings[0]);
    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }

    return (
        <div>

            <Box className='top-axn-bar-1'
            >
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }

                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>

                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Locale</span>
                </Box>
            </Box>

            <Box className="bgwhite main-scroll-wrap" sx={{ pl: 2, pr: 2 }}>

                <div className='profile-heading' style={{ paddingBottom: '5px' }}>LOCALE</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>

                <Grid className="locale-main-comtainer">
                    <Box
                        sx={{
                            flexDirection: "column",
                            position: "relative",
                            display: "flex",
                        }}
                    >

                        <Box>
                            <Grid container spacing={2} sx={{ fontSize: 10 }}>
                                <Grid item lg={6}>
                                    <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>DEFAULT LOCALE</InputLabel>
                                        <OutlinedInput
                                            className="input-text"
                                            label="DEFAULT LOCALE"
                                            value={requestData.default_locale}
                                            onChange={(e) => {
                                                updateRequestData("default_locale", e.currentTarget.value);
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={6}>
                                    <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>CITY</InputLabel>
                                        <OutlinedInput
                                            className="input-text"
                                            label="RESOURCE IDS"
                                            value={requestData.locale_city}
                                            onChange={(e) => {
                                                updateRequestData("locale_city", e.currentTarget.value);
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>

                        {isrequesting && (
                            <Box
                                sx={{
                                    textAlighn: "center",
                                    backgroundColor: "dark.darkest",
                                    opacity: 0.8,
                                    position: "fixed",
                                    top: 40,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 100,
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}
        </div>
    );
}

function IntegrationSettings(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for integration settings was successfull!",
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    //debugger;
    const integrationSettings = {
        primary_revup_property_id: system_settings[0].primary_revup_property_id, //RU ID
    };
    //debugger;
    const [requestData, setRequestData] = useState(integrationSettings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const cancelSave = () => {
        setRequestData({
            primary_revup_property_id: system_settings[0].primary_revup_property_id, //RU ID
        });
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        switch (key[0]) {
            case "primary_revup_property_id":
                updatedObj[key[0]] = val;
                break;

            default:
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        const request_system_settings = {
            ...system_settings[0],
            primary_revup_property_id: requestData.primary_revup_property_id, //RU ID
        };
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 4000);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    // console.log(system_settings[0]);
    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }

    return (
        <div>
            <Box className='top-axn-bar-1'
            >
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }

                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>

                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Integration</span>
                </Box>
            </Box>

            <Box className="bgwhite main-scroll-wrap" sx={{ pl: 2, pr: 2 }}>
                <div className='profile-heading' style={{ paddingBottom: '5px' }}>INTEGRATION</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>

                <Grid className="locale-main-comtainer" >
                    <Box
                        sx={{
                            flexDirection: "column",
                            position: "relative",
                            display: "flex",
                        }}
                    >


                        <Box>
                            <Grid container spacing={2} sx={{ fontSize: 10 }}>
                                <Grid item lg={12}>
                                    <FormControl className="textfield" fullWidth size="small">
                                        <InputLabel>RU ID</InputLabel>
                                        <OutlinedInput
                                            className="input-text"
                                            label="RU ID"
                                            value={requestData.primary_revup_property_id}
                                            onChange={(e) => {
                                                updateRequestData(
                                                    "primary_revup_property_id",
                                                    e.currentTarget.value
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>

                        {isrequesting && (
                            <Box
                                sx={{
                                    textAlighn: "center",
                                    backgroundColor: "dark.darkest",
                                    opacity: 0.8,
                                    position: "fixed",
                                    top: 40,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 100,
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}
        </div>
    );
}

function DiscountRulesSettings(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;

    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const rate_discount_rules = {
        enabled: false,
        default: {
            discount: {
                branddotcom: {
                    month: {
                        1: 0,
                        2: 0,
                        3: 0,
                        4: 0,
                        5: 0,
                        6: 0,
                        7: 0,
                        8: 0,
                        9: 0,
                        10: 0,
                        11: 0,
                        12: 0,
                    },
                },
                ota: {
                    month: {
                        1: 0,
                        2: 0,
                        3: 0,
                        4: 0,
                        5: 0,
                        6: 0,
                        7: 0,
                        8: 0,
                        9: 0,
                        10: 0,
                        11: 0,
                        12: 0,
                    },
                },
            },
            markup: 0,
        },
    };
    system_settings[0].rate_discount_rules = system_settings[0]
        .rate_discount_rules
        ? system_settings[0].rate_discount_rules
        : rate_discount_rules;
    const settings_ = JSON.parse(
        JSON.stringify({
            rate_discount_rules: system_settings[0].rate_discount_rules,
        })
    );
    const settings = JSON.parse(
        JSON.stringify({
            rate_discount_rules: system_settings[0].rate_discount_rules,
        })
    );

    //const settings = JSON.parse(JSON.stringify(system_settings[0].market_segment_category_mapping));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");

        if (key[0] == "rate_discount_rules") {
            //debugger;
            if (key[1] === "enabled") {
                updatedObj[key[0]][key[1]] = !updatedObj[key[0]][key[1]];
            }
            if (key[1] === "markup") {
                updatedObj[key[0]]["default"][key[1]] = isNaN(Number(val))
                    ? 0
                    : Number(val);
            }

            if (key[1] === "branddotcom") {
                updatedObj[key[0]]["default"]["discount"][key[1]]["month"][index + 1] =
                    isNaN(Number(val)) ? 0 : Number(val);
            }
            if (key[1] === "ota") {
                updatedObj[key[0]]["default"]["discount"][key[1]]["month"][index + 1] =
                    isNaN(Number(val)) ? 0 : Number(val);
            }
        }

        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        const request_system_settings = {
            ...system_settings[0],
            rate_discount_rules: requestData.rate_discount_rules,
        };

        //debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    // console.log(system_settings);
    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }

    return (
        <div>
            {/* <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Discount Rules</span>
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Discount Rules
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div> */}

            <Box className='top-axn-bar-1'>
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }

                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>

                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Discount Rules</span>
                </Box>
            </Box>

            <Box className="bgwhite main-scroll-wrap" sx={{ pl: 2, pr: 2 }}>

                <div className='profile-heading' style={{ paddingBottom: '5px' }}>DISCOUNT RULES</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>

                <Grid>
                    <Box
                        sx={{
                            pt: 0,
                            flexDirection: "column",
                            position: "relative",
                            display: "flex",
                        }}
                    >
                        {/* <Alert
                            severity="success"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestSuccess ? "flex" : "none",
                            }}
                        >
                            {requestSuccessMsg}
                        </Alert>
                        <Alert
                            severity="error"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestError ? "flex" : "none",
                            }}
                        >
                            {resErrorMsg}
                        </Alert> */}

                        <Box sx={{ mt: { lg: 0 } }}>
                            <Box sx={{}}>
                                <Grid
                                    container
                                    spacing={0}
                                    sx={{ fontSize: 14, mt: 0, pb: 1, fontWeight: "bold" }}
                                >
                                    <Grid item lg={3}>
                                        <FormControlLabel
                                            sx={{ m: 0, p: 0 }}
                                            control={
                                                <Switch
                                                    className="switch-button"
                                                    size="small"
                                                    // color="green"
                                                    checked={requestData.rate_discount_rules.enabled}
                                                    onChange={(e) => {
                                                        updateRequestData(
                                                            "rate_discount_rules>enabled",
                                                            e.currentTarget.value
                                                        );
                                                    }}
                                                />
                                            }
                                            label="Enable Discount"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        lg={9}
                                        sx={{
                                            visibility: requestData.rate_discount_rules.enabled
                                                ? "visible"
                                                : "hidden",
                                            pt: 2,
                                        }}
                                    >
                                        <FormControl className="textfield" fullWidth size="small">
                                            <InputLabel>MARKUP</InputLabel>
                                            <OutlinedInput
                                                className="input-text"
                                                label="MARKUP"
                                                value={requestData.rate_discount_rules.default.markup}
                                                onChange={(e) => {
                                                    updateRequestData(
                                                        "rate_discount_rules>markup",
                                                        e.currentTarget.value
                                                    );
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">%</InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid
                                    className="property-heading-title-heading"
                                    container
                                    spacing={0}
                                    sx={{
                                        visibility: requestData.rate_discount_rules.enabled
                                            ? "visible"
                                            : "hidden",
                                        fontSize: 12.5,
                                        mt: 1,
                                        pb: 1,
                                        // borderTop: "1px solid #ccc",
                                        // borderBottom: "1px solid #ccc",
                                        // fontWeight: "bold",
                                        // color: "#01a4ef",
                                    }}
                                >
                                    <Grid item xs={2}>
                                        MONTHS
                                    </Grid>
                                    <Grid item xs={5}>
                                        BRAND.COM
                                    </Grid>
                                    <Grid item xs={5}>
                                        OTA
                                    </Grid>
                                </Grid>
                                <Box
                                    sx={{
                                        visibility: requestData.rate_discount_rules.enabled
                                            ? "visible"
                                            : "hidden",
                                        maxHeight: "48vh",
                                        overflowY: "auto",
                                    }}
                                >
                                    {months.map((item, index) => {
                                        return (
                                            <Grid container spacing={1} sx={{ fontSize: 12.5, pt: 1 }}>
                                                <Grid item xs={2} sx={{ lineHeight: 3 }}>
                                                    {moment().month(index).format("MMMM").toUpperCase()}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl className="textfield" fullWidth size="small">
                                                        <OutlinedInput className="input-text"
                                                            value={
                                                                requestData.rate_discount_rules.default.discount
                                                                    .branddotcom.month[item]
                                                            }
                                                            onChange={(e) => {
                                                                updateRequestData(
                                                                    "rate_discount_rules>branddotcom",
                                                                    e.target.value,
                                                                    index
                                                                );
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">%</InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={1}></Grid>
                                                <Grid item xs={4}>
                                                    <FormControl className="textfield" fullWidth size="small">
                                                        <OutlinedInput className="input-text"
                                                            value={
                                                                requestData.rate_discount_rules.default.discount
                                                                    .ota.month[item]
                                                            }
                                                            onChange={(e) => {
                                                                updateRequestData(
                                                                    "rate_discount_rules>ota",
                                                                    e.target.value,
                                                                    index
                                                                );
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">%</InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Box>

                                {/* <Divider sx={{clear:'both', mt:1, mb:2}} /> */}
                            </Box>
                        </Box>
                        {isrequesting && (
                            <Box
                                sx={{
                                    textAlighn: "center",
                                    backgroundColor: "dark.darkest",
                                    opacity: 0.8,
                                    position: "fixed",
                                    top: 40,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 100,
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}
        </div>
    );
}

function AlertsSettings(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const [expanded, setExpanded] = useState({});

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState({});
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;

    const settings_ = JSON.parse(
        JSON.stringify({
            notification_settings: system_settings[0].notification_settings,
        })
    );
    const settings = JSON.parse(
        JSON.stringify({
            notification_settings: system_settings[0].notification_settings,
        })
    );

    //const settings = JSON.parse(JSON.stringify(system_settings[0].market_segment_category_mapping));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const [selectedResource, setSelectedResource] = useState("market");
    const [compsetNames, setCompsetNames] = useState([]);
    let compsetPropOptions = compsetNames.map(item => {
        return { display: item, value: item };
    });
    const resourceOptions = [{ display: "Market", value: "market" },
    { display: "Hotel", value: "property" },
    { display: "Compset", value: "compset" },
    ...compsetPropOptions
    ]
    const getKpiOptions = (selectedResource) => {
        let options = [];

        switch (selectedResource) {
            case "market":
                options = [{ display: "Occupancy", value: "occ" },
                { display: "ADR", value: "adr" },
                { display: "RevPar", value: "revpar" },
                { display: "Revenue", value: "revenue" },
                { display: "Rooms Sold", value: "rooms_sold" },
                { display: "Rooms Available", value: "rooms_available" },
                ]
                break;
            case "property":
                options = [{ display: "Occupancy", value: "occ" },
                { display: "ADR", value: "adr" },
                { display: "RevPar", value: "revpar" },
                { display: "Revenue", value: "revenue" },
                { display: "Rooms Sold", value: "rooms_sold" },
                { display: "Rooms Available", value: "rooms_available" },
                { display: "Rate", value: "rate" },
                ]
                break;
            case "compset":
                options = [{ display: "Median Rate", value: "median" },
                { display: "Average Rate", value: "average" },

                ]
                break;

            default:
                options = [{ display: "Rate", value: "rate" },
                ]
                break;
        }
        return options
    }

    const [resetBtnActive, setResetBtnActive] = useState(false);

    const [kpiOptions, setkpiOptions] = useState(getKpiOptions(selectedResource))
    const [selectedKpi, setSelectedKpi] = useState(kpiOptions[0].value);

    const getUnitOptions = (selectedKpi) => {
        let options = [];
        if (selectedKpi === "occ") {
            options = [{ display: "%", value: "percentage" }]

        }
        if (selectedKpi === "rooms_sold") {
            options = [{ display: "Rooms", value: "rooms" }]
        }
        if (selectedKpi === "rooms_available") {
            options = [{ display: "Rooms", value: "rooms" },
            { display: "%", value: "percentage" }
            ]
        }
        if (selectedKpi === "adr" || selectedKpi === "revpar" || selectedKpi === "revenue" ||
            selectedKpi === "median" || selectedKpi === "average" || selectedKpi === "rate") {
            options = [{ display: "%", value: "percentage" },
            { display: ui_state.currency_symbol, value: ui_state.currency_symbol }
            ]
        }

        return options;

    }
    const [unitOptions, setUnitOptions] = useState(getUnitOptions(selectedKpi));
    const [selectedUnit, setSelectedUnit] = useState(unitOptions[0].value);
    const [delta, setDelta] = useState("decrease");
    const [unitVal, setUnitVal] = useState("");
    const [days, setDays] = useState("");
    const [ruleAlerts, setRuleAlerts] = useState([]);

    const getAlerts = async () => {
        let requestAPI = "alerts/rules/" + app_state.property_id + "/"
        try {
            let response = await fetchDataSimple({ api: requestAPI, request: { "method": "GET" } });
            console.log(response);
            if (response.status === "success" && response.setting) {
                setRuleAlerts(response.setting.rules)
            }

        } catch (error) {
            console.log(error);
        }

    };
    const getCompsetNames = async () => {
        let requestAPI = "rms/properties/compset-names/" + app_state.property_id + "/";
        try {
            let response = await fetchDataSimple({ api: requestAPI, request: { "method": "GET" } });
            console.log(response);
            if (response.status === "success") {
                setCompsetNames(response.compset)
            }

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getCompsetNames();
        getAlerts();
        return () => {

        }
    }, [])
    const cancelSave = () => {
        //setRequestData();
        //setIsChangeRequest(false);
        setUnitVal("");
        setDays("");
        setDelta("decrease");

        const value = "market";
        setSelectedResource(value);
        const kpiOptions = getKpiOptions(value);
        setSelectedKpi(kpiOptions[0].value);
        setkpiOptions(kpiOptions);
        const unitOptions = getUnitOptions(kpiOptions[0].value);
        setUnitOptions(unitOptions);
        setSelectedUnit(unitOptions[0].value);

        setResetBtnActive(false);
    };
    // const updateRequestData = (keys, val, index) => {
    //     const updatedObj = { ...requestData };
    //     const key = keys.split(">");

    //     if (key[0] == "notification_settings") {
    //         if (key[1] == "dynamic_notifications") {
    //             updatedObj[key[0]][key[1]][index][key[2]] = val;
    //         } else {
    //             if (key[2] == "threshold_value" || key[2] == "range_horizon") {
    //                 updatedObj[key[0]][key[1]][key[2]] = isNaN(Number(val))
    //                     ? 0
    //                     : Number(val);
    //             } else {
    //                 updatedObj[key[0]][key[1]][key[2]] = val;
    //             }
    //         }
    //     }

    //     setRequestData({ ...requestData, ...updatedObj });
    //     setIsChangeRequest(true);
    // };

    const addNewAlert = async () => {
        setIsRequesting(true);
        let alertType = "pickup";
        if (selectedResource !== "market" && selectedResource !== "property") {
            alertType = "compset";
        }
        let newAlert = {
            "name": "",
            "resource_type": selectedResource,
            "alert_type": alertType,
            "kpi": selectedKpi,
            "delta": delta,
            "threshold": unitVal,
            "unit": selectedUnit,
            //"dba_range": days,
            "pickup": days
        };
        let payload = {
            "newRules": [
                { ...newAlert }

            ],
            "method": "POST"
        }
        let requestAPI = "alerts/rules/" + app_state.property_id + "/"
        try {
            let response = await fetchDataSimple({ api: requestAPI, request: payload });
            console.log(response);
            if (response.status === "success" && response.setting) {
                setRuleAlerts(response.setting.rules);
                setDays(prev => "");
                setUnitVal(prev => "");
            }
            setIsRequesting(false)
        } catch (error) {
            console.log(error);
            setIsRequesting(false)
        }
        setRequestData({ ...requestData });
    };
    const deleteItem = async (alert) => {
        setIsRequesting(true);
        console.log(alert);
        let payload = {
            "uid": alert.uid,
            "method": "DELETE"
        }
        let requestAPI = "alerts/rules/" + app_state.property_id + "/"
        try {
            let response = await fetchDataSimple({ api: requestAPI, request: payload });
            console.log(response);
            if (response.status === "success" && response.setting) {
                setRuleAlerts(response.setting.rules)
            }
            setIsRequesting(false);
        } catch (error) {
            console.log(error);
            setIsRequesting(false);
        }
    };

    const handleConfirmation = (index) => {
        deleteItem(selectedAlert);
        setOpenConfirm(false)
    };
    const handleConfirmationCancel = () => {
        setOpenConfirm(false)
    }

    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        //reverting changes back
        setUnitVal("");
        setDays("");
        setDelta("decrease");
        const value = "market";
        setSelectedResource(value);
        const kpiOptions = getKpiOptions(value);
        setSelectedKpi(kpiOptions[0].value);
        setkpiOptions(kpiOptions);
        const unitOptions = getUnitOptions(kpiOptions[0].value);
        setUnitOptions(unitOptions);
        setSelectedUnit(unitOptions[0].value);
        setResetBtnActive(false);

        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }
    const customDropdownIcon = (
        <i style={{ color: "#606060", height: "25px", width: "25px", alignItems: "center", fontSize: "20px", marginRight: "8px", cursor: "pointer" }} class="ph-bold ph-caret-down"></i>
    );

    const [expandArea1, setExpandArea1] = useState(false);
    const handleCustomIconClick1 = () => {
        // Toggle the value of expandArea when the custom icon is clicked
        setExpandArea1(!expandArea1);
    };
    const [expandArea2, setExpandArea2] = useState(false);
    const handleCustomIconClick2 = () => {
        // Toggle the value of expandArea when the custom icon is clicked
        setExpandArea2(!expandArea2);
    };
    const [expandArea3, setExpandArea3] = useState(false);
    const handleCustomIconClick3 = () => {
        // Toggle the value of expandArea when the custom icon is clicked
        setExpandArea3(!expandArea3);
    };
    const [expandArea4, setExpandArea4] = useState(false);
    const handleCustomIconClick4 = () => {
        // Toggle the value of expandArea when the custom icon is clicked
        setExpandArea4(!expandArea4);
    };

    // console.log(system_settings);
    let menuItemStyle = {
        textTransform: "uppercase",
        fontSize: "13px"
    }
    let createAlertBox = (<Paper elevation={2} className="create-alert-section" variant="outlined" sx={{ mb: 1, overflow: "hidden" }} >
        <h2 className="title" >Create New Alerts</h2>
        <Box sx={{ fontSize: "15px" }}>
            <p className="create-new-alert-p">
                If
                <Select
                    size="small"
                    sx={{ height: "26px", mr: 1 }}
                    value={selectedResource}
                    className="create-alert-select"
                    onChange={(e) => {
                        const value = e.target.value;
                        setSelectedResource(value);
                        const kpiOptions = getKpiOptions(value);
                        setSelectedKpi(kpiOptions[0].value);
                        setkpiOptions(kpiOptions);
                        const unitOptions = getUnitOptions(kpiOptions[0].value);
                        setUnitOptions(unitOptions);
                        setSelectedUnit(unitOptions[0].value);
                        setResetBtnActive(true);
                    }}
                    //IconComponent={() => customDropdownIcon}
                    open={expandArea1}
                    onClose={() => setExpandArea1(false)} // Close the select when the menu is closed
                    onOpen={() => setExpandArea1(true)} // Open the select when the menu is opened
                    IconComponent={() => (
                        <i
                            onClick={handleCustomIconClick1}
                            style={{
                                color: "#606060",
                                height: "25px",
                                width: "25px",
                                alignItems: "center",
                                fontSize: "20px",
                                marginRight: "8px",
                                cursor: "pointer"
                            }}
                            className={`ph-bold ${expandArea1 ? "ph-caret-up" : "ph-caret-down"}`}
                        ></i>
                    )}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                >
                    {resourceOptions.map((item, index) => {
                        return (<MenuItem key={index} value={item.value} style={menuItemStyle}>{item.display}</MenuItem>)
                    })}
                </Select>
                <Select
                    size="small"
                    className="create-alert-select"
                    sx={{ height: "26px", mr: 1 }}
                    value={selectedKpi}
                    onChange={(e) => {
                        const value = e.target.value;
                        setSelectedKpi(value);
                        const unitOptions = getUnitOptions(value);
                        setSelectedUnit(unitOptions[0].value);
                        setUnitOptions(unitOptions);
                        setResetBtnActive(true);
                    }}
                    //IconComponent={() => customDropdownIcon}
                    open={expandArea2}
                    onClose={() => setExpandArea2(false)} // Close the select when the menu is closed
                    onOpen={() => setExpandArea2(true)} // Open the select when the menu is opened
                    IconComponent={() => (
                        <i
                            onClick={handleCustomIconClick2}
                            style={{
                                color: "#606060",
                                height: "25px",
                                width: "25px",
                                alignItems: "center",
                                fontSize: "20px",
                                marginRight: "8px",
                                cursor: "pointer"
                            }}
                            className={`ph-bold ${expandArea2 ? "ph-caret-up" : "ph-caret-down"}`}
                        ></i>
                    )}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                >
                    {kpiOptions.map((item, index) => {
                        return (<MenuItem key={index} style={menuItemStyle} value={item.value}>{item.display}</MenuItem>)
                    })}
                </Select>
                <Select
                    size="small"
                    className="create-alert-select"
                    sx={{ height: "26px", mr: 1 }}
                    value={delta}
                    onChange={(e) => {
                        const value = e.target.value;
                        setDelta(value);
                        setResetBtnActive(true);
                    }}
                    //IconComponent={() => customDropdownIcon}
                    open={expandArea3}
                    onClose={() => setExpandArea3(false)} // Close the select when the menu is closed
                    onOpen={() => setExpandArea3(true)} // Open the select when the menu is opened
                    IconComponent={() => (
                        <i
                            onClick={handleCustomIconClick3}
                            style={{
                                color: "#606060",
                                height: "25px",
                                width: "25px",
                                alignItems: "center",
                                fontSize: "20px",
                                marginRight: "8px",
                                cursor: "pointer"
                            }}
                            className={`ph-bold ${expandArea3 ? "ph-caret-up" : "ph-caret-down"}`}
                        ></i>
                    )}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                >
                    <MenuItem style={menuItemStyle} value="decrease">Decrease</MenuItem>
                    <MenuItem style={menuItemStyle} value="increase">Increase</MenuItem>
                </Select>
                by
                <OutlinedInput
                    value={unitVal}
                    className="create-alert-input"
                    onChange={e => {
                        let { value } = e.target;
                        setUnitVal(prev => {
                            return value;
                        });
                        setResetBtnActive(true);
                    }}
                    onBlur={e => {
                        let { value } = e.target;
                        value = Number(value);
                        if (isNaN(value) || value <= 0) {
                            value = "";
                        }
                        setUnitVal(prev => {
                            return value;
                        });
                    }}
                />
                <Select
                    size="small"
                    className="create-alert-select"
                    sx={{ height: "26px", mr: 1 }}
                    value={selectedUnit}
                    onChange={(e) => {
                        const value = e.target.value;
                        console.log(value);
                        setSelectedUnit(value);
                        setResetBtnActive(true);

                    }}
                    //IconComponent={() => customDropdownIcon}
                    open={expandArea4}
                    onClose={() => setExpandArea4(false)} // Close the select when the menu is closed
                    onOpen={() => setExpandArea4(true)} // Open the select when the menu is opened
                    IconComponent={() => (
                        <i
                            onClick={handleCustomIconClick4}
                            style={{
                                color: "#606060",
                                height: "25px",
                                width: "25px",
                                alignItems: "center",
                                fontSize: "20px",
                                marginRight: "8px",
                                cursor: "pointer"
                            }}
                            className={`ph-bold ${expandArea4 ? "ph-caret-up" : "ph-caret-down"}`}
                        ></i>
                    )}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                >
                    {unitOptions.map((item, index) => {
                        return (<MenuItem style={menuItemStyle} value={item.value} key={index} >{item.display}</MenuItem>)
                    })}

                </Select>
                compared to past
                <OutlinedInput
                    value={days}
                    className="create-alert-input"
                    onChange={e => {
                        let { value } = e.target;
                        setDays(prev => {
                            return value;
                        });
                        setResetBtnActive(true);
                    }}
                    onBlur={e => {
                        let { value } = e.target;
                        value = toInteger(value);
                        if (isNaN(value) || value <= 0) {
                            value = "";
                        }
                        setDays(prev => {
                            return value;
                        });
                    }}
                />
                days
            </p>
        </Box>
        <Box>
            {/* <Button
                // disabled={!isChangeRequest}
                onClick={cancelSave}
                variant="outlined"
                className={(true) ? "navigate-button btn-active" : "navigate-button"}

                // color="error"
                size="small"
                sx={{ minWidth: "auto" }}
            >
                cancel
            </Button>
            <Button
                disabled={
                    !days || !unitVal
                }
                onClick={() => {
                    addNewAlert();
                }}
                variant="outlined"
                size="small"
                className={(!days || !unitVal) ? "navigate-button" : "navigate-button btn-active"}

                // color="green"
                sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
            >
                <span style={{ display: !isrequesting ? "block" : "none" }}>
                    Save
                </span>
                <span
                    style={{
                        display: isrequesting ? "block" : "none",
                        paddingRight: 10,
                    }}
                >
                    saving...
                </span>
                <CircularProgress
                    sx={{ display: isrequesting ? "block" : "none" }}
                    color="white"
                    size="16px"
                />
            </Button> */}

            <Box className='profile-buttons'>
                <Button
                    className='profile-cancel-btn'
                    disabled={!resetBtnActive}
                    onClick={cancelSave}
                >
                    Reset
                </Button>
                <Button
                    className='profile-save-btn'
                    disabled={!days || !unitVal}
                    onClick={() => {
                        addNewAlert();
                    }}
                >
                    <span style={{ display: !isrequesting ? "block" : "none" }}>
                        Save
                    </span>
                    <span
                        style={{
                            display: isrequesting ? "block" : "none",
                            paddingRight: 10,
                        }}
                    >
                        saving...
                    </span>
                </Button>
            </Box>
        </Box>
    </Paper>)

    return (
        <div>
            <Box className='top-axn-bar-1'
            >
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }

                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Alerts</span>
                </Box>
            </Box>

            <Box className="bgwhite main-scroll-wrap-segments" sx={{ pl: 2, pr: 2 }}>

                <div className='profile-heading'>ALERTS</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>

                <Box className="inventory-settings alert-settings" >
                    <UserConfirmationAlert open={openConfirm}
                        onConfirm={handleConfirmation} index={1} onCancel={handleConfirmationCancel}
                    />

                    <Grid>
                        <Box
                            sx={{
                                pt: 2,
                                flexDirection: "column",
                                position: "relative",
                                display: "flex",
                            }}
                        >
                            {/* <Alert
                                severity="success"
                                sx={{
                                    width: "100%",
                                    marginTop: -10,
                                    display: isRequestSuccess ? "flex" : "none",
                                }}
                            >
                                {requestSuccessMsg}
                            </Alert>
                            <Alert
                                severity="error"
                                sx={{
                                    width: "100%",
                                    marginTop: -10,
                                    display: isRequestError ? "flex" : "none",
                                }}
                            >
                                {resErrorMsg}
                            </Alert> */}

                            <Box sx={{ mt: { lg: 0 } }}>

                                {createAlertBox}

                                <Box sx={{ mt: 4, mb: 2 }}>
                                    {ruleAlerts.map(
                                        (item, index) => {
                                            return (
                                                <Paper elevation={2} sx={{ mb: 1, overflow: "hidden", borderRadius: "6px" }} variant="outlined">
                                                    <Grid
                                                        container
                                                        className="header alertitem"
                                                        sx={{ backgroundColor: "#F8F9FA" }}
                                                    >
                                                        <div className="rate-push-badge">{index + 1}</div>

                                                        <Grid item xs={11} className="alert-text-section">
                                                            <p className="alert-text">{item.text_rule}</p>
                                                            <div className="bottom-text">{item.desc}</div> {/* might change to bottom text */}
                                                            {/* <div className="bottom-text">Created By: {item.created_by}, Created Date: {moment(item.ts).format("ddd, MMM DD, YYYY")}</div> */}
                                                        </Grid>

                                                        <Grid item xs={1} textAlign="right">
                                                            <Button
                                                                variant="text"
                                                                onClick={() => {
                                                                    setOpenConfirm(true);
                                                                    setSelectedAlert(item);
                                                                }}
                                                                className="deleteBtn"
                                                            >
                                                                <Tooltip title="Delete">
                                                                    {/* <DeleteOutlineOutlinedIcon
                                                                        fontSize="small"
                                                                        color="error"
                                                                        className="arrow-down inside-line-rateplan expend-arrow delete-icon-button"
                                                                    ></DeleteOutlineOutlinedIcon> */}
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#E03131" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>
                                                                </Tooltip>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>


                                                </Paper>
                                            );
                                        }
                                    )}
                                </Box>

                            </Box>

                            {isrequesting && (
                                <Box
                                    sx={{
                                        textAlighn: "center",
                                        backgroundColor: "dark.darkest",
                                        opacity: 0.8,
                                        position: "fixed",
                                        top: 40,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        zIndex: 100,
                                    }}
                                >
                                    <CircularProgress
                                        size={40}
                                        sx={{
                                            color: "white",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-20px",
                                            marginLeft: "-20px",
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid>

                </Box>

            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}

        </div>
    );
}

function ManageRatePlans(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const [expanded, setExpanded] = useState({});

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;

    const settings_ = JSON.parse(
        JSON.stringify({
            notification_settings: system_settings[0].notification_settings,
        })
    );
    const settings = JSON.parse(
        JSON.stringify({
            notification_settings: system_settings[0].notification_settings,
        })
    );
    if (
        settings.notification_settings.pickup_up_adr.threshold_type === "percent"
    ) {
        settings.notification_settings.pickup_up_adr.threshold_value = Math.round(
            settings.notification_settings.pickup_up_adr.threshold_value * 100
        );
    }
    if (
        settings.notification_settings.pickup_down_adr.threshold_type === "percent"
    ) {
        settings.notification_settings.pickup_down_adr.threshold_value = Math.round(
            settings.notification_settings.pickup_down_adr.threshold_value * 100
        );
    }
    if (
        settings.notification_settings.pickup_up_occ.threshold_type === "percent"
    ) {
        settings.notification_settings.pickup_up_occ.threshold_value = Math.round(
            settings.notification_settings.pickup_up_occ.threshold_value * 100
        );
    }
    if (
        settings.notification_settings.pickup_down_occ.threshold_type === "percent"
    ) {
        settings.notification_settings.pickup_down_occ.threshold_value = Math.round(
            settings.notification_settings.pickup_down_occ.threshold_value * 100
        );
    }

    //const settings = JSON.parse(JSON.stringify(system_settings[0].market_segment_category_mapping));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");

        if (key[0] == "notification_settings") {
            if (key[1] == "dynamic_notifications") {
                updatedObj[key[0]][key[1]][index][key[2]] = val;
            } else {
                if (key[2] == "threshold_value" || key[2] == "range_horizon") {
                    updatedObj[key[0]][key[1]][key[2]] = isNaN(Number(val))
                        ? 0
                        : Number(val);
                } else {
                    updatedObj[key[0]][key[1]][key[2]] = val;
                }
            }
        }

        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const addNewDynamicAlert = () => {
        const item = {
            active: false,
            name:
                "New Dynamic Notification " +
                (requestData.notification_settings.dynamic_notifications.length + 1),
            reference_source: "hotel_otb",
            field: "occ",
            field_comparison: "higher",
            comparison_operation: "",
            reference_comparison_source: "yeseterday_hotel_otb",
            field_scope: "total",
            field_scope_filter: "BAR",
            alert_level: "FYI",
        };
        requestData.notification_settings.dynamic_notifications.push(item);
        setRequestData({ ...requestData });
    };


    const deleteItem = (index) => {
        requestData.notification_settings.dynamic_notifications.splice(index, 1);
        setRequestData({ ...requestData });
    };
    const saveChanges = (event) => {
        setIsRequesting(true);
        const notification_settings = JSON.parse(
            JSON.stringify(requestData.notification_settings)
        );
        if (notification_settings.pickup_up_adr.threshold_type === "percent") {
            notification_settings.pickup_up_adr.threshold_value =
                notification_settings.pickup_up_adr.threshold_value / 100;
        }
        if (notification_settings.pickup_down_adr.threshold_type === "percent") {
            notification_settings.pickup_down_adr.threshold_value =
                notification_settings.pickup_down_adr.threshold_value / 100;
        }
        if (notification_settings.pickup_up_occ.threshold_type === "percent") {
            notification_settings.pickup_up_occ.threshold_value =
                notification_settings.pickup_up_occ.threshold_value / 100;
        }
        if (notification_settings.pickup_down_occ.threshold_type === "percent") {
            notification_settings.pickup_down_occ.threshold_value =
                notification_settings.pickup_down_occ.threshold_value / 100;
        }
        const request_system_settings = {
            ...system_settings[0],
            notification_settings: notification_settings,
        };

        // debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    const handleExpand = (index) => {
        if (!expanded["id_" + index]) {
            expanded["id_" + index] = true;
        } else {
            expanded["id_" + index] = false;
        }
        setExpanded({ ...expanded });
    };
    // console.log(system_settings);

    return (
        <div>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Property Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Manage Rate Plans
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Manage Rate Plans
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="inventory-settings border-radious4 " sx={{ p: 1, pt: 0, mb: 2, mt: 2, ml: 1, mr: 1 }}>


                {/* <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} /> */}
                <Paper>
                    <Grid className="property-setting-container-spacing">
                        <Box
                            sx={{
                                pt: 2,
                                flexDirection: "column",
                                position: "relative",
                                display: "flex",
                            }}
                        >
                            <Alert
                                severity="success"
                                sx={{
                                    width: "100%",
                                    marginTop: -10,
                                    display: isRequestSuccess ? "flex" : "none",
                                }}
                            >
                                {requestSuccessMsg}
                            </Alert>
                            <Alert
                                severity="error"
                                sx={{
                                    width: "100%",
                                    marginTop: -10,
                                    display: isRequestError ? "flex" : "none",
                                }}
                            >
                                {resErrorMsg}
                            </Alert>
                            <Alert
                                severity="error"
                                className="save-changes-alert display-none"
                                sx={{ marginTop: "10px" }}
                            >
                                {unsavedAlertMsg}
                            </Alert>

                            <Box sx={{ mt: { lg: 0 } }}>
                                <Box sx={{ mt: 1, mb: 2, display: "none" }}>
                                    {requestData.notification_settings.dynamic_notifications.map(
                                        (item, index) => {
                                            return (
                                                <Paper elevation={2} sx={{ mb: 1, overflow: "hidden" }}>
                                                    <Grid
                                                        container
                                                        className="header"
                                                        sx={{ backgroundColor: "blue.main" }}
                                                    >
                                                        <Grid item xs={6}>
                                                            <Input
                                                                fullWidth
                                                                className="accordian-field"
                                                                size="small"
                                                                sx={{
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    mt: 1,
                                                                    textAlign: "center",
                                                                    color: "blue.dark",
                                                                }}
                                                                value={item.name}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>name",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={3} textAlign="right">
                                                            <Switch
                                                                className=""
                                                                size="medium"
                                                                color="green"
                                                                checked={item.active}
                                                                onChange={(e) => {
                                                                    const checked = e.target.checked;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>active",
                                                                        checked,
                                                                        index
                                                                    );
                                                                }}
                                                            />{" "}
                                                            <Typography
                                                                sx={{ color: "blue.dark", display: "inline-block" }}
                                                                visibility={item.active ? "hidden" : "visible"}
                                                            >
                                                                Activate
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} textAlign="right">
                                                            <Button
                                                                variant="text"
                                                                onClick={() => {
                                                                    deleteItem(index);
                                                                }}
                                                            >
                                                                <Tooltip title="Delete">
                                                                    <DeleteIcon
                                                                        fontSize="medium"
                                                                        color="warning"
                                                                    ></DeleteIcon>
                                                                </Tooltip>
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                type="button"
                                                                sx={{
                                                                    backgroundColor: "blue.dark",
                                                                    borderRadius: 0,
                                                                    border: "none",
                                                                    margin: 0,
                                                                    pb: 1.5,
                                                                }}
                                                                onClick={() => {
                                                                    handleExpand(index);
                                                                }}
                                                            >
                                                                <ArrowDropDownIcon
                                                                    sx={{
                                                                        display: expanded["id_" + index]
                                                                            ? "none"
                                                                            : "inline-block",
                                                                    }}
                                                                    className="arrow-down"
                                                                />
                                                                <ArrowDropUpIcon
                                                                    sx={{
                                                                        display: expanded["id_" + index]
                                                                            ? "inline-block"
                                                                            : "none",
                                                                    }}
                                                                    className="arrow-up"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                    <Collapse
                                                        in={expanded["id_" + index]}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        <Box sx={{ fontSize: "15px", p: 2 }}>
                                                            When
                                                            <Select
                                                                className="inline-select"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.reference_source}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>reference_source",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="hotel_otb">on the books</MenuItem>
                                                                <MenuItem value="hotel_forecast">forecast</MenuItem>
                                                            </Select>
                                                            <Select
                                                                className="inline-select"
                                                                size="small"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.field}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>field",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="occ">occ</MenuItem>
                                                                <MenuItem value="revpar">revpar</MenuItem>
                                                                <MenuItem value="adr">adr</MenuItem>
                                                                <MenuItem value="rooms">rooms</MenuItem>
                                                            </Select>
                                                            is
                                                            <Select
                                                                className="inline-select"
                                                                size="small"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.field_comparison}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>field_comparison",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="higher">higher</MenuItem>
                                                                <MenuItem value="higher">lower</MenuItem>
                                                            </Select>
                                                            than
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{ width: 40, ml: 0.5, mr: 0.5 }}
                                                                value={item.comparison_operation}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                            vs
                                                            <Select
                                                                className="inline-select"
                                                                size="small"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.reference_comparison_source}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>reference_comparison_source",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="yeseterday_hotel_otb">
                                                                    yesterday's hotel otb
                                                                </MenuItem>
                                                                <MenuItem value="yeseterday_hotel_forecast">
                                                                    yesterday's hotel forecast
                                                                </MenuItem>
                                                                <MenuItem value="sdly_hotel_otb">
                                                                    sdly hotel otb
                                                                </MenuItem>
                                                                <MenuItem value="sdly_hotel_actual">
                                                                    sdly hotel actual{" "}
                                                                </MenuItem>
                                                            </Select>
                                                            <br />
                                                            <br />
                                                            Field Scope
                                                            <Select
                                                                className="inline-select"
                                                                size="small"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.field_scope}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>field_scope",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="total">Total</MenuItem>
                                                                <MenuItem value="segmented">Segmented</MenuItem>
                                                            </Select>
                                                            {item.field_scope == "segmented" && (
                                                                <Box sx={{ display: "inline-block" }}>
                                                                    Field Scope Filter
                                                                    <Select
                                                                        className="inline-select"
                                                                        size="small"
                                                                        sx={{ mr: 1, ml: 1 }}
                                                                        value={item.field_scope_filter}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "notification_settings>dynamic_notifications>field_scope_filter",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        {/* {system_settings[0].market_segment_category_mapping} */}
                                                                        {Object.keys(
                                                                            system_settings[0]
                                                                                .market_segment_category_mapping
                                                                        ).map((key) => {
                                                                            return <MenuItem value={key}>{key}</MenuItem>;
                                                                        })}
                                                                    </Select>
                                                                </Box>
                                                            )}
                                                            Alert level
                                                            <Select
                                                                className="inline-select"
                                                                size="small"
                                                                sx={{ mr: 1, ml: 1 }}
                                                                value={item.alert_level}
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "notification_settings>dynamic_notifications>alert_level",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="FYI">FYI</MenuItem>
                                                                <MenuItem value="Normal">Normal</MenuItem>
                                                                <MenuItem value="Urgent">Urgent</MenuItem>
                                                            </Select>
                                                        </Box>
                                                    </Collapse>
                                                </Paper>
                                            );
                                        }
                                    )}
                                </Box>
                                <Box sx={{ mt: 1, mb: 2 }}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className="add-button"
                                        sx={{
                                            lineHeight: "auto",
                                            backgroundColor: "#fff",
                                            // fontWeight: "bold",
                                        }}
                                        onClick={() => {
                                            addNewDynamicAlert();
                                        }}
                                    >
                                        Add New Plan
                                    </Button>
                                </Box>
                            </Box>
                            {isrequesting && (
                                <Box
                                    sx={{
                                        textAlighn: "center",
                                        backgroundColor: "dark.darkest",
                                        opacity: 0.8,
                                        position: "fixed",
                                        top: 40,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        zIndex: 100,
                                    }}
                                >
                                    <CircularProgress
                                        size={40}
                                        sx={{
                                            color: "white",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-20px",
                                            marginLeft: "-20px",
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Paper>
            </Box>
        </div>
    );
}

function PricingRules(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const [expanded, setExpanded] = useState({});

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;

    const settings_ = JSON.parse(
        JSON.stringify({
            price_rule_settings: system_settings[0].price_rule_settings,
        })
    );
    const settings = JSON.parse(JSON.stringify(settings_));

    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, value, index) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        const size = key.length;
        let val = value;
        if (moment.isMoment(value)) {
            //val = value.format('YYYY-MM-DD');
        } else {
            //val = value
        }
        // debugger;
        if (size == 2) {
            updatedObj[key[0]]["rules"][index][key[1]] = val;
        }
        if (size == 3) {
            if (key[2] === "dow_filter") {
                updatedObj[key[0]]["rules"][index][key[1]][key[2]][val] =
                    !updatedObj[key[0]]["rules"][index][key[1]][key[2]][val];
            } else {
                updatedObj[key[0]]["rules"][index][key[1]][key[2]] = val;
            }
        }
        if (size == 4) {
            updatedObj[key[0]]["rules"][index][key[1]][key[2]][key[3]] = val;
        }
        if (size == 5) {
            updatedObj[key[0]]["rules"][index][key[1]][key[2]][key[3]][key[4]] = val;
        }

        setRequestData({
            ...requestData,
            price_rule_settings: updatedObj.price_rule_settings,
        });
        setIsChangeRequest(true);
    };

    const addNewPricingRule = () => {
        const today = new moment().format("YYYY-MM-DD");
        const tomorrow = new moment().add(1, "days").format("YYYY-MM-DD");
        const item = {
            rule_name:
                "Pricing Rule " + (requestData.price_rule_settings.rules.length + 1),
            open: false,
            rule_period: {
                blackout_date_ranges: [],
                effective_date_range: {
                    from: today,
                    until: tomorrow,
                },
                start_date: today,
                dow_filter: {
                    Sun: true,
                    Mon: true,
                    Tue: true,
                    Wed: true,
                    Thu: true,
                    Fri: true,
                    Sat: true,
                },
                days_before_arrival_range: {
                    from: 0,
                    to: 0,
                },
            },
            rule_conditions: {
                hotel_occupancy_threshold: {
                    rule_type: "value_threshold",
                    threshold_value: 0,
                    range_horizon: 0,
                    threshold_type: "fixed_val",
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
                hotel_occupancy_vs_sdly: {
                    rule_type: "value_threshold",
                    threshold_value: 3,
                    range_horizon: 0,
                    threshold_type: "diff_threshold",
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
                hotel_occupancy_pickup_threshold: {
                    rule_type: "value_threshold",
                    threshold_value: 0,
                    range_horizon: 0,
                    threshold_type: "diff_threshold",
                    pickup_days: 0,
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
                hotel_occupancy_pickup_vs_sdly: {
                    rule_type: "value_threshold",
                    threshold_value: 0,
                    range_horizon: 0,
                    threshold_type: "diff_threshold",
                    pickup_days: 0,
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
                market_forecast_occupancy_threshold: {
                    rule_type: "value_threshold",
                    threshold_value: 0,
                    range_horizon: 0,
                    threshold_type: "fixed_val",
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
                hotel_forecast_occupancy_threshold: {
                    rule_type: "value_threshold",
                    threshold_value: 0,
                    range_horizon: 0,
                    threshold_type: "fixed_val",
                    rule_metrics: ["occ"],
                    comparison_operation: "greater_than",
                    active: false,
                },
            },
            rule_actions: [
                {
                    target_settings: "BAR",
                    target_action: "increase",
                    change_value: 0,
                    change_metric: "currency",
                    target_rule: "greater_than",
                    target_hotel: "Compset_Avg",
                },
            ],
            rule_action_setting: "simple",
            active: false,
        };
        requestData.price_rule_settings.rules.push(item);
        setRequestData({ ...requestData });
    };
    const copyRule = (index) => {
        const clone = JSON.parse(
            JSON.stringify(requestData.price_rule_settings["rules"][index])
        );
        requestData.price_rule_settings.rules.push(clone);
        setRequestData({ ...requestData });
    };
    const addBlackOutDates = (rowIndex) => {
        const from = new moment().format("YYYY-MM-DD");
        const until = new moment().add(1, "days").format("YYYY-MM-DD");
        const datePair = { from: from, until: until };
        requestData.price_rule_settings.rules[
            rowIndex
        ].rule_period.blackout_date_ranges.push(datePair);
        setRequestData({ ...requestData });
    };
    const removeBlackOutDateRange = (rowIndex, itemIndex) => {
        requestData.price_rule_settings.rules[
            rowIndex
        ].rule_period.blackout_date_ranges.splice(itemIndex, 1);
        setRequestData({ ...requestData });
    };
    const deleteItem = (index) => {
        requestData.price_rule_settings.rules.splice(index, 1);
        setRequestData({ ...requestData });
    };
    const saveChanges = (event) => {
        setIsRequesting(true);
        const price_rule_settings = JSON.parse(
            JSON.stringify(requestData.price_rule_settings)
        );
        const request_system_settings = {
            ...system_settings[0],
            price_rule_settings: price_rule_settings,
        };

        //debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };

    const changePricingRuleOrder = (item, index, type) => {
        const size = requestData.price_rule_settings.rules.length;

        if (type == "up" && index) {
            requestData.price_rule_settings.rules[index] =
                requestData.price_rule_settings.rules[index - 1];
            requestData.price_rule_settings.rules[index - 1] = item;

            setRequestData({
                ...requestData,
                rules:
                    requestData.price_rule_settings.rules,
            });
            setIsChangeRequest(true);
            // debugger;
        }
        if (type == "down" && index < size) {
            requestData.price_rule_settings.rules[index] =
                requestData.price_rule_settings.rules[index + 1];
            requestData.price_rule_settings.rules[index + 1] = item;
            setRequestData({
                ...requestData, rules:
                    requestData.price_rule_settings.rules,
            });
            setIsChangeRequest(true);
        }
    };




    const handleExpand = (index) => {
        if (!expanded["id_" + index]) {
            expanded["id_" + index] = true;
        } else {
            expanded["id_" + index] = false;
        }
        setExpanded({ ...expanded });
    };
    // console.log(system_settings);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -40 }}>
                Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Property Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Pricing Rules
            </Box>
            <div className="property-setting-heading">
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Pricing Rules
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                        onClick={() => {
                            addNewPricingRule();
                        }}
                    >
                        Add New
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="inventory-settings " sx={{ p: 1, pt: 0, mb: 2, mt: 0, ml: 1, mr: 1 }}>


                {/* <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} /> */}

                <Grid>
                    <Box
                        sx={{
                            pt: 2,
                            flexDirection: "column",
                            position: "relative",
                            display: "flex",
                        }}
                    >
                        <Alert
                            severity="success"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestSuccess ? "flex" : "none",
                            }}
                        >
                            {requestSuccessMsg}
                        </Alert>
                        <Alert
                            severity="error"
                            sx={{
                                width: "100%",
                                marginTop: -10,
                                display: isRequestError ? "flex" : "none",
                            }}
                        >
                            {resErrorMsg}
                        </Alert>
                        <Alert
                            severity="error"
                            className="save-changes-alert display-none"
                            sx={{ marginTop: "10px" }}
                        >
                            {unsavedAlertMsg}
                        </Alert>

                        <Box
                            sx={{ mt: { lg: 0 }, pr: 0, maxHeight: "70vh", overflow: "auto" }}
                        >
                            <Box sx={{ mt: 0, mb: 2 }}>
                                {requestData.price_rule_settings.rules.map((item, index) => {
                                    return (
                                        <Paper
                                            key={"row-uid-" + index}
                                            className="pricing-rules item"
                                            elevation={2}
                                            sx={{ mb: 2, overflow: "hidden", border: "1px solid #d1d1d1", borderRadius: "4px", marginBottom: "20px" }}
                                        >
                                            <Grid
                                                container
                                                className="header"
                                                sx={{
                                                    backgroundColor: expanded["id_" + index]
                                                        ? "blue.main"
                                                        : "grey.lighter",
                                                }}
                                            >

                                                <Grid item xs={0.3}>
                                                    <Box sx={{ position: "relative", height: "100%", ml: .35, textAlign: "center" }}>
                                                        <Button
                                                            size="small"
                                                            sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                            disabled={index === 0}
                                                            onClick={() => {
                                                                changePricingRuleOrder(item, index, "up");
                                                            }}
                                                        >
                                                            <KeyboardArrowUpOutlinedIcon
                                                                fontSize="large"
                                                                color="{index == 0}? disabled :primary" sx={{ position: "absolute", top: -32, marginLeft: 1.25 }} />
                                                        </Button>
                                                        <span style={{ fontSize: 17, marginTop: 13, display: "inline-block" }}>{index + 1}</span>

                                                        <Button
                                                            size="small"
                                                            sx={{ minWidth: "auto", p: 0, lineHeight: "auto" }}
                                                            disabled={index === requestData.price_rule_settings.rules.length - 1}
                                                        >
                                                            <KeyboardArrowDownOutlinedIcon
                                                                fontSize="large"
                                                                color="{index === requestData.price_rule_settings.rules.length-1}?disabled:primary"
                                                                sx={{ position: "absolute", bottom: -32, ml: -1.2 }}
                                                                onClick={() => {
                                                                    changePricingRuleOrder(item, index, "down");
                                                                }}
                                                            />

                                                        </Button>
                                                    </Box>
                                                </Grid>


                                                <Grid item xs={2}>

                                                    <Input
                                                        className="accordian-field"
                                                        size="small"
                                                        sx={{
                                                            ml: 4,
                                                            mr: 1,
                                                            mt: 1,
                                                            textAlign: "center",
                                                            color: "blue.dark",
                                                        }}
                                                        value={item.rule_name}
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            updateRequestData(
                                                                "price_rule_settings>rule_name",
                                                                value,
                                                                index
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3.4}>
                                                    <Box
                                                        sx={{ ml: 0, mt: 0.8, color: "#1976d2" }}
                                                        className="checkbox-push-btns pricing-rule-dow"
                                                    >
                                                        <ButtonGroup disableElevation variant="contained">

                                                            {Object.keys(item.rule_period.dow_filter).map(
                                                                (dow) => (
                                                                    <Button
                                                                        // className="pricing-rule-dow"
                                                                        sx={{ paddingLeft: 0.5, paddingRight: 0.5, border: "1px solid #1565c0" }}

                                                                        key={"dow-uid-" + dow}
                                                                        size="small"
                                                                        color={
                                                                            item.rule_period.dow_filter[dow]
                                                                                ? "primary"
                                                                                : "white"
                                                                        }
                                                                        onClick={() => {
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_period>dow_filter",
                                                                                dow,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        {/* {dow} */}
                                                                        {dow.charAt(0)}
                                                                    </Button>
                                                                )
                                                            )}
                                                        </ButtonGroup>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={3.5}>
                                                    <MobileDatePicker
                                                        sx={{ fontSize: 10 }}
                                                        inputFormat="ddd, MMM DD YYYY"
                                                        disablePast={true}
                                                        value={item.rule_period.effective_date_range.from}
                                                        onChange={(val) => {
                                                            const formated = new moment(val).format(
                                                                "YYYY-MM-DD"
                                                            );
                                                            updateRequestData(
                                                                "price_rule_settings>rule_period>effective_date_range>from",
                                                                formated,
                                                                index
                                                            );
                                                        }}
                                                        renderInput={(params) => (
                                                            <Tooltip title="Effective From">
                                                                <TextField
                                                                    label="From"
                                                                    className="date-picker textfield"
                                                                    variant="standard"
                                                                    sx={{ mt: 0.5, ml: 0.5, width: 140 }}
                                                                    {...params}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    />
                                                    <MobileDatePicker
                                                        inputFormat="ddd, MMM DD YYYY"
                                                        value={item.rule_period.effective_date_range.until}
                                                        onChange={(val) => {
                                                            const formated = val.format("YYYY-MM-DD");
                                                            updateRequestData(
                                                                "price_rule_settings>rule_period>effective_date_range>until",
                                                                formated,
                                                                index
                                                            );
                                                        }}
                                                        renderInput={(params) => (
                                                            <Tooltip title="Effective Until">
                                                                <TextField
                                                                    label="From"
                                                                    className="date-picker textfield"
                                                                    variant="standard"
                                                                    sx={{ mt: 0.5, ml: 1, width: 140 }}
                                                                    {...params}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    />
                                                </Grid>

                                                <Grid item xs={2.8} textAlign="right">
                                                    <Switch
                                                        className="switch-button"
                                                        size="medium"
                                                        // color="green"
                                                        checked={item.active}
                                                        onChange={(e) => {
                                                            const checked = e.target.checked;
                                                            updateRequestData(
                                                                "price_rule_settings>active",
                                                                checked,
                                                                index
                                                            );
                                                        }}
                                                    />
                                                    {/* <Typography
                          sx={{ display: "inline-block" }}
                          visibility={item.active ? "hidden" : "visible"}
                        >
                          Activate
                        </Typography> */}

                                                    <Button
                                                        variant="text"
                                                        onClick={() => {
                                                            copyRule(index);
                                                        }}
                                                    >
                                                        <Tooltip title="Copy Rule">
                                                            <CopyRuleIcon
                                                                fontSize="small"
                                                                color="primary"
                                                            ></CopyRuleIcon>
                                                        </Tooltip>
                                                    </Button>
                                                    <Button
                                                        variant="text"
                                                        onClick={() => {
                                                            deleteItem(index);
                                                        }}
                                                    >
                                                        <Tooltip title="Delete">
                                                            <DeleteOutlineOutlinedIcon
                                                                className="arrow-down inside-line-rateplan expend-arrow delete-icon-button"

                                                                color="error"
                                                            ></DeleteOutlineOutlinedIcon>
                                                        </Tooltip>
                                                    </Button>
                                                    <Button
                                                        variant="text"
                                                        type="button"

                                                        onClick={() => {
                                                            handleExpand(index);
                                                        }}
                                                    >
                                                        <KeyboardArrowDownOutlined

                                                            sx={{
                                                                display: expanded["id_" + index]
                                                                    ? "none"
                                                                    : "inline-block",
                                                                fontSize: 40,
                                                            }}
                                                            // color="primary"
                                                            className="arrow-down inside-line-rateplan expend-arrow button-grey"
                                                        />
                                                        <KeyboardArrowUpOutlinedIcon
                                                            color="primary"
                                                            sx={{
                                                                display: expanded["id_" + index]
                                                                    ? "inline-block"
                                                                    : "none",
                                                                fontSize: 40,
                                                            }}
                                                            className="arrow-down inside-line-rateplan expend-arrow button-grey"
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            <Collapse
                                                in={expanded["id_" + index]}
                                                timeout="auto"
                                                unmountOnExit
                                                sx={{
                                                    backgroundColor: "white",
                                                    maxHeight: "55vh",
                                                    overflow: "auto",
                                                }}
                                            >
                                                <Box sx={{ fontSize: "15px", p: 2 }}>
                                                    <Box display="inline-block" sx={{ float: "left" }}>
                                                        Start Date
                                                        <MobileDatePicker
                                                            sx={{ fontSize: 10 }}
                                                            inputFormat="dd, MMM DD YYYY"
                                                            value={item.rule_period.effective_date_range.from}
                                                            onChange={(val) => {
                                                                const formated = val.format("YYYY-MM-DD");
                                                                updateRequestData(
                                                                    "price_rule_settings>rule_period>effective_date_range>from",
                                                                    formated,
                                                                    index
                                                                );
                                                            }}
                                                            renderInput={(params) => (
                                                                <Tooltip title="Effective From">
                                                                    <TextField
                                                                        label="From"
                                                                        className="date-picker textfield"
                                                                        variant="standard"
                                                                        sx={{ mt: -1, ml: 0.5, mr: 1, width: 110 }}
                                                                        {...params}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        />
                                                        Between
                                                        <Input
                                                            className="alert-input inline-input"
                                                            size="small"
                                                            sx={{
                                                                width: 40,
                                                                position: "relative",
                                                                top: -4,
                                                                ml: 0.5,
                                                                mr: 0.5,
                                                            }}
                                                            value={
                                                                item.rule_period.days_before_arrival_range.from
                                                            }
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                updateRequestData(
                                                                    "price_rule_settings>rule_period>days_before_arrival_range>from",
                                                                    value,
                                                                    index
                                                                );
                                                            }}
                                                        />
                                                        &#38;
                                                        <Input
                                                            className="alert-input inline-input"
                                                            size="small"
                                                            sx={{
                                                                width: 40,
                                                                position: "relative",
                                                                top: -4,
                                                                ml: 0.5,
                                                                mr: 0.5,
                                                            }}
                                                            value={
                                                                item.rule_period.days_before_arrival_range.to
                                                            }
                                                            onChange={(e) => {
                                                                const value = e.target.value;
                                                                updateRequestData(
                                                                    "price_rule_settings>rule_period>days_before_arrival_range>to",
                                                                    value,
                                                                    index
                                                                );
                                                            }}
                                                        />
                                                        days before arrival
                                                        <Typography
                                                            fontWeight="bold"
                                                            display="inline-block"
                                                            sx={{ ml: 0.5, fontSize: 14 }}
                                                        >
                                                            Black out Dates
                                                        </Typography>
                                                    </Box>

                                                    <Box display="inline-block" float="left">
                                                        <Button
                                                            type="button"
                                                            variant="contained"
                                                            size="small"
                                                            className="add-button"
                                                            // color="green"
                                                            sx={{
                                                                backgroundColor: "#fff",
                                                                fontWeight: "bold",
                                                                ml: 1,
                                                                mt: -1,
                                                                minWidth: "auto",
                                                                textTransform: "none",
                                                                float: "left",
                                                                display: "inline-block",
                                                                color: "white.main",
                                                            }}
                                                            onClick={(e) => {
                                                                addBlackOutDates(index);
                                                            }}
                                                        >
                                                            + Add
                                                        </Button>
                                                        <Box
                                                            float="left"
                                                            sx={{ display: "inline-block", mt: -1 }}
                                                        >
                                                            {item.rule_period.blackout_date_ranges.map(
                                                                (dateItem, ind) => (
                                                                    <Box
                                                                        sx={{
                                                                            pb: 1,
                                                                            backgroundColor: "grey.lighter",
                                                                            p: 1,
                                                                            m: 0.5,
                                                                            mt: 0,
                                                                        }}
                                                                    >
                                                                        <MobileDatePicker
                                                                            sx={{ fontSize: 10 }}
                                                                            inputFormat="ddd, MMM DD YYYY"
                                                                            value={dateItem.from}
                                                                            onChange={(val) => {
                                                                                const formated = val.format("YYYY-MM-DD");
                                                                                updateRequestData(
                                                                                    "price_rule_settings>rule_period>blackout_date_ranges>" +
                                                                                    ind +
                                                                                    ">from",
                                                                                    formated,
                                                                                    index
                                                                                );
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <Tooltip title="From">
                                                                                    <TextField
                                                                                        label="From"
                                                                                        className="date-picker"
                                                                                        variant="standard"
                                                                                        sx={{
                                                                                            mt: -1,
                                                                                            ml: 0.5,
                                                                                            mr: 1,
                                                                                            width: 110,
                                                                                        }}
                                                                                        {...params}
                                                                                    />
                                                                                </Tooltip>
                                                                            )}
                                                                        />
                                                                        <MobileDatePicker
                                                                            sx={{ fontSize: 10 }}
                                                                            inputFormat="ddd, MMM DD YYYY"
                                                                            value={dateItem.until}
                                                                            onChange={(val) => {
                                                                                const formated = val.format("YYYY-MM-DD");
                                                                                updateRequestData(
                                                                                    "price_rule_settings>rule_period>blackout_date_ranges>" +
                                                                                    ind +
                                                                                    ">until",
                                                                                    formated,
                                                                                    index
                                                                                );
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <Tooltip title="To">
                                                                                    <TextField
                                                                                        label="From"
                                                                                        className="date-picker"
                                                                                        variant="standard"
                                                                                        sx={{
                                                                                            mt: -1,
                                                                                            ml: 0.5,
                                                                                            mr: 1,
                                                                                            width: 110,
                                                                                        }}
                                                                                        {...params}
                                                                                    />
                                                                                </Tooltip>
                                                                            )}
                                                                        />
                                                                        <Button
                                                                            size="small"
                                                                            variant="outlined"
                                                                            color="error"
                                                                            sx={{ fontSize: 10 }}
                                                                            onClick={() => {
                                                                                removeBlackOutDateRange(index, ind);
                                                                            }}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </Box>
                                                                )
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions
                                                                                .hotel_occupancy_threshold.active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>hotel_occupancy_threshold>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Hotel OTB occupancy is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions.hotel_occupancy_threshold
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_threshold>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="less_than">less than</MenuItem>
                                                                <MenuItem value="greater_than">
                                                                    greater than
                                                                </MenuItem>
                                                            </Select>
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions.hotel_occupancy_threshold
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_threshold>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions.hotel_occupancy_vs_sdly
                                                                                .active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>hotel_occupancy_vs_sdly>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Hotel OTB occupancy is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions.hotel_occupancy_vs_sdly
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_vs_sdly>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="greater_than">ahead of</MenuItem>
                                                                <MenuItem value="less_than">behind</MenuItem>
                                                            </Select>
                                                            last year by more than
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions.hotel_occupancy_vs_sdly
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_vs_sdly>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions
                                                                                .hotel_occupancy_pickup_threshold.active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>hotel_occupancy_pickup_threshold>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Hotel
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_threshold.pickup_days
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_threshold>pickup_days",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                            day occupancy pickup is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_threshold
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_threshold>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="less_than">less than</MenuItem>
                                                                <MenuItem value="greater_than">
                                                                    greater than
                                                                </MenuItem>
                                                            </Select>
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_threshold
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_threshold>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions
                                                                                .hotel_occupancy_pickup_vs_sdly.active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>hotel_occupancy_pickup_vs_sdly>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Hotel
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_vs_sdly.pickup_days
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_vs_sdly>pickup_days",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                            day occupancy pickup is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_vs_sdly
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_vs_sdly>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="greater_than">
                                                                    greater than
                                                                </MenuItem>
                                                                <MenuItem value="less_than">less than</MenuItem>
                                                            </Select>
                                                            <br />
                                                            <span style={{ marginLeft: 60 }}>
                                                                last year pickup by more than
                                                            </span>
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_occupancy_pickup_vs_sdly
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_occupancy_pickup_vs_sdly>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions
                                                                                .market_forecast_occupancy_threshold
                                                                                .active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>market_forecast_occupancy_threshold>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Market forecast occupancy is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .market_forecast_occupancy_threshold
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>market_forecast_occupancy_threshold>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="greater_than">
                                                                    greater than
                                                                </MenuItem>
                                                                <MenuItem value="less_than">less than</MenuItem>
                                                            </Select>
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .market_forecast_occupancy_threshold
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>market_forecast_occupancy_threshold>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box sx={{ fontSize: "15px", pb: 1 }}>
                                                            <FormControlLabel
                                                                sx={{ m: 0, p: 0 }}
                                                                control={
                                                                    <Switch
                                                                        className="switch-button"
                                                                        size="medium"
                                                                        // color="green"
                                                                        checked={
                                                                            item.rule_conditions
                                                                                .hotel_forecast_occupancy_threshold.active
                                                                        }
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_conditions>hotel_forecast_occupancy_threshold>active",
                                                                                checked,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                label=""
                                                            />{" "}
                                                            Hotel forecast is
                                                            <Select
                                                                className="alert-input inline-select"
                                                                size="small"
                                                                sx={{
                                                                    height: "22px",
                                                                    ml: 1,
                                                                    mr: 1,
                                                                    fontSize: 11,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_forecast_occupancy_threshold
                                                                        .comparison_operation
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_forecast_occupancy_threshold>comparison_operation",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            >
                                                                <MenuItem value="greater_than">
                                                                    greater than
                                                                </MenuItem>
                                                                <MenuItem value="less_than">less than</MenuItem>
                                                            </Select>
                                                            <Input
                                                                className="alert-input inline-input"
                                                                size="small"
                                                                sx={{
                                                                    width: 40,
                                                                    position: "relative",
                                                                    ml: 0.5,
                                                                    mr: 0.5,
                                                                }}
                                                                value={
                                                                    item.rule_conditions
                                                                        .hotel_forecast_occupancy_threshold
                                                                        .threshold_value
                                                                }
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        %
                                                                    </InputAdornment>
                                                                }
                                                                onChange={(e) => {
                                                                    const value = e.target.value;
                                                                    updateRequestData(
                                                                        "price_rule_settings>rule_conditions>hotel_forecast_occupancy_threshold>threshold_value",
                                                                        value,
                                                                        index
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Box
                                                        sx={{ m: 2, mb: 0, p: 1, pl: 2 }}
                                                    >
                                                        SET YOUR PRICING RULE OUTPUT HERE:
                                                    </Box>
                                                    <Grid item xs={12}>
                                                        <Paper elevation={0} sx={{ m: 2, mt: 0, p: 1, border: "1px solid #DADADA" }}>
                                                            <Box
                                                                sx={{ mt: 0.8 }}
                                                                className="checkbox-push-btns"
                                                            >
                                                                <span
                                                                    style={{ color: "#333", fontSize: "14px", marginRight: 10 }}
                                                                >
                                                                    PRICING RULE OUTPUT
                                                                </span>
                                                                <ButtonGroup disableElevation variant="contained">
                                                                    <Button
                                                                        size="small"
                                                                        color={
                                                                            item.rule_action_setting === "simple"
                                                                                ? "blue"
                                                                                : "dark"
                                                                        }
                                                                        sx={{ color: "white.main" }}
                                                                        onClick={() => {
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_action_setting",
                                                                                "simple",
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        Simple
                                                                    </Button>
                                                                    <Button
                                                                        size="small"
                                                                        color={
                                                                            item.rule_action_setting === "advanced"
                                                                                ? "blue"
                                                                                : "dark"
                                                                        }
                                                                        sx={{ color: "white.main" }}
                                                                        onClick={() => {
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_action_setting",
                                                                                "advanced",
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        Advanced
                                                                    </Button>
                                                                </ButtonGroup>
                                                                <Box sx={{ mt: 3, mb: 2, fontSize: 14 }}>
                                                                    <Select
                                                                        className="alert-input inline-select"
                                                                        size="small"
                                                                        sx={{
                                                                            height: "22px",
                                                                            ml: 1,
                                                                            mr: 1,
                                                                            fontSize: 11,
                                                                        }}
                                                                        value={item.rule_actions[0].target_settings}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>target_settings",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MenuItem value="BAR">BAR</MenuItem>
                                                                    </Select>
                                                                    Must be
                                                                    <Select
                                                                        className="alert-input inline-select"
                                                                        size="small"
                                                                        sx={{
                                                                            height: "22px",
                                                                            ml: 1,
                                                                            mr: 1,
                                                                            fontSize: 11,
                                                                            display:
                                                                                item.rule_action_setting === "advanced"
                                                                                    ? "none"
                                                                                    : "inline-flex",
                                                                        }}
                                                                        value={item.rule_actions[0].target_action}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>target_action",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MenuItem value="increase">increase</MenuItem>
                                                                        <MenuItem value="decrease">decrease</MenuItem>
                                                                    </Select>
                                                                    <Select
                                                                        className="alert-input inline-select"
                                                                        size="small"
                                                                        sx={{
                                                                            height: "22px",
                                                                            ml: 1,
                                                                            mr: 1,
                                                                            fontSize: 11,
                                                                            display:
                                                                                item.rule_action_setting === "simple"
                                                                                    ? "none"
                                                                                    : "inline-flex",
                                                                        }}
                                                                        value={item.rule_actions[0].target_rule}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>target_rule",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MenuItem value="greater_than">
                                                                            greater than
                                                                        </MenuItem>
                                                                        <MenuItem value="less_than">
                                                                            less than
                                                                        </MenuItem>
                                                                    </Select>
                                                                    <Select
                                                                        className="alert-input inline-select"
                                                                        size="small"
                                                                        sx={{
                                                                            height: "22px",
                                                                            ml: 1,
                                                                            mr: 1,
                                                                            fontSize: 11,
                                                                            display:
                                                                                item.rule_action_setting === "simple"
                                                                                    ? "none"
                                                                                    : "inline-flex",
                                                                        }}
                                                                        value={item.rule_actions[0].target_hotel}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>target_hotel",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MenuItem value="Compset_Median">
                                                                            Compset Median{" "}
                                                                        </MenuItem>
                                                                        <MenuItem value="Compset_Avg">
                                                                            Compset Avg{" "}
                                                                        </MenuItem>
                                                                        <MenuItem value="Compset_Max">
                                                                            Compset Max{" "}
                                                                        </MenuItem>
                                                                        <MenuItem value="Compset_Min">
                                                                            Compset Min{" "}
                                                                        </MenuItem>
                                                                    </Select>
                                                                    by
                                                                    <Input
                                                                        className="alert-input inline-input"
                                                                        size="small"
                                                                        sx={{
                                                                            width: 40,
                                                                            position: "relative",
                                                                            ml: 0.5,
                                                                            mr: 0.5,
                                                                        }}
                                                                        value={item.rule_actions[0].change_value}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>change_value",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    />
                                                                    <Select
                                                                        className="alert-input inline-select"
                                                                        size="small"
                                                                        sx={{
                                                                            height: "22px",
                                                                            ml: 1,
                                                                            mr: 1,
                                                                            fontSize: 11,
                                                                        }}
                                                                        value={item.rule_actions[0].change_metric}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            updateRequestData(
                                                                                "price_rule_settings>rule_actions>0>change_metric",
                                                                                value,
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MenuItem value="currency">$</MenuItem>
                                                                    </Select>
                                                                </Box>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </Collapse>
                                        </Paper>
                                    );
                                })}
                            </Box>
                        </Box>
                        {isrequesting && (
                            <Box
                                sx={{
                                    textAlighn: "center",
                                    backgroundColor: "dark.darkest",
                                    opacity: 0.8,
                                    position: "fixed",
                                    top: 40,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    zIndex: 100,
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    sx={{
                                        color: "white",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>

            </Box>
        </LocalizationProvider>
    );
}

function DefaultRoomTypePricing(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for settings was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const [expanded, setExpanded] = useState({});

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });

    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const room_settings = JSON.parse(
        JSON.stringify(system_settings[0].inventory_settings.current.room_settings)
    );
    const settings_ = JSON.parse(
        JSON.stringify({
            pricing_settings: system_settings[0].pricing_settings,
        })
    );
    // debugger; 
    //   {
    //     "plan_name": "default",
    //     "plan_period": {
    //         "effective_date_range": {
    //             "from": "2022-01-01",
    //             "until": "2026-01-01"
    //         }
    //     },
    //     "base_room": "",
    //     "room_plans": {
    //         "": {
    //             "Wed": 0,
    //             "Sun": 0,
    //             "Fri": 0,
    //             "Tue": 0,
    //             "Mon": 0,
    //             "Thu": 0,
    //             "Sat": 0
    //         },
    //         "PresidentialSuite1": {
    //             "Wed": 100,
    //             "Sun": 100,
    //             "Fri": 100,
    //             "Tue": 100,
    //             "Mon": 100,
    //             "Thu": 100,
    //             "Sat": 100
    //         },
    //         "JuniorDouble1": {
    //             "Wed": 0,
    //             "Sun": 0,
    //             "Fri": 0,
    //             "Tue": 0,
    //             "Mon": 0,
    //             "Thu": 0,
    //             "Sat": 0
    //         },
    //         "ConciergeQueen1": {
    //             "Wed": 30,
    //             "Sun": 30,
    //             "Fri": 30,
    //             "Tue": 30,
    //             "Mon": 30,
    //             "Thu": 30,
    //             "Sat": 30
    //         },
    //         "StandardQueen1": {
    //             "Wed": 10,
    //             "Sun": 10,
    //             "Fri": 10,
    //             "Tue": 10,
    //             "Mon": 10,
    //             "Thu": 10,
    //             "Sat": 10
    //         },
    //         "ConciergeKing1": {
    //             "Wed": 40,
    //             "Sun": 40,
    //             "Fri": 40,
    //             "Tue": 40,
    //             "Mon": 40,
    //             "Thu": 40,
    //             "Sat": 40
    //         },
    //         "StandardKing1": {
    //             "Wed": 20,
    //             "Sun": 20,
    //             "Fri": 20,
    //             "Tue": 20,
    //             "Mon": 20,
    //             "Thu": 20,
    //             "Sat": 20
    //         },
    //         "ExecutiveSuite1": {
    //             "Wed": 80,
    //             "Sun": 80,
    //             "Fri": 80,
    //             "Tue": 80,
    //             "Mon": 80,
    //             "Thu": 80,
    //             "Sat": 80
    //         }
    //     },
    //     "active": true,
    //     "open": false,
    //     "base_rate_code": "42985"
    // }
    const settings = JSON.parse(JSON.stringify(settings_));

    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const containerRef = useRef(null);

    //////////////////drag drop
    const [roomSettingsOld, setRoomSettingsOld] = useState(JSON.parse(JSON.stringify(requestData.pricing_settings.room_dow_plans))); //taking a backup of Old Array
    const [roomSettings, setRoomSettings] = useState(requestData.pricing_settings.room_dow_plans);
    // console.log('roomSettingsOld', roomSettingsOld);
    // console.log('roomSettingsNew', roomSettings);

    const onDragEnd = (result) => {
        console.log("result.source", result.source.index);
        console.log("result.destination", result.destination.index);
        console.log('New', roomSettings[result.source.index].plan_name.toLowerCase());

        if (!result.destination) {
            return;
        }
        if (roomSettings[result.source.index].plan_name.toLowerCase() === 'default' && result.source.index === 0) {
            return;
        }
        if (roomSettings[result.destination.index].plan_name.toLowerCase() === 'default' && result.destination.index === 0) {
            return;
        }
        // if (result.source.index === 0 || result.destination.index === 0) {
        //     return;
        // }

        const updatedRoomSettings = Array.from(roomSettings);
        const [movedItem] = updatedRoomSettings.splice(result.source.index, 1);
        updatedRoomSettings.splice(result.destination.index, 0, movedItem);

        setRoomSettings(updatedRoomSettings);

        requestData.pricing_settings.room_dow_plans = updatedRoomSettings;
        setRequestData({
            ...requestData, room_dow_plans: requestData.pricing_settings.room_dow_plans,
        });
        setIsChangeRequest(true);

    };
    /////////////drag derop end

    const cancelSave = () => {
        setRoomSettings(JSON.parse(JSON.stringify(roomSettingsOld)));
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, value, index) => {
        //const updatedObj = { ...requestData };
        const updatedObj = { ...requestData };
        updatedObj.pricing_settings.room_dow_plans = roomSettings;
        const key = keys.split(">");
        const size = key.length;
        let val = value;

        // debugger;
        if (size == 2) {
            updatedObj[key[0]]["room_dow_plans"][index][key[1]] = val;
        }
        if (size == 3) {
            updatedObj[key[0]]["room_dow_plans"][index][key[1]][key[2]] = val;
        }
        if (size == 4) {
            if (key[2] === 'effective_date_range') {
                updatedObj[key[0]]["room_dow_plans"][index][key[1]][key[2]][key[3]] =
                    val !== '' ? val : '';
                // val !== '' ? moment(val).format('YYYY-MM-DD') : '';
            } else {
                updatedObj[key[0]]["room_dow_plans"][index][key[1]][key[2]][key[3]] =
                    // !isNaN(val) ? Number(val) : 0;
                    val !== '' ? Number(val) : 0;
            }

        }

        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };
    const addNewPricingRule = (plan_name = null) => {
        let daysAdd = (plan_name && plan_name.toLowerCase() === "default") ? 1460 : 1;
        const today = new moment().format("YYYY-MM-DD");
        const tomorrow = new moment().add(daysAdd, "days").format("YYYY-MM-DD");
        const size = roomSettings.length;
        const item = {
            open: false,
            plan_name: (plan_name) ? plan_name : "New Room Type Plan " + size,
            active: false,
            plan_period: {
                effective_date_range: {
                    from: today,
                    until: tomorrow,
                },
            },
            base_room: "StandardTwoQueens1",
            base_rate_code: "",
            room_plans: {},
        };
        //Making DRTP rows according to inventory room types
        if (room_settings.length) {
            room_settings.map((room, index) => {
                item.room_plans[room.room_lodgiq_code] = {
                    Sun: 0,
                    Mon: 0,
                    Tue: 0,
                    Wed: 0,
                    Thu: 0,
                    Fri: 0,
                    Sat: 0,
                }
            });
        }
        // debugger;
        //requestData.pricing_settings.room_dow_plans.push(item);
        roomSettings.push(item);
        setRequestData({ ...requestData });

        if (plan_name && plan_name.toLowerCase() === "default") {
            setIsChangeRequest(false);
        }
        else {
            setIsChangeRequest(true);
        }
        // setIsChangeRequest(true);

        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };
    const copyRule = (index) => {
        const clone = JSON.parse(
            JSON.stringify(roomSettings[index])
        );
        clone.plan_name = "Copy of " + clone.plan_name;
        roomSettings.push(clone);
        setRequestData({ ...requestData });
        setIsChangeRequest(true);
    };
    const deleteItem = (index) => {
        roomSettings.splice(index, 1);
        setRequestData({ ...requestData });
        setIsChangeRequest(true);
    };
    const saveChangesTemp = (event) => {
        setIsRequesting(true);
        setTimeout(() => {
            setIsRequesting(false);
        }, 3000);
    };
    const saveChanges = (event) => {
        setIsRequesting(true);
        const pricing_settings = JSON.parse(
            JSON.stringify(requestData.pricing_settings)
        );
        if (pricing_settings.room_dow_plans) {
            try {
                pricing_settings.room_dow_plans.forEach((plan) => {
                    if (plan.plan_period) {
                        plan.plan_period.effective_date_range.from = moment(plan.plan_period.effective_date_range.from).format('YYYY-MM-DD')
                        plan.plan_period.effective_date_range.until = moment(plan.plan_period.effective_date_range.until).format('YYYY-MM-DD')
                    }
                })
            } catch (e) {
                console.error(e)
            }
        }

        const request_system_settings = {
            ...system_settings[0],
            pricing_settings: pricing_settings,
        };

        //debugger;

        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };
    const handleExpand = (index) => {
        if (!expanded["id_" + index]) {
            expanded["id_" + index] = true;
        } else {
            expanded["id_" + index] = false;
        }
        setExpanded({ ...expanded });
        setDateRangePickerVisiblity(false);
    };
    // console.log(system_settings);
    const changeRoomTypePricingOrder = (item, index, type) => {

        const tempObj = item;
        const size = requestData.pricing_settings.room_dow_plans.length;

        if (type == "up" && index) {
            requestData.pricing_settings.room_dow_plans[index] =
                requestData.pricing_settings.room_dow_plans[index - 1];
            requestData.pricing_settings.room_dow_plans[index - 1] = item;

            setRequestData({
                ...requestData,
                room_dow_plans:
                    requestData.pricing_settings.room_dow_plans,
            });
            setIsChangeRequest(true);
            // debugger;
        }
        if (type == "down" && index < size) {
            requestData.pricing_settings.room_dow_plans[index] =
                requestData.pricing_settings.room_dow_plans[index + 1];
            requestData.pricing_settings.room_dow_plans[index + 1] = item;
            setRequestData({
                ...requestData, room_dow_plans:
                    requestData.pricing_settings.room_dow_plans,
            });
            setIsChangeRequest(true);
        }
    };
    // console.log(requestData.pricing_settings.room_dow_plans);
    if (roomSettings.length === 0) {
        addNewPricingRule('Default');
    }
    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        //reverting changes back
        setRoomSettings(JSON.parse(JSON.stringify(roomSettingsOld)));
        setRequestData(settings_);
        setIsChangeRequest(false);

        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }

    const [dateRangePickerVisiblity, setDateRangePickerVisiblity] = useState(false);
    const toggleDateRangePickerVisiblity = () => {
        if (dateRangePickerVisiblity) {
            setDateRangePickerVisiblity(false);
        }
        else {
            setDateRangePickerVisiblity(true);
        }
    };

    // debugger;

    //Fetch system timezone
    const [timeZone, setTimeZone] = useState('');
    useEffect(() => {
        const getTimeZone = () => {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setTimeZone(timeZone);
        };

        getTimeZone();
    }, []);


    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>

            <Box className='top-axn-bar-1'>
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }

                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>

                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Pricing Seasons</span>
                </Box>
            </Box>

            <div className="property-setting-heading" style={{ display: "none" }}>
                <Typography
                    className="property-setting-main-heading"
                    // variant="h1"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Pricing Seasons
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        variant="outlined"
                        size="small"
                        className="navigate-button btn-active"
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                        onClick={() => {
                            addNewPricingRule();
                        }}
                    >
                        Add New
                    </Button>
                    {/* <AddNewIcon sx={{ mr: 0.5 }}></AddNewIcon> */}
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-button btn-active" : "navigate-button"}

                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />
            </div>

            <Box className="bgwhite main-scroll-wrap-pricing" sx={{ pl: 2, pr: 2, scrollBehavior: 'smooth' }} ref={containerRef}>

                <div className='profile-heading'>PRICING SEASONS</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>

                <Box className="inventory-settings">

                    <Grid>
                        <Box
                            sx={{
                                pt: 2,
                                flexDirection: "column",
                                position: "relative",
                                display: "flex",
                            }}
                        >

                            <Box
                            //sx={{ mt: { lg: 0 }, pr: 0, maxHeight: "calc(100vh - 185px)", overflow: "auto" }}
                            >
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="room-settings-list" direction="vertical">
                                        {(provided) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {roomSettings.map((item, index) => (
                                                    <Draggable
                                                        key={"roomsettings-" + index}
                                                        draggableId={"roomsettings-" + index}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                className={((item.plan_name.toLowerCase() === "default" && index === 0)) ? "pricing-seasons-draggable-row cursor-none" : "pricing-seasons-draggable-row"}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                <Tooltip title="Drag and Drop" arrow followCursor >
                                                                    <Grid item md={0.3} className="room-type-details-col" style={{ pr: '10px', cursor: "grab" }}>
                                                                        <svg
                                                                            style={{ marginTop: "18px" }}
                                                                            //className="draggable-icon" 
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="32"
                                                                            height="32"
                                                                            fill="#000000"
                                                                            viewBox="0 0 256 256"
                                                                            className={((item.plan_name.toLowerCase() === "default" && index === 0)) ? "display-none" : "draggable-icon"}
                                                                        >
                                                                            <path d="M104,60A12,12,0,1,1,92,48,12,12,0,0,1,104,60Zm60,12a12,12,0,1,0-12-12A12,12,0,0,0,164,72ZM92,116a12,12,0,1,0,12,12A12,12,0,0,0,92,116Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,116ZM92,184a12,12,0,1,0,12,12A12,12,0,0,0,92,184Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,184Z"></path>
                                                                        </svg>
                                                                    </Grid>
                                                                </Tooltip>
                                                                <Paper
                                                                    key={"row-uid-" + index}
                                                                    className="pricing-rules item"
                                                                    elevation={1}
                                                                >
                                                                    <Grid
                                                                        container
                                                                        className="header"
                                                                        sx={{
                                                                            backgroundColor: expanded["id_" + index]
                                                                                ? "blue.main"
                                                                                : "grey.lighter",
                                                                        }}
                                                                    >
                                                                        <Grid item className="rate-push-badge-pricing">
                                                                            {index + 1}
                                                                        </Grid>

                                                                        <Grid item>
                                                                            {(expanded["id_" + index]) ?
                                                                                <><TextField
                                                                                    className="input-style-plan-name"
                                                                                    size="small"
                                                                                    fullWidth
                                                                                    margin="dense"
                                                                                    value={item.plan_name}
                                                                                    onChange={(e) => {
                                                                                        const value = e.target.value;
                                                                                        updateRequestData(
                                                                                            "pricing_settings>plan_name",
                                                                                            value,
                                                                                            index
                                                                                        );
                                                                                    }}
                                                                                    // className={(item.plan_name === "Default") ? "display-none right-icons" : "right-icons"}
                                                                                    inputProps={
                                                                                        { readOnly: (item.plan_name.toLowerCase() === "default") ? true : false }
                                                                                    }
                                                                                /></>
                                                                                :
                                                                                <>
                                                                                    <div>{item.plan_name}</div></>
                                                                            }

                                                                        </Grid>

                                                                        <Grid item>
                                                                            {(item.base_rate_code) ? "Base Rate Plan - " + item.base_rate_code : ""}
                                                                        </Grid>

                                                                        <Grid item>
                                                                            <Box
                                                                                className={
                                                                                    index !== 1000 && expanded["id_" + index]
                                                                                        ? dateRangePickerVisiblity
                                                                                            ? "pricing-season-expamded-datepicker-active"
                                                                                            : "pricing-season-expamded-datepicker"
                                                                                        : ""
                                                                                }
                                                                                sx={{
                                                                                    width: "100%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "flex-start",
                                                                                    gap: "10px",
                                                                                }}
                                                                            >
                                                                                <Box sx={{ mt: "3px" }}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#606060" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-96-88v64a8,8,0,0,1-16,0V132.94l-4.42,2.22a8,8,0,0,1-7.16-14.32l16-8A8,8,0,0,1,112,120Zm59.16,30.45L152,176h16a8,8,0,0,1,0,16H136a8,8,0,0,1-6.4-12.8l28.78-38.37A8,8,0,1,0,145.07,132a8,8,0,1,1-13.85-8A24,24,0,0,1,176,136,23.76,23.76,0,0,1,171.16,150.45Z"></path></svg>
                                                                                </Box>

                                                                                <Box>
                                                                                    <div onClick={toggleDateRangePickerVisiblity} style={{ cursor: "pointer" }}>
                                                                                        {dateFormat(item.plan_period.effective_date_range.from, "ddd,MMM DD YYYY")} -{' '}
                                                                                        {dateFormat(item.plan_period.effective_date_range.until, "ddd,MMM DD YYYY")}
                                                                                    </div>
                                                                                </Box>

                                                                                <Box
                                                                                    //ref={boxRef}
                                                                                    //className={dateRangePickerVisiblity ? "date-range-picker-container" : "date-range-picker-container-false"}
                                                                                    //onClick={handleBoxClick2}
                                                                                    //className={((index === 0)) ? "pricing-seasons-draggable-row cursor-none" : "pricing-seasons-draggable-row"}
                                                                                    className={
                                                                                        index !== 1000 && expanded["id_" + index]
                                                                                            ? dateRangePickerVisiblity
                                                                                                ? "date-range-picker-container"
                                                                                                : "date-range-picker-container-false"
                                                                                            : "date-range-picker-container-false"
                                                                                    }
                                                                                >
                                                                                    {expanded["id_" + index] ? (
                                                                                        <DateRangePicker
                                                                                            showSelectionPreview={true}
                                                                                            moveRangeOnFirstSelection={false}
                                                                                            months={2}
                                                                                            direction="horizontal"
                                                                                            ranges={[
                                                                                                {
                                                                                                    startDate: new Date(item.plan_period.effective_date_range.from),
                                                                                                    endDate: new Date(item.plan_period.effective_date_range.until),
                                                                                                    key: 'selection',
                                                                                                },
                                                                                            ]}
                                                                                            minDate={new Date()} // disabled previous date from current date
                                                                                            onChange={(ranges) => {
                                                                                                console.log("start", ranges.selection.startDate);
                                                                                                console.log("end", ranges.selection.endDate);

                                                                                                // Update data using the formatted dates
                                                                                                updateRequestData(
                                                                                                    "pricing_settings>plan_period>effective_date_range>from",
                                                                                                    ranges.selection.startDate,
                                                                                                    index
                                                                                                );

                                                                                                updateRequestData(
                                                                                                    "pricing_settings>plan_period>effective_date_range>until",
                                                                                                    ranges.selection.endDate,
                                                                                                    index
                                                                                                );
                                                                                            }}
                                                                                        />

                                                                                    )
                                                                                        : (
                                                                                            ""
                                                                                        )}

                                                                                    {expanded["id_" + index] ?
                                                                                        <Box sx={{ background: "#FFF" }} className="datepicker-done-btn"><Button onClick={toggleDateRangePickerVisiblity}>Done</Button></Box>
                                                                                        : <Box sx={{ background: "#FFF" }}></Box>}


                                                                                </Box>

                                                                            </Box>
                                                                        </Grid>

                                                                        <Grid item sx={{ justifySelf: "end", display: "grid", gridTemplateColumns: "repeat(4, 1fr)", alignItems: "center", justifyItems: "end" }}>
                                                                            <Switch
                                                                                className="switch-button"
                                                                                size="medium"
                                                                                // color="green"
                                                                                checked={item.active}
                                                                                onChange={(e) => {
                                                                                    const checked = e.target.checked;
                                                                                    updateRequestData(
                                                                                        "pricing_settings>active",
                                                                                        checked,
                                                                                        index
                                                                                    );
                                                                                }}
                                                                            />

                                                                            <Button
                                                                                variant="text"
                                                                                onClick={() => {
                                                                                    copyRule(index);
                                                                                }}
                                                                                className="right-icons"
                                                                            >
                                                                                <Tooltip title="Copy Rule">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00415F" viewBox="0 0 256 256"><path d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"></path></svg>
                                                                                </Tooltip>
                                                                            </Button>

                                                                            {(item.plan_name.toLowerCase() === "default") ?
                                                                                <Grid></Grid>
                                                                                :
                                                                                <Button
                                                                                    variant="text"
                                                                                    onClick={() => {
                                                                                        deleteItem(index);
                                                                                    }}
                                                                                    className={(item.plan_name.toLowerCase() === "default") ? "display-none right-icons" : "right-icons"}>
                                                                                    <Tooltip title="Delete">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#E03131" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>
                                                                                    </Tooltip>
                                                                                </Button>
                                                                            }

                                                                            <Button
                                                                                variant="text"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    handleExpand(index);
                                                                                }}
                                                                            >
                                                                                {/* <KeyboardArrowDownOutlined fontSize="small"
                                                                                    sx={{ display: expanded["id_" + index] ? "none": "inline-block",}}
                                                                                    //className="arrow-down inside-line-rateplan expend-arrow button-grey"
                                                                                /> 
                                                                                
                                                                                <KeyboardArrowUpOutlinedIcon fontSize="small"
                                                                                    sx={{display: expanded["id_" + index]? "inline-block": "none",}}
                                                                                    //className="arrow-down inside-line-rateplan expend-arrow button-grey"
                                                                                /> */}

                                                                                {expanded["id_" + index] ?
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00415f" viewBox="0 0 256 256"><path d="M213.66,165.66a8,8,0,0,1-11.32,0L128,91.31,53.66,165.66a8,8,0,0,1-11.32-11.32l80-80a8,8,0,0,1,11.32,0l80,80A8,8,0,0,1,213.66,165.66Z"></path></svg>
                                                                                    :
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#00415f" viewBox="0 0 256 256"><path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path></svg>
                                                                                }
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Collapse
                                                                        in={expanded["id_" + index]}
                                                                        timeout="auto"
                                                                        unmountOnExit
                                                                        sx={{ backgroundColor: "white", overflow: "visible" }}
                                                                    >
                                                                        <Box sx={{ pb: 1.1 }}>
                                                                            <Box
                                                                                display="inline-block"
                                                                                sx={{ p: 1, pl: 3, display: "flex", alignItems: "center" }}
                                                                            >
                                                                                Base Rate Plan
                                                                                <TextField
                                                                                    className="accordian-field input-style-baserate"
                                                                                    size="small"

                                                                                    sx={{
                                                                                        ml: 2,
                                                                                        width: 80,
                                                                                        "& input": { textAlign: "center" },
                                                                                        color: "blue.dark",
                                                                                    }}
                                                                                    value={item.base_rate_code}
                                                                                    onChange={(e) => {
                                                                                        const value = e.target.value;
                                                                                        updateRequestData(
                                                                                            "pricing_settings>base_rate_code",
                                                                                            value,
                                                                                            index
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                            <Box className="room-plans-grids">
                                                                                <Grid container className="room-plans-grids-header">
                                                                                    <Grid
                                                                                        className="header-item header-color"
                                                                                        item
                                                                                        xs={3.5}
                                                                                        sx={{ textAlign: "left" }}
                                                                                    >
                                                                                        <span style={{ paddingLeft: 22 }}>
                                                                                            ROOM TYPE
                                                                                        </span>
                                                                                    </Grid>
                                                                                    <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                                        SUN
                                                                                    </Grid>
                                                                                    <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                                        MON
                                                                                    </Grid>
                                                                                    <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                                        TUE
                                                                                    </Grid>
                                                                                    <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                                        WED
                                                                                    </Grid>
                                                                                    <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                                        THU
                                                                                    </Grid>
                                                                                    <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                                        FRI
                                                                                    </Grid>
                                                                                    <Grid className="header-color pl-10" item xs={1.2} sx={{ textAlign: "left" }}>
                                                                                        SAT
                                                                                    </Grid>
                                                                                </Grid>

                                                                                <Box className="room-plans-grids-data" sx={{ overflow: "auto" }}>
                                                                                    {room_settings.map((itm, i) => {
                                                                                        const dow = (item.room_plans[itm.room_lodgiq_code]) ? item.room_plans[itm.room_lodgiq_code] : [];
                                                                                        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                                                                                        // const days = Object.keys(dow);
                                                                                        return (
                                                                                            <Grid
                                                                                                container
                                                                                                className={
                                                                                                    "content" + (i % 2 ? " odd" : " even")
                                                                                                }
                                                                                            >
                                                                                                <Grid item className="room-type">
                                                                                                    <div className="name">
                                                                                                        {itm.display_name}
                                                                                                    </div>
                                                                                                </Grid>

                                                                                                {days.map((val, ind) => (
                                                                                                    <Grid
                                                                                                        item
                                                                                                        className="value-edit"
                                                                                                    >
                                                                                                        <Input
                                                                                                            className="form-control value-edit-text"
                                                                                                            sx={{ input: { textAlign: "center" } }}
                                                                                                            size="small"
                                                                                                            value={dow[val]}
                                                                                                            // onClick={(e) => {
                                                                                                            //     e.target.setSelectionRange(0, 0); // Set cursor to the beginning
                                                                                                            // }}
                                                                                                            onChange={(e) => {
                                                                                                                const value = e.target.value;
                                                                                                                updateRequestData(
                                                                                                                    "pricing_settings>room_plans>" +
                                                                                                                    itm.room_lodgiq_code +
                                                                                                                    ">" +
                                                                                                                    val,
                                                                                                                    value,
                                                                                                                    index
                                                                                                                );
                                                                                                            }}
                                                                                                        />

                                                                                                    </Grid>
                                                                                                ))}
                                                                                            </Grid>
                                                                                        );
                                                                                    })}
                                                                                </Box>

                                                                                <Box sx={{ pt: 2, backgroundColor: "#FFF", display: "flex", justifyContent: "end", gap: "10px" }}>
                                                                                    <Button
                                                                                        disabled={!isChangeRequest}
                                                                                        onClick={cancelSave}
                                                                                        variant="outlined"
                                                                                        className={(isChangeRequest) ? "sub-navigate-button-active-cancel" : "sub-navigate-button-cancel"}
                                                                                        size="small"
                                                                                    //sx={{ mr: 1}}
                                                                                    >
                                                                                        Cancel
                                                                                    </Button>
                                                                                    <Button
                                                                                        disabled={!isChangeRequest}
                                                                                        onClick={() => {
                                                                                            saveChangesTemp();
                                                                                        }}
                                                                                        variant="outlined"
                                                                                        size="small"
                                                                                        className={(isChangeRequest) ? "sub-navigate-button-active" : "sub-navigate-button"}
                                                                                    >
                                                                                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                                                                                            Save
                                                                                        </span>
                                                                                        <span
                                                                                            style={{
                                                                                                display: isrequesting ? "block" : "none",
                                                                                                paddingRight: 10,
                                                                                            }}
                                                                                        >
                                                                                            saving...
                                                                                        </span>
                                                                                        <CircularProgress
                                                                                            sx={{ display: isrequesting ? "block" : "none" }}
                                                                                            color="white"
                                                                                            size="16px"
                                                                                        />
                                                                                    </Button>
                                                                                </Box>

                                                                            </Box>
                                                                        </Box>
                                                                        <Grid container spacing={1}></Grid>
                                                                    </Collapse>
                                                                </Paper>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>


                            </Box>
                            {isrequesting && (
                                <Box
                                    sx={{
                                        textAlighn: "center",
                                        backgroundColor: "dark.darkest",
                                        opacity: 0.8,
                                        position: "fixed",
                                        top: 40,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        zIndex: 100,
                                    }}
                                >
                                    <CircularProgress
                                        size={40}
                                        sx={{
                                            color: "white",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-20px",
                                            marginLeft: "-20px",
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid>

                    <Button
                        variant="outlined"
                        className="add-button"
                        size="small"
                        sx={{ lineHeight: "auto" }}
                        onClick={() => {
                            addNewPricingRule();
                        }}
                    >
                        <span className="add-btn-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#333333" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>
                        </span>
                        <span className="add-btn-txt">Add New Pricing Season</span>
                    </Button>

                </Box>

            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}

        </LocalizationProvider>
    );
}

function AddNewProperty(props) {
    const dispatch = useDispatch();
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "New property is added successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    const settings_ = {
        property_name: "",
        status: "PRELAUNCH",
        assigned_id: "",
        assigned_id_type: "MANUAL",
    };

    const settings = JSON.parse(JSON.stringify(settings_));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);

    const cancelSave = () => {
        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val) => {
        const updatedObj = { ...requestData };
        const key = keys.split(">");
        //debugger;
        if (key[0]) {
            updatedObj[key[0]] = val;
        }
        if (key[0] === "assigned_id_type" && val === "AUTO") {
            updatedObj.assigned_id_type = val;
            updatedObj.assigned_id_manual = "";
        }
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
    };

    const saveChanges = (event) => {
        setIsRequesting(true);
        const new_property = JSON.parse(JSON.stringify(requestData));
        // debugger;
        let payload = {
            property_id: app_state.property_id,
            user_id: user.user_id,
            token_string: user.token_string,
            property_name: new_property.property_name,
            status: new_property.status,
            assigned_id:
                new_property.assigned_id_manual || new_property.assigned_id_type,
        };
        dataService
            .update({ api: "settings/add_property", request: payload })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };
    //console.log(system_settings);

    return (
        <div>
            <Box className='breadcrumb' sx={{ color: 'white.main', position: 'absolute', top: -60 }}>
                Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Property Settings
                <ArrowForwardIosOutlinedIcon
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                Add New Property
            </Box>

            <Box className="segments-settings" sx={{ p: 2, pt: 2, mb: 2, mt: 2, ml: 2, mr: 2, border: "1px solid #efefef" }}>
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ mr: 3, display: { lg: "inline-block", xs: "none" } }}
                >
                    Add New Property
                </Typography>
                <Box sx={{ float: "right" }}>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            saveChanges();
                        }}
                        variant="contained"
                        size="small"
                        color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Add New
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="contained"
                        color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>
                </Box>
                <Divider sx={{ clear: "both", mt: { xs: 2, lg: 0 } }} />

                <Box
                    sx={{
                        pt: 2,
                        flexDirection: "column",
                        position: "relative",
                        display: "flex",
                    }}
                >
                    <Alert
                        severity="success"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestSuccess ? "flex" : "none",
                        }}
                    >
                        {requestSuccessMsg}
                    </Alert>
                    <Alert
                        severity="error"
                        sx={{
                            width: "100%",
                            marginTop: -10,
                            display: isRequestError ? "flex" : "none",
                        }}
                    >
                        {resErrorMsg}
                    </Alert>
                    <Alert
                        severity="error"
                        className="save-changes-alert display-none"
                        sx={{ marginTop: "10px" }}
                    >
                        {unsavedAlertMsg}
                    </Alert>

                    <Box sx={{ mt: { lg: 0 } }}>
                        <Grid container spacing={2} sx={{ fontSize: 10 }}>
                            <Grid item lg={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel>Property Name</InputLabel>
                                    <OutlinedInput
                                        label="Property Name"
                                        value={requestData.property_name}
                                        onChange={(e) => {
                                            updateRequestData("property_name", e.currentTarget.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item lg={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel> Property ID</InputLabel>
                                    <OutlinedInput
                                        label=" Property ID"
                                        value={requestData.assigned_id_type}
                                        onChange={(e) => {
                                            // const value = e.target.checked;
                                            requestData.assigned_id_type = e.target.value;
                                            updateRequestData("assigned_id_type", e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            {/* <Grid item lg={12}>
                <FormControl fullWidth size="small">
                  <FormLabel id="demo-radio-buttons-group-label">
                    Property ID
                  </FormLabel>
                  <RadioGroup
                    row
                    value={requestData.assigned_id_type}
                    defaultValue="MANUAL"
                    onChange={(e) => {
                      const value = e.target.checked;
                      requestData.assigned_id_type = e.target.value;
                      updateRequestData("assigned_id_type", e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="MANUAL"
                      control={<Radio size="small" />}
                      label="Manual"
                    />
                    <FormControlLabel
                      value="AUTO"
                      control={<Radio size="small" />}
                      label="Auto Assign"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid> 
              {requestData.assigned_id_type === "MANUAL" && (
                <Grid item lg={12} sx={{ mt: -2.5 }}>
                  <FormControl fullWidth size="small" sx={{ pt: 0, mt: 0 }}>
                    <OutlinedInput
                      value={requestData.assigned_id_manual}
                      onChange={(e) => {
                        const value = e.target.value;
                        updateRequestData("assigned_id_manual", value);
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item lg={12}>
                <FormControl fullWidth size="small">
                  <FormLabel id="demo-radio-buttons-group-label">
                    Initial Status
                  </FormLabel>
                  <RadioGroup
                    row
                    name="row-radio-buttons-group"
                    value={requestData.status}
                    onChange={(e) => {
                      const value = e.target.value;
                      requestData.status = value;
                      updateRequestData("status", value);
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          size="small"
                          value="PRELAUNCH"
                          checked={requestData.status === "PRELAUNCH"}
                        />
                      }
                      label="Prelaunch"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          size="small"
                          value="ACTIVE"
                          checked={requestData.status === "ACTIVE"}
                        />
                      }
                      label="Live"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>*/}
                        </Grid>
                    </Box>
                    {isrequesting && (
                        <Box
                            sx={{
                                textAlighn: "center",
                                backgroundColor: "dark.darkest",
                                opacity: 0.8,
                                position: "fixed",
                                top: 40,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                zIndex: 100,
                            }}
                        >
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: "white",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-20px",
                                    marginLeft: "-20px",
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
}

function SegmentsMappingSettings(props) {
    const dispatch = useDispatch();
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for property mapping was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });

    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const market_segment_category_mapping = JSON.parse(
        JSON.stringify(system_settings[0].market_segment_category_mapping)
    );
    const market_segment_category_order = JSON.parse(
        JSON.stringify(system_settings[0].market_segment_category_order)
    );
    let market_code_ignorelist = JSON.parse(
        JSON.stringify(system_settings[0].market_code_ignorelist)
    );
    //Handling market_code_ignorelist in case its coming wrong from API
    market_code_ignorelist = market_code_ignorelist && market_code_ignorelist[0] !== "{NULL}" ? market_code_ignorelist : [];

    const lvl1_mseg_grouping = JSON.parse(
        JSON.stringify(system_settings[0].lvl1_mseg_grouping)
    );
    const topSegments = {};
    // debugger;
    let Transient = lvl1_mseg_grouping.filter((item) => item.Transient);
    let Group = lvl1_mseg_grouping.filter((item) => item.Group);
    let Contract = lvl1_mseg_grouping.filter((item) => item.Contract);
    topSegments.Transient = Transient.length ? Transient[0].Transient : [];
    topSegments.Group = Group.length ? Group[0].Group : [];
    topSegments.Contract = Contract.length ? Contract[0].Contract : [];
    // debugger;
    const settings_ = JSON.parse(
        JSON.stringify({
            market_segment_category_mapping: market_segment_category_mapping,
            market_segment_category_order: market_segment_category_order,
            market_code_ignorelist: market_code_ignorelist,
            topSegments: topSegments,
            lvl1_mseg_grouping: lvl1_mseg_grouping,
        })
    );
    const settings = JSON.parse(
        JSON.stringify({
            market_segment_category_mapping:
                system_settings[0].market_segment_category_mapping,
            market_segment_category_order:
                system_settings[0].market_segment_category_order,
            market_code_ignorelist: market_code_ignorelist,
            topSegments: topSegments,
            lvl1_mseg_grouping: lvl1_mseg_grouping,
        })
    );
    //const settings = JSON.parse(JSON.stringify(system_settings[0].market_segment_category_mapping));
    const [requestData, setRequestData] = useState(settings);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);
    // debugger; 
    const [ignoreCodes, setIgnoreCodes] = useState([]);

    useEffect(() => {

        requestData.market_segment_category_order.map((item, index) => {
            if (requestData.market_segment_category_mapping[item]) {
                requestData.market_segment_category_mapping[item].map((itm, idx) => {
                    if (ignoreCodes.indexOf(itm) === -1) {
                        ignoreCodes.push(itm);
                    }
                });
            }

        });
        // debugger;
        // setRequestData({ ...requestData });
        setIgnoreCodes([...ignoreCodes]);

        ratePlanGetData();
        // segmentGetData();
        // topSegmentGetData();
    }, []);

    const [selectedValues, setSelectedValues] = useState(requestData.topSegments["Transient"]);
    const handleRemoveChip = (chipToRemove) => {
        setSelectedValues((chips) => chips.filter((chip) => chip !== chipToRemove));
    };

    const [roomSettingsOld, setRoomSettingsOld] = useState(JSON.parse(JSON.stringify(requestData.market_segment_category_order))); //taking a backup of Old Array
    const [roomSettings, setRoomSettings] = useState(requestData.market_segment_category_order);

    //console.log('old-segment-order', roomSettingsOld);
    //console.log('new-segment-order', roomSettings);

    const cancelSave = () => {
        setRoomSettings(JSON.parse(JSON.stringify(roomSettingsOld)));
        setTopSegmentsUnappedArray(JSON.parse(JSON.stringify(topSegmentsUnappedArrayBackup)));
        //setSegmentsUnappedArray(JSON.parse(JSON.stringify(segmentsUnappedArrayBackup)));

        setRatePlanMainData(JSON.parse(JSON.stringify(ratePlanMainDataBackup)));
        setSegmentMainData(JSON.parse(JSON.stringify(segmentMainDataBackup)));
        setTopSegmentsMainArray(JSON.parse(JSON.stringify(topSegmentsMainArrayBackup)));

        setRequestData(settings_);
        setIsChangeRequest(false);
    };
    const updateRequestData = (keys, val, index, item) => {
        const updatedObj = { ...requestData };
        updatedObj.market_segment_category_order = roomSettings;
        const key = keys.split(">");
        // const originalSettings = settings_;
        // debugger;
        if (key[0] === 'market_segment_category_mapping') {
            const size = updatedObj[key[0]][key[1]].length;
            updatedObj[key[0]][key[1]].splice(0, size);
            updatedObj[key[0]][key[1]] = [...val];
        } else
            if (key[0] == "lvl1_mseg_grouping") {
                updatedObj.topSegments[key[1]] = val;
            } else
                if (key[0] == "market_segment_category_order") {
                    const isValuePresent = updatedObj.market_segment_category_order.includes(val);
                    if (isValuePresent) {
                        alert(val + " is already exists. Please enter some different value.");
                        // setTimeout(() => {
                        //     containerRef.current.scrollTop = currentScrollTop;
                        // }, 0);
                        return;
                    }
                    updatedObj.market_segment_category_order[index] = val;
                    let temp = updatedObj.market_segment_category_mapping[item];
                    updatedObj.market_segment_category_mapping[val] = temp;
                    if (updatedObj.topSegments.Transient.indexOf(item) !== -1) {
                        let i = updatedObj.topSegments.Transient.indexOf(item);
                        updatedObj.topSegments.Transient.splice(i, 1, val);
                    }
                    if (updatedObj.topSegments.Group.indexOf(item) !== -1) {
                        let i = updatedObj.topSegments.Group.indexOf(item);
                        updatedObj.topSegments.Group.splice(i, 1, val);
                    }
                    if (updatedObj.topSegments.Contract.indexOf(item) !== -1) {
                        let i = updatedObj.topSegments.Contract.indexOf(item);
                        updatedObj.topSegments.Contract.splice(i, 1, val);
                    }
                    delete updatedObj.market_segment_category_mapping[item];

                    //debugger;
                    // let size = val.length;
                    // let l = updatedObj.market_segment_category_order[key[1]].length;
                    // updatedObj.market_segment_category_order[key[1]].splice(0, l);
                    // val.map((item, i) => {
                    //   updatedObj.market_segment_category_order[key[1]].push(item);
                    // });
                } else if (key[0] == "market_code_ignorelist") {
                    // updatedObj.market_code_ignorelist = val;
                    let size = val.length;

                    let l = updatedObj.market_code_ignorelist.length;
                    updatedObj.market_code_ignorelist.splice(0, l);
                    val.map((item, i) => {
                        updatedObj.market_code_ignorelist.push(item);
                    });
                } else {
                    let finalVal;
                    if (typeof val === "string" && val !== "") {
                        // finalVal = val.split(",");
                        finalVal = val;
                        // finalVal.map((item, index) => {
                        //   finalVal[index] = finalVal[index].trim();
                        // });
                        //updatedObj[key[0]][key[1]].push(finalVal);
                    } else {
                        //updatedObj[key[0]][key[1]].pop();
                    }
                    //updatedObj[key[0]][key[1]] = finalVal;
                }
        //  debugger;
        setRequestData({ ...requestData, ...updatedObj });
        setIsChangeRequest(true);
        //debugger;
    };
    const deleteMarketSegment = (item) => {
        if (containerRef.current) {

            const currentScrollTop = containerRef.current.scrollTop;

            const updatedSegmentOrderList = [...segmentOrderList];
            const updatedSegmentMappingData = { ...segmentMappingData };

            // assign codes to to unmapped section
            const codes = updatedSegmentMappingData[item].codes;
            Object.assign(updatedSegmentMappingData.UNMAPPED.codes, codes);

            // remove item from updatedSegmentOrderList
            const indexToDelete = updatedSegmentOrderList.indexOf(item);
            updatedSegmentOrderList.splice(indexToDelete, 1);

            // Remove the item from updatedSegmentMappingData
            delete updatedSegmentMappingData[item];

            setSegmentMappingData(updatedSegmentMappingData);
            setSegmentOrderList(updatedSegmentOrderList);

            setIsChangeRequest(true);

            setTimeout(() => {
                containerRef.current.scrollTop = currentScrollTop;
            }, 0);
        }
    };
    const changeSegmentsOrder = (item, index, type) => {
        //debugger;
        const tempObj = item;
        const size = requestData.market_segment_category_order.length;
        if (type == "up" && index) {
            requestData.market_segment_category_order[index] =
                requestData.market_segment_category_order[index - 1];
            requestData.market_segment_category_order[index - 1] = item;
            setRequestData({
                ...requestData,
                market_segment_category_order:
                    requestData.market_segment_category_order,
            });
            setIsChangeRequest(true);
        }
        if (type == "down" && index < size) {
            requestData.current.room_settings[index] =
                requestData.current.room_settings[index + 1];
            requestData.current.room_settings[index + 1] = item;
            setRequestData({ ...requestData, current: requestData.current });
            setIsChangeRequest(true);
        }
    };
    const addNewSegmentMap = () => {
        if (containerRef.current) {
            // adding item into segmentOrderList array
            const updatedSegmentOrderList = [...segmentOrderList];
            const newRatePlan = "Segment Code " + (updatedSegmentOrderList.length + 1);
            updatedSegmentOrderList.push(newRatePlan)

            // Adding object into segmentMappingData
            const updatedSegmentMappingData = { ...segmentMappingData };
            const mappingOrder = Object.keys(updatedSegmentMappingData).length;
            updatedSegmentMappingData[newRatePlan] = { order: mappingOrder, codes: {} }

            setSegmentOrderList(updatedSegmentOrderList)
            setSegmentMappingData(updatedSegmentMappingData)

            setIsChangeRequest(true);

            setTimeout(() => {
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }, 0);
        }
    };
    const saveChanges = (event) => {
        setIsRequesting(true);
        let lvl1_mseg_grouping = [];
        lvl1_mseg_grouping.push({ Transient: requestData.topSegments.Transient });
        lvl1_mseg_grouping.push({ Group: requestData.topSegments.Group });
        lvl1_mseg_grouping.push({ Contract: requestData.topSegments.Contract });

        const request_system_settings = {
            ...system_settings[0],
            market_segment_category_mapping:
                requestData.market_segment_category_mapping,
            market_segment_category_order: roomSettings,
            market_code_ignorelist: requestData.market_code_ignorelist,
            lvl1_mseg_grouping: lvl1_mseg_grouping,
        };
        // debugger;
        let payload = {
            "resource_id": app_state.property_id,
            "resource_type": "property",
            "update_type": "full_system_settings",
            "data": {
                "full_ss": request_system_settings
            }
        };

        let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
        let active_ds_id = dsInfo.active_source.id;
        ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

        dataService
            .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setIsRequestSuccess(true);
                    setIsRequesting(false);
                    setIsChangeRequest(false);
                    setTimeout(() => {
                        profile.property_details[prop_details_index].system_settings[0] =
                            request_system_settings;
                        dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
                    }, 500);
                    setTimeout(() => {
                        setIsRequestSuccess(false);
                    }, 5000);
                } else {
                    setIsRequestError(true);
                    setIsRequesting(false);
                    setResErrorMsg(data.message);
                    //return Promise.reject(data);
                }
            })
            .catch((err) => {
                setIsRequesting(false);
                setIsRequestError(true);
                setResErrorMsg(
                    (err && err.original && err.original.statusText) ||
                    "Something went wrong, please try again later!"
                );
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
                //return Promise.reject(err);
            });
    };
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }

        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }

        if (value2 === 0) {
            ratePlanGetData();
        }
        else if (value2 === 1) {
            segmentGetData();
        }
        else if (value2 === 2) {
            topSegmentGetData();
        }
        cleanIDB();
        //reverting changes back
        // setRoomSettings(JSON.parse(JSON.stringify(roomSettingsOld)));

        // setRatePlanMainData(JSON.parse(JSON.stringify(ratePlanMainDataBackup)));
        // setSegmentMainData(JSON.parse(JSON.stringify(segmentMainDataBackup)));
        // setTopSegmentsMainArray(JSON.parse(JSON.stringify(topSegmentsMainArrayBackup)));

        // setRequestData(settings_);
        setIsChangeRequest(false);

        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        //setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }

    function CustomChipDeleteIcon() {
        return <svg className="custom-chip-delete-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#606060" viewBox="0 0 256 256"><path d="M165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path></svg>
    }

    function ExpandMoreIcon() {
        return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#606060" viewBox="0 0 256 256"><path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path></svg>
    }

    //mapping tabs starts
    const [value2, setValue2] = React.useState(0);

    const handleChange2 = (event, newValue) => {
        if (isChangeRequest) {
            setIsShakeButton(true);
            setTimeout(function () {
                setIsShakeButton(false);
            }, 3000); // 3000 milliseconds = 3 seconds
            return;
        }
        else {
            if (newValue === 0) {
                ratePlanGetData();
            }
            else if (newValue === 1) {
                segmentGetData();
            }
            else if (newValue === 2) {
                topSegmentGetData();
            }
        }
        setValue2(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    //mapping tabs ends


    //Segments meta data 
    const segmentAllCodesArray = ['TBAR', 'TCON', 'TPRC', 'TPKG', 'TDIS', 'TFIT', 'TNOP', 'TOPA', 'TPRM', 'COMP', 'HUSE', 'PSBY', 'TOTH', 'TRANSIENT', 'GCOR', 'GASC', 'GTRT', 'GGOV', 'GSMF', 'GOTH', 'CONTRACT', 'CAIR', 'CODE1', 'CODE2', 'CODE3', 'CODE4', 'CODE5'];
    const segmentMappedArray = [];

    roomSettings.map((item, index) => {
        if (requestData.market_segment_category_mapping[item]) {
            requestData.market_segment_category_mapping[item].map((item2, index2) => {
                segmentMappedArray.push(item2);
            });
        }

    });
    const segmentUnmappedArray = segmentAllCodesArray.filter(item => !segmentMappedArray.includes(item));
    //const [segmentsUnappedArrayBackup, setSegmentsUnappedArrayBackup] = useState(segmentAllCodesArray.filter(item => !segmentMappedArray.includes(item)));
    //const [segmentUnmappedArray, setSegmentsUnappedArray] = useState(segmentsUnappedArrayBackup);
    // console.log("segmentAllCodesArray", segmentAllCodesArray);
    // console.log("segmentMappedArray", segmentMappedArray);
    // console.log("segmentUnmappedArray", segmentUnmappedArray);


    //Rate Plan meta data



    //delete function for Top Segment Chips

    const handleDeleteTopSegments = (option, itemName) => {

        const sourceIndex = topSegmentsMainArray.findIndex(item => itemName in item);
        const targetIndex = topSegmentsMainArray.findIndex(item => "Unmapped" in item);
        const sourceArray = topSegmentsMainArray[sourceIndex][itemName];
        const targetArray = topSegmentsMainArray[targetIndex]["Unmapped"];
        const valueIndex = sourceArray.indexOf(option);

        // Update source array by removing the value
        sourceArray.splice(valueIndex, 1);

        // Update target array by adding the value
        targetArray.push(option);

        // Update state directly
        setTopSegmentsMainArray([...topSegmentsMainArray]);

        setIsChangeRequest(true);
    };

    //delete function for Segment Chips
    const handleDeleteSegments = (item, option) => {
        if (containerRef.current) {

            const currentScrollTop = containerRef.current.scrollTop;

            const updatedSegmentMappingData = { ...segmentMappingData };

            console.log("item", item)
            console.log("option", option)

            // Add 'option' to UNMAPPED.codes
            updatedSegmentMappingData.UNMAPPED.codes[option] = segmentMappingData[item].codes[option];

            // Remove the 'option' from the current object
            delete updatedSegmentMappingData[item].codes[option];

            setSegmentMainData(updatedSegmentMappingData);
            setIsChangeRequest(true);

            setTimeout(() => {
                containerRef.current.scrollTop = currentScrollTop;
            }, 0);
        }
    };

    //delete function for Rate Plan Chips
    const handleDeleteRatePlan = (item, option) => {

        if (containerRef.current) {

            const currentScrollTop = containerRef.current.scrollTop;

            const updatedRatePlanMappingData = { ...ratePlanMappingData };

            console.log("item", item)
            console.log("option", option)

            // Add 'option' to UNMAPPED.codes
            updatedRatePlanMappingData.UNMAPPED.codes[option] = ratePlanMappingData[item].codes[option];

            // Remove the 'option' from the current object
            delete updatedRatePlanMappingData[item].codes[option];

            setRatePlanMappingData(updatedRatePlanMappingData);
            setIsChangeRequest(true);

            setTimeout(() => {
                containerRef.current.scrollTop = currentScrollTop;
            }, 0);
        }
    };


    //drag and drop handle for Top Segments
    const handleDragEndTopSegments = (result) => {
        if (!result.destination) {
            return;
        }

        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        const draggedChip = result.draggableId;

        console.log("result.source.droppableId", result.source.droppableId)
        console.log("result.destination.droppableId", result.destination.droppableId)
        console.log("draggedChip", draggedChip)

        let sourceKey = result.source.droppableId;
        let targetKey = result.destination.droppableId;
        let value = draggedChip;

        if (result.destination.droppableId !== 'Unmapped') {
            console.log("moving from unmapped to map")

            const sourceIndex = topSegmentsMainArray.findIndex(item => sourceKey in item);
            const targetIndex = topSegmentsMainArray.findIndex(item => targetKey in item);

            if (sourceIndex !== -1 && targetIndex !== -1) {
                const sourceArray = topSegmentsMainArray[sourceIndex][sourceKey];
                const targetArray = topSegmentsMainArray[targetIndex][targetKey];
                const valueIndex = sourceArray.indexOf(value);

                if (valueIndex !== -1) {
                    // Update source array by removing the value
                    sourceArray.splice(valueIndex, 1);

                    // Update target array by adding the value
                    targetArray.push(value);

                    // Update state directly
                    setTopSegmentsMainArray([...topSegmentsMainArray]);
                }
            }
        }
        setIsChangeRequest(true);
    };

    //drag and drop handle for Segments
    const handleDragEndSegments = (result) => {
        if (containerRef.current) {

            const currentScrollTop = containerRef.current.scrollTop;

            if (!result.destination) {
                return;
            }

            const sourceIndex = result.source.index;
            const destinationIndex = result.destination.index;

            console.log("result.source.droppableId", result.source.droppableId)
            console.log("result.destination.droppableId", result.destination.droppableId)

            if (result.type === "droppableItem") {
                console.log("main item drag");

                const segmentOrderListCopy = [...segmentOrderList];

                // Reorder the data based on the drag and drop result
                const [removed] = segmentOrderListCopy.splice(sourceIndex, 1);
                segmentOrderListCopy.splice(destinationIndex, 0, removed);

                setSegmentOrderList(segmentOrderListCopy)
            }
            else if (result.type === "droppableSubItem") {
                console.log("sub item drag");

                if (result.source.droppableId === 'unmappedSegmentChips' && result.destination.droppableId !== 'unmappedSegmentChips' && result.destination.droppableId !== 'ignoredSegmentChips') {
                    // Dragged chip
                    const draggedChip = result.draggableId;

                    // Add the dragged chip to segmentMappingData[result.destination.droppableId].codes
                    const newItemData = { ...segmentMappingData[result.destination.droppableId].codes };
                    newItemData[draggedChip] = segmentMappingData.UNMAPPED.codes[draggedChip];

                    // Remove the dragged chip from segmentMappingData.UNMAPPED.codes
                    const newUnmappedArray = { ...segmentMappingData.UNMAPPED.codes };
                    delete newUnmappedArray[draggedChip];

                    const updatedData = { ...segmentMappingData };
                    updatedData.UNMAPPED.codes = newUnmappedArray;
                    updatedData[result.destination.droppableId].codes = newItemData;

                    setSegmentMappingData(updatedData);
                }

                else if (result.source.droppableId === 'unmappedSegmentChips' && result.destination.droppableId !== 'unmappedSegmentChips' && result.destination.droppableId === 'ignoredSegmentChips') {
                    // Dragged chip
                    const draggedChip = result.draggableId;

                    // Add the dragged chip to segmentMappingData[result.destination.droppableId].codes
                    const newItemData = { ...segmentMappingData.IGNORED.codes };
                    newItemData[draggedChip] = segmentMappingData.UNMAPPED.codes[draggedChip];

                    // Remove the dragged chip from segmentMappingData.UNMAPPED.codes
                    const newUnmappedArray = { ...segmentMappingData.UNMAPPED.codes };
                    delete newUnmappedArray[draggedChip];

                    const updatedData = { ...segmentMappingData };
                    updatedData.UNMAPPED.codes = newUnmappedArray;
                    updatedData.IGNORED.codes = newItemData;

                    setSegmentMappingData(updatedData);
                }

                else {
                    // Dragged chip
                    const draggedChip = result.draggableId;

                    // Add the dragged chip to segmentMappingData.[result.destination.droppableId].codes
                    const newItemData = { ...segmentMappingData[result.destination.droppableId].codes };
                    newItemData[draggedChip] = segmentMappingData[result.source.droppableId].codes[draggedChip];

                    // Remove the dragged chip from segmentMappingData[result.source.droppableId].codes
                    const newUnmappedArray = { ...segmentMappingData[result.source.droppableId].codes };
                    delete newUnmappedArray[draggedChip];

                    const updatedData = { ...segmentMappingData };
                    updatedData[result.source.droppableId].codes = newUnmappedArray;
                    updatedData[result.destination.droppableId].codes = newItemData;

                    setSegmentMappingData(updatedData);
                }
            }
            setIsChangeRequest(true);

            setTimeout(() => {
                containerRef.current.scrollTop = currentScrollTop;
            }, 0);
        }
    };

    //drag and drop handle for Rate Plan
    const handleDragEndRatePlan = (result) => {

        if (containerRef.current) {

            const currentScrollTop = containerRef.current.scrollTop;

            if (!result.destination) {
                return; // Dropped outside of a valid droppable area
            }

            const sourceIndex = result.source.index;
            const destinationIndex = result.destination.index;

            if (result.type === "droppableItem") {
                console.log("main item drag");

                // Create a copy of ratePlanOrderList
                const ratePlanOrderListCopy = [...ratePlanOrderList];

                // Reorder the data based on the drag and drop result
                const [removed] = ratePlanOrderListCopy.splice(sourceIndex, 1);
                ratePlanOrderListCopy.splice(destinationIndex, 0, removed);

                setRatePlanOrderList(ratePlanOrderListCopy)
            }
            else if (result.type === "droppableSubItem") {
                console.log("sub item drag");

                if (result.source.droppableId === 'unmappedSegmentChips' && result.destination.droppableId !== 'unmappedSegmentChips') {
                    // Dragged chip
                    const draggedChip = result.draggableId;

                    // Add the dragged chip to ratePlanMappingData[result.destination.droppableId].codes
                    const newItemData = { ...ratePlanMappingData[result.destination.droppableId].codes };
                    newItemData[draggedChip] = ratePlanMappingData.UNMAPPED.codes[draggedChip];

                    // Remove the dragged chip from ratePlanMappingData.UNMAPPED.codes
                    const newUnmappedArray = { ...ratePlanMappingData.UNMAPPED.codes };
                    delete newUnmappedArray[draggedChip];

                    const updatedData = { ...ratePlanMappingData };
                    updatedData.UNMAPPED.codes = newUnmappedArray;
                    updatedData[result.destination.droppableId].codes = newItemData;

                    setRatePlanMappingData(updatedData);


                    // // Dragged chip
                    // const draggedChip = result.draggableId;

                    // // Add the dragged chip to ratePlanMappingData[result.destination.droppableId].codes
                    // ratePlanMappingData[result.destination.droppableId].codes[draggedChip] = ratePlanMappingData.UNMAPPED.codes[draggedChip];

                    // // Remove the dragged chip from ratePlanMappingData.UNMAPPED.codes
                    // delete ratePlanMappingData.UNMAPPED.codes[draggedChip];
                }
                else {
                    // Dragged chip
                    const draggedChip = result.draggableId;

                    // Add the dragged chip to ratePlanMappingData.[result.destination.droppableId].codes
                    const newItemData = { ...ratePlanMappingData[result.destination.droppableId].codes };
                    newItemData[draggedChip] = ratePlanMappingData[result.source.droppableId].codes[draggedChip];

                    // Remove the dragged chip from ratePlanMappingData[result.source.droppableId].codes
                    const newUnmappedArray = { ...ratePlanMappingData[result.source.droppableId].codes };
                    delete newUnmappedArray[draggedChip];

                    const updatedData = { ...ratePlanMappingData };
                    updatedData[result.source.droppableId].codes = newUnmappedArray;
                    updatedData[result.destination.droppableId].codes = newItemData;

                    setRatePlanMappingData(updatedData);
                }

            }

            setIsChangeRequest(true);
            //console.log("After drag drop ratePlanMainArray", ratePlanMainArray);

            setTimeout(() => {
                containerRef.current.scrollTop = currentScrollTop;
            }, 0);
        }


    };

    const [editableIndex, setEditableIndex] = useState(-1);
    const handleEditClick = (e, index) => {
        e.preventDefault(); // Prevent the default behavior (scrolling to top)
        setEditableIndex(index);
    };

    const containerRef = useRef(null);
    const [previousScrollTop, setPreviousScrollTop] = useState(0);

    const addNewRatePlan = () => {

        if (containerRef.current) {

            // adding item into ratePlanOrderList array
            const updatedRatePlanOrderList = [...ratePlanOrderList];
            const newRatePlan = "Segment Code " + (updatedRatePlanOrderList.length + 1);
            updatedRatePlanOrderList.push(newRatePlan)

            // Adding object into ratePlanMappingData
            const updatedRatePlanMappingData = { ...ratePlanMappingData };
            const mappingOrder = Object.keys(updatedRatePlanMappingData).length;
            updatedRatePlanMappingData[newRatePlan] = { order: mappingOrder, codes: {} }

            setRatePlanOrderList(updatedRatePlanOrderList)
            setRatePlanMappingData(updatedRatePlanMappingData)

            setIsChangeRequest(true);

            setTimeout(() => {
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }, 0);
        }
    };

    const deleteRatePlan = (ratePlanName) => {
        if (containerRef.current) {

            const currentScrollTop = containerRef.current.scrollTop;

            const updatedRatePlanOrderList = [...ratePlanOrderList];
            const updatedRatePlanMappingData = { ...ratePlanMappingData };

            // assign codes to to unmapped section
            const codes = updatedRatePlanMappingData[ratePlanName].codes;
            Object.assign(updatedRatePlanMappingData.UNMAPPED.codes, codes);

            // remove item from updatedRatePlanOrderList
            const indexToDelete = updatedRatePlanOrderList.indexOf(ratePlanName);
            updatedRatePlanOrderList.splice(indexToDelete, 1);

            // Remove the item from updatedSegmentMappingData
            delete updatedRatePlanMappingData[ratePlanName];

            setRatePlanMappingData(updatedRatePlanMappingData);
            setRatePlanOrderList(updatedRatePlanOrderList);

            setIsChangeRequest(true);

            setTimeout(() => {
                containerRef.current.scrollTop = currentScrollTop;
            }, 0);
        }
    };

    // Rate Plan Save function
    const ratePlanSaveData = async () => {

        console.log("ratePlanMappingData : ", ratePlanMappingData)
        console.log("ratePlanOrderList : ", ratePlanOrderList)

        setIsRequesting(true);
        try {
            const response = await DataService.update({
                api: "rms/properties/mapping/" + app_state.property_id + "/" + app_state.active_data_src.id + "/?typeOfMapping=rate_code__market_segment&raw=false&includeDescOrder=true",
                request: { method: 'PUT', mapping: ratePlanMappingData, orderedList: ratePlanOrderList }
            });
            if (response.status === "success") {
                setIsRequestSuccess(true);
                setIsRequesting(false);
                setIsChangeRequest(false);
                setEditableIndex(-1);
                setTimeout(() => {
                    setIsRequestSuccess(false);
                }, 2000);
            } else {
                setIsRequestError(true);
                setIsRequesting(false);
                setResErrorMsg(response.message);
            }
        }
        catch (err) {
            setIsRequesting(false);
            setIsRequestError(true);
            setResErrorMsg(
                (err && err.original && err.original.statusText) ||
                "Something went wrong, please try again later!"
            );
            setTimeout(() => {
                setIsRequestError(false);
            }, 5000);
        }
    }

    // Top Segments Save function
    const topSegmentSaveData = async () => {

        console.log("topSegment Save data : ", topSegmentsMainArray)

        setIsRequesting(true);
        try {
            const response = await DataService.update({
                api: "rms/properties/mapping/" + app_state.property_id + "/" + app_state.active_data_src.id + "/?typeOfMapping=market_group__aggregates",
                request: { method: 'PUT', mapping: topSegmentsMainArray }
            });
            if (response.status === "success") {
                setIsRequestSuccess(true);
                setIsRequesting(false);
                setIsChangeRequest(false);
                setTimeout(() => {
                    setIsRequestSuccess(false);
                }, 2000);
            } else {
                setIsRequestError(true);
                setIsRequesting(false);
                setResErrorMsg(response.message);
            }
        }
        catch (err) {
            setIsRequesting(false);
            setIsRequestError(true);
            setResErrorMsg(
                (err && err.original && err.original.statusText) ||
                "Something went wrong, please try again later!"
            );
            setTimeout(() => {
                setIsRequestError(false);
            }, 5000);
        }
    }

    // Segment Save function
    const segmentSaveData = async () => {
        console.log("segmentSaveData is called")
        console.log("segmentMappingData : ", segmentMappingData)
        console.log("segmentOrderList : ", segmentOrderList)

        setIsRequesting(true);
        try {
            const response = await DataService.update({
                api: "rms/properties/mapping/" + app_state.property_id + "/" + app_state.active_data_src.id + "/?typeOfMapping=market_group__market_code&raw=false&includeDescOrder=True",
                request: { method: 'PUT', mapping: segmentMappingData, orderedList: segmentOrderList }
            });
            if (response.status === "success") {
                setIsRequestSuccess(true);
                setIsRequesting(false);
                setIsChangeRequest(false);
                setEditableIndex(-1);
                setTimeout(() => {
                    setIsRequestSuccess(false);
                }, 2000);
            } else {
                setIsRequestError(true);
                setIsRequesting(false);
                setResErrorMsg(response.message);
            }
        }
        catch (err) {
            setIsRequesting(false);
            setIsRequestError(true);
            setResErrorMsg(
                (err && err.original && err.original.statusText) ||
                "Something went wrong, please try again later!"
            );
            setTimeout(() => {
                setIsRequestError(false);
            }, 5000);
        }
    }

    const handleRatePlanInputChange = async (newValue, index, oldValue) => {

        if (containerRef.current) {

            const currentScrollTop = containerRef.current.scrollTop;

            setEditableIndex(-1)

            if (newValue !== "" && newValue !== oldValue) {
                const updatedRatePlanOrderList = [...ratePlanOrderList];

                const isValuePresent = updatedRatePlanOrderList.includes(newValue);
                if (isValuePresent) {
                    //alert(newValue + " is already exists. Please enter some different value.");
                    setIsRequestError(true);
                    setResErrorMsg(newValue + " is already exists. Please enter some different value.");
                    setTimeout(() => {
                        setIsRequestError(false);
                    }, 5000);

                    setTimeout(() => {
                        containerRef.current.scrollTop = currentScrollTop;
                    }, 0);
                    return;
                }

                updatedRatePlanOrderList[index] = newValue;

                const updatedRatePlanMappingData = { ...ratePlanMappingData };
                updatedRatePlanMappingData[newValue] = updatedRatePlanMappingData[oldValue];
                delete updatedRatePlanMappingData[oldValue];

                setRatePlanOrderList(updatedRatePlanOrderList);
                setRatePlanMappingData(updatedRatePlanMappingData);
                //setIsChangeRequest(true);

                //PATCH API Call
                setIsRequesting(true);
                try {
                    const response = await DataService.update({
                        api: "rms/properties/mapping/" + app_state.property_id + "/" + app_state.active_data_src.id + "/?typeOfMapping=rate_code__market_segment",
                        request: { method: 'PATCH', renameDict: { [oldValue]: newValue } }
                    });
                    if (response.status === "success") {
                        //setIsRequestSuccess(true);
                        setIsRequesting(false);
                        //setIsChangeRequest(false);
                        setEditableIndex(-1);
                        // setTimeout(() => {
                        //     setIsRequestSuccess(false);
                        // }, 5000);
                        //ratePlanGetData();
                    } else {
                        setIsRequestError(true);
                        setIsRequesting(false);
                        setResErrorMsg(response.message);
                    }
                }
                catch (err) {
                    setIsRequesting(false);
                    setIsRequestError(true);
                    setResErrorMsg(
                        (err && err.original && err.original.statusText) ||
                        "Something went wrong, please try again later!"
                    );
                    setTimeout(() => {
                        setIsRequestError(false);
                    }, 5000);
                }
            }

            setTimeout(() => {
                containerRef.current.scrollTop = currentScrollTop;
            }, 0);
        }
    };

    const handleSegmentInputChange = async (newValue, index, oldValue) => {
        if (containerRef.current) {

            const currentScrollTop = containerRef.current.scrollTop;

            setEditableIndex(-1)

            if (newValue !== "" && newValue !== oldValue) {
                const updatedSegmentOrderList = [...segmentOrderList];

                const isValuePresent = updatedSegmentOrderList.includes(newValue);
                if (isValuePresent) {
                    //alert(newValue + " is already exists. Please enter some different value.");
                    setIsRequestError(true);
                    setResErrorMsg(newValue + " is already exists. Please enter some different value.");
                    setTimeout(() => {
                        setIsRequestError(false);
                    }, 5000);

                    setTimeout(() => {
                        containerRef.current.scrollTop = currentScrollTop;
                    }, 0);
                    return;
                }

                updatedSegmentOrderList[index] = newValue;

                const updatedSegmentMappingData = { ...segmentMappingData };
                updatedSegmentMappingData[newValue] = updatedSegmentMappingData[oldValue];
                delete updatedSegmentMappingData[oldValue];

                setSegmentOrderList(updatedSegmentOrderList);
                setSegmentMappingData(updatedSegmentMappingData);
                //setIsChangeRequest(true);

                //PATCH API Call
                setIsRequesting(true);
                try {
                    const response = await DataService.update({
                        api: "rms/properties/mapping/" + app_state.property_id + "/" + app_state.active_data_src.id + "/?typeOfMapping=market_group__market_code",
                        request: { method: 'PATCH', renameDict: { [oldValue]: newValue } }
                    });
                    if (response.status === "success") {
                        //setIsRequestSuccess(true);
                        setIsRequesting(false);
                        //setIsChangeRequest(false);
                        setEditableIndex(-1);
                        // setTimeout(() => {
                        //     setIsRequestSuccess(false);
                        // }, 5000);
                        //segmentGetData();
                    } else {
                        setIsRequestError(true);
                        setIsRequesting(false);
                        setResErrorMsg(response.message);
                    }
                }
                catch (err) {
                    setIsRequesting(false);
                    setIsRequestError(true);
                    setResErrorMsg(
                        (err && err.original && err.original.statusText) ||
                        "Something went wrong, please try again later!"
                    );
                    setTimeout(() => {
                        setIsRequestError(false);
                    }, 5000);
                }
            }


            setTimeout(() => {
                containerRef.current.scrollTop = currentScrollTop;
            }, 0);
        }
    };

    const [isShakeButton, setIsShakeButton] = useState(false);



    // Rate Plan New API Integration  
    const [ratePlanMainData, setRatePlanMainData] = useState(JSON.parse(JSON.stringify({})));
    const [ratePlanMainDataBackup, setRatePlanMainDataBackup] = useState(JSON.parse(JSON.stringify({})));

    const [ratePlanOrderList, setRatePlanOrderList] = useState(JSON.parse(JSON.stringify([])));
    const [ratePlanMappingData, setRatePlanMappingData] = useState(JSON.parse(JSON.stringify({ UNMAPPED: { codes: {} } })));

    useEffect(() => {
        if (ratePlanMainData.ordered_list !== undefined && ratePlanMainData.mapping !== undefined) {
            if (ratePlanMainData.mapping.UNMAPPED === undefined) {
                console.log("UNMAPPED undefined, adding UNMAPPED locally")
                // adding item into ratePlanOrderList array
                ratePlanMainData.ordered_list.push('UNMAPPED')

                // Adding object into ratePlanMappingData
                const mappingOrder = Object.keys(ratePlanMainData.mapping).length;
                ratePlanMainData.mapping['UNMAPPED'] = { order: mappingOrder, codes: {} }
            }
            setRatePlanOrderList(JSON.parse(JSON.stringify(ratePlanMainData.ordered_list)));
            setRatePlanMappingData(JSON.parse(JSON.stringify(ratePlanMainData.mapping)));
        }

    }, [ratePlanMainData]);
    // console.log("ratePlanMappingData", ratePlanMappingData)
    // console.log("ratePlanOrderList", ratePlanOrderList)

    const ratePlanGetData = async () => {
        setIsRequesting(true);
        try {
            const response = await DataService.getData({
                api: "rms/properties/mapping/" + app_state.property_id + "/" + app_state.active_data_src.id + "/?typeOfMapping=rate_code__market_segment&raw=False&includeDescOrder=True",
                //api: "rms/properties/mapping/10000647/10000647/?typeOfMapping=rate_code__market_segment&raw=False&includeDescOrder=True",
                request: { method: 'GET' }
            });
            if (response.status === "success") {
                setRatePlanMainData({ mapping: response.mapping, ordered_list: response.ordered_list })
                setRatePlanMainDataBackup({ mapping: response.mapping, ordered_list: response.ordered_list })
                console.log("ratePlanGetData is fetched")
                setIsRequesting(false);
            }
            else {
                setIsRequestError(true);
                setIsRequesting(false);
                setResErrorMsg(response.message);
            }
        }
        catch (error) {
            console.error('An error occurred', error);
            setIsRequesting(false);
            setIsRequestError(true);
            setResErrorMsg(
                (error && error.original && error.original.statusText) ||
                "Something went wrong, please try again later!"
            );
            setTimeout(() => {
                setIsRequestError(false);
            }, 5000);
        }
    }



    // Segment New API Integration  
    const [segmentMainData, setSegmentMainData] = useState(JSON.parse(JSON.stringify({})));
    const [segmentMainDataBackup, setSegmentMainDataBackup] = useState(JSON.parse(JSON.stringify({})));

    const [segmentOrderList, setSegmentOrderList] = useState(JSON.parse(JSON.stringify([])));
    const [segmentMappingData, setSegmentMappingData] = useState(JSON.parse(JSON.stringify({ UNMAPPED: { codes: {} }, IGNORED: { codes: {} } })));

    useEffect(() => {
        if (segmentMainData.ordered_list !== undefined && segmentMainData.mapping !== undefined) {
            setSegmentOrderList(JSON.parse(JSON.stringify(segmentMainData.ordered_list)));
            setSegmentMappingData(JSON.parse(JSON.stringify(segmentMainData.mapping)));
        }

    }, [segmentMainData]);

    const segmentGetData = async () => {
        setIsRequesting(true);
        try {
            const response = await DataService.getData({
                api: "rms/properties/mapping/" + app_state.property_id + "/" + app_state.active_data_src.id + "/?typeOfMapping=market_group__market_code&raw=False&includeDescOrder=True",
                request: { method: 'GET' }
            });
            if (response.status === "success") {
                setSegmentMainData({ mapping: response.mapping, ordered_list: response.ordered_list })
                setSegmentMainDataBackup({ mapping: response.mapping, ordered_list: response.ordered_list })
                console.log("segmentGetData is fetched")
                setIsRequesting(false);
            }
            else {
                setIsRequestError(true);
                setIsRequesting(false);
                setResErrorMsg(response.message);
            }
        }
        catch (error) {
            console.error('An error occurred', error);
            setIsRequesting(false);
            setIsRequestError(true);
            setResErrorMsg(
                (error && error.original && error.original.statusText) ||
                "Something went wrong, please try again later!"
            );
            setTimeout(() => {
                setIsRequestError(false);
            }, 5000);
        }
    }
    // console.log("segmentOrderList : ", segmentOrderList)
    // console.log("segmentMappingData : ", segmentMappingData)



    // Top Segment New API Integration
    //Top Segments meta data
    const topSegmentsArray = ["Transient", "Group", "Contract"];
    const topSegmentsAllCodesArray = requestData.market_segment_category_order;
    const topSegmentsMappedArray = requestData.topSegments['Contract'].concat(requestData.topSegments['Group'], requestData.topSegments['Transient']);
    const set1 = new Set(topSegmentsAllCodesArray);
    const set2 = new Set(topSegmentsMappedArray);
    const [topSegmentsUnappedArrayBackup, setTopSegmentsUnappedArrayBackup] = useState([...new Set([...set1].filter(value => !set2.has(value)))]);


    const [topSegmentsMainArray, setTopSegmentsMainArray] = useState(JSON.parse(JSON.stringify([])));
    const [topSegmentsMainArrayBackup, setTopSegmentsMainArrayBackup] = useState(JSON.parse(JSON.stringify([])));

    const [topSegmentsUnappedArray, setTopSegmentsUnappedArray] = useState([]);
    const topSegmentsUnappedArrayLength = topSegmentsUnappedArray.includes("UNMAPPED") ? topSegmentsUnappedArray.length - 1 : topSegmentsUnappedArray.length

    useEffect(() => {
        if (topSegmentsMainArray !== undefined) {
            topSegmentsMainArray.map((item, index) => {
                const key = Object.keys(item)[0];
                const values = item[key];
                if (key === "Unmapped") {
                    setTopSegmentsUnappedArray(values)
                }
            })

        }

    }, [topSegmentsMainArray]);

    const topSegmentGetData = async () => {
        setIsRequesting(true);
        try {
            const response = await DataService.getData({
                api: "rms/properties/mapping/" + app_state.property_id + "/" + app_state.active_data_src.id + "/?typeOfMapping=market_group__aggregates&raw=False&includeDescOrder=True",
                //api: "rms/properties/mapping/10000647/10000647/?typeOfMapping=rate_code__market_segment&raw=False&includeDescOrder=True",
                request: { method: 'GET' }
            });
            if (response.status === "success") {
                setTopSegmentsMainArray(JSON.parse(JSON.stringify(response.mapping)))
                setTopSegmentsMainArrayBackup(JSON.parse(JSON.stringify(response.mapping)))
                console.log("topSegmentGetData is fetched")
                setIsRequesting(false);
            }
            else {
                setIsRequestError(true);
                setIsRequesting(false);
                setResErrorMsg(response.message);
            }
        }
        catch (error) {
            console.error('An error occurred', error);
            setIsRequesting(false);
            setIsRequestError(true);
            setResErrorMsg(
                (error && error.original && error.original.statusText) ||
                "Something went wrong, please try again later!"
            );
            setTimeout(() => {
                setIsRequestError(false);
            }, 5000);
        }
    }
    //console.log("topSegmentsMainArray", topSegmentsMainArray)
    //console.log("requestData.topSegments", requestData.topSegments)





    const outsideClickRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (outsideClickRef.current && !outsideClickRef.current.contains(event.target)) {
                setEditableIndex(-1);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div style={{ maxWidth: "unset" }}>

            <Box className='top-axn-bar-1'
            >
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    //disabled={isChangeRequest}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }

                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>

                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => {
                            // value2 === 0 ? ratePlanSaveData() : saveChanges()
                            if (value2 === 0) {
                                ratePlanSaveData();
                            } else if (value2 === 1) {
                                segmentSaveData();
                            } else if (value2 === 2) {
                                topSegmentSaveData();
                            }
                        }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            Save
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Property Mapping</span>
                </Box>
                {/* <Box pt={0.5} className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings/Property Settings/</span>
                    <span className="breadcrum-active">Mapping</span>
                </Box> */}
            </Box>

            <Box className="bgwhite main-scroll-wrap-segments" sx={{ pl: 2, pr: 2 }}>

                <div className='profile-heading'>PROPERTY MAPPING</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none", marginTop: "10px"
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none", marginTop: "10px"
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>


                <Box pt={1.4} sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs className="mapping-tabs" value={value2} onChange={handleChange2} aria-label="basic tabs example">
                            <Tab label="Rate Plans" {...a11yProps(0)} />
                            <Tab label="Segments" {...a11yProps(1)} />
                            <Tab label="Top Segments" {...a11yProps(2)} />
                        </Tabs>
                    </Box>

                    {/* PLEASE DO NOT DELETE THE BELOW COMMENTED CODE */}
                    {/* <CustomTabPanel className="custom-tab-panel" value={value2} index={0}>
                        <DragDropContext onDragEnd={handleDragEndRatePlan}>
                            <Box className="tab-data-container">
                                <Box className="tab-data-left-section">
                                    <Box>
                                        <div style={{ fontFamily: "SFUI Semibold" }}>
                                            Unmapped ({Object.keys(ratePlanMappingData.UNMAPPED.codes).length == 0 ? '0' : Object.keys(ratePlanMappingData.UNMAPPED.codes).length - 1})
                                        </div>

                                        <div className="chips">
                                            <Droppable droppableId="unmappedSegmentChips" type="droppableSubItem">
                                                {(provided) => (
                                                    <div ref={provided.innerRef} style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                                                        {Object.keys(ratePlanMappingData.UNMAPPED.codes).map((option, index) => (
                                                            option === '{NULL}' ? null : (
                                                                <Draggable key={option} draggableId={option} index={index}>
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <Tooltip title={ratePlanMappingData.UNMAPPED.codes[option]} arrow followCursor >
                                                                                <Chip
                                                                                    size="small"
                                                                                    label={option}
                                                                                    variant="filled"
                                                                                />
                                                                            </Tooltip>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        ))}
                                                    </div>
                                                )}
                                            </Droppable>

                                        </div>
                                    </Box>
                                    <div className="bottom-text">
                                        <div style={{ marginTop: "3px" }}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#606060" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path></svg></div>
                                        <div>To map the Rate Plan, drag and drop the pills.</div>
                                    </div>
                                </Box>

                                <Box className="tab-data-right-section" ref={containerRef}>
                                    <Box className="segments-list-table">

                                        <Box className="segments-list-data" sx={{ paddingBottom: "70px" }}>

                                            <Droppable droppableId="room-settings-list" type="droppableItem" direction="vertical">
                                                {(provided) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {ratePlanOrderList.map((item, index) => (
                                                            item === 'UNMAPPED' ? null : (
                                                                <Draggable
                                                                    key={"roomsettings-" + index}
                                                                    draggableId={"roomsettings-" + index}
                                                                    index={index}
                                                                >
                                                                    {(provided) => (
                                                                        <div className="segments-draggable-row"
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={provided.innerRef}
                                                                        >
                                                                            <Tooltip title="Drag and Drop" arrow followCursor >
                                                                                <Grid item md={0.3} className="room-type-details-col" style={{ pr: '10px' }}>
                                                                                    <svg className="draggable-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000000" viewBox="0 0 256 256"><path d="M104,60A12,12,0,1,1,92,48,12,12,0,0,1,104,60Zm60,12a12,12,0,1,0-12-12A12,12,0,0,0,164,72ZM92,116a12,12,0,1,0,12,12A12,12,0,0,0,92,116Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,116ZM92,184a12,12,0,1,0,12,12A12,12,0,0,0,92,184Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,184Z"></path></svg>
                                                                                </Grid>
                                                                            </Tooltip>

                                                                            <Grid sx={{ position: "relative" }}>

                                                                                <Box autoFocus style={editableIndex === index ? { top: "5px" } : {}} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", position: "absolute", padding: "0px 5px 0px 10px", width: "100%" }}>

                                                                                    <div key={index}>
                                                                                        {editableIndex === index ? (
                                                                                            <FormControl className="textfield" fullWidth size="small">
                                                                                                <input
                                                                                                    autoFocus
                                                                                                    key={index}
                                                                                                    type="text"
                                                                                                    defaultValue={item}
                                                                                                    onBlur={(e) => handleRatePlanInputChange(e.target.value, index, item)}
                                                                                                    onKeyPress={(e) => {
                                                                                                        if (e.key === 'Enter') {
                                                                                                            handleRatePlanInputChange(e.target.value, index, item);
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </FormControl>
                                                                                        ) : (
                                                                                            <Box
                                                                                                sx={{
                                                                                                    display: "flex",
                                                                                                    gap: "4px",
                                                                                                    justifyContent: "space-between",
                                                                                                    alignItems: "center",
                                                                                                }}
                                                                                            >
                                                                                                <div style={{ fontFamily: "SFUI Medium", color: "#333333" }}>{item}</div>
                                                                                                <Tooltip title="Click to edit" arrow followCursor >
                                                                                                    <div
                                                                                                        style={{ cursor: "pointer", padding: "0px 4px" }}
                                                                                                        onClick={(e) => handleEditClick(e, index)}
                                                                                                    >
                                                                                                        <svg
                                                                                                            style={{ marginTop: "6px" }}
                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                            width="22"
                                                                                                            height="22"
                                                                                                            fill="#606060"
                                                                                                            viewBox="0 0 256 256"
                                                                                                            className="profile-edit-pencil-icon"
                                                                                                        >
                                                                                                            <path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                            </Box>
                                                                                        )}
                                                                                    </div>

                                                                                    <Box display="flex" spacing={0} sx={{ float: "right" }}>
                                                                                        <Button
                                                                                            variant=""
                                                                                            size="small"
                                                                                            sx={{ minWidth: "auto", p: '4px', lineHeight: "auto", borderRadius: '50px' }}
                                                                                            onClick={() => {
                                                                                                deleteRatePlan(item);
                                                                                            }}
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#E03131" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>

                                                                                        </Button>
                                                                                    </Box>
                                                                                </Box>

                                                                                <Droppable droppableId={item} type="droppableSubItem" direction="horizontal">
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            className={`segments-details${snapshot.isDraggingOver ? ' highlighted' : ''}`}
                                                                                            ref={provided.innerRef}
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                gap: "5px",
                                                                                                flexWrap: "wrap",
                                                                                                minHeight: "auto"
                                                                                            }}
                                                                                        >
                                                                                            {
                                                                                                Object.keys(ratePlanMappingData[item].codes).length > 0
                                                                                                    ?
                                                                                                    Object.keys(ratePlanMappingData[item].codes).map((option, index) => (
                                                                                                        <Draggable key={option} draggableId={option} index={index}>
                                                                                                            {(provided) => (
                                                                                                                <div
                                                                                                                    ref={provided.innerRef}
                                                                                                                    {...provided.draggableProps}
                                                                                                                    {...provided.dragHandleProps}
                                                                                                                >
                                                                                                                    <Tooltip title={ratePlanMappingData[item].codes[option]} arrow followCursor >
                                                                                                                        <Chip
                                                                                                                            size="small"
                                                                                                                            label={option}
                                                                                                                            variant="filled"
                                                                                                                            onDelete={() => handleDeleteRatePlan(item, option)}
                                                                                                                        />
                                                                                                                    </Tooltip>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </Draggable>
                                                                                                    ))
                                                                                                    :
                                                                                                    <Chip
                                                                                                        size="small"
                                                                                                        label="Drag & Drop Codes Here"
                                                                                                        variant="filled"
                                                                                                        style={{ border: "1.4px Solid #B2B2B2", color: "#B2B2B2" }}
                                                                                                    />
                                                                                            }

                                                                                            {provided.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>

                                                                            </Grid>

                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        ))}
                                                    </div>
                                                )}
                                            </Droppable>

                                        </Box>

                                        <Button
                                            variant="outlined"
                                            className="add-button"
                                            size="small"
                                            sx={{ lineHeight: "auto", bottom: "20px !important" }}
                                            onClick={() => {
                                                addNewRatePlan();
                                            }}
                                        >
                                            <span className="add-btn-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#333333" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>
                                            </span>
                                            <span className="add-btn-txt">Add Segment Code</span>
                                        </Button>

                                    </Box>
                                </Box>
                            </Box>
                        </DragDropContext>
                    </CustomTabPanel> */}

                    <CustomTabPanel className="custom-tab-panel" value={value2} index={0}>

                        <Box className="tab-data-container" style={{ gridTemplateColumns: "1fr" }}>

                            <Box className="tab-data-right-section" ref={containerRef}>
                                <Box className="segments-list-table">
                                    <Box className="segments-list-data" sx={{ paddingBottom: "70px" }}>
                                        {ratePlanOrderList.map((item, index) => (
                                            // item === 'UNMAPPED' ? null : (
                                            <div className="segments-draggable-row" style={{ gridTemplateColumns: "1fr" }}>
                                                <Grid sx={{ position: "relative" }}>
                                                    <Box sx={{ top: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", position: "absolute", padding: "0px 5px 0px 10px", width: "100%" }}>
                                                        <div style={{ fontFamily: "SFUI Medium", color: "#333333" }}>{item}</div>
                                                    </Box>

                                                    <div
                                                        className="segments-details"
                                                        style={{
                                                            display: "flex",
                                                            gap: "5px",
                                                            flexWrap: "wrap",
                                                            minHeight: "auto"
                                                        }}
                                                    >
                                                        {
                                                            Object.keys(ratePlanMappingData[item].codes).length > 0
                                                                ?
                                                                Object.keys(ratePlanMappingData[item].codes).map((option, index) => (
                                                                    <Tooltip title={ratePlanMappingData[item].codes[option]} arrow followCursor >
                                                                        <Chip
                                                                            size="small"
                                                                            label={option}
                                                                            variant="filled"
                                                                            style={{ cursor: "default" }}
                                                                        />
                                                                    </Tooltip>
                                                                ))
                                                                :
                                                                <Chip
                                                                    size="small"
                                                                    label="No Mapped Codes"
                                                                    variant="filled"
                                                                    style={{ border: "1.4px Solid #B2B2B2", color: "#B2B2B2" }}
                                                                />
                                                        }
                                                    </div>
                                                </Grid>
                                            </div>
                                            // )
                                        ))}
                                    </Box>

                                </Box>
                            </Box>
                        </Box>

                    </CustomTabPanel>

                    <CustomTabPanel className="custom-tab-panel" value={value2} index={1}>
                        <DragDropContext onDragEnd={handleDragEndSegments}>
                            {/* Segments Data */}
                            <Box className="tab-data-container">
                                <Grid sx={{ display: "grid", gridTemplateRows: "75% auto", gap: 2 }}>
                                    <Box className="tab-data-left-section1">
                                        <Box>
                                            <div style={{ fontFamily: "SFUI Semibold" }}>
                                                Unmapped ({Object.keys(segmentMappingData.UNMAPPED.codes).length == 0 ? '0' : Object.keys(segmentMappingData.UNMAPPED.codes).length})
                                            </div>

                                            <div className="chips">
                                                <Droppable droppableId="unmappedSegmentChips" type="droppableSubItem">
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>

                                                            {Object.keys(segmentMappingData.UNMAPPED.codes).map((option, index) => (
                                                                option === '{NULL}' ? null : (
                                                                    <Draggable key={option} draggableId={option} index={index}>
                                                                        {(provided) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <Tooltip title={segmentMappingData.UNMAPPED.codes[option]} arrow followCursor >
                                                                                    <Chip
                                                                                        size="small"
                                                                                        label={option}
                                                                                        variant="filled"
                                                                                    />
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            ))}

                                                        </div>
                                                    )}
                                                </Droppable>
                                            </div>
                                        </Box>
                                        <div className="bottom-text">
                                            <div style={{ marginTop: "2px" }}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#606060" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path></svg></div>
                                            <div>To map the Segments, drag and drop the pills.</div>
                                        </div>
                                    </Box>

                                    <Box className="tab-data-left-section2">
                                        <div style={{ fontFamily: "SFUI Semibold" }}>
                                            Ignored Code ({Object.keys(segmentMappingData.IGNORED.codes).length == 0 ? '0' : Object.keys(segmentMappingData.IGNORED.codes).length})
                                        </div>

                                        <div className="chips">
                                            <Droppable droppableId="ignoredSegmentChips" type="droppableSubItem" direction="horizontal">
                                                {(provided, snapshot) => (
                                                    <div
                                                        className={`ignored-chips${snapshot.isDraggingOver ? ' highlighted' : ''}`}
                                                        ref={provided.innerRef}
                                                        style={{
                                                            display: "flex",
                                                            gap: "5px",
                                                            flexWrap: "wrap",
                                                            minHeight: "auto"
                                                        }}
                                                    >

                                                        {Object.keys(segmentMappingData.IGNORED.codes).map((option, index) => (
                                                            <Draggable key={option} draggableId={option} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <Tooltip title={segmentMappingData.IGNORED.codes[option]} arrow followCursor >
                                                                            <Chip
                                                                                size="small"
                                                                                label={option}
                                                                                variant="filled"
                                                                                onDelete={() => handleDeleteSegments('IGNORED', option)}
                                                                            />
                                                                        </Tooltip>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))
                                                        }

                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    </Box>
                                </Grid>

                                <Box className="tab-data-right-section" ref={containerRef}>
                                    <Box className="segments-list-table">

                                        <Box className="segments-list-data" sx={{ paddingBottom: "70px" }}>

                                            <Droppable droppableId="room-settings-list" type="droppableItem" direction="vertical">
                                                {(provided) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {segmentOrderList.map((item, index) => (
                                                            item === 'UNMAPPED' ? null : (
                                                                <Draggable
                                                                    key={"roomsettings-" + index}
                                                                    draggableId={"roomsettings-" + index}
                                                                    index={index}
                                                                >
                                                                    {(provided) => (
                                                                        <div className="segments-draggable-row"
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={provided.innerRef}
                                                                        >
                                                                            <Tooltip title="Drag and Drop" arrow followCursor >
                                                                                <Grid item md={0.3} className="room-type-details-col" style={{ pr: '10px' }}>
                                                                                    <svg className="draggable-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000000" viewBox="0 0 256 256"><path d="M104,60A12,12,0,1,1,92,48,12,12,0,0,1,104,60Zm60,12a12,12,0,1,0-12-12A12,12,0,0,0,164,72ZM92,116a12,12,0,1,0,12,12A12,12,0,0,0,92,116Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,116ZM92,184a12,12,0,1,0,12,12A12,12,0,0,0,92,184Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,184Z"></path></svg>
                                                                                </Grid>
                                                                            </Tooltip>

                                                                            <Grid sx={{ position: "relative" }}>

                                                                                <Box style={editableIndex === index ? { top: "5px" } : {}} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", position: "absolute", left: "12px", right: "4px" }}>

                                                                                    <div key={index}>

                                                                                        {editableIndex === index ? (
                                                                                            <FormControl className="textfield" fullWidth size="small">
                                                                                                <input
                                                                                                    autoFocus
                                                                                                    //ref={outsideClickRef}  
                                                                                                    //onBlur={() => setEditableIndex(-1)}                                                                                             
                                                                                                    key={index}
                                                                                                    type="text"
                                                                                                    defaultValue={item}
                                                                                                    onBlur={(e) => handleSegmentInputChange(e.target.value, index, item)}
                                                                                                    onKeyPress={(e) => {
                                                                                                        if (e.key === 'Enter') {
                                                                                                            handleSegmentInputChange(e.target.value, index, item);
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                            </FormControl>
                                                                                        ) : (
                                                                                            <Box
                                                                                                sx={{
                                                                                                    display: "flex",
                                                                                                    gap: "4px",
                                                                                                    justifyContent: "space-between",
                                                                                                    alignItems: "center",
                                                                                                }}
                                                                                            >
                                                                                                <div style={{ fontFamily: "SFUI Medium", color: "#333333" }}>{item}</div>
                                                                                                <Tooltip title="Click to edit" arrow followCursor >
                                                                                                    <div
                                                                                                        style={{ cursor: "pointer", padding: "0px 4px" }}
                                                                                                        onClick={(e) => handleEditClick(e, index)}
                                                                                                    >
                                                                                                        <svg
                                                                                                            style={{ marginTop: "6px" }}
                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                            width="22"
                                                                                                            height="22"
                                                                                                            fill="#606060"
                                                                                                            viewBox="0 0 256 256"
                                                                                                            className="profile-edit-pencil-icon"
                                                                                                        >
                                                                                                            <path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM92.69,208H48V163.31l88-88L180.69,120ZM192,108.69,147.32,64l24-24L216,84.69Z"></path>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </Tooltip>
                                                                                            </Box>
                                                                                        )}
                                                                                    </div>


                                                                                    <Box display="flex" spacing={0} sx={{ float: "right" }}>
                                                                                        <Button
                                                                                            variant=""
                                                                                            size="small"
                                                                                            sx={{ minWidth: "auto", p: '4px', lineHeight: "auto", borderRadius: '50px' }}
                                                                                            onClick={() => {
                                                                                                deleteMarketSegment(item);
                                                                                            }}
                                                                                        >
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#E03131" viewBox="0 0 256 256"><path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path></svg>

                                                                                        </Button>
                                                                                    </Box>
                                                                                </Box>

                                                                                <Droppable droppableId={item} type="droppableSubItem" direction="horizontal">
                                                                                    {(provided, snapshot) => (
                                                                                        <div
                                                                                            className={`segments-details${snapshot.isDraggingOver ? ' highlighted' : ''}`}
                                                                                            ref={provided.innerRef}
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                gap: "5px",
                                                                                                flexWrap: "wrap",
                                                                                                minHeight: "auto"
                                                                                            }}
                                                                                        >

                                                                                            {Object.keys(segmentMappingData[item].codes).length > 0
                                                                                                ?
                                                                                                Object.keys(segmentMappingData[item].codes).map((option, index) => (
                                                                                                    <Draggable key={option} draggableId={option} index={index}>
                                                                                                        {(provided) => (
                                                                                                            <div
                                                                                                                ref={provided.innerRef}
                                                                                                                {...provided.draggableProps}
                                                                                                                {...provided.dragHandleProps}
                                                                                                            >
                                                                                                                <Tooltip title={segmentMappingData[item].codes[option]} arrow followCursor >
                                                                                                                    <Chip
                                                                                                                        size="small"
                                                                                                                        label={option}
                                                                                                                        variant="filled"
                                                                                                                        onDelete={() => handleDeleteSegments(item, option)}
                                                                                                                    />
                                                                                                                </Tooltip>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Draggable>
                                                                                                ))
                                                                                                :
                                                                                                <Chip
                                                                                                    size="small"
                                                                                                    label="Drag & Drop Codes Here"
                                                                                                    variant="filled"
                                                                                                    style={{ border: "1.4px Solid #B2B2B2", color: "#B2B2B2" }}
                                                                                                />
                                                                                            }

                                                                                            {provided.placeholder}
                                                                                        </div>
                                                                                    )}
                                                                                </Droppable>

                                                                            </Grid>

                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        ))}
                                                    </div>
                                                )}
                                            </Droppable>

                                        </Box>

                                        <Button
                                            variant="outlined"
                                            className="add-button"
                                            size="small"
                                            sx={{ lineHeight: "auto", bottom: "20px !important" }}
                                            onClick={() => {
                                                addNewSegmentMap();
                                            }}
                                        >
                                            <span className="add-btn-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#333333" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>
                                            </span>
                                            <span className="add-btn-txt">Add Segment</span>
                                        </Button>

                                    </Box>
                                </Box>
                            </Box>
                        </DragDropContext>
                    </CustomTabPanel>

                    <CustomTabPanel className="custom-tab-panel" value={value2} index={2}>
                        <DragDropContext onDragEnd={handleDragEndTopSegments}>
                            {/* Top Segments Data */}
                            <Box className="tab-data-container">
                                <Box className="tab-data-left-section">
                                    <Box>
                                        <div style={{ fontFamily: "SFUI Semibold" }}>Unmapped ({topSegmentsUnappedArrayLength})</div>
                                        <div className="chips">
                                            <Droppable droppableId="Unmapped">
                                                {(provided) => (
                                                    <div ref={provided.innerRef} style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                                                        {topSegmentsUnappedArray.map((option, index) => (
                                                            option === "UNMAPPED" ? "" :
                                                                <Draggable key={option} draggableId={option} index={index}>
                                                                    {(provided) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <Chip
                                                                                size="small"
                                                                                label={option}
                                                                                variant="filled"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                        ))}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    </Box>
                                    <div className="bottom-text">
                                        <div style={{ marginTop: "2px" }}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#606060" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path></svg></div>
                                        <div>To map the Top Segments, drag and drop the pills.</div>
                                    </div>
                                </Box>

                                <Box className="tab-data-right-section">
                                    <Box className="segments-list-table">

                                        <Box className="segments-list-data" sx={{ paddingBottom: "70px" }}>
                                            {topSegmentsMainArray.map((item, index) => (
                                                Object.keys(item)[0] === 'Unmapped' ? "" :
                                                    <Box className="segments-non-draggable-row">
                                                        <Box style={{ fontFamily: "SFUI Medium", color: "#333333" }} sx={{ position: "absolute", top: "5px", left: "18px" }}>
                                                            {Object.keys(item)[0]}
                                                        </Box>

                                                        <Droppable droppableId={Object.keys(item)[0]} direction="horizontal">
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    className={`segments-details${snapshot.isDraggingOver ? ' highlighted' : ''}`}
                                                                    ref={provided.innerRef}
                                                                    style={{
                                                                        display: "flex",
                                                                        gap: "5px",
                                                                        flexWrap: "wrap",
                                                                        minHeight: "auto"
                                                                    }}
                                                                >
                                                                    {item[Object.keys(item)[0]].length > 0
                                                                        ?
                                                                        item[Object.keys(item)[0]].map((option, index) => (
                                                                            <Draggable key={option} draggableId={option} index={index}>
                                                                                {(provided) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        <Chip
                                                                                            size="small"
                                                                                            label={option}
                                                                                            variant="filled"
                                                                                            onDelete={() => handleDeleteTopSegments(option, Object.keys(item)[0])}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        ))
                                                                        :
                                                                        <Chip
                                                                            size="small"
                                                                            label="Drag & Drop Codes Here"
                                                                            variant="filled"
                                                                            style={{ border: "1.4px Solid #B2B2B2", color: "#B2B2B2" }}
                                                                        />
                                                                    }

                                                                    {provided.placeholder}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </Box>
                                            ))}
                                        </Box>

                                    </Box>
                                </Box>

                            </Box>
                        </DragDropContext>
                    </CustomTabPanel>
                </Box>


                {isrequesting && (
                    <Box
                        sx={{
                            textAlighn: "center",
                            backgroundColor: "dark.darkest",
                            opacity: 0.8,
                            position: "fixed",
                            top: 40,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: 100,
                        }}
                    >
                        <CircularProgress
                            size={40}
                            sx={{
                                color: "white",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-20px",
                                marginLeft: "-20px",
                            }}
                        />
                    </Box>
                )}

            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}
        </div>
    );
}

function MarketMappingSettings(props) {
    const dispatch = useDispatch();
    const [isrequesting, setIsRequesting] = useState(false);
    const [resError, setResError] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [requestSuccessMsg, setRequestSuccessMsg] = useState(
        "Your change request for Market Mapping was successfull!"
    );
    const [isRequestError, setIsRequestError] = useState(false);
    const [resErrorMsg, setResErrorMsg] = useState("");
    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { user_login_type } = user;
    const refreshDataBtnAccess = [0,1,2,3,4,5];
    const { app_state, ui_state } = app.current_state;
    const { property_details } = auth.profile || [];

    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));
    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });

    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    const market_segment_category_mapping = JSON.parse(
        JSON.stringify(system_settings[0].market_segment_category_mapping)
    );
    const market_segment_category_order = JSON.parse(
        JSON.stringify(system_settings[0].market_segment_category_order)
    );
    let market_code_ignorelist = JSON.parse(
        JSON.stringify(system_settings[0].market_code_ignorelist)
    );
    //Handling market_code_ignorelist in case its coming wrong from API
    market_code_ignorelist = market_code_ignorelist && market_code_ignorelist[0] !== "{NULL}" ? market_code_ignorelist : [];

    const lvl1_mseg_grouping = JSON.parse(
        JSON.stringify(system_settings[0].lvl1_mseg_grouping)
    );
    const topSegments = {};
    // debugger;
    // let Transient = lvl1_mseg_grouping.filter((item) => item.Transient);
    // let Group = lvl1_mseg_grouping.filter((item) => item.Group);
    // let Contract = lvl1_mseg_grouping.filter((item) => item.Contract);
    // topSegments.Transient = Transient.length ? Transient[0].Transient : [];
    // topSegments.Group = Group.length ? Group[0].Group : [];
    // topSegments.Contract = Contract.length ? Contract[0].Contract : [];
    // debugger;
    // const settings_ = JSON.parse(
    //     JSON.stringify({
    //         market_segment_category_mapping: market_segment_category_mapping,
    //         market_segment_category_order: market_segment_category_order,
    //         market_code_ignorelist: market_code_ignorelist,
    //         topSegments: topSegments,
    //         lvl1_mseg_grouping: lvl1_mseg_grouping,
    //     })
    // );
    const settings = JSON.parse(
        JSON.stringify({
            market_segment_category_mapping:
                system_settings[0].market_segment_category_mapping,
            market_segment_category_order:
                system_settings[0].market_segment_category_order,
            market_code_ignorelist: market_code_ignorelist,
            topSegments: topSegments,
            lvl1_mseg_grouping: lvl1_mseg_grouping,
        })
    );

    const [isChangeRequest, setIsChangeRequest] = useState(false);
    localStorage.setItem('isChanged', isChangeRequest);
    const [requestData, setRequestData] = useState(settings);

    const cancelSave = () => {
        // setRoomSettings(JSON.parse(JSON.stringify(roomSettingsOld)));
        // setRequestData(settings_);
        setCompState((compState) => {
            return {
                ...compState,
                segmentMapping: deepCopy(compState.segmentMapping_),
                // segmentMapping_: deepCopy(segmentMapping),
                // mappingOrderedList: mappingOrderedList, //response.ordered_list,
                // segmentAllCodesArray,
                segmentMappedArray: deepCopy(compState.segmentMappedArray_),
                // segmentMappedArray_: deepCopy(segmentMappedArray),
                segmentUnmappedArray: deepCopy(compState.segmentUnmappedArray_),
                // segmentUnmappedArray_: deepCopy(compState.segmentUnmappedArray_),
                mappingOrderedList: deepCopy(compState.mappingOrderedList_),
            }
        });
        setIsChangeRequest(false);
    };

    const saveChanges = async () => {
        console.log("saveChanges");
        const { mappingOrderedList, segmentAllCodesNameMap, segmentMapping, segmentUnmappedArray } = compState;
        //let unmappedCodes = {};
        //console.log("compState",compState)
        // segmentUnmappedArray.forEach(segment => {
        //     unmappedCodes[segment] = segmentAllCodesNameMap[segment];
        // })
        let unmappedCodes = segmentUnmappedArray.reduce((acc, segment) => {
            acc[segment] = segmentAllCodesNameMap[segment];
            return acc;
        }, {});

        let payload = {
            "mapping": {
                "UNMAPPED": {
                    "order": 8,
                    "codes": unmappedCodes
                },
                "TRASH": {
                    "order": 9,
                    "codes": {}
                }
            },
            "orderedList": mappingOrderedList
        };

        //Making payload ready
        // mappingOrderedList.map((group, index) => {
        //     let codes = {};
        //     let segmentCodes = segmentMapping[group];
        //     segmentCodes.forEach((segment) => {
        //         codes[segment] = segmentAllCodesNameMap[segment];
        //     });
        //     payload.mapping[group] = {
        //         order: index,
        //         codes: codes
        //     }
        // });
        mappingOrderedList.map((group, index) => {
            if (group !== "UNMAPPED") {
                let codes = {};
                let segmentCodes = segmentMapping[group];
                if (segmentCodes) {
                    segmentCodes.forEach((segment) => {
                        codes[segment] = segmentAllCodesNameMap[segment];
                    });
                }
                else {
                    console.warn(`No segment codes found for group: ${group}`);
                }

                payload.mapping[group] = {
                    order: index,
                    codes: codes
                };
            }
        });

        //insert UNMAPPED at the end in ordered list
        payload.orderedList.push('UNMAPPED');

        console.log("payload", payload)

        setIsRequesting(true);
        try {
            const response = await DataService.update({
                api: "rms/properties/mapping/" + app_state.property_id + "/" + app_state.active_data_src.id + "/?typeOfMapping=amadeus_market_group__market_code&raw=false&includeDescOrder=True",
                request: { method: 'PUT', mapping: payload.mapping, orderedList: payload.orderedList }
            });
            if (response.status === "success") {
                setIsRequestSuccess(true);
                setIsRequesting(false);
                setIsChangeRequest(false);
                // setEditableIndex(-1);
                setTimeout(() => {
                    setIsRequestSuccess(false);
                }, 2000);
            } else {
                setIsRequestError(true);
                setIsRequesting(false);
                setResErrorMsg(response.message);
            }
        }
        catch (err) {
            setIsRequesting(false);
            setIsRequestError(true);
            setResErrorMsg(
                (err && err.original && err.original.statusText) ||
                "Something went wrong, please try again later!"
            );
            setTimeout(() => {
                setIsRequestError(false);
            }, 5000);
        }
    }

    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);

    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }

        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        //reverting changes back


        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }

    const [isShakeButton, setIsShakeButton] = useState(false);

    const [roomSettingsOld, setRoomSettingsOld] = useState(JSON.parse(JSON.stringify(requestData.market_segment_category_order))); //taking a backup of Old Array
    const [roomSettings, setRoomSettings] = useState([]);
    const [compState, setCompState] = useState({
        segmentMapping: {},
        mappingOrderedList: [],
        segmentAllCodesArray: [],
        segmentUnmappedArray: [],
        segmentMappedArray: []
    });

    //Segments meta data 
    //['TBAR', 'TCON', 'TPRC', 'TPKG', 'TDIS', 'TFIT', 'TNOP', 'TOPA', 'TPRM', 'COMP', 'HUSE', 'PSBY', 'TOTH', 'TRANSIENT', 'GCOR', 'GASC', 'GTRT', 'GGOV', 'GSMF', 'GOTH', 'CONTRACT', 'CAIR', 'CODE1', 'CODE2', 'CODE3', 'CODE4', 'CODE5'];
    let segmentAllCodesArray = compState.segmentAllCodesArray;
    let segmentMappedArray = compState.segmentMappedArray;

    // compState.orderedList.map((item, index) => {
    //     requestData.market_segment_category_mapping[item].map((item2, index2) => {
    //         segmentMappedArray.push(item2);
    //     });
    // });

    let segmentUnmappedArray = segmentAllCodesArray.filter(item => !compState.segmentMappedArray.includes(item));

    const handleDeleteSegments = (item, index) => {
        let segmentCode = compState.segmentMapping[item][index];
        let indexInSegmentMappingArray = compState.segmentMappedArray.indexOf(segmentCode);
        if (segmentCode && indexInSegmentMappingArray > -1) {
            compState.segmentUnmappedArray.push(segmentCode);
            compState.segmentMappedArray.splice(indexInSegmentMappingArray, 1);
            compState.segmentMapping[item].splice(index, 1);
            setCompState({ ...compState });
        }
        setIsChangeRequest(true);
    };

    //drag and drop handle for Market Mapping
    const handleDragEndSegments = (result) => {
        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        if (result.type === "droppableItem") {
            console.log("main item drag");
            const updatedRoomSettings = Array.from(roomSettings);
            const [movedItem] = updatedRoomSettings.splice(result.source.index, 1);
            updatedRoomSettings.splice(result.destination.index, 0, movedItem);

            const srcIndex = result.source.index;
            const srcItem = compState.mappingOrderedList[srcIndex];
            const destIndex = result.destination.index;
            const destItem = compState.mappingOrderedList[destIndex];

            setCompState((compState) => {
                compState.mappingOrderedList.splice(srcIndex, 1);
                compState.mappingOrderedList.splice(destIndex, 0, srcItem);
                return { ...compState, }
            });
        }
        else if (result.type === "droppableSubItem") {
            console.log("sub item drag");
            if (result.source.droppableId === 'unmappedSegmentChips' && result.destination.droppableId !== 'unmappedSegmentChips') {
                setCompState((compState) => {
                    compState.segmentMapping[result.destination.droppableId].splice(destinationIndex, 0, result.draggableId);
                    if (!compState.segmentMappedArray.includes(result.draggableId)) {
                        compState.segmentMappedArray.push(result.draggableId);
                    }
                    compState.segmentUnmappedArray.splice(result.source.index, 1);
                    return { ...compState, }
                });
            }
            else {
                const itemToMove = compState.segmentMapping[result.source.droppableId][sourceIndex];
                // Remove itemToMove from the source droppableId
                compState.segmentMapping[result.source.droppableId].splice(sourceIndex, 1);
                // Add itemToMove to the destination droppableId
                compState.segmentMapping[result.destination.droppableId].push(itemToMove);
                setCompState({ ...compState });
            }
        }
        setIsChangeRequest(true);
    };

    useEffect(() => {

        getMarketSegmentMappingData();

    }, []);

    const getMarketSegmentMappingData = async () => {
        setIsRequesting(true);
        // debugger;
        try {
            const response = await DataService.getData({
                api: "rms/properties/mapping/" + app_state.property_id + "/" + app_state.active_data_src.id + "/?typeOfMapping=amadeus_market_group__market_code&raw=false&includeDescOrder=True",
                //api: "rms/properties/mapping/10000647/10000647/?typeOfMapping=rate_code__market_segment&raw=False&includeDescOrder=True",
                request: { method: 'GET' }
            });

            if (response.status === "success") {

                setCompState(() => {
                    let segmentMapping = {};
                    let segmentAllCodesArray = [];
                    let segmentAllCodesNameMap = {};
                    let mappingOrderedList = [];
                    let segmentUnmappedArray = [];
                    let segmentMappedArray = [];
                    // let unmapped = response.segmentMapping && response.segmentMapping.UNMAPPED && response.segmentMapping.UNMAPPED.codes ? Object.keys(response.segmentMapping.UNMAPPED.codes) : [];

                    response.ordered_list.forEach((code) => {
                        let segment = response.mapping[code];
                        if (segment && segment.codes) {
                            let codesArr = Object.keys(segment.codes);
                            // debugger;
                            codesArr.forEach((codeName) => {
                                // debugger; 
                                if (!segmentAllCodesArray.includes(codeName)) {
                                    segmentAllCodesArray.push(codeName);
                                    segmentAllCodesNameMap[codeName] = segment.codes[codeName];
                                }
                            });

                            if (code !== "UNMAPPED") {
                                mappingOrderedList.push(code);
                                segmentMappedArray = [...segmentMappedArray, ...codesArr];

                                segmentMapping[code] = codesArr;
                            } else {
                                // segmentUnmappedArray = segmentAllCodesArray.filter(item => !segmentMappedArray.includes(item));
                            }
                        }
                    });
                    segmentUnmappedArray = segmentAllCodesArray.filter(item => !segmentMappedArray.includes(item));
                    // debugger;
                    return {
                        ...compState,
                        segmentAllCodesNameMap,
                        segmentMapping: segmentMapping,
                        segmentMapping_: deepCopy(segmentMapping), //segmentMapping backup
                        mappingOrderedList: mappingOrderedList,
                        mappingOrderedList_: deepCopy(mappingOrderedList),//mappingOrderedList backup
                        segmentAllCodesArray,
                        segmentMappedArray,
                        segmentMappedArray_: deepCopy(segmentMappedArray), //segmentMappedArray backup
                        segmentUnmappedArray,
                        segmentUnmappedArray_: deepCopy(segmentUnmappedArray) //segmentUnmappedArray backup
                    }
                });
                setIsRequesting(false);
            }
            else {
                setIsRequestError(true);
                setIsRequesting(false);
                setResErrorMsg(response.message);
            }
        }
        catch (error) {
            console.error('An error occurred', error);
            setIsRequesting(false);
            setIsRequestError(true);
            setResErrorMsg(
                (error && error.original && error.original.statusText) ||
                "Something went wrong, please try again later!"
            );
            setTimeout(() => {
                setIsRequestError(false);
            }, 5000);
        }
    }


    return (
        <div style={{ maxWidth: "unset" }}>

            <Box className='top-axn-bar-1'
            >
                <Tooltip title="Back to Dashboard" arrow followCursor >
                    <Button
                        size="medium"
                        variant="contained"
                        className='back-to-dash-btn'
                        onClick={goBackToDashboard}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                            <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                                <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                    <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                    <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                </g>
                            </g>
                        </svg>
                        <span className='back-to-dash-txt'>Back to Dashboard</span>
                    </Button>
                </Tooltip>

                <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                    {refreshDataBtnAccess.includes(user_login_type) &&
                        <Tooltip title="Refresh Data" >
                            <box
                                onClick={refreshData}
                                size="medium"
                                variant="contained"
                                className='refresh-btn'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                            </box>
                        </Tooltip>
                    }

                    <Button
                        disabled={!isChangeRequest}
                        onClick={cancelSave}
                        variant="outlined"
                        className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                        // color="error"
                        size="small"
                        sx={{ minWidth: "auto" }}
                    >
                        cancel
                    </Button>

                    <Button
                        disabled={!isChangeRequest}
                        onClick={() => { saveChanges() }}
                        variant="outlined"
                        size="small"
                        className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                        // color="green"
                        sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                    >
                        <span style={{ display: !isrequesting ? "block" : "none" }}>
                            Save
                        </span>
                        <span
                            style={{
                                display: isrequesting ? "block" : "none",
                                paddingRight: 10,
                            }}
                        >
                            saving...
                        </span>
                        <CircularProgress
                            sx={{ display: isrequesting ? "block" : "none" }}
                            color="white"
                            size="16px"
                        />
                    </Button>
                </Box>

                <Alert
                    icon={false}
                    sx={{
                        display: refreshedData ? "block" : "none",
                        position: "fixed",
                        left: 0,
                        fontSize: 17,
                        textAlign: "center",
                        borderRadius: 0,
                        lineHeight: 1,
                        bottom: 40,
                        width: "100%",
                        zIndex: 100,
                        backgroundColor: "green.main",
                        color: "white.main",
                    }}
                    severity="success"
                >
                    Data refreshed successfully!
                </Alert>

            </Box>

            <Box className='top-axn-bar'>
                <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-inactive">Property Settings</span>
                    <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                        sx={{ top: 3, position: "relative", height: 18 }}
                    ></ArrowForwardIosOutlinedIcon>
                    <span className="breadcrum-active">Market Mapping</span>
                </Box>
                {/* <Box pt={0.5} className='breadcrumb' sx={{ color: 'white.main' }}>
                    <span className="breadcrum-inactive">Settings/Property Settings/</span>
                    <span className="breadcrum-active">Mapping</span>
                </Box> */}
            </Box>

            <Box className="bgwhite main-scroll-wrap-segments" sx={{ pl: 2, pr: 2 }}>

                <div className='profile-heading'>MARKET MAPPING</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                    }}
                >
                    {requestSuccessMsg}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                    }}
                >
                    {resErrorMsg}
                </Alert>
                <Alert
                    severity="error"
                    className="save-changes-alert display-none"
                    sx={{ marginTop: "10px" }}
                >
                    {unsavedAlertMsg}
                </Alert>


                <Box pt={1.5} sx={{ width: '100%' }}>

                    <DragDropContext onDragEnd={handleDragEndSegments}>
                        {/* Segments Data */}
                        <Box className="tab-data-container">

                            <Box className="tab-data-left-section market-mapping">
                                <Box>
                                    <div style={{ fontFamily: "SFUI Semibold" }}>Unmapped ({compState.segmentUnmappedArray.length})</div>

                                    <div className="chips">
                                        <Droppable droppableId="unmappedSegmentChips" type="droppableSubItem">
                                            {(provided) => (
                                                <div ref={provided.innerRef} style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                                                    {compState.segmentUnmappedArray.map((option, index) => (
                                                        <Draggable key={option} draggableId={option} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <Chip
                                                                        size="small"
                                                                        label={option}
                                                                        variant="filled"
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                </div>
                                            )}
                                        </Droppable>

                                    </div>
                                </Box>
                                <div className="bottom-text">
                                    <div style={{ marginTop: "2px" }}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#606060" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path></svg></div>
                                    <div>To map the Market, drag and drop the pills.</div>
                                </div>
                            </Box>

                            <Box className="tab-data-right-section market-mapping">
                                <Box className="segments-list-table">

                                    <Box className="segments-list-data">

                                        <Droppable droppableId="room-settings-list" type="droppableItem" direction="vertical">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {compState.mappingOrderedList.map((item, index) => (
                                                        item === 'UNMAPPED' ? null : (
                                                            <Draggable
                                                                key={"roomsettings-" + index}
                                                                draggableId={"roomsettings-" + index}
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <div className="segments-draggable-row"
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        ref={provided.innerRef}
                                                                    >
                                                                        <Tooltip title="Drag and Drop" arrow followCursor >
                                                                            <Grid item md={0.3} className="room-type-details-col" style={{ pr: '10px' }}>
                                                                                <svg className="draggable-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#000000" viewBox="0 0 256 256"><path d="M104,60A12,12,0,1,1,92,48,12,12,0,0,1,104,60Zm60,12a12,12,0,1,0-12-12A12,12,0,0,0,164,72ZM92,116a12,12,0,1,0,12,12A12,12,0,0,0,92,116Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,116ZM92,184a12,12,0,1,0,12,12A12,12,0,0,0,92,184Zm72,0a12,12,0,1,0,12,12A12,12,0,0,0,164,184Z"></path></svg>
                                                                            </Grid>
                                                                        </Tooltip>

                                                                        <Grid sx={{ position: "relative" }}>

                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", position: "absolute", top: "5px", left: "12px", width: "97%" }}>

                                                                                <div key={index}>
                                                                                    <Box
                                                                                        sx={{
                                                                                            display: "flex",
                                                                                            justifyContent: "space-between",
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        <div style={{ marginBottom: "3px", fontFamily: "SFUI Medium", color: "#333333" }}>{item}</div>
                                                                                        <div
                                                                                            style={{ cursor: "pointer", padding: "0px 6px" }}
                                                                                        >
                                                                                            {/* <Tooltip title="Market tooltip information need to show here" arrow followCursor >
                                                                                                <svg style={{ marginTop: "4px" }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#606060" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm16-40a8,8,0,0,1-8,8,16,16,0,0,1-16-16V128a8,8,0,0,1,0-16,16,16,0,0,1,16,16v40A8,8,0,0,1,144,176ZM112,84a12,12,0,1,1,12,12A12,12,0,0,1,112,84Z"></path></svg>
                                                                                            </Tooltip> */}
                                                                                        </div>
                                                                                    </Box>
                                                                                </div>

                                                                            </Box>

                                                                            <Droppable droppableId={item} type="droppableSubItem" direction="horizontal">
                                                                                {(provided, snapshot) => (
                                                                                    <div
                                                                                        className={`segments-details${snapshot.isDraggingOver ? ' highlighted' : ''}`}
                                                                                        ref={provided.innerRef}
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            gap: "5px",
                                                                                            flexWrap: "wrap",
                                                                                            minHeight: "auto"
                                                                                        }}
                                                                                    >
                                                                                        {compState.segmentMapping && compState.segmentMapping[item] && compState.segmentMapping[item].length > 0 ?

                                                                                            compState.segmentMapping[item].map((option, index) => (
                                                                                                <Draggable key={option} draggableId={option} index={index}>
                                                                                                    {(provided) => (
                                                                                                        <div
                                                                                                            ref={provided.innerRef}
                                                                                                            {...provided.draggableProps}
                                                                                                            {...provided.dragHandleProps}
                                                                                                        >
                                                                                                            <Chip
                                                                                                                size="small"
                                                                                                                label={option}
                                                                                                                variant="filled"
                                                                                                                onDelete={() => handleDeleteSegments(item, index)}
                                                                                                            />
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Draggable>
                                                                                            ))
                                                                                            :
                                                                                            <Chip
                                                                                                size="small"
                                                                                                label="Drag & Drop Codes Here"
                                                                                                variant="filled"
                                                                                                style={{ border: "1.4px Solid #B2B2B2", color: "#B2B2B2" }}
                                                                                            />
                                                                                        }

                                                                                        {provided.placeholder}
                                                                                    </div>
                                                                                )}
                                                                            </Droppable>

                                                                        </Grid>

                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    ))}
                                                </div>
                                            )}
                                        </Droppable>

                                    </Box>

                                </Box>
                            </Box>
                        </Box>
                    </DragDropContext>
                </Box>


                {isrequesting && (
                    <Box
                        sx={{
                            textAlighn: "center",
                            backgroundColor: "dark.darkest",
                            opacity: 0.8,
                            position: "fixed",
                            top: 40,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: 100,
                        }}
                    >
                        <CircularProgress
                            size={40}
                            sx={{
                                color: "white",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-20px",
                                marginLeft: "-20px",
                            }}
                        />
                    </Box>
                )}

            </Box>

            {refreshingData && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "dark.darkest",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}
        </div>
    );
}

export {
    InventorySettings,
    CompsetSettings,
    RatesSettings,
    SegmentsSettings,
    MarketsSettings,
    LocaleSettings,
    IntegrationSettings,
    DiscountRulesSettings,
    AlertsSettings,
    ManageRatePlans,
    PricingRules,
    DefaultRoomTypePricing,
    AddNewProperty,
    SegmentsMappingSettings,
    MarketMappingSettings,
    DecisionResyncSettings,
};
