import React, { useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { keys } from 'highcharts';
import RoomTypeRestrictionsList from './RoomTypeRestrictionsList';
import { deepCopy, getAppData, getObjectFilter } from '../../app/util';
import moment from 'moment';
import { useSelector } from 'react-redux';
import dataService from '../../services/data.service';


export const getClassName = (type) => {
    let classStyle = "btn-cta";
    if (type.toLowerCase() === "cta") {
        classStyle = "btn-cta";
    } else if (type.toLowerCase() === "ctd") {
        classStyle = "btn-ctd";
    } else if (type.toLowerCase() === "cls") {
        classStyle = "btn-cls";
    } else if (type.toLowerCase() === "open") {
        classStyle = "btn-open";
    } else if (type.toLowerCase() === "close") {
        classStyle = "btn-close";
    } else if (type.toLowerCase() === "min_los") {
        classStyle = "btn-min_los";
    } else if (type.toLowerCase() === "max_los") {
        classStyle = "btn-max_los";
    }

    return classStyle;
}
export const getResName = (type, value) => {
    let resDetail = {};
    let resName;
    if (type.toLowerCase() === "cta") {
        resDetail.resName = "cta";
        resDetail.fullName = "Close To Arrival";
    } else if (type.toLowerCase() === "ctd") {
        resDetail.resName = "ctd";
        resDetail.fullName = "Close To Departure";
    } else if (type.toLowerCase() === "cls") {
        resDetail.resName = "cls";
        resDetail.fullName = "Close";
    } else if (type.toLowerCase() === "open") {
        resDetail.resName = "open";
        resDetail.fullName = "Open";
    } else if (type.toLowerCase() === "close") {
        resDetail.resName = "cls";
        resDetail.fullName = "Close";
    } else if (type.toLowerCase() === "min_los") {
        resDetail.resName = "mn " + value;
        resDetail.fullName = "Minimum Length Of Stay";
    } else if (type.toLowerCase() === "max_los") {
        resDetail.resName = "mx " + value;
        resDetail.fullName = "Maximum Length Of Stay";
    }
    return resDetail;
}


export const getRestrictionAll = (stayDate = '', type = 'comma', restrictionType) => {
    let restrictionsData = (dataService.getSeriesById("GetRestrictions").data) ? deepCopy(dataService.getSeriesById("GetRestrictions").data) : [];
    let resType = (restrictionType === "restrictions_rec") ? "rec" : "cur" ;
//   debugger; 
    let restrictionData;
    if (stayDate !== '' && restrictionsData.cur && restrictionsData.cur.length > 0) {
        restrictionData = restrictionsData[resType].filter((itm) =>
            moment(itm.stay_date).format('YYYY-MM-DD') === moment(stayDate).format('YYYY-MM-DD')
        )
    }
    if (restrictionData && restrictionData[0]) {
        if (restrictionData[0] && restrictionData[0]['min_los'] <= 1) {
            delete restrictionData[0]['min_los']
        }
        if (restrictionData[0] && restrictionData[0]['max_los'] <= 1) {
            delete restrictionData[0]['max_los']
        }
        let keys = Object.keys(restrictionData[0])
        let finalRestriction = [];
        keys.map((item, index) => {
            if (item != "stay_date" && item != "asof_date" && restrictionData[0][item]) {
                finalRestriction.push(getResName(item, restrictionData[0][item]).resName.toUpperCase())
            }
        })
        if (type === 'comma' && finalRestriction.length > 0) {
            return finalRestriction.join();
        }
        // return finalRestriction;
    }
}

function Restrictions(props) {
    const state = useSelector(appState => appState);
    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let {restriction_type, currentRestrictions} = props;
    if(!restriction_type){
        restriction_type = 'cur';
    }
    
    // debugger  
    // change code - 30-June 
     if(!currentRestrictions){
        if(props.data){
            currentRestrictions= [props.data];
        }else {
            currentRestrictions=[];
        }
        
    }
    let restrictionHouseData =  currentRestrictions.find(i => !i.hasOwnProperty("room_type"));
    // end change code => 30 june  
    let systemSettings = getAppData(auth, app_state);
    let roomSettings = systemSettings.inventory_settings.current.room_settings;
              
    
    let roomTypeRestdata = currentRestrictions.filter(i => i.hasOwnProperty("room_type"));
    // change code ==> 30June 
    //let data = props.data;
    // end change code ==> 30 June 
    // debugger;

    let listItems;
    let chipArr = [];
    let chipArrAll = [];
    
    if (restrictionHouseData) {
        
        if (restrictionHouseData['min_los'] <= 1) {
            delete restrictionHouseData['min_los']
        }
        if (restrictionHouseData['max_los'] <= 1) {
            delete restrictionHouseData['max_los']
        }
        let keys = Object.keys(restrictionHouseData)
        let count = 1;
        keys.map((item, index) => {
            if (!["stay_date", "asof_date"].includes(item) && restrictionHouseData[item]) {
                let itemName = item.toUpperCase();
                let classStyle = getClassName(itemName);
                let itemNameDetails = getResName(item,restrictionHouseData[item]);
                itemName = itemNameDetails.resName;
                let itemFullName = itemNameDetails.fullName;
                chipArrAll.push(
                    // <Chip label={itemName} size="small" className={classStyle} sx={{marginRight:"5px !important", minWidth: "45px !important", height: "25px !important" }} />
                    <div className={"chip " + classStyle}>{itemName}</div>
                )
                
                if (count <= 2) {
                    chipArr.push(
                        <Tooltip title={itemFullName} placement="top" arrow>
                            {/* <Chip label={itemName} size="small" className={classStyle} sx={{ minWidth: "45px !important", height: "17px !important" }} /> */}
                            <div className={"chip " + classStyle}>{itemName}</div>
                        </Tooltip>
                    )
                }
                count++;
            }
        })
    }

    
    return (
        <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
                {chipArr}
                {
                    // (data[0] && (Object.keys(data[0]).length) > 2) ? <RestrictionsList chipArrAll={chipArrAll} /> : <></>
                    ( chipArrAll.length > 2 || (roomTypeRestdata && roomTypeRestdata.length>0)) ?
                     <RoomTypeRestrictionsList chipArrAll={chipArrAll} restrictionHouseData={restrictionHouseData} roomTypeRestdata={roomTypeRestdata} /> : <></>
                }
            </Stack>
        </Stack>
    )
}

export default Restrictions
