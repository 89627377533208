import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment';
import { updateAppState } from '../../actions/appstate';
import { ArrowForwardIosOutlined as ArrowForwardIosOutlinedIcon } from "@mui/icons-material/";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { dateFormat, deepCopy, getDataSources, toInteger, cleanIDB, clearAPIRegistryData, getPermissions } from "../../app/util";
import { API_REGISTRY } from "../../app/config";

import { SYSTEM_SETTINGS_UPDATED } from "../../actions/types";
import { DateRangePicker } from 'react-date-range';
import DataService from "../../services/data.service";
import dataService, { fetchDataSimple } from "../../services/data.service";

import {
    Alert,
    AlertTitle,
    Grid,
    Box,
    Button,
    CircularProgress,
    Tooltip,
    FormControlLabel,
    Checkbox,
    TextField,
    IconButton
} from "@mui/material/";
import "./style.css";


export function DecisionResyncSettings() {
    const [dateRangePickerVisiblity, setDateRangePickerVisiblity] = useState(false);
    // const WAIT_TIME = 1800
    // const [time, setTime] = useState(WAIT_TIME);
    const [timerStart, setTimerStart] = useState(false);
    const [isChangeRequest, setIsChangeRequest] = useState(false);
    const [isShakeButton, setIsShakeButton] = useState(false);
    const [isRequestSuccess, setIsRequestSuccess] = useState(false);
    const [isRequestError, setIsRequestError] = useState(false);
    const [isrequesting, setIsRequesting] = useState(false);
    const { app, auth } = useSelector((state) => state);
    const { app_state, ui_state } = app.current_state;
    const { user, profile } = auth;

    const { property_details, current_property_date } = auth.profile || [];
    //Data source information
    const [dsInfo, setDsInfo] = useState(getDataSources(property_details, app_state.property_id));

    let prop_details_index = 0;
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            prop_details_index = index;
            return item.id === app_state.property_id;
        }
        //
    });
    const [startDate, setStartDate] = React.useState(moment(current_property_date));
    const [endDate, setEndDate] = React.useState(moment(current_property_date).add(1, 'day'));
    const [startDateTemp, setStartDateTemp] = React.useState(moment(current_property_date));
    const [endDateTemp, setEndDateTemp] = React.useState(moment(current_property_date).add(1, 'day'));


    const { system_settings } = prop_details && prop_details.length ? prop_details[0] : null;
    const localeSettings = {
        default_locale: system_settings[0].default_locale, //DEFAULT LOCALE
        locale_city: system_settings[0].locale_city, //CITY
    };
    const [requestData, setRequestData] = useState(localeSettings);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [refreshingData, setRefreshingData] = useState(false);
    const [refreshedData, setRefreshedData] = useState(false);
    const { permissions, index } = getPermissions(property_details, app_state.property_id);

    const toggleDateRangePickerVisiblity = () => {
        if (dateRangePickerVisiblity) {
            setStartDate(startDateTemp);
            setEndDate(endDateTemp);
            setDateRangePickerVisiblity(false);
        }
        else {
            setDateRangePickerVisiblity(true);
        }
    };

    const startSync = async () => {

        // let oldDecisionResyncTimer = {}
        //setTime(WAIT_TIME);
        setDateRangePickerVisiblity(false);
        setIsRequesting(true)
        // if (localStorage.getItem('decisionResyncTimer')) {
        //     oldDecisionResyncTimer = JSON.parse(localStorage.getItem('decisionResyncTimer'));
        // }
        //oldDecisionResyncTimer[app_state.property_id] = Date.now();
        //localStorage.setItem("decisionResyncTimer", JSON.stringify(oldDecisionResyncTimer));
        //setTimerStart(true);
        let { uri } = API_REGISTRY["DecisionResync"];
        uri += app_state.property_id + "/";

        let formData = new FormData();
        formData.append('fromDate', dateFormat(startDate, "YYYY-MM-DD"));
        formData.append('toDate', dateFormat(endDate, "YYYY-MM-DD"));

        try {
            let response = await fetchDataSimple({ api: uri, request: { method: 'POST', formData } });
            if (response.status === "success") {
                setIsRequestSuccess('Decision Resync Success');
                setTimeout(() => {
                    setIsRequestSuccess(false);
                }, 5000);
            }
            if (response.status === "error") {
                setIsRequestError(response.message);
                setTimeout(() => {
                    setIsRequestError(false);
                }, 5000);
            }
            setIsRequesting(false)
        } catch (error) {
            setIsRequestError('Decision Resync Error');
            setTimeout(() => {
                setIsRequestError(false);
            }, 5000);
            setIsRequesting(false)
        }

        // let timer = setInterval(() => {
        //     setTime((time) => {
        //         if (time === 0) {
        //             clearInterval(timer);
        //             setTimerStart(false);
        //             return 0;
        //         }
        //         else {
        //             return time - 1;
        //         }
        //     });
        // }, 1000);
    }

    function goBackToDashboard() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        dispatch(
            updateAppState("navigate_to_dashboard", {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                },
            }, "settings_click")
        );
        navigate("/");
    }
    function refreshData() {
        if (isChangeRequest) {
            setIsShakeButton(true);
            let shakeAlert = document.getElementsByClassName("save-changes-alert");
            if (shakeAlert.length > 0) {
                shakeAlert[0].className = shakeAlert[0].className.replace("display-none", "").trim();
                setTimeout(() => {
                    shakeAlert[0].className += " display-none";
                }, 2000);
            }
            setTimeout(function () {
                setIsShakeButton(false);
            }, 2000); // 3000 milliseconds = 3 seconds
            return;
        }
        cleanIDB();
        const payload = {
            token_string: user.token_string,
            resourceId: app_state.property_id,
            resourceType: "property",
            targetCache: ["srr", "forecast", "otb"],
            operation: "flush",
        };
        setRefreshingData(true);
        // debugger;
        DataService.getData({ api: "rms/properties/clear-cache/" + app_state.property_id + "/" + app_state.active_data_src.id + "/", request: { method: 'GET' } })
            .then((data) => {
                //Do some task here just after respons
                if (data.status === "success") {
                    setRefreshedData(true);
                    setRefreshingData(false);
                    console.log("refreshData Success");
                    setTimeout(() => {
                        setRefreshedData(false);
                        // window.location.reload();
                    }, 2000);
                    clearAPIRegistryData();
                } else {
                    setRefreshingData(false);
                    console.warn("refreshData Error, data could not refreshed");
                    setTimeout(() => {
                        setRefreshedData(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                setRefreshingData(false);
                setTimeout(() => {
                    setRefreshedData(false);
                }, 3000);
            });
    }

    // const cancelSave = () => {
    //     setRequestData({
    //         default_locale: system_settings[0].default_locale, //DEFAULT LOCALE
    //         locale_city: system_settings[0].locale_city, //CITY
    //     });
    //     setIsChangeRequest(false);
    // };

    // const saveChanges = (event) => {
    //     setIsRequesting(true);
    //     const request_system_settings = {
    //         ...system_settings[0],
    //         default_locale: requestData.default_locale, //DEFAULT LOCALE
    //         locale_city: requestData.locale_city, //CITY
    //     };
    //     let payload = {
    //         "resource_id": app_state.property_id,
    //         "resource_type": "property",
    //         "update_type": "full_system_settings",
    //         "data": {
    //             "full_ss": request_system_settings
    //         }
    //     };

    //     let ss_api_config = deepCopy(API_REGISTRY['SaveSystemSettings']);
    //     let active_ds_id = dsInfo.active_source.id;
    //     ss_api_config.uri = ss_api_config.uri + app_state.property_id + '/' + active_ds_id + '/';

    //     dataService
    //         .update({ api: ss_api_config.uri, request: { ...ss_api_config.payload, ...payload } })
    //         .then((data) => {
    //             //Do some task here just after respons
    //             if (data.status === "success") {
    //                 setIsRequestSuccess(true);
    //                 setIsRequesting(false);
    //                 setTimeout(() => {
    //                     profile.property_details[prop_details_index].system_settings[0] =
    //                         request_system_settings;
    //                     dispatch({ type: SYSTEM_SETTINGS_UPDATED, payload: profile });
    //                 }, 4000);
    //                 setTimeout(() => {
    //                     setIsRequestSuccess(false);
    //                 }, 5000);
    //             } else {
    //                 setIsRequestError(true);
    //                 setIsRequesting(false);
    //                 setResErrorMsg(data.message);
    //                 //return Promise.reject(data);
    //             }
    //         })
    //         .catch((err) => {
    //             setIsRequesting(false);
    //             setIsRequestError(true);
    //             setResErrorMsg(
    //                 (err && err.original && err.original.statusText) ||
    //                 "Something went wrong, please try again later!"
    //             );
    //             setTimeout(() => {
    //                 setIsRequestError(false);
    //             }, 5000);
    //             //return Promise.reject(err);
    //         });
    // };

    // useEffect(() => {
    //     let remainTime = -1

    //     if (localStorage.getItem('decisionResyncTimer')) {
    //         const oldDecisionResyncTimer = JSON.parse(localStorage.getItem('decisionResyncTimer'));
    //         const decisionResyncTimerStartTime = oldDecisionResyncTimer[app_state.property_id];
    //         const decisionResyncTimerEndTime = decisionResyncTimerStartTime + WAIT_TIME * 1000;
    //         remainTime = Math.floor((decisionResyncTimerEndTime - Date.now()) / 1000);
    //     }

    //     if (remainTime >= 0) {
    //         setTimerStart(true);
    //         setTime(remainTime);
    //         let timer = setInterval(() => {
    //             setTime((time) => {
    //                 if (time === 0) {
    //                     clearInterval(timer);
    //                     setTimerStart(false);
    //                     return 0;
    //                 }
    //                 else {
    //                     return time - 1;
    //                 }
    //             });
    //         }, 1000);

    //         return () => {
    //             clearTimeout(timer);
    //         };
    //     }
    // }, [])

    return (<>
        <Box className='top-axn-bar-1'
        >
            <Tooltip title="Back to Dashboard" arrow followCursor >
                <Button
                    size="medium"
                    variant="contained"
                    className='back-to-dash-btn'
                    onClick={goBackToDashboard}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                        <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                            <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                            <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                            </g>
                            <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                            </g>
                            <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                            </g>
                            <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                            </g>
                            <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                            </g>
                        </g>
                    </svg>
                    <span className='back-to-dash-txt'>Back to Dashboard</span>
                </Button>
            </Tooltip>

            <Box className={(isShakeButton) ? "top-axn-bar-1-sub bt-shake" : "top-axn-bar-1-sub"}>
                <Tooltip title="Refresh Data" >
                    <box
                        onClick={refreshData}
                        size="medium"
                        variant="contained"
                        className='refresh-btn'
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#00415F" viewBox="0 0 256 256"><path d="M240,56v48a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16H211.4L184.81,71.64l-.25-.24a80,80,0,1,0-1.67,114.78,8,8,0,0,1,11,11.63A95.44,95.44,0,0,1,128,224h-1.32A96,96,0,1,1,195.75,60L224,85.8V56a8,8,0,1,1,16,0Z"></path></svg>
                    </box>
                </Tooltip>

                <Button
                    //disabled={!isChangeRequest}
                    disabled={true}
                    //onClick={cancelSave}
                    variant="outlined"
                    className={(isChangeRequest) ? "navigate-cancel-button btn-active" : "navigate-cancel-button"}
                    size="small"
                    sx={{ minWidth: "auto" }}
                >
                    cancel
                </Button>

                <Button
                    //disabled={!isChangeRequest}
                    disabled={true}
                    // onClick={() => {
                    //     saveChanges();
                    // }}
                    variant="outlined"
                    size="small"
                    className={(isChangeRequest) ? "navigate-save-button btn-active" : "navigate-save-button"}
                    sx={{ minWidth: "auto", mr: 1, color: "white.main" }}
                >
                    <span style={{ display: !isrequesting ? "block" : "none" }}>
                        Save
                    </span>
                    <span
                        style={{
                            display: isrequesting ? "block" : "none",
                            paddingRight: 10,
                        }}
                    >
                        saving...
                    </span>
                    <CircularProgress
                        sx={{ display: isrequesting ? "block" : "none" }}
                        color="white"
                        size="16px"
                    />
                </Button>
            </Box>

            <Alert
                icon={false}
                sx={{
                    display: refreshedData ? "block" : "none",
                    position: "fixed",
                    left: 0,
                    fontSize: 17,
                    textAlign: "center",
                    borderRadius: 0,
                    lineHeight: 1,
                    bottom: 40,
                    width: "100%",
                    zIndex: 100,
                    backgroundColor: "green.main",
                    color: "white.main",
                }}
                severity="success"
            >
                Data refreshed successfully!
            </Alert>

        </Box>
        <Box className='top-axn-bar' >
            <Box className='breadcrumb' sx={{ color: 'white.main' }}>
                <span className="breadcrum-inactive">Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-inactive">Property Settings</span>
                <ArrowForwardIosOutlinedIcon className="breadcrum-inactive"
                    sx={{ top: 3, position: "relative", height: 18 }}
                ></ArrowForwardIosOutlinedIcon>
                <span className="breadcrum-active">Decision Resync</span>
            </Box>
        </Box>
        {(permissions && permissions.rate_push === 0) ? (
            <Box sx={{ pl: 2, pr: 2 }}>
                <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    Pricing is not enabled for this data source! Please contact success@lodgiq.com for further details.
                </Alert></Box>
        ) : (
            <Box className="bgwhite main-scroll-wrap decision_resync" sx={{ pl: 2, pr: 2 }}>
                <div className='profile-heading' style={{ paddingBottom: '5px' }}>Decision Resync</div>

                <Alert
                    severity="success"
                    sx={{
                        display: isRequestSuccess ? "all" : "none",
                        marginBottom: '8px',
                    }}
                >
                    {isRequestSuccess}
                </Alert>
                <Alert
                    severity="error"
                    sx={{
                        display: isRequestError ? "all" : "none",
                        marginBottom: '8px',
                    }}
                >
                    {isRequestError}
                </Alert>

                <Grid container spacing={2}>
                    <Grid item xs={12} position='relative'>
                        <div className='top-bar-item'>
                            <label style={{ fontFamily: "SFUI Regular", color: "#606060", fontWeight: "unset", fontSize: "12px" }}>Stay Start Date and Stay End Date</label>
                            <TextField
                                tabIndex={1}
                                // onKeyDown={preventTyping}
                                onClick={toggleDateRangePickerVisiblity}
                                style={{ cursor: "pointer" }}
                                fullWidth
                                className="text-field "
                                size="small"
                                value={dateFormat(startDate, "ddd, MMM DD YYYY") + " - " + dateFormat(endDate, "ddd, MMM DD YYYY")}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={toggleDateRangePickerVisiblity} edge="end">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00415F" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-96-88v64a8,8,0,0,1-16,0V132.94l-4.42,2.22a8,8,0,0,1-7.16-14.32l16-8A8,8,0,0,1,112,120Zm59.16,30.45L152,176h16a8,8,0,0,1,0,16H136a8,8,0,0,1-6.4-12.8l28.78-38.37A8,8,0,1,0,145.07,132a8,8,0,1,1-13.85-8A24,24,0,0,1,176,136,23.76,23.76,0,0,1,171.16,150.45Z"></path></svg>
                                        </IconButton>
                                    ),
                                }}
                            />

                            <Box className={dateRangePickerVisiblity ? 'date-range-picker' : 'date-range-picker-false'}>
                                <DateRangePicker
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    direction="horizontal"
                                    ranges={[
                                        {
                                            startDate: new Date(startDateTemp),
                                            endDate: new Date(endDateTemp),
                                            key: 'selection',
                                        },
                                    ]}
                                    minDate={moment(current_property_date).toDate()}
                                    onChange={(ranges) => {
                                        setStartDateTemp(ranges.selection.startDate);
                                        setEndDateTemp(ranges.selection.endDate);
                                    }}
                                />
                                <Box sx={{ background: "#FFF" }} className="datepicker-done-btn"><Button onClick={toggleDateRangePickerVisiblity}>Done</Button></Box>
                            </Box>
                        </div>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Box ml={1} display={'flex'} gap={'8px'}>
                            <FormControlLabel className="textfield input-text"
                                control={
                                    <Checkbox
                                        className="colored-checkbox"
                                    />
                                }
                                label="Pricing"
                                onChange={(e) => { }}
                            />
                            <FormControlLabel className="textfield input-text"
                                control={
                                    <Checkbox
                                        className="colored-checkbox"
                                    />
                                }
                                label="Restrictions"
                                onChange={(e) => { }}
                            />
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} textAlign="right">
                        <Button onClick={startSync} className='button normal-case' variant="contained" size="medium" >
                            {/* {timerStart ?
                                `${Math.floor(time / 60)}`.padStart(2, 0) + `${':'}` + `${time % 60}`.padStart(2, 0) + `${' min'} `
                                :
                                'Start'
                            } */}
                            Start
                        </Button>
                        {/* {timerStart ?
                            <>
                                <br />
                                <label style={{ fontFamily: "SFUI Regular", color: "#606060", fontWeight: "unset", fontSize: "12px", marginTop: '10px' }}>Wait for  {`${Math.floor(time / 60)}`.padStart(2, 0)}:{`${time % 60}`.padStart(2, 0)} minutes before using it again.</label>
                            </>
                            :
                            ''
                        } */}

                    </Grid>
                </Grid>


            </Box>
        )}

        {isrequesting && (
            <Box
                sx={{
                    textAlighn: "center",
                    backgroundColor: "dark.darkest",
                    opacity: 0.8,
                    position: "fixed",
                    top: 40,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 100,
                }}
            >
                <CircularProgress
                    size={40}
                    sx={{
                        color: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-20px",
                        marginLeft: "-20px",
                    }}
                />
            </Box>
        )}

    </>
    )
}

