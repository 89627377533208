import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import { Box, Switch, Select, FormControl, MenuItem, InputLabel } from '@mui/material'
import moment from "moment";
import { UPDATE_STATE } from '../../../../actions/types';

function ToggleRoomTypePricing() {
    const {app_state, ui_state} = useSelector(state => state.app.current_state);
    const { workspace_controls, primary_stay_range, as_of_date, data_load } = useSelector(state => state.app.current_state.app_state);
    const dispatch = useDispatch();


    // const handleChange = (value) => {
    //     workspace_controls.isRoomTypePricing = value;
    //     let date_range_type = "rangeTileChange";
    //     if (workspace_controls.isRoomTypePricing === true) {
    //         date_range_type = "pricingRoomType"
    //     }
    //     dispatch(updateAppState("toggleRoomTypePricing", {
    //         app_state: {
    //             workspace_controls: { ...workspace_controls },
    //             primary_stay_range: {
    //                 ...primary_stay_range,
    //                 date_from: as_of_date,
    //                 date_until: new moment(as_of_date).add(30, 'days').format("YYYY-MM-DD")
    //             },
    //             date_range_type,
    //         },
    //         ui_state: { showspinner: true }
    //     }));
    // }

    const handleChange = (value) => {
        workspace_controls.isRoomTypePricing = value;
        let date_range_type = "rangeTileChange_pricingHolel";
        if (workspace_controls.isRoomTypePricing === true) {
            date_range_type = "rangeTileChange_pricingRoomType";
        }
        dispatch({
            type: UPDATE_STATE,
            payload: {
                app_state: {
                    workspace_controls: { ...workspace_controls },
                    // primary_stay_range: {
                    //     ...primary_stay_range,
                    //     date_from: as_of_date,
                    //     date_until: new moment(as_of_date).add(30, 'days').format("YYYY-MM-DD")
                    // },
                    // historicalRange: {
                    //     ...primary_stay_range,
                    //     date_from: as_of_date,
                    //     date_until: new moment(as_of_date).add(30, 'days').format("YYYY-MM-DD")
                    // },
                    // date_range_type,
                    data_load: false, kpi_load: false
                },
                ui_state: { showspinner: true }
            }
        });
        // dispatch(updateAppState("toggleRoomTypePricing", {
        //     app_state: {
        //         workspace_controls: { ...workspace_controls },
        //         primary_stay_range: {
        //             ...primary_stay_range,
        //             date_from: as_of_date,
        //             date_until: new moment(as_of_date).add(30, 'days').format("YYYY-MM-DD")
        //         },
        //         date_range_type,
        //     },
        //     ui_state: { showspinner: true }
        // }));
    }

    React.useEffect(() => {
        if(!ui_state.showspinner && !app_state.data_load){
            //setSegmentType(controls.segmentType);
        }
    },[ui_state.showspinner]);

    return (
        // <Box className='control-option' sx={{ backgroundColor: 'blue' }}>
        //     <Box className='filterElementText' sx={{ ml: .5 }}>Room Type</Box>
        //     <Switch className='control-switch' checked={workspace_controls.isRoomTypePricing} onChange={() => {
        //         handleChange(!workspace_controls.isRoomTypePricing)
        //     }} />

        //     {/* <div class="checkbox-wrapper-51" checked={workspace_controls.isRoomTypePricing} onChange={() => {
        //         handleChange(!workspace_controls.isRoomTypePricing)
        //     }} >
        //         <input type="checkbox" id="cbx-51" />
        //         <label for="cbx-51" class="toggle">
        //             <span>
        //             <svg width="10px" height="10px" viewBox="0 0 10 10">
        //                 <path d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
        //             </svg>
        //             </span>
        //         </label>
        //     </div> */}
        // </Box>
        <>
            <Box id="tabletype" sx={{ position: "relative", paddingLeft: 0, backgroundColor: 'blue', height: 1 / 2 }}>
                <Box className="data-level-label">Data Level </Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                    <Select disabled={data_load} fullWidth
                        className='tabular-data-filter'
                        value={workspace_controls.isRoomTypePricing}
                        onChange={(e) => handleChange(e.target.value)}
                        sx={{ height: "30px", mt: "5px" }}
                    >

                        <MenuItem value={false} className='tabular-data-filter'>Hotel</MenuItem>
                        <MenuItem value={true} className='tabular-data-filter'>Room Type</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </>

    )
}

export default ToggleRoomTypePricing;
