import { Box, Select, MenuItem } from '@mui/material'
import moment from 'moment';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import { deepCopy, dateDiff, calculateCompStayRange } from '../../../../app/util';
import { UPDATE_STATE } from '../../../../actions/types';
import { APP_CACHE } from '../../../../app/config-v4-api';
import { sendLog } from "../../../../app/log-interface";

//this value will need to be changed with state later


const ScopePicker = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const [scopeType, setScopeType] = useState(controls.scopeType);
    const {current_state} =  useSelector(
        (state) => state.app
    );
    const { app_state, ui_state } = current_state;
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(scopeType !== app_state.workspace_controls.scopeType) {
            let apc = APP_CACHE.controls;
            setScopeType(apc.scopeType);
        }
    }, [app_state.workspace_controls.scopeType]);

    const handleChange = (type, value) => {
        if (value !== scopeType) {
            controls.scopeType = value;
            setScopeType(value);
            let controlSupport = {dba: false};
            // //////// update range //////
            // debugger
            let historicalRange = deepCopy(app_state.historicalRange);
            let workspace_controls = { ...app_state.workspace_controls, scopeType: value }
            APP_CACHE.controls.scopeType = value;
            let date_range_type = 'scopeTypeChanged_' + value;
            
            if (value === "Dba") {
                // debugger;
                historicalRange = deepCopy(app_state.primary_stay_range);
                if (ui_state.active_tiles.includes('market')) {
                    workspace_controls.segmented_view = false;
                    workspace_controls.segmentType = 'hotel';
                    //app_state.workspace_controls.segmented_view = false;
                }
                controlSupport.dba = true;
            }
            sendLog({ category: "scope_select", action: "change_scope" },
             { ...current_state },{  app_state: {
                data_load: false, kpi_load: false,
                date_range_type: date_range_type,
                workspace_controls: workspace_controls,
                controlSupport: {...app_state.controlSupport, ...controlSupport}
                //historicalRange: historicalRange
            } })

            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        data_load: false, kpi_load: false,
                        date_range_type: date_range_type,
                        workspace_controls: workspace_controls,
                        controlSupport: {...app_state.controlSupport, ...controlSupport}
                        //historicalRange: historicalRange
                    }
                }
            });
        }
    }

    return (
        <Box id="scope" sx={{ backgroundColor: 'blue', height: 1 / 2 }}>
            <Box className="data-level-label">Scope</Box>
            <Box>
                
                <Select disabled={ui_state.showspinner} fullWidth value={scopeType}
                    sx={{ height: "30px", mt: "5px", fontSize: "13px" }}
                    onChange={(e) => {
                        // setScopeType(e.target.value); 
                        handleChange("scope_select", e.target.value);
                    }} >
                    <MenuItem className='tabular-data-filter' value="Date"> Date </MenuItem>
                    <MenuItem className='tabular-data-filter' value="Dba"> DBA </MenuItem>
                </Select>
            </Box>
        </Box>
    )
}

export default ScopePicker