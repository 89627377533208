import React, { useState, useEffect } from 'react'
import { LibraryAddCheck as CheckboxDoubleIcon, CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material';

function CheckboxEl({ isChecked, disabledInput, options, method, classes }) {
    const [checkedVal, setCheckedVal] = useState(isChecked);
    let isSingle = "single";

    // let checkBoxEl =(isChecked) ? (<img src='assets/img/single-check-enable.svg' className={'icon-check icon-check-single checked '}/>) : ((<img src='assets/img/single-check-disable.svg' className={'icon-check icon-check-single checked '}/>))

    let checkBoxEl = (
        <div className='new-single-checkbox'>
            <div class="checkboxes__row">
                <div class="checkboxes__item">
                    <label class="checkbox style-c">
                        <input
                            type="checkbox"
                            checked={isChecked}
                            disabled={disabledInput}
                            className={classes}
                            onChange={(e) => {
                                let checked = e.target.checked;
                                method({ checked, ...options })
                            }}
                        />
                        <div class="checkbox__checkmark"></div>
                    </label>
                </div>
            </div>
        </div>
    )

    if (classes.includes("doubleCheckbox")) {
        isSingle = "double";
        // checkBoxEl = (isChecked) ? (<img src='assets/img/double-check-enable.svg' className={'icon-check icon-check-double checked '} />) : (<img src='assets/img/double-check-disable.svg' className={'icon-uncheck icon-check-double '} />)
        checkBoxEl = (
            <div className='new-double-checkbox'>
                <div class="checkboxes__row">
                    <div class="checkboxes__item">
                        <label class="checkbox style-c">
                            <input
                                type="checkbox"
                                checked={isChecked}
                                disabled={disabledInput}
                                className={classes}
                                onChange={(e) => {
                                    let checked = e.target.checked;                                
                                    method({ checked, ...options })
                                }}
                            />
                            <div class="checkbox__checkmark"></div>
                        </label>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <label className={"checkbox-label  " + isSingle + " " + classes}>
            {checkBoxEl}
            {/* <input
                checked={isChecked}
                type="checkbox"
                disabled={disabledInput}
                className={classes}
                style={{ visibility: "hidden" }}
                onChange={(e) => {
                    let checked = e.target.checked;
                    // setCheckedVal(checked);
                    method({ checked, ...options })
                }}
            /> */}
        </label>
    )
}

export default CheckboxEl
