import { useCallback, useState } from "react";
import { Button, Tooltip } from '@mui/material';
import CircularProgress, {
    circularProgressClasses,
} from '@mui/material/CircularProgress';
import GridOnIcon from '@mui/icons-material/GridOn';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { API_BASE_URL, API_REPORT_URL, APP_CACHE } from "../../../../app/config";
import BarChartIcon from '@mui/icons-material/BarChart';
import BarChartSharpIcon from '@mui/icons-material/BarChartSharp';
import moment from 'moment';
import dataService, { fetchDataSimple } from "../../../../services/data.service";
import { in_array, replaceMultiText, deepCopy } from '../../../../app/util';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import MultiSegmentPicker from '../leftComponents/MultiSegmentPicker';
import { UPDATE_STATE } from "../../../../actions/types";
import { sendLog } from '../../../../app/log-interface';

// this component is my test of a state change of only the workspace control - Neeval

const GridViewButton = () => {
    const dispatch = useDispatch();
    const { app_state, ui_state } = useSelector(state => state.app.current_state);
    const curr_ui_state = ui_state;
    const { app, auth } = useSelector((state) => state);
    const { property_id, property_name } = app_state;
    const { sdlyOn, isRoomTypePricing, segmentType } = app_state.workspace_controls;
    const [view, setView] = useState(ui_state.workspace_view == 'table' ? 'table' : 'chart');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    useEffect(() => {
        let isSubscribed = true;
        // debugger;
        if (!app_state.workspace_controls.disableExport) {
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        workspace_controls: { ...app_state.workspace_controls, disableExport: false, }
                    }
                }
            })
            // dispatch(updateAppState("exportStatus", {
            //     app_state: {
            //         workspace_controls: { ...app_state.workspace_controls, disableExport: false, }
            //     }
            // }));
        } else {
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        workspace_controls: { ...app_state.workspace_controls, disableExport: true, }
                    }
                }
            })
            // dispatch(updateAppState("exportStatus", {
            //     app_state: {
            //         workspace_controls: { ...app_state.workspace_controls, disableExport: true, }
            //     }
            // }));
        }
        return () => (isSubscribed = false);
    }, [app_state.workspace_controls.disableExport]);

    const handleSwitch = useCallback(() => {
        let workspace_view = 'table';
        let new_ui_state = {}; let new_app_state = {};
        if (ui_state.workspace_view === 'table') {
            new_ui_state.workspace_view = 'chart';
        } else {
            new_ui_state.workspace_view = 'table';
        }
        // APP_CACHE.controls = deepCopy(app_state.workspace_controls);
        APP_CACHE.controls.user_view = new_ui_state.workspace_view;
        new_ui_state.workspace_view_changed = true;

        new_app_state.user_view = new_ui_state.workspace_view;

        APP_CACHE.user_view = new_app_state.user_view;

        // if (app_state.data_load || ui_state.showspinner) {
        // debugger;
        // new_app_state.data_load = false;
        // new_app_state.kpi_load = false;
        // new_app_state.date_range_type = 'rangeTileChange_userView-' + new_app_state.user_view;
        // new_app_state.date_range_type = 'userViewChange_userView-' + new_app_state.user_view;

        // new_ui_state.showspinner = true;
        // }
        setView(new_ui_state.workspace_view);
        dispatch({
            type: UPDATE_STATE,
            payload: {
                app_state: {
                    // ...new_app_state,
                    user_view: new_ui_state.workspace_view,
                    // changing_view: new_ui_state.workspace_view
                    //date_range_type: 'rangeTileChange_userView-'+new_app_state.user_view,
                    data_load: false, kpi_load: false
                },
                ui_state: {
                    // ...new_ui_state,
                    workspace_view_changed: true,
                    workspace_view: new_ui_state.workspace_view,
                    showspinner: true,
                    // user_view: workspace_view,
                    // workspace_view,
                }
            }
        });

        setTimeout(() => {
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        // ...new_app_state,
                        changing_view: new_ui_state.workspace_view
                        //date_range_type: 'rangeTileChange_userView-'+new_app_state.user_view,
                        // data_load: false, kpi_load: false
                    }
                }
            });
            sendLog({ category: "gridViewButton", action: "switch_to_" + new_ui_state.workspace_view }, { ...app.current_state }, {
                app_state: {
                    ...new_app_state,
                    //date_range_type: 'rangeTileChange_userView-'+new_app_state.user_view,
                    // data_load: false, kpi_load: false
                },
                ui_state: {
                    ...new_ui_state,
                    // showspinner: true,
                    // user_view: workspace_view,
                    // workspace_view,
                }
            });


        }, 100);

    });

    const exportReportTableToExcel = (type) => {
        // debugger;
        // dispatch({
        //     type: UPDATE_STATE,
        //     payload: {
        //         workspace_controls: { ...app_state.workspace_controls, downloadExportButton: true, }
        //     }
        // })
        window.analyze.track("export_download", {
            "selected_tile": ui_state.active_tiles.join(),
            ...app_state.primary_stay_range,
            property_id, property_name, segmentType,
            file_type: type
        }, {}, {});
        dispatch(updateAppState("toggleChartTableView", {
            app_state: {
                workspace_controls: { ...app_state.workspace_controls, downloadExportButton: true, exportType: type }
            }
        }));


    }



    const exportReportToExcel = () => {
        let apiUrl;
        let market_id = app_state.selected_market.market_id;
        let user_id = auth.user.user_id
        let from_date = moment(app_state.as_of_date).format("YYYY-MM-01");
        let to_date = moment(app_state.as_of_date).add(11, "M").endOf("month").format("YYYY-MM-DD");
        let msg;
        const request = {
            "asOfDate": app_state.as_of_date,
            "method": "GET",
        };
        const correction = {
            "{pid}": app_state.property_id,
            "{asOfDate}": app_state.as_of_date,
            "{mid}": market_id,
            // "{toDate}": 
        };

        let tileName;
        if (ui_state.active_tiles[0] === "market_summary") {
            apiUrl = "reports/market-summary-report/{mid}/?asOfDate={asOfDate}";
            apiUrl = replaceMultiText(apiUrl, correction);
            tileName = "Market Summary"
        } else if (ui_state.active_tiles[0] === "hotel_summary") {
            apiUrl = "reports/hotel-summary-report/{pid}/?asOfDate={asOfDate}";
            apiUrl = replaceMultiText(apiUrl, correction);
            tileName = "Hotel Summary"
        }

        msg = "Generating the report, please wait..."
        const exportToast = toast(<div><LinearProgress className='toast-popup-progress' color='green' /> {msg}</div>, {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide
        });

        // dispatch(updateAppState('downloadReport', {
        //     ui_state: {
        //         header_alert_msg: tileName + " report is generating , please wait..."
        //     }
        // }));

        // debugger;
        let api = apiUrl;
        fetchDataSimple({ api, request })
            .then((response) => {
                if (response.status == "success") {
                    toast.update(exportToast, {
                        render: "Report Successfully Downloaded.",
                        type: toast.TYPE.SUCCESS,
                        autoClose: 3000
                    })
                    if (response.data.length > 0) {
                        window.location.href = response.data[0].url;
                    }
                }

            })
            .catch((error) => {
                msg = "Some error occour.Please try again.";
                toast.update(exportToast, {
                    render: msg,
                    type: toast.TYPE.ERROR,
                    autoClose: 3000
                })
                // setTimeout(() => {
                //     dispatch(updateAppState('onHideAlert', {
                //         ui_state: {
                //             header_alert_msg: "",
                //         }
                //     }));
                // }, 3000);
            });
    }

    let icon;
    let iconExportReport;
    let multiPicker;
    let enableSwitch = !app_state.workspace_controls.disableExport;
    let reportsArr = ["bi_pace_comparison", "productionMarketReport", "productionReport",
        "revpar_index", "bi_pickup_analysis", "flights", "rm_room_type_ratesend_status", "restrictions_push_status",
        "DailyRatesendStatus", "budget", "hotel_summary", "market_summary"];
    if (!in_array(reportsArr, ui_state.active_tiles) && !isRoomTypePricing) {
        // console.log(ui_state.workspace_view);
        // console.log(ui_state.show_chart_icon);
        // debugger;
        if (ui_state.workspace_view === 'chart') {
            icon = (
                <Tooltip sx={{ cursor: app_state.data_load ? 'not-allowed' : 'pointer' }} title="Table View" followCursor>
                    {/* <GridOnIcon onClick={handleSwitch} className='grid-view' /> */}
                    <svg onClick={handleSwitch} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#606060" viewBox="0 0 256 256"><path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM40,112H80v32H40Zm56,0H216v32H96ZM216,64V96H40V64ZM40,160H80v32H40Zm176,32H96V160H216v32Z"></path></svg>
                </Tooltip>
            )
        }

        if (ui_state.workspace_view === 'table' && ui_state.show_chart_icon) {
            icon = (
                <Tooltip title="Chart View" followCursor>
                    {/* <BarChartSharpIcon onClick={handleSwitch} className='chart-view' /> */}
                    <svg onClick={handleSwitch} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#606060" viewBox="0 0 256 256"><path d="M224,200h-8V40a8,8,0,0,0-8-8H152a8,8,0,0,0-8,8V80H96a8,8,0,0,0-8,8v40H48a8,8,0,0,0-8,8v64H32a8,8,0,0,0,0,16H224a8,8,0,0,0,0-16ZM160,48h40V200H160ZM104,96h40V200H104ZM56,144H88v56H56Z"></path></svg>
                </Tooltip>
            )
        }
        if (enableSwitch === false) {
            icon = "";
        }
    }

    if (ui_state.active_tiles[0] === "otb_v2_multi_segment") {
        multiPicker = (<MultiSegmentPicker />);
    }
    // debugger; 
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const downloadOptions = (<Popper open={open} anchorEl={anchorEl} onMouseLeave={() => {
        handlePopoverClose()
    }} id="download-options-popover" >
         <Paper className="inner-background">
            <Button onClick={() => {
                exportReportTableToExcel("excel");
                handlePopoverClose()
            }} >excel</Button>
        </Paper>
        <Paper className="inner-background">
            <Button onClick={() => {
                exportReportTableToExcel("csv");
                handlePopoverClose()
            }}> csv</Button> </Paper>
       
    </Popper>)
    if (ui_state.active_tiles[0] === "market_summary" || ui_state.active_tiles[0] === "hotel_summary") {
        iconExportReport = (<>
            <Button disabled={app_state.workspace_controls.disableExport} onMouseOver={(event) => {
                setAnchorEl(event.currentTarget);
            }}
                size='small' className="control-btns"
                sx={{ minWidth: 'auto', ml: -2, mr: 1 }}>
                <Tooltip title="Download Report" arrow followCursor>
                    {/* <FileDownloadIcon
                        // onClick={exportReportToExcel} 
                        onClick={exportReportTableToExcel}
                        fontSize="large" sx={{ mt: 1.2, mr: "-6px" }}
                        className='downloadBtn' /> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#606060" viewBox="0 0 256 256"><path d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,132.69V40a8,8,0,0,0-16,0v92.69L93.66,106.34a8,8,0,0,0-11.32,11.32Z"></path></svg>
                </Tooltip>
            </Button>
            {downloadOptions}
        </>
        )
    } else if (ui_state.active_tiles[0] === "restrictions") {

        iconExportReport = (
            <>
                <Button size='small' onMouseOver={(event) => {
                    setAnchorEl(event.currentTarget);
                }}
                    className="control-btns" sx={{ position: "relative", minWidth: 'auto', ml: 0, mr: 1 }} >
                    <Tooltip arrow followCursor>
                        {/* <FileDownloadIcon fontSize="large" sx={{ mt: 1.2, mr: "-6px" }}
                            className='downloadBtn' /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#606060" viewBox="0 0 256 256"><path d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,132.69V40a8,8,0,0,0-16,0v92.69L93.66,106.34a8,8,0,0,0-11.32,11.32Z"></path></svg>
                    </Tooltip>
                </Button>
                {downloadOptions}
            </>
        )

    } else if (ui_state.active_tiles[0] === "forecast"
        || ui_state.active_tiles[0] === "otb" || ui_state.active_tiles[0] === "market"
        || ui_state.active_tiles[0] === "pickup" || ui_state.active_tiles[0] === "pace"
        || ui_state.active_tiles[0] === "compset" || ui_state.active_tiles[0] === "market_sell_rates"
        || ui_state.active_tiles[0] === "bi_pace_comparison" || ui_state.active_tiles[0] === "flights"
        || ui_state.active_tiles[0] === "productionMarketReport" || ui_state.active_tiles[0] === "revpar_index"
        || ui_state.active_tiles[0] === "productionReport" ||
        ui_state.active_tiles[0] === "bi_sensitivity_analysis"
        || ui_state.active_tiles[0] === "restrictions_push_status"
        || ui_state.active_tiles[0] === "otb_v2_multi_segment"
        || ui_state.active_tiles[0] === "rm_room_type_ratesend_status"
        || ui_state.active_tiles[0] === "DailyRatesendStatus"
        || ui_state.active_tiles[0] === "budget"
        || ui_state.active_tiles[0] === "pricing"
        || ui_state.active_tiles[0] === "bi_pickup_analysis") {

        if ((ui_state.workspace_view === 'table') && ui_state.show_chart_icon) {
            if (!app_state.workspace_controls.disableExport) {
                if (ui_state.showDownloadReport) {
                    iconExportReport = (
                        <>
                            <Button onMouseOver={(event) => {
                                setAnchorEl(event.currentTarget);
                            }}
                                size='small' className="control-btns" sx={{ position: "relative", minWidth: 'auto', ml: -2, mr: 1 }} >

                                <Tooltip followCursor arrow>
                                    {/* <FileDownloadIcon fontSize="large" sx={{ mt: 1.2, mr: "-6px" }}
                                        className='downloadBtn' /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#606060" viewBox="0 0 256 256"><path d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,132.69V40a8,8,0,0,0-16,0v92.69L93.66,106.34a8,8,0,0,0-11.32,11.32Z"></path></svg>
                                </Tooltip>
                            </Button>
                            {downloadOptions}
                        </>
                    )
                }
            } else {
                iconExportReport = (
                    <span style={{ position: 'relative' }}>
                        <Tooltip title="Loading...">
                            <CircularProgress size={30} sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }} disableShrink />
                        </Tooltip>
                        <Button size='small' disabled className="control-btns" sx={{ position: "relative", minWidth: 'auto', ml: -2, mr: 1, opacity: 0.5 }}>

                            {/* <FileDownloadIcon fontSize="large" sx={{ mt: 1.2, mr: "-6px" }}
                                className='downloadBtn' /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#606060" viewBox="0 0 256 256"><path d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,132.69V40a8,8,0,0,0-16,0v92.69L93.66,106.34a8,8,0,0,0-11.32,11.32Z"></path></svg>

                        </Button>

                    </span>


                )
            }

        }
    }

    return (
        <>
            {/* {multiPicker} */}
            {icon && <>
                {ui_state.showspinner && <Button disabled size='small' className="control-btns" sx={{ minWidth: 'auto', mr: 1, mt: .5 }}>
                    {icon}
                </Button>}
                {!ui_state.showspinner && <Button size='small' className="control-btns" sx={{ minWidth: 'auto', mr: 1, mt: .5 }}>
                    {icon}
                </Button>}
                {!iconExportReport && (<><Button disabled size='small'

                    className="control-btns" sx={{ minWidth: 'auto', ml: -2, mr: 1 }}>
                    <Tooltip arrow followCursor>
                        {/* <FileDownloadIcon onClick={exportReportToExcel} fontSize="large" sx={{ mt: 1.2, mr: "-6px" }} className='downloadBtn' /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#606060" viewBox="0 0 256 256"><path d="M224,152v56a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V152a8,8,0,0,1,16,0v56H208V152a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,132.69V40a8,8,0,0,0-16,0v92.69L93.66,106.34a8,8,0,0,0-11.32,11.32Z"></path></svg>
                    </Tooltip>
                </Button>{downloadOptions}</>)}
            </>

            }
            {iconExportReport}
        </>
    );
}

export default GridViewButton