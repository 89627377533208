import React, { useState, useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import { Block } from '@mui/icons-material';
import { isNumber } from '../../app/util';
function InputEl({ onBlur, subcol_id, disabledInput, inputVal, handleKeyDown, edited, currency, isRoomTypePricing }) {
    let editbtn = (<div></div>);
    if (edited) {
        editbtn = (<EditIcon className="editBtn" />)
    }

    const [editMode, setEditMode] = useState(false);

    const [inputText, setInputText] = useState(inputVal);
    const resetInputText = (value = "") => {
        setInputText(value);
    }
    useEffect(() => {
        let val = "";
        if (inputVal !== "") {
            if (inputVal.includes("(")) {
                val = inputVal.replace(currency, "");
                val = parseInt(val.replace(/\D/g, '')) * -1;
            } else {
                val = parseInt(inputVal.replace(currency, ""));
            }
            if (isNaN(val)) {
                val = "";
            }
        }
        setInputText(val)
        return () => {

        }
    }, [inputVal])
    const handleInputChange = (e) => {
        let val = e.target.value;
       
        setInputText(val);
    }
    const handleFocus = (event) => {
        let val = event.target.value;
        if (subcol_id === 'SellRate' && val <= 0) {
            val = parseInt(inputVal.replace(currency, ""));
            // if(isNaN(val)){
            //     val = "";
            // }
            setInputText(val);
        }
        event.target.select()
    };

    let inputSection = (<input
        autoFocus
        onFocus={handleFocus}
        value={inputText}
        onKeyDown={handleKeyDown}
        onBlur={(e) => {
            setEditMode(false);
            let { value } = e.target.value;
            if (value !== '') {
                value = parseInt(e.target.value);
            }
            if (isNaN(value)) {
                value = '';
            }
            setInputText(value)
            onBlur(value, resetInputText)
        }}
        disabled={disabledInput} type="string"
        className={subcol_id}
        onChange={handleInputChange}
    />)
    let sty = {
        display: "block",
        width: "100%"
    }
    if (!inputVal || inputVal == "") {
        sty["height"] = "100%"
    }

    return (
        <>
            {!editMode && (<span style={sty} className="dataView" onClick={() => {
                setEditMode(true)
            }}>{inputVal}</span>)}
            {editMode && inputSection}
            {editbtn}
        </>
    )
}

export default InputEl
