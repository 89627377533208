import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import DataService, { fetchDataSimple } from "../../services/data.service";
import { getFromSession } from '../../app/util';
import { CustomSVGIcon } from '../layout/dashboard-ui';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getData, updateAppState } from '../../actions/appstate';
import { async, jsonEval } from '@firebase/util';
const token = getFromSession("token");

const ZendeskDashboard = (props) => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { auth, app } = state;
    const { user, profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const [returnUrl, setReturnUrl] = useState(null);
    const formRef = useRef(null);
    const inputRef = useRef(null);
    const inputRet = useRef(null);

    const handleSubmit = async () => {
        let payload = {
            method: "GET",
        }
        let username = (user.fullname) ? user.fullname : "Lodgiq"
        let userEmail = (user.email) ? user.email : "user@lodgiq.com";
        let getJwtTokenUrl = 'users/zendesk-login/?name='+username+'&email='+userEmail;
        try {
            const data = await fetchDataSimple({ api: getJwtTokenUrl, request: payload });
            if (data) {
                let tempReturn = 'https://lodgiqhelp.zendesk.com/access/jwt';
                setReturnUrl(tempReturn);
                let jwt = data.jwt;
                inputRef.current.value = jwt;
                inputRet.current.value = data.return_to;
                formRef.current.submit();
            } else {
                console.log("some error occour!");
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        handleSubmit();
    }, [])

    return (
        <div>
            <form ref={formRef} action={returnUrl} method="post" target="_blank">
                <input ref={inputRef} type="hidden" name="jwt"></input>
                <input ref={inputRet} type="hidden" name="return_to"></input>
            </form>
        </div>
    )
}

export default ZendeskDashboard
