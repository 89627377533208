import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useCallback, useEffect, useRef, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { processColumns, processData, processDataNew } from "./processData";
import moment from "moment";
import _ from "lodash";
import {
    convertToPercent, round, getFromState, getTransformedSegments, numberFormatForReport,
    truncate, deepCopy, processColumnsNew,
    getAppData, getCompsetName, dateFormat, getSdlyDate,
    getPermissions, in_array, sum, getSeriesById, divide,
    generateTable, getDatesInRange, getMarketSegment,
    aggregateSegmentedGroupedData,
    preferredOrder,
    removeSpecialCharExceptDecimal, downloadcsvwithTable,
    getCorrectValue,
    showPercentValues,
    getCacheDataKey,
    removeSpecialChar,
    removeCurrSymbol,
    getStayToSdly, getStayToComp, formattedCompDateCalc, isDateInRange,
    getSDLYorSDTLYDate,
    getSumOfData
} from "../../app/util"
import { transformSegmentData } from "./segmentedData";
import { useDownloadExcel } from 'react-export-table-to-excel';
import "./table-common.css";
import { updateAppState } from "../../actions/appstate";
import { Button, Tooltip, getTableSortLabelUtilityClass, IconButton, Popover } from "@mui/material";
import dataService from "../../services/data.service";
import { Box } from "@mui/system";
import { marketSellRatesTotal } from "./tilesData/marketsellrates";
import TableToExcel from "@linways/table-to-excel";
import Spinner from "../Spinner";
import { format, parse, set } from "date-fns";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Filter from "./Filter";
import { APP_CACHE, TILES } from "../../app/config";
import Restrictions, { getResName, getRestrictionAll } from "./Restrictions";
import { primaryHeaderDisplay } from './table-functions';
import {
    generalizeData, transformFlightsdata, getFlightSegments,
    otbTransformTotal, transFormMarketData, transFormOtbData,
    transFormPickupData, transFormForecastData
}
    from "./functions";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoButtonMarketSellRate from '../Workspace/subComponents/leftComponents/InfoButtonMarketSellRate';
import DisplayData from './DisplayData';

import { Alert, AlertTitle } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso'
import { UPDATE_STATE } from '../../actions/types';

import DataService from "../../services/data.service";

import TableLoadingLottie from "../../lotties/table-loading.json";
import Lottie from "react-lottie";
import Skeleton from "@mui/material/Skeleton";
import msg from "../Workspace/messages.js";



// import { dateFormat } from 'highcharts';

// const processWorker = new Worker('/workers/app.process.js');
const PrimaryHeaderCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#606060",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const SecondaryHeaderGreyCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#b2b2b2",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const SecondaryHeaderBlueCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3fa7cd",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#ebebeb",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));






function TableCommon(props) {
    // debugger;
    const state = useSelector(appState => appState);
    let compDisplayData = props.compDisplayData;
    compDisplayData[0].data = (compDisplayData && compDisplayData[0].data === null) ? [] : compDisplayData[0].data;
    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const apc = APP_CACHE;

    const [containerHeight, setContainerHeight] = useState(720); // Default height
    const [containerWidth, setContainerWidth] = useState(1280); // Default width

    const { workspace_controls, notes, selected_market, eventList, eventListSDLY } = app_state;
    const { sdlyOn, segmented_view } = workspace_controls;

    const { active_tiles, table_filters } = ui_state;
    let segments = null
    let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
    let segment_ignore_codes = getFromState('system_settings>market_code_ignorelist', state);
    // segment_ignore_codes.push('COMP');
    segments = getTransformedSegments(marketSegments, segment_ignore_codes);
    const cache_data_key = getCacheDataKey({ active_tiles, stayRange: app_state.primary_stay_range, property_id: app_state.property_id, workspace_controls: workspace_controls, selected_market, comp_stay_range: app_state.comp_stay_range });
    if (active_tiles[0] === 'flights') {
        segments = getFlightSegments(workspace_controls.segmentType);
    }
    let filteredDow = table_filters.table_common.dow.map(d => d.code);
    let filteredSegments = ui_state.table_filters.table_common.segments.map(d => d.code);
    let marketFilteredSegments = ui_state.table_filters.table_common.marketSegments.map(d => d.code);
    if (in_array(ui_state.active_tiles, ['flights', 'hotel_summary', 'market_summary', 'budget'])) {
        filteredDow = ['all'];
    }

    if (active_tiles.includes('market')) {
        filteredSegments = marketFilteredSegments;
    }

    if (filteredSegments.includes('all')) {
        // debugger;
        filteredSegments = [];
        segments.list.map((item) => {
            if (item.name === 'Other') {
                if (!filteredSegments.includes('Other')) {
                    filteredSegments.push('Other');
                }
            } else {
                if (!filteredSegments.includes(item.code)) {
                    filteredSegments.push(item.code);
                }
            }
        });

        if (active_tiles.includes('market')) {
            let market_segments = getMarketSegment(active_tiles);
            filteredSegments = market_segments.map(item => item.code);
        } else {
            filteredSegments = segments.arrUnique;
        }
    }
    if (active_tiles.includes('flights')) {
        filteredSegments = deepCopy(segments.arrUnique);
    }
    const dispatch = useDispatch();

    const stayDates = getDatesInRange(app_state.primary_stay_range.date_from, app_state.primary_stay_range.date_until);

    useEffect(() => {
        const updateContainerHeight = () => {
            const newHeight = window.innerHeight;
            setContainerHeight(newHeight);
        };

        const updateContainerWidth = () => {
            const newWidth = window.innerWidth;
            setContainerWidth(newWidth);
        };

        // Initial height and width update
        updateContainerHeight();
        updateContainerWidth();

        // Event listener for window resize
        window.addEventListener("resize", updateContainerHeight);
        window.addEventListener("resize", updateContainerWidth);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", updateContainerHeight);
            window.addEventListener("resize", updateContainerWidth);

        };
    }, []);

    let selectedPropertyName = profile.property_details.filter(property => property.id == app_state.property_id)[0].name;
    const active_tiles_className =
        "tile-active-" + ui_state.active_tiles.join(" tile-active-");
    const [columns, setColumns] = useState([]);
    const [data_cols, setDataCols] = useState([]);
    const [data_setup, setDataSetup] = useState([]);
    let [displayData, setDisplayData] = useState(compDisplayData);
    const [compLoad, setCompLoad] = useState(false);
    let [reloadGrid, setReloadGrid] = useState("");
    let [compLoadStatus, setCompLoadStatus] = useState('loading');
    const [renderBodyData, setRenderBodyData] = useState([]);
    const { property_details } = useSelector(state => state.auth.profile);
    const tableVirtuoso = useRef(null);

    const [totalRowsData, setTotalRowsData] = useState([])

    const { permissions, index } = getPermissions(property_details, app_state.property_id);
    const getNeededColumns = ({ auth, app_state, ui_state }) => {
        const { workspace_controls, as_of_date } = app_state;
        const { sdlyOn, pickUpType, segmented_view } = workspace_controls;
        const { active_tiles } = ui_state;
        let startDate = app_state.primary_stay_range.date_from;
        let endDate = app_state.primary_stay_range.date_until;
        // let restrictions = (getAppData(auth, app_state).restrictions) ? getAppData(auth, app_state).restrictions.enable : false;
        let restrictions = (permissions && permissions.restrictions === 1) ? true : false;
        let finalColumns = [];
        active_tiles.map((tile, i) => {
            let tableConfig = deepCopy(TILES[tile]['tableConfig']);
            let columns = tableConfig['columns'];
            let sdlyColumns = deepCopy(TILES[tile]['tableConfig']['sdlyOn_columns']);
            let restrictionsColumns = deepCopy(TILES[tile]['tableConfig']['restrictionsOn_columns']);
            let dataDbaColumns = deepCopy(TILES[tile]['tableConfig']['dataDbaColumns']);
            let dataDateColumns = deepCopy(TILES[tile]['tableConfig']['dataDateColumns']);
            let dbaSdlyColumns = deepCopy(TILES[tile]['tableConfig']['dbaOn_sdly_columns']);
            let marketDateSdlyOn_columns = deepCopy(TILES[tile]['tableConfig']['marketDateSdlyOn_columns']);
            let userForecastColumns = deepCopy(TILES[tile]['tableConfig']['userForecast_columns']);
            finalColumns = [...finalColumns, ...columns];
            //  debugger;
            let tile_segmented_view = true;
            let segmentedViewIgnore = ['compset'];
            if (segmentedViewIgnore.includes(active_tiles[0])) {
                tile_segmented_view = false;
            }
            if (active_tiles[0] === "market" && app_state.workspace_controls.scopeType === 'Dba') {
                tile_segmented_view = false;
            }

            if (in_array(active_tiles, ["compset"]) && tile === "compset") {
                let ord = (active_tiles[0] === "compset") ? 4 : 100;
                let subColumns = getCompsetName(ord, app_state);
                columns[1]['subcolumns'] = [...columns[1]['subcolumns'], ...subColumns];
            }

            // check Scope is DBA
            if (dataDbaColumns && app_state.workspace_controls.scopeType === 'Dba') {
                let dateColIndex = columns.findIndex(item => item.type === "dateColumn");
                let dbaSubColumns = columns[dateColIndex].subcolumns_dba;
                columns[dateColIndex]['subcolumns'] = dbaSubColumns;
                if (dataDbaColumns) {
                    finalColumns = [...finalColumns, ...dataDbaColumns];
                }
            } else {
                if (dataDateColumns) {
                    finalColumns = [...finalColumns, ...dataDateColumns];
                }
            }
            /// end ///

            /// to check user forecast ///
            let userForecastData = [];
            if (!dataService.getSeriesById("ManualForecast").data) {
                userForecastData = [];
            } else {
                userForecastData = dataService.getSeriesById("ManualForecast").data.data;
            }
            if (userForecastData.length > 0 && userForecastColumns && !workspace_controls.segmented_view) {
                finalColumns = [...finalColumns, ...userForecastColumns];
            }
            //// end ///

            if (sdlyOn && sdlyColumns) {
                finalColumns = [...finalColumns, ...sdlyColumns];
            }

            if (sdlyOn && dbaSdlyColumns && app_state.workspace_controls.scopeType === 'Dba' && in_array(active_tiles, ["market"])) {
                finalColumns = [...finalColumns, ...dbaSdlyColumns];
            }
            if (sdlyOn && marketDateSdlyOn_columns && app_state.workspace_controls.scopeType !== 'Dba' && in_array(active_tiles, ["market"])) {
                finalColumns = [...finalColumns, ...marketDateSdlyOn_columns];
            }



            if (restrictions && restrictionsColumns) {
                finalColumns = [...finalColumns, ...restrictionsColumns];
            }
            if (segmented_view && tile_segmented_view) {
                let dateColIndex = columns.findIndex(item => item.type === "dateColumn");
                let dateColumn = columns[dateColIndex];
                if (dateColIndex > -1 && dateColumn["subcolumns_segmented"]) {
                    dateColumn["subcolumns_segmented"].map(segmented => {
                        columns[dateColIndex].subcolumns.push(segmented);
                    });
                }
            }
        });

        //// remove the comparison column after click on the clear comparison button
        if (in_array(ui_state.active_tiles, ["pace"]) && !app_state.workspace_controls.clearComparision) {
            let columnsWithOutComp = finalColumns.filter((item) => (item.id !== "comparison_pace" && item.id !== "bc_date_diff" && item.id !== "comparison_pace_dba" && item.id !== "bc_dba_diff"))
            finalColumns = [...columnsWithOutComp];
        }
        // End //

        let tempArr = [];
        let tmpArr = [];
        finalColumns.map((itm, idx) => {

            if (!tmpArr.includes(itm.id)) {
                tempArr.push(itm);
                let pushIndex = tmpArr.push(itm.id);
                if (!['hotel_summary', 'market_summary'].some(elem => active_tiles.includes(elem))) {
                    itm.order = pushIndex + 1;
                }
            }
        })

        finalColumns = [...tempArr];
        finalColumns.sort(function (a, b) { return a.order - b.order });
        finalColumns.map((item, index) => {
            let subColumns = item.subcolumns;
            subColumns.sort(function (a, b) { return a.order - b.order });
        })

        let sortedColumns = [...finalColumns];
        let sortedFinalColumns = sortedColumns.filter((item) => item.hidden !== true);
        return sortedFinalColumns;
    };


    const compRef = useRef({
        itemDragged: {},
        itemDroppedOn: {},
        columns: getNeededColumns({ auth, app_state, ui_state }),
        compLoad: false,
        processWorker: null
        // columns: deepCopy(TILES[active_tiles[0]]['tableConfig']['columns']),
        // sortedColumn: TILES[active_tiles[0]]['tableConfig']['columns'][0][0]
    });
    // debugger;

    // debugger;   
    // }
    const TableStyle = {
        height: "60vh",
        overflow: "auto",
    };



    useEffect(() => {
        let isSubscribed = true;
        let finalProcessData;
        if (compRef.current.processWorker) compRef.current.processWorker.terminate();
        if (!compRef.current.processWorker) compRef.current.processWorker = new Worker('/workers/app.process.js');
        let date_dba = app_state.workspace_controls.scopeType.toLocaleLowerCase();
        if (active_tiles[0] === 'flights') {
            date_dba = 'month';
        }
        compRef.current.filteredColumns = getFilteredColumns(compRef.current.columns);
        // debugger;
        if (reloadGrid === 'reOrderedMainCols' && compRef.current.data_setup) {
            let t = compRef.current.columns;
            let mainColOrders = compRef.current.columns.map((item) => ({ id: item.id, order: item.order }))
            compRef.current.data_setup.forEach((row, index) => {
                let newOrderColumn = [];
                // compRef.current.data_setup[index].sort()
                mainColOrders.forEach((col) => {
                    let colData = row.find((itm) => col.id === itm.id)
                    newOrderColumn.push(colData);
                })
                compRef.current.data_setup[index].splice(0, row.length, ...newOrderColumn);
            });
            setReloadGrid('applySorting');
        }

        if (reloadGrid === 'applySorting' && compRef.current.data_setup) {
            let finalRows = [];
            let sortedColumn = getSortedColumn(compRef.current.columns);
            let data_setup = deepCopy(compRef.current.data_setup);
            data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls, active_tiles: ui_state.active_tiles });
            if (date_dba === 'date' || date_dba === 'month') {
                data_setup = compRef.current.data_setup.filter((item) => item[0][date_dba] === '' || filteredDow.includes(moment(item[0][date_dba]).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
            }
            if (workspace_controls.segmented_view) {
                data_setup = data_setup.filter((item) => (item[0][date_dba] === '' && filteredSegments.includes(item[0].segment_code)) || item[0].segment === 'Total' || filteredSegments.includes(item[0].segment_code) || filteredSegments.includes('all'));

                let totalsRows = data_setup.filter(item => item[0].segment === 'Total');

                totalsRows = sortData({ data: totalsRows, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls, active_tiles: ui_state.active_tiles });
                let segmentedTotalsRows = data_setup.filter(item => item[0][date_dba] === '' && filteredSegments.includes(item[0].segment_code));
                // debugger;
                totalsRows.forEach(item => {
                    let segmentedRows = data_setup.filter(d => item[0][date_dba] === d[0][date_dba] && d[0].segment !== 'Total');
                    segmentedRows = getFilteredData({ data: segmentedRows, columns: compRef.current.columns });

                    if (segmentedRows.length > 0) {
                        let calcTotal = getTotalData(segmentedRows, 'segment');
                        let totalOfFiltered = [...[item[0]], ...calcTotal];
                        totalOfFiltered.forEach((col, i) => {
                            totalOfFiltered[i].id = item[i].id;

                            //  Adding sellrate value in total row of segmented view
                            if (totalOfFiltered[i].hasOwnProperty('SellRate')) {
                                let dateHotelTotal = totalsRows.find((itm) => {
                                    return moment(itm[0].date).isSame(totalOfFiltered[0].date)
                                });
                                let columnDetails = compRef.current.columns.find(col => col.id === totalOfFiltered[i].id);
                                let identity = columnDetails ? columnDetails.id : null;
                                if (identity === "sdly" && workspace_controls && workspace_controls.dayDate === 'date') {
                                    identity = "sdtly";
                                }
                                if (identity === "sdly_actual" && workspace_controls && workspace_controls.dayDate === 'date') {
                                    identity = "sdtly_actual";
                                }
                                if (identity && dateHotelTotal && dateHotelTotal[i] && dateHotelTotal[i].id && dateHotelTotal[i].id === identity) {
                                    totalOfFiltered[i].SellRate = dateHotelTotal[i].SellRate;
                                }
                            }
                            // debugger;
                            if (active_tiles[0] === 'forecast' && ["forecast_difference", "userForecast"].includes(item[i].id)) {
                                totalOfFiltered[i] = { ...totalOfFiltered[i], ...item[i] };
                            }
                        });
                        if (totalOfFiltered.length) {
                            finalRows = [...finalRows, ...segmentedRows, ...[totalOfFiltered]];
                        }
                    }
                });
                let finalSegmentsTotal = [];
                if (finalRows.length) {
                    filteredSegments.forEach((item) => {
                        let segmentTotalRow = finalRows.filter((row) => row && row[0] && row[0].segment_code === item);
                        let dateCol = deepCopy(finalRows[0][0]);
                        dateCol.date = ''; dateCol.dow = ''; dateCol.segment = segments.map[item] ? segments.map[item] : item; dateCol.segment_code = item;
                        let calcTotal = getTotalData(segmentTotalRow, 'segmentTotal');
                        if (calcTotal.length) {
                            segmentTotalRow = [...[dateCol], ...getTotalData(segmentTotalRow, 'segmentTotal')];
                            finalSegmentsTotal = [...finalSegmentsTotal, ...[segmentTotalRow]];
                        }
                    });
                    finalSegmentsTotal.sort((a, b) => {
                        let A = a[1] && a[1].Rooms ? a[1].Rooms : 0; let B = b[1] && b[1].Rooms ? b[1].Rooms : 0;
                        if (B > 0 || A > 0) {
                            return B - A;
                        }
                    });
                    if (date_dba !== 'dba' && active_tiles[0] !== 'flights') {
                        finalRows = [...finalRows, ...finalSegmentsTotal];
                    }
                }
                data_setup = deepCopy(finalRows);
                data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls, active_tiles: ui_state.active_tiles });

            } else {
                data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls, active_tiles });
                data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
            }

            // compRef.current.data_setup = deepCopy(data_setup);
            // console.log(data_setup);
            // debugger;
            setDataSetup(data_setup);
            setReloadGrid('appliedSorting');
            setCompLoadStatus('sortedCols');
        }

        let start = moment().format("x");

        // const cache_data_key = getCacheDataKey({ active_tiles, stayRange: app_state.primary_stay_range, data_id: '' });
        // apc['cache_keys'] = apc['cache_keys'] ? apc['cache_keys'] : { general: 'generalized__' + cache_data_key, 'final': 'finalized__' + cache_data_key };
        if (hasInfoBoxTable()) {
            ui_state.showDownloadReport = false;
        } else {
            ui_state.showDownloadReport = true;
        }

        const filteredSegment = ui_state.table_filters.table_common.segments.map(d => d.code);
        if (filteredSegment.includes('all')) {
            let segmentArr = segments.list
            segmentArr = segmentArr.map(v => ({ ...v, hidden: false, title: v.name, type: "property_segments" }))
            segmentArr = segmentArr.map(v =>
                v.name === 'Other'
                    ? { ...v, code: 'Other', hidden: false, title: v.name, type: "property_segments" }
                    : { ...v, hidden: false, title: v.name, type: "property_segments" }
            );
            let segmentArrUnique = segmentArr.filter((obj, index) => {
                return index === segmentArr.findIndex(o => obj.name === o.name);
            });
            ui_state.table_filters.table_common.segments = segmentArrUnique;
        }

        const filteredMarketSegment = ui_state.table_filters.table_common.marketSegments.map(d => d.code);
        if (filteredMarketSegment.includes('all') && active_tiles.includes('market')) {
            let segmentArr = getMarketSegment(active_tiles);
            segmentArr = segmentArr.map(v => ({ ...v, hidden: false, title: v.name, type: "market_segments" }))
            segmentArr = segmentArr.filter((obj, index) => {
                return index === segmentArr.findIndex(o => obj.name === o.name);
            });
            ui_state.table_filters.table_common.marketSegments = segmentArr;
            // ui_state.table_filters.table_common.marketIntellimergeSegments = segmentArr;
        }

        if (app_state.data_load) {
            dispatch(
                updateAppState("data_load", {
                    app_state: {
                        data_load: false,
                    },
                    ui_state: {
                        table_filters: ui_state.table_filters,
                        showDownloadReport: ui_state.showDownloadReport
                    }
                })
            );
        }


        return () => {
            // debugger;
            isSubscribed = false;
            // compRef.current.processWorker.terminate();
            // compRef.current.processWorker = null;
        }

    }, [
        // displayData,
        // compLoad,
        reloadGrid,
        // compLoadStatus
    ]);

    useEffect(() => {
        let finalProcessData;

        if (!['applySorting', 'appliedSorting'].includes(reloadGrid) && !compRef.current.data_setup) {
            // debugger;
            if (compLoad && displayData && displayData.length > 0) {
                //Updating table_filters kpis
                let table_filters = deepCopy(ui_state.table_filters);
                compRef.current.columns.map((col) => {
                    col.subcolumns.map((subcol) => {
                        // debugger;
                        if (!ui_state.table_filters.table_common.kpis.includes(subcol.value)
                            && !ui_state.table_filters.table_common.kpis_hidden.includes(subcol.value)
                            && !subcol.hidden) {
                            // ui_state.table_filters.table_common.kpis.push(subcol.value);
                            table_filters.table_common.kpis.push(subcol.value);
                        }
                    });
                });
                compRef.current.columns.map((item) => {
                    if (item.id !== "staydates") {
                        item.subcolumns.map((sub_cols) => {
                            if (in_array(table_filters.table_common.kpis, [sub_cols.value])) {
                                sub_cols.hidden = false;
                            } else {
                                sub_cols.hidden = true;
                            }
                        })
                    }
                })
                // debugger;
                // dispatch(updateAppState("change_filter", {
                //     // app_state: { data_load: true },
                //     ui_state: {
                //         ...ui_state,
                //         // showspinner: true
                //     }
                // }));

                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        ui_state: {
                            table_filters: table_filters
                        }
                    }
                });

                let dataSourceMap = {
                    otb: { Date: 'OSRSegment' },
                    pickup: { Date: 'OSRSegment' },
                    pace: { Date: 'OSRBookingPaceSegment', Dba: 'OSRBookingPaceSegmentDBA' },
                    compset: { Date: 'Compset', Dba: 'CompsetBookingPace' },
                    market: { Date: 'MarketARISegmented', Dba: 'MarketDba' },
                    market_sell_rates: { Date: 'MarketSellRate' },
                    forecast: { Date: 'OSRSegment', forecast: 'OSRContrainedForecast', userforecast: 'ManualForecast' }
                };
                let helperDataSourceMap = {
                    market: { Date: 'MarketForecastSegmented' },
                    forecast: { Date: 'OSRContrainedForecast' }
                };


                let start = moment().format("x");
                let tile = ui_state.active_tiles[0];

                apc['cache_keys'] = apc['cache_keys'] && apc['cache_keys'].final === 'finalized__' + cache_data_key ? apc['cache_keys'] : { general: 'generalized__' + cache_data_key, 'final': 'finalized__' + cache_data_key };

                if (!in_array(ui_state.active_tiles, ['forecast', 'otb', 'pickup', 'market', 'flights', 'hotel_summary', 'market_summary', 'budget']) || (ui_state.active_tiles.includes('market') && workspace_controls.scopeType === 'Dba')) {
                    let tileData = getSeriesById(dataSourceMap[tile][workspace_controls.scopeType]);
                    let DATA = (tileData && tileData.data) ? tileData.data : [];
                    let helper_DATA = null;
                    if (tile === 'market' && helperDataSourceMap[tile]
                        && helperDataSourceMap[tile][workspace_controls.scopeType]
                        && getSeriesById(helperDataSourceMap[tile][workspace_controls.scopeType]).data
                    ) {
                        helper_DATA = getSeriesById(helperDataSourceMap[tile][workspace_controls.scopeType]).data;
                    }
                    if (tile === 'forecast' && helperDataSourceMap[tile]
                        && helperDataSourceMap[tile][workspace_controls.scopeType]
                        && getSeriesById(helperDataSourceMap[tile][workspace_controls.scopeType]).data
                    ) {
                        helper_DATA = { data: getSeriesById(helperDataSourceMap[tile][workspace_controls.scopeType]).data.data_transformed };
                    }
                    // debugger
                    // let DATA = displayData;
                    if (!DATA) {
                        DATA = { data: [] }
                        //filteredSegments = ui_state.table_filters.table_common.marketSegments.map(d => d.code);
                    }
                    //Add manula forecast data if available
                    if (ui_state.active_tiles.includes('forecast') && DATA.data?.length) {
                        let userForecast = getSeriesById(dataSourceMap[tile]['userforecast']).data;
                        if (userForecast && userForecast.data && userForecast.data.length) {
                            DATA.data.forEach(item => {
                                let segmentKey = '';
                                let keys = Object.keys(item);
                                segmentKey = keys.find(key => key.indexOf('Segment_') >= 0);
                                let manualForecast = userForecast.data.find(data => new moment(item.index.date).isSame(data.index.date, 'day'))
                                let totalKey = 'HotelTotal';
                                if (manualForecast) {
                                    let dateData = deepCopy(manualForecast.total_forecast);
                                    dateData.Occ = dateData['Occ%'];
                                    delete dateData['Occ%'];
                                    item[totalKey].userforecastdata = dateData;
                                    item[segmentKey].userforecastdata = dateData;
                                }
                            });
                        }
                    }

                    let marketAllSegment = getMarketSegment(ui_state.active_tiles).map(item => item.code);
                    let segment_filtered = (active_tiles[0] === 'market') ? marketAllSegment : segments.arrUnique;

                    const generalizedData = generalizeData({
                        data: (DATA.data) ? deepCopy(DATA.data) : [],
                        helperData: helper_DATA && helper_DATA.data ? helper_DATA.data : null,
                        stayDates, tile,
                        data_id: dataSourceMap[tile][workspace_controls.scopeType],
                        columns: compRef.current.columns,
                        capacity: getAppData(auth, app_state).property_current_total_capacity,
                        filters: {
                            dow: ['all'], //filteredDow,
                            segments: segment_filtered,
                            //['all'], //filteredSegments,
                        },
                        segments: !['market', 'market_sell_rates'].includes(tile) ? segments : { list: [], map: {} },
                        workspace_controls
                    });

                    finalProcessData = generalizedData.data;

                } else if (ui_state.active_tiles.includes('market')) {
                    finalProcessData = transFormMarketData({ dayDate: workspace_controls.dayDate });

                } else if (ui_state.active_tiles.includes('otb')) {
                    finalProcessData = transFormOtbData({ dayDate: workspace_controls.dayDate });

                } else if (ui_state.active_tiles.includes('pickup')) {
                    finalProcessData = transFormPickupData({ dayDate: workspace_controls.dayDate, pickUpType: workspace_controls.pickUpType });

                } else if (ui_state.active_tiles.includes('forecast')) {
                    finalProcessData = transFormForecastData({ stayDates, dayDate: workspace_controls.dayDate });

                } else {
                    finalProcessData = displayData;
                }

                let et = moment().format('x');
                console.log("Time taken generalizedData: ", et - start, finalProcessData);
                // finalProcessData = transFormMarketData({dayDate: workspace_controls.dayDate});

                // debugger;
                start = moment().format('x');

                setupData(finalProcessData)
                    .then((rs) => {
                        let transforemedData = finalProcessData;
                        let finalRows = [];
                        let date_dba = workspace_controls.scopeType.toLocaleLowerCase();
                        if (active_tiles[0] === 'flights') {
                            date_dba = 'month';
                        }
                        let timetaken = moment().format('x') - start;
                        console.log('Time taken setupData:', timetaken);
                        // debugger;
                        let data_setup = typeof rs === 'string' ? JSON.parse(rs) : deepCopy(rs);
                        // compRef.current.data_setup = copyRS;
                        let sortedColumn = getSortedColumn(compRef.current.columns);
                        //need to fix
                        data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls, active_tiles: ui_state.active_tiles })

                        compRef.current.data_setup = !compRef.current.data_setup && data_setup.length ? deepCopy(data_setup) : compRef.current.data_setup;
                        apc[apc.cache_keys.final] = deepCopy(compRef.current.data_setup);

                        // debugger;
                        if (date_dba === 'date' && compRef.current.data_setup) {
                            data_setup = compRef.current.data_setup.filter((item) => item[0][date_dba] === '' || filteredDow.includes(moment(item[0][date_dba]).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
                        }

                        if (workspace_controls.segmented_view) {
                            data_setup = data_setup.filter((item) => (item[0][date_dba] === '' && filteredSegments.includes(item[0].segment_code)) || item[0].segment === 'Total' || filteredSegments.includes(item[0].segment_code) || filteredSegments.includes('all'));
                            let months = [];

                            let totalsRows = data_setup.filter((item) => {
                                return item[0].segment === 'Total'
                            });
                            // let segmentedTotalsRows = data_setup.filter(item => item[0][date_dba] === '' && filteredSegments.includes(item[0].segment_code));
                            // let filteredTotalRows = getFilteredData({ data: totalsRows, columns: compRef.current.columns });
                            const compRefColumns = compRef.current.columns;
                            const dayDate = workspace_controls.dayDate;

                            totalsRows.forEach(item => {
                                let segmentedRows = data_setup.filter(d => item[0][date_dba] === d[0][date_dba] && d[0].segment !== 'Total');
                                segmentedRows = getFilteredData({ data: segmentedRows, columns: compRef.current.columns });
                                // debugger;
                                if (segmentedRows.length > 0) {
                                    let end = moment().format('x');
                                    let calcTotal = getTotalData(segmentedRows, 'segment');
                                    // let calcTotal = getSumOfData(segmentedRows, {capacity});
                                    // debugger;
                                    // console.log('calcTotal', calcTotal, 'calcTotalNew');
                                    // let t = transforemedData;
                                    let totalOfFiltered = [...[item[0]], ...calcTotal];
                                    totalOfFiltered.forEach((col, i) => {
                                        totalOfFiltered[i].id = item[i].id;
                                        //Adding sellrate value in total row of segmented view
                                        if (totalOfFiltered[i].hasOwnProperty('SellRate')) {
                                            let dateHotelTotal = transforemedData.find(itm => moment(itm.index.date).isSame(totalOfFiltered[0].date));
                                            let columnDetails = compRefColumns.find(col => col.id === totalOfFiltered[i].id);
                                            let identity = columnDetails ? columnDetails.mainKey : null;
                                            const total = dateHotelTotal?.total;
                                            const { primary } = total;
                                            let sdlyKey = 'sdly';
                                            let sdlyActualKey = 'sdly_actual';
                                            if (dayDate === 'date') {
                                                sdlyKey = "sdtly";
                                                sdlyActualKey = 'sdtly_actual';
                                            }
                                            if (identity === "sdly" && dayDate === 'date') {
                                                identity = "sdtly";
                                            }
                                            if (identity === "sdly_actual" && dayDate === 'date') {
                                                identity = "sdtly_actual";
                                            }
                                            if (identity && total && total[identity] && total[identity].SellRate) {
                                                totalOfFiltered[i].SellRate = total[identity].SellRate;
                                            }
                                            if (identity === "difference_sdly") {
                                                totalOfFiltered[i].SellRate = primary.SellRate - total[sdlyKey].SellRate;
                                            }
                                            if (identity === "variance_sdly" || identity === "otb_variance_sdly") {
                                                let varValue = convertToPercent(((primary.SellRate - total[sdlyKey].SellRate) / total[sdlyKey].SellRate));
                                                varValue = (varValue && isFinite(varValue)) ? varValue : 0;
                                                totalOfFiltered[i].SellRate = getCorrectValue(varValue) + "%";
                                            }
                                            if (identity === "pickup_diff_sdly" || identity === "otb_remaing_pickup_sdly") {
                                                totalOfFiltered[i].SellRate = total[sdlyActualKey].SellRate - total[sdlyKey].SellRate;
                                            }

                                            if (identity === "primary" && active_tiles[0] === 'market') {
                                                totalOfFiltered[i].SellRate = total[identity].SellRate
                                            }
                                            if (identity === "sdly_diff_sdowly" && active_tiles[0] === 'market') {
                                                totalOfFiltered[i].SellRate = item[i].SellRate
                                            }

                                        }
                                        if (active_tiles[0] === 'forecast' && ["forecast_difference", "userForecast"].includes(item[i].id)) {
                                            totalOfFiltered[i] = { ...totalOfFiltered[i], ...item[i] };
                                        }
                                    });

                                    // totalOfFiltered = getFilteredData({ data: totalOfFiltered, columns: compRef.current.columns });
                                    if (totalOfFiltered.length) {
                                        finalRows = [...finalRows, ...segmentedRows, ...[totalOfFiltered]];
                                    }
                                }
                            });

                            let finalSegmentsTotal = [];
                            if (finalRows.length) {
                                filteredSegments.forEach((item) => {
                                    let segmentTotalRow = finalRows.filter((row) => row && row[0] && row[0].segment_code === item);
                                    let dateCol = deepCopy(finalRows[0][0]);
                                    dateCol[date_dba] = ''; dateCol.dow = ''; dateCol.segment = segments.map[item] ? segments.map[item] : item; dateCol.segment_code = item;
                                    // debugger;
                                    let calcTotal = getTotalData(segmentTotalRow, 'segmentTotal');
                                    if (calcTotal.length) {
                                        segmentTotalRow = [...[dateCol], ...calcTotal];
                                        finalSegmentsTotal = [...finalSegmentsTotal, ...[segmentTotalRow]];
                                    }
                                });
                                finalSegmentsTotal.sort((a, b) => {
                                    let A = a[1] && a[1].Rooms ? a[1].Rooms : 0; let B = b[1] && b[1].Rooms ? b[1].Rooms : 0;
                                    if (B > 0 || A > 0) {
                                        return B - A;
                                    }
                                });
                                finalRows = (workspace_controls.scopeType === 'Dba' || active_tiles[0] === 'flights') ? [...finalRows] : [...finalRows, ...finalSegmentsTotal];
                            }

                            data_setup = deepCopy(finalRows);
                        } else {
                            //need to fix
                            data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls, active_tiles: ui_state.active_tiles });
                            data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
                        }
                        setDataSetup(data_setup);
                        setTimeout(() => {
                            // setDataSetup(copyRS);
                            setCompLoadStatus('loaded');
                        }, 200);

                    });

            }
        }
    }, [compLoad]);

    useEffect(() => {
        let isSubscribed = true;
        // debugger;
        // if (app_state.workspace_controls.rate_plan === 0 || app_state.workspace_controls.rate_plan === false) {
        let propDetails = getPermissions(profile.property_details, app_state.property_id);
        // let propDetails = dataService.getSeriesById("GetPropData").data;
        let rate_plan = (propDetails.permissions.rate_plan) ? propDetails.permissions.rate_plan : 1;
        let room_type = (propDetails.permissions.room_type) ? propDetails.permissions.room_type : 1;
        dispatch({
            type: UPDATE_STATE,
            payload: {
                app_state: {
                    workspace_controls: { ...app_state.workspace_controls, rate_plan, room_type }
                }
            }
        })
        // dispatch(updateAppState("toggleChartTableView", {
        //     app_state: {
        //         workspace_controls: { ...app_state.workspace_controls }
        //     }
        // }));
        // }
        if (app_state.workspace_controls.downloadExportButton === true) {
            // downloadReportToExcel(app_state, ui_state, selectedPropertyName);
            downloadReportToExcel(selectedPropertyName);

            setTimeout(() => {
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            workspace_controls: { ...app_state.workspace_controls, downloadExportButton: false, }
                        }
                    }
                })
            }, 50);
        }

        if (isSubscribed) {
            if (!ui_state.showspinner) {
                //setCompLoad(false);
                //Handle multiple data set for intellimerge
                if (compDisplayData && compDisplayData.constructor.name === 'Array' && compDisplayData.length && compDisplayData.length > 1) {
                    // check for segmentrd data 
                    let transformedData = [];
                    if (workspace_controls.scopeType === 'Dba' && compDisplayData[0].data && !compDisplayData[0].data.length) {
                        transformedData = compDisplayData[1].data;
                        // transformedData = transformSegmentData(app_state, auth, compDisplayData[1].data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments });
                    } else {
                        transformedData = compDisplayData[0].data;
                        // transformedData = transformSegmentData(app_state, auth, compDisplayData[0].data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments });
                    }
                    // let transformedData = transformSegmentData(app_state, auth, compDisplayData[0].data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments });
                    // debugger;          

                    setDisplayData(prev => {
                        return transformedData
                    });
                    setCompLoad(true);
                    // setupData();
                }
                if (compDisplayData && compDisplayData.length && compDisplayData.length === 1) {

                    let transformedData = compDisplayData[0].data;
                    // let transformedData = transformSegmentData(app_state, auth, compDisplayData[0].data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments });
                    // debugger;
                    if (active_tiles[0] === "flights") {
                        transformedData = deepCopy(transformFlightsdata(transformedData, workspace_controls));
                    }
                    setDisplayData(prev => {
                        return transformedData
                    });
                    setCompLoad(true);
                    // setupData();
                }
            }
        }
        return () => (isSubscribed = false);
    }, [
        // ui_state.showspinner, 
        // app_state.data_load,
        workspace_controls.sdlyOn,
        // workspace_controls.scopeType,
        workspace_controls.downloadExportButton,
    ]);

    const onDragStartHandler = (columParam) => {
        return (event) => {
            // event.preventDefault();
            event.dataTransfer.setData("columnMeta", JSON.stringify(columParam));
            compRef.current.itemDragged = deepCopy(columParam);
            // debugger;
        }
    };


    const mainColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            const draggedCellOrder = columnMeta.order;
            const draggedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === draggedCellOrder);
            const droppedCellOrder = columParam.order;
            const droppedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === droppedCellOrder);
            compRef.current.itemDroppedOn = columParam;
            if (droppedCellOrderIndex === 0) return

            compRef.current.columns.map((mainCol, i) => {
                //moving to left
                if (draggedCellOrder > droppedCellOrder && i <= draggedCellOrderIndex) {
                    // debugger;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i <= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order + 1;
                    }
                }
                //moving to right
                if (draggedCellOrder < droppedCellOrder && i >= draggedCellOrderIndex) {
                    // debugger;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i >= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order - 1;
                    }
                }
            });
            compRef.current.columns = processColumnsNew(compRef.current.columns);
            // setupData();
            let ts = Date.now();
            // setReloadGrid(ts);
            // setCompLoadStatus('loading');

            setReloadGrid('reOrderedMainCols');
            // setCompLoadStatus('sortingCols');
        }
    };

    const dataColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            compRef.current.itemDroppedOn = columParam;
            // debugger;
            let { itemDragged, itemDroppedOn } = compRef.current;
            if (columnMeta.parent === columParam.parent) {
                let draggedOrder = columnMeta.order;
                let droppedOrder = columParam.order;
                let mainColumn = compRef.current.columns.filter((item) => item.id === columnMeta.parent);
                compRef.current.columns.map((mainCol, i) => {
                    if (mainCol.id === columnMeta.parent) {
                        compRef.current.columns[i].subcolumns.map((col) => {
                            if (col.value === columnMeta.value) {
                                col.order = droppedOrder;
                            }
                            if (col.value === columParam.value) {
                                col.order = draggedOrder;
                            }
                        });
                    }
                });
                let columns = processColumnsNew(compRef.current.columns);
                compRef.current.columns = columns;
                // setupData();
                let ts = Date.now();
                // setCompLoadStatus('loading');
                setReloadGrid('applySorting');
            }
        }
    };

    const columnToggleHandler = (item, index, hiddenStatus) => {
        // debugger;
        let cols = compRef.current.columns;
        let items = item;
        if (item && item.constructor.name === 'Object') {
            items = [item]
        }
        let hiddenSubcols = items.filter(col => col.hidden);
        hiddenSubcols = hiddenSubcols.map(col => col.value);
        compRef.current.columns.forEach(column => {
            let subcosl = column.subcolumns;
            subcosl.forEach(subcol => {
                if (hiddenSubcols.includes(subcol.value)) {
                    subcol.hidden = true;
                } else {
                    subcol.hidden = false;
                }
            })
        });

        compLoadStatus = 'sortingCols';
        reloadGrid = 'applySorting';
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    }

    const applyFilterHandler = (item) => {
        // debugger;
        let ts = Date.now();
        compLoadStatus = 'sortingCols';
        reloadGrid = 'applySorting';
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    };

    const clearFilterHandler = (item, type) => {
        // debugger;
        if (type === 'all') {
            compRef.current.columns.map((main, i) => {
                main.subcolumns.map((sub, j) => {
                    //it depends on property sorted
                    sub.filter = null;
                });
            });
        }
        compLoadStatus = 'sortingCols';
        reloadGrid = 'applySorting';
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    };

    const onOrderByChangeHandler = (item) => {
        // debugger;
        setCompLoadStatus('sortingCols');
        let cols = compRef.current.columns;
        compRef.current.sortedColumn = item;
        compRef.current.columns.map((main) => {
            if (main.id === item.parent) {
                main.subcolumns.map((col) => {
                    if (col.value === item.value) {
                        col.sorted = item.sorted;
                    } else {
                        col.sorted = null;
                    }
                })
            } else {
                main.subcolumns.map((col) => {
                    col.sorted = null;
                });
            }

        });
        compLoadStatus = 'sortingCols';
        reloadGrid = 'applySorting';
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    }

    const setupData = async (transforemedData) => {
        // let { capacity } = transforemedData[0].total;
        let strt = moment().format('x');
        let apc = APP_CACHE;
        // debugger;
        apc['cache_keys'] = apc['cache_keys'] && apc['cache_keys'].final === 'finalized__' + cache_data_key ? apc['cache_keys'] : { general: 'generalized__' + cache_data_key, 'final': 'finalized__' + cache_data_key };

        if (apc[apc.cache_keys.final] && !['flights'].includes(active_tiles[0])) {
            // debugger;
            compRef.current.data_setup = deepCopy(apc[apc.cache_keys.final]);
            return Promise.resolve(JSON.stringify(apc[apc.cache_keys.final]));
        }

        let workers = compRef.current.processWorker; //new Worker('/workers/app.process.js');
        let finalRows = [];
        let date_dba = workspace_controls.scopeType.toLocaleLowerCase();
        if (active_tiles[0] === 'flights') {
            date_dba = 'month';
        }
        let columns = processColumnsNew(compRef.current.columns);
        console.log('time taken in processColumnsNew', moment().format('x') - strt);
        compRef.current.columns = columns;
        transforemedData = (date_dba === 'dba') ? transforemedData.slice(0, workspace_controls.dba) : transforemedData;

        let start = moment().format('x');
        let end = moment().format('x');
        //compRef.current.filteredColumns = getFilteredColumns(compRef.current.columns);
        // debugger;
        // setColumns(columns);
        let data_cols = [];
        columns.map((i) => {
            i.subcolumns.map((x) => {
                data_cols.push(x.value);
            });
        });
        compRef.current.data_cols = data_cols;
        //setDataCols(data_cols);
        const restrictionData = dataService.getSeriesById("GetRestrictions").data;
        const restrictionRoomTypeData = dataService.getSeriesById("GetRestrictionsRoomType").data;
        let columnStr = columns;
        workers.postMessage(JSON.stringify({ type: 'processdata', columnStr, transforemedData: transforemedData, auth, app_state, ui_state, segments, restrictionData, restrictionRoomTypeData }));

        // let data_setup = processDataNew(columns, transforemedData, {
        //     auth,
        //     app_state,
        //     ui_state,
        //     segments
        // });

        end = moment().format('x');
        // debugger;
        console.log('Time taken:', end - start);

        // { data, sortBy, parent, order }
        // let t = compRef.current.sortedColumn;
        start = moment().format('x');

        const onMessageHandler = (resolve, reject, options) => {
            start = moment().format('x');

            return (event) => {
                // const { capacity } = options;
                end = moment().format('x');
                console.log('Time taken in worker revert post:', end - start);

                const { response } = event.data;
                let data_setup = JSON.parse(response);


                // debugger;
                console.log('Time taken after worker:', moment().format('x') - end);
                resolve(data_setup);

                // compRef.current.processWorker = new Worker('/workers/app.process.js');
                if (compRef.current.processWorker) {
                    compRef.current.processWorker.terminate();
                    compRef.current.processWorker = null;
                }

            };
        }

        // return processDataNew(columns, transforemedData, {
        //     auth,
        //     app_state,
        //     ui_state,
        //     segments,
        //     restrictionData,
        //     restrictionRoomTypeData
        // });
        start = moment().format('x');
        return new Promise(function (resolve, reject) {
            // let processed = processDataNew(columns, transforemedData, {
            //     auth,
            //     app_state,
            //     ui_state,
            //     segments,
            //     restrictionData,
            //     restrictionRoomTypeData
            // });
            end = moment().format('x');
            // debugger;
            console.log('Time taken in processDataNew:', end - start);
            // resolve(processed);
            workers.removeEventListener('message', onMessageHandler(resolve, reject));
            workers.addEventListener('message', onMessageHandler(resolve, reject, {}));
        });

    };

    const setupData_xx = (transforemedData) => {
        let finalRows = [];
        let date_dba = workspace_controls.scopeType.toLocaleLowerCase();
        let columns = processColumnsNew(compRef.current.columns);
        compRef.current.columns = columns;
        transforemedData = (date_dba === 'dba') ? transforemedData.slice(0, workspace_controls.dba) : transforemedData;

        let start = moment().format('x');
        let end = moment().format('x');
        //compRef.current.filteredColumns = getFilteredColumns(compRef.current.columns);
        // debugger;
        // setColumns(columns);
        let data_cols = [];
        columns.map((i) => {
            i.subcolumns.map((x) => {
                data_cols.push(x.value);
            });
        });
        compRef.current.data_cols = data_cols;
        //setDataCols(data_cols);

        let data_setup = processDataNew(columns, transforemedData, {
            auth,
            app_state,
            ui_state,
            segments
        });
        end = moment().format('x');
        // debugger;
        console.log('Time taken:', end - start);

        // { data, sortBy, parent, order }
        let t = compRef.current.sortedColumn;

        let sortedColumn = getSortedColumn(compRef.current.columns);
        data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls, active_tiles: ui_state.active_tiles })

        compRef.current.data_setup = !compRef.current.data_setup && data_setup.length ? deepCopy(data_setup) : compRef.current.data_setup;


        // debugger;
        if (date_dba === 'date' && compRef.current.data_setup) {
            data_setup = compRef.current.data_setup.filter((item) => item[0][date_dba] === '' || filteredDow.includes(moment(item[0][date_dba]).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
        }


        // data_setup = getFilteredData({ data: data_setup, columns });

        if (workspace_controls.segmented_view) {
            data_setup = data_setup.filter((item) => (item[0][date_dba] === '' && filteredSegments.includes(item[0].segment_code)) || item[0].segment === 'Total' || filteredSegments.includes(item[0].segment_code) || filteredSegments.includes('all'));

            let totalsRows = data_setup.filter(item => item[0].segment === 'Total');
            let segmentedTotalsRows = data_setup.filter(item => item[0][date_dba] === '' && filteredSegments.includes(item[0].segment_code));
            // let filteredTotalRows = getFilteredData({ data: totalsRows, columns: compRef.current.columns });

            totalsRows.forEach(item => {
                let segmentedRows = data_setup.filter(d => item[0][date_dba] === d[0][date_dba] && d[0].segment !== 'Total');
                segmentedRows = getFilteredData({ data: segmentedRows, columns: compRef.current.columns });

                if (segmentedRows.length > 0) {
                    let calcTotal = getTotalData(segmentedRows, 'segment');
                    // let t = transforemedData;
                    let totalOfFiltered = [...[item[0]], ...calcTotal];
                    totalOfFiltered.forEach((col, i) => {
                        totalOfFiltered[i].id = item[i].id;

                        //Adding sellrate value in total row of segmented view
                        if (totalOfFiltered[i].hasOwnProperty('SellRate')) {
                            let dateHotelTotal = transforemedData.find(itm => moment(itm.index.date).isSame(totalOfFiltered[0].date));
                            let columnDetails = compRef.current.columns.find(col => col.id === totalOfFiltered[i].id);
                            let identity = columnDetails ? columnDetails.mainKey : null;
                            // debugger;
                            if (identity && dateHotelTotal && dateHotelTotal.HotelTotal && dateHotelTotal.HotelTotal[identity] && dateHotelTotal.HotelTotal[identity].SellRate) {
                                totalOfFiltered[i].SellRate = dateHotelTotal.HotelTotal[identity].SellRate;
                            }
                        }
                        //--

                        if (active_tiles[0] === 'forecast' && ["forecast_difference", "userForecast"].includes(item[i].id)) {
                            totalOfFiltered[i] = { ...totalOfFiltered[i], ...item[i] };
                        }
                    });
                    // debugger;

                    // totalOfFiltered = getFilteredData({ data: totalOfFiltered, columns: compRef.current.columns });
                    if (totalOfFiltered.length) {
                        finalRows = [...finalRows, ...segmentedRows, ...[totalOfFiltered]];
                    }
                }
            });
            let finalSegmentsTotal = [];
            // debugger;
            if (finalRows.length) {
                filteredSegments.forEach((item) => {
                    let segmentTotalRow = finalRows.filter((row) => row && row[0] && row[0].segment_code === item);
                    let dateCol = deepCopy(finalRows[0][0]);
                    dateCol[date_dba] = ''; dateCol.dow = ''; dateCol.segment = segments.map[item] ? segments.map[item] : item; dateCol.segment_code = item;
                    let calcTotal = getTotalData(segmentTotalRow, 'segmentTotal');
                    if (calcTotal.length) {
                        segmentTotalRow = [...[dateCol], ...calcTotal];
                        finalSegmentsTotal = [...finalSegmentsTotal, ...[segmentTotalRow]];
                    }
                });
                finalSegmentsTotal.sort((a, b) => {
                    let A = a[1] && a[1].Rooms ? a[1].Rooms : 0; let B = b[1] && b[1].Rooms ? b[1].Rooms : 0;
                    if (B > 0 || A > 0) {
                        return B - A;
                    }
                });
                finalRows = (workspace_controls.scopeType === 'Dba') ? [...finalRows] : [...finalRows, ...finalSegmentsTotal];

            }

            data_setup = deepCopy(finalRows);
        } else {

            data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls, active_tiles: ui_state.active_tiles });
            data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
        }


        setDataSetup(data_setup);
        setCompLoadStatus('loaded');
        end = moment().format('x');
        let timetaken = end - start;
        console.log('Time taken:', timetaken);
        // debugger;
    };

    const renderHeader = useCallback(() => {
        let columns = compRef.current.columns;
        let cols = [];
        let colheaders = [];
        let count = 0;
        let bckgClass = "grey";

        for (let index = 0; index < columns.length; index++) {
            if (index > 0) {
                bckgClass = "light_blue";
            }
            if (index === 0) {
                if (active_tiles[0] === "flights") {
                    if (workspace_controls.segmentType === "total") {
                        columns[index].subcolumns = columns[index].subcolumns.filter(i => {
                            if (i.value !== "segment") {
                                return i;
                            }
                        })
                    } else {
                        columns[index].subcolumns[1].display = workspace_controls.segmentType.replace(/([a-z])([A-Z])/g, '$1 $2');
                    }

                }
            }


            const col = columns[index];

            // const col_len = col["subcolumns"].length;
            let colSpan = col["subcolumns"].length;
            let hiddenCols = col["subcolumns"].filter(item => item.hidden);
            colSpan = colSpan - hiddenCols.length;
            // debugger
            let primaryHSty = {};
            if (col.type == "dateColumn") {
                primaryHSty = {
                    position: "sticky",
                    left: col.left ? col.left : 0,
                    zIndex: 5,
                };
                // colSpan = 2;
            }
            colSpan = colSpan > 1 ? colSpan : null;
            const header = (
                <PrimaryHeaderCell
                    onDrop={mainColumnOnDropHandler(col)}
                    key={index}
                    align="center"
                    colSpan={colSpan}
                    style={primaryHSty}
                    className={"primary-header-cell header_col "}
                    sx={{ fontWeight: "bold !important" }}
                    data-fill-color="606060"
                    data-f-color="ffffff"
                    data-b-r-s="thin"
                    data-a-v="middle"
                    data-b-a-c="ffffff"
                    data-a-h="center"
                    data-f-sz="16"
                >
                    {col.draggable && <div draggable={col.draggable} onDragStart={onDragStartHandler(col)} onDragOver={(e) => { e.preventDefault() }}>
                        {/* <span>{col.display}</span> */}
                        <span>
                            {primaryHeaderDisplay(col, app_state, ui_state)}
                        </span>
                        {(ui_state.active_tiles[0] === "market_sell_rates" &&
                            <div className="sellrate-info-icon">
                                <InfoButtonMarketSellRate star={col.display} />
                            </div>
                        )}

                    </div>}

                    {!col.draggable && <div>
                        {/* <span>{col.display}</span> */}
                        {primaryHeaderDisplay(col, app_state, ui_state)}

                    </div>}

                </PrimaryHeaderCell>
            );
            let subcolumns = col["subcolumns"].map(item => item.value)
            col["subcolumns"].map((item, sub_col_index) => {
                let dbaSegment = (app_state.workspace_controls.scopeType === "Dba") ? "-dba" : "";
                bckgClass = ((item.display).toLowerCase() === "comparison date") ? "header-grey" : "header-blue";
                let segmentClassWidth = ((item.display).toLowerCase() === "segment") ? "col-head-label col-head-label-segment" : "col-head-label";
                let sty = {};
                // sty["paddingBottom"] = "18px";
                sty["fontWeight"] = "bold";
                sty["background"] = "#3fa7cd";
                sty["fontSize"] = "19px !important";
                let hasFilterClass = item.filter && (item.filter.gte || item.filter.lte) ? "has-filter" : "";
                if (!hasFilterClass && !['dow', 'date', 'segment'].includes(item.type)) {
                    //  debugger; 
                    let kpiHidden = ui_state.table_filters.table_common.kpis_hidden;
                    //  ui_state.table_filters.table_common.kpis_hidden.length
                    let is_hidden_kpis_in_table = kpiHidden.some(ele => subcolumns.includes(ele))
                    if (is_hidden_kpis_in_table) {
                        hasFilterClass = "has-filter";
                    }
                }
                if (!hasFilterClass && ['dow'].includes(item.type) && ui_state.table_filters.table_common.dow.length < 7) {
                    hasFilterClass = "has-filter";
                }
                if (!hasFilterClass && ['segment'].includes(item.type)) {
                    if (ui_state.active_tiles[0] === "market") {
                        let activeSegmentMarket = ui_state.table_filters.table_common.marketSegments;
                        let segmentListMarket = getMarketSegment(ui_state.active_tiles);
                        if (activeSegmentMarket.length !== segmentListMarket.length) {
                            hasFilterClass = "has-filter";
                        }
                    }
                    else {

                        let activeSegment = ui_state.table_filters.table_common.segments;
                        let segmentList = new Set(segments.list.map(item => item.name));
                        if (activeSegment.length !== segmentList.size) {
                            hasFilterClass = "has-filter";
                        }
                    }
                }
                let headerClass = "header-col-" + item.value + dbaSegment;
                if (active_tiles[0] === 'flights' && item.value === 'segment') {
                    headerClass = 'header-col-flights-segment';
                }
                let el = (
                    <SecondaryHeaderBlueCell
                        onDrop={dataColumnOnDropHandler(item)}
                        className={"header_col  " + bckgClass + " col_len_1 " + headerClass}
                        style={sty}
                        key={count}
                        align="center"
                        data-fill-color="B2B2B2"
                        data-f-color="ffffff"
                        data-a-v="middle"
                        data-a-h="center"
                        data-f-bold="true"
                        data-f-sz="15"
                        // data-b-a-c="ffffff"
                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        sx={{ fontSize: "13px", padding: "0px !important", top: "30px !important", verticalAlign: "top" }}
                    >
                        <div className={"filter-position-r " + bckgClass} onDragOver={(e) => { e.preventDefault() }}>

                            <ColumnSorting
                                className={"column-sorting field-" + item.value}
                                colItem={item} sortable={item.sortable} sorted={item.sorted}
                                label={item.display} value={item.value}
                                onOrderByChange={onOrderByChangeHandler} />

                            <div className={segmentClassWidth} draggable={item.draggable} onDragStart={onDragStartHandler(item)}>
                                {item.display}
                            </div>
                            {item.filterMenu &&
                                <div className={"filter-main " + item.type}>
                                    {hasFilterClass && <span className={hasFilterClass}></span>}
                                    <Filter filteredColumns={compRef.current.filteredColumns} columnList={compRef.current.columns} currentColumn={item} segments={segments}
                                        columnToggleHandler={columnToggleHandler}
                                        applyFilterHandler={applyFilterHandler}
                                        clearFilterHandler={clearFilterHandler}
                                    />
                                </div>}
                        </div>
                    </SecondaryHeaderBlueCell>
                );


                if (col.type == "dateColumn") {
                    var colClass = "date_subcolumn";
                    sty["position"] = "sticky";
                    sty["zIndex"] = "5";
                    sty["textAlign"] = "center";
                    sty["paddingBottom"] = "18px";
                    sty["fontWeight"] = "bold";
                    sty["paddingLeft"] = "15px";
                    sty["paddingRight"] = "15px";
                    sty["background"] = "#b2b2b2";

                    if ((item.display).toLowerCase() === 'dba' || (item.display).toLowerCase() === 'month') {
                        sty["left"] = sub_col_index * 64;
                    }

                    if ((item.display).toLowerCase() === 'dow') {
                        // sty["left"] = "70px !important";
                        sty["width"] = "60px !important";
                    }
                    if ((item.display).toLowerCase() === 'segment' && ui_state.active_tiles[0].toLowerCase() !== "pace") {
                        sty["left"] = sub_col_index * 64;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }

                    if ((item.display).toLowerCase() === 'segment' && app_state.workspace_controls.scopeType === "Dba" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                        sty["left"] = sub_col_index * 80.5;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }
                    if ((item.display).toLowerCase() === 'segment' && app_state.workspace_controls.scopeType === "Date" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                        sty["left"] = sub_col_index * 74.5;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }
                    if (item.value === "segment" && active_tiles[0] === 'flights') {
                        sty["left"] = "94px";
                        colClass = "date_subcolumn_segment";
                    }

                    <SecondaryHeaderGreyCell
                        className={"header_col  " + bckgClass + " " + colClass}
                        style={sty}
                        key={count}
                        align="left"
                        data-fill-color="3fa7cd"
                        data-f-color="ffffff"
                        data-a-v="middle"
                        data-a-h="center"
                        data-f-bold="true"
                        data-f-sz="15"
                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        // sx={{pl:3.5,pr:13.5,pt:1}}
                        sx={{ fontSize: "13px", padding: "0px !important", top: "30px !important" }}
                    >
                        {/* <div className="filter-position-r">
                                {item.display}
                                <Filter className="filter-position-a" />
                            </div> */}
                        <div className="filter-position-r date-col" sx={{ minWidth: "80px !important" }}>
                            <div className="col-head-label">
                                {item.display}
                            </div>
                            <div className="filter-main">
                                <Filter />
                            </div>
                        </div>
                    </SecondaryHeaderGreyCell>
                }
                if (!item.hidden) {
                    cols.push(el);
                }
                count++;

            });
            if (!col.hidden) {
                colheaders.push(header);
            }

        }

        return (
            <>
                <TableRow data-height="30" className="header_row top_header">{colheaders}</TableRow>
                <TableRow data-height="30" className="header_row bottom_header">{cols}</TableRow>
            </>
        );
    });


    const renderBody = useCallback((params) => {
        // let data_setup = data_setup ? data_setup : [];
        const rows = [];
        const size = data_setup ? data_setup.length : 0;
        var colCount = 0;
        var totalArr = [];
        var rowsCount = 0;
        var columnsCounts = 0;
        if (size > 0) {
            for (let index = 0; index < size; index++) {
                var evenRow = (rowsCount % 2 === 0) ? "even-row" : "";
                let columnTotal = 0;
                const data_columns = data_setup[index];
                const cols = [];
                const reportCols = [];
                let count = 0;
                totalArr[index] = 0;
                colCount = 0;
                var totalRow = "";
                var totalCol = "";
                let rowDate;
                // debugger;
                data_columns.forEach((column, col_index) => {
                    let subCols = compRef.current.columns[col_index].subcolumns.filter(col => !col.hidden);
                    // let subCols = Object.keys(column); debugger;
                    let cDate;
                    let cellClass = "data-cell";
                    subCols.map((subcol, sub_col_index) => {
                        let key = subcol.value;
                        let val = column[key];
                        let isTotalRow = column['segment'];
                        // debugger;
                        let txtAlignClass = (app_state.workspace_controls.segmented_view && key === 'Revenue') ? "text-align-right" : "";
                        let txtAlignClassml = (key === 'ADR' || key === 'Occ') ? "text-align-left-ml" : "";
                        let txtAlignClassRight = (key === 'Rooms') ? "" : "";

                        if (key === 'segment' && val === "Total") {
                            totalRow = "total-row";
                            totalCol = "total-col";
                        }
                        let sty = {};
                        if (col_index === 0) {
                            sty = { position: "sticky", zIndex: 2, textAlign: "left" };
                            sty["textAlign"] = "center";
                            if (key === "segment" && app_state.workspace_controls.scopeType === "Date" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                                sty["left"] = sub_col_index * 74.5;
                                sty["borderRight"] = "solid 1px #ddd";
                                sty["borderCollapse"] = "collapse";
                            }
                            if (key === "segment" && app_state.workspace_controls.scopeType === "Dba" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                                sty["left"] = sub_col_index * 80.5;
                                sty["borderRight"] = "solid 1px #ddd";
                            } else if (key === "segment" && ui_state.active_tiles[0].toLowerCase() !== "pace") {
                                sty["left"] = sub_col_index * 64;
                                sty["borderRight"] = "solid 1px #ddd";
                            } else {
                                sty["left"] = sub_col_index * 83;
                                sty["left"] = sub_col_index * 83;
                                if ((!app_state.workspace_controls.segmented_view) && (key === "dow" || key === "date_market_summary" || key === "dba")) {
                                    sty["borderRight"] = "solid 1px #ddd";
                                    // sty["width"] = "solid 1px #ddd"; 
                                }
                            }
                            if (key === "segment" && active_tiles[0] === 'flights') {
                                sty["left"] = sub_col_index * 94;
                            }
                        } else {
                            if (key.toLowerCase() === "revenue") {
                                // sty["color"] = "#3583B8";
                                sty["textAlign"] = "center";
                                sty["ml"] = "5px !important";
                                sty["padingLeft"] = "5px";
                            }
                        }
                        var txtAlign = { textAlign: "center" };
                        let rowsBackColor = (rowsCount % 2 === 0) ? "ebebeb" : "ffffff";
                        // var totalRow = "1";
                        let dataType = numberFormatForReport(key);
                        let reportData;
                        // if (!val)
                        let tmpVal;

                        if (val && typeof val === "number") {
                            tmpVal = val;
                        } else if (val && typeof val !== "object") {
                            tmpVal = val.replaceAll("%", "");
                        } else if (val && typeof val === "object") {
                            let resVal = (val[0]) ? Object.keys(val[0]).join(",") : "";
                            // let resValDetails = getResName(resVal,val[0]['max_los']);
                            tmpVal = resVal;
                        }
                        val = (val) ? val : 0;
                        if (key === 'date') {
                            reportData = (val && val !== 0) ? moment(val).format('YYYY/MM/DD') : '';
                            val = (val && val !== 0) ? moment(val).format('YYYY/MM/DD') : '';
                            rowDate = reportData;
                        } else if (key === 'Revenue' || key === 'ADR' || key === 'Occ' || key === 'RevPAR') {
                            tmpVal = (tmpVal && isFinite(tmpVal) && !isNaN(tmpVal)) ? tmpVal : parseFloat(0.00);
                            reportData = tmpVal;//(Number.isFinite(tmpVal)) ? parseFloat(tmpVal).toFixed(2) : parseFloat(0.00).toFixed(2);
                        } else {
                            reportData = (tmpVal);
                        }
                        let brdRight;
                        let dowStyle
                        if (key === "dow") {
                            dowStyle = "dow-style";
                            val = (val && val !== 0) ? val : '';
                        }
                        if (key === "compdate") {
                            reportData = moment(val).format('MMM DD YYYY,  ddd');
                            cDate = moment(val).format('MMM DD YYYY,  ddd');;
                            dowStyle = "dow-style-comp-date";
                        }
                        if (key === "segment" && app_state.workspace_controls.scopeType !== "Dba") {
                            dowStyle = "segment-td-style";
                        }
                        if (key === "segment" && app_state.workspace_controls.scopeType === "Dba") {
                            dowStyle = "segment-td-style-dba";
                        }
                        reportCols.push(
                            <TableCell
                                data-t={dataType.type}
                                // data-num-fmt={dataType.numfmt}
                                data-fill-color={rowsBackColor}
                                data-b-r-s="thin"
                                data-b-a-c="ffffff"
                                data-f-sz="12"
                                data-a-h="center"
                                key={count} className={cellClass + " " + totalCol}
                                style={sty}>
                                {
                                    (key === "Restrictions") ? getRestrictionAll(rowDate, 'comma', 'cur')
                                        : reportData
                                }
                            </TableCell>
                        );

                        cols.push(
                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="thin" data-b-a-c="ffffff"
                                data-t="s" data-exclude="true" data-f-sz="13" data-a-h="center" key={count}
                                className={dowStyle + " " + brdRight + " " + cellClass + " " + totalCol + " " + txtAlignClass + " " + txtAlignClassml + " " + txtAlignClassRight}
                                style={sty}>
                                {
                                    (key === "Restrictions") ?
                                        <Restrictions currentRestrictions={val} stay_date={rowDate} />
                                        :
                                        (subcol.type === "percent" || (key !== "compdate" && key !== "date" && key !== "dow"
                                            && key !== "segment" && key != "month" && key != "date_market_summary" &&
                                            key != "dba" && typeof val === "string")) ?
                                            showPercentValues(val)
                                            :
                                            <DisplayData displayValue={displayDataFormat(val, key, "")} dataKey={key} stay_date={rowDate} compdate={cDate} isTotalRow={isTotalRow} />

                                    // displayDataFormat(val, key, "", subcol.type)
                                }
                            </TableCell>
                        );
                        colCount++;

                        if (typeof val === 'string') {
                            //totalArr[count] = parseFloat(totalArr[count] + 0);
                        } else {
                            if (count === 4) {
                                if (totalArr[count] === 'undefind') {
                                    totalArr[count] = 0;
                                }
                            }
                            totalArr[count] += val;// parseFloat(totalArr[count] + val);
                        }
                        count += 1;
                    });
                    columnsCounts++;
                });
                rows.push(<>{cols}</>);
                // rows.push(<StyledTableRow data-exclude="true" data-height="20" className={(totalRow) ? totalRow : evenRow + " table-row"}>{cols}</StyledTableRow>);
                // rows.push(<StyledTableRow data-height="20" sx={{ display: "none" }}>{reportCols}</StyledTableRow>);

                rowsCount++;
            }
        } else {
            rows.push(<StyledTableRow className="">
                <TableCell colSpan={5}
                    sx={{ height: "auto", borderRight: "0px solid #ddd", background: "#fff", textAlign: "center", borderBottom: "0px solid" }}>
                    <Spinner />
                </TableCell></StyledTableRow>);
        }

        if (rowsCount <= 12) {
            let h = 30 * (12 - rowsCount) + 'px';
            // debugger;
            rows.push(
                <><TableCell
                    sx={{ height: "300px !important", borderRight: "0px solid #ddd", background: "#fff", borderBottom: "0px solid" }}>
                    <div style={{ height: h }}></div>
                </TableCell></>);
        }

        let footerCols = [];
        let totalResultSet = [];
        var colSpanTotal = 2;
        let columns = processColumnsNew(compRef.current.columns);
        // if (data_setup.length > 0) {
        //     columns = data_setup[0];
        // }
        let count = 0;
        let marketOtbTotal = {};
        let marketSdlyTotal = {};
        let marketSdlyActualTotal = {};
        let hotelSummaryOtbTotal = {};
        let hotelSummarySdlyOtbTotal = {};
        let hotelSummaryTotalForecast = {};
        let hotelSummarySdlyActualTotal = {};
        let marketSummaryOtbTotal = {};
        let marketSummarySdlyOtbTotal = {};
        let marketSummaryTotalForecast = {};
        let marketSummarySdlyActualTotal = {};
        let otbCurrentTotal = {};
        let otbSdlyTotal = {};

        let totalData;
        if (workspace_controls.segmented_view) {
            let onlyTotalRows = data_setup.filter((item) => item[0].date && item[0].segment === 'Total');
            onlyTotalRows = deepCopy(onlyTotalRows);
            // onlyTotalRows.forEach((item) => delete item[0].segment);
            totalData = getTotalData(onlyTotalRows);
        } else {
            totalData = getTotalData(data_setup);
        }

        columns.map((column, col_index) => {
            // let subCols = Object.keys(column);
            let subCols = compRef.current.columns[col_index].subcolumns.filter(col => !col.hidden);
            let frontColumn = Object.keys(column).length;
            // debugger;  
            if (col_index === 0) {
                footerCols.push(
                    <TableCell
                        colSpan={subCols.length}
                        className="data-cell"

                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        data-a-h="center"
                        data-f-sz="15"
                        data-fill-color="b1e2f4"
                        data-f-color="000000"
                        data-f-bold="true"

                        key={Math.random()}
                        style={{
                            borderRight: "1px solid #ddd",
                            left: 0,
                            position: "sticky",
                            fontWeight: "bold",
                            zIndex: 5,
                            borderBottomLeftRadius: "6px",
                            textTransform: "capitalize",
                            fontSize: 14
                        }}
                    >
                        Total
                    </TableCell>
                );
            } else {
                if (ui_state.active_tiles[0].toLowerCase() === 'compset') {
                    subCols.map((key, sub_col_index) => {
                        var finalTotal;
                        let sty = {};
                        sty["background"] = "#606060 !important";
                        sty = { fontWeight: "bold" };
                        if (key.value.toLowerCase() === "revenue") {
                            sty["color"] = "#3583B8";
                        } else {
                            sty["color"] = "#fff !important";
                        }
                        footerCols.push(
                            <TableCell style={sty} className="data-cell"
                                data-b-r-s="thin"
                                data-b-a-c="ffffff"
                                data-a-h="center"
                                data-f-sz="14"
                                data-fill-color="b1e2f4"
                                data-f-color="000000"
                                data-f-bold="true"

                                key={Math.random()}>
                                {compsetTotal(data_setup, key.value, sub_col_index, count)}
                                {/* {
                    // (ui_state.active_tiles[0].toLowerCase() !== 'compset') ?
                    //   totalCalulate(data_setup, key, sub_col_index, count)
                    //   : compsetTotal(data_setup, key, sub_col_index, count)
                    } */}
                            </TableCell>
                        );
                        totalResultSet.push(compsetTotal(data_setup, key.value, sub_col_index, count));

                    });
                }
                ///// for Market Sell rate Total row ////
                if (ui_state.active_tiles[0].toLowerCase() === 'market_sell_rates') {
                    subCols.map((key, sub_col_index) => {
                        var finalTotal;
                        let sty = {};
                        sty["background"] = "#606060 !important";
                        sty = { fontWeight: "bold" };
                        sty["color"] = "#fff !important";

                        footerCols.push(
                            <TableCell style={sty} data-b-r-s="thin"
                                data-b-a-c="ffffff"
                                data-a-h="center"
                                data-f-sz="14"
                                data-fill-color="b1e2f4"
                                data-f-color="000000"
                                data-f-bold="true" className="data-cell" key={Math.random()}>
                                {marketSellRatesTotal(data_setup, key, sub_col_index, count, ui_state)}

                            </TableCell>
                        );
                        totalResultSet.push(marketSellRatesTotal(data_setup, key, sub_col_index, count, ui_state));

                    });
                }

                if (ui_state.active_tiles[0].toLowerCase() !== 'compset' && ui_state.active_tiles[0].toLowerCase() !== 'market_sell_rates') {


                    let sty = {};
                    let dt1 = moment(app_state.primary_stay_range.date_from);
                    let dt2 = moment(app_state.primary_stay_range.date_until);
                    let daysCount = dt2.diff(dt1, 'days') + 1;

                    let system_settings = getAppData(auth, app_state);
                    // let totalCapacity = system_settings.inventory_settings.current.total_capacity;
                    let totalCapacity = system_settings.property_current_total_capacity;

                    let marketCapacity = (dataService.getSeriesById("MarketCapacity").data) ? dataService.getSeriesById("MarketCapacity").data.data : [];
                    let totalMarketCapacity = (marketCapacity && marketCapacity.length > 0) ? marketCapacity[marketCapacity.length - 1]['Capacity'] : 0;


                    let i = totalData[col_index - 1];
                    if (i) {
                        subCols.map((col) => {
                            if (col.parent === "market_otb_pickup" && (col.display === "Occ" || col.display === "RevPAR")) {
                                let pickupDaysCt = app_state.workspace_controls.pickUpDays;
                                let asOfDate = app_state.as_of_date;
                                let pickupDate = moment(asOfDate).subtract(pickupDaysCt, "days");
                                i[col.value] = (col.value === "Occ") ? convertToPercent(i['Rooms'] / (totalMarketCapacity * daysCount)) : i['Revenue'] / (totalMarketCapacity * daysCount);
                            }
                            if ((col.parent === "pickup_difference") && (col.display === "Occ" || col.display === "RevPAR")) {
                                let pickupDaysCt = app_state.workspace_controls.pickUpDays;
                                let asOfDate = app_state.as_of_date;
                                let pickupDate = moment(asOfDate).subtract(pickupDaysCt, "days");
                                // i[col.value] = (col.value === "Occ") ? convertToPercent(i['Rooms'] / (totalCapacity * daysCount)) : i['Revenue'] / (totalCapacity * daysCount);
                                i[col.value] = (col.value === "Occ") ? convertToPercent(divide(i['Rooms'], (totalCapacity * daysCount))) : divide(i['Revenue'], (totalCapacity * daysCount));

                            }
                            if ((col.parent === "pickup_sdow_ly") && (col.display === "Occ" || col.display === "RevPAR")) {
                                let pickupDaysCt = app_state.workspace_controls.pickUpDays;
                                let asOfDate = app_state.as_of_date;
                                let pickupDate = moment(asOfDate).subtract(pickupDaysCt, "days");
                                i[col.value] = (col.value === "Occ") ? convertToPercent(i['Rooms'] / (totalCapacity * daysCount)) : i['Revenue'] / (totalCapacity * daysCount);
                            }

                            if (ui_state.active_tiles[0].toLowerCase() === 'market_summary') {
                                if (col.parent === "market_summary_otb") {
                                    marketSummaryOtbTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "market_summary_sdly_otb") {
                                    marketSummarySdlyOtbTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "market_summary_variance_percent") {
                                    i[col.value] = convertToPercent((marketSummaryOtbTotal[col.value] - marketSummarySdlyOtbTotal[col.value]) / marketSummarySdlyOtbTotal[col.value]) + "%";
                                }

                                if (col.parent === "market_summary_total_forcast") {
                                    marketSummaryTotalForecast[col.value] = i[col.value];
                                }
                                if (col.parent === "market_summary_sdly_actuals") {
                                    marketSummarySdlyActualTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "market_summary_sdly_actuals_variance_percent") {
                                    if (col.value === "Occ") {
                                    }
                                    i[col.value] = convertToPercent((marketSummaryTotalForecast[col.value] - marketSummarySdlyActualTotal[col.value]) / marketSummarySdlyActualTotal[col.value]) + "%";
                                }
                            }

                            if (ui_state.active_tiles[0].toLowerCase() === 'hotel_summary') {
                                if (col.parent === "hotel_summary_otb") {
                                    hotelSummaryOtbTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "hotel_summary_sdly_otb") {
                                    hotelSummarySdlyOtbTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "hotel_summary_variance_percent") {
                                    // i[col.value] = convertToPercent((hotelSummaryOtbTotal[col.value] - hotelSummarySdlyOtbTotal[col.value]) / hotelSummarySdlyOtbTotal[col.value]) + "%";
                                    i[col.value] = convertToPercent(divide((hotelSummaryOtbTotal[col.value] - hotelSummarySdlyOtbTotal[col.value]), hotelSummarySdlyOtbTotal[col.value])) + "%";

                                }
                                if (col.parent === "hotel_summary_total_forcast") {
                                    hotelSummaryTotalForecast[col.value] = i[col.value];
                                }
                                if (col.parent === "hotel_summary_sdly_actuals") {
                                    hotelSummarySdlyActualTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "hotel_summary_sdly_actuals_variance_percent") {
                                    // i[col.value] = convertToPercent((hotelSummaryTotalForecast[col.value] - hotelSummarySdlyActualTotal[col.value]) / hotelSummarySdlyActualTotal[col.value]) + "%";
                                    i[col.value] = convertToPercent(divide((hotelSummaryTotalForecast[col.value] - hotelSummarySdlyActualTotal[col.value]), hotelSummarySdlyActualTotal[col.value])) + "%";

                                }
                            }

                            if (ui_state.active_tiles[0].toLowerCase() === 'market') {
                                if (col.parent === "market_otb") {
                                    marketOtbTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "market_sdly") {
                                    marketSdlyTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "market_actual_sdly") {
                                    marketSdlyActualTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "market_variance_sdly") {
                                    i[col.value] = convertToPercent((marketOtbTotal[col.value] - marketSdlyTotal[col.value]) / marketSdlyTotal[col.value]) + "%";
                                }
                                if (col.parent === "sdly_var_market_last_year") {
                                    i[col.value] = convertToPercent((marketSdlyTotal[col.value] - marketSdlyActualTotal[col.value]) / marketSdlyActualTotal[col.value]) + "%";
                                }
                            }

                            if (ui_state.active_tiles[0].toLowerCase() === 'otb') {

                                if (col.parent === "otb_current") {
                                    otbCurrentTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "otb_sdly") {
                                    otbSdlyTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "otb_variance_sdly") {
                                    i[col.value] = convertToPercent(divide(otbCurrentTotal[col.value] - otbSdlyTotal[col.value], otbSdlyTotal[col.value])) + "%";

                                }
                            }
                            let occValue = (ui_state.active_tiles[0].toLowerCase() === 'pace') ? i.Occ / app_state.workspace_controls.dba : i.Occ;
                            let revParValue = (ui_state.active_tiles[0].toLowerCase() === 'pace') ? i.RevPAR / app_state.workspace_controls.dba : i.RevPAR;
                            revParValue = ((typeof revParValue).toLowerCase() === "string") ? revParValue : displayDataFormat(revParValue, "RevPAR", "");
                            let adrValue = ((typeof i.ADR).toLowerCase() === "string") ? i.ADR : displayDataFormat(i.ADR, "ADR", "");
                            footerCols.push(
                                <TableCell style={sty} className="data-cell" data-b-r-s="thin"
                                    data-b-a-c="ffffff"
                                    data-a-h="center"
                                    data-f-sz="14"
                                    data-fill-color="b1e2f4"
                                    data-f-color="000000"
                                    data-f-bold="true" key={Math.random()}>
                                    {(col.value !== "date" && col.value !== 'dow' && col.value !== 'compdate' && col.value !== 'Restrictions') ?
                                        displayDataFormat(i[col.value], col.value, "")
                                        // i[col.value]
                                        : <></>
                                    }

                                </TableCell>
                            )
                            totalResultSet.push(displayDataFormat(i[col.value], col.value, ""));
                            // if(totalRowsData.length === 0){
                            //     totalRowsData.push(i[col.value]);
                            // }
                        })
                    }
                    // });
                }

            }
            count++;
        });

        // console.log("totalRowsDatatotalRowsDatatotalRowsDatatotalRowsData ",totalRowsData);
        setTotalRowsData(totalResultSet);
        rows.push(
            // <StyledTableRow
            //     sx={{
            //         position: "sticky",
            //         bottom: 0,
            //         zIndex: 5,
            //         backgroundColor: "#fff",
            //     }}
            //     className="table-row footer-row"
            // >
            <>{
                ((in_array(ui_state.active_tiles, ["pace", "market", "compset"]) && app_state.workspace_controls.scopeType === "Dba") || in_array(ui_state.active_tiles, ["flights"])) ?
                    <></> :
                    footerCols

            }

            </>
            // </StyledTableRow>
        );

        return rows;
        //full table body is array of rows
        return <TableBody className="table-body">{rows}</TableBody>;
    });

    /////
    const getTotalData = useCallback((dataSet, type = 'Total') => {
        // debugger;
        // if(type === 'segmentTotal') debugger;
        let size = dataSet.length;
        let firstDataSize = size ? dataSet[0].length : 0;
        // var date1 = moment(app_state.primary_stay_range.date_from);
        // var date2 = moment(app_state.primary_stay_range.date_until);
        var daysCount = (type === 'segment') ? 1 : dataSet.length; //date2.diff(date1, 'days') + 1;
        let dataArray = [];
        if (active_tiles[0] === 'flights' && dataSet.length > 0) {
            dataSet[0].forEach(dItem => {
                if (dItem.id !== 'staydates') {
                    dataArray.push({
                        ...dItem
                    })
                }
            })
            dataSet.forEach((dataItem, index) => {
                if (index > 0) {
                    let arrivalsVarianceIndex = -1;
                    let stayoversVarianceIndex = -1;
                    let arrivalsDifferenceIndex = -1;
                    let stayoversDifferenceIndex = -1;
                    let arrivalsSdlyIndex = -1;
                    let stayoversSdlyIndex = -1;
                    dataItem.forEach((item, itemIndex) => {
                        if (item.id !== 'staydates') {
                            let dIndex = dataArray.findIndex(i => i.id === item.id);
                            Object.keys(item).forEach(key => {

                                if (item[key] === "arrivals_variance") {
                                    arrivalsVarianceIndex = itemIndex - 1;
                                }
                                if (item[key] === "stayovers_variance") {
                                    stayoversVarianceIndex = itemIndex - 1;
                                }
                                if (item[key] === "arrivals_difference") {
                                    arrivalsDifferenceIndex = itemIndex - 1;
                                }
                                if (item[key] === "stayovers_difference") {
                                    stayoversDifferenceIndex = itemIndex - 1;
                                }
                                if (item[key] === "arrivals_sdly") {
                                    arrivalsSdlyIndex = itemIndex - 1;
                                }
                                if (item[key] === "stayovers_sdly") {
                                    stayoversSdlyIndex = itemIndex - 1;
                                }

                                if (key !== 'id') {
                                    dataArray[dIndex][key] += item[key];
                                }

                                if ((dataItem.length - 1) === itemIndex) {
                                    dataArray[arrivalsVarianceIndex].total_flights = ((dataArray[arrivalsDifferenceIndex].total_flights * 100) / dataArray[arrivalsSdlyIndex].total_flights);
                                    dataArray[arrivalsVarianceIndex].international = ((dataArray[arrivalsDifferenceIndex].international * 100) / dataArray[arrivalsSdlyIndex].international);
                                    dataArray[arrivalsVarianceIndex].domestic = ((dataArray[arrivalsDifferenceIndex].domestic * 100) / dataArray[arrivalsSdlyIndex].domestic);
                                    dataArray[stayoversVarianceIndex].total_flights = ((dataArray[stayoversDifferenceIndex].total_flights * 100) / dataArray[stayoversSdlyIndex].total_flights);
                                    dataArray[stayoversVarianceIndex].per_day = ((dataArray[stayoversDifferenceIndex].per_day * 100) / dataArray[stayoversSdlyIndex].per_day);
                                }
                            })
                        }
                    })
                }
            })
            return dataArray;
        }
        if (size > 0) {
            // let keysArray = Object.keys(dataSet[0][1]);
            let dataObj = {}
            for (let j = 1; j < firstDataSize; j++) {
                let roomsTotal = 0;
                let occ = 0;
                let revPar = 0;
                let revenue = 0;
                let sellRate = 0;
                let total_sellRate;
                var total_sellRate_count = 0;
                let lenforSegment = 1;

                /// for variance 
                let varRoomsTotal = 0;
                let varRoomsSdlyTotal = 0;
                let varOccTotal = 0;
                let varOccSdlyTotal = 0;
                let varRevParTotal = 0;
                let varRevParSdlyTotal = 0;
                let varRevenueTotal = 0;
                let varRevenueSdlyTotal = 0;
                let varAdrTotal = 0;
                let varAdrSdlyTotal = 0;

                for (let i = 0; i < size; i++) {
                    let items = dataSet[i];
                    const { segment, date } = items[0];
                    const { Occ, RevPAR, Revenue, Rooms, id, SellRate } = items[j];
                    if (segmented_view) {
                        if (type === 'Total' && items[0] && segment === "Total" && date !== '') {
                            if (items[j] && Object.keys(items[j]).length) {
                                let total_sellRate = (SellRate !== '') ? SellRate : 0;
                                if (SellRate !== '') {
                                    total_sellRate = SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                let market_sdly_index;
                                let primary_index = 1;
                                if (id === "market_variance_sdly") {
                                    market_sdly_index = items.findIndex((it) => it.id === "market_sdly");
                                } else {
                                    market_sdly_index = 2;
                                }
                                if (id === "sdly_var_market_last_year") {
                                    primary_index = items.findIndex((it) => it.id === "market_sdly");
                                    market_sdly_index = items.findIndex((it) => it.id === "market_actual_sdly");
                                }
                                if (typeof items[j].Rooms === "string" || id === "sdly_var_market_last_year" || items[j].id === "market_variance_sdly") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[primary_index].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[market_sdly_index].Rooms);

                                    varRevParTotal += parseFloat(items[primary_index].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[market_sdly_index].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[primary_index].Occ);
                                    varOccSdlyTotal += (items[market_sdly_index].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[primary_index].Revenue);
                                    varRevenueSdlyTotal += (items[market_sdly_index].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);

                                    if (id === "sdly_var_market_last_year") {
                                        roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsTotal) + "%";
                                        occ = convertToPercent(((occTmp - occSdlyTmp) / occTmp)) + "%";
                                        revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParTmp) + "%";
                                        revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueTmp)) + "%";
                                        adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrTotal)) + "%";
                                    } else {
                                        roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                        occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                        revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                        revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                        adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                    }

                                } else {
                                    // console.log(getCorrectValue(items[j].Occ), " test ", items[j].Occ);
                                    roomsTotal += (items[j].Rooms);
                                    occ += getCorrectValue(items[j].Occ);
                                    revPar += getCorrectValue(items[j].RevPAR);
                                    revenue += getCorrectValue(items[j].Revenue);
                                    sellRate += getCorrectValue(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id

                                }

                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                        if (type === 'segment' && items[0] && segment !== "Total" && date !== '') {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (SellRate) ? SellRate : 0;
                                if (SellRate) {
                                    total_sellRate = SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                let market_sdly_index;
                                let sdly_actual_index;
                                let primary_index = 1;
                                if (id === "market_variance_sdly") {
                                    market_sdly_index = items.findIndex((it) => it.id === "market_sdly");
                                } else {
                                    market_sdly_index = 2;
                                }
                                if (id === "sdly_var_market_last_year") {
                                    primary_index = items.findIndex((it) => it.id === "market_sdly");
                                    market_sdly_index = items.findIndex((it) => it.id === "market_actual_sdly");
                                }
                                if (typeof Rooms === "string" || id === "sdly_var_market_last_year" || id === "market_variance_sdly") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[primary_index].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[market_sdly_index].Rooms);

                                    varRevParTotal += parseFloat(items[primary_index].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[market_sdly_index].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[primary_index].Occ);
                                    varOccSdlyTotal += (items[market_sdly_index].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[primary_index].Revenue);
                                    varRevenueSdlyTotal += (items[market_sdly_index].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);

                                    if (id === "sdly_var_market_last_year") {
                                        roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsTotal) + "%";
                                        occ = convertToPercent(((occTmp - occSdlyTmp) / occTmp)) + "%";
                                        revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParTmp) + "%";
                                        revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueTmp)) + "%";
                                        adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrTotal)) + "%";
                                    } else {
                                        roomsTotal = (isFinite((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal)) ? convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%" : 0 + "%";
                                        occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                        revPar = isFinite((revParTmp - revParSdlyTmp) / revParSdlyTmp) ? convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%" : 0 + "%";
                                        revenue = isFinite((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp) ? convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%" : 0 + "%";
                                        adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                    }

                                } else {
                                    roomsTotal += (Rooms);
                                    occ += getCorrectValue(Occ);
                                    revPar += getCorrectValue(RevPAR);
                                    revenue += getCorrectValue(Revenue);
                                    sellRate += getCorrectValue(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if (id && id === 'comparison_pace') {
                                    dataObj.compdate = ''
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                        if (type === 'segmentTotal' && items[0] && segment !== "Total") {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (SellRate !== '') ? SellRate : 0;
                                if (SellRate !== '') {
                                    total_sellRate = SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let compDate = '';
                                let adrTotal;
                                let primaryValtotal
                                let market_sdly_index;
                                let primary_index = 1;
                                if (id === "market_variance_sdly") {
                                    market_sdly_index = items.findIndex((it) => it.id === "market_sdly");
                                } else {
                                    market_sdly_index = 2;
                                }
                                if (id === "sdly_var_market_last_year") {
                                    primary_index = items.findIndex((it) => it.id === "market_sdly");
                                    market_sdly_index = items.findIndex((it) => it.id === "market_actual_sdly");
                                }
                                let occVal;
                                if (typeof Rooms === "string" || id === "sdly_var_market_last_year" || id === "market_variance_sdly") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[primary_index].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[market_sdly_index].Rooms);

                                    varRevParTotal += parseFloat(items[primary_index].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[market_sdly_index].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[primary_index].Occ);
                                    varOccSdlyTotal += (items[market_sdly_index].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[primary_index].Revenue);
                                    varRevenueSdlyTotal += (items[market_sdly_index].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);

                                    if (id === "sdly_var_market_last_year") {
                                        roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsTotal) + "%";
                                        occ = convertToPercent(((occTmp - occSdlyTmp) / occTmp)) + "%";
                                        revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParTmp) + "%";
                                        revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueTmp)) + "%";
                                        adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrTotal)) + "%";

                                    } else {
                                        roomsTotal = (isFinite(convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal))) ? convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + '%' : + "0 %";
                                        occ = (isFinite(convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)))) ? convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%" : 0 + "%";
                                        revPar = (isFinite(convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp))) ? convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%" : 0 + "%";
                                        revenue = (isFinite(convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)))) ? convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%" : 0 + "%";
                                        adrTotal = (isFinite(convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)))) ? convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%" : 0 + "%";
                                    }
                                    if (id === 'otb_variance_sdly') {
                                        sellRate = (sellRate === 0) ? "0%" : sellRate;
                                    }
                                    occVal = occ;
                                } else {
                                    roomsTotal += (Rooms);
                                    occ += parseFloat(Occ);
                                    revPar += parseFloat(RevPAR);
                                    revenue += parseFloat(Revenue);
                                    sellRate += parseFloat(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                    // compDate = (items[j].id && items[j].id === 'comparison_pace') ? "yes" : '';
                                    occVal = (occ / dataSet.length) ? occ / dataSet.length : 0;
                                }
                                // console.log(occ," here  ",dataSet.length);
                                // let occVal = (occ / dataSet.length) ? occ / dataSet.length : 0;
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occVal,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": id
                                }
                                if (id && id === 'comparison_pace') {
                                    dataObj.compdate = ''
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                    } else {
                        if (items[0] && segment !== "Total" && date !== '' && items[0].dba !== '') {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                let market_sdly_index;
                                let primary_index = 1;
                                if (items[j].id === "market_variance_sdly") {
                                    market_sdly_index = items.findIndex((it) => it.id === "market_sdly");
                                } else {
                                    market_sdly_index = 2;
                                }
                                if (items[j].id === "sdly_var_market_last_year") {
                                    primary_index = items.findIndex((it) => it.id === "market_sdly");
                                    market_sdly_index = items.findIndex((it) => it.id === "market_actual_sdly");
                                }

                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[primary_index].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[market_sdly_index].Rooms);

                                    varRevParTotal += parseFloat(items[primary_index].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[market_sdly_index].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[primary_index].Occ);
                                    varOccSdlyTotal += (items[market_sdly_index].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[primary_index].Revenue);
                                    varRevenueSdlyTotal += (items[market_sdly_index].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += items[j].Rooms ? (items[j].Rooms) : 0;
                                    occ += (items[j].Occ) ? parseFloat(items[j].Occ) : 0;
                                    revPar += (items[j].RevPAR) ? parseFloat(items[j].RevPAR) : 0;
                                    revenue += (items[j].Revenue) ? (parseFloat(items[j].Revenue)) : 0;
                                    sellRate += (total_sellRate) ? parseFloat(total_sellRate) : 0;
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,//(revenue / roomsTotal)
                                    "id": items[j].id
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                    }
                }
                // let sellRateValue = (ui_state.active_tiles[0].toLowerCase() === 'market') ? 0 : '';
                let sRate = round(dataObj.SellRate / total_sellRate_count);
                if (sRate <= 0 && ui_state.active_tiles[0].toLowerCase() === 'market' && app_state.workspace_controls.scopeType === 'Dba') {
                    // sRate = "";
                }
                let adrTotal = dataObj.ADR;
                if (ui_state.active_tiles[0] === "market_summary" || ui_state.active_tiles[0] === "hotel_summary") {
                    daysCount = dataSet.length;
                    sRate = round(dataObj.SellRate / daysCount);
                }

                let occTotal = type === 'Total' ? dataObj.Occ / daysCount : dataObj.Occ;
                if (typeof dataObj.Occ !== "string" && typeof dataObj.RevPAR !== "string") {
                    var newValues = {
                        Occ: occTotal,
                        RevPAR: round(dataObj.RevPAR / daysCount),
                        SellRate: sRate,
                        Revenue: round(dataObj.Revenue),
                        ADR: round(dataObj.ADR)
                    }
                    dataObj = { ...dataObj, ...newValues };
                }
                dataArray.push(dataObj);
            }

            let diffRpickupIndex = dataArray.findIndex((it) => it.id === "otb_remaing_pickup_sdly_x");
            if (diffRpickupIndex >= 0) {
                let currentIndex = dataArray.findIndex((it) => it.id === "otb_actual_sdly");
                let sdlyIndex = dataArray.findIndex((it) => it.id === "otb_sdly");
                dataArray[diffRpickupIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
            }

            let diffIndex = dataArray.findIndex((it) => it.id === "otb_diff_sdly");
            if (diffIndex >= 0) {
                let currentIndex = dataArray.findIndex((it) => it.id === "otb_current");
                let sdlyIndex = dataArray.findIndex((it) => it.id === "otb_sdly");
                dataArray[diffIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
            }

            let marketDiffIndex = dataArray.findIndex((it) => it.id === "diff_market_sdly");
            if (marketDiffIndex >= 0) {
                let marketPrimaryIndex = dataArray.findIndex((it) => it.id === "market_otb");
                let marketSdlyIndex = dataArray.findIndex((it) => it.id === "market_sdly");
                dataArray[marketDiffIndex].ADR = dataArray[marketPrimaryIndex].ADR - dataArray[marketSdlyIndex].ADR;
            }

            let sdlyDiffIndex = dataArray.findIndex((it) => it.id === "sdly_diff_last_year");
            if (sdlyDiffIndex >= 0) {
                let marketSdlyPrimaryIndex = dataArray.findIndex((it) => it.id === "market_sdly");
                let marketSdlyActualIndex = dataArray.findIndex((it) => it.id === "market_actual_sdly");
                dataArray[sdlyDiffIndex].ADR = dataArray[marketSdlyPrimaryIndex].ADR - dataArray[marketSdlyActualIndex].ADR;
            }

            let hotelSummaryDiffIndex = dataArray.findIndex((it) => it.id === "hotel_summary_sdly_variance");
            if (hotelSummaryDiffIndex >= 0) {
                let from = dataArray.findIndex((it) => it.id === "hotel_summary_otb");
                let to = dataArray.findIndex((it) => it.id === "hotel_summary_sdly_otb");
                dataArray[hotelSummaryDiffIndex].ADR = dataArray[from].ADR - dataArray[to].ADR;
            }
            let marketSummaryDiffIndex = dataArray.findIndex((it) => it.id === "market_summary_sdly_variance");
            if (marketSummaryDiffIndex >= 0) {
                let from = dataArray.findIndex((it) => it.id === "market_summary_otb");
                let to = dataArray.findIndex((it) => it.id === "market_summary_sdly_otb");
                dataArray[marketSummaryDiffIndex].ADR = dataArray[from].ADR - dataArray[to].ADR;
            }


            let marketOtbSdlyVariance = dataArray.findIndex((it) => it.id === "market_variance_sdly");
            if (marketOtbSdlyVariance >= 0) {
                dataArray[marketOtbSdlyVariance].SellRate = '0.00%';
            }
            let marketSdlyVar = dataArray.findIndex((it) => it.id === "sdly_var_market_last_year");

            if (marketSdlyVar >= 0) {
                let sdly_diff_last_year = dataArray.findIndex((it) => it.id === "sdly_diff_last_year");
                let market_actual_sdly = dataArray.findIndex((it) => it.id === "market_actual_sdly");
                dataArray[marketSdlyVar].Occ = ((dataArray[sdly_diff_last_year].Occ / dataArray[market_actual_sdly].Occ) * 100).toFixed(2);
                dataArray[marketSdlyVar].Rooms = ((dataArray[sdly_diff_last_year].Rooms / dataArray[market_actual_sdly].Rooms) * 100).toFixed(2);
                dataArray[marketSdlyVar].ADR = ((dataArray[sdly_diff_last_year].ADR / dataArray[market_actual_sdly].ADR) * 100).toFixed(2);
                dataArray[marketSdlyVar].RevPAR = ((dataArray[sdly_diff_last_year].RevPAR / dataArray[market_actual_sdly].RevPAR) * 100).toFixed(2);
                dataArray[marketSdlyVar].Revenue = ((dataArray[sdly_diff_last_year].Revenue / dataArray[market_actual_sdly].Revenue) * 100).toFixed(2);
                dataArray[marketSdlyVar].SellRate = ((dataArray[sdly_diff_last_year].SellRate / dataArray[market_actual_sdly].SellRate) * 100).toFixed(2);
                // dataArray[marketDiffIndex].ADR = dataArray[marketPrimaryIndex].ADR - dataArray[marketSdlyIndex].ADR;
            }

        }


        /** Calculation for Pickup and Market when mode = date  */
        // if(in_array(ui_state.active_tiles,["pickup","market"])){
        //         let system_settings = getAppData(auth, app_state);
        //         let totalCapacity = system_settings.inventory_settings.current.total_capacity;
        //         dataArray[0]['Occ'] = dataArray[0]['Rooms'] / (totalCapacity * 20) ;
        // }
        /** end  */

        if (ui_state.active_tiles[0] === "pace" && dataArray[1]) {
            let paceAdrDiff = dataArray[0]["ADR"] - dataArray[1]["ADR"];
            let paceRevparDiff = dataArray[0]["RevPAR"] - dataArray[1]["RevPAR"];

            dataArray.forEach((dataItem, i) => {
                if (i === 2) {
                    dataArray[i].ADR = paceAdrDiff;
                    dataArray[i].RevPAR = paceRevparDiff;
                }
            })
        }
        if (type === "Total") {
            dataArray = otbTransformTotal(dataArray, ui_state.active_tiles, workspace_controls, dataSet);
        }
        return dataArray;
    });

    ////////
    /////////
    const compsetTotal_xx = (obj, key, index, count) => {
        let total = 0;
        let daysInMonth = moment().daysInMonth();
        let totalDays = 0;
        for (let i = 0; i < obj.length; i++) {
            total = parseFloat(total + obj[i][count][key]);
            totalDays++;
        }
        total = (app_state.workspace_controls.scopeType !== 'Dba') ? round(total / totalDays) : round(total / 366);
        return displayDataFormat(total.toFixed(2), 'compset', '');
    };

    const compsetTotal = (obj, key, index, count) => {
        let total = 0;
        let daysInMonth = moment().daysInMonth();
        let totalDays = 0;
        for (let i = 0; i < obj.length; i++) {
            console.log("here i ", " ", obj[i][count]['compset_Courtyard by Marriott Key West Waterfront'], ' ', total);
            if (obj[i][count] && obj[i][count][key]) {
                total = parseFloat(total + obj[i][count][key]);
                totalDays++;
            }
        }
        total = (app_state.workspace_controls.scopeType !== 'Dba') ? round(total / totalDays) : round(total / 366);
        // console.log(obj," test ",total);
        total = (isNaN(total)) ? 0 : total;
        return displayDataFormat(total.toFixed(2), key, '');
    };

    /////////




    const displayDataFormat = useCallback((val, key, type, colType = "currency") => {
        // if(!val){ val = 0; }
        key = key.toLowerCase();
        let value = val;
        let currSymbol = ui_state.currency_symbol;
        if (key) {
            let keysArr = key.split("_");
            if ((keysArr[0] === "compset" || keysArr[0] === "cm") && (val === "" || !val)) {
                return "";
            }
        } // ask atul

        if (val !== '' && key !== 'segment' && key !== 'compdate' &&
            !['total_flights_percent', 'domestic_percent', 'international_percent', 'per_day_percent', 'total_flights', 'domestic', 'international', 'per_day'].includes(key)) {
            if (key.toLowerCase() !== "rooms" && key.toLowerCase() !== "dba" && key.toLowerCase() !== "revenue") {
                val = Number.isInteger(val) ? val.toFixed(2) : val;
            }
            if (type === "") {
                if (key === "date") {
                    val = moment(val).format("MMM DD");
                } else if (key === "dow" || key === "date_market_summary" || key === "month" || key === "dba") {
                    val = val;
                } else if (key === "occ" || key === "tcocc") {
                    val = parseFloat(val).toFixed(2) + "%";
                } else if (key === "sellrate") {
                    if (!val.toString().includes("%")) {
                        val = currSymbol + Math.round(val);
                    } else {
                        val = val;//currSymbol + Math.round(val);
                    }
                } else if ((key === "adr" || key === "revpar" || key === "sellrate") && val !== "") {
                    // debugger;
                    if (val) {
                        if (!val.toString().includes("%") && (key.toLowerCase() === "sellrate" || key.toLowerCase() === "adr" || key.toLowerCase() === "revpar")) {
                            val = parseFloat(val);
                        }
                    }
                    if (typeof val !== "string" && (key === "sellrate" || key === "adr" || key === "revpar")) {
                        val = (!isFinite(val)) ? parseFloat(0.00).toFixed(2) : parseFloat(val).toFixed(2);
                        val = currSymbol + (val).toLocaleString(undefined, { minimumFractionDigits: 2 });
                    } else {
                        val = val;
                    }

                } else if (key === "revenue") {
                    if (typeof val === "string") {
                        currSymbol = "";
                    }
                    // if (val && !val.toString().includes("%")) {
                    //     val = parseFloat(val).toFixed(2);
                    // }
                    val = (val) ? currSymbol + (val).toLocaleString(undefined, { minimumFractionDigits: 2 }) : currSymbol + "0.00";

                } else if (key === "rooms" || colType === "number") {
                    val = (val) ? val.toLocaleString() : 0;
                } else {
                    val = currSymbol + val;
                }
            } else if (type !== "" && typeof val !== "string") {
                val = val + "%";
            }
            if (key !== "rooms" && key !== "dba") {

                if (value >= 0 || typeof value === "string") {
                    val = val;
                } else {
                    if (Number.isFinite(value)) {
                        val = "(" + val.toString().replaceAll("-", "") + ")";
                    } else {
                        val = currSymbol + parseFloat(0.00).toFixed(2);
                    }
                }

            }
        }
        // if(key === "flights_segment"){
        //     val = val;
        // }
        if (key === 'segment') {
            // val = truncate(val, 17);
            val = val;
        }
        if (key === 'compdate') {
            val = (val && (moment(val, "YYYY/MM/DD", true).isValid() || moment(val, "YYYY-MM-DD", true).isValid())) ? dateFormat(val, "MMM DD YYYY,  ddd") : '';
        }
        if (['total_flights', 'domestic', 'international', 'per_day'].includes(key)) {
            val = parseInt(val)
            val = (val) ? val.toLocaleString() : 0;


        }
        if (['total_flights_percent', 'domestic_percent', 'international_percent', 'per_day_percent'].includes(key)) {
            val = (val) ? round(val * 100).toFixed(2) : 0;
            val = val + "%";
        }

        if (key) {
            let keysArr = key.split("_");
            if (keysArr[0] === "compset" || keysArr[0] === "cm") {
                let compsetValue = parseInt(removeCurrSymbol(val, currSymbol));
                compsetValue = (compsetValue && compsetValue > 0) ? currSymbol + compsetValue : "";
                return compsetValue;
            }
        }
        val = (val !== 'Infinity%') ? val : "0.00%";
        return val;
    });
    const displayDataFormat_backup = (val, key, type) => {
        // if(!val){ val = "0.00"; }
        key = key.toLowerCase();
        let value = val;
        let currSymbol = ui_state.currency_symbol;
        if (val !== '' && key !== 'segment' && key !== 'compdate') {
            if (key.toLowerCase() !== "rooms" && key.toLowerCase() !== "dba" && key.toLowerCase() !== "revenue") {
                val = Number.isInteger(val) ? val.toFixed(2) : val;
            }
            if (type === "") {
                if (key === "date") {
                    val = moment(val).format("MMM DD");
                } else if (key === "dow" || key === "date_market_summary" || key === "month" || key === "dba") {
                    val = val;
                } else if (key === "occ" || key === "tcocc") {
                    val = parseFloat(val).toFixed(2) + "%";
                } else if ((key === "adr" || key === "revpar" || key === "sellrate") && val !== "") {
                    // if(key === "sellrate"){
                    // }
                    if (typeof val !== "string" || key === "sellrate" || key === "adr" || key === "revpar") {
                        val = (!isFinite(val)) ? parseFloat(0.00).toFixed(2) : parseFloat(val).toFixed(2);
                        val = currSymbol + (val).toLocaleString(undefined, { minimumFractionDigits: 2 });
                    } else {
                        val = val;
                    }

                } else if (key === "revenue") {
                    if (typeof val === "string") {
                        currSymbol = "";
                    }
                    val = currSymbol + (val).toLocaleString(undefined, { minimumFractionDigits: 2 });

                } else if (key === "rooms") {
                    val = val.toLocaleString();
                } else {
                    val = currSymbol + val;
                }
            } else if (type !== "" && typeof val !== "string") {
                val = val + "%";
            }
            if (key !== "rooms" && key !== "dba") {

                if (value >= 0 || typeof value === "string") {
                    val = val;
                } else {
                    if (Number.isFinite(value)) {
                        val = "(" + val.toString().replaceAll("-", "") + ")";
                    } else {
                        val = currSymbol + parseFloat(0.00).toFixed(2);
                    }
                }

            }
        }
        if (key === 'segment') {
            val = truncate(val, 17);
        }
        if (key === 'compdate') {
            // let compVal =  dateFormat(val, "MMM DD, YYYY ddd");
            val = dateFormat(val, "MMM DD YYYY,  ddd");
            // debugger;
        }
        return val;
    };

    const totalCalulate = (obj, key, index, count) => {
        let dataIndex = index + 1;
        var total = 0;
        let symbol = "$";
        var totalRevenue = 0;
        for (let i = 0; i < obj.length; i++) {
            let dataInnerObj = obj[i][count];
            totalRevenue = parseFloat(total) + parseFloat(dataInnerObj.Revenue);
            if (key.toLowerCase() === "rooms") {
                symbol = "";
                total = parseFloat(total) + parseFloat(dataInnerObj.Rooms);
            }
            if (key.toLowerCase() === "occ") {
                symbol = "%";
                total = (parseFloat(total) + parseFloat(dataInnerObj.Occ)).toFixed(2);
            }
            if (key.toLowerCase() === "adr") {
                total = (parseFloat(total) + parseFloat(dataInnerObj.ADR)).toFixed(2);
            }
            if (key.toLowerCase() === "revpar") {
                total = (parseFloat(total) + parseFloat(dataInnerObj.RevPAR)).toFixed(
                    2
                );
            }
            if (key.toLowerCase() === "revenue") {
                total = (parseFloat(total) + parseFloat(dataInnerObj.Revenue));
            }
            if (key.toLowerCase() === "sellrate" && dataInnerObj.SellRate !== "") {
                total = parseFloat(total) + parseFloat(dataInnerObj.SellRate);
            }
        }

        if (
            key.toLowerCase() === "adr" ||
            key.toLowerCase() === "occ" ||
            key.toLowerCase() === "sellrate" ||
            key.toLowerCase() === "revpar"
        ) {
            total = (total / 12);
        }
        return displayDataFormat(round(total), key, "");
    };

    const calculateColWidth = (tile) => {
        let colWidth = [];
        let dataColumn = (tile === "compset") ? 20 : 12;
        for (let i = 1; i <= data_cols.length; i++) {
            if (i <= 2) {
                colWidth.push(10);
            } else {
                colWidth.push(dataColumn)
            }
        }
        return (colWidth.toString()).trim();
    }

    /// function to add info text while table dtaa is blank.
    const { segmentType } = app_state.workspace_controls
    const hasInfoBoxTable = () => {
        let infoText = false;
        if (((compDisplayData.data && compDisplayData.data.length <= 0) || (compDisplayData[0].data && compDisplayData[0].data.length <= 0))) {
            infoText = 'Data for ' + ui_state.active_tiles[0] + ' is not available, Please contact success@lodgiq.com for further details.';
        }
        if (ui_state.active_tiles[0] === 'forecast' && permissions.hfc === 0) {
            infoText = 'System forecast is not enabled for this property! Please contact success@lodgiq.com for further details.';
        }
        if (ui_state.active_tiles[0] === 'budget' && permissions.budget === 0) {
            infoText = 'Budget is not uploaded for this property! Please upload the budget through the uploads menu in report section. If you need any further help, Please contact success@lodgiq.com for further details.';
        }

        if (ui_state.active_tiles[0] === "flights" && permissions.flights === 0) {
            infoText = 'Flights data is not enabled for this property! Please contact success@lodgiq.com for further details. ';
        }

        if (segmentType === 'rate_plan' && ui_state.active_tiles[0] === 'otb_v2_multi_segment') {
            infoText = 'Rate Plan chart view is under development, It will be available in subsequent releases of LodgIQ RM v4';
        }
        if (ui_state.active_tiles[0] === 'market_sell_rates' && ((compDisplayData.data && compDisplayData.data.length <= 0) || (compDisplayData[0].data && compDisplayData[0].data.length <= 0))) {
            infoText = 'Market Sell Rates are not available, Please contact success@lodgiq.com for further details.';
        }
        if ((ui_state.active_tiles[0] === 'market' && dataService.getSeriesById("MarketCapacity").data && dataService.getSeriesById("MarketCapacity").data.data.length <= 0)) {
            infoText = 'Data is not available for the selected market, Please contact success@lodgiq.com for further details.';
        }
        if (ui_state.active_tiles[0] === 'market' && app_state.market_settings && app_state.market_settings.length <= 0) {
            infoText = 'Market data is not available , please contact success@lodgiq.com for further details. ';
        }
        if (ui_state.active_tiles[0] === 'market_summary' && app_state.market_settings && (app_state.market_settings.length <= 0 || compDisplayData[0].data.length <= 0)) {
            infoText = 'Market Summary is not available , please contact success@lodgiq.com for further details.';
        }

        return infoText;
    }
    ///// End ////////
    //console.log("renderBodyData.length",renderBodyData.length)
    let tableContClass = renderBodyData.length < 12 ? "table-scrollable no-scroll" : "table-scrollable ";
    if (['loaded', 'sortedCols'].includes(compLoadStatus) && compLoad && displayData.length) {

        let newRenderBodyData = data_setup && data_setup.length ? renderBody({ data_setup: data_setup }) : [];
        setRenderBodyData(newRenderBodyData);
        setCompLoadStatus('loaded..');
    }
    const hasTotalRow = (app_state, ui_state) => {
        if (ui_state.active_tiles[0] === "compset" &&
            app_state.workspace_controls.scopeType === "Dba") {
            return false;
        }
        if (ui_state.active_tiles[0] === "market" &&
            app_state.workspace_controls.scopeType === "Dba") {
            return false;
        }
        if (ui_state.active_tiles[0] === "pace" &&
            app_state.workspace_controls.scopeType === "Dba") {
            return false;
        }
        if (in_array(ui_state.active_tiles, ["flights"])) {
            return false;
        }
        return true
    }



    const downloadReportToExcel = (selectedPropertyName) => {
        const { dayDate } = app_state.workspace_controls;
        selectedPropertyName = (ui_state.active_tiles.includes("market_summary")) ? app_state.selected_market.label : selectedPropertyName;
        let exportFileName = moment(app_state.as_of_date).format('MMM DD YYYY') + "_" + selectedPropertyName + "_" + active_tiles[0];
        if (app_state.workspace_controls.downloadExportButton === true && compRef.current.data_setup) {
            let sheetName = active_tiles[0] ? active_tiles[0] : " ";
            const { current } = compRef;
            const table = document.createElement('table');
            const header = document.querySelector("#mainTable .table-head").cloneNode(true); // generateTableHead(table, colKeys)

            if (!in_array(ui_state.active_tiles, ["flights", "hotel_summary", "market_summary"])) {
                if (workspace_controls.scopeType !== 'Dba') {

                    // Add same style for row 1 for Notes and Events
                    const firstRow = header.querySelectorAll('tr')[0];
                    if (firstRow) {
                        // Add the Notes column heading to the first row of the header
                        const newTh = firstRow.querySelector('th').cloneNode(true);
                        newTh.textContent = 'Notes';
                        newTh.setAttribute('colspan', '2');
                        if (!sdlyOn) {
                            newTh.removeAttribute('colspan');
                        }
                        firstRow.appendChild(newTh);

                        // Add the Events column heading to the first row of the header
                        const eventsTh = firstRow.querySelector('th').cloneNode(true);
                        eventsTh.textContent = 'Events';
                        eventsTh.setAttribute('colspan', '2');
                        if (!sdlyOn) {
                            eventsTh.removeAttribute('colspan');
                        }
                        firstRow.appendChild(eventsTh);
                    }

                    const secondRow = header.querySelectorAll('tr')[1];
                    if (secondRow) {
                        // Add the new "Note TY" column heading to the second row of the header
                        const newTh = secondRow.querySelector('th').cloneNode(true); // Clone an existing th to retain styles
                        newTh.textContent = 'Note TY';
                        secondRow.appendChild(newTh);

                        // Add the new "Note SDLY" column heading to the second row of the header
                        if (sdlyOn) {
                            const secondRow2 = header.querySelectorAll('tr')[1];
                            if (secondRow2) {
                                const newTh = secondRow2.querySelector('th').cloneNode(true); // Clone an existing th to retain styles
                                newTh.textContent = dayDate === 'day' ? 'Note SDLY' : 'Note SDTLY';
                                secondRow2.appendChild(newTh);
                            }
                        }

                        // Add the new "Event TY" column heading to the second row of the header
                        const eventsTh2 = secondRow.querySelector('th').cloneNode(true);
                        eventsTh2.textContent = 'Event TY';
                        secondRow.appendChild(eventsTh2);

                        // Add the new "Event SDLY" column heading to the second row of the header
                        if (sdlyOn) {
                            const secondRow2 = header.querySelectorAll('tr')[1];
                            if (secondRow2) {
                                const eventsTh2 = secondRow2.querySelector('th').cloneNode(true); // Clone an existing th to retain styles
                                eventsTh2.textContent = dayDate === 'day' ? 'Event SDLY' : 'Event SDTLY';
                                secondRow2.appendChild(eventsTh2);
                            }
                        }
                    }

                }
            }

            table.append(header);

            let data = [];
            let columnNodes = document.querySelectorAll(".filter-position-r .col-head-label");

            let colNames = [];
            columnNodes.forEach(item => {
                colNames.push(item.textContent);
            });

            let colNameHash = {};
            current.columns.forEach(col => {
                col.subcolumns.forEach(subcol => {
                    if (colNames.includes(subcol.display)) {
                        colNameHash[subcol.value] = subcol.display;
                    }
                });
            });

            let totalArrObj = [];
            if (hasTotalRow(app_state, ui_state)) {
                totalArrObj.push({
                    TotalRow: "TotalRow",
                    id: 'TotalRow'
                });
            } else {
                totalArrObj.push({});
            }

            let data_setupExcel = deepCopy(data_setup);
            data_setupExcel.push(totalArrObj);
            let totalDataLength = data_setupExcel.length;

            data_setupExcel.forEach((dItem, idx) => {
                let dataPoint = {};
                let rowDate = dItem[0].date;
                dItem.forEach((item, index) => {
                    if (item.hasOwnProperty("Restrictions")) {
                        let restrictionData = getRestrictionAll(rowDate, 'comma', 'cur');
                        item["Restrictions"] = restrictionData ? getRestrictionAll(rowDate, 'comma', 'cur') : '';
                        item = preferredOrder(item, ['Restrictions']);
                    }
                    if (active_tiles[0] === 'hotel_summary') {
                        delete item['date_market_summary'];
                    }

                    if (!ui_state.active_tiles.includes("pace")) {
                        if (item.id === "forecast_primary") {
                            delete item.SellRate;
                        }
                        item = item.hasOwnProperty('Rooms') ? preferredOrder(item, ['Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue', 'SellRate']) : item;
                    }
                    if (ui_state.active_tiles.includes("pace")) {
                        item = item.hasOwnProperty('Rooms') ? preferredOrder(item, ['compdate', 'Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue']) : item;
                    }

                    Object.keys(item).forEach(key => {
                        if (key !== 'id' && key !== 'TotalRow' && colNameHash.hasOwnProperty(key) && key !== 'ignore_sort' && key !== 'segment_code') {
                            let value = item[key];
                            value = key === 'compdate' && value !== "" ? moment(value).format('YYYY/MM/DD') : value;
                            if (value === true) {
                                value = "true";
                            }
                            if (item.id === "compset_medium") {
                                if (value === null) {
                                    value = 0;
                                }
                            }
                            if (item.id === "arrivals_variance" || item.id === "stayovers_variance") {
                                value = round(item[key]).toFixed(2)
                            }
                            if (key === "per_day" )  {
                                if(item.id === "stayovers_variance"){
                                    value = round(item[key]).toFixed(2)
                                }else{
                                    value = Math.trunc(item[key])
                                }
                            }
                            if (value === Infinity || value === -Infinity) {
                                value = 0;
                            }
                            if (typeof value === "string" && value.indexOf("Infinity") !== -1) {
                                value = value.replace("Infinity", "0");
                            }
                            if (value === null || value === "" || value === 'NaN') {
                                value = 0;
                                if (key === 'dow' || key === 'date' || key === "compdate") {
                                    value = "";
                                }
                            } else {
                                value = value ? value : 0;
                            }
                            value = value && typeof value === 'string' && value.indexOf("%") > -1 ? value.replaceAll('%', '') : value;
                            dataPoint[key + "-" + index] = value;
                        }
                    });
                });

                if (!in_array(ui_state.active_tiles, ["flights", "hotel_summary", "market_summary"])) {
                    if (workspace_controls.scopeType !== 'Dba') {
                        if (segmented_view) {
                            // Add the new columns here if the row is Total
                            if (dItem[0].segment === "Total") {
                                dataPoint["Note TY"] = calculateNewColumnValue(dItem);
                                if (sdlyOn) {
                                    if (dayDate === 'day') {
                                        dataPoint["Note SDLY"] = calculateNewColumnValue2(dItem);
                                    } else {
                                        dataPoint["Note SDTLY"] = calculateNewColumnValue2(dItem);
                                    }
                                }

                                dataPoint["Event TY"] = calculateEventTY(dItem);
                                if (sdlyOn) {
                                    if (dayDate === 'day') {
                                        dataPoint["Event SDLY"] = calculateEventSDLY(dItem);
                                    } else {
                                        dataPoint["Event SDTLY"] = calculateEventSDLY(dItem);
                                    }
                                }
                            }
                        }
                        else {
                            // Add the new columns here if the row is not Total
                            if (!dItem[0].TotalRow) {
                                dataPoint["Note TY"] = calculateNewColumnValue(dItem);
                                if (sdlyOn) {
                                    if (dayDate === 'day') {
                                        dataPoint["Note SDLY"] = calculateNewColumnValue2(dItem);
                                    } else {
                                        dataPoint["Note SDTLY"] = calculateNewColumnValue2(dItem);
                                    }
                                }

                                dataPoint["Event TY"] = calculateEventTY(dItem);
                                if (sdlyOn) {
                                    if (dayDate === 'day') {
                                        dataPoint["Event SDLY"] = calculateEventSDLY(dItem);
                                    } else {
                                        dataPoint["Event SDTLY"] = calculateEventSDLY(dItem);
                                    }
                                }
                            }
                        }
                    }
                }

                if (dItem[0].TotalRow === "TotalRow") {
                    dItem = data_setupExcel[0];
                    let count = 0;
                    dItem.forEach((item, index) => {
                        if (active_tiles[0] === 'hotel_summary') {
                            delete item['date_market_summary'];
                        }
                        Object.keys(item).forEach((key, keyIndex) => {
                            if (key !== 'id' && key !== 'segment_code') {
                                let value = item[key];
                                if (key === "date" || key === "date_market_summary" || key === "month") {
                                    value = "Total";
                                } else if (key === "dow") {
                                    value = "";
                                } else if (key.toLocaleLowerCase() === "segment") {
                                    value = "";
                                } else {
                                    if (totalRowsData[count]) {
                                        value = totalRowsData[count];
                                    }
                                    else {
                                        if (key === "compdate") {
                                            value = "";
                                        }
                                        else {
                                            value = 0;
                                        }
                                    }
                                    count++;
                                }
                                dataPoint[key + "-" + index] = value;
                            }
                        });
                    });
                }

                data.push(dataPoint);
            });

            const tBody = generateTable(table, data);
            if (app_state.workspace_controls.exportType == 'csv') {
                downloadcsvwithTable(table, exportFileName, compRef.current.columns);
            } else {
                TableToExcel.convert(table, {
                    name: exportFileName + ".xlsx",
                    sheet: {
                        name: sheetName.toUpperCase()
                    }
                });
            }

        }
    }

    const calculateNewColumnValue = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        //const formatted_comp_date = sdlyOn ? dateFormat(getStayToSdly(app_state, formatted_stay_date), "YYYY-MM-DD") : dateFormat(getStayToComp(app_state, formatted_stay_date), "YYYY-MM-DD");
        let dataForDate = notes && notes.find(item => item.stay_date === formatted_stay_date);
        let current_stay_date = dateFormat(formatted_stay_date, "YYYY/MM/DD")
        //let current_comp_date = dateFormat(formatted_comp_date, "YYYY-MM-DD")

        if (!dataForDate) {
            dataForDate = {
                note: '',
                last_edited_by: '',
                last_edited_at: ''
            }
        }
        let noteData = "";
        if (dataForDate.note !== '') {
            noteData = current_stay_date + " - " + dataForDate.note;
        }
        return noteData
    }
    const calculateNewColumnValue2 = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        const formatted_comp_date = formattedCompDateCalc(sdlyOn, formatted_stay_date, app_state);
        // let formatted_comp_date = ''
        // if(sdlyOn && !workspace_controls.clearComparision){
        //     formatted_comp_date = dateFormat(getStayToSdly(app_state, formatted_stay_date), "YYYY-MM-DD");
        // }
        // if(!sdlyOn && workspace_controls.clearComparision){
        //     formatted_comp_date = dateFormat(getStayToComp(app_state, formatted_stay_date), "YYYY-MM-DD")
        // }
        let dataForDate = notes && notes.find(item => item.stay_date === formatted_comp_date);
        let current_stay_date = dateFormat(formatted_comp_date, "YYYY/MM/DD")
        //let current_comp_date = dateFormat(formatted_comp_date, "YYYY-MM-DD")

        if (!dataForDate) {
            dataForDate = {
                note: '',
                last_edited_by: '',
                last_edited_at: ''
            }
        }
        let noteData = "";
        if (dataForDate.note !== '') {
            noteData = current_stay_date + " - " + dataForDate.note;
        }
        return noteData
    }
    const calculateEventTY = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        let current_stay_date = dateFormat(formatted_stay_date, "YYYY/MM/DD")

        const filteredEventList = eventList.filter(event =>
            isDateInRange(formatted_stay_date, event.start_date, event.end_date) && event.is_ignored === false
        );

        let EventData = filteredEventList.map(event => event.title).join(", ");

        return EventData
    }
    const calculateEventSDLY = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        const formatted_comp_date = formattedCompDateCalc(sdlyOn, formatted_stay_date, app_state);
        let current_stay_date = dateFormat(formatted_comp_date, "YYYY/MM/DD")

        const filteredEventList = eventListSDLY.filter(event =>
            isDateInRange(formatted_comp_date, event.start_date, event.end_date) && event.is_ignored === false
        );

        let EventData = filteredEventList.map(event => event.title).join(", ");

        return EventData
    }


    const tableSkeletonLoader = (
        <Box p={0} m={0}>
            <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table-view-scaleton-loader"
            >
                <TableHead
                    sx={{ background: "#606060", opacity: "0.4" }}
                    className="table-head"
                >
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell sx={{ width: "15%" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "70%" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>

            <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table-view-scaleton-loader"
            >
                <TableHead
                    sx={{ background: "#01A4EF", opacity: "0.3" }}
                    className="table-head"
                >
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell
                            sx={{ background: "#B2B2B2", width: "7.5%" }}
                            align="center"
                        >
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell
                            sx={{ background: "#B2B2B2", width: "7.5%" }}
                            align="center"
                        >
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "10vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "25vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "30vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody className="table-body">
                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 700 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 700 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 700 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 750 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 800 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 830 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 850 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 890 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 950 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 900 && containerHeight < 950
                                ? { display: "all" }
                                : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 950 && containerHeight < 1001
                                ? { display: "all" }
                                : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 1000 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1020 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1050 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1080 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 1100 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1130 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1170 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 1200 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1240 && containerHeight < 1280
                                ? { display: "all" }
                                : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 1280 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 1300 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table-view-scaleton-loader"
            >
                <TableHead
                    sx={{ background: "#01A4EF", opacity: "0.3" }}
                    className="table-head"
                >
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell sx={{ width: "15%" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "10vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "25vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "30vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
        </Box>
    );
    const TableComponentsValue = useCallback(TableComponents(tableContClass, active_tiles_className, calculateColWidth, data_setup), [tableContClass, active_tiles_className, data_setup])

    return (

        <Paper sx={{ width: "100%", paddingLeft: "0 !important" }} elevation={0}>

            {
                (compLoadStatus === 'sortingCols' && compLoad && displayData.length && data_setup.length) ? <Alert sx={{ position: 'absolute', width: 'calc(100% - 8px)', zIndex: 999, textAlign: 'center', opacity: 0.95, borderRadius: 0, lineHeight: 1, p: 0, pl: 1, left: 0, top: 70 }} severity="info" color='info' textAlign='center' icon={false}>Sorting data...</Alert> : ''
            }
            {compLoadStatus === 'loading' && !hasInfoBoxTable() && (
                <>
                    <TableLottieLoader />
                    {tableSkeletonLoader}
                </>
            )}


            {!['loading', 'sortingCols'].includes(compLoadStatus) && !hasInfoBoxTable() && <TableVirtuoso
                ref={tableVirtuoso}
                data={renderBodyData.length ? new Array(renderBodyData.length - 1) : [1]}
                components={TableComponentsValue}
                fixedHeaderContent={() => (
                    renderHeader()
                )}
                fixedFooterContent={!renderBodyData.length > 3 ? () => (<></>) : () => (<TableRow sx={{
                    position: "sticky",
                    bottom: 0,
                    zIndex: 3,
                    backgroundColor: "#fff",
                }}
                    className="table-row footer-row">{renderBodyData.length ? renderBodyData[renderBodyData.length - 1] : []}</TableRow>)}
                itemContent={!renderBodyData.length ? () => (<TableCell colSpan={8} sx={{ p: 0, borderBottom: 'none', position: 'absolute', width: '100%' }}>
                    <Alert severity="info" sx={{ backgroundColor: "#fff" }}>
                        <AlertTitle>No data found</AlertTitle>
                    </Alert>
                </TableCell>) : (index) => (
                    <>
                        {
                            (compLoadStatus !== 'loading' && compLoad && displayData.length && data_setup.length) ? renderBodyData[index] : ''
                        }
                    </>
                )}
            />}

            {hasInfoBoxTable() && <Box sx={{ p: 1 }}>
                <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    {hasInfoBoxTable()}</Alert>
            </Box>}

            {/* {!hasInfoBoxTable() && <TableContainer className="table-scrollable">
                <Table
                    stickyHeader
                    size="small"
                    aria-label="a dense table"
                    className={active_tiles_className + " react-dynamic-table table-common"}
                    id="mainTable"
                    data-cols-width={calculateColWidth(ui_state.active_tiles[0])}
                >
                    {renderHeader()}
                    <tbody className="table-body">
                        {
                            (compLoadStatus === 'sortingCols' && compLoad && displayData.length && data_setup.length) ? <Alert sx={{ position: 'absolute', width: 'calc(100% - 8px)', zIndex: 999, textAlign: 'center', opacity: 0.95, borderRadius: 0, lineHeight: 1, p: 0, pl: 1, left: 0, top: 80 }} severity="info" color='info' textAlign='center' icon={false}>Sorting data...</Alert> : ''
                        }
                        {
                            (compLoadStatus === 'loaded..' && compLoad && displayData.length && data_setup.length) ? <TableRowSet key={1} reloadComp={reloadGrid} dataSet={renderBodyData} /> : ''
                        }
                    </tbody>
                </Table>
            </TableContainer>} */}



        </Paper>
    );
}

export default memo(TableCommon);

function randomMessage() {
    const messages = msg;

    const randomIndex = Math.floor(Math.random() * messages.length);
    const randomMessage = messages[randomIndex];

    return randomMessage;
}
const defaultOptionsTable = {
    loop: true,
    autoplay: true,
    animationData: TableLoadingLottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
const TableLottieLoader = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(true);
        }, 2000); // Adding delay in milliseconds

        // Clear the timeout if the component is unmounted before the delay is completed
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <Box className="lottie-main-container">
                <Box className="table-lottie-container">
                    <Lottie options={defaultOptionsTable} height={40} width={40} />
                    <div>{randomMessage()}</div>
                </Box>
            </Box>
        );
    }
    return null;
};

const TableComponents = (tableContClass, active_tiles_className, calculateColWidth, data_setup) => ({
    Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} className={tableContClass} style={{ ...props.style, boxShadow: 'none' }} />),
    Table: (props) => <Table {...props} stickyHeader size="small" aria-label="a dense table" className={active_tiles_className + " react-dynamic-table table-common"} id="mainTable" data-cols-width={calculateColWidth()} />,
    TableHead: React.forwardRef((props, ref) => <TableHead ref={ref} {...props} className="table-head" style={{ ...props.style, zIndex: 3 }} />),
    TableRow: (props) => {
        let ind = props['data-index'];
        let item = data_setup && data_setup.length ? data_setup[ind] : {};
        //let total_row = item && item[0] && item[0].segment === 'Total' || item[0].flights_segment === 'Total' ? ' total-row' : '';
        let total_row = ((item && item[0] && item[0].segment && item[0].segment === 'Total') || (item && item[0] && item[0].flights_segment && item[0].flights_segment === 'Total')) ? ' total-row' : '';

        return (<TableRow  {...props} className={`${props['data-index'] % 2 ? 'table-row even-row' + total_row : 'table-row' + total_row}`} />);
    },
    TableBody: React.forwardRef((props, ref) => <TableBody className="table-body" {...props} ref={ref} />)

})

const TableRowSet = (props) => {
    // debugger; 
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let { dataSet } = props;
    let tm = props.compRef;

    const dispatch = useDispatch();
    const [renderCount, setRenderCount] = useState(1);
    const chunkLength = 50;
    const dataSize = dataSet.length;
    let totalRow = dataSet.slice(-1);
    const compRef = useRef({
        intervalId: null,
        renderItems: (dataSize > chunkLength) ? dataSet.slice(0, chunkLength).concat(totalRow) : dataSet,
        chunks: Math.ceil(dataSize / chunkLength),
        index: 1,
        renderCount: 0
    });

    useEffect(() => {
        let isSubscribed = true;
        if (compRef.current.index >= compRef.current.chunks) {
            clearInterval(compRef.current.intervalId);
            // debugger
            if (app_state.workspace_controls.disableExport) {
                // debugger;
                dispatch(updateAppState("exportStatus", {
                    app_state: {
                        workspace_controls: { ...app_state.workspace_controls, disableExport: false }
                    }
                }));
            }

        }
        return () => (isSubscribed = false);
    }, [renderCount]);

    useEffect(() => {

        let isSubscribed = true;
        if (compRef.current.chunks > 1) {
            dispatch(updateAppState("exportStatus", {
                app_state: {
                    workspace_controls: { ...app_state.workspace_controls, disableExport: true }
                }
            }));
            compRef.current.intervalId = setInterval(() => {
                compRef.current.renderItems.splice(-1);
                let newArray = dataSet.slice(chunkLength * compRef.current.index, chunkLength * (compRef.current.index + 1));
                if (compRef.current.chunks === compRef.current.index + 1) {
                    compRef.current.renderItems = compRef.current.renderItems.concat(newArray);
                } else {
                    compRef.current.renderItems = compRef.current.renderItems.concat(newArray, totalRow);
                }

                compRef.current.renderCount = compRef.current.index;
                setRenderCount(compRef.current.index);
                compRef.current.index++;
            }, 90);
            return () => clearInterval(compRef.current.intervalId);
        }
        return () => (isSubscribed = false);
    }, []);

    if (!compRef.current.renderItems.length) {
        return (
            <>Loading...</>
        );
    }
    let ri = compRef.current.renderItems;
    // debugger;
    return (compRef.current.renderItems)
};

//Helper functions
const getFilteredData = (params) => {
    // debugger;
    // {data:data_setup, sortBy: 'Occ', parent: 'otb_prior'};
    const { data, columns } = params;
    const filteredColumns = getFilteredColumns(columns);
    let filtereddData = deepCopy(data);
    if (!filteredColumns.length) {
        return filtereddData;
    }
    // debugger
    filtereddData = [];
    let pushedRow = [];
    data.map((row, i) => {
        for (let j = 0; j < row.length; j++) {
            for (let k = 0; k < filteredColumns.length; k++) {
                let { parent, value, filter } = filteredColumns[k];
                if (row[j].id === parent) {
                    // debugger
                    let rowData = null;
                    if (filter) {
                        let dataValue = row[j][value];

                        if (typeof dataValue !== 'number' && dataValue && dataValue.indexOf('%') !== -1) {
                            dataValue = Number(dataValue.replaceAll(/%/g, ''));
                        }
                        if (filter.gte && filter.lte) {
                            if (dataValue >= Number(filter.gte) && dataValue <= filter.lte) {
                                rowData = row;
                            }
                        } else {
                            if (filter.gte && dataValue >= Number(filter.gte)) {
                                rowData = row;
                            }
                            if (filter.lte && dataValue <= filter.lte) {
                                rowData = row;
                            }
                        }

                        if (pushedRow.indexOf(i) === -1 && rowData) {
                            filtereddData.push(rowData);
                            pushedRow.push(i);
                        }
                    }
                }
            }
        }
    });
    // debugger;
    return filtereddData;
}

const sortData = (params) => {
    // {data:data_setup, sortBy: 'Occ', parent: 'otb_prior'};
    const { data, sortBy, parent, order, workspace_controls, active_tiles } = params;

    const sortedData = deepCopy(data);
    let finalSortedData = [];
    if (!order) {
        return sortedData;
    }
    let sortedParentIndex = null;
    if (sortedData[0]) {
        sortedData[0].map((item, i) => {
            if (item.id === parent) {
                sortedParentIndex = i;
            }
        });
        let weekdaysShort = moment.weekdaysShort();
        // debugger;
        let sortedDates = [];
        let tempSortedDates = deepCopy(sortedData);
        let tempSortedDatesTotal = [];
        let tempSortedDatesBlank = [];
        let dba_date = workspace_controls.scopeType.toLowerCase();
        if (workspace_controls.segmented_view) {
            tempSortedDatesTotal = deepCopy(sortedData.filter(d => d[0].segment === 'Total'));
            tempSortedDates = deepCopy(sortedData.filter(d => d[0][dba_date] !== '' && d[0].segment !== 'Total'));
            tempSortedDatesBlank = deepCopy(sortedData.filter(d => d[0][dba_date] === ''));
            tempSortedDatesTotal = arraySort(tempSortedDatesTotal);

        } else {
            tempSortedDates = arraySort(sortedData);
            // deepCopy(sortedData);
        }

        function arraySort(arr) {
            let arr_copy = deepCopy(arr);
            arr_copy.sort((a, b) => {
                if (sortedParentIndex !== null) {
                    let A = a[sortedParentIndex][sortBy];
                    let B = b[sortedParentIndex][sortBy];
                    //This is added for Pickup variance to sdly column
                    A = (A) ? A : 0;
                    B = (B) ? B : 0;

                    if (typeof A !== 'number' && A.indexOf('%') !== -1) {
                        A = Number(A.replaceAll(/%/g, ''));
                    }
                    if (typeof B !== 'number' && B.indexOf('%') !== -1) {
                        B = Number(B.replaceAll(/%/g, ''));
                    }
                    //---
                    if ((sortBy === 'date') || (sortBy === 'compdate') || (sortBy === 'month')) {
                        A = Number(moment(A).format('x'));
                        B = Number(moment(B).format('x'));
                    }

                    if (sortBy === 'dow') {
                        A = weekdaysShort.indexOf(A);
                        B = weekdaysShort.indexOf(B);
                    }

                    if (order === 'asc') {
                        return A - B;
                    }

                    if (order === 'desc') {
                        return B - A;
                    }
                }
            });
            return arr_copy;
        }

        // let sortedTotal = arraySort(tempSortedDatesTotal);
        // let sortedSegment = arraySort(tempSortedDates);
        // tempSortedDates.sort((a, b) => {
        //     if (sortedParentIndex !== null) {
        //         let A = a[sortedParentIndex][sortBy];
        //         let B = b[sortedParentIndex][sortBy];
        //         //This is added for Pickup variance to sdly column
        //         A = (A) ? A : 0;
        //         B = (B) ? B : 0;

        //         if (typeof A !== 'number' && A.indexOf('%') !== -1) {
        //             A = Number(A.replaceAll(/%/g, ''));
        //         }
        //         if (typeof B !== 'number' && B.indexOf('%') !== -1) {
        //             B = Number(B.replaceAll(/%/g, ''));
        //         }
        //         //---
        //         if ((sortBy === 'date') || (sortBy === 'compdate')) {
        //             A = moment(A).format('x');
        //             B = moment(B).format('x');
        //         }

        //         if (sortBy === 'dow') {
        //             A = weekdaysShort.indexOf(A);
        //             B = weekdaysShort.indexOf(B);
        //         }

        //         if (order === 'asc') {
        //             return A - B;
        //         }

        //         if (order === 'desc') {
        //             return B - A;
        //         }
        //     }
        // });

        if (workspace_controls.segmented_view) {
            tempSortedDatesTotal.map((item, i) => {
                if (!sortedDates.includes(item[0][dba_date])) {
                    sortedDates.push(item[0][dba_date]);
                    let dateFilter = tempSortedDates.filter((d) => d[0][dba_date] === item[0][dba_date] && d[0].segment !== 'Total');
                    let dateFilterTotal = tempSortedDatesTotal.filter((d) => d[0][dba_date] === item[0][dba_date] && d[0].segment === 'Total');
                    dateFilter = arraySort(dateFilter);
                    // debugger;
                    finalSortedData = [...finalSortedData, ...dateFilter, ...dateFilterTotal];
                }
            });
            finalSortedData = [...finalSortedData, ...tempSortedDatesBlank];
        } else {
            finalSortedData = tempSortedDates;
        }

    }
    return finalSortedData;
}

const getSortedColumn = (columns) => {
    //Get sorted column details from column configuration
    let sortedColumn = {};
    columns.map((main, i) => {
        main.subcolumns.map((sub, j) => {
            //it depends on property sorted
            if (sub.sorted) {
                sortedColumn = sub;
            }
        });
    });
    return sortedColumn;
};
const getFilteredColumns = (columns) => {
    //Get filter column details from column configuration
    let filteredColumns = [];
    columns.map((main, i) => {
        let subcols = main.subcolumns;
        for (let j = 0; j < subcols.length; j++) {
            let sub = subcols[j];
            if (sub.filterable && sub.filter && (sub.filter.gte || sub.filter.lte)) {
                filteredColumns.push(sub);
            }
        }
    });
    // debugger;
    return filteredColumns;
};
//Helper components
const ColumnSorting = (props) => {
    const { colItem, sortable, onOrderByChange, sorted, label, value } = props;

    const onClickHandler = (ev) => {
        // debugger;
        if (!sorted) {
            colItem.sorted = 'asc';
        }
        if (sorted === 'asc') {
            colItem.sorted = 'desc';
        }
        if (sorted === 'desc') {
            colItem.sorted = null;
        }
        onOrderByChange(colItem);
    }

    if (!sortable) {
        return (<></>);
    }
    return (
        <div style={{ position: 'relative' }}>
            <Tooltip title={"Sort by " + label} arrow>
                <Button className={props.className + ' order-' + colItem.sorted} onClick={onClickHandler}>
                    <ArrowDropUpIcon className='order-arrow order-arrow-up' />
                    <ArrowDropDownIcon className='order-arrow order-arrow-down' />
                </Button>
            </Tooltip>

        </div>

    );
}