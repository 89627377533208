import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandler } from "../../app/ErrorHandler";
import { Container, Grid, Modal } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState, memo, useRef } from "react";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";

import { updateAppState } from "../../actions/appstate";
import { sendLog } from "../../app/log-interface";

import { getTilesInOrder, getTileById, deepCopy, abortRequestSafe, calculateCompStayRange, calculatePrimaryStayRange, getPermissions, getSeriesById, in_array, getUnique } from "../../app/util";
import theme from "../../theme";
import Header from "../Header";
import { DashboardFooter } from "../layout/dashboard-ui";

import { TILES, TILE_GROUP_ONE, TILE_GROUP_TWO, ABORT_REQUEST_CONTROLLERS, APP_CACHE } from "../../app/config";
import { UPDATE_STATE } from "../../actions/types";
import PriceCalendar from "../PriceCalendar";
import Tile from "../Tile/";
import Workspace from "../Workspace";
import DailyFlash from "../DailyFlash";
import AlertPopup from "./AlertPopup";
import GroupPricing from "../GroupPricing";
import { determineMeasureStates } from "../../components/Measure/measure-logic";
import { AtuoLogoutTimer } from '../AutoLogoutTimer';
import { useProductVariant } from "../../hooks/use-product-variant";
import { analytics } from '../../hooks/use-analytics';

import FullScreenLock from "../LockLayer/fullScreenLock.js";
import { useOnlineStatus } from '../../hooks/helper-hooks';

const DashboardLayout = () => {
    const isOnline = useOnlineStatus();
    const dispatch = useDispatch();
    let [displayData, setDisplayData] = useState([]);
    const [openPriceCalendar, setOpenPriceCalendar] = React.useState(false);
    const [mergeCompatibilty, setMergeCompatibilty] = React.useState({});
    const state = useSelector(state => state);
    const { auth, app } = state;
    const { profile, user } = auth;
    let { current_state } = app;
    let { app_state, ui_state } = current_state
    const { controlSupport, workspace_controls, property_id, property_name, user_view, primary_stay_range } = app_state;
    const { scopeType, segmented_view, sdlyOn, segmentType } = workspace_controls;
    let { active_tiles, fullScreenLockData } = ui_state;
    const tileDetails = active_tiles[0] ? getTileById(active_tiles[0]) : getTileById('otb');
    let { defaultMeasures } = tileDetails.chartConfig;
    defaultMeasures = defaultMeasures ? defaultMeasures : ['Occ', 'ADR'];
    const tilesGroupOne = getTilesInOrder(TILES, TILE_GROUP_ONE);
    const tilesGroupTwo = getTilesInOrder(TILES, TILE_GROUP_TWO);
    let [activeTile, setActiveTile] = useState(active_tiles[0]);
    const { hasFeature } = useProductVariant();
    const compRef = useRef({ timoutId: null });
    const [apiAsOfDate, setApiAsOf] = useState(app_state.as_of_date);

    useEffect(() => {
        let isSubscribed = true;
        let compatibility = {};
        window.analyze = analytics;
        let dailyFlashInit = false;
        tilesGroupOne.map((tile, index) => {
            compatibility[tile.id] = tile.mergeable ? true : false;
        });
        tilesGroupTwo.map((tile, index) => {
            compatibility[tile.id] = tile.mergeable ? true : false;
        });
        const analyze = window.analyze;
        setMergeCompatibilty((state) => {
            return { ...state, ...compatibility };
        });
        tileMergeLogic(ui_state.active_tiles);

        if (isOnline && window.analyze && window.analyze.track) {
            window.analyze.track("tile_change", {
                "selected_tile": active_tiles.join(),
                ...app_state.primary_stay_range,
                property_id, property_name,
                data_view: user_view,
            }, {}, current_state);
        }
        return () => {
            isSubscribed = false
            // debugger;
            //Reseting APP_CACHE data on unloading of this component
            APP_CACHE.chartData = null;
            APP_CACHE.tableData = null;
            APP_CACHE.renderTableData = null;
        }
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        tileMergeLogic(ui_state.active_tiles);
        return () => (isSubscribed = false);
    }, [scopeType, sdlyOn, segmented_view, ui_state.active_tiles.join('_')]);

    useEffect(() => {
        let isSubscribed = true
        const analyze = window.analyze;

        let dailyFlashInit = false;
        if (hasFeature(['daily_flash', 'daily_flash_mark'], 'tiles', 'some')) {
            dailyFlashInit = true
        }
        if (auth.profile.justLoggedIn) {
            if (auth.profile.flash_report) {
                ui_state.show_flash_report = dailyFlashInit;
                //Record Flash Report popup open
                // console.log('open flash')
                // if (analyze) {
                //     analyze.track("flash_report_opened", {
                //         "previous_flash_report_popup": 'No',
                //         "selected_flash_report_popup": 'Yes',
                //         "Opened On Login": "Yes"
                //     }, null, { app_state, ui_state });
                // }
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        ui_state: { ...ui_state }
                    }
                });
            }
            dispatch({ type: "PROFILE_SUCCESS", payload: { ...auth.profile, justLoggedIn: false } });
        }
        else if (!auth.profile.justLoggedIn && ui_state.show_flash_report) {
            ui_state.show_flash_report = dailyFlashInit;
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    ui_state: { ...ui_state }
                }
            });
        }

        /** for Group pricing popup */
        if (ui_state.show_group_pricing) {
            //Record Group Pricing popup open
            if (analyze) {
                analyze.track("group_pricing_popup_opened", {
                    "previous_group_pricing_popup": 'No',
                    "current_group_pricing_popup": 'Yes',
                }, null, { app_state, ui_state });
            }
        }

        if (ui_state.show_price_calendar) {
            setOpenPriceCalendar(true);
            dispatch(
                updateAppState("change_group_pricing", {
                    ui_state: {
                        show_price_calendar: false,
                    },
                })
            );
            //Record Pricing popup open
            // analyze.track("pricing_popup_opened", {
            //     "previous_group_pricing_popup": 'No',
            //     "current_group_pricing_popup": 'Yes',
            // }, null, { app_state, ui_state });
        }

        // if (!app_state.data_load) {
        //     dispatch({
        //         type: UPDATE_STATE,
        //         payload: {
        //             app_state: {
        //                 data_load: true,
        //                 kpi_load: true,
        //             },
        //             ui_state: {
        //                 showspinner: true
        //             },
        //         }
        //     });
        // }
        return () => {
            isSubscribed = false;
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        data_load: false,
                        kpi_load: false,
                    },
                    ui_state: {
                        showspinner: false
                    },
                }
            });
        };
    }, [ui_state.show_flash_report, ui_state.show_price_calendar]);
    let showAlert = false;
    useEffect(() => {

        if ((app_state.alertShowable == true) &&
            ui_state.showspinner === false &&
            (ui_state.active_tiles[0] === "otb" || ui_state.active_tiles[0] === "market")) {
            let apiAsOfDate;
            if (ui_state.active_tiles[0] === "otb") {
                apiAsOfDate = app_state.metas?.osr?.as_of;

                setApiAsOf(apiAsOfDate);
            }
            if (ui_state.active_tiles[0] === "market") {
                apiAsOfDate = app_state.metas?.market?.as_of;

                setApiAsOf(apiAsOfDate);
            }
            if (moment(apiAsOfDate).isBefore(app_state.as_of_date)) {
                dispatch({
                    type: UPDATE_STATE,
                    payload: {

                        ui_state: {
                            show_alert_popup: true
                        },
                        app_state: {
                            alertShowable: false
                        }
                    }
                });

            }

        }
    }, [ui_state.showspinner])


    useEffect(() => {
        if (!app_state.data_load) {
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        data_load: true,
                        kpi_load: true,
                    },
                    ui_state: {
                        showspinner: true
                    },
                }
            });
        }
    }, [])

    const handlePriceCalendarClose = () => setOpenPriceCalendar(false);
    const handleDailyFlashClose = () => {
        ui_state.show_flash_report = false;
        dispatch({
            type: 'UPDATE_STATE',
            payload: {
                app_state: {
                    data_load: false,
                },
                ui_state: {
                    show_flash_report: false,
                }
            }
        })

        // dispatch(updateAppState("change_flash_report", {
        //     app_state: {
        //         ...app_state,
        //         data_load: true,
        //     },
        //     ui_state: {
        //         ...ui_state
        //     },
        // }));


    }

    const handleAlertPopupClose = () => {
        ui_state.show_alert_popup = false;
        dispatch(updateAppState("toggleAlertPopup", {
            app_state: {
                ...app_state,
                data_load: true,
            },
            ui_state: {
                ...ui_state
            },
        }));

    }
    const handleGroupPricingClose = () => {
        ui_state.show_group_pricing = false
        dispatch({
            type: UPDATE_STATE,
            payload: {
                ui_state: {
                    show_group_pricing: false
                }
            }
        });
    };
    let historicalRange = null;

    const handleTileClick = useCallback((e) => {

        let curr_app_state = deepCopy(app_state);
        let curr_ui_state = deepCopy(ui_state);
        let workspace_controls = deepCopy(curr_app_state.workspace_controls);

        let id = e.currentTarget.id;
        let hasLockOnTitle = !hasFeature([id], 'tiles')
        ui_state.isLock = hasLockOnTitle;

        let currTile = TILES[id];
        let prevTile = TILES[curr_ui_state.active_tiles[0]];
        let { data_sources } = currTile;
        let { defaultMeasures } = currTile.chartConfig;

        let date_range_type = "rangeTileChange_" + id;

        defaultMeasures = defaultMeasures ? defaultMeasures : ['Occ', 'ADR'];

        if (compRef.current.timoutId) {
            clearTimeout(compRef.current.timoutId);
            compRef.current.timoutId = null;
        }

        let new_controlSupport = {};
        if (currTile.controlSupport && !curr_ui_state.active_tiles.includes(id)) {
            for (let key in curr_app_state.workspace_controls) {
                if (!currTile.controlSupport[key]) {
                    new_controlSupport[key] = false;
                    if (key === 'sdlyOn') {
                        workspace_controls[key] = false;
                        //app_state.workspace_controls[key] = false;
                    }
                    if (key === 'segmented_view') {
                        workspace_controls[key] = false;
                        //app_state.workspace_controls[key] = false;
                    }
                    if (key === 'scopeType' && curr_app_state.workspace_controls[key] === 'Dba') {
                        workspace_controls[key] = 'Date';
                        //app_state.workspace_controls[key] = 'Date';

                        //app_state.primary_stay_range = { ...app_state.primary_stay_range, ...app_state.historicalRange };

                        //date_range_type = 'scopeTypeChanged_Date';
                        //app_state.date_range_type = 'scopeTypeChanged_Date';
                    }
                } else {
                    new_controlSupport[key] = true;
                    if (curr_app_state.workspace_controls[key]) {
                        workspace_controls[key] = curr_app_state.workspace_controls[key];
                    }
                }
            }
        }

        //Abort in progress API calls
        if (!curr_ui_state.active_tiles.includes(id) && currTile.view !== "popup") {
            //Aborting active apis
            let abotControllers = ABORT_REQUEST_CONTROLLERS;
            abotControllers.forEach((val, key) => {
                // console.log('abotControllers', ABORT_REQUEST_CONTROLLERS.get(key));
                if (val && !['OSR', 'OSRContrainedForecast'].includes(key)) {
                    val.abort();
                }
            });
        }

        if (currTile.view === "popup") {
            if (id === "price_calendar") {
                setOpenPriceCalendar(true);
            }
            if (id === "daily_flash") {
                ui_state.show_flash_report = true;
            }
            if (id === "group") {
                ui_state.show_group_pricing = true
            }
            let payload = {
                ui_state: { ...ui_state }
            }
            // sendLog({ category: "dashboard_click", action: "popup_open" }, { ...current_state }, { ...payload })
            dispatch({
                type: UPDATE_STATE,
                payload: payload
            });
        }

        if (currTile.view !== "popup") {
            if (curr_ui_state.active_tiles[0] === 'otb') {
                let restrictions = getSeriesById("GetRestrictions");
                let restrictionsRoomType = getSeriesById("GetRestrictionsRoomType");
                restrictions.data = null;
                restrictionsRoomType.data = null;
                // debugger;
            }
            // curr_app_state.workspace_controls.disableExport = false;
            // workspace_controls.disableExport = false;
            let app_state = { workspace_controls: deepCopy(workspace_controls) };
            let ui_state = {};

            //Handler for single tile selection
            if (curr_ui_state.active_tiles.length === 1 && !curr_ui_state.active_tiles.includes(id)) {

                APP_CACHE.active_tiles = [id];
                active_tiles = [id];
                ui_state.active_tiles = active_tiles;
                activeTile = id;
                app_state.date_range_type = 'rangeTileChange_' + id;
                if (TILES[activeTile].chartConfig && TILES[activeTile].chartConfig.defaultMeasures) {
                    ui_state.left_measure = TILES[activeTile].chartConfig.defaultMeasures[0];
                    ui_state.right_measure = TILES[activeTile].chartConfig.defaultMeasures[1];
                    const measuresState = determineMeasureStates({
                        active_tiles: [activeTile],
                        userSelected_left_measure: TILES[activeTile].chartConfig.defaultMeasures[0],
                        userSelected_right_measure: TILES[activeTile].chartConfig.defaultMeasures[1],
                    });
                    ui_state.left_measures_list = measuresState.leftList;
                    ui_state.right_measures_list = measuresState.rightList;
                }


                setActiveTile(id);

                if (currTile.defaultView) {
                    ui_state.workspace_view = currTile.defaultView;
                } else {
                    ui_state.workspace_view = auth.profile.default_view.toLowerCase();

                    if (curr_app_state.user_view) {
                        ui_state.workspace_view = curr_app_state.user_view.toLowerCase();
                        // ui_state.workspace_view = app_state.user_view.toLowerCase();
                    }
                }

                // if(id === 'pickup'){
                //     workspace_controls.pickUpType = "Standard";
                // }

                if (id === 'flights' || id === 'market' || id === 'compset') {
                    workspace_controls.segmented_view = false;
                }
                if (id === 'flights') {
                    workspace_controls.segmentType = 'total';
                    workspace_controls.segmented_view = false;
                }
                if (curr_app_state.workspace_controls.isRoomTypePricing) {
                    workspace_controls.isRoomTypePricing = false;
                }

                if (id === 'pace') {

                    app_state.comp_stay_range = calculateCompStayRange({ primary_stay_range: curr_app_state.primary_stay_range, workspace_controls, property_id });

                    if (curr_app_state.workspace_controls.scopeType !== "Dba") {
                        //First set the historical range
                        // debugger;
                        workspace_controls.scopeType = "Dba";
                        historicalRange = deepCopy(curr_app_state.primary_stay_range);
                    }

                    compRef.current.timoutId = setTimeout(() => {
                        dispatch({
                            type: UPDATE_STATE, payload: {
                                app_state: {
                                    ...app_state,
                                    workspace_controls: { ...workspace_controls, disableExport: false },
                                    date_range_type: 'rangeTileChange_' + id,
                                    // primary_stay_range: { ...app_state.primary_stay_range, date_until: app_state.primary_stay_range.date_from },
                                    controlSupport: new_controlSupport,
                                    data_load: false, kpi_load: false
                                },
                                ui_state: {
                                    ...ui_state,
                                    showspinner: true
                                }
                            }
                        });
                    }, 800);
                }

                if (id !== 'pace') {

                    if (id === "hotel_summary") {
                        if (hasFeature(["hotel_summary"], 'tiles')) {
                            APP_CACHE.active_tiles = ["hotel_summary"];
                            ui_state.active_tiles = ["hotel_summary"];
                            active_tiles = ui_state.active_tiles;
                            ui_state.isLock = false;
                            workspace_controls.summaryType = "hotel_summary";

                        } else if (hasFeature(["market_summary"], 'tiles')) {
                            APP_CACHE.active_tiles = ["market_summary"];
                            ui_state.active_tiles = ["market_summary"];
                            active_tiles = ui_state.active_tiles;
                            ui_state.isLock = false;
                            workspace_controls.summaryType = "market_summary";
                        }
                    }

                    app_state.date_range_type = 'rangeTileChange_' + active_tiles.join();

                    if (curr_app_state.workspace_controls.scopeType === "Date" && id !== "pricing") {
                        if (curr_app_state.workspace_controls.isRoomTypePricing) {
                            workspace_controls.isRoomTypePricing = false;
                        }
                        //app_state.historicalRange = deepCopy(curr_app_state.primary_stay_range);
                    }

                    if (curr_app_state.workspace_controls.isRestrictionsRoomType) {
                        workspace_controls.isRestrictionsRoomType = false;
                    }
                    app_state.comp_stay_range = calculateCompStayRange({ primary_stay_range: curr_app_state.primary_stay_range, workspace_controls, property_id });

                    if (curr_app_state.workspace_controls.scopeType === "Dba") {
                        // debugger;
                        if (!new_controlSupport.scopeType) {
                            workspace_controls.scopeType = 'Date';
                            let { date_from } = curr_app_state.primary_stay_range
                            app_state.primary_stay_range = calculatePrimaryStayRange({ date_from, date_range: curr_app_state.historicalRange });
                        }
                        //historicalRange = {};
                    }
                    let segmentType = workspace_controls.segmentType;
                    if (id !== 'flights') {
                        segmentType = (prevTile.type) ? 'hotel' : workspace_controls.segmentType;
                        app_state.workspace_controls.segmentType = segmentType;
                        dispatch({
                            type: UPDATE_STATE, payload: {
                                app_state: {
                                    workspace_controls: { ...workspace_controls, disableExport: false, segmentType },
                                },
                                ui_state: {
                                    showspinner: true
                                }
                            }
                        });
                    }
                    compRef.current.timoutId = setTimeout(() => {
                        // delete app_state.primary_stay_range;
                        dispatch({
                            type: UPDATE_STATE, payload: {
                                app_state: {
                                    ...app_state,
                                    controlSupport: new_controlSupport,
                                    workspace_controls: { ...workspace_controls, disableExport: false },
                                    //date_range_type: date_range_type,
                                    data_load: false, kpi_load: false,
                                },
                                ui_state: {
                                    ...ui_state,
                                    showspinner: true
                                }
                            }
                        });

                    }, 800);

                }

                if (id !== 'pace') {
                    ui_state.showspinner = true;
                    // sendLog({ category: "dashboard_click", action: "change_tile" }, { ...current_state }, { app_state: { data_load: false, kpi_load: false }, ui_state })

                    dispatch({ type: UPDATE_STATE, payload: { app_state: { data_load: false, kpi_load: false }, ui_state } });
                } else if (id === 'pace') {
                    ui_state.showspinner = true;
                    workspace_controls.scopeType = "Dba";
                    // sendLog({ category: "dashboard_click", action: "change_tile" }, { ...current_state }, {
                    //     app_state: {
                    //         data_load: false, kpi_load: false,
                    //         workspace_controls: { ...workspace_controls }
                    //     },
                    //     ui_state
                    // })

                    dispatch({
                        type: UPDATE_STATE, payload: {
                            app_state: {
                                data_load: false, kpi_load: false,
                                workspace_controls: { ...workspace_controls }
                            },
                            ui_state
                        }
                    });
                }
                APP_CACHE.controls = deepCopy(workspace_controls);

                tileMergeLogic([id]);
            }
            //Handle for multiple tile selection
            if (curr_ui_state.active_tiles.length > 1) {
                // debugger;
                //Handling intellimerge conditions
                let thisIndex = curr_ui_state.active_tiles.indexOf(id);
                let activeTiles = deepCopy(curr_ui_state.active_tiles);
                if (thisIndex > -1) {
                    // ui_state.active_tiles.splice(thisIndex, 1);
                    activeTiles.splice(thisIndex, 1);
                    APP_CACHE.active_tiles = activeTiles;

                    if (activeTiles.length >= 1) {
                        let currTile = TILES[activeTiles[0]];
                        ui_state.left_measure = currTile.chartConfig.defaultMeasures[0];
                        ui_state.right_measure = currTile.chartConfig.defaultMeasures[1];
                        const measuresState = determineMeasureStates({
                            active_tiles: activeTiles,
                            userSelected_left_measure: currTile.chartConfig.defaultMeasures[0],
                            userSelected_right_measure: currTile.chartConfig.defaultMeasures[1],
                        });
                        ui_state.left_measures_list = measuresState.leftList;
                        ui_state.right_measures_list = measuresState.rightList;
                    }
                    // debugger;
                    // if([2,3].includes(activeTiles.length) && in_array(activeTiles, ['compset', 'market_sell_rates', 'pricing'])){
                    if (in_array(activeTiles, ['compset', 'market_sell_rates', 'pricing'])) {
                        //workspace_controls.segmented_view = false;
                        // workspace_controls.segmentType = "hotel";
                    }
                    APP_CACHE.controls = deepCopy(workspace_controls);
                    dispatch({
                        type: UPDATE_STATE, payload: {
                            app_state: {
                                //...app_state,
                                data_load: false, kpi_load: false,
                                date_range_type: 'rangeTileChange_' + activeTiles.join(''),
                                controlSupport,

                                workspace_controls: { ...app_state.workspace_controls, ...workspace_controls },
                            },
                            ui_state: {
                                ...ui_state,
                                active_tiles: activeTiles,
                                showspinner: true
                            }
                        }
                    });
                }
                if (thisIndex === -1) {
                    let app_state = {}; let ui_state = {}; let workspace_controls = {};
                    activeTiles.splice(0);
                    activeTiles.push(id);
                    APP_CACHE.active_tiles = activeTiles;
                    //ui_state.active_tile = activeTiles;

                    //setting up chart measures
                    let currTile = TILES[activeTiles[0]];
                    if (currTile.chartConfig.defaultMeasures) {
                        ui_state.left_measure = currTile.chartConfig.defaultMeasures[0];
                        ui_state.right_measure = currTile.chartConfig.defaultMeasures[1];
                        const measuresState = determineMeasureStates({
                            active_tiles: activeTiles,
                            userSelected_left_measure: currTile.chartConfig.defaultMeasures[0],
                            userSelected_right_measure: currTile.chartConfig.defaultMeasures[1],
                        });
                        ui_state.left_measures_list = measuresState.leftList;
                        ui_state.right_measures_list = measuresState.rightList;
                    } else {
                        // Added to fix flight and budget default views.
                        if (currTile.defaultView) {
                            ui_state.workspace_view = currTile.defaultView;
                        }
                    }

                    let noSegments = ['market_sell_rates', 'flights', 'compset', 'hotel_summary', 'market_summary'];
                    if (noSegments.includes(activeTiles[0])) {
                        workspace_controls.segmented_view = false;
                    }

                    if (id === 'pace' && curr_app_state.workspace_controls.scopeType !== 'Dba') {
                        let scopeType = curr_app_state.workspace_controls.scopeType;
                        workspace_controls.scopeType = scopeType !== 'Dba' ? 'Dba' : scopeType;
                        APP_CACHE.controls.scopeType = workspace_controls.scopeType;
                        //app_state.historicalRange = deepCopy(curr_app_state.primary_stay_range);
                        app_state.primary_stay_range = deepCopy(curr_app_state.primary_stay_range);
                        app_state.comp_stay_range = deepCopy(curr_app_state.comp_stay_range);
                        app_state.primary_stay_range.date_until = app_state.primary_stay_range.date_from;
                        app_state.comp_stay_range.date_until = app_state.comp_stay_range.date_from;
                    }

                    if (id !== 'pace') {
                        let scopeType = curr_app_state.workspace_controls.scopeType;
                        if (scopeType !== 'Date') {
                            APP_CACHE.controls.scopeType = 'Date';
                            // workspace_controls.scopeType = 'Date';
                            // if (!new_controlSupport.scopeType) {
                            workspace_controls.scopeType = 'Date';
                            let { date_from } = curr_app_state.primary_stay_range
                            //app_state.historicalRange = deepCopy(curr_app_state.primary_stay_range);
                            app_state.primary_stay_range = calculatePrimaryStayRange({ date_from, date_range: curr_app_state.historicalRange });
                            app_state.comp_stay_range = calculateCompStayRange({ primary_stay_range: app_state.primary_stay_range, workspace_controls, property_id });
                            // }
                        }
                    }

                    app_state.date_range_type = "rangeTileChange_" + id;
                    // debugger;
                    if (id === "hotel_summary") {
                        if (hasFeature(["hotel_summary"], 'tiles')) {
                            APP_CACHE.active_tiles = ["hotel_summary"];
                            ui_state.active_tiles = ["hotel_summary"];
                            active_tiles = ui_state.active_tiles;
                            ui_state.isLock = false;
                            workspace_controls.summaryType = "hotel_summary";

                        } else if (hasFeature(["market_summary"], 'tiles')) {
                            APP_CACHE.active_tiles = ["market_summary"];
                            ui_state.active_tiles = ["market_summary"];
                            active_tiles = ui_state.active_tiles;
                            ui_state.isLock = false;
                            workspace_controls.summaryType = "market_summary";
                        }
                    }
                    if (id === 'flights') {
                        workspace_controls.segmentType = 'total';
                        workspace_controls.segmented_view = false;
                    }
                    dispatch({
                        type: UPDATE_STATE,
                        payload: {
                            app_state: {
                                ...app_state,
                                controlSupport: new_controlSupport,
                                workspace_controls: {
                                    ...curr_app_state.workspace_controls,
                                    ...workspace_controls
                                },
                                data_load: false, kpi_load: false
                                //primary_stay_range: { ...app_state.primary_stay_range },
                                //comp_stay_range: { ...app_state.comp_stay_range }
                            },
                            ui_state: {
                                ...ui_state,
                                showspinner: true
                            }
                        }
                    })
                }
                //making control options available on the basis of tiles                
            }
        }

        // }, 50);
    });

    const onTileMergeHandler = useCallback((tile) => {
        const newTile = tile;
        let old_app_state = deepCopy(app_state);
        let old_ui_state = deepCopy(ui_state);
        let active_tiles = deepCopy(ui_state.active_tiles);
        let old_tiles = deepCopy(ui_state.active_tiles);
        let firstTile = TILES[active_tiles[0]];
        const event_metadata = {
            ...primary_stay_range,
            property_id, property_name,
            segmentType,
            data_view: user_view,
        };
        let comp_stay_range = {};
        //Do merging actions only if existing tile has mergeable true
        if (!active_tiles.includes(newTile.id) && firstTile.mergeable) {

            let id = newTile.id;
            let currTile = TILES[id];
            if (id === 'pace') {
                app_state.comp_stay_range = calculateCompStayRange({ primary_stay_range: app_state.primary_stay_range, workspace_controls: app_state.workspace_controls, property_id });
            }
            let { defaultMeasures } = currTile.chartConfig;

            const measuresState = determineMeasureStates({
                active_tiles: [id],
                userSelected_left_measure: "Occ",
                userSelected_right_measure: "ADR",
            });

            ui_state.left_measures_list = measuresState.leftList;
            ui_state.right_measures_list = measuresState.rightList;

            if (!defaultMeasures) {
                ui_state.left_measure = 'Occ';
                ui_state.right_measure = ui_state.right_measure;
            } else if (defaultMeasures && !defaultMeasures[0] && !ui_state.left_measure) {
                ui_state.left_measure = 'Occ';
                ui_state.right_measure = defaultMeasures[1];
            } else if (defaultMeasures && defaultMeasures[0]) {
                ui_state.left_measure = defaultMeasures[0];
                ui_state.right_measure = defaultMeasures[1];
            }
            // debugger;

            // if (ui_state.active_tiles[0] === "market_sell_rates") {
            //     active_tiles.shift();
            //     active_tiles.push(newTile.id);
            //     active_tiles.push("market_sell_rates");
            //     app_state.workspace_controls.segmented_view = false;
            // }else if (ui_state.active_tiles[0] === "compset") {
            //     active_tiles.shift();
            //     active_tiles.push(newTile.id);
            //     active_tiles.push("compset");
            //     app_state.workspace_controls.segmented_view = false;
            // }else if (ui_state.active_tiles[0] === "pricing") {
            //     active_tiles.shift();
            //     active_tiles.push(newTile.id);
            //     active_tiles.push("pricing");
            //     app_state.workspace_controls.segmented_view = false;
            if (in_array(active_tiles, ['pricing']) && ['compset', 'market_sell_rates'].includes(newTile.id)) {
                if (['otb', 'pickup', 'pace', 'forecast'].includes(newTile.id)) {
                    active_tiles.splice(0, 0, newTile.id);
                } else {
                    active_tiles.push(newTile.id);
                }
                let princing_idx = active_tiles.indexOf('pricing');
                active_tiles.splice(princing_idx, 1);
                active_tiles.push("pricing");
            }

            if (!in_array(active_tiles, ['market']) && in_array(active_tiles, ['compset', 'market_sell_rates', 'pricing']) && ['otb', 'pickup', 'pace', 'forecast', 'market'].includes(newTile.id)) {
                active_tiles.splice(0, 0, newTile.id);
                let princing_idx = active_tiles.indexOf('pricing');
                if (princing_idx > -1) {
                    active_tiles.splice(princing_idx, 1);
                    active_tiles.push("pricing");
                }
            } else {
                active_tiles.push(newTile.id);
            }
            active_tiles = getUnique(active_tiles);

            /** move market tile to first position  */
            // if(active_tiles.includes('market')){
            //     let marketTileIndex = active_tiles.indexOf('market');
            //     active_tiles.splice(marketTileIndex, 1);
            //     active_tiles.unshift('market')
            // }
            /** end move market tile to first position  */
            // let apc = APP_CACHE; debugger;
            tileMergeLogic(active_tiles);
            // app_state.date_range_type = 'rangeTileChange_' + active_tiles.join('-');
            app_state.controlSupport = controlSupport;
            ui_state.active_tiles = active_tiles;
            APP_CACHE.active_tiles = active_tiles;
            ui_state.showspinner = true;
            if (app_state.data_load) app_state.data_load = false;

            if (active_tiles.length > 1 && in_array(active_tiles, ['pricing'])) {
                app_state.workspace_controls.isRoomTypePricing = false;
            }

            // debugger;
            // dispatch({
            //     type: UPDATE_STATE,
            //     payload: {
            //         app_state: { ...app_state },
            //         ui_state: {
            //             ...ui_state
            //         },
            //     }
            // })
            dispatch(
                updateAppState("change_active_tiles", {
                    app_state: { ...app_state },
                    ui_state: {
                        ...ui_state
                    },
                },)
            );
            if (active_tiles.length > 1) {
                window.analyze.track("tile_merged", {
                    "Previous Tiles": old_tiles,
                    "Primary Tile": firstTile.id,
                    "Current Tiles": active_tiles,
                    "Intellimerged Tiles": active_tiles.slice(0).join(","),
                    ...event_metadata
                }, { old_app_state, old_ui_state }, current_state);
            }

        } else {
            console.warn(firstTile.title, "is not mergeable");
        }
    });

    const tileMergeLogic = useCallback((active_tiles = []) => {
        // debugger;
        let activeTile = APP_CACHE.active_tiles.length ? APP_CACHE.active_tiles : ui_state.active_tiles; //ui_state.active_tiles;
        let tileDetails = getTileById(activeTile);
        let { segmented_view, scopeType, sdlyOn } = app_state.workspace_controls;

        let compatibleTiles = [];
        let compatibility = {};
        let tileList = ['pace', 'otb', 'pickup', 'budget', 'pricing',
            'forecast', 'market', 'compset', 'market_sell_rates', 'restrictions'];
        let dateView = ['pace', 'otb', 'pickup', 'budget', 'pricing',
            'forecast', 'market', 'compset', 'market_sell_rates', 'restrictions'];
        let segmentedView = ['pace', 'otb', 'pickup', 'forecast', 'pricing', 'compset', 'market_sell_rates', "market"];
        let dbaView = ['pace', 'market', 'compset'];
        const { permissions, index } = getPermissions(profile.property_details, app_state.property_id);
        compatibleTiles = dateView;
        if (segmented_view) {
            compatibleTiles = segmentedView;
            //If pace selected in segmented + DBA 
            let indexOfPace = compatibleTiles.indexOf('pace');
            if (indexOfPace > -1) {
                dbaView = ['pace']
            }
            if (active_tiles.includes('market')) {
                let indexOfPace = compatibleTiles.indexOf('pace');
                if (indexOfPace > -1) {
                    compatibleTiles.splice(indexOfPace, 1);
                }
            }

            if (active_tiles && segmented_view && active_tiles.includes('pace')) {
                let indexOfMarket = compatibleTiles.indexOf('market');
                if (indexOfMarket > -1) {
                    compatibleTiles.splice(indexOfMarket, 1);
                }
            }
        }

        if (scopeType === 'Dba') {
            compatibleTiles = dbaView;
        }
        if (sdlyOn) {
            let indexOfPace = compatibleTiles.indexOf('pace');
            // debugger;
            if (indexOfPace > -1) {
                compatibleTiles.splice(indexOfPace, 1);
            }
        }
        if (ui_state.active_tiles.length === 1) {
            activeTile = activeTile[0];
            if (['market'].includes(activeTile)) {
                if (segmented_view) {
                    compatibleTiles = ['market', 'pickup', 'otb', 'forecast', 'compset', 'pricing', 'market_sell_rates'];
                } else {
                    if (scopeType === 'Dba') {
                        dbaView = ['market', 'pace', 'compset'];
                    }
                }
            }

            if (['pace'].includes(activeTile)) {
                if (segmented_view) {
                    if (scopeType === 'Date') {
                        segmentedView = ['pace', 'forecast', 'budget'];
                        dbaView = [];
                    }
                    if (scopeType === 'Dba') {
                        segmentedView = [];
                        dbaView = [];
                    }

                } else {
                    if (scopeType === 'Date') {
                        dateView = ['pace', 'forecast', 'pricing'];
                    }
                    if (scopeType === 'Dba') {
                        segmentedView = [];
                        dbaView = ['pace', 'compset', 'market'];
                    }
                }
            }
        }

        tileList.map((tile) => {
            if (!compatibleTiles.includes(tile)) {
                compatibility[tile] = false;
            } else {
                compatibility[tile] = true;
            }
        });

        if (['budget'].includes(activeTile) && permissions.budget === 0) {
            dateView.map((tile) => {
                compatibility[tile] = false;
            });
        }

        if (['restrictions', 'hotel_summary', 'flights'].includes(activeTile) ||
            (tileDetails && tileDetails.type && ['report', 'summary_report'].includes(tileDetails.type))) {
            tileList.map((tile) => {
                compatibility[tile] = false;
            });
        }

        setMergeCompatibilty((state) => {
            return { ...state, ...compatibility };
        });
    });

    const getActiveTileClass = useCallback((tile) => {
        let activeTileClass = ''
        //This line is added to instantly show active tile on random and quickly clicking
        let active_tiles = APP_CACHE.active_tiles ? APP_CACHE.active_tiles : ui_state.active_tiles;

        if (tile.id === "hotel_summary") {
            if (active_tiles[0] === "hotel_summary" || active_tiles[0] === "market_summary") {
                activeTileClass = `${activeTileClass} active`
            } else {
                activeTileClass = `${activeTileClass} `
            }
        } else if (active_tiles.includes(tile.id)) {
            activeTileClass = `${activeTileClass} active`
        }
        return activeTileClass;
    });
    const handleFullScreenLock = () => {
        ui_state.fullScreenLockData = { status: false, title: '', module: '' }
        dispatch({
            type: UPDATE_STATE,
            payload: {
                ui_state: { ...ui_state }
            }
        });
    }
    const handelRequestSupport = () => {
        ui_state.fullScreenLockData = { ...ui_state.fullScreenLockData, status: false, }
        dispatch({
            type: UPDATE_STATE,
            payload: {
                ui_state: { ...ui_state }
            }
        });
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <ThemeProvider theme={theme}>
                <AtuoLogoutTimer timeout={15} graceTime={5}>
                    <FullScreenLock
                        userName={user.fullname}
                        fullScreenLockData={fullScreenLockData}
                        handelClose={handleFullScreenLock}
                        handelRequestSupport={handelRequestSupport}
                        propertyName={app_state.property_name}
                    />
                    <Box sx={{ backgroundColor: "dark.lighter", minHeight: "100%" }}>
                        <Header />
                        <Container
                            maxWidth="xxl"
                            className="container-control"
                            sx={{
                                pt: { xs: "65px", sm: "55px", md: "85px", lg: "55px" },
                                pl: 1,
                                pr: 1,
                            }}
                        >
                            <Grid
                                sx={{ mb: { sm: 10, lg: 0 }, minHeight: { xs: "100%" } }}
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch"
                            >
                                <Grid item lg={11} xs={12} className="work-space-wrapper">
                                    <Workspace id="workspace" onTileMerge={onTileMergeHandler} />
                                </Grid>
                                <Grid
                                    sx={{ display: { xs: "none", lg: "flex" }, flexWrap: "wrap" }}
                                    item
                                    lg={1}
                                    xs={6}
                                >
                                    {tilesGroupOne.map((tile) => {
                                        let hasFeatureParameters = [[tile.id], 'tiles']
                                        if (tile.id === 'hotel_summary') {
                                            hasFeatureParameters = [['hotel_summary', 'market_summary'], 'tiles', 'some']
                                        }
                                        return (
                                            <Grid
                                                item
                                                lg={12}
                                                xs={4}
                                                id={tile.id}
                                                key={tile.id}
                                                onClick={handleTileClick}
                                                className={`${getActiveTileClass(tile)} ${hasFeature(...hasFeatureParameters) ? "" : "lock-tile"}`}
                                            >
                                                <Tile isDraggable={hasFeature(...hasFeatureParameters)} tile={tile} activeTiles={active_tiles} activeTile={activeTile} compatibility={mergeCompatibilty[tile.id]} />
                                            </Grid>
                                        )
                                    }

                                    )}
                                    <Modal
                                        open={openPriceCalendar}
                                        onClose={handlePriceCalendarClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <PriceCalendar handleClose={handlePriceCalendarClose} />
                                    </Modal>
                                    <Modal
                                        open={ui_state.show_alert_popup}
                                        onClose={handleAlertPopupClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                        className={"asof-alert-popup modal-style " + (ui_state.show_flash_report && "flash-open")}>
                                        <AlertPopup handleClose={handleAlertPopupClose} apiAsOfDate={apiAsOfDate} propertyAsOf={app_state.as_of_date} />
                                    </Modal>
                                    {/* Daily Flash Popup  */}
                                    <Modal
                                        open={ui_state.show_flash_report}
                                        onClose={handleDailyFlashClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                        className="modal-style">
                                        <DailyFlash handleClose={handleDailyFlashClose} />
                                    </Modal>

                                    {/* End Daily Flash Popup */}



                                    <Modal
                                        open={ui_state.show_group_pricing}
                                        onClose={handleGroupPricingClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description" className="modal-style">
                                        <GroupPricing handleClose={handleGroupPricingClose} />
                                    </Modal>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                sx={{ pb: 4, display: { xs: "none", lg: "flex" } }}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                {tilesGroupTwo.map((tile, index) => (
                                    <Grid
                                        item
                                        key={index}
                                        lg={!tile.usedSpace ? 2 : 1 * tile.usedSpace}
                                        xs={4}
                                        id={tile.id}
                                        onClick={handleTileClick}
                                        className={`${getActiveTileClass(tile)} ${hasFeature([tile.id], 'tiles') ? "" : "lock-tile"}`}
                                    >
                                        {!active_tiles.includes('hotel_summary') && !active_tiles.includes('market_summary') && <Tile isDraggable={hasFeature([tile.id], 'tiles')} tile={tile} activeTiles={active_tiles} activeTile={activeTile} compatibility={mergeCompatibilty[tile.id]} />}
                                        {(active_tiles.includes('hotel_summary') || active_tiles.includes('market_summary')) && <Tile isDraggable={hasFeature([tile.id], 'tiles')} tile={tile} activeTiles={active_tiles} activeTile={activeTile} compatibility={mergeCompatibilty[tile.id]} />}
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>
                    </Box>
                    <DashboardFooter />
                </AtuoLogoutTimer>
            </ThemeProvider>
        </ErrorBoundary >
    );
}

export default memo(DashboardLayout); 