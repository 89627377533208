import React, { useRef, useState, useEffect, useCallback } from 'react';
import moment from "moment";
import { TableVirtuoso } from 'react-virtuoso'
import { useDispatch, useSelector } from "react-redux";

import { Paper, Tooltip, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Filter from "./Filter";
import { UPDATE_STATE } from "../../actions/types";
import { sortData, getSortedColumn, getFilteredColumns, ColumnSorting, getFilteredData } from "./TableHelperFunctions";
import {
    generateTable, downloadcsvwithTable, round, getNumDaysFromDateRange, safeReference, dateDiff,
    getCompsetMedian, isEmptyObj, getFromState, getTransformedSegments, downloadReportToExcel,
    numberFormatForReport, truncate, sortStringArray, dateFormat, deepCopy, processColumnsNew, getAppData,
    getStayToSdly, getStayToComp, formattedCompDateCalc, isDateInRange
} from "../../app/util";
import { updateAppState, getPropertyInfo, restrictionsAvailable } from "../../actions/appstate";
import { TILES } from "../../app/config";
import { transformRestrictionsRoomTypeData } from "./restrictionsData";
import RoomTypeRestrictionFilter from "./RoomTypeRestrictionFilter";
import RestrictionTableCell from './RestrictionTableCell';
import { primaryHeaderDisplay } from './table-functions';
import { processColumns, processData } from "./processData";
import TableToExcel from '@linways/table-to-excel';
import NotesPopup from './NotesPopup';
import EventsPopup from './EventsPopup';
const PrimaryHeaderCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#606060",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const SecondaryHeaderBlueCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3fa7cd",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#ebebeb",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
function TableRestrictionsRoomType({
    compDisplayData,
}) {
    const { auth } = useSelector(appState => appState);
    // debugger; 
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { sdlyOn, workspace_controls, primary_stay_range, room_settings, notes, eventList, eventListSDLY } = app_state;
    const { segmented_view } = workspace_controls;
    const { active_tiles, table_filters } = ui_state;
    let filteredDow = table_filters.table_common.dow.map(d => d.code);

    const tableVirtuoso = useRef(null);
    const [rows, setRows] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [compLoadStatus, setCompLoadStatus] = useState('loading');
    const [compLoad, setCompLoad] = useState(false)
    const [propertyInfo, setPropertyInfo] = useState(getPropertyInfo(auth.profile.property_details,
        app_state.property_id));
    const [restrictionsHouseData, setRestrictionsHouseData] = useState([])
    const [reloadGrid, setReloadGrid] = useState("");
    const [allChecked, setAllChecked] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        let isSubscribed = true;
        let property_info = getPropertyInfo(auth.profile.property_details, app_state.property_id);
        setPropertyInfo(property_info);
        if (app_state.workspace_controls.downloadExportButton === true) {
            let name = moment(app_state.as_of_date).format('MMM DD YYYY') + " Restrictions";
            downloadRMTypeResExcel(name, propertyInfo.name, "mainTable");
            dispatch(updateAppState("toggleChartTableView", {
                app_state: {
                    workspace_controls: { ...app_state.workspace_controls, downloadExportButton: false, }
                }
            }));
        }


        // debugger; 
        if (isSubscribed) {

            if (app_state.data_load) {
                dispatch(
                    updateAppState("data_load", {
                        app_state: {
                            data_load: false,
                        },
                    })
                );
            }
            if (compLoad && reloadGrid !== "applySorting") {
                setupData();
            }

            if (!ui_state.showspinner) {
                if (compDisplayData && compDisplayData.constructor.name === 'Array' && compDisplayData.length) {
                    // Transform and build data for restrictions
                    let start = moment().format("x");
                    const restrictionsHouseData = compDisplayData.find((item) => item.data_id === "GetRestrictions");
                    let transformedData = transformRestrictionsRoomTypeData(app_state, auth, compDisplayData,
                        {
                            tile: "restrictions",
                        });
                    // debugger;
                    console.log("time taken transformRestrictionsData:", moment().format("x") - start);
                    setDisplayData(transformedData);
                    setRestrictionsHouseData(restrictionsHouseData);
                    setCompLoad(true);

                }
            }
        }
        return () => {
            // app_state.savedRestrictions.splice(0);
            //app_state.updatedRestrictions.splice(0);
            return (isSubscribed = false);
        }
    }, [ui_state.showspinner,
    workspace_controls.downloadExportButton,
    app_state.property_id, compLoad,
        reloadGrid,
        // app_state.updatedRestrictions.length,
        // app_state.roomTypeRestrictions.length
    ]);

    useEffect(() => {
        let isSubscribed = true;

        if (reloadGrid === "applySorting" && compRef.current.data_setup) {
            let finalRows = [];
            let sortedColumn = getSortedColumn(compRef.current.columns);
            let data_setup = deepCopy(compRef.current.data_setup);
            data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });
            data_setup = compRef.current.data_setup.filter((item) => item[0]['date'] === '' || filteredDow.includes(moment(item[0].date).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
            data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });
            data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
            // setDataSetup(data_setup);
            compRef.current.data_setup = deepCopy(data_setup);
            // setReloadGrid('appliedSorting');
            // setCompLoadStatus('sortedCols');

        }

        if (compLoad && displayData && displayData.length > 0 && reloadGrid !== "applySorting") {

            setupData()
            // setAllChecked(false);
        }

        return () => (isSubscribed = false);
    }, [reloadGrid])
    useEffect(() => {
        let isSubscribed = true;
        if (ui_state.updatedFilter === true) {
            updateRoomFilter()
        }
        return () => {
            isSubscribed = false
        }
    }, [ui_state.updatedFilter])
    const getRestrictionsArray = (restrictions) => {
        const array = [];
        if (restrictions.cta) {
            array.push("CTA");
        }
        if (restrictions.ctd) {
            array.push("CTD");
        }
        if (restrictions.close) {
            array.push("CLS");
        }
        if (restrictions.min_los > 1) {
            array.push("MN " + restrictions.min_los);
        }
        if (restrictions.max_los > 1) {
            array.push("MX " + restrictions.max_los);

        }

        return array;
    }
    const downloadRMTypeResExcel = (name, selectedPropertyName, tableID) => {
        let exportFileName = name + "_" + selectedPropertyName;
        //let table = document.getElementById(tableID);
        const table = document.createElement('table');
        const { current } = compRef;
        const header = document.querySelector("#mainTable .table-head").cloneNode(true)

        // Add same style for row 1 for Notes and Events
        const firstRow = header.querySelectorAll('tr')[0];
        if (firstRow) {
            // Add the Notes column heading to the first row of the header
            const newTh = firstRow.querySelector('th').cloneNode(true);
            newTh.textContent = 'Notes';
            newTh.removeAttribute('colspan');
            firstRow.appendChild(newTh);

            // Add the Events column heading to the first row of the header
            const eventsTh = firstRow.querySelector('th').cloneNode(true);
            eventsTh.textContent = 'Events';
            eventsTh.removeAttribute('colspan');
            firstRow.appendChild(eventsTh);
        }

        const secondRow = header.querySelectorAll('tr')[1];
        if (secondRow) {
            // Add the new "Note TY" column heading to the second row of the header
            const newTh = secondRow.querySelector('th').cloneNode(true); // Clone an existing th to retain styles
            newTh.textContent = 'Note TY';
            secondRow.appendChild(newTh);

            // Add the new "Event TY" column heading to the second row of the header
            const eventsTh2 = secondRow.querySelector('th').cloneNode(true);
            eventsTh2.textContent = 'Event TY';
            secondRow.appendChild(eventsTh2);
        }

        table.append(header);
        let data = [];
        let columnNodes = document.querySelectorAll(".filter-position-r .col-head-label");
        let colNames = [];
        columnNodes.forEach(item => {
            colNames.push(item.textContent);
        })
        let colNameHash = {};
        let data_cols = [];
        current.columns.forEach(col => {
            col.subcolumns.forEach(subcol => {
                if (colNames.includes(subcol.display)) {
                    colNameHash[subcol.value] = subcol.display;
                    data_cols.push(subcol.value);
                }
            })
        })
        let data_setupExcel = deepCopy(current.data_setup);
        data_setupExcel.forEach((dItem, idx) => {
            let dataPoint = {};
            let rowDate = dItem[0].date;
            dItem.forEach((item, index) => {
                if (item.id.includes("restrictions")) {
                    if (item.cur) {
                        item.cur = getRestrictionsArray(item.cur).join(",")
                    }
                    if (item.rec) {
                        item.rec = getRestrictionsArray(item.rec).join(",")
                    }
                }
                Object.keys(item).forEach(key => {
                    if (colNameHash[key]) {
                        let value = (item[key]) ? item[key] : "";
                        if (key === "Rooms") {
                            value = (item[key]) ? item[key] : 0;
                        }

                        dataPoint[key + "-" + index] = value;
                    }
                })
            })

            if (segmented_view) {
                // Add the new columns here if the row is Total
                if (dItem[0].segment === "Total") {
                    dataPoint["Note TY"] = calculateNewColumnValue(dItem);
                    dataPoint["Event TY"] = calculateEventTY(dItem);
                }
            }
            else {
                // Add the new columns here if the row is not Total
                if (!dItem[0].TotalRow) {
                    dataPoint["Note TY"] = calculateNewColumnValue(dItem);
                    dataPoint["Event TY"] = calculateEventTY(dItem);
                }
            }
            data.push(dataPoint);
        })
        const tBody = generateTable(table, data)
        if (app_state.workspace_controls.exportType == 'csv') {
            downloadcsvwithTable(table, exportFileName, compRef.current.columns);
        } else {
            TableToExcel.convert(table, {
                name: exportFileName + ".xlsx",
                sheet: {
                    name: name.toUpperCase()
                }
            });
        }

    }

    const calculateNewColumnValue = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        //const formatted_comp_date = sdlyOn ? dateFormat(getStayToSdly(app_state, formatted_stay_date), "YYYY-MM-DD") : dateFormat(getStayToComp(app_state, formatted_stay_date), "YYYY-MM-DD");
        let dataForDate = notes && notes.find(item => item.stay_date === formatted_stay_date);
        let current_stay_date = dateFormat(formatted_stay_date, "YYYY/MM/DD")
        //let current_comp_date = dateFormat(formatted_comp_date, "YYYY-MM-DD")

        if (!dataForDate) {
            dataForDate = {
                note: '',
                last_edited_by: '',
                last_edited_at: ''
            }
        }
        let noteData = "";
        if (dataForDate.note !== '') {
            noteData = current_stay_date + " - " + dataForDate.note;
        }
        return noteData
    }
    const calculateNewColumnValue2 = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        const formatted_comp_date = formattedCompDateCalc(sdlyOn, formatted_stay_date, app_state);
        // let formatted_comp_date = ''
        // if(sdlyOn && !workspace_controls.clearComparision){
        //     formatted_comp_date = dateFormat(getStayToSdly(app_state, formatted_stay_date), "YYYY-MM-DD");
        // }
        // if(!sdlyOn && workspace_controls.clearComparision){
        //     formatted_comp_date = dateFormat(getStayToComp(app_state, formatted_stay_date), "YYYY-MM-DD")
        // }
        let dataForDate = notes && notes.find(item => item.stay_date === formatted_comp_date);
        let current_stay_date = dateFormat(formatted_comp_date, "YYYY/MM/DD")
        //let current_comp_date = dateFormat(formatted_comp_date, "YYYY-MM-DD")

        if (!dataForDate) {
            dataForDate = {
                note: '',
                last_edited_by: '',
                last_edited_at: ''
            }
        }
        let noteData = "";
        if (dataForDate.note !== '') {
            noteData = current_stay_date + " - " + dataForDate.note;
        }
        return noteData
    }
    const calculateEventTY = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        let current_stay_date = dateFormat(formatted_stay_date, "YYYY/MM/DD")

        const filteredEventList = eventList.filter(event =>
            isDateInRange(formatted_stay_date, event.start_date, event.end_date) && event.is_ignored === false
        );

        let EventData = filteredEventList.map(event => event.title).join(", ");

        return EventData
    }


    const setupData = () => {

        let columns = processColumnsNew(compRef.current.columns);
        compRef.current.columns = columns;
        let data_cols = [];
        columns.map((i) => {
            i.subcolumns.map((x) => {
                data_cols.push(x.value);
            });
        });
        compRef.current.data_cols = data_cols;
        let data_setup = processData(columns, displayData, {
            auth,
            app_state,
            ui_state,
        });
        data_setup = data_setup.map(data_item => {
            const { date } = data_item[0];
            const updatedRestriction = app_state.roomTypeRestrictions.find(date_data => date_data.stay_date === date);
            if (!isEmptyObj(updatedRestriction)) {
                data_item = data_item.map(item => {
                    const roomtype = item.id.replace('_restrictions', '')
                    if (updatedRestriction.hasOwnProperty(roomtype)) {
                        //item.cur = updatedRestriction[roomtype].entry
                        item.updated_cur = updatedRestriction[roomtype].entry
                    }
                    return item;
                })
            }
            return data_item;
        });

        let t = compRef.current.sortedColumn;
        // debugger;
        let sortedColumn = getSortedColumn(compRef.current.columns);
        data_setup = sortData({
            data: data_setup, sortBy: sortedColumn.value,
            parent: sortedColumn.parent, order: sortedColumn.sorted
        });


        compRef.current.filteredColumns = getFilteredColumns(compRef.current.columns);
        data_setup = getFilteredData({ data: data_setup, columns });
        data_setup = data_setup.filter(data_item => {
            const { date } = data_item[0];
            if (dateDiff(date, app_state.as_of_date) > -1) {
                return data_item;
            }
        })

        compRef.current.data_setup = deepCopy(data_setup);
        setCompLoadStatus('loaded');

    }
    const setupRoomTypeInputs = () => {

    }
    const getNeededColumns = () => {
        let startDate = app_state.primary_stay_range.date_from;
        let endDate = app_state.primary_stay_range.date_until;
        // const restrictionsEnabled = getAppData(auth, app_state).restrictions.enable;

        console.log(ui_state.selectedRoomPricingCategory);
        console.log("selectedRoomPricingRoomCodes", ui_state.selectedRoomPricingRoomCodes);

        let finalColumns = [];
        let tableConfig = deepCopy(TILES['restrictions']['tableConfig']);
        let columns = tableConfig['columns'];
        columns = columns.filter(col => col.id === "staydates");
        let sdlyColumns = deepCopy(tableConfig['sdlyOn_columns']);
        const restrictionColumnTemplate = tableConfig["roomTypeColumnTemplate"];
        finalColumns = [...finalColumns, ...columns];
        room_settings.forEach(room => {
            if (ui_state.selectedRoomPricingRoomCodes.includes(room.room_code)) {
                const roomTypeColumn = { ...restrictionColumnTemplate };
                roomTypeColumn.room_type = room.room_code;
                roomTypeColumn.id = room.room_code + "_restrictions";
                roomTypeColumn.display = {
                    is_base: room.is_base,
                    room_inventory: room.inventory,
                    room_code: room.room_code,
                    room_display_name: room.display_name
                }
                finalColumns.push(roomTypeColumn);
            }

        })


        if (sdlyOn && sdlyColumns) {
            finalColumns = [...finalColumns, ...sdlyColumns];
        }
        finalColumns.sort(function (a, b) { return a.order - b.order });
        finalColumns.map((item, index) => {
            let subColumns = item.subcolumns;
            subColumns.sort(function (a, b) { return a.order - b.order });
        })

        let sortedColumns = [...finalColumns];
        // debugger;
        return sortedColumns;
    }
    const compRef = useRef({
        itemDragged: {},
        itemDroppedOn: {},
        columns: getNeededColumns(),
        data_setup: [],
    });
    const updateRoomFilter = () => {
        dispatch({
            type: UPDATE_STATE,
            payload: {
                ui_state: {
                    updatedFilter: false,
                    // showspinner:false
                }
            },
        });
        compRef.current.columns = getNeededColumns({ auth, app_state, ui_state });
        setupData();


    }
    const onDragStartHandler = (columParam) => {
        return (event) => {
            // event.preventDefault();
            event.dataTransfer.setData("columnMeta", JSON.stringify(columParam));
            compRef.current.itemDragged = deepCopy(columParam);
        }
    };
    const columnToggleHandler = (item) => {
        let cols = compRef.current.columns;
        let ts = Date.now();
        setCompLoadStatus('loading');
        setReloadGrid(ts);
    }
    const applyFilterHandler = (item) => {
        let ts = Date.now();
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    };
    const clearFilterHandler = (item, type) => {
        // debugger;
        if (type === 'all') {
            compRef.current.columns.map((main, i) => {
                main.subcolumns.map((sub, j) => {
                    //it depends on property sorted
                    sub.filter = null;
                });
            });
        }
        setCompLoadStatus('loading');
        let ts = Date.now();
        setReloadGrid(ts);
    };
    const dataColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            compRef.current.itemDroppedOn = columParam;
            // debugger;
            let { itemDragged, itemDroppedOn } = compRef.current;
            if (columnMeta.parent === columParam.parent) {
                let draggedOrder = columnMeta.order;
                let droppedOrder = columParam.order;
                let mainColumn = compRef.current.columns.filter((item) => item.id === columnMeta.parent);
                compRef.current.columns.map((mainCol, i) => {
                    if (mainCol.id === columnMeta.parent) {
                        compRef.current.columns[i].subcolumns.map((col) => {
                            if (col.value === columnMeta.value) {
                                col.order = droppedOrder;
                            }
                            if (col.value === columParam.value) {
                                col.order = draggedOrder;
                            }
                        });
                    }
                });
                let columns = processColumnsNew(compRef.current.columns);
                compRef.current.columns = columns;
                // setupData();
                let ts = Date.now();
                setCompLoadStatus('loading');
                setReloadGrid(ts);
            }
        }
    };
    const onOrderByChangeHandler = (item) => {
        // debugger;
        setCompLoadStatus('loading');
        let cols = compRef.current.columns;
        compRef.current.sortedColumn = item;
        compRef.current.columns.map((main) => {
            if (main.id === item.parent) {
                main.subcolumns.map((col) => {
                    if (col.value === item.value) {
                        col.sorted = item.sorted;
                    } else {
                        col.sorted = null;
                    }
                })
            } else {
                main.subcolumns.map((col) => {
                    col.sorted = null;
                });
            }

        });
        let ts = Date.now();
        setReloadGrid(ts);
        // setupData();
        // debugger;
    }
    const mainColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            const draggedCellOrder = columnMeta.order;
            const draggedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === draggedCellOrder);
            const droppedCellOrder = columParam.order;
            const droppedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === droppedCellOrder);
            compRef.current.itemDroppedOn = columParam;

            compRef.current.columns.map((mainCol, i) => {
                //moving to left
                if (draggedCellOrder > droppedCellOrder && i <= draggedCellOrderIndex) {
                    // debugger;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i <= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order + 1;
                    }
                }
                //moving to right
                if (draggedCellOrder < droppedCellOrder && i >= draggedCellOrderIndex) {
                    // debugger;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i >= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order - 1;
                    }
                }
            });
            compRef.current.columns = processColumnsNew(compRef.current.columns);
            // setupData();
            // let ts = Date.now();
            // setReloadGrid(ts);
            // setCompLoadStatus('loading');
        }
    };
    const renderHeader = () => {
        let columns = compRef.current.columns;
        let cols = [];
        let colheaders = [];
        let count = 0;
        let bckgClass = "grey";
        let subHeaderStickyLeft = 0;
        for (let index = 0; index < columns.length; index++) {
            const col = columns[index];
            if (!col.isSticky) {
                bckgClass = "light_blue";
            }
            // const col_len = col["subcolumns"].length;
            let colSpan = col["subcolumns"].length;
            let hiddenCols = col["subcolumns"].filter(item => item.hidden);
            colSpan = colSpan - hiddenCols.length;
            // debugger
            let primaryHSty = {};
            if (col.type == "dateColumn" || col.isSticky === true) {
                primaryHSty = {
                    position: "sticky",
                    left: col.left ? col.left : 0,
                    zIndex: 5,
                };
                // colSpan = 2;
            }
            colSpan = colSpan > 1 ? colSpan : null;
            const header = (
                <PrimaryHeaderCell
                    onDrop={mainColumnOnDropHandler(col)}
                    key={index}
                    align="center"
                    colSpan={colSpan}
                    style={primaryHSty}
                    className={"primary-header-cell header_col "}
                    sx={{ fontWeight: "bold !important" }}
                    data-fill-color="606060"
                    data-f-color="ffffff"
                    data-b-r-s="thin"
                    data-a-v="middle"
                    data-b-a-c="ffffff"
                    data-a-h="center"
                    data-f-sz="16"
                >
                    {col.draggable && <div draggable={col.draggable} onDragStart={onDragStartHandler(col)} onDragOver={(e) => { e.preventDefault() }}>
                        <span>
                            {primaryHeaderDisplay(col, app_state, ui_state)}
                        </span>

                    </div>}

                    {!col.draggable && <div>
                        <span>
                            {primaryHeaderDisplay(col, app_state, ui_state)}
                        </span>

                    </div>}

                </PrimaryHeaderCell>
            )
            let subColClass = "";
            col["subcolumns"].map((item, sub_col_index) => {
                bckgClass = ((item.display).toLowerCase() === "comparison date") ? "header-grey" : "header-blue";
                let sty = {};
                subColClass = item.subcol_id;
                let stickyStyInner = {}
                // sty["paddingBottom"] = "18px";
                sty["fontWeight"] = "bold";
                sty["background"] = "#3fa7cd";
                sty["fontSize"] = "19px !important";
                if (col.isSticky === true) {
                    sty["position"] = "sticky";
                    sty["zIndex"] = 2;

                    sty["left"] = subHeaderStickyLeft;
                    if (!item.hidden) {
                        subHeaderStickyLeft += 81;
                    }

                    sty["background"] = "#b2b2b2";
                    stickyStyInner["width"] = "50px";
                } else {
                    sty["zIndex"] = 1
                }


                let el = (
                    <SecondaryHeaderBlueCell
                        onDrop={dataColumnOnDropHandler(item)}
                        className={"secondary-header-cell header_col  " + bckgClass + " col_len_1  header-col-" + item.value}
                        style={sty}
                        key={count}
                        align="center"
                        data-fill-color="B2B2B2"
                        data-f-color="ffffff"
                        data-a-v="middle"
                        data-a-h="center"
                        data-f-bold="true"
                        data-f-sz="15"
                        // data-b-a-c="ffffff"
                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        sx={{ fontSize: "13px", top: "30px" }}
                    >
                        <div className={"filter-position-r " + subColClass + " " + bckgClass} style={stickyStyInner} onDragOver={(e) => { e.preventDefault() }}>

                            <ColumnSorting
                                className={"column-sorting field-" + item.value}
                                colItem={item} sortable={item.sortable} sorted={item.sorted}
                                label={item.display} value={item.value}
                                onOrderByChange={onOrderByChangeHandler} />

                            <div className="col-head-label" style={{ "width": item.width ? item.width : 'auto' }} draggable={item.draggable} onDragStart={onDragStartHandler(item)}>

                                {item.value === 'Rooms' && <>Rooms Sold</>}
                                {item.value !== 'Rooms' && item.display}
                            </div>
                            {item.filterMenu &&
                                <div className="filter-main">
                                    <Filter filteredColumns={compRef.current.filteredColumns} columnList={compRef.current.columns} currentColumn={item}
                                        columnToggleHandler={columnToggleHandler}
                                        applyFilterHandler={applyFilterHandler}
                                        clearFilterHandler={clearFilterHandler}
                                    />
                                </div>}
                        </div>
                    </SecondaryHeaderBlueCell>
                );


                if (col.type == "dateColumn") {
                    var colClass = "date_subcolumn";
                    sty["position"] = "sticky";
                    sty["zIndex"] = "5";
                    sty["textAlign"] = "center";
                    // sty["paddingBottom"] = "18px";
                    sty["fontWeight"] = "bold";
                    // sty["paddingLeft"] = "15px";
                    // sty["paddingRight"] = "15px";
                    sty["background"] = "#b2b2b2";

                    if ((item.display).toLowerCase() === 'dba' || (item.display).toLowerCase() === 'month') {
                        sty["left"] = sub_col_index * 64;
                    }

                    if (item.hasOwnProperty("value") && (item.display).toLowerCase() === 'dow') {
                        // sty["left"] = "70px !important";
                        sty["width"] = "60px !important";
                    }
                }
                if (!item.hidden) {
                    cols.push(el);
                }
                count++;

            });
            if (!col.hidden) {
                colheaders.push(header);
            }

        }

        return (
            <>
                <TableRow data-height="30" className="header_row top_header">{colheaders}</TableRow>
                <TableRow data-height="30" className="header_row bottom_header">{cols}</TableRow>
            </>
        );
    }
    const hasRestrictions = (value) => {
        // debugger; 
        let result = false;
        if (value && (value.cta || value.ctd || value.close || value.min_los > 1 || value.max_los > 1)) {
            result = true;
        }
        return result;
    }
    const renderRow = (dataPoint, count) => {

        let houseLvlResRec = {}, houseLvlResCur = {};

        const cells = [];
        let dateData = dataPoint.find(item => item.id === "staydates");
        if (restrictionsHouseData.rec) {
            houseLvlResRec = restrictionsHouseData.rec.find(i => i.stay_date === dateData["date"]);

        }
        if (restrictionsHouseData.cur) {
            houseLvlResCur = restrictionsHouseData.cur.find(i => i.stay_date === dateData["date"]);

        }
        let rowStayDate = new moment(dateData["date"]).startOf('day');

        let today = new moment().subtract(1, 'days');
        let cellClass = "data-cell";
        compRef.current.columns.forEach((col, colIndex) => {
            let subCols = col.subcolumns.filter(col => !col.hidden);
            // let subCols = Object.keys(column); debugger;
            const { type, className } = col;
            const cellData = dataPoint.find(item => item.id === col.id);

            subCols.forEach((sub_col, sub_col_index) => {
                let sty = {};
                const { value, hidden, type, subcol_id } = sub_col;
                let subcol_key = subcol_id.replace(col.id + "_", "");
                let data = cellData[value];
                let display = ""
                if (colIndex === 0) {
                    sty = { ...sty, position: "sticky", zIndex: 1, textAlign: "center" };
                    sty["left"] = sub_col_index * 81;
                }
                if (type === "component" &&
                    subcol_id === 'restrictions_rec' &&
                    rowStayDate.isSameOrAfter(today) && hasRestrictions(data)) {

                    display = (<RestrictionTableCell editable={false}
                        recData={cellData['rec']} cur={cellData['cur']} houseLvlRes={houseLvlResRec}
                        data={cellData[value]} room_type={col.room_type}
                        stay_date={dateData["date"]} />);
                    // displayData = (<><Restrictions restriction_type="rec" data={val} stay_date={val.stay_date} /></>);
                }
                else if (type === "component" && subcol_id === 'restrictions_cur' && !rowStayDate.isSameOrBefore(today)) {
                    let updatedRestriction = cellData[value];

                    display = (<RestrictionTableCell editable={true} houseLvlRes={houseLvlResCur}
                        recData={cellData['rec']} room_type={col.room_type}
                        cur={cellData[value]} data={updatedRestriction}
                        stay_date={dateData["date"]} />);
                    // display ="cur"

                } else {
                    display = displayDataFormat(data, type, value, dateData["date"]);
                }

                cells.push(<TableCell

                    data-b-r-s="thin" data-b-a-c="ffffff"
                    data-t="s" data-exclude="true" data-f-sz="5" data-a-h="center"
                    subcol_key={count}
                    className={cellClass + " data-cell-" + subcol_key}
                    style={sty}
                >
                    {display}
                </TableCell>)
            })
        })
        return <>{cells}</>;
    }
    useEffect(() => {
        if (compRef.current.data_setup.length > 0 && compLoad) {
            const rows = [];

            compRef.current.data_setup.forEach((item, index) => {

                const tableRow = renderRow(item, index);
                rows.push(tableRow);
            })
            setRows(state => rows);
        }
    }, [compRef.current.data_setup]);


    const displayDataFormat = (data, type, subcol_value, stay_date) => {
        let sonu = data;
        let returnElement = "";
        if (type === "date") {
            //returnElement = moment(data).format("MMM DD");
            const formattedDate = moment(data).format("MMM DD");
            returnElement = (
                <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
                    <NotesPopup params={{ stay_date: data }} />
                    {formattedDate}
                </Box>
            );
        }
        if (type === "dow") {
            //returnElement = data;
            returnElement = (
                <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
                    <EventsPopup params={{ stay_date: stay_date }} />
                    {sonu}
                </Box>
            );
        }
        if (type === "component") {
            returnElement = "";
        }
        if (type === "currency") {

            returnElement = (isNaN(data) || data === null) ? "" : ui_state.currency_symbol + data;
        }
        if (type === "number") {

            returnElement = (isNaN(data) || data === null) ? "" : data;
        }
        return returnElement;
    }
    let tableContClass = "restrictions-roomtype-table", active_tiles_className = "restrictions roomtype";
    let TableComponentsValue = useCallback(TableComponents(tableContClass, active_tiles_className), [tableContClass, active_tiles_className])
    return (
        // <Paper sx={{ width: "100%", height: "inherit" }} className="restrictions-roomtype" elevation={0}>
        <>
            <RoomTypeRestrictionFilter />
            <TableVirtuoso
                ref={tableVirtuoso}
                data={new Array(rows.length)}
                components={TableComponentsValue}
                fixedHeaderContent={() => (
                    renderHeader()
                )}

                itemContent={(index, row) => (
                    <>
                        {
                            (compLoadStatus !== "loading" &&
                                compLoad == true
                                && displayData.length &&
                                rows.length > 0)
                                ? rows[index] : ''
                        }
                    </>
                )}
            />
        </>
        // {/* </Paper> */}
    )
}

export default TableRestrictionsRoomType;
const containerStyle = {
    position: "  "
}
const TableComponents = (tableContClass, active_tiles_className) => ({
    Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} className={tableContClass} />),
    Table: (props) => <Table {...props} id="mainTable" stickyHeader size="small" aria-label="a dense table" className={active_tiles_className + " react-dynamic-table"} />,
    TableHead: React.forwardRef((props, ref) => <TableHead ref={ref} {...props} className="table-head" style={{ ...props.style, zIndex: 2 }} />),
    TableRow: (props) => <TableRow  {...props} className={`${props['data-index'] % 2 ? 'table-row even-row' : 'table-row'}`} />,
    TableBody: React.forwardRef((props, ref) => <TableBody className="table-body" {...props} ref={ref} />),
});
