import moment from "moment";
import { aggregateObject, convertToPercent, daysCountFromRange, deepCopy, getAppData, getCorrectValue, getObjectFilter, round, setUpDate, truncate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { ExitToApp, LocalFireDepartment } from "@mui/icons-material";
import HeaderMsg from "../HeaderMsg";
import { dateFormat } from "highcharts";

// Function to Manupulate the segmented data for Scope type is Date

export function bookingCurveSegmentedDateData(app_state, auth, segments) {
    let otbData = [];
    let transformed = [];
    let bookingCurveSegmentedData = dataService.getSeriesById("OSRBookingPaceSegment").data.data;
    let segmentTotalAll = {};
    let ingonreKeys = ['HotelTotalxx', 'index', 'Segment_TOTAL', 'forecastDate', 'total_forecast_xx'];

    bookingCurveSegmentedData.map((item, index) => {
        let segmentsKeys = Object.keys(item).sort().reverse();
        let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
        let comparisonTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0, date: "" };

        segmentsKeys.forEach((key, segIndex) => {
            let tmp = {
                index: {},
                HotelTotal: {
                    primary: {},
                    comparison: {}
                }
            }
            
            if (!ingonreKeys.includes(key)) {
                // debugger; 
                if (!item[key].primary) {
                    item[key].primary = deepCopy(primaryTotal);
                }
                if (!item[key].comparison) {
                    item[key].comparison = deepCopy(comparisonTotal);
                }
                let segmentCode = key.split('_')[1];
                let segmentName = segmentCode; //segments.map[segmentCode];
                if (key === "HotelTotal") { segmentName = "Total"; }
                if (!segmentName) {
                    segmentName = "Other";
                }
                let compDate = item[key]['comparison']['date'];
                if (segmentName === "Other" || segmentName === "Total") {
                    let firstKey = Object.keys(bookingCurveSegmentedData[index])[1]
                    compDate = (bookingCurveSegmentedData[index][firstKey]['comparison']['date']) ? bookingCurveSegmentedData[index][firstKey]['comparison']['date'] : moment().format('YYYY-MM-DD')
                    item[key]['comparison']['date'] = compDate;
                }


                tmp.HotelTotal = deepCopy(item[key]);
                tmp.index.date = deepCopy(item.index.date);
                tmp.segment = segmentName;
                tmp.segment_code = segmentCode;
                transformed.push(tmp);

                // debugger;
                if (!segmentTotalAll[key]) {
                    segmentTotalAll[key] = {
                        primary: deepCopy(primaryTotal),
                        comparison: deepCopy(comparisonTotal)
                    };
                }

                if (key !== 'HotelTotal') {
                    let daysCountNo = daysCountFromRange(app_state)
                    let fieldArr = Object.keys(primaryTotal);
                    fieldArr.map((k) => {
                        if (k === "Rooms") {
                            segmentTotalAll[key].primary[k] += parseFloat(getCorrectValue(item[key].primary[k]));
                            segmentTotalAll[key].comparison[k] += parseFloat(getCorrectValue(item[key].comparison[k]));
                        } else {
                            segmentTotalAll[key].primary[k] += (parseFloat(getCorrectValue(item[key].primary[k]) / daysCountNo));
                            segmentTotalAll[key].comparison[k] += parseFloat(getCorrectValue(item[key].comparison[k]) / daysCountNo);
                        }

                        if (key !== "Index") {
                            // segmentTotalAll[key].comparison[k] += parseFloat(bookingCurveSegmenteSdlydData[index][key].primary[k]);
                            // segmentTotalAll[key].comparePace[k] += parseFloat(OSRBookingPaceCompareToSegment[index][key].primary[k]);
                        }
                    })
                }
            }
        })
    })
    // console.log(allSegmentTotal(transformed,auth,app_state));
    // debugger;
    transformed = transformed.concat(allSegmentTotal(transformed, auth, app_state));
    return transformed;
}


export function bookingCurveSegmentedDateData_old(app_state, auth, segments) {
    let otbData = [];
    let transformed = [];
    let bookingCurveSegmentedData = dataService.getSeriesById("OSRBookingPaceSegment").data.data;
    // debugger;
    let segmentTotalAll = {};
    let ingonreKeys = ['HotelTotalxx', 'index', 'Segment_TOTAL', 'forecastDate', 'total_forecast_xx'];

    bookingCurveSegmentedData.map((item, index) => {
        let segmentsKeys = Object.keys(item).sort().reverse();
        let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
        let comparisonTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0, date: "" };

        segmentsKeys.forEach((key, segIndex) => {
            let tmp = {
                index: {},
                HotelTotal: {
                    primary: {},
                    comparison: {}
                }
            }
            if (!item[key].primary) {
                item[key].primary = deepCopy(primaryTotal);
            }
            if (!item[key].comparison) {
                item[key].comparison = deepCopy(comparisonTotal);
            }
            if (!ingonreKeys.includes(key)) {

                let segmentCode = key.split('_')[1];
                let segmentName = segments.map[segmentCode];
                if (key === "HotelTotal") { segmentName = "Total"; }
                if (!segmentName) segmentName = "Other";
                let compDate = item[key]['comparison']['date'];
                if (segmentName === "Other" || segmentName === "Total") {
                    let firstKey = Object.keys(bookingCurveSegmentedData[index])[1]
                    compDate = (bookingCurveSegmentedData[index][firstKey]['comparison']['date']) ? bookingCurveSegmentedData[index][firstKey]['comparison']['date'] : moment().format('YYYY-MM-DD')
                    item[key]['comparison']['date'] = compDate;
                }


                tmp.HotelTotal = deepCopy(item[key]);
                tmp.index.date = deepCopy(item.index.date);
                tmp.segment = segmentName;
                tmp.segment_code = segmentCode;
                // transformed.push(tmp);
                let idx = null;
                for (let i = 0; i < transformed.length; i++) {
                    if (item.index.date === transformed[i].index.date && transformed[i].segment === tmp.segment) {
                        idx = i;
                    }
                }
                if (idx !== null) {
                    let keys = [
                        ['primary', 'comparison'],
                        ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms', 'SellRate']
                    ];
                    let aggTotal = aggregateObject(transformed[idx].HotelTotal, tmp.HotelTotal, keys, 2);
                    aggTotal.comparison.date = compDate;
                    transformed[idx].HotelTotal = aggTotal;

                } else {
                    transformed.push(tmp);
                }

                // // debugger;
                if (!segmentTotalAll[key]) {
                    segmentTotalAll[key] = {
                        primary: deepCopy(primaryTotal),
                        comparison: deepCopy(comparisonTotal)
                    };
                }

                if (key !== 'HotelTotal') {
                    let daysCountNo = daysCountFromRange(app_state)
                    let fieldArr = Object.keys(primaryTotal);
                    fieldArr.map((k) => {
                        if (k === "Rooms") {
                            segmentTotalAll[key].primary[k] += parseFloat(getCorrectValue(item[key].primary[k]));
                            segmentTotalAll[key].comparison[k] += parseFloat(getCorrectValue(item[key].comparison[k]));
                        } else {
                            segmentTotalAll[key].primary[k] += (parseFloat(getCorrectValue(item[key].primary[k]) / daysCountNo));
                            segmentTotalAll[key].comparison[k] += parseFloat(getCorrectValue(item[key].comparison[k]) / daysCountNo);
                        }

                        if (key !== "Index") {
                            // segmentTotalAll[key].comparison[k] += parseFloat(bookingCurveSegmenteSdlydData[index][key].primary[k]);
                            // segmentTotalAll[key].comparePace[k] += parseFloat(OSRBookingPaceCompareToSegment[index][key].primary[k]);
                        }
                    })
                }
            }
        })
    })
    transformed = transformed.concat(allSegmentTotal(transformed, auth, app_state));
    return transformed;
}


function allSegmentTotal(transformed, auth, app_state) {
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let result = [];
    let segmentArr = [];
    let segmentCodeArr = [];
    if (transformed && transformed.length) {
        transformed.map((item, index) => {
            if (segmentArr.indexOf(item.segment) < 0 && item.segment !== 'Total') {
                segmentArr.push(item.segment);
                segmentCodeArr.push(item.segment_code);
            }
        })
    }
    segmentArr.map((itm, idx) => {
        let temp = {
            HotelTotal: {
                primary: { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 },
                comparison: { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 },
            },
            index: { date: "" },

        }
        let res = transformed.filter((item) => item.segment === itm);
        temp.segment = itm;
        temp.index.segment = temp.segment;

        temp.segment_code = itm === 'Other' ? 'Other' : segmentCodeArr[idx];
        temp.index.segment_code = temp.segment_code;

        res.map((filteredData, i) => {
            Object.keys(blankObj).map((kpi) => {
                temp.HotelTotal.primary[kpi] += filteredData.HotelTotal.primary[kpi];
                temp.HotelTotal.comparison[kpi] += filteredData.HotelTotal.comparison[kpi];
            })
        })
        result.push(temp);
    })

    let totalCapacity = getAppData(auth, app_state).property_current_total_capacity;
    let mainKey = ["primary", "comparison"];
    let daysCount = daysCountFromRange(app_state);
    result.map((itm) => {
        mainKey.map((key) => {
            let totalCap = totalCapacity * daysCount;
            itm['HotelTotal'][key]['ADR'] = (itm['HotelTotal'][key]['Revenue'] / itm['HotelTotal'][key]['Rooms']);
            itm['HotelTotal'][key]['Occ'] = (itm['HotelTotal'][key]['Rooms'] / totalCap);
            itm['HotelTotal'][key]['RevPAR'] = (itm['HotelTotal'][key]['Revenue'] / totalCap);
        })
    })
    return result;
}



/// Function to manipulate the segmented data for Dba 

export function bookingCurveSegmentedDbaData(app_state, segments) {
    let otbData = [];
    let transformed = [];
    let bookingCurveSegmentedData = dataService.getSeriesById("OSRBookingPaceSegmentDBA").data.data;
    let dbaCount = app_state.workspace_controls.dba;
    let segmentTotalAll = {};
    let ingonreKeys = ['HotelTotalxx', 'index', 'Segment_TOTAL', 'forecastDate', 'total_forecast_xx'];

    bookingCurveSegmentedData = bookingCurveSegmentedData.slice(0, dbaCount);

    bookingCurveSegmentedData.map((item, index) => {
        let segmentsKeys = Object.keys(item).sort().reverse();
        let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
        let comparisonTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
        segmentsKeys.forEach((key, segIndex) => {
            let tmp = {
                index: {},
                HotelTotal: {
                    primary: {},
                    comparison: {}
                }
            }
            if (!item[key].primary) {
                item[key].primary = deepCopy(primaryTotal);
            }
            if (!item[key].comparison) {
                item[key].comparison = deepCopy(comparisonTotal);
            }
            if (!ingonreKeys.includes(key)) {

                let segmentCode = key.split('_')[1];
                let segmentName = segments.map[segmentCode];
                if (key === "HotelTotal") { segmentName = "Total"; }
                if (!segmentName) segmentName = "Other";

                tmp.HotelTotal = deepCopy(item[key]);
                tmp.index.dba = deepCopy(item.index.dba);
                tmp.segment = segmentName;
                tmp.segment_code = segmentCode;

                let idx = null;
                for (let i = 0; i < transformed.length; i++) {
                    if (item.index.dba === transformed[i].index.dba && transformed[i].segment === tmp.segment) {
                        idx = i;
                    }
                }
                if (idx !== null) {
                    let keys = [
                        ['primary', 'comparison'],
                        ['ADR', 'Occ', 'RevPAR', 'Revenue', 'Rooms', 'SellRate']
                    ];
                    let aggTotal = aggregateObject(transformed[idx].HotelTotal, tmp.HotelTotal, keys, 2);
                    transformed[idx].HotelTotal = aggTotal;
                } else {
                    transformed.push(tmp);
                }
                if (!segmentTotalAll[key]) {
                    segmentTotalAll[key] = {
                        primary: deepCopy(primaryTotal),
                        comparison: deepCopy(primaryTotal)
                    };
                }

                if (key !== 'HotelTotal') {
                    let fieldArr = Object.keys(primaryTotal);
                    fieldArr.map((k) => {
                        segmentTotalAll[key].primary[k] += parseFloat(item[key].primary[k]);
                        segmentTotalAll[key].comparison[k] += parseFloat(item[key].comparison[k]);
                        if (key !== "Index") {
                            // segmentTotalAll[key].comparison[k] += parseFloat(bookingCurveSegmenteSdlydData[index][key].primary[k]);
                            // segmentTotalAll[key].comparePace[k] += parseFloat(OSRBookingPaceCompareToSegment[index][key].primary[k]);
                        }
                    })
                }
            }
        })
    })
    let totalResult = bookingCurveSegmentTotal(segmentTotalAll, segments);

    // transformed = transformed.concat(totalResult);
    return transformed;
    // return [];
}

function checkValueExists(arr, k, value) {
    return arr.some(function (el) {
        return el.segment === value;
    });
}

function bookingCurveSegmentTotal(SegmentData, segments) {
    let transformed = [];
    let marketKeys = Object.keys(SegmentData)
    let otherTotal = {
        primary: { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 },
        comparison: { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0, date: "n/a" }
    };
    let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    var tmp = {};
    // debugger; 
    marketKeys.map((key) => {
        tmp = {};
        let segmentName = getObjectFilter(segments.list, key.split("_")[1], "code");
        if (key !== "total_forecast" && segmentName.length > 0 && segmentName[0].name !== "Other") {
            if (!checkValueExists(transformed, "segment", segmentName[0].name)) {

                tmp.HotelTotal = (SegmentData[key]);
                tmp.index = { dba: "" };
                tmp.segment = segmentName[0].name;//truncate(segmentName[0].name, 17);
                transformed.push(tmp);
            }
            // debugger; 
        } else if (segmentName.length > 0 && segmentName[0].name === "Other") {
            let fieldArr = Object.keys(primaryTotal);
            fieldArr.map((k) => {
                otherTotal['primary'][k] += parseFloat(SegmentData[key]['primary'][k]);
                otherTotal['comparison'][k] += parseFloat(SegmentData[key]['comparison'][k]);
            })
        }
    })

    tmp.HotelTotal = otherTotal;
    tmp.index = { dba: "" };
    tmp.segment = "Other";
    transformed.push(tmp);

    return transformed;
}


export function getBookingCurveSegmentedData(data, columns, app_state) {
    let setup_data = [];
    // let data = dataService.getSeriesById("OSRBookingPaceDBA").data.data;
    // console.log("Segmented Data => ", data);
    let dbaCount = app_state.workspace_controls.dba;
    let dataKey = "HotelTotal";
    if (!app_state.workspace_controls.segmented_view) {
        data = data.slice(0, dbaCount);
    }
    if (data && data.length > 0) {
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = { id: col.id };
                if (col.type === 'dateColumn') {

                    if (app_state.workspace_controls.scopeType === "Date") {
                        dataObj = setUpDate(dataObj, item.index.date);
                    } else {
                        dataObj['dba'] = item.index.dba;
                    }
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.segment;
                    }
                }
                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (mainKey === "compDateDIff") {
                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey]['primary'][key.value] - item[dataKey]['comparison'][key.value]);
                            } else {
                                dataObj[key.value] = round(item[dataKey]['primary'][key.value] - item[dataKey]['comparison'][key.value])
                            }
                        } else {
                            if (key.value === "compdate") {
                                dataObj['compdate'] = (item[dataKey][mainKey]['date']) ? moment(item[dataKey][mainKey]['date']).format("YYYY/MM/DD") : "";
                            } else if (key.value === "date") {
                                // console.log("item[dataKey][mainKey]['date'] ",item[dataKey][mainKey]['date']);
                                let tmpDateData = setUpDate(dataObj, item[dataKey][mainKey]['date']);
                                dataObj["cdate"] = tmpDateData['date'];
                            } else if (key.value === "dow") {
                                let tmpDateData = setUpDate(dataObj, item[dataKey][mainKey]['date']);
                                dataObj['cdow'] = tmpDateData['dow'];
                            } else if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value])
                            } else {
                                dataObj[key.value] = round(item[dataKey][mainKey][key.value])
                            }
                        }
                    })
                }
                data_columns.push(dataObj);
            })
            setup_data.push(data_columns);
        })
    }
    return setup_data;

}







// functions to manipulate DBA data non segment view ///
// Data will fetch from API Registry (OSRBookingPaceDBA)

export function getBookingCurveDbaData(columns, app_state) {
    let setup_data = [];
    let data = dataService.getSeriesById("OSRBookingPaceSegmentDBA").data.data;
    // debugger;
    let dbaCount = app_state.workspace_controls.dba;
    let dataKey = "HotelTotal";
    if (!app_state.workspace_controls.segmented_view) {
        data = data.slice(0, dbaCount);
    }
    if (data && data.length > 0) {
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = { id: col.id };
                if (col.type === 'dateColumn') {
                    dataObj['dba'] = item.index.dba;
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.segment;
                    }
                }
                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (mainKey === "compDateDIff") {
                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey]['primary'][key.value] - item[dataKey]['comparison'][key.value]);
                            } else {
                                dataObj[key.value] = round(item[dataKey]['primary'][key.value] - item[dataKey]['comparison'][key.value])
                            }
                        } else {
                            if (key.value === "date") {
                                let tmpDateData = setUpDate(dataObj, item[dataKey][mainKey]['date']);
                                dataObj["cdate"] = tmpDateData['date'];
                            } else if (key.value === "dow") {
                                let tmpDateData = setUpDate(dataObj, item[dataKey][mainKey]['date']);
                                dataObj['cdow'] = tmpDateData['dow'];
                            } else if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value])
                            } else {
                                dataObj[key.value] = round(item[dataKey][mainKey][key.value])
                            }
                        }
                    })
                }
                data_columns.push(dataObj);
            })
            setup_data.push(data_columns);
        })
    }
    return setup_data;
}




// function to manipulate non segmented booking curve data  
export function getBookingCurveData(data, columns, app_state) {
    let setup_data = [];
    if (data && data.length > 0) {
        let as_of_date = app_state.as_of_date;
        let dbaCount = app_state.workspace_controls.dba;
        let startDate = app_state.primary_stay_range.date_from;
        let dataKey = "HotelTotal";
        let idx = 0;
        if (!app_state.workspace_controls.segmented_view) {
            // data = data.slice(0, dbaCount);
        }
        data.map((item, index) => {
            let data_columns = [];
            columns.map(col => {
                let dataObj = { id: col.id };
                if (col.type === 'dateColumn') {
                    dataObj = setUpDate(dataObj, item.index.date);
                    // add segments name 
                    if (app_state.workspace_controls.segmented_view) {
                        dataObj["segment"] = item.index.segment;
                    }
                }
                if (col.type === 'dataColumn') {
                    let { mainKey } = col;
                    col.subcolumns.map(key => {
                        if (mainKey === "compDateDIff") {
                            if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey]['primary'][key.value] - item[dataKey]['comparison'][key.value]);
                            } else {
                                dataObj[key.value] = round(item[dataKey]['primary'][key.value] - item[dataKey]['comparison'][key.value])
                            }
                        } else {
                            if (key.value === "date") {
                                let tmpDateData = setUpDate(dataObj, item[dataKey][mainKey]['date']);
                                dataObj["cdate"] = tmpDateData['date'];
                            } else if (key.value === "compdate") {
                                dataObj['compdate'] = item[dataKey][mainKey]['date'];//dateFormat(item[dataKey][mainKey]['date'], "MMM DD, YYYY ddd");
                            } else if (key.value === "Occ") {
                                dataObj[key.value] = convertToPercent(item[dataKey][mainKey][key.value])
                            } else {
                                dataObj[key.value] = round(item[dataKey][mainKey][key.value])
                            }
                        }
                    })
                }
                data_columns.push(dataObj);
            })
            setup_data.push(data_columns);
        })
    } else {
        // return <>
        <HeaderMsg msg="generating the data ...." />
        // </>
    }
    return setup_data;
}



// function to get booking curve data for Intellimerge 
export const getBookingCurveDataInt = (mainKey, id, tileName, key, index, dataKey, auth, app_state, ui_state) => {
    let otbPaceData = (app_state.workspace_controls.scopeType !== 'Dba') ? deepCopy(dataService.getSeriesById("OSRBookingPaceSegment").data.data) : deepCopy(dataService.getSeriesById("OSRBookingPaceSegmentDBA").data.data);
    let dbaCount = app_state.workspace_controls.dba;
    // debugger;
    let { active_tiles } = ui_state;
    if (active_tiles[0] === "compset" && app_state.workspace_controls.scopeType === "Dba") {
        //     otbPaceData.splice(0,1);
    }
    let dataObj = {};
    let value = key.value;
    let otbPaceDataKey = otbPaceData[index][dataKey];
    if (mainKey === "compDateDIff" && tileName === "pace" && otbPaceData[index]) {
        if (value === "Occ") {
            dataObj[value] = convertToPercent(otbPaceDataKey['primary'][value] - otbPaceDataKey['comparison'][value]);
        } else {
            dataObj[value] = round(otbPaceDataKey['primary'][value] - otbPaceDataKey['comparison'][value])
        }
    } else {
        if (tileName === "pace" && otbPaceData[index]) {
            if (value === "date") {
                let tmpDateData = setUpDate(dataObj, otbPaceDataKey[mainKey]['date']);
                dataObj["cdate"] = tmpDateData['date'];
            } else if (value === "compdate") {
                dataObj['compdate'] = otbPaceDataKey[mainKey]['date'];
            } else if (value === "Occ") {
                dataObj[value] = convertToPercent(otbPaceDataKey[mainKey][value])
            } else {
                dataObj[value] = round(otbPaceDataKey[mainKey][value])
            }
        }
    }
    return dataObj;
}



export const getBookingCurveDataInt_segment = (mainKey, id, tileName, key, index, dataKey, auth, segmentedData) => {

    let value = key.value;
    let dataObj = {};
    let segmentedDataKey = segmentedData[dataKey];
    let segmentedMain = segmentedDataKey[mainKey];
    let segmentedPrimary = segmentedDataKey['primary'];
    let segmentedComparison = segmentedDataKey['comparison'];
    let segmentedDate = segmentedMain['date'];

    if (mainKey === "compDateDIff" && tileName === "pace" && segmentedData) {
        if (value === "Occ") {
            dataObj[value] = convertToPercent(segmentedPrimary[value] - segmentedComparison[value]);
        } else {
            dataObj[value] = round(segmentedPrimary[value] - segmentedComparison[value])
        }
    } else {
        if (tileName === "pace" && segmentedData) {
            if (value === "date") {
                let tmpDateData = setUpDate(dataObj, segmentedDate);
                dataObj["cdate"] = tmpDateData['date'];
            } else if (value === "compdate") {
                dataObj['compdate'] = segmentedDate;
            } else if (value === "Occ") {
                dataObj[value] = convertToPercent(segmentedMain[value])
            } else {
                dataObj[value] = round(segmentedMain[value])
            }
        }
    }
    return dataObj;
}