import React from 'react'
import {
  AppBar
  , Box
  , Toolbar
  , IconButton
  , Typography
  , Menu
  , MenuItem
  , Container
  , Avatar
  , Button
  , Tooltip
  , FormControl
  , InputLabel
  , Select
  , ListSubheader
  , TextField
  , InputAdornment
  , List
  , ListItem
  , ListItemIcon
  , ListItemText
  , Divider
  , Drawer
} from '@mui/material/';
import {
  Person as PersonIcon
  , SettingsOutlined as SettingsOutlinedIcon
  , ScheduleOutlined as ScheduleOutlinedIcon
  , VideocamOutlined as VideocamOutlinedIcon
  , PowerSettingsNewOutlined as PowerSettingsNewOutlinedIcon
  , AssessmentOutlined as AssessmentOutlinedIcon
  , ArrowForwardRounded as ArrowForwardRoundedIcon
  , ApartmentSharp as ApartmentSharpIcon
  , Search as SearchIcon
  , KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon
} from '@mui/icons-material/';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DownloadingIcon from '@mui/icons-material/Downloading';


import UploadsPopUp from './UploadsPopUp';
import { useDispatch, useSelector } from 'react-redux';
import { in_array } from '../../app/util';
import LockIcon from '../Icons/LockIcon'

const MenuSingleItem = (props) => {
  const { label, access, menuId, hasLockOnMenu } = props;
  const { app, auth } = useSelector((state) => state);
  const { app_state, ui_state } = app.current_state;
  let selectedReport = (ui_state.active_tiles[0] === menuId) ? "selected-report" : "";
  let selectedIcon = (ui_state.active_tiles[0] === menuId) ? "showIcon" : "hideIcon";

  // console.log("checking.... ",props);
  let searchElementArr = [menuId];
  const exportReportArr = ["HMSReport", "bi_hotel_segmented_summary_report", "bi_market_segmented_summary_report"];
  let isExport = in_array(exportReportArr, searchElementArr);
  let exportIcon = (isExport) ? "showIcon" : "hideIcon";

  const remainReports = [];
  let remaingClass = (in_array(remainReports, searchElementArr)) ? "#ccc" : "";


  return (
    <div>
      {
        access !== 'disabled' && (
          <>
            <MenuItem
              key={props.index}
              onClick={() => { props.onClick(menuId, label) }}
              className={`${selectedReport} ${hasLockOnMenu ? 'lock-menu' : ''} `} >
              <div className={'report-list-outer'} style={{ color: remaingClass }}>
                <div className={"report-name"}>{label}</div>
                {hasLockOnMenu === false ?
                  <>
                    <div className={selectedIcon}><TaskAltIcon color="primary" fontSize="small" /></div>
                    <div className={exportIcon}><DownloadingIcon color="primary" fontSize="small" /></div>
                  </>
                  :
                  <span className='lock_icon' >
                    <LockIcon fill="#999999" />
                  </span>
                }
              </div>
            </MenuItem>
          </>
        )
      }
    </div>
  )
}

export default MenuSingleItem
