import {
    getCompsetName, convertToPercent, getCorrectValue, round, divide,
    transformMarketForecastItem, transformHotelForecastItem, deepCopy
} from "./util";
// Making columns config for tables 
// because it may be very big list and reduce readability if kept in main config file
export const generatDefaultSubcolumns = (kpi, parent) => {
    const kpisMap = {
        Occ: {
            "display": "Occ%",
            "value": "Occ",
            "subcol_id": parent + "_Occ",
            "type": "percent",

        },
        ADR: {
            "display": "ADR",
            "value": "ADR",
            "subcol_id": parent + "_ADR",
            "type": "currency",
        },
        RevPAR: {
            "display": "RevPAR",
            "value": "RevPAR",
            "subcol_id": parent + "_RevPAR",
            "type": "currency",
        },
        Revenue: {
            "display": "Revenue",
            "value": "Revenue",
            "subcol_id": parent + "_Revenue",
            "type": "currency",
        },
        Rooms: {
            "display": "Rooms Sold",
            "value": "Rooms",
            "subcol_id": parent + "_Rooms",
            "type": "integer",
        },
        SellRate: {
            "display": "Sell Rate",
            "value": "SellRate",
            "subcol_id": parent + "_SellRate",
            "type": "currency",
        },
        Restrictions: {
            "display": "Restrictions",
            "value": "Restrictions",
            "subcol_id": parent + "_Restrictions",
            "type": "restrictions",
        },
        date: {
            "display": "Date",
            "value": "date",
            "subcol_id": parent + "_date",
            "type": "date",
        },
        dba: {
            "display": "DBA",
            "value": "dba",
            "subcol_id": parent + "_dba",
            "type": "integer"
        },
        dow: {
            "display": "DOW",
            "value": "dow",
            "subcol_id": parent + "_dow",
            "type": "dow",
        },
        segment: {
            "display": "Segment",
            "value": "segment",
            "subcol_id": parent + "_segment",
            "type": "segment",
        },
        month: {
            "display": "Month",
            "value": "month",
            "subcol_id": parent + "_month",
            "type": "string"
        },
        avgrate: {
            "display": "Avg Rate",
            "value": "avg",
            "subcol_id": parent + "_avg",
            "type": "string"
        },
        cmedium: {
            "display": "Compset Median",
            "value": 'CM',
            "subcol_id": parent + "_CM",
            "type": "currency"
        },
        compdate: {
            "display": "Comparison Date",
            "value": 'compdate',
            "subcol_id": parent + "_compdate",
            "type": "compdate"
        },
        total_flights: {
            "display": "Total",
            "value": 'total_flights',
            "subcol_id": parent + "_total_flights",
            "type": "number"
        },
        flights_segment: {
            "display": "Point Of Sale",
            "value": 'segment',
            "subcol_id": parent + "_segment",
            "type": "string"
        },
        domestic: {
            "display": "Domestic",
            "value": 'domestic',
            "subcol_id": parent + "_domestic",
            "type": "number"
        },
        international: {
            "display": "International",
            "value": 'international',
            "subcol_id": parent + "_international",
            "type": "number"
        },
        per_day: {
            "display": "Per Day",
            "value": 'per_day',
            "subcol_id": parent + "_per_day",
            "type": "number"
        },
        total_flights_percent: {
            "display": "Total",
            "value": 'total_flights', //'total_flights_percent', changed to fix the filter issue
            "subcol_id": parent + "_total_flights_percent",
            "type": "number"
        },
        domestic_percent: {
            "display": "Domestic",
            "value": 'domestic', //'domestic_percent', changed to fix the filter issue
            "subcol_id": parent + "_domestic_percent",
            "type": "number"
        },
        international_percent: {
            "display": "International",
            "value": 'international', //'international_percent', changed to fix the filter issue
            "subcol_id": parent + "_international_percent",
            "type": "number"
        },
        per_day_percent: {
            "display": "Per Day",
            "value": 'per_day', //'per_day_percent', changed to fix the filter issue
            "subcol_id": parent + "_per_day_percent",
            "type": "number"
        },

        //For Resctrictions
        rec: {
            "display": "Lodgiq Rec",
            "value": 'rec',
            "subcol_id": parent + "_rec",
            "type": "restriction"
        },
        cur: {
            "display": "Current",
            "value": 'cur',
            "subcol_id": parent + "_cur",
            "type": "restriction"
        },
        current_rate: {
            "display": "Current Rate",
            "value": "SellRate",
            "subcol_id": parent + "_SellRate",
            "type": "currency",
        },
        sold: {
            "display": "Rooms Sold",
            "value": "Rooms",
            "subcol_id": parent + "_Rooms",
            "type": "number",
        },
        // for Rate Push Report 
        UpdateTime: {
            "display": "Update Time",
            "value": 'UpdateTime',
            "subcol_id": parent + "_UpdateTime",
            "type": "string"
        },
        User: {
            "display": "User",
            "value": 'User',
            "subcol_id": parent + "_User",
            "type": "string"
        },
        Action: {
            "display": "Action",
            "value": 'Action',
            "subcol_id": parent + "_Action",
            "type": "string"
        },
        Roomsrate: {
            "display": "Rooms & Rate",
            "value": 'Roomsrate',
            "subcol_id": parent + "_Roomsrate",
            "type": "number"
        },
        RoomsRestrictions: {
            "display": "Rooms & Restrictions",
            "value": 'RoomsRestrictions',
            "subcol_id": parent + "_RoomsRestrictions",
            "type": "restrictions-component"
        },
        Status: {
            "display": "Status",
            "value": 'Status',
            "subcol_id": parent + "_Status",
            "type": "number"
        },
        PriorRate: {
            "display": "Prior Rate",
            "value": 'PriorRate',
            "subcol_id": parent + "_PriorRate",
            "type": "number"
        },
        RecRate: {
            "display": "Rec Rate",
            "value": 'RecRate',
            "subcol_id": parent + "_RecRate",
            "type": "number"
        },
        ChangeRate: {
            "display": "Change Rate",
            "value": 'ChangeRate',
            "subcol_id": parent + "_ChangeRate",
            "type": "number"
        },

    };
    return {
        ...kpisMap[kpi],
        "parent": parent,
        "className": "col-sub",
        "draggable": true,
        "sortable": true,
        "filterable": true, "filterMenu": true,
        "sorted": null,
        "hidden": false,
        "order": 1
    }
}

export const TABLE_CONFIG = {
    pricing: {
        columns: [
            {

                id: "date",
                display: "{rangeyear}",
                series: 'OSR',
                left: 0,
                type: "dateColumn",
                isSticky: true,
                roomTypeCompatible: true,
                is_default: true,
                subcolumns: [
                    { subcol_id: "date", value: "date", display: "Date", type: "date", format: "MM DD" },
                    { subcol_id: "dow", value: "dow", display: "DOW", type: "dow", filterable: true, filterMenu: true },
                ]
            },
            {
                id: "otb",
                display: "On The Books",
                series: 'OSR',
                type: "dataColumn",
                left: 152,
                view: "hidden",
                isSticky: true,
                roomTypeCompatible: true,
                subcolumns: [
                    { subcol_id: "otb_Occ", value: "Occ", display: "Occ", type: "percent" },
                    { subcol_id: "otb_ADR", value: "ADR", display: "ADR", type: "currency" },
                ]
            },
            {
                id: "forecast",
                type: "dataColumn",
                left: 300,
                view: "hidden",
                display: "Forecast",
                roomTypeCompatible: true,
                isSticky: true,
                series: 'OSRContrainedForecast',
                subcolumns: [{ subcol_id: "forecast_Occ", value: "Occ", display: "Occ", type: "percent" },
                { subcol_id: "forecast_ADR", value: "ADR", display: "ADR", type: "currency" },]
            },

        ],
        houseLevelColumns: [
            {
                id: "restrictions",
                type: "dataColumn",
                display: "Restrictions",
                series: 'OSR',
                hidden: false,
                isSticky: false,
                subcolumns: [{ subcol_id: "restrictions_house", hidden: false, value: "restrictions_house", display: "Current", type: "restriction_display" },]
            },
            {
                id: "pricing",
                type: "dataColumn",
                isSticky: false,
                display: "Bar Pricing",
                series: 'OSRRateRecommend',
                draggable: true,
                tileName: "pricing",
                subcolumns: [{ subcol_id: "pricing_Sold", value: "Sold", display: "Sold", type: "integer" },
                { subcol_id: "pricing_SellRate", value: "SellRate", display: "Current Rate", type: "currency" },
                { subcol_id: "pricing_RecSellRate", value: "RecSellRate", display: "Lodgiq Rate", type: "currency" },
                { subcol_id: "pricing_Explainer", value: "Explainer", display: "Explainer", type: "html", hidden: true, },
                {
                    subcol_id: "pricing_ratediff", value: "ratediff", display: "+/-", type: "diff", method: {
                        type: "diff",
                        keys: ["SellRate", "RecSellRate"]
                    }
                },
                { subcol_id: "pricing_Override", value: "Override", display: "Override Rate", type: "input" },
                { subcol_id: "pricing_Approve", value: "Approve", display: "Approve", type: "checkbox" },
                ]
            }],
        roomTypeOnColumns: [{
            type: "roomTypeColumn",
            isBase: true,
            series: 'OSRRateRecommend',
            isSticky: true,
            left: 441,
            subcolumns: [{ subcol_id: "Sold", value: "Sold", display: "Sold", type: "integer", left: 83 },
            { subcol_id: "restrictions_current", hidden: false, value: "restrictions_current", display: "restrictions", type: "restriction_display" },
            { subcol_id: "RecSellRate", value: "RecSellRate", display: "Lodgiq Rate", left: 43, type: "currency", hasSelectAll: true, },
            { subcol_id: "SellRate", value: "SellRate", display: "Current Rate", left: 101, type: "currency" },
            { subcol_id: "baseOnlyUpdate", value: "baseOnlyUpdate", hidden: false, left: 82, hasSelectAll: true, display: "Update Base Only", type: "checkbox" },]
        },]
    },
    otb: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "isSticky": true,
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        "draggable": false,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true,
                        "filterMenu": true,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": true,
                        "sortable": false,
                        // "hidden": true,
                        "order": 3
                    }
                ],
                "subcolumns_rateplan": [
                    {
                        ...generatDefaultSubcolumns("Occ", "staydates"),
                        "order": 1,
                    }, {
                        ...generatDefaultSubcolumns("Rooms", "staydates"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "staydates"),
                        "order": 3
                    }
                ],

            },
            {
                "id": "otb_current",
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "otb",
                "display": "as of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_current"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_current"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_current"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_current"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_current"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_current"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            }
        ],
        restrictionsOn_columns: [
            {
                "id": "otb_restrictions",
                "dataId": "GetRestrictions",
                "type": "dataColumn",
                "mainKey": "restrictions",
                "tileName": "otb",
                "display": "",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": null,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Restrictions", "otb_restrictions"),
                        // "hidden": true,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,
                        "order": 1
                    }
                ],

            }
        ],
        sdlyOn_columns: [
            {
                "id": "otb_sdly",
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                        "hidden": false,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_actual_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "otb",
                "display": "{sdYear-actuals} Actuals",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_actual_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_actual_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_actual_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_actual_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_actual_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_actual_sdly"),
                        "hidden": false,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "calculate": ({ primary, sdly, sdtly }, workspace_controls) => {
                    let dayDate = workspace_controls.dayDate;
                    let calc = {};
                    if (primary && sdly) {
                        let kpis = Object.keys(primary);
                        kpis.map(kpi => {
                            calc[kpi] = (dayDate === 'day') ? primary[kpi] - sdly[kpi] : primary[kpi] - sdtly[kpi];
                        })
                    }
                    return calc;
                },
                "tileName": "otb",
                "display": "Difference To As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
                        "hidden": false,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_variance_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "variance_sdly",
                "calculate": ({ primary, sdly, sdtly }, workspace_controls) => {
                    let dayDate = workspace_controls.dayDate;
                    let calc = {};
                    if (primary && sdly && sdtly) {
                        let kpis = Object.keys(primary);
                        kpis.map(kpi => {
                            calc[kpi] = (dayDate === 'day') ? ((primary[kpi] - sdly[kpi]) / sdly[kpi]) : ((primary[kpi] - sdtly[kpi]) / sdtly[kpi]);
                            calc[kpi] = (calc[kpi] && isFinite(calc[kpi])) ? convertToPercent(calc[kpi]) + '' : '0%';
                        })
                    }
                    return calc;
                },
                "tileName": "otb",
                "display": "% Difference To As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_variance_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_variance_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_variance_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_variance_sdly"),
                        "type": "percent",
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_variance_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_variance_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },

            {
                "id": "otb_remaing_pickup_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "pickup_diff_sdly",
                "calculate": ({ sdly_actual, sdly, sdtly, sdtly_actual }, workspace_controls) => {
                    let dayDate = workspace_controls.dayDate;
                    let calc = {};
                    if (sdly_actual && sdly && sdtly && sdtly_actual) {

                        let kpis = Object.keys(sdly_actual);
                        kpis.map(kpi => {
                            if (kpi === "ADR") {
                                let adrVal = (dayDate === 'day') ? (sdly_actual['Revenue'] - sdly["Revenue"]) / (sdly_actual['Rooms'] - sdly["Rooms"]) : (sdtly_actual['Revenue'] - sdtly["Revenue"]) / (sdtly_actual['Rooms'] - sdtly["Rooms"])
                                calc[kpi] = adrVal;
                            } else {
                                calc[kpi] = (dayDate === 'day') ? sdly_actual[kpi] - sdly[kpi] : sdtly_actual[kpi] - sdtly[kpi];
                            }
                        })
                    }
                    return calc;
                },
                "tileName": "otb",
                // "display": "REMAINING PICK UP FOR As Of - {otbSdlyDate}",
                "display": "Remaining Pick Up - {sdYear-actuals}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_remaing_pickup_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_remaing_pickup_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_remaing_pickup_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_remaing_pickup_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_remaing_pickup_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_remaing_pickup_sdly"),
                        "hidden": false,
                        "order": 6
                    }
                ],
            },
        ]
    },
    pickup: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                // "display": "2023",
                "isSticky": true,
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true, "filterMenu": true,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": true, "filterMenu": true,
                        // "hidden": true,
                        "sortable": false,

                        "order": 3
                    }
                ],
                "subcolumns_rateplan": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "staydates"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "staydates"),
                        "order": 2
                    }
                ],

            },

            {
                "id": "pickup_difference",
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "otb_difference",
                "calculate": ({ current, primary, prior, pickup }, controls) => {
                    current = (!current) ? { "ADR": 0, "Occ": 0, "RevPAR": 0, "Revenue": 0, "Rooms": 0, "sellRate": 0 } : current;
                    prior = (!prior) ? { "ADR": 0, "Occ": 0, "RevPAR": 0, "Revenue": 0, "Rooms": 0, "sellRate": 0 } : prior;
                    let calc = {};
                    let a = controls.pickUpType === 'Standard' ? current : primary;
                    let b = controls.pickUpType === 'Standard' ? prior : pickup;
                    if (a && b) {
                        let kpis = Object.keys(a);
                        kpis.map(kpi => {
                            if (kpi === "ADR") {
                                //calc[kpi] = (getCorrectValue(a['Revenue']) - getCorrectValue(b['Revenue'])) / (getCorrectValue(a['Rooms']) - getCorrectValue(b['Rooms']))
                                calc[kpi] = divide((getCorrectValue(a['Revenue']) - getCorrectValue(b['Revenue'])), (getCorrectValue(a['Rooms']) - getCorrectValue(b['Rooms'])))

                            } else {
                                calc[kpi] = getCorrectValue(a[kpi]) - getCorrectValue(b[kpi])
                            }
                        })
                    }
                    return calc;
                },
                // kpis.map(kpi=>{calc[kpi] = primary[kpi] - sdly[kpi]})

                "tileName": "pickup",
                "display": "%dayscount% Day Pickup as of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_difference"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_difference"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_difference"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_difference"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_difference"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_difference"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "pickup_otb_current",
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "current",
                "tileName": "pickup",
                "display": "OTB - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_otb_current"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_otb_current"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_otb_current"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_otb_current"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_otb_current"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_otb_current"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "pickup_prior",
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "pickup",
                "tileName": "pickup",
                "display": "OTB - {subsPickupDays}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_prior"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_prior"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_prior"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_prior"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_prior"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_prior"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            }
        ],

        sdlyOn_columns: [
            {
                "id": "pickup_sdow_ly",
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "otb_sdly_diff",
                "tileName": "pickup",
                "calculate": ({ sdly, sdly_pickup, sdtly, sdtly_pickup }, workspace_controls, controls) => {
                    let dayDate = workspace_controls.dayDate;
                    let calc = {};
                    let kpis = Object.keys(sdly);
                    kpis.map(kpi => {
                        if (kpi === 'ADR') {
                            calc[kpi] = (dayDate === 'day') ? round(divide(sdly['Revenue'] - sdly_pickup['Revenue'], sdly['Rooms'] - sdly_pickup['Rooms'])) : round(divide(sdtly['Revenue'] - sdtly_pickup['Revenue'], sdtly['Rooms'] - sdtly_pickup['Rooms']));
                        } else {
                            calc[kpi] = (dayDate === 'day') ? getCorrectValue(sdly[kpi]) - getCorrectValue(sdly_pickup[kpi]) : getCorrectValue(sdtly[kpi]) - getCorrectValue(sdtly_pickup[kpi]);
                        }
                    })
                    return calc;
                },
                // "display": "PICKUP SDOW LAST YEAR",
                "display": "%dayscount% Day Pickup as of - {subsYearDays}",

                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_sdow_ly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_sdow_ly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_sdow_ly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_sdow_ly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_sdow_ly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_sdow_ly"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "pickup_otb_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "pickup",
                // "display": "OTB - SDLY - {subsYearDays}",
                "display": "OTB - {sdYear} - {subsYearDays}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 6,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_otb_sdly"),
                        // "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "pickup_otb_sdly_prior", // likewise: otb_sdly, otb_prior,...
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "sdly_pickup",
                "tileName": "pickup",
                // "display": "OTB - SDLY - {sdlyPickupDays}",
                "display": "OTB - {sdYear} - {sdlyPickupDays}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "pickup_otb_sdly_prior"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "pickup_otb_sdly_prior"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "pickup_otb_sdly_prior"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "pickup_otb_sdly_prior"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "pickup_otb_sdly_prior"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "pickup_otb_sdly_prior"),
                        // "hidden": true,
                        "order": 6
                    }
                ],
            },
        ]
    },

    market: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        // "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true,
                        "filterMenu": true,
                        "order": 2
                    },

                ],
                "subcolumns_dba": [
                    {
                        ...generatDefaultSubcolumns("dba", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },


                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": true,
                        // "hidden": true,
                        "sortable": false,
                        "order": 3
                    }
                ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "market_otb",
                "dataId": "MarketARI",
                // "colc_id": "MarketARI",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "market",
                "display": "Market OTB",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_otb"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_otb"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_otb"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_otb"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_otb"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_otb"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_otb_prior",
                "dataId": "MarketARI",
                // "colc_id": "MarketARI",
                "type": "dataColumn",
                "mainKey": "pickup",
                "tileName": "market",
                "display": "As Of - {subsPickupDays}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": true,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_otb_prior"),
                        "order": 2,
                        "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_otb_prior"),
                        "order": 1,
                        "hidden": false,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_otb_prior"),
                        "order": 3,
                        "hidden": false,
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_otb_prior"),
                        "order": 4, "hidden": false,
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_otb_prior"),
                        "order": 5, "hidden": false,
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_otb_prior"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_otb_pickup",
                "dataId": "MarketARI",
                // "colc_id": "MarketARI",

                "type": "dataColumn",
                "mainKey": "marketotbpickup",
                "tileName": "market",
                "display": "Market Pickup - %dayscount% Day",
                // "display": "%dayscount% Day Pickup as of - {asOfDate}",
                "calculate": ({ primary, pickup }, controls) => {
                    // debugger;
                    let calc = {};
                    let a = primary;
                    let b = pickup;
                    if (a && b) {
                        let kpis = Object.keys(a);
                        kpis.map(kpi => {
                            if (kpi === 'ADR') {
                                // calc[kpi] = round((primary['Revenue'] - pickup['Revenue']) / (primary['Rooms'] - pickup['Rooms']))
                                calc[kpi] = round(divide((primary['Revenue'] - pickup['Revenue']), (primary['Rooms'] - pickup['Rooms'])))

                            } else {
                                calc[kpi] = getCorrectValue(a[kpi]) - getCorrectValue(b[kpi])
                            }
                        })
                    }
                    return calc;
                },
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_otb_pickup"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_otb_pickup"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_otb_pickup"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_otb_pickup"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_otb_pickup"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_otb_pickup"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_projected",
                "dataId": "MarketForecastSegmented",
                // "colc_id": "MarketForecastSegmented",

                "type": "dataColumn",
                "mainKey": "market_projected",
                "tileName": "market",
                "display": "{UNCONSTRAINED} Market Forecast",
                // "display": "{UNCONSTRAINED} Market Forecast AS OF - %asOfDate%",
                // "calculate": (data, controls, meta) => {
                //     // debugger;  
                //     const { original_item, identity, date, segmentCode } = meta;
                //     let output = {};
                //     if (data && data.length) {
                //         let segmentedItem = data.find((item) => item.market_code === segmentCode);
                //         let transforemed = transformMarketForecastItem(segmentedItem);
                //         output = controls.unconstrained ? transforemed.forecast_uncons : transforemed.forecast;
                //         // if(!output) debugger;
                //     } else if (data && !data.length && original_item) {
                //         // debugger
                //         if (segmentCode && original_item['MarketSegment_' + segmentCode]) {
                //             output = deepCopy(original_item['MarketSegment_' + segmentCode].market_projected);
                //             // output.SellRate = original_item.HotelTotal.primary.SellRate;
                //         }
                //     }
                //     // debugger;
                //     return output;
                // },
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 11,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_projected"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_projected"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_projected"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_projected"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_projected"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_projected"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            }
        ],
        dataDbaColumns: [
            {
                "id": "market_pace",
                "dataId": "MarketDba",
                // "colc_id": "MarketDba",

                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "market",

                "display": "Market Pace",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_pace"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_pace"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_pace"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_pace"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_pace"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_pace"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },

        ],
        dbaOn_sdly_columns: [
            {
                "id": "market_dba_sdly_pace",
                "dataId": "MarketSdlyDba",
                // "colc_id": "MarketSdlyDba",
                "type": "dataColumn",
                // "mainKey": "sdly_dba_comp",
                "mainKey": "sdly",

                "tileName": "market",
                // "display": "SDLY Comparision Market Pace",
                "display": "{sdYear} Comparision Market Pace",

                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_dba_sdly_pace"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_dba_sdly_pace"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_dba_sdly_pace"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_dba_sdly_pace"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_dba_sdly_pace"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_dba_sdly_pace"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_dba_sdly_variance",
                "dataId": "MarketSdlyDba",
                // "colc_id": "MarketSdlyDba",

                "type": "dataColumn",
                "mainKey": "dba_variance",
                "tileName": "market",
                "display": "Variance",
                "calculate": ({ primary, sdly }, controls) => {
                    let calc = {};
                    let a = primary;
                    let b = sdly;
                    if (a && b) {
                        let kpis = Object.keys(a);
                        kpis.map(kpi => {
                            if (kpi === 'ADR_xx') {
                                calc[kpi] = round((a['Revenue'] - b['Revenue']) / (a['Rooms'] - b['Rooms']))
                            } else {
                                calc[kpi] = getCorrectValue(a[kpi]) - getCorrectValue(b[kpi])
                            }
                        })
                    }
                    return calc;
                },
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_dba_sdly_variance"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_dba_sdly_variance"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_dba_sdly_variance"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_dba_sdly_variance"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_dba_sdly_variance"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_dba_sdly_variance"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
        ],
        marketDateSdlyOn_columns: [
            {
                "id": "market_sdly",
                "dataId": "MarketARI",
                // "colc_id": "MarketARI",

                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "market",
                // "display": "Market Sdly",
                "display": "Market OTB same {day-date} - {sdYear}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_sdly"),
                        "order": 6
                    }
                ],

            },
            {
                "id": "diff_market_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "MarketARI",
                // "colc_id": "MarketARI",

                "type": "dataColumn",
                "mainKey": "sdly_diff_sdowly",
                "tileName": "market",
                "display": "Market OTB Vs Market OTB Same {day-date} - {sdYear}",
                "calculate": ({ primary, pickup, sdly, sdtly }, workspace_controls, controls) => {
                    let dayDate = workspace_controls.dayDate;
                    let calc = {};
                    let a = primary;
                    let b = (dayDate === 'day') ? sdly : sdtly;
                    if (a && b) {
                        let kpis = Object.keys(a);
                        kpis.map(kpi => {
                            if (kpi === 'ADR_x') {
                                calc[kpi] = round(divide((a['Revenue'] - b['Revenue']), (a['Rooms'] - b['Rooms'])))
                            } else {
                                calc[kpi] = getCorrectValue(a[kpi]) - getCorrectValue(b[kpi])
                            }
                        })
                    }
                    return calc;
                },
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 6,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "diff_market_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "diff_market_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "diff_market_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "diff_market_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "diff_market_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "diff_market_sdly"),
                        "order": 6
                    }
                ],
            },
            {
                "id": "market_variance_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "MarketARI",
                // "colc_id": "MarketARI",

                "type": "dataColumn",
                "mainKey": "sdly_variance_sdowly",
                "tileName": "market",
                "display": "Market OTB Vs Market OTB Same {day-date} - {sdYear} %",
                "calculate": (data, controls, meta) => {
                    let calc = {};
                    let dayDate = controls.dayDate;
                    const { primary, sdly, sdtly } = data;
                    if (primary) {
                        let kpis = Object.keys(primary);
                        kpis.map(kpi => {
                            // debugger;
                            calc[kpi] = (dayDate === 'day') ? ((primary[kpi] - sdly[kpi]) / sdly[kpi] * 100) : ((primary[kpi] - sdtly[kpi]) / sdtly[kpi] * 100);
                            calc[kpi] = (calc[kpi] && isFinite(calc[kpi])) ? calc[kpi] + '' : '0%';
                        })
                    }
                    return calc;
                },
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_variance_sdly"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_variance_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_variance_sdly"),
                        "order": 3,
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_variance_sdly"),
                        "order": 4,
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_variance_sdly"),
                        "order": 5,
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_variance_sdly"),
                        hidden: true,
                        "order": 6,
                    }
                ],
            },
            {
                "id": "market_actual_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "MarketARI",
                // "colc_id": "MarketARI",

                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "market",
                // "display": "Last Year Actual",
                "display": "Market Actuals {sdYear-market-actuals}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 8,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_actual_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_actual_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_actual_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_actual_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_actual_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_actual_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "sdly_diff_last_year", // likewise: otb_sdly, otb_prior,...
                "dataId": "MarketARI",
                "type": "dataColumn",
                "mainKey": "sdly_diff_market_last_year_actuals",
                "tileName": "market",
                "display": "Market OTB Vs Market Actual - {sdYear}",
                "calculate": ({ sdly, sdly_actual, sdtly, sdtly_actual }, controls) => {
                    let calc = {};
                    let dayDate = controls.dayDate;
                    let a = (dayDate === 'day') ? sdly : sdtly;
                    let b = (dayDate === 'day') ? sdly_actual : sdtly_actual;
                    if (a && b) {
                        let kpis = Object.keys(a);
                        kpis.map(kpi => {
                            calc[kpi] = getCorrectValue(a[kpi]) - getCorrectValue(b[kpi]);
                        })
                    }
                    return calc;
                },
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 9,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "sdly_diff_last_year"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "sdly_diff_last_year"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "sdly_diff_last_year"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "sdly_diff_last_year"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "sdly_diff_last_year"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "sdly_diff_last_year"),

                        "order": 6
                    }
                ],
            },
            {
                "id": "sdly_var_market_last_year", // likewise: otb_sdly, otb_prior,...
                "dataId": "MarketARI",
                // "colc_id": "MarketARI",

                "type": "dataColumn",
                "mainKey": "sdly_variance_market_last_year_actuals",
                "tileName": "market",
                // "display": "% Difference to Market LY Actual",
                "display": "Market OTB Vs Market Actual - {sdYear} %",
                "calculate": (data, controls, meta) => {
                    let calc = {};
                    let dayDate = controls.dayDate;
                    let { sdly, sdly_actual, sdtly, sdtly_actual } = data;

                    if (sdly) {
                        let kpis = Object.keys(sdly);
                        kpis.map(kpi => {
                            calc[kpi] = (dayDate === 'day') ? ((sdly[kpi] - sdly_actual[kpi]) / sdly_actual[kpi] * 100) : ((sdtly[kpi] - sdtly_actual[kpi]) / sdtly_actual[kpi] * 100);
                            calc[kpi] = (calc[kpi] && isFinite(calc[kpi])) ? calc[kpi] + '' : '0%';
                        })
                    }

                    return calc;
                },
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 10,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "sdly_var_market_last_year"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "sdly_var_market_last_year"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "sdly_var_market_last_year"),
                        "order": 3,
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "sdly_var_market_last_year"),
                        "order": 4,
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "sdly_var_market_last_year"),
                        "order": 5,
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "sdly_var_market_last_year"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
        ],
    },

    // configuration for Market Summary (market_summary)
    market_summary: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("month", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    }
                ],
            },

            {
                "id": "market_summary_otb",
                "type": "dataColumn",
                "mainKey": "primary",
                "display": "OTB As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [

                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_otb"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_otb"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_otb"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_otb"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_otb"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_otb"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },


            {
                "id": "market_summary_total_forcast",
                "type": "dataColumn",
                "mainKey": "total_forecast",
                "display": "System Forecast As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 6,
                "subcolumns": [

                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_total_forcast"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_total_forcast"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_total_forcast"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_total_forcast"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_total_forcast"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_total_forcast"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
        ],
            // Market summary SDLY Column
            sdlyOn_columns: [{
                "id": "market_summary_sdly_otb",
                "type": "dataColumn",
                "mainKey": "sdly",
                "display": "OTB As Of - {substract364}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [

                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_sdly_otb"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_sdly_otb"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_sdly_otb"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_sdly_otb"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_sdly_otb"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_sdly_otb"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_summary_sdly_variance",
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "display": "Difference to {sdlyText}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [

                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_sdly_variance"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_sdly_variance"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_sdly_variance"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_sdly_variance"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_sdly_variance"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_sdly_variance"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "market_summary_variance_percent",
                "type": "dataColumn",
                "mainKey": "difference_sdly_percent",
                "display": "% Difference to {sdlyText}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [

                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_variance_percent"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_variance_percent"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_variance_percent"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_variance_percent"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_variance_percent"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "market_summary_variance_percent"),
                    //     "hidden": true,
                    //     "order": 6
                    // }
                ],

            },


            {
                "id": "market_summary_sdly_actuals",
                "type": "dataColumn",
                "mainKey": "sdly_actuals",
                "display": "Last Year Actuals",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [

                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_sdly_actuals"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_sdly_actuals"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_sdly_actuals"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_sdly_actuals"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_sdly_actuals"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_sdly_actuals"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "market_summary_sdly_actuals_variance",
                "type": "dataColumn",
                "mainKey": "difference_forcast_actualSDLY",
                "display": "System forecast - LY Actuals",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 8,
                "subcolumns": [

                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_sdly_actuals_variance"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_sdly_actuals_variance"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_sdly_actuals_variance"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_sdly_actuals_variance"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_sdly_actuals_variance"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_summary_sdly_actuals_variance"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "market_summary_sdly_actuals_variance_percent",
                "type": "dataColumn",
                "mainKey": "difference_forcast_actualSDLY_percent",
                "display": "System forecast - LY Actuals (%)",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 9,
                "subcolumns": [

                    {
                        ...generatDefaultSubcolumns("Occ", "market_summary_sdly_actuals_variance_percent"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_summary_sdly_actuals_variance_percent"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_summary_sdly_actuals_variance_percent"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_summary_sdly_actuals_variance_percent"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_summary_sdly_actuals_variance_percent"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "market_summary_sdly_actuals_variance_percent"),
                    //     "hidden": true,
                    //     "order": 6
                    // }
                ],

            },


        ],
    },

    // configuration for Hotel Summary (hotel_summary)
    hotel_summary: {
        columns: [
            {
                "id": "hotel_staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("month", "hotel_staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    }
                ],
            },

            {
                "id": "hotel_summary_otb",
                "type": "dataColumn",
                "mainKey": "primary",
                "display": "OTB As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "hotel_summary_otb"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "hotel_summary_otb"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "hotel_summary_otb"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "hotel_summary_otb"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "hotel_summary_otb"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "hotel_summary_otb"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "hotel_summary_total_forcast",
                "type": "dataColumn",
                "mainKey": "total_forecast",
                "display": "System Forecast As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 6,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "hotel_summary_total_forcast"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"
    
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "hotel_summary_total_forcast"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "hotel_summary_total_forcast"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "hotel_summary_total_forcast"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "hotel_summary_total_forcast"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "hotel_summary_total_forcast"),
                        // "hidden": true,
                        "order": 6
                    }
                ],
    
            },
    
        ],
        // Market summary SDLY Column
        sdlyOn_columns: [{
            "id": "hotel_summary_sdly_otb",
            "type": "dataColumn",
            "mainKey": "sdly",
            "display": "OTB As Of - {substract364}",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": 3,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("Occ", "hotel_summary_sdly_otb"),
                    "order": 1,
                },
                {
                    ...generatDefaultSubcolumns("Rooms", "hotel_summary_sdly_otb"),
                    "order": 2,
                },

                {
                    ...generatDefaultSubcolumns("ADR", "hotel_summary_sdly_otb"),
                    "order": 3
                },
                {
                    ...generatDefaultSubcolumns("RevPAR", "hotel_summary_sdly_otb"),
                    "order": 4
                },
                {
                    ...generatDefaultSubcolumns("Revenue", "hotel_summary_sdly_otb"),
                    "order": 5
                },
                {
                    ...generatDefaultSubcolumns("SellRate", "hotel_summary_sdly_otb"),
                    // "hidden": true,
                    "order": 6
                }
            ],

        },
        {
            "id": "hotel_summary_sdly_variance",
            "type": "dataColumn",
            "mainKey": "difference_sdly",
            "display": "Difference To {sdlyText}",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": 4,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("Rooms", "hotel_summary_sdly_variance"),
                    "order": 2,
                    // "hidden": false,
                    // "sortable": false,
                    // "sorted": "asc"

                },
                {
                    ...generatDefaultSubcolumns("Occ", "hotel_summary_sdly_variance"),
                    "order": 1,
                },
                {
                    ...generatDefaultSubcolumns("ADR", "hotel_summary_sdly_variance"),
                    "order": 3
                },
                {
                    ...generatDefaultSubcolumns("RevPAR", "hotel_summary_sdly_variance"),
                    "order": 4
                },
                {
                    ...generatDefaultSubcolumns("Revenue", "hotel_summary_sdly_variance"),
                    "order": 5
                },
                {
                    ...generatDefaultSubcolumns("SellRate", "hotel_summary_sdly_variance"),
                    // "hidden": true,
                    "order": 6
                }
            ],

        },

        {
            "id": "hotel_summary_variance_percent",
            "type": "dataColumn",
            "mainKey": "difference_sdly_percent",
            "display": "% Difference to {sdlyText}",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": 5,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("Rooms", "hotel_summary_variance_percent"),
                    "order": 2,
                    // "hidden": false,
                    // "sortable": false,
                    // "sorted": "asc"

                },
                {
                    ...generatDefaultSubcolumns("Occ", "hotel_summary_variance_percent"),
                    "order": 1,
                },
                {
                    ...generatDefaultSubcolumns("ADR", "hotel_summary_variance_percent"),
                    "order": 3
                },
                {
                    ...generatDefaultSubcolumns("RevPAR", "hotel_summary_variance_percent"),
                    "order": 4
                },
                {
                    ...generatDefaultSubcolumns("Revenue", "hotel_summary_variance_percent"),
                    "order": 5
                },
                {
                    ...generatDefaultSubcolumns("SellRate", "hotel_summary_variance_percent"),
                    "hidden": true,
                    "order": 6
                }
            ],

        },

  
        {
            "id": "hotel_summary_sdly_actuals",
            "type": "dataColumn",
            "mainKey": "sdly_actuals",
            "display": "Last Year Actual",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": 7,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("Rooms", "hotel_summary_sdly_actuals"),
                    "order": 2,
                    // "hidden": false,
                    // "sortable": false,
                    // "sorted": "asc"

                },
                {
                    ...generatDefaultSubcolumns("Occ", "hotel_summary_sdly_actuals"),
                    "order": 1,
                },
                {
                    ...generatDefaultSubcolumns("ADR", "hotel_summary_sdly_actuals"),
                    "order": 3
                },
                {
                    ...generatDefaultSubcolumns("RevPAR", "hotel_summary_sdly_actuals"),
                    "order": 4
                },
                {
                    ...generatDefaultSubcolumns("Revenue", "hotel_summary_sdly_actuals"),
                    "order": 5
                },
                {
                    ...generatDefaultSubcolumns("SellRate", "hotel_summary_sdly_actuals"),
                    // "hidden": true,
                    "order": 6
                }
            ],

        },

        {
            "id": "hotel_summary_sdly_actuals_variance",
            "type": "dataColumn",
            "mainKey": "difference_forcast_actualSDLY",
            "display": "System Forecast - LY Actuals",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": 8,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("Rooms", "hotel_summary_sdly_actuals_variance"),
                    "order": 2,
                    // "hidden": false,
                    // "sortable": false,
                    // "sorted": "asc"

                },
                {
                    ...generatDefaultSubcolumns("Occ", "hotel_summary_sdly_actuals_variance"),
                    "order": 1,
                },
                {
                    ...generatDefaultSubcolumns("ADR", "hotel_summary_sdly_actuals_variance"),
                    "order": 3
                },
                {
                    ...generatDefaultSubcolumns("RevPAR", "hotel_summary_sdly_actuals_variance"),
                    "order": 4
                },
                {
                    ...generatDefaultSubcolumns("Revenue", "hotel_summary_sdly_actuals_variance"),
                    "order": 5
                },
                {
                    ...generatDefaultSubcolumns("SellRate", "hotel_summary_sdly_actuals_variance"),
                    // "hidden": true,
                    "order": 6
                }
            ],

        },

        {
            "id": "hotel_summary_sdly_actuals_variance_percent",
            "type": "dataColumn",
            "mainKey": "difference_forcast_actualSDLY_percent",
            "display": "System Forecast - LY Actuals (%)",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 6,
            "order": 9,
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("Rooms", "hotel_summary_sdly_actuals_variance_percent"),
                    "order": 2,
                    // "hidden": false,
                    // "sortable": false,
                    // "sorted": "asc"

                },
                {
                    ...generatDefaultSubcolumns("Occ", "hotel_summary_sdly_actuals_variance_percent"),
                    "order": 1,
                },
                {
                    ...generatDefaultSubcolumns("ADR", "hotel_summary_sdly_actuals_variance_percent"),
                    "order": 3
                },
                {
                    ...generatDefaultSubcolumns("RevPAR", "hotel_summary_sdly_actuals_variance_percent"),
                    "order": 4
                },
                {
                    ...generatDefaultSubcolumns("Revenue", "hotel_summary_sdly_actuals_variance_percent"),
                    "order": 5
                },
                {
                    ...generatDefaultSubcolumns("SellRate", "hotel_summary_sdly_actuals_variance_percent"),
                    "hidden": true,
                    "order": 6
                }
            ],

        },


        ],
    },

    // Configuration of Forcast 

    forecast: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true, "filterMenu": true,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": true, "filterMenu": true,
                        // "hidden": true,
                        "sortable": false,
                        "order": 3
                    }
                ]

            },

        ],
        dataDateColumns: [
            {
                "id": "forecast_primary",
                // "dataId": "OSRContrainedForecast",
                // "colc_id": "OSRContrainedForecast",

                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "forecast",
                "display": "System Forecast As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                // "calculate": (data, controls, meta) => {
                //     const { original_item, identity, date, segmentCode } = meta;
                //     let output = {};
                //     debugger 
                //     if (data && data.length) {
                //         let segmentedItem = data.find((item) => item.market_code === segmentCode);
                //         let transformed = transformHotelForecastItem(segmentedItem);
                //         output = controls.unconstrained ? transformed.forecast_uncons : transformed.forecast;

                //     } else if (original_item) {
                //         // debugger
                //         if (segmentCode && original_item['Segment_' + segmentCode]) {
                //             output = deepCopy(original_item['Segment_' + segmentCode].primary);
                //             // output.SellRate = original_item.HotelTotal.primary.SellRate;
                //         }
                //     }
                //     // debugger;
                //     return output;
                // },
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "forecast_primary"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "forecast_primary"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },

                    {
                        ...generatDefaultSubcolumns("ADR", "forecast_primary"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "forecast_primary"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "forecast_primary"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "forecast_primary"),
                    //     "hidden": true,
                    //     "order": 6
                    // }
                ],

            }
        ],
        userForecast_columns: [
            {
                "id": "userForecast",
                "dataId": "ManualForecast",
                "colc_id": "ManualForecast",

                "type": "dataColumn",
                "mainKey": "userforecastdata",
                "tileName": "forecast",
                "display": "User Forecast Uploaded - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "calculate": (data, controls, meta) => {
                    // const { original_item, identity, date, segmentCode } = meta;
                    // let output = {};
                    // if (data && data.length) {
                    //     let segmentedItem = data.find((item) => item.market_code === segmentCode);                        
                    //     let transforemed = transformMarketForecastItem(segmentedItem);
                    //     output = controls.unconstrained ? transforemed.forecast_uncons : transforemed.forecast;
                    //     // if(!output) debugger;
                    // } else if (data && !data.length && original_item) {
                    //     // debugger
                    //     if(segmentCode && original_item['MarketSegment_' + segmentCode]){
                    //         output = deepCopy(original_item['MarketSegment_' + segmentCode].primary);
                    //         // output.SellRate = original_item.HotelTotal.primary.SellRate;
                    //     }
                    // }
                    // // debugger;
                    // return output;
                    return {};
                },
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "userForecast"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "userForecast"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "userForecast"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "userForecast"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "userForecast"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "userForecast"),
                    //     "hidden": true,
                    //     "order": 6
                    // }
                ],

            },
            {
                "id": "forecast_difference", // likewise: otb_sdly, otb_prior,...
                "colc_id": "OSRContrainedForecast",
                "type": "dataColumn",
                "mainKey": "difference",
                "tileName": "forecast",
                "display": "Difference To User Forecast",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "calculate": (data, controls, meta) => {
                    // if (meta.date === "2024-02-23T00:00:00") debugger;
                    let calc = {};
                    let a = meta.original_item.HotelTotal.primary;
                    let b = meta.original_item.HotelTotal.userforecastdata;
                    if (a && b) {
                        let kpis = Object.keys(a);
                        kpis.map(kpi => {
                            if (data && data.length) {
                                let x = data[0];
                                let key = 'predicted_total_cons_' + kpi.toLocaleLowerCase();
                                calc[kpi] = x[key] - b[kpi];
                                if (kpi === 'Occ') {
                                    calc[kpi] = (Math.round(x[key] * 10000) - Math.round(b[kpi] * 10000)) / 10000;
                                }
                            } else {
                                calc[kpi] = a[kpi] - b[kpi];
                            }
                            // calc[kpi] = (calc[kpi] && isFinite(calc[kpi])) ? calc[kpi] + '' : '0%';
                        })
                    }
                    return calc;
                },

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "forecast_difference"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("Rooms", "forecast_difference"),
                        "order": 2
                    },

                    {
                        ...generatDefaultSubcolumns("ADR", "forecast_difference"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "forecast_difference"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "forecast_difference"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "forecast_difference"),
                    //     "hidden": true,
                    //     "order": 6
                    // }
                ],
            }
        ]
    },
    /// end forecast

    // Configuration for Booking Curve tile (pace)
    pace: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true, "filterMenu": true,
                        "order": 2
                    },

                ],
                "subcolumns_dba": [
                    {
                        ...generatDefaultSubcolumns("dba", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },


                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": true, "filterMenu": true,
                        // "hidden": true,
                        "sortable": false,
                        "order": 3
                    }
                ],
                "subcolumns_rateplan": [
                    {
                        ...generatDefaultSubcolumns("Occ", "staydates"),
                        "order": 1,
                    }, {
                        ...generatDefaultSubcolumns("Rooms", "staydates"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "staydates"),
                        "order": 3
                    }
                ],
            },
        ],
        dataDateColumns: [
            {
                "id": "booking_curve",
                "dataId": "OSRBookingPace",
                // "colc_id": "OSRBookingPace",

                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "pace",
                "display": "as of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "booking_curve"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "booking_curve"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "booking_curve"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "booking_curve"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "booking_curve"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "booking_curve"),
                    //     // "hidden": true,
                    //     "order": 6
                    // }
                ],

            },
            {
                "id": "comparison_pace",
                "dataId": "OSRBookingPace",
                // "colc_id": "OSRBookingPace",

                "type": "dataColumn",
                "mainKey": "comparison",
                "tileName": "pace",
                // "display": "{comparisonrangeyear}",
                "display": "as of - {compAsOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("compdate", "comparison_pace"),
                        // "sorted": 'asc',
                        "sorted": null,
                        "filterable": false,
                        "filterMenu": false,
                        "order": 1
                    },

                    {
                        ...generatDefaultSubcolumns("Rooms", "comparison_pace"),
                        "order": 3,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "comparison_pace"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "comparison_pace"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "comparison_pace"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "comparison_pace"),
                        "order": 6
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "comparison_pace"),
                    //     // "hidden": true,
                    //     "order": 8
                    // }
                ],

            },
            {
                "id": "bc_date_diff",
                "dataId": "OSRBookingPace",
                // "colc_id": "OSRBookingPace",

                "type": "dataColumn",
                "mainKey": "compDateDIff",
                "calculate": ({ primary, comparison }, controls) => {
                    // debugger;
                    let calc = {};
                    let a = primary;
                    let b = comparison;

                    if (a) {
                        let kpis = Object.keys(a);
                        kpis.map(kpi => { calc[kpi] = a[kpi] - b[kpi] })
                    }
                    return calc;
                },
                // "calculate": (primary, comparison)=>{ return primary - comparison; },
                "tileName": "pace",
                "display": "Difference",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "bc_date_diff"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "bc_date_diff"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "bc_date_diff"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "bc_date_diff"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "bc_date_diff"),
                        "order": 5
                    },

                ],

            },
        ],
        dataDbaColumns: [
            {
                "id": "booking_curve_dba",
                "dataId": "OSRBookingPaceDBA",
                // "colc_id": "OSRBookingPaceDBA",

                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "pace",
                "display": "DBA {dbamaindate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "booking_curve_dba"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "booking_curve_dba"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "booking_curve_dba"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "booking_curve_dba"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "booking_curve_dba"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "booking_curve_dba"),
                    //     // "hidden": true,
                    //     "order": 6
                    // }
                ],

            },
            {
                "id": "comparison_pace_dba",
                "dataId": "OSRBookingPaceDBA",
                // "colc_id": "OSRBookingPaceDBA",

                "type": "dataColumn",
                "mainKey": "comparison",
                "tileName": "pace",

                "display": "DBA {dbacomparisiondate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "comparison_pace_dba"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "comparison_pace_dba"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "comparison_pace_dba"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "comparison_pace_dba"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "comparison_pace_dba"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "comparison_pace_dba"),
                    //     // "hidden": true,
                    //     "order": 8
                    // }
                ],

            },
            {
                "id": "bc_dba_diff",
                "dataId": "OSRBookingPaceDBA",
                // "colc_id": "OSRBookingPaceDBA",

                "type": "dataColumn",
                "mainKey": "compDateDIff",
                "tileName": "pace",
                "display": "Difference",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "calculate": ({ primary, comparison }, controls) => {
                    // debugger;
                    let calc = {};
                    let a = primary;
                    let b = comparison;
                    if (a) {
                        let kpis = Object.keys(a);
                        kpis.map(kpi => { calc[kpi] = a[kpi] - b[kpi] })
                    }
                    return calc;
                },
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "bc_dba_diff"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "bc_dba_diff"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "bc_dba_diff"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "bc_dba_diff"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "bc_dba_diff"),
                        "order": 5
                    },

                ],

            },
        ]
    },
    /// end Booking Curve

    // Configuration for market sell rate 
    market_sell_rates: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true, "filterMenu": true,
                        "order": 2
                    },

                ],
            },

        ],
        dataDateColumns: [
            {
                "id": "market_sell_three_star",
                "dataId": "MarketSellRate",
                // "colc_id": "MarketSellRate",

                "type": "dataColumn",
                "mainKey": "sets",
                "tileName": "market_sell_rates",
                "k": "3_star",
                "display": "3 Star",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("avgrate", "market_sell_three_star"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },

                ],

            },
            {
                "id": "market_sell_four_star",
                "dataId": "MarketSellRate",
                // "colc_id": "MarketSellRate",

                "type": "dataColumn",
                "mainKey": "sets",
                "tileName": "market_sell_rates",

                "k": "4_star",
                "display": "4 Star",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("avgrate", "market_sell_four_star"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },

                ],

            },
            {
                "id": "market_sell_five_star",
                "dataId": "MarketSellRate",
                // "colc_id": "MarketSellRate",

                "type": "dataColumn",
                "mainKey": "sets",
                "tileName": "market_sell_rates",

                "k": "5_star",
                "display": "5 Star",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("avgrate", "market_sell_five_star"),
                        "order": 1,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },

                ],

            },
        ]

    },

    // End Market Sell Rate 
    // Configuration for Compset 
    compset: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true, "filterMenu": true,
                        "order": 2
                    },

                ],
                "subcolumns_dba": [
                    {
                        ...generatDefaultSubcolumns("dba", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },


                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "sortable": false,
                        "order": 3
                    }
                ]
            },
            {
                "id": "compset_medium",
                "dataId": "Compset",
                // "colc_id": "Compset",

                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "compset",
                "display": "Compset - As Of {asofdatetime} ",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("cmedium", "compset_medium"),
                        "order": 3,
                    },

                ],

            }
        ],
        dataDateColumns: [

        ],
        dataDbaColumns: [

        ]
    },

    restrictions: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "isSticky": true,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        // "sorted": 'asc',
                        "filterable": false,
                        "filterMenu": false,
                        "isSticky": true,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false, "isSticky": true,
                        "filterable": true,
                        "filterMenu": true,
                        "order": 2
                    },

                ],
            },
            {
                "id": "restrictions_otb",
                "type": "dataColumn",
                "mainKey": "otb",
                "display": "OTB",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 2,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "restrictions_otb"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "restrictions_otb"),
                        "order": 2
                    },
                ],

            },
            {
                "id": "restrictions_forecast",
                "type": "dataColumn",
                "mainKey": "forecast",
                "display": "Forecast",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 2,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Occ", "restrictions_forecast"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "restrictions_forecast"),
                        "order": 2
                    },
                ],

            },
            {
                "id": "restrictions_bar_pricing",
                "type": "dataColumn",
                "mainKey": "bar_pricing",
                "display": "BAR Pricing",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 2,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "restrictions_bar_pricing"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "restrictions_bar_pricing"),
                        "order": 2
                    },
                ],

            },
            {
                "id": "restrictions",
                "type": "dataColumn",
                "mainKey": "restrictions",
                "display": "Restrictions",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 2,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("rec", "restrictions"),
                        "order": 1,
                        "type": 'component',
                        "width": 140,
                        "sortable": false,
                    },
                    {
                        ...generatDefaultSubcolumns("cur", "restrictions"),
                        "order": 2,
                        "type": 'component',
                        "width": 150,
                        "sortable": false,
                    },

                ],

            }
        ],
        roomTypeColumnTemplate: {
            "id": "restrictions_room_type",
            "type": "dataColumn",
            "mainKey": "restrictions",
            "display": "",
            "backgroundColor": "grey",
            "className": "col-main",
            "draggable": true,
            "hidden": false,
            "colspan": 2,
            "order": 4,
            "type": "roomTypeColumn",
            "subcolumns": [
                {
                    ...generatDefaultSubcolumns("sold", "bar_pricing"),
                    "order": 1,
                    "type": 'number',
                    "width": 65,
                    "sortable": false,
                },
                {
                    ...generatDefaultSubcolumns("current_rate", "bar_pricing"),
                    "order": 2,
                    "type": 'currency',
                    "width": 75,
                    "sortable": false,
                },
                {
                    ...generatDefaultSubcolumns("rec", "restrictions"),
                    "order": 3,
                    "type": 'component',
                    "width": 100,
                    "sortable": false,
                },
                {
                    ...generatDefaultSubcolumns("cur", "restrictions"),
                    "order": 4,
                    "type": 'component',
                    "width": 120,
                    "sortable": false,
                },

            ],

        }
    },

    budget: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
            },
            {
                "id": "restrictions_current",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "budget",
                "display": "",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "restrictions_current"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "restrictions_current"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "restrictions_current"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "restrictions_current"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "restrictions_current"),
                        "order": 5
                    }
                ],

            }
        ]
    },

    flights: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("month", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("flights_segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        "sortable": false,
                        "filterMenu": false,
                        // "hidden": true,
                        "order": 3
                    }
                ],
            },
        ],
        dataDateColumns: [
            {
                "id": "arrivals_variance",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Arrivals Variance %",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("total_flights_percent", "arrivals_variance"),
                        "order": 1,
                        "access_key": "arrival_total_sdly_variance",
                        "type": 'percent'
                    },
                    {
                        ...generatDefaultSubcolumns("domestic_percent", "arrivals_variance"),
                        "order": 2,
                        "access_key": "arrival_dom_sdly_variance",
                        "type": 'percent'
                    },
                    {
                        ...generatDefaultSubcolumns("international_percent", "arrivals_variance"),
                        "order": 2,
                        "access_key": "arrival_intl_sdly_variance",
                        "type": 'percent'
                    },
                ],

            },
            {
                "id": "stayovers_variance",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Stayovers Variance %",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("total_flights_percent", "stayovers_variance"),
                        "order": 1,
                        "access_key": "stay_total_sdly_variance",
                        "type": 'percent'
                    },
                    {
                        ...generatDefaultSubcolumns("per_day_percent", "stayovers_variance"),
                        "order": 2,
                        "access_key": "stay_per_day_sdly_variance",
                        "type": 'percent'
                    },

                ],

            },
            {
                "id": "arrivals_difference",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Arrivals Variance #",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("total_flights", "arrivals_difference"),
                        "order": 1,
                        "access_key": "arrival_total_sdly_difference"

                    },
                    {
                        ...generatDefaultSubcolumns("domestic", "arrivals_difference"),
                        "order": 2,
                        "access_key": "arrival_dom_sdly_difference"

                    },
                    {
                        ...generatDefaultSubcolumns("international", "arrivals_difference"),
                        "order": 2,
                        "access_key": "arrival_intl_sdly_difference"

                    },
                ],

            },
            {
                "id": "stayovers_difference",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Stayovers Variance #",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("total_flights", "stayovers_difference"),
                        "order": 1,
                        "access_key": "stay_total_sdly_difference"

                    },
                    {
                        ...generatDefaultSubcolumns("per_day", "stayovers_difference"),
                        "order": 2,
                        "access_key": "stay_per_day_sdly_difference"

                    },

                ],

            },
            
            {
                "id": "arrivals",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flight",
                "display": "Arrivals",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("total_flights", "arrivals"),
                        "order": 1,
                        "access_key": "arrival_total",
                    },
                    {
                        ...generatDefaultSubcolumns("domestic", "arrivals"),
                        "order": 2,
                        "access_key": "arrival_dom",

                    },
                    {
                        ...generatDefaultSubcolumns("international", "arrivals"),
                        "order": 2,
                        "access_key": "arrival_intl"
                    },
                ],

            },
            {
                "id": "stayovers",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Stayovers",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("total_flights", "stayovers"),
                        "order": 1,
                        "access_key": "stay_total",
                    },
                    {
                        ...generatDefaultSubcolumns("per_day", "stayovers"),
                        "order": 2,
                        "access_key": "stay_per_day",

                    },

                ],

            },
            {
                "id": "arrivals_sdly",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Arrivals SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("total_flights", "arrivals_sdly"),
                        "order": 1,
                        "access_key": "arrival_total_sdly",
                    },
                    {
                        ...generatDefaultSubcolumns("domestic", "arrivals_sdly"),
                        "order": 2,
                        "access_key": "arrival_dom_sdly",

                    },
                    {
                        ...generatDefaultSubcolumns("international", "arrivals_sdly"),
                        "order": 2,
                        "access_key": "arrival_intl_sdly"
                    },
                ],

            },

            {
                "id": "stayovers_sdly",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "flights",
                "display": "Stay Overs SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("total_flights", "stayovers_sdly"),
                        "order": 1,
                        "access_key": "stay_total_sdly",
                    },
                    {
                        ...generatDefaultSubcolumns("per_day", "stayovers_sdly"),
                        "order": 2,
                        "access_key": "stay_per_day_sdly",

                    },

                ],

            },
            

        ]


    },

    //////// For Reports Section ///////

    /** BI Sensitivity  */
    bi_sensitivity_analysis: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("dba", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": true,
                        // "hidden": true,
                        "sortable": false,
                        "order": 2
                    }
                ]

            },
            // data columns

        ],
        dataDbaColumns: [],
    },
    /** end pickup by segment  */

    /** Pickup by segment  */
    bi_pickup_analysis: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true,
                        "filterMenu": true,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,
                        "order": 3
                    }
                ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "variance_pickup",
                "type": "dataColumn",
                "mainKey": "variance_pickup",
                "tileName": "Report",
                "display": "%dayscount% Day Pickup as of - {asOfDate}",
                // "display": "Variance in Pickup",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "variance_pickup"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "variance_pickup"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "variance_pickup"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "variance_pickup"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "variance_pickup"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "variance_pickup"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            {
                "id": "otb_primary_report",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "Report",
                "display": "As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_primary_report"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_primary_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_primary_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_primary_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_primary_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_primary_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_pickup_report",
                "type": "dataColumn",
                "mainKey": "pickup",
                "tileName": "Report",
                "display": "As Of - {subsPickupDays}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_pickup_report"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_pickup_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_pickup_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_pickup_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_pickup_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_pickup_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_sdly",
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

            // {
            //     "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
            //     "type": "dataColumn",
            //     "mainKey": "difference_sdly",
            //     "tileName": "otb",
            //     "display": "DIFFERENCE TO SDLY",
            //     "backgroundColor": "grey",
            //     "className": "col-main",
            //     "draggable": true,
            //     "hidden": false,
            //     "colspan": 6,
            //     "order": 4,
            //     "subcolumns": [
            //         {
            //             ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
            //             "order": 2
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
            //             "order": 1,
            //         },
            //         {
            //             ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
            //             "order": 3
            //         },
            //         {
            //             ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
            //             "order": 4
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
            //             "order": 5
            //         },
            //         {
            //             ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
            //             // "hidden": true,
            //             "order": 6
            //         }
            //     ],
            // },
            // {
            //     "id": "otb_end_sdly", // likewise: otb_sdly, otb_prior,...
            //     "type": "dataColumn",
            //     "mainKey": "sdly_actual",
            //     "tileName": "dpbs-report",
            //     "display": "End SDLY - {otbSdlyDate}",
            //     "backgroundColor": "grey",
            //     "className": "col-main",
            //     "draggable": true,
            //     "hidden": false,
            //     "colspan": 6,
            //     "order": 5,
            //     "subcolumns": [
            //         {
            //             ...generatDefaultSubcolumns("Rooms", "otb_end_sdly"),
            //             "order": 2
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Occ", "otb_end_sdly"),
            //             "order": 1,
            //         },
            //         {
            //             ...generatDefaultSubcolumns("ADR", "otb_end_sdly"),
            //             "order": 3
            //         },
            //         {
            //             ...generatDefaultSubcolumns("RevPAR", "otb_end_sdly"),
            //             "order": 4
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Revenue", "otb_end_sdly"),
            //             "order": 5
            //         },
            //         {
            //             ...generatDefaultSubcolumns("SellRate", "otb_end_sdly"),
            //             "hidden": true,
            //             "order": 6
            //         }
            //     ],
            // },
            // {
            //     "id": "otb_sdly_pickup_var", // likewise: otb_sdly, otb_prior,...
            //     "type": "dataColumn",
            //     "mainKey": "sdly_pickup_diff",
            //     "tileName": "dpbs-report",
            //     "display": "SDLY Pickup Difference - {otbSdlyDate}",
            //     "backgroundColor": "grey",
            //     "className": "col-main",
            //     "draggable": true,
            //     "hidden": false,
            //     "colspan": 6,
            //     "order": 6,
            //     "subcolumns": [
            //         {
            //             ...generatDefaultSubcolumns("Rooms", "otb_sdly_pickup_var"),
            //             "order": 2
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Occ", "otb_sdly_pickup_var"),
            //             "order": 1,
            //         },
            //         {
            //             ...generatDefaultSubcolumns("ADR", "otb_sdly_pickup_var"),
            //             "order": 3
            //         },
            //         {
            //             ...generatDefaultSubcolumns("RevPAR", "otb_sdly_pickup_var"),
            //             "order": 4
            //         },
            //         {
            //             ...generatDefaultSubcolumns("Revenue", "otb_sdly_pickup_var"),
            //             "order": 5
            //         },
            //         {
            //             ...generatDefaultSubcolumns("SellRate", "otb_sdly_pickup_var"),
            //             "order": 6
            //         }
            //     ],
            // },

            // // /// Market columns ///
            {
                "id": "market_report",
                "type": "dataColumn",
                "mainKey": "marketprimary",
                "tileName": "Report",
                "display": "Market",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_report"),
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_sdly_report",
                "type": "dataColumn",
                "mainKey": "marketsdly",
                "tileName": "Report",
                "display": "Market {sdlyText}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 8,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_sdly_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_sdly_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_sdly_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_sdly_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_sdly_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_sdly_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_sdly_diff_report",
                "type": "dataColumn",
                "mainKey": "market_sdly_diff",
                "tileName": "Report",
                "display": "Difference to Market {sdlyText}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 9,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_sdly_diff_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_sdly_diff_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_sdly_diff_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_sdly_diff_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_sdly_diff_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_sdly_diff_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

        ],
    },
    /** end pickup by segment  */

    bi_pace_comparison: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": 'asc',
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true, "filterMenu": true,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,
                        "order": 3
                    }
                ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "otb_primary_report",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "Report",
                "display": "As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_primary_report"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_primary_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_primary_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_primary_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_primary_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_primary_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_sdly",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "tileName": "otb",
                "display": "DIFFERENCE TO {sdlyText}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
                        // "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_end_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "dpbs-report",
                "display": "End {sdlyText} - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_end_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_end_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_end_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_end_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_end_sdly"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "otb_end_sdly"),
                    //     "hidden": true,
                    //     "order": 6
                    // }
                ],
            },
            {
                "id": "otb_sdly_pickup_var", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_pickup_diff",
                "tileName": "dpbs-report",
                "display": "{sdlyText} Pickup Difference - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 6,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly_pickup_var"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly_pickup_var"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly_pickup_var"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly_pickup_var"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly_pickup_var"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly_pickup_var"),
                        "order": 6
                    }
                ],
            },

            // // /// Market columns ///
            {
                "id": "market_report",
                "type": "dataColumn",
                "mainKey": "marketprimary",
                "tileName": "Report",
                "display": "Market",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_report"),
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_sdly_report",
                "type": "dataColumn",
                "mainKey": "marketsdly",
                "tileName": "Report",
                "display": "Market {sdlyText}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 8,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_sdly_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_sdly_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_sdly_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_sdly_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_sdly_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_sdly_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "market_sdly_diff_report",
                "type": "dataColumn",
                "mainKey": "market_sdly_diff",
                "tileName": "Report",
                "display": "Difference to Market {sdlyText}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 9,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "market_sdly_diff_report"),
                        "order": 2,
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "market_sdly_diff_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "market_sdly_diff_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "market_sdly_diff_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "market_sdly_diff_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "market_sdly_diff_report"),
                        // "hidden": true,
                        "order": 6
                    }
                ],

            },

        ],
    },

    // Market Code Report
    productionMarketReport: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "sortable": false,
                        "order": 3
                    }
                ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "otb_primary_report",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "Report",
                "display": "As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_primary_report"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_primary_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_primary_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_primary_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_primary_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_primary_report"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_sdly",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "tileName": "otb",
                "display": "DIFFERENCE TO SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_end_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "dpbs-report",
                "display": "SDLY Actual",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_end_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_end_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_end_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_end_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_end_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_end_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },


        ],
    },


    // Segment Production 
    productionReport: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false, "filterMenu": false,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        // "hidden": true,
                        "sortable": false,
                        "order": 3
                    }
                ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "otb_primary_report",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "Report",
                "display": "As Of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_primary_report"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_primary_report"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_primary_report"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_primary_report"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_primary_report"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_primary_report"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_sdly",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],

            },
            {
                "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "tileName": "otb",
                "display": "DIFFERENCE TO SDLY",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },
            {
                "id": "otb_end_sdly", // likewise: otb_sdly, otb_prior,...
                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "dpbs-report",
                "display": "SDLY Actual",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_end_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_end_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_end_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_end_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_end_sdly"),
                        "order": 5
                    },
                    {
                        ...generatDefaultSubcolumns("SellRate", "otb_end_sdly"),
                        "hidden": true,
                        "order": 6
                    }
                ],
            },


        ],
    },


    // Revpar index 
    revpar_index: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("month", "staydates"),
                        // "draggable": true,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,
                        "order": 1
                    },
                ],
            },

            {
                "id": "inner_columns",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "revpar_index",
                "display": "",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [

                ],

            }
            // data columns
        ],
        dailycolumns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true, "filterMenu": true,
                        "order": 2
                    },
                ],
            },

            {
                "id": "inner_columns",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "revpar_index",
                "display": "",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [

                ],

            }
            // data columns
        ],

    },


    /** OTB Multi Segment  */
    otb_v2_multi_segment: {
        columns: [
            {
                "id": "staydates", // likewise: otb_sdly, otb_prior,...
                "type": "dateColumn",
                "isSticky": true,
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,

                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false, "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true,
                        "filterMenu": true,
                        "order": 2
                    },

                ],
                "subcolumns_segmented": [
                    {
                        ...generatDefaultSubcolumns("segment", "staydates"),
                        "draggable": false,
                        "filterable": true,
                        // "hidden": true,
                        "sortable": false,
                        "order": 3
                    }
                ]

            },
            {
                "id": "otb_current",
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "otb",
                "display": "as of - {asOfDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_current"),
                        "order": 2,
                        // "hidden": false,
                        // "sortable": false,
                        // "sorted": "asc"

                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_current"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_current"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_current"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_current"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "otb_current"),
                    //     // "hidden": true,
                    //     "order": 6
                    // }
                ],

            }
        ],

        sdlyOn_columns: [
            {
                "id": "otb_sdly",
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "As Of - {otbSdlyDate}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_sdly"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "otb_sdly"),
                    //     "hidden": true,
                    //     "order": 6
                    // }
                ],

            },
            {
                "id": "otb_actual_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "sdly_actual",
                "tileName": "otb",
                "display": "{sdYear-actuals} Actuals",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_actual_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_actual_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_actual_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_actual_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_actual_sdly"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "otb_actual_sdly"),
                    //     "hidden": true,
                    //     "order": 6
                    // }
                ],
            },
            {
                "id": "otb_diff_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "difference_sdly",
                "tileName": "otb",
                "display": "DIFFERENCE TO {sdYear}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 4,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_diff_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_diff_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_diff_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_diff_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_diff_sdly"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "otb_diff_sdly"),
                    //     "hidden": true,
                    //     "order": 6
                    // }
                ],
            },
            {
                "id": "otb_variance_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "variance_sdly",
                "tileName": "otb_v2_multi_segment",
                "display": "% DIFFERENCE TO {sdYear}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 5,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_variance_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_variance_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_variance_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_variance_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_variance_sdly"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "otb_variance_sdly"),
                    //     "hidden": true,
                    //     "order": 6
                    // }
                ],
            },

            {
                "id": "otb_remaing_pickup_sdly", // likewise: otb_sdly, otb_prior,...
                "dataId": "OSR",
                // "colc_id": "OSR",
                "type": "dataColumn",
                "mainKey": "pickup_diff_sdly",
                "tileName": "otb",
                "display": "REMAINING PICK UP - {sdYear-actuals}",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 7,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Rooms", "otb_remaing_pickup_sdly"),
                        "order": 2
                    },
                    {
                        ...generatDefaultSubcolumns("Occ", "otb_remaing_pickup_sdly"),
                        "order": 1,
                    },
                    {
                        ...generatDefaultSubcolumns("ADR", "otb_remaing_pickup_sdly"),
                        "order": 3
                    },
                    {
                        ...generatDefaultSubcolumns("RevPAR", "otb_remaing_pickup_sdly"),
                        "order": 4
                    },
                    {
                        ...generatDefaultSubcolumns("Revenue", "otb_remaing_pickup_sdly"),
                        "order": 5
                    },
                    // {
                    //     ...generatDefaultSubcolumns("SellRate", "otb_remaing_pickup_sdly"),
                    //     "hidden": true,
                    //     "order": 6
                    // }
                ],
            },
        ]
    },



    /** Room rate push Date range  */
    rm_room_type_ratesend_status: {
        columns: [
            {
                "id": "staydates",
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false,
                        "sortable": false,
                        "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true,
                        "filterMenu": true,
                        "sortable": false,

                        "order": 2
                    },

                ],
                // "subcolumns_segmented": [
                //     {
                //         ...generatDefaultSubcolumns("segment", "staydates"),
                //         "draggable": false,
                //         "filterable": false,
                //         // "hidden": true,
                // "sortable": false,
                //         "order": 3
                //     }
                // ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "rate_send_status",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "Report",
                "display": "Tracking",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("UpdateTime", "rate_send_status"),
                        "order": 1,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },
                    {
                        ...generatDefaultSubcolumns("User", "rate_send_status"),
                        "order": 2,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },
                    {
                        ...generatDefaultSubcolumns("Action", "rate_send_status"),
                        "order": 3,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },

                ],

            },
            {
                "id": "update_details",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "Update Details",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("Roomsrate", "update_details"),
                        "order": 1,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },
                    {
                        ...generatDefaultSubcolumns("Status", "update_details"),
                        "order": 1,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },

                ],

            },


        ],
    },
    restrictions_push_status: {
        columns: [
            {
                "id": "staydates",
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false,
                        "sortable": false,
                        "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                        "order": 2
                    },

                ],
                // "subcolumns_segmented": [
                //     {
                //         ...generatDefaultSubcolumns("segment", "staydates"),
                //         "draggable": false,
                //         "filterable": false,
                //         // "hidden": true,
                //         "order": 3
                //     }
                // ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "restriction_send_status",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "Report",
                "display": "Tracking",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("UpdateTime", "restriction_send_status"),
                        "order": 1,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },
                    {
                        ...generatDefaultSubcolumns("User", "restriction_send_status"),
                        "order": 2,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },
                    {
                        ...generatDefaultSubcolumns("Action", "rate_send_status"),
                        "order": 3,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },

                ],

            },
            {
                "id": "update_details",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "Update Details",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("RoomsRestrictions", "update_details"),
                        "order": 1,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },
                    {
                        ...generatDefaultSubcolumns("Status", "update_details"),
                        "order": 1,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },

                ],

            },


        ],
    },

    // Daily Rate Push Status 
    dailyRatesendStatus: {
        columns: [
            {
                "id": "staydates",
                "type": "dateColumn",
                "display": "{rangeyear}",
                "backgroundColor": "grey",
                "className": "otb data-column otb-current",
                "draggable": false,
                "colspan": 2, //This should be <subcolumns.length>
                "order": 1,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("date", "staydates"),
                        // "draggable": true,
                        "sorted": null,
                        "filterable": false,
                        "sortable": false,
                        "filterMenu": false,
                        "order": 1
                    },
                    {
                        ...generatDefaultSubcolumns("dow", "staydates"),
                        "draggable": false,
                        "filterable": true,
                        "filterMenu": true,
                        "sortable": false,
                        "order": 2
                    },
                ],
            },
            // data columns

        ],
        dataDateColumns: [
            {
                "id": "rate_send_status",
                "type": "dataColumn",
                "mainKey": "primary",
                "tileName": "Report",
                "display": "Tracking",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 2,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("UpdateTime", "rate_send_status"),
                        "order": 1,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,
                    },
                    {
                        ...generatDefaultSubcolumns("User", "rate_send_status"),
                        "order": 2,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,
                    },





                ],
            },

            {
                "id": "prior_details",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "Prior Details",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("PriorRate", "rate_send_status"),
                        "order": 2,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,
                    },
                    {
                        ...generatDefaultSubcolumns("RecRate", "rate_send_status"),
                        "order": 2,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,
                    },
                ],
            },

            {
                "id": "update_details",
                "type": "dataColumn",
                "mainKey": "sdly",
                "tileName": "otb",
                "display": "Update Details",
                "backgroundColor": "grey",
                "className": "col-main",
                "draggable": true,
                "hidden": false,
                "colspan": 6,
                "order": 3,
                "subcolumns": [
                    {
                        ...generatDefaultSubcolumns("ChangeRate", "update_details"),
                        "order": 1,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },
                    {
                        ...generatDefaultSubcolumns("Action", "rate_send_status"),
                        "order": 2,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },
                    {
                        ...generatDefaultSubcolumns("Status", "update_details"),
                        "order": 3,
                        "draggable": false,
                        "filterable": false,
                        "filterMenu": false,
                        "sortable": false,

                    },
                ],
            },
        ],
    },
    /////// End Reports Sections //////
};