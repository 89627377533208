import React, { useState, useEffect } from 'react'
import { Box, Button, List, ListItem, ListItemText, Menu, MenuItem } from '@mui/material';
import { ArrowDropDownSharp as ArrowDropDownSharpIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../actions/appstate';

function Measure({ options, side, selectedMeasure }) {
    const dispatch = useDispatch();
    const { app_state, ui_state } = useSelector(state => state.app.current_state);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const [selectedIndex, setSelectedIndex] = useState(0);
    useEffect(() => {
        // debugger;
        let isSubscribed = true;
        options.map((measure, index) => {
            if (selectedMeasure.value === measure.value) {
                setSelectedIndex(index);
            }
        })
        return () => (isSubscribed = false)
    }, [selectedMeasure, app_state.active_tiles])

    var sxObj = {
        ml: 1,
        mr: 1
    };
    var direction = side;
    if (direction === 'right') {
        sxObj['right'] = 0;
    }



    return (
        <Box
            className='chart-measures'
            sx={sxObj}
        >

            <List
                className='chart-list'
                component="nav"
                aria-label="Measures"
                sx={{ bgcolor: '#606060', borderRadius: 1, color: '#fff', fontFamily: "SFUI Medium", fontSize: '14px', pl: 0.5, pr: 3, pt: 0, pb: 0, cursor: 'pointer' }}
                onClick={handleClickListItem}
            >
                <ListItem
                    className='list-item'
                    button
                    id="lock-button"
                    aria-haspopup="listbox"
                    aria-controls="lock-menu"
                    aria-label=""
                    aria-expanded={'true'}
                    sx={{ p: 0 }}

                >
                    <ListItemText

                        primary={options[selectedIndex] ? options[selectedIndex].label : ''}
                    />
                </ListItem>
                <ArrowDropDownSharpIcon sx={{ position: 'absolute', right: 0, top: 1 }} />
            </List>

            <Menu
                id="lock-menu"
                elevation={6}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null)
                }}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
                transformOrigin={{ horizontal: side, vertical: 'top' }}
                anchorOrigin={{ horizontal: side, vertical: 'bottom' }}

            >
                {options.map((option, index) => (
                    <MenuItem
                        key={option.value}
                        // disabled={index === 0}
                        selected={index === selectedIndex}
                        onClick={(event) => {
                            let uiState = {
                                shown: true,
                                showspinner: true
                            };
                            if (side === 'left') {
                                uiState["left_measure"] = option.value;

                            } else {
                                uiState["right_measure"] = option.value;
                            }
                            
                            if (ui_state[side + "_measure"] !== uiState[side + "_measure"]) {
                                
                                dispatch({
                                    type: "UPDATE_STATE",
                                    payload: {
                                        app_state: {
                                            data_load: true,
                                            // date_range_type: "rangeTileChange_" + side + "_" + option.value
                                        },
                                        ui_state:{...uiState, showspinner: true}
                                    }
                                });
                                setSelectedIndex(index);
                            }

                            // dispatch(updateAppState('measure_change', {
                            //     ui_state: ui_state,
                            //     app_state: { data_load: true }
                            // }));

                            setAnchorEl(null)
                        }}
                        sx={{ minWidth: 110, color: '#004d9a', textTransform: 'uppercase', fontSize: 12, fontWeight: '600' }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}

export default Measure
