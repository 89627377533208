
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConnectingAirportsSharpIcon from '@mui/icons-material/ConnectingAirportsSharp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Button, Box, FormControl, Checkbox, Grid, Chip, ListItemText } from '@mui/material'
import { deepCopy, getSeriesById } from "../../../../app/util";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { UPDATE_STATE } from '../../../../actions/types';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AirportCodes = () => {
    const dispatch = useDispatch();
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const { app_state } = useSelector(
        (state) => state.app.current_state
    );

    const { workspace_controls } = app_state;
    let { data } = deepCopy(getSeriesById('GetFlights'));

    let airport_codes = (data && data.meta) ? data.meta.airport_codes : [];
    const [selectedAirports, setSelectedAirports] = useState([...airport_codes]);

    useEffect(() => {
        if(workspace_controls.selected_airport_codes.length){
            setSelectedAirports([...workspace_controls.selected_airport_codes])
        }
        if (!app_state.data_load && !workspace_controls.selected_airport_codes.length) {
            // data = deepCopy(getSeriesById('GetFlights')).data;

            if (airport_codes.length > 0) {
                setSelectedAirports(prev => [...airport_codes])
                workspace_controls.selected_airport_codes = [...airport_codes];

                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            // data_load: false, kpi_load: false,
                            workspace_controls: {
                                ...workspace_controls,
                                selected_airport_codes: [...workspace_controls.selected_airport_codes]
                            }
                        },
                        // ui_state: { 
                        //     showspinner: true 
                        // }
                    }
                })
            }
        }

    }, [app_state.data_load])
    const handleChange = (selectedAirports) => {
        let codes = airport_codes[0];
        if (selectedAirports.length > 0) {
            codes = selectedAirports;
        }
        setSelectedAirports(prev => [...codes]);
        if (codes.toString() == workspace_controls.selected_airport_codes.toString()) {
            return;
        }
        workspace_controls.selected_airport_codes = codes;
        dispatch({
            type: UPDATE_STATE,
            payload: {
                app_state: {
                    data_load: false, kpi_load: false,
                    workspace_controls: {
                        ...workspace_controls,
                        selected_airport_codes: [...codes]
                    }
                },
                ui_state: {
                    showspinner: true
                }
            }
        })
    }
    const handleMultiselectChange = useCallback((ev, newValue, reason) => {

        if (newValue.length < 1) {
            return;
            newValue = [selectedAirports[0]];
        }
        console.log("newValue", newValue);
        setSelectedAirports(prev => [...newValue]);
        if (ev.currentTarget.classList.contains("MuiChip-deleteIcon")) {
            handleChange(newValue);
        }

    }, []);
    return (
        <>
            {airport_codes && airport_codes.length > 0 && (
                <Box sx={{ position: "absolute", top: 0 }}>
                    <Box className="data-level-label" sx={{ marginTop: "8px" }}>Airport Codes</Box>
                    {/* {airport_codes.map((item, index) => (
                        <Tooltip title={item} arrow followCursor>
                            <Chip icon={<FlightIcon />} size="small" color="primary" label={item} sx={{ mt: .9, ml: 1 }} />
                        </Tooltip>
                    )
                    )} */
                        <Autocomplete
                            multiple
                            id="flights-multi-select"
                            className='tabular-data-filter'
                            size="small"
                            options={airport_codes}
                            getOptionLabel={(option) => option}
                            defaultValue={workspace_controls.selected_airport_codes.length > 0 ? [...workspace_controls.selected_airport_codes] : [...airport_codes]}
                            disableCloseOnSelect
                            onBlur={() => {
                                if (selectedAirports.length > 0) {
                                    handleChange(selectedAirports);
                                }
                            }}
                            disableClearable={true}
                            onChange={handleMultiselectChange}
                            onInputChange={(event, newInputValue) => {
                                console.log(newInputValue);
                            }}
                            // inputValue={selectedAirport}
                            renderOption={(props, option, { selected }) => (
                                <li {...props} className="airport-code-dropdown-options">
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={selected}
                                        className="airport-code-dropdown-checkbox"
                                    // onChange={(ev) => {
                                    //     let {checked} = ev.target;
                                    //     let airports =selectedAirports;
                                    //     console.log(checked);
                                    //     if(checked){
                                    //         airports.push(option)
                                    //     }else  {
                                    //         airports  = airports.filter(i => i !==option)
                                    //     }

                                    //     setSelectedAirports(prev=>[...airports]);

                                    // }}
                                    />
                                    {option}
                                </li>
                            )}
                            renderInput={(params) => {

                                if (selectedAirports.length < 1) {
                                    setSelectedAirports(airport_codes);
                                }
                                console.log("selected codes", selectedAirports)
                                console.log("all codes", airport_codes)
                                const displayedAirports = selectedAirports.slice(0, 3);
                                const remainingCount = selectedAirports.length - 3;

                                return (
                                    // <TextField
                                    //     {...params}
                                    //     class="flights-multiselect-input-wrapper"
                                    //     label=""
                                    //     placeholder=""

                                    // />
                                    <TextField
                                        {...params}
                                        className="flights-multiselect-input-wrapper"
                                        label=""
                                        placeholder=""
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <>
                                                    {displayedAirports.map((airport, index) => (
                                                        // <Chip className="airport-code-chip" key={airport} label={airport} />
                                                        <span className="airport-code-chip">{airport}{index === displayedAirports.length - 1 ? "" : ","}</span>
                                                    ))}
                                                    {remainingCount > 0 && (
                                                        // <Chip className="airport-code-chip" label={`+${remainingCount}`} />
                                                        <span className="airport-code-chip">{`+${remainingCount}`}</span>
                                                    )}
                                                </>
                                            )
                                        }}
                                    />
                                )
                            }}
                        />

                    }
                </Box>

            )}
        </>
    )
}

export default AirportCodes