import React, { useState } from 'react'
import { Grid, Modal, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { fetchDataSimple } from '../../services/data.service';
import { setUpDate, checkExistance, convertToPercent } from '../../app/util';
import moment from 'moment';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';

const AlertDetails = (props) => {
    let { alertDetails } = props;
    // debugger;
    let { kpi, delta, unit } = alertDetails.alert_rule;
    let percentageSymbol;
    let dollorSymbol;
    let statusClass;
    let deltaSymbol;
    let bgRooms;
    let bgOcc;
    let bgAdr;
    let bgRevpar;
    let bgRevenue;
    if (kpi === "rooms") {
        bgRooms = "bgRooms";
    } else if (kpi === "occ" || kpi === "occupancy") {
        bgOcc = "bgOcc";
    } if (kpi === "adr") {
        bgAdr = "bgAdr";
    } if (kpi === "revpar") {
        bgRevpar = "bgRevpar";
    } if (kpi === "revenue") {
        bgRevenue = "bgRevenue";
    }

    if (unit && unit === "percentage") {
        percentageSymbol = "%";
    } else if (unit && unit === "dollor") {
        dollorSymbol = "$";
    }

    if (delta === "increase") {
        statusClass = "delta-increase";
        deltaSymbol = "+";
    } else {
        statusClass = "delta-decrease";
        deltaSymbol = "-";

    }
    let data = alertDetails.data;
    return (
        <>
            <Table sx={{ minWidth: 600 }} size="small" stickyHeader aria-label="a dense table">
                <TableHead>
                    <TableRow className='alert-row-head-style'>
                        <TableCell align="center" sx={{ width: "5vw", top: ' -1px' }} >Date</TableCell>
                        <TableCell align="center" sx={{ width: "5vw", borderRight: "1px solid #ddd", top: ' -1px' }}>DOW</TableCell>
                        <TableCell align="center" sx={{ width: "7vw", top: ' -1px' }}>Occ%</TableCell>
                        <TableCell align="center" sx={{ width: "10vw", top: ' -1px' }}>Room Sold</TableCell>
                        <TableCell align="center" sx={{ width: "10vw", top: ' -1px' }}>ADR</TableCell>
                        <TableCell align="center" sx={{ width: "7vw", top: ' -1px' }}>RevPAR</TableCell>
                        <TableCell align="center" sx={{ width: "9vw", top: ' -1px' }}>Revenue</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {
                        (data && data.length > 0 && data.map((item, index) => {
                            // console.log(index, " ", item);
                            // debugger;
                            let bgClass = (index % 2 === 0) ? "bg-gray1 hoverStyle" : "hoverStyle";
                            // if(item.observed_pickup>=0){
                            //     statusClass = "delta-increase";
                            //     deltaSymbol = "+";
                            // }else {
                            //     statusClass = "delta-decrease";
                            //     deltaSymbol = "";
                            // }
                            return (
                                <>
                                    <TableRow className={bgClass} key={Math.random()} sx={{ '&:last-child td, &:last-child th': { borderBottom: "1px solid #ddd" } }}>
                                        <TableCell scope="row" align="center" >
                                            {moment(item.stay_date).format("MMM DD")}
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: "1px solid #ddd !important" }}>
                                            {moment(item.stay_date).format("ddd")}
                                        </TableCell>


                                        <TableCell align="center" sx={{ p: 0 }} className={bgOcc}>
                                            <div className='valContainer'>
                                                <div className={(kpi === "occ" || kpi === "occupancy") ? 'val-left' : 'full-width'}>{checkExistance(convertToPercent(item.snapshot.occ), "0.00")}%</div>
                                                {(kpi && (kpi === "occ" || kpi === "occupancy") &&
                                                    <div className={'val-right ' + statusClass}>
                                                        {deltaSymbol}{dollorSymbol}{checkExistance(convertToPercent(item.observed_pickup), "0")}{percentageSymbol}
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" sx={{ p: 0 }} className={bgRooms}>
                                            <div className='valContainer'>
                                                <div className={(kpi === "rooms") ? 'val-left' : 'full-width'}>{checkExistance(item.snapshot.rooms, 0, "Rooms")}</div>
                                                {(kpi && kpi === "rooms" &&
                                                    <div className={'val-right ' + statusClass}>
                                                        {deltaSymbol}{dollorSymbol}{checkExistance(item.observed_pickup, "0")}{percentageSymbol}
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>



                                        <TableCell align="center" sx={{ p: 0 }} className={bgAdr}>
                                            <div className='valContainer'>
                                                <div className={(kpi === "adr") ? 'val-left' : 'full-width'}>
                                                    ${checkExistance(item.snapshot.adr, "0")}</div>
                                                {(kpi && kpi === "adr" &&
                                                    <div className={'val-right ' + statusClass}>
                                                        {deltaSymbol}{dollorSymbol}{checkExistance(item.observed_pickup, "0")}{percentageSymbol}
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>


                                        <TableCell align="center" sx={{ p: 0 }} className={bgRevpar}>
                                            <div className='valContainer'>
                                                <div className={(kpi === "revpar") ? 'val-left' : 'full-width'}>${checkExistance(item.snapshot.revpar, "0.00")}</div>
                                                {(kpi && kpi === "revpar" &&
                                                    <div className={'val-right ' + statusClass}>
                                                        {deltaSymbol}{dollorSymbol}{checkExistance(item.observed_pickup, "0")}{percentageSymbol}
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>

                                        <TableCell align="center" sx={{ p: 0 }} className={bgRevenue}>
                                            <div className='valContainer'>
                                                <div className={(kpi === "revenue") ? 'val-left' : 'full-width'}>${checkExistance(item.snapshot.revenue, "0.00")}</div>
                                                {(kpi && kpi === "revenue" &&
                                                    <div className={'val-right ' + statusClass}>
                                                        {deltaSymbol}{dollorSymbol}{checkExistance(item.observed_pickup, "0")}{percentageSymbol}
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>


                                    </TableRow>
                                </>
                            );

                        }))
                    }
                </TableBody>
            </Table>
        </>
    )
}

export default AlertDetails
