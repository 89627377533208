import React from "react";

function LockIcon({ fill = '#EBEBEB', height = "12", width = "9" }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox={`0 0 9 12`}
        >
            <path
                fill={fill}
                d="M7.662 4.439V3.17A3.175 3.175 0 005.707.24 3.175 3.175 0 001.316 3.17V4.44A1.316 1.316 0 000 5.754v4.93A1.316 1.316 0 001.316 12h6.346a1.316 1.316 0 001.316-1.316v-4.93A1.316 1.316 0 007.662 4.44zM2.184 3.075A2.307 2.307 0 014.487.768a2.307 2.307 0 012.302 2.307v1.364H2.184V3.075zm3.193 5.263a.878.878 0 01-.026.215.44.44 0 01-.026.07 1.014 1.014 0 01-.044.123l-.044.061a.628.628 0 01-.07.105.623.623 0 01-.057.053.877.877 0 01-.092.088.171.171 0 00-.075.136v.706a.439.439 0 01-.877 0V9.18a.153.153 0 00-.022-.08.07.07 0 00-.026-.034.101.101 0 00-.044-.03.649.649 0 01-.11-.11.53.53 0 01-.07-.07.636.636 0 01-.08-.141.816.816 0 01-.047-.083.816.816 0 01-.035-.18.44.44 0 010-.08.877.877 0 01.65-.912.877.877 0 01.775.15.877.877 0 01.32.71v.018z"
            ></path>
        </svg>
    );
}

export default LockIcon;