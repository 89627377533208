import React, { useState, useEffect, useReducer, useRef } from 'react';
import { API_REGISTRY } from "../../app/config";
import moment from 'moment';
import {
    Tooltip, Box, Alert, Stack, Chip, CircularProgress,
    Grid, Button, LinearProgress, Badge, Modal, IconButton,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import {
    CloseSharp as CloseSharpIcon,
    ArrowBackIosNewSharp as ArrowBackIosNewSharpIcon,
    ArrowForwardIosSharp as ArrowForwardIosSharpIcon,
    CheckSharp as CheckSharpIcon,
    CloudUploadOutlined as CloudUploadOutlinedIcon,
    FileDownloadOutlined as FileDownloadOutlinedIcon
}
    from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { getPropertyInfo, updateAppState } from '../../actions/appstate';
import dataService from '../../services/data.service';
import { deepCopy, isRestrictionSame,generateTableHead, generateTable,
    safeReference, isEmptyObj,getRestrictionsArray } from "../../app/util";
import { UPDATE_STATE } from "../../actions/types";
import "./style.css";
import TableToExcel from "@linways/table-to-excel";
import { APP_CACHE } from '../../app/config-v4-api';

export default function SendRestrictions(props) {
    
    const { modalOpen, handleModalClose } = props;
    const dispatch = useDispatch();
    const { app_state, ui_state } = useSelector((state) => state.app.current_state);
    const { isRestrictionsRoomType } = app_state.workspace_controls;

    const {  property_id, property_name } = app_state;
    const [rows, setRows] = useState([]);
    const [numUpdates, setNumUpdates] = useState(0);
    const [ratePushSucessOpen, setRatePushSuccessOpen] = useState(false);
    const [restrictionsPushFail, setRestrictionsPushFail] = useState(false);
    const compRef = useRef({ pushedCount: 0, rows: [] });
    const [errorMessage, setErrorMessage] = useState("");

    const [compState, setCompState] = useState({
        count: app_state.savedRestrictions.length,
        rows: deepCopy(app_state.savedRestrictions),
        pushSuccess: false,
        pushError: false,
        progress: {
            all: 'idle',
        },
        pushStatus: {},
        pushedCount: 0,
        successTimeout: 4000
    });
    const [restrictionsRoomTypeData, setRestrictionsRoomTypeData] = useState({
        count: app_state.roomTypeRestrictions.length,
        rows: [],
        pushSuccess: false,
        pushError: false,
        progress: {
            all: 'idle',
        },
        pushStatus: {},
        pushedCount: 0,
        successTimeout: 4000
    });
    const [resHouseLvlData, setResHouseLvlData] = useState(dataService.getSeriesById("GetRestrictions").data)
    const [resRmTypeData, setResRmTypeData] = useState(dataService.getSeriesById("GetRestrictionsRoomType").data)
   
   
  
    // debugger;
    useEffect(() => {
        let isSubscribed = true;
        // debugger
        const resRmTypeData = dataService.getSeriesById("GetRestrictionsRoomType").data;
        if(resRmTypeData && resRmTypeData.status === "success"){
            setResRmTypeData(prev=>{
                return {rec:resRmTypeData.rec, cur:resRmTypeData.cur}
            })
        }
        const resHouseLvlData = dataService.getSeriesById("GetRestrictions").data;
        if(resHouseLvlData && resHouseLvlData.status === "success"){
            setResHouseLvlData(prev =>{
                return {rec:resHouseLvlData.rec, cur:resHouseLvlData.cur};
            })
        }
        // setResHouseLvlData({rec:resHouseLvlData.rec, cur:resHouseLvlData.cur})
        if (isRestrictionsRoomType === true) {
            app_state.roomTypeRestrictions.sort((a, b) => {
                return moment(a.stay_date).format('x') - moment(b.stay_date).format('x');
            });
            //compRef.current.rows = deepCopy(app_state.roomTypeRestrictions);
            restrictionsRoomTypeData.rows = app_state.roomTypeRestrictions.map(day_data => {
                const roomtypes = Object.keys(day_data).filter(item =>
                    item !== "stay_date" && item !== "pushed");
                const rowData = {
                    stay_date: day_data.stay_date,
                    pushed: day_data.pushed,
                    date_updates: []
                }

                roomtypes.forEach(room_type => {
                    rowData.date_updates.push(day_data[room_type]);
                })
                //Object.keys(item).forEach(key=>{})
                return rowData;
            });
            setRestrictionsRoomTypeData(state => {
                return { ...restrictionsRoomTypeData }
            });
        }
        if (compRef.current.pushedCount === compState.pushedCount) {
            app_state.savedRestrictions.sort((a, b) => {
                return moment(a.stay_date).format('x') - moment(b.stay_date).format('x');
            });
            compRef.current.rows = deepCopy(app_state.savedRestrictions);
            compState.rows = deepCopy(app_state.savedRestrictions);
            setCompState(state => {
                return { ...state, ...compState }
            });
        }

        return () => (isSubscribed = false);
    }, [app_state.savedRestrictions, compState.pushedCount, app_state.roomTypeRestrictions])

    const downloadReportToExcel = (name, selectedPropertyName, tableID) => {

        let exportFileName = name + "_" + selectedPropertyName;
        
        const table = document.createElement('table');
        let colKeys = ["Date", "Restrictions"];
        const header = generateTableHead(table,colKeys)
        const data =[];
        if(isRestrictionsRoomType){
            exportFileName = "Restrictions_Room Type_"+selectedPropertyName
            restrictionsRoomTypeData.rows.forEach(row=>{
                const dataPoint={"date":"","restrictions":""};
                dataPoint.date= row.stay_date;
                dataPoint.restrictions =[];
                row.date_updates.forEach(update=>{
                    let res = getRestrictionsArray(update.entry).join(" ");
                    res = update.room_type + " " + res;
                    dataPoint.restrictions.push(res);
                })
                dataPoint.restrictions =  dataPoint.restrictions.join(", ")
                data.push(dataPoint);
            })
        }else {
            compState.rows.forEach(row=>{
                const dataPoint={"date":"","restrictions":""};
                dataPoint.date= row.stay_date;
                dataPoint.restrictions=getRestrictionsArray(row.entry).join(" ");;
                data.push(dataPoint);
            })
        }
        const tBody = generateTable(table, data);

        TableToExcel.convert(table, {
            name: exportFileName + ".xlsx",
            sheet: {
                name: name.toUpperCase()
            }
        });

    }
    const handleClose = (e) => {
        // debugger; 
        let data_load =false;
        if (isRestrictionsRoomType) {

            restrictionsRoomTypeData.progress = { all: 'idle' };
            restrictionsRoomTypeData.pushStatus = { all: 'idle' };
            
            app_state.roomTypeRestrictions = app_state.roomTypeRestrictions.filter(rest => {
                if (!rest.pushed) {
                    return rest;
                }
                if(rest.pushed){
                    data_load = true;
                }

            })
            setRestrictionsRoomTypeData(state => {
                return { ...state, ...restrictionsRoomTypeData }
            });
            dispatch(
                updateAppState("removeRestrictionRow", {
                    app_state: {
                        roomTypeRestrictions: [...app_state.roomTypeRestrictions],
                        data_load: data_load,
                        //savedRestrictions: compState.rows
                    },
                    ui_state: {
                        ...ui_state,
                        showspinner: data_load,
                    }
                })
            )
        } else {
            compState.progress = { all: 'idle' };
            compState.pushStatus = { all: 'idle' };
            compRef.current.rows.map((item, index) => {
                if (item.pushed) {
                    compState.rows.splice(index, 1);
                    compRef.current.rows.splice(index, 1);
                    compState.pushedCount = 0;
                    data_load = true;
                }
                
            });
            setCompState(state => {
                return { ...state, ...compState }
            });
            dispatch(
                updateAppState("removeRestrictionRow", {
                    app_state: {
                        //savedRestrictions:[...app_state.savedRestrictions],
                        data_load: data_load,
                        //savedRestrictions: compState.rows
                    },
                    ui_state: {
                        ...ui_state,
                        showspinner: data_load,
                    }
                })
            )
        }
        setRestrictionsPushFail(false);
        setErrorMessage("");
        handleModalClose();
    };
    const compareUpdatedRestriction = (rest) => {
        let isSame = true;
        rest.entry.min_los = (rest.entry.min_los === "") ? 1 : rest.entry.min_los;
        rest.entry.max_los = (rest.entry.max_los === "") ? 1 : rest.entry.max_los;
        if (rest.entry.cta !== rest.cur.cta) {
            isSame = false;
        }
        if (rest.entry.ctd !== rest.cur.ctd) {
            isSame = false;
        }
        if (rest.entry.close !== rest.cur.close) {
            isSame = false;
        }
        if (rest.entry.min_los !== rest.cur.min_los) {
            isSame = false;
        }
        if (rest.entry.max_los !== rest.cur.max_los) {
            isSame = false;
        }
        return isSame;
    }
    const handleDeleteRoomTypeRestriction = (index, dateIndex, rule) => {

        return (e) => {
            if (isRestrictionsRoomType) {
                const dateRest = restrictionsRoomTypeData.rows[index]
                const { stay_date } = dateRest;
                const rmTypeRestIndex = app_state.roomTypeRestrictions.findIndex(i => i.stay_date === stay_date);
                const { room_type } = dateRest.date_updates[dateIndex];
                if (rmTypeRestIndex >= 0) {
                    app_state.roomTypeRestrictions[rmTypeRestIndex][room_type].entry[rule] = app_state.roomTypeRestrictions[rmTypeRestIndex][room_type].cur[rule]
                    //app_state.roomTypeRestrictions.splice(rmTypeRestIndex, 1);
                    delete app_state.roomTypeRestrictions[rmTypeRestIndex][room_type].delta[rule];
                }
                restrictionsRoomTypeData.rows[index].date_updates[dateIndex].entry[rule] = dateRest.date_updates[dateIndex].cur[rule]
                //restrictionsRoomTypeData.rows[index]
                delete restrictionsRoomTypeData.rows[index].date_updates[dateIndex].delta[rule]
                console.log(restrictionsRoomTypeData, app_state.roomTypeRestrictions);

                if (compareUpdatedRestriction(restrictionsRoomTypeData.rows[index].date_updates[dateIndex])) {
                    restrictionsRoomTypeData.rows[index].date_updates.splice(dateIndex, 1);
                    delete app_state.roomTypeRestrictions[rmTypeRestIndex][room_type];
                }
                let nomoreRest = true;
                restrictionsRoomTypeData.rows[index].date_updates.forEach(i => {
                    if (!compareUpdatedRestriction(i)) {
                        nomoreRest = false;
                    }
                })
                if (nomoreRest) {
                    app_state.roomTypeRestrictions.splice(rmTypeRestIndex, 1);
                    restrictionsRoomTypeData.rows.splice(index, 1);
                }
                setRestrictionsRoomTypeData(state => {
                    return { ...state, rows: restrictionsRoomTypeData.rows, count:restrictionsRoomTypeData.rows.length }
                });

                dispatch(updateAppState("removeRestrictionRow", {
                    app_state: {
                        roomTypeRestrictions: app_state.roomTypeRestrictions
                    }
                }));
                if (app_state.roomTypeRestrictions.length === 0) {
                    setTimeout(() => { handleClose() }, 200);
                }
            }
        }
    }
    const handleDeleteRule = (index, rule) => {
        return (e) => {

            delete compState.rows[index].delta[rule];
            delete compRef.current.rows[index].delta[rule];
            delete app_state.savedRestrictions[index].delta[rule];
            // let entry = deepCopy(compState.rows[index].entry);
            let cur = deepCopy(compState.rows[index].cur);
            compState.rows[index].entry[rule] = cur[rule];
            compRef.current.rows[index].entry[rule] = cur[rule];
            app_state.savedRestrictions[index].entry[rule] = cur[rule];
            // compState.rows[index].entry[rule] = compState.rows[index].cur[rule];
            //compRef.current.rows[index].entry[rule] = compState.rows[index].cur[rule];
            // app_state.savedRestrictions[index].entry[rule] = compState.rows[index].cur[rule];

            let hasDelta = Object.keys(compState.rows[index].delta);
            if (hasDelta && hasDelta.length === 0) {
                // debugger;  
                compState.rows.splice(index, 1);
                compRef.current.rows.splice(index, 1);
                app_state.savedRestrictions.splice(index, 1);

            } else {
                // app_state.savedRestrictions = compState.rows;
                // dispatch(updateAppState("removeRestrictionRule", {
                //     app_state: {
                //         ...app_state,
                //         // savedRestrictions: compState.rows
                //     }
                // }));
            }
            setCompState(state => {
                return { ...state, ...compState }
            });
            dispatch(updateAppState("removeRestrictionRule", {
                app_state: {
                    ...app_state,
                    // savedRestrictions: compState.rows
                }
            }));

            if (!compState.rows.length) {
                handleClose();
            }

        }
    };
    const handleDelete = (index) => {
        // debugger;
        if (isRestrictionsRoomType) {
            const { stay_date } = restrictionsRoomTypeData.rows[index]
            const rmTypeRestIndex = app_state.roomTypeRestrictions.findIndex(i => i.stay_date === stay_date);
            if (rmTypeRestIndex >= 0) {
                app_state.roomTypeRestrictions.splice(rmTypeRestIndex, 1);
            }
            restrictionsRoomTypeData.rows.splice(index, 1);
            setRestrictionsRoomTypeData(state => {
                return { ...state, rows: restrictionsRoomTypeData.rows }
            });

            dispatch(updateAppState("removeRestrictionRow", {
                app_state: {
                    roomTypeRestrictions: app_state.roomTypeRestrictions
                }
            }));
            if (app_state.roomTypeRestrictions.length === 0) {
                setTimeout(() => { handleClose() }, 200);
            }
        } else {
            compState.rows.splice(index, 1);
            setCompState(state => {
                return { ...state, rows: compState.rows, count:compState.rows.length }
            });
            // let savedRestrictions = compState.restrictions
            dispatch(updateAppState("removeRestrictionRow", {
                app_state: {
                    ...app_state,
                    savedRestrictions: compState.rows
                }
            }))
        }

    }
    let closetimeOutId ;
    const updateRestrictionsDisplay = (restrictions, all) => {
        if (all) {

            if (isRestrictionsRoomType) {
                restrictionsRoomTypeData.pushStatus.all = 'loaded';
                restrictionsRoomTypeData.progress.all = 'loaded';
                app_state.roomTypeRestrictions = [];
                setRestrictionsRoomTypeData(state => {
                    return { ...state, ...restrictionsRoomTypeData }
                });
                setTimeout(() => {
                    restrictionsRoomTypeData.pushStatus.all = 'idle';
                    restrictionsRoomTypeData.progress.all = 'idle';
                    setRestrictionsPushFail(false)
                    handleModalClose();
                }, 5000);
            } else {
                compState.pushStatus.all = 'loaded';
                compState.progress.all = 'loaded';
                setCompState(state => {
                    return { ...state, ...compState }
                });
                setRestrictionsPushFail(false)
                closetimeOutId = setTimeout(() => {
                    compState.pushStatus.all = 'idle';
                    compState.progress.all = 'idle';
               
                    handleModalClose();
                }, 5000);
                app_state.savedRestrictions = [];
            }
        } else {
            if (isRestrictionsRoomType) {
                restrictions.forEach((rest) => {
                    restrictionsRoomTypeData.pushStatus[rest.stay_date] = 'success';
                    restrictionsRoomTypeData.progress[rest.stay_date] = 'loaded';
                    app_state.roomTypeRestrictions.forEach((date_data, index) => {
                        if (date_data.stay_date === rest.stay_date) {
                            app_state.roomTypeRestrictions[index].pushed = true;
                            const room_types = Object.keys(app_state.roomTypeRestrictions[index]).filter(i => i !== 'stay_date');
                            if (room_types.length == 0) {
                                //app_state.roomTypeRestrictions.splice(index, 1);
                            }
                        }
                    });
                });
                if (app_state.roomTypeRestrictions.length === 0) {
                    // restrictionsRoomTypeData.pushStatus.all = 'loaded';
                    // restrictionsRoomTypeData.progress.all = 'loaded';
                    setTimeout(() => {
                        restrictionsRoomTypeData.pushStatus.all = 'idle';
                        restrictionsRoomTypeData.progress.all = 'idle';
                        setRestrictionsPushFail(false)
                        handleModalClose();
                    }, 5000);
                }

                // debugger
                setRestrictionsRoomTypeData(state => {
                    return { ...state, ...restrictionsRoomTypeData }
                });

            } else {
                restrictions.forEach((rest) => {
                    compState.pushStatus[rest.stay_date] = 'success';
                    compState.progress[rest.stay_date] = 'loaded';
                    compState.rows.forEach((itm, index) => {
                        let item = deepCopy(itm);
                        if (item.stay_date === rest.stay_date) {
                            // debugger;
                            if (item.entry.min_los == 0) {
                                item.entry.min_los = 1;
                                item.cur.min_los = 1;
                            }
                            if (item.entry.max_los == 0) {
                                item.entry.max_los = 1;
                                item.cur.max_los = 1;
                            }

                            // if(item.min_los == "") delete item.min_los;
                            // if(item.max_los == "") delete  item.max_los;

                            let updatedIndex;
                            app_state.updatedRestrictions.filter((updated, i) => {
                                if (updated.stay_date === rest.stay_date) {
                                    updatedIndex = i;
                                }
                            });
                            if (updatedIndex > -1) {
                                app_state.updatedRestrictions[updatedIndex] = deepCopy(item);
                            } else {
                                app_state.updatedRestrictions.push(deepCopy(item));
                            }


                            if (compState.rows[index].entry.min_los == 0) {
                                compState.rows[index].entry.min_los = 1;
                            }
                            if (compState.rows[index].entry.max_los == 0) {
                                compState.rows[index].entry.max_los = 1;
                            }
                            compState.rows[index].cur = deepCopy(item.entry);
                            compState.rows[index].pushed = true;
                            compRef.current.rows[index].pushed = true;

                            app_state.savedRestrictions.splice(index, 1);
                            compState.pushedCount += 1;
                        }
                    });
                });


                // debugger
                setCompState(state => {
                    return { ...state, ...compState }
                });
            }
        }

        dispatch(
            updateAppState("updateRestrictions", {
                app_state: {
                    ...app_state,
                    data_load: true,
                    //savedRestrictions: compState.rows
                },
                ui_state: {
                    ...ui_state,
                    showspinner: true,
                }
            })
        )
    }
    const pushRestrictions = ({ restrictions, all }) => {
        let registery = API_REGISTRY['SendRestrictionsPush'];
        let url = registery.uri + app_state.property_id + "/";
        setRestrictionsPushFail(false);
        dataService.getData({
            api: url,
            request: { "as_of_date": app_state.as_of_date, restrictions: restrictions }
        })
            .then((rs) => {
                // debugger;
                if (rs.status === 'success') {

                    updateRestrictionsDisplay(restrictions, all)

                    console.log("Send restrictions success", rs);
                } else {
                    pushErrorHandler(restrictions, all, rs.message)
                }
            }, e => {
                pushErrorHandler(restrictions, all, "Restriction push failed! Please contact the administrator.")
            })
            .catch((e)=>{
                pushErrorHandler(restrictions, all, "Restriction push failed! Please contact the administrator.")
            });
    }

    const pushErrorHandler = (restrictions, all, errorMessage) => {
        restrictions.map((rest) => {
            compState.pushStatus[rest.stay_date] = 'error';
            compState.progress[rest.stay_date] = 'error';
        });
        
        restrictions.forEach((rest) => {
            // restrictionsRoomTypeData.pushStatus[rest.stay_date] = 'success';
            if(isRestrictionsRoomType){
                restrictionsRoomTypeData.progress[rest.stay_date] = 'failed';
                // app_state.roomTypeRestrictions.forEach((date_data, index) => {
                //     if (date_data.stay_date === rest.stay_date) {
                //         app_state.roomTypeRestrictions[index].pushed = true;
                //         const room_types = Object.keys(app_state.roomTypeRestrictions[index]).filter(i => i !== 'stay_date');
                //         if (room_types.length == 0) {
                //             //app_state.roomTypeRestrictions.splice(index, 1);
                //         }
                //     }
                // });
            }else {
                compState.progress[rest.stay_date] ='failed'
            }

        });
        if(all){
            setRestrictionsPushFail(true);
        }
        if(isRestrictionsRoomType){
            setRestrictionsRoomTypeData(state => {
                return { ...state, ...restrictionsRoomTypeData }
            });
        }
        setErrorMessage(errorMessage)
        setCompState(state => {
            return { ...state, ...compState }
        });
    }

    const handleRestrictionPush = (row) => {
        //Handling
        let restrictions = [];
        let rows = [];
        if (!row) {
            if (isRestrictionsRoomType) {
                rows = restrictionsRoomTypeData.rows;
                restrictionsRoomTypeData.progress.all = 'loading';
            } else {
                rows = compState.rows;
                compState.progress.all = 'loading';
            }


        } else if (row && row.stay_date) {
            rows.push(row);
            if (isRestrictionsRoomType) {
                restrictionsRoomTypeData.progress[row.stay_date] = 'loading';
            } else {
                compState.progress[row.stay_date] = 'loading'
            }

        }

        if (isRestrictionsRoomType) {

            rows.forEach(row => {

                row.date_updates.forEach(item => {
                    const restriction = {};
                    restriction.stay_date = row.stay_date;
                    restriction.room_type = item.room_type;
                    const keys = Object.keys(item.delta);
                    keys.forEach(key=>{
                        if(key === "min_los" || key === "max_los"){
                            restriction[key] = (item.entry[key] ==="" || item.entry[key] ===0) ? 1 : item.entry[key];
                            
                        }else{
                            restriction[key] = item.entry[key];
                        }
                    })
                   
                    restriction.user_action ="Overriden";
                    let findIndex = resRmTypeData.rec.findIndex(i=> {
                        if(i.stay_date === row.stay_date && i.room_type === item.room_type){
                            return i;
                        }
                    });
                    if(findIndex>-1){
                        if(isRestrictionSame(item.entry, resRmTypeData.rec[findIndex])){
                            restriction.user_action="Recomendation Accept";
                        }
                    }
                    restrictions.push(restriction);
                })


            })

        } else {
            console.log(resHouseLvlData);       
            rows.forEach(row => {
                const restriction = {};
                restriction.stay_date = row.stay_date;
                const keys = Object.keys(row.delta);
                keys.forEach(key=>{
                    if(key === "min_los" || key === "max_los"){
                        restriction[key] = (row.entry[key] ==="" || row.entry[key] ===0) ? 1 : row.entry[key];
                    }else{
                        restriction[key] = row.entry[key];
                    }
                })
                
                restriction.user_action ="Overriden";
                
                const findIndex = resHouseLvlData.rec.findIndex(i=> i.stay_date === row.stay_date);
                if(findIndex>-1){
                    if(isRestrictionSame(row.entry, resHouseLvlData.rec[findIndex])){
                        restriction.user_action="Recomendation Accept";
                    }
                }
                restrictions.push(restriction);
            });
        }

        if (!row) {
            pushRestrictions({ restrictions, all: true });

        } else if (row && row.stay_date) {
            pushRestrictions({ restrictions, all: false });
        }
        if (isRestrictionsRoomType) {
            setRestrictionsRoomTypeData(state => {
                return { ...state, ...restrictionsRoomTypeData }
            });
        } else {
            setCompState(state => {
                return { ...state, ...compState }
            });
        }

    }

    const restrictionPushSucessDiv = (<>
        <Grid item className='success-popup'></Grid>
        <div className='rate-push-success'>
            <div className='rate-push-success-container'>
                <Grid item className='task-complete'></Grid>
                <Grid item className='success-text'>Success!</Grid>
                <Grid item>Check the Restrictions Push Status Below </Grid>
                <Button variant="contained"
                    className='button-green'
                    onClick={() => {
                        APP_CACHE.active_tiles = ["restrictions_push_status"];                        
                        dispatch({
                            type: UPDATE_STATE,
                            payload: {
                                app_state: {
                                    ...app_state,
                                    data_load: false, 
                                    kpi_load: false,
                                    date_range_type: 'rangeTileChange_restrictions_push_status' ,
                                    workspace_controls:{
                                        ...app_state.workspace_controls,
                                        logsRatePushMode: 'date_range',
                                    }
                                },
                                ui_state: { active_tiles: ["restrictions_push_status"], showspinner:true }
                            }
                        })
                        clearTimeout(closetimeOutId);
                        
                        setTimeout(()=>{
                            // dispatch(updateAppState("sendRestrictionsClose", {
                            //     app_state: {
                            //        data_load:true,
    
                            //     },
                            //     ui_state: { active_tiles: [tile], showspinner:true }
                            // }));
                           
                            //handleModalClose();
                            dispatch({
                                type:UPDATE_STATE,
                                payload:{ui_state:{sendRestrictionsModalOpen:false}}
                            })
                        }, 250)
                        
                       
                    }}
                >
                    Restrictions Push Status
                </Button>
            </div>
        </div></>
    );


    const chipEl = (chipClass,) => {
        return
    }
    const chipsCurDisplay = (row) => {
        let chipsArr = [];
        if (row.cur.cta) {
            chipsArr.push(<div data-mui-internal-clone-element="true" className="chip res-rule res-cta" size='small'  >CTA</div>)
        }
        if (row.cur.ctd) {
            chipsArr.push(<div data-mui-internal-clone-element="true" className="chip res-rule res-ctd" size='small'  >CTD</div>)
        }
        if (row.cur.close) {
            chipsArr.push(<div data-mui-internal-clone-element="true" className="chip res-rule res-cls" size='small'>CLS</div>)
        }
        if (row.cur.min_los != 0 && row.cur.min_los > 1) {
            chipsArr.push(<div data-mui-internal-clone-element="true" className="chip res-rule res-min_los" size='small'  >{"MN " + row.cur.min_los}</div>)
        }
        if (row.cur.max_los != 0 && row.cur.max_los > 1) {
            chipsArr.push(<div data-mui-internal-clone-element="true" className="chip res-rule res-max_los" size='small'  >{"MX " + row.cur.max_los} </div>)
        }

        return chipsArr;
    }
   
    //old layout
  
    const roomTypeRestrictionUpdates = (<div className='RTP-Restriction-Model '>
        {restrictionsRoomTypeData.rows.map((date_data, index) => {
            let header = (
                <div>
                    <div className='rtp-info-heading'>
                        <Grid container direction="row" alignItems="center"
                        >
                            <Grid className='rtp-heading' item xs={1}>
                                <Grid container direction="row" alignItems="center"
                                >
                                    <Grid item>{moment(date_data.stay_date).format("ddd, MMM DD, YYYY")}</Grid>
                                    {(!date_data.pushed && restrictionsRoomTypeData.progress[date_data.stay_date] !== 'failed') && (<Grid item>
                                        <Button onClick={() => handleRestrictionPush(date_data)}
                                            className='icon-btn icon-status icon-ratepush'>
                                            <span className='upload-button'></span>
                                        </Button>
                                    </Grid>)}
                                    {restrictionsRoomTypeData.progress[date_data.stay_date] === 'loading' &&
                                        <Grid item><CircularProgress size={20} /></Grid>}

                                    {restrictionsRoomTypeData.progress[date_data.stay_date] === 'loaded' &&
                                        <Grid item>
                                            <Button sx={{ minWidth: 'auto' }} disabled><span className='in-progress'></span></Button>
                                        </Grid>}
                                    {restrictionsRoomTypeData.progress[date_data.stay_date] === 'failed' && <Grid item>
                                        <Tooltip title={errorMessage} arrow>
                                            <Button className='icon-btn icon-status icon-ratepush'>
                                                <span className='error'></span></Button></Tooltip>
                                    </Grid>}

                                    {/* <Grid item >
                                        <Button className='icon-btn icon-status icon-warning'>
                                            <span className='error'></span>
                                        </Button>
                                    </Grid> */}

                                    <Grid item>
                                        <Button disabled={restrictionsRoomTypeData.progress[date_data.stay_date] === 'loading'} className='icon-btn icon-status icon-cancel'
                                            onClick={() => {
                                                handleDelete(index)
                                            }}>
                                            <span className=''>x</span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>

                    </div>
                </div>
            );

            const updates = [];
            date_data.date_updates.forEach((row, dateIndex) => {
                if (row && row.cur && row.entry) {
                    updates.push(<Grid item className='margin-bottom' xs={3} md={2}>
                        <Box>
                            <Stack mt={2} pr={0.8} className='res-rules-display restrictions-delta' justifyContent="flex-start" alignItems="center" direction="row" spacing={1} sx={{ background: "rgba(235, 235, 235, 0.70)", borderRadius: "50px", width: "max-content" }}>
                                <Box p={1.2} sx={{
                                    background: "#EBEBEB", borderTopLeftRadius: "50px",
                                    borderBottomLeftRadius: "50px", fontFamily: "SFUI Semibold",
                                    color: "#333333", marginRight: "6px !important"
                                }}>
                                    {row.room_type}
                                </Box>
                                {row.delta.cta === true && row.cur.cta && <Chip className="chip removed" label="CTA"
                                    size="small" onDelete={handleDeleteRoomTypeRestriction(index, dateIndex, 'cta')} />}
                                {row.delta.cta && row.entry.cta && <Chip className="chip res-rule res-cta" size='small' label="CTA" onDelete={handleDeleteRoomTypeRestriction(index, dateIndex, 'cta')} />}

                                {row.delta.ctd === true && row.cur.ctd && <Chip className="chip removed" label="CTD" size="small" onDelete={handleDeleteRoomTypeRestriction(index, dateIndex, 'ctd')} />}
                                {row.delta.ctd && row.entry.ctd && <Chip className="chip res-rule res-ctd" label="CTD" size="small" onDelete={handleDeleteRoomTypeRestriction(index, dateIndex, 'ctd')} />}

                                {row.delta.close === true && row.cur.close && <Chip className="chip removed" label="CLS" size="small" onDelete={handleDeleteRoomTypeRestriction(index, dateIndex, 'close')} />}
                                {row.delta.close && row.entry.close && <Chip className="chip res-rule res-cls" label="CLS" size="small" onDelete={handleDeleteRoomTypeRestriction(index, dateIndex, 'close')} />}

                                {row.delta.min_los && row.cur.min_los > 1 && <Chip className="chip removed" label={"MN " + row.cur.min_los} size="small" onDelete={handleDeleteRoomTypeRestriction(index, dateIndex, 'min_los')} />}
                                {row.delta.min_los && row.entry.min_los > 1 && <Chip className="chip res-rule res-min_los" label={"MN " + row.entry.min_los} size="small" onDelete={handleDeleteRoomTypeRestriction(index, dateIndex, 'min_los')} />}

                                {row.delta.max_los && row.cur.max_los > 1 && <Chip className="chip removed" label={"MX " + row.cur.max_los} size="small" onDelete={handleDeleteRoomTypeRestriction(index, dateIndex, 'max_los')} />}
                                {row.delta.max_los && row.entry.max_los > 1 && <Chip className="chip res-rule res-max_los" label={"MX " + row.entry.max_los} size="small" onDelete={handleDeleteRoomTypeRestriction(index, dateIndex, 'max_los')} />}
                            </Stack>
                        </Box>
                    </Grid>)
                }

            })
            return (<Box sx={{ flexGrow: 1 }} key={index}>{header}<Grid container sx={{display:"flex", flexDirection:"column"}}>{updates}</Grid></Box>);
        })}
    </div>)


    const houseRestrictionTable = (
        <Grid pl={2} pr={2} sx={{ overflowX: "hidden", overflowY: "auto" }}>
            {compRef.current.rows.length > 0 ?
                compRef.current.rows.map((row, index) => (
                    <Grid mt={2.5} mb={4} className='rtp-heading' >
                        <Box pl={1} sx={{ display: 'flex',fontSize:"14px",fontWeight:"bold",color:"#333",
                             alignItems: 'center', justifyContent: "space-between", minWidth:"300px",
                              borderRadius:"8px 8px 0px 0px",lineHeight:"40px",
                        width: "210px", background: "#bfe4f9", borderTopLeftRadius: "6px", 
                        borderTopRightRadius: "6px" }}>
                            <Box>{moment(row.stay_date).format("ddd, MMM DD, YYYY")}</Box>
                            <Box>
                                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                    {!['loaded', 'loading', 'failed'].includes(compState.progress[row.stay_date]) &&
                                        <Grid>
                                            <Button onClick={() => handleRestrictionPush(row)} className='icon-btn icon-status icon-ratepush'>
                                                <span className='upload-button'></span>
                                            </Button>
                                        </Grid>}

                                    {compState.progress[row.stay_date] === 'loading' &&
                                        <Grid item><CircularProgress size={18} /></Grid>}

                                    {compState.progress[row.stay_date] === 'loaded' &&
                                        <Grid item>
                                            <Button sx={{ minWidth: 'auto' }} disabled><span className='in-progress'></span></Button>
                                        </Grid>}

                                    {compState.progress[row.stay_date] === 'failed' && <Grid item><Tooltip title={errorMessage} arrow><Button className='icon-btn icon-status icon-ratepush'><span className='error'></span></Button></Tooltip></Grid>}


                                    {!['loading'].includes(compState.progress[row.stay_date]) &&
                                        <Grid item>
                                            <Button onClick={() => {
                                                handleDelete(index)
                                            }} className='icon-btn icon-status icon-cancel'>
                                                <span className='push-delete' style={{ width: "12px", minHeight: "12px" }}></span>
                                            </Button>
                                        </Grid>}

                                    {['loading'].includes(compState.progress[row.stay_date]) &&
                                        <Grid item>
                                            <Button disabled onClick={() => {
                                                handleDelete(index)
                                            }} className='icon-btn icon-status icon-cancel'>
                                                <span className='push-delete'></span>
                                            </Button>
                                        </Grid>
                                    }

                                </Grid>
                            </Box>
                        </Box>

                        <Box sx={{ borderBottom: "1px solid #bfe4f9" }}></Box>

                        <Stack mt={2} pr={0.8} className='res-rules-display restrictions-delta' justifyContent="flex-start" alignItems="center" direction="row" spacing={1} sx={{ background: "rgba(235, 235, 235, 0.70)", borderRadius: "50px", width: "max-content" }}>
                            <Box p={1.2} sx={{ background: "#EBEBEB", borderTopLeftRadius: "50px", borderBottomLeftRadius: "50px", fontFamily: "SFUI Semibold", color: "#333333", marginRight: "6px !important" }}>
                                House
                            </Box>
                            {row.delta.cta && row.cur.cta && <Chip className="chip removed" label="CTA" size="small" onDelete={handleDeleteRule(index, 'cta')} />}
                            {row.delta.cta && !row.cur.cta && <Chip className="chip res-rule res-cta" size='small' label="CTA" onDelete={handleDeleteRule(index, 'cta')} />}

                            {row.delta.ctd && row.cur.ctd && <Chip className="chip removed" label="CTD" size="small" onDelete={handleDeleteRule(index, 'ctd')} />}
                            {row.delta.ctd && !row.cur.ctd && <Chip className="chip res-rule res-ctd" label="CTD" size="small" onDelete={handleDeleteRule(index, 'ctd')} />}

                            {row.delta.close && row.cur.close && <Chip className="chip removed" label="CLS" size="small" onDelete={handleDeleteRule(index, 'close')} />}
                            {row.delta.close && !row.cur.close && <Chip className="chip res-rule res-cls" label="CLS" size="small" onDelete={handleDeleteRule(index, 'close')} />}

                            {row.delta.min_los  && row.cur.min_los > 1 && <Chip className="chip removed" label={"MN " + row.cur.min_los} size="small" onDelete={handleDeleteRule(index, 'min_los')} />}
                            {row.delta.min_los && row.entry.min_los > 1 && <Chip className="chip res-rule res-min_los" label={"MN " + row.entry.min_los} size="small" onDelete={handleDeleteRule(index, 'min_los')} />}

                            {row.delta.max_los  && row.cur.max_los > 1 && <Chip className="chip removed" label={"MX " + row.cur.max_los} size="small" onDelete={handleDeleteRule(index, 'max_los')} />}
                            {row.delta.max_los && row.entry.max_los > 1 && <Chip className="chip res-rule res-max_los" label={"MX " + row.entry.max_los} size="small" onDelete={handleDeleteRule(index, 'max_los')} />}
                        </Stack>
                    </Grid>
                ))

                : (
                    <p>No Record Found!</p>
                )
            }
        </Grid>
    )

    let disabledConfirm = true;
    if (isRestrictionsRoomType && restrictionsRoomTypeData.rows.length > 0) {
        disabledConfirm = false;
    }
    if (!isRestrictionsRoomType && compState.rows.length > 0) {
        disabledConfirm = false;
    }
    return (<>
        <Modal
            className="popup-modal-wrap"
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className='popup-modal restrictions-push' sx={{ height: "auto !important" }}>
           {(compState.progress.all !== 'loaded' && restrictionsRoomTypeData.progress.all !== "loaded") &&
            (<>
                <Box className='modal-titlebar-res-review-update'>
                    <Grid container spacing={0} className='modal restrictions-update'>
                        <Grid item xs={7}>
                            <item>Restrictions - Review and update</item>
                        </Grid>
                        <Grid className='right-cta' xs={5} item alignItems="end">
                            <Badge anchorOrigin={{ vertical: 'top', horizontal: 'right', }} component="div" badgeContent={(isRestrictionsRoomType) ? app_state.roomTypeRestrictions.length : app_state.savedRestrictions.length} color="primary" className='btn-ratepush' sx={{ mt: 0, mr: 1, position: 'relative' }}>
                                <Button disabled={disabledConfirm} className='PushRateButton-popup' size="small" sx={{ m: "auto", textTransform: "none" }} onClick={() =>
                                    handleRestrictionPush(false)
                                }>
                                    <span>Confirm All Changes</span>
                                </Button>
                            </Badge>

                            <IconButton aria-label="delete" onClick={() => {
                                let name = "";
                                if (isRestrictionsRoomType) {
                                    name = "Restrictions"
                                } else {
                                    name = "RoomRestrictions"
                                }
                                downloadReportToExcel("Restrictions", property_name, "restrictionsTable")
                            }}>
                                <FileDownloadOutlinedIcon className='Rate-Push-DownloadButton '

                                    fontSize='large' />
                            </IconButton>

                            <Button sx={{
                                minWidth: 'auto', position: 'absolute', top: -12, right: -12, width: '24px', height: '24px', p: '7px',
                                borderRadius: '50%', borderWidth: '1px', borderStyle: 'solid',
                                borderColor: '#ccc', boxShadow: '0 0 4px #000', background: '#000', color: '#FFF', fontSize: '10px',
                                '&:hover': { backgroundColor: '#000' }
                            }} onClick={handleClose}>
                                <CloseSharpIcon fontSize='small' />
                            </Button>

                        </Grid>
                    </Grid>

                </Box>

                <Box sx={{ height: '100%' }} id="restrictionsTable">

                    {!isRestrictionsRoomType && houseRestrictionTable}
                    {isRestrictionsRoomType && roomTypeRestrictionUpdates}

                
                    {restrictionsPushFail && (<Stack sx={{ width: '100%', paddingLeft: "16px", paddingRight: "16px",marginBottom:"10px" }} spacing={2}>
                        <Alert severity="warning" sx={{ color: "#333333", background: "rgba(224, 49, 49, 0.10)", pt: "0px", pb: "0px", border: "1px solid #E03131" }}>
                            {errorMessage}</Alert>
                    </Stack>)}
                </Box>
                </>
            )}
            {compState.progress.all === 'loaded' && restrictionPushSucessDiv}
            {restrictionsRoomTypeData.progress.all === 'loaded' && restrictionPushSucessDiv}
            </Box>
        </Modal>

    </>);
}
