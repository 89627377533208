import React, { useEffect, useState, useRef } from 'react'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { getOptions } from "./chartlogic"
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../actions/appstate';
import Alert from '@mui/material/Alert';
import { transformData, getMergedData } from './functions';
import {
  getTileById, getCompsetMedian,
  getResourceIdByPropId, getSeriesById,
  deepCopy, getFromState, getTransformedSegments, filterTopSegments,
  aggregateObject, dateFormat, in_array, getSDLYorSDTLYDate,
} from '../../app/util';
import '../../simple-grid.css';
import './chart-view.css';
import moment from 'moment';
import DataService from "../../services/data.service";
import { UPDATE_STATE } from '../../actions/types';
import { APP_CACHE, TILES } from "../../app/config";

function ChartCommon({ compDisplayData }) {
  // debugger;  
  const dispatch = useDispatch();
  const state = useSelector(appState => appState);
  const { property_details } = useSelector(state => state.auth.profile);
  const { app_state, ui_state } = useSelector(state => state.app.current_state);
  const { workspace_controls } = app_state;
  const { sdlyOn, segmented_view, pickUpType, segmentType, dayDate } = workspace_controls;
  const [displayData, setDisplayData] = useState([]);
  const [compLoad, setCompLoad] = useState(false);
  const [options, setOptions] = useState({});
  const resource_id = getResourceIdByPropId(app_state.property_id, property_details);
  let segments = null

  let inventory = getFromState('system_settings>inventory_settings', state);
  if (!inventory) console.error("inventory_settings is not available for this property");
  const { total_capacity } = inventory && inventory.current ? inventory.current : {};
  // debugger
  let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
  let lv1_segGrouping = getFromState('system_settings>lvl1_mseg_grouping', state);
  if (segmented_view) {
    // debugger
    let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
    let segment_ignore_codes = getFromState('system_settings>market_code_ignorelist', state);
    segments = getTransformedSegments(marketSegments, segment_ignore_codes);
    // segments = getTransformedSegments(marketSegments);  
  }

  let compRef = useRef({
    compLoad: false
  });

  useEffect(() => {
    // debugger;
    let isSubscribed = true;
    let apc = APP_CACHE;
    let apcChartData = null; //apc.chartData;
    if (apcChartData && !sdlyOn && app_state.changing_view && app_state.changing_view === 'chart') {
      // debugger
      setOptions({ options, ...apcChartData });
      compRef.current.compLoad = true;
      dispatch({
        type: "UPDATE_STATE",
        payload: {
          app_state: {
            changing_view: ""
          }
        },
      });
    } else {
      if (isSubscribed && compRef.current.compLoad && displayData && displayData.length) {
        let param = {
          ...app_state.primary_stay_range,
          ...app_state.workspace_controls,
          active_api: app_state.active_api,
          ...ui_state,
          resource_id,
          segmented_view,
          pickUpType,
          segments,
          total_capacity,
          workspace_controls: app_state.workspace_controls,
          property_id: app_state.property_id,
          property_details: property_details,
          lv1_segGrouping: filterTopSegments(lv1_segGrouping),
          hasUserForecastData: false
        };

        //Set true if user forecast is not blank
        if (ui_state.active_tiles.includes('forecast')) {
          let userForecast = getSeriesById('ManualForecast').data;
          if (userForecast && userForecast.data && userForecast.data.length) {
            param.hasUserForecastData = true;
          }
        }

        let chartOptions = getOptions(displayData, ui_state, app_state, param);
        apc.chartData = deepCopy(chartOptions);
        setOptions({ options, ...chartOptions });

      }

    }

    return () => {
      isSubscribed = false;
      dispatch({
        type: "UPDATE_STATE",
        payload: {
          app_state: {
            changing_view: ""
          }
        },
      });
    }
  }, [compLoad]);


  useEffect(() => {
    let isSubscribed = true;

    if (!ui_state.showspinner && !compRef.current.compLoad) {
      // debugger
      if (compDisplayData && compDisplayData.length === 1) {
        let transformedData = [];

        if (['market'].includes(ui_state.active_tiles[0])) {
          transformedData = getMergedData(compDisplayData, { ui_state: ui_state, app_state: app_state, segments });

        } else if (compDisplayData[0].data) {
          let params = {
            dataSet: compDisplayData[0].data, data_id: compDisplayData[0].data_id,
            segmentType, topSegments: filterTopSegments(lv1_segGrouping),
            range: app_state.primary_stay_range,
            segments
          }

          transformedData = transformData(params);
        }
        compRef.current.compLoad = (transformedData && transformedData.length > 0);

        // debugger;  

        setDisplayData(transformedData);
        setCompLoad(true);
      }
      if (compDisplayData && (compDisplayData.constructor.name === 'Array' && compDisplayData.length > 1)) {
        let mergedData = [];
        let transformedData = [];
        let topSegments = filterTopSegments(lv1_segGrouping);

        try {
          mergedData = getMergedData(compDisplayData, { ui_state: ui_state, app_state: app_state, segments, topSegments });
        } catch (e) {
          console.warn("Code Block Failed!!", e);
        }

        // debugger;  
        if (ui_state.active_tiles.includes('forecast')) {
          let params = {};
          if (segmented_view) {
            params = {
              dataSet: mergedData, data_id: 'OSRSegment',
              range: ui_state.primary_stay_range, segments,
              topSegments: filterTopSegments(lv1_segGrouping), segmentType
            };


            transformedData = transformData(params);
            compRef.current.compLoad = true;
            setDisplayData(transformedData);
            setCompLoad(true);

          } else {
            let userForecast = getSeriesById('ManualForecast').data;
            if (userForecast && userForecast.data && userForecast.data.length) {
              let totalKey = 'HotelTotal';
              mergedData.forEach((item) => {
                let manualForecast = userForecast.data.find(data => new moment(item.index.date).isSame(data.index.date, 'day'))
                if (manualForecast) {
                  let dateData = deepCopy(manualForecast.total_forecast);
                  dateData.Occ = dateData['Occ%'] / 100;
                  delete dateData['Occ%'];
                  item[totalKey].userforecastdata = dateData;
                }
              });
            }
            // transformedData = transformData(params);
            compRef.current.compLoad = true;
            setDisplayData(mergedData);
            setCompLoad(true);
          }


        } else {
          compRef.current.compLoad = true;
          setDisplayData(mergedData);
          setCompLoad(true);
        }

      }
    }

    return () => (isSubscribed = false)
  }, [
    ui_state.showspinner,
    // ui_state,
    app_state.workspace_controls,
    // app_state.data_load
  ]);

  return (
    <>
      {compRef.current.compLoad && <HighchartsReact
        highcharts={Highcharts}
        options={options} />}
      {!compRef.current.compLoad && <div style={{ margin: "0 10px" }}><Alert sx={{ hight: 30 }} severity="warning">{(compRef.current.infoTxt) ? compRef.current.infoTxt : "No Data Found!"}</Alert></div>}
    </>
  );
}

export default ChartCommon;
