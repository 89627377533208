import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useRef, useState, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { processColumns, processData } from "./processData";
import moment from "moment";
import {
    convertToPercent, round, getCompsetMedian, getFromSession, getFromState, getTransformedSegments,
    numberFormatForReport, truncate, sortStringArray, deepCopy, divide, downloadcsvwithTable,
    processColumnsNew, getAppData, getCompsetName, dateFormat, in_array,
    getDaysForPickupOcc, getDatesInRange, getPermissions, getCorrectValue,
    generalizeDataSet, generateTable, preferredOrder, showPercentValues, getMarketSegment, removeCurrSymbol,
    getStayToSdly, getStayToComp, isDateInRange,
    getCacheDataKey, getSDLYorSDTLYDate, formattedCompDateCalc,
} from "../../app/util"
import { transformSegmentData } from "./segmentedData";
import { useDownloadExcel } from 'react-export-table-to-excel';
import "./table-common.css";

import { updateAppState } from "../../actions/appstate";
import { Button, Tooltip, getTableSortLabelUtilityClass, IconButton, Popover, Alert, AlertTitle } from "@mui/material";
import dataService from "../../services/data.service";
import { Box } from "@mui/system";
import { marketSellRatesTotal } from "./tilesData/marketsellrates";
import TableToExcel from "@linways/table-to-excel";
import Spinner from "../Spinner";
import { format, parse } from "date-fns";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Filter from "./Filter";
import { APP_CACHE, TILES } from "../../app/config";
import Restrictions, { getResName, getRestrictionAll } from "./Restrictions";
import { primaryHeaderDisplay } from './table-functions';
import { processDataIntelimerge, processDataIntelimergeSegment } from './processDataIntelimerge';
import { getActiveTilesData } from "./processDataIntelimerge";
// import { dateFormat } from 'highcharts';
import { getTotals, otbTransformTotal } from "./functions";
import { TableVirtuoso } from 'react-virtuoso'
import DisplayData from './DisplayData';
import { TableLottieLoader } from '../layout/dashboard-ui';
import { UPDATE_STATE } from '../../actions/types';
import DataService from "../../services/data.service";

const PrimaryHeaderCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#606060",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const SecondaryHeaderGreyCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#b2b2b2",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const SecondaryHeaderBlueCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3fa7cd",
        color: "white",
        borderRight: "1px solid white",
        textTransform: "uppercase",
        fontWieght: "700",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: "#ebebeb",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));






function TableIntelimerge({ compDisplayData }) {
    const state = useSelector(appState => appState);

    const { auth, app } = useSelector(state => state);
    const { profile } = auth;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const { workspace_controls, notes, selected_market, eventList, eventListSDLY } = app_state;
    const { sdlyOn, segmented_view, dayDate } = workspace_controls;

    const { active_tiles, table_filters } = ui_state;
    const filteredDow = table_filters.table_common.dow.map(d => d.code);
    let filteredSegments = (active_tiles.length > 1 && active_tiles.includes("market")) ? ui_state.table_filters.table_common.marketIntellimergeSegments.map(d => d.name) : ui_state.table_filters.table_common.segments.map(d => d.name);

    const cache_data_key = getCacheDataKey({ active_tiles, stayRange: app_state.primary_stay_range, property_id: app_state.property_id, workspace_controls: workspace_controls, selected_market, comp_stay_range: app_state.comp_stay_range });
    const apc = APP_CACHE;
    // if (active_tiles.includes("market")) {
    //     let market_segments = getMarketSegment(active_tiles);
    //     filteredSegments = market_segments.map(d => d.name);
    // }
    const stayDates = getDatesInRange(app_state.primary_stay_range.date_from, app_state.primary_stay_range.date_until);

    let selectedPropertyName = profile.property_details.find(property => property.id == app_state.property_id).name;
    const active_tiles_className =
        "tile-active-" + ui_state.active_tiles.join(" tile-active-");
    const [columns, setColumns] = useState([]);
    const [data_cols, setDataCols] = useState([]);
    const [data_setup, setDataSetup] = useState([]);
    const dispatch = useDispatch();
    const [displayData, setDisplayData] = useState(compDisplayData);
    const [compLoad, setCompLoad] = useState(false);
    const [reloadGrid, setReloadGrid] = useState("");
    const [compLoadStatus, setCompLoadStatus] = useState('loading');
    const [renderBodyData, setRenderBodyData] = useState([]);
    const { property_details } = useSelector(state => state.auth.profile);
    const { permissions, index } = getPermissions(property_details, app_state.property_id);
    const tableVirtuoso = useRef(null);
    const [totalRowsData, setTotalRowsData] = useState([])

    const [containerHeight, setContainerHeight] = useState(800); // Default height
    const [containerWidth, setContainerWidth] = useState(1280); // Default width    


    useEffect(() => {
      
        if (active_tiles.includes('market') && active_tiles.length > 1) {
            let segmentArr = getMarketSegment();
            segmentArr = segmentArr.map(v => ({ ...v, hidden: false, title: v.name, type: "market_segments" }))
            segmentArr = segmentArr.filter((obj, index) => {
                return index === segmentArr.findIndex(o => obj.name === o.name);
            });
            if (!segmentArr.includes('UNMAPPED')) {
                segmentArr.push('UNMAPPED');
            }
            // let test = segmentArr.filter(item => item !== "UNMAPPED");
            // ui_state.table_filters.table_common.marketSegments = test;
            ui_state.table_filters.table_common.marketIntellimergeSegments = segmentArr;

            dispatch({
                type: UPDATE_STATE,
                payload: {
                    ui_state: { table_filters: ui_state.table_filters }
                }
            })
        }



        return () => {
            ui_state.table_filters.table_common.marketIntellimergeSegments = getMarketSegment(ui_state.active_tiles);
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    ui_state: { table_filters: ui_state.table_filters }
                }
            })
        }
    }, []);

    const getNeededColumns = useCallback(({ auth, app_state, ui_state }) => {
        const { workspace_controls, as_of_date } = app_state;
        const { sdlyOn, pickUpType, segmented_view } = workspace_controls;
        const { active_tiles } = ui_state;
        let startDate = app_state.primary_stay_range.date_from;
        let endDate = app_state.primary_stay_range.date_until;
        // let restrictions = (getAppData(auth, app_state).restrictions) ? getAppData(auth, app_state).restrictions.enable : false;
        let restrictions = (permissions && permissions.restrictions === 1) ? true : false;
        let finalColumns = [];
        active_tiles.map((tile, tileIndex) => {
            let tableConfig = deepCopy(TILES[tile]['tableConfig']);
            let columns = tableConfig['columns'];
            let sdlyColumns = deepCopy(TILES[tile]['tableConfig']['sdlyOn_columns']);
            let restrictionsColumns = deepCopy(TILES[tile]['tableConfig']['restrictionsOn_columns']);

            let dataDbaColumns = deepCopy(TILES[tile]['tableConfig']['dataDbaColumns']);
            let dataDateColumns = deepCopy(TILES[tile]['tableConfig']['dataDateColumns']);
            let dbaSdlyColumns = deepCopy(TILES[tile]['tableConfig']['dbaOn_sdly_columns']);
            let marketDateSdlyOn_columns = deepCopy(TILES[tile]['tableConfig']['marketDateSdlyOn_columns']);

            // let dataDbaColumns = deepCopy(TILES[tile]['tableConfig']['dataDbaColumns']);
            // let dataDateColumns = deepCopy(TILES[tile]['tableConfig']['dataDateColumns']);
            // let dbaSdlyColumns = deepCopy(TILES[tile]['tableConfig']['dbaOn_sdly_columns']);


            let userForecastColumns = deepCopy(TILES[tile]['tableConfig']['userForecast_columns']);
            if (tile === 'pricing' && tileIndex !== 0) {
                let pricingColumns = deepCopy(tableConfig.houseLevelColumns.filter(item => {
                    if (item.id === 'pricing') {
                        item.subcolumns = item.subcolumns.filter(sub_col => sub_col.subcol_id !== 'pricing_Sold'
                            && sub_col.subcol_id !== 'pricing_Override'
                            && sub_col.subcol_id !== 'pricing_Approve');
                        return item;
                    }
                }))

                columns = deepCopy(pricingColumns);
            }
            finalColumns = [...finalColumns, ...columns];
            let tile_segmented_view = true;
            let segmentedViewIgnore = ['compset'];
            if (segmentedViewIgnore.includes(active_tiles[0])) {
                tile_segmented_view = false;
            }
            if (active_tiles[0] === "market" && app_state.workspace_controls.scopeType === 'Dba') {
                tile_segmented_view = false;
            }

            if (in_array(active_tiles, ["compset"]) && tile === "compset") {
                let ord = (active_tiles[0] === "compset") ? 4 : 100;
                let subColumns = getCompsetName(ord, app_state);
                columns[1]['subcolumns'] = [...columns[1]['subcolumns'], ...subColumns];
            }

            // check Scope is DBA
            if (dataDbaColumns && app_state.workspace_controls.scopeType === 'Dba') {
                let dateColIndex = columns.findIndex(item => item.type === "dateColumn");
                let dbaSubColumns = columns[dateColIndex].subcolumns_dba;
                columns[dateColIndex]['subcolumns'] = dbaSubColumns;
                if (dataDbaColumns) {
                    finalColumns = [...finalColumns, ...dataDbaColumns];
                }
            } else {
                if (dataDateColumns) {
                    finalColumns = [...finalColumns, ...dataDateColumns];
                }
            }
            /// end ///

            /// to check user forecast ///
            let userForecastData = [];
            if (!dataService.getSeriesById("ManualForecast").data) {
                userForecastData = [];
            } else {
                userForecastData = dataService.getSeriesById("ManualForecast").data.data;
            }
            if (userForecastData.length > 0 && userForecastColumns && !workspace_controls.segmented_view) {
                finalColumns = [...finalColumns, ...userForecastColumns];
            }
            //// end ///

            if (sdlyOn && sdlyColumns) {
                finalColumns = [...finalColumns, ...sdlyColumns];
            }

            if (sdlyOn && dbaSdlyColumns && app_state.workspace_controls.scopeType === 'Dba' && in_array(active_tiles, ["market"])) {
                finalColumns = [...finalColumns, ...dbaSdlyColumns];
            }
            if (sdlyOn && marketDateSdlyOn_columns && app_state.workspace_controls.scopeType !== 'Dba' && in_array(active_tiles, ["market"])) {
                finalColumns = [...finalColumns, ...marketDateSdlyOn_columns];
            }


            if (restrictions && restrictionsColumns) {
                finalColumns = [...finalColumns, ...restrictionsColumns];
            }

            if (segmented_view && tile_segmented_view) {
                let dateColIndex = columns.findIndex(item => item.type === "dateColumn");
                let dateColumn = columns[dateColIndex];
                if (dateColIndex > -1 && dateColumn["subcolumns_segmented"]) {
                    dateColumn["subcolumns_segmented"].map(segmented => {
                        columns[dateColIndex].subcolumns.push(segmented);
                    });
                }
            }
        });
        //// remove the comparison column after click on the clear comparison button
        if (in_array(ui_state.active_tiles, ["pace"]) && !app_state.workspace_controls.clearComparision) {
            let columnsWithOutComp = finalColumns.filter((item) => (item.id !== "comparison_pace" && item.id !== "bc_date_diff" && item.id !== "comparison_pace_dba" && item.id !== "bc_dba_diff"))
            finalColumns = [...columnsWithOutComp];
        }
        // End //

        let tempArr = [];
        let tmpArr = [];
        finalColumns.map((itm, idx) => {

            if (!tmpArr.includes(itm.id)) {
                tempArr.push(itm);
                let pushIndex = tmpArr.push(itm.id);
                itm.order = pushIndex + 1;
            }
        })

        finalColumns = [...tempArr];
        finalColumns.sort(function (a, b) { return a.order - b.order });
        finalColumns.map((item, index) => {
            let subColumns = item.subcolumns;
            subColumns.sort(function (a, b) { return a.order - b.order });
        })

        //////////
        let sortedColumns = [...finalColumns];
        let sortedFinalColumns = sortedColumns.filter((item) => item.hidden !== true);
        return sortedFinalColumns;
        // return sortedColumns;
    });

    const compRef = useRef({
        itemDragged: {},
        itemDroppedOn: {},
        columns: getNeededColumns({ auth, app_state, ui_state })
        // columns: deepCopy(TILES[active_tiles[0]]['tableConfig']['columns']),
        // sortedColumn: TILES[active_tiles[0]]['tableConfig']['columns'][0][0]
    });

    let marketFilteredSegments = ui_state.table_filters.table_common.marketSegments.map(d => d.code);

    let segments = null
    if (segmented_view) {
        let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
        let segment_ignore_codes = getFromState('system_settings>market_code_ignorelist', state);
        segments = getTransformedSegments(marketSegments, segment_ignore_codes);
        console.log(ui_state.table_filters.table_common);

        if (active_tiles.includes('market')) {
            let marketFilteredSegments = ui_state.table_filters.table_common.marketSegments.map(d => d.code);
            if (marketFilteredSegments) {
                marketFilteredSegments = filteredSegments;
            }
            segments.arrUnique = marketFilteredSegments;
        }
        if (filteredSegments.includes('all')) {

            filteredSegments = [];
            segments.list.map((item) => {
                if (item.name === 'Other') {
                    if (!filteredSegments.includes('Other')) {
                        filteredSegments.push('Other');
                    }
                } else {
                    if (!filteredSegments.includes(item.code)) {
                        filteredSegments.push(item.code);
                    }
                }
            });

            if (active_tiles.includes('marketxx')) {
                let market_segments = getMarketSegment(active_tiles);
                filteredSegments = market_segments.map(item => item.code);
            } else {
                filteredSegments = segments.arrUnique;
            }
        }

    }

    const TableStyle = {
        height: "60vh",
        overflow: "auto",
    };



    useEffect(() => {
        let isSubscribed = true;
        let date_dba = app_state.workspace_controls.scopeType.toLocaleLowerCase();
        compRef.current.filteredColumns = getFilteredColumns(compRef.current.columns);
        //Horizontal scrolling jumping issue fixed on sorting columns

        if (reloadGrid === 'reOrderedMainCols' && compRef.current.data_setup) {
            let t = compRef.current.columns;
            let mainColOrders = compRef.current.columns.map((item) => ({ id: item.id, order: item.order }))
            compRef.current.data_setup.forEach((row, index) => {
                let newOrderColumn = [];
                // compRef.current.data_setup[index].sort()
                mainColOrders.forEach((col) => {
                    let colData = row.find((itm) => col.id === itm.id)
                    newOrderColumn.push(colData);
                })
                compRef.current.data_setup[index].splice(0, row.length, ...newOrderColumn);
            });
            setReloadGrid('applySorting');
        }

        if (reloadGrid === 'applySorting' && compRef.current.data_setup) {
            let finalRows = [];
            let sortedColumn = getSortedColumn(compRef.current.columns);
            let data_setup = deepCopy(compRef.current.data_setup);

            // data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });
            if (date_dba === 'date') {
                data_setup = compRef.current.data_setup.filter((item) => item[0][date_dba] === '' || filteredDow.includes(moment(item[0].date).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
            }

            if (workspace_controls.segmented_view) {
                data_setup = data_setup.filter((item) => (item[0][date_dba] === '' && filteredSegments.includes(item[0].segment)) || item[0].segment === 'Total' || filteredSegments.includes(item[0].segment) || filteredSegments.includes('all'));

                let totalsRows = data_setup.filter(item => item[0].segment === 'Total');
                totalsRows = sortData({ data: totalsRows, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });
                let segmentedTotalsRows = data_setup.filter(item => item[0][date_dba] === '' && filteredSegments.includes(item[0].segment_code));
                // totalsRows = getFilteredData({ data: totalsRows, columns: compRef.current.columns });

                totalsRows.forEach(item => {
                    let pricingIndex = item.findIndex(item => item.id === "pricing");
                    let compsetIndex = item.findIndex(item => item.id === "compset_medium");
                    let segmentedRows = data_setup.filter(d => item[0][date_dba] === d[0][date_dba] && d[0].segment !== 'Total');
                    segmentedRows = getFilteredData({ data: segmentedRows, columns: compRef.current.columns });

                    if (segmentedRows.length > 0) {

                        let calcTotal = getTotalData(segmentedRows, 'segment');


                        let totalOfFiltered = [...[item[0]], ...calcTotal];
                        totalOfFiltered.forEach((col, i) => {
                            totalOfFiltered[i].id = item[i].id;
                        });

                        if (pricingIndex > -1) {
                            totalOfFiltered.splice(pricingIndex, 1, item[pricingIndex]);
                        }
                        if (compsetIndex > -1) {
                            totalOfFiltered.splice(compsetIndex, 1, item[compsetIndex]);
                        }
                        // totalOfFiltered = getFilteredData({ data: totalOfFiltered, columns: compRef.current.columns });
                        if (totalOfFiltered.length) {
                            finalRows = [...finalRows, ...segmentedRows, ...[totalOfFiltered]];
                        }
                    }
                });


                let finalSegmentsTotal = [];
                if (finalRows.length) {
                    filteredSegments.forEach((item) => {
                        let segmentTotalRow = finalRows.filter((row) => row && row[0] && row[0].segment === item);
                        let dateCol = deepCopy(finalRows[0][0]);
                        dateCol.date = ''; dateCol.dow = ''; dateCol.segment = segments.map[item] ? segments.map[item] : item; dateCol.segment = item;

                        let calcTotal = getTotalData(segmentTotalRow, 'segmentTotal');

                        if (calcTotal.length) {
                            segmentTotalRow = [...[dateCol], ...getTotalData(segmentTotalRow, 'segmentTotal')];
                            finalSegmentsTotal = [...finalSegmentsTotal, ...[segmentTotalRow]];
                        }
                    });
                    finalSegmentsTotal.sort((a, b) => {
                        let A = a[1] && a[1].Rooms ? a[1].Rooms : 0; let B = b[1] && b[1].Rooms ? b[1].Rooms : 0;
                        if (B > 0 || A > 0) {
                            return B - A;
                        }
                    });
                    finalRows = [...finalRows, ...finalSegmentsTotal];

                }

                data_setup = deepCopy(finalRows);
                data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });

            } else {
                data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls });
                data_setup = getFilteredData({ data: data_setup, columns: compRef.current.columns });
            }

            // compRef.current.data_setup = deepCopy(data_setup);
            // console.log(data_setup);
            ;
            setDataSetup(data_setup);
            setReloadGrid('appliedSorting');
            setCompLoadStatus('sortedCols');
        }
        //

        if (!['applySorting', 'appliedSorting'].includes(reloadGrid) && !compRef.current.data_setup) {
            if (compLoad && displayData && displayData.length > 0) {
                compRef.current.columns.map((col) => {
                    col.subcolumns.map((subcol) => {
                        ;
                        if (!ui_state.table_filters.table_common.kpis.includes(subcol.value)
                            && !ui_state.table_filters.table_common.kpis_hidden.includes(subcol.value)
                            && !subcol.hidden) {
                            ui_state.table_filters.table_common.kpis.push(subcol.value);
                        }
                    });
                });
                compRef.current.columns.map((item) => {
                    if (item.id !== "staydates") {
                        item.subcolumns.map((sub_cols) => {
                            if (in_array(ui_state.table_filters.table_common.kpis, [sub_cols.value])) {
                                sub_cols.hidden = false;
                            } else {
                                sub_cols.hidden = true;
                            }
                        })
                    }
                })

                setupData();
            }
        }


        if (app_state.data_load) {
            // dispatch(
            //     updateAppState("data_load", {
            //         app_state: {
            //             data_load: false,
            //             kpi_load: false
            //         },
            //         ui_state: {
            //             showspinner: false
            //         }
            //     })
            // );
        }


        return () => (isSubscribed = false);

    }, [displayData, compLoad, reloadGrid, compLoadStatus]);

    useEffect(() => {
        let isSubscribed = true;
        if (app_state.workspace_controls.downloadExportButton === true) {
            downloadReportToExcel(app_state, ui_state, selectedPropertyName);
            dispatch(updateAppState("toggleChartTableView", {
                app_state: {
                    workspace_controls: { ...app_state.workspace_controls, downloadExportButton: false, }
                }
            }, "dashboard"));
        }

        if (isSubscribed) {
            if (!ui_state.showspinner) {
                //setCompLoad(false);
                //Handle multiple data set for intellimerge

                if (compDisplayData && compDisplayData.constructor.name === 'Array' && compDisplayData.length) {
                    // check for segmentrd data 
                    let transformedData = [];
                    if (workspace_controls.scopeType === 'Dba' && compDisplayData[0].data && !compDisplayData[0].data.length) {
                        transformedData = transformSegmentData(app_state, auth, compDisplayData[1].data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments });
                    } else {
                        let display_data = compDisplayData[0].data;
                        if (active_tiles.length > 1 && active_tiles.includes('market')) {
                            display_data = (dataService.getSeriesById("marketOtbSegment").data) ?
                                deepCopy(dataService.getSeriesById("marketOtbSegment").data.data) : display_data;
                        }

                        transformedData = transformSegmentData(app_state, auth, display_data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments, active_tiles: active_tiles });
                    }
                    // let transformedData = transformSegmentData(app_state, auth, compDisplayData[0].data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments });

                    // Doing some general transformation for filter and sorting
                    // let newDataSet = generalizeDataSet()(compDisplayData, {stayDates, controls: workspace_controls});

                    ;


                    setDisplayData(transformedData);
                    setCompLoad(true);
                    // setupData();
                }
                if (compDisplayData && compDisplayData.data) {
                    let transformedData = transformSegmentData(app_state, auth, compDisplayData.data, { segmentedOn: app_state.workspace_controls.segmented_view, tile: ui_state.active_tiles[0], segments: segments });
                    ;
                    setDisplayData(transformedData);
                    setCompLoad(true);
                    // setupData();
                }
            }
        }
        return () => (isSubscribed = false);
    }, [
        ui_state.showspinner,
        // workspace_controls.sdlyOn,
        // workspace_controls.scopeType,
        workspace_controls.downloadExportButton,
    ]);


    const filteredSegment = ui_state.table_filters.table_common.segments.map(d => d.code);
    if (segmented_view && filteredSegment.includes('all')) {
        let segmentArr = segments.list
        segmentArr = segmentArr.map(v => ({ ...v, hidden: false, title: v.name, type: "property_segments" }))
        segmentArr = segmentArr.map(v =>
            v.name === 'Other'
                ? { ...v, code: 'Other', hidden: false, title: v.name, type: "property_segments" }
                : { ...v, hidden: false, title: v.name, type: "property_segments" }
        );
        let segmentArrUnique = segmentArr.filter((obj, index) => {
            return index === segmentArr.findIndex(o => obj.name === o.name);
        });
        ui_state.table_filters.table_common.segments = segmentArrUnique;
    }


    const filteredMarketSegment = ui_state.table_filters.table_common.marketSegments.map(d => d.code);
    if (segmented_view && filteredMarketSegment.includes('all') && active_tiles.includes('market')) {
        let segmentArr = getMarketSegment();
        segmentArr = segmentArr.map(v => ({ ...v, hidden: false, title: v.name, type: "market_segments" }))
        segmentArr = segmentArr.filter((obj, index) => {
            return index === segmentArr.findIndex(o => obj.name === o.name);
        });
        ui_state.table_filters.table_common.marketSegments = segmentArr;
        // ui_state.table_filters.table_common.marketIntellimergeSegments = segmentArr;
    }


    const onDragStartHandler = (columParam) => {
        return (event) => {
            // event.preventDefault();
            event.dataTransfer.setData("columnMeta", JSON.stringify(columParam));
            compRef.current.itemDragged = deepCopy(columParam);
            ;
        }
    };


    const mainColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            const draggedCellOrder = columnMeta.order;
            const draggedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === draggedCellOrder);
            const droppedCellOrder = columParam.order;
            const droppedCellOrderIndex = compRef.current.columns.findIndex(col => col.order === droppedCellOrder);
            compRef.current.itemDroppedOn = columParam;
            if (droppedCellOrderIndex === 0) return;

            compRef.current.columns.map((mainCol, i) => {
                //moving to left
                if (draggedCellOrder > droppedCellOrder && i <= draggedCellOrderIndex) {
                    ;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i <= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order + 1;
                    }
                }
                //moving to right
                if (draggedCellOrder < droppedCellOrder && i >= draggedCellOrderIndex) {
                    ;
                    if (draggedCellOrderIndex === i) {
                        mainCol.order = droppedCellOrder;
                    } else if (i >= droppedCellOrderIndex) {
                        mainCol.order = mainCol.order - 1;
                    }
                }
            });
            compRef.current.columns = processColumnsNew(compRef.current.columns);
            // setupData();
            let ts = Date.now();
            // setReloadGrid(ts);
            // setCompLoadStatus('loading');
            setReloadGrid('reOrderedMainCols');

        }
    };
    const dataColumnOnDropHandler = (columParam) => {
        return (event) => {
            event.preventDefault();
            let columnMeta = JSON.parse(event.dataTransfer.getData("columnMeta"));
            compRef.current.itemDroppedOn = columParam;
            ;
            let { itemDragged, itemDroppedOn } = compRef.current;
            if (columnMeta.parent === columParam.parent) {
                let draggedOrder = columnMeta.order;
                let droppedOrder = columParam.order;
                let mainColumn = compRef.current.columns.filter((item) => item.id === columnMeta.parent);
                compRef.current.columns.map((mainCol, i) => {
                    if (mainCol.id === columnMeta.parent) {
                        compRef.current.columns[i].subcolumns.map((col) => {
                            if (col.value === columnMeta.value) {
                                col.order = droppedOrder;
                            }
                            if (col.value === columParam.value) {
                                col.order = draggedOrder;
                            }
                        });
                    }
                });
                let columns = processColumnsNew(compRef.current.columns);
                compRef.current.columns = columns;
                // setupData();
                let ts = Date.now();
                // setCompLoadStatus('loading');
                setReloadGrid('applySorting');
            }
        }
    };
    const columnToggleHandler = (item, index, hiddenStatus) => {
        let cols = compRef.current.columns;
        let items = item;
        if (item && item.constructor.name === 'Object') {
            items = [item]
        }
        let hiddenSubcols = items.filter(col => col.hidden);
        hiddenSubcols = hiddenSubcols.map(col => col.value);
        compRef.current.columns.forEach(column => {
            let subcosl = column.subcolumns;
            subcosl.forEach(subcol => {
                if (hiddenSubcols.includes(subcol.value)) {
                    subcol.hidden = true;
                } else {
                    subcol.hidden = false;
                }
            })
        });
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    }
    const applyFilterHandler = (item) => {
        let ts = Date.now();
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    };
    const clearFilterHandler = (item, type) => {
        if (type === 'all') {
            compRef.current.columns.map((main, i) => {
                main.subcolumns.map((sub, j) => {
                    //it depends on property sorted
                    sub.filter = null;
                });
            });
        }
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
    };

    const onOrderByChangeHandler = (item) => {
        // let cols = compRef.current.columns;
        compRef.current.sortedColumn = item;
        compRef.current.columns.map((main) => {
            if (main.id === item.parent) {
                main.subcolumns.map((col) => {
                    if (col.value === item.value) {
                        col.sorted = item.sorted;
                    } else {
                        col.sorted = null;
                    }
                })
            } else {
                main.subcolumns.map((col) => {
                    col.sorted = null;
                });
            }

        });
        let ts = Date.now();
        setCompLoadStatus('sortingCols');
        setReloadGrid('applySorting');
        // setupData();
    }

    const setupData = useCallback(() => {
        apc['cache_keys'] = apc['cache_keys'] && apc['cache_keys'].final === 'finalized__' + cache_data_key ? apc['cache_keys'] : { general: 'generalized__' + cache_data_key, 'final': 'finalized__' + cache_data_key };
        // debugger;
        if (apc[apc.cache_keys.final]) {
            let dataSetup = apc[apc.cache_keys.final];
            setDataSetup(dataSetup);
            compRef.current.data_setup = deepCopy(dataSetup);
            setCompLoadStatus('loaded');
        } else {

            let start = moment().format('x');
            // let columns = processColumns(displayData, { app_state, ui_state });
            let columns = processColumnsNew(compRef.current.columns);
            compRef.current.columns = columns;
            compRef.current.filteredColumns = getFilteredColumns(compRef.current.columns);
            // setColumns(columns);
            let data_cols = [];
            columns.map((i) => {
                i.subcolumns.map((x) => {
                    data_cols.push(x.value);
                });
            });
            compRef.current.data_cols = data_cols;
            if (!segmented_view) {

                let start = moment().format('x');
                // let data_setup = (app_state.workspace_controls.segmented_view) ? processDataIntelimergeSegment(columns, displayData, state, { auth, app_state, ui_state, }) : processDataIntelimerge(columns, displayData, { auth, app_state, ui_state, });
                // let inDowFilter = filteredDow.includes(itemDow) || filteredDow.includes('all');

                let data_setup = processDataIntelimerge(columns, displayData, { auth, app_state, ui_state, dataSet: compDisplayData });


                compRef.current.data_setup = deepCopy(data_setup);
                if (workspace_controls.scopeType === 'Date') {
                    data_setup = data_setup.filter((item) => filteredDow.includes(moment(item[0].date).format('ddd').toLocaleLowerCase()) || filteredDow.includes('all'));
                }

                // console.log("Time taken processDataIntelimerge: ", moment().format('x') - start);
                let t = compRef.current.sortedColumn;
                let sortedColumn = getSortedColumn(compRef.current.columns);
                data_setup = sortData({ data: data_setup, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted, workspace_controls: app_state.workspace_controls })

                data_setup = getFilteredData({ data: data_setup, columns });

                setDataSetup(data_setup);
                setCompLoadStatus('loaded');

                //setting into apc cache
                apc[apc.cache_keys.final] = deepCopy(data_setup);
            }

            if (segmented_view) {
                let data_orignal = (dataService.getSeriesById("OSRSegment").data) ? deepCopy(dataService.getSeriesById("OSRSegment").data.data) : [];
                let manualForecast = (dataService.getSeriesById("ManualForecast") && dataService.getSeriesById("ManualForecast").data) ? dataService.getSeriesById("ManualForecast").data.data : [];
                const { otb, pickup, forecast, pace, pricing, compset, market_sell_rates, market } = getActiveTilesData(state, app_state, auth, active_tiles, segments);
                let marketForcastData = (dataService.getSeriesById("MarketForecastSegmented").data) ? dataService.getSeriesById("MarketForecastSegmented").data.data : [];
                let marketOtbSegment = (dataService.getSeriesById("MarketARISegmented").data) ? dataService.getSeriesById("MarketARISegmented").data.data : [];
                //    debugger;
                const worker = new Worker("/js/worker.js");
                columns.map(col => delete col.calculate);
                worker.postMessage({ params: { dataSet: { otb, pickup, forecast, manualForecast, pace, pricing, compset, market_sell_rates, market, marketForcastData, marketOtbSegment }, columns, app_state, ui_state, stayDates, segments, auth } });
                worker.addEventListener('message', (res) => {
                    compRef.current.data_setup = deepCopy(res.data);
                    let data = deepCopy(res.data);
                    let testData = data.filter((item) => item[0].segment === 'Total');
                    let indexes = [];
                    data = data.filter((item) => filteredSegments.includes(item[0].segment) || item[0].segment === 'Total');
                    data = data.filter((item) => filteredDow.includes(moment(item[0].date).format('ddd').toLocaleLowerCase()) || item[0].dow === '');
                    let totalRows = data.filter((item, index) => {
                        if (item[0].segment === "Total") {
                            indexes.push(index);
                            return true;
                        }
                    });
                    totalRows.forEach((item, index) => {
                        let pricingIndex = item.findIndex(item => item.id === "pricing");
                        let compsetIndex = item.findIndex(item => item.id === "compset_medium");
                        let market_sell_three_star = item.findIndex(item => item.id === "market_sell_three_star");
                        let market_sell_four_star = item.findIndex(item => item.id === "market_sell_four_star");
                        let market_sell_five_star = item.findIndex(item => item.id === "market_sell_five_star");
                        let pickup_diff = item.findIndex(item => item.id === "pickup_difference");
                        let pickup_sdow_ly = item.findIndex(item => item.id === "pickup_sdow_ly");

                        let date_data = item[0].date
                        let dateFilterData = data.filter((dataItem) => dataItem[0].date == date_data && dataItem[0].segment !== 'Total')
                        // let totalData = getTotalData(dateFilterData[0], 'segment');
                        let calcTotal = getTotalData(dateFilterData, 'segment');
                        let totalData = [...[item[0]], ...calcTotal];

                        totalData.forEach((col, i) => {
                            if (item[i]) totalData[i].id = item[i].id;

                            //Adding sellrate value in total row of segmented view
                            if (totalData[i].hasOwnProperty('SellRate')) {
                                let dateHotelTotal = data_orignal.find(itm => moment(itm.index.date).isSame(totalData[0].date));
                                let columnDetails = compRef.current.columns.find(col => col.id === totalData[i].id);
                                let identity = columnDetails ? columnDetails.mainKey : null;
                                
                                if (identity && dateHotelTotal && dateHotelTotal.HotelTotal && dateHotelTotal.HotelTotal[identity] && dateHotelTotal.HotelTotal[identity].SellRate) {
                                    totalData[i].SellRate = dateHotelTotal.HotelTotal[identity].SellRate;
                                }
                                if(totalData[i].id === 'market_otb' && active_tiles && active_tiles.includes('market')){
                                    totalData[i].SellRate = item[i].SellRate;
                                }
                                if(totalData[i].id === 'market_sdly' && active_tiles && active_tiles.includes('market')){
                                    totalData[i].SellRate = item[i].SellRate;
                                }
                                if(totalData[i].id === 'market_actual_sdly' && active_tiles && active_tiles.includes('market')){
                                    totalData[i].SellRate = item[i].SellRate;
                                }
                            }


                        });
                        if (pricingIndex > -1) {
                            totalData.splice(pricingIndex, 1, item[pricingIndex]);
                        }
                        if (compsetIndex > -1) {
                            totalData.splice(compsetIndex, 1, item[compsetIndex]);
                        }
                        if (market_sell_three_star > -1) {
                            totalData.splice(market_sell_three_star, 1, item[market_sell_three_star]);
                        }
                        if (market_sell_four_star > -1) {
                            totalData.splice(market_sell_four_star, 1, item[market_sell_four_star]);
                        }
                        if (market_sell_five_star > -1) {
                            totalData.splice(market_sell_five_star, 1, item[market_sell_five_star]);
                        }
                        if(pickup_diff > -1){
                            let pickup_otb = item.findIndex(item => item.id === "pickup_otb_current");
                            let pickup_prior = item.findIndex(item => item.id === "pickup_prior");
                            totalData[pickup_diff]['SellRate'] = totalData[pickup_otb]['SellRate'] - totalData[pickup_prior]['SellRate'];
                        }
                        if(pickup_sdow_ly > -1){
                            let pickup_otb_sdly = item.findIndex(item => item.id === "pickup_otb_sdly");
                            let pickup_otb_sdly_prior = item.findIndex(item => item.id === "pickup_otb_sdly_prior");
                            totalData[pickup_sdow_ly]['SellRate'] = totalData[pickup_otb_sdly]['SellRate'] - totalData[pickup_otb_sdly_prior]['SellRate'];
                        }

                        let obj = deepCopy(item[0]);
                        data.splice(indexes[index], 1, totalData)
                    })

                    let sortedColumn = getSortedColumn(compRef.current.columns);
                    let data_setup = sortData({ data: data, sortBy: sortedColumn.value, parent: sortedColumn.parent, order: sortedColumn.sorted || 'default', workspace_controls: app_state.workspace_controls })
                    data_setup = getFilteredData({ data: data_setup, columns });
                    let segmentFinalRowsStartIndex = data_setup.findIndex(item => item[0].date === '');
                    let segmentFinalRows = data_setup.filter((item) => item[0].date === '');
                    let singleSegmentAllTotal = [];
                    segmentFinalRows.forEach((item) => {

                        let segment = item[0].segment;
                        let singleSegmentAll = data_setup.filter((d) => d[0].date !== '' && d[0].segment === segment);
                        let tmpTotal = getTotalData(singleSegmentAll, 'segmentTotal');
                        tmpTotal.splice(0, 0, item[0]);
                        tmpTotal[0].date = ''; tmpTotal[0].dow = '';
                        // if (tmpTotal[0].date === '') {
                        //     tmpTotal[0].compdate = '';
                        // }
                        // console.log(tmpTotal, " => ", item);
                        // tmpTotal.forEach((d, i) => tmpTotal[i].id = item[i].id);
                        tmpTotal.forEach((d, i) => tmpTotal[i].id = compRef.current.columns[i].id);

                        singleSegmentAllTotal.push(tmpTotal);
                    });

                    data_setup.splice(segmentFinalRowsStartIndex, segmentFinalRows.length);
                    data_setup = [...data_setup, ...singleSegmentAllTotal];


                    /** this block is used to create the blank row if segment code is not exist in any of the tile . */
                    let tmpCorrectRow = data_setup.filter((item) => item.length === compRef.current.columns.length);
                    data_setup.forEach((itm, index) => {
                        if (itm.length !== compRef.current.columns.length && tmpCorrectRow) {
                            let tmpArr = [];
                            tmpCorrectRow.forEach((tmpRow, tmpRowIndex) => {
                                if (itm[tmpRowIndex] && tmpRow.id === itm[tmpRowIndex].id) {
                                    tmpArr.push(itm[tmpRowIndex])
                                } else {
                                    let tmp = deepCopy(tmpRow);
                                    tmp = { ...tmp, Occ: 0, Rooms: 0, ADR: 0, RevPAR: 0, Revenue: 0, SellRate: 0 };
                                    tmpArr.push(tmp);
                                }
                            })
                            // if (tmpArr[0].date === '') {
                            //     let idx = tmpArr.findIndex((itm) => itm['id'] === "comparison_pace");
                            //     //if(idx > -1) tmpArr[idx].compdate = '';
                            // }

                            data_setup[index] = tmpArr;
                        }
                    })
                    /**  end this block is used to create the blank row if segment code is not exist in any of the tiel . */

                    setDataSetup(data_setup);
                    setCompLoadStatus('loaded');
                    worker.terminate();
                    //setting into apc cache
                    apc[apc.cache_keys.final] = deepCopy(data_setup);
                    // return data;
                });
            };

        }

    });
    const renderHeader = useCallback(() => {
        let columns = compRef.current.columns;
        let cols = [];
        let colheaders = [];
        let count = 0;
        let bckgClass = "grey";
        for (let index = 0; index < columns.length; index++) {
            if (index > 0) {
                bckgClass = "light_blue";
            }

            const col = columns[index];
            // const col_len = col["subcolumns"].length;
            let colSpan = col["subcolumns"].length;
            let hiddenCols = col["subcolumns"].filter(item => item.hidden);
            colSpan = colSpan - hiddenCols.length;

            let primaryHSty = {};
            if (col.type == "dateColumn") {
                primaryHSty = {
                    position: "sticky",
                    left: col.left ? col.left : 0,
                    zIndex: 5,
                };
                // colSpan = 2;
            }
            colSpan = colSpan > 1 ? colSpan : null;
            const header = (
                <PrimaryHeaderCell
                    onDrop={mainColumnOnDropHandler(col)}
                    key={index}
                    align="center"
                    colSpan={colSpan}
                    style={primaryHSty}
                    className={"primary-header-cell header_col "}
                    sx={{ fontWeight: "bold !important" }}
                    data-fill-color="606060"
                    data-f-color="ffffff"
                    data-b-r-s="thin"
                    data-a-v="middle"
                    data-b-a-c="ffffff"
                    data-a-h="center"
                    data-f-sz="16"
                >
                    {col.draggable && <div draggable={col.draggable} onDragStart={onDragStartHandler(col)} onDragOver={(e) => { e.preventDefault() }}>
                        {/* <span>{col.display}</span> */}
                        {primaryHeaderDisplay(col, app_state, ui_state)}

                    </div>}

                    {!col.draggable && <div>
                        {/* <span>{col.display}</span> */}
                        {primaryHeaderDisplay(col, app_state, ui_state)}

                    </div>}

                </PrimaryHeaderCell>
            );

            col["subcolumns"].map((item, sub_col_index) => {
                bckgClass = ((item.display).toLowerCase() === "comparison date") ? "header-grey" : "header-blue";
                let sty = {};
                // sty["paddingBottom"] = "18px";
                sty["fontWeight"] = "bold";
                sty["background"] = "#3fa7cd";
                sty["fontSize"] = "19px !important";

                let hasFilterClass = item.filter && (item.filter.gte || item.filter.lte) ? "has-filter" : "";
                if (!hasFilterClass && !['dow', 'date', 'segment'].includes(item.type)) {
                    let kpiHidden = ui_state.table_filters.table_common.kpis_hidden;
                    //  ui_state.table_filters.table_common.kpis_hidden.length
                    if (kpiHidden.length) {
                        hasFilterClass = "has-filter";
                    }
                }
                if (!hasFilterClass && ['dow'].includes(item.type) && ui_state.table_filters.table_common.dow.length < 7) {
                    hasFilterClass = "has-filter";
                }
                if (!hasFilterClass && ['segment'].includes(item.type)) {
                    let activeSegment = ui_state.table_filters.table_common.segments;
                    let initialAllSegment = ui_state.table_filters.table_common.segments.map(d => d.code)
                    if (!initialAllSegment.includes('all')) {
                        let segmentList = (segments && segments.list) ? new Set(segments.list.map(item => item.name)) : [];
                        if (activeSegment.length !== segmentList.size) {
                            hasFilterClass = "has-filter";
                        }
                    }
                }

                let el = (
                    <SecondaryHeaderBlueCell
                        onDrop={dataColumnOnDropHandler(item)}
                        className={"header_col  " + bckgClass + " col_len_1  header-col-" + item.value}
                        style={sty}
                        key={count}
                        align="center"
                        data-fill-color="B2B2B2"
                        data-f-color="ffffff"
                        data-a-v="middle"
                        data-a-h="center"
                        data-f-bold="true"
                        data-f-sz="15"
                        // data-b-a-c="ffffff"
                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        sx={{ fontSize: "13px", padding: "0px !important", top: "30px !important" }}
                    >
                        <div className={"filter-position-r " + bckgClass} onDragOver={(e) => { e.preventDefault() }}>

                            <ColumnSorting
                                className={"column-sorting field-" + item.value}
                                colItem={item} sortable={item.sortable} sorted={item.sorted}
                                label={item.display} value={item.value}
                                onOrderByChange={onOrderByChangeHandler} />

                            <div className="col-head-label" draggable={item.draggable} onDragStart={onDragStartHandler(item)}>
                                {item.display}
                            </div>
                            {item.filterMenu &&
                                <div className={"filter-main " + item.type}>
                                    {hasFilterClass && <span className={hasFilterClass}></span>}
                                    <Filter filteredColumns={compRef.current.filteredColumns} columnList={compRef.current.columns} currentColumn={item} segments={segments}
                                        columnToggleHandler={columnToggleHandler}
                                        applyFilterHandler={applyFilterHandler}
                                        clearFilterHandler={clearFilterHandler}
                                    />
                                </div>}
                        </div>
                    </SecondaryHeaderBlueCell>
                );


                if (col.type == "dateColumn") {
                    var colClass = "date_subcolumn";
                    sty["position"] = "sticky";
                    sty["zIndex"] = "5";
                    sty["textAlign"] = "center";
                    sty["paddingBottom"] = "18px";
                    sty["fontWeight"] = "bold";
                    sty["paddingLeft"] = "15px";
                    sty["paddingRight"] = "15px";
                    sty["background"] = "#b2b2b2";

                    if ((item.display).toLowerCase() === 'dba' || (item.display).toLowerCase() === 'month') {
                        sty["left"] = sub_col_index * 64;
                    }

                    if ((item.display).toLowerCase() === 'dow') {
                        // sty["left"] = "70px !important";
                        sty["width"] = "60px !important";
                    }
                    if ((item.display).toLowerCase() === 'segment' && ui_state.active_tiles[0].toLowerCase() !== "pace") {
                        sty["left"] = sub_col_index * 64;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }

                    if ((item.display).toLowerCase() === 'segment' && app_state.workspace_controls.scopeType === "Dba" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                        sty["left"] = sub_col_index * 100;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }
                    if ((item.display).toLowerCase() === 'segment' && app_state.workspace_controls.scopeType === "Date" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                        sty["left"] = sub_col_index * 74.5;
                        sty["width"] = "120px !important";
                        colClass = "date_subcolumn_segment";
                    }


                    <SecondaryHeaderGreyCell
                        className={"header_col  " + bckgClass + " " + colClass}
                        style={sty}
                        key={count}
                        align="left"
                        data-fill-color="3fa7cd"
                        data-f-color="ffffff"
                        data-a-v="middle"
                        data-a-h="center"
                        data-f-bold="true"
                        data-f-sz="15"
                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        // sx={{pl:3.5,pr:13.5,pt:1}}
                        sx={{ fontSize: "13px", padding: "0px !important", top: "30px !important" }}
                    >
                        {/* <div className="filter-position-r">
                                {item.display}
                                <Filter className="filter-position-a" />
                            </div> */}
                        <div className="filter-position-r date-col" sx={{ minWidth: "80px !important" }}>
                            <div className="col-head-label">
                                {item.display}
                            </div>
                            <div className="filter-main">
                                <Filter applyFilterHandler={applyFilterHandler}
                                    clearFilterHandler={clearFilterHandler} />
                            </div>
                        </div>
                    </SecondaryHeaderGreyCell>
                }
                if (!item.hidden) {
                    cols.push(el);
                }
                count++;

            });
            if (!col.hidden) {
                colheaders.push(header);
            }

        }

        return (
            <>
                <TableRow data-height="30" className="header_row top_header">{colheaders}</TableRow>
                <TableRow data-height="30" className="header_row bottom_header">{cols}</TableRow>
            </>
        );
    });
    const renderBody = useCallback((params) => {
        // const data_setup = compRef.current.data_setup;
        const rows = [];
        const size = data_setup.length;
        var colCount = 0;
        var totalArr = [];
        var rowsCount = 0;
        var columnsCounts = 0;
        if (size > 0) {
            for (let index = 0; index < size; index++) {
                var evenRow = (rowsCount % 2 === 0) ? "even-row" : "";
                let columnTotal = 0;
                const data_columns = data_setup[index] ? data_setup[index] : [];
                const cols = [];
                const reportCols = [];
                let count = 0;
                totalArr[index] = 0;
                colCount = 0;
                var totalRow = "";
                var totalCol = "";
                let rowDate;
                data_columns.map((column, col_index) => {
                    let subCols = [];
                    if (compRef.current.columns[col_index] && compRef.current.columns[col_index].subcolumns) {
                        subCols = compRef.current.columns[col_index].subcolumns.filter(col => !col.hidden);
                    }
                    let cellClass = "data-cell";
                    subCols.map((subcol, sub_col_index) => {
                        let key = subcol.value;
                        column = (column) ? column : {
                            "Occ": 0,
                            "Rooms": 0,
                            "ADR": null,
                            "RevPAR": 0,
                            "Revenue": 0,
                            "SellRate": 0,
                            "id": "otb"
                        }
                        let val = column[key];
                        let isTotalRow = column['segment'];

                        let txtAlignClass = (app_state.workspace_controls.segmented_view && key === 'Revenue') ? "text-align-right" : "";
                        let txtAlignClassml = (key === 'ADR' || key === 'Occ') ? "text-align-left-ml" : "";
                        let txtAlignClassRight = (key === 'Rooms') ? "" : "";

                        if (key === 'segment' && val === "Total") {
                            totalRow = "total-row";
                            totalCol = "total-col";
                        }
                        let sty = {};
                        if (col_index === 0) {
                            sty = { position: "sticky", zIndex: 2, textAlign: "left" };
                            sty["textAlign"] = "center";
                            if (key === "segment" && app_state.workspace_controls.scopeType === "Date" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                                sty["left"] = sub_col_index * 74.5;
                                sty["borderRight"] = "solid 1px #ddd";
                                sty["borderCollapse"] = "collapse";
                            }
                            if (key === "segment" && app_state.workspace_controls.scopeType === "Dba" && ui_state.active_tiles[0].toLowerCase() === "pace") {
                                sty["left"] = sub_col_index * 100;
                                sty["borderRight"] = "solid 1px #ddd";
                            } else if (key === "segment" && ui_state.active_tiles[0].toLowerCase() !== "pace") {
                                sty["left"] = sub_col_index * 64;
                                sty["borderRight"] = "solid 1px #ddd";
                            } else {
                                sty["left"] = sub_col_index * 83;
                                sty["left"] = sub_col_index * 83;
                                if ((!app_state.workspace_controls.segmented_view) && (key === "dow" || key === "date_market_summary" || key === "dba")) {
                                    sty["borderRight"] = "solid 1px #ddd";
                                    // sty["width"] = "solid 1px #ddd";
                                }
                            }
                        } else {
                            if (key.toLowerCase() === "revenue") {
                                // sty["color"] = "#3583B8";
                                sty["textAlign"] = "center";
                                sty["ml"] = "5px !important";
                                sty["padingLeft"] = "5px";
                            }
                        }
                        var txtAlign = { textAlign: "center" };
                        let rowsBackColor = (rowsCount % 2 === 0) ? "ebebeb" : "ffffff";
                        // var totalRow = "1";
                        let dataType = numberFormatForReport(key);
                        let reportData;
                        // if (!val)
                        let tmpVal;

                        if (val && typeof val === "number") {
                            tmpVal = val;
                        } else if (val && typeof val !== "object") {
                            tmpVal = val.replaceAll("%", "");
                        } else if (val && typeof val === "object") {
                            let resVal = (val[0]) ? Object.keys(val[0]).join(",") : "";
                            // let resValDetails = getResName(resVal,val[0]['max_los']);
                            tmpVal = resVal;
                        }

                        if (key === 'date') {
                            reportData = (val) ? moment(val).format('YYYY/MM/DD') : '';
                            rowDate = reportData;
                        } else if (key === 'Revenue' || key === 'ADR' || key === 'Occ' || key === 'RevPAR') {
                            tmpVal = (tmpVal && isFinite(tmpVal) && !isNaN(tmpVal)) ? tmpVal : parseFloat(0.00);
                            reportData = tmpVal;//(Number.isFinite(tmpVal)) ? parseFloat(tmpVal).toFixed(2) : parseFloat(0.00).toFixed(2);
                        } else {
                            reportData = (tmpVal);
                        }
                        let brdRight;
                        let dowStyle
                        if (key === "dow") {
                            dowStyle = "dow-style";
                        }
                        if (key === "compdate") {
                            reportData = moment(val).format('MMM DD YYYY,  ddd');
                            dowStyle = "dow-style-comp-date";
                        }
                        if (key === "segment") {
                            dowStyle = "segment-td-style";
                        }

                        let colorcode = ""
                        if (column.id === 'pricing' && key === 'ratediff') {
                            if (typeof val === 'number') {
                                colorcode = (val >= 0) ? "green-highlight" : "red-highlight";
                            }
                        }
                        //    console.log("error ",val);
                        val = (val && (val.toString() == 'Infinity%' || val.toString().includes('Infinity'))) ? "0%" : val;

                        // if(column.id === "otb_variance_sdly" && key === "RevPAR" && data_columns[0].date === '' ) {
                        //     val = (val * 145)+"%";
                        // }

                        cols.push(
                            <TableCell data-fill-color={rowsBackColor} data-b-r-s="thin" data-b-a-c="ffffff"
                                data-t="s" data-exclude="true" data-f-sz="13" data-a-h="center" key={count}
                                className={dowStyle + " " + brdRight + " " + cellClass + " " + colorcode + " " + totalCol + " " + txtAlignClass + " " + txtAlignClassml + " " + txtAlignClassRight}
                                style={sty}>

                                {
                                    (key === "Restrictions") ?
                                        <Restrictions data={val} stay_date={rowDate} />
                                        :
                                        (subcol.type === "percent" || key !== "compdate" && key !== "date" && key !== "dow" && key !== "segment" && key != "month" && key !== "RecSellRate" && key !== "ratediff" && key != "date_market_summary" && key != "dba" && typeof val === "string") ?
                                            showPercentValues(val)
                                            :
                                            // displayDataFormat(val, key, "", column.id)+" =>  " + typeof val
                                            // val+" "+key
                                            <DisplayData displayValue={displayDataFormat(val, key, "", column.id)} dataKey={key} stay_date={rowDate} isTotalRow={isTotalRow} />
                                }
                            </TableCell>
                        );
                        colCount++;

                        if (typeof val === 'string') {
                            //totalArr[count] = parseFloat(totalArr[count] + 0);
                        } else {
                            if (count === 4) {
                                if (totalArr[count] === 'undefind') {
                                    totalArr[count] = 0;
                                }
                            }
                            totalArr[count] += val;// parseFloat(totalArr[count] + val);
                        }
                        count += 1;
                    });
                    columnsCounts++;
                });
                rows.push(<>{cols}</>);
                // rows.push(<StyledTableRow data-exclude="true" data-height="20" className={(totalRow) ? totalRow : evenRow + " table-row"}>{cols}</StyledTableRow>);
                // rows.push(<StyledTableRow data-height="20" sx={{ display: "none" }}>{reportCols}</StyledTableRow>);

                rowsCount++;
            }
        } else {
            rows.push(<StyledTableRow className="">
                <TableCell colSpan={5}
                    sx={{ height: "auto", borderRight: "0px solid #ddd", background: "#fff", textAlign: "center", borderBottom: "0px solid" }}>
                    <Spinner />
                </TableCell></StyledTableRow>);
        }

        if (rowsCount <= 12) {
            let h = 30 * (12 - rowsCount) + 'px';
            ;
            rows.push(
                <><TableCell
                    sx={{ height: "300px !important", borderRight: "0px solid #ddd", background: "#fff", borderBottom: "0px solid" }}>
                    <div style={{ height: h }}></div>
                </TableCell></>);
        }

        let footerCols = [];
        let totalResultSet = [];
        var colSpanTotal = 2;
        let columns = processColumnsNew(compRef.current.columns);
        // if (data_setup.length > 0) {
        //     columns = data_setup[0];
        // }
        let count = 0;
        let marketOtbTotal = {};
        let marketSdlyTotal = {};
        let marketSdlyActualTotal = {};
        let otbCurrentTotal = {};
        let otbSdlyTotal = {};

        columns.map((column, col_index) => {
            // let subCols = Object.keys(column);
            let subCols = compRef.current.columns[col_index].subcolumns.filter(col => !col.hidden);
            let frontColumn = Object.keys(column).length;
            ;
            if (col_index === 0) {
                footerCols.push(
                    <TableCell
                        colSpan={subCols.length}
                        className="data-cell"

                        data-b-r-s="thin"
                        data-b-a-c="ffffff"
                        data-a-h="center"
                        data-f-sz="15"
                        data-fill-color="b1e2f4"
                        data-f-color="000000"
                        data-f-bold="true"

                        key={Math.random()}
                        style={{
                            borderRight: "1px solid #ddd",
                            left: 0,
                            position: "sticky",
                            fontWeight: "bold",
                            zIndex: 5,
                            borderBottomLeftRadius: "6px",
                            textTransform: "capitalize",
                            fontSize: 14
                        }}
                    >
                        Total
                    </TableCell>
                );
            } else {
                if (ui_state.active_tiles[0].toLowerCase() === 'compsetx') {
                    subCols.map((key, sub_col_index) => {
                        var finalTotal;
                        let sty = {};
                        sty["background"] = "#606060 !important";
                        sty = { fontWeight: "bold" };
                        if (key.value.toLowerCase() === "revenue") {
                            // sty["color"] = "#3583B8";
                        } else {
                            sty["color"] = "#fff !important";
                        }
                        footerCols.push(
                            <TableCell style={sty} className="data-cell"
                                data-b-r-s="thin"
                                data-b-a-c="ffffff"
                                data-a-h="center"
                                data-f-sz="14"
                                data-fill-color="b1e2f4"
                                data-f-color="000000"
                                data-f-bold="true"

                                key={Math.random()}>
                                {compsetTotal(data_setup, key.value, sub_col_index, count)}
                                {/* {
                    // (ui_state.active_tiles[0].toLowerCase() !== 'compset') ?
                    //   totalCalulate(data_setup, key, sub_col_index, count)
                    //   : compsetTotal(data_setup, key, sub_col_index, count)
                    } */}
                            </TableCell>
                        );
                    });
                }
                ///// for Market Sell rate Total row ////
                if (ui_state.active_tiles[0].toLowerCase() === 'market_sell_ratesx') {
                    subCols.map((key, sub_col_index) => {
                        var finalTotal;
                        let sty = {};
                        sty["background"] = "#606060 !important";
                        sty = { fontWeight: "bold" };
                        sty["color"] = "#fff !important";

                        footerCols.push(
                            <TableCell style={sty} data-b-r-s="thin"
                                data-b-a-c="ffffff"
                                data-a-h="center"
                                data-f-sz="14"
                                data-fill-color="b1e2f4"
                                data-f-color="000000"
                                data-f-bold="true" className="data-cell" key={Math.random()}>
                                {marketSellRatesTotal(data_setup, key, sub_col_index, count, ui_state)}
                            </TableCell>
                        );
                    });
                }

                // if (ui_state.active_tiles[0].toLowerCase() === 'compset' && ui_state.active_tiles[0].toLowerCase() !== 'market_sell_rates') {
                if (ui_state.active_tiles[0].toLowerCase() !== 'market_sell_ratesx') {
                    let getTotalRows = data_setup.filter((item, index) => {
                        if (item[0] && item[0].segment === "Total") {
                            return true;
                        }
                    })

                    let system_settings = getAppData(auth, app_state);
                    let totalCapacity = system_settings.property_current_total_capacity;
                    let allRowTotalCapacity = totalCapacity * (getTotalRows.length)


                    let totalSegmentedRows = data_setup.filter((item, index) => {
                        if (item[0] && item[0].date === "") {
                            // indexes.push(index);
                            return true;
                        }
                    })

                    // console.log("totalRows => ", totalRows);
                    totalSegmentedRows.map((row) => {
                        for (let i = 1; i < row.length; i++) {
                            if (row[i].hasOwnProperty('RevPAR')) {
                                //row[i].RevPAR = row[i].Revenue / allRowTotalCapacity;
                            }
                        }
                    })
                    const totalData = !workspace_controls.segmented_view ? getTotalData(data_setup) : getTotalData(getTotalRows);

                    // const totalData = getTotalData(getTotalRows);
                    // const totalSegmentedData = getTotalData(data_setup);
                    // console.log("Total Data => ",totalData);
                    let sty = {};

                    let dt1 = moment(app_state.primary_stay_range.date_from);
                    let dt2 = moment(app_state.primary_stay_range.date_until);
                    let daysCount = dt2.diff(dt1, 'days') + 1;


                    let marketCapacity = (dataService.getSeriesById("MarketCapacity").data) ? dataService.getSeriesById("MarketCapacity").data.data : [];
                    let totalMarketCapacity = (marketCapacity && marketCapacity.length > 0) ? marketCapacity[marketCapacity.length - 1]['Capacity'] : 0;


                    let i = totalData[col_index - 1];
                    if (i) {
                        subCols.map((col) => {

                            if (in_array(ui_state.active_tiles, ['market'])) {
                                if (col.parent === "market_otb") {
                                    marketOtbTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "market_sdly") {
                                    marketSdlyTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "market_actual_sdly") {
                                    marketSdlyActualTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "market_variance_sdly") {
                                    i[col.value] = convertToPercent((marketOtbTotal[col.value] - marketSdlyTotal[col.value]) / marketSdlyTotal[col.value]) + "%";
                                }
                                if (col.parent === "sdly_var_market_last_year") {
                                    i[col.value] = convertToPercent((marketSdlyTotal[col.value] - marketSdlyActualTotal[col.value]) / marketSdlyActualTotal[col.value]) + "%";
                                }
                            }


                            if (col.parent === "market_otb_pickup" && (col.display === "Occ" || col.display === "RevPAR")) {
                                let pickupDaysCt = app_state.workspace_controls.pickUpDays;
                                let asOfDate = app_state.as_of_date;
                                let pickupDate = moment(asOfDate).subtract(pickupDaysCt, "days");
                                i[col.value] = (col.value === "Occ") ? convertToPercent(i['Rooms'] / (totalMarketCapacity * daysCount)) : i['Revenue'] / (totalMarketCapacity * daysCount);
                            }
                            if ((col.parent === "pickup_difference") && (col.display === "Occ" || col.display === "RevPAR")) {
                                let pickupDaysCt = app_state.workspace_controls.pickUpDays;
                                let asOfDate = app_state.as_of_date;
                                let pickupDate = moment(asOfDate).subtract(pickupDaysCt, "days");
                                // i[col.value] = (col.value === "Occ") ? convertToPercent(i['Rooms'] / (totalCapacity * daysCount)) : i['Revenue'] / (totalCapacity * daysCount);
                                i[col.value] = (col.value === "Occ") ? convertToPercent(divide(i['Rooms'], (totalCapacity * daysCount))) : divide(i['Revenue'], (totalCapacity * daysCount))
                            }
                            if ((col.parent === "pickup_sdow_ly") && (col.display === "Occ" || col.display === "RevPAR")) {
                                let pickupDaysCt = app_state.workspace_controls.pickUpDays;
                                let asOfDate = app_state.as_of_date;
                                let pickupDate = moment(asOfDate).subtract(pickupDaysCt, "days");
                                i[col.value] = (col.value === "Occ") ? convertToPercent(i['Rooms'] / (totalCapacity * daysCount)) : i['Revenue'] / (totalCapacity * daysCount);
                            }

                            if (in_array(ui_state.active_tiles, ['otb'])) {
                                if (col.parent === "otb_current") {
                                    otbCurrentTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "otb_sdly") {
                                    otbSdlyTotal[col.value] = i[col.value];
                                }
                                if (col.parent === "otb_variance_sdly") {
                                    i[col.value] = convertToPercent((otbCurrentTotal[col.value] - otbSdlyTotal[col.value]) / otbSdlyTotal[col.value]) + "%";
                                }
                            }

                            let matchKpis = (!workspace_controls.segmented_view) ? `Rooms|ADR|Occ|RevPAR|Revenue|SellRate|compdate|RecSellRate|ratediff` : `Rooms|ADR|Occ|RevPAR|Revenue|SellRate|compdate`;
                            let occValue = (ui_state.active_tiles[0].toLowerCase() === 'pace') ? i.Occ / app_state.workspace_controls.dba : i.Occ;
                            let revParValue = (ui_state.active_tiles[0].toLowerCase() === 'pace') ? i.RevPAR / app_state.workspace_controls.dba : i.RevPAR;
                            revParValue = ((typeof revParValue).toLowerCase() === "string") ? revParValue : displayDataFormat(revParValue, "RevPAR", "");
                            let adrValue = ((typeof i.ADR).toLowerCase() === "string") ? i.ADR : displayDataFormat(i.ADR, "ADR", "");
                            footerCols.push(
                                <TableCell style={sty} className="data-cell" data-b-r-s="thin"
                                    data-b-a-c="ffffff"
                                    data-a-h="center"
                                    data-f-sz="14"
                                    data-fill-color="b1e2f4"
                                    data-f-color="000000"
                                    data-f-bold="true" key={Math.random()}
                                    val={col.value}>
                                    {
                                        // col.value
                                        (workspace_controls.segmented_view) ?
                                            (!col.value.match(/^(Rooms|ADR|Occ|RevPAR|Revenue|SellRate|compdate|RecSellRate|ratediff)$/)) ?
                                                // compsetTotal(data_setup, col.value, '', count)
                                                (col.value === "avg") ? marketSellRatesTotal(data_setup, col, '', count, ui_state) : compsetTotal(data_setup, col.value, '', count)

                                                :
                                                (col.value === "avg") ?
                                                    marketSellRatesTotal(data_setup, col, '', count, ui_state)
                                                    :
                                                    (col.value !== "date" && col.value !== 'dow' && col.value !== 'compdate') ?
                                                        displayDataFormat(i[col.value], col.value, "")
                                                        :
                                                        <></>
                                            :
                                            (!col.value.match(/^(Rooms|ADR|Occ|RevPAR|Revenue|SellRate|compdate|RecSellRate|ratediff)$/)) ?
                                                // compsetTotal(data_setup, col.value, '', count)
                                                (col.value === "avg") ? marketSellRatesTotal(data_setup, col, '', count, ui_state) : compsetTotal(data_setup, col.value, '', count)

                                                :
                                                (col.value === "avg") ?
                                                    marketSellRatesTotal(data_setup, col, '', count, ui_state)
                                                    :
                                                    (col.value !== "date" && col.value !== 'dow' && col.value !== 'compdate') ?
                                                        displayDataFormat(i[col.value], col.value, "")
                                                        :
                                                        <></>

                                    }
                                    {/* {(col.value !== "date" && col.value !== 'dow' && col.value !== 'compdate') ?
                                        displayDataFormat(i[col.value], col.value, "") : <></>
                                    }*/}


                                </TableCell>
                            );

                            // console.log();

                            if (!col.value.match(/^(Rooms|ADR|Occ|RevPAR|Revenue|SellRate|compdate|RecSellRate|ratediff)$/)) {
                                let val = compsetTotal(data_setup, col.value, '', count);
                                totalResultSet.push(val)
                            }
                            else {
                                let val = displayDataFormat(i[col.value], col.value, "");
                                // console.log("point 2", val, i, col.value, totalData)
                                totalResultSet.push(val);
                            }

                        })
                    }
                    // });
                }
            }
            count++;
        });

        setTotalRowsData(totalResultSet);
        // console.log("footerColsfooterColsfooterCols ", footerCols);
        rows.push(<>{
            (in_array(ui_state.active_tiles, ["pace", "market", "compset"]) && app_state.workspace_controls.scopeType === "Dba") ?
                <></> :
                footerCols
        }</>
        );
        // console.log("Total Row ", rows);
        return rows;
    });


    const getTotalData_31May = useCallback((dataSet, type = 'Total') => {

        var date1 = moment(app_state.primary_stay_range.date_from);
        var date2 = moment(app_state.primary_stay_range.date_until);
        var daysCount = (type === 'segment') ? 1 : dataSet.length; //date2.diff(date1, 'days') + 1;
        let dataArray = [];
        if (dataSet.length > 0) {
            // let keysArray = Object.keys(dataSet[0][1]);
            let dataObj = {}
            for (let j = 1; j < dataSet[0].length; j++) {
                let roomsTotal = 0;
                let occ = 0;
                let revPar = 0;
                let revenue = 0;
                let sellRate = 0;
                let total_sellRate;
                var total_sellRate_count = 0;
                let lenforSegment = 1;

                /// for variance 
                let varRoomsTotal = 0;
                let varRoomsSdlyTotal = 0;
                let varOccTotal = 0;
                let varOccSdlyTotal = 0;
                let varRevParTotal = 0;
                let varRevParSdlyTotal = 0;
                let varRevenueTotal = 0;
                let varRevenueSdlyTotal = 0;
                let varAdrTotal = 0;
                let varAdrSdlyTotal = 0;

                for (let i = 0; i < dataSet.length; i++) {
                    let items = dataSet[i];
                    if (app_state.workspace_controls.segmented_view) {
                        if (type === 'Total' && items[0] && items[0].segment === "Total" && items[0].date !== '') {
                            if (items[j] && Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                let market_sdly_index;
                                let primary_index = 1;
                                if (items[j].id === "market_variance_sdly") {
                                    market_sdly_index = items.findIndex((it) => it.id === "market_sdly");
                                } else {
                                    market_sdly_index = 2;
                                }
                                if (items[j].id === "sdly_var_market_last_year") {
                                    primary_index = items.findIndex((it) => it.id === "market_sdly");
                                    market_sdly_index = items.findIndex((it) => it.id === "market_actual_sdly");
                                }
                                if (typeof items[j].Rooms === "string" || items[j].id === "sdly_var_market_last_year" || items[j].id === "market_variance_sdly") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[primary_index].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[market_sdly_index].Rooms);

                                    varRevParTotal += parseFloat(items[primary_index].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[market_sdly_index].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[primary_index].Occ);
                                    varOccSdlyTotal += (items[market_sdly_index].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[primary_index].Revenue);
                                    varRevenueSdlyTotal += (items[market_sdly_index].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);

                                    if (items[j].id === "sdly_var_market_last_year") {
                                        roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsTotal) + "%";
                                        occ = convertToPercent(((occTmp - occSdlyTmp) / occTmp)) + "%";
                                        revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParTmp) + "%";
                                        revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueTmp)) + "%";
                                        adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrTotal)) + "%";
                                    } else {
                                        roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                        occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                        revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                        revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                        adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                    }

                                } else {
                                    console.log(getCorrectValue(items[j].Occ), " test ", items[j].Occ);
                                    roomsTotal += (items[j].Rooms);
                                    occ += getCorrectValue(items[j].Occ);
                                    revPar += getCorrectValue(items[j].RevPAR);
                                    revenue += getCorrectValue(items[j].Revenue);
                                    sellRate += getCorrectValue(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id

                                }

                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                        if (type === 'segment' && items[0] && items[0].segment !== "Total" && items[0].date !== '') {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                let market_sdly_index;
                                let sdly_actual_index;
                                let primary_index = 1;
                                if (items[j].id === "market_variance_sdly") {
                                    market_sdly_index = items.findIndex((it) => it.id === "market_sdly");
                                } else {
                                    market_sdly_index = 2;
                                }
                                if (items[j].id === "sdly_var_market_last_year") {
                                    primary_index = items.findIndex((it) => it.id === "market_sdly");
                                    market_sdly_index = items.findIndex((it) => it.id === "market_actual_sdly");
                                }
                                if (typeof items[j].Rooms === "string" || items[j].id === "sdly_var_market_last_year" || items[j].id === "market_variance_sdly") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[primary_index].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[market_sdly_index].Rooms);

                                    varRevParTotal += parseFloat(items[primary_index].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[market_sdly_index].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[primary_index].Occ);
                                    varOccSdlyTotal += (items[market_sdly_index].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[primary_index].Revenue);
                                    varRevenueSdlyTotal += (items[market_sdly_index].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);

                                    if (items[j].id === "sdly_var_market_last_year") {
                                        roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsTotal) + "%";
                                        occ = convertToPercent(((occTmp - occSdlyTmp) / occTmp)) + "%";
                                        revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParTmp) + "%";
                                        revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueTmp)) + "%";
                                        adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrTotal)) + "%";
                                    } else {
                                        roomsTotal = (isFinite((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal)) ? convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%" : 0 + "%";
                                        occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                        revPar = isFinite((revParTmp - revParSdlyTmp) / revParSdlyTmp) ? convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%" : 0 + "%";
                                        revenue = isFinite((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp) ? convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%" : 0 + "%";
                                        adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                    }

                                } else {
                                    roomsTotal += (items[j].Rooms);
                                    occ += getCorrectValue(items[j].Occ);
                                    revPar += getCorrectValue(items[j].RevPAR);
                                    revenue += getCorrectValue(items[j].Revenue);
                                    sellRate += getCorrectValue(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if (items[j].id && items[j].id === 'comparison_pace') {
                                    dataObj.compdate = ''
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                        if (type === 'segmentTotal' && items[0] && items[0].segment !== "Total") {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let compDate = '';
                                let adrTotal;
                                let primaryValtotal
                                let market_sdly_index;
                                let primary_index = 1;
                                if (items[j].id === "market_variance_sdly") {
                                    market_sdly_index = items.findIndex((it) => it.id === "market_sdly");
                                } else {
                                    market_sdly_index = 2;
                                }
                                if (items[j].id === "sdly_var_market_last_year") {
                                    primary_index = items.findIndex((it) => it.id === "market_sdly");
                                    market_sdly_index = items.findIndex((it) => it.id === "market_actual_sdly");
                                }
                                let occVal;
                                if (typeof items[j].Rooms === "string" || items[j].id === "sdly_var_market_last_year" || items[j].id === "market_variance_sdly") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[primary_index].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[market_sdly_index].Rooms);

                                    varRevParTotal += parseFloat(items[primary_index].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[market_sdly_index].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[primary_index].Occ);
                                    varOccSdlyTotal += (items[market_sdly_index].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[primary_index].Revenue);
                                    varRevenueSdlyTotal += (items[market_sdly_index].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);

                                    if (items[j].id === "sdly_var_market_last_year") {
                                        roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsTotal) + "%";
                                        occ = convertToPercent(((occTmp - occSdlyTmp) / occTmp)) + "%";
                                        revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParTmp) + "%";
                                        revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueTmp)) + "%";
                                        adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrTotal)) + "%";
                                    } else {
                                        roomsTotal = (isFinite(convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal))) ? convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + '%' : + "0 %";
                                        occ = (isFinite(convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)))) ? convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%" : 0 + "%";
                                        revPar = (isFinite(convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp))) ? convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%" : 0 + "%";
                                        revenue = (isFinite(convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)))) ? convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%" : 0 + "%";
                                        adrTotal = (isFinite(convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)))) ? convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%" : 0 + "%";
                                    }
                                    occVal = occ;
                                } else {
                                    roomsTotal += (items[j].Rooms);
                                    occ += parseFloat(items[j].Occ);
                                    revPar += parseFloat(items[j].RevPAR);
                                    revenue += parseFloat(items[j].Revenue);
                                    sellRate += parseFloat(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                    // compDate = (items[j].id && items[j].id === 'comparison_pace') ? "yes" : '';
                                    occVal = (occ / dataSet.length) ? occ / dataSet.length : 0;
                                }
                                // console.log(occ," here  ",dataSet.length);
                                // let occVal = (occ / dataSet.length) ? occ / dataSet.length : 0;
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occVal,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if (items[j].id && items[j].id === 'comparison_pace') {
                                    dataObj.compdate = ''
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                    } else {
                        if (items[0] && items[0].segment !== "Total" && items[0].date !== '' && items[0].dba !== '') {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                let market_sdly_index;
                                let primary_index = 1;
                                if (items[j].id === "market_variance_sdly") {
                                    market_sdly_index = items.findIndex((it) => it.id === "market_sdly");
                                } else {
                                    market_sdly_index = 2;
                                }
                                if (items[j].id === "sdly_var_market_last_year") {
                                    primary_index = items.findIndex((it) => it.id === "market_sdly");
                                    market_sdly_index = items.findIndex((it) => it.id === "market_actual_sdly");
                                }

                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[primary_index].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[market_sdly_index].Rooms);

                                    varRevParTotal += parseFloat(items[primary_index].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[market_sdly_index].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[primary_index].Occ);
                                    varOccSdlyTotal += (items[market_sdly_index].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[primary_index].Revenue);
                                    varRevenueSdlyTotal += (items[market_sdly_index].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += items[j].Rooms ? (items[j].Rooms) : 0;
                                    occ += (items[j].Occ) ? parseFloat(items[j].Occ) : 0;
                                    revPar += (items[j].RevPAR) ? parseFloat(items[j].RevPAR) : 0;
                                    revenue += (items[j].Revenue) ? (parseFloat(items[j].Revenue)) : 0;
                                    sellRate += (total_sellRate) ? parseFloat(total_sellRate) : 0;
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,//(revenue / roomsTotal)
                                    "id": items[j].id
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                    }
                }
                // let sellRateValue = (ui_state.active_tiles[0].toLowerCase() === 'market') ? 0 : '';
                let sRate = round(dataObj.SellRate / total_sellRate_count);
                if (sRate <= 0 && ui_state.active_tiles[0].toLowerCase() === 'market' && app_state.workspace_controls.scopeType === 'Dba') {
                    // sRate = "";
                }
                let adrTotal = dataObj.ADR;
                if (ui_state.active_tiles[0] === "market_summary" || ui_state.active_tiles[0] === "hotel_summary") {
                    daysCount = dataSet.length;
                    sRate = round(dataObj.SellRate / daysCount);
                }

                let occTotal = type === 'Total' ? dataObj.Occ / daysCount : dataObj.Occ;
                if (typeof dataObj.Occ !== "string" && typeof dataObj.RevPAR !== "string") {
                    var newValues = {
                        Occ: occTotal,
                        RevPAR: round(dataObj.RevPAR / daysCount),
                        SellRate: sRate,
                        Revenue: round(dataObj.Revenue),
                        ADR: round(dataObj.ADR)
                    }
                    dataObj = { ...dataObj, ...newValues };
                }
                if (
                    (dataObj.id && dataObj.id === "compset_medium" ||
                        dataObj.id && dataObj.id === "market_sell_three_star" ||
                        dataObj.id && dataObj.id === "market_sell_four_star" ||
                        dataObj.id && dataObj.id === "market_sell_five_star") &&
                    type === 'segmentTotal'
                ) {
                    dataArray.push(dataSet[0][j]);
                }
                else {
                    dataArray.push(dataObj);
                }
                dataObj = {};

            }

            let diffRpickupIndex = dataArray.findIndex((it) => it.id === "otb_remaing_pickup_sdly_x");
            if (diffRpickupIndex >= 0) {
                let currentIndex = dataArray.findIndex((it) => it.id === "otb_actual_sdly");
                let sdlyIndex = dataArray.findIndex((it) => it.id === "otb_sdly");
                dataArray[diffRpickupIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
            }

            let diffIndex = dataArray.findIndex((it) => it.id === "otb_diff_sdly");
            if (diffIndex >= 0) {
                let currentIndex = dataArray.findIndex((it) => it.id === "otb_current");
                let sdlyIndex = dataArray.findIndex((it) => it.id === "otb_sdly");
                dataArray[diffIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
            }

            let marketDiffIndex = dataArray.findIndex((it) => it.id === "diff_market_sdly");
            if (marketDiffIndex >= 0) {
                let marketPrimaryIndex = dataArray.findIndex((it) => it.id === "market_otb");
                let marketSdlyIndex = dataArray.findIndex((it) => it.id === "market_sdly");
                dataArray[marketDiffIndex].ADR = dataArray[marketPrimaryIndex].ADR - dataArray[marketSdlyIndex].ADR;
            }

            let sdlyDiffIndex = dataArray.findIndex((it) => it.id === "sdly_diff_last_year");
            if (sdlyDiffIndex >= 0) {
                let marketSdlyPrimaryIndex = dataArray.findIndex((it) => it.id === "market_sdly");
                let marketSdlyActualIndex = dataArray.findIndex((it) => it.id === "market_actual_sdly");
                dataArray[sdlyDiffIndex].ADR = dataArray[marketSdlyPrimaryIndex].ADR - dataArray[marketSdlyActualIndex].ADR;
            }

            let hotelSummaryDiffIndex = dataArray.findIndex((it) => it.id === "hotel_summary_sdly_variance");
            if (hotelSummaryDiffIndex >= 0) {
                let from = dataArray.findIndex((it) => it.id === "hotel_summary_otb");
                let to = dataArray.findIndex((it) => it.id === "hotel_summary_sdly_otb");
                dataArray[hotelSummaryDiffIndex].ADR = dataArray[from].ADR - dataArray[to].ADR;
            }

            let marketSdlyVar = dataArray.findIndex((it) => it.id === "sdly_var_market_last_year");
            if (marketSdlyVar >= 0) {
                let sdly_diff_last_year = dataArray.findIndex((it) => it.id === "sdly_diff_last_year");
                let market_actual_sdly = dataArray.findIndex((it) => it.id === "market_actual_sdly");
                dataArray[marketSdlyVar].Occ = ((dataArray[sdly_diff_last_year].Occ / dataArray[market_actual_sdly].Occ) * 100).toFixed(2);
                dataArray[marketSdlyVar].Rooms = ((dataArray[sdly_diff_last_year].Rooms / dataArray[market_actual_sdly].Rooms) * 100).toFixed(2);
                dataArray[marketSdlyVar].ADR = ((dataArray[sdly_diff_last_year].ADR / dataArray[market_actual_sdly].ADR) * 100).toFixed(2);
                dataArray[marketSdlyVar].RevPAR = ((dataArray[sdly_diff_last_year].RevPAR / dataArray[market_actual_sdly].RevPAR) * 100).toFixed(2);
                dataArray[marketSdlyVar].Revenue = ((dataArray[sdly_diff_last_year].Revenue / dataArray[market_actual_sdly].Revenue) * 100).toFixed(2);
                dataArray[marketSdlyVar].SellRate = ((dataArray[sdly_diff_last_year].SellRate / dataArray[market_actual_sdly].SellRate) * 100).toFixed(2);
                // dataArray[marketDiffIndex].ADR = dataArray[marketPrimaryIndex].ADR - dataArray[marketSdlyIndex].ADR;
            }

        }

        let otbVarSdly = dataArray.findIndex((it) => it.id === "otb_variance_sdly");
        if (otbVarSdly >= 0) {
            let otbDiffCurrent = dataArray.findIndex((it) => it.id === "otb_diff_sdly");
            let otbSdly = dataArray.findIndex((it) => it.id === "otb_sdly");
            dataArray[otbVarSdly].Occ = ((dataArray[otbDiffCurrent].Occ / dataArray[otbSdly].Occ) * 100).toFixed(2);
            dataArray[otbVarSdly].Rooms = ((dataArray[otbDiffCurrent].Rooms / dataArray[otbSdly].Rooms) * 100).toFixed(2);
            dataArray[otbVarSdly].ADR = ((dataArray[otbDiffCurrent].ADR / dataArray[otbSdly].ADR) * 100).toFixed(2);
            dataArray[otbVarSdly].RevPAR = ((dataArray[otbDiffCurrent].RevPAR / dataArray[otbSdly].RevPAR) * 100).toFixed(2);
            dataArray[otbVarSdly].Revenue = ((dataArray[otbDiffCurrent].Revenue / dataArray[otbSdly].Revenue) * 100).toFixed(2);
            dataArray[otbVarSdly].SellRate = ((dataArray[otbDiffCurrent].SellRate / dataArray[otbSdly].SellRate) * 100).toFixed(2);

        }

        /** Calculation for Pickup and Market when mode = date  */
        // if(in_array(ui_state.active_tiles,["pickup","market"])){
        //         let system_settings = getAppData(auth, app_state);
        //         let totalCapacity = system_settings.inventory_settings.current.total_capacity;
        //         dataArray[0]['Occ'] = dataArray[0]['Rooms'] / (totalCapacity * 20) ;
        // }
        /** end  */

        if (ui_state.active_tiles[0] === "pace" && dataArray[1]) {
            let paceAdrDiff = dataArray[0]["ADR"] - dataArray[1]["ADR"];
            let paceRevparDiff = dataArray[0]["RevPAR"] - dataArray[1]["RevPAR"];

            dataArray.forEach((dataItem, i) => {
                if (i === 2) {
                    dataArray[i].ADR = paceAdrDiff;
                    dataArray[i].RevPAR = paceRevparDiff;
                }
            })
        }
        if (type === "Total") {
            dataArray = otbTransformTotal(dataArray, ui_state.active_tiles, workspace_controls, dataSet);
        }
        return dataArray;
    });

    // replace this function with table Common (if any issue comes , then we will again open this.)
    const getTotalData = useCallback((dataSetAll, type = 'Total') => {
        let dataSet = (app_state.workspace_controls.segmented_view && type === 'Total') ? dataSetAll.filter((item) => item[0].segment === "Total" && item[0].date !== "") : dataSetAll;
        var date1 = moment(app_state.primary_stay_range.date_from);
        var date2 = moment(app_state.primary_stay_range.date_until);
        var daysCount = (type === 'segment') ? 1 : dataSet.length; //date2.diff(date1, 'days') + 1;
        let dataArray = [];
        if (dataSet.length > 0) {
            // let keysArray = Object.keys(dataSet[0][1]);
            let dataObj = {}
            for (let j = 1; j < dataSet[0].length; j++) {
                let roomsTotal = 0;
                let occ = 0;
                let revPar = 0;
                let revenue = 0;
                let sellRate = 0;
                let RecSellRate = 0;
                let ratediff = 0;
                let total_sellRate;
                var total_sellRate_count = 0;
                let lenforSegment = 1;

                /// for variance 
                let varRoomsTotal = 0;
                let varRoomsSdlyTotal = 0;
                let varOccTotal = 0;
                let varOccSdlyTotal = 0;
                let varRevParTotal = 0;
                let varRevParSdlyTotal = 0;
                let varRevenueTotal = 0;
                let varRevenueSdlyTotal = 0;
                let varAdrTotal = 0;
                let varAdrSdlyTotal = 0;

                for (let i = 0; i < dataSet.length; i++) {
                    let items = dataSet[i];
                    if (app_state.workspace_controls.segmented_view) {
                        ;
                        if (type === 'Total' && items[0] && items[0].segment === "Total" && items[0].date !== '') {
                            if (items[j] && Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[1].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                    varRevParTotal += parseFloat(items[1].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[1].Occ);
                                    varOccSdlyTotal += (items[2].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[1].Revenue);
                                    varRevenueSdlyTotal += (items[2].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += (items[j].Rooms);
                                    occ += parseFloat(items[j].Occ);
                                    revPar += parseFloat(items[j].RevPAR);
                                    revenue += parseFloat(items[j].Revenue);
                                    sellRate += parseFloat(total_sellRate);
                                    RecSellRate += (items[j].RecSellRate) ? parseFloat(items[j].RecSellRate) : 0;
                                    ratediff += (items[j].ratediff) ? parseFloat(items[j].ratediff) : 0;
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "RecSellRate": RecSellRate,
                                    "ratediff": ratediff,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                    ;
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                        if (type === 'segment' && items[0] && items[0].segment !== "Total" && items[0].date !== '') {
                            if (items[j] && Object.keys(items[j]) && Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[1].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                    varRevParTotal += parseFloat(items[1].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[1].Occ);
                                    varOccSdlyTotal += (items[2].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[1].Revenue);
                                    varRevenueSdlyTotal += (items[2].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += (items[j].Rooms);
                                    occ += parseFloat(items[j].Occ);
                                    revPar += parseFloat(items[j].RevPAR);
                                    revenue += parseFloat(items[j].Revenue);
                                    sellRate += parseFloat(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if (items[j].id && items[j].id === 'comparison_pace') {
                                    dataObj.compdate = ''
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                        if (type === 'segmentTotal' && items[0] && items[0].segment !== "Total") {
                            if (items[j] && Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[1].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                    varRevParTotal += parseFloat(items[1].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[1].Occ);
                                    varOccSdlyTotal += (items[2].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[1].Revenue);
                                    varRevenueSdlyTotal += (items[2].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    roomsTotal += (items[j].Rooms);
                                    occ += parseFloat(items[j].Occ);
                                    revPar += parseFloat(items[j].RevPAR);
                                    revenue += parseFloat(items[j].Revenue);
                                    sellRate += parseFloat(total_sellRate);
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ / dataSet.length,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if (items[j].id && items[j].id === 'comparison_pace') {
                                    dataObj.compdate = ''
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                    } else {
                        if (items[0] && items[0].segment !== "Total" && items[0].date !== '' && items[0].dba !== '') {
                            if (Object.keys(items[j]).length) {
                                let total_sellRate = (items[j].SellRate !== '') ? items[j].SellRate : 0;
                                if (items[j].SellRate !== '') {
                                    total_sellRate = items[j].SellRate;
                                    total_sellRate_count++;
                                } else {
                                    total_sellRate = 0;
                                    total_sellRate_count++;
                                }
                                let adrTotal;
                                let primaryValtotal
                                if (typeof items[j].Rooms === "string") {
                                    // total for variance /////
                                    varRoomsTotal += parseFloat(items[1].Rooms);
                                    varRoomsSdlyTotal += parseFloat(items[2].Rooms);

                                    varRevParTotal += parseFloat(items[1].RevPAR);
                                    varRevParSdlyTotal += parseFloat(items[2].RevPAR);
                                    let revParTmp = (varRevParTotal / total_sellRate_count);
                                    let revParSdlyTmp = (varRevParSdlyTotal / total_sellRate_count);

                                    varOccTotal += (items[1].Occ);
                                    varOccSdlyTotal += (items[2].Occ);
                                    let occTmp = (varOccTotal / total_sellRate_count);
                                    let occSdlyTmp = (varOccSdlyTotal / total_sellRate_count);

                                    varRevenueTotal += (items[1].Revenue);
                                    varRevenueSdlyTotal += (items[2].Revenue);
                                    let revenueTmp = (varRevenueTotal / total_sellRate_count);
                                    let revenueSdlyTmp = (varRevenueSdlyTotal / total_sellRate_count);

                                    varAdrTotal = (varRevenueTotal / varRoomsTotal);
                                    varAdrSdlyTotal = (varRevenueSdlyTotal / varRoomsSdlyTotal);
                                    roomsTotal = convertToPercent((varRoomsTotal - varRoomsSdlyTotal) / varRoomsSdlyTotal) + "%";
                                    occ = convertToPercent(((occTmp - occSdlyTmp) / occSdlyTmp)) + "%";
                                    revPar = convertToPercent((revParTmp - revParSdlyTmp) / revParSdlyTmp) + "%";
                                    revenue = convertToPercent(((revenueTmp - revenueSdlyTmp) / revenueSdlyTmp)) + "%";
                                    adrTotal = convertToPercent(((varAdrTotal - varAdrSdlyTotal) / varAdrSdlyTotal)) + "%";
                                } else {
                                    // roomsTotal += (items[j].Rooms);
                                    // occ += parseFloat(items[j].Occ);
                                    // revPar += parseFloat(items[j].RevPAR);
                                    // revenue += parseFloat(items[j].Revenue);
                                    // sellRate += parseFloat(total_sellRate);
                                    roomsTotal += items[j].Rooms ? (items[j].Rooms) : 0;
                                    occ += (items[j].Occ) ? parseFloat(items[j].Occ) : 0;
                                    revPar += (items[j].RevPAR) ? parseFloat(items[j].RevPAR) : 0;
                                    RecSellRate += (items[j].RecSellRate) ? parseFloat(items[j].RecSellRate) : 0;
                                    ratediff += (items[j].ratediff) ? parseFloat(items[j].ratediff) : 0;

                                    revenue += (items[j].Revenue) ? (parseFloat(items[j].Revenue)) : 0;
                                    sellRate += (total_sellRate) ? parseFloat(total_sellRate) : 0;
                                    adrTotal = (revenue / roomsTotal);
                                    adrTotal = (isNaN(adrTotal) || !isFinite(adrTotal)) ? 0.00 : adrTotal;
                                }
                                dataObj = {
                                    "Rooms": roomsTotal,
                                    "Occ": occ,
                                    "RevPAR": revPar,
                                    "Revenue": revenue,
                                    "SellRate": sellRate,
                                    "RecSellRate": RecSellRate,
                                    "ratediff": ratediff,
                                    "ADR": adrTotal,
                                    "id": items[j].id
                                }
                                if ('SellRate' in items[j] === false) {
                                    delete dataObj["SellRate"];
                                }
                                if (Object.keys(items[j]).includes("Restrictions")) {
                                    dataObj = { "Restrictions": "" };
                                }
                            }
                            lenforSegment++;
                        }
                    }
                }
                // let sellRateValue = (ui_state.active_tiles[0].toLowerCase() === 'market') ? 0 : '';
                let sRate = round(dataObj.SellRate / total_sellRate_count);
                if (sRate <= 0 && ui_state.active_tiles[0].toLowerCase() === 'market' && app_state.workspace_controls.scopeType === 'Dba') {
                    // sRate = "";
                }
                let adrTotal = dataObj.ADR;
                if (ui_state.active_tiles[0] === "market_summary" || ui_state.active_tiles[0] === "hotel_summary") {
                    daysCount = dataSet.length;
                    sRate = round(dataObj.SellRate / daysCount);
                }


                let occTotal = type === 'Total' ? dataObj.Occ / daysCount : dataObj.Occ;
                // let ratediffTotal = type === 'Total' ? round(dataObj.ratediff / daysCount): dataObj.ratediff;
                // let occTotal = type === 'Total' ? dataObj.Occ / daysCount : dataObj.Occ;

                if (typeof dataObj.Occ !== "string" && typeof dataObj.RevPAR !== "string") {
                    var newValues = {
                        Occ: occTotal,
                        RevPAR: round(dataObj.RevPAR / daysCount),
                        SellRate: sRate,
                        ratediff: round(ratediff / daysCount),
                        RecSellRate: round(RecSellRate / daysCount),
                        Revenue: round(dataObj.Revenue),
                        ADR: round(dataObj.ADR)
                    }
                    dataObj = { ...dataObj, ...newValues };
                }
                if (
                    (dataObj.id && dataObj.id === "compset_medium" ||
                        dataObj.id && dataObj.id === "market_sell_three_star" ||
                        dataObj.id && dataObj.id === "market_sell_four_star" ||
                        dataObj.id && dataObj.id === "market_sell_five_star") &&
                    type === 'segmentTotal'
                ) {
                    dataArray.push(dataSet[0][j]);
                }
                else {
                    dataArray.push(dataObj);
                }
                dataObj = {};

            }
        }

        let diffRpickupIndex = dataArray.findIndex((it) => it.id === "otb_remaing_pickup_sdly_x");
        if (diffRpickupIndex >= 0) {
            let currentIndex = dataArray.findIndex((it) => it.id === "otb_actual_sdly");
            let sdlyIndex = dataArray.findIndex((it) => it.id === "otb_sdly");
            dataArray[diffRpickupIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
        }

        let diffIndex = dataArray.findIndex((it) => it.id === "otb_diff_sdly");
        if (diffIndex >= 0) {
            let currentIndex = dataArray.findIndex((it) => it.id === "otb_current");
            let sdlyIndex = dataArray.findIndex((it) => it.id === "otb_sdly");
            dataArray[diffIndex].ADR = dataArray[currentIndex].ADR - dataArray[sdlyIndex].ADR;
        }

        let marketDiffIndex = dataArray.findIndex((it) => it.id === "diff_market_sdly");
        if (marketDiffIndex >= 0) {
            let marketPrimaryIndex = dataArray.findIndex((it) => it.id === "market_otb");
            let marketSdlyIndex = dataArray.findIndex((it) => it.id === "market_sdly");
            dataArray[marketDiffIndex].Occ = dataArray[marketPrimaryIndex].Occ - dataArray[marketSdlyIndex].Occ;
            dataArray[marketDiffIndex].Rooms = dataArray[marketPrimaryIndex].Rooms - dataArray[marketSdlyIndex].Rooms;
            dataArray[marketDiffIndex].ADR = dataArray[marketPrimaryIndex].ADR - dataArray[marketSdlyIndex].ADR;
            dataArray[marketDiffIndex].RevPAR = dataArray[marketPrimaryIndex].RevPAR - dataArray[marketSdlyIndex].RevPAR;
            dataArray[marketDiffIndex].Revenue = dataArray[marketPrimaryIndex].Revenue - dataArray[marketSdlyIndex].Revenue;
            dataArray[marketDiffIndex].SellRate = dataArray[marketPrimaryIndex].SellRate - dataArray[marketSdlyIndex].SellRate;
        }

        let marketVarSdly = dataArray.findIndex((it) => it.id === "market_variance_sdly");
        if (marketVarSdly >= 0) {
            let marketdIndex = dataArray.findIndex((it) => it.id === "diff_market_sdly");
            let marketSdlyIndex = dataArray.findIndex((it) => it.id === "market_sdly");
            dataArray[marketVarSdly].Occ = ((dataArray[marketdIndex].Occ / dataArray[marketSdlyIndex].Occ) * 100).toFixed(2);
            dataArray[marketVarSdly].Rooms = ((dataArray[marketdIndex].Rooms / dataArray[marketSdlyIndex].Rooms) * 100).toFixed(2);
            dataArray[marketVarSdly].ADR = ((dataArray[marketdIndex].ADR / dataArray[marketSdlyIndex].ADR) * 100).toFixed(2);
            dataArray[marketVarSdly].RevPAR = ((dataArray[marketdIndex].RevPAR / dataArray[marketSdlyIndex].RevPAR) * 100).toFixed(2);
            dataArray[marketVarSdly].Revenue = ((dataArray[marketdIndex].Revenue / dataArray[marketSdlyIndex].Revenue) * 100).toFixed(2);
            dataArray[marketVarSdly].SellRate = ((dataArray[marketdIndex].SellRate / dataArray[marketSdlyIndex].SellRate) * 100).toFixed(2);
        }

        let sdlyDiffIndex = dataArray.findIndex((it) => it.id === "sdly_diff_last_year");
        if (sdlyDiffIndex >= 0) {
            let marketSdlyPrimaryIndex = dataArray.findIndex((it) => it.id === "market_sdly");
            let marketSdlyActualIndex = dataArray.findIndex((it) => it.id === "market_actual_sdly");
            dataArray[sdlyDiffIndex].ADR = dataArray[marketSdlyPrimaryIndex].ADR - dataArray[marketSdlyActualIndex].ADR;
        }

        let hotelSummaryDiffIndex = dataArray.findIndex((it) => it.id === "hotel_summary_sdly_variance");
        if (hotelSummaryDiffIndex >= 0) {
            let from = dataArray.findIndex((it) => it.id === "hotel_summary_otb");
            let to = dataArray.findIndex((it) => it.id === "hotel_summary_sdly_otb");
            dataArray[hotelSummaryDiffIndex].ADR = dataArray[from].ADR - dataArray[to].ADR;
        }

        let marketSdlyVar = dataArray.findIndex((it) => it.id === "sdly_var_market_last_year");
        if (marketSdlyVar >= 0) {
            let sdly_diff_last_year = dataArray.findIndex((it) => it.id === "sdly_diff_last_year");
            let market_actual_sdly = dataArray.findIndex((it) => it.id === "market_actual_sdly");
            dataArray[marketSdlyVar].Occ = ((dataArray[sdly_diff_last_year].Occ / dataArray[market_actual_sdly].Occ) * 100).toFixed(2);
            dataArray[marketSdlyVar].Rooms = ((dataArray[sdly_diff_last_year].Rooms / dataArray[market_actual_sdly].Rooms) * 100).toFixed(2);
            dataArray[marketSdlyVar].ADR = ((dataArray[sdly_diff_last_year].ADR / dataArray[market_actual_sdly].ADR) * 100).toFixed(2);
            dataArray[marketSdlyVar].RevPAR = ((dataArray[sdly_diff_last_year].RevPAR / dataArray[market_actual_sdly].RevPAR) * 100).toFixed(2);
            dataArray[marketSdlyVar].Revenue = ((dataArray[sdly_diff_last_year].Revenue / dataArray[market_actual_sdly].Revenue) * 100).toFixed(2);
            dataArray[marketSdlyVar].SellRate = ((dataArray[sdly_diff_last_year].SellRate / dataArray[market_actual_sdly].SellRate) * 100).toFixed(2);
            // dataArray[marketDiffIndex].ADR = dataArray[marketPrimaryIndex].ADR - dataArray[marketSdlyIndex].ADR;
        }

        let otbVarSdly = dataArray.findIndex((it) => it.id === "otb_variance_sdly");
        if (otbVarSdly >= 0) {
            let otbDiffCurrent = dataArray.findIndex((it) => it.id === "otb_diff_sdly");
            let otbSdly = dataArray.findIndex((it) => it.id === "otb_sdly");
            dataArray[otbVarSdly].Occ = ((dataArray[otbDiffCurrent].Occ / dataArray[otbSdly].Occ) * 100).toFixed(2);
            dataArray[otbVarSdly].Rooms = ((dataArray[otbDiffCurrent].Rooms / dataArray[otbSdly].Rooms) * 100).toFixed(2);
            dataArray[otbVarSdly].ADR = ((dataArray[otbDiffCurrent].ADR / dataArray[otbSdly].ADR) * 100).toFixed(2);
            dataArray[otbVarSdly].RevPAR = ((dataArray[otbDiffCurrent].RevPAR / dataArray[otbSdly].RevPAR) * 100).toFixed(2);
            dataArray[otbVarSdly].Revenue = ((dataArray[otbDiffCurrent].Revenue / dataArray[otbSdly].Revenue) * 100).toFixed(2);
            dataArray[otbVarSdly].SellRate = ((dataArray[otbDiffCurrent].SellRate / dataArray[otbSdly].SellRate) * 100).toFixed(2);

        }

        let paceDiff = dataArray.findIndex((it) => it.id === "bc_date_diff");
        if (paceDiff >= 0) {
            let from = dataArray.findIndex((it) => it.id === "booking_curve");
            let to = dataArray.findIndex((it) => it.id === "comparison_pace");
            dataArray[paceDiff].ADR = dataArray[from].ADR - dataArray[to].ADR;
        }

        // if (ui_state.active_tiles[0] === "pace" && dataArray[1]) {
        //     let paceAdrDiff = dataArray[0]["ADR"] - dataArray[1]["ADR"];
        //     let paceRevparDiff = dataArray[0]["RevPAR"] - dataArray[1]["RevPAR"];

        //     dataArray.forEach((dataItem, i) => {
        //         if (i === 2) {
        //             dataArray[i].ADR = paceAdrDiff;
        //             dataArray[i].RevPAR = paceRevparDiff;
        //         }
        //     })
        // }

        if (type === "Total") {
            dataArray = otbTransformTotal(dataArray, ui_state.active_tiles, workspace_controls, dataSet);

        }

        return dataArray;
    });


    const compsetTotal = (obj, key, index, count) => {
        let total = 0;
        let daysInMonth = moment().daysInMonth();
        let totalDays = 0;
        for (let i = 0; i < obj.length; i++) {
            if (obj[i][count] && obj[i][count][key]) {
                total = parseFloat(total + obj[i][count][key]);
                totalDays++;
            }
        }
        total = (app_state.workspace_controls.scopeType !== 'Dba') ? round(total / totalDays) : round(total / 366);
        total = (isNaN(total)) ? 0 : total;
        return displayDataFormat(total.toFixed(2), key, '', 'compset_medium');
    };

    // const compsetTotal = (obj, key, index, count) => {
    //     let total = 0;
    //     let daysInMonth = moment().daysInMonth();
    //     let totalDays = 0;
    //     for (let i = 0; i < obj.length; i++) {
    //         total = parseFloat(total + obj[i][count][key]);
    //         totalDays++;
    //     }
    //     total = (app_state.workspace_controls.scopeType !== 'Dba') ? round(total / totalDays) : round(total / 366);
    //     return displayDataFormat(total.toFixed(2), 'compset', '');
    // };




    const displayDataFormat = useCallback((val, key, type, col_id) => {
        // if(!isFinite(val)){ val = "0.00"; }

        key = key.toLowerCase();
        let value = val;
        let currSymbol = ui_state.currency_symbol;
        if (isNaN(val)) {
            // console.log(key, val)
        }
        if (col_id === "pricing" || col_id === "compset_medium" || col_id === "market_sell_three_star" || col_id === "market_sell_four_star" || col_id === "market_sell_five_star") {
            // if (col_id === "pricing" || col_id === "market_sell_three_star" || col_id === "market_sell_four_star" || col_id === "market_sell_five_star") {
            if (val === "" || !val) {
                return "";
            }
        }
        if (val !== '' && key !== 'segment' && key !== 'compdate' && key !== 'restrictions') {
            if (key.toLowerCase() !== "rooms" && key.toLowerCase() !== "dba" && key.toLowerCase() !== "revenue") {
                val = Number.isInteger(val) ? val.toFixed(2) : val;
            }
            if (type === "") {
                if (key === "date") {
                    val = moment(val).format("MMM DD");
                } else if (key === "dow" || key === "date_market_summary" || key === "month" || key === "dba") {
                    val = val;
                } else if (key === "occ" || key === "tcocc") {
                    val = parseFloat(val).toFixed(2) + "%";
                } else if (key === "recsellrate") {
                    val = currSymbol + Math.round(val);
                } else if (key === "sellrate") {
                    if (val && !val.toString().includes("%")) {
                        val = currSymbol + Math.round(val);
                    } else {
                        val = val;//currSymbol + Math.round(val);
                    }
                } else if ((key === "adr" || key === "revpar" || key === "sellrate") && val !== "") {
                    if (val) {
                        if (!val.toString().includes("%") && (key.toLowerCase() === "sellrate" || key.toLowerCase() === "adr" || key.toLowerCase() === "revpar")) {
                            val = parseFloat(val);
                        }
                    }
                    if (typeof val !== "string" && (key === "sellrate" || key === "adr" || key === "revpar")) {
                        val = (!isFinite(val)) ? parseFloat(0.00).toFixed(2) : parseFloat(val).toFixed(2);
                        val = currSymbol + (val).toLocaleString(undefined, { minimumFractionDigits: 2 });
                    } else {
                        val = val;
                    }

                } else if (key === "revenue") {
                    if (typeof val === "string") {
                        currSymbol = "";
                    }

                    val = (val) ? (val).toLocaleString(undefined, { minimumFractionDigits: 2 }) : parseFloat(0.00).toFixed(2);
                    val = currSymbol + val;
                    // val =  (val && !NaN(val)) ? (val).toLocaleString(undefined, { minimumFractionDigits: 2 }) : "";

                } else if (key === "rooms") {
                    val = (val) ? val.toLocaleString() : 0;
                } else {
                    val = currSymbol + val;
                }
            } else if (type !== "" && typeof val !== "string") {
                val = val + "%";
            }
            if (key !== "rooms" && key !== "dba") {

                if (value >= 0 || typeof value === "string") {
                    val = val;
                } else {
                    if (Number.isFinite(value)) {
                        val = "(" + val.toString().replaceAll("-", "") + ")";
                    } else {
                        val = currSymbol + parseFloat(0.00).toFixed(2);
                    }
                }

            }
        }
        if (key === 'segment') {
            if (val) {
                val = val; //truncate(val, 11);
            } else {
                val = "";
            }
        }
        if (key === 'restrictions') {
            val = ""
        }
        if (key === 'compdate') {
            // let compVal =  dateFormat(val, "MMM DD, YYYY ddd");
            // val = (val) ? dateFormat(val, "MMM DD YYYY,  ddd") : '';
            val = (val && (moment(val, "YYYY/MM/DD", true).isValid() || moment(val, "YYYY-MM-DD", true).isValid())) ? dateFormat(val, "MMM DD YYYY,  ddd") : '';

            ;
        }
        if (key.includes("pricing_")) {
            if (val === "") {
                return "";
            }
        }

        val = (val !== 'Infinity%') ? val : "0.00%";
        if (col_id && col_id === "compset_medium") {
            let compsetValue = parseInt(removeCurrSymbol(val, currSymbol));
            compsetValue = (compsetValue && compsetValue > 0) ? currSymbol + compsetValue : "";
            return compsetValue;
        }

        return val;
    });


    const totalCalulate = (obj, key, index, count) => {
        let dataIndex = index + 1;
        var total = 0;
        let symbol = "$";
        var totalRevenue = 0;
        for (let i = 0; i < obj.length; i++) {
            let dataInnerObj = obj[i][count];
            totalRevenue = parseFloat(total) + parseFloat(dataInnerObj.Revenue);
            if (key.toLowerCase() === "rooms") {
                symbol = "";
                total = parseFloat(total) + parseFloat(dataInnerObj.Rooms);
            }
            if (key.toLowerCase() === "occ") {
                symbol = "%";
                total = (parseFloat(total) + parseFloat(dataInnerObj.Occ)).toFixed(2);
            }
            if (key.toLowerCase() === "adr") {
                total = (parseFloat(total) + parseFloat(dataInnerObj.ADR)).toFixed(2);
            }
            if (key.toLowerCase() === "revpar") {
                total = (parseFloat(total) + parseFloat(dataInnerObj.RevPAR)).toFixed(
                    2
                );
            }
            if (key.toLowerCase() === "revenue") {
                total = (parseFloat(total) + parseFloat(dataInnerObj.Revenue));
            }
            if (key.toLowerCase() === "sellrate" && dataInnerObj.SellRate !== "") {
                total = parseFloat(total) + parseFloat(dataInnerObj.SellRate);
            }
        }

        if (
            key.toLowerCase() === "adr" ||
            key.toLowerCase() === "occ" ||
            key.toLowerCase() === "sellrate" ||
            key.toLowerCase() === "revpar"
        ) {
            total = (total / 12);
        }
        return displayDataFormat(round(total), key, "");
    };

    const calculateColWidth = (tile) => {
        let colWidth = [];
        let dataColumn = (tile === "compset") ? 20 : 12;
        for (let i = 1; i <= data_cols.length; i++) {
            if (i <= 2) {
                colWidth.push(10);
            } else {
                colWidth.push(dataColumn)
            }
        }
        return (colWidth.toString()).trim();
    }
    let start = moment().format('x');

    let tableContClass = "table-scrollable ";

    //Horizontal scrolling jumping issue fixed on sorting columns
    if (['loaded', 'sortedCols'].includes(compLoadStatus) && compLoad && displayData.length) {
        let newRenderBodyData = data_setup.length ? renderBody({ data_setup: data_setup }) : [];
        setRenderBodyData(newRenderBodyData);
        setCompLoadStatus('loaded..');
    }

    const downloadReportToExcel_xx = (selectedPropertyName) => {
        let exportFileName = moment(app_state.as_of_date).format('MMM DD YYYY') + "_" + selectedPropertyName + "_" + active_tiles[0];
        if (app_state.workspace_controls.downloadExportButton === true
            && compRef.current.data_setup) {
            let sheetName = active_tiles[0] ? active_tiles[0] : " ";
            const { current } = compRef;
            const table = document.createElement('table');
            const header = document.querySelector("#mainTable .table-head").cloneNode(true)  //generateTableHead(table,colKeys)
            table.append(header);
            let data = [];
            let columnNodes = document.querySelectorAll(".filter-position-r .col-head-label");
            let colNames = [];
            columnNodes.forEach(item => {
                colNames.push(item.textContent);
            })
            let colNameHash = {};
            current.columns.forEach(col => {
                col.subcolumns.forEach(subcol => {
                    if (colNames.includes(subcol.display)) {
                        colNameHash[subcol.value] = subcol.display;
                    }
                })
            })
            // compRef.current.
            let totalArrObj = [{
                TotalRow: "TotalRow",
                id: 'TotalRow'
            }]

            let data_setupExcel = deepCopy(data_setup);
            data_setupExcel.push(totalArrObj);
            let totalDataLength = data_setupExcel.length;
            data_setupExcel.forEach((dItem, idx) => {
                let dataPoint = {};
                let rowDate = dItem[0].date;
                dItem.forEach((item, index) => {
                    if (item.hasOwnProperty("Restrictions")) {
                        let restrictionData = getRestrictionAll(rowDate, 'comma', 'cur')
                        item["Restrictions"] = (restrictionData) ? restrictionData : '';
                    }
                    Object.keys(item).forEach(key => {
                        if (key && key !== 'id' && key !== 'TotalRow' && key !== 'ignore_sort') {
                            // let value = item[key];
                            let value = (item[key]) ? item[key] : 0;

                            if (key === 'Total') {
                                console.log(item[key]);
                            }
                            if (value === true) {
                                value = "true"
                            }
                            if (value === null || value === "") {
                                value = "";
                            }
                            else if (!isNaN(value)) {

                                value = (value && value !== "undefined") ? round(value) : '';

                            }
                            dataPoint[key + "-" + index] = value;
                        }
                    })
                })
                console.log("totalRowsData ", totalRowsData);

                if (dItem[0].TotalRow === "TotalRow") {
                    dItem = data_setupExcel[0];
                    let count = 0;
                    dItem.forEach((item, index) => {
                        Object.keys(item).forEach((key, keyIndex) => {
                            if (key !== 'id') {
                                let value = item[key];
                                if (key === "date") {
                                    value = "Total";
                                } else if (key === "dow") {
                                    value = "";
                                } else if (key.toLocaleLowerCase() === "segment") {
                                    value = "";
                                } else {
                                    value = (totalRowsData[count]) ? totalRowsData[count] : '';
                                    count++;
                                }
                                dataPoint[key + "-" + index] = value;
                            }
                        })
                    })
                }

                data.push(dataPoint);

            })
            const tBody = generateTable(table, data);

            if (app_state.workspace_controls.exportType == 'csv') {
                downloadcsvwithTable(table, exportFileName, compRef.current.columns);
            } else {
                TableToExcel.convert(table, {
                    name: exportFileName + ".xlsx",
                    sheet: {
                        name: sheetName.toUpperCase()
                    }
                });
            }

        }
    }

    const hasTotalRow = (app_state, ui_state) => {
        if (ui_state.active_tiles[0] === "compset" &&
            app_state.workspace_controls.scopeType === "Dba") {
            return false;
        }
        if (ui_state.active_tiles[0] === "market" &&
            app_state.workspace_controls.scopeType === "Dba") {
            return false;
        }
        if (ui_state.active_tiles[0] === "pace" &&
            app_state.workspace_controls.scopeType === "Dba") {
            return false;
        }
        if (in_array(ui_state.active_tiles, ["flights"])) {
            return false;
        }
        return true
    }

    const downloadReportToExcel = (app_state, ui_state, selectedPropertyName) => {
        let exportFileName = moment(app_state.as_of_date).format('MMM DD YYYY') + "_" + selectedPropertyName + "_" + ui_state.active_tiles[0];
        if (app_state.workspace_controls.downloadExportButton === true
            && compRef.current.data_setup) {
            let sheetName = active_tiles[0] ? active_tiles[0] : " ";
            const { current } = compRef;
            const table = document.createElement('table');
            const header = document.querySelector("#mainTable .table-head").cloneNode(true)  //generateTableHead(table,colKeys)

            if (!in_array(ui_state.active_tiles, ["flights", "hotel_summary", "market_summary"])) {
                if (workspace_controls.scopeType !== 'Dba') {

                    // Add same style for row 1 for Notes and Events
                    const firstRow = header.querySelectorAll('tr')[0];
                    if (firstRow) {
                        // Add the Notes column heading to the first row of the header
                        const newTh = firstRow.querySelector('th').cloneNode(true);
                        newTh.textContent = 'Notes';
                        newTh.setAttribute('colspan', '2');
                        if (!sdlyOn) {
                            newTh.removeAttribute('colspan');
                        }
                        firstRow.appendChild(newTh);

                        // Add the Events column heading to the first row of the header
                        const eventsTh = firstRow.querySelector('th').cloneNode(true);
                        eventsTh.textContent = 'Events';
                        eventsTh.setAttribute('colspan', '2');
                        if (!sdlyOn) {
                            eventsTh.removeAttribute('colspan');
                        }
                        firstRow.appendChild(eventsTh);
                    }

                    const secondRow = header.querySelectorAll('tr')[1];
                    if (secondRow) {
                        // Add the new "Note TY" column heading to the second row of the header
                        const newTh = secondRow.querySelector('th').cloneNode(true); // Clone an existing th to retain styles
                        newTh.textContent = 'Note TY';
                        secondRow.appendChild(newTh);

                        // Add the new "Note SDLY" column heading to the second row of the header
                        if (sdlyOn) {
                            const secondRow2 = header.querySelectorAll('tr')[1];
                            if (secondRow2) {
                                const newTh = secondRow2.querySelector('th').cloneNode(true); // Clone an existing th to retain styles
                                newTh.textContent = dayDate === 'day' ? 'Note SDLY' : 'Note SDTLY';
                                secondRow2.appendChild(newTh);
                            }
                        }

                        // Add the new "Event TY" column heading to the second row of the header
                        const eventsTh2 = secondRow.querySelector('th').cloneNode(true);
                        eventsTh2.textContent = 'Event TY';
                        secondRow.appendChild(eventsTh2);

                        // Add the new "Event SDLY" column heading to the second row of the header
                        if (sdlyOn) {
                            const secondRow2 = header.querySelectorAll('tr')[1];
                            if (secondRow2) {
                                const eventsTh2 = secondRow2.querySelector('th').cloneNode(true); // Clone an existing th to retain styles
                                eventsTh2.textContent = dayDate === 'day' ? 'Event SDLY' : 'Event SDTLY';
                                secondRow2.appendChild(eventsTh2);
                            }
                        }
                    }

                }
            }


            table.append(header);
            let data = [];
            let columnNodes = document.querySelectorAll(".filter-position-r .col-head-label");
            let colNames = [];
            columnNodes.forEach(item => {
                colNames.push(item.textContent);
            })
            let colNameHash = {};
            current.columns.forEach(col => {
                col.subcolumns.forEach(subcol => {
                    if (colNames.includes(subcol.display)) {
                        colNameHash[subcol.value] = subcol.display;
                    }
                })
            })
            let totalArrObj = [];
            if (hasTotalRow(app_state, ui_state)) {
                totalArrObj.push({
                    TotalRow: "TotalRow",
                    id: 'TotalRow'
                })
            } else {
                totalArrObj.push({

                })
            }
            let data_setupExcel = deepCopy(data_setup);
            data_setupExcel.push(totalArrObj);
            let totalDataLength = data_setupExcel.length;
            data_setupExcel.forEach((dItem, idx) => {
                let dataPoint = {};
                let rowDate = dItem[0].date;
                dItem.forEach((item, index) => {
                    if (item.hasOwnProperty("Restrictions")) {
                        let restrictionData = (getRestrictionAll(rowDate, 'comma', 'cur'))
                        item["Restrictions"] = (restrictionData) ? getRestrictionAll(rowDate, 'comma', 'cur') : '';
                        item = preferredOrder(item, ['Restrictions']);

                    }
                    if (active_tiles[0] === 'hotel_summary') {
                        delete item['date_market_summary'];
                    }
                    if (item.id === "forecast_primary") {
                        delete item.SellRate;
                        delete item.RecSellRate;
                        delete item.ratediff;
                    }

                    if (!ui_state.active_tiles.includes("pace")) {

                        if (item.id === "pricing") {

                            delete item.stay_date;
                            delete item.Explainer;
                            item = { SellRate: item.SellRate, RecSellRate: item.RecSellRate, ratediff: item.ratediff }
                        } else {
                            item = (item.hasOwnProperty('Rooms')) ? preferredOrder(item, ['Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue', 'SellRate']) : item;

                        }
                    }

                    if (item.id === "compset_medium") {
                        delete item.index;
                    }


                    if (ui_state.active_tiles.includes("pace")) {
                        // if(item.hasOwnProperty('Rooms')){
                        //     item = preferredOrder(item, ['Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue']);
                        // }else if(item.hasOwnProperty('compdate')){
                        //     item = preferredOrder(item, ['compdate','Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue']);
                        // }else{
                        //     item = item;
                        // } 
                        if (item.id === "booking_curve" ||
                            item.id === "comparison_pace" ||
                            item.id === "bc_date_diff"
                        ) {
                            item = (item.hasOwnProperty('Rooms')) ? preferredOrder(item, ['compdate', 'Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue']) : item;

                        } else {
                            if (item.id === "pricing") {

                                delete item.stay_date;
                                delete item.Explainer;
                                item = { SellRate: item.SellRate, RecSellRate: item.RecSellRate, ratediff: item.ratediff }
                            } else {
                                item = (item.hasOwnProperty('Rooms')) ? preferredOrder(item, ['Occ', 'Rooms', 'ADR', 'RevPAR', 'Revenue', 'SellRate']) : item;

                            }
                        }

                    }


                    Object.keys(item).forEach(key => {

                        if (key !== 'id' && key !== 'TotalRow' && key !== index &&
                            key !== 'ignore_sort' && key !== 'segment_code' && colNameHash[key]) {
                            let value = (item[key]); //? item[key] : 0;
                            value = (key === 'compdate' && value !== "") ? moment(value).format('YYYY/MM/DD') : value;
                            if (value === true) {
                                value = "true"
                            }
                            if (value === Infinity || value === -Infinity) {
                                value = 0;
                            }
                            if (typeof value === "string" && value.indexOf("Infinity") !== -1) {
                                value = value.replace("Infinity", "0");
                            }
                            if (value === null || value === "" || value === "NaN" || value === "NaN%") {
                                if (value === "NaN%") {
                                    value = '0%';
                                }
                                else {
                                    value = 0;
                                }
                                if (key === 'dow' || key === 'date' || key === "compdate") {
                                    value = "";
                                }
                            } else {
                                value = (value) ? value : 0;
                            }
                            dataPoint[key + "-" + index] = value;
                        }
                    })
                })

                if (!in_array(ui_state.active_tiles, ["flights", "hotel_summary", "market_summary"])) {
                    if (workspace_controls.scopeType !== 'Dba') {
                        if (segmented_view) {
                            // Add the new columns here if the row is Total
                            if (dItem[0].segment === "Total") {
                                dataPoint["Note TY"] = calculateNewColumnValue(dItem);
                                if (sdlyOn) {
                                    if (dayDate === 'day') {
                                        dataPoint["Note SDLY"] = calculateNewColumnValue2(dItem);
                                    } else {
                                        dataPoint["Note SDTLY"] = calculateNewColumnValue2(dItem);
                                    }
                                }

                                dataPoint["Event TY"] = calculateEventTY(dItem);
                                if (sdlyOn) {
                                    if (dayDate === 'day') {
                                        dataPoint["Event SDLY"] = calculateEventSDLY(dItem);
                                    } else {
                                        dataPoint["Event SDTLY"] = calculateEventSDLY(dItem);
                                    }
                                }
                            }
                        }
                        else {
                            // Add the new columns here if the row is not Total
                            if (!dItem[0].TotalRow) {
                                dataPoint["Note TY"] = calculateNewColumnValue(dItem);
                                if (sdlyOn) {
                                    if (dayDate === 'day') {
                                        dataPoint["Note SDLY"] = calculateNewColumnValue2(dItem);
                                    } else {
                                        dataPoint["Note SDTLY"] = calculateNewColumnValue2(dItem);
                                    }
                                }

                                dataPoint["Event TY"] = calculateEventTY(dItem);
                                if (sdlyOn) {
                                    if (dayDate === 'day') {
                                        dataPoint["Event SDLY"] = calculateEventSDLY(dItem);
                                    } else {
                                        dataPoint["Event SDTLY"] = calculateEventSDLY(dItem);
                                    }
                                }
                            }
                        }
                    }
                }

                if (dItem[0].TotalRow === "TotalRow") {
                    dItem = data_setupExcel[0];
                    let count = 0;
                    dItem.forEach((item, index) => {
                        if (active_tiles[0] === 'hotel_summary') {
                            delete item['date_market_summary'];
                        }
                        Object.keys(item).forEach((key, keyIndex) => {
                            if (key !== 'id' && key !== 'ignore_sort' && key !== 'segment_code') {
                                let value = item[key];
                                if (key === "date" || key === "date_market_summary" || key === "month") {
                                    value = "Total";
                                } else if (key === "dow") {
                                    value = "";
                                } else if (key.toLocaleLowerCase() === "segment") {
                                    value = "";
                                } else {
                                    if (totalRowsData[count]) {
                                        value = totalRowsData[count];
                                    }
                                    else {
                                        if (key === "compdate") {
                                            value = "";
                                        }
                                        else {
                                            value = 0;
                                        }
                                    }
                                    count++;
                                }
                                dataPoint[key + "-" + index] = value;
                            }
                        })
                    })
                }

                data.push(dataPoint);

            })
            const tBody = generateTable(table, data);
            if (app_state.workspace_controls.exportType == 'csv') {
                downloadcsvwithTable(table, exportFileName, compRef.current.columns);
            } else {
                TableToExcel.convert(table, {
                    name: exportFileName + ".xlsx",
                    sheet: {
                        name: sheetName.toUpperCase()
                    }
                });
            }
        }
    }

    const calculateNewColumnValue = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        //const formatted_comp_date = sdlyOn ? dateFormat(getStayToSdly(app_state, formatted_stay_date), "YYYY-MM-DD") : dateFormat(getStayToComp(app_state, formatted_stay_date), "YYYY-MM-DD");
        let dataForDate = notes && notes.find(item => item.stay_date === formatted_stay_date);
        let current_stay_date = dateFormat(formatted_stay_date, "YYYY/MM/DD")
        //let current_comp_date = dateFormat(formatted_comp_date, "YYYY-MM-DD")

        if (!dataForDate) {
            dataForDate = {
                note: '',
                last_edited_by: '',
                last_edited_at: ''
            }
        }
        let noteData = "";
        if (dataForDate.note !== '') {
            noteData = current_stay_date + " - " + dataForDate.note;
        }
        return noteData
    }
    const calculateNewColumnValue2 = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        const formatted_comp_date = formattedCompDateCalc(sdlyOn, formatted_stay_date, app_state);
        // let formatted_comp_date = ''
        // if(sdlyOn && !workspace_controls.clearComparision){
        //     formatted_comp_date = dateFormat(getStayToSdly(app_state, formatted_stay_date), "YYYY-MM-DD");
        // }
        // if(!sdlyOn && workspace_controls.clearComparision){
        //     formatted_comp_date = dateFormat(getStayToComp(app_state, formatted_stay_date), "YYYY-MM-DD")
        // }
        let dataForDate = notes && notes.find(item => item.stay_date === formatted_comp_date);
        let current_stay_date = dateFormat(formatted_comp_date, "YYYY/MM/DD")
        //let current_comp_date = dateFormat(formatted_comp_date, "YYYY-MM-DD")

        if (!dataForDate) {
            dataForDate = {
                note: '',
                last_edited_by: '',
                last_edited_at: ''
            }
        }
        let noteData = "";
        if (dataForDate.note !== '') {
            noteData = current_stay_date + " - " + dataForDate.note;
        }
        return noteData
    }
    const calculateEventTY = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        let current_stay_date = dateFormat(formatted_stay_date, "YYYY/MM/DD")

        const filteredEventList = eventList.filter(event =>
            isDateInRange(formatted_stay_date, event.start_date, event.end_date) && event.is_ignored === false
        );

        let EventData = filteredEventList.map(event => event.title).join(", ");

        return EventData
    }
    const calculateEventSDLY = (dItem) => {
        const formatted_stay_date = dateFormat(dItem[0].date, "YYYY-MM-DD");
        const formatted_comp_date = formattedCompDateCalc(sdlyOn, formatted_stay_date, app_state);
        let current_stay_date = dateFormat(formatted_comp_date, "YYYY/MM/DD")

        const filteredEventList = eventListSDLY.filter(event =>
            isDateInRange(formatted_comp_date, event.start_date, event.end_date) && event.is_ignored === false
        );

        let EventData = filteredEventList.map(event => event.title).join(", ");

        return EventData
    }


    const tableSkeletonLoader = (
        <Box p={0} m={0}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="table-view-scaleton-loader">
                <TableHead sx={{ background: "#606060", opacity: "0.4" }} className="table-head">
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell sx={{ width: "15%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "70%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "15%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                </TableHead>
            </Table>

            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="table-view-scaleton-loader">
                <TableHead sx={{ background: "#01A4EF", opacity: "0.3" }} className="table-head">
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell sx={{ background: "#B2B2B2", width: "7.5%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ background: "#B2B2B2", width: "7.5%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "10vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "25vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "30vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody className="table-body">

                    <TableRow >
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow sx={containerHeight > 700 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                    <TableRow sx={containerHeight > 700 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                    <TableRow sx={containerHeight > 700 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow sx={containerHeight > 750 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow sx={containerHeight > 800 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                    <TableRow sx={containerHeight > 830 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow sx={containerHeight > 850 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow sx={containerHeight > 890 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                    <TableRow sx={containerHeight > 950 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow sx={containerHeight > 1000 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                    <TableRow sx={containerHeight > 1020 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                    <TableRow sx={containerHeight > 1050 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                    <TableRow sx={containerHeight > 1080 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow sx={containerHeight > 1100 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                    <TableRow sx={containerHeight > 1130 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                    <TableRow sx={containerHeight > 1170 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow sx={containerHeight > 1200 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                    <TableRow sx={containerHeight > 1240 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow sx={containerHeight > 1280 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                    <TableRow sx={containerHeight > 1300 ? { display: "all" } : { display: "none" }}>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>

                </TableBody>
            </Table>

            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="table-view-scaleton-loader">
                <TableHead sx={{ background: "#01A4EF", opacity: "0.3" }} className="table-head">
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell sx={{ width: "15%" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "10vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "25vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell sx={{ width: "30vh" }} align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                    </TableRow>
                </TableHead>
            </Table>

        </Box>
    );
    const TableComponentsValue = useCallback(TableComponents(tableContClass, active_tiles_className, calculateColWidth, data_setup), [tableContClass, active_tiles_className, data_setup])

    return (

        <Paper sx={{ width: "100%" }} elevation={0}>
            {compLoadStatus == 'loading' && <Box item lg={12} sx={{
                pb: 0, position: "absolute", left: "calc(50% - 39px)"
                , top: "calc(50% - 95.15px)"
            }}>
                <Box sx={{ pb: 0 }}> <Spinner /></Box>
            </Box>}
            {/* <button onClick={onDownload} className="primary"> Download</button> */}

            {
                (compLoadStatus === 'sortingCols' && compLoad && displayData.length && data_setup.length) ? <Alert sx={{ position: 'absolute', width: 'calc(100% - 8px)', zIndex: 999, textAlign: 'center', opacity: 0.95, borderRadius: 0, lineHeight: 1, p: 0, pl: 1, left: 0, top: 70 }} severity="info" color='info' textAlign='center' icon={false}>Sorting data...</Alert> : ''
            }
            {compLoadStatus === 'loaded..' && <TableVirtuoso
                ref={tableVirtuoso}
                data={renderBodyData.length ? new Array(renderBodyData.length - 1) : [1]}
                components={TableComponentsValue}
                fixedHeaderContent={() => (
                    renderHeader()
                )}
                fixedFooterContent={!(renderBodyData.length > 2) ? () => (<></>) : () => (<TableRow sx={{
                    position: "sticky",
                    bottom: 0,
                    zIndex: 3,
                    backgroundColor: "#fff",
                }}
                    className="table-row footer-row">{renderBodyData.length ? renderBodyData[renderBodyData.length - 1] : []}</TableRow>)}

                itemContent={!renderBodyData.length ? () => (<TableCell colSpan={8} sx={{ p: 0, borderBottom: 'none', position: 'absolute', width: '100%' }}>
                    <Alert severity="info" sx={{ backgroundColor: "#fff" }}>
                        <AlertTitle>No data found</AlertTitle>
                    </Alert>
                </TableCell>) : (index) => (
                    <>
                        {
                            (compLoadStatus !== 'loading' && compLoad && displayData.length && data_setup.length) ? renderBodyData[index] : ''
                        }
                    </>
                )}
            />}

            {compLoadStatus === 'loading' && <div className="table-scrollable">
                <>
                    <TableLottieLoader />
                    {tableSkeletonLoader}
                </>
            </div>}
            {compLoadStatus === 'loading____' && <TableContainer className="table-scrollable">
                <Table
                    stickyHeader
                    size="small"
                    aria-label="a dense table"
                    className={active_tiles_className + " react-dynamic-table table-common"}
                    id="mainTable"
                    data-cols-width={calculateColWidth(ui_state.active_tiles[0])}
                >
                    {renderHeader()}

                    {/* {console.log("time taken renderBody", moment().format('x') - start)} */}

                    <tbody className="table-body">

                        {
                            (compLoadStatus === 'sortingCols' && compLoad && displayData.length && data_setup.length) ? <Alert sx={{ position: 'absolute', width: 'calc(100% - 8px)', zIndex: 999, textAlign: 'center', opacity: 0.95, borderRadius: 0, lineHeight: 1, p: 0, pl: 1, left: 0, top: 80 }} severity="info" color='info' textAlign='center' icon={false}>Sorting data...</Alert> : ''
                        }

                        {/* {renderBody().map((row, i) => <IntelimergeRow key={i} wait={0.1*i} value={row} />)} */}

                        {/* {compLoad && displayData.length && data_setup.length && <TableRowSet key={1} dataSet={renderBody()} />} */}
                        {/* {compLoadStatus !== 'loading' && cacheStore['renderBody'] && <TableRowSet key={1} reloadComp={reloadGrid} dataSet={cacheStore['renderBody']} />} */}
                        {
                            (compLoadStatus === 'loaded..' && compLoad && displayData.length && data_setup.length) ? <TableRowSet key={1} reloadComp={reloadGrid} dataSet={renderBodyData} /> : ''
                        }
                        {
                            // (compLoad && displayData.length && data_setup.length) ? <TableRowSet key={1} reloadComp={reloadGrid} renderBody={renderBody({data_setup, app_state, columns})} /> : ''
                        }

                    </tbody>
                </Table>
            </TableContainer>}
        </Paper>
    );
}

export default memo(TableIntelimerge);

const TableComponents = (tableContClass, active_tiles_className, calculateColWidth, data_setup) => ({
    Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} className={tableContClass} style={{ ...props.style, boxShadow: 'none' }} />),
    Table: (props) => <Table {...props} stickyHeader size="small" aria-label="a dense table" className={active_tiles_className + " react-dynamic-table table-common"} id="mainTable" data-cols-width={calculateColWidth()} />,
    TableHead: React.forwardRef((props, ref) => <TableHead ref={ref} {...props} className="table-head" style={{ ...props.style, zIndex: 3 }} />),
    TableRow: (props) => {
        let ind = props['data-index'];
        let item = data_setup[ind];
        let total_row = item && item[0] && item[0].segment === 'Total' ? ' total-row' : '';
        // console.log('data-index', item[0].segment, total_row);
        return (<TableRow  {...props} className={`${props['data-index'] % 2 ? 'table-row even-row' + total_row : 'table-row' + total_row}`} />);
    },
    TableBody: React.forwardRef((props, ref) => <TableBody className="table-body" {...props} ref={ref} />)

})

const TableRowSet = (props) => {
    ;
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    let { dataSet } = props;
    let tm = props.compRef;

    const dispatch = useDispatch();
    const [renderCount, setRenderCount] = useState(1);
    const chunkLength = 50;
    const dataSize = dataSet.length;
    let totalRow = dataSet.slice(-1);
    const compRef = useRef({
        intervalId: null,
        renderItems: (dataSize > chunkLength) ? dataSet.slice(0, chunkLength).concat(totalRow) : dataSet,
        chunks: Math.ceil(dataSize / chunkLength),
        index: 1,
        renderCount: 0
    });

    useEffect(() => {
        let isSubscribed = true;
        if (compRef.current.index >= compRef.current.chunks) {
            clearInterval(compRef.current.intervalId);

            if (app_state.workspace_controls.disableExport) {
                ;
                dispatch(updateAppState("exportStatus", {
                    app_state: {
                        workspace_controls: { ...app_state.workspace_controls, disableExport: false }
                    }
                }));
            }

        }
        return () => (isSubscribed = false);
    }, [renderCount]);

    useEffect(() => {

        let isSubscribed = true;
        if (compRef.current.chunks > 1) {
            dispatch(updateAppState("exportStatus", {
                app_state: {
                    workspace_controls: { ...app_state.workspace_controls, disableExport: true }
                }
            }));
            compRef.current.intervalId = setInterval(() => {
                compRef.current.renderItems.splice(-1);
                let newArray = dataSet.slice(chunkLength * compRef.current.index, chunkLength * (compRef.current.index + 1));
                if (compRef.current.chunks === compRef.current.index + 1) {
                    compRef.current.renderItems = compRef.current.renderItems.concat(newArray);
                } else {
                    compRef.current.renderItems = compRef.current.renderItems.concat(newArray, totalRow);
                }

                compRef.current.renderCount = compRef.current.index;
                setRenderCount(compRef.current.index);
                compRef.current.index++;
            }, 90);
            return () => clearInterval(compRef.current.intervalId);
        }



        return () => (isSubscribed = false);
    }, []);

    if (!compRef.current.renderItems.length) {
        return (
            <>Loading...</>
        );
    }
    let ri = compRef.current.renderItems;
    ;
    return (compRef.current.renderItems)
};

//Helper functions
const getFilteredData = (params) => {

    // {data:data_setup, sortBy: 'Occ', parent: 'otb_prior'};
    const { data, columns } = params;
    const filteredColumns = getFilteredColumns(columns);
    let filtereddData = deepCopy(data);
    if (!filteredColumns.length) {
        return filtereddData;
    }

    filtereddData = [];
    let pushedRow = [];
    data.map((row, i) => {
        for (let j = 0; j < row.length; j++) {
            for (let k = 0; k < filteredColumns.length; k++) {
                let { parent, value, filter } = filteredColumns[k];
                if (row[j].id === parent) {

                    let rowData = null;
                    if (filter) {
                        let dataValue = row[j][value];

                        if (dataValue && typeof dataValue !== 'number' && dataValue.indexOf('%') !== -1) {
                            dataValue = Number(dataValue.replaceAll(/%/g, ''));
                        }
                        if (filter.gte && filter.lte) {
                            if (dataValue >= Number(filter.gte) && dataValue <= filter.lte) {
                                rowData = row;
                            }
                        } else {
                            if (filter.gte && dataValue >= Number(filter.gte)) {
                                rowData = row;
                            }
                            if (filter.lte && dataValue <= filter.lte) {
                                rowData = row;
                            }
                        }

                        if (pushedRow.indexOf(i) === -1 && rowData) {
                            ;
                            filtereddData.push(rowData);
                            pushedRow.push(i);
                        }
                    }
                }
            }
        }
    });
    ;
    return filtereddData;
}

// const sortData = (params) => {
//     // {data:data_setup, sortBy: 'Occ', parent: 'otb_prior'};
//     const { data, sortBy, parent, order } = params;
//     const sortedData = deepCopy(data);
//     if (!order) {
//         return sortedData;
//     }
//     let sortedParentIndex = null;
//     if (sortedData[0]) {
//         sortedData[0].map((item, i) => {
//             if (item.id === parent) {
//                 sortedParentIndex = i;
//             }
//         });
//         let weekdaysShort = moment.weekdaysShort();
//         sortedData.sort((a, b) => {
//             if (sortedParentIndex !== null) {
//                 let A = a[sortedParentIndex][sortBy];
//                 let B = b[sortedParentIndex][sortBy];
//                 //This is added for Pickup variance to sdly column
//                 A = (A) ? A : 0;
//                 B = (B) ? B : 0;

//                 if (typeof A !== 'number' && A.indexOf('%') !== -1) {
//                     A = Number(A.replaceAll(/%/g, ''));
//                 }
//                 if (typeof B !== 'number' && B.indexOf('%') !== -1) {
//                     B = Number(B.replaceAll(/%/g, ''));
//                 }
//                 if ((sortBy === 'date') || (sortBy === 'compdate')) {
//                     A = moment(A).format('x');
//                     B = moment(B).format('x');
//                 }
//                 if (sortBy === 'dow') {
//                     A = weekdaysShort.indexOf(A);
//                     B = weekdaysShort.indexOf(B);
//                 }

//                 if (order === 'asc') {
//                     return A - B;
//                 }
//                 if (order === 'desc') {
//                     return B - A;
//                 }
//             }
//         });
//     }
//     return sortedData;
// }


const sortData = (params) => {

    // {data:data_setup, sortBy: 'Occ', parent: 'otb_prior'};
    let { data, sortBy, parent, order, workspace_controls } = params;
    const sortedData = deepCopy(data);
    let finalSortedData = [];
    let sortedParentIndex = null;

    if (sortedData[0]) {
        if (parent) {
            sortedData[0].map((item, i) => {
                if (item.id === parent) {
                    sortedParentIndex = i;
                }
            });
        } else {
            sortedParentIndex = 1;
        }

        let weekdaysShort = moment.weekdaysShort();

        let sortedDates = [];
        let tempSortedDates = deepCopy(sortedData);
        let tempSortedDatesTotal = [];
        let tempSortedDatesBlank = [];
        let dba_date = workspace_controls.scopeType.toLowerCase();

        if (!order && workspace_controls.segmented_view) {
            order = 'asc';
            sortBy = dba_date;
            sortedParentIndex = 0;
            tempSortedDatesTotal = deepCopy(sortedData.filter(d => d[0].segment === 'Total'));
            tempSortedDates = deepCopy(sortedData.filter(d => d[0][dba_date] !== '' && d[0].segment !== 'Total'));
            tempSortedDatesBlank = deepCopy(sortedData.filter(d => d[0][dba_date] === ''));

            tempSortedDatesTotal = arraySort(tempSortedDatesTotal);
            tempSortedDatesTotal.map((item, i) => {
                if (!sortedDates.includes(item[0][dba_date])) {
                    sortedDates.push(item[0][dba_date]);
                    let dateFilter = tempSortedDates.filter((d) => d[0][dba_date] === item[0][dba_date] && d[0].segment !== 'Total');
                    ;
                    finalSortedData = [...finalSortedData, ...dateFilter, item];
                }
            });
            finalSortedData = [...finalSortedData, ...tempSortedDatesBlank];
            return finalSortedData;
        }

        if (order && workspace_controls.segmented_view) {
            tempSortedDatesTotal = deepCopy(sortedData.filter(d => d[0].segment === 'Total'));

            tempSortedDates = deepCopy(sortedData.filter(d => d[0][dba_date] !== '' && d[0].segment !== 'Total'));

            tempSortedDatesBlank = deepCopy(sortedData.filter(d => d[0][dba_date] === ''));

            tempSortedDatesTotal = order !== 'default' ? arraySort(tempSortedDatesTotal) : tempSortedDatesTotal;


        } else {
            tempSortedDates = order !== 'default' ? arraySort(sortedData) : sortedData;
            // deepCopy(sortedData);
        }

        function arraySort(arr) {
            let arr_copy = deepCopy(arr);

            arr_copy.sort((a, b) => {
                if (sortedParentIndex !== null) {
                    let A = a[sortedParentIndex][sortBy];
                    let B = b[sortedParentIndex][sortBy];
                    //This is added for Pickup variance to sdly column
                    A = (A) ? A : 0;
                    B = (B) ? B : 0;

                    if (typeof A !== 'number' && A.indexOf('%') !== -1) {
                        A = Number(A.replaceAll(/%/g, ''));
                    }
                    if (typeof B !== 'number' && B.indexOf('%') !== -1) {
                        B = Number(B.replaceAll(/%/g, ''));
                    }
                    //---
                    if ((sortBy === 'date') || (sortBy === 'compdate')) {
                        A = Number(moment(A).format('x'));
                        B = Number(moment(B).format('x'));
                    }

                    if (sortBy === 'dow') {
                        A = weekdaysShort.indexOf(A);
                        B = weekdaysShort.indexOf(B);
                    }

                    if (order === 'asc') {
                        return A - B;
                    }

                    if (order === 'desc') {
                        return B - A;
                    }
                }
            });
            return arr_copy;
        }

        if (workspace_controls.segmented_view) {
            tempSortedDatesTotal.map((item, i) => {
                if (!sortedDates.includes(item[0][dba_date])) {
                    sortedDates.push(item[0][dba_date]);
                    let dateFilter = tempSortedDates.filter((d) => d[0][dba_date] === item[0][dba_date] && d[0].segment !== 'Total');

                    let dateFilterTotal = tempSortedDatesTotal.filter((d) => d[0][dba_date] === item[0][dba_date] && d[0].segment === 'Total');
                    dateFilter = order !== 'default' ? arraySort(dateFilter) : dateFilter;
                    if (order === 'default') {
                        //Sort desc on basis of rooms value
                        dateFilter = dateFilter.sort((a, b) => {
                            if (a && a[1] && a[1].Rooms) {
                                return b[1].Rooms - a[1].Rooms
                            }
                        });
                    }

                    ;
                    finalSortedData = [...finalSortedData, ...dateFilter, ...dateFilterTotal];
                }
            });
            //Sort desc on basis of rooms value
            tempSortedDatesBlank = tempSortedDatesBlank.sort((a, b) => {
                if (a && a[1] && a[1].Rooms) {
                    return b[1].Rooms - a[1].Rooms
                }
            });
            finalSortedData = [...finalSortedData, ...tempSortedDatesBlank];
        } else {
            finalSortedData = tempSortedDates;
        }
    } else {
        return sortedData;
    }
    return finalSortedData;
}

const getSortedColumn = (columns) => {
    //Get sorted column details from column configuration
    let sortedColumn = {};
    columns.map((main, i) => {
        main.subcolumns.map((sub, j) => {
            //it depends on property sorted
            if (sub.sorted) {
                sortedColumn = sub;
            }
        });
    });
    return sortedColumn;
};
const getFilteredColumns = (columns) => {
    //Get filter column details from column configuration
    let filteredColumns = [];
    columns.map((main, i) => {
        let subcols = main.subcolumns;
        for (let j = 0; j < subcols.length; j++) {
            let sub = subcols[j];
            if (sub.filterable && sub.filter && (sub.filter.gte || sub.filter.lte)) {
                filteredColumns.push(sub);
            }
        }
    });
    ;
    return filteredColumns;
};
//Helper components
const ColumnSorting = (props) => {
    const { colItem, sortable, onOrderByChange, sorted, label, value } = props;

    const onClickHandler = (ev) => {
        if (!sorted) {
            colItem.sorted = 'asc';
        }
        if (sorted === 'asc') {
            colItem.sorted = 'desc';
        }
        if (sorted === 'desc') {
            colItem.sorted = null;
        }
        onOrderByChange(colItem);
    }

    if (!sortable) {
        return (<></>);
    }
    return (
        <div style={{ position: 'relative' }}>
            <Tooltip title={"Sort by " + label} arrow>
                <Button className={props.className + ' order-' + colItem.sorted} onClick={onClickHandler}>
                    <ArrowDropUpIcon className='order-arrow order-arrow-up' />
                    <ArrowDropDownIcon className='order-arrow order-arrow-down' />
                </Button>
            </Tooltip>

        </div>

    );
}