import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';
import { Box, Select, MenuItem, FormControl, Switch } from '@mui/material'
import { useEffect, useState } from 'react';
import { deepCopy, getFromState, in_array } from '../../../../app/util';
import dataService from '../../../../services/data.service';
import { sendLog } from '../../../../app/log-interface';
import moment from 'moment';
import { APP_CACHE } from "../../../../app//config";
import DayDateToggle from './DayDateToggle';


const SdYears = () => {
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    // const { app_state, ui_state } = useSelector(
    //     (state) => state.app.current_state
    // );
    const app = useSelector(
        (state) => state.app
    );
    const { app_state, ui_state } = app.current_state

    let [sdYear, setSdYear] = useState(null);
    let comObj = { default_ly: controls.sdYear, propertyYears: controls.propertyYears, sdYearsArr: controls.sdYearsArr, rt_years: controls.rt_years, rp_years: controls.rp_years };
    const [comState, setComState] = useState(comObj);
    let [yearsList, setYearsList] = useState(controls.sdYearsArr || []);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        controls.sdlyOn = false;
        let value = e.target.value;
        if (sdYear !== value) {

            if (value && value !== sdYear) {
                setSdYear(value);
                let type = "sdyear";
                const updateTypes = [type];
                controls.sdYear = value;
                controls.sdlyOn = true;
                APP_CACHE.controls.sdlyOn = true;
                APP_CACHE.controls.sdYear = value;
                dispatch({
                    type: 'UPDATE_STATE',
                    payload: {
                        app_state: {
                            date_range_type: 'rangeTileChange_sdYear' + value,
                            workspace_controls: controls,
                            data_load: false
                        },
                        ui_state: { showspinner: true }
                    }
                })
                // dispatch(
                //     updateAppState(
                //         updateTypes, {
                //         app_state: { workspace_controls: controls, data_load: true },
                //         ui_state: { showspinner: true }
                //     }));
            }
            if (!value) {
                setSdYear(null);
                let type = "sdyear";
                const updateTypes = [type];
                //controls.sdYear = controls.sdYear;
                controls.sdlyOn = false;
                APP_CACHE.controls.sdlyOn = false;
                APP_CACHE.controls.sdYear = controls.sdYear;
                dispatch({
                    type: 'UPDATE_STATE',
                    payload: {
                        app_state: {
                            date_range_type: 'rangeTileChange_sdYear' + value,
                            workspace_controls: controls,
                            data_load: false
                        },
                        ui_state: { showspinner: true }
                    }
                })
                // dispatch(
                //     updateAppState(
                //         updateTypes, {
                //         app_state: { workspace_controls: controls, data_load: true },
                //         ui_state: { showspinner: true }
                //     }));
            }
            sendLog({ category: "workspace_controls", action: "change_Compare_asOf" }, { ...app.current_state }, {
                app_state: { workspace_controls: controls, data_load: true },
                ui_state: { showspinner: true }
            })
        }
    }

    useEffect(() => {
        let asOfDateYear = Number(moment(app_state.as_of_date, "YYYY-MM-DD").format("YYYY"));
        if (controls.sdlyOn) {
            if (controls.sdYear >= asOfDateYear) {
                setSdYear(asOfDateYear - 1);
                controls.sdYear = asOfDateYear - 1;
            } else {
                setSdYear(controls.sdYear);
            }

        }
        let propertyDetails = deepCopy(dataService.getSeriesById("GetPropData").data);
        if (propertyDetails && propertyDetails.years) {

            controls.propertyYears = propertyDetails.years;
            let sdYearsArr = [null].concat(propertyDetails.years);
            let rt_years = propertyDetails["rt-years"];
            let rp_years = propertyDetails["rp-years"];
            let default_ly = propertyDetails.default_ly;

            let lyYears = moment(app_state.as_of_date).subtract(1, 'years').format('YYYY');
            if (!controls.sdlyOn) {
                setSdYear(sdYearsArr[0]);
            }

            controls.sdYearsArr = (sdYearsArr.length > 0) ? sdYearsArr : controls.sdYearsArr;

            controls.rt_years = (rt_years.length > 0) ? rt_years : controls.sdYear;
            controls.rp_years = (rp_years.length > 0) ? rp_years : controls.sdYear;

            yearsList = sdYearsArr;
            controls.sdYearsArr = sdYearsArr;
            setYearsList(sdYearsArr);

            dispatch({
                type: 'UPDATE_STATE',
                payload: {
                    app_state: {
                        workspace_controls: { ...controls },
                    }
                }
            })
        }
        else {
            if (!controls.sdlyOn) {
                setSdYear(null);
            }
            dispatch({
                type: 'UPDATE_STATE',
                payload: {
                    app_state: {
                        workspace_controls: { ...controls },
                    }
                }
            })
        }

        //If active tiles has been changed
        // if (ui_state.active_tiles.includes('market') && app_state.selected_market) {
        if (in_array(ui_state.active_tiles, ['market', 'market_summary']) && app_state.selected_market) {
            let marketSdYears = [];
            let allYears = [];
            let sdYearsArr = app_state.workspace_controls.sdYearsArr;
            const selected_market = app_state.selected_market;

            if (ui_state.active_tiles.length === 1) {
                marketSdYears = selected_market.year ? selected_market.year : controls.sdYearsArr;
                sdYearsArr = marketSdYears[0] ? [null].concat(marketSdYears) : marketSdYears;
            } else {
                allYears = (selected_market && selected_market.year) ? controls.sdYearsArr.filter(item => selected_market.year.includes(item)) : controls.sdYearsArr;
                sdYearsArr = [null].concat(allYears)
            }
            if (sdYearsArr.includes(controls.sdYear)) {
                setSdYear(controls.sdYear);
            }
            else {
                setSdYear(sdYearsArr[0]);

            }
            if (!controls.sdlyOn) {
                setSdYear(sdYearsArr[0]);
            }

            setYearsList(sdYearsArr);

            dispatch({
                type: 'UPDATE_STATE',
                payload: {
                    app_state: {
                        workspace_controls: { ...controls, sdYearsArr },
                    }
                }
            })

        }
        dispatch({
            type: 'UPDATE_STATE',
            payload: {
                app_state: {
                    workspace_controls: { ...controls },
                }
            }
        })
    }, [app_state.property_id, ui_state.active_tiles.join('_'), app_state.as_of_date, app_state.workspace_controls.segmentType]);

    let segmentType = app_state.workspace_controls.segmentType;
    if (!ui_state.active_tiles.includes('market')) {

    }
    if (ui_state.active_tiles[0] === "otb_v2_multi_segment" && segmentType === "rate_plan") {
        yearsList = (controls.rp_years.length > 1) ? controls.rp_years : controls.rp_years.splice(0, 0, null);
    } else if (ui_state.active_tiles[0] === "otb_v2_multi_segment" && segmentType === "room_type") {
        yearsList = (controls.rt_years.length > 1) ? controls.rt_years : controls.rt_years.splice(0, 0, null);
    }

    return (
        <>
            <Box id="sdyearBox" sx={{ height: 1 / 2 }}>
                {/* LRV-880 (uncomment the below div) */}
                {/* <Box className="data-level-label"><DayDateToggle /></Box> */}
                <Box className="data-level-label">Compare As Of</Box>
                <FormControl size="small" sx={{ minWidth: "100%", borderRadius: "16px" }}>
                    <Select displayEmpty className='tabular-data-filter' value={sdYear} sx={{ height: "30px", minWidth: "70px", mt: "5px" }}
                        onChange={handleChange}>
                        <MenuItem value={null} className='tabular-data-filter'> --- </MenuItem>
                        {/* <MenuItem value="" disabled className='tabular-data-filter'>Select Year </MenuItem> */}
                        {yearsList && yearsList.map((items, index) => {
                            // if (!items) {
                            //     return (<MenuItem value={items} className='tabular-data-filter'> --- </MenuItem>)
                            // }
                            if (items && items <= moment(app_state.as_of_date).subtract(1, 'years').format('YYYY')) {
                                return (<MenuItem value={items} className='tabular-data-filter'> {items} </MenuItem>)
                            }
                        })}

                    </Select>
                </FormControl>
            </Box>

        </>
    )
}

export default SdYears