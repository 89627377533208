import { convertToPercent } from "../../app/util";

export const checkCorrectValue = (val, symbol = '') => {
    if (!val || !isFinite(val)) {
        return "";
    }
    if (symbol === '%') {
        return (convertToPercent(val) + symbol);
    }
    val = (typeof (val) === 'number' && symbol) ? parseFloat(val).toFixed(2) : val;
    return (symbol + val);
}

export function getKpisSymbol(kpi) {
    let symbol = '';
    kpi = kpi.toLowerCase();
    if (kpi === 'occ') {
        symbol = '%';
    } else if (kpi === 'rooms') {
        symbol = '';
    } else {
        symbol = '$';
    }
    return symbol;
}

export function getDataMainKey(tile) {
    let dataMainKey = 'primary';
    switch (tile) {
        case 'pickup':
            dataMainKey = "pickup"
            break;
        case 'pace':
            dataMainKey = "pace"
            break;
        default:
            break;
    }
    return dataMainKey;
}