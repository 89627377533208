



import {Button, Box, Select, MenuItem} from '@mui/material'
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';


const CategoriesType = (props) => {
    const [type, setType] = useState("All")
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const dispatch = useDispatch();

    const handleChange = (type,value)=>{
        const updateTypes = [type];
        controls.categoryType = value;
        dispatch(updateAppState(updateTypes, {app_state: {workspace_controls: controls}}));
    }

    return (
        <Box id="categories_type" sx={{paddingLeft:0,backgroundColor:'blue', height: 1/2, width: "130px"}}>
        <Box className="data-level-label">Categories</Box>
        <Box> 
            <Select value={type} sx={{backgroundColor: "grey.300", height: "25px", mt: "5px", width: 1}} onChange={(e) => {setType(e.target.value); handleChange("categories_select", e.target.value);}}> 
                <MenuItem value="All"> ALL </MenuItem>
                <MenuItem value="Conference"> CONFERENCE </MenuItem>
                <MenuItem value="Sport"> SPORT </MenuItem>
                <MenuItem value="Holiday"> HOLIDAY </MenuItem>
                <MenuItem value="Music"> MUSIC </MenuItem>
                <MenuItem value="In_House"> IN HOUSE </MenuItem>
                <MenuItem value="Other"> OTHER </MenuItem>
            </Select> 
        </Box>
     </Box>
    )
}

export default CategoriesType
