import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ChartCommon from './ChartCommon';
import ChartIntellimerge from './ChartIntellimerge';
import { getPermissions, getSeriesById } from '../../app/util';
import '../../simple-grid.css';
import './chart-view.css';
import { Alert, AlertTitle, Box } from '@mui/material';
import dataService from '../../services/data.service';

function Chart({ compDisplayData }) {
    // debugger;
    const dispatch = useDispatch();
    const state = useSelector(appState => appState);
    const { property_details } = useSelector(state => state.auth.profile);
    const { app_state, ui_state } = useSelector(state => state.app.current_state);
    const { segmentType } = app_state.workspace_controls
    // const { profile } = auth;
    const { permissions, index } = getPermissions(property_details, app_state.property_id);
    let infoText = '';
    if (ui_state.active_tiles[0] === 'forecast' && permissions.hfc == 0) {
        infoText = 'System forecast is not enabled for this property! Please contact success@lodgiq.com for further details.';
    }
    if (segmentType === 'rate_plan' && ui_state.active_tiles[0] === 'otb_v2_multi_segment') {
        infoText = 'Rate Plan chart view is under development, It will be available in subsequent releases of LodgIQ RM v4. Please switch to data view';
    }

    if (ui_state.active_tiles[0] === 'market_sell_rates' && ((compDisplayData && compDisplayData.data && compDisplayData.data.length <= 0) || (compDisplayData && compDisplayData[0] && compDisplayData[0].data && compDisplayData[0].data.length <= 0))) {

        // if (ui_state.active_tiles[0] === 'market_sell_rates' && compDisplayData.data && compDisplayData.data.length <= 0) {
        // infoText = 'Market Sell Rates are not available for the selected market, Please contact success@lodgiq.com for further details.';
        infoText = 'Market sell rate data is not available , Please contact success@lodgiq.com for further details.';
    }
    if (ui_state.active_tiles[0] === 'pricing' && permissions && permissions.rate_push === 0) {
        infoText = 'Pricing is not enabled for this data source! Please contact success@lodgiq.com for further details.';
    }
    if ((ui_state.active_tiles[0] === 'market' && dataService.getSeriesById("MarketCapacity").data && dataService.getSeriesById("MarketCapacity").data.data.length <= 0)) {
        infoText = 'Data is not available for the selected market, Please contact success@lodgiq.com for further details.';
    }
    if (ui_state.active_tiles[0] === 'market' && app_state.market_settings && app_state.market_settings.length <= 0) {
        infoText = 'Market data is not available , please contact success@lodgiq.com for further details.';
    }
    if (permissions.room_type === 0 && segmentType === "room_class") {
        infoText = 'Room Class is not enabled for this property! Please contact success@lodgiq.com for further details.';
    }
    if (permissions.room_type === 0 && segmentType === "room_type") {
        infoText = 'Room Type is not enabled for this property! Please contact success@lodgiq.com for further details.';
    }
    const hasInfoBox = () => {
        // console.log(compDisplayData.data," length ",compDisplayData.data.length," ui_state.active_tiles[0]",ui_state.active_tiles[0]);
        if (ui_state.active_tiles[0] === 'forecast' && permissions.hfc == 0) {
            return true;
        }
        if (segmentType === 'rate_plan' && ui_state.active_tiles[0] === 'otb_v2_multi_segment') {
            return true;
        }
        if (ui_state.active_tiles[0] === 'market_sell_rates' && compDisplayData && compDisplayData[0] && compDisplayData[0].data && compDisplayData[0].data.length <= 0) {
            return true;
        }
        if (ui_state.active_tiles[0] === 'pricing' && permissions && permissions.rate_push === 0) {
            return true;
        }
        if ((ui_state.active_tiles[0] === 'market' && dataService.getSeriesById("MarketCapacity").data && dataService.getSeriesById("MarketCapacity").data.data.length <= 0)) {
            return true;
        }
        if (ui_state.active_tiles[0] === 'market' && app_state.market_settings && app_state.market_settings.length <= 0) {
            return true;
        }
        if (permissions.room_type === 0 && segmentType === "room_class") {
            return true;
        }
        if (permissions.room_type === 0 && segmentType === "room_type") {
            return true;
        }
        return false;
    }
    // useEffect(() => {
        // if (ui_state.active_tiles.includes('forecast')) {
            // let dt = compDisplayData;
            // debugger;
            // let userForecast = getSeriesById('userforecast').data;
            // if (userForecast && userForecast.data && userForecast.data.length) {
            //     // DATA.data.forEach(item => {
            //     //     let segmentKey = '';
            //     //     let keys = Object.keys(item);
            //     //     segmentKey = keys.find(key => key.indexOf('Segment_') >= 0);
            //     //     let manualForecast = userForecast.data.find(data => new moment(item.index.date).isSame(data.index.date, 'day'))
            //     //     let totalKey = 'HotelTotal';
            //     //     if (manualForecast) {
            //     //         let dateData = manualForecast.total_forecast;
            //     //         dateData.Occ = dateData['Occ%'] / 100;
            //     //         delete dateData['Occ%'];
            //     //         item[totalKey].userforecastdata = dateData;
            //     //         item[segmentKey].userforecastdata = dateData;
            //     //     }
            //     // });
            // }
        // }
    // }, []);

    return (
        <>
            {(ui_state.active_tiles.length === 1) &&
                <>
                    {!hasInfoBox() && <ChartCommon compDisplayData={compDisplayData} />}
                    {hasInfoBox() && <Box sx={{ p: 1 }}>
                        <Alert severity="info">
                            <AlertTitle>Info</AlertTitle>
                            {infoText}
                        </Alert></Box>}
                    {/* {ui_state.active_tiles[0] === 'forecast' && permissions.hfc == 1 && <ChartCommon compDisplayData={compDisplayData} /> */}
                </>
            }

            {ui_state.active_tiles.length > 1 && compDisplayData.length && <ChartIntellimerge compDisplayData={compDisplayData} />}
        </>
    );
}

export default Chart;
