import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//import theme from '../../theme';
import Header from "../Header";
import { DashboardFooter } from "../layout/dashboard-ui";
import {
    Box
    , Container
    , Grid
    , Paper
    , Chip
    , Avatar
    , Button
    , TextField
    , CircularProgress
    , Alert
    , FormControl
    , Radio
    , RadioGroup
    , FormControlLabel
    , Checkbox
    , label
    , Accordion
    , AccordionDetails
    , AccordionSummary
    , Typography
    , List
    , Tabs
    , Tab
    , ListSubheader
    , ListItem
    , ListItemButton
    , Divider
    , ListItemIcon
    , ListItemText
    , styles as styled,
    Badge,
    Select,
    MenuItem,
    Tooltip,
    Input,
    InputAdornment,
    IconButton,
    Autocomplete,
    Modal,
    InputLabel
} from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import "./styles.css";
import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {
    convertToPercent, getCompsetName, round, getNumDaysFromDateRange, safeReference, dateDiff,
    isString, isEmptyObj, getFromState, getTransformedSegments, getRestrictionsStr, getStringFromHTML,
    numberFormatForReport, truncate, isNumber, dateFormat, deepCopy, processColumnsNew, getAppData, divide, generateTable, getPermissions,
    getStayToSdly, getStayToComp, getFromSession, isDateInRange, getDemandImpactName, getDemandImpactColor
} from "../../app/util";
import { CloseSharp as CloseSharpIcon, Label } from '@mui/icons-material';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format, addDays, differenceInDays } from 'date-fns';
import { FileUploader } from "react-drag-drop-files";
import DataService from "../../services/data.service";
import { UPDATE_STATE } from '../../actions/types';
import { error } from "highcharts";
import { fetchDataSimple } from "../../services/data.service";

const theme = createTheme({
    components: {
        MuiSlider: {
            styleOverrides: {
                thumb: {
                    height: "14px",
                    width: "14px",
                    backgroundColor: '#D0EBFF', // Default color
                    '&:hover': {
                        boxShadow: "0px 0px 0px 8px rgba(25, 118, 210, 0.12)", // Default hover color
                    },
                },
                rail: {
                    backgroundColor: 'lightgrey',
                    padding: "0px",
                    border: "1px solid gray",
                    width: "200px",
                    right: "0px"
                },
                mark: {
                    height: "2px",
                    width: "1px",
                    color: "gray",
                },
                root: {
                    padding: "0px 0px"
                }
            },
        },
    },
});
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const EventsSettings = () => {
    const dispatch = useDispatch();
    const { app, auth } = useSelector((state) => state);
    const { app_state, ui_state } = app.current_state;
    const { avgImpactData, eventList } = app_state;
    //const [avgImpactData, setAvgImpactData] = useState(app_state.avgImpactData); // day wise immact data
    //const [eventList, setEventList] = useState([]); // Event Data
    // console.log("app_state.avgImpactData", avgImpactData)
    // console.log("app_state.eventList", eventList)

    const [isrequesting, setIsRequesting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const navigate = useNavigate();
    function goBackToDashboard() {
        dispatch({
            type: UPDATE_STATE,
            payload: {
                app_state: {
                    data_load: true,
                    kpi_load: true,
                },
                ui_state: {
                    active_settings_menu: "",
                    showspinner: true,
                }
            },
        });

        navigate("/");
    }
    const eventsIconBlack = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#333333" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H72v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V208Zm-31.38-94.36-29.84-2.31-11.43-26.5a8,8,0,0,0-14.7,0l-11.43,26.5-29.84,2.31a8,8,0,0,0-4.47,14.14l22.52,18.59-6.86,27.71a8,8,0,0,0,11.82,8.81L128,167.82l25.61,15.07a8,8,0,0,0,11.82-8.81l-6.86-27.71,22.52-18.59a8,8,0,0,0-4.47-14.14Zm-32.11,23.6a8,8,0,0,0-2.68,8.09l3.5,14.12-13.27-7.81a8,8,0,0,0-8.12,0l-13.27,7.81,3.5-14.12a8,8,0,0,0-2.68-8.09l-11.11-9.18,14.89-1.15a8,8,0,0,0,6.73-4.8l6-13.92,6,13.92a8,8,0,0,0,6.73,4.8l14.89,1.15Z"></path></svg>
    const eventsIconWhite = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H72v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V208Zm-31.38-94.36-29.84-2.31-11.43-26.5a8,8,0,0,0-14.7,0l-11.43,26.5-29.84,2.31a8,8,0,0,0-4.47,14.14l22.52,18.59-6.86,27.71a8,8,0,0,0,11.82,8.81L128,167.82l25.61,15.07a8,8,0,0,0,11.82-8.81l-6.86-27.71,22.52-18.59a8,8,0,0,0-4.47-14.14Zm-32.11,23.6a8,8,0,0,0-2.68,8.09l3.5,14.12-13.27-7.81a8,8,0,0,0-8.12,0l-13.27,7.81,3.5-14.12a8,8,0,0,0-2.68-8.09l-11.11-9.18,14.89-1.15a8,8,0,0,0,6.73-4.8l6-13.92,6,13.92a8,8,0,0,0,6.73,4.8l14.89,1.15Z"></path></svg>

    // CUSTOM SLIDER STARTS
    const handleChangeDemandImpactSlider = (index, newValue) => {
        //console.log("newValue", newValue)
        let adjustedValue = newValue
        // let adjustedValue = Math.max(0.5, Math.min(5.5, newValue));
        // if (adjustedValue === 0) {
        //     adjustedValue = 0.5;
        // } else if (adjustedValue === 1) {
        //     adjustedValue = 1.5;
        // } else if (adjustedValue === 2) {
        //     adjustedValue = 2.5;
        // } else if (adjustedValue === 3) {
        //     adjustedValue = 3.5;
        // } else if (adjustedValue === 4) {
        //     adjustedValue = 4.5;
        // } else if (adjustedValue === 5) {
        //     adjustedValue = 4.5;
        // }
        console.log("adjustedValue", adjustedValue)
        setDemandImpactRows(prevRows => {
            const newRows = [...prevRows];
            newRows[index].demand_impact = adjustedValue;
            return newRows;
        });
    };
    // CUSTOM SLIDER ENDS

    // CALENDAR FUNCTIONS STARTS
    const [selectedDate, setSelectedDate] = useState(dateFormat(new Date(), "YYYY-MM-DD"));
    const [dates, setDates] = useState([]);
    const containerRef = useRef(null);
    const [scrollPos, setScrollPos] = useState(0);
    const initialScrollDone = useRef(false);

    const [startDateAPI, setStartDateAPI] = useState("");
    const [endDateAPI, setEndDateAPI] = useState("");

    const [isIgnoredList, setIsIgnoredList] = useState(false);
    const showActiveList = () => {
        if(!isIgnoredList){
            return;
        }
        avgImpactGetData();
        setIsIgnoredList(false);
    };
    const showIgnoredList = () => {
        if(isIgnoredList){
            return;
        }
        avgImpactGetIgnoredData()
        setIsIgnoredList(true);
    };

    // Filter Active eventList based on the selected date
    const filteredEventList = eventList.filter(event =>
        isDateInRange(selectedDate, event.start_date, event.end_date) && event.is_ignored === false
    );

    // Filter Ignored eventList based on the selected date
    const filteredIgnoredEventList = eventList.filter(event =>
        isDateInRange(selectedDate, event.start_date, event.end_date) && event.is_ignored === true
    );


    // STARTS NEW MONTH LOADING LOGIC ON SCROLL
    useEffect(() => {
        const initialDate = new Date();
        loadInitialMonths(initialDate);
    }, []);

    useEffect(() => {
        if (containerRef.current && dates.length > 0 && !initialScrollDone.current) {
            const currentMonthIndex = dates.findIndex(date =>
                date.getMonth() === new Date().getMonth() &&
                date.getFullYear() === new Date().getFullYear()
            );

            if (currentMonthIndex !== -1) {
                const calendarHeight = containerRef.current.firstChild.offsetHeight;
                const offset = -3; // Adjust this value as needed to bring the current month near the top
                const targetScrollPosition = Math.max(0, (currentMonthIndex - offset) * calendarHeight);
                containerRef.current.scrollTop = targetScrollPosition;
                initialScrollDone.current = true;
            }
        }
    }, [dates]);

    const loadInitialMonths = (startDate) => {
        const newDates = [];
        const currentMonth = 0; // Set the starting month to January
        const currentYear = startDate.getFullYear();

        for (let i = -84; i <= 12; i++) {
            newDates.push(new Date(currentYear, currentMonth + i, 1));
        }

        setDates(newDates);

        setStartDateAPI(dateFormat(newDates[0], "YYYY-MM-DD"));
        setEndDateAPI(dateFormat(newDates[newDates.length - 1], "YYYY-MM-DD"));

        let yearStart = newDates[0].getFullYear();
        let yearEnd = newDates[newDates.length - 1].getFullYear();
        let start = `${yearStart}-01-01`;
        let end = `${yearEnd}-12-31`;
        avgImpactGetData2(dateFormat(start, "YYYY-MM-DD"), dateFormat(end, "YYYY-MM-DD"));
        EventsListGetData2(dateFormat(start, "YYYY-MM-DD"), dateFormat(end, "YYYY-MM-DD"));
    };

    const loadMoreMonths = (direction) => {
        const newDates = [...dates];
        const earliestAllowedDate = new Date(new Date().getFullYear() - 7, 0, 1); // January 1st, 7 years ago

        if (direction === 'up') {
            const firstDate = newDates[0];
            for (let i = 1; i <= 6; i++) {
                const newDate = new Date(firstDate.getFullYear(), firstDate.getMonth() - i, 1);
                if (newDate >= earliestAllowedDate && !newDates.some(date => date.getTime() === newDate.getTime())) {
                    newDates.unshift(newDate);
                }
            }
        } else {
            const lastDate = newDates[newDates.length - 1];
            for (let i = 1; i <= 6; i++) {
                const newDate = new Date(lastDate.getFullYear(), lastDate.getMonth() + i, 1);
                if (!newDates.some(date => date.getTime() === newDate.getTime())) {
                    newDates.push(newDate);
                }
            }
        }
        setDates(newDates);
    };

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        const calendarHeight = containerRef.current.firstChild.offsetHeight;

        const firstDate = new Date(dates[0]);
        const lastDate = new Date(dates[dates.length - 1]);
        const firstDateOld = new Date(startDateAPI);
        const lastDateOld = new Date(endDateAPI);

        if (scrollTop < scrollPos && scrollTop <= calendarHeight * 3) {
            if (firstDate.getFullYear() < firstDateOld.getFullYear()) {
                console.log("firstDate changed", dateFormat(firstDate, "YYYY-MM-DD"));

                let yearStart = firstDate.getFullYear();
                let yearEnd = lastDateOld.getFullYear();
                let start = `${yearStart}-01-01`;
                let end = `${yearEnd}-12-31`;
                avgImpactGetData2(dateFormat(start, "YYYY-MM-DD"), dateFormat(end, "YYYY-MM-DD"));
                EventsListGetData2(dateFormat(start, "YYYY-MM-DD"), dateFormat(end, "YYYY-MM-DD"));

                setStartDateAPI(dateFormat(start, "YYYY-MM-DD"));
                setEndDateAPI(dateFormat(end, "YYYY-MM-DD"));

                loadMoreMonths('up');
            }

        }
        else if (scrollTop > scrollPos && scrollHeight - scrollTop - clientHeight <= calendarHeight * 3) {
            if (lastDate.getFullYear() > lastDateOld.getFullYear()) {
                console.log("lastDate changed", dateFormat(lastDate, "YYYY-MM-DD"));

                let yearStart = firstDateOld.getFullYear();
                let yearEnd = lastDate.getFullYear();
                let start = `${yearStart}-01-01`;
                let end = `${yearEnd}-12-31`;

                avgImpactGetData2(dateFormat(start, "YYYY-MM-DD"), dateFormat(end, "YYYY-MM-DD"));
                EventsListGetData2(dateFormat(start, "YYYY-MM-DD"), dateFormat(end, "YYYY-MM-DD"));

                setStartDateAPI(dateFormat(start, "YYYY-MM-DD"));
                setEndDateAPI(dateFormat(end, "YYYY-MM-DD"));
            }
            loadMoreMonths('down');
        }
        setScrollPos(scrollTop);
    };
    // ENDS NEW MONTH LOADING LOGIC ON SCROLL



    const CustomDropdownIcon = (props) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#606060"
            viewBox="0 0 256 256"
            style={{ top: "10px", right: "10px" }}
            {...props}
        >
            <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
        </svg>
    );
    const CustomDropdownIcon2 = (props) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#606060"
            viewBox="0 0 256 256"
            style={{ top: "2px" }}
            {...props}
        >
            <path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path>
        </svg>
    );

    // Events Dates to be highlighted
    const tileClassName = ({ date }) => {
        const dateString = dateFormat(date, "YYYY-MM-DD");
        const impact = avgImpactData.find(item => item.date === dateString)?.demand_impact;

        //Check if this date having any event or not
        const eventCount = eventList.filter(event =>
            isDateInRange(dateString, event.start_date, event.end_date) && event.is_ignored === isIgnoredList ? true : false
        );

        if (eventCount.length > 0) {
            if (impact >= -10 && impact < -5) {
                let className = 'highlight-very-low'
                if (selectedDate && dateString === selectedDate) {
                    className += ' selected-date';
                }
                return className;
            }
            else if (impact >= -5 && impact < 0) {
                let className = 'highlight-low'
                if (selectedDate && dateString === selectedDate) {
                    className += ' selected-date';
                }
                return className;
            }
            else if (impact >= 0 && impact < 5) {
                let className = 'highlight-nutral'
                if (selectedDate && dateString === selectedDate) {
                    className += ' selected-date';
                }
                return className;
            }
            else if (impact >= 5 && impact < 10) {
                let className = 'highlight-high'
                if (selectedDate && dateString === selectedDate) {
                    className += ' selected-date';
                }
                return className;
            }
            else if (impact >= 10) {
                let className = 'highlight-very-high'
                if (selectedDate && dateString === selectedDate) {
                    className += ' selected-date';
                }
                return className;
            }
        }
        else {
            if (selectedDate && dateString === selectedDate) {
                let className = 'selected-date';
                return className;
            }
            return null;
        }
    };

    const handleSelectedDate = (date) => {
        const formattedDaste = dateFormat(date, "YYYY-MM-DD");
        setSelectedDate(formattedDaste);
    }
    // CALENDAR FUNCTIONS ENDS

    // ADD EVENTS STARTS
    const [openAddEvent, setOpenAddEvent] = useState(false);
    const [addEventTabValue, setAddEventTabValue] = useState(0);
    const [eventTitle, setEventTitle] = useState(""); //add event tile payload
    const [category, setCategory] = useState(""); //add event category payload
    const [description, setDescription] = useState(''); //add event Description payload
    const [addEventStartDate, setAddEventStartDate] = useState(moment()); //add event start date payload
    const [addEventEndDate, setAddEventEndDate] = useState(moment()); //add event end date payload
    const [addEventStartDateTemp, setAddEventStartDateTemp] = useState(moment());
    const [addEventEndDateTemp, setAddEventEndDateTemp] = useState(moment());
    const [dateRangePickerVisiblity, setDateRangePickerVisiblity] = useState(false);
    const [dayTypes, setDayTypes] = useState(['Pre Event', 'Event', 'Post Event']);
    const [demandImpactRows, setDemandImpactRows] = useState([]); //add event demand impact payload
    const [addEventSaveBtnEnabled, setAddEventSaveBtnEnabled] = useState(false);
    const [addEventNextBtnEnabled, setAddEventNextBtnEnabled] = useState(false);

    function handleAddEventOpen() {
        setOpenAddEvent(true);
        setAddEventTabValue(0);

        setEventTitle("");
        setCategory("");
        setDescription("");
        setAddEventStartDate(moment(selectedDate));
        setAddEventEndDate(moment(selectedDate));
        setAddEventStartDateTemp(moment(selectedDate));
        setAddEventEndDateTemp(moment(selectedDate));
        setAddEventNextBtnEnabled(false)
    }
    function handleAddEventClose() {
        setOpenAddEvent(false);
    }
    const addEventTabChange = (event, newValue) => {
        if (newValue === 1) {
            addDemandImpactTab()
        }
        else {
            setAddEventTabValue(newValue);
        }
    };
    const addDemandImpactTab = () => {
        if (!addEventNextBtnEnabled) {
            return;
        }
        setAddEventTabValue(1);
        setAddEventSaveBtnEnabled(true);
        // Parse the formatted date strings to Date objects
        let startDate = new Date(addEventStartDate);
        let endDate = new Date(addEventEndDate);
        let dateArray = [];
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            dateArray.push({
                date: dateFormat(currentDate, "YYYY-MM-DD"),
                day_type: "Event",
                demand_impact: 0
            });
            currentDate.setDate(currentDate.getDate() + 1);
        }
        setDemandImpactRows(dateArray)
    };
    const handleEventTitleChange = (event) => {
        setEventTitle(event.target.value);
        if (event.target.value !== "" && category !== "") {
            setAddEventNextBtnEnabled(true)
        }
        else {
            setAddEventNextBtnEnabled(false)
        }
    };
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        if (eventTitle !== "" && event.target.value !== "") {
            setAddEventNextBtnEnabled(true)
        }
        else {
            setAddEventNextBtnEnabled(false)
        }
    };
    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };
    const handleDayTypeChange = (event, index) => {
        const newDayType = event.target.value;

        setDemandImpactRows(prevRows => {
            const newRows = [...prevRows];
            newRows[index].day_type = newDayType;
            return newRows;
        });
    };
    const preventTyping = (e) => {
        e.preventDefault();
        return false;
    }
    const toggleDateRangePickerVisiblity = () => {
        if (dateRangePickerVisiblity) {
            setAddEventStartDate(addEventStartDateTemp);
            setAddEventEndDate(addEventEndDateTemp);
            setDateRangePickerVisiblity(false);
        }
        else {
            setDateRangePickerVisiblity(true);
        }
    };
    const handleSaveAddEvent = async () => {
        let payload = {
            title: eventTitle,
            category: category,
            start_date: dateFormat(addEventStartDate, "YYYY-MM-DD"),
            end_date: dateFormat(addEventEndDate, "YYYY-MM-DD"),
            description: description,
            demand_impacts: demandImpactRows
        }
        //console.log("Add payload", payload);
        setIsSaving(true);
        try {
            const response = await DataService.update({
                api: "rms/properties/events/" + app_state.property_id + "/",
                request: payload
            });
            if (response.status === "success") {
                console.log("Event created successfully");
                avgImpactGetData();
                EventsListGetData();
                handleAddEventClose();
                setIsSaving(false);

                //Tracking analytics
                const { property_id, property_name, workspace_controls } = app_state;
                const { title, event_id, category, created_at, created_by, demand_impacts, description, start_date, end_date, last_edited_at, last_edited_by } = response.data.data;
                const { demand_impact, day_type } = demand_impacts[0];

                window.analyze.track("add_event", {
                    // "selected_tile": ui_state.active_tiles.join(),
                    // primary_stay_range,
                    property_id, property_name,
                    // data_view: user_view,
                    // event_id: event_id,
                    event_title: title,
                    // event_category: category,
                    // event_start_date: start_date,
                    // event_end_date: end_date,
                    // event_description: description,
                    // event_demand_impact_name: getDemandImpactName(demand_impact),
                    // event_demand_impact_num: demand_impact,
                    // event_day_type: day_type,

                    // created_at: created_at,
                    created_by: created_by,
                    // last_edited_at, last_edited_by

                }, {}, {
                    app_state: {
                        workspace_controls,
                        // data_load: true
                    },
                    ui_state: { showspinner: true }
                });
            }
            else {
                console.log("Event not created")
                setIsSaving(false);
            }
        }
        catch (err) {
            console.error('An error occurred', err);
        }
    }
    // ADD EVENTS ENDS

    // DELETE EVENT STARTS
    const handleDeleteEvent = async (event, index) => {
        // console.log("event to be deleted", filteredEventList[index])
        let payload = {
            event_id: filteredEventList[index].event_id,
            title: filteredEventList[index].title,
            category: filteredEventList[index].category,
            start_date: dateFormat(filteredEventList[index].start_date, "YYYY-MM-DD"),
            end_date: dateFormat(filteredEventList[index].end_date, "YYYY-MM-DD"),
            description: filteredEventList[index].description,
            demand_impacts: filteredEventList[index].demand_impacts,
            is_ignored: true
        }
        console.log("Delete Event Payload", payload)

        setIsRequesting(true);
        try {
            const response = await DataService.update({
                api: "rms/properties/events/" + app_state.property_id + "/",
                request: payload
            });
            if (response.status === "success") {
                console.log("Event deleted successfully");
                avgImpactGetData();
                EventsListGetData();

                //Tracking analytics
                const { property_id, property_name, workspace_controls } = app_state;
                const { title, event_id, category, created_at, created_by, demand_impacts, description, start_date, end_date, last_edited_at, last_edited_by } = response.data.data;
                const { demand_impact, day_type } = demand_impacts[0];
                window.analyze.track("delete_event", {
                    // "selected_tile": ui_state.active_tiles.join(),
                    // primary_stay_range,
                    property_id, property_name,
                    // data_view: user_view,
                    // event_id: event_id,
                    event_title: title,
                    // event_category: category,
                    // event_start_date: start_date,
                    // event_end_date: end_date,
                    // event_description: description,
                    // event_demand_impact_name: getDemandImpactName(demand_impact),
                    // event_demand_impact_num: demand_impact,
                    // event_day_type: day_type,

                    // created_at: created_at,
                    // created_by: created_by,
                    // last_edited_at, 
                    last_edited_by

                }, {}, {
                    app_state: {
                        workspace_controls,
                        // data_load: true
                    },
                    ui_state: { showspinner: true }
                });
            }
            else {
                console.log("Event not deleted")
                setIsRequesting(false);
            }
        }
        catch (err) {
            console.error('An error occurred', err);
            setIsRequesting(false);
        }
    }
    const handleRestoreEvent = async (event, index) => {
        // console.log("event to be deleted", filteredIgnoredEventList[index])
        let payload = {
            event_id: filteredIgnoredEventList[index].event_id,
            title: filteredIgnoredEventList[index].title,
            category: filteredIgnoredEventList[index].category,
            start_date: dateFormat(filteredIgnoredEventList[index].start_date, "YYYY-MM-DD"),
            end_date: dateFormat(filteredIgnoredEventList[index].end_date, "YYYY-MM-DD"),
            description: filteredIgnoredEventList[index].description,
            demand_impacts: filteredIgnoredEventList[index].demand_impacts,
            is_ignored: false
        }
        console.log("Restore Event Payload", payload)

        setIsRequesting(true);
        try {
            const response = await DataService.update({
                api: "rms/properties/events/" + app_state.property_id + "/",
                request: payload
            });
            if (response.status === "success") {
                console.log("Event restored successfully");
                avgImpactGetIgnoredData();
                EventsListGetData();
            }
            else {
                console.log("Event not restored")
                setIsRequesting(false);
            }
        }
        catch (err) {
            console.error('An error occurred', err);
            setIsRequesting(false);
        }
    }
    // DELETE EVENT ENDS

    //EDIT EVENTS STARTS
    const [openEditEvent, setOpenEditEvent] = useState(false);
    const [editEventTabValue, setEditEventTabValue] = useState(0);
    const [eventId, setEventId] = useState(null);
    const [isEditDateChanged, setIsEditDateChanged] = useState(false);

    function handleEditEventOpen(event, index) {
        console.log("event to be edited", filteredEventList[index])

        setOpenEditEvent(true);
        setEditEventTabValue(0);

        setEventId(filteredEventList[index].event_id);
        setEventTitle(filteredEventList[index].title);
        setCategory(filteredEventList[index].category);
        setDescription(filteredEventList[index].description);

        setAddEventStartDate(moment(filteredEventList[index].start_date));
        setAddEventEndDate(moment(filteredEventList[index].end_date));
        setAddEventStartDateTemp(moment(filteredEventList[index].start_date));
        setAddEventEndDateTemp(moment(filteredEventList[index].end_date));

        setDemandImpactRows(filteredEventList[index].demand_impacts)
        setAddEventNextBtnEnabled(true)
        setIsEditDateChanged(false);
    }
    function handleEditEventClose() {
        setOpenEditEvent(false);
    }
    const editEventTabChange = (event, newValue) => {
        if (newValue === 1) {
            editDemandImpactTab()
        }
        else {
            setEditEventTabValue(newValue);
        }
    };
    const editDemandImpactTab = () => {
        setEditEventTabValue(1);
        setAddEventSaveBtnEnabled(true);
        if (isEditDateChanged) {
            addDemandImpactTab();
        }
    };
    const handleSaveEditEvent = async () => {
        let payload = {
            event_id: eventId,
            title: eventTitle,
            category: category,
            start_date: dateFormat(addEventStartDate, "YYYY-MM-DD"),
            end_date: dateFormat(addEventEndDate, "YYYY-MM-DD"),
            description: description,
            demand_impacts: demandImpactRows
        }
        //console.log("Edit payload", payload);
        setIsSaving(true);
        try {
            const response = await DataService.update({
                api: "rms/properties/events/" + app_state.property_id + "/",
                request: payload
            });
            if (response.status === "success") {
                console.log("Event edited successfully");
                avgImpactGetData();
                EventsListGetData();
                handleEditEventClose();
                setIsSaving(false);

                //Tracking analytics
                const { property_id, property_name, workspace_controls } = app_state;
                const { title, event_id, category, created_at, created_by, demand_impacts, description, start_date, end_date, last_edited_at, last_edited_by } = response.data.data;
                const { demand_impact, day_type } = demand_impacts[0];
                //debugger;
                window.analyze.track("edit_event", {
                    // "selected_tile": ui_state.active_tiles.join(),
                    // primary_stay_range,
                    property_id, property_name,
                    // data_view: user_view,
                    // event_id: event_id,
                    event_title: title,
                    // event_category: category,
                    // event_start_date: start_date,
                    // event_end_date: end_date,
                    // event_description: description,
                    // event_demand_impact_name: getDemandImpactName(demand_impact),
                    // event_demand_impact_num: demand_impact,
                    // event_day_type: day_type,

                    // created_at: created_at,
                    created_by: created_by,
                    // last_edited_at, 
                    last_edited_by

                }, {}, {
                    app_state: {
                        workspace_controls,
                        // data_load: true
                    },
                    ui_state: { showspinner: true }
                });
            }
            else {
                console.log("Event not edited")
                setIsSaving(false);
            }
        }
        catch (err) {
            console.error('An error occurred', err);
            setIsSaving(false);
        }
    }


    //UPLOAD EVENTS STARTS
    const [openUploadEvent, setOpenUploadEvent] = useState(false);
    const [eventUploadSaveBtnEnabled, setEventUploadSaveBtnEnabled] = useState(false);
    const [eventUploadCancelBtnEnabled, setEventUploadCancelBtnEnabled] = useState(true);
    const [eventUploadStatus, setEventUploadStatus] = useState({ status: "", message: "" });
    const fileTypes = ["XLS", "XLSX"];
    const [fileData, setFileData] = useState("");
    const [fileName, setFileName] = useState("");

    function handleUploadEventOpen(event, index) {
        setOpenUploadEvent(true);
        setOpenAddEvent(false);
        setOpenEditEvent(false);
    }
    function handleUploadEventClose() {
        setOpenUploadEvent(false);
        setFileName("");
        setEventUploadSaveBtnEnabled(false);
        setEventUploadCancelBtnEnabled(true);
        setEventUploadStatus({ status: "", message: "" });
    }
    const handleChange = (file) => {
        if (file) {
            console.log('File selected:', file);
            setFileData(file);
            setFileName(file.name);
            setEventUploadSaveBtnEnabled(true);
        }
    };
    const uploadEventData = async () => {
        console.log('Uploading file:', fileData);
        let file = fileData;
        let formData = new FormData();

        if (file) {
            formData.append("file", file);
        }

        // Log the contents of formData
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }

        setEventUploadStatus({ status: "uploading", message: "Uploading..." });
        try {
            const response = await DataService.uploadFile({
                api: "rms/properties/upload-events-file/" + app_state.property_id + "/",
                request: formData
            });
            if (response.status === "success") {
                if (response.data.length !== 0) {
                    console.log("Event uploaded successfully");
                    avgImpactGetData();
                    EventsListGetData();

                    setEventUploadCancelBtnEnabled(false);
                    setEventUploadSaveBtnEnabled(false);
                    setEventUploadStatus({ status: "uploaded", message: "Uploaded" });

                    //Tracking analytics
                    // debugger;
                    const { property_id, property_name, workspace_controls } = app_state;
                    // const { title, event_id, category, created_at, created_by, demand_impacts, description, start_date, end_date, last_edited_at, last_edited_by } = response.data.data;
                    // const { demand_impact, day_type } = demand_impacts[0];
                    window.analyze.track("bulk_events_upload", {
                        // "selected_tile": ui_state.active_tiles.join(),
                        // primary_stay_range,
                        property_id, property_name,
                        number_of_events: response.data.length

                    }, {}, {
                        app_state: {
                            workspace_controls,
                            // data_load: true
                        },
                        ui_state: { showspinner: true }
                    });
                }
                else {
                    console.log("Event not uploaded successfully");
                    // avgImpactGetData();
                    //EventsListGetData();
                    //handleEditEventClose();

                    setEventUploadCancelBtnEnabled(false);
                    setEventUploadSaveBtnEnabled(false);
                    setEventUploadStatus({ status: "error", message: response.error[0].error });
                }
            }
            else {
                console.log("Event not uploaded");
                setEventUploadStatus({ status: "error", message: response.message });
            }
        } catch (err) {
            console.error('An error occurred', err);
            setEventUploadStatus({ status: "error", message: err.message });
        }
    };

    const [eventTemplateURL, setEventTemplateURL] = useState("");
    useEffect(() => {
        let request = { method: "GET" }
        fetchDataSimple({ api: "upload/templates/", request }).then(res => {
            console.log(res)
            if (res.status === "success") {
                setEventTemplateURL(res.templates.event);
            }
        }).catch(err => {
            console.log(err);
        })
    }, [])

    const downloadEventTemplate = () => {
        console.log("URL", eventTemplateURL)
        window.location.href = eventTemplateURL;
    }
    //UPLOAD EVENTS ENDS


    //GET API for date wise impact fetch 
    const avgImpactGetData = async () => {
        let yearStart = new Date(startDateAPI).getFullYear(); 
        let yearEnd = new Date(endDateAPI).getFullYear(); 
        let fromDate = `${yearStart}-01-01`;
        let toDate = `${yearEnd}-12-31`;
        setIsRequesting(true);
        try {
            const response = await DataService.getData({
                api: "rms/properties/events/" + app_state.property_id + "/?fromDate=" + fromDate + "&toDate=" + toDate + "&avgDemandImpact=true&isIgnored=false",
                request: { method: 'GET' }
            });
            if (response.status === "success") {
                console.log("Avg Impact data is fetched", response.data)
                //setAvgImpactData(response.data);
                app_state.avgImpactData = response.data;
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            avgImpactData: response.data
                        }
                    }
                })
                setIsRequesting(false);
            }
            else {
                console.log("Events List API status error")
                setIsRequesting(false);
            }
        }
        catch (error) {
            console.error('An error occurred', error);
            setIsRequesting(false);
        }
    }
    const avgImpactGetIgnoredData = async () => {
        let yearStart = new Date(startDateAPI).getFullYear(); 
        let yearEnd = new Date(endDateAPI).getFullYear(); 
        let fromDate = `${yearStart}-01-01`;
        let toDate = `${yearEnd}-12-31`;
        setIsRequesting(true);
        try {
            const response = await DataService.getData({
                api: "rms/properties/events/" + app_state.property_id + "/?fromDate=" + fromDate + "&toDate=" + toDate + "&avgDemandImpact=true&isIgnored=true",
                request: { method: 'GET' }
            });
            if (response.status === "success") {
                console.log("Avg Impact data is fetched", response.data)
                //setAvgImpactData(response.data);
                app_state.avgImpactData = response.data;
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            avgImpactData: response.data
                        }
                    }
                })
                setIsRequesting(false);
            }
            else {
                console.log("Events List API status error")
                setIsRequesting(false);
            }
        }
        catch (error) {
            console.error('An error occurred', error);
            setIsRequesting(false);
        }
    }
    //GET API event list fetch 
    const EventsListGetData = async () => {
        let yearStart = new Date(startDateAPI).getFullYear(); 
        let yearEnd = new Date(endDateAPI).getFullYear(); 
        let fromDate = `${yearStart}-01-01`;
        let toDate = `${yearEnd}-12-31`;

        setIsRequesting(true);
        try {
            const response = await DataService.getData({
                api: "rms/properties/events/" + app_state.property_id + "/?fromDate=" + fromDate + "&toDate=" + toDate + "",
                request: { method: 'GET' }
            });
            if (response.status === "success") {
                console.log("Events List is fetched", response.data)
                //setEventList(response.data);
                app_state.eventList = response.data;
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            eventList: response.data
                        }
                    }
                })
                setIsRequesting(false);
            }
            else {
                console.log("Events List API status error")
                setIsRequesting(false);
            }
        }
        catch (error) {
            console.error('An error occurred', error);
            setIsRequesting(false);
        }
    }

    const avgImpactGetData2 = async (fromDate, toDate) => {
        try {
            const response = await DataService.getData({
                api: "rms/properties/events/" + app_state.property_id + "/?fromDate=" + fromDate + "&toDate=" + toDate + "&avgDemandImpact=true&isIgnored=false",
                request: { method: 'GET' }
            });
            if (response.status === "success") {
                console.log("Avg Impact data is fetched", response.data)
                //setAvgImpactData(response.data);
                app_state.avgImpactData = response.data;
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            avgImpactData: response.data
                        }
                    }
                })
            }
            else {
                console.log("Events List API status error")
            }
        }
        catch (error) {
            console.error('An error occurred', error);
        }
    }
    const EventsListGetData2 = async (fromDate, toDate) => {
        setIsRequesting(true);
        try {
            const response = await DataService.getData({
                api: "rms/properties/events/" + app_state.property_id + "/?fromDate=" + fromDate + "&toDate=" + toDate + "",
                request: { method: 'GET' }
            });
            if (response.status === "success") {
                console.log("Events List is fetched", response.data)
                //setEventList(response.data);
                app_state.eventList = response.data;
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            eventList: response.data
                        }
                    }
                })
                setIsRequesting(false);
            }
            else {
                console.log("Events List API status error")
                setIsRequesting(false);
            }
        }
        catch (error) {
            console.error('An error occurred', error);
            setIsRequesting(false);
        }
    }


    const [hovered, setHovered] = useState(false);
    const [hoveredSticky, setHoveredSticky] = useState(false);
    const handleHover = () => {
        setHovered(true);
    };
    const handleHoverOut = () => {
        setHovered(false);
    };
    const toggleHover = () => {
        if (hovered) {
            setHovered(false);
        }
        else {
            setHovered(true);
            setHoveredSticky(true);
        }
    };



    return (
        <ThemeProvider theme={theme}>
            {/* Header Start */}
            <Box>
                <Header />
            </Box>
            {/* Header End */}

            <Box sx={{ pt: "60px", height: "100%", pl: 2, pr: 2 }}>

                <Box className='top-axn-bar-1'>
                    <Tooltip title="Back to Dashboard" arrow followCursor >
                        <Button
                            size="medium"
                            variant="contained"
                            className='back-to-dash-btn'
                            onClick={goBackToDashboard}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                                <g id="Group_2234" data-name="Group 2234" transform="translate(24154 19449)">
                                    <path id="Path_3278" data-name="Path 3278" d="M.889,0h8.3a.889.889,0,0,1,.889.889V9.482a.889.889,0,0,1-.889.889H.889A.889.889,0,0,1,0,9.482V.889A.889.889,0,0,1,.889,0Z" transform="translate(-24154 -19449)" fill="#606060" />
                                    <g id="Path_3279" data-name="Path 3279" transform="translate(-24142.74 -19449)" fill="#333">
                                        <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                        <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                    </g>
                                    <g id="Path_3280" data-name="Path 3280" transform="translate(-24142.74 -19443.371)" fill="#333">
                                        <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                        <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                    </g>
                                    <g id="Path_3283" data-name="Path 3283" transform="translate(-24142.74 -19437.74)" fill="#333">
                                        <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                        <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                    </g>
                                    <g id="Path_3281" data-name="Path 3281" transform="translate(-24154 -19437.74)" fill="#333">
                                        <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                        <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                    </g>
                                    <g id="Path_3282" data-name="Path 3282" transform="translate(-24148.369 -19437.74)" fill="#333">
                                        <path d="M 3.852076530456543 4.341016292572021 L 0.8889365196228027 4.341016292572021 C 0.6193365454673767 4.341016292572021 0.3999965190887451 4.121676445007324 0.3999965190887451 3.852076530456543 L 0.3999965190887451 0.8889365196228027 C 0.3999965190887451 0.6193365454673767 0.6193365454673767 0.3999965190887451 0.8889365196228027 0.3999965190887451 L 3.852076530456543 0.3999965190887451 C 4.121676445007324 0.3999965190887451 4.341016292572021 0.6193365454673767 4.341016292572021 0.8889365196228027 L 4.341016292572021 3.852076530456543 C 4.341016292572021 4.121676445007324 4.121676445007324 4.341016292572021 3.852076530456543 4.341016292572021 Z" stroke="none" />
                                        <path d="M 0.8889365196228027 0.7999966144561768 C 0.8398964405059814 0.7999966144561768 0.7999966144561768 0.8398964405059814 0.7999966144561768 0.8889365196228027 L 0.7999966144561768 3.852076530456543 C 0.7999966144561768 3.901116609573364 0.8398964405059814 3.941016674041748 0.8889365196228027 3.941016674041748 L 3.852076530456543 3.941016674041748 C 3.901116609573364 3.941016674041748 3.941016674041748 3.901116609573364 3.941016674041748 3.852076530456543 L 3.941016674041748 0.8889365196228027 C 3.941016674041748 0.8398964405059814 3.901116609573364 0.7999966144561768 3.852076530456543 0.7999966144561768 L 0.8889365196228027 0.7999966144561768 M 0.8889365196228027 -3.337860107421875e-06 L 3.852076530456543 -3.337860107421875e-06 C 4.343016624450684 -3.337860107421875e-06 4.741016387939453 0.3979964256286621 4.741016387939453 0.8889365196228027 L 4.741016387939453 3.852076530456543 C 4.741016387939453 4.343016624450684 4.343016624450684 4.741016387939453 3.852076530456543 4.741016387939453 L 0.8889365196228027 4.741016387939453 C 0.3979964256286621 4.741016387939453 -3.337860107421875e-06 4.343016624450684 -3.337860107421875e-06 3.852076530456543 L -3.337860107421875e-06 0.8889365196228027 C -3.337860107421875e-06 0.3979964256286621 0.3979964256286621 -3.337860107421875e-06 0.8889365196228027 -3.337860107421875e-06 Z" stroke="none" fill="#333" />
                                    </g>
                                </g>
                            </svg>
                            <span className='back-to-dash-txt'>Back to Dashboard</span>
                        </Button>
                    </Tooltip>

                    <Box className="event-page-title">
                        {eventsIconBlack}
                        Events & Holidays
                    </Box>

                    <Box className="action-buttons">
                        <div
                            className="slider"
                            style={{ transform: isIgnoredList ? 'translateX(100%)' : 'translateX(0)' }}
                        />
                        <button
                            className={!isIgnoredList ? 'active-btn enabled' : 'active-btn'}
                            onClick={showActiveList}
                        >
                            Active
                        </button>
                        <button
                            className={isIgnoredList ? 'ignored-btn enabled' : 'ignored-btn'}
                            onClick={showIgnoredList}
                        >
                            Ignored
                        </button>
                    </Box>

                </Box>

                <Box className="events-home-page-container">
                    <Box className="events-home-page-left">
                        <Box className="week-days-heading">
                            <div>Sun</div>
                            <div>Mon</div>
                            <div>Tue</div>
                            <div>Wed</div>
                            <div>Thu</div>
                            <div>Fri</div>
                            <div>Sat</div>
                        </Box>
                        <div className="calendar-container" onScroll={handleScroll} ref={containerRef}>
                            {dates.map((date, index) => (
                                <Calendar
                                    key={index}
                                    value={date}
                                    calendarType="US"
                                    tileClassName={tileClassName}
                                    onChange={handleSelectedDate}
                                />
                            ))}
                        </div>
                    </Box>

                    <Box className="events-home-page-right">
                        <Box className="right-header">
                            <div className="header-date">{dateFormat(selectedDate, "ddd, DD MMM, YYYY")}</div>
                        </Box>

                        <Box className="right-body">
                            {/* Empty Active Events screen*/}
                            {!isIgnoredList && filteredEventList.length === 0 ? (
                                <Box className="top-axn-bar-1" sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: "250px", color: "#606060" }}>
                                    <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M54.9361 9.56692V38.0395C54.9361 39.2565 54.453 40.4216 53.5937 41.284L40.784 54.0937C39.9247 54.953 38.7565 55.4361 37.5395 55.4361H9.06692M9.06692 40.4858V14.1538C9.06692 11.6219 11.1219 9.56692 13.6538 9.56692H39.9858M24.0172 4.41428V14.7196M39.9828 4.41428V9.56692M62 2.5L2 62.5M53.7955 41.0791L40.5791 54.2955C40.1571 54.7175 39.4385 54.4178 39.4385 53.8215V43.1921C39.4385 41.394 40.894 39.9385 42.6921 39.9385H53.3215C53.9178 39.9385 54.2145 40.6571 53.7955 41.0791Z" stroke="#67C8F5" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <div>No Active Events are Available on this Date. You Can Always Add an Event.</div>
                                </Box>
                            ) : null}

                            {/* Empty Ignored Events screen*/}
                            {isIgnoredList && filteredIgnoredEventList.length === 0 ? (
                                <Box className="top-axn-bar-1" sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: "250px", color: "#606060" }}>
                                    <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M54.9361 9.56692V38.0395C54.9361 39.2565 54.453 40.4216 53.5937 41.284L40.784 54.0937C39.9247 54.953 38.7565 55.4361 37.5395 55.4361H9.06692M9.06692 40.4858V14.1538C9.06692 11.6219 11.1219 9.56692 13.6538 9.56692H39.9858M24.0172 4.41428V14.7196M39.9828 4.41428V9.56692M62 2.5L2 62.5M53.7955 41.0791L40.5791 54.2955C40.1571 54.7175 39.4385 54.4178 39.4385 53.8215V43.1921C39.4385 41.394 40.894 39.9385 42.6921 39.9385H53.3215C53.9178 39.9385 54.2145 40.6571 53.7955 41.0791Z" stroke="#67C8F5" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <div>No Ignored Events are Available on this Date.</div>
                                </Box>
                            ) : null}


                            {/* Active Events to be looped */}
                            {!isIgnoredList && filteredEventList.map((event, index) => (
                                <Box key={index} className="events-accordion">
                                    <Box className="events-list">
                                        <div className="title-row">
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                                <div className="event-title">{event.title}</div>
                                                <div className="event-date" style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                    {event.start_date === event.end_date ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#00415F" viewBox="0 0 256 256">
                                                            <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-64-56a16,16,0,1,1-16-16A16,16,0,0,1,144,152Z"></path>
                                                        </svg>
                                                        :
                                                        <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.489716 1.27472H2.60529V2.05827C2.60529 2.32761 2.82566 2.54798 3.09501 2.54798C3.36435 2.54798 3.58472 2.32761 3.58472 2.05827V1.27472H5.54358V2.05827C5.54358 2.32761 5.76396 2.54798 6.0333 2.54798C6.30264 2.54798 6.52302 2.32761 6.52302 2.05827V1.27472H8.48188V2.05827C8.48188 2.32761 8.70225 2.54798 8.9716 2.54798C9.24094 2.54798 9.46131 2.32761 9.46131 2.05827V1.27472H11.4202V2.05827C11.4202 2.32761 11.6405 2.54798 11.9099 2.54798C12.1792 2.54798 12.3996 2.32761 12.3996 2.05827V1.27472H14.5103C14.7796 1.27472 15 1.05435 15 0.785004C15 0.51566 14.7796 0.295288 14.5103 0.295288H0.489716C0.220372 0.295288 0 0.51566 0 0.785004C0 1.05435 0.220372 1.27472 0.489716 1.27472Z" fill="#00415F" />
                                                            <path d="M13.4574 5.04553C13.4574 5.04553 13.4574 5.02594 13.4525 5.01615C13.4231 4.94759 13.3839 4.88882 13.3301 4.83985L11.6797 3.18951C11.4887 2.99852 11.1802 2.99852 10.9892 3.18951C10.7982 3.3805 10.7982 3.68902 10.9892 3.88001L11.8168 4.70763H3.19295L4.02057 3.88001C4.21156 3.68902 4.21156 3.3805 4.02057 3.18951C3.82958 2.99852 3.52106 2.99852 3.33007 3.18951L1.67973 4.83985C1.62586 4.88882 1.58178 4.94759 1.5573 5.01615C1.5573 5.02594 1.5573 5.04064 1.5524 5.05043C1.53771 5.0994 1.52302 5.14348 1.52302 5.19735C1.52302 5.32467 1.57199 5.452 1.66503 5.54504L3.19295 7.07296C3.29089 7.1709 3.41332 7.21497 3.54065 7.21497C3.66797 7.21497 3.7904 7.166 3.88835 7.07296C4.07933 6.88197 4.07933 6.57345 3.88835 6.38246L3.19295 5.68706H11.8315L11.1361 6.38246C10.9452 6.57345 10.9452 6.88197 11.1361 7.07296C11.2341 7.1709 11.3565 7.21497 11.4838 7.21497C11.6112 7.21497 11.7336 7.166 11.8315 7.07296L13.3595 5.54504C13.4574 5.4471 13.5015 5.32467 13.5015 5.19735C13.5015 5.14348 13.4868 5.0945 13.4721 5.05043L13.4574 5.04553Z" fill="#00415F" />
                                                            <path d="M14.5103 2.6802C14.2409 2.6802 14.0206 2.90058 14.0206 3.16992V7.21497C14.0206 7.48432 14.2409 7.70469 14.5103 7.70469C14.7796 7.70469 15 7.48432 15 7.21497V3.16992C15 2.90058 14.7796 2.6802 14.5103 2.6802Z" fill="#00415F" />
                                                            <path d="M0.489716 2.6802C0.220372 2.6802 0 2.90058 0 3.16992V7.21497C0 7.48432 0.220372 7.70469 0.489716 7.70469C0.75906 7.70469 0.979432 7.48432 0.979432 7.21497V3.16992C0.979432 2.90058 0.75906 2.6802 0.489716 2.6802Z" fill="#00415F" />
                                                        </svg>
                                                    }
                                                    <div>{event.start_date === event.end_date ? dateFormat(event.start_date, "ddd, DD MMM, YYYY") : dateFormat(event.start_date, "ddd, DD MMM, YYYY") + " - " + dateFormat(event.end_date, "ddd, DD MMM, YYYY")}</div>
                                                </div>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                <Tooltip title="Move to Ignore" arrow>
                                                    <svg className="event-edit-delete-icons" onClick={(event) => handleDeleteEvent(event, index)} xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="#606060" viewBox="0 0 256 256"><path d="M176,128a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,128Zm56,0A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path></svg>
                                                </Tooltip>
                                                <Tooltip title="Edit" arrow>
                                                    <svg className="event-edit-delete-icons" onClick={(event) => handleEditEventOpen(event, index)} xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#606060" viewBox="0 0 256 256">
                                                        <path d="M227.32,73.37,182.63,28.69a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16H115.32l112-112A16,16,0,0,0,227.32,73.37ZM136,75.31,152.69,92,68,176.69,51.31,160ZM48,208V179.31L76.69,208Zm48-3.31L79.32,188,164,103.31,180.69,120Zm96-96L147.32,64l24-24L216,84.69Z"></path>
                                                    </svg>
                                                </Tooltip>
                                            </Box>
                                        </div>

                                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                            <div className="category-row">
                                                Category: <span>{event.category}</span>
                                            </div>
                                            <div className="devider"></div>
                                            {event.demand_impacts.map((impact, impactIndex) => (
                                                impact.date !== selectedDate ? null :
                                                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                        <div className="category-row">
                                                            Day Type: <span>{impact.day_type}</span>
                                                        </div>
                                                        <div className="devider"></div>
                                                        <div className="category-row" style={{ display: "flex", alignItems: "center" }}>
                                                            Demand Impact:
                                                            <div style={{ display: "flex", alignItems: "center", gap: "4px", paddingLeft: "4px" }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill={getDemandImpactColor(impact.demand_impact)} viewBox="0 0 256 256">
                                                                    <path d="M246.66,132.44,201,200.88A16,16,0,0,1,187.72,208H32a8,8,0,0,1-6.66-12.44L70.39,128l-45-67.56A8,8,0,0,1,32,48H187.72A16,16,0,0,1,201,55.12l45.63,68.44A8,8,0,0,1,246.66,132.44Z"></path>
                                                                </svg>
                                                                <div style={{ fontFamily: "SFUI Medium" }}>{getDemandImpactName(impact.demand_impact)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            ))}
                                        </div>

                                        <div className="description-row">
                                            Description - {event.description}
                                        </div>

                                        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#606060" viewBox="0 0 256 256">
                                                <path d="M229.66,58.34l-32-32a8,8,0,0,0-11.32,0l-96,96A8,8,0,0,0,88,128v32a8,8,0,0,0,8,8h32a8,8,0,0,0,5.66-2.34l96-96A8,8,0,0,0,229.66,58.34ZM124.69,152H104V131.31l64-64L188.69,88ZM200,76.69,179.31,56,192,43.31,212.69,64ZM224,128v80a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h80a8,8,0,0,1,0,16H48V208H208V128a8,8,0,0,1,16,0Z"></path>
                                            </svg>
                                            <div style={{ fontFamily: "SFUI Regular", color: "#606060", fontSize: "12px" }}>{event.last_edited_by}</div>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                            {/* Active Events Loop ends here */}

                            {/* Ignored Events to be looped */}
                            {isIgnoredList && filteredIgnoredEventList.map((event, index) => (
                                <Box key={index} className="events-accordion">
                                    <Box className="events-list">
                                        <div className="title-row">
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                                <div className="event-title">{event.title}</div>
                                                <div className="event-date" style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                    {event.start_date === event.end_date ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#00415F" viewBox="0 0 256 256">
                                                            <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-64-56a16,16,0,1,1-16-16A16,16,0,0,1,144,152Z"></path>
                                                        </svg>
                                                        :
                                                        <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.489716 1.27472H2.60529V2.05827C2.60529 2.32761 2.82566 2.54798 3.09501 2.54798C3.36435 2.54798 3.58472 2.32761 3.58472 2.05827V1.27472H5.54358V2.05827C5.54358 2.32761 5.76396 2.54798 6.0333 2.54798C6.30264 2.54798 6.52302 2.32761 6.52302 2.05827V1.27472H8.48188V2.05827C8.48188 2.32761 8.70225 2.54798 8.9716 2.54798C9.24094 2.54798 9.46131 2.32761 9.46131 2.05827V1.27472H11.4202V2.05827C11.4202 2.32761 11.6405 2.54798 11.9099 2.54798C12.1792 2.54798 12.3996 2.32761 12.3996 2.05827V1.27472H14.5103C14.7796 1.27472 15 1.05435 15 0.785004C15 0.51566 14.7796 0.295288 14.5103 0.295288H0.489716C0.220372 0.295288 0 0.51566 0 0.785004C0 1.05435 0.220372 1.27472 0.489716 1.27472Z" fill="#00415F" />
                                                            <path d="M13.4574 5.04553C13.4574 5.04553 13.4574 5.02594 13.4525 5.01615C13.4231 4.94759 13.3839 4.88882 13.3301 4.83985L11.6797 3.18951C11.4887 2.99852 11.1802 2.99852 10.9892 3.18951C10.7982 3.3805 10.7982 3.68902 10.9892 3.88001L11.8168 4.70763H3.19295L4.02057 3.88001C4.21156 3.68902 4.21156 3.3805 4.02057 3.18951C3.82958 2.99852 3.52106 2.99852 3.33007 3.18951L1.67973 4.83985C1.62586 4.88882 1.58178 4.94759 1.5573 5.01615C1.5573 5.02594 1.5573 5.04064 1.5524 5.05043C1.53771 5.0994 1.52302 5.14348 1.52302 5.19735C1.52302 5.32467 1.57199 5.452 1.66503 5.54504L3.19295 7.07296C3.29089 7.1709 3.41332 7.21497 3.54065 7.21497C3.66797 7.21497 3.7904 7.166 3.88835 7.07296C4.07933 6.88197 4.07933 6.57345 3.88835 6.38246L3.19295 5.68706H11.8315L11.1361 6.38246C10.9452 6.57345 10.9452 6.88197 11.1361 7.07296C11.2341 7.1709 11.3565 7.21497 11.4838 7.21497C11.6112 7.21497 11.7336 7.166 11.8315 7.07296L13.3595 5.54504C13.4574 5.4471 13.5015 5.32467 13.5015 5.19735C13.5015 5.14348 13.4868 5.0945 13.4721 5.05043L13.4574 5.04553Z" fill="#00415F" />
                                                            <path d="M14.5103 2.6802C14.2409 2.6802 14.0206 2.90058 14.0206 3.16992V7.21497C14.0206 7.48432 14.2409 7.70469 14.5103 7.70469C14.7796 7.70469 15 7.48432 15 7.21497V3.16992C15 2.90058 14.7796 2.6802 14.5103 2.6802Z" fill="#00415F" />
                                                            <path d="M0.489716 2.6802C0.220372 2.6802 0 2.90058 0 3.16992V7.21497C0 7.48432 0.220372 7.70469 0.489716 7.70469C0.75906 7.70469 0.979432 7.48432 0.979432 7.21497V3.16992C0.979432 2.90058 0.75906 2.6802 0.489716 2.6802Z" fill="#00415F" />
                                                        </svg>
                                                    }
                                                    <div>{event.start_date === event.end_date ? dateFormat(event.start_date, "ddd, DD MMM, YYYY") : dateFormat(event.start_date, "ddd, DD MMM, YYYY") + " - " + dateFormat(event.end_date, "ddd, DD MMM, YYYY")}</div>
                                                </div>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                                                <Tooltip title="Move to Active" arrow>
                                                    <svg className="event-edit-delete-icons" onClick={(event) => handleRestoreEvent(event, index)} xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#606060" viewBox="0 0 256 256"><path d="M90.34,61.66a8,8,0,0,1,0-11.32l32-32a8,8,0,0,1,11.32,0l32,32a8,8,0,0,1-11.32,11.32L136,43.31V96a8,8,0,0,1-16,0V43.31L101.66,61.66A8,8,0,0,1,90.34,61.66Zm64,132.68L136,212.69V160a8,8,0,0,0-16,0v52.69l-18.34-18.35a8,8,0,0,0-11.32,11.32l32,32a8,8,0,0,0,11.32,0l32-32a8,8,0,0,0-11.32-11.32Zm83.32-72-32-32a8,8,0,0,0-11.32,11.32L212.69,120H160a8,8,0,0,0,0,16h52.69l-18.35,18.34a8,8,0,0,0,11.32,11.32l32-32A8,8,0,0,0,237.66,122.34ZM43.31,136H96a8,8,0,0,0,0-16H43.31l18.35-18.34A8,8,0,0,0,50.34,90.34l-32,32a8,8,0,0,0,0,11.32l32,32a8,8,0,0,0,11.32-11.32Z"></path></svg>
                                                </Tooltip>
                                            </Box>
                                        </div>

                                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                            <div className="category-row">
                                                Category: <span>{event.category}</span>
                                            </div>
                                            <div className="devider"></div>
                                            {event.demand_impacts.map((impact, impactIndex) => (
                                                impact.date !== selectedDate ? null :
                                                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                                        <div className="category-row">
                                                            Day Type: <span>{impact.day_type}</span>
                                                        </div>
                                                        <div className="devider"></div>
                                                        <div className="category-row" style={{ display: "flex", alignItems: "center" }}>
                                                            Demand Impact:
                                                            <div style={{ display: "flex", alignItems: "center", gap: "4px", paddingLeft: "4px" }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill={getDemandImpactColor(impact.demand_impact)} viewBox="0 0 256 256">
                                                                    <path d="M246.66,132.44,201,200.88A16,16,0,0,1,187.72,208H32a8,8,0,0,1-6.66-12.44L70.39,128l-45-67.56A8,8,0,0,1,32,48H187.72A16,16,0,0,1,201,55.12l45.63,68.44A8,8,0,0,1,246.66,132.44Z"></path>
                                                                </svg>
                                                                <div style={{ fontFamily: "SFUI Medium" }}>{getDemandImpactName(impact.demand_impact)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            ))}
                                        </div>

                                        <div className="description-row">
                                            Description - {event.description}
                                        </div>

                                        <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#606060" viewBox="0 0 256 256">
                                                <path d="M229.66,58.34l-32-32a8,8,0,0,0-11.32,0l-96,96A8,8,0,0,0,88,128v32a8,8,0,0,0,8,8h32a8,8,0,0,0,5.66-2.34l96-96A8,8,0,0,0,229.66,58.34ZM124.69,152H104V131.31l64-64L188.69,88ZM200,76.69,179.31,56,192,43.31,212.69,64ZM224,128v80a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32h80a8,8,0,0,1,0,16H48V208H208V128a8,8,0,0,1,16,0Z"></path>
                                            </svg>
                                            <div style={{ fontFamily: "SFUI Regular", color: "#606060", fontSize: "12px" }}>{event.last_edited_by}</div>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                            {/* Ignored Events Loop ends here */}

                        </Box>

                        {isIgnoredList ? null :
                            <Box
                                className="add-upload-btns-container"
                                onMouseEnter={handleHover}
                                onMouseLeave={handleHoverOut}
                            >
                                <Button
                                    onClick={handleUploadEventOpen}
                                    variant="outlined"
                                    size="small"
                                    className={hovered ? "upload-event-btn visible" : "upload-event-btn"}
                                    sx={!hovered ? { display: "none" } : { display: "all" }}
                                >
                                    <div className="btn-container">
                                        <span style={{ marginTop: "6px" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00415F" viewBox="0 0 256 256"><path d="M178.34,165.66,160,147.31V208a8,8,0,0,1-16,0V147.31l-18.34,18.35a8,8,0,0,1-11.32-11.32l32-32a8,8,0,0,1,11.32,0l32,32a8,8,0,0,1-11.32,11.32ZM160,40A88.08,88.08,0,0,0,81.29,88.68,64,64,0,1,0,72,216h40a8,8,0,0,0,0-16H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.12A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,100.8,66,8,8,0,0,0,3.2,15.34,7.9,7.9,0,0,0,3.2-.68A88,88,0,0,0,160,40Z"></path></svg>
                                        </span>
                                        <span>
                                            Upload Event Calendar
                                        </span>
                                    </div>
                                </Button>

                                <Button
                                    onClick={handleAddEventOpen}
                                    variant="outlined"
                                    size="small"
                                    className={hovered ? "add-event-btn visible" : "add-event-btn"}
                                    sx={!hovered ? { display: "none" } : { display: "all" }}
                                >
                                    <div className="btn-container">
                                        <span style={{ marginTop: "6px" }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00415F" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-48-56a8,8,0,0,1-8,8H136v16a8,8,0,0,1-16,0V160H104a8,8,0,0,1,0-16h16V128a8,8,0,0,1,16,0v16h16A8,8,0,0,1,160,152Z"></path></svg>
                                        </span>
                                        <span>
                                            Add Event
                                        </span>
                                    </div>
                                </Button>

                                <Button
                                    variant="outlined"
                                    className={hovered ? "add-upload-button rotate" : "add-upload-button"}
                                    size="small"
                                    sx={{ lineHeight: "auto" }}
                                //onClick={toggleHover}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#333333" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H136v80a8,8,0,0,1-16,0V136H40a8,8,0,0,1,0-16h80V40a8,8,0,0,1,16,0v80h80A8,8,0,0,1,224,128Z"></path></svg>

                                </Button>
                            </Box>
                        }
                    </Box>
                </Box>

            </Box>

            {/* Add Event Popup */}
            <Modal
                open={openAddEvent}
                onClose={handleAddEventClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="add-event-popup" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '750px',
                    height: '422px',
                    bgcolor: '#fff',
                    boxShadow: 24, borderRadius: '8px'
                }}>
                    <div className="pop-up-header clearfix request-popup-header">
                        <Box sx={{ float: 'left' }}>
                            <div className="tile-title" data-tile-icon="icon-icon-events" data-id="events">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-48-56a8,8,0,0,1-8,8H136v16a8,8,0,0,1-16,0V160H104a8,8,0,0,1,0-16h16V128a8,8,0,0,1,16,0v16h16A8,8,0,0,1,160,152Z"></path></svg>
                                <div className="tile-name">Add Event</div>
                            </div>
                        </Box>
                        <Box sx={{ float: 'right' }}>
                            <Button className='cross-btn'
                                sx={{
                                    minWidth: 'auto', width: '22px', height: '22px', p: '7px', borderRadius: '50%', backgroundColor: 'black', color: 'white', fontSize: '10px', '&:hover': { background: '#000' }
                                }} onClick={handleAddEventClose}>
                                <CloseSharpIcon fontSize='small' background="black" />
                            </Button>
                        </Box>
                    </div>

                    <Box className="event-tab-container" p={1}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs className="tabs" value={addEventTabValue} onChange={addEventTabChange} aria-label="basic tabs example">
                                <Tab className="event-tabs" label="Event Details" {...a11yProps(0)} />
                                <Tab className="event-tabs" label="Demand Impact" {...a11yProps(1)} />
                            </Tabs>
                        </Box>

                        {/* Events Detalis Tab */}
                        <CustomTabPanel className="tab-contents" value={addEventTabValue} index={0}>
                            <Box>
                                <div className="form-label">Event Title*</div>
                                <TextField
                                    value={eventTitle}
                                    className="form-textfield"
                                    placeholder="Add Event Title"
                                    onChange={handleEventTitleChange}
                                    autoFocus
                                ></TextField>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                <Box sx={{ width: "100%" }}>
                                    <div className="form-label">Category*</div>
                                    <FormControl fullWidth>
                                        <Select
                                            value={category}
                                            onChange={handleCategoryChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            className="event-category-dropdown"
                                            IconComponent={CustomDropdownIcon}
                                        >
                                            <MenuItem value="">
                                                <em>Select Category</em>
                                            </MenuItem>
                                            <MenuItem value="Sports">Sports</MenuItem>
                                            <MenuItem value="Conference">Conference</MenuItem>
                                            <MenuItem value="Concerts">Concerts</MenuItem>
                                            <MenuItem value="Academic">Academic</MenuItem>
                                            <MenuItem value="Festivals">Festivals</MenuItem>
                                            <MenuItem value="Public Holidays">Public Holidays</MenuItem>
                                            <MenuItem value="School Holidays">School Holidays</MenuItem>
                                            <MenuItem value="Observances">Observances</MenuItem>
                                            <MenuItem value="Weather">Weather</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                    <div className="form-label">Date Range*</div>
                                    {/* <TextField className="form-textfield" placeholder="Add Event Title"></TextField> */}
                                    <TextField
                                        tabIndex={1}
                                        onKeyDown={preventTyping}
                                        onClick={toggleDateRangePickerVisiblity}
                                        style={{ cursor: "pointer" }}
                                        fullWidth
                                        className="form-textfield"
                                        size="small"
                                        value={dateFormat(addEventStartDate, "YYYY-MM-DD") === dateFormat(addEventEndDate, "YYYY-MM-DD") ? dateFormat(addEventStartDate, "ddd, MMM DD YYYY") : dateFormat(addEventStartDate, "ddd, MMM DD YYYY") + " - " + dateFormat(addEventEndDate, "ddd, MMM DD YYYY")}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={toggleDateRangePickerVisiblity} edge="end">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00415F" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-96-88v64a8,8,0,0,1-16,0V132.94l-4.42,2.22a8,8,0,0,1-7.16-14.32l16-8A8,8,0,0,1,112,120Zm59.16,30.45L152,176h16a8,8,0,0,1,0,16H136a8,8,0,0,1-6.4-12.8l28.78-38.37A8,8,0,1,0,145.07,132a8,8,0,1,1-13.85-8A24,24,0,0,1,176,136,23.76,23.76,0,0,1,171.16,150.45Z"></path></svg>
                                                </IconButton>
                                            ),
                                        }}
                                    />

                                </Box>
                                {/* Date ramge picker */}
                                <Grid item
                                    className={
                                        dateRangePickerVisiblity ? "date-range-picker-container" : "date-range-picker-container-false"
                                    }
                                >
                                    <div className='top-bar-item'>
                                        <DateRangePicker
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            direction="horizontal"
                                            ranges={[
                                                {
                                                    startDate: new Date(addEventStartDateTemp),
                                                    endDate: new Date(addEventEndDateTemp),
                                                    key: 'selection',
                                                },
                                            ]}
                                            //minDate={new Date()} // disabled previous date from current date
                                            onChange={(ranges) => {
                                                // Update arrival and departure dates based on user selection
                                                console.log("addEventStartDate", ranges.selection.startDate);
                                                console.log("addEventEndDate", ranges.selection.endDate);
                                                setAddEventStartDateTemp(ranges.selection.startDate);
                                                setAddEventEndDateTemp(ranges.selection.endDate);
                                            }}
                                        />
                                        <Box sx={{ background: "#FFF" }} className="datepicker-done-btn"><Button onClick={toggleDateRangePickerVisiblity}>Done</Button></Box>
                                    </div>
                                </Grid>
                            </Box>
                            <Box>
                                <div className="form-label">Description</div>
                                <Box className="form-textfield">
                                    <textarea
                                        value={description}
                                        className="form-textarea"
                                        placeholder="Add Description About Event"
                                        onChange={handleDescriptionChange}

                                    ></textarea>
                                </Box>
                            </Box>

                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Box sx={{ opacity: 0, pointerEvents: "none", display: "flex", alignItems: "center", gap: "8px" }} onClick={handleUploadEventOpen}>
                                    <svg width="15" height="15" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 1.5H9.5V1C9.5 0.72 9.28 0.5 9 0.5C8.72 0.5 8.5 0.72 8.5 1V1.5H3.5V1C3.5 0.72 3.28 0.5 3 0.5C2.72 0.5 2.5 0.72 2.5 1V1.5H1C0.45 1.5 0 1.95 0 2.5V12.5C0 13.05 0.45 13.5 1 13.5H11C11.56 13.5 12 13.05 12 12.5V2.5C12 1.95 11.56 1.5 11 1.5ZM11 12.5H1V5.5H11V12.5ZM11 4.5H1V2.5H2.5V3C2.5 3.27 2.73 3.5 3 3.5C3.27 3.5 3.5 3.27 3.5 3V2.5H8.5V3C8.5 3.27 8.73 3.5 9 3.5C9.27 3.5 9.5 3.27 9.5 3V2.5H11V4.5Z" fill="#00415F" />
                                        <path d="M7.51 7.74L6.34 6.57C6.14 6.37 5.83 6.37 5.63 6.57L4.48 7.72C4.28 7.92 4.28 8.23 4.48 8.43C4.68 8.63 4.99 8.63 5.19 8.43L5.49 8.13V10.51C5.49 10.78 5.71 11.01 5.99 11.01C6.27 11.01 6.49 10.78 6.49 10.51V8.15L6.79 8.45C6.89 8.55 7.02 8.6 7.14 8.6C7.26 8.6 7.4 8.55 7.49 8.45C7.69 8.25 7.69 7.94 7.49 7.74H7.51Z" fill="#00415F" />
                                    </svg>
                                    <div className="upload-event-calendar">Upload Event Calendar</div>
                                </Box>
                                <Button
                                    className="add-demand-impacts-btn"
                                    onClick={addDemandImpactTab}
                                    disabled={!addEventNextBtnEnabled}
                                    sx={!addEventNextBtnEnabled ? { opacity: "0.4" } : {}}
                                >
                                    Next
                                </Button>
                            </Box>
                        </CustomTabPanel>

                        {/* Demand Impact Tab */}
                        <CustomTabPanel className="tab-contents" value={addEventTabValue} index={1}>

                            <TableContainer component={Paper} className="demand-impact-table">
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="table-row">
                                            <TableCell className="table-header" align="left">Date</TableCell>
                                            <TableCell className="table-header" align="left">Day Type</TableCell>
                                            <TableCell className="table-header" align="left" style={{ paddingLeft: "12px" }}>Demand Impact</TableCell>
                                            <TableCell className="table-header" align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {demandImpactRows.map((row, index) => (
                                            <TableRow
                                                className="table-row"
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell className="table-cell" align="left" sx={{ width: '130px' }}>
                                                    {dateFormat(row.date, "ddd, DD MMM, YYYY")}
                                                </TableCell>

                                                <TableCell className="table-cell" align="left" sx={{ width: "170px" }}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            value={row.day_type}
                                                            onChange={(event) => handleDayTypeChange(event, index)}
                                                            displayEmpty
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            className="event-category-dropdown day-type"
                                                            IconComponent={CustomDropdownIcon2}
                                                        >
                                                            {dayTypes.map((dayType, dayTypeIndex) => (
                                                                <MenuItem key={dayTypeIndex} value={dayType}>
                                                                    {dayType}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>

                                                <TableCell className="table-cell" align="right" sx={{ width: '200px' }}>
                                                    <Box sx={{ position: "relative", padding: "2px" }}>
                                                        <Box>
                                                            <ThemeProvider theme={theme}>
                                                                <Box sx={{ width: 200 }}>
                                                                    <Slider
                                                                        value={row.demand_impact}
                                                                        onChange={(event, newValue) => handleChangeDemandImpactSlider(index, newValue)}
                                                                        step={5}
                                                                        marks={[
                                                                            { value: -5, },
                                                                            { value: 0, },
                                                                            { value: 5, },
                                                                        ]}
                                                                        min={-10}
                                                                        max={10}
                                                                        track={false}
                                                                        sx={{
                                                                            '& .MuiSlider-thumb': {
                                                                                backgroundColor: getDemandImpactColor(row.demand_impact),
                                                                            }
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </ThemeProvider>
                                                        </Box>
                                                    </Box>
                                                </TableCell>

                                                <TableCell className="table-cell" align="left" sx={{ width: "100px" }}>
                                                    {getDemandImpactName(row.demand_impact)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Box sx={{ opacity: 0, pointerEvents: "none", display: "flex", alignItems: "center", gap: "8px" }} onClick={handleUploadEventOpen}>
                                    <svg width="15" height="15" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 1.5H9.5V1C9.5 0.72 9.28 0.5 9 0.5C8.72 0.5 8.5 0.72 8.5 1V1.5H3.5V1C3.5 0.72 3.28 0.5 3 0.5C2.72 0.5 2.5 0.72 2.5 1V1.5H1C0.45 1.5 0 1.95 0 2.5V12.5C0 13.05 0.45 13.5 1 13.5H11C11.56 13.5 12 13.05 12 12.5V2.5C12 1.95 11.56 1.5 11 1.5ZM11 12.5H1V5.5H11V12.5ZM11 4.5H1V2.5H2.5V3C2.5 3.27 2.73 3.5 3 3.5C3.27 3.5 3.5 3.27 3.5 3V2.5H8.5V3C8.5 3.27 8.73 3.5 9 3.5C9.27 3.5 9.5 3.27 9.5 3V2.5H11V4.5Z" fill="#00415F" />
                                        <path d="M7.51 7.74L6.34 6.57C6.14 6.37 5.83 6.37 5.63 6.57L4.48 7.72C4.28 7.92 4.28 8.23 4.48 8.43C4.68 8.63 4.99 8.63 5.19 8.43L5.49 8.13V10.51C5.49 10.78 5.71 11.01 5.99 11.01C6.27 11.01 6.49 10.78 6.49 10.51V8.15L6.79 8.45C6.89 8.55 7.02 8.6 7.14 8.6C7.26 8.6 7.4 8.55 7.49 8.45C7.69 8.25 7.69 7.94 7.49 7.74H7.51Z" fill="#00415F" />
                                    </svg>
                                    <div className="upload-event-calendar">Upload Event Calendar</div>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    <Button
                                        className="add-demand-impacts-btn2"
                                        onClick={handleAddEventClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="add-demand-impacts-btn"
                                        onClick={handleSaveAddEvent}
                                        disabled={isSaving}
                                        sx={!addEventSaveBtnEnabled ? { opacity: "0.4" } : {}}
                                    >
                                        {isSaving ? "Saving..." : "Save"}
                                    </Button>
                                </Box>
                            </Box>

                        </CustomTabPanel>

                    </Box>

                </Box>

            </Modal>

            {/* Edit Event Popup */}
            <Modal
                open={openEditEvent}
                onClose={handleEditEventClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="add-event-popup" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '750px',
                    height: '422px',
                    bgcolor: '#fff',
                    boxShadow: 24, borderRadius: '8px'
                }}>
                    <div className="pop-up-header clearfix request-popup-header">
                        <Box sx={{ float: 'left' }}>
                            <div className="tile-title" data-tile-icon="icon-icon-events" data-id="events">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-38.34-85.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L116,164.69l42.34-42.35A8,8,0,0,1,169.66,122.34Z"></path></svg>
                                <div className="tile-name">Edit Event</div>
                            </div>
                        </Box>
                        <Box sx={{ float: 'right' }}>
                            <Button className='cross-btn'
                                sx={{
                                    minWidth: 'auto', width: '22px', height: '22px', p: '7px', borderRadius: '50%', backgroundColor: 'black', color: 'white', fontSize: '10px', '&:hover': { background: '#000' }
                                }} onClick={handleEditEventClose}>
                                <CloseSharpIcon fontSize='small' background="black" />
                            </Button>
                        </Box>
                    </div>

                    <Box className="event-tab-container" p={1}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs className="tabs" value={editEventTabValue} onChange={editEventTabChange} aria-label="basic tabs example">
                                <Tab className="event-tabs" label="Event Details" {...a11yProps(0)} />
                                <Tab className="event-tabs" label="Demand Impact" {...a11yProps(1)} />
                            </Tabs>
                        </Box>

                        {/* Events Detalis Tab */}
                        <CustomTabPanel className="tab-contents" value={editEventTabValue} index={0}>
                            <Box>
                                <div className="form-label">Event Title*</div>
                                <TextField
                                    value={eventTitle}
                                    className="form-textfield"
                                    placeholder="Add Event Title"
                                    onChange={handleEventTitleChange}
                                    autoFocus
                                ></TextField>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                <Box sx={{ width: "100%" }}>
                                    <div className="form-label">Category*</div>
                                    <FormControl fullWidth>
                                        <Select
                                            value={category}
                                            onChange={handleCategoryChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            className="event-category-dropdown"
                                            IconComponent={CustomDropdownIcon}
                                        >
                                            <MenuItem value="">
                                                <em>Select Category</em>
                                            </MenuItem>
                                            <MenuItem value="Sports">Sports</MenuItem>
                                            <MenuItem value="Conference">Conference</MenuItem>
                                            <MenuItem value="Concerts">Concerts</MenuItem>
                                            <MenuItem value="Academic">Academic</MenuItem>
                                            <MenuItem value="Festivals">Festivals</MenuItem>
                                            <MenuItem value="Public Holidays">Public Holidays</MenuItem>
                                            <MenuItem value="School Holidays">School Holidays</MenuItem>
                                            <MenuItem value="Observances">Observances</MenuItem>
                                            <MenuItem value="Weather">Weather</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                    <div className="form-label">Date Range*</div>
                                    {/* <TextField className="form-textfield" placeholder="Add Event Title"></TextField> */}
                                    <TextField
                                        tabIndex={1}
                                        onKeyDown={preventTyping}
                                        onClick={toggleDateRangePickerVisiblity}
                                        style={{ cursor: "pointer" }}
                                        fullWidth
                                        className="form-textfield"
                                        size="small"
                                        value={dateFormat(addEventStartDate, "YYYY-MM-DD") === dateFormat(addEventEndDate, "YYYY-MM-DD") ? dateFormat(addEventStartDate, "ddd, MMM DD YYYY") : dateFormat(addEventStartDate, "ddd, MMM DD YYYY") + " - " + dateFormat(addEventEndDate, "ddd, MMM DD YYYY")}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={toggleDateRangePickerVisiblity} edge="end">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00415F" viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-96-88v64a8,8,0,0,1-16,0V132.94l-4.42,2.22a8,8,0,0,1-7.16-14.32l16-8A8,8,0,0,1,112,120Zm59.16,30.45L152,176h16a8,8,0,0,1,0,16H136a8,8,0,0,1-6.4-12.8l28.78-38.37A8,8,0,1,0,145.07,132a8,8,0,1,1-13.85-8A24,24,0,0,1,176,136,23.76,23.76,0,0,1,171.16,150.45Z"></path></svg>
                                                </IconButton>
                                            ),
                                        }}
                                    />

                                </Box>
                                {/* Date ramge picker */}
                                <Grid item
                                    className={
                                        dateRangePickerVisiblity ? "date-range-picker-container" : "date-range-picker-container-false"
                                    }
                                >
                                    <div className='top-bar-item'>
                                        <DateRangePicker
                                            showSelectionPreview={true}
                                            moveRangeOnFirstSelection={false}
                                            months={2}
                                            direction="horizontal"
                                            ranges={[
                                                {
                                                    startDate: new Date(addEventStartDateTemp),
                                                    endDate: new Date(addEventEndDateTemp),
                                                    key: 'selection',
                                                },
                                            ]}
                                            //minDate={new Date()} // disabled previous date from current date
                                            onChange={(ranges) => {
                                                // Update arrival and departure dates based on user selection
                                                console.log("addEventStartDate", ranges.selection.startDate);
                                                console.log("addEventEndDate", ranges.selection.endDate);
                                                setAddEventStartDateTemp(ranges.selection.startDate);
                                                setAddEventEndDateTemp(ranges.selection.endDate);
                                                setIsEditDateChanged(true);
                                            }}
                                        />
                                        <Box sx={{ background: "#FFF" }} className="datepicker-done-btn"><Button onClick={toggleDateRangePickerVisiblity}>Done</Button></Box>
                                    </div>
                                </Grid>
                            </Box>
                            <Box>
                                <div className="form-label">Description</div>
                                <Box className="form-textfield">
                                    <textarea
                                        value={description}
                                        className="form-textarea"
                                        placeholder="Add Description About Event"
                                        onChange={handleDescriptionChange}
                                    ></textarea>
                                </Box>
                            </Box>

                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Box sx={{ opacity: 0, pointerEvents: "none", display: "flex", alignItems: "center", gap: "8px" }} onClick={handleUploadEventOpen}>
                                    <svg width="15" height="15" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 1.5H9.5V1C9.5 0.72 9.28 0.5 9 0.5C8.72 0.5 8.5 0.72 8.5 1V1.5H3.5V1C3.5 0.72 3.28 0.5 3 0.5C2.72 0.5 2.5 0.72 2.5 1V1.5H1C0.45 1.5 0 1.95 0 2.5V12.5C0 13.05 0.45 13.5 1 13.5H11C11.56 13.5 12 13.05 12 12.5V2.5C12 1.95 11.56 1.5 11 1.5ZM11 12.5H1V5.5H11V12.5ZM11 4.5H1V2.5H2.5V3C2.5 3.27 2.73 3.5 3 3.5C3.27 3.5 3.5 3.27 3.5 3V2.5H8.5V3C8.5 3.27 8.73 3.5 9 3.5C9.27 3.5 9.5 3.27 9.5 3V2.5H11V4.5Z" fill="#00415F" />
                                        <path d="M7.51 7.74L6.34 6.57C6.14 6.37 5.83 6.37 5.63 6.57L4.48 7.72C4.28 7.92 4.28 8.23 4.48 8.43C4.68 8.63 4.99 8.63 5.19 8.43L5.49 8.13V10.51C5.49 10.78 5.71 11.01 5.99 11.01C6.27 11.01 6.49 10.78 6.49 10.51V8.15L6.79 8.45C6.89 8.55 7.02 8.6 7.14 8.6C7.26 8.6 7.4 8.55 7.49 8.45C7.69 8.25 7.69 7.94 7.49 7.74H7.51Z" fill="#00415F" />
                                    </svg>
                                    <div className="upload-event-calendar">Upload Event Calendar</div>
                                </Box>
                                <Button
                                    className="add-demand-impacts-btn"
                                    onClick={editDemandImpactTab}
                                    disabled={!addEventNextBtnEnabled}
                                    sx={!addEventNextBtnEnabled ? { opacity: "0.4" } : {}}
                                >
                                    Next
                                </Button>
                            </Box>
                        </CustomTabPanel>

                        {/* Demand Impact Tab */}
                        <CustomTabPanel className="tab-contents" value={editEventTabValue} index={1}>

                            <TableContainer component={Paper} className="demand-impact-table">
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="table-row">
                                            <TableCell className="table-header" align="left">Date</TableCell>
                                            <TableCell className="table-header" align="left">Day Type</TableCell>
                                            <TableCell className="table-header" align="left" style={{ paddingLeft: "12px" }}>Demand Impact</TableCell>
                                            <TableCell className="table-header" align="center"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {demandImpactRows.map((row, index) => (
                                            <TableRow
                                                className="table-row"
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell className="table-cell" align="left" sx={{ width: '130px' }}>
                                                    {dateFormat(row.date, "ddd, DD MMM, YYYY")}
                                                </TableCell>

                                                <TableCell className="table-cell" align="left" sx={{ width: "170px" }}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            value={row.day_type}
                                                            onChange={(event) => handleDayTypeChange(event, index)}
                                                            displayEmpty
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            className="event-category-dropdown day-type"
                                                            selected={row.dayType}
                                                            IconComponent={CustomDropdownIcon2}
                                                        >
                                                            {dayTypes.map((dayType, dayTypeIndex) => (
                                                                <MenuItem key={dayTypeIndex} value={dayType}>
                                                                    {dayType}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>

                                                <TableCell className="table-cell" align="right" sx={{ width: '200px' }}>
                                                    <Box sx={{ position: "relative", padding: "2px" }}>
                                                        <Box>
                                                            <ThemeProvider theme={theme}>
                                                                <Box sx={{ width: 200 }}>
                                                                    <Slider
                                                                        value={row.demand_impact}
                                                                        onChange={(event, newValue) => handleChangeDemandImpactSlider(index, newValue)}
                                                                        step={5}
                                                                        marks={[
                                                                            { value: 5, },
                                                                            { value: 0, },
                                                                            { value: -5, },
                                                                        ]}
                                                                        min={-10}
                                                                        max={10}
                                                                        track={false}
                                                                        sx={{
                                                                            '& .MuiSlider-thumb': {
                                                                                backgroundColor: getDemandImpactColor(row.demand_impact),
                                                                            }
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </ThemeProvider>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="table-cell" align="left" sx={{ width: "100px" }}>
                                                    {getDemandImpactName(row.demand_impact)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Box sx={{ opacity: 0, pointerEvents: "none", display: "flex", alignItems: "center", gap: "8px" }} onClick={handleUploadEventOpen}>
                                    <svg width="15" height="15" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 1.5H9.5V1C9.5 0.72 9.28 0.5 9 0.5C8.72 0.5 8.5 0.72 8.5 1V1.5H3.5V1C3.5 0.72 3.28 0.5 3 0.5C2.72 0.5 2.5 0.72 2.5 1V1.5H1C0.45 1.5 0 1.95 0 2.5V12.5C0 13.05 0.45 13.5 1 13.5H11C11.56 13.5 12 13.05 12 12.5V2.5C12 1.95 11.56 1.5 11 1.5ZM11 12.5H1V5.5H11V12.5ZM11 4.5H1V2.5H2.5V3C2.5 3.27 2.73 3.5 3 3.5C3.27 3.5 3.5 3.27 3.5 3V2.5H8.5V3C8.5 3.27 8.73 3.5 9 3.5C9.27 3.5 9.5 3.27 9.5 3V2.5H11V4.5Z" fill="#00415F" />
                                        <path d="M7.51 7.74L6.34 6.57C6.14 6.37 5.83 6.37 5.63 6.57L4.48 7.72C4.28 7.92 4.28 8.23 4.48 8.43C4.68 8.63 4.99 8.63 5.19 8.43L5.49 8.13V10.51C5.49 10.78 5.71 11.01 5.99 11.01C6.27 11.01 6.49 10.78 6.49 10.51V8.15L6.79 8.45C6.89 8.55 7.02 8.6 7.14 8.6C7.26 8.6 7.4 8.55 7.49 8.45C7.69 8.25 7.69 7.94 7.49 7.74H7.51Z" fill="#00415F" />
                                    </svg>
                                    <div className="upload-event-calendar">Upload Event Calendar</div>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    <Button
                                        className="add-demand-impacts-btn2"
                                        onClick={handleEditEventClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="add-demand-impacts-btn"
                                        onClick={handleSaveEditEvent}
                                        disabled={isSaving}
                                        sx={!addEventSaveBtnEnabled ? { opacity: "0.4" } : {}}
                                    >
                                        {isSaving ? "Saving..." : "Save"}
                                    </Button>
                                </Box>
                            </Box>

                        </CustomTabPanel>

                    </Box>

                </Box>

            </Modal>

            {/* Upload Event Popup */}
            <Modal
                open={openUploadEvent}
                onClose={handleUploadEventClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="add-event-popup" sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '700px',
                    height: '366px',
                    bgcolor: '#fff',
                    boxShadow: 24, borderRadius: '8px'
                }}>
                    <div className="pop-up-header clearfix request-popup-header">
                        <Box sx={{ float: 'left' }}>
                            <div className="tile-title" data-tile-icon="icon-icon-events" data-id="events">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" viewBox="0 0 256 256"><path d="M178.34,165.66,160,147.31V208a8,8,0,0,1-16,0V147.31l-18.34,18.35a8,8,0,0,1-11.32-11.32l32-32a8,8,0,0,1,11.32,0l32,32a8,8,0,0,1-11.32,11.32ZM160,40A88.08,88.08,0,0,0,81.29,88.68,64,64,0,1,0,72,216h40a8,8,0,0,0,0-16H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.12A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,100.8,66,8,8,0,0,0,3.2,15.34,7.9,7.9,0,0,0,3.2-.68A88,88,0,0,0,160,40Z"></path></svg>
                                <div className="tile-name">Upload Events</div>
                            </div>
                        </Box>
                        <Box sx={{ float: 'right' }}>
                            <Button className='cross-btn'
                                sx={{
                                    minWidth: 'auto', width: '22px', height: '22px', p: '7px', borderRadius: '50%', backgroundColor: 'black', color: 'white', fontSize: '10px', '&:hover': { background: '#000' }
                                }} onClick={handleUploadEventClose}>
                                <CloseSharpIcon fontSize='small' background="black" />
                            </Button>
                        </Box>
                    </div>

                    <Box className="event-tab-container" p={1}>
                        <Box className="events-upload" p={1}>
                            <FileUploader
                                handleChange={handleChange}
                                name="file"
                                classes={fileName === "" ? "drop_area" : eventUploadStatus.status === "" ? "drop_area with-file" : eventUploadStatus.status === "error" ? "drop_area with-file error" : eventUploadStatus.status === "uploaded" ? "drop_area with-file uploaded disabled" : "drop_area with-file disabled"}
                                types={fileTypes}
                                disabled={eventUploadStatus.status !== "" && eventUploadStatus.status !== "error"}
                                children={<UploadInnerHtml fileName={fileName} eventUploadStatus={eventUploadStatus} />}
                            //style={eventUploadStatus.status === "uploaded" ? {border: "2px solid $Tertiary !important"} : eventUploadStatus.status === "error" ? {border: "2px dashed #E03131 !important"} : {} }
                            />
                        </Box>
                        <Box className="upload-event-action-btns" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Button
                                className="add-demand-impacts-btn3"
                                sx={{ display: "flex", alignItems: "center", gap: "6px" }}
                                onClick={downloadEventTemplate}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00415F" viewBox="0 0 256 256"><path d="M224,144v64a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V144a8,8,0,0,1,16,0v56H208V144a8,8,0,0,1,16,0Zm-101.66,5.66a8,8,0,0,0,11.32,0l40-40a8,8,0,0,0-11.32-11.32L136,124.69V32a8,8,0,0,0-16,0v92.69L93.66,98.34a8,8,0,0,0-11.32,11.32Z"></path></svg>
                                <div className="upload-event-calendar">Template</div>
                            </Button>

                            {eventUploadStatus.status !== "uploaded" && eventUploadStatus.status !== "error" ?
                                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    <Button
                                        className="add-demand-impacts-btn2"
                                        onClick={handleUploadEventClose}
                                        disabled={!eventUploadCancelBtnEnabled}
                                        sx={!eventUploadCancelBtnEnabled ? { opacity: "0.4" } : {}}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="add-demand-impacts-btn"
                                        onClick={uploadEventData}
                                        disabled={!eventUploadSaveBtnEnabled}
                                        sx={!eventUploadSaveBtnEnabled ? { opacity: "0.4" } : {}}
                                    >
                                        Upload
                                    </Button>
                                </Box>
                                :
                                <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                    <Button
                                        className="add-demand-impacts-btn"
                                        onClick={handleUploadEventClose}
                                    >
                                        Okay
                                    </Button>
                                </Box>
                            }
                        </Box>
                    </Box>

                </Box>

            </Modal>

            {/* Footer Start */}
            <Box>
                <DashboardFooter />
            </Box>
            {/* Footer End */}

            {isrequesting && (
                <Box
                    sx={{
                        textAlighn: "center",
                        backgroundColor: "black",
                        opacity: 0.8,
                        position: "fixed",
                        top: 40,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 100,
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "white",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                </Box>
            )}

        </ThemeProvider>

    );
}

export function UploadInnerHtml(props) {
    return (
        <>
            {props.fileName !== "" ?
                <div className="upload-area-filename">
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={props.eventUploadStatus.status === "uploaded" ? "#2F9E44" : props.eventUploadStatus.status === "error" ? "#E03131" : "#606060"} viewBox="0 0 256 256"><path d="M156,208a8,8,0,0,1-8,8H120a8,8,0,0,1-8-8V152a8,8,0,0,1,16,0v48h20A8,8,0,0,1,156,208ZM92.65,145.49a8,8,0,0,0-11.16,1.86L68,166.24,54.51,147.35a8,8,0,1,0-13,9.3L58.17,180,41.49,203.35a8,8,0,0,0,13,9.3L68,193.76l13.49,18.89a8,8,0,0,0,13-9.3L77.83,180l16.68-23.35A8,8,0,0,0,92.65,145.49Zm98.94,25.82c-4-1.16-8.14-2.35-10.45-3.84-1.25-.82-1.23-1-1.12-1.9a4.54,4.54,0,0,1,2-3.67c4.6-3.12,15.34-1.72,19.82-.56a8,8,0,0,0,4.07-15.48c-2.11-.55-21-5.22-32.83,2.76a20.58,20.58,0,0,0-8.95,14.95c-2,15.88,13.65,20.41,23,23.11,12.06,3.49,13.12,4.92,12.78,7.59-.31,2.41-1.26,3.33-2.15,3.93-4.6,3.06-15.16,1.55-19.54.35A8,8,0,0,0,173.93,214a60.63,60.63,0,0,0,15.19,2c5.82,0,12.3-1,17.49-4.46a20.81,20.81,0,0,0,9.18-15.23C218,179,201.48,174.17,191.59,171.31ZM40,112V40A16,16,0,0,1,56,24h96a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88v24a8,8,0,1,1-16,0V96H152a8,8,0,0,1-8-8V40H56v72a8,8,0,0,1-16,0ZM160,80h28.68L160,51.31Z"></path></svg>
                        <div className="upload-area-txt">{props.fileName}</div>
                    </div>
                    <div
                        className={props.eventUploadStatus.status === "uploaded" ? "upload-status uploaded" : props.eventUploadStatus.status === "error" ? "upload-status error" : "upload-status"}
                        style={props.eventUploadStatus === "" ? { opacity: 0, height: "8px" } : { opacity: 1, height: "8px" }}
                    >
                        {props.eventUploadStatus.message}
                    </div>
                </div>
                :
                <div className="upload-area">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#606060" viewBox="0 0 256 256"><path d="M224,144v64a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V144a8,8,0,0,1,16,0v56H208V144a8,8,0,0,1,16,0ZM93.66,77.66,120,51.31V144a8,8,0,0,0,16,0V51.31l26.34,26.35a8,8,0,0,0,11.32-11.32l-40-40a8,8,0,0,0-11.32,0l-40,40A8,8,0,0,0,93.66,77.66Z"></path></svg>
                    <div className="upload-area-txt">Select or Drop a file to upload</div>
                </div>
            }
        </>
    )
}

export {
    EventsSettings
}