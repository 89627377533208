import { deepCopy, safeReference, getNumDaysFromDateRange, timeDiff } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import moment from "moment";
export function getPricingDataFormatted({ primary_stay_range, room_settings }, isRoomType = false, rateRecomended = []) {
    const numDays = getNumDaysFromDateRange(primary_stay_range);
    let baseRoom = "";
    room_settings.map(room => {
        if (room.is_base === true) {
            baseRoom = room.room_code;
        }
    })

    if (rateRecomended.length === 0 &&
        dataService.getSeriesById("OSRRateRecommend")
        && dataService.getSeriesById("OSRRateRecommend").data) {
        rateRecomended = deepCopy(dataService.getSeriesById("OSRRateRecommend").data.data);
    }


    let pricingData = [];
    let currDate = moment(primary_stay_range.date_from);

    for (let index = 0; index < numDays; index++) {
        let dataPoint = {
            stay_date: currDate.format("YYYY-MM-DD")
        };
        if (!isRoomType) {
            let found = false;
            rateRecomended.map(rateData => {

                if (rateData.stay_date === currDate.format("YYYY-MM-DD")) {
                    if (rateData.is_base === true || rateData.room_type === baseRoom) {

                        dataPoint.RecSellRate = rateData.algo_generated_recommended_rate;
                        dataPoint.SellRate = rateData.current_sell_rate;
                        if (rateData.pushed === true) {
                            dataPoint.SellRate = rateData.resolved_sell_rate
                        }
                        dataPoint.Explainer = rateData.explainer?.txt_msg.length ? rateData.explainer.html_msg : null;
                        found = true;
                    }
                }
            })
            if (!found) {
                dataPoint.RecSellRate = 0;
                dataPoint.SellRate = 0
            }
        } else {

            rateRecomended.map(rateData => {

                if (rateData.stay_date === currDate.format("YYYY-MM-DD") && rateData.room_type) {

                    dataPoint[rateData.room_type] = {
                        RecSellRate: rateData.algo_generated_recommended_rate,
                        SellRate: rateData.current_sell_rate,
                        lodgiq_differential: rateData.algo_generated_differential,
                        hierarchy_order: rateData.hierarchy_order,
                        rooms_sold: rateData.rooms_sold
                    }
                    if (rateData.pushed === true) {
                        dataPoint[rateData.room_type].SellRate = rateData.resolved_sell_rate
                    }
                }
            })
        }

        pricingData.push(dataPoint);
        currDate.add(1, 'days');
    }
    return pricingData;
}

export function getPricingDataInt(dataPoint, key) {
    let pricingDataInt = {};
    if (key.subcol_id === "pricing_SellRate") {
        pricingDataInt.SellRate = dataPoint.SellRate
        if (pricingDataInt.SellRate === null) {
            pricingDataInt.SellRate = 0
        }
    }
    if (key.subcol_id === "pricing_RecSellRate") {
        pricingDataInt.RecSellRate = dataPoint.RecSellRate;
        if (pricingDataInt.RecSellRate === null) {
            pricingDataInt.RecSellRate = ""
        }
    }
    if (key.subcol_id === "pricing_ratediff") {
        // dataPoint.RecSellRate = dataPoint.RecSellRate | 0;
        // dataPoint.RecSellRate = dataPoint.SellRate | 0;
        let rateDiff;
        if (dataPoint.RecSellRate === null || dataPoint.RecSellRate === "") {
            rateDiff = "";
        } else {
            rateDiff = dataPoint.RecSellRate - dataPoint.SellRate;
        }
        pricingDataInt.ratediff = rateDiff;

    }
    return pricingDataInt;
}
export function addPastDates(data, as_of_date) {
    let osrData = [];
    if (dataService.getSeriesById("OSR").data) {
        osrData = dataService.getSeriesById("OSR").data.data;
        data = data.map((item, dataIndex) => {

            if (timeDiff(item.stay_date, as_of_date, 'days') > 0) {
                let osrDp = osrData[dataIndex];
                item.SellRate = osrDp.HotelTotal.primary.SellRate;
            }
            return item;

        })
    }
    return data;
}