

import {Box, Select, MenuItem} from '@mui/material'
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import { updateAppState } from '../../../../actions/appstate';


const EventType = () => {
    const [eventType, setEventType] = useState("All")
    const controls = useSelector(state => state.app.current_state.app_state.workspace_controls);
    const dispatch = useDispatch();

    const handleChange = (type,value)=>{
        const updateTypes = [type];
        controls.scopeType = value;
        dispatch(updateAppState(updateTypes, {app_state: {workspace_controls: controls}}));
    }
    return (
        <Box id="event_type" sx={{ml: 2, paddingLeft:0,backgroundColor:'blue', height: 1/2, width: "130px"}}>
            <Box className="data-level-label">Event Type</Box>
            <Box> 
                <Select value={eventType} sx={{backgroundColor: "grey.300", height: "25px", mt: "5px", width: 1}} onChange={(e) => {setEventType(e.target.value); handleChange("event_select", e.target.value);}}> 
                    <MenuItem value="All"> ALL </MenuItem>
                    <MenuItem value="System"> SYSTEM </MenuItem>
                    <MenuItem value="Custom"> CUSTOM </MenuItem>
                </Select> 
            </Box>
         </Box>
    )
}

export default EventType
