import React, { useState, useEffect, useRef, memo, useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorHandler } from "../../app/ErrorHandler";
import {
    Box,
    Paper,
    Fade,
    Tab,
    Tabs,
    Chip,
    Badge,
    Button,
} from "@mui/material";
import WorkspaceControls from "./WorkspaceControls";
import SendRatesButton from "./subComponents/rightComponents/sendRatesButton";
import moment from "moment";
import Chart from "../Chart";
import Spinner from "../Spinner";
import Measure from "../Measure";
import { useDispatch, useSelector } from "react-redux";
import DynamicTable from "../DynamicTable";
import {
    getData,
    getPropertyInfo,
    updateAppState,
} from "../../actions/appstate";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/auth";
import {
    getTileById,
    getSeriesById,
    dateDiff,
    safeReference,
    abortAndGetSignal,
    abortRequestSafe,
    deepCopy,
    getFromState,
    aggregateSegmentedGroupedData,
    in_array,
    correctTotalValue,
    isSameDateRange,
    calculateCompStayRange,
    getPermissions,
    getMarketFutureSegmentedAgg,
    getMarketSegment,
    dateFormat,
    getCacheDataKey,
    getNotesData,
    avgImpactGetData, EventsListGetData, avgImpactGetDataSDLY, EventsListGetDataSDLY, avgImpactGetDataComparision, EventsListGetDataComparision,
    transformMarketForecastItem,
    calculateIdentityDiff,
    checkMonthData

} from "../../app/util";
import {
    api_status,
    promises,
    API_REGISTRY,
    TILES,
    APP_CACHE,
} from "../../app/config";

import { UPDATE_STATE } from "../../actions/types";
import dataService from "../../services/data.service";
import "./workspace_style.scss";
import "../../simple-grid.css";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { getPricingDataFormatted } from "../DynamicTable/tilesData/pricing";

import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Lottie from "react-lottie";
import TableLoadingLottie from "../../lotties/table-loading.json";
import GraphLoadingLottie from "../../lotties/graph-loading.json";
import LockLayer from "../LockLayer/index.js";
import { useProductVariant } from "../../hooks/use-product-variant";

import msg from "./messages.js";

// import AbortController from "abort-controller";

function Workspace(props) {
    const { updateDataCache } = dataService;
    const state = useSelector((appState) => appState);
    const { hasFeature, productVariant } = useProductVariant();
    const navigate = useNavigate();
    const {
        profile: { property_details },
        user,
    } = useSelector((state) => state.auth);
    const { app_state, ui_state } = useSelector(
        (state) => state.app.current_state
    );
    const curr_app_state = app_state;
    const curr_ui_state = ui_state;

    const {
        data_status,
        active_api,
        active_apis,
        kpi_load,
        roomtype_pricing_overrides,
        as_of_date,
        property_id,
        data_load,
        workspace_controls,
        primary_stay_range,
    } = app_state;
    const { permissions, index } = getPermissions(property_details, property_id);
    const dispatch = useDispatch();
    let [displayData, setDisplayData] = useState([]);
    // const [activeTile, setActiveTile] = useState("otb");
    const [compState, setCompState] = useState({
        active_api: active_api,
        active_apis: active_apis,
        activeTiles: ui_state.active_tiles,
        displayDataId: app_state.active_api,
        dataLoading: false,
        dataStatus: {},
        dataLoaded: false,
        kpiLoading: false,
        aborted: false,
        reloadOnAbort: false,
    });

    let compRef = useRef({
        active_api: active_api,
        active_apis: active_apis,
        activeTiles: ui_state.active_tiles,
        displayDataId: app_state.active_api,
        dataLoading: false,
        dataStatus: {},
        dataLoaded: false,
        kpiLoading: false,
        firstLoad: true,
    });

    // console.log("setJustLoaded called after commonSuccessHandler", data_status);
    if (
        APP_CACHE.active_tiles.length &&
        !in_array(ui_state.active_tiles, APP_CACHE.active_tiles)
    ) {
        ui_state.active_tiles = deepCopy(APP_CACHE.active_tiles);
    }
    if (!APP_CACHE.user_view) APP_CACHE.user_view = app_state.user_view;
    if (!APP_CACHE.controls) APP_CACHE.controls = app_state.workspace_controls;

    let {
        left_measure,
        right_measure,
        table_view,
        active_tiles,
        showspinner,
        isLock,
    } = ui_state;

    const {
        dba,
        sdlyOn,
        categoryType,
        clearComparision,
        scopeType,
        pickUpType,
        infoButton,
        pickUpDays,
        gridView,
        downloadButton,
        optionsClick,
        sendRates,
        expand_button,
        marketSelect,
        sdly2019On,
        sdYear,
        isRoomTypePricing,
        dayDate
    } = workspace_controls;

    const setInitialState = () => { };

    //const flight_as_of_date = app_state.workspace_controls.flight_as_of_date;
    const { data } = deepCopy(getSeriesById('GetFlights'));
    let flight_as_of_date = (data && data.meta) ? data.meta.as_of_date : "";

    // below code is to update the market capacity registry if somehow this registry is empty .
    if (active_tiles.includes('market')) {
        let marketCapacity = (dataService.getSeriesById("MarketCapacity").data) ? dataService.getSeriesById("MarketCapacity").data.data : [];
        // if(marketCapacity && marketCapacity.length <= 0){
        let finalData = (dataService.getSeriesById("MarketARISegmented").data) ? dataService.getSeriesById("MarketARISegmented").data : {};
        let capacityData = {}
        let dataSet = [];
        let marketData = deepCopy(finalData);
        if (marketData && marketData.data && marketData.data.length) {

            if (marketData.data[0] && marketData.data[0]['MarketTotal']
                && marketData.data[0]['MarketTotal']['primary']
                && marketData.data[0]['MarketTotal']['primary']['Capacity']) {
                dataSet = [{ "Capacity": marketData.data[0]['MarketTotal']['primary']['Capacity'], "Month": moment().format('YYYY-MM') }];
            }

            capacityData.data_id = "MarketCapacity";
            capacityData.data = dataSet;
            capacityData.ok = true;
            capacityData.status = "success";
            updateDataCache("MarketCapacity", { data: capacityData, loaded: true, loading: false });
        }

        // }
    }



    useEffect(() => {
        //console.log("ggg",flight_as_of_date)
        dispatch({
            type: UPDATE_STATE,
            payload: {
                app_state: {
                    workspace_controls: {
                        ...workspace_controls,
                        flight_as_of_date: flight_as_of_date
                    }
                },
            }
        })
    }, [])

    //Fixed chart data issue on day/date change and changing user view from table to chart
    useEffect(() => {
        let apc = APP_CACHE;
        apc.chartData = null;

        return () => { };
    }, [dayDate]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && app_state.data_load && ui_state.showspinner) {
            compRef.current.kpi_load = true;
        }
        return () => (isSubscribed = false);
    }, []);

    useEffect(() => {
        if (
            data_load &&
            productVariant &&
            productVariant.pv &&
            productVariant.pv.length === 1 &&
            productVariant.pv[0] === "Group IQ"
        ) {
            ui_state.show_group_pricing = true;
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    ui_state: {
                        show_group_pricing: true,
                    },
                },
            });
        }

    }, [property_id]);

    //Handle First time after component mounted
    useEffect(() => {
        let isSubscribed = true;
        let apc = APP_CACHE;
        if (compRef.current.timeoutid2) clearTimeout(compRef.current.timeoutid2);

        if (!data_load) {
            compRef.current.dataLoading = false;
        }
        // debugger;
        if (data_load && showspinner && !compRef.current.dataLoading) {
            if (compState.reloadOnAbort) {
                compState.reloadOnAbort = false;
                compRef.current.dataLoading = false;
                //setCompState({ ...compState });
            }

            if (
                hasFeature(ui_state.active_tiles, "tiles") === false &&
                hasFeature(ui_state.active_tiles, "reports") === false &&
                hasFeature(ui_state.active_tiles, "logs") === false
            ) {
                ui_state.isLock = true;
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        ui_state: {
                            // ...ui_state,
                            isLock: true,
                        },
                    },
                });
                // return
            }

            //setting DATA call for active_tiles[0]
            //This will be modifed for intelligence scenario
            // if (data_load && showspinner && compRef.current.dataLoading) {
            //     compRef.current.dataLoading = false;
            // }

            if (isSubscribed && data_load) {
                // debugger;
                // setCompState({ ...compState, dataLoading: true });

                // setDisplayData([]);
                let dataStatus = {};
                let activeApis = [];
                let t = active_tiles;
                // let active_dataId = getTileById(active_tiles[0]).data_sources[0]; debugger;
                for (let i = 0; i < active_tiles.length; i++) {
                    let currTile = active_tiles[i];
                    let tileDetails = getTileById(currTile);
                    //This is temporary until data_sources is not added to the tile config
                    // debugger;
                    console.log(active_tiles)
                    if (active_tiles[0] == "restrictions_push_status") {
                        console.log("logsRatePushMode", app_state.workspace_controls.logsRatePushMode)
                        tileDetails.data_sources = (app_state.workspace_controls.logsRatePushMode === "date") ? ["RestrictionsStatus"] : ["RestrictionsStatusDateRange"]
                    }
                    if (!tileDetails.data_sources.length) {
                        tileDetails.data_sources = ["OSR"];
                    }
                    tileDetails.data_sources.map((name) => {
                        if (activeApis.indexOf(name) === -1) {

                            //This code is added to avoid calling of OTB/Forecast api for single market tile
                            let lastIndex = activeApis.push(name) - 1;
                            if (active_tiles.length === 1 && active_tiles.includes('market') && ['marketOtbSegment', 'ForecastMarketSegment'].includes(name)) {
                                activeApis.splice(lastIndex, 1);
                            }
                        }
                    });
                }

                if (apc.dayDateToggle) {
                    // !['GetRestrictions','GetRestrictionsRoomType'].includes(name) && 
                    let restrictionIndex = activeApis.indexOf('GetRestrictions');
                    // debugger
                    if (restrictionIndex > -1) {
                        activeApis.splice(restrictionIndex, 1);
                    }
                    let restrictionRtIndex = activeApis.indexOf('GetRestrictionsRoomType');
                    if (restrictionRtIndex > -1) {
                        activeApis.splice(restrictionRtIndex, 1);
                    }
                    // apc.dayDateToggle = null;
                }

                let ai = activeApis;
                activeApis.map((name) => {
                    // debugger;
                    api_status[name] = "initiated";
                    dataStatus[name] = "initiated";
                });

                //Now call data calling function
                if (isLock === false && !compRef.current.dataLoading) {
                    // debugger;
                    if (!APP_CACHE.active_tiles.length) {
                        APP_CACHE.active_tiles = deepCopy(ui_state.active_tiles);
                    }
                    // console.log("wrong display", apc, workspace_controls);
                    if (compRef.current.timeoutid)
                        clearTimeout(compRef.current.timeoutid);

                    if (
                        apc.active_tiles.length &&
                        apc.active_tiles.join() === ui_state.active_tiles.join() &&
                        apc.controls.scopeType === workspace_controls.scopeType
                    ) {
                        compRef.current.timeoutid = setTimeout(() => {
                            //compRef.current.dataLoading = true;
                            fetchData(activeApis);
                        }, 300);
                    }
                }
            }

            if (ui_state.showspinner && data_load && displayData.length) {
                // compRef.current.dataLoading = true;
                setDisplayData([]);
                setCompState({ ...compState, dataLoading: true, dataLoaded: false });
            }
            if (kpi_load) {
                compRef.current.kpiLoading = false;
            }
        }

        // if (compRef.current.timeoutid2) clearTimeout(compRef.current.timeoutid2);
        compRef.current.timeoutid2 = setTimeout(() => {
            // debugger;
            if (!data_load && !kpi_load && showspinner) {
                compRef.current.dataLoading = false;
                compRef.current.kpiLoading = false;
                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            data_load: false,
                            kpi_load: false,
                        },
                        ui_state: {
                            showspinner: false,
                        },
                    },
                });
            }
        }, 10000);

        console.log(
            "this useEffect is called",
            "\n Tile:",
            active_tiles,
            "\n data_load:",
            data_load,
            "\n kpi_load:",
            kpi_load,
            "\n property_id:",
            property_id,
            "\n showspinner:",
            showspinner,
            "\n compState.reloadOnAbort:",
            compState.reloadOnAbort
        );
        const cache_data_key = getCacheDataKey({ active_tiles, stayRange: app_state.primary_stay_range, property_id: property_id, workspace_controls: workspace_controls, selected_market: app_state.selected_market, comp_stay_range: app_state.comp_stay_range });
        apc['cache_keys'] = apc['cache_keys'] ? apc['cache_keys'] : { general: 'generalized__' + cache_data_key, 'final': 'finalized__' + cache_data_key };

        return () => {
            isSubscribed = false;
        }
    }, [
        data_load,
        // kpi_load,
        // property_id,
        // active_tiles[0],
        showspinner,
        // compState.reloadOnAbort
    ]);

    useEffect(() => {

        if (app_state.changing_view) {
            // debugger;
            compRef.current.dataLoading = false;
            compRef.current.kpiLoading = false;
            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        data_load: false,
                        kpi_load: false,
                        // changing_view: ""
                    },
                    ui_state: {
                        showspinner: false,
                    },
                },
            });
        }


        return () => {
            //Do those tasks here that are needed on unmount of this component

        };
    }, [app_state.changing_view]);

    const fetchData = (activeApis) => {
        let start = moment().format('x');
        if (!in_array(ui_state.active_tiles, ['restrictions_push_status'])) {
            //    console.log(ui_state.active_tiles);
            //    debugger;
            if (!in_array(ui_state.active_tiles, ['hotel_summary', 'market_summary', 'bi_sensitivity_analysis',
                'flights', 'revpar_index', 'bi_pickup_analysis', 'bi_pace_comparison', 'otb_v2_multi_segment', 'DailyRatesendStatus', 'rm_room_type_ratesend_status'])) {
                // if (!ui_state.show_flash_report) {
                getNotesData(sdlyOn, app_state, dispatch);
                // }
            }
        }

        if (!checkMonthData(app_state.avgImpactData, app_state.primary_stay_range.date_until) && !in_array(ui_state.active_tiles, ['bi_sensitivity_analysis', 'revpar_index', 'flights', 'hotel_summary', 'market_summary'])) {
            // if (app_state.eventList && !app_state.eventList.length) {
            EventsListGetData(sdlyOn, app_state, dispatch);
            // }
            // if (app_state.avgImpactData && !app_state.avgImpactData.length) {
            avgImpactGetData(sdlyOn, app_state, dispatch);
            // }
        }

        if (sdlyOn) {
            if (app_state.avgImpactDataSDLY && !app_state.avgImpactDataSDLY.length && !in_array(ui_state.active_tiles, ['bi_sensitivity_analysis', 'revpar_index', 'flights', 'hotel_summary', 'market_summary'])) {
                avgImpactGetDataSDLY(sdlyOn, app_state, dispatch); // avgImpactDataSDLY
            }
            if (app_state.eventListSDLY && !app_state.eventListSDLY.length && !in_array(ui_state.active_tiles, ['bi_sensitivity_analysis', 'revpar_index', 'flights', 'hotel_summary', 'market_summary'])) {
                EventsListGetDataSDLY(sdlyOn, app_state, dispatch); //eventListSDLY
            }
        }
        else {
            if (!sdlyOn && in_array(ui_state.active_tiles, ["pace"]) && workspace_controls.scopeType === "Date") {
                if (app_state.avgImpactDataSDLY && !app_state.avgImpactDataSDLY.length && !in_array(ui_state.active_tiles, ['bi_sensitivity_analysis', 'revpar_index', 'flights', 'hotel_summary', 'market_summary'])) {
                    avgImpactGetDataComparision(sdlyOn, app_state, dispatch); // avgImpactDataSDLY
                }
                if (app_state.eventListSDLY && !app_state.eventListSDLY.length&& !in_array(ui_state.active_tiles, ['bi_sensitivity_analysis', 'revpar_index', 'flights', 'hotel_summary', 'market_summary'])) {
                    EventsListGetDataComparision(sdlyOn, app_state, dispatch);
                }
            }
        }


        console.log(
            "fetchData called with",
            app_state,
            ui_state,
            activeApis,
            data_status
        );
        console.log("dispached from APP_CACHE", APP_CACHE);

        if (APP_CACHE.property_id && APP_CACHE.propertySwitchStatus === "done") {
            app_state.property_id = APP_CACHE.property_id;
            app_state.active_data_src = APP_CACHE.active_data_src
                ? deepCopy(APP_CACHE.active_data_src)
                : app_state.active_data_src;
        }

        //For KPI data only
        // debugger
        console.log(
            "selectedAPI kpi_load",
            String(kpi_load),
            String(compRef.current.kpiLoading)
        );
        if ((compRef.current.kpi_load || kpi_load) && !compRef.current.kpiLoading) {
            compRef.current.kpiLoading = true;
            // debugger;
            let kpiPromises = [];
            let kpi_datas = [];

            // if (!ui_state.active_tiles.includes("otb")) {
            //     if (kpi_datas.indexOf("OSR") < 0) {
            //         kpi_datas.push("OSR");
            //     }
            // }
            app_state.kpi_status["OSR"] = "loading";
            app_state.kpi_status["OSRContrainedForecast"] = "loading";

            let new_data_status = {
                OSRContrainedForecast: "loading",
                OSR: "loading",
            };

            if (!activeApis.includes("OSR")) kpi_datas.push("OSR");
            if (!activeApis.includes("OSRContrainedForecast")) kpi_datas.push("OSRContrainedForecast");

            kpi_datas.map((active_api) => {
                let kpiPromise = getData({
                    ...app_state,
                    workspace_controls: { ...APP_CACHE.controls },
                    active_api: active_api,
                    active_apis: app_state.active_apis,
                    data_status: app_state.data_status,
                    active_tiles: ui_state.active_tiles,
                })();
                kpiPromises.push(kpiPromise);
                kpiPromise
                    .then((data) => {
                        // debugger;
                        if (data && data.ok) {
                            new_data_status[data.data_id] = "loaded";
                            app_state.kpi_status[data.data_id] = "loaded";
                            if (
                                app_state.kpi_status["OSR"] &&
                                app_state.kpi_status["OSRContrainedForecast"]
                            ) {
                                app_state.kpi_load = false;
                            }
                            dispatch({
                                type: UPDATE_STATE,
                                payload: {
                                    app_state: {
                                        kpi_status: {
                                            ...app_state.kpi_status,
                                            // new_data_status
                                        },
                                        //kpi_status: { ...new_data_status },
                                    },
                                },
                            });
                        }
                    })
                    .catch((err) => {
                        //  debugger;
                        new_data_status[err.data_id] = "loaded";
                        app_state.kpi_status[err.data_id] = "loaded";

                        /** added these below line to fix market IQ loading issue - Ticket OPS-2756 */
                        let new_ui_state = deepCopy(ui_state);
                        delete new_ui_state.showspinner;
                        /** OPS-2756 */

                        dispatch({
                            type: UPDATE_STATE,
                            payload: {
                                app_state: {
                                    // ...app_state,
                                    kpi_status: {
                                        ...app_state.kpi_status,
                                        // new_data_status
                                    },
                                },
                                ui_state: {
                                    ...new_ui_state,
                                },
                            },
                        });
                        // debugger;
                    });
            });

            dispatch({
                type: UPDATE_STATE,
                payload: {
                    app_state: {
                        // ...app_state,
                        kpi_status: { ...new_data_status },
                    }
                }
            });
            //on all kpiPromises
            Promise.allSettled(kpiPromises).then((result) => {

                compRef.current.kpiLoading = false;
                compRef.current.kpi_load = false;
                app_state.kpi_load = false;
                setCompState({ ...compState, kpiLoading: false });
                let new_data_status = {};
                result.map(({ status, value }, i) => {
                    if (status === "fulfilled" && value) {
                        new_data_status[value.data_id] = "loaded";
                    } else {
                        new_data_status[kpi_datas[i]] = "loaded";
                    }
                    // app_state.kpi_status[value.data_id] = 'loaded';
                });
                if (
                    app_state.kpi_status["OSR"] &&
                    app_state.kpi_status["OSRContrainedForecast"]
                ) {
                    app_state.kpi_load = false;
                }

                dispatch({
                    type: UPDATE_STATE,
                    payload: {
                        app_state: {
                            //...app_state,
                            kpi_status: app_state.kpi_status,
                            kpi_load: app_state.kpi_load
                        },
                    },
                });
            });
        }

        //Load main workspace data
        if (data_load && !compRef.current.dataLoading) {
            //setDisplayData([]);
            // setCompState({ ...compState, dataLoading: true });
            let new_data_status = {};
            let mainPromises = [];
            let secondaryPromises = [];
            let main_active_api = "";

            //For cancel all api calls

            let ignore_api_registies = ["OSRYear"];
            
            if (permissions.restrictions === 0) {
                ignore_api_registies.push('GetRestrictions');
                ignore_api_registies.push('GetRestrictionsRoomType');
            }
            if (
                ui_state.active_tiles.includes("otb") ||
                ui_state.active_tiles.includes("pickup") ||
                ui_state.active_tiles.includes("pace") ||
                ui_state.active_tiles.includes("otb_v2_multi_segment")
            ) {
                if (ui_state.active_tiles.length >= 1) {
                    ignore_api_registies.splice(0, 0, "OSRSegment");
                }

                if (!workspace_controls.segmented_view) {
                    ignore_api_registies.push("GetRatePlan");
                    ignore_api_registies.push("GetPaceRatePlan");
                }

                /**  ignore api on the basis of drop down in otb multilevel report  */
                let segmentType = app_state.workspace_controls.segmentType
                if (active_tiles[0] === "otb_v2_multi_segment" && ["hotel", "top_market_segment", "market_segment"].includes(segmentType)) {
                    ignore_api_registies.push("GetRatePlan");
                    ignore_api_registies.push("GetRatePlanNoMissingDays");
                    ignore_api_registies.push("GetRoomClass");
                    ignore_api_registies.push("GetRoomType");
                } else if (active_tiles[0] === "otb_v2_multi_segment" && ["rate_plan"].includes(segmentType)) {
                    ignore_api_registies.push("GetRoomClass");
                    ignore_api_registies.push("GetRoomType");
                    // ignore_api_registies.push("OSR");
                } else if (active_tiles[0] === "otb_v2_multi_segment" && ["room_class"].includes(segmentType)) {
                    ignore_api_registies.push("GetRatePlan");
                    ignore_api_registies.push("GetRatePlanNoMissingDays");
                    ignore_api_registies.push("GetRoomType");
                    // ignore_api_registies.push("OSR");
                    ignore_api_registies.push("GetRateCodeMapping");
                } else if (active_tiles[0] === "otb_v2_multi_segment" && ["room_type"].includes(segmentType)) {
                    ignore_api_registies.push("GetRatePlan");
                    ignore_api_registies.push("GetRatePlanNoMissingDays");
                    ignore_api_registies.push("GetRoomClass");
                    // ignore_api_registies.push("OSR");
                    ignore_api_registies.push("GetRateCodeMapping");
                }
                /** end */

            }
            console.log("api_status", api_status)
            activeApis.map((activeApi) => {
                // debugger;
                //Adding this to reduce otp api calls to one
                if (api_status[activeApi] !== "loading") {
                    // debugger;
                    if (!ignore_api_registies.includes(activeApi)) {
                        new_data_status[activeApi] = "loading";
                        api_status[activeApi] = 'loading';
                        app_state.data_status[activeApi] = "loading";
                        //api_status[activeApi] = 'loading';


                        app_state.active_apis = activeApis;
                        let apiCallStarting = getData({
                            ...app_state,
                            active_api: activeApi,
                            active_apis: app_state.active_apis,
                            data_status: app_state.data_status,
                            active_tiles: ui_state.active_tiles,
                        })();
                        mainPromises.push(apiCallStarting);
                        apiCallStarting.then(
                            commonSuccessHandler(activeApi),
                            commonErrorHandler(activeApi)
                        );
                    }

                    compRef.current.dataStatus = new_data_status;
                    //setCompState({ ...compState, dataStatus: { ...new_data_status } })
                }
            });

            // if (ui_state.active_tiles.includes('otb')) {
            //     let activeApi = "GetRatePlan";
            //     if (compRef.current.dataStatus['GetRatePlan'] !== 'loading') {
            //         compRef.current.dataStatus['GetRatePlan'] = 'loading';
            //         let GetRatePlan = getData({
            //             ...app_state, active_api: "GetRatePlan",
            //             active_apis: app_state.active_apis,
            //             data_status: app_state.data_status,
            //             active_tiles: ui_state.active_tiles
            //         })();
            //         secondaryPromises.push(GetRatePlan);
            //         GetRatePlan.then(
            //             commonSuccessHandler(activeApi),
            //             commonErrorHandler(activeApi)
            //         )
            //     }
            // };

            let apc = app_state.primary_stay_range;
            Promise.allSettled(mainPromises).then((settled) => {
                let timeTaken = moment().format('x') - start;
                console.log('time taken in workspace index', timeTaken);
                // debugger;
                let apc = APP_CACHE;
                // if (apc.active_tiles[0] === "otb") {
                //     console.log(settled)
                //     let val = settled.find(i => i.value && i.value.data_id === "OSR");
                //     let { meta } = val ? val.value : {};
                //     // settled.find(i => i && i.value && i.value.data_id === "OSR").value;
                //     console.log(meta);
                // }

                if (
                    apc.active_tiles.join() === ui_state.active_tiles.join() &&
                    apc.controls.scopeType === workspace_controls.scopeType &&
                    apc.controls.sdYear == workspace_controls.sdYear &&
                    apc.controls.sdlyOn === workspace_controls.sdlyOn &&
                    data_load &&
                    showspinner
                ) {
                    compRef.current.dataLoading = false;
                    let isSamePrimary = true;
                    let isSameComparison = true;

                    if (APP_CACHE.primary_stay_range && !compRef.current.firstLoad) {
                        compRef.current.firstLoad = false;
                        isSamePrimary = isSameDateRange(
                            APP_CACHE.primary_stay_range,
                            app_state.primary_stay_range
                        );
                        isSameComparison = isSameDateRange(
                            APP_CACHE.comp_stay_range,
                            app_state.comp_stay_range
                        );
                    }

                    if (isSamePrimary && isSameComparison) {
                        let isAborted = false;
                        // let isSameRange = true;

                        // if (APP_CACHE.propertySwitchStatus !== 'inprogress') {
                        //     let isSamePrimary = isSameDateRange(APP_CACHE.primary_stay_range, primary_stay_range);
                        //     let isSameComparison = isSameDateRange(APP_CACHE.comp_stay_range, app_state.comp_stay_range);
                        //     isSameComparison = true;
                        //     isSameRange = isSamePrimary && isSameComparison ? true : false;
                        // }

                        let aborted = settled.filter(
                            (item) =>
                                item.status === "rejected" &&
                                item.reason.data &&
                                item.reason.data.indexOf("AbortError:") > -1
                        );
                        isAborted = aborted.length ? true : false;
                        compRef.current.dataLoading = false;

                        if (isAborted) {
                            compRef.current.abortedRequest = true;
                            compRef.current.dataLoading = false;
                            compRef.current.dataLoaded = false;
                            setCompState({
                                ...compState,
                                dataLoading: false,
                                dataLoaded: false,
                            });
                        }

                        if (APP_CACHE.propertySwitchStatus !== "inprogress") {
                            APP_CACHE.propertySwitchStatus = ""; //Reset to default value
                            if (
                                settled[0].status === "rejected" &&
                                settled[0].reason &&
                                settled[0].reason.response &&
                                settled[0].reason.response.status === 401
                            ) {
                                navigate("/login");
                            }
                            let dataAsOf;
                            //let dataAsOfChanged = false;
                            let app_state = {};
                            let ui_state = {};
                            compRef.current.dataLoading = false;
                            compRef.current.dataLoaded = true;
                            let dataArray = settled.map((item) => {
                                if (item.value && item.status === "fulfilled") {
                                    let marketSegments = getFromState(
                                        "system_settings>market_segment_category_mapping",
                                        state
                                    );
                                    let blankObj = {
                                        primary: {
                                            ADR: 0,
                                            Occ: 0,
                                            RevPAR: 0,
                                            Revenue: 0,
                                            Rooms: 0,
                                        },
                                        pickup: {
                                            ADR: 0,
                                            Occ: 0,
                                            RevPAR: 0,
                                            Revenue: 0,
                                            Rooms: 0,
                                        },
                                        current: {
                                            ADR: 0,
                                            Occ: 0,
                                            RevPAR: 0,
                                            Revenue: 0,
                                            Rooms: 0,
                                        },
                                        prior: {
                                            ADR: 0,
                                            Occ: 0,
                                            RevPAR: 0,
                                            Revenue: 0,
                                            Rooms: 0,
                                        },
                                        sdly: {
                                            ADR: 0,
                                            Occ: 0,
                                            RevPAR: 0,
                                            Revenue: 0,
                                            Rooms: 0,
                                        },
                                        sdtly: {
                                            ADR: 0,
                                            Occ: 0,
                                            RevPAR: 0,
                                            Revenue: 0,
                                            Rooms: 0,
                                        },
                                        sdly_actual: {
                                            ADR: 0,
                                            Occ: 0,
                                            RevPAR: 0,
                                            Revenue: 0,
                                            Rooms: 0,
                                        },
                                        sdtly_actual: {
                                            ADR: 0,
                                            Occ: 0,
                                            RevPAR: 0,
                                            Revenue: 0,
                                            Rooms: 0,
                                        },
                                        sdly_pickup: {
                                            ADR: 0,
                                            Occ: 0,
                                            RevPAR: 0,
                                            Revenue: 0,
                                            Rooms: 0,
                                        },
                                        sdtly_pickup: {
                                            ADR: 0,
                                            Occ: 0,
                                            RevPAR: 0,
                                            Revenue: 0,
                                            Rooms: 0,
                                        },
                                    };

                                    if (item.value && ["OSR"].includes(item.value.data_id)) {
                                        //Update data in local cache
                                        //Fix for multiple segments
                                        let OSRSegmentedData = item.value;
                                        let { updateDataCache } = dataService;
                                        // debugger;
                                        if (!OSRSegmentedData.data) OSRSegmentedData.data = [];
                                        OSRSegmentedData.data.forEach((item, index) => {
                                            let itm = { index: item.index, HotelTotal: item.HotelTotal };
                                            let HotelCapacity = (item.HotelTotal) ? item.HotelTotal.primary.Capacity : 0;

                                            let segments = Object.keys(marketSegments);
                                            let itemSegments = Object.keys(item);
                                            let allSegmentedDataGroup = [];
                                            // console.log("itemSegments "+ item.index.date, itemSegments);
                                            // let item = deepCopy(itm);
                                            segments.forEach((segName) => {
                                                let firstCode = "";
                                                let segmentedDataGroup = [];
                                                //  debugger;
                                                if (
                                                    marketSegments[segName] &&
                                                    marketSegments[segName].length >= 1
                                                ) {
                                                    let codes = marketSegments[segName];


                                                    // debugger;
                                                    codes.forEach((code, idx) => {
                                                        firstCode = firstCode ? firstCode : code;
                                                        if (item["Segment_" + code]) {
                                                            segmentedDataGroup.push(
                                                                deepCopy(item["Segment_" + code])
                                                            );
                                                            allSegmentedDataGroup.push(deepCopy(item["Segment_" + code]));
                                                            delete item["Segment_" + code];
                                                        } else {
                                                            segmentedDataGroup.push(deepCopy(blankObj));
                                                            // debugger;
                                                            delete item["Segment_" + code];
                                                        }
                                                    });
                                                    const aggregatedData =
                                                        aggregateSegmentedGroupedData(segmentedDataGroup);

                                                    item["Segment_" + firstCode] = aggregatedData;
                                                    itm["Segment_" + firstCode] = aggregatedData;
                                                }
                                            });
                                            const hotelTotalAggregated = aggregateSegmentedGroupedData(allSegmentedDataGroup);
                                            // itm["HotelTotal"] = hotelTotalAggregated;
                                            if (HotelCapacity) {
                                                itm["HotelTotal"].primary.Capacity = HotelCapacity;
                                            } else {
                                                itm["HotelTotal"] = hotelTotalAggregated;
                                            }
                                            let dataMainKeys = Object.keys(itm["HotelTotal"]);
                                            if (dataMainKeys) {
                                                dataMainKeys.forEach(dataMainKey => {
                                                    hotelTotalAggregated[dataMainKey] = { ...hotelTotalAggregated[dataMainKey], SellRate: 0 };
                                                    hotelTotalAggregated[dataMainKey]['SellRate'] = item["HotelTotal"][dataMainKey]['SellRate'];
                                                });
                                            }
                                            OSRSegmentedData.data[index] = itm;
                                        });

                                        let osrSegmentData = dataService.getSeriesById("OSRSegment");
                                        let osrData = dataService.getSeriesById("OSR");
                                        if (!osrData.data.transformed) {
                                            osrData.data.transformed = true;
                                            osrSegmentData.data = OSRSegmentedData;
                                            osrData.data.data = OSRSegmentedData.data;

                                            if (updateDataCache) {
                                                updateDataCache("OSRSegment", osrSegmentData);
                                                updateDataCache("OSR", osrData);
                                            }

                                        }
                                        // Change to stop fallback as of date
                                        if (item.value.meta && item.value.meta.as_of && !curr_app_state.data_as_of) {
                                            dataAsOf = item.value.meta.as_of;
                                            app_state.data_as_of = dataAsOf;
                                            dispatch({
                                                type: UPDATE_STATE,
                                                payload: {
                                                    app_state: {
                                                        data_as_of: dataAsOf,
                                                    },
                                                },
                                            });
                                        }
                                        // if (item.value.meta && item.value.meta.as_of) {
                                        //     if (
                                        //         !curr_app_state.data_as_of &&
                                        //         curr_app_state.primary_stay_range.as_of_date !==
                                        //         item.value.meta.as_of
                                        //     ) {
                                        //         dataAsOf = item.value.meta.as_of;
                                        //         app_state.data_as_of = dataAsOf;
                                        //         app_state.primary_stay_range = {
                                        //             ...curr_app_state.primary_stay_range,
                                        //             as_of_date: dataAsOf,
                                        //         };
                                        //         app_state.primary_stay_range.as_of_date = dataAsOf;
                                        //         dataAsOfChanged = true;

                                        //         APP_CACHE.primary_stay_range = deepCopy(
                                        //             app_state.primary_stay_range
                                        //         );
                                        //         APP_CACHE.primary_stay_range.as_of_date = dataAsOf;
                                        //         let comp_stay_range = calculateCompStayRange({
                                        //             primary_stay_range: deepCopy(
                                        //                 app_state.primary_stay_range
                                        //             ),
                                        //         });
                                        //         app_state.comp_stay_range = comp_stay_range;

                                        //         // app_state.historicalRange = deepCopy(APP_CACHE.primary_stay_range);

                                        //         dispatch({
                                        //             type: UPDATE_STATE,
                                        //             payload: {
                                        //                 app_state: {
                                        //                     data_as_of: dataAsOf,
                                        //                     as_of_date: dataAsOf,
                                        //                     historicalRange: {
                                        //                         ...APP_CACHE.primary_stay_range,
                                        //                     },
                                        //                     primary_stay_range: deepCopy(
                                        //                         APP_CACHE.primary_stay_range
                                        //                     ),
                                        //                     comp_stay_range,
                                        //                     date_range_type: "dataAsOfChanged",
                                        //                 },
                                        //             },
                                        //         });
                                        //     }
                                        //     if (
                                        //         !curr_app_state.data_as_of &&
                                        //         curr_app_state.primary_stay_range.as_of_date ===
                                        //         item.value.meta.as_of
                                        //     ) {
                                        //         dataAsOf = item.value.meta.as_of;
                                        //         app_state.data_as_of = dataAsOf;
                                        //         dataAsOfChanged = false;
                                        //     }
                                        // }
                                    }
                                    if (
                                        item.value &&
                                        ["OSRContrainedForecast"].includes(item.value.data_id)
                                    ) {
                                        //Update data in local cache
                                        //Fix for multiple segments
                                        let blankPaceObj = {
                                            comparison: {
                                                Rooms: 0,
                                                Occ: 0,
                                                Revenue: 0,
                                                RevPAR: 0,
                                                ADR: 0,
                                            },
                                            primary: {
                                                Rooms: 0,
                                                Occ: 0,
                                                Revenue: 0,
                                                RevPAR: 0,
                                                ADR: 0,
                                            },
                                        };
                                        let segmentedData = deepCopy(item.value);
                                        let { updateDataCache } = dataService;
                                        let segmentedGroupedForecastData = [];
                                        let dates = [];
                                        let segments = Object.keys(marketSegments);
                                        if (!segmentedData.data) segmentedData.data = [];

                                        segmentedData.data.forEach((item, index) => {
                                            if (!dates.includes(item.stay_date)) {
                                                dates.push(item.stay_date);
                                                let dateData = segmentedData.data.filter(
                                                    (di, ind) => di.stay_date === item.stay_date
                                                );
                                                let segments = Object.keys(marketSegments);
                                                let itmKeys = Object.keys(item);
                                                let compDate;
                                                itmKeys.forEach((itm) => {
                                                    if (itm !== "HotelTotal" && itm !== "index") {
                                                        compDate =
                                                            item[itm].comparison && item[itm].comparison.date
                                                                ? item[itm].comparison.date
                                                                : null;
                                                        if (compDate) {
                                                            blankPaceObj.comparison.date = compDate;
                                                        }
                                                    }
                                                });
                                                segments.forEach((segName, i) => {
                                                    let firstCode = marketSegments[segName][0];
                                                    let codes = marketSegments[segName];
                                                    // let segmentedDataGroup = dateData.filter((di, ind) => codes.includes(di.market_code));
                                                    let segmentedDataGroup = dateData.filter((di, ind) =>
                                                        codes.includes(di.market_code)
                                                    );
                                                    // debugger;
                                                    if (segmentedDataGroup && segmentedDataGroup.length) {
                                                        const aggregatedData =
                                                            aggregateSegmentedGroupedData(
                                                                segmentedDataGroup,
                                                                true
                                                            );

                                                        aggregatedData.market_code = segName; //firstCode;
                                                        segmentedGroupedForecastData.push(aggregatedData);
                                                    } else {
                                                        segmentedDataGroup.push(deepCopy(blankPaceObj));
                                                    }
                                                });
                                            }

                                            // let itm = item;
                                            // debugger;
                                        });

                                        let segmented_data = dataService.getSeriesById(
                                            item.value.data_id
                                        );

                                        // debugger;

                                        segmented_data.data.data_transformed = correctTotalValue(
                                            segmentedGroupedForecastData,
                                            marketSegments
                                        );
                                        updateDataCache(item.value.data_id, segmented_data);
                                    }

                                    if (active_tiles.length > 1
                                        && active_tiles.includes('market')
                                        && item.value
                                        && ["ForecastMarketSegment"].includes(item.value.data_id)
                                    ) {
                                        //Update data in local cache
                                        //Fix for multiple segments
                                        let blankObj = {
                                            comparison: {
                                                Rooms: 0,
                                                Occ: 0,
                                                Revenue: 0,
                                                RevPAR: 0,
                                                ADR: 0,
                                            },
                                            primary: {
                                                Rooms: 0,
                                                Occ: 0,
                                                Revenue: 0,
                                                RevPAR: 0,
                                                ADR: 0,
                                            },
                                        };
                                        let segmentedData = deepCopy(item.value);
                                        let { updateDataCache } = dataService;
                                        let segmentedGroupedForecastData = [];
                                        let dates = [];
                                        let segments = getMarketSegment(active_tiles);
                                        //Object.keys(marketSegments);
                                        if (!segmentedData.data) segmentedData.data = [];
                                        // debugger;
                                        segmentedData.data.forEach((item, index) => {
                                            if (!dates.includes(item.stay_date)) {
                                                dates.push(item.stay_date);
                                                let dateData = segmentedData.data.filter(
                                                    (di, ind) => di.stay_date === item.stay_date
                                                );

                                                segments.forEach((segName, i) => {
                                                    let codes = [segName.code];
                                                    let segmentedDataGroup = dateData.filter((di, ind) =>
                                                        codes.includes(di.market_segment)
                                                    );

                                                    if (segmentedDataGroup && segmentedDataGroup.length) {
                                                        const aggregatedData =
                                                            aggregateSegmentedGroupedData(
                                                                segmentedDataGroup,
                                                                true,
                                                                'ForecastMarketSegment'
                                                            );
                                                        // debugger;
                                                        aggregatedData.market_code = segName.code; //firstCode;
                                                        segmentedGroupedForecastData.push(aggregatedData);
                                                    } else {
                                                        segmentedDataGroup.push(deepCopy(blankObj));
                                                    }
                                                });
                                            }
                                        });

                                        let segmented_data = dataService.getSeriesById(
                                            item.value.data_id
                                        );

                                        // debugger;

                                        segmented_data.data.data = correctTotalValue(
                                            segmentedGroupedForecastData,
                                            segments
                                        );
                                        updateDataCache(item.value.data_id, segmented_data);
                                    }

                                    if (
                                        item.value &&
                                        item.value.data_id &&
                                        [
                                            "OSRBookingPaceSegment",
                                            "OSRBookingPaceSegmentDBA",
                                        ].includes(item.value.data_id)
                                    ) {
                                        //Update data in local cache
                                        //Fix for multiple segments

                                        let segmentedData = deepCopy(item.value);
                                        let { updateDataCache } = dataService;

                                        segmentedData.data.forEach((item, index) => {
                                            let segments = Object.keys(marketSegments);
                                            segments.forEach((segName) => {
                                                let firstCode = "";
                                                let segmentedDataGroup = [];
                                                if (
                                                    marketSegments[segName] &&
                                                    marketSegments[segName].length > 1
                                                ) {
                                                    let codes = marketSegments[segName];
                                                    segmentedDataGroup.push(deepCopy(item.index));
                                                    codes.forEach((code, idx) => {
                                                        firstCode = firstCode ? firstCode : code;
                                                        if (item["Segment_" + code]) {
                                                            segmentedDataGroup.push(
                                                                deepCopy(item["Segment_" + code])
                                                            );
                                                            delete item["Segment_" + code];
                                                        }
                                                    });
                                                    const aggregatedData =
                                                        aggregateSegmentedGroupedData(segmentedDataGroup);

                                                    item["Segment_" + firstCode] = aggregatedData;
                                                }
                                            });
                                            // let itm = item;
                                        });

                                        let segmented_data = dataService.getSeriesById(
                                            item.value.data_id
                                        );

                                        if (!segmented_data.data.transformed) {
                                            segmented_data.data.transformed = true;
                                            segmented_data.data.data = segmentedData.data;
                                            updateDataCache(item.value.data_id, segmented_data);
                                        }
                                    }

                                    return item.value;
                                } else {
                                    if (item.reason) {
                                        return {
                                            data: [],
                                            status: "error",
                                            data_id: item.reason.data_id,
                                            message: item.reason.message,
                                        };
                                    }
                                }
                            });

                            if (
                                !APP_CACHE.active_tiles.length ||
                                in_array(active_tiles, APP_CACHE.active_tiles)
                            ) {
                                if (settled.length === 1) {
                                    setDisplayData((prev) => {
                                        return dataArray;
                                    });
                                    // setCompState({ ...compState, dataLoading: false, dataLoaded:true });
                                }
                                if (settled.length > 1) {
                                    // let dataArray = settled.map(({ value }) => value);
                                    /** add  MarketForecast agg data in the api registry */
                                    let marketForecast = settled.find((item) => {
                                        if (item.value && item.value.data_id && item.value.data_id === "MarketForecastSegmented") {
                                            return true;
                                        }
                                    })
                                    if (marketForecast && marketForecast.value) {
                                        let marketForecastDataAgg = getMarketFutureSegmentedAgg(marketForecast.value.data);
                                        let marketForecastData = getSeriesById('MarketForecast');
                                        // debugger;
                                        let response = deepCopy(marketForecast.value);
                                        response.data = marketForecastDataAgg;
                                        response.data_id = "MarketForecast";
                                        marketForecastData.data = response;
                                    }
                                    /** end  */

                                    if (active_tiles.length > 1) {
                                        if (active_tiles.includes('market')) {
                                            let marketForecastData = getSeriesById('MarketForecast');
                                            let marketForecastSegmented = dataArray.find(item => item.data_id === "MarketForecastSegmented");
                                            let marketForecastSegmented_copy = deepCopy(marketForecastSegmented);
                                            marketForecastSegmented_copy.data = marketForecastData.data;
                                            marketForecastSegmented_copy.data_id = 'MarketForecast';
                                            dataArray.push(marketForecastSegmented_copy);
                                            console.log(ui_state);
                                            // debugger;
                                            // ui_state.table_filters = {
                                            //     ...curr_ui_state.table_filters,
                                            //     table_common: {
                                            //         ...curr_ui_state.table_filters.table_common,
                                            //         segments: getMarketSegment(active_tiles)
                                            //     }
                                            // }

                                            // ui_state.table_filters.table_common.segments = getMarketSegment(active_tiles);
                                        }
                                        // debugger;
                                        setDisplayData((prev) => {
                                            return deepCopy(dataArray);
                                        });
                                    } else {

                                        if (["compset", "market"].includes(active_tiles[0])) {
                                            // debugger;
                                            if (dataArray.length) {
                                                if (scopeType === "Date") {
                                                    setDisplayData([dataArray[0]]);
                                                    main_active_api = dataArray[0].data_id;
                                                }
                                                if (scopeType === "Dba") {
                                                    setDisplayData([dataArray[1]]);
                                                    main_active_api = dataArray[1].data_id;
                                                }
                                            }

                                            // debugger;
                                        } else {
                                            //Adding this to reduce otb api calls
                                            let osr = dataArray.find(
                                                (data) => data && data.data_id && data.data_id === "OSR"
                                            );
                                            if (osr) {
                                                let OSRSegment = deepCopy(osr);
                                                OSRSegment.data_id = "OSRSegment";
                                                dataArray.splice(1, 0, OSRSegment);
                                                let OSRYear = deepCopy(osr);
                                                OSRYear.data_id = "OSRYear";
                                                dataArray.splice(2, 0, OSRYear);
                                                // dataArray.push(OSRSegment);
                                                // dataArray.push(OSRYear);
                                            }

                                            setDisplayData((prev) => {
                                                return deepCopy(dataArray);
                                            });
                                        }
                                    }
                                }

                                // debugger;
                                ui_state.showspinner = false;
                                app_state.data_load = false;
                                app_state.active_api = main_active_api;
                                app_state.api_call_complete = true;

                                // if (dataAsOfChanged) {
                                //     app_state.date_range_type = "dataAsOfChanged";
                                //     app_state.data_as_of = dataAsOf;
                                // }
                                dispatch({
                                    type: UPDATE_STATE,
                                    payload: {
                                        app_state: {
                                            ...app_state,
                                        },
                                        ui_state: {
                                            ...ui_state,
                                            showspinner: false,
                                        },
                                    },
                                });

                                setCompState({
                                    ...compState,
                                    dataLoading: false,
                                    dataLoaded: true,
                                    dataStatus: { ...new_data_status },
                                });
                            } else {
                                if (
                                    APP_CACHE.active_tiles.length &&
                                    !in_array(active_tiles, APP_CACHE.active_tiles)
                                ) {
                                    let at = APP_CACHE.active_tiles;
                                    // debugger;
                                    compRef.current.dataLoading = false;
                                    ui_state.active_tiles = deepCopy(APP_CACHE.active_tiles);
                                    compState.reloadOnAbort = true;
                                    // compState.dataStatus = {};
                                    //compState.activeTiles = APP_CACHE.active_tiles;
                                    compState.dataLoading = false;
                                    setCompState({ ...compState });
                                    console.log("Dispatched from workspace", app_state);
                                    dispatch({
                                        type: UPDATE_STATE,
                                        payload: {
                                            app_state: { ...app_state },
                                            ui_state: { ...ui_state, showspinner: false },
                                        },
                                    });
                                }
                            }

                            // debugger;
                        }

                        if (!isAborted && APP_CACHE.propertySwitchStatus === "inprogress") {
                            compRef.current.abortedRequest = true;
                            compRef.current.dataLoading = false;
                            compRef.current.dataLoaded = false;
                            setCompState({
                                ...compState,
                                dataLoading: false,
                                dataLoaded: false,
                            });
                        }
                    }
                }

            });
        }
        //-->
    };

    function commonSuccessHandler(data_id) {
        // debugger;
        return handleData;
        function handleData(rs) {
            let new_data_status = {};
            if (rs && rs.status === "success") {
                api_status[data_id] = "loaded";

                if (data_id === "GetRatePlan") {
                    compRef.current.dataStatus["GetRatePlan"] = "loaded";
                }

                if (["OSR", "OSRContrainedForecast", "marketOtbSegment"].includes(data_id)) {
                    app_state.kpi_status[data_id] = "loaded";
                    let kpiLoad = kpi_load;
                    if (app_state.kpi_status['OSR'] && app_state.kpi_status['OSRContrainedForecast']) {
                        kpiLoad = false;
                    }
                    // debugger
                    let metas = app_state.metas;
                    if (data_id === "OSR") {
                        metas.osr = deepCopy(rs.meta);
                    }
                    if (data_id === "marketOtbSegment") {
                        metas.market = rs.meta;
                    }
                    dispatch({
                        type: UPDATE_STATE,
                        payload: {
                            app_state: {
                                // ...app_state,
                                kpi_status: { ...app_state.kpi_status },
                                kpi_load: kpiLoad,
                                metas: { ...metas }
                            },
                            // ui_state: { ...ui_state },
                        },
                    });
                }
                //let cached = DataService.getSeriesById(data_id);
                // console.log('commonSuccessHandler sent for data_status update active_dataId', data_id);
            } else {
                // console.log('commonSuccessHandler Data NOT OK for:', data_id);
                new_data_status[data_id] = "error";
                api_status[data_id] = "error";
            }
        }
    }

    function commonErrorHandler(data_id, active_dataId) {
        let new_data_status = {};
        return handleError;
        function handleError(data) {
            // debugger;
            console.log(
                "commonErrorHandler called for: ",
                app_state,
                data,
                data_id,
                active_dataId
            );
            api_status[data_id] = "error";
            new_data_status[data_id] = "error";
        }
    }

    const acceptAllRates = () => {
        const { date_from, date_until } = primary_stay_range;

        let stay_date = date_from;
        const propertyInfo = getPropertyInfo(property_details, property_id);
        const room_settings = safeReference(
            propertyInfo.system_settings[0],
            [],
            ["inventory_settings", "current", "room_settings"]
        );
        const pricingData = getPricingDataFormatted(app_state, true);
        const numDays = pricingData.length;
        let baseRoom = room_settings.filter((room) => room.is_base)[0];
        for (let index = 0; index < numDays; index++) {
            if (!roomtype_pricing_overrides[stay_date]) {
                roomtype_pricing_overrides[stay_date] = {};
            }
            room_settings.forEach((room) => {
                let override = {
                    SellRate: 0,
                };

                if (room.is_base === true) {
                    override = {
                        lodgiqRateDouble: true,
                        lodgiqRateSingle: false,
                    };
                    override.SellRate = pricingData[index][room.room_code].RecSellRate;
                    override["previous_data"] = deepCopy(pricingData[index][room.room_code]);
                    override["previous_data"]["baseRoom"] = deepCopy(pricingData[index][baseRoom.room_code]);
                } else {
                    let baseRate = pricingData[index][baseRoom.room_code].SellRate;
                    override = {
                        curr_diff_arrow: true,
                        current_differential:
                            pricingData[index][room.room_code].lodgiq_differential,
                        lodgiq_diff_checked: true,
                    };
                    override.SellRate = pricingData[index][room.room_code].RecSellRate;
                    override["previous_data"] = deepCopy(pricingData[index][room.room_code]);
                    override["previous_data"]["baseRoom"] = deepCopy(pricingData[index][baseRoom.room_code]);
                }
                if (
                    pricingData[index][room.room_code].SellRate !==
                    pricingData[index][room.room_code].RecSellRate
                ) {
                    if (!roomtype_pricing_overrides[stay_date][room.room_code]) {
                        roomtype_pricing_overrides[stay_date][room.room_code] = {};
                    }
                    roomtype_pricing_overrides[stay_date][room.room_code] = {
                        ...override,
                    };
                }
            });
            stay_date = moment(stay_date).add(1, "days").format("YYYY-MM-DD");
        }
        dispatch(
            updateAppState("change_pricing_rates", {
                app_state: {
                    roomtype_pricing_overrides: roomtype_pricing_overrides,
                },
            })
        );
    };

    const OnDropHandler = (event) => {
        if(event.dataTransfer.getData("tileDetails")){
            event.preventDefault();
            let tileDetails = JSON.parse(event.dataTransfer.getData("tileDetails"));
            let activeTile = TILES[ui_state.active_tiles];
            // debugger
            props.onTileMerge(tileDetails);
        }
        // debugger;
    };
    // console.log("Rerendering with", displayData, Date.now());

    const renderWorkspacePanel = () => {
        let tiles =
            ui_state.active_tiles && ui_state.active_tiles.length
                ? ui_state.active_tiles
                : APP_CACHE.active_tiles;
        let titles = [];
        let title = "";
        let user_view = app_state.user_view;
        tiles.map((tile) => {
            let title = TILES[tile].title ? TILES[tile].title : TILES[tile].label;
            let id = TILES[tile].id;
            if (tile === "pickup") {
                title = app_state.workspace_controls.pickUpType + " " + title;
            }
            titles.push({ label: title, id: id });
        });
        ui_state.show_chart_icon = true;
        if (ui_state.active_tiles[0] === "restrictions") {
            ui_state.table_view = true;
            ui_state.workspace_view = "table";
            ui_state.show_chart_icon = false;
        }
        if (ui_state.active_tiles[0] === "market_summary") {
            ui_state.table_view = true;
            ui_state.workspace_view = "table";
            ui_state.show_chart_icon = false;
        }
        if (ui_state.active_tiles[0] === "hotel_summary") {
            ui_state.table_view = true;
            ui_state.workspace_view = "table";
            ui_state.show_chart_icon = false;
        }
        if (ui_state.active_tiles[0] === "forcast") {
            ui_state.table_view = true;
            ui_state.workspace_view = "table";
            ui_state.show_chart_icon = true;
        }
        if (ui_state.active_tiles[0] === "pace") {
            // title = "Booking Curve";
            //ui_state.table_view = true;
            //ui_state.show_chart_icon = false;
        }
        if (ui_state.active_tiles[0] === "pickup") {
            // title = app_state.workspace_controls.pickUpType + "  Pickup";
        }
        // title = titles.join(" + ");
        // debugger;
        let measures = (
            <>
                {ui_state.left_measures_list &&
                    ui_state.left_measures_list.length > 0 && (
                        <Measure
                            options={ui_state.left_measures_list}
                            selectedMeasure={(() => {
                                //debugger;
                                let selected = ui_state.left_measures_list.filter(
                                    (measure) => measure.value === ui_state.left_measure
                                );
                                return selected.length
                                    ? selected[0]
                                    : ui_state.left_measures_list[0];
                            })()}
                            side="left"
                        />
                    )}
                {ui_state.right_measures_list &&
                    ui_state.right_measures_list.length > 0 && (
                        <Measure
                            options={ui_state.right_measures_list}
                            selectedMeasure={(() => {
                                //debugger;
                                let selected = ui_state.right_measures_list.filter(
                                    (measure) => measure.value === ui_state.right_measure
                                );
                                return selected.length
                                    ? selected[0]
                                    : ui_state.right_measures_list[0];
                            })()}
                            side="right"
                        />
                    )}
            </>
        );
        let workspacePanelClasses = "work-space controls";

        switch (ui_state.workspace_view) {
            case "table":
                workspacePanelClasses += " table-view";
                break;
            case "chart":
                workspacePanelClasses += " chart-view";
                break;

            default:
                break;
        }
        const pricingButtons = (
            <Box
                className="pricing-buttons"
                sx={isRoomTypePricing === true ? { right: "50px" } : { right: "125px" }}
            >
                {isRoomTypePricing === true && (
                    <>
                        {" "}
                        <Button
                            className="closeall"
                            onClick={() => {
                                dispatch(
                                    updateAppState("change_pricing_rates", {
                                        app_state: {
                                            roomtype_pricing_overrides: {},
                                            clearAllRoomTypePricing: true,
                                        },
                                    })
                                );
                            }}
                            size="small"
                            sx={{ m: "auto" }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="18"
                                viewBox="0 0 14 15"
                                fill="none"
                            >
                                <path
                                    d="M11.247 11.128C11.2877 11.1686 11.3199 11.2169 11.3419 11.27C11.3639 11.3231 11.3752 11.38 11.3752 11.4375C11.3752 11.495 11.3639 11.5519 11.3419 11.605C11.3199 11.6581 11.2877 11.7064 11.247 11.747C11.2064 11.7877 11.1581 11.8199 11.105 11.8419C11.0519 11.8639 10.995 11.8752 10.9375 11.8752C10.88 11.8752 10.8231 11.8639 10.77 11.8419C10.7169 11.8199 10.6686 11.7877 10.628 11.747L7 8.11851L3.37203 11.747C3.28994 11.8291 3.1786 11.8752 3.0625 11.8752C2.9464 11.8752 2.83506 11.8291 2.75297 11.747C2.67088 11.6649 2.62476 11.5536 2.62476 11.4375C2.62476 11.3214 2.67088 11.2101 2.75297 11.128L6.38148 7.5L2.75297 3.87203C2.67088 3.78994 2.62476 3.6786 2.62476 3.5625C2.62476 3.4464 2.67088 3.33506 2.75297 3.25297C2.83506 3.17088 2.9464 3.12476 3.0625 3.12476C3.1786 3.12476 3.28994 3.17088 3.37203 3.25297L7 6.88148L10.628 3.25297C10.7101 3.17088 10.8214 3.12476 10.9375 3.12476C11.0536 3.12476 11.1649 3.17088 11.247 3.25297C11.3291 3.33506 11.3752 3.4464 11.3752 3.5625C11.3752 3.6786 11.3291 3.78994 11.247 3.87203L7.61851 7.5L11.247 11.128Z"
                                    fill="black"
                                />
                            </svg>
                            Clear
                        </Button>
                        <Button
                            className="acceptall"
                            size="small"
                            onClick={acceptAllRates}
                            sx={{ m: "auto" }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="18"
                                viewBox="0 0 14 15"
                                fill="none"
                            >
                                <path
                                    d="M12.5595 4.74703L5.55953 11.747C5.5189 11.7877 5.47065 11.82 5.41754 11.842C5.36442 11.864 5.30749 11.8753 5.25 11.8753C5.1925 11.8753 5.13557 11.864 5.08246 11.842C5.02935 11.82 4.9811 11.7877 4.94047 11.747L1.87797 8.68453C1.79588 8.60244 1.74976 8.4911 1.74976 8.375C1.74976 8.2589 1.79588 8.14756 1.87797 8.06547C1.96006 7.98337 2.0714 7.93726 2.1875 7.93726C2.3036 7.93726 2.41494 7.98337 2.49703 8.06547L5.25 10.819L11.9405 4.12797C12.0226 4.04587 12.1339 3.99976 12.25 3.99976C12.3661 3.99976 12.4774 4.04587 12.5595 4.12797C12.6416 4.21006 12.6877 4.3214 12.6877 4.4375C12.6877 4.5536 12.6416 4.66494 12.5595 4.74703Z"
                                    fill="#606060"
                                />
                            </svg>
                            Accept
                        </Button>
                    </>
                )}
                {ui_state.workspace_view !== "chart" && <SendRatesButton />}
            </Box>
        );
        let flightsAsof = <Box></Box>
        if (in_array(ui_state.active_tiles, ["flights"])) {
            let text = flight_as_of_date === "" ? "loading.." : dateFormat(flight_as_of_date, "MMM DD, YYYY");
            if (permissions.flights === 0) {
                text = "N.A.";
            }
            flightsAsof = (<Box sx={{ mr: 1, justifySelf: "end", fontFamily: "SFUI Regular", fontSize: "12px" }}>
                <span style={{ color: "#606060" }}>Data Updated on :</span> <span style={{ color: "#333333" }}>{text}</span></Box>)

        }

        return (
            <>
                <Paper
                    className={workspacePanelClasses}
                    elevation={1}
                    sx={{ borderRadius: 0 }}
                >
                    <Box className="top1">
                        <WorkspaceControls />
                    </Box>
                    <Box className="top2">
                        {titles.length === 1 && (
                            <Box
                                className="title single-title"
                                style={{ textTransform: "uppercase", textAlign: "center" }}
                            >
                                <Box></Box>
                                <Box>{titles[0].label}</Box>
                                {flightsAsof}
                            </Box>
                        )}

                        {titles.length > 1 && (
                            <Box
                                className="title"
                                style={{ textTransform: "uppercase", textAlign: "center" }}
                            >
                                {titles.map((title, i) => {
                                    return (
                                        <Chip
                                            className="merged-title"
                                            sx={{ mr: 1 }}
                                            variant="outlined"
                                            color="info"
                                            size="small"
                                            label={title.label}
                                            onDelete={handleUnslect(title.id)}
                                        />
                                    );
                                })}
                                {titles.length > 2 && (
                                    <Chip
                                        className="clear-merged-title"
                                        sx={{ mr: 1 }}
                                        variant="outlined"
                                        color="warning"
                                        size="small"
                                        label="Clear"
                                        onDelete={handleUnslect("all")}
                                    />
                                )}
                            </Box>
                        )}
                        {active_tiles[0] === "pricing" && pricingButtons}
                        {ui_state.workspace_view == "chart" && isLock === false && measures}
                    </Box>
                </Paper>
                <LockLayer
                    userName={user.fullname}
                    isLock={isLock}
                    module={titles[0].label}
                />
            </>
        );
    };
    //console.log("State Updating for: Workspace", displayData, app_state, ui_state);

    function arraymove(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        return arr;
    }

    const handleUnslect = (id) => {
        // debugger;
        return (event) => {
            let thisIndex = ui_state.active_tiles.indexOf(id);
            let activeTiles = ui_state.active_tiles;
            if (thisIndex !== -1) {
                activeTiles.splice(thisIndex, 1);
            } else {
                app_state.workspace_controls.scopeType = "Date";
                activeTiles.splice(0);
                activeTiles.push("otb");
            }
            // debugger;


            // if (activeTiles[0] === "pricing") {
            //     activeTiles = arraymove(activeTiles, 0, 1);
            // }
            if (activeTiles[0] === "compset") {
                activeTiles = arraymove(activeTiles, 0, 1);
                app_state.workspace_controls.segmented_view = false;
            }
            if (activeTiles[0] === "market_sell_rates") {
                activeTiles = arraymove(activeTiles, 0, 1);
                app_state.workspace_controls.segmented_view = false;
            }
            if (activeTiles.includes("pricing")) {
                let princing_idx = activeTiles.indexOf('pricing');
                activeTiles.splice(princing_idx, 1);
                activeTiles.push("pricing");
            }

            if ([2, 3].includes(activeTiles.length) && in_array(activeTiles, ['compset', 'market_sell_rates', 'pricing'])) {
                // app_state.workspace_controls.segmented_view = false;
                // app_state.workspace_controls.segmentType = "hotel";
            }


            APP_CACHE.active_tiles = activeTiles;
            APP_CACHE.controls = deepCopy(app_state.workspace_controls);
            // debugger;
            dispatch(
                updateAppState("change_active_tiles", {
                    app_state: {
                        ...app_state,
                        primary_stay_range: { ...app_state.primary_stay_range, ...app_state.historicalRange },
                        date_range_type: "rangeTileChange_" + activeTiles.join(""),
                        data_load: false, kpi_load: false,
                        workspace_controls: { ...app_state.workspace_controls },
                    },
                    //app_state: {active_dataId, active_api, active_apis, data_load: true },
                    ui_state: {
                        ...ui_state,
                        active_tiles: activeTiles,
                        // workspace_view,
                        showspinner: true,
                    },
                })
            );
        };
    };

    const [containerHeight, setContainerHeight] = useState(720); // Default height
    const [containerWidth, setContainerWidth] = useState(1280); // Default width

    useEffect(() => {
        const updateContainerHeight = () => {
            const newHeight = window.innerHeight;
            setContainerHeight(newHeight);
        };

        const updateContainerWidth = () => {
            const newWidth = window.innerWidth;
            setContainerWidth(newWidth);
        };

        // Initial height and width update
        updateContainerHeight();
        updateContainerWidth();

        // Event listener for window resize
        window.addEventListener("resize", updateContainerHeight);
        window.addEventListener("resize", updateContainerWidth);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("resize", updateContainerHeight);
            window.addEventListener("resize", updateContainerWidth);
            APP_CACHE.controls.scopeType = "Date";
        };
    }, []);

    function randomMessage() {
        const messages = msg;

        const randomIndex = Math.floor(Math.random() * messages.length);
        const randomMessage = messages[randomIndex];

        return randomMessage;
    }

    const graphSkeletonLoader = (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box className="graph-view-scaleton-loader">
                <Skeleton className="skeleton-bars" variant="rounded" height={"40%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"50%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"80%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"70%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"50%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"30%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"20%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"10%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"60%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"90%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"80%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"43%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"45%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"56%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"68%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"45%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"23%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"65%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"37%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"87%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"23%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"45%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"66%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"77%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"55%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"22%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"54%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"41%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"62%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"37%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"21%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"58%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"41%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"55%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"64%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"62%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"53%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"43%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"45%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"54%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"68%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"38%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"57%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"77%"} />
                <Skeleton className="skeleton-bars" variant="rounded" height={"55%"} />

                <Skeleton
                    sx={containerWidth > 1500 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"40%"}
                />
                <Skeleton
                    sx={containerWidth > 1500 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"50%"}
                />
                <Skeleton
                    sx={containerWidth > 1600 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"80%"}
                />
                <Skeleton
                    sx={containerWidth > 1600 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"70%"}
                />
                <Skeleton
                    sx={containerWidth > 1700 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"50%"}
                />
                <Skeleton
                    sx={containerWidth > 1700 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"30%"}
                />
                <Skeleton
                    sx={containerWidth > 1750 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"30%"}
                />
                <Skeleton
                    sx={containerWidth > 1800 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"20%"}
                />
                <Skeleton
                    sx={containerWidth > 1850 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"40%"}
                />
                <Skeleton
                    sx={containerWidth > 1800 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"10%"}
                />
                <Skeleton
                    sx={containerWidth > 1900 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"60%"}
                />
                <Skeleton
                    sx={containerWidth > 1900 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"90%"}
                />
                <Skeleton
                    sx={containerWidth > 1950 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"30%"}
                />
                <Skeleton
                    sx={containerWidth > 2000 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"80%"}
                />
                <Skeleton
                    sx={containerWidth > 2000 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"43%"}
                />
                <Skeleton
                    sx={containerWidth > 2050 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"60%"}
                />
                <Skeleton
                    sx={containerWidth > 2100 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"45%"}
                />
                <Skeleton
                    sx={containerWidth > 2100 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"56%"}
                />
                <Skeleton
                    sx={containerWidth > 2150 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"60%"}
                />
                <Skeleton
                    sx={containerWidth > 2200 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"68%"}
                />
                <Skeleton
                    sx={containerWidth > 2200 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"45%"}
                />
                <Skeleton
                    sx={containerWidth > 1250 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"40%"}
                />
                <Skeleton
                    sx={containerWidth > 2250 ? { display: "all" } : { display: "none" }}
                    className="skeleton-bars"
                    variant="rounded"
                    height={"60%"}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "end",
                    padding: "4px 16px 8px 16px",
                }}
            >
                <Skeleton variant="rounded" width={100} height={20} />
                <Skeleton variant="rounded" width={100} height={20} />
            </Box>
        </Box>
    );

    const tableSkeletonLoader = (
        <Box p={0} m={0}>
            <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table-view-scaleton-loader"
            >
                <TableHead
                    sx={{ background: "#606060", opacity: "0.4" }}
                    className="table-head"
                >
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell sx={{ width: "15%" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "70%" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "15%" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>

            <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table-view-scaleton-loader"
            >
                <TableHead
                    sx={{ background: "#01A4EF", opacity: "0.3" }}
                    className="table-head"
                >
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell
                            sx={{ background: "#B2B2B2", width: "7.5%" }}
                            align="center"
                        >
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell
                            sx={{ background: "#B2B2B2", width: "7.5%" }}
                            align="center"
                        >
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "10vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "25vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "30vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody className="table-body">
                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 700 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 700 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 700 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 750 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 800 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 830 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 850 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 890 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 950 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 900 && containerHeight < 950
                                ? { display: "all" }
                                : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 950 && containerHeight < 1001
                                ? { display: "all" }
                                : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 1000 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1020 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1050 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1080 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 1100 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1130 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1170 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 1200 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                    <TableRow
                        sx={
                            containerHeight > 1240 && containerHeight < 1280
                                ? { display: "all" }
                                : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 1280 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>

                    <TableRow
                        sx={
                            containerHeight > 1300 ? { display: "all" } : { display: "none" }
                        }
                    >
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                className="table-view-scaleton-loader"
            >
                <TableHead
                    sx={{ background: "#01A4EF", opacity: "0.3" }}
                    className="table-head"
                >
                    <TableRow sx={{ border: "1px solid #FFFFFF" }}>
                        <TableCell sx={{ width: "15%" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "10vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "25vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell sx={{ width: "30vh" }} align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                        <TableCell align="center">
                            <Skeleton className="skeleton-line" variant="rounded"></Skeleton>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
        </Box>
    );

    const defaultOptionsGraph = {
        loop: true,
        autoplay: true,
        animationData: GraphLoadingLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const defaultOptionsTable = {
        loop: true,
        autoplay: true,
        animationData: TableLoadingLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const GraphLottieLoader = () => {
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const timer = setTimeout(() => {
                setLoading(true);
            }, 2000); // Adding delay in milliseconds

            // Clear the timeout if the component is unmounted before the delay is completed
            return () => clearTimeout(timer);
        }, []);

        if (loading) {
            return (
                <Box className="lottie-main-container">
                    <Box className="table-lottie-container">
                        <Lottie options={defaultOptionsGraph} height={40} width={40} />
                        <div>{randomMessage()}</div>
                    </Box>
                </Box>
            );
        }
        return null;
    };

    const TableLottieLoader = () => {
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            const timer = setTimeout(() => {
                setLoading(true);
            }, 2000); // Adding delay in milliseconds

            // Clear the timeout if the component is unmounted before the delay is completed
            return () => clearTimeout(timer);
        }, []);

        if (loading) {
            return (
                <Box className="lottie-main-container">
                    <Box className="table-lottie-container">
                        <Lottie options={defaultOptionsTable} height={40} width={40} />
                        <div>{randomMessage()}</div>
                    </Box>
                </Box>
            );
        }
        return null;
    };

    return (
        <Box item lg={12} id="mychartview" sx={{ md: { pb: 3 } }}>
            {renderWorkspacePanel()}

            {isLock === false && (
                <>
                    {ui_state.workspace_view === "chart" && (
                        <Box
                            onDragOver={(e) => {
                                e.preventDefault();
                            }}
                            onDrop={OnDropHandler}
                            className="workspace-chart-container"
                        >
                            <ErrorBoundary FallbackComponent={ErrorHandler}>
                                {showspinner && (
                                    <>
                                        <GraphLottieLoader />
                                        {graphSkeletonLoader}
                                    </>
                                )}
                                {!showspinner && (
                                    <Chart compDisplayData={deepCopy(displayData)} />
                                )}
                            </ErrorBoundary>
                        </Box>
                    )}

                    {ui_state.workspace_view === "table" && (
                        <Box
                            onDragOver={(e) => {
                                e.preventDefault();
                            }}
                            onDrop={OnDropHandler}
                            className="workspace-table-container"
                        >
                            <ErrorBoundary FallbackComponent={ErrorHandler}>
                                {(!showspinner && displayData && displayData.length) ? (
                                    <>
                                        {displayData &&
                                            displayData.length &&
                                            ui_state.active_tiles[0] === "restrictions" &&
                                            app_state.kpi_status["OSR"] === "loaded" &&
                                            app_state.kpi_status["OSRContrainedForecast"] ===
                                            "loaded" && (
                                                <DynamicTable compDisplayData={deepCopy(displayData)} />
                                            )}
                                        {displayData &&
                                            displayData.length &&
                                            ui_state.active_tiles[0] !== "restrictions" && (
                                                <DynamicTable compDisplayData={deepCopy(displayData)} />
                                            )}
                                    </>
                                )
                                    :
                                    (
                                        <>
                                            <TableLottieLoader />
                                            {tableSkeletonLoader}
                                        </>
                                    )
                                }
                            </ErrorBoundary>
                        </Box>
                    )}
                </>
            )}
            {isLock !== false && (
                <div className="blurImageHolder">
                    {ui_state.active_tiles[0] === "pickup" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/Pickup-hotel-graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "pickup" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/Pickup-Segment-Graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "pickup" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img src="../assets/img/blur-images/Pickup-hotel.png" alt="" />
                        )}
                    {ui_state.active_tiles[0] === "pickup" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img src="../assets/img/blur-images/Pickup-Segment.png" alt="" />
                        )}

                    {ui_state.active_tiles[0] === "pace" &&
                        app_state.workspace_controls.scopeType === "Date" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/Booking-curve-Date-Hotel-Graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "pace" &&
                        app_state.workspace_controls.scopeType === "Date" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/Booking-curve-Date-Segment-Graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "pace" &&
                        app_state.workspace_controls.scopeType === "Date" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/Booking-curve-Date-Hotel.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "pace" &&
                        app_state.workspace_controls.scopeType === "Date" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/Booking-curve-Date-Segment.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "pace" &&
                        app_state.workspace_controls.scopeType === "Dba" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/Booking-curve-DBA-Hotel-Graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "pace" &&
                        app_state.workspace_controls.scopeType === "Dba" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/Booking-curve-DBA-Segment-Graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "pace" &&
                        app_state.workspace_controls.scopeType === "Dba" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/Booking-curve-DBA-Hotel.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "pace" &&
                        app_state.workspace_controls.scopeType === "Dba" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/Booking-curve-DBA-Segment.png"
                                alt=""
                            />
                        )}

                    {ui_state.active_tiles[0] === "compset" &&
                        app_state.workspace_controls.scopeType === "Date" &&
                        ui_state.workspace_view === "chart" && (
                            <img
                                src="../assets/img/blur-images/Compset-date-graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "compset" &&
                        app_state.workspace_controls.scopeType === "Dba" &&
                        ui_state.workspace_view === "chart" && (
                            <img
                                src="../assets/img/blur-images/Compset-DBA-graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "compset" &&
                        app_state.workspace_controls.scopeType === "Date" &&
                        ui_state.workspace_view === "table" && (
                            <img src="../assets/img/blur-images/Compset-date.png" alt="" />
                        )}
                    {ui_state.active_tiles[0] === "compset" &&
                        app_state.workspace_controls.scopeType === "Dba" &&
                        ui_state.workspace_view === "table" && (
                            <img src="../assets/img/blur-images/Compset-DBA.png" alt="" />
                        )}

                    {ui_state.active_tiles[0] === "market_sell_rates" &&
                        ui_state.workspace_view === "chart" && (
                            <img
                                src="../assets/img/blur-images/Market-sell-rate-graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "market_sell_rates" &&
                        ui_state.workspace_view === "table" && (
                            <img
                                src="../assets/img/blur-images/Market-sell-rate.png"
                                alt=""
                            />
                        )}

                    {ui_state.active_tiles[0] === "market" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/Market-Hotel-Graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "market" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/market-segment-graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "market" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img src="../assets/img/blur-images/Market-Hotel.png" alt="" />
                        )}
                    {ui_state.active_tiles[0] === "market" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img src="../assets/img/blur-images/market-segment.png" alt="" />
                        )}

                    {ui_state.active_tiles[0] === "flights" && (
                        <img src="../assets/img/blur-images/Flight.png" alt="" />
                    )}

                    {ui_state.active_tiles[0] === "market_summary" && (
                        <img
                            src="../assets/img/blur-images/Summary-report-hotel.png"
                            alt=""
                        />
                    )}

                    {ui_state.active_tiles[0] === "budget" && (
                        <img
                            src="../assets/img/blur-images/Summary-report-hotel.png"
                            alt=""
                        />
                    )}

                    {ui_state.active_tiles[0] === "forecast" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/Forecast-hotel-Graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "forecast" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/Forecast-Segment-graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "forecast" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img src="../assets/img/blur-images/Forecast-hotel.png" alt="" />
                        )}
                    {ui_state.active_tiles[0] === "forecast" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/Forecast-Segment.png"
                                alt=""
                            />
                        )}

                    {ui_state.active_tiles[0] === "restrictions" &&
                        app_state.workspace_controls.isRestrictionsRoomType === false && (
                            <img
                                src="../assets/img/blur-images/Restriction-hotel.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "restrictions" &&
                        app_state.workspace_controls.isRestrictionsRoomType === true && (
                            <img
                                src="../assets/img/blur-images/Restriction-Room-type.png"
                                alt=""
                            />
                        )}

                    {ui_state.active_tiles[0] === "pricing" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.isRoomTypePricing === false && (
                            <img src="../assets/img/blur-images/Pricing-hotel.png" alt="" />
                        )}
                    {ui_state.active_tiles[0] === "pricing" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.isRoomTypePricing === true && (
                            <img
                                src="../assets/img/blur-images/Pricing-Room-type.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "pricing" &&
                        ui_state.workspace_view === "chart" && (
                            <img
                                src="../assets/img/blur-images/Pricing-hotel-graph.png"
                                alt=""
                            />
                        )}

                    {ui_state.active_tiles[0] === "otb" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-Hotel-graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "otb" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-Segment-graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "otb" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-Hotel.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "otb" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-segment.png"
                                alt=""
                            />
                        )}

                    {ui_state.active_tiles[0] === "otb_v2_multi_segment" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-Hotel-graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "otb_v2_multi_segment" &&
                        ui_state.workspace_view === "chart" &&
                        app_state.workspace_controls.segmentType !== "hotel" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-Segment-graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "otb_v2_multi_segment" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-Hotel.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "otb_v2_multi_segment" &&
                        ui_state.workspace_view === "table" &&
                        app_state.workspace_controls.segmentType !== "hotel" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-segment.png"
                                alt=""
                            />
                        )}

                    {ui_state.active_tiles[0] === "bi_pace_comparison" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-Hotel.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "bi_pace_comparison" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-segment.png"
                                alt=""
                            />
                        )}

                    {ui_state.active_tiles[0] === "bi_pickup_analysis" &&
                        app_state.workspace_controls.segmentType === "hotel" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-Hotel.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "bi_pickup_analysis" &&
                        app_state.workspace_controls.segmentType === "market_segment" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-segment.png"
                                alt=""
                            />
                        )}

                    {ui_state.active_tiles[0] === "revpar_index" && (
                        <img src="../assets/img/blur-images/On-the-book-Hotel.png" alt="" />
                    )}

                    {ui_state.active_tiles[0] === "bi_sensitivity_analysis" &&
                        ui_state.workspace_view === "chart" && (
                            <img
                                src="../assets/img/blur-images/On-the-book-Hotel-graph.png"
                                alt=""
                            />
                        )}
                    {ui_state.active_tiles[0] === "bi_sensitivity_analysis" &&
                        ui_state.workspace_view === "table" && (
                            <img
                                src="../assets/img/blur-images/Compset-DBA-graph.png"
                                alt=""
                            />
                        )}
                </div>
            )}
        </Box>
    );
}

export default memo(Workspace);
