
import moment from "moment";
import { it } from "date-fns/locale";
import dataService from "../../services/data.service";
import { deepCopy, aggregateObject, truncate, daysCountFromRange, getAppData, getCorrectValue, getMarketSegment, round, checkProperValue } from "../../app/util";
import { API_REGISTRY, TILES } from "../../app/config";
import { Key, Map, TempleBuddhist } from "@mui/icons-material";
import marketSegmentedJson from './marketSegmented.json';
import { forecastMarketSegmentedData, forecastSegmentedData } from "./tilesData/forecast";
import { bookingCurveSegmentedDbaData, bookingCurveSegmentedDateData } from "./tilesData/bookingcurvenew";


export function convertToPercent(num, type = 'dec') {
    if (!num) {
        return 0;
    }
    if (type === 'int') {
        num = num / 100;
    } else if (type === 'dec') {
        num = num * 100;
    }
    return Number.parseFloat((Math.round((num * 1000) / 10) / 100).toFixed(2));
}



function getSegmentName(app_state, auth, name) {
    let segmentName = name.split("Segment_");
    name = segmentName[1];
    const { property_details } = auth.profile || [];
    const prop_details = property_details.filter((item, index) => {
        if (item.id === app_state.property_id) {
            return item.id === app_state.property_id;
        }
    });
    const { system_settings } =
        prop_details && prop_details.length ? prop_details[0] : null;
    let mapping = system_settings[0].market_segment_category_mapping;
    let segment = "";
    for (let [key, value] of Object.entries(mapping)) {
        if (value.includes(name)) {
            segment = key;
        }
    }
    return segment;
    // return truncate(segment, 18);
}

export function transformSegmentData(app_state, auth, data, params) {
    data = data ? data : [];
    let { segmentedOn, tile, segments, active_tiles } = params;
    let transformed = [];
    const { dayDate } = app_state.workspace_controls;
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let otbSegmentData = data; //(dataService.getSeriesById("OSR").data) ? deepCopy(dataService.getSeriesById("OSR").data.data) : [];
    let otbSegments = [];
    if (otbSegmentData[0]) {
        otbSegments = Object.keys(otbSegmentData[0]).filter(item =>
            item !== "HotelTotal" && item !== "index");
    }

    if (segmentedOn && (tile === 'otb' || tile === 'pickup')) {

        //  otbSegmentData = otbSegmentData.slice(0,20);
        let segmentTotalAll = {};
        otbSegmentData.map((item, i) => {
            let currentTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let pickupTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let priorTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let sdlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let sdly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let sdly_pickupTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let sdtlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let sdtly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let sdtly_pickupTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

            let ignoreKeys = ['HotelTotalx', 'index'];

            let row = 0;
            let HotelTotal = deepCopy(item.HotelTotal);
            let index = deepCopy(item.index);

            let tempDate = item.index.date;
            let itemKeys = Object.keys(item);
            itemKeys.sort().push(itemKeys.shift());
            itemKeys.map((key) => {
                if (key !== 'index') {
                    let totalAll = {
                        HotelTotal: {}
                    }
                    let tmp = {};
                    if (!item[key].current) {
                        item[key].current = deepCopy(blankObj);
                    }
                    if (!item[key].pickup) {
                        item[key].pickup = deepCopy(blankObj);
                    }
                    if (!item[key].primary) {
                        item[key].primary = deepCopy(blankObj);
                    }
                    if (!item[key].prior) {
                        item[key].prior = deepCopy(blankObj);
                    }
                    if (!item[key].sdly) {
                        item[key].sdly = deepCopy(blankObj);
                    }
                    if (!item[key].sdly_actual) {
                        item[key].sdly_actual = deepCopy(blankObj);
                    }
                    if (!item[key].sdly_pickup) {
                        item[key].sdly_pickup = deepCopy(blankObj);
                    }

                    if (!item[key].sdtly) {
                        item[key].sdtly = deepCopy(blankObj);
                    }
                    if (!item[key].sdtly_actual) {
                        item[key].sdtly_actual = deepCopy(blankObj);
                    }
                    if (!item[key].sdtly_pickup) {
                        item[key].sdtly_pickup = deepCopy(blankObj);
                    }


                    if (!ignoreKeys.includes(key)) {
                        let segmentCode = key.split('_')[1];
                        let segmentName = segmentCode; //segments.map[segmentCode];

                        if (key === "HotelTotal") { segmentName = "Total"; }
                        if (!segmentName) {
                            // debugger;
                            segmentName = "Other";
                            // segmentCode = "Other";
                        }

                        tmp.HotelTotal = deepCopy(item[key]);
                        tmp.index = deepCopy(item.index);
                        tmp.index.segment = segmentName;
                        tmp.index.segment_code = segmentName;
                        transformed.push(tmp);

                        let cols = Object.keys(primaryTotal);
                        cols.map((k) => {
                            currentTotal[k] += parseFloat(item[key].current[k]);
                            pickupTotal[k] += parseFloat(item[key].pickup[k]);
                            primaryTotal[k] += parseFloat(item[key].primary[k]);
                            priorTotal[k] += parseFloat(item[key].prior[k]);
                            sdlyTotal[k] += parseFloat(item[key].sdly[k]);
                            sdly_actualTotal[k] += parseFloat(item[key].sdly_actual[k]);
                            sdly_pickupTotal[k] += parseFloat(item[key].sdly_pickup[k]);
                           
                            // LRV-880 uncomment below 3 lines 
                            // sdtlyTotal[k] += parseFloat(item[key].sdtly[k]);
                            // sdtly_actualTotal[k] += parseFloat(item[key].sdtly_actual[k]);
                            // sdtly_pickupTotal[k] += parseFloat(item[key].sdtly_pickup[k]);
                        })

                        if (!segmentTotalAll[key]) {
                            segmentTotalAll[key] = {
                                current: deepCopy(blankObj),
                                pickup: deepCopy(blankObj),
                                primary: deepCopy(blankObj),
                                prior: deepCopy(blankObj),
                                sdly: deepCopy(blankObj),
                                sdly_pickup: deepCopy(blankObj),
                                sdly_actual: deepCopy(blankObj),
                                sdtly: deepCopy(blankObj),
                                sdtly_pickup: deepCopy(blankObj),
                                sdtly_actual: deepCopy(blankObj)
                            };
                        }
                        let myKey = key;
                        let fieldArr = Object.keys(primaryTotal);
                        fieldArr.map((k) => {
                            segmentTotalAll[key].current[k] += parseFloat(getCorrectValue(item[key].current[k]));
                            segmentTotalAll[key].pickup[k] += parseFloat(getCorrectValue(item[key].pickup[k]));
                            segmentTotalAll[key].primary[k] += parseFloat(getCorrectValue(item[key].primary[k]));
                            segmentTotalAll[key].prior[k] += parseFloat(getCorrectValue(item[key].prior[k]));
                            segmentTotalAll[key].sdly[k] += parseFloat(getCorrectValue(item[key].sdly[k]));
                            segmentTotalAll[key].sdly_pickup[k] += parseFloat(getCorrectValue(item[key].sdly_pickup[k]));
                            segmentTotalAll[key].sdly_actual[k] += parseFloat(getCorrectValue(item[key].sdly_actual[k]));
                            
                            // LRV-880 uncomment below 3 lines 
                            // segmentTotalAll[key].sdtly[k] += parseFloat(getCorrectValue(item[key].sdtly[k]));
                            // segmentTotalAll[key].sdtly_pickup[k] += parseFloat(getCorrectValue(item[key].sdtly_pickup[k]));
                            // segmentTotalAll[key].sdtly_actual[k] += parseFloat(getCorrectValue(item[key].sdtly_actual[k]));
                        })
                    }
                }
            })
        })

        // adding the final total 
        let marketSeg = deepCopy(segments);
        if (active_tiles && active_tiles.length > 1 && active_tiles.includes('market')) {
            marketSeg.list = getMarketSegment(active_tiles);
        }
        let test = osrSegmentTotal(app_state, auth, segmentTotalAll, marketSeg);
        transformed = transformed.concat(test);
    }

    else if (segmentedOn && tile === 'market_xx') {
        if (app_state.workspace_controls.scopeType === 'Dba') {
            transformed = [];
        } else {
            let marketSegmentData = deepCopy(dataService.getSeriesById("MarketARISegmented").data.data);
            // let marketSegmentData = marketSegmentedJson.data;
            // marketSegmentData = marketSegmentData.slice(0,2);

            let segmentTotalAll = {};
            let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

            marketSegmentData.map((item, i) => {
                let marketSegments = Object.keys(marketSegmentData);
                let MarketTotal = deepCopy(item.MarketTotal);
                let index = deepCopy(item.index);
                let ignoreKeys = ['MarketTotalx', 'index'];


                ///////
                // item['MarketSegment_G-Group-Comm'] = deepCopy(item['MarketSegment_G-Group']);
                // let fieldArr1 = Object.keys(blankObj);
                // fieldArr1.map((k) => {

                //     item['MarketSegment_G-Group-Comm'].pickup[k] = parseFloat(item['MarketSegment_G-Group']['pickup'][k]) - parseFloat(item['MarketSegment_G-Group']['pickup_noncomm'][k]);
                //     item['MarketSegment_G-Group-Comm'].primary[k] = parseFloat(item['MarketSegment_G-Group']['primary'][k]) - parseFloat(item['MarketSegment_G-Group']['primary_noncomm'][k]);
                //     item['MarketSegment_G-Group-Comm'].sdly[k] = parseFloat(item['MarketSegment_G-Group']['sdly'][k]) - parseFloat(item['MarketSegment_G-Group']['sdly_noncomm'][k]);
                //     item['MarketSegment_G-Group-Comm'].sdly_actuals[k] = parseFloat(item['MarketSegment_G-Group']['sdly_actuals'][k]) - parseFloat(item['MarketSegment_G-Group']['sdly_actuals_noncomm'][k]);
                // })

                // item['MarketSegment_G-Group-Comm'].pickup["ADR"] = parseFloat(item['MarketSegment_G-Group-Comm'].pickup["Revenue"]) / parseFloat(item['MarketSegment_G-Group-Comm'].pickup["Rooms"])
                // item['MarketSegment_G-Group-Comm'].primary["ADR"] = parseFloat(item['MarketSegment_G-Group-Comm'].primary["Revenue"]) / parseFloat(item['MarketSegment_G-Group-Comm'].primary["Rooms"])
                // item['MarketSegment_G-Group-Comm'].sdly["ADR"] = parseFloat(item['MarketSegment_G-Group-Comm'].sdly["Revenue"]) / parseFloat(item['MarketSegment_G-Group-Comm'].sdly["Rooms"])
                // item['MarketSegment_G-Group-Comm'].sdly_actuals["ADR"] = parseFloat(item['MarketSegment_G-Group-Comm'].sdly_actuals["Revenue"]) / parseFloat(item['MarketSegment_G-Group-Comm'].sdly_actuals["Rooms"])

                // fieldArr1.map((k) => {
                //     item['MarketSegment_G-Group'].pickup[k] = item['MarketSegment_G-Group'].pickup_noncomm[k]
                //     item['MarketSegment_G-Group'].primary[k] = item['MarketSegment_G-Group'].primary_noncomm[k]
                //     item['MarketSegment_G-Group'].sdly[k] = item['MarketSegment_G-Group'].sdly_noncomm[k]
                //     item['MarketSegment_G-Group'].sdly_actuals[k] = item['MarketSegment_G-Group'].sdly_actuals_noncomm[k]
                // })
                /////////////////


                let keysArr = Object.keys(item).sort();
                keysArr.forEach(key => {
                    let tmp = {};
                    if (!ignoreKeys.includes(key)) {
                        let segmentDetails = key;
                        let segmentName = segmentDetails.split('_')[1];
                        if (key === "MarketTotal") { segmentName = "Total"; }
                        if (!segmentName) segmentName = "Other";
                        tmp.MarketTotal = deepCopy(item[key]);
                        tmp.index = deepCopy(item.index);
                        tmp.index.segment = segmentName;
                        transformed.push(tmp);


                        if (!segmentTotalAll[key]) {
                            segmentTotalAll[key] = {
                                pickup: deepCopy(blankObj),
                                pickup_noncomm: deepCopy(blankObj),
                                primary: deepCopy(blankObj),
                                primary_noncomm: deepCopy(blankObj),
                                sdly: deepCopy(blankObj),
                                sdly_actual_noncomm: deepCopy(blankObj),
                                sdly_actual: deepCopy(blankObj),
                                sdly_noncomm: deepCopy(blankObj)
                            };
                        }
                        let fieldArr = Object.keys(blankObj);
                        fieldArr.map((k) => {
                            // console.log(key, " ", k, "item[key].pickup[k] ", item, " "," => ",item[key].pickup[k]);
                            segmentTotalAll[key].pickup[k] += (item[key].pickup[k]) ? parseFloat(item[key].pickup[k]) : 0;
                            segmentTotalAll[key].pickup_noncomm[k] += (item[key].pickup_noncomm[k]) ? parseFloat(item[key].pickup_noncomm[k]) : 0;
                            segmentTotalAll[key].primary[k] += parseFloat(item[key].primary[k]);
                            segmentTotalAll[key].primary_noncomm[k] += parseFloat(item[key].primary_noncomm[k]);
                            segmentTotalAll[key].sdly[k] += parseFloat(item[key].sdly[k]);
                            segmentTotalAll[key].sdly_actual_noncomm[k] += parseFloat(item[key].sdly_actual_noncomm[k]);
                            segmentTotalAll[key].sdly_actual[k] += parseFloat(item[key].sdly_actual[k]);
                            segmentTotalAll[key].sdly_noncomm[k] += parseFloat(item[key].sdly_noncomm[k]);

                            if (key === "MarketSegment_G-Group" && k === "Occ") {
                                console.log(key, " ==> ", segmentTotalAll[key].pickup['Occ']);
                            }
                        })

                        ///
                        fieldArr.map((k) => {
                            if (key === "MarketSegment_G-Group" && k === "Occ") {
                                // segmentTotalAll[key].pickup[k] = segmentTotalAll[key].pickup[k] / 3;
                                console.log(key, " segmentTotalAll[key].pickup[k] ", segmentTotalAll[key].pickup[k]);
                            }
                        })
                        ///

                    }
                });
            })
            console.log("segmentTotalAll =>", segmentTotalAll);
            transformed = transformed.concat(marketSegmentTotal(segmentTotalAll, auth, app_state));
        }
    }

    else if (segmentedOn && tile === 'market') {
        if (app_state.workspace_controls.scopeType === 'Dba') {
            transformed = [];
        } else {
            let marketSegmentData = (dataService.getSeriesById("MarketARISegmented").data) ? deepCopy(dataService.getSeriesById("MarketARISegmented").data.data) : [];
            let segmentTotalAll = {};
            let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
            let calKeys = ['marketotbpickup', 'sdly_variance_sdowly', 'sdly_diff_market_last_year_actuals', "sdly_diff_sdowly", "sdly_variance_market_last_year_actuals"];
            marketSegmentData.map((item, i) => {
                let marketSegments = Object.keys(marketSegmentData);
                let MarketTotal = deepCopy(item.MarketTotal);
                let index = deepCopy(item.index);
                let ignoreKeys = ['MarketTotalx', 'index'];
                let keysArr = Object.keys(item).sort();
                keysArr.forEach(key => {
                    let tmp = {};
                    /** */
                    if (key !== 'index') {
                        let dataObj = {};
                        let marketDataPrimary = item[key]['primary'];
                        let marketDataPickup = item[key]['pickup'];
                        // LRV-880
                        // let marketDataSdly = (dayDate === 'day') ? item[key]['sdly'] : item[key]['sdtly'] ;
                        // let marketDataSdlyActuals = (dayDate === 'day') ? item[key]['sdly_actual'] : item[key]['sdtly_actual'];

                        let marketDataSdly = (dayDate === 'day') ? item[key]['sdly'] : item[key]['sdly'] ;
                        let marketDataSdlyActuals = (dayDate === 'day') ? item[key]['sdly_actual'] : item[key]['sdly_actual'];
                        calKeys.forEach(mainKey => {
                            if (mainKey === "marketotbpickup") {
                                dataObj.Occ = convertToPercent(marketDataPrimary['Occ'] - marketDataPickup['Occ']);
                                dataObj.Rooms = marketDataPrimary['Rooms'] - marketDataPickup['Rooms'];
                                dataObj.RevPAR = marketDataPrimary['RevPAR'] - marketDataPickup['RevPAR'];
                                dataObj.Revenue = marketDataPrimary['Revenue'] - marketDataPickup['Revenue'];
                                dataObj.SellRate = marketDataPrimary['SellRate'] - marketDataPickup['SellRate'];
                                dataObj.ADR = 0;
                                let adrValue = round(dataObj.Revenue / dataObj.Rooms);
                                dataObj.ADR = (isNaN(adrValue)) ? 0.00 : adrValue;
                                item[key][mainKey] = deepCopy(dataObj);
                                marketSegmentData[i][key][mainKey] = deepCopy(dataObj);
                            } else if (mainKey === "sdly_variance_sdowly") {
                                let occValue = (marketDataPrimary['Occ'] - marketDataSdly['Occ']) / marketDataSdly['Occ'];
                                dataObj.Occ = (occValue && isFinite(occValue)) ? convertToPercent(occValue) + "%" : 0.00 + "%";
                                dataObj.Rooms = (((marketDataPrimary['Rooms'] - marketDataSdly['Rooms']) / marketDataSdly['Rooms']) * 100) + "%";
                                dataObj.RevPAR = (((marketDataPrimary['RevPAR'] - marketDataSdly['RevPAR']) / marketDataSdly['RevPAR']) * 100) + "%";
                                dataObj.Revenue = (((marketDataPrimary['Revenue'] - marketDataSdly['Revenue']) / marketDataSdly['Revenue']) * 100) + "%";
                                dataObj.SellRate = (((marketDataPrimary['SellRate'] - marketDataSdly['SellRate']) / marketDataSdly['SellRate']) * 100) + "%";
                                dataObj.ADR = 0;
                                let adr1 = marketDataPrimary['Revenue'] / marketDataPrimary['Rooms'];
                                let adr2 = marketDataSdly['Revenue'] / marketDataSdly['Rooms'];
                                let adrValue = ((adr1 - adr2) / adr2) * 100;
                                dataObj.ADR = (adrValue && isFinite(adrValue)) ? adrValue + "%" : 0.00 + "%";

                                item[key][mainKey] = deepCopy(dataObj);
                                marketSegmentData[i][key][mainKey] = deepCopy(dataObj);
                            } else if (mainKey === "sdly_diff_market_last_year_actuals") {
                                dataObj.Occ = convertToPercent(marketDataSdly['Occ'] - marketDataSdlyActuals['Occ']);
                                dataObj.Rooms = marketDataSdly['Rooms'] - marketDataSdlyActuals['Rooms'];
                                dataObj.RevPAR = marketDataSdly['RevPAR'] - marketDataSdlyActuals['RevPAR'];
                                dataObj.Revenue = marketDataSdly['Revenue'] - marketDataSdlyActuals['Revenue'];
                                dataObj.SellRate = marketDataSdly['SellRate'] - marketDataSdlyActuals['SellRate'];
                                dataObj.ADR = marketDataSdly['ADR'] - marketDataSdlyActuals['ADR'];
                                item[key][mainKey] = deepCopy(dataObj);
                                marketSegmentData[i][key][mainKey] = deepCopy(dataObj);
                            } else if (mainKey === "sdly_diff_sdowly") {
                                dataObj.Occ = convertToPercent(marketDataPrimary['Occ'] - marketDataSdly['Occ']);
                                dataObj.Rooms = marketDataPrimary['Rooms'] - marketDataSdly['Rooms'];
                                dataObj.RevPAR = marketDataPrimary['RevPAR'] - marketDataSdly['RevPAR'];
                                dataObj.Revenue = marketDataPrimary['Revenue'] - marketDataSdly['Revenue'];
                                dataObj.SellRate = marketDataPrimary['SellRate'] - marketDataSdly['SellRate'];
                                dataObj.ADR = marketDataPrimary['ADR'] - marketDataSdly['ADR'];
                                item[key][mainKey] = deepCopy(dataObj);
                                marketSegmentData[i][key][mainKey] = deepCopy(dataObj);
                            } else if (mainKey === "sdly_variance_market_last_year_actuals") {
                                let occValue = (marketDataSdly['Occ'] - marketDataSdlyActuals['Occ']) / marketDataSdlyActuals['Occ'];
                                dataObj.Occ = checkProperValue(convertToPercent(occValue)) + "%";
                                dataObj.Rooms = (((marketDataSdly['Rooms'] - marketDataSdlyActuals['Rooms']) / marketDataSdlyActuals['Rooms']) * 100) + "%";
                                dataObj.RevPAR = (((marketDataSdly['RevPAR'] - marketDataSdlyActuals['RevPAR']) / marketDataSdlyActuals['RevPAR']) * 100) + "%";
                                dataObj.Revenue = (((marketDataSdly['Revenue'] - marketDataSdlyActuals['Revenue']) / marketDataSdlyActuals['Revenue']) * 100) + "%";
                                dataObj.SellRate = (((marketDataSdly['SellRate'] - marketDataSdlyActuals['SellRate']) / marketDataSdlyActuals['SellRate']) * 100) + "%";
                                dataObj.ADR = 0;
                                let adr1 = marketDataSdly['Revenue'] / marketDataSdlyActuals['Rooms'];
                                let adr2 = marketDataSdly['Revenue'] / marketDataSdlyActuals['Rooms'];
                                let adrValue = ((adr1 - adr2) / adr2) * 100;
                                dataObj.ADR = (adrValue && isFinite(adrValue)) ? adrValue + "%" : 0.00 + "%";
                                item[key][mainKey] = deepCopy(dataObj);
                                marketSegmentData[i][key][mainKey] = deepCopy(dataObj);
                            }
                        });
                    }

                    /** */


                    if (!ignoreKeys.includes(key)) {
                        let segmentDetails = key;
                        let segmentName = segmentDetails.split('_')[1];
                        if (key === "MarketTotal") { segmentName = "Total"; }
                        if (!segmentName) segmentName = "Other";
                        tmp.MarketTotal = deepCopy(item[key]);
                        tmp.index = deepCopy(item.index);
                        tmp.index.segment = segmentName;
                        transformed.push(tmp);
                        if (!segmentTotalAll[key]) {
                            segmentTotalAll[key] = {
                                pickup: deepCopy(blankObj),
                                pickup_noncomm: deepCopy(blankObj),
                                primary: deepCopy(blankObj),
                                primary_noncomm: deepCopy(blankObj),
                                sdly: deepCopy(blankObj),
                                sdly_actual_noncomm: deepCopy(blankObj),
                                sdly_actual: deepCopy(blankObj),
                                sdly_noncomm: deepCopy(blankObj),
                                sdtly: deepCopy(blankObj),
                                sdtly_actual: deepCopy(blankObj),
                            };
                        }
                        let fieldArr = Object.keys(blankObj);
                        fieldArr.map((k) => {
                            segmentTotalAll[key].pickup[k] += (item[key].pickup[k]) ? parseFloat(item[key].pickup[k]) : 0;
                            segmentTotalAll[key].pickup_noncomm[k] += (item[key].pickup_noncomm[k]) ? parseFloat(item[key].pickup_noncomm[k]) : 0;
                            segmentTotalAll[key].primary[k] += (item[key].primary[k]) ? parseFloat(item[key].primary[k]) : 0;
                            segmentTotalAll[key].primary_noncomm[k] += parseFloat(item[key].primary_noncomm[k]);
                            segmentTotalAll[key].sdly[k] += parseFloat(item[key].sdly[k]);
                            segmentTotalAll[key].sdly_actual_noncomm[k] += parseFloat(item[key].sdly_actual_noncomm[k]);
                            segmentTotalAll[key].sdly_actual[k] += parseFloat(item[key].sdly_actual[k]);
                            segmentTotalAll[key].sdly_noncomm[k] += parseFloat(item[key].sdly_noncomm[k]);
                            
                            // LRV-880
                            // segmentTotalAll[key].sdtly[k] += parseFloat(item[key].sdtly[k]);
                            // segmentTotalAll[key].sdtly_actual[k] += parseFloat(item[key].sdtly_actual[k]);

                            if (key === "MarketSegment_G-Group" && k === "Occ") {
                                // console.log(key," ==> " ,segmentTotalAll[key].pickup['Occ']);
                            }
                        })

                        ///
                        fieldArr.map((k) => {
                            if (key === "MarketSegment_G-Group" && k === "Occ") {
                                // segmentTotalAll[key].pickup[k] = segmentTotalAll[key].pickup[k] / 3;
                                // console.log(key," segmentTotalAll[key].pickup[k] ",segmentTotalAll[key].pickup[k]);
                            }
                        })
                        ///

                    }
                });
            })
            // console.log("segmentTotalAll in market ==>", segmentTotalAll);
            transformed = transformed.concat(marketSegmentTotal(segmentTotalAll, auth, app_state));
        }
    }

    else if (segmentedOn && tile === 'forecast') {
        if (active_tiles && active_tiles.includes('market')) {
            transformed = forecastMarketSegmentedData(auth, app_state, params);
        } else {
            transformed = forecastSegmentedData(auth, app_state, params);
        }

    } else if (segmentedOn && tile === 'pace') {
        transformed = (app_state.workspace_controls.scopeType === "Date") ? bookingCurveSegmentedDateData(app_state, auth, segments) : bookingCurveSegmentedDbaData(app_state, segments);
        // debugger;
    } else if (segmentedOn && tile === 'pricing') {
        transformed = pricingSegmented(data, otbSegments, segments);
    } else if (segmentedOn && tile === 'compset') {
        transformed = compsetSegmented(data, otbSegments, segments);
    } else if (segmentedOn && tile === 'market_sell_rates') {
        transformed = marketSellRatesSegmented(data, otbSegments, segments);
    }



    if (transformed.length) {
        return (transformed);
    }
    return (data);
}



function marketSellRatesSegmented(data, otbSegments, segments) {
    // debugger;
    let segmented = [];
    otbSegments.push("Total");
    data.forEach((dataItem, dIndex) => {

        otbSegments.forEach(segment => {
            let segName = null;
            let sets = {};
            if (segment === "Total") {
                segName = "Total";
                sets = dataItem;
            } else {
                let segCode = segment.replace("Segment_", "");
                segName = segments.map[segCode];
                let keys = Object.keys(dataItem.sets);
                // debugger;
                keys.forEach((key) => {
                    sets[key] = {
                        "avg": 0,
                        "min": 0,
                        "max": 0,
                        "med": 0
                    };
                })
            }
            if (segName) {
                segmented.push({
                    sets,
                    index: {
                        date: moment(dataItem.index.date).format(),
                        segment: segName,
                        // timestamp: moment(dataItem.stay_date).valueOf()
                    }
                })
            }


        })
    })
    return segmented;
}

function compsetSegmented(data, otbSegments, segments) {
    let segmented = [];
    otbSegments.push("Total");
    data.forEach((dataItem, dIndex) => {

        otbSegments.forEach(segment => {
            let segName = null;
            let sets = {};
            if (segment === "Total") {
                segName = "Total";
                sets = dataItem;
            } else {
                let segCode = segment.replace("Segment_", "");
                segName = segments.map[segCode];
                let keys = Object.keys(dataItem);
                keys.forEach((key) => {
                    sets[key] = {
                        sell_rate: 0
                    };
                })
            }
            if (segName) {
                segmented.push({
                    sets,
                    index: {
                        date: moment(dataItem.index.date).format(),
                        segment: segName,
                        // timestamp: moment(dataItem.stay_date).valueOf()
                    }
                })
            }


        })
    })
    return segmented;
}

function pricingSegmented(data, otbSegments, segments) {
    let segmented = [];
    let segmentGroups = deepCopy(segments.arrUnique);
    segmentGroups.push("Total");
    // otbSegments.push("Total")
    data.forEach((dataItem, dIndex) => {
        // debugger;
        segmentGroups.forEach(groupCode => {
            let segment = "Segment_" + groupCode;
            let segName = groupCode;
            let sets;
            if (groupCode === "Total") {
                segName = "Total";
                sets = dataItem;

            } else {
                let segCode = segment.replace("Segment_", "");

                segName = groupCode; //segments.map[segCode];

                sets = {
                    RecSellRate: 0,
                    SellRate: 0
                };

            }
            if (segName) {
                segmented.push({
                    sets,
                    index: {
                        date: moment(dataItem.stay_date).format(),
                        segment: segName,
                        timestamp: moment(dataItem.stay_date).valueOf()
                    }
                })
            }


        })
    })

    return segmented;
}

function marketSegmentTotal(marketSegmentData, auth, app_state) {
    let transformed = [];

    let marketKeys = Object.keys(marketSegmentData)
    marketKeys.map((key) => {
        let tmp = {
            HotelTotal: {},
            index: { segment: '', date: '' }
        };
        if (key !== "MarketTotal") {
            tmp.MarketTotal = deepCopy(marketSegmentData[key]);
            tmp.index = { date: "" };
            tmp.index.segment = key.split("Segment_")[1];
            transformed.push(tmp);
        }
    })

    let totalCapacity = getAppData(auth, app_state).property_current_total_capacity;
    let mainKey = ["pickup", "pickup_noncomm", "primary", "primary_noncomm", "sdly", "sdly_actual", "sdly_actual_noncomm", "sdly_noncomm"];
    let daysCount = daysCountFromRange(app_state);
    transformed.map((itm) => {
        mainKey.map((key) => {
            let totalCap = totalCapacity * daysCount;
            itm['MarketTotal'][key]['ADR'] = (itm['MarketTotal'][key]['Revenue'] / itm['MarketTotal'][key]['Rooms']);
            itm['MarketTotal'][key]['Occ'] = itm['MarketTotal'][key]['Occ'] / daysCount; //   (itm['MarketTotal'][key]['Rooms'] / totalCap);
            itm['MarketTotal'][key]['RevPAR'] = (itm['MarketTotal'][key]['RevPAR'] / daysCount);
        })
    })
    return transformed;
}



function osrSegmentTotal(app_state, auth, otbSegmentData, segments) {
    let transformed = [];
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let segmentsList = segments.list;
    let idx = null;
    var tmp = {};
    for (let i = 0; i < segmentsList.length; i++) {
        tmp = {
            HotelTotal: {},
            index: { segment: '', date: '' }
        };
        let name = segmentsList[i].name;
        let segmentCode = "Segment_" + segmentsList[i].code;
        if (!otbSegmentData[segmentCode]) {
            otbSegmentData[segmentCode] = {
                current: deepCopy(blankObj),
                pickup: deepCopy(blankObj),
                primary: deepCopy(blankObj),
                prior: deepCopy(blankObj),
                sdly: deepCopy(blankObj),
                sdly_pickup: deepCopy(blankObj),
                sdly_actual: deepCopy(blankObj),
                sdtly: deepCopy(blankObj),
                sdtly_pickup: deepCopy(blankObj),
                sdtly_actual: deepCopy(blankObj),
            };
        }
        tmp.HotelTotal = otbSegmentData[segmentCode];
        tmp.index = { date: "" };
        // tmp.index.segment = name + " Total";
        tmp.index.segment = name;
        tmp.index.segment_code = segmentsList[i].code;
        transformed.push(tmp);
    }
    let Keys = Object.keys(blankObj);
    let obj = {};
    transformed.forEach((item) => {
        if (obj[item.index.segment]) {
            Keys.forEach(element => {
                obj[item.index.segment]['HotelTotal']['current'][element] = obj[item.index.segment]['HotelTotal']['current'][element] + item['HotelTotal']['current'][element];
                obj[item.index.segment]['HotelTotal']['pickup'][element] = obj[item.index.segment]['HotelTotal']['pickup'][element] + item['HotelTotal']['pickup'][element];
                obj[item.index.segment]['HotelTotal']['primary'][element] = obj[item.index.segment]['HotelTotal']['primary'][element] + item['HotelTotal']['primary'][element];
                obj[item.index.segment]['HotelTotal']['prior'][element] = obj[item.index.segment]['HotelTotal']['prior'][element] + item['HotelTotal']['prior'][element];
                obj[item.index.segment]['HotelTotal']['sdly'][element] = obj[item.index.segment]['HotelTotal']['sdly'][element] + item['HotelTotal']['sdly'][element];
                obj[item.index.segment]['HotelTotal']['sdly_pickup'][element] = obj[item.index.segment]['HotelTotal']['sdly_pickup'][element] + item['HotelTotal']['sdly_pickup'][element];
                obj[item.index.segment]['HotelTotal']['sdly_actual'][element] = obj[item.index.segment]['HotelTotal']['sdly_actual'][element] + item['HotelTotal']['sdly_actual'][element];

                // LRV-880
                // obj[item.index.segment]['HotelTotal']['sdtly'][element] = obj[item.index.segment]['HotelTotal']['sdtly'][element] + item['HotelTotal']['sdtly'][element];
                // obj[item.index.segment]['HotelTotal']['sdtly_pickup'][element] = obj[item.index.segment]['HotelTotal']['sdtly_pickup'][element] + item['HotelTotal']['sdtly_pickup'][element];
                // obj[item.index.segment]['HotelTotal']['sdtly_actual'][element] = obj[item.index.segment]['HotelTotal']['sdtly_actual'][element] + item['HotelTotal']['sdtly_actual'][element];
            });
        } else {
            obj[item.index.segment] = item
        }
    })
    let calculatedValue = Object.values(obj)
    let totalCapacity = getAppData(auth, app_state).property_current_total_capacity;
    // let mainKey = ["current", "pickup", "primary", "prior", "sdly", "sdly_pickup", "sdly_actual", "sdtly", "sdtly_pickup", "sdtly_actual"];
    let mainKey = ["current", "pickup", "primary", "prior", "sdly", "sdly_pickup", "sdly_actual"];
    let daysCount = daysCountFromRange(app_state);
    calculatedValue.map((itm) => {
        mainKey.map((key) => {
            let totalCap = totalCapacity * daysCount;
            itm['HotelTotal'][key]['ADR'] = (itm['HotelTotal'][key]['Revenue'] / itm['HotelTotal'][key]['Rooms']);
            itm['HotelTotal'][key]['Occ'] = (itm['HotelTotal'][key]['Rooms'] / totalCap);
            itm['HotelTotal'][key]['RevPAR'] = (itm['HotelTotal'][key]['Revenue'] / totalCap);
        })
    })
    return calculatedValue;
}



function transformedMarketData(app_state, auth, marketSegmentData) {

}






function allSegmentTotal(app_state, auth, otbSegmentData) {
    let transformed = [];
    let data = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let segments = Object.keys(otbSegmentData);
    delete segments.HotelTotal;
    let blankObj = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };

    let currentTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let pickupTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let primaryTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let priorTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdlyTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdly_actualTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let sdly_pickupTotal = { Rooms: 0, Occ: 0, ADR: 0, RevPAR: 0, SellRate: 0, Revenue: 0 };
    let row = 1;
    segments.map((name) => {
        if (name !== "index") {
            let tmp = {
                HotelTotal: {},

            };
            if (name !== 'index' && name !== 'Total' && getSegmentName(app_state, auth, name) !== "") {
                if (otbSegmentData.primary === undefined) {
                    otbSegmentData.primary = { ...blankObj };
                }

                tmp.HotelTotal = otbSegmentData[name];
                tmp.index = { date: "" };//deepCopy(item.index);
                tmp.index.segment = truncate(getSegmentName(app_state, auth, name), 12) + " Total";
                transformed.push(tmp);
            }
        }
        row++;
    })
    if (transformed.length) {
        return transformed;
    }
    return data;

}