import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Login from "../components/Auth/Login";
import { EventsSettings } from '../components/Events/EventsSettings';
import NewPassword from "../components/Auth/NewPassword";
import { PrivateRoute } from './PrivateRoute';
import DashboardLayout from "../components/Dashboard/DashboardLayout";
import { clearMessage } from "../actions/message";
import { history } from "./history";
import ProfileSettings from "../components/Settings";
import { setLastEventLog, cleanIDB, deepCopy } from "./util"
import { DEBUG_MODE } from "./config-default";
import { APP_CACHE } from "./config";
import { FBNotifications } from "../components/layout/dashboard-ui";
import DisplayOnlineStatus from "../components/DisplayOnlineStatus";

//This is for analytics
import { analytics } from '../hooks/use-analytics';
import moment from 'moment';

let APP_RELOADED = true;

// Code is added to start week from sunday in moment js
// moment.updateLocale('en', {
//     week : {
//         dow : 0,
//         doy : 12
//      }
// });

function App() {
    // debugger;
    if (APP_RELOADED) {
        APP_RELOADED = false;
        cleanIDB(); //Clean the IDB on reload
    }

    const { auth, app } = useSelector(state => state);
    const { current_state, prev_state } = app;
    const { app_state, ui_state } = current_state;
    const [activeScreenClass, setActiveScreenClass] = useState('active-screen-');
    const [reload, setReload] = React.useState(0);
    const dispatch = useDispatch();
    const ts = new Date().getTime();
    let [newKey, setNewKey] = useState(ts);

    const compRef = useRef({ app_state, ui_state });

    //Disable all console log in production
    // if (process.env.NODE_ENV === 'development' && !DEBUG_MODE) {
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
        console.log = () => { }
        // console.error = () => { }
        console.debug = () => { }
    }
    // debugger;
    useEffect(() => {
        //Seting analyze into global widnow variable so that we can access from anywhere
       
    
        window.analyze = analytics;
        window.analyze.intilize();
        if (ui_state && app_state && !compRef.current.app_state && compRef.current.ui_state) {
            compRef.current.app_state = app_state;
            compRef.current.ui_state = ui_state;
            // APP_CACHE.controls = deepCopy(app_state.workspace_controls);
        }
    }, []);

    useEffect(() => {
        const old_app_state = prev_state.app_state;
        const old_ui_state = prev_state.ui_state;
        
        if (old_ui_state && old_ui_state.active_tiles && ui_state && app_state && ui_state.active_tiles && (ui_state.active_tiles.join() !== old_ui_state.active_tiles.join() || old_app_state.property_id !== app_state.property_id)) {
            const { active_tiles } = ui_state;
            const {
                primary_stay_range, property_id,
                property_name, user_view, active_data_src,
                workspace_controls
            } = app_state;
    
            //This object we will expand later to capture more information
            const event_metadata = {
                ...primary_stay_range,
                property_id, property_name,
                data_view: user_view,
                segmentType:workspace_controls.segmentType
            };
            //Record Tile changes single
            if (active_tiles && old_ui_state && ui_state.active_tiles.length === 1 && old_ui_state.active_tiles[0] !== active_tiles[0]) {
                let previous_tile = old_ui_state.active_tiles.join();
                if(old_ui_state.active_tiles.length>1){
                    previous_tile = "intellimerged tile";
                }
                window.analyze.track("tile_change", {
                    "previous_tile": previous_tile,
                    "selected_tile": active_tiles.join(),
                    ...event_metadata
                }, { old_app_state, old_ui_state }, current_state);
                // compRef.current.ui_state.active_tiles = active_tiles;
            }
            
            //Record Property change
            if (active_tiles && old_app_state && old_app_state.property_id !== property_id) {
                window.analyze.track("property_changed", {
                    "previous_property": old_app_state.property_id,
                    "selected_property": property_id,
                    ...event_metadata
                }, { old_app_state, old_ui_state }, current_state);
                // compRef.current.app_state.property_id = property_id;
            }

            //Record Data Source change
            // if (active_tiles && old_app_state && old_app_state.active_data_src && old_app_state.active_data_src.pms !== active_data_src.pms) {
                // window.analyze.track("ds_changed", {
                //     "previous_data_source": old_app_state.active_data_src.pms,
                //     "selected_data_source": active_data_src.pms,
                //     ...event_metadata
                // }, { old_app_state, old_ui_state }, current_state);
                // compRef.current.app_state.active_data_src = app_state.active_data_src;
            // }
 //Record Data Source change
              
            //Record Room type pricing toggle
            // if (active_tiles && old_app_state && old_app_state.workspace_controls.isRoomTypePricing !== workspace_controls.isRoomTypePricing) {
                // window.analyze.track("rtp_toggle", {
                //     "pr": workspace_controls.isRoomTypePricing ? 'Yes' : 'No',
                //     ...event_metadata
                // }, { old_app_state, old_ui_state }, current_state);
                // compRef.current.app_state.workspace_controls.isRoomTypePricing = workspace_controls.isRoomTypePricing;
            // }
            

            //Record Alert popup open
            //...That is captured in AlertList.js
        }
        if ( old_ui_state && old_ui_state.show_flash_report !== ui_state.show_flash_report 
            && ui_state.show_flash_report === true && !auth.profile.justLoggedIn) {
            let openedOnLogin =  auth.profile.justLoggedIn
            window.analyze.track("flash_report_opened", {
                "previous_flash_report_popup": 'No',
                "selected_flash_report_popup": 'Yes',
                "Opened On Login": openedOnLogin
            }, null, { app_state, ui_state });
        }


        if (ui_state && ui_state.active_tiles) {
            let activeScreenClass = 'active-screen-';
            activeScreenClass += ui_state && ui_state.active_tiles ? ui_state.active_tiles.join().toLowerCase() : '';
            setActiveScreenClass(activeScreenClass);
        }

    }, [
        ui_state,
        app_state,
    ]);

    useEffect(() => {
        let isSubscribed = true;
        history.listen((location) => {
            dispatch(clearMessage()); // clear message when changing location
        });

        return () => (isSubscribed = false);
    }, [dispatch]);

    // useEffect(() => {

    //     let isSubscribed = true;
    //     if (app_state.rerender_app) {
    //         setReload((state)=> state += 1);
    //         //debugger;
    //     }

    //     return () => (isSubscribed = false);
    // }, [app_state.rerender_app]);

    return (
        <>
            <div onClick={() => {
                setLastEventLog();
            }} className={"App " + activeScreenClass} style={{ height: '100vh' }}>
                <Router>
                    <Routes>
                        <Route element={<Login />} path="/login" />
                        <Route element={<EventsSettings />} path="/events" />
                        <Route element={<NewPassword />} path="/new-password" />
                        <Route key={newKey} element={<PrivateRoute reload={reload} component={DashboardLayout} />} path="/" />
                        <Route element={<PrivateRoute component={ProfileSettings} />} path="/settings" />
                    </Routes>
                </Router>
                <FBNotifications />
                <DisplayOnlineStatus />
            </div>

        </>
    );
}

export default App;
