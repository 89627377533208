import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { deepCopy } from "../app/util";

export function useProductVariant() {
    const {
        auth: { profile: { property_details } },
        app: { current_state: { app_state: { property_id } } }
    } = useSelector(state => state);

    let product_variant = property_details.find((propertyEle) => propertyEle.id === property_id).product_variant;
    let product_variant_keys = product_variant ? Object.keys(product_variant) : [];

    const hasFeature = useCallback(
        (features = [], type = "", match = 'every') => {
            if (!product_variant_keys.includes(type) || type === '') return true
            if (features.length === 0) return false;

            let allFeatureInType = product_variant[type].map((item) => item.toUpperCase());

            const hasAllVariant = features[match](feature => {
                let _feature = feature.toUpperCase();
                return allFeatureInType?.includes(_feature);
            });

            return hasAllVariant
        },
        [property_id],
    )

    const productVariant = deepCopy(product_variant);
    return { hasFeature, productVariant }
}
