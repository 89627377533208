import moment from "moment";
import { getAppData, getSeriesById } from "../../app/util";
import dataService from "../../services/data.service";

// function to create initial payload as per the date selected
export const createPayload = () =>{

}

// function is used to calculate the room details
export const calculateRoomDetails = (auth, app_state, dates) => {
    let { arrivalDate, departureDate } = dates;
    arrivalDate = moment(arrivalDate).format("YYYY-MM-DD");
    departureDate = moment(departureDate).format("YYYY-MM-DD");
    let data = {};
    let system_settings = getAppData(auth, app_state);
    let totalNights = Math.abs(moment(arrivalDate).diff(moment(departureDate), 'days'));
    let totalCapacity = parseInt(system_settings.property_current_total_capacity);
    let selectedDates = [];
    let datesSelectedArrObj = [];
    for (var m = moment(arrivalDate); m.isBefore(departureDate); m.add(1, 'days')) {
        let date = moment(m).format("YYYY-MM-DD");
        selectedDates.push(date);
    }

    selectedDates.map((items, i) => {
        let otbData = getOtbData(items);
        let roomsAvailable = (otbData.length > 0 ) ? totalCapacity - otbData[0]['HotelTotal']['primary']['Rooms'] : totalCapacity ;
        let dataObj = {
            "primary": { 
                "rooms": roomsAvailable 
            },
            "date": items,
            "ancillary": {
                "food_bev": '',
                "facilities": '',
                "audio_visual": '',
                "other": ''
            }
        }
        datesSelectedArrObj.push(dataObj);
    })

    let finalPayload = {
        "as_of_date": app_state.as_of_date,
        "group_name": "",
        "dates": datesSelectedArrObj,
        "overall_request": {
            "profit_percents": {
                "food_bev": '',
                "facilities": '',
                "audio_visual":'',
                "other": ''
            },
            "comp_rooms": {
                "comp_room_amt": '',
                "comp_room_per_rooms": ''
            },
            "comission_percent": ''
        }
    }
    data = {totalNights,totalCapacity,selectedDates,datesSelectedArrObj,finalPayload};
    return data;
}

// function to get the OTB data as per the given date 
export const getOtbData = (selectedDate) => {
    let otbData = (dataService.getSeriesById("OSRYearGroup").data) ? dataService.getSeriesById("OSRYearGroup").data.data : [];
    console.log("Checking otb data ==>> ",otbData);
    let otbFilteredData = [];
    if(otbData){
        otbFilteredData =  otbData.filter((item,index) => {
                return moment(item.index.date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD');
        })
    }
    return otbFilteredData;
}

// function to make correct values like : -ve , not a number etc

export const getCorrectValue = (value) => {
    value = (!value || value < 0 || !Number(value)) ? 0 : value.replace(/^0+/, '') ;
    return value;
}