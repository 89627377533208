import moment from "moment";
import { capitalize, convertToPercent, deepCopy, getAppData, getDatesInRange, getFromState, getObjectFilter, getTransformedSegments, in_array, round, setUpDate } from "../../../app/util";
import { useDataSummary } from "../../Tile/kpiService";
import dataService from "../../../services/data.service";
import { useSelector } from "react-redux";
import { transformSegmentData } from "../segmentedData";
import { getSegmentedCode } from "../processDataReports";

import jsonData from '../test.json';

/**   */


export function getReportData_RoomTypePushStatus(columns, auth, app_state, ui_state) {
    let roomType = dataService.getSeriesById("RoomTypePushStatus").data;
    let data = (roomType) ? roomType.data : [];
    let setup_data = [];
    data.map((item, index) => {
        let data_columns = [];
        columns.map(col => {
            let dataObj = {};
            if (col.type === 'dateColumn') {
                dataObj = setUpDate(dataObj, item.index.date);
            }
            if (col.type === 'dataColumn') {
                col.subcolumns.map(key => {
                   
                    if(key.value === "User"){
                        dataObj[key.value] = item.rate_change.user_name
                    }else if(key.value === "Action"){
                        dataObj[key.value] = (item.rate_change.user_action && item.rate_change.user_action !== null) ? capitalize(item.rate_change.user_action.replaceAll("_"," ")) : "";
                    }else if(key.value === "UpdateTime"){
                        dataObj[key.value] = moment.utc(item.rate_change.update_timestamp).local().format("ddd, MMM DD, YYYY hh:mm A");
                    }else if(key.value === "Status"){
                        dataObj[key.value] = item.rate_status.status;
                    }else if(key.value === "Roomsrate"){
                        dataObj[key.value] = item.rate_change_by_room_code;
                        // debugger;
                    } 
                })
            }
            data_columns.push(dataObj);
        })
        setup_data.push(data_columns);
    })

    return setup_data;
}


/** function for daily rate push */
 
export function getReportData_RoomTypePushStatusDateRange(columns, auth, app_state, ui_state) {
    let roomType = dataService.getSeriesById("RoomTypePushStatusDateRange").data;
    let data = (roomType) ? roomType.data : [];
    data = data.sort((a,b)=> (moment(a.index.date) > moment(b.index.date) ? 1 : -1) );
    const { active_tiles, table_filters } = ui_state;
    const filteredDow = table_filters.table_reports.dow.map(d => d.code);
    data = data.filter((item) => filteredDow.includes(moment(item.index.date).format('ddd').toLocaleLowerCase()));
    let setup_data = [];
    data.map((item, index) => {
        let data_columns = [];
        columns.map(col => {
            let dataObj = {};
            if (col.type === 'dateColumn') {
                dataObj = setUpDate(dataObj, item.index.date);
            }
            if (col.type === 'dataColumn') {
                col.subcolumns.map(key => {
                   
                    if(key.value === "User"){
                        dataObj[key.value] = item.rate_change.user_name
                    }else if(key.value === "Action"){
                        dataObj[key.value] = (item.rate_change.user_action) ? capitalize(item.rate_change.user_action.replaceAll("_"," ")) : "";
                    }else if(key.value === "UpdateTime"){
                        dataObj[key.value] = moment.utc(item.rate_change.update_timestamp).local().format("ddd, MMM DD, YYYY hh:mm A");
                    }else if(key.value === "Status"){
                        dataObj[key.value] = item.rate_status.status;
                    }else if(key.value === "Roomsrate"){
                        dataObj[key.value] = item.rate_change_by_room_code;
                        // debugger;
                    } 
                })
            }
            data_columns.push(dataObj);
        })
        setup_data.push(data_columns);
    })

    return setup_data;
}
 

/** functions for Daily rate push status report */


export function getReportData_dailyRatesendStatus(columns, auth, app_state, ui_state) {
    let roomType = dataService.getSeriesById("RoomTypePushStatus").data;
    // debugger;
    let data = (roomType) ? roomType.data : [];
    let setup_data = [];
    data.map((item, index) => {
        let data_columns = [];
        columns.map(col => {
            let dataObj = {};
            if (col.type === 'dateColumn') {
                dataObj = setUpDate(dataObj, item.index.date);
            }
            if (col.type === 'dataColumn') {
                col.subcolumns.map(key => {
                   
                    if(key.value === "User"){
                        dataObj[key.value] = item.rate_change.user_name
                    }else if(key.value === "Action"){
                        dataObj[key.value] = (item.rate_change.user_action) ? capitalize(item.rate_change.user_action.replaceAll("_"," ")) : "";
                    }else if(key.value === "UpdateTime"){
                        dataObj[key.value] = moment.utc(item.rate_change.update_timestamp).local().format("ddd, MMM DD, YYYY hh:mm A");
                    }else if(key.value === "Status"){
                        dataObj[key.value] = item.rate_status.status;
                    }
                    else if(key.value === "PriorRate"){
                        dataObj[key.value] = (item.snapshot && item.snapshot.rates) ? item.snapshot.rates.current_rate: 0 ;
                    }
                    else if(key.value === "RecRate"){
                        dataObj[key.value] = (item.snapshot && item.snapshot.rates) ? item.snapshot.rates.recommended_rate : 0;
                    }else if(key.value === "ChangeRate"){
                        dataObj[key.value] = item.rate_change.changed_rate;
                    }

                })
            }
            data_columns.push(dataObj);
        })
        setup_data.push(data_columns);
    })

    return setup_data;
}


 
export function getReportData_dailyRatesendStatusDateRange(columns, auth, app_state, ui_state) {
    let roomType = dataService.getSeriesById("RoomTypePushStatusDateRange").data;
    let data = (roomType) ? roomType.data : [];
    data = data.sort((a,b)=> (moment(a.index.date) > moment(b.index.date) ? 1 : -1) );

    const { active_tiles, table_filters } = ui_state;
    const filteredDow = table_filters.table_reports.dow.map(d => d.code);
    data = data.filter((item) => filteredDow.includes(moment(item.index.date).format('ddd').toLocaleLowerCase()));
    // debugger; 
    let setup_data = [];
    data.map((item, index) => {
        let data_columns = [];
        columns.map(col => {
            let dataObj = {};
            if (col.type === 'dateColumn') {
                dataObj = setUpDate(dataObj, item.index.date);
            }
            if (col.type === 'dataColumn') {
                col.subcolumns.map(key => {
                   
                    if(key.value === "User"){
                        dataObj[key.value] = item.rate_change.user_name
                    }else if(key.value === "Action"){
                        dataObj[key.value] = (item.rate_change.user_action) ? capitalize(item.rate_change.user_action.replaceAll("_"," ")) : "";
                    }else if(key.value === "UpdateTime"){
                        dataObj[key.value] = moment.utc(item.rate_change.update_timestamp).local().format("ddd, MMM DD, YYYY hh:mm A");
                    }else if(key.value === "Status"){
                        dataObj[key.value] = item.rate_status.status;
                    }
                    else if(key.value === "PriorRate"){
                        dataObj[key.value] = (item.snapshot && item.snapshot.rates)?item.snapshot.rates.current_rate : 0;
                    }
                    else if(key.value === "RecRate"){
                        dataObj[key.value] = (item.snapshot && item.snapshot.rates)?item.snapshot.rates.recommended_rate : 0;
                    }else if(key.value === "ChangeRate"){
                        dataObj[key.value] = item.rate_change.changed_rate;
                    }
                })
            }
            data_columns.push(dataObj);
        })
        setup_data.push(data_columns);
    })

    return setup_data;
}
