import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import { CloseSharp as CloseSharpIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { checkExistance, truncate } from '../../app/util';
import { Grid, Modal, Tooltip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';


import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LightTooltip } from '../layout/dashboard-ui';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dataService, { fetchDataSimple } from '../../services/data.service';
import AlertFeedback from './AlertFeedback';
import { deepCopy } from '@firebase/util';
import AlertDetails from './AlertDetails';
import CompsetAlertDetails from './CompsetAlertDetails';
import { TILES, APP_CACHE } from '../../app/config-v4-api';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppState } from '../../actions/appstate';
import { useNavigate } from 'react-router-dom';

const AlertList = (props) => {
    //Using global analytics variable
    const analyze = window.analyze;

    const { app, auth } = useSelector((state) => state);
    const { app_state } = app.current_state;
    const dispatch = useDispatch();

    var alertsDetailsData = (props.alertsDetails.alerts && props.alertsDetails.alerts.length > 0) ? props.alertsDetails.alerts : [];
    const [alertsDetailsAll, setAlertsDetailsAll] = React.useState(alertsDetailsData);

    useEffect(() => {
        setAlertsDetailsAll(alertsDetailsData);
    }, [props.alertsDetails.alerts])



    let alertsDetails = alertsDetailsAll.filter((item) => item.status === "Success" || item.status === "fetched" || item.status === "new");
    let alertsReadDetails = alertsDetailsAll.filter((item) => item.status === "read");

    const [open, setOpen] = React.useState(false);
    const [selectedAlert, setSelectedAlert] = React.useState(null);

    const handleClose = () => {
        setOpen(false);

        analyze.track("Notification popup closed", {})
    }

    /** Function is used to update the status of a selected alert */
    const updateAlertStatus = (alert_id, updated_status, asOfDate = null) => {
        const request = {
            "method": "PUT",
            "alertIds": alert_id,
            "status": updated_status,
            "asOfDate": asOfDate
        };

        let api = "alerts/alerts/";
        fetchDataSimple({ api, request })
            .then((response) => {
                if (response.status === "success") {
                    setAlertsDetailsAll(response.alerts)
                    let alertCounts = (response.meta.total - response.meta.read);
                    // debugger
                    //Tracking for analytics
                    let details = response.alerts[0];
                    analyze.track("notification_popup_open", {
                        alert_title: details.header,
                        alert_rule: details.alert_rule.text_rule,
                        alert_count: alertCounts,
                        alert_id: details.alert_id
                    });

                    dispatch({
                        type: 'UPDATE_STATE',
                        payload: {
                            app_state: {
                                app_state: { alertCounts: alertCounts },
                            },
                        }
                    });

                    // dispatch(
                    //     updateAppState(
                    //         "alertUpdate", {
                    //         app_state: { alertCounts: alertCounts },
                    //     }));
                }
            })
            .catch((error) => {
                console.log("Error", error);
            })
    }


    return (
        <div className='alert-container'>
            <Box className="alert-top-bar">
                <div className='notification-left-text'>Notification</div>
                <div className='notification-right-text'>
                </div>
            </Box>

            <Box className="alert-content-bar">
                {
                    (alertsDetails.length <= 0) ?
                        <Alert icon={false} severity="info" className="alert-box-content">
                            <AlertTitle className="alert-text-first" sx={{ textAlign: "center", pl: 12 }}>Notification not found </AlertTitle>
                        </Alert>
                        : <></>
                }

                {/* to show alert where status is Fetched and New  */}

                {(alertsDetails && alertsDetails.length > 0 && alertsDetails.map((item, index) => {
                    let datesCount = item.data.length;
                    return (
                        <>
                            <Alert
                                key={index}
                                icon={false} severity="info"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            debugger;
                                            updateAlertStatus(item.alert_id, "Read", item.asof_date);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" color='disabled' />
                                    </IconButton>
                                }
                                className="alert-box-content">
                                <Box sx={{ width: "300px" }} onClick={() => {
                                    props.handleCloseAlert();
                                    setOpen(true);
                                    setSelectedAlert(item);
                                    updateAlertStatus(item.alert_id, "Fetched", item.asof_date);
                                }}>
                                    <AlertTitle className="alert-text-first"
                                    // onClick={() => {
                                    //     props.handleCloseAlert();
                                    //     setOpen(true);
                                    //     setSelectedAlert(item);
                                    //     updateAlertStatus(item.alert_id, "Fetched", item.asof_date);
                                    // }}
                                    >
                                        {truncate(item.header, 40)}
                                    </AlertTitle>
                                    <AlertTitle className="alert-text-second">Triggered for {datesCount} days</AlertTitle>
                                </Box>
                            </Alert>



                        </>
                    )
                })
                )}




            </Box>

            {/* To Show Read Alert */}
            {(alertsReadDetails && alertsReadDetails.length > 0 &&
                <Box>
                    <div>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "#4794BB" }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className='alert-read-top-bar'
                            >
                                <Typography className='notification-read-left-text'>READ NOTIFICATION</Typography>
                            </AccordionSummary>
                            <AccordionDetails className='acc-read-detail'>
                                <Typography>
                                    {(alertsReadDetails && alertsReadDetails.length > 0 && alertsReadDetails.map((item) => {
                                        let datesCount = item.data.length;
                                        return (
                                            <>
                                                <Box sx={{ width: "100%" }}
                                                    onClick={() => {
                                                        props.handleCloseAlert();
                                                        setOpen(true);
                                                        setSelectedAlert(item);
                                                        updateAlertStatus(item.alert_id, "Fetched", item.asof_date);
                                                    }}>
                                                    <Alert icon={false} severity="info"
                                                        className="alert-read-box-content">
                                                        <AlertTitle className="alert-text-first disbale-color"
                                                            onClick={() => {
                                                                props.handleCloseAlert();
                                                                setOpen(true);
                                                                setSelectedAlert(item);
                                                                updateAlertStatus(item.alert_id, "Fetched", item.asof_date);
                                                            }}>
                                                            {truncate(item.header, 40)}
                                                        </AlertTitle>
                                                        <AlertTitle className="alert-text-second disbale-color">Triggered for {datesCount} days</AlertTitle>
                                                    </Alert>
                                                </Box>
                                            </>
                                        )
                                    })
                                    )}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                    </div>
                </Box>
            )}
            <Modal
                className="popup-modal-wrap"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" >
                <AlertDetailsPopup handleClose={handleClose} selectedAlert={selectedAlert} />
            </Modal>
        </div>
    )
}



export function AlertDetailsPopup(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { app, auth } = useSelector((state) => state);
    const { user, profile } = auth;
    const { app_state, ui_state } = app.current_state;



    let alertDetails = props.selectedAlert;
    let alertTitle = alertDetails.header;
    let alert_type = alertDetails.alert_rule.alert_type;

    let popupContainer;
    let tableContainer;
    if (alert_type === "compset") {
        popupContainer = "alert-popup-compset";
        tableContainer = "table-container-compset"
    } else {
        popupContainer = "alert-popup";
        tableContainer = "table-container"
    }

    const goToTile = (id) => {
        if (id === "settings") {
            dispatch(
                updateAppState("gotoSettings", {
                    ui_state: {
                        isFromHeader: false,
                        active_settings_menu: "propertySettingsMenu",
                        activeSettingsComponentName: "AlertsSettings",
                        settingsActiveIndex: 7,
                    },
                })
            );
            navigate("/settings");
        } else {
            handleOpenReport(id)
        }
        props.handleClose();
    }

    const handleOpenReport = (id) => {
        let currTile = TILES[id];
        if (ui_state.active_tiles.length === 1 && ui_state.active_tiles[0] !== id) {
            const active_tiles = [id];
            let workspace_view = 'table';
            if (currTile.defaultView) {
                workspace_view = currTile.defaultView;
            }

            APP_CACHE.active_tiles = active_tiles;
            dispatch(
                updateAppState("change_active_tiles", {
                    app_state: {
                        date_range_type: 'rangeTileChange',
                        workspace_controls: app_state.workspace_controls,
                        data_load: true,
                    },
                    ui_state: {
                        ...ui_state,
                        active_tiles,
                        workspace_view,
                        showspinner: true
                    },
                })
            );
        }
    }

    return (
        <>
            <Box className={'popup-modal ' + popupContainer} sx={{}}>
                <Box sx={{ position: "relative", height: "100%" }}>
                    <Box className='modal-titlebar'>
                        <Grid container spacing={0} className='PricingModal' sx={{ display: "flex", alignItems: "center" }}>
                            <Grid item xs={9} sx={{ display: "flex", alignItems: "center", gap: "8px" }}>

                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#F8F9FA" viewBox="0 0 256 256"><path d="M221.8,175.94C216.25,166.38,208,139.33,208,104a80,80,0,1,0-160,0c0,35.34-8.26,62.38-13.81,71.94A16,16,0,0,0,48,200H88.81a40,40,0,0,0,78.38,0H208a16,16,0,0,0,13.8-24.06ZM128,216a24,24,0,0,1-22.62-16h45.24A24,24,0,0,1,128,216ZM48,184c7.7-13.24,16-43.92,16-80a64,64,0,1,1,128,0c0,36.05,8.28,66.73,16,80Z"></path></svg>

                                <div className='text-green'>{alertTitle}</div>
                            </Grid>
                            <Grid className='right-cta' xs={3} item alignItems="end">

                                <Button sx={{
                                    minWidth: 'auto', position: 'absolute', top: -12, right: -12, width: '24px', height: '24px', p: '7px',
                                    borderRadius: '50%', borderWidth: '1px', borderStyle: 'solid',
                                    borderColor: '#ccc', boxShadow: '0 0 4px #000', background: '#000', color: '#FFF', fontSize: '10px',
                                    '&:hover': { backgroundColor: '#000' }
                                }} onClick={() => {
                                    props.handleClose()
                                }}>
                                    <CloseSharpIcon fontSize='small' />
                                </Button>
                            </Grid>
                        </Grid>

                    </Box>

                    <Box sx={{ pl: 2, pr: 2 }} className='modal-content' >
                        <Grid item xs={12} className="alert-title-grid">
                            <item><span className='alert-text-title'>{alertDetails.alert_rule.text_rule}</span> </item>
                        </Grid>
                        <TableContainer component={Paper} className={tableContainer}>
                            {(alert_type === "compset") ?
                                <CompsetAlertDetails alertDetails={alertDetails} />
                                :
                                <AlertDetails alertDetails={alertDetails} />
                            }
                        </TableContainer>

                        <Box className='footer-titlebar'>
                            <Grid container spacing={0} className='PricingModal'>
                                <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                    <AlertFeedback alertDetails={alertDetails} alertTitle={alertTitle} />
                                </Box>

                                <Grid className='right-cta' item alignItems="end" textAlign={"right"}>
                                    <Button onClick={() => { goToTile("settings") }} variant="outlined" size='small' className='alert-footer-btn' sx={{ background: "#F8F9FA !important", border: "0px solid #EBEBEB !important", boxShadow: "0px 0px 0px 1px #EBEBEB" }}>Edit Alert</Button>
                                    <Button onClick={() => { goToTile("pricing") }} variant="outlined" size='small' className='alert-footer-btn'>Go To Pricing</Button>
                                    <Button onClick={() => { goToTile("restrictions") }} variant="outlined" size='small' className='alert-footer-btn'>Go To Restriction</Button>
                                </Grid>
                            </Grid>
                        </Box>

                    </Box>


                </Box>
            </Box>
        </>
    )
}

export default AlertList
