import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import {
    Alert, AlertTitle, Snackbar
} from '@mui/material';
import moment from 'moment';
import { logout } from "../../actions/auth";
import { isLoggedIn, addToSession, getFromSession, getDataSources, clearAPIRegistryData } from '../../app/util';
import { ConfirmLogoutDialog } from '../layout/dashboard-ui';
import DataService from "../../services/data.service";
import AuthService from "../../services/auth.service";
import { useOnlineStatus } from '../../hooks/helper-hooks';

export function AtuoLogoutTimer(props) {
    const isOnline = useOnlineStatus();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { children, timeout = 1, graceTime = 2 } = props;
    timeout = timeout * 60 * 1000;
    let [gracetime, setGraceTime] = useState(graceTime * 60000);
    const [confirmOptions, setConfirmOptions] = useState({
        title: "It looks like you have't been using the application for some time!",
        descriptions: <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            You will be logged out automatically in { }
        </Alert>,
        labelOk: "Logout",
        labelCancel: "Wait, I'm still here!",
        open: false,
    });
    const compRefs = useRef({ logoutTimer: null });


    const visibilityChangeHandler = (e) => {
        // debugger;
        let hidden = e.target.hidden;
        if (hidden) {
            // clearTimeout(compRefs.current.logoutTimer);
            compRefs.current.offlineTime = moment().format('x');
            // debugger;
            if (!confirmOptions.open) {
                // compRefs.current.logoutTimer = setTimeout(() => {
                //     openConfirmDialog();
                // }, timeout);
                compRefs.current.logoutTimerOnHidden = setTimeout(() => {
                    handleUserAction('logout');
                }, timeout + gracetime);
            }
            if (confirmOptions.open && compRefs.current.offlineTime) {
                let timeLapsed = compRefs.current.offlineTime - compRefs.current.dialogOpenTime;
                compRefs.current.logoutTimerOnHidden = setTimeout(() => {
                    handleUserAction('logout');
                }, gracetime - timeLapsed);
            }

        }
        if (!hidden) {
            clearTimeout(compRefs.current.logoutTimerOnHidden);

            compRefs.current.onlineTime = moment().format('x');
            compRefs.current.sleeptime = compRefs.current.onlineTime - compRefs.current.offlineTime;

            if (compRefs.current.sleeptime >= (timeout + gracetime)) {
                clearTimeout(compRefs.current.logoutTimer);
                gracetime = gracetime - (compRefs.current.sleeptime - timeout);
                setGraceTime(gracetime);
                handleUserAction('logout');
            }
            // debugger;
            // if (compRefs.current.sleeptime <= (timeout + gracetime)) {
            //     setConfirmOptions((state) => {
            //         return { ...state,  open: true };
            //     });
            // }

            console.log('visibilitychange happeneduser', document.hidden, compRefs.current.sleeptime);
        }
    };

    useEffect(() => {
        if (isLoggedIn()) {
            clearTimeout(compRefs.current.logoutTimer);
            // console.log('Cleared timeout');
            compRefs.current.logoutTimer = setTimeout(() => {
                // console.log('Logging out now due to inactivity');
                // debugger;
                openConfirmDialog();
            }, timeout);

            // document.addEventListener("visibilitychange", onBrowserTabInactiveHandler);

            // window.addEventListener('visibilitychange', visibilityChangeHandler);
            document.addEventListener("mousewheel", handleMouseMove, false);
            document.addEventListener("mousemove", handleMouseMove, false);
            document.addEventListener("mouseleave", handleMouseLeave);
            document.addEventListener("mouseenter", handleMouseEnter)
        }

        //Cleaning up
        return () => {
            // document.removeEventListener("visibilitychange", visibilityChangeHandler);
            document.removeEventListener("mousewheel", handleMouseMove);
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("blur", handleMouseLeave);
            document.removeEventListener("mouseenter", handleMouseEnter);

        }

    }, []);

    function openConfirmDialog(event, item, selectedIndex) {
        // debugger;
        // let isSelected = Number(item.id) === dsInfo.active_source.id ? true : false;
        compRefs.current.dialogOpenTime = moment().format('x');
        confirmOptions.open = true;
        setConfirmOptions((state) => {
            return { ...state, open: true };
        });
    }

    let handleMouseMove = () => {
        if(!document.hasFocus()){
            window.focus();
        }
        if (isLoggedIn()) {
            if (compRefs.current.logoutTimer) {
                clearTimeout(compRefs.current.logoutTimer);
            }
            // console.log('Cleared timeout');
            compRefs.current.mouseLeaveTime = 0;
            compRefs.current.logoutTimer = setTimeout(() => {
                // console.log('Logging out now due to inactivity');
                openConfirmDialog();
            }, timeout);
        }
    };

    let handleMouseLeave = () => {
        if(document.hasFocus()){
            window.blur();
        }
        compRefs.current.mouseLeaveTime = moment().format('x');
    };
    let handleMouseEnter = () => {
        if(!document.hasFocus()){
            window.focus();
        }
        compRefs.current.mouseEnterTime = moment().format('x');
        if(compRefs.current.mouseLeaveTime){
            let timeLapsed = compRefs.current.mouseEnterTime - compRefs.current.mouseLeaveTime;
            if (timeLapsed > timeout && timeLapsed < (gracetime + timeout) && !confirmOptions.open) {
                // debugger;
                openConfirmDialog();
            } else if(timeLapsed >= (gracetime + timeout) && !confirmOptions.open) {
                handleUserAction('logout');
            }
        }
    };

    function handleUserAction(action) {
        // debugger;
        if (!action) {
            setConfirmOptions((state) => {
                return { ...state, open: false };
            })
            // handleDSChange(conFirmDialog.event, conFirmDialog.option);
        }
        if (action === 'logout') {
            // debugger;
            if (!isOnline) {
                clearAPIRegistryData();
                navigate("/login");
                // window.location.reload();
            } else {
                let token = getFromSession('token');
                AuthService.logout({ token: token })
                    .then((rs) => {
                        clearAPIRegistryData();
                        window.location.reload();
                        // debugger;                  
                        // dispatch({ type: 'USER_LOGOUT' });
                        // navigate("/login");
                    }, (err) => {
                        clearAPIRegistryData();
                        window.location.reload();
                    });
                setTimeout(() => {
                    setConfirmOptions((state) => {
                        return { ...state, open: false };
                    });
                }, 200);
            }


        }
        if (action === 'keeploggedin') {
            setConfirmOptions((state) => {
                return { ...state, open: false };
            });
            if (isLoggedIn()) {
                clearTimeout(compRefs.current.logoutTimer);
                clearTimeout(compRefs.current.logoutTimerOnHidden);
                // console.log('Cleared timeout');
                compRefs.current.logoutTimer = setTimeout(() => {
                    // console.log('Logging out now due to inactivity');
                    openConfirmDialog();
                }, timeout);
            }

        }
    }
    return (<div style={{ height: '100vh' }}
    // onMouseMove={handleMouseMove} //Added in doc event listner
    >
        {children}
        <ConfirmLogoutDialog {...confirmOptions} openConfirmDialog={openConfirmDialog} handleUserAction={handleUserAction} graceTime={gracetime / 60000} timeout={timeout} />
    </div>);
}