import React, {  useEffect , useRef, useState} from 'react';
import 
  {
    Link, 
    Typography,  
    LinearProgress, Alert, Snackbar
  } 
from '@mui/material/';
import { Height } from '@mui/icons-material';


function Copyright (props) {
  return (
    <Typography variant="body2" color="text.secondary" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://app.lodgiq.com/">
        LodgIQ
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function LoginLogo (props) {
  return (
    <Link href='https://lodgiq.com/' target='_blank'>
        <img
              src={`assets/img/logo-login3.svg`}
              alt={`Login`}
              width="230"
              height="auto"
              loading="lazy"
              // className='ml-25'
            />
    </Link>
  );
}

function LoginForm (props) {
  return (
    <Link href='https://lodgiq.com/' target='_blank'>
        <img
              src={`assets/img/logo-login.png`}
              alt={`Login`}
              width="160"
              loading="lazy"
            />
    </Link>
  );
}

const LoginProgressbar = React.forwardRef(function LoginProgressbar(props, ref) {
  if(!props.requesting) return '';
  return <LinearProgress ref={ref} sx={{position:'absolute', width:'100%'}} color='green' {...props} />;
});


const LoginAlertBar = React.forwardRef(function LoginAlertBar(props, ref) {
  let {resError, handleClose, resErrorMsg} = props;
  if(resError){
    //we are using Snackbar only for hiding
    return (<div><Snackbar
      open={resError}
      autoHideDuration={6000}
      onClose={handleClose}
      // message={resErrorMsg}
      anchorOrigin={{vertical:'top', horizontal: 'left'}}
      sx={{display:'none', width:'100%'}}
    />
    <Alert  elevation={2} ref={ref} variant="filled" {...props} /></div>);
  } else {
    return '';
  }
});

export {Copyright, LoginLogo, LoginProgressbar, LoginAlertBar, LoginForm};