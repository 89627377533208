import * as React from 'react';
import { Table, TableCell, TableContainer, TableRow, TableBody, Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CloseSharp as CloseSharpIcon } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import moment from 'moment';
import { daysCountFromRange, deepCopy, getAppData, getCorrectValue, getFromState, getPermissions, getTransformedSegments, in_array, sendMultipleAPIs, truncate } from '../../app/util';
import { useSelector } from 'react-redux';
import dataService, { fetchDataSimple } from '../../services/data.service';
import RatePlanList from './RatePlanList';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import RatePlanListTotal from './RatePlanListTotal';
import './rate-plan-popup.scss'
import RatePlanHeader from './RatePlanHeader';
import MultipleTileOptions from './MultipleTileOptions';
import { useRef } from 'react';

export default function RatePlanPopup(props) {

    const state = useSelector(appState => appState);
    const { auth, app } = useSelector(state => state);
    let { stay_date, segmentName } = props.params;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    const { app_state, ui_state } = useSelector(state => state.app.current_state);
    const { property_details } = useSelector(state => state.auth.profile);
    const { permissions, index } = getPermissions(property_details, app_state.property_id);
    const { comp_as_of_date, as_of_date } = app_state;
    const {sdYear, dayDate} = app_state.workspace_controls;
    // stay_date = (stay_date) ? stay_date : as_of_date;
    const [ratePlans, setRatePlans] = React.useState(null);
    const [showRatePlan, setShowRatePlan] = React.useState(false);
    const [ratePlansTotal, setRatePlansTotal] = React.useState(null);
    let firstTile = ui_state.active_tiles[0];
    // let ratePlanMeta;
    let ratePlanMeta = useRef("");
    //Making first tile active for room plan in case 
    if (ui_state.active_tiles.length > 1) {
        let ratePlanSupportedTiles = ['otb', 'pickup', 'pace'];
        let ratePlanSupportedTile = '';
        ui_state.active_tiles.forEach(tile => {
            if (ratePlanSupportedTiles.includes(tile) && !ratePlanSupportedTile) {
                ratePlanSupportedTile = tile;
                firstTile = tile;
            }
        });
    }
    const [activeTiles, setActiveTiles] = React.useState([firstTile]);
    segmentName = (segmentName === 0) ? "" : segmentName;
    let resTotal = segmentName.includes("Total")
    segmentName = segmentName.replaceAll(" Total", "");
    let pickupType = app_state.workspace_controls.pickUpType;
    let active_tiles_global = ui_state.active_tiles;
    // let active_tiles = ui_state.ratePlan_tiles;
    let active_tiles = ui_state.active_tiles;
    let ignore_tile = ['forecast', 'market',];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        let pickupDays = app_state.workspace_controls.pickUpDays;
        let as_of_date = app_state.primary_stay_range.as_of_date;
        let stayDate = moment(stay_date).format('YYYY-MM-DD');
        let sdYear = app_state.workspace_controls.sdYear;
        let property_id = app_state.property_id;
        // Rate plan API
        let comp_as_of_date = app_state.comp_stay_range.comp_as_of_date;
        let compStayDate = app_state.comp_stay_range.date_from;
        let compToDate = app_state.comp_stay_range.date_until;
        let startDate = app_state.primary_stay_range.date_from;
        let endDate = app_state.primary_stay_range.date_until;

// debugger;

        const apiUrls = [];
        // LRV-880 uncomment the below comment 
        // let apiRatePlan = "rms/properties/otb-rp/" + property_id + "/?includeStandard=true&includeSdly=true&includeSdtly=true&fillMissingDays=true&pickupDays=" + pickupDays + "&asOfDate=" + as_of_date + "&stayDate=" + stayDate + "&sdYear=" + sdYear;
        // let apiRatePlanRange = "rms/properties/otb-rp/" + property_id + "/?includeStandard=true&includeSdly=true&includeSdtly=true&fillMissingDays=true&pickupDays=" + pickupDays + "&asOfDate=" + as_of_date + "&fromDate=" + startDate + "&toDate=" + endDate + "&sdYear=" + sdYear;

        let apiRatePlan = "rms/properties/otb-rp/" + property_id + "/?includeStandard=true&includeSdly=true&fillMissingDays=true&pickupDays=" + pickupDays + "&asOfDate=" + as_of_date + "&stayDate=" + stayDate + "&sdYear=" + sdYear;
        let apiRatePlanRange = "rms/properties/otb-rp/" + property_id + "/?includeStandard=true&includeSdly=true&fillMissingDays=true&pickupDays=" + pickupDays + "&asOfDate=" + as_of_date + "&fromDate=" + startDate + "&toDate=" + endDate + "&sdYear=" + sdYear;
        let apiRatePlanPace = "rms/properties/booking-curve-rp/" + property_id + "/?scope=DATE" + "&asOfDate=" + as_of_date + "&fromStayDate=" + stayDate + "&toStayDate=" + stayDate + "&compAsOfDate=" + comp_as_of_date + "&compFromStayDate=" + compStayDate + "&compToStayDate=" + compStayDate;
        let apiRatePlanPaceRange = "rms/properties/booking-curve-rp/" + property_id + "/?scope=DATE" + "&asOfDate=" + as_of_date + "&fromStayDate=" + startDate + "&toStayDate=" + endDate + "&compAsOfDate=" + comp_as_of_date + "&compFromStayDate=" + compStayDate + "&compToStayDate=" + compToDate;
      
        if(in_array(active_tiles,['otb','pickup'])){
            if(!stayDate || stayDate === "Invalid date"){
                apiUrls.push({api:apiRatePlanRange,type:'pace'});
            }else{
                apiUrls.push({api:apiRatePlan,type:'otb'});
            }
        }
        if(in_array(active_tiles,['pace'])){
            if(!stayDate || stayDate === "Invalid date"){
                apiUrls.push({api:apiRatePlanPaceRange,type:'pace'});
            }else{
                apiUrls.push({api:apiRatePlanPace,type:'pace'});
            }
        }
        sendMultipleAPIs(apiUrls, 'GET')
            .then(responses => {
                let getRatePlan = {};
                let GetPaceRatePlan = {};
                getRatePlan = responses[0];
                GetPaceRatePlan = (active_tiles.length > 1) ? responses[1] : responses[0];
                ratePlanMeta.current = getRatePlan?.meta;
                // End rate Plan 
                let kpis = ["Occ", "Rooms", "ADR", "Revenue"];
                /** BL to get the Rate plans  */
                let rateCodesMapping = dataService.getSeriesById("GetRateCodeMapping").data;
                let ratePlanDataAll = deepCopy(getRatePlan);
                let ratePlanData = deepCopy(getRatePlan);
                let ratePlanPaceData = deepCopy(GetPaceRatePlan);
                let ratePlanPaceDataAll = deepCopy(GetPaceRatePlan);
             
                ratePlanData = (ratePlanData && ratePlanData.data.length > 0) ? ratePlanData.data.filter(itmData => moment(itmData.index.date).format("YYYY-MM-DD") === moment(stay_date).format("YYYY-MM-DD")) : [];
                ratePlanPaceData = (ratePlanPaceData && ratePlanPaceData.data.length > 0) ? ratePlanPaceData.data.filter(itmData => moment(itmData.index.date).format("YYYY-MM-DD") === moment(stay_date).format("YYYY-MM-DD")) : [];
             
                if(active_tiles.length == 1 && active_tiles[0] == "pace") {
                ratePlanData = ratePlanPaceData;
               }
                if (ratePlanData) {
                    let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
                    let segment_ignore_codes = getFromState('system_settings>market_code_ignorelist', state);
                    let segments = getTransformedSegments(marketSegments, segment_ignore_codes);
                    let codes = Object.keys(segments.map).filter((item) => segments.map[item] === segmentName)
                    let ratePlanCodes = [];
                    codes.map((code) => {
                        if (rateCodesMapping && rateCodesMapping["agg"][code]) {
                            ratePlanCodes = [...ratePlanCodes, ...rateCodesMapping["agg"][code]];
                        }
                    })

                    let finalData = [];
                    /** Full final data calc */
                    let finalFullData = [];
                    let plansTotal = {};
                    let daysCount = daysCountFromRange(app_state);
                    let capacity = getAppData(auth, app_state).property_current_total_capacity * daysCount;
                    ratePlanCodes.map((item) => {
                        plansTotal[item] = {
                            "data": {
                                "primary": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "pickup": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "prior": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "pace": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "sdly": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "sdly_pickup": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "sdtly": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "sdtly_pickup": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "comparison": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0, date: '' },
                                "ratePlanCode": null
                            }
                        };
                    })
                    /** end full final  */
                    ratePlanCodes.map((item) => {
                        // debugger;
                        if (ratePlanData[0] && ratePlanData[0]["Segment_" + item]) {
                            let obj = {};
                            if (ratePlanPaceData[0]) {
                                ratePlanData[0]["Segment_" + item]['pace'] = (ratePlanPaceData[0]["Segment_" + item]) ? ratePlanPaceData[0]["Segment_" + item].primary : { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 };
                                ratePlanData[0]["Segment_" + item]['comparison'] = (ratePlanPaceData[0]["Segment_" + item]) ? ratePlanPaceData[0]["Segment_" + item].comparison : { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0, date: ratePlanPaceData[0]["HotelTotal"].comparison.date };
                            }
                            let data = ratePlanData[0]["Segment_" + item];
                            if (active_tiles.length) {
                                active_tiles.map((tile) => {
                                    if (tile === 'pickup') {
                                        data = getRatePlanPickupData(data, item, dayDate);
                                    }
                                })
                            }
                            obj.data = data;
                            obj.ratePlanCode = item;
                            finalData.push(obj);
                        }
                        ratePlanDataAll.data.map((itm) => {
                            if (itm["Segment_" + item]) {
                                plansTotal[item].ratePlanCode = item;
                                let mainKeys = Object.keys(plansTotal[item].data);
                                if (active_tiles.length) {
                                    active_tiles.map((tile) => {
                                        if (tile === 'otb') {
                                            kpis.map((kpi) => {
                                                mainKeys.map((mainKey) => {
                                                    if (itm["Segment_" + item][mainKey]) {
                                                        if (kpi === "ADR") {
                                                            let adr = getCorrectValue(plansTotal[item].data[mainKey].Revenue / plansTotal[item].data[mainKey]['Rooms'])
                                                            plansTotal[item].data[mainKey]['ADR'] = adr;
                                                        } else {
                                                            plansTotal[item].data[mainKey][kpi] += itm["Segment_" + item][mainKey][kpi];
                                                        }
                                                    }
                                                })
                                            })
                                            mainKeys.map((mainKey) => {
                                                if (itm["Segment_" + item][mainKey]) {
                                                    plansTotal[item].data[mainKey].Occ = plansTotal[item].data[mainKey].Rooms / capacity;
                                                }
                                            })
                                        } else if (tile === 'pickup') {
                                            // debugger;
                                            let dt = itm["Segment_" + item];
                                            let pickupData = getRatePlanPickupData(dt, item, dayDate);
                                            kpis.map((kpi) => {
                                                if (kpi === 'ADR') {
                                                    let adrPickup = pickupData.pickup.Revenue / pickupData.pickup.Rooms;
                                                    plansTotal[item].data.pickup['ADR'] = adrPickup;

                                                    let adrSdly_pickup = pickupData.sdly_pickup.Revenue / pickupData.sdly_pickup.Rooms;
                                                    plansTotal[item].data.sdly_pickup['ADR'] = adrSdly_pickup;
                                                } else {
                                                    plansTotal[item].data.pickup[kpi] += pickupData.pickup[kpi];
                                                    plansTotal[item].data.sdly_pickup[kpi] += pickupData.sdly_pickup[kpi];
                                                }
                                            })
                                        } else if (tile === 'pace') {
                                            let ratePlanPaceDataAll_filtered = (ratePlanPaceDataAll && ratePlanPaceDataAll.data.length > 0) ? ratePlanPaceDataAll.data.filter(itmData => moment(itmData.index.date).format("YYYY-MM-DD") === moment(itm.index.date).format("YYYY-MM-DD")) : [];
                                            kpis.map((kpi) => {
                                                if (kpi === "date") {
                                                    plansTotal[item].data.comparison.date = ratePlanPaceDataAll_filtered[0]["HotelTotal"].comparison.date;
                                                } else if (kpi === "ADR") {
                                                    let adr = getCorrectValue(plansTotal[item].data.pace.Revenue / plansTotal[item].data.pace['Rooms'])
                                                    plansTotal[item].data.pace['ADR'] = adr;
                                                    let adrCompare = getCorrectValue(plansTotal[item].data.comparison.Revenue / plansTotal[item].data.comparison['Rooms'])
                                                    plansTotal[item].data.comparison['ADR'] = adrCompare;

                                                } else {
                                                    plansTotal[item].data.pace[kpi] += (ratePlanPaceDataAll_filtered[0] && ratePlanPaceDataAll_filtered[0]["Segment_" + item]) ? ratePlanPaceDataAll_filtered[0]["Segment_" + item].primary[kpi] : 0;
                                                    plansTotal[item].data.comparison[kpi] += (ratePlanPaceDataAll_filtered[0] && ratePlanPaceDataAll_filtered[0]["Segment_" + item]) ? ratePlanPaceDataAll_filtered[0]["Segment_" + item].comparison[kpi] : 0;
                                                }
                                            })
                                            plansTotal[item].data.pace.Occ = plansTotal[item].data.pace.Rooms / capacity;
                                            plansTotal[item].data.comparison.Occ = plansTotal[item].data.comparison.Rooms / capacity;
                                        }
                                    })
                                }

                                finalFullData.push(plansTotal);
                            }
                        })
                    })
                    setRatePlansTotal(finalFullData);
                    setRatePlans(finalData);
                    setShowRatePlan(true);
                    /** END BL  */
                }

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };


    const handleClick_x = (event) => {
        setAnchorEl(event.currentTarget);
        let pickupDays = app_state.workspace_controls.pickUpDays;
        let as_of_date = app_state.primary_stay_range.as_of_date;
        let stayDate = moment(stay_date).format('YYYY-MM-DD');
        let sdYear = app_state.workspace_controls.sdYear;
        let property_id = app_state.property_id;
        // Rate plan API
        let comp_as_of_date = app_state.comp_stay_range.comp_as_of_date;
        let compStayDate = app_state.comp_stay_range.date_from;
        const apiUrls = [];
        let apiRatePlan = "rms/properties/otb-rp/" + property_id + "/?includeStandard=true&includeSdly=true&fillMissingDays=true&pickupDays=" + pickupDays + "&asOfDate=" + as_of_date + "&stayDate=" + stayDate + "&sdYear=" + sdYear;
        // let apiRatePlan = "rms/properties/otb-rp/" + property_id + "/?includeStandard=true&includeSdly=true&fillMissingDays=true&pickupDays=" + pickupDays + "&asOfDate=" + as_of_date + "&fromDate=" + stayDate + "&toDate=" + stayDate + "&sdYear=" + sdYear;
        let apiRatePlanPace = "rms/properties/booking-curve-rp/" + property_id + "/?scope=DATE" + "&asOfDate=" + as_of_date + "&fromStayDate=" + stayDate + "&toStayDate=" + stayDate + "&compAsOfDate=" + comp_as_of_date + "&compFromStayDate=" + compStayDate + "&compToStayDate=" + compStayDate
        if(in_array(active_tiles,['otb','pickup'])){
            apiUrls.push({api:apiRatePlan,type:'otb'});
        }
        if(in_array(active_tiles,['pace'])){
            apiUrls.push({api:apiRatePlanPace,type:'pace'});
        }
        sendMultipleAPIs(apiUrls, 'GET')
            .then(responses => {
                let getRatePlan = {};
                let GetPaceRatePlan = {};
                getRatePlan = responses[0];
                GetPaceRatePlan = (active_tiles.length > 1) ? responses[1] : responses[0];

                // End rate Plan 
                let kpis = ["Occ", "Rooms", "ADR", "Revenue"];
                /** BL to get the Rate plans  */
                let rateCodesMapping = dataService.getSeriesById("GetRateCodeMapping").data;
                let ratePlanDataAll = deepCopy(getRatePlan);
                let ratePlanData = deepCopy(getRatePlan);
                let ratePlanPaceData = deepCopy(GetPaceRatePlan);
                let ratePlanPaceDataAll = deepCopy(GetPaceRatePlan);
                debugger;
             
                ratePlanData = (ratePlanData && ratePlanData.data.length > 0) ? ratePlanData.data.filter(itmData => moment(itmData.index.date).format("YYYY-MM-DD") === moment(stay_date).format("YYYY-MM-DD")) : [];
                ratePlanPaceData = (ratePlanPaceData && ratePlanPaceData.data.length > 0) ? ratePlanPaceData.data.filter(itmData => moment(itmData.index.date).format("YYYY-MM-DD") === moment(stay_date).format("YYYY-MM-DD")) : [];
             
                if(active_tiles.length == 1 && active_tiles[0] == "pace") {
                ratePlanData = ratePlanPaceData;
               }
                if (ratePlanData) {
                    let marketSegments = getFromState('system_settings>market_segment_category_mapping', state);
                    let segment_ignore_codes = getFromState('system_settings>market_code_ignorelist', state);
                    let segments = getTransformedSegments(marketSegments, segment_ignore_codes);
                    let codes = Object.keys(segments.map).filter((item) => segments.map[item] === segmentName)
                    let ratePlanCodes = [];
                    codes.map((code) => {
                        if (rateCodesMapping && rateCodesMapping["agg"][code]) {
                            ratePlanCodes = [...ratePlanCodes, ...rateCodesMapping["agg"][code]];
                        }
                    })

                    let finalData = [];
                    /** Full final data calc */
                    let finalFullData = [];
                    let plansTotal = {};
                    let daysCount = daysCountFromRange(app_state);
                    let capacity = getAppData(auth, app_state).property_current_total_capacity * daysCount;
                    ratePlanCodes.map((item) => {
                        plansTotal[item] = {
                            "data": {
                                "primary": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "pickup": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "prior": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "pace": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "sdly": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "sdly_pickup": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 },
                                "comparison": { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0, date: '' },
                                "ratePlanCode": null
                            }
                        };
                    })
                    /** end full final  */
                    ratePlanCodes.map((item) => {
                        // debugger;
                        if (ratePlanData[0] && ratePlanData[0]["Segment_" + item]) {
                            let obj = {};
                            if (ratePlanPaceData[0]) {
                                ratePlanData[0]["Segment_" + item]['pace'] = (ratePlanPaceData[0]["Segment_" + item]) ? ratePlanPaceData[0]["Segment_" + item].primary : { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0 };
                                ratePlanData[0]["Segment_" + item]['comparison'] = (ratePlanPaceData[0]["Segment_" + item]) ? ratePlanPaceData[0]["Segment_" + item].comparison : { Rooms: 0, Occ: 0, ADR: 0, Revenue: 0, date: ratePlanPaceData[0]["HotelTotal"].comparison.date };
                            }
                            let data = ratePlanData[0]["Segment_" + item];
                            if (active_tiles.length) {
                                active_tiles.map((tile) => {
                                    if (tile === 'pickup') {
                                        data = getRatePlanPickupData(data, item);
                                    }
                                })
                            }
                            obj.data = data;
                            obj.ratePlanCode = item;
                            finalData.push(obj);
                        }
                        ratePlanDataAll.data.map((itm) => {
                            if (itm["Segment_" + item]) {
                                plansTotal[item].ratePlanCode = item;
                                let mainKeys = Object.keys(plansTotal[item].data);
                                if (active_tiles.length) {
                                    active_tiles.map((tile) => {
                                        if (tile === 'otb') {
                                            kpis.map((kpi) => {
                                                mainKeys.map((mainKey) => {
                                                    if (itm["Segment_" + item][mainKey]) {
                                                        if (kpi === "ADR") {
                                                            let adr = getCorrectValue(plansTotal[item].data[mainKey].Revenue / plansTotal[item].data[mainKey]['Rooms'])
                                                            plansTotal[item].data[mainKey]['ADR'] = adr;
                                                        } else {
                                                            plansTotal[item].data[mainKey][kpi] += itm["Segment_" + item][mainKey][kpi];
                                                        }
                                                    }
                                                })
                                            })
                                            mainKeys.map((mainKey) => {
                                                if (itm["Segment_" + item][mainKey]) {
                                                    plansTotal[item].data[mainKey].Occ = plansTotal[item].data[mainKey].Rooms / capacity;
                                                }
                                            })
                                        } else if (tile === 'pickup') {
                                            // debugger;
                                            let dt = itm["Segment_" + item];
                                            let pickupData = getRatePlanPickupData(dt, item);
                                            kpis.map((kpi) => {
                                                if (kpi === 'ADR') {
                                                    let adrPickup = pickupData.pickup.Revenue / pickupData.pickup.Rooms;
                                                    plansTotal[item].data.pickup['ADR'] = adrPickup;

                                                    let adrSdly_pickup = pickupData.sdly_pickup.Revenue / pickupData.sdly_pickup.Rooms;
                                                    plansTotal[item].data.sdly_pickup['ADR'] = adrSdly_pickup;
                                                } else {
                                                    plansTotal[item].data.pickup[kpi] += pickupData.pickup[kpi];
                                                    plansTotal[item].data.sdly_pickup[kpi] += pickupData.sdly_pickup[kpi];
                                                }
                                            })
                                        } else if (tile === 'pace') {
                                            let ratePlanPaceDataAll_filtered = (ratePlanPaceDataAll && ratePlanPaceDataAll.data.length > 0) ? ratePlanPaceDataAll.data.filter(itmData => moment(itmData.index.date).format("YYYY-MM-DD") === moment(itm.index.date).format("YYYY-MM-DD")) : [];
                                            kpis.map((kpi) => {
                                                if (kpi === "date") {
                                                    plansTotal[item].data.comparison.date = ratePlanPaceDataAll_filtered[0]["HotelTotal"].comparison.date;
                                                } else if (kpi === "ADR") {
                                                    let adr = getCorrectValue(plansTotal[item].data.pace.Revenue / plansTotal[item].data.pace['Rooms'])
                                                    plansTotal[item].data.pace['ADR'] = adr;
                                                    let adrCompare = getCorrectValue(plansTotal[item].data.comparison.Revenue / plansTotal[item].data.comparison['Rooms'])
                                                    plansTotal[item].data.comparison['ADR'] = adrCompare;

                                                } else {
                                                    plansTotal[item].data.pace[kpi] += (ratePlanPaceDataAll_filtered[0] && ratePlanPaceDataAll_filtered[0]["Segment_" + item]) ? ratePlanPaceDataAll_filtered[0]["Segment_" + item].primary[kpi] : 0;
                                                    plansTotal[item].data.comparison[kpi] += (ratePlanPaceDataAll_filtered[0] && ratePlanPaceDataAll_filtered[0]["Segment_" + item]) ? ratePlanPaceDataAll_filtered[0]["Segment_" + item].comparison[kpi] : 0;
                                                }
                                            })
                                            plansTotal[item].data.pace.Occ = plansTotal[item].data.pace.Rooms / capacity;
                                            plansTotal[item].data.comparison.Occ = plansTotal[item].data.comparison.Rooms / capacity;
                                        }
                                    })
                                }

                                finalFullData.push(plansTotal);
                            }
                        })
                    })
                    setRatePlansTotal(finalFullData);
                    setRatePlans(finalData);
                    setShowRatePlan(true);
                    /** END BL  */
                }

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });



    };

    const getRatePlanPickupData = (data, item, dayDate) => {
        if (data) {
            let sdlyKey = (dayDate === 'day') ? "sdly" : "sdtly" ;
            let sdlyPickupKey = (dayDate === 'day') ? "sdly_pickup" : "sdtly_pickup" ;
            if (pickupType === 'Standard') {
                Object.keys(data.pickup).map((kpi) => {
                    if (kpi === 'ADR') {
                        let adrValue = (data['current']['Revenue'] - data['prior']['Revenue']) / (data['current']['Rooms'] - data['prior']['Rooms']);
                        data['pickup'][kpi] = (adrValue && !isNaN(adrValue) && isFinite(adrValue)) ? adrValue : 0;
                        let adrSdlyValue = (data[sdlyKey]['Revenue'] - data[sdlyPickupKey]['Revenue']) / (data[sdlyKey]['Rooms'] - data[sdlyPickupKey]['Rooms']);
                        data[sdlyPickupKey][kpi] = (adrSdlyValue && !isNaN(adrSdlyValue) && isFinite(adrSdlyValue)) ? adrSdlyValue : 0;
                    } else {
                        data['pickup'][kpi] = data['current'][kpi] - data['prior'][kpi];
                        data[sdlyPickupKey][kpi] = data[sdlyKey][kpi] - data[sdlyPickupKey][kpi];
                    }
                })
            } else {
                Object.keys(data.pickup).map((kpi) => {
                    if (kpi === 'ADR') {
                        let adrValue = (data['primary']['Revenue'] - data['pickup']['Revenue']) / (data['primary']['Rooms'] - data['pickup']['Rooms']);
                        data['pickup'][kpi] = (adrValue && !isNaN(adrValue) && isFinite(adrValue)) ? adrValue : 0;
                        // data['pickup'][kpi] = adrValue;

                        let adrSdlyValue = (data[sdlyKey]['Revenue'] - data[sdlyPickupKey]['Revenue']) / (data[sdlyKey]['Rooms'] - data[sdlyPickupKey]['Rooms']);
                        data[sdlyPickupKey][kpi] = (adrSdlyValue && isFinite(adrSdlyValue)) ? adrSdlyValue : 0;
                    } else {
                        data['pickup'][kpi] = data['primary'][kpi] - data['pickup'][kpi];
                        data[sdlyPickupKey][kpi] = data[sdlyKey][kpi] - data[sdlyPickupKey][kpi];
                    }
                })
            }
        }
        return data;
    }

    const handleClose = () => {
        setAnchorEl(null);
    };


    const updateActiveTile = (event, selectedTile) => {
        let tileArr = deepCopy(activeTiles)
        if (activeTiles.includes(selectedTile)) {
            tileArr = activeTiles.filter(x => x !== selectedTile);
        } else {
            tileArr.push(selectedTile);
        }
        if (tileArr && tileArr.length === 0) tileArr.push(selectedTile)
        setActiveTiles(tileArr)
    }
    let compAsof = app_state.comp_stay_range.comp_as_of_date
    let compdate = (in_array(active_tiles, ['pace']) && ratePlans && ratePlans[0] && ratePlans[0]['data']['comparison']) ? ratePlans[0]['data']['comparison'].date : compAsof;
    let metas = ratePlanMeta.current;
    return (
        <div className='rateplan-details'>
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                // onMouseOver={handleClick}
                // onMouseLeave={handleClose}
                sx={{ lineHeight: 1, fontSize: "18px", mt: "-7px" }}
            >
                {/* <Tooltip title={"Rate Plan Of " + segmentName} arrow followCursor> */}
                {permissions.rate_plan == 1 && <KeyboardDoubleArrowRightIcon sx={{ fontSize: 13, position: "absolute", top: 5, right: 0, cursor: "pointer", color: "#808080" }} />
                }

                {/* </Tooltip> */}
            </div>
            <Menu
                className='rateplan-details'
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}

                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        mt: -3,
                        ml: 2,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                <Box className='rateplan-box'>

                    <Button className='close-btn' onClick={handleClose}>
                        <CloseSharpIcon fontSize='small' />
                    </Button>
                    <div className='rateplan-box-title'>
                        <span>{truncate(segmentName)}</span>
                        {(stay_date &&
                            <span>{activeTiles.length <= 1 && moment(as_of_date).format("ddd, MMM DD, YYYY")}</span>
                        )}
                    </div>  
                    <Box className="rateplan-box-content" >
                        <TableContainer className='rateplan-table-container'>
                            <Table className='rateplan-table'>
                               {(showRatePlan && <> <RatePlanHeader ratePlanMeta={metas} segmentName={segmentName} stay_date={stay_date} compdate={compdate} activeTiles={activeTiles} /> </>)}
                                <TableBody sx={{ overflowX: "hidden", overflowY: "auto" }}>
                                    {(!showRatePlan &&
                                        <> <TableRow >
                                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                                        <TableCell align="center"><Skeleton className="skeleton-line" variant="rounded"></Skeleton></TableCell>
                                    </TableRow></>
                                    )}
                                    {(stay_date === "" && showRatePlan &&
                                        <>
                                            <RatePlanListTotal data={ratePlansTotal} activeTiles={activeTiles} />
                                        </>
                                    )}

                                    {(stay_date !== "" && ratePlans && ratePlans.length > 0 &&
                                        <>
                                            <RatePlanList data={ratePlans} activeTiles={activeTiles} dayDate={dayDate} />
                                        </>
                                    )}
                                    {(stay_date !== "" && ratePlans && ratePlans.length <= 0 &&
                                        <>
                                            <TableRow>
                                                <TableCell colSpan={7} className='table-heading' sx={{ textAlign: "center" }}>No Rate Plan Found!</TableCell>
                                            </TableRow>
                                        </>
                                    )}
                                </TableBody>
                            </Table></TableContainer>
                    </Box>

                    {/* Multiple tiles */}
                    {(active_tiles && active_tiles.length > 1 && <MultipleTileOptions updateActiveTile={updateActiveTile} activeTiles={activeTiles} />)}
                    {/* End Multiple tiles */}
                </Box>

            </Menu>
        </div>
    );
}